import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { Flex, Box, Text, Icon, Tooltip, Collapse } from '@chakra-ui/react'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import { isDesktop } from 'react-device-detect'
import {
    briefingParamsState,
    excludedAerodromesState,
    excludedAwsState,
    collapseDropdownsState,
} from '../../../../../globalState'

import { ReactComponent as AWSIcon } from '../../../../../icons/assets/aws.svg'
import { ReactComponent as AerodromeIcon } from '../../../../../icons/assets/aerodrome.svg'
import { ReactComponent as METARIcon } from '../../../../../icons/assets/metar.svg'
import { ReactComponent as TAFIcon } from '../../../../../icons/assets/taf.svg'
import { ReactComponent as ATISIcon } from '../../../../../icons/assets/atis.svg'
import { ReactComponent as NOTAMIcon } from '../../../../../icons/assets/notam-outline.svg'
import { ChevronRightIcon } from '@chakra-ui/icons'

export default function NoAerodromeInfo(props) {
    const { briefingData } = props
    const params = useRecoilValue(briefingParamsState)
    const excludedAerodromes = useRecoilValue(excludedAerodromesState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const [show, setShow] = useState(true)
    const handleToggle = () => setShow(!show)

    useEffect(() => {
        if (collapseDropdowns) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [collapseDropdowns])

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize } = useAdjustedFontSize()

    const noInfoOptions = [
        {
            dataType: 'NOTAM',
            icon: NOTAMIcon,
            enabled: params?.aerodromesNotam,
        },
        {
            dataType: 'METAR',
            icon: METARIcon,
            enabled: params?.aerodromesMetar,
        },
        {
            dataType: 'TAF',
            icon: TAFIcon,
            enabled: params?.aerodromesTaf,
        },
        {
            dataType: 'ATIS',
            icon: ATISIcon,
            enabled: params?.aerodromesAtis,
        },
    ]

    return (
        <>
            <Box
                bg="rgba(255,255,255,1)"
                w={{ base: '100%', md: '100%' }}
                borderRadius="20px"
                zIndex="1"
                transition="all ease 300ms"
                boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                _hover={{
                    boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                }}
                marginTop="25px"
                marginBottom="10px"
                overflow="hidden"
                onClick={handleToggle}
                cursor="pointer"
            >
                <Flex
                    bg="white"
                    direction="column"
                    width="100%"
                    w={{ base: '100%', md: '100%' }}
                    padding="15px"
                    minHeight="30px"
                    border="none"
                    zIndex="1"
                >
                    <Flex
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        width="100%"
                    >
                        <Flex
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            ml="3px"
                            mr="14px"
                        >
                            <Icon
                                as={AerodromeIcon}
                                width="28px"
                                height="28px"
                                color="#1778b7"
                            />
                        </Flex>
                        <Flex
                            justifyContent={{
                                base: 'flex-start',
                                '2xl': 'space-between',
                            }}
                            alignItems={{
                                base: 'flex-start',
                                '2xl': 'baseline',
                            }}
                            width="100%"
                            pr="15px"
                            minHeight="28px"
                            flexDirection={{ base: 'column', '2xl': 'row' }}
                            gap={{
                                base: '5px',
                                '2xl': '15px',
                            }}
                        >
                            <Text
                                textAlign="left"
                                color="#1778b7"
                                fontSize={calculateFontSize(0.85)}
                                fontWeight="600"
                                lineHeight="1.3"
                                pt="5px"
                                pb="3px"
                                textTransform="uppercase"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                            >
                                Selected Aerodromes
                                <span
                                    style={{
                                        display: show ? 'block' : 'inline',
                                        paddingLeft: '3px',
                                        opacity: show ? '0' : '1',
                                        visibility: show ? 'hidden' : 'visible',
                                        height: show ? '0px' : 'auto',
                                        minHeight: show ? '0px' : '28px',
                                        transition: 'opacity ease 250ms',
                                    }}
                                >
                                    with no information
                                </span>
                            </Text>
                            <Text
                                textAlign="left"
                                color="gray.400"
                                fontSize={calculateFontSize(0.8)}
                                lineHeight="1.3"
                                height={show ? '0px' : 'auto'}
                                opacity={show ? '0' : '1'}
                                visibility={show ? 'hidden' : 'visible'}
                                transition="opacity ease 250ms"
                            >
                                Expand to view selected aerodromes and those
                                with no information
                            </Text>
                        </Flex>
                        <Flex
                            color="gray.300"
                            gap="15px"
                            width="30px"
                            minHeight="28px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Icon
                                as={ChevronRightIcon}
                                boxSize={'22px'}
                                transform={
                                    show ? 'rotate(90deg)' : 'rotate(0deg)'
                                }
                                transition="all ease 250ms"
                            />
                        </Flex>
                    </Flex>
                    <Collapse in={show} animateOpacity>
                        <Flex
                            flexDirection="row"
                            flexWrap="wrap"
                            width="100%"
                            pt={{
                                base: '10px',
                                xl: '0px',
                            }}
                            pl={{
                                base: '5px',
                                xl: '45px',
                            }}
                            pr={{
                                base: '0px',
                                xl: '15px',
                            }}
                            pb="15px"
                            textAlign="left"
                            fontSize={calculateFontSize(0.8)}
                            fontWeight="400"
                            lineHeight="1.3"
                            gap="3px"
                        >
                            {briefingData.res.aerodromes &&
                            briefingData.res.aerodromes.length === 0
                                ? 'None'
                                : briefingData.res.aerodromes &&
                                  briefingData.res.aerodromes
                                      .sort((a, b) => {
                                          const aerodromeA = a.designator
                                          const aerodromeB = b.designator
                                          if (aerodromeA < aerodromeB) {
                                              return -1
                                          }
                                          if (aerodromeA > aerodromeB) {
                                              return 1
                                          } else {
                                              return 0
                                          }
                                      })
                                      .map((aerodrome, index, arr) => (
                                          <Flex
                                              key={`selected-${aerodrome.designator}-${index}`}
                                          >
                                              <Tooltip
                                                  display={
                                                      excludedAerodromes.includes(
                                                          aerodrome.designator
                                                      ) && isDesktop
                                                          ? 'flex'
                                                          : 'none'
                                                  }
                                                  // isDisabled={isDesktop ? false : true}
                                                  label={`You have excluded ${aerodrome.designator}`}
                                                  hasArrow
                                                  borderRadius="7px"
                                                  height="26px"
                                                  fontSize="0.7rem"
                                                  alignItems="center"
                                                  placement="top"
                                                  m="5px"
                                                  color="white"
                                                  bg="gray.500"
                                              >
                                                  <Text
                                                      as="span"
                                                      color={
                                                          excludedAerodromes.includes(
                                                              aerodrome.designator
                                                          )
                                                              ? 'orange.400'
                                                              : 'gray.500'
                                                      }
                                                      textDecoration={
                                                          excludedAerodromes.includes(
                                                              aerodrome.designator
                                                          )
                                                              ? 'line-through'
                                                              : 'none'
                                                      }
                                                  >
                                                      {aerodrome.designator}
                                                  </Text>
                                              </Tooltip>
                                              {index < arr.length - 1 && ', '}
                                          </Flex>
                                      ))}
                        </Flex>
                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            width="100%"
                            gap="12px"
                            pl={{
                                base: '3px',
                                xl: '40px',
                            }}
                            pb="6px"
                        >
                            {briefingData.res.aerodromes &&
                                noInfoOptions
                                    .filter((feat) =>
                                        feat.enabled ? true : !feat
                                    )
                                    .map((data) => (
                                        <Flex
                                            key={`AerodromesNoInfo${data.dataType}`}
                                            my="auto"
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            width="100%"
                                        >
                                            <Flex
                                                direction="column"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                ml="3px"
                                                mr="12px"
                                                pt="3px"
                                            >
                                                <Icon
                                                    as={data.icon}
                                                    width="22px"
                                                    height="22px"
                                                    color="#1778b7"
                                                />
                                            </Flex>
                                            <Flex
                                                my="auto"
                                                direction="column"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                width="100%"
                                            >
                                                <Text
                                                    textAlign="left"
                                                    color="#1778b7"
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                    fontWeight="500"
                                                    lineHeight="1.3"
                                                    pt="5px"
                                                    pb="3px"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                >
                                                    Selected Aerodromes with no{' '}
                                                    <strong>
                                                        {data.dataType}
                                                    </strong>{' '}
                                                    information
                                                </Text>
                                                <Flex
                                                    flexDirection="row"
                                                    flexWrap="wrap"
                                                    textAlign="left"
                                                    color="gray.500"
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                    fontWeight="400"
                                                    lineHeight="1.3"
                                                    pr="5px"
                                                    gap="3px"
                                                >
                                                    {briefingData.res
                                                        .aerodromes &&
                                                        briefingData.res.aerodromes
                                                            .filter(
                                                                (aerodrome) =>
                                                                    data.dataType ===
                                                                    'NOTAM'
                                                                        ? !aerodrome.notam
                                                                        : data.dataType ===
                                                                          'METAR'
                                                                        ? !aerodrome.metar
                                                                        : data.dataType ===
                                                                          'TAF'
                                                                        ? !aerodrome.taf
                                                                        : data.dataType ===
                                                                          'ATIS'
                                                                        ? !aerodrome.atis
                                                                        : null
                                                            )
                                                            .sort((a, b) => {
                                                                const aerodromeA =
                                                                    a.designator
                                                                const aerodromeB =
                                                                    b.designator
                                                                if (
                                                                    aerodromeA <
                                                                    aerodromeB
                                                                ) {
                                                                    return -1
                                                                }
                                                                if (
                                                                    aerodromeA >
                                                                    aerodromeB
                                                                ) {
                                                                    return 1
                                                                } else {
                                                                    return 0
                                                                }
                                                            })
                                                            .map(
                                                                (
                                                                    aerodrome,
                                                                    index,
                                                                    arr
                                                                ) => (
                                                                    <Flex
                                                                        key={`${aerodrome.designator}-${index}`}
                                                                    >
                                                                        <Tooltip
                                                                            display={
                                                                                excludedAerodromes.includes(
                                                                                    aerodrome.designator
                                                                                ) &&
                                                                                isDesktop
                                                                                    ? 'flex'
                                                                                    : 'none'
                                                                            }
                                                                            // isDisabled={isDesktop ? false : true}
                                                                            label={`You have excluded ${aerodrome.designator}`}
                                                                            hasArrow
                                                                            borderRadius="7px"
                                                                            height="26px"
                                                                            fontSize="0.7rem"
                                                                            alignItems="center"
                                                                            placement="top"
                                                                            m="5px"
                                                                            color="white"
                                                                            bg="gray.500"
                                                                        >
                                                                            <Text
                                                                                as="span"
                                                                                opacity={
                                                                                    excludedAerodromes.includes(
                                                                                        aerodrome.designator
                                                                                    )
                                                                                        ? '0.4'
                                                                                        : '1'
                                                                                }
                                                                                textDecoration={
                                                                                    excludedAerodromes.includes(
                                                                                        aerodrome.designator
                                                                                    )
                                                                                        ? 'line-through'
                                                                                        : 'none'
                                                                                }
                                                                                fontWeight={
                                                                                    excludedAerodromes.includes(
                                                                                        aerodrome.designator
                                                                                    )
                                                                                        ? '300'
                                                                                        : '400'
                                                                                }
                                                                            >
                                                                                {
                                                                                    aerodrome.designator
                                                                                }
                                                                            </Text>
                                                                        </Tooltip>
                                                                        {index <
                                                                            arr.length -
                                                                                1 &&
                                                                            ', '}
                                                                    </Flex>
                                                                )
                                                            )}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    ))}
                        </Flex>
                    </Collapse>
                </Flex>
            </Box>
        </>
    )
}

export function NoAwsInfo(props) {
    const { briefingData, awsWithNoData, awsWithNoDataList, dataLoading } =
        props
    const params = useRecoilValue(briefingParamsState)
    const excludedAws = useRecoilValue(excludedAwsState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const [show, setShow] = useState(true)
    const handleToggle = () => setShow(!show)

    useEffect(() => {
        if (collapseDropdowns) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [collapseDropdowns])

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize } = useAdjustedFontSize()

    return (
        <>
            <Box
                bg="rgba(255,255,255,1)"
                w={{ base: '100%', md: '100%' }}
                borderRadius="20px"
                zIndex="1"
                transition="all ease 300ms"
                boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                _hover={{
                    boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                }}
                marginTop="25px"
                marginBottom="10px"
                overflow="hidden"
                onClick={handleToggle}
                cursor="pointer"
            >
                <Flex
                    bg="white"
                    direction="column"
                    width="100%"
                    w={{ base: '100%', md: '100%' }}
                    padding="15px"
                    minHeight="30px"
                    border="none"
                    zIndex="1"
                >
                    <Flex
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        width="100%"
                    >
                        <Flex
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            ml="3px"
                            mr="14px"
                        >
                            <Icon
                                as={AWSIcon}
                                width="28px"
                                height="28px"
                                color="#1778b7"
                            />
                        </Flex>
                        <Flex
                            justifyContent={{
                                base: 'flex-start',
                                '2xl': 'space-between',
                            }}
                            alignItems={{
                                base: 'flex-start',
                                '2xl': 'baseline',
                            }}
                            width="100%"
                            pr="15px"
                            minHeight="28px"
                            flexDirection={{ base: 'column', '2xl': 'row' }}
                            gap={{
                                base: '5px',
                                '2xl': '15px',
                            }}
                        >
                            <Text
                                textAlign="left"
                                color="#1778b7"
                                fontSize={calculateFontSize(0.85)}
                                fontWeight="600"
                                lineHeight="1.3"
                                pt="5px"
                                pb="3px"
                                textTransform="uppercase"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                            >
                                Selected Automatic Weather Stations
                                <span
                                    style={{
                                        display: show ? 'block' : 'inline',
                                        paddingLeft: '3px',
                                        opacity: show ? '0' : '1',
                                        visibility: show ? 'hidden' : 'visible',
                                        height: show ? '0px' : 'auto',
                                        minHeight: show ? '0px' : '28px',
                                        transition: 'opacity ease 250ms',
                                    }}
                                >
                                    with no information
                                </span>
                            </Text>
                            <Text
                                textAlign="left"
                                color="gray.400"
                                fontSize={calculateFontSize(0.8)}
                                lineHeight="1.3"
                                height={show ? '0px' : 'auto'}
                                opacity={show ? '0' : '1'}
                                visibility={show ? 'hidden' : 'visible'}
                                transition="opacity ease 250ms"
                            >
                                Expand to view selected AWS and those with no
                                information
                            </Text>
                        </Flex>
                        <Flex
                            color="gray.300"
                            gap="15px"
                            width="30px"
                            minHeight="28px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Icon
                                as={ChevronRightIcon}
                                boxSize={'22px'}
                                transform={
                                    show ? 'rotate(90deg)' : 'rotate(0deg)'
                                }
                                transition="all ease 250ms"
                            />
                        </Flex>
                    </Flex>
                    <Collapse in={show} animateOpacity>
                        <Flex
                            flexDirection="row"
                            flexWrap="wrap"
                            width="100%"
                            pt={{
                                base: '10px',
                                xl: '0px',
                            }}
                            pl={{
                                base: '5px',
                                xl: '45px',
                            }}
                            pr={{
                                base: '0px',
                                xl: '15px',
                            }}
                            pb="10px"
                            textAlign="left"
                            fontSize={calculateFontSize(0.8)}
                            fontWeight="400"
                            lineHeight="1.3"
                            gap="3px"
                        >
                            {briefingData.res.aws &&
                            briefingData.res.aws.length === 0
                                ? 'None'
                                : briefingData.res.aws &&
                                  briefingData.res.aws
                                      .sort((a, b) => {
                                          const awsA = a.designator
                                          const awsB = b.designator
                                          if (awsA < awsB) {
                                              return -1
                                          }
                                          if (awsA > awsB) {
                                              return 1
                                          } else {
                                              return 0
                                          }
                                      })
                                      .map((aws, index, arr) => (
                                          <Flex
                                              key={`${aws.designator}-${index}`}
                                          >
                                              <Tooltip
                                                  display={
                                                      excludedAws.includes(
                                                          aws.designator
                                                      ) && isDesktop
                                                          ? 'flex'
                                                          : 'none'
                                                  }
                                                  // isDisabled={isDesktop ? false : true}
                                                  label={`You have excluded ${aws.designator}`}
                                                  hasArrow
                                                  borderRadius="7px"
                                                  height="26px"
                                                  fontSize="0.7rem"
                                                  alignItems="center"
                                                  placement="top"
                                                  m="5px"
                                                  color="white"
                                                  bg="gray.500"
                                              >
                                                  <Text
                                                      as="span"
                                                      color={
                                                          excludedAws.includes(
                                                              aws.designator
                                                          )
                                                              ? 'orange.400'
                                                              : 'gray.500'
                                                      }
                                                      textDecoration={
                                                          excludedAws.includes(
                                                              aws.designator
                                                          )
                                                              ? 'line-through'
                                                              : 'none'
                                                      }
                                                  >
                                                      {aws.designator}
                                                  </Text>
                                              </Tooltip>
                                              {index < arr.length - 1 && ', '}
                                          </Flex>
                                      ))}
                        </Flex>
                        {!dataLoading && // List of AWS with no data
                            params.aws &&
                            briefingData &&
                            briefingData.res &&
                            briefingData.res.aws && (
                                <Flex
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    width="100%"
                                    pl={{
                                        base: '5px',
                                        xl: '45px',
                                    }}
                                    pb="6px"
                                >
                                    <Text
                                        textAlign="left"
                                        color="#1778b7"
                                        fontSize={calculateFontSize(0.8)}
                                        fontWeight="500"
                                        lineHeight="1.3"
                                        pt="5px"
                                        pb="3px"
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    >
                                        Selected Automatic Weather Stations with
                                        no information
                                    </Text>
                                    <Flex flexDirection="row" flexWrap="wrap">
                                        <Text
                                            textAlign="left"
                                            flexDirection="row"
                                            color="gray.500"
                                            fontSize={calculateFontSize(0.8)}
                                            fontWeight="400"
                                            lineHeight="1.3"
                                            pr="5px"
                                        >
                                            {awsWithNoData
                                                ? awsWithNoDataList
                                                : 'None'}
                                        </Text>
                                    </Flex>
                                </Flex>
                            )}
                    </Collapse>
                </Flex>
            </Box>
        </>
    )
}
