import { useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    dataModeLayerSourcesState,
    selectedWxTileTimesState,
} from '../../../globalState'
import constructLayerSources from '../../../config/utils/constructLayerSources'

export default function DefineDataModeLayerSources() {
    const setDataModeLayerSources = useSetRecoilState(dataModeLayerSourcesState)
    const satelliteTimes = useRecoilValue(selectedWxTileTimesState)

    useEffect(() => {
        const sources = constructLayerSources(satelliteTimes)
        setDataModeLayerSources(sources)
    }, [satelliteTimes, setDataModeLayerSources])

    return null
}
