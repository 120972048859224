import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import {
    Box,
    Flex,
    Text,
    Button,
    useBreakpointValue,
    Tooltip,
    Icon,
} from '@chakra-ui/react'
import PremiumBadge from '../../../controls/PremiumBadge'
import {
    setModalVisibilityState,
    settingsMenuOpenState,
} from '../../../../globalState'
import { GiCheckMark } from 'react-icons/gi'

import { ReactComponent as Question } from '../../../../icons/assets/question.svg'

export default function SettingsToggle(props) {
    const {
        setProfileData,
        toggleActive,
        profileSettingsState,
        profileSettingText,
        profileSettingSubText,
        tooltipLabel,
        userTier,
        isPremium,
        showPremiumBadge,
        mfaExpired,
        toggleSwitch,
        toggleCheck,
        toggleIndicator,
        toggleOnIcon,
        onCloseHandler,
        callbackFunction,
        toggleColor,
        toggleColorHover,
        isBriefingOnly,
    } = props

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const questionButtonSize = useBreakpointValue({
        base: '1.2rem',
        md: '1.1rem',
    })

    const updateProfileSettings = () => {
        setProfileData((prevState) => {
            const profileSettings = prevState.profileSettings || {}
            const newSetting = profileSettings[profileSettingsState]
            const newProfileSetting =
                newSetting !== undefined ? !newSetting : true
            return {
                ...prevState,
                profileSettings: {
                    ...profileSettings,
                    [profileSettingsState]: newProfileSetting,
                },
            }
        })
    }

    return (
        <Flex
            fontWeight="400"
            cursor="pointer"
            color="light.80"
            onClick={() => {
                if (isBriefingOnly && profileSettingsState === 'disable_map') {
                    return null
                }
                if (profileSettingsState === 'disable_map') {
                    callbackFunction()
                } else if (profileSettingsState === 'brief_mode_only') {
                    callbackFunction()
                } else if (showPremiumBadge) {
                    if (!isPremium) {
                        updateProfileSettings()
                    } else if (mfaExpired && userTier > 1) {
                        setShowResetMfaModal(true)
                        setSettingsMenuOpen(false)
                    } else if (isPremium) {
                        setShowPricingTiersModal(true)
                        setSettingsMenuOpen(false)
                        onCloseHandler()
                    }
                } else {
                    updateProfileSettings()
                }
            }}
            _hover={{
                color: 'light.900',
            }}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            fontSize={{
                base: '0.8rem',
                lg: '0.8rem',
            }}
            height="32px"
        >
            <Flex
                gap={{
                    base: '2px',
                    smd: '5px',
                    md: '2px',
                    ml: '5px',
                }}
                lineHeight="1.4"
                width={{
                    base: '100%',
                    sm: 'auto',
                }}
                flexDirection={{
                    base: 'column',
                    smd: 'row',
                    md: 'column',
                    ml: 'row',
                }}
                alignItems="baseline"
                opacity={showPremiumBadge && isPremium ? '0.4' : '1'}
            >
                <Text>{profileSettingText}</Text>
                {profileSettingSubText && (
                    <Text color="gray.400" fontWeight="300" fontSize="0.75rem">
                        {profileSettingSubText}
                    </Text>
                )}
            </Flex>
            <Tooltip
                hasArrow
                px="7px"
                py="5px"
                fontSize="0.7rem"
                alignItems="center"
                textAlign="center"
                lineHeight="1.2"
                display="flex"
                whiteSpace="pre-line"
                label={tooltipLabel}
                placement="top"
                marginLeft="7px"
                marginBottom="5px"
                borderRadius="10px"
                bgColor={'gray.500'}
            >
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    pl="8px"
                    color="gray.400"
                    opacity="0.8"
                >
                    <Icon
                        as={Question}
                        width={questionButtonSize}
                        height={questionButtonSize}
                    />
                </Flex>
            </Tooltip>
            <Tooltip
                px="7px"
                py="5px"
                height="24px"
                fontSize="0.7rem"
                alignItems="center"
                textAlign="center"
                lineHeight="1.2"
                display="flex"
                label={
                    isBriefingOnly && profileSettingsState === 'disable_map'
                        ? 'Enabled with Briefing Only Mode'
                        : profileSettingsState === 'disable_map' && toggleActive
                        ? 'Map Disabled'
                        : profileSettingsState === 'disable_map' &&
                          !toggleActive
                        ? 'Map Enabled'
                        : toggleActive
                        ? 'Enabled'
                        : 'Disabled'
                }
                placement="left"
                marginRight="-10px"
                borderRadius="10px"
                border="1px solid #ededed"
                color="gray.600"
                bgColor={'white'}
            >
                <Flex my="auto" ml="auto" pl="10px">
                    {showPremiumBadge && isPremium && (
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            pr="10px"
                        >
                            <PremiumBadge />
                        </Flex>
                    )}
                    {!(
                        isBriefingOnly && profileSettingsState === 'disable_map'
                    ) && (
                        <Button
                            isDisabled={showPremiumBadge && isPremium}
                            {...toggleSwitch}
                            background={toggleActive ? toggleColor : 'gray.200'}
                            _hover={{
                                background: toggleActive
                                    ? toggleColorHover
                                    : 'gray.300',
                            }}
                            _focus={{
                                background: toggleActive
                                    ? toggleColorHover
                                    : 'gray.300',
                            }}
                            _active={{
                                background: toggleActive
                                    ? toggleColorHover
                                    : 'gray.300',
                            }}
                        >
                            <Box
                                {...toggleCheck}
                                opacity={toggleActive ? '0.6' : '0'}
                                pl="3px"
                                pr="1px"
                            >
                                <GiCheckMark fontSize="14px" />
                            </Box>
                            <Box
                                {...toggleIndicator}
                                background="light.10"
                                transform={
                                    toggleActive
                                        ? 'translateX(110%)'
                                        : 'translateX(0%)'
                                }
                            >
                                <Box
                                    {...toggleOnIcon}
                                    opacity={toggleActive ? '1' : '0.4'}
                                ></Box>
                            </Box>
                        </Button>
                    )}
                    {isBriefingOnly &&
                        profileSettingsState === 'disable_map' && (
                            <Flex
                                width="42px"
                                justifyContent="center"
                                alignItems="center"
                                color="orange.400"
                                pl="2px"
                            >
                                <GiCheckMark
                                    fontSize="1.4rem"
                                    fontWeight="700"
                                />
                            </Flex>
                        )}
                </Flex>
            </Tooltip>
        </Flex>
    )
}
