import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useBreakpointValue } from '@chakra-ui/react'

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
    position: 'absolute',
    width: '100%',
    height: 40,
    transform: 'translate(0%, -50%)',
    cursor: 'pointer',
}

const railInnerStyle = {
    position: 'absolute',
    width: '100%',
    height: 6,
    transform: 'translate(0%, -50%)',
    borderRadius: 4,
    pointerEvents: 'none',
}

export function SliderRail({ getRailProps }) {
    return (
        <>
            <Box style={railOuterStyle} {...getRailProps()} />
            <Box bgColor={'gray.200'} style={railInnerStyle} />
        </>
    )
}

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
}) {
    return (
        <>
            <Box
                style={{
                    left: `${percent}%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                    zIndex: 5,
                    width: 24,
                    height: 42,
                    cursor: 'pointer',
                    backgroundColor: 'none',
                }}
                {...getHandleProps(id)}
            />
            <Box
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `${percent}%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    width: 18,
                    height: 18,
                    borderRadius: '50%',
                    boxShadow: '0px 2px 6px -2px rgba(0,0,0,0.6)',
                    backgroundColor: 'white',
                }}
            />
        </>
    )
}

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

Handle.defaultProps = {
    disabled: false,
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({
    color,
    bg,
    index,
    source,
    target,
    getTrackProps,
    disabled,
}) {
    return (
        <Box
            background={bg}
            style={{
                position: 'absolute',
                transform: 'translate(0%, -50%)',
                height: 6,
                zIndex: 1,
                backgroundImage: color,
                borderRadius: 4,
                cursor: 'pointer',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    )
}

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

Track.defaultProps = {
    disabled: false,
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ index, tick, count, format, altPadding }) {
    return (
        <Box px="2px">
            <Box
                bgColor={'#C7CFD9'}
                ml={useBreakpointValue({
                    base: '1px',
                    md: '0px',
                })}
                mr={index === count - 1 ? '5px' : '0px'}
                style={{
                    position: 'absolute',
                    marginTop: 8,
                    width: 2.5,
                    height: 2.5,
                    borderRadius: 1.5,
                    left: `${tick.percent - (index === count - 1 ? 1 : 0)}%`,
                }}
            />
            <Box
                style={{
                    position: 'absolute',
                    marginTop: 15,
                    fontSize: 10,
                    textAlign: 'center',
                    fontFamily: 'Arial, san-serif',
                    marginLeft:
                        !altPadding && index === 0
                            ? '-10%'
                            : `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                <Text color="#C7CFD9" mt="-2px">
                    {format(tick.value)}
                </Text>
            </Box>
        </Box>
    )
}

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
}

Tick.defaultProps = {
    format: (d) => d,
}
