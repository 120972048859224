import React, { useCallback, forwardRef } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Badge, Tooltip } from '@chakra-ui/react'
import { useTier } from '../../auth/hooks'
import { RiStarLine } from 'react-icons/ri'

import { ReactComponent as ExpiredIcon } from '../../icons/assets/expired.svg'

import {
    setModalVisibilityState,
    settingsMenuOpenState,
    mfaVerifiedState,
} from '../../globalState'
import { MfaPreviouslyVerified } from '../functions/MfaHandler'

const PremiumBadge = forwardRef((props, ref) => {
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const previouslyVerified = MfaPreviouslyVerified()
    const userTier = useTier()
    const mfaExpired = userTier > 1 && !mfaVerified
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    return (
        <Tooltip
            label={
                previouslyVerified && mfaExpired
                    ? 'Your MFA requires reset'
                    : mfaExpired
                    ? 'Premium features require setting MFA'
                    : 'Premium Only'
            }
            hasArrow
            borderRadius="7px"
            height="26px"
            fontSize="0.8rem"
            alignItems="center"
            display="flex"
            placement="auto"
            m="5px"
            color="light.10"
            bg="brand.premium"
        >
            <Badge
                ref={ref}
                className="premiumBadge"
                bg="rgba(0, 188, 248, 1)"
                _hover={{ bg: 'rgb(0, 174, 230)' }}
                color="#FFFFFF"
                textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                borderRadius="10"
                p="1px"
                width="14px"
                height="14px"
                fontSize="0.7rem"
                fontWeight="600"
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex="1"
                cursor="pointer"
                onClick={() => {
                    if (mfaExpired) {
                        setShowResetMfaModal(true)
                        setSettingsMenuOpen(false)
                    } else {
                        setShowPricingTiersModal(true)
                        setSettingsMenuOpen(false)
                    }
                }}
            >
                {mfaExpired ? (
                    <ExpiredIcon color="#FFFFFF" height="12px" width="12px" />
                ) : (
                    <RiStarLine color="#FFFFFF" />
                )}
            </Badge>
        </Tooltip>
    )
})

export default PremiumBadge
