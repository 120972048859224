import React from 'react'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
} from '@react-pdf/renderer'
import { parseISO } from 'date-fns'

import formatDateTime from '../../../../../util/dateFormatter'

import AerodromesExport from './AerodromesExportPDF'
import NOTAMExport from './NOTAMExportPDF'
import METARExport from './METARExportPDF'
import TAFExport from './TAFExportPDF'
import ATISExport from './ATISExportPDF'
import AWSExport from './AWSExportPDF'
import EnrouteNotamExport from './EnrouteNotamExportPDF'
import AAWExport from './AAWExportPDF'
import SigmetExport from './SigmetExport'
import SigmetChartsExport from './SigmetChartsExportPDF'
import SigwxExport from './SigwxExportPDF'
import GraforExport from './GraforExportPDF'
import MSLExport from './MSLExportPDF'
import SpaceWxExport from './SpaceWxExportPDF'

import { PreflightIcon } from './assets/SvgImages'

import OpenSansLight from './assets/fonts/OpenSans-Light.ttf'
import OpenSansLightItalic from './assets/fonts/OpenSans-LightItalic.ttf'
import OpenSansRegular from './assets/fonts/OpenSans-Regular.ttf'
import OpenSansItalic from './assets/fonts/OpenSans-Italic.ttf'
import OpenSansMedium from './assets/fonts/OpenSans-Medium.ttf'
import OpenSansMediumItalic from './assets/fonts/OpenSans-MediumItalic.ttf'
import OpenSansSemiBold from './assets/fonts/OpenSans-SemiBold.ttf'
import OpenSansSemiBoldItalic from './assets/fonts/OpenSans-SemiBoldItalic.ttf'
import OpenSansBold from './assets/fonts/OpenSans-Bold.ttf'
import OpenSansBoldItalic from './assets/fonts/OpenSans-BoldItalic.ttf'
import OpenSansExtraBold from './assets/fonts/OpenSans-ExtraBold.ttf'
import OpenSansExtraBoldItalic from './assets/fonts/OpenSans-ExtraBoldItalic.ttf'
import AzeretMonoThin from './assets/fonts/AzeretMono-Thin.ttf'
import AzeretMonoExtraLight from './assets/fonts/AzeretMono-ExtraLight.ttf'
import AzeretMonoLight from './assets/fonts/AzeretMono-Light.ttf'
import AzeretMonoRegular from './assets/fonts/AzeretMono-Regular.ttf'
import AzeretMonoMedium from './assets/fonts/AzeretMono-Medium.ttf'
import AzeretMonoSemiBold from './assets/fonts/AzeretMono-SemiBold.ttf'
import AzeretMonoBold from './assets/fonts/AzeretMono-Bold.ttf'
import AzeretMonoExtraBold from './assets/fonts/AzeretMono-ExtraBold.ttf'
import DroidSansMono from './assets/fonts/droid-sans-mono.regular.ttf'

export default function ExportPDF(props) {
    const {
        userRoles,
        briefingData,
        params,
        excludedAerodromes,
        excludedAws,
        timeZone,
        briefingMetarCount,
        selectedAreas,
        briefingAreaSource,
        periodToString,
        maxAltitude,
        showOutdatedErrors,
        aerodromesSort,
        briefingOrder,
        sigwxLevel,
        altAerodromeLayout,
        isPremium,
    } = props

    const periodTo = periodToString && parseISO(periodToString)

    const advancedUser = userRoles.includes('Professional')

    // Compile list of Selected Contents (selected from menu)
    const filteredContents = Object.keys(
        Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'aerodromes') // Remove 'aerodromes' group param
                .filter(([key, value]) => !(isPremium && key === 'aws')) // Remove 'aws' if isPremium
                .filter(([key, value]) => value === true) // Select only those ticked
        )
    )
    const selectedMetItemsToCapitalize = [
        'aws',
        'aaw',
        'sigmet',
        'sigwx',
        'msl',
        'grafor',
    ]
    const formattedContents =
        filteredContents &&
        Array.isArray(filteredContents) &&
        filteredContents.map((key) => {
            if (key === 'aerodromesNotam') {
                return 'NOTAM'
            } else if (key === 'aerodromesMetar') {
                return 'METAR'
            } else if (key === 'aerodromesTaf') {
                return 'TAF'
            } else if (key === 'aerodromesAtis') {
                return 'ATIS'
            } else if (key === 'enroute') {
                return 'En-route NOTAM'
            } else if (key === 'spacewx') {
                return 'SPACEWX'
            } else if (selectedMetItemsToCapitalize.includes(key)) {
                // capitalize each word
                return key.toUpperCase()
            } else {
                return key
            }
        })

    const noAws = briefingData && briefingData.res && !briefingData.res.aws

    const fonts =
        (Font.register({
            family: 'Open Sans',
            fonts: [
                { src: OpenSansLight, fontWeight: 300 },
                {
                    src: OpenSansLightItalic,
                    fontWeight: 300,
                    fontStyle: 'italic',
                },
                { src: OpenSansRegular, fontWeight: 400 },
                { src: OpenSansItalic, fontWeight: 400, fontStyle: 'italic' },
                { src: OpenSansMedium, fontWeight: 500 },
                {
                    src: OpenSansMediumItalic,
                    fontWeight: 500,
                    fontStyle: 'italic',
                },
                { src: OpenSansSemiBold, fontWeight: 600 },
                {
                    src: OpenSansSemiBoldItalic,
                    fontWeight: 600,
                    fontStyle: 'italic',
                },
                { src: OpenSansBold, fontWeight: 700 },
                {
                    src: OpenSansBoldItalic,
                    fontWeight: 700,
                    fontStyle: 'italic',
                },
                { src: OpenSansExtraBold, fontWeight: 800 },
                {
                    src: OpenSansExtraBoldItalic,
                    fontWeight: 800,
                    fontStyle: 'italic',
                },
            ],
        }),
        Font.register({
            family: 'AzeretMono',
            fonts: [
                { src: AzeretMonoThin, fontWeight: 100 },
                { src: AzeretMonoExtraLight, fontWeight: 200 },
                { src: AzeretMonoLight, fontWeight: 300 },
                { src: AzeretMonoRegular, fontWeight: 400 },
                { src: AzeretMonoMedium, fontWeight: 500 },
                { src: AzeretMonoSemiBold, fontWeight: 600 },
                { src: AzeretMonoBold, fontWeight: 700 },
                { src: AzeretMonoExtraBold, fontWeight: 800 },
            ],
        }),
        Font.register({
            family: 'DroidSansMono',
            src: DroidSansMono,
            fontWeight: 'normal',
        }))

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingLeft: 25,
            paddingRight: 25,
            paddingTop: 35,
            paddingBottom: 50,
            height: '100vh',
        },
        header: {
            overflow: 'hidden',
            fontSize: '11pt',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: 15,
            paddingLeft: 5,
            paddingRight: 0,
            fontWeight: '700',
            fontFamily: 'Open Sans',
            letterSpacing: '0.2',
            textTransform: 'uppercase',
            color: 'rgb(20, 100, 140)',
        },
        fixedHeaderLine: {
            // borderTop: '1px solid #e5e5e5',
            marginTop: -1,
            marginLeft: 5,
            marginRight: 5,
            flexGrow: 1,
        },
        infoBox: {
            border: '1px solid #e5e5e5',
            overflow: 'hidden',
            borderRadius: 0,
            margin: 5,
            marginTop: -1,
            flexGrow: 1,
        },
        spacer: {
            marginTop: 12,
            marginBottom: 12,
        },
        section: {
            border: '1px solid #e5e5e5',
            borderRadius: 0,
            marginLeft: 5,
            marginRight: 5,
            marginTop: 10,
            marginBottom: 10,
            flexGrow: 1,
        },
        sectionTitle: {
            backgroundColor: '#F7F8F9',
            borderBottom: '1px solid #e5e5e5',
            overflow: 'hidden',
            fontSize: '9pt',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
            textTransform: 'uppercase',
            fontWeight: '600',
            fontFamily: 'Open Sans',
        },
        sectionContent: {
            fontSize: '8pt',
            fontWeight: '300',
            fontFamily: 'Open Sans',
        },
        boxCell: {
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 0,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
            flexGrow: 1,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        labelNormal: {
            fontSize: '8pt',
            fontWeight: '600',
            fontFamily: 'Open Sans',
        },
        labelSmall: {
            fontSize: '7pt',
            fontWeight: '500',
            fontFamily: 'Open Sans',
        },
        textNormal: {
            fontSize: '8pt',
            fontWeight: '300',
            fontFamily: 'Open Sans',
        },
        textSmall: {
            fontSize: '7pt',
            fontWeight: '300',
            fontFamily: 'Open Sans',
        },
        error: {
            fontSize: '8pt',
            fontWeight: '300',
            fontFamily: 'Open Sans',
            color: 'rgb(255, 0, 0)',
        },
        errorBold: {
            paddingLeft: 1,
            fontSize: '8pt',
            fontWeight: '500',
            fontFamily: 'Open Sans',
            color: 'rgb(255, 0, 0)',
        },
        aawText: {
            fontSize: '7pt',
            fontWeight: '300',
            fontFamily: 'AzeretMono',
        },
        footerBox: {
            // borderTop: '1px solid #ededed',
            overflow: 'hidden',
            borderRadius: 0,
            marginLeft: 5,
            marginRight: 5,
            marginBottom: -20,
            paddingTop: 15, // was 10 with borderTop
            flexGrow: 1,
        },
        ifrBadge: {
            backgroundColor: 'rgb(146, 39, 143)',
            color: 'white',
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 1,
            marginRight: 3,
            borderRadius: 10,
            minWidth: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '7pt',
            fontWeight: '600',
            lineHeight: '1.4',
        },
        vfrBadge: {
            backgroundColor: 'rgb(0, 135, 188)',
            color: 'white',
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 1,
            marginRight: 3,
            borderRadius: 10,
            minWidth: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '7pt',
            fontWeight: '600',
        },
    })

    // PDF DOCUMENT
    if (briefingData) {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View
                        style={[
                            styles.header,
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            },
                        ]}
                    >
                        <PreflightIcon
                            width="90"
                            viewBox="0 0 146 30"
                            fill="rgb(20, 192, 154)"
                            alt="PreFlight"
                        />
                        <Text
                            style={{
                                marginRight: 5,
                            }}
                        >
                            PreFlight Information Briefing
                        </Text>
                    </View>
                    <View fixed style={styles.fixedHeaderLine} />

                    <View style={[styles.infoBox, { textAlign: 'center' }]}>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '1',
                                    border: '1px solid #e5e5e5',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                            >
                                <div /// INFO BOXES
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #e5e5e5',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: '2',
                                            borderRight: '1px solid #e5e5e5',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                borderBottom:
                                                    '1px solid #e5e5e5',
                                            }}
                                        >
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        borderRight:
                                                            '1px solid #e5e5e5',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelSmall,
                                                        { marginRight: 4 },
                                                    ]}
                                                >
                                                    Briefing ID:
                                                </Text>
                                                <Text
                                                    style={[
                                                        styles.textSmall,
                                                        {
                                                            marginRight: 8,
                                                        },
                                                    ]}
                                                >
                                                    {briefingData.id}
                                                </Text>
                                            </div>
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelSmall,
                                                        { marginRight: 4 },
                                                    ]}
                                                >
                                                    User:
                                                </Text>
                                                <Text
                                                    style={[
                                                        styles.textSmall,
                                                        {
                                                            marginRight: 8,
                                                        },
                                                    ]}
                                                >
                                                    {briefingData.user}
                                                </Text>
                                            </div>
                                        </div>

                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    borderBottom:
                                                        '1px solid #e5e5e5',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelSmall,
                                                    { marginRight: 4 },
                                                ]}
                                            >
                                                Selected Contents:
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.textSmall,
                                                    {
                                                        marginRight: 8,
                                                    },
                                                ]}
                                            >
                                                {formattedContents
                                                    .map((area) => area)
                                                    .join(', ')}
                                            </Text>
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            {briefingAreaSource ===
                                                'briefingarea' && (
                                                <div
                                                    style={[
                                                        styles.boxCell,
                                                        {
                                                            borderRight:
                                                                '1px solid #e5e5e5',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'flex-start',
                                                            alignItems:
                                                                'flex-start',
                                                        },
                                                    ]}
                                                >
                                                    <Text
                                                        style={[
                                                            styles.labelSmall,
                                                            { marginRight: 4 },
                                                        ]}
                                                    >
                                                        Briefing Areas Selected:
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            styles.textSmall,
                                                            {
                                                                marginRight: 8,
                                                            },
                                                        ]}
                                                    >
                                                        {selectedAreas
                                                            .map(
                                                                (area) =>
                                                                    area.designator
                                                            )
                                                            .join(', ')}
                                                    </Text>
                                                </div>
                                            )}
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelSmall,
                                                        { marginRight: 4 },
                                                    ]}
                                                >
                                                    {`${
                                                        briefingAreaSource ===
                                                        'briefingarea'
                                                            ? 'AAW Areas Included:'
                                                            : 'AAW Areas Selected:'
                                                    }`}
                                                </Text>
                                                <Text
                                                    style={[
                                                        styles.textSmall,
                                                        {
                                                            marginRight: 8,
                                                        },
                                                    ]}
                                                >
                                                    {briefingData.res.areamet &&
                                                        briefingData.res.areamet
                                                            .aaw &&
                                                        briefingData.res.areamet.aaw
                                                            .map(
                                                                (aaw) =>
                                                                    aaw.area
                                                            )
                                                            .join(', ')}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>

                                    <div /// TIME BOXES
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: '1',
                                        }}
                                    >
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    borderBottom:
                                                        '1px solid #e5e5e5',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelSmall,
                                                    { marginRight: 4 },
                                                ]}
                                            >
                                                Issued:
                                            </Text>

                                            <Text
                                                style={[
                                                    styles.textSmall,
                                                    {
                                                        marginRight: 8,
                                                    },
                                                ]}
                                            >
                                                {briefingData?.time &&
                                                    formatDateTime(
                                                        briefingData.time,
                                                        timeZone
                                                    )}
                                            </Text>
                                        </div>

                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    borderBottom:
                                                        '1px solid #e5e5e5',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelSmall,
                                                    { marginRight: 4 },
                                                ]}
                                            >
                                                Period To:
                                            </Text>

                                            <Text
                                                style={[
                                                    styles.textSmall,
                                                    {
                                                        marginRight: 8,
                                                    },
                                                ]}
                                            >
                                                {periodToString &&
                                                    formatDateTime(
                                                        periodToString,
                                                        timeZone
                                                    )}
                                            </Text>
                                        </div>

                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelSmall,
                                                    { marginRight: 4 },
                                                ]}
                                            >
                                                Altitude Selected:
                                            </Text>

                                            <Text
                                                style={[
                                                    styles.textSmall,
                                                    {
                                                        marginRight: 8,
                                                    },
                                                ]}
                                            >
                                                SFC{' '}
                                                {maxAltitude === 0
                                                    ? 'only'
                                                    : maxAltitude < 13500
                                                    ? `– ${
                                                          maxAltitude >= 10000
                                                              ? maxAltitude.toLocaleString(
                                                                    'en'
                                                                )
                                                              : maxAltitude
                                                      } ft`
                                                    : maxAltitude === 10000
                                                    ? '– 10,000 ft'
                                                    : `– FL${
                                                          maxAltitude / 100
                                                      }`}
                                            </Text>
                                        </div>
                                    </div>
                                </div>

                                <div /// SELECTED AERODROMES and AWS
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div /// AERODROMES
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            borderBottom: '1px solid #e5e5e5',
                                        }}
                                    >
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelSmall,
                                                    {
                                                        marginRight: 4,
                                                    },
                                                ]}
                                            >
                                                Aerodromes Selected:
                                            </Text>

                                            <Text
                                                style={[
                                                    styles.textSmall,
                                                    {
                                                        marginRight: 2,
                                                        textAlign: 'left',
                                                        whiteSpace: 'pre-wrap',
                                                        paddingLeft: 5,
                                                        paddingRight: 5,
                                                        width: '90%',
                                                    },
                                                ]}
                                            >
                                                {briefingData.res &&
                                                briefingData.res.aerodromes
                                                    .length
                                                    ? briefingData.res
                                                          .aerodromes &&
                                                      briefingData.res.aerodromes
                                                          .filter(
                                                              (aerodrome) =>
                                                                  !excludedAerodromes.includes(
                                                                      aerodrome.designator
                                                                  )
                                                          ) // Filter out excluded aerodromes
                                                          .sort((a, b) => {
                                                              const aerodromeA =
                                                                  a.designator
                                                              const aerodromeB =
                                                                  b.designator
                                                              if (
                                                                  aerodromeA <
                                                                  aerodromeB
                                                              ) {
                                                                  return -1
                                                              }
                                                              if (
                                                                  aerodromeA >
                                                                  aerodromeB
                                                              ) {
                                                                  return 1
                                                              } else {
                                                                  return 0
                                                              }
                                                          })
                                                          .map(
                                                              (aerodromes) =>
                                                                  aerodromes.designator
                                                          )
                                                          .join(', ')
                                                    : 'None'}
                                            </Text>
                                        </div>
                                    </div>

                                    {!isPremium && params.aws && (
                                        <div /// AWS
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                borderBottom:
                                                    '1px solid #e5e5e5',
                                            }}
                                        >
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelSmall,
                                                        {
                                                            marginRight: 4,
                                                        },
                                                    ]}
                                                >
                                                    AWS Selected:
                                                </Text>

                                                <Text
                                                    style={[
                                                        styles.textSmall,
                                                        {
                                                            marginRight: 2,
                                                            textAlign: 'left',
                                                            whiteSpace:
                                                                'pre-wrap',
                                                            paddingLeft: 5,
                                                            paddingRight: 5,
                                                            width: '90%',
                                                        },
                                                    ]}
                                                >
                                                    {noAws
                                                        ? 'There is no AWS information available for the selected areas'
                                                        : briefingData.res
                                                              .aws &&
                                                          briefingData.res.aws
                                                              .length === 0
                                                        ? 'None'
                                                        : briefingData.res
                                                              .aws &&
                                                          briefingData.res.aws
                                                              .sort((a, b) => {
                                                                  const awsA =
                                                                      a.designator
                                                                  const awsB =
                                                                      b.designator
                                                                  if (
                                                                      awsA <
                                                                      awsB
                                                                  ) {
                                                                      return -1
                                                                  }
                                                                  if (
                                                                      awsA >
                                                                      awsB
                                                                  ) {
                                                                      return 1
                                                                  } else {
                                                                      return 0
                                                                  }
                                                              })
                                                              .map(
                                                                  (aws) =>
                                                                      aws.designator
                                                              )
                                                              .join(', ')}
                                                </Text>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div /// DESELECTED AERODROMES and AWS
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div /// AERODROMES
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            borderBottom: '1px solid #e5e5e5',
                                        }}
                                    >
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelSmall,
                                                    {
                                                        marginRight: 4,
                                                    },
                                                ]}
                                            >
                                                Aerodromes Excluded:
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.textSmall,
                                                    {
                                                        marginRight: 2,
                                                        textAlign: 'left',
                                                        whiteSpace: 'pre-wrap',
                                                        paddingLeft: 5,
                                                        paddingRight: 5,
                                                        width: '90%',
                                                    },
                                                ]}
                                            >
                                                {excludedAerodromes &&
                                                excludedAerodromes.length
                                                    ? excludedAerodromes.join(
                                                          ', '
                                                      )
                                                    : 'None'}
                                            </Text>
                                        </div>
                                    </div>

                                    {!isPremium && params.aws && !noAws && (
                                        <div /// AWS
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                borderBottom:
                                                    '1px solid #e5e5e5',
                                            }}
                                        >
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelSmall,
                                                        {
                                                            marginRight: 4,
                                                        },
                                                    ]}
                                                >
                                                    AWS Excluded:
                                                </Text>
                                                <Text
                                                    style={[
                                                        styles.textSmall,
                                                        {
                                                            marginRight: 2,
                                                            textAlign: 'left',
                                                            whiteSpace:
                                                                'pre-wrap',
                                                            paddingLeft: 5,
                                                            paddingRight: 5,
                                                            width: '90%',
                                                        },
                                                    ]}
                                                >
                                                    {excludedAws &&
                                                    excludedAws.length === 0
                                                        ? 'None'
                                                        : excludedAws &&
                                                          excludedAws
                                                              .map((aws) => aws)
                                                              .join(', ')}
                                                </Text>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div /// AERODROMES and AWS WITH NO INFORMATION
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {/* {!altAerodromeLayout && ( */}
                                    <div /// AERODROMES
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            borderBottom:
                                                !isPremium &&
                                                params.aws &&
                                                '1px solid #e5e5e5',
                                        }}
                                    >
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelSmall,
                                                    {
                                                        marginRight: 4,
                                                    },
                                                ]}
                                            >
                                                Aerodromes with No Information:
                                            </Text>

                                            <Text
                                                style={[
                                                    styles.textSmall,
                                                    {
                                                        marginRight: 2,
                                                        textAlign: 'left',
                                                        whiteSpace: 'pre-wrap',
                                                        paddingLeft: 5,
                                                        paddingRight: 5,
                                                        width: '90%',
                                                        flexGrow: '2',
                                                    },
                                                ]}
                                            >
                                                {briefingData.res.aerodromes &&
                                                    briefingData.res.aerodromes
                                                        .filter(
                                                            (aerodrome) =>
                                                                !aerodrome.metar &&
                                                                !aerodrome.taf &&
                                                                !aerodrome.atis &&
                                                                !aerodrome.notam
                                                        )
                                                        .sort((a, b) => {
                                                            const aerodromeA =
                                                                a.designator
                                                            const aerodromeB =
                                                                b.designator
                                                            if (
                                                                aerodromeA <
                                                                aerodromeB
                                                            ) {
                                                                return -1
                                                            }
                                                            if (
                                                                aerodromeA >
                                                                aerodromeB
                                                            ) {
                                                                return 1
                                                            } else {
                                                                return 0
                                                            }
                                                        })
                                                        .map(
                                                            (aerodrome) =>
                                                                aerodrome.designator
                                                        )
                                                        .join(', ')}
                                            </Text>
                                        </div>
                                        {/* )} */}

                                        {/* {altAerodromeLayout && (
                                            <>
                                                <div /// AERODROMES WITH NO METAR INFORMATION
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        borderBottom:
                                                            '1px solid #e5e5e5',
                                                    }}
                                                >
                                                    <div
                                                        style={[
                                                            styles.boxCell,
                                                            {
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent:
                                                                    'flex-start',
                                                                alignItems:
                                                                    'flex-start',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                styles.labelSmall,
                                                                {
                                                                    marginRight: 4,
                                                                },
                                                            ]}
                                                        >
                                                            Aerodromes with No METAR
                                                            Information:
                                                        </Text>

                                                        <Text
                                                            style={[
                                                                styles.textSmall,
                                                                {
                                                                    marginRight: 2,
                                                                    textAlign: 'left',
                                                                    whiteSpace:
                                                                        'pre-wrap',
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                    width: '90%',
                                                                    flexGrow: '2',
                                                                },
                                                            ]}
                                                        >
                                                            {briefingData.res
                                                                .aerodromes &&
                                                                briefingData.res.aerodromes
                                                                    .filter(
                                                                        (aerodrome) =>
                                                                            !aerodrome.metar
                                                                    )
                                                                    .sort((a, b) => {
                                                                        const aerodromeA =
                                                                            a.designator
                                                                        const aerodromeB =
                                                                            b.designator
                                                                        if (
                                                                            aerodromeA <
                                                                            aerodromeB
                                                                        ) {
                                                                            return -1
                                                                        }
                                                                        if (
                                                                            aerodromeA >
                                                                            aerodromeB
                                                                        ) {
                                                                            return 1
                                                                        } else {
                                                                            return 0
                                                                        }
                                                                    })
                                                                    .map(
                                                                        (aerodrome) =>
                                                                            aerodrome.designator
                                                                    )
                                                                    .join(', ')}
                                                        </Text>
                                                    </div>
                                                </div>

                                                <div /// AERODROMES WITH NO TAF INFORMATION
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        borderBottom:
                                                            '1px solid #e5e5e5',
                                                    }}
                                                >
                                                    <div
                                                        style={[
                                                            styles.boxCell,
                                                            {
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent:
                                                                    'flex-start',
                                                                alignItems:
                                                                    'flex-start',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                styles.labelSmall,
                                                                {
                                                                    marginRight: 4,
                                                                },
                                                            ]}
                                                        >
                                                            Aerodromes with No TAF
                                                            Information:
                                                        </Text>

                                                        <Text
                                                            style={[
                                                                styles.textSmall,
                                                                {
                                                                    marginRight: 2,
                                                                    textAlign: 'left',
                                                                    whiteSpace:
                                                                        'pre-wrap',
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                    width: '90%',
                                                                    flexGrow: '2',
                                                                },
                                                            ]}
                                                        >
                                                            {briefingData.res
                                                                .aerodromes &&
                                                                briefingData.res.aerodromes
                                                                    .filter(
                                                                        (aerodrome) =>
                                                                            !aerodrome.taf
                                                                    )
                                                                    .sort((a, b) => {
                                                                        const aerodromeA =
                                                                            a.designator
                                                                        const aerodromeB =
                                                                            b.designator
                                                                        if (
                                                                            aerodromeA <
                                                                            aerodromeB
                                                                        ) {
                                                                            return -1
                                                                        }
                                                                        if (
                                                                            aerodromeA >
                                                                            aerodromeB
                                                                        ) {
                                                                            return 1
                                                                        } else {
                                                                            return 0
                                                                        }
                                                                    })
                                                                    .map(
                                                                        (aerodrome) =>
                                                                            aerodrome.designator
                                                                    )
                                                                    .join(', ')}
                                                        </Text>
                                                    </div>
                                                </div>

                                                <div /// AERODROMES WITH NO ATIS INFORMATION
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        borderBottom:
                                                            '1px solid #e5e5e5',
                                                    }}
                                                >
                                                    <div
                                                        style={[
                                                            styles.boxCell,
                                                            {
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent:
                                                                    'flex-start',
                                                                alignItems:
                                                                    'flex-start',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                styles.labelSmall,
                                                                {
                                                                    marginRight: 4,
                                                                },
                                                            ]}
                                                        >
                                                            Aerodromes with No ATIS
                                                            Information:
                                                        </Text>

                                                        <Text
                                                            style={[
                                                                styles.textSmall,
                                                                {
                                                                    marginRight: 2,
                                                                    textAlign: 'left',
                                                                    whiteSpace:
                                                                        'pre-wrap',
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                    width: '90%',
                                                                    flexGrow: '2',
                                                                },
                                                            ]}
                                                        >
                                                            {briefingData.res
                                                                .aerodromes &&
                                                                briefingData.res.aerodromes
                                                                    .filter(
                                                                        (aerodrome) =>
                                                                            !aerodrome.atis
                                                                    )
                                                                    .sort((a, b) => {
                                                                        const aerodromeA =
                                                                            a.designator
                                                                        const aerodromeB =
                                                                            b.designator
                                                                        if (
                                                                            aerodromeA <
                                                                            aerodromeB
                                                                        ) {
                                                                            return -1
                                                                        }
                                                                        if (
                                                                            aerodromeA >
                                                                            aerodromeB
                                                                        ) {
                                                                            return 1
                                                                        } else {
                                                                            return 0
                                                                        }
                                                                    })
                                                                    .map(
                                                                        (aerodrome) =>
                                                                            aerodrome.designator
                                                                    )
                                                                    .join(', ')}
                                                        </Text>
                                                    </div>
                                                </div>

                                                <div /// AERODROMES WITH NO NOTAM INFORMATION
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <div
                                                        style={[
                                                            styles.boxCell,
                                                            {
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent:
                                                                    'flex-start',
                                                                alignItems:
                                                                    'flex-start',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={[
                                                                styles.labelSmall,
                                                                {
                                                                    marginRight: 4,
                                                                },
                                                            ]}
                                                        >
                                                            Aerodromes with No NOTAM
                                                            Information:
                                                        </Text>

                                                        <Text
                                                            style={[
                                                                styles.textSmall,
                                                                {
                                                                    marginRight: 2,
                                                                    textAlign: 'left',
                                                                    whiteSpace:
                                                                        'pre-wrap',
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                    width: '90%',
                                                                    flexGrow: '2',
                                                                },
                                                            ]}
                                                        >
                                                            {briefingData.res
                                                                .aerodromes &&
                                                                briefingData.res.aerodromes
                                                                    .filter(
                                                                        (aerodrome) =>
                                                                            !aerodrome.notam
                                                                    )
                                                                    .sort((a, b) => {
                                                                        const aerodromeA =
                                                                            a.designator
                                                                        const aerodromeB =
                                                                            b.designator
                                                                        if (
                                                                            aerodromeA <
                                                                            aerodromeB
                                                                        ) {
                                                                            return -1
                                                                        }
                                                                        if (
                                                                            aerodromeA >
                                                                            aerodromeB
                                                                        ) {
                                                                            return 1
                                                                        } else {
                                                                            return 0
                                                                        }
                                                                    })
                                                                    .map(
                                                                        (aerodrome) =>
                                                                            aerodrome.designator
                                                                    )
                                                                    .join(', ')}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </>
                                        )} */}
                                    </div>

                                    {!isPremium && params.aws && !noAws && (
                                        <div /// AWS
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelSmall,
                                                        {
                                                            marginRight: 4,
                                                        },
                                                    ]}
                                                >
                                                    AWS with No Information:
                                                </Text>

                                                <Text
                                                    style={[
                                                        styles.textSmall,
                                                        {
                                                            marginRight: 2,
                                                            textAlign: 'left',
                                                            whiteSpace:
                                                                'pre-wrap',
                                                            paddingLeft: 5,
                                                            paddingRight: 5,
                                                            width: '90%',
                                                            flexGrow: '2',
                                                        },
                                                    ]}
                                                >
                                                    {briefingData &&
                                                        briefingData.res &&
                                                        briefingData.res.aws &&
                                                        briefingData.res.aws
                                                            .sort((a, b) => {
                                                                const awsA =
                                                                    a.designator
                                                                const awsB =
                                                                    b.designator
                                                                if (
                                                                    awsA < awsB
                                                                ) {
                                                                    return -1
                                                                }
                                                                if (
                                                                    awsA > awsB
                                                                ) {
                                                                    return 1
                                                                } else {
                                                                    return 0
                                                                }
                                                            })
                                                            .filter(
                                                                (key) =>
                                                                    !key.data ||
                                                                    (key.data
                                                                        ?.obs_timestamp &&
                                                                        Object.keys(
                                                                            key.data
                                                                        )
                                                                            ?.length ===
                                                                            1)
                                                            )
                                                            .map(
                                                                (aws) =>
                                                                    aws.designator
                                                            )
                                                            .join(', ')}
                                                </Text>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </View>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: briefingOrder
                                ? 'column-reverse'
                                : 'column',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {params.aerodromes && // AERODROMES SECTION (STANDARD LAYOUT)
                                !altAerodromeLayout &&
                                briefingData &&
                                briefingData.res &&
                                briefingData.res.aerodromes &&
                                briefingData.res.aerodromes
                                    .sort((a, b) =>
                                        aerodromesSort
                                            ? a.name > b.name
                                            : a.lat < b.lat
                                            ? 1
                                            : -1
                                    )
                                    .map((aerodrome) => {
                                        if (
                                            excludedAerodromes.includes(
                                                aerodrome.designator
                                            )
                                        ) {
                                            return null
                                        } else {
                                            return (
                                                <AerodromesExport
                                                    key={`${aerodrome.designator}`}
                                                    aerodrome={aerodrome}
                                                    briefingData={briefingData}
                                                    params={params}
                                                    timeZone={timeZone}
                                                    fonts={fonts}
                                                    styles={styles}
                                                    metarCount={
                                                        briefingMetarCount &&
                                                        briefingMetarCount[
                                                            aerodrome.designator
                                                        ]
                                                            ? briefingMetarCount[
                                                                  aerodrome
                                                                      .designator
                                                              ]
                                                            : 1
                                                    }
                                                    periodTo={periodTo}
                                                    showOutdatedErrors={
                                                        showOutdatedErrors
                                                    }
                                                />
                                            )
                                        }
                                    })}
                            {/* // AERODROMES SECTION (ALTERNATIVE LAYOUT) */}

                            {params.aerodromes &&
                                params.aerodromesNotam &&
                                altAerodromeLayout &&
                                briefingData && (
                                    <NOTAMExport
                                        briefingData={briefingData}
                                        periodTo={periodTo}
                                        timeZone={timeZone}
                                        fonts={fonts}
                                        styles={styles}
                                        showOutdatedErrors={showOutdatedErrors}
                                        aerodromesSort={aerodromesSort}
                                        excludedAerodromes={excludedAerodromes}
                                    />
                                )}
                            {params.aerodromes &&
                                params.aerodromesMetar &&
                                altAerodromeLayout &&
                                briefingData && (
                                    <METARExport
                                        briefingData={briefingData}
                                        timeZone={timeZone}
                                        fonts={fonts}
                                        styles={styles}
                                        briefingMetarCount={briefingMetarCount}
                                        showOutdatedErrors={showOutdatedErrors}
                                        aerodromesSort={aerodromesSort}
                                        excludedAerodromes={excludedAerodromes}
                                    />
                                )}
                            {params.aerodromes &&
                                params.aerodromesTaf &&
                                altAerodromeLayout &&
                                briefingData && (
                                    <TAFExport
                                        briefingData={briefingData}
                                        timeZone={timeZone}
                                        fonts={fonts}
                                        styles={styles}
                                        showOutdatedErrors={showOutdatedErrors}
                                        aerodromesSort={aerodromesSort}
                                        excludedAerodromes={excludedAerodromes}
                                    />
                                )}
                            {params.aerodromes &&
                                params.aerodromesAtis &&
                                altAerodromeLayout &&
                                briefingData && (
                                    <ATISExport
                                        briefingData={briefingData}
                                        timeZone={timeZone}
                                        fonts={fonts}
                                        styles={styles}
                                        showOutdatedErrors={showOutdatedErrors}
                                        aerodromesSort={aerodromesSort}
                                        excludedAerodromes={excludedAerodromes}
                                    />
                                )}

                            {!isPremium && params.aws && briefingData && (
                                <AWSExport
                                    briefingData={briefingData}
                                    timeZone={timeZone}
                                    fonts={fonts}
                                    styles={styles}
                                    showOutdatedErrors={showOutdatedErrors}
                                    aerodromesSort={aerodromesSort}
                                    excludedAws={excludedAws}
                                />
                            )}

                            {(params.aerodromes ||
                                (!isPremium && params.aws)) && (
                                <View style={styles.spacer}></View>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {params.enroute && ( // ENROUTE NOTAM SECTION
                                <EnrouteNotamExport
                                    briefingData={briefingData}
                                    timeZone={timeZone}
                                    styles={styles}
                                    periodTo={periodTo}
                                    maxAltitude={maxAltitude}
                                    showOutdatedErrors={showOutdatedErrors}
                                    fonts={fonts}
                                />
                            )}

                            {((params.aerodromes &&
                                params.enroute &&
                                briefingData.res.enroutenotam) ||
                                (params.enroute &&
                                    briefingData.res.enroutenotam)) && (
                                <View style={styles.spacer}></View>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {params.aaw && ( // AAW SECTION
                                <AAWExport
                                    briefingData={briefingData}
                                    timeZone={timeZone}
                                    styles={styles}
                                    showOutdatedErrors={showOutdatedErrors}
                                    fonts={fonts}
                                />
                            )}

                            {params.grafor && ( // GRAFOR SECTION
                                <GraforExport
                                    briefingData={briefingData}
                                    timeZone={timeZone}
                                    styles={styles}
                                    periodTo={periodTo}
                                    showOutdatedErrors={showOutdatedErrors}
                                    fonts={fonts}
                                />
                            )}

                            {params.msl && ( // MSL SECTION
                                <MSLExport
                                    briefingData={briefingData}
                                    timeZone={timeZone}
                                    styles={styles}
                                    periodTo={periodTo}
                                    showOutdatedErrors={showOutdatedErrors}
                                    fonts={fonts}
                                />
                            )}

                            {params.sigmet && ( // SIGMET SECTION
                                <SigmetExport
                                    briefingData={briefingData}
                                    params={params}
                                    timeZone={timeZone}
                                    styles={styles}
                                    showOutdatedErrors={showOutdatedErrors}
                                    fonts={fonts}
                                />
                            )}
                            {params.sigmet && ( // SIGMET CHARTS
                                <SigmetChartsExport
                                    briefingData={briefingData}
                                    params={params}
                                    timeZone={timeZone}
                                    styles={styles}
                                    showOutdatedErrors={showOutdatedErrors}
                                />
                            )}

                            {params.sigwx && ( // SIGWX SECTION
                                <SigwxExport
                                    briefingData={briefingData}
                                    timeZone={timeZone}
                                    periodTo={periodTo}
                                    styles={styles}
                                    showOutdatedErrors={showOutdatedErrors}
                                    sigwxLevel={sigwxLevel}
                                    fonts={fonts}
                                />
                            )}

                            {params.spacewx && ( // SPACE WEATHER SECTION
                                <SpaceWxExport
                                    briefingData={briefingData}
                                    params={params}
                                    timeZone={timeZone}
                                    styles={styles}
                                    showOutdatedErrors={showOutdatedErrors}
                                    fonts={fonts}
                                />
                            )}
                        </div>
                    </div>

                    <View
                        fixed
                        style={[styles.footerBox, { textAlign: 'center' }]}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <PreflightIcon
                                width="55"
                                viewBox="0 0 146 30"
                                fill="rgb(136, 143, 145)"
                                alt="PreFlight"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Text
                                    style={[
                                        styles.textSmall,
                                        {
                                            marginRight: 3,
                                            fontWeight: '500',
                                            opacity: '0.5',
                                        },
                                    ]}
                                >
                                    Issued:
                                </Text>
                                <Text
                                    style={[
                                        styles.textSmall,
                                        { fontWeight: '300', opacity: '0.5' },
                                    ]}
                                >
                                    {briefingData?.time &&
                                        formatDateTime(
                                            briefingData.time,
                                            timeZone
                                        )}
                                </Text>
                            </div>
                            {!advancedUser && (
                                <Text
                                    style={[
                                        styles.textSmall,
                                        {
                                            fontWeight: '700',
                                            letterSpacing: '1.5',
                                            opacity: '0.3',
                                            // color: 'rgb(94, 176, 234)',
                                        },
                                    ]}
                                >
                                    NOT FOR COMMERCIAL USE
                                </Text>
                            )}
                            <Text
                                style={[
                                    styles.textSmall,
                                    {
                                        fontWeight: '500',
                                        letterSpacing: '0.5',
                                        color: 'rgb(60, 74, 90)',
                                    },
                                ]}
                                render={({ pageNumber, totalPages }) =>
                                    `${pageNumber} / ${totalPages}`
                                }
                            />
                        </div>
                    </View>
                </Page>
            </Document>
        )
    }
    return null
}
