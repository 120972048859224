import { useAuth0 } from '@auth0/auth0-react'

const auth0CustomDomain = JSON.parse(
    process.env.REACT_APP_DEV_ENV.toLowerCase()
)
    ? process.env.REACT_APP_DEV_AUTH0_CUSTOM_DOMAIN
    : process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN

export default function useRoles() {
    const { user } = useAuth0()
    const userRoles = user ? user[`https://${auth0CustomDomain}/roles`] : []

    return userRoles
}

export function useTier() {
    const { user } = useAuth0()
    const userRoles = user ? user[`https://${auth0CustomDomain}/roles`] : []

    if (
        userRoles.some((role) =>
            [
                'Admin',
                'Dev',
                'Aeropath',
                'MetService Aviation',
                'MetService Service Desk',
                'MetService',
                'Professional',
                'Evaluation',
            ].includes(role)
        )
    ) {
        return 3
    }
    if (userRoles.includes('Plus')) {
        return 2
    } else {
        return 1
    }
}

export function useAdminRole() {
    const { user } = useAuth0()
    const userRoles = user ? user[`https://${auth0CustomDomain}/roles`] : []

    return userRoles.some((role) => ['Admin', 'Dev'].includes(role))
}

export function useMetAdminRole() {
    const { user } = useAuth0()
    const userRoles = user ? user[`https://${auth0CustomDomain}/roles`] : []

    return userRoles.some((role) =>
        ['MetService Aviation', 'MetService Service Desk'].includes(role)
    )
}

export function useLoginsCount() {
    const { user } = useAuth0()
    const loginsCount = user
        ? user[`https://${auth0CustomDomain}/loginsCount`]
        : null

    return loginsCount
}

export function useUserMetaData() {
    const { user } = useAuth0()
    const metaData = user
        ? user[`https://${auth0CustomDomain}/user_metadata`]
        : null

    return metaData
}

export function useUserTotpFactor() {
    const { user } = useAuth0()
    const totpFactor = user
        ? user[`https://${auth0CustomDomain}/totpFactor`]
        : null

    return totpFactor
}
