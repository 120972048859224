import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AdvisoriesListIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 3.5H6C5.17157 3.5 4.5 4.17157 4.5 5V19C4.5 19.8284 5.17157 20.5 6 20.5H18C18.8284 20.5 19.5 19.8284 19.5 19V5C19.5 4.17157 18.8284 3.5 18 3.5ZM6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V5C21 3.34315 19.6569 2 18 2H6Z"
                fill="currentColor"
            />
            <path
                d="M9 6.75C9 6.33579 9.33579 6 9.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H9.75C9.33579 7.5 9 7.16421 9 6.75Z"
                fill="currentColor"
            />
            <path
                d="M6 6.75C6 6.19772 6.44772 5.75 7 5.75C7.55228 5.75 8 6.19772 8 6.75C8 7.30228 7.55228 7.75 7 7.75C6.44772 7.75 6 7.30228 6 6.75Z"
                fill="currentColor"
            />
            <path
                d="M9 17.25C9 16.8358 9.33579 16.5 9.75 16.5H14.75C15.1642 16.5 15.5 16.8358 15.5 17.25C15.5 17.6642 15.1642 18 14.75 18H9.75C9.33579 18 9 17.6642 9 17.25Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 16.25L16.0384 17.0706C16.016 17.0785 16 17.0998 16 17.1248C16 17.1491 16.0151 17.1698 16.0363 17.1783L16.7825 17.4675L17.0699 18.212C17.0779 18.2342 17.0991 18.25 17.124 18.25C17.1482 18.25 17.169 18.2349 17.1774 18.2137L18 16.25Z"
                fill="currentColor"
            />
            <path
                d="M6 17.25C6 16.6977 6.44772 16.25 7 16.25C7.55228 16.25 8 16.6977 8 17.25C8 17.8023 7.55228 18.25 7 18.25C6.44772 18.25 6 17.8023 6 17.25Z"
                fill="currentColor"
            />
            <path
                d="M9 13.75C9 13.3358 9.33579 13 9.75 13H14.75C15.1642 13 15.5 13.3358 15.5 13.75C15.5 14.1642 15.1642 14.5 14.75 14.5H9.75C9.33579 14.5 9 14.1642 9 13.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 12.75L16.0384 13.5706C16.016 13.5785 16 13.5998 16 13.6248C16 13.6491 16.0151 13.6698 16.0363 13.6783L16.7825 13.9675L17.0699 14.712C17.0779 14.7342 17.0991 14.75 17.124 14.75C17.1482 14.75 17.169 14.7349 17.1774 14.7137L18 12.75Z"
                fill="currentColor"
            />
            <path
                d="M6 13.75C6 13.1977 6.44772 12.75 7 12.75C7.55228 12.75 8 13.1977 8 13.75C8 14.3023 7.55228 14.75 7 14.75C6.44772 14.75 6 14.3023 6 13.75Z"
                fill="currentColor"
            />
            <path
                d="M9 10.25C9 9.83579 9.33579 9.5 9.75 9.5H17.25C17.6642 9.5 18 9.83579 18 10.25C18 10.6642 17.6642 11 17.25 11H9.75C9.33579 11 9 10.6642 9 10.25Z"
                fill="currentColor"
            />
            <path
                d="M6 10.25C6 9.69772 6.44772 9.25 7 9.25C7.55228 9.25 8 9.69772 8 10.25C8 10.8023 7.55228 11.25 7 11.25C6.44772 11.25 6 10.8023 6 10.25Z"
                fill="currentColor"
            />
        </Icon>
    )
}
