export const constructStatusData = (array, key) => {
    if (Array.isArray(array)) {
        // console.log({ array })
        const initialValue = {}
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key].toLowerCase()]: { ...item, mapOutdated: false },
            }
        }, initialValue)
    }
}
