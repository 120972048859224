// COMPONENT PARTS CAN BE IDENTIFIED HERE:
// client > node_modeuls > @chakra-ui > theme > dist > declarations > src > components

import { mode } from '@chakra-ui/theme-tools'
import { isMobileOnly, isIOS } from 'react-device-detect'

// Switch
export const switchStyles = {
    parts: ['track', 'thumb'],
    colorScheme: 'teal',
    baseStyle: {
        track: {
            _focus: {
                border: 'none',
                boxShadow: 'none',
            },
            _checked: {
                background: 'light.200',
            },
        },
        // TODO - to fix
        colorScheme: {
            active: (props) => ({
                background: 'light.200',
            }),
            normal: (props) => ({
                background: 'light.100',
            }),
        },
        thumb: {
            _focus: {
                border: 'none',
                boxShadow: 'none',
            },
        },
    },
    variants: {
        localtime: (props) => ({
            track: {
                _focus: {
                    border: 'none',
                    boxShadow: 'none',
                },
                _checked: {
                    background: 'light.400',
                },
            },
        }),
    },
}

// Checkbox
export const checkboxStyles = {
    parts: ['label', 'icon', 'container', 'control'],
    colorScheme: 'teal',
    baseStyle: {
        control: {
            borderRadius: '100%',
            border: '2px solid',
            borderColor: '#d5deea',
            padding: '3px',
            width: { base: '24px', md: '20px' },
            height: { base: '24px', md: '20px' },
            fontSize: { base: '0.8rem', md: '0.7rem' },
            _focus: {
                boxShadow: 'none',
            },
            _checked: {
                background: 'light.200',
                borderColor: 'light.200',
            },
            icon: {
                width: { base: '1rem', md: '0.9rem' },
            },
            label: {
                width: { base: '1rem', md: '0.9rem' },
            },
        },
    },
    variants: {
        desktop: (props) => ({
            control: {
                padding: '2px',
                width: '14px',
                height: '14px',
                minHeight: '14px',
                borderRadius: '100%',
                border: '2px solid',
                borderColor: '#d5deea',
                _focus: {
                    background: 'light.201',
                },
                _hover: {
                    background: 'light.201',
                },
                _active: {
                    background: 'light.201',
                },
                _checked: {
                    background: 'light.200',
                    borderColor: 'light.200',
                },
            },
        }),
        mobile: (props) => ({
            control: {
                width: '26px',
                height: '26px',
                minHeight: '26px',
                borderRadius: '100%',
                border: '2px solid',
                borderColor: '#d5deea',
                _checked: {
                    background: 'light.200',
                    borderColor: 'light.200',
                },
            },
        }),
        favourites: (props) => ({
            control: {
                width: '26px',
                height: '26px',
                minHeight: '26px',
                borderRadius: '100%',
                border: '2px solid',
                borderColor: '#d5deea',
                _focus: {
                    background: 'light.201',
                },
                _hover: {
                    background: 'light.201',
                },
                _active: {
                    background: 'light.201',
                },
                _checked: {
                    background: 'light.200',
                    borderColor: 'light.200',
                },
            },
        }),
        update: (props) => ({
            control: {
                width: '26px',
                height: '26px',
                minHeight: '26px',
                borderRadius: '100%',
                border: '2px solid',
                borderColor: '#d5deea',
                _focus: {
                    background: 'cyan.500',
                },
                _hover: {
                    background: 'cyan.500',
                },
                _active: {
                    background: 'cyan.500',
                },
                _checked: {
                    background: 'cyan.400',
                    borderColor: 'cyan.400',
                },
            },
        }),

        warning: (props) => ({
            control: {
                width: '26px',
                height: '26px',
                minHeight: '26px',
                borderRadius: '100%',
                border: '2px solid',
                borderColor: '#d5deea',
                _focus: {
                    background: 'orange.500',
                },
                _hover: {
                    background: 'orange.500',
                },
                _active: {
                    background: 'orange.500',
                },
                _checked: {
                    background: 'orange.400',
                    borderColor: 'orange.400',
                },
            },
        }),

        delete: (props) => ({
            control: {
                width: '26px',
                height: '26px',
                minHeight: '26px',
                borderRadius: '100%',
                border: '2px solid',
                borderColor: '#d5deea',
                _focus: {
                    background: 'red.600',
                },
                _hover: {
                    background: 'red.600',
                },
                _active: {
                    background: 'red.600',
                },
                _checked: {
                    background: 'red.500',
                    borderColor: 'red.500',
                },
            },
        }),
    },
}

// Inputs (Form Fields)
export const inputStyles = {
    parts: ['element', 'field', 'addon'],
    colorScheme: 'teal',
    baseStyle: {
        field: {
            borderRadius: '10px',
            _focus: {
                borderColor: 'brand.200',
            },
        },
    },
    variants: {
        primary: (props) => ({
            field: {
                borderColor: '#E1E7EE',
                border: '1px solid #E1E7EE',
                borderRadius: '10px',
                background: '#FDFEFF',
                _focus: {
                    borderColor: 'brand.200',
                },
            },
            element: {
                color: 'light.200',
            },
            addon: {
                color: 'light.200',
            },
        }),
    },
}

// // Textarea (Form Fields)
// export const primary = defineStyle((props) => ({
//     backgroundColor: mode('light.10', 'gray.700')(props),
//     backgroundClip: 'padding-box',
//     border: '1px solid',
//     transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
//     borderColor: mode('#cbd5e0', 'gray.600')(props),
//     borderRadius: '7px',
//     color: mode('light.100', 'light.10')(props),
//     background: mode('light.10', 'gray.700')(props),
//     _focus: {
//         borderColor: 'brand.200',
//     },
// }))
// export const textareaStyles = defineStyleConfig({
//     variants: { primary },
// })

// Modals
export const modalStyles = {
    parts: ['dialogContainer', 'dialog', 'overlay', 'header', 'closeButton'],
    baseStyle: {
        dialogContainer: {
            zIndex: '1400',
            height: '100%',
            minHeight: '100%',
            paddingBottom: {
                base: isMobileOnly && isIOS ? '85px' : '70px',
                md: '0',
            },
            width: { base: '100%', md: 'calc(100% - 50px)' },
            marginLeft: { base: '0px', md: '50px' },
            right: '0px',
        },
        dialog: {
            borderRadius: 20,
            boxShadow: '0px 10px 30px -10px rgba(0,0,0,0.5)',
            overflow: 'hidden',
            my: 'auto',
            mx: { base: '15px', md: 'auto' },
        },
        overlay: {
            // backdropFilter: 'blur(2px)',
            zIndex: 'unset',
            left: 'unset',
            right: 0,
            width: { base: '100%', md: 'calc(100% - 50px)' },
            marginRight: '0px',
            height: {
                base:
                    isMobileOnly && isIOS
                        ? 'calc(100% - 85px)'
                        : 'calc(100% - 70px)',
                md: '100%',
            },
            minHeight: {
                base:
                    isMobileOnly && isIOS
                        ? 'calc(100% - 85px)'
                        : 'calc(100% - 70px)',
                md: '100%',
            },
        },
        header: {
            bg: mode('#4a5568'),
            paddingStart: '5',
            paddingEnd: '4',
            paddingTop: '3',
            paddingBottom: '3',
            fontSize: '1.1rem',
        },
        closeButton: {
            fontSize: '1rem',
            color: mode('white', 'gray.800'),
            top: '3',
            right: '3',
            opacity: '0.7',
            _hover: {
                opacity: '1',
            },
            _focus: {
                border: 'none',
            },
        },
        body: {
            fontSize: '0.9rem',
            color: mode('light.900', 'light.900'),
            // bg: mode('white', 'white'),
        },
    },

    variants: {
        primary: (props) => ({
            dialogContainer: {
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    md: '0',
                },
            },
            header: {
                bg: mode('#4a5568')(props),
            },
            closeButton: {
                top: '9px',
                opacity: '0.6',
            },
        }),
        alert: () => ({
            dialogContainer: {
                zIndex: '1400',
            },
            dialog: {
                zIndex: '1199',
            },
            overlay: {
                zIndex: '1199',
            },
        }),
        aerodrome: (props) => ({
            header: {
                bg: mode('#215082', '#215082')(props),
            },
            closeButton: {
                top: '9px',
                opacity: '0.6',
            },
            dialog: {
                // mt: { base: '0px', md: 'auto' },
                // top: { base: '45px', md: 'auto' },
                marginTop: 'auto',
                marginBottom: { base: '0px', md: 'auto' },
                bottom: { base: '0px', md: 'auto' },
                mb: { base: '0px', md: 'auto' },
                mx: { base: '0px', md: 'auto' },
                height: { base: 'calc(100% - 47px)', md: '90vh' },
                minHeight: { base: 'calc(100% - 47px)', md: '90vh' },
                borderTopRadius: { base: '25', md: 15 },
                borderBottomRadius: { base: '0px', md: 15 },
                boxShadow: {
                    base: '0px -5px 20px -5px rgba(0,0,0,0.4)',
                    md: '0px 10px 25px -5px rgba(0,0,0,0.4)',
                },
            },
        }),
        fullscreen: (props) => ({
            dialogContainer: {
                zIndex: '1500',
                height: '100%',
                minHeight: '100%',
                paddingBottom: {
                    base: '0px',
                    md: '0',
                },
                width: { base: '100%', md: 'calc(100% - 50px)' },
                marginLeft: { base: '0px', md: '50px' },
                right: '0px',
                overflow: 'hidden',
            },
            dialog: {
                zIndex: '1500',
                height: { base: 'calc(100% - 47px)', md: 'calc(100% - 30px)' },
                maxHeight: {
                    base: 'calc(100% - 47px)',
                    md: 'calc(100% - 30px)',
                },
                width: { base: '100%', md: 'calc(100% - 30px)' },
                minWidth: { base: '100%', md: 'calc(100% - 30px)' },
                maxWidth: { base: '100%', md: 'calc(100% - 30px)' },
                marginBottom: { base: '0px', md: 'auto' },
                marginInlineStart: {
                    base: '0px !important',
                    md: '15px !important',
                },
                marginInlineEnd: {
                    base: '0px !important',
                    md: '15px !important',
                },
                borderBottomRadius: { base: '0px', md: '20px' },
                overflow: 'hidden',
            },
            overlay: {
                zIndex: '1499',
            },
            closeButton: {
                top: '2',
                right: '2',
            },
        }),
        onlineUsers: (props) => ({
            dialogContainer: {
                zIndex: '1199',
            },
            dialog: {
                zIndex: '1199',
            },
            overlay: {
                zIndex: '1199',
            },
            closeButton: {
                top: '2',
                right: '2',
            },
        }),
        notam: (props) => ({
            dialogContainer: {
                zIndex: '1400',
            },
            header: {
                bg: mode('#FF7F00', '#FF7F00')(props),
            },
            closeButton: {
                top: '2',
                right: '2',
            },
            overlay: {
                zIndex: '1300',
            },
        }),
        notamMapDisabled: (props) => ({
            dialogContainer: {
                zIndex: '1400',
                width: {
                    base: '100%',
                    md: 'calc(100% - 50px)',
                    xl: 'calc(100% - 500px)',
                },
                marginTop: { base: '0px', md: '20px' },
                marginLeft: { base: '0px', md: '50px', xl: '500px' },
            },
            dialog: {
                marginInlineStart: { base: '15px', xl: 'auto' },
                marginInlineEnd: { base: '15px', xl: 'auto' },
            },
            header: {
                bg: mode('#FF7F00', '#FF7F00')(props),
            },
            closeButton: {
                top: '2',
                right: '2',
            },
            overlay: {
                bg: { base: 'blackAlpha.600', xl: 'blackAlpha.300' },
                backdropFilter: 'blur(2px)',
                zIndex: '1399',
                width: {
                    base: '100%',
                    md: 'calc(100% - 50px)',
                    xl: 'calc(100% - 500px)',
                },
                marginTop: { base: '0px', md: '70px' },
                height: {
                    base:
                        isMobileOnly && isIOS
                            ? 'calc(100% - 85px)'
                            : 'calc(100% - 70px)',
                    md: 'calc(100% - 70px)',
                },
                minHeight: {
                    base:
                        isMobileOnly && isIOS
                            ? 'calc(100% - 85px)'
                            : 'calc(100% - 70px)',
                    md: 'calc(100% - 70px)',
                },
            },
        }),
        legend: (props) => ({
            dialogContainer: {
                zIndex: '1299',
                pointerEvents: 'none',
                overflow: 'hidden',
            },
            header: {
                bg: mode('rgb(93, 122, 152)', 'rgb(93, 122, 152)')(props),
            },
        }),
        rpas: (props) => ({
            dialogContainer: {
                zIndex: '1400',
            },
            header: {
                bg: mode('#FF5757', '#FF5757')(props),
            },
            overlay: {
                zIndex: '1300',
            },
        }),
        aws: (props) => ({
            dialogContainer: {
                zIndex: '1400',
            },
            dialog: {
                maxHeight: 'calc(100% - 4.5rem)',
                marginLeft: '15px !important',
                marginRight: '15px !important',
            },
            header: {
                bg: mode('#2D5C8E', '#2D5C8E')(props),
                backgroundImage:
                    'linear-gradient(140deg, rgb(26, 55, 109) 0%, rgb(65, 119, 189) 80%)',
            },
            closeButton: {
                top: '4',
                right: '4',
            },
            overlay: {
                zIndex: '1300',
            },
        }),
        webcam: (props) => ({
            dialogContainer: {
                zIndex: '1400',
            },
            dialog: {
                maxHeight: 'calc(100% - 4.5rem)',
                marginLeft: '15px !important',
                marginRight: '15px !important',
            },
            header: {
                bg: mode('webcam.100', 'webcam.100')(props),
                backgroundImage:
                    'linear-gradient(140deg, rgb(26, 89, 109) 0%, rgb(45, 119, 142) 80%)',
            },
            closeButton: {
                top: '4',
                right: '4',
            },
            overlay: {
                zIndex: '1300',
            },
        }),
        weather: (props) => ({
            dialogContainer: {
                zIndex: '1400',
            },
            closeButton: {
                top: '2',
                right: '2',
            },
        }),
        weatherMapDisabled: (props) => ({
            dialogContainer: {
                zIndex: '1400',
                width: {
                    base: '100%',
                    md: 'calc(100% - 50px)',
                    xl: 'calc(100% - 500px)',
                },
                marginTop: { base: '0px', md: '20px' },
                marginLeft: { base: '0px', md: '50px', xl: '500px' },
            },
            dialog: {
                marginInlineStart: { base: '15px', xl: 'auto' },
                marginInlineEnd: { base: '15px', xl: 'auto' },
            },
            header: {
                bg: mode('#FF7F00', '#FF7F00')(props),
            },
            closeButton: {
                top: '2',
                right: '2',
            },
            overlay: {
                bg: { base: 'blackAlpha.600', xl: 'blackAlpha.300' },
                backdropFilter: 'blur(2px)',
                zIndex: '1399',
                width: {
                    base: '100%',
                    md: 'calc(100% - 50px)',
                    xl: 'calc(100% - 500px)',
                },
                marginTop: { base: '0px', md: '70px' },
                height: {
                    base:
                        isMobileOnly && isIOS
                            ? 'calc(100% - 85px)'
                            : 'calc(100% - 70px)',
                    md: 'calc(100% - 70px)',
                },
                minHeight: {
                    base:
                        isMobileOnly && isIOS
                            ? 'calc(100% - 85px)'
                            : 'calc(100% - 70px)',
                    md: 'calc(100% - 70px)',
                },
            },
        }),
        dataStatus: (props) => ({
            dialogContainer: {
                zIndex: '1410',
                paddingBottom: '0px',
            },
            dialog: {
                zIndex: '1410',
                mx: { base: '15px', md: '15px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
                width: '100%',
            },
            header: {
                // bg: mode('#353D4D', '#191c24')(props),
            },
            closeButton: {
                top: '2',
                right: '2',
            },
        }),
        profile: (props) => ({
            dialogContainer: {
                zIndex: '1410',
                paddingBottom: {
                    base: 'unset',
                    md: '0',
                },
            },
            dialog: {
                height: { base: 'calc(100% - 3rem)', md: 'auto' },
                maxHeight: 'calc(100% - 3rem)',
                zIndex: '1410',
                mx: { base: '15px', md: '15px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
                width: '100%',
            },
            header: {
                // bg: mode('#353D4D', '#191c24')(props),
            },
            closeButton: {
                top: '2',
                right: '2',
            },
        }),
        announcements: (props) => ({
            dialogContainer: {
                zIndex: '1510',
                paddingBottom: '0px',
            },
            dialog: {
                zIndex: '1500',
                mx: { base: '15px', md: '15px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1509',
                height: '100%',
                minHeight: '100%',
                width: '100%',
            },
        }),
        premiumOnly: (props) => ({
            dialogContainer: {
                zIndex: { base: '1400', md: '1399' },
                dialog: {
                    zIndex: '1399',
                },
            },
            dialog: {
                zIndex: '1399',
                mx: { base: '15px', md: '15px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: 'unset',
                height: {
                    base:
                        isMobileOnly && isIOS
                            ? 'calc(100% - 85px)'
                            : 'calc(100% - 70px)',
                    md: '100%',
                },
                minHeight: {
                    base:
                        isMobileOnly && isIOS
                            ? 'calc(100% - 85px)'
                            : 'calc(100% - 70px)',
                    md: '100%',
                },
            },
        }),

        manageFavourites: (props) => ({
            dialogContainer: {
                zIndex: '1410',
                dialog: {
                    zIndex: '1410',
                },
                paddingBottom: '0px',
            },
            dialog: {
                zIndex: '1410',
                mx: { base: '15px', md: '15px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
                width: '100%',
            },
        }),

        showFavourites: (props) => ({
            dialogContainer: {
                zIndex: '1410',
                dialog: {
                    zIndex: '1410',
                },
            },
            dialog: {
                zIndex: '1410',
                mx: { base: '15px', md: '15px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
                width: '100%',
            },
        }),

        briefingOnlySelection: (props) => ({
            dialogContainer: {
                zIndex: '1410',
                dialog: {
                    zIndex: '1410',
                },
                paddingBottom: '0px',
            },
            dialog: {
                zIndex: '1410',
                mx: { base: '15px', md: '15px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
                width: '100%',
            },
        }),

        loginMessages: (props) => ({
            dialogContainer: {
                zIndex: '1410',
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
            },
        }),
        pricingTiers: (props) => ({
            dialogContainer: {
                zIndex: '1401',
                height: '100%',
                minHeight: '100%',
                paddingBottom: '0',
            },
            dialog: {
                zIndex: '1400',
                mx: { base: '15px', md: '30px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1401',
                height: '100%',
                minHeight: '100%',
            },
        }),
        terms: (props) => ({
            dialogContainer: {
                zIndex: '1410',
            },
            dialog: {
                zIndex: '1400',
                mx: { base: '15px', md: '30px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
            },
        }),
        termsLandingPage: (props) => ({
            dialogContainer: {
                zIndex: '1401',
            },
            dialog: {
                zIndex: '1400',
                mx: { base: '15px', md: '30px !important', lg: 'auto' },
            },
            overlay: {
                width: { base: '100%', md: '100%' },
                height: {
                    base: '100% ',
                    md: '100%',
                },
                minHeight: {
                    base: '100%',
                    md: '100%',
                },
                zIndex: '1401',
            },
        }),
        contactUs: (props) => ({
            dialogContainer: {
                zIndex: '1410',
                paddingBottom: '0px !important',
            },
            dialog: {
                zIndex: '1400',
                mx: { base: '15px', md: '30px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1409',
                height: '100%',
                minHeight: '100%',
            },
        }),
        contactUsLandingPage: (props) => ({
            dialogContainer: {
                zIndex: '1401',
                paddingBottom: '0px !important',
            },
            dialog: {
                zIndex: '1400',
                mx: { base: '15px', md: '30px !important', lg: 'auto' },
            },
            overlay: {
                width: { base: '100%', md: '100%' },
                height: {
                    base: '100% ',
                    md: '100%',
                },
                minHeight: {
                    base: '100%',
                    md: '100%',
                },
                zIndex: '1401',
            },
        }),
        faqs: (props) => ({
            dialogContainer: {
                zIndex: '1401',
            },
            dialog: {
                zIndex: '1400',
                mx: { base: '15px', md: '30px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1399',
            },
        }),
        deviceWarning: (props) => ({
            dialogContainer: {
                width: '100% !important',
                marginLeft: '0px !important',
                paddingBottom: '0px !important',
                zIndex: '1401',
            },
            dialog: {
                zIndex: '1400',
                mx: { base: '15px', md: '30px !important', lg: 'auto' },
            },
            overlay: {
                zIndex: '1399',
            },
        }),
        alertMessage: (props) => ({
            dialogContainer: {
                width: '100% !important',
                marginLeft: '0px !important',
                paddingBottom: '0px !important',
                zIndex: '1600',
            },
            dialog: {
                zIndex: '1600',
                mx: '30px',
            },
            header: {
                bg: 'white',
            },
            overlay: {
                zIndex: '1599',
                height: '100%',
                minHeight: '100%',
                width: '100%',
            },
        }),
        mfa: (props) => ({
            dialogContainer: {
                zIndex: '1800',
                paddingBottom: '0px',
            },
            dialog: {
                zIndex: '1800',
                borderRadius: 21,
                boxShadow: 'none',
                overflow: 'unset',
                background: 'transparent',
            },
            body: {
                boxShadow: '0px 10px 30px -10px rgba(0,0,0,0.6)',
                borderRadius: 21,
                overflow: 'hidden',
            },
            overlay: {
                zIndex: '1799',
                height: '100%',
                minHeight: '100%',
                width: '100%',
                backdropFilter: 'blur(2px)',
            },
        }),
        whyMfa: (props) => ({
            dialogContainer: {
                zIndex: '1900',
                paddingBottom: '0px',
            },
            dialog: {
                zIndex: '1900',
                borderRadius: 21,
            },
            overlay: {
                zIndex: '1899',
                height: '100%',
                minHeight: '100%',
                width: '100%',
                backdropFilter: 'blur(2px)',
            },
        }),
        briefingOptions: (props) => ({
            dialogContainer: {
                zIndex: '1399',
                border: 'none',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: '100%',
                minHeight: '100%',
                marginLeft: { base: '0px', md: '50px' },
                marginRight: '0px',
                top: '0px',
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    md: '0px',
                },
            },
            overlay: {
                zIndex: '1300',
            },
            dialog: {
                backgroundColor: 'transparent',
                border: 'none',
                opacity: '0.6',
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',
            },
        }),
        briefingOnlyOptions: (props) => ({
            dialogContainer: {
                zIndex: '1399',
                border: 'none',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: '100%',
                minHeight: '100%',
                marginLeft: { base: '0px', md: '50px' },
                marginRight: '0px',
                top: '0px',
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '55px' : '40px',
                    md: '0px',
                },
            },
            overlay: {
                zIndex: '1300',
            },
            dialog: {
                backgroundColor: 'transparent',
                border: 'none',
                opacity: '0.6',
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',
            },
        }),
        briefingStandard: (props) => ({
            dialogContainer: {
                border: 'none',
                zIndex: '1100',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                marginLeft: { base: '0px', md: '50px' },
                marginRight: '0px',
                marginBottom: '0px',
                marginTop: '0px',
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    md: '0px',
                },
                position: 'fixed',
            },
            // start
            overlay: {
                zIndex: '1100',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: '100%',
                minHeight: '100%',
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    md: '0px',
                },
            },
            // end
            dialog: {
                border: 'none',
                borderTopRadius: { base: '0', md: 0 },
                borderBottomRadius: { base: '0px', md: 0 },
                borderLeft: { base: '0px', md: '1px solid #E2E8F0' },
                // borderBottom: { base: '1px solid', md: '0px' },
                borderLeftColor: '#E2E8F0',
                // borderBottomColor: mode('#E2E8F0', 'rgba(45, 62, 79, 0)'),
                boxShadow: 'none',
                marginLeft: '0px',
                marginRight: '0px',
                marginBottom: '0px',
                marginTop: '0px',
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',

                position: { base: 'relative', md: 'fixed' },
                width: { base: '100%', md: 'calc(100% - 50px)' },
                right: '0px',
            },
        }),

        briefingOnly: (props) => ({
            dialogContainer: {
                border: 'none',
                zIndex: '1100',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',
                marginLeft: { base: '0px', md: '50px' },
                marginRight: '0px',
                marginBottom: '0px',
                marginTop: '0px',
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '55px' : '40px',
                    md: '0px',
                },
                position: 'fixed',
            },
            // start
            overlay: {
                zIndex: '1100',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: '100%',
                minHeight: '100%',
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    md: '0px',
                },
            },
            // end
            dialog: {
                border: 'none',
                borderTopRadius: { base: '0', md: 0 },
                borderBottomRadius: { base: '0px', md: 0 },
                borderLeft: { base: '0px', md: '1px solid #E2E8F0' },
                // borderBottom: { base: '1px solid', md: '0px' },
                borderLeftColor: '#E2E8F0',
                // borderBottomColor: mode('#E2E8F0', 'rgba(45, 62, 79, 0)'),
                boxShadow: 'none',
                marginLeft: '0px',
                marginRight: '0px',
                marginBottom: '0px',
                marginTop: '0px',
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',

                position: { base: 'relative', md: 'fixed' },
                width: { base: '100%', md: 'calc(100% - 50px)' },
                right: '0px',
            },
        }),

        imageLooper: (props) => ({
            dialogContainer: {
                border: 'none',
                zIndex: '1100',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: {
                    base: isMobileOnly && isIOS ? '100%' : '100%',
                    md: '100%',
                },
                minHeight: {
                    base: isMobileOnly && isIOS ? '100%' : '100%',
                    md: '100%',
                },
                maxHeight: '100%',
                marginLeft: { base: '0px', md: '50px' },
                marginRight: '0px',
                marginBottom: '0px',
                marginTop: '0px',
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    md: '0px',
                },
                position: 'absolute',
            },
            overlay: {
                zIndex: '1100',
                width: { base: '100%', md: 'calc(100% - 50px)' },
                height: {
                    base: isMobileOnly && isIOS ? '100%' : '100%',
                    md: '100%',
                },
                minHeight: {
                    base: isMobileOnly && isIOS ? '100%' : '100%',
                    md: '100%',
                },
                paddingBottom: {
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    md: '0px',
                },
            },
            dialog: {
                border: 'none',
                borderTopRadius: { base: '0', md: 0 },
                borderBottomRadius: { base: '0px', md: 0 },
                borderLeft: { base: '0px', md: '1px solid #E2E8F0' },
                // borderBottom: { base: '1px solid', md: '0px' },
                borderLeftColor: '#E2E8F0',
                // borderBottomColor: mode('#E2E8F0', 'rgba(45, 62, 79, 0)'),
                boxShadow: 'none',
                marginLeft: '0px',
                marginRight: '0px',
                marginBottom: '0px',
                marginTop: '0px',
                height: '100%',
                minHeight: '100%',
                maxHeight: '100%',

                position: { base: 'relative', md: 'fixed' },
                width: { base: '100%', md: 'calc(100% - 50px)' },
                right: '0px',
            },
        }),
    },
}

// Drawer
export const drawerStyles = {
    parts: ['dialogContainer', 'dialog', 'header', 'closeButton'],
    baseStyle: {
        closeButton: {
            fontSize: '1rem',
            color: mode('white', 'gray.800'),
            top: '3',
            right: '3',
            opacity: '0.7',
            _hover: {
                opacity: '1',
            },
            _focus: {
                border: 'none',
            },
        },
        body: {
            fontSize: '0.9rem',
            color: mode('light.900', 'light.900'),
        },
    },
    variants: {
        aerodrome: (props) => ({
            closeButton: {
                top: '9px',
                opacity: '0.6',
            },
            dialogContainer: {
                width: '0vw',
            },
        }),
        webcamDrawer: (props) => ({
            closeButton: {
                top: '9px',
                opacity: '0.6',
            },
            dialogContainer: {
                width: '0vw',
            },
        }),
        settings: (props) => ({
            dialogContainer: {
                zIndex: '1502',
            },
        }),
    },
}

// Slider
export const sliderStyles = {
    parts: ['track', 'container', 'thumb', 'filledTrack', 'marker'],
    baseStyle: {
        filledTrack: {
            background: 'light.200',
            borderRadius: '5px',
        },
        track: {
            height: '6px',
            borderRadius: '5px',
        },
    },
    variants: {
        grafor: (props) => ({
            filledTrack: {
                background: 'light.200',
            },
            track: {
                height: '6px',
            },
        }),
    },
    fontAdjust: {
        grafor: (props) => ({
            filledTrack: {
                background: 'gray.400',
            },
            track: {
                height: '6px',
            },
        }),
    },
}

// Headings
export const headingStyles = {
    baseStyle: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: 'white',
    },
    variants: {
        primary: (props) => ({
            fontSize: '1.1rem',
            fontWeight: 'bold',
            color: 'white',
            // need to add light and dark mode color options as a test
        }),
        mainHeading: (props) => ({
            fontSize: '1.1rem',
            fontWeight: 'bold',
            color: 'white',
            // need to add light and dark mode color options as a test
        }),
        subHeading: (props) => ({
            fontSize: '0.85rem',
            fontWeight: '600',
            paddingTop: '1',
            paddingBottom: '1px',
            color: 'white',
            // need to add light and dark mode color options as a test
        }),
        rpas: (props) => ({
            fontSize: '1.1rem',
            fontWeight: 'bold',
            color: 'white',
            // need to add light and dark mode color options as a test
        }),
    },
}

// Badge
export const badgeStyles = {
    colorScheme: {
        ifr: (props) => ({
            background: 'yellow',
        }),
        vfr: (props) => ({
            background: 'red',
            // need to add light and dark mode color options as a test
        }),
    },
}

// Dividers
export const dividerStyles = {
    baseStyle: {
        borderColor: 'gray.200',
        borderStyle: 'solid',
    },
    variants: {
        primary: {
            marginTop: '15px',
            marginBottom: '15px',
            borderColor: 'gray.200',
            borderStyle: 'solid',
        },
        modalFooter: {
            marginTop: '15px',
            marginBottom: '15px',
            borderColor: 'gray.200',
            borderStyle: 'solid',
        },
        altitidues: {
            marginTop: '4px',
            marginBottom: '4px',
            borderStyle: 'solid',
            width: '80px',
            borderColor: '#4A5568',
            opacity: '1',

            // need to add light and dark mode color options as a test
        },
    },
}

// Tables
export const tableStyles = {
    parts: ['Tbody', 'Tr', 'Td'],

    // style object for base or default style
    baseStyle: {},

    // styles for different sizes ("sm", "md", "lg")
    sizes: {},

    variants: {
        striped: (props) => ({
            th: {
                fontFamily: 'Open Sans',
                fontWeight: '700',
                fontSize: '0.7rem',
                textAlign: 'center',
                px: '5px',
            },
            td: {
                fontFamily: 'Open Sans',
                fontWeight: 'normal',
                fontSize: '0.7rem',
                textAlign: 'center',
                px: '5px',
            },
        }),
        normal: (props) => ({
            td: {
                paddingStart: '1',
                paddingEnd: '1',
                paddingTop: '1',
                paddingBottom: '1',
                fontFamily: 'Open Sans',
                letterSpacing: '0',
                lineHeight: '1.2',
                fontWeight: 'normal',
                verticalAlign: 'baseline',
            },
        }),
        notam: (props) => ({
            td: {
                paddingStart: '1',
                paddingEnd: '1',
                paddingTop: '1',
                paddingBottom: '1',
                fontFamily: 'Open Sans',
                letterSpacing: '0',
                lineHeight: '1.2',
                fontWeight: 'normal',
                verticalAlign: 'baseline',
            },
        }),
        weather: (props) => ({
            td: {
                paddingStart: '1',
                paddingEnd: '1',
                paddingTop: '1',
                paddingBottom: '1',
                fontFamily: 'Open Sans',
                letterSpacing: '0',
                lineHeight: '1.2',
                fontWeight: 'normal',
                verticalAlign: 'baseline',
            },
        }),
        status: (props) => ({
            th: {
                paddingStart: '1',
                paddingEnd: '1',
                paddingTop: '1',
                paddingBottom: '1',
                fontFamily: 'Open Sans',
                fontWeight: '600',
                fontSize: '0.8rem',
                letterSpacing: '0',
                lineHeight: '1.2',
                verticalAlign: 'baseline',
            },
            td: {
                paddingStart: '1',
                paddingEnd: '1',
                paddingTop: '1',
                paddingBottom: '1',
                fontFamily: 'Open Sans',
                fontSize: '0.8rem',
                letterSpacing: '0',
                lineHeight: '1.2',
                fontWeight: 'normal',
                verticalAlign: 'baseline',
            },
        }),
    },

    // default values for `size` and `variant`
    defaultProps: {
        size: '',
        variant: '',
    },
}

// Buttons
export const brandRing = {
    _focus: {
        ring: 2,
        ringColor: 'brand.500',
    },
}
export const buttonStyles = {
    variants: {
        primary: (props) => ({
            rounded: 'none',
            ...brandRing,
            color: mode('white', 'gray.800')(props),
            backgroundColor: mode('brand.500', 'brand.200')(props),

            _hover: {
                backgroundColor: mode('brand.600', 'brand.300')(props),
            },

            _active: {
                backgroundColor: mode('brand.700', 'brand.400')(props),
            },
        }),
    },
}
