import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function ObstaclesIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M12.0002 2.5L5.07715 19.8077L6.45796 20.3945L12.0002 6.53887L17.5425 20.3945L18.9233 19.8077L12.0002 2.5Z"
                fill="currentColor"
            />
            <path
                d="M12.0002 21.5C13.1048 21.5 14.0002 20.6046 14.0002 19.5C14.0002 18.3954 13.1048 17.5 12.0002 17.5C10.8957 17.5 10.0002 18.3954 10.0002 19.5C10.0002 20.6046 10.8957 21.5 12.0002 21.5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
