import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function RoutesIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M10.1379 17.4461L3.09965 15.1L11.5534 9.46416L12.9653 9.67005L19.6889 5.18765L20.0418 3.80523L21.7916 2.6387C21.9065 2.56212 21.9376 2.4069 21.861 2.29202C21.7844 2.17713 21.6292 2.14609 21.5143 2.22268L19.7787 3.37976L18.3442 3.17056L11.6205 7.65297L11.2619 9.0576L2.43031 14.9453C2.35675 14.9944 2.31756 15.0757 2.319 15.1579C2.30583 15.2729 2.37426 15.3853 2.48839 15.4233L9.98558 17.9224L10.6936 19.1696L18.3599 21.7243L19.6733 21.1516L21.6044 21.7953C21.7353 21.839 21.8769 21.7682 21.9206 21.6372C21.9643 21.5062 21.8935 21.3646 21.7625 21.321L19.839 20.6798L19.1263 19.4244L11.46 16.8697L10.1379 17.4461Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
