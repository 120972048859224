import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function SigwxIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.25 18.75C8.183 18.75 9.75 17.183 9.75 15.25C9.75 13.317 8.183 11.75 6.25 11.75C4.317 11.75 2.75 13.317 2.75 15.25C2.75 17.183 4.317 18.75 6.25 18.75ZM6.25 19.5C8.59721 19.5 10.5 17.5972 10.5 15.25C10.5 12.9028 8.59721 11 6.25 11C3.90279 11 2 12.9028 2 15.25C2 17.5972 3.90279 19.5 6.25 19.5Z"
                fill="currentColor"
            />
            <path
                d="M6 8C4.61929 8 3.5 9.11929 3.5 10.5C3.5 10.5906 3.50482 10.6801 3.51423 10.7683C3.03216 11.0632 2.60136 11.4337 2.23832 11.8632C2.08409 11.4378 2 10.9787 2 10.5C2 8.37618 3.65521 6.63895 5.746 6.50794C6.50027 3.90385 8.9028 2 11.75 2C14.0505 2 16.2543 3.42855 17.3251 5.50334C17.3795 5.50126 17.4342 5.50014 17.489 5.50001L17.5 5.5C19.8182 5.5 22.0002 7.51472 22.0002 10C22.0002 10.9817 21.6858 11.8899 21.1523 12.6296C20.6224 12.3155 20.3029 12.1794 19.6818 12.0591C20.189 11.5218 20.5 10.7972 20.5 10C20.5 8.3879 19.123 7.07279 17.625 7.00292C17.5954 7.00154 17.5658 7.00065 17.5362 7.00025L17.5114 7.00002L17.5 7C17.4752 7 17.4505 7.0003 17.4259 7.0009C16.5672 7.02176 15.798 7.40341 15.264 8.00002L14.1563 6.98858C14.6065 6.48893 15.1672 6.0907 15.7998 5.83233C14.9336 4.43612 13.3735 3.5 11.75 3.5C9.21051 3.5 7.13647 5.49286 7.00647 8H6Z"
                fill="currentColor"
            />
            <path
                d="M10.9948 13C11.2163 13.4663 11.3711 13.9704 11.4468 14.5H18.9999C19.2966 14.5 19.5866 14.588 19.8333 14.7528C20.08 14.9176 20.2722 15.1519 20.3858 15.426C20.4993 15.7001 20.529 16.0016 20.4711 16.2926C20.4132 16.5836 20.2704 16.8509 20.0606 17.0607C19.8508 17.2704 19.5836 17.4133 19.2926 17.4712C19.0016 17.5291 18.7 17.4993 18.4259 17.3858C18.1518 17.2723 17.9176 17.08 17.7527 16.8334C17.7247 16.7913 17.6993 16.7513 17.6751 16.7132C17.5577 16.5279 17.4704 16.3902 17.2499 16.305C17.1132 16.2522 17.0244 16.2345 16.8786 16.25C16.6698 16.2722 16.5302 16.3387 16.3899 16.495C16.2626 16.637 16.2232 16.7547 16.2099 16.945C16.2017 17.0629 16.2499 17.245 16.2499 17.245C16.2499 17.245 16.316 17.5537 16.8786 18.1163C17.4412 18.6789 18.2043 18.995 18.9999 18.995V19C19.7956 19 20.5587 18.6839 21.1213 18.1213C21.6839 17.5587 21.9999 16.7956 21.9999 16C21.9999 15.2044 21.6839 14.4413 21.1213 13.8787C20.5587 13.3161 19.7956 13 18.9999 13H10.9948Z"
                fill="currentColor"
            />
            <path
                d="M10.9948 17.5C11.2163 17.0337 11.3711 16.5296 11.4468 16H13C13.7956 16 14.5587 16.3161 15.1213 16.8787C15.6839 17.4413 16 18.2044 16 19C16 19.7956 15.6839 20.5587 15.1213 21.1213C14.5587 21.6839 13.7956 22 13 22V21.995C12.2044 21.995 11.4413 21.6789 10.8787 21.1163C10.3161 20.5537 10.25 20.245 10.25 20.245C10.25 20.245 10.2018 20.0629 10.21 19.945C10.2233 19.7547 10.2626 19.637 10.39 19.495C10.5302 19.3387 10.6699 19.2722 10.8787 19.25C11.0244 19.2345 11.1133 19.2522 11.25 19.305C11.4704 19.3902 11.5577 19.5279 11.6752 19.7132C11.6993 19.7513 11.7247 19.7913 11.7528 19.8334C11.9176 20.08 12.1519 20.2723 12.426 20.3858C12.7001 20.4993 13.0017 20.5291 13.2926 20.4712C13.5836 20.4133 13.8509 20.2704 14.0607 20.0607C14.2704 19.8509 14.4133 19.5836 14.4712 19.2926C14.5291 19.0016 14.4993 18.7001 14.3858 18.426C14.2723 18.1519 14.08 17.9176 13.8334 17.7528C13.5867 17.588 13.2967 17.5 13 17.5H10.9948Z"
                fill="currentColor"
            />
            <path
                d="M5.34481 13.4955C5.29397 12.9617 5.71376 12.5 6.25 12.5C6.78624 12.5 7.20603 12.9617 7.15519 13.4955L6.97987 15.3363C6.94404 15.7126 6.62799 16 6.25 16C5.87201 16 5.55596 15.7126 5.52013 15.3363L5.34481 13.4955Z"
                fill="currentColor"
            />
            <path
                d="M6.25 18C6.66421 18 7 17.6642 7 17.25C7 16.8358 6.66421 16.5 6.25 16.5C5.83579 16.5 5.5 16.8358 5.5 17.25C5.5 17.6642 5.83579 18 6.25 18Z"
                fill="currentColor"
            />
        </Icon>
    )
}
