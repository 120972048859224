import * as turf from '@turf/turf'
import * as polylabel from '@mapbox/polylabel'

const getViewport = (mapInstance) => {
    const canvas = mapInstance.getCanvas()
    let { width, height } = canvas
    const w = width / window.devicePixelRatio
    const h = height / window.devicePixelRatio
    const cUL = mapInstance.unproject([0, 0]).toArray()
    const cUR = mapInstance.unproject([w, 0]).toArray()
    const cLR = mapInstance.unproject([w, h]).toArray()
    const cLL = mapInstance.unproject([0, h]).toArray()
    return {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Polygon',
            coordinates: [[cUL, cUR, cLR, cLL, cUL]],
        },
    }
}

const toSinglePoly = (feat) => {
    if (turf.getType(feat) === 'MultiPolygon') {
        return feat.geometry.coordinates.map((singlePolyCoords) =>
            turf.polygon(singlePolyCoords, feat.properties)
        )
    }
    return feat
}

export default function calcSymbolPlacement(mapInstance, data, uid) {
    const viewport = getViewport(mapInstance)

    const polygonsInViewport = turf.featureCollection(
        data.features.filter(
            (feat) =>
                (feat.geometry.type === 'Polygon' ||
                    feat.geometry.type === 'MultiPolygon') &&
                turf.booleanIntersects(feat, viewport)
        )
    )

    const clippedPolygons = turf.featureCollection(
        polygonsInViewport.features
            .map((feat) => {
                const clippedFeat = { ...feat }
                clippedFeat.geometry = turf.intersect(viewport, feat).geometry
                return clippedFeat
            })
            .flatMap((feat) => toSinglePoly(feat))
            .filter((feat) => turf.area(feat) >= 500) // area must be greater than or equal to 500 sq meters
    )

    const labelPoints = turf.featureCollection(
        clippedPolygons.features
            .map((feat) => {
                const labelFeat = { ...feat }
                const polyLabelPos = polylabel(feat.geometry.coordinates, 0.001)

                const polyLabelContained = turf.booleanContains(
                    feat,
                    turf.point(polyLabelPos)
                )

                if (!polyLabelContained) {
                    console.log(
                        feat,
                        turf.area(feat),
                        'PolyLabelNotContainedInThisFeature'
                    )
                }

                if (!Number.isNaN(polyLabelPos[0])) {
                    labelFeat.geometry = turf.point(polyLabelPos).geometry
                    return labelFeat
                }

                return null
            })
            .filter((feat) => feat)
    )

    return labelPoints
}
