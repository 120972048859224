import React from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import {
    Button,
    Flex,
    Text,
    useColorModeValue,
    useBreakpointValue,
} from '@chakra-ui/react'
import { isMobileOnly, isIOS } from 'react-device-detect'

import {
    briefingOptionsState,
    dataModeState,
    clickedFeaturesState,
    layerSelectionState,
    layerSelectionHandler,
    briefingStandardState,
    briefingAreaSourceState,
    selectedBriefingAreasState,
    briefingCreatedState,
    isBriefingOnlyState,
} from '../../../../globalState'
import BriefIcon from '../../../../icons/menu-icons/BriefIcon'

export default function BRIEF() {
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const setBriefingOptions = useSetRecoilState(briefingOptionsState)
    const setBriefingStandard = useSetRecoilState(briefingStandardState)
    const [briefingSource, setBriefingSource] = useRecoilState(
        briefingAreaSourceState
    )
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const selectedAreas = useRecoilValue(selectedBriefingAreasState)
    const briefingCreated = useRecoilValue(briefingCreatedState)
    const setClickedFeatures = useSetRecoilState(clickedFeaturesState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setLayerSelection = useSetRecoilState(layerSelectionHandler)

    const active = dataMode === 'brief'

    // Sidebar Style Colors (Light and Dark Mode)
    const activeColor = useColorModeValue('light.200', 'dark.200')
    const inactiveColor = useColorModeValue('brand.strong', 'dark.90')
    const textHover = useColorModeValue('light.80', 'dark.80')
    const collectionItemBg = useColorModeValue('light.20', 'dark.20')
    const collectionItemHover = useColorModeValue('light.20', 'dark.20')
    const navButtonRingInner = useColorModeValue('light.10', 'dark.53')

    const briefingReduce = useBreakpointValue({
        base: true,
        md: false,
    })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <Button
            {...noFocus}
            id={'brief'}
            borderRadius={0}
            cursor={isBriefingOnly ? 'default' : 'pointer'}
            border="none"
            px="0px"
            w={{
                base: isBriefingOnly ? '100%' : '20vw',
                md: '100%',
            }}
            marginBottom={{
                base: isMobileOnly && isIOS ? '-15px' : '0',
                md: '0',
            }}
            paddingBottom={{
                base: isMobileOnly && isIOS ? '15px' : 'initial',
                md: 'initial',
            }}
            height={{
                base: isMobileOnly && isIOS ? '85px' : '70px',
                md: '70px',
            }}
            onClick={() => {
                setClickedFeatures([])
                if (!isBriefingOnly) {
                    if (dataMode !== 'brief') {
                        setDataMode('brief')
                    }
                    if (layerSelection.brief.briefingareas) {
                        setLayerSelection({
                            mode: 'brief',
                            layer: 'briefingareas',
                        })
                    }
                    if (
                        briefingSource &&
                        selectedAreas.length > 0 &&
                        briefingCreated
                    ) {
                        setBriefingStandard(true)
                    } else if (briefingSource && !briefingCreated) {
                        setBriefingSource(null)
                        setBriefingOptions(true)
                    } else {
                        !briefingSource
                            ? setBriefingOptions(true)
                            : setBriefingOptions(false)
                    }
                }
            }}
            variant="outline"
            color={active ? activeColor : inactiveColor}
            bgColor={{
                base: 'none',
                md: !isBriefingOnly && active && collectionItemBg,
            }}
            _focus={{
                border: 'none',
                backgroundColor: {
                    base: 'none',
                    md: !isBriefingOnly && collectionItemHover,
                },
                color: textHover,
            }}
            _hover={{
                border: 'none',
                backgroundColor: {
                    base: 'none',
                    md: !isBriefingOnly && collectionItemHover,
                },
                color: textHover,
            }}
            _active={{
                border: 'none',
                backgroundColor: {
                    base: 'none',
                    md: !isBriefingOnly && collectionItemHover,
                },
                color: textHover,
            }}
        >
            <Flex
                pt={{ base: '0px', md: 'unset' }}
                w="100%"
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                zIndex="1"
            >
                {(!isBriefingOnly || !briefingReduce) && (
                    <Flex //DATAMODE ICON
                        marginBottom={{
                            base: active ? '22px' : '2px',
                            md: '0px',
                        }}
                        transition="all ease-out 300ms"
                        zIndex="1"
                    >
                        <BriefIcon active={active} />
                    </Flex>
                )}

                {!(isBriefingOnly && briefingReduce) && (
                    <Text // DATAMODE TITLE
                        position={{
                            base: 'absolute',
                            md: 'relative',
                        }}
                        mt={{
                            base: active ? '45px' : '25px',
                            md: '3px',
                        }}
                        fontSize={{
                            base: '0.65rem',
                            md: active ? '0.65rem' : '0.55rem',
                        }}
                        color={
                            active && (!isBriefingOnly || !briefingReduce)
                                ? activeColor
                                : inactiveColor
                        }
                        letterSpacing="0.5px"
                        fontWeight={
                            isBriefingOnly ? '600' : active ? '500' : '400'
                        }
                        opacity={{ base: active ? '1' : '0', md: '1' }}
                        height={{
                            base: '15px',
                            md: 'auto',
                        }}
                        transition="all ease-out 400ms"
                    >
                        {isBriefingOnly && briefingReduce
                            ? 'PREFLIGHT BRIEFING'
                            : 'BRIEF'}
                    </Text>
                )}
                {isBriefingOnly && briefingReduce && (
                    <Text
                        fontSize={'0.65rem'}
                        color="gray.400"
                        fontWeight="300"
                        pt="10px"
                        px="5px"
                        whiteSpace="pre-wrap"
                        lineHeight="1.4"
                    >
                        {`AERO, MET and ADVS modes are currently disabled. \nYou can enable these in your profile.`}
                    </Text>
                )}
            </Flex>
            {!isBriefingOnly && (
                <Flex
                    display={{ base: 'flex', md: 'none' }}
                    direction="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    position="absolute"
                    bottom="0"
                    width="100%"
                    height={{
                        base: isMobileOnly && isIOS ? '85px' : '70px',
                        md: '70px',
                    }}
                    marginBottom={{
                        base: isMobileOnly && isIOS ? '-3px' : '-3px',
                        md: '100%',
                    }}
                    opacity={active ? '1' : '0'}
                    _hover={{ opacity: '1' }}
                >
                    {/* <Flex // SELECTED DATAMODE OUTER CIRCLE ** Not used for now **
                    opacity={active ? '0' : '0.7'}
                    position="absolute"
                    transition="width ease-out 150ms, height ease-out 150ms, opacity ease-in 350ms"
                    transitionDelay="100ms"
                    alignItems="center"
                    justifyContent="center"
                    // bg={navButtonRingOuter}
                    width={active ? '52px' : '0px'}
                    height={active ? '52px' : '0px'}
                    borderRadius="100%"
                ></Flex> */}
                    <Flex // SELECTED DATAMODE INNER CIRCLE
                        opacity={active ? '1' : '0'}
                        position="absolute"
                        width={active ? '42px' : '0px'}
                        height={active ? '42px' : '0px'}
                        boxShadow="inset 0px 2px 6px -2px rgba(0,0,0,0.25)"
                        bg={navButtonRingInner}
                        transition="all ease-in 250ms"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="100%"
                    ></Flex>
                </Flex>
            )}
        </Button>
    )
}
