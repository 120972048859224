import React, { useEffect, useCallback } from 'react'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { useTier } from '../../../../auth/hooks'
import {
    Center,
    Flex,
    SlideFade,
    VStack,
    Tooltip,
    Text,
} from '@chakra-ui/react'

import {
    layerSelectionState,
    simulateFreeUserState,
    setModalVisibilityState,
    basemapState,
    mfaVerifiedState,
} from '../../../../globalState'

import PremiumBadge from '../../PremiumBadge'
import standardImage from './assets/standard.png'
import vncImage from './assets/vnc.png'
import linzTopoImage from './assets/linztopo.png'
import satelliteImage from './assets/satellite.png'
import { isDesktop } from 'react-device-detect'

export default function ImagerySwitcher(props) {
    const { show, setShow } = props
    const setLayerSelection = useSetRecoilState(layerSelectionState)

    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const [basemap, setBasemap] = useRecoilState(basemapState)

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        setShow(false)
    }, [basemap, setShow])

    useEffect(() => {
        setLayerSelection((prevState) => {
            return {
                ...prevState,
                constant: {
                    ...prevState.constant,
                    satellite: basemap === 'satellite',
                    vnc: basemap === 'vnc',
                    linztopo: basemap === 'linztopo',
                },
            }
        })
    }, [basemap, setLayerSelection])

    return (
        <>
            <Tooltip
                display={isDesktop ? 'flex' : 'none'}
                isDisabled={isDesktop ? false : true}
                label="Change Base Map"
                visibility={!show ? 'visible' : 'hidden'}
                hasArrow
                borderRadius="7px"
                height="26px"
                fontSize="0.7rem"
                alignItems="center"
                placement="left"
                m="5px"
                color="light.100"
                bg="light.10"
            >
                <Flex flexDirection="column">
                    <Center // STANDARD MAP and OPEN BUTTON
                        zIndex="1"
                        w={{ base: '32px', md: '30px' }}
                        h={{ base: '32px', md: '30px' }}
                        bgImage={`url(${
                            !show && basemap === 'standard'
                                ? standardImage
                                : !show && basemap === 'vnc'
                                ? vncImage
                                : !show && basemap === 'linztopo'
                                ? linzTopoImage
                                : !show && basemap === 'satellite'
                                ? satelliteImage
                                : standardImage
                        })`}
                        bgSize="contain"
                        bgPosition="center"
                        bgRepeat="no-repeat"
                        border={
                            show && basemap === 'standard'
                                ? '2px solid rgba(255,255,255,1)'
                                : show && basemap !== 'standard'
                                ? '0px solid rgba(255,255,255,1)'
                                : '2px solid rgba(255,255,255,1)'
                        }
                        borderRadius="10px"
                        _hover={{
                            backgroundColor: 'white.100',
                            cursor: 'pointer',
                            boxShadow:
                                'inset 0px 0px 20px -5px rgba(0,0,0,0.2)',
                        }}
                        onClick={() => {
                            if (!show) setShow((prevState) => !prevState)
                            if (show && basemap === 'standard')
                                setShow((prevState) => !prevState)
                            if (show) setBasemap('standard')
                        }}
                    />
                    <Text
                        position="absolute"
                        opacity={show ? '1' : '0'}
                        transition="all ease 250ms"
                        top="5px"
                        right="45px"
                        pointerEvents="none"
                        fontSize="12px"
                        letterSpacing="0.2px"
                        textAlign="right"
                        whiteSpace="nowrap"
                        color="rgb(255, 255, 255)"
                        textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                        fontWeight="500"
                    >
                        Standard
                    </Text>
                </Flex>
            </Tooltip>
            <SlideFade
                unmountOnExit
                offsetY="-20px"
                animateOpacity
                marginTop="0"
                style={{
                    marginTop: '0px !important',
                    overflow: 'initial',
                }}
                zIndex={show ? '1' : '0'}
                in={show}
            >
                <Flex
                    zIndex={show ? '1' : '0'}
                    w="100%"
                    marginTop={{
                        base: '0.9rem',
                        md: '0.9rem',
                        lg: '0.8rem',
                    }}
                    marginBottom="3"
                    direction={'column'}
                    // display={!sliderVisible && 'none'}
                >
                    <VStack
                        spacing={{
                            base: '0.85rem',
                            md: '0.85rem',
                            lg: '3',
                        }}
                        alignItems="flex-end"
                        // pb={!sliderVisible ? '15px' : '0'}
                    >
                        <Flex // SATELLITE BASE MAP
                            flexDirection="row"
                            justifyContent="flex-end"
                        >
                            <Text
                                opacity={show ? '1' : '0'}
                                position="relative"
                                transition="all ease 500ms"
                                right="15px"
                                top="5px"
                                pointerEvents="none"
                                fontSize="12px"
                                letterSpacing="0.2px"
                                textAlign="right"
                                whiteSpace="nowrap"
                                color="rgb(255, 255, 255)"
                                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                                fontWeight="500"
                            >
                                Satellite
                            </Text>
                            <Center
                                w={{ base: '32px', md: '30px' }}
                                h={{ base: '32px', md: '30px' }}
                                bgImage={`url(${satelliteImage})`}
                                bgSize="contain"
                                bgPosition="center"
                                bgRepeat="no-repeat"
                                border={
                                    basemap === 'satellite'
                                        ? '2px solid rgba(255,255,255,1)'
                                        : '0px solid rgba(255,255,255,1)'
                                }
                                borderRadius="10px"
                                _hover={{
                                    backgroundColor: 'white.100',
                                    cursor: 'pointer',
                                    boxShadow:
                                        'inset 0px 0px 20px -5px rgba(0,0,0,0.2)',
                                }}
                                onClick={() => setBasemap('satellite')}
                            />
                        </Flex>

                        <Flex // VNC BASE MAP
                            flexDirection="row"
                            justifyContent="flex-end"
                        >
                            <Text
                                opacity={show ? '1' : '0'}
                                position="relative"
                                transition="all ease 500ms"
                                right="15px"
                                top="5px"
                                pointerEvents="none"
                                fontSize="12px"
                                letterSpacing="0.2px"
                                textAlign="right"
                                whiteSpace="nowrap"
                                color="rgb(255, 255, 255)"
                                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                                fontWeight="500"
                            >
                                VNC
                            </Text>
                            <Center
                                opacity={isPremium ? '0.5' : '1'}
                                w={{ base: '32px', md: '30px' }}
                                h={{ base: '32px', md: '30px' }}
                                bgImage={`url(${vncImage})`}
                                bgSize="contain"
                                bgPosition="center"
                                bgRepeat="no-repeat"
                                border={
                                    basemap === 'vnc'
                                        ? '2px solid rgba(255,255,255,1)'
                                        : '0px solid rgba(255,255,255,1)'
                                }
                                borderRadius="10px"
                                _hover={{
                                    backgroundColor: 'white.100',
                                    cursor: 'pointer',
                                    boxShadow:
                                        !isPremium &&
                                        'inset 0px 0px 20px -5px rgba(0,0,0,0.2)',
                                }}
                                onClick={() =>
                                    isPremium
                                        ? setShowPricingTiers(true)
                                        : setBasemap('vnc')
                                }
                            />

                            {show && // PREMIUM BADGE
                                isPremium && (
                                    <Flex
                                        position="absolute"
                                        right="-2px"
                                        mt="-5px"
                                    >
                                        <PremiumBadge />
                                    </Flex>
                                )}
                        </Flex>

                        <Flex // LINZ TOPO BASE MAP
                            flexDirection="row"
                            justifyContent="flex-end"
                        >
                            <Text
                                opacity={show ? '1' : '0'}
                                position="relative"
                                transition="all ease 500ms"
                                right="15px"
                                top="5px"
                                pointerEvents="none"
                                fontSize="12px"
                                letterSpacing="0.2px"
                                textAlign="right"
                                whiteSpace="nowrap"
                                color="rgb(255, 255, 255)"
                                textShadow="rgba(0, 0, 0, 0.5) 1px 1px 3px"
                                fontWeight="500"
                            >
                                LINZ Topo
                            </Text>
                            <Center
                                opacity={isPremium ? '0.5' : '1'}
                                w={{ base: '32px', md: '30px' }}
                                h={{ base: '32px', md: '30px' }}
                                bgImage={`url(${linzTopoImage})`}
                                bgSize="contain"
                                bgPosition="center"
                                bgRepeat="no-repeat"
                                border={
                                    basemap === 'linztopo'
                                        ? '2px solid rgba(255,255,255,1)'
                                        : '0px solid rgba(255,255,255,1)'
                                }
                                borderRadius="10px"
                                _hover={{
                                    backgroundColor: 'white.100',
                                    cursor: 'pointer',
                                    boxShadow:
                                        !isPremium &&
                                        'inset 0px 0px 20px -5px rgba(0,0,0,0.25)',
                                }}
                                onClick={() =>
                                    isPremium
                                        ? setShowPricingTiers(true)
                                        : setBasemap('linztopo')
                                }
                            />
                            {show && // PREMIUM BADGE
                                isPremium && (
                                    <Flex
                                        position="absolute"
                                        right="-2px"
                                        mt="-5px"
                                    >
                                        <PremiumBadge />
                                    </Flex>
                                )}
                        </Flex>
                    </VStack>
                </Flex>
            </SlideFade>
        </>
    )
}
