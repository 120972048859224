import React from 'react'
import { Flex, Collapse, Center, IconButton, Box } from '@chakra-ui/react'
import { MdOutlineExpandMore } from 'react-icons/md'
import AltitudeSlider from '../../map/controls/sliders/altitude-slider'
import { ReactComponent as AltitudeRangeIcon } from '../../icons/assets/altitude-range.svg'
import TimeSlider from '../../map/controls/sliders/time-slider'

export default function Controls({
    altitudeSliderExpanded,
    setAltitudeSliderExpanded,
    showFilters,
    setShowFilters,
}) {
    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <>
            <Flex
                id={'mapControls'}
                direction={'column'}
                position={'absolute'}
                borderRadius={'20px'}
                justifyContent={'center'}
                w={{ base: '40px', md: '38px' }}
                top="85px"
                right="15px"
                bgColor="rgba(20,35,50, 0.3)"
                backdropFilter="blur(2px)"
                boxShadow="inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)"
                zIndex="2"
                opacity={{
                    base: showFilters ? '1' : '0',
                    md: '1',
                }}
                visibility={{
                    base: showFilters ? 'visible' : 'hidden',
                    md: 'visible',
                }}
                transition="opacity ease 300ms"
            >
                <Center
                    w="100%"
                    h="40px"
                    borderRadius={'20px'}
                    boxShadow="0px 4px 12px -5px rgba(0, 0, 0, 0.3)"
                >
                    <IconButton
                        w="40px"
                        h="40px"
                        minW="40px"
                        transform={altitudeSliderExpanded && 'rotate(180deg)'}
                        style={{ transition: 'transform 0.2s' }}
                        fontSize="1.6rem"
                        borderRadius={'20px'}
                        bgColor="white"
                        color={'brand.strong'}
                        _hover={{
                            backgroundColor: 'light.20',
                        }}
                        _active={{
                            backgroundColor: 'light.20',
                        }}
                        icon={
                            altitudeSliderExpanded ? (
                                <MdOutlineExpandMore />
                            ) : (
                                <AltitudeRangeIcon width="22px" height="22px" />
                            )
                        }
                        onClick={() =>
                            setAltitudeSliderExpanded(!altitudeSliderExpanded)
                        }
                        {...noFocus}
                    />
                </Center>
                <Collapse
                    animateOpacity
                    in={altitudeSliderExpanded}
                    style={{
                        overflow: 'initial',
                    }}
                >
                    <Flex
                        flexDir="column"
                        alignItems="center"
                        pt="25px"
                        pb="20px"
                    >
                        <AltitudeSlider mapToolsExpanded={true} />
                    </Flex>
                </Collapse>
            </Flex>
            <Flex
                zIndex="2"
                transition="opacity ease 300ms"
                opacity={{
                    base: showFilters ? '1' : '0',
                    md: '1',
                }}
                visibility={{
                    base: showFilters ? 'visible' : 'hidden',
                    md: 'visible',
                }}
            >
                <TimeSlider showFilters={showFilters} />
            </Flex>

            <Box // Shaded background behind Slide Up and Slide Down Drawers.
                className="closeOverlay"
                background={{ base: 'rgba(0,0,0,0.4)', md: 'transparent' }}
                transition="opacity ease 300ms"
                transitionDelay="0ms"
                height="100%"
                width="100%"
                display={{
                    base: 'flex',
                    md: 'none',
                }}
                zIndex="1"
                position="fixed"
                top="0px"
                left="0"
                marginTop="0 !important"
                // backdropFilter="blur(2px)"
                opacity={{
                    base: showFilters ? '1' : '0',
                    md: '1',
                }}
                visibility={{
                    base: showFilters ? 'visible' : 'hidden',
                    md: 'visible',
                }}
                onClick={() => {
                    setShowFilters(false)
                }}
            ></Box>
        </>
    )
}
