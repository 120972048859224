import React, { useState, useContext, useEffect, useRef } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Source, Layer, MapContext } from 'react-mapbox-gl'
import {
    altitudeModeState,
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    selectedTimeState,
    VectorLayerRefreshInfo,
    layerStatusState,
    layersLoaded,
} from '../../../globalState'
import calcSymbolPlacement from '../functions/dynamicSymbols'

export default function Notam() {
    const [altitudeMode, setAltitudeMode] = useRecoilState(altitudeModeState)
    const selectedLowerAltitude = useRecoilValue(selectedLowerAltitudeState)
    const selectedUpperAltitude = useRecoilValue(selectedUpperAltitudeState)
    useEffect(() => {
        if (altitudeMode !== 'double') {
            setAltitudeMode('double')
        }
    }, [altitudeMode, setAltitudeMode])

    const selectedTime = useRecoilValue(selectedTimeState)
    const layerData = useRecoilValue(VectorLayerRefreshInfo)
    const [loaded, setLoaded] = useRecoilState(layersLoaded)
    const [mounted, setMounted] = useState(false)
    const layerState = useRecoilValue(layerStatusState)
    const mapInstance = useContext(MapContext)

    const layerId = 'notam'

    // labelData is defined here as a useRef, directly using an empty FeatureCollection in the source breaks dynamic symbol placement
    const labelData = useRef({
        type: 'FeatureCollection',
        features: [],
    })

    // trigger loading once to avoid redrawing the time slider.

    useEffect(() => {
        if (!mounted) {
            setLoaded(!layerState[`${layerId}Loading`])
            setMounted(!layerState[`${layerId}Mounted`])
        }
    }, [layerState, setLoaded, mounted, loaded])

    useEffect(() => {
        const dynamicSymbols = () => {
            calcSymbolPlacement(mapInstance, 'notam-fill', 'notam-symbol', 'id')
        }

        const moveEndCallback = () => {
            var tileLoad = setInterval(function () {
                if (mapInstance.loaded()) {
                    dynamicSymbols()
                    clearInterval(tileLoad)
                }
            }, 300)
        }

        mapInstance.on('moveend', moveEndCallback)

        function renderCallback(e) {
            if (e.target && e.target.loaded()) {
                dynamicSymbols()
                mapInstance.off('render', renderCallback)
            }
        }

        mapInstance.on('render', renderCallback)

        return () => {
            mapInstance.off('moveend', moveEndCallback)
        }
    }, [layerData.notam.dataURL, mapInstance])

    return (
        <>
            <Source
                id="notam"
                geoJsonSource={{
                    type: 'geojson',
                    data: layerData.notam.dataURL,
                }}
            />
            <Source
                id="notam-symbol"
                geoJsonSource={{
                    type: 'geojson',
                    data: labelData.current,
                }}
            />
            <Layer
                id="notam-fill"
                before="aerodromes-symbol"
                sourceId="notam"
                type="fill"
                paint={{
                    'fill-antialias': true,
                    'fill-color': '#ff7f00',
                    'fill-opacity': 0.1,
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    [
                        'any',
                        ['==', ['get', 'permanent'], 'PERM'],
                        ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                    ],
                ]}
            />
            <Layer
                id="notam-line"
                before="aerodromes-symbol"
                sourceId="notam"
                type="line"
                paint={{
                    'line-color': '#ff7f00',
                    'line-width': 2,
                    'line-dasharray': [6, 4],
                    'line-opacity': 1,
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    [
                        'any',
                        ['==', ['get', 'permanent'], 'PERM'],
                        ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                    ],
                ]}
            />
            <Layer
                id="notam-symbol"
                before="aerodromes-symbol"
                sourceId="notam-symbol"
                layout={{
                    'icon-image': [
                        'match',
                        ['get', 'code23'],
                        [
                            'WA',
                            'WB',
                            'WC',
                            'WD',
                            'WE',
                            'WF',
                            'WG',
                            'WH',
                            'WJ',
                            'WL',
                            'WM',
                            'WP',
                            'WR',
                            'WS',
                            'WT',
                            'WV',
                            'WW',
                            'WY',
                        ],
                        'notam-warning',
                        ['RA', 'RD', 'RM', 'RO', 'RP', 'RR', 'RT'],
                        'notam-warning',
                        [
                            'CA',
                            'CB',
                            'CC',
                            'CD',
                            'CE',
                            'CG',
                            'CL',
                            'CM',
                            'CP',
                            'CR',
                            'CS',
                            'CT',
                            'NA',
                            'NB',
                            'NC',
                            'ND',
                            'NF',
                            'NL',
                            'NM',
                            'NN',
                            'NT',
                            'NV',
                            'NX',
                        ],
                        'notam-comms',
                        ['WU', 'WZ'],
                        'notam-drone',
                        ['OB', 'OL'],
                        'notam-obstacle',
                        'FA',
                        'notam-aerodrome',
                        'notam',
                    ],
                    'icon-anchor': 'center',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.4,
                        10,
                        0.6,
                        12,
                        0.8,
                        16,
                        1,
                    ],
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-field': [
                        'case',
                        ['!=', ['get', 'itemg'], ['literal', null]],
                        [
                            'format',
                            ['get', 'upperlabel'],
                            {},
                            '\n—————\n',
                            {
                                'font-scale': 0.5,
                                'text-font': [
                                    'literal',
                                    ['Roboto Black', 'Arial Unicode MS Bold'],
                                ],
                            },
                            ['get', 'lowerlabel'],
                            {},
                        ],
                        '',
                    ],
                    'text-line-height': 1,
                    'text-font': ['Roboto Bold', 'Arial Unicode MS Bold'],
                    'text-offset': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        ['literal', [1, -0.5]],
                        10,
                        ['literal', [1.5, -1]],
                        12,
                        ['literal', [2, -1.5]],
                        16,
                        ['literal', [3, -2]],
                    ],
                    'text-anchor': 'left',
                    'text-size': 10,
                    'text-allow-overlap': true,
                }}
                paint={{
                    'text-color': '#ff7f00',
                    'text-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        0,
                        9,
                        0,
                        10,
                        1,
                    ],
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    [
                        'any',
                        ['==', ['get', 'permanent'], 'PERM'],
                        ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                    ],
                ]}
            />
        </>
    )
}
