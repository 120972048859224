import { useEffect, useState, useContext } from 'react'
import { MapContext } from 'react-mapbox-gl'

// see https://github.com/tannerlinsley/react-query/issues/293
// see https://usehooks.com/useDebounce/
export default function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState({
        search: value,
        coords: { lat: 0, lon: 0 },
    })
    const mapInstance = useContext(MapContext)

    useEffect(
        () => {
            // Update debounced value after delay
            const coords = mapInstance.getCenter()
            const handler = setTimeout(() => {
                setDebouncedValue({ search: value, coords })
            }, delay)

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay, mapInstance] // Only re-call effect if value or delay changes
    )

    return debouncedValue
}
