import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloudImageryIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_2201_33338)">
                <path
                    d="M10.0514 12L12.4771 14.43L11.5686 15.3386L10.66 14.43L10.0514 15.0386L11.05 16.0329L10.5529 16.5343L10.7457 16.7314C11.1443 16.5471 11.6329 16.6157 11.9586 16.9457L10.4457 18.4586C10.1157 18.1329 10.0471 17.6443 10.2314 17.2457L10.0343 17.0529L9.53286 17.55L8.53857 16.5514L7.93 17.16L8.83857 18.0686L7.93 18.9771L5.5 16.5514L6.41714 15.6429L7.32571 16.5514L7.93 15.9471L6.71714 14.7343C6.38286 14.4 6.38286 13.8557 6.71714 13.5214L7.02143 13.2171C7.35571 12.8829 7.9 12.8829 8.23429 13.2171L9.44714 14.43L10.0514 13.8257L9.14286 12.9171L10.0514 12ZM12.7857 17.5714C12.7857 18.5186 12.0186 19.2857 11.0714 19.2857V18.4286C11.5429 18.4286 11.9286 18.0429 11.9286 17.5714H12.7857ZM14.5 17.5714C14.5 19.4657 12.9657 21 11.0714 21V20.1429C12.49 20.1429 13.6429 18.99 13.6429 17.5714H14.5Z"
                    fill="currentcolor"
                />
                <path
                    d="M15.2836 16H17.5C18.8051 16 19.9806 15.4445 20.8026 14.5568C21.5458 13.7542 22.0002 12.6802 22.0002 11.5C22.0002 9.01472 19.8182 7 17.5 7L17.489 7.00001C17.4342 7.00014 17.3795 7.00126 17.3251 7.00334C16.2543 4.92855 14.0505 3.5 11.75 3.5C8.9028 3.5 6.50027 5.40385 5.746 8.00794C3.65521 8.13895 2 9.87618 2 12C2 13.6159 2.95821 15.0081 4.33743 15.6392C4.51565 15.1649 4.76006 14.7209 5.06 14.3173C4.14513 13.9458 3.5 13.0482 3.5 12C3.5 10.6193 4.61929 9.5 6 9.5H7.00647C7.13647 6.99286 9.21051 5 11.75 5C13.3735 5 14.9336 5.93612 15.7998 7.33233C15.1672 7.5907 14.6065 7.98893 14.1563 8.48859L15.264 9.50002C15.798 8.90341 16.5672 8.52176 17.4259 8.5009C17.4505 8.5003 17.4752 8.5 17.5 8.5C17.5121 8.5 17.5241 8.50008 17.5362 8.50025C17.5658 8.50065 17.5954 8.50154 17.625 8.50292C19.123 8.57279 20.5 9.8879 20.5 11.5C20.5 13.1569 19.1569 14.5 17.5 14.5H14.5701C14.8819 14.9574 15.1245 15.4619 15.2836 16Z"
                    fill="currentcolor"
                />
            </g>
            <defs>
                <clipPath id="clip0_2201_33338">
                    <rect
                        width="20"
                        height="19"
                        fill="white"
                        transform="translate(2 2.5)"
                    />
                </clipPath>
            </defs>
        </Icon>
    )
}
