import { config } from '../config'

export default function canUse(mode, layer, tier, showWIP, mapDisabled) {
    const { layers } = config
    const { tier: layerTier } =
        layers[layer].collection.filter((l) => l.id === mode)[0] || {}

    const wip = layers[layer]?.wip ?? false
    const unavailable = layers[layer]?.unavailable ?? false
    const mapLayer = layers[layer]?.mapLayer ?? false

    return (
        // if map is disabled, only return layers that are not mapLayers, and not wip/unavailable
        (layerTier <= tier &&
            !wip &&
            mapDisabled &&
            !mapLayer &&
            !unavailable) ||
        // otherwise if map is enabled, return all layers excluding wip/unavailable
        (layerTier <= tier && !wip && !mapDisabled && !unavailable) ||
        // otherwise if map is disabled, and the user is tier 3 (admin) and showWIP is true, return all layers except those that are not mapLayers
        (tier === 3 &&
            showWIP &&
            (wip || unavailable) &&
            mapDisabled &&
            !mapLayer) ||
        // otherwise if map is enabled, and the user is tier 3 (admin) and showWIP is true, return all layers
        (tier === 3 && showWIP && (wip || unavailable) && !mapDisabled)
    )
}
