import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    Text,
    Flex,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Divider,
    TableContainer,
    Badge,
} from '@chakra-ui/react'
import { parseISO, formatDistanceToNow } from 'date-fns'
import decodePhoneticLetter from '../../../../util/decodePhoneticLetter'
import formatDateTime, {
    timeZoneAbbreviation,
} from '../../../../util/dateFormatter'
import {
    cloudCoverDecode,
    // cloudCoverOktasDecode,
    cloudTypeDecode,
} from '../../../../util/metDecode'

import ErrorText from '../ErrorText'
import ImprRawToggle from '../ImprRawToggle'
import FixedPosToggle from '../FixedPosToggle'

import {
    timeZoneState,
    decodedTextDefaultState,
    atisFixFirstColumnState,
} from '../../../../globalState'

export default function AtisContent(props) {
    const decodeDefault = useRecoilValue(decodedTextDefaultState)

    const { data, isPremium, showErrors, mfaExpired, fullscreen, tempATIS } =
        props
    const atis = data && data.atis
    const [raw, setRaw] = useState(
        tempATIS || isPremium || !decodeDefault ? true : false
    )
    const timeZone = useRecoilValue(timeZoneState)
    const [fixFirstColumn, setFixFirstColumn] = useRecoilState(
        atisFixFirstColumnState
    )
    useEffect(() => {
        if (tempATIS || isPremium) {
            setRaw(true)
        } else if (!decodeDefault) {
            setRaw(!decodeDefault)
        }
    }, [tempATIS, isPremium, decodeDefault])

    const formatRawAtis = (atis) => {
        return <Text style={{ whiteSpace: 'pre-wrap' }}>{atis.raw}</Text>
    }

    const hasCloud = atis && atis.cloud
    const cloudlayers = hasCloud ? atis.cloud : []
    const reversedCloudLayers = [] // reverse order so cloud layers stacked with heighest at top
    for (let i = cloudlayers && cloudlayers.length - 1; i >= 0; i--) {
        reversedCloudLayers.push(cloudlayers[i])
    }

    const instanceRef = useRef()
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)
    const checkScrollbarVisibility = () => {
        if (instanceRef.current) {
            setIsScrollbarVisible(
                instanceRef.current.scrollWidth >
                    instanceRef.current.clientWidth
            )
        }
    }
    useEffect(() => {
        checkScrollbarVisibility()
        // listener to check table scrollbar visibility
        const resizeListener = () => {
            checkScrollbarVisibility()
        }
        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    useEffect(() => {
        const delay = setTimeout(() => {
            checkScrollbarVisibility()
        }, 310)

        return () => {
            clearTimeout(delay)
        }
    }, [fullscreen])

    useEffect(() => {
        // reset fixed first column if there is no overflow
        if (!isScrollbarVisible) {
            setFixFirstColumn(false)
        }
    }, [isScrollbarVisible, setFixFirstColumn, fullscreen])

    // useEffect(() => {
    //     console.log('ATIS data', data)
    // }, [data])

    return (
        <>
            <Box w="100%">
                <Flex
                    marginBottom={{ base: '20px', sm: '20px' }}
                    justifyContent="space-between"
                    gap="10px"
                >
                    <Flex
                        justifyContent="flex-start"
                        alignItems={{ base: 'baseline', smd: 'center' }}
                        gap={tempATIS && '10px'}
                        marginBottom={2}
                    >
                        {tempATIS && (
                            <Badge
                                height="16px"
                                minWidth="42px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                variant="solid"
                                bg="brand.plus"
                                borderRadius="10"
                                px="4px"
                                py="0px"
                                fontSize="0.65rem"
                            >
                                TEMP
                            </Badge>
                        )}
                        <Text
                            width="auto"
                            fontSize={{
                                base: '0.85rem',
                                xs: '0.9rem',
                                sm: '1rem',
                            }}
                            fontWeight="bold"
                            lineHeight="1.3"
                            minHeight={{ base: '40px', smd: '20px' }}
                        >
                            AUTOMATIC TERMINAL INFORMATION SERVICE
                        </Text>
                    </Flex>
                    <ImprRawToggle // IMPR/RAW Toggle
                        isPremium={isPremium}
                        selectedData={atis}
                        raw={raw}
                        setRaw={setRaw}
                        mfaExpired={mfaExpired}
                        tempATIS={tempATIS}
                    />
                </Flex>

                <Box w="100%">
                    {(showErrors || atis.outofdate) && (
                        <Flex mb="15px" justifyContent="center">
                            <ErrorText>
                                There has been an issue with the connection
                                between PreFlight and IFIS. This ATIS was last
                                validated with IFIS{' '}
                                <strong>
                                    {formatDistanceToNow(
                                        parseISO(atis.updated),
                                        {
                                            addSuffix: true,
                                        }
                                    )}
                                </strong>
                                .<br />
                                There is a possibility that this ATIS is no
                                longer current. Check back again soon, or
                                alternatively visit{' '}
                                <a
                                    style={{
                                        fontWeight: '500',
                                        textDecoration: 'underline',
                                    }}
                                    href="https://ifis.airways.co.nz/"
                                >
                                    IFIS
                                </a>{' '}
                                for an up to date aerodrome briefing.
                            </ErrorText>
                        </Flex>
                    )}

                    {!raw && (
                        <Box w="100%" pl={0} pr={0}>
                            <Table
                                // {...tabSwipeHeader}
                                size="sm"
                                fontFamily="Open Sans"
                                marginTop="1"
                                marginBottom="3"
                            >
                                <Tbody verticalAlign="baseline">
                                    <Tr>
                                        <Td
                                            border="none"
                                            paddingStart="0"
                                            paddingEnd="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="55px"
                                            whiteSpace="pre"
                                            fontSize="0.9rem"
                                            textAlign="left"
                                        >
                                            Issued:
                                        </Td>

                                        <Td width="100%" fontSize="0.9rem">
                                            <Flex
                                                gap={{
                                                    base: '2px',
                                                    sm: '10px',
                                                }}
                                                alignItems="baseline"
                                                flexDirection={{
                                                    base: 'column',
                                                    sm: 'row',
                                                }}
                                            >
                                                <Text>
                                                    {atis?.validfrom &&
                                                        formatDateTime(
                                                            atis.validfrom,
                                                            timeZone
                                                        )}{' '}
                                                </Text>
                                                <Text
                                                    color="gray.400"
                                                    fontSize="0.75rem"
                                                    fontWeight="400"
                                                >
                                                    {atis?.validfrom &&
                                                        formatDistanceToNow(
                                                            parseISO(
                                                                atis.validfrom
                                                            ),
                                                            {
                                                                addSuffix: true,
                                                            }
                                                        )}
                                                </Text>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </Box>
                    )}

                    {!raw && (
                        <Box w="100%" pl={0} pr={0}>
                            <Divider
                                zIndex="2"
                                borderColor="light.300"
                                borderBottomWidth="1px"
                                marginTop="13px"
                                marginBottom="0px"
                            />
                            <TableContainer
                                className="scrollHorizontal"
                                ref={instanceRef}
                            >
                                <Table
                                    size="sm"
                                    variant="simple"
                                    colorScheme="gray"
                                    fontFamily="Open Sans"
                                    marginTop="0"
                                    marginBottom="4"
                                >
                                    <Tbody
                                        verticalAlign="top"
                                        whiteSpace="pre"
                                        borderBottom="1px solid #EAEDF0 !important"
                                        clipPath="inset(0px 0px 0px 0px)"
                                    >
                                        {isScrollbarVisible && (
                                            <Tr>
                                                <Td
                                                    zIndex="2"
                                                    border="none"
                                                    paddingTop={3}
                                                    paddingStart="3"
                                                    paddingEnd="3"
                                                    fontWeight="bold"
                                                    width="170px"
                                                    pb="0px"
                                                    minWidth="170px"
                                                    height={
                                                        isScrollbarVisible
                                                            ? '57px'
                                                            : '0px'
                                                    }
                                                    minHeight={
                                                        isScrollbarVisible
                                                            ? '57px'
                                                            : '0px'
                                                    }
                                                    fontSize="0.9rem"
                                                    borderColor="transparent"
                                                    bg="light.35"
                                                    position={
                                                        fixFirstColumn
                                                            ? 'absolute'
                                                            : 'relative'
                                                    }
                                                >
                                                    {!isPremium &&
                                                        !raw &&
                                                        isScrollbarVisible && (
                                                            <Flex
                                                                width="100%"
                                                                justifyContent="flex-start"
                                                                mb="-12px"
                                                            >
                                                                <FixedPosToggle // Fix First Column Toggle
                                                                    fixFirstColumn={
                                                                        fixFirstColumn
                                                                    }
                                                                    setFixFirstColumn={
                                                                        setFixFirstColumn
                                                                    }
                                                                />
                                                            </Flex>
                                                        )}
                                                </Td>
                                                <Td
                                                    border="none"
                                                    width="100%"
                                                    fontSize="0.9rem"
                                                    pb="0px"
                                                    borderColor="transparent"
                                                    position="relative"
                                                    height={
                                                        isScrollbarVisible
                                                            ? '57px'
                                                            : '0px'
                                                    }
                                                    minHeight={
                                                        isScrollbarVisible
                                                            ? '57px'
                                                            : '0px'
                                                    }
                                                    paddingLeft={
                                                        fixFirstColumn
                                                            ? '186px'
                                                            : '4'
                                                    }
                                                ></Td>
                                            </Tr>
                                        )}
                                        <Tr>
                                            <Td
                                                borderTop={
                                                    isScrollbarVisible &&
                                                    '1px solid #E8EBEE !important'
                                                }
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                Information:{' '}
                                            </Td>
                                            <Td
                                                borderTop={
                                                    isScrollbarVisible &&
                                                    '1px solid #E8EBEE !important'
                                                }
                                                lineHeight="1.4"
                                                py="5px"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.information
                                                    ? decodePhoneticLetter(
                                                          atis.information
                                                      )
                                                    : 'Not defined'}
                                            </Td>
                                        </Tr>
                                        {atis.apch && (
                                            <Tr>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    zIndex="1"
                                                    borderTop="1px solid #E8EBEE !important"
                                                    borderBottom="none"
                                                    paddingStart="3"
                                                    paddingEnd="1"
                                                    fontWeight="bold"
                                                    width="170px"
                                                    minWidth="170px"
                                                    minHeight="120px"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    bg="light.35"
                                                    position={
                                                        fixFirstColumn
                                                            ? 'absolute'
                                                            : 'relative'
                                                    }
                                                    pointerEvents={
                                                        fixFirstColumn
                                                            ? 'none'
                                                            : 'initial'
                                                    }
                                                >
                                                    Approach:{' '}
                                                </Td>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    borderTop="1px solid #EAEDF0 !important"
                                                    borderBottom="none"
                                                    width="100%"
                                                    fontSize="0.9rem"
                                                    position="relative"
                                                    paddingLeft={
                                                        fixFirstColumn
                                                            ? '186px'
                                                            : '4'
                                                    }
                                                    whiteSpace="pre-line"
                                                >
                                                    {atis.apch
                                                        ? atis.apch
                                                        : 'Not defined'}
                                                </Td>
                                            </Tr>
                                        )}
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                Runway:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.rwy
                                                    ? atis.rwy
                                                    : 'Not defined'}{' '}
                                            </Td>
                                        </Tr>
                                        {atis.rwycond && (
                                            <Tr>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    zIndex="1"
                                                    borderTop="1px solid #E8EBEE !important"
                                                    borderBottom="none"
                                                    paddingStart="3"
                                                    paddingEnd="1"
                                                    fontWeight="bold"
                                                    width="170px"
                                                    minWidth="170px"
                                                    minHeight="120px"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    bg="light.35"
                                                    position={
                                                        fixFirstColumn
                                                            ? 'absolute'
                                                            : 'relative'
                                                    }
                                                    pointerEvents={
                                                        fixFirstColumn
                                                            ? 'none'
                                                            : 'initial'
                                                    }
                                                >
                                                    Runway Condition:{' '}
                                                </Td>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    borderTop="1px solid #EAEDF0 !important"
                                                    borderBottom="none"
                                                    width="100%"
                                                    fontSize="0.9rem"
                                                    position="relative"
                                                    paddingLeft={
                                                        fixFirstColumn
                                                            ? '186px'
                                                            : '4'
                                                    }
                                                    whiteSpace="pre-line"
                                                >
                                                    {atis.rwycond}
                                                </Td>
                                            </Tr>
                                        )}
                                        {atis.oprinfo && (
                                            <Tr>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    zIndex="1"
                                                    borderTop="1px solid #E8EBEE !important"
                                                    borderBottom="none"
                                                    paddingStart="3"
                                                    paddingEnd="1"
                                                    fontWeight="bold"
                                                    width="170px"
                                                    minWidth="170px"
                                                    minHeight="120px"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    bg="light.35"
                                                    position={
                                                        fixFirstColumn
                                                            ? 'absolute'
                                                            : 'relative'
                                                    }
                                                    pointerEvents={
                                                        fixFirstColumn
                                                            ? 'none'
                                                            : 'initial'
                                                    }
                                                >
                                                    Operational Info:{' '}
                                                </Td>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    borderTop="1px solid #EAEDF0 !important"
                                                    borderBottom="none"
                                                    width="100%"
                                                    fontSize="0.9rem"
                                                    position="relative"
                                                    paddingLeft={
                                                        fixFirstColumn
                                                            ? '186px'
                                                            : '4'
                                                    }
                                                    whiteSpace="pre-line"
                                                >
                                                    {atis.oprinfo}
                                                </Td>
                                            </Tr>
                                        )}
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                Wind:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis?.wind?.direction
                                                    ? atis.wind.direction ===
                                                      'VRB'
                                                        ? 'Variable'
                                                        : atis.wind
                                                              .direction ===
                                                          'CALM'
                                                        ? 'Calm'
                                                        : `${atis.wind.direction}°M`
                                                    : 'Not available'}
                                                {atis?.wind?.direction &&
                                                    (atis.wind.swing
                                                        ? ` Varying ${atis.wind.swing[0]}-${atis.wind.swing[1]}°M`
                                                        : '')}
                                                {atis?.wind?.direction &&
                                                    `${
                                                        atis.wind.strength
                                                            ? ', ' +
                                                              atis.wind.strength
                                                            : ''
                                                    }${
                                                        atis.wind.gust
                                                            ? '/G' +
                                                              atis.wind.gust
                                                            : ''
                                                    }${
                                                        atis.wind.strength
                                                            ? ' kt'
                                                            : ''
                                                    }`}
                                                {atis?.wind?.direction &&
                                                    (atis.wind.remark
                                                        ? `, ${atis.wind.remark}`
                                                        : '')}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                Visibility:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.visibility
                                                    ? atis.visibility
                                                    : 'Not available'}
                                            </Td>
                                        </Tr>
                                        {atis.weather && (
                                            <Tr>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    zIndex="1"
                                                    borderTop="1px solid #E8EBEE !important"
                                                    borderBottom="none"
                                                    paddingStart="3"
                                                    paddingEnd="1"
                                                    fontWeight="bold"
                                                    width="170px"
                                                    minWidth="170px"
                                                    minHeight="120px"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    bg="light.35"
                                                    position={
                                                        fixFirstColumn
                                                            ? 'absolute'
                                                            : 'relative'
                                                    }
                                                    pointerEvents={
                                                        fixFirstColumn
                                                            ? 'none'
                                                            : 'initial'
                                                    }
                                                >
                                                    Weather:{' '}
                                                </Td>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    borderTop="1px solid #EAEDF0 !important"
                                                    borderBottom="none"
                                                    width="100%"
                                                    fontSize="0.9rem"
                                                    position="relative"
                                                    paddingLeft={
                                                        fixFirstColumn
                                                            ? '186px'
                                                            : '4'
                                                    }
                                                    whiteSpace="pre-line"
                                                >
                                                    {atis.weather}
                                                </Td>
                                            </Tr>
                                        )}
                                        {atis.cloud && (
                                            <Tr>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    zIndex="1"
                                                    borderTop="1px solid #E8EBEE !important"
                                                    borderBottom="none"
                                                    paddingStart="3"
                                                    paddingEnd="1"
                                                    fontWeight="bold"
                                                    width="170px"
                                                    minWidth="170px"
                                                    minHeight="120px"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    bg="light.35"
                                                    position={
                                                        fixFirstColumn
                                                            ? 'absolute'
                                                            : 'relative'
                                                    }
                                                    pointerEvents={
                                                        fixFirstColumn
                                                            ? 'none'
                                                            : 'initial'
                                                    }
                                                >
                                                    Cloud:{' '}
                                                </Td>
                                                <Td
                                                    py="5px"
                                                    lineHeight="1.5"
                                                    borderTop="1px solid #EAEDF0 !important"
                                                    borderBottom="none"
                                                    width="100%"
                                                    fontSize="0.9rem"
                                                    position="relative"
                                                    paddingLeft={
                                                        fixFirstColumn
                                                            ? '186px'
                                                            : '4'
                                                    }
                                                    whiteSpace="pre-line"
                                                >
                                                    <Flex
                                                        flexDirection="column"
                                                        justifyContent="space-between"
                                                    >
                                                        {reversedCloudLayers.map(
                                                            (
                                                                cloudLayer,
                                                                index
                                                            ) => (
                                                                <Flex
                                                                    key={`cloudLayer-${index}`}
                                                                    gap="5px"
                                                                >
                                                                    <Text>
                                                                        {cloudCoverDecode(
                                                                            cloudLayer.cover
                                                                        )}
                                                                    </Text>
                                                                    {/* <Text
                                                                        color="gray.500"
                                                                        fontWeight="300"
                                                                    >
                                                                        {`(${cloudCoverOktasDecode(
                                                                            cloudLayer.cover
                                                                        )})`}
                                                                    </Text> */}
                                                                    {cloudLayer.type && (
                                                                        <Text>
                                                                            {cloudTypeDecode(
                                                                                cloudLayer.type
                                                                            )}
                                                                        </Text>
                                                                    )}
                                                                    {cloudLayer.altitude && (
                                                                        <Text>
                                                                            {
                                                                                cloudLayer.altitude
                                                                            }
                                                                        </Text>
                                                                    )}
                                                                </Flex>
                                                            )
                                                        )}
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        )}
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                Temperature:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.temperature ||
                                                atis.temperature === 0
                                                    ? atis.temperature !== 0
                                                        ? `${atis.temperature}°C`
                                                        : '0°C'
                                                    : 'Not Available'}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                Dewpoint:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.dewpoint ||
                                                atis.dewpoint === 0
                                                    ? atis.dewpoint !== 0
                                                        ? `${atis.dewpoint}°C`
                                                        : '0°C'
                                                    : 'Not Available'}
                                            </Td>
                                        </Tr>
                                        {atis.sigwx && (
                                            <Tr>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    zIndex="1"
                                                    borderTop="1px solid #E8EBEE !important"
                                                    borderBottom="none"
                                                    paddingStart="3"
                                                    paddingEnd="1"
                                                    fontWeight="bold"
                                                    width="170px"
                                                    minWidth="170px"
                                                    minHeight="120px"
                                                    fontSize="0.9rem"
                                                    textAlign="left"
                                                    bg="light.35"
                                                    position={
                                                        fixFirstColumn
                                                            ? 'absolute'
                                                            : 'relative'
                                                    }
                                                    pointerEvents={
                                                        fixFirstColumn
                                                            ? 'none'
                                                            : 'initial'
                                                    }
                                                >
                                                    Significant Weather:{' '}
                                                </Td>
                                                <Td
                                                    lineHeight="1.4"
                                                    py="5px"
                                                    borderTop="1px solid #EAEDF0 !important"
                                                    borderBottom="none"
                                                    width="100%"
                                                    fontSize="0.9rem"
                                                    position="relative"
                                                    paddingLeft={
                                                        fixFirstColumn
                                                            ? '186px'
                                                            : '4'
                                                    }
                                                    whiteSpace="pre-line"
                                                >
                                                    {atis.sigwx}
                                                </Td>
                                            </Tr>
                                        )}
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                QNH:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.qnh
                                                    ? `${atis.qnh} hPa`
                                                    : 'Not Available'}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                2000 ft Wind:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.twothousandftwind
                                                    .direction &&
                                                    atis.twothousandftwind
                                                        .type}{' '}
                                                {atis.twothousandftwind
                                                    .direction
                                                    ? `${atis.twothousandftwind.direction} °M`
                                                    : 'Not available'}
                                                {atis.twothousandftwind
                                                    .direction &&
                                                atis.twothousandftwind.swing
                                                    ? ` Varying ${atis.wind.twothousandftwind[0]}-${atis.twothousandftwind.swing[1]}°M`
                                                    : ''}
                                                {atis.twothousandftwind
                                                    .direction && ', '}
                                                {atis.twothousandftwind
                                                    .direction &&
                                                    `${
                                                        atis.twothousandftwind
                                                            .strength
                                                    }${
                                                        atis.twothousandftwind
                                                            .gust
                                                            ? '/G' +
                                                              atis
                                                                  .twothousandftwind
                                                                  .gust
                                                            : ''
                                                    } kt`}
                                                {atis.twothousandftwind
                                                    .direction &&
                                                atis.twothousandftwind.remark
                                                    ? `, ${atis.twothousandftwind.remark}`
                                                    : ''}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                zIndex="1"
                                                borderTop="1px solid #E8EBEE !important"
                                                borderBottom="none"
                                                paddingStart="3"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width="170px"
                                                minWidth="170px"
                                                minHeight="120px"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                bg="light.35"
                                                position={
                                                    fixFirstColumn
                                                        ? 'absolute'
                                                        : 'relative'
                                                }
                                                pointerEvents={
                                                    fixFirstColumn
                                                        ? 'none'
                                                        : 'initial'
                                                }
                                            >
                                                Contact:{' '}
                                            </Td>
                                            <Td
                                                lineHeight="1.4"
                                                py="5px"
                                                borderTop="1px solid #EAEDF0 !important"
                                                borderBottom="none"
                                                width="100%"
                                                fontSize="0.9rem"
                                                position="relative"
                                                paddingLeft={
                                                    fixFirstColumn
                                                        ? '186px'
                                                        : '4'
                                                }
                                                whiteSpace="pre-line"
                                            >
                                                {atis.contact
                                                    ? atis.contact
                                                    : 'Not Available'}
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                    {raw && <Box fontSize="0.9rem">{formatRawAtis(atis)}</Box>}
                </Box>
                <Divider
                    zIndex="2"
                    borderColor="light.300"
                    borderBottomWidth="1px"
                    marginTop={raw ? '20px' : '10px'}
                    marginBottom="10px"
                />
                <Box align="Right" color="gray.400" zIndex="2">
                    {raw && (
                        <Flex flexDirection="column">
                            <Text
                                height="22px"
                                as="i"
                                fontSize="0.7rem"
                                paddingBottom="5px"
                                paddingRight="3px"
                            >
                                All times referenced in the standard ATIS are{' '}
                                <strong>UTC</strong>
                            </Text>
                        </Flex>
                    )}
                    {!raw && (
                        <Flex flexDirection="column">
                            <Text
                                height="22px"
                                as="i"
                                fontSize="0.7rem"
                                paddingBottom="5px"
                                paddingRight="3px"
                            >
                                All dates and times referenced are{' '}
                                <strong>
                                    {`${
                                        timeZone === 'UTC'
                                            ? 'UTC'
                                            : timeZoneAbbreviation(
                                                  new Date(),
                                                  Intl.DateTimeFormat().resolvedOptions()
                                                      .timeZone,
                                                  'z'
                                              )
                                    }`}
                                </strong>
                            </Text>
                        </Flex>
                    )}
                    <Flex flexDirection="column">
                        <Text
                            height="25px"
                            as="i"
                            fontSize="0.7rem"
                            paddingBottom="5px"
                            paddingRight="3px"
                        >
                            All heights reference feet above aerodrome level
                        </Text>
                    </Flex>
                    {raw && (
                        <Flex flexDirection="column">
                            <Text
                                height="22px"
                                as="i"
                                fontSize="0.7rem"
                                lineHeight="1"
                                paddingBottom="5px"
                                paddingRight="3px"
                            >
                                Wind:°MAGNETIC / Speed:KT
                            </Text>
                        </Flex>
                    )}
                </Box>
            </Box>
        </>
    )
}
