import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Center, Spinner, Flex, Text } from '@chakra-ui/react'
import { ReactComponent as PreflightIcon } from '../icons/assets/preflight-icon-shadow.svg'
import { isMobileOnly, isIOS } from 'react-device-detect'

export default function ManageSubscription() {
    const { getAccessTokenSilently } = useAuth0()
    const { isLoading, user, loginWithRedirect } = useAuth0()

    useEffect(() => {
        // if user is logged in, and is redirected from Stripe to this page (in cases of mistakenly trying to duplicate a subscription), redirect directly to Stripe's Manage Subscription page
        if (user) {
            async function fetchSubscriptionAndRedirect() {
                const accessToken = await getAccessTokenSilently()
                fetch(`${window.location.origin}/managesubscription`, {
                    headers: {
                        email: user.email,
                        authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then((res) => res.json())
                    .then((session) => window.location.replace(session.url))
            }
            fetchSubscriptionAndRedirect()
        }
    }, [getAccessTokenSilently, user])

    useEffect(() => {
        // if user is NOT logged in, and is redirected from Stripe to this page, redirect to login then redirect to Stripes Manage Subscription page
        if (!isLoading && !user) {
            loginWithRedirect({
                authorizationParams: {
                    redirect_uri: `${window.location.origin}/manage-subscription`,
                },
            })
        }
    }, [isLoading, user, loginWithRedirect])

    const fillColor = 'light.200'
    const emptyColor = 'rgba(255,255,255,0.2)'
    const emptyColorSecond = 'rgba(255,255,255,0.2)'

    if (isLoading) {
        return (
            <Flex
                width="100%"
                height="100%"
                background="radial-gradient(#ffffff 40%, #dbe0e6 100%) rgb(238, 240, 244) !important"
            >
                <Center
                    flexDirection="column"
                    position="fixed"
                    w="100%"
                    h="100%"
                    pb={{
                        base: isMobileOnly && isIOS ? '85px' : '70px',
                        lg: '20px',
                    }}
                >
                    <Spinner
                        size="4xl"
                        color={fillColor}
                        style={{
                            borderBottomColor: emptyColorSecond,
                            borderLeftColor: emptyColor,
                        }}
                        emptyColor={emptyColor}
                        thickness="1px"
                        speed="0.8s"
                        boxSize="110px"
                    />
                    <PreflightIcon
                        style={{ marginTop: '-92px', zIndex: '1' }}
                        width="80px"
                        height="80px"
                    />
                </Center>
            </Flex>
        )
    }

    if (!isLoading && user) {
        return (
            <Flex
                width="100%"
                height="100%"
                background="radial-gradient(#ffffff 40%, #dbe0e6 100%) rgb(238, 240, 244) !important"
            >
                <Center
                    flexDirection="column"
                    position="fixed"
                    w="100%"
                    h="100%"
                    pb={{
                        base: isMobileOnly && isIOS ? '85px' : '70px',
                        lg: '20px',
                    }}
                >
                    <Spinner
                        size="4xl"
                        color={fillColor}
                        style={{
                            borderBottomColor: emptyColorSecond,
                            borderLeftColor: emptyColor,
                        }}
                        emptyColor={emptyColor}
                        thickness="1px"
                        speed="0.8s"
                        boxSize="110px"
                    />
                    <PreflightIcon
                        style={{ marginTop: '-92px', zIndex: '1' }}
                        width="80px"
                        height="80px"
                    />
                    <Text
                        fontSize="1rem"
                        fontWeight="500"
                        pt="40px"
                        mb="-40px"
                        color="gray.500"
                        height="30px"
                        letterSpacing="0.5px"
                    >
                        Redirecting.....
                    </Text>
                </Center>
            </Flex>
        )
    }
}
