import React from 'react'
import { useRecoilValue } from 'recoil'

import {
    layerSelectionState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../globalState'

import { useTier } from '../../../auth/hooks'
import SatelliteLayer from './Satellite'
import VNCLayer from './VNC'
import LinzTopoLayer from './LinzTopo'

export default function BaseLayers() {
    const layerSelection = useRecoilValue(layerSelectionState)
    const userTier = useTier()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    return (
        <>
            <SatelliteLayer />
            {!isPremium && <VNCLayer />}
            {layerSelection.constant.linztopo && !isPremium && (
                <LinzTopoLayer />
            )}
        </>
    )
}
