import React, { useEffect } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Flex, Text, Skeleton } from '@chakra-ui/react'
import { addHours, parseISO, formatISO } from 'date-fns'

import Slider from '../briefing-slider'

import formatDateTime from '../../../../../util/dateFormatter'
import {
    timeZoneState,
    selectedBriefingTimeState,
    mobileDrawerExpandedState,
} from '../../../../../globalState'

export default function TimeSelection(props) {
    const { briefingData, dataLoading } = props

    const timeZone = useRecoilValue(timeZoneState)
    const mobileDrawerExpanded = useRecoilState(mobileDrawerExpandedState)
    const [selectedHours, setSelectedHours] = useRecoilState(
        selectedBriefingTimeState
    )
    useEffect(() => {
        !selectedHours && setSelectedHours(48)
    }, [selectedHours, setSelectedHours])

    const issuedTime = briefingData && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)

    return (
        <Flex
            direction={'column'}
            w="100%"
            px={{
                base: '20px',
                md: '22px',
            }}
            minHeight={{
                base: mobileDrawerExpanded ? '96px' : '0px',
                md: '110px',
                lg: '120px',
            }}
        >
            <Flex w="100%" mt={3} mb={3} minHeight="34px">
                <Flex
                    fontWeight={{ base: '600', md: '700' }}
                    fontSize={{
                        base: '0.75rem',
                        md: '0.7rem',
                    }}
                    pr="8px"
                    pb={{ base: '0', md: '2px' }}
                    opacity="0.7"
                    minWidth="68px"
                    alignItems={{ base: 'center', md: 'flex-start' }}
                >
                    Period To:
                </Flex>
                <Flex
                    w={'100%'}
                    flexDirection="row"
                    justifyContent={{
                        base: 'space-between',
                        md: 'flex-end',
                    }}
                    alignItems="center"
                    flexWrap="wrap"
                >
                    {dataLoading ? (
                        <Skeleton
                            startColor="#F7F8F9"
                            endColor="#DBDEE1"
                            borderRadius="6px"
                            height="14px"
                            width="100%"
                            mt="1px"
                            mb="19px"
                        />
                    ) : (
                        <>
                            <Text
                                fontWeight={{
                                    base: '400',
                                    md: '500',
                                }}
                                fontSize={{
                                    base: '0.75rem',
                                    md: '0.7rem',
                                }}
                                textAlign="right"
                                paddingRight={{
                                    base: '7px',
                                    md: '0px',
                                }}
                            >
                                {periodTo &&
                                    formatDateTime(
                                        formatISO(periodTo),
                                        timeZone
                                    )}
                            </Text>
                            <Text
                                fontWeight="300"
                                fontSize={{
                                    base: '0.75rem',
                                    md: '0.7rem',
                                }}
                                textAlign="right"
                            >
                                (+ {selectedHours} Hours)
                            </Text>
                        </>
                    )}
                </Flex>
            </Flex>
            <Flex // TIMESLIDER FOR SELECTING BRIEFING RANGE
                transition="all ease 400ms"
                position={{ base: 'relative', md: 'relative' }}
                // mx="10px"
                mt={{ base: mobileDrawerExpanded ? '5px' : '0px', md: '8px' }}
                mb={{ base: mobileDrawerExpanded ? '15px' : '10px', md: '0px' }}
                height={{
                    base: mobileDrawerExpanded ? '30px' : '0px',
                    md: '30px',
                }}
                opacity={{
                    base: mobileDrawerExpanded ? '1' : '0',
                    md: '1',
                }}
                visibility={{
                    base: mobileDrawerExpanded ? 'visible' : 'hidden',
                    md: 'visible',
                }}
                w={{ base: '100%', md: '100%' }}
                borderRadius="15px"
                zIndex="1"
            >
                <Slider
                    baseColor={
                        'linear-gradient(to right, #00A697 5%, #02E6A5 80%)'
                    }
                    format={'+'}
                    min={1}
                    max={48}
                    ticks={[1, 12, 24, 36, 48]}
                    value={selectedHours}
                    setValue={setSelectedHours}
                />
            </Flex>
        </Flex>
    )
}
