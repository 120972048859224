export default function buildCtaLabels(
    featureCollection,
    selectedLowerAltitude,
    selectedUpperAltitude
) {
    return {
        type: 'FeatureCollection',
        features: featureCollection.features.map((feat) => {
            if (feat.properties.type === 'CTA_LABEL') {
                const ctaCount = feat.properties.airspaceid.length

                if (ctaCount === 1) {
                    feat.properties.label = `${feat.properties.class[0]}  LL ${feat.properties.lowerlabel[0]}  TM\n${feat.properties.csabbrev[0]} ${feat.properties.frequency[0]}  \n`
                    return feat
                }

                if (
                    selectedLowerAltitude <= 9500 &&
                    ctaCount > 1 &&
                    Math.min(...feat.properties.lwfilter) >= 9500
                ) {
                    feat.properties.label = `${
                        feat.properties.class[ctaCount - 1]
                    } LL ${feat.properties.lowerlabel[ctaCount - 1]} TM\n${
                        feat.properties.csabbrev[ctaCount - 1]
                    } ${feat.properties.frequency[ctaCount - 1]}\n`
                    return feat
                }

                if (ctaCount > 1) {
                    const series = new Array(ctaCount)
                        .fill(1)
                        .map((_, i) => (_ === 1 ? i : i + 1))

                    const ctas = series.map((i) => ({
                        designator: feat.properties.designator[i],
                        class: feat.properties.class[i],
                        lowerlabel: feat.properties.lowerlabel[i],
                        csabbrev: feat.properties.csabbrev[i],
                        frequency: feat.properties.frequency[i],
                        lowerfilter: feat.properties.lwfilter[i],
                        upperfilter: feat.properties.upfilter[i],
                    }))

                    feat.properties.label = ctas
                        .filter(
                            (cta) =>
                                (cta.lowerfilter < 9500 &&
                                    cta.lowerfilter <= selectedUpperAltitude &&
                                    cta.upperfilter >= selectedLowerAltitude) ||
                                (selectedLowerAltitude >= 9500 &&
                                    cta.upperfilter > 9500 &&
                                    cta.lowerfilter <= selectedUpperAltitude &&
                                    cta.upperfilter >= selectedLowerAltitude)
                        )
                        .filter((cta, i, arr) => {
                            const nextCta = arr[i + 1]

                            if (nextCta === undefined) {
                                return cta
                            }

                            if (
                                nextCta !== undefined &&
                                (cta.class !== nextCta.class ||
                                    (cta.csabbrev !== nextCta.csabbrev &&
                                        cta.frequency !== nextCta.frequency))
                            ) {
                                return cta
                            }

                            return null
                        })
                        .filter((cta) => cta)
                        .map(
                            (cta) =>
                                `${cta.class}  LL ${cta.lowerlabel}  TM\n${cta.csabbrev} ${cta.frequency}  \n`
                        )
                        .join('')
                    return feat
                }
            }

            return feat
        }),
    }
}
