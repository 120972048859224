import React, { useEffect, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { Flex, Text, Spinner, Link } from '@chakra-ui/react'
import { isIOS, isAndroid } from 'react-device-detect'
import { useUserTotpFactor } from '../../../auth/hooks'
import { QRCodeSVG } from 'qrcode.react'
import { userProfileState } from '../../../globalState'

export default function Authenticator({
    setTotpFactor,
    binding,
    setBinding,
    authenticatorSelected,
}) {
    const { isLoading, getAccessTokenSilently } = useAuth0()
    const userTotpFactor = useUserTotpFactor()

    const profileData = useRecoilValue(userProfileState)
    const recentlyVerifiedWithTotp = profileData && profileData.mfa_totp_set

    const getFactor = useCallback(async () => {
        if (authenticatorSelected) {
            const accessToken = await getAccessTokenSilently()
            fetch(`${window.location.origin}/mfa/verifyTOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => res.json())

                .then((data) => {
                    setBinding(data)
                    setTotpFactor(data.sid)
                })
        }
    }, [
        authenticatorSelected,
        getAccessTokenSilently,
        setTotpFactor,
        setBinding,
    ])

    useEffect(() => {
        if (!userTotpFactor && !recentlyVerifiedWithTotp) {
            getFactor()
        }
    }, [userTotpFactor, recentlyVerifiedWithTotp, getFactor])

    useEffect(() => {
        if (!authenticatorSelected) {
            setBinding(null)
        }
    }, [authenticatorSelected, setBinding])

    return (
        <Flex
            flexDirection="column"
            marginLeft="20px"
            marginRight="20px"
            alignItems="center"
            justifyContent="flex-start"
            minHeight={userTotpFactor ? '100px' : '340px'}
        >
            {(isLoading || (!binding && !userTotpFactor && !profileData)) && (
                <Spinner
                    mt="25px"
                    mb="30px"
                    color={'light.200'}
                    thickness="2px"
                    speed="0.45s"
                    boxSize="45px"
                />
            )}
            {!isLoading && userTotpFactor ? (
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text
                        pb="40px"
                        fontSize={{
                            base: '0.9rem',
                            md: '0.9rem',
                        }}
                        fontWeight="400"
                        color="gray.600"
                    >
                        Use a time-based one time verification code from your
                        chosen{' '}
                        <span style={{ fontWeight: '600' }}>
                            authenticator app
                        </span>{' '}
                        you previously set up for PreFlight
                    </Text>
                </Flex>
            ) : (
                !isLoading && (
                    <>
                        <Flex
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {!binding && (
                                <>
                                    <Text
                                        pt="10px"
                                        pb="25px"
                                        color="gray.500"
                                        fontSize="0.8rem"
                                    >
                                        Loading QR Code...
                                    </Text>
                                    <Spinner
                                        mt="15px"
                                        mb="40px"
                                        color={'light.200'}
                                        thickness="2px"
                                        speed="0.45s"
                                        boxSize="45px"
                                    />
                                </>
                            )}

                            <Flex
                                opacity={binding ? '1' : '0'}
                                visibility={binding ? 'visible' : 'hidden'}
                                transition="all ease 300ms"
                                color="gray.500"
                                fontSize="0.8rem"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {binding && (
                                    <>
                                        <Text
                                            pb="10px"
                                            fontSize={{
                                                base: '0.9rem',
                                                md: '0.9rem',
                                            }}
                                            fontWeight="400"
                                            color="gray.600"
                                        >
                                            Scan the QR Code below with your
                                            chosen Authenticator app, such as{' '}
                                            <Link
                                                color="light.200"
                                                fontWeight="600"
                                                href={
                                                    isIOS
                                                        ? 'https://apps.apple.com/us/app/google-authenticator/id388497605'
                                                        : isAndroid
                                                        ? 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
                                                        : 'https://www.google.com/search?q=google+authenticator'
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Google Authenticator
                                            </Link>{' '}
                                            or{' '}
                                            <Link
                                                color="light.200"
                                                fontWeight="600"
                                                href={
                                                    'https://www.microsoft.com/en-nz/security/mobile-authenticator-app'
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Microsoft Authenticator
                                            </Link>
                                        </Text>
                                        <Text pb="25px">
                                            Helpful when WiFi is available, but
                                            limited or no cellular connectivity
                                        </Text>
                                        {binding && binding.uri && (
                                            <Flex
                                                pb="25px"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <QRCodeSVG
                                                    value={binding.uri}
                                                />
                                            </Flex>
                                        )}
                                        <Text>
                                            Alternatively{' '}
                                            <Link
                                                color="light.200"
                                                fontWeight="600"
                                                href={binding && binding.uri}
                                            >
                                                click here
                                            </Link>{' '}
                                            to add to your Authenticator app
                                        </Text>
                                        <Text pt="15px">
                                            Or enter this code manually:
                                        </Text>
                                        <Text
                                            pt="5px"
                                            pb="25px"
                                            fontSize={{
                                                base: '0.85rem',
                                                xs: '0.9rem',
                                                sm: '0.95rem',
                                            }}
                                            letterSpacing={{
                                                base: '0px',
                                                xs: '0.2px',
                                                sm: '0.5px',
                                            }}
                                            fontWeight="500"
                                        >
                                            {binding && binding.secret}
                                        </Text>
                                        <Flex
                                            border="1px solid"
                                            borderColor="gray.300"
                                            bg="light.30"
                                            p="10px"
                                            mb="20px"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="column"
                                        >
                                            <Text
                                                fontWeight="600"
                                                fontSize="0.9rem"
                                            >
                                                IMPORTANT
                                            </Text>
                                            <Text pt="5px">
                                                After you click{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    SELECT
                                                </span>{' '}
                                                below you will be prompted to
                                                enter the 6-digit code displayed
                                                by your Authenticator app.
                                            </Text>
                                            <Text pt="5px">
                                                Failing to complete this process
                                                will leave your authenticator
                                                connection unverified. You will
                                                then need to remove it from your
                                                Authenticator app and enroll a
                                                new one.
                                            </Text>
                                            <Text pt="5px">
                                                Please make sure to complete the
                                                process for seamless access and
                                                security.
                                            </Text>
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        </Flex>
                    </>
                )
            )}
        </Flex>
    )
}
