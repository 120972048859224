import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { bearingToAzimuth } from '@turf/helpers'
import rhumbDistance from '@turf/rhumb-distance'
import centroid from '@turf/centroid'
import rhumbBearing from '@turf/rhumb-bearing'

const Constants = MapboxDraw.constants
const { doubleClickZoom } = MapboxDraw.lib

const RulerMode = { ...MapboxDraw.modes.draw_line_string }

const RulerStyle = [
    {
        id: 'gl-draw-ruler-vertex',
        type: 'circle',
        filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'mode', 'ruler'],
            ['!has', 'user_distance'],
        ],
        paint: {
            'circle-radius': 7,
            'circle-color': '#FF00FF',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#FFFFFF',
        },
    },
    {
        id: 'gl-draw-ruler-distance',
        type: 'symbol',
        filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'mode', 'ruler'],
            ['has', 'user_distance'],
        ],
        layout: {
            'text-field': [
                'concat',
                [
                    'to-string',
                    ['/', ['round', ['*', ['get', 'user_distance'], 100]], 100],
                ],
                ' NM / ',
                ['%', ['+', ['round', ['get', 'user_azimuth']], 360], 360],
                '°T',
            ],
            'text-size': 10,
            'text-offset': [
                'case',
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        0,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        22.5,
                    ],
                ],
                ['literal', [-1, 0]], //"n",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        22.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        67.5,
                    ],
                ],
                ['literal', [0, -1]], //"ne",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        67.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        112.5,
                    ],
                ],
                ['literal', [0, -1]], //"e",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        112.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        157.5,
                    ],
                ],
                ['literal', [1, 0]], //"se",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        157.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        180,
                    ],
                ],
                ['literal', [1, 0]], //"s",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        180,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        202.5,
                    ],
                ],
                ['literal', [-1, 0]], //"s",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        202.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        247.5,
                    ],
                ],
                ['literal', [0, -1]], //"sw",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        247.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        292.5,
                    ],
                ],
                ['literal', [0, -1]], //"w",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        292.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        337.5,
                    ],
                ],
                ['literal', [1, 0]], //"nw",
                [
                    'all',
                    [
                        '>=',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        337.5,
                    ],
                    [
                        '<',
                        [
                            '%',
                            ['+', ['round', ['get', 'user_azimuth']], 360],
                            360,
                        ],
                        360,
                    ],
                ],
                ['literal', [1, 0]], //"n",
                ['literal', [0, -1]],
            ],
            'text-rotate': [
                'case',
                ['>', ['get', 'user_azimuth'], 180],
                ['-', ['get', 'user_azimuth'], 270],
                ['-', ['get', 'user_azimuth'], 90],
            ],
            'text-rotation-alignment': 'map',
            'text-allow-overlap': true,
            'text-optional': false,
        },
    },
    {
        id: 'gl-draw-ruler-active',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'user_active', true],
            ['==', 'mode', 'ruler'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#FF00FF',
            'line-dasharray': [0.5, 2],
            'line-width': 3,
        },
    },
    {
        id: 'gl-draw-ruler-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'user_active', false],
            ['==', 'mode', 'ruler'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#FF00FF',
            'line-width': 2,
        },
    },
]

const dragPan = {
    enable: (ctx) => {
        setTimeout(() => {
            // First check we've got a map and some context.
            if (
                !ctx.map ||
                !ctx.map.dragPan ||
                !ctx._ctx ||
                !ctx._ctx.store ||
                !ctx._ctx.store.getInitialConfigValue
            ) {
                return
            }
            // Now check initial state wasn't false (we leave it disabled if so)
            if (!ctx._ctx.store.getInitialConfigValue('dragPan')) return
            ctx.map.dragPan.enable()
        }, 0)
    },
    disable(ctx) {
        setTimeout(() => {
            if (!ctx.map || !ctx.map.dragPan) return
            // Always disable here, as it's necessary in some cases.
            ctx.map.dragPan.disable()
        }, 0)
    },
}

const touchZoomRotate = {
    enable: (ctx) => {
        setTimeout(() => {
            // First check we've got a map and some context.
            if (
                !ctx.map ||
                !ctx.map.touchZoomRotate ||
                !ctx._ctx ||
                !ctx._ctx.store ||
                !ctx._ctx.store.getInitialConfigValue
            ) {
                return
            }
            // Now check initial state wasn't false (we leave it disabled if so)
            if (!ctx._ctx.store.getInitialConfigValue('touchZoomRotate')) return
            ctx.map.touchZoomRotate.enable()
        }, 0)
    },
    disable(ctx) {
        setTimeout(() => {
            if (!ctx.map || !ctx.map.touchZoomRotate) return
            // Always disable here, as it's necessary in some cases.
            ctx.map.touchZoomRotate.disable()
        }, 0)
    },
}

RulerMode.onSetup = function (opts) {
    this.clearSelectedFeatures()
    doubleClickZoom.disable(this)
    dragPan.disable(this)
    touchZoomRotate.disable(this)
    //disable clicking of aerodrome icons
    this.updateUIClasses({ mouse: Constants.cursors.ADD })

    var leg = {}
    leg = this.newFeature({
        type: 'Feature',
        properties: {
            active: true,
        },
        geometry: {
            type: 'LineString',
            coordinates: [],
        },
    })
    this.addFeature(leg)

    var distance = {}
    distance = this.newFeature({
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Point',
            coordinates: [],
        },
    })
    this.addFeature(distance)

    return {
        legs: [leg],
        distances: [distance],
        lastClick: null,
        keyPress: null,
    }
}

// Whenever a user clicks on the map, Draw will call `onClick` Expand this to setInnerHtml of div when it has been clicked.
RulerMode.onClick = function (state, e) {
    // simulate double clicking to end drawing
    // if last click was less than 300ms ago
    if (state.lastClick && Date.now() - state.lastClick < 300) {
        return this.changeMode('static')
    }
    state.lastClick = Date.now()

    const clickedCoords = [e.lngLat.lng, e.lngLat.lat]

    const vertexPoint = this.newFeature({
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Point',
            coordinates: [],
        },
    })

    vertexPoint.coordinates = clickedCoords
    this.addFeature(vertexPoint)

    const lastLeg = state.legs[state.legs.length - 1]

    // add first point to first line
    if (lastLeg.coordinates.length === 0) {
        lastLeg.addCoordinate(
            lastLeg.coordinates.length,
            clickedCoords[0],
            clickedCoords[1]
        )
    } else {
        // finish off last line, and make new line with start coord of last line

        // add end coord
        lastLeg.addCoordinate(
            lastLeg.coordinates.length,
            clickedCoords[0],
            clickedCoords[1]
        )

        // remove coord from mouseMove (if exists)
        if (lastLeg.coordinates.length > 2) {
            lastLeg.removeCoordinate(2)
        }

        // set prev leg to inactive
        lastLeg.properties.active = false

        const newLeg = this.newFeature({
            type: 'Feature',
            properties: {
                active: true,
            },
            geometry: {
                type: 'LineString',
                coordinates: [],
            },
        })
        newLeg.addCoordinate(0, clickedCoords[0], clickedCoords[1])
        this.addFeature(newLeg)
        state.legs.push(newLeg)

        const newDistance = this.newFeature({
            type: 'Feature',
            properties: {
                active: true,
            },
            geometry: {
                type: 'Point',
                coordinates: [],
            },
        })
        newDistance.coordinates = clickedCoords
        this.addFeature(newDistance)
        state.distances.push(newDistance)
    }
}

RulerMode.onTrash = function (state) {
    this.deleteFeature([state.rectangle.id], {
        silent: true,
    })
    this.changeMode('static')
}

RulerMode.onMouseMove = function (state, e) {
    this.updateUIClasses({ mouse: 'add' })

    // only do things if already clicked to start drawing the line
    if (state.legs[0].coordinates.length > 0) {
        const mouseCoord = [e.lngLat.lng, e.lngLat.lat]
        const lastLeg = state.legs[state.legs.length - 1]

        lastLeg.updateCoordinate(1, ...mouseCoord)

        const lastLegLength = rhumbDistance(
            lastLeg.coordinates[0],
            lastLeg.coordinates[1],
            { units: 'nauticalmiles' }
        )
        const lastLegAzimuth = bearingToAzimuth(
            rhumbBearing(lastLeg.coordinates[0], lastLeg.coordinates[1])
        )

        lastLeg.properties.length = lastLegLength
        lastLeg.properties.lengthuom = 'NM'

        const lastDistance = state.distances[state.distances.length - 1]

        lastDistance.updateCoordinate(...centroid(lastLeg).geometry.coordinates)

        lastDistance.properties.distance = lastLegLength
        lastDistance.properties.distanceuom = 'NM'
        lastDistance.properties.azimuth = lastLegAzimuth
    }

    const totalDistance = state.distances
        .map((feat) => feat.properties.distance)
        .reduce((a, b) => {
            return a + b
        })

    if (
        totalDistance > 0 &&
        document.getElementsByClassName('combined-distances').length > 0
    ) {
        document.getElementsByClassName(
            'combined-distances'
        )[0].innerHTML = `${totalDistance.toFixed(2)} NM total`
    }
}

// Whenever a user clicks on a key while focused on the map, it will be sent here
RulerMode.onKeyUp = function (state, e) {
    // Enter Key
    if (e.keyCode === 13) {
        state.keyPress = 'Enter'
        return this.changeMode('static')
    }
    // Esc Key
    if (e.keyCode === 27) {
        state.keyPress = 'Esc'
        return this.changeMode('static')
    }
}
RulerMode.onMouseDown = function (state, e) {
    // on first click, save clicked point coords as starting for  rectangle
    const startPoint = [e.lngLat.lng, e.lngLat.lat]
    state.startPoint = startPoint
}

RulerMode.onMouseUp = function (state, e) {
    this.onClick(state, e)
}
// support mobile taps
RulerMode.onTouchStart = function (state, e) {
    this.onMouseDown(state, e)
}

RulerMode.onTouchMove = function (state, e) {
    this.onMouseMove(state, e)
}

RulerMode.onDrag = function (state, e) {
    this.onMouseMove(state, e)
}

RulerMode.onTouchEnd = function (state, e) {
    this.onMouseUp(state, e)
    this.onMouseMove(state, e)
}

RulerMode.onTap = function (state, e) {
    this.onMouseMove(state, e)
    this.onClick(state, e)
}

// This is the only required function for a mode.
// It decides which features currently in Draw's data store will be rendered on the map.
// All features passed to `display` will be rendered, so you can pass multiple display features per internal feature.
// See `styling-draw` in `API.md` for advice on making display features
RulerMode.toDisplayFeatures = function (state, geojson, display) {
    display(geojson)
}

RulerMode.onStop = function (state) {
    doubleClickZoom.enable(this)
    dragPan.enable(this)
    touchZoomRotate.enable(this)
    this.updateUIClasses({ mouse: 'none' })

    // pass through a bogus feature to cancel draw mode
    if (state.keyPress === 'Esc') {
        this.map.fire('draw.create', {
            features: [{ properties: { cancel: true }, geometry: {} }],
        })
    }
}

export { RulerMode, RulerStyle }
