import React from 'react'
import WXRasterTiles from './components/WXRasterTiles'

export default function satelliteCloudInfrared() {
    return (
        <WXRasterTiles
            layerId={'satelliteCloudInfrared'}
            timesURL={
                'https://api.wxtiles.com/v1/wxtiles/layer/jma-himawari-fulldisk-ir/instance/him8-disk_2km_ir/'
            }
            rasterURL={
                'https://api.wxtiles.com/v1/wxtiles/tile/jma-himawari-fulldisk-ir/infrared/him8-disk_2km_ir/'
            }
            limit={-9}
        />
    )
}
