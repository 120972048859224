import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function BriefIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M6.75 9.5C6.33579 9.5 6 9.83579 6 10.25C6 10.6642 6.33579 11 6.75 11H17.25C17.6642 11 18 10.6642 18 10.25C18 9.83579 17.6642 9.5 17.25 9.5H6.75Z"
                fill="currentColor"
            />
            <path
                d="M6 13.75C6 13.3358 6.33579 13 6.75 13H14.25C14.6642 13 15 13.3358 15 13.75C15 14.1642 14.6642 14.5 14.25 14.5H6.75C6.33579 14.5 6 14.1642 6 13.75Z"
                fill="currentColor"
            />
            <path
                d="M6.75 16.5C6.33579 16.5 6 16.8358 6 17.25C6 17.6642 6.33579 18 6.75 18H11.25C11.6642 18 12 17.6642 12 17.25C12 16.8358 11.6642 16.5 11.25 16.5H6.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8293 4H18C19.6569 4 21 5.34315 21 7V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V7C3 5.34315 4.34315 4 6 4H9.17071C9.58254 2.83481 10.6938 2 12 2C13.3062 2 14.4175 2.83481 14.8293 4ZM17 7.5V5.5H18C18.8284 5.5 19.5 6.17157 19.5 7V19C19.5 19.8284 18.8284 20.5 18 20.5H6C5.17157 20.5 4.5 19.8284 4.5 19V7C4.5 6.17157 5.17157 5.5 6 5.5H7V7.5H17ZM13 5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
