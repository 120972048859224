import React, { useState, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer } from 'react-mapbox-gl'

import {
    layerSelectionState,
    VectorLayerRefreshInfo,
} from '../../../globalState'

export default function WebcamsLayer() {
    const layerSelection = useRecoilValue(layerSelectionState)
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    const [hoveredId, setHoveredId] = useState(null)

    const onHover = (e) => {
        setHoveredId(e.features[0].properties.id)
    }

    const offHover = useCallback((e) => {
        setHoveredId(null)
    }, [])

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.0,
        3,
        0.1,
        4,
        0.3,
        5,
        0.5,
        6,
        0.5,
        7,
        0.6,
        8,
        0.6,
        9,
        0.7,
        10,
        0.7,
        11,
        0.6,
        12,
        0.6,
    ]

    return (
        <GeoJSONLayer
            id="webcams"
            // before="aerodromes-symbol"
            data={layerData.webcams.dataURL}
            symbolOnMouseEnter={onHover}
            symbolOnMouseLeave={offHover}
            symbolLayout={{
                visibility: layerSelection.met.webcams ? 'visible' : 'none',
                'symbol-sort-key': ['to-number', ['get', 'order']],
                'symbol-z-order': 'source',
                'icon-image': 'camera',
                'icon-size': zoomLevelDefinition,
                'icon-allow-overlap': true,
                'icon-ignore-placement': false,
                'icon-optional': false,
                'text-field': [
                    'match',
                    ['get', 'id'],
                    hoveredId || 'null',
                    ['get', 'name'],
                    '',
                ],
                'text-font': [
                    'Roboto Medium',
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold',
                ],
                'text-offset': [1.25, -1.25],
                'text-anchor': 'left',
                'text-letter-spacing': 0.025,
                'text-size': 12,
                'text-allow-overlap': true,
                'text-ignore-placement': true,
            }}
            symbolPaint={{
                'text-halo-color': '#fff',
                'text-halo-width': 1,
                'text-color': '#10576d',
            }}
        />
    )
}
