import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useInView } from 'react-intersection-observer'
import {
    Flex,
    Box,
    Text,
    Button,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Portal,
    useBreakpointValue,
    Tooltip,
} from '@chakra-ui/react'
import { isMobile, isDesktop } from 'react-device-detect'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import useConfirm from '../../../alerts/warning-message/components/useConfirm'
import { ExcludedAWSWarningMessage } from '../../../alerts/warning-message/components/WarningMessages'
import {
    excludedAwsState,
    inViewPositionState,
    aerodromesSortState,
    collapseDropdownsState,
    firstCloseState,
    timeZoneState,
} from '../../../../../globalState'

import CloseIcon from '../../../../../icons/menu-icons/CloseIcon'

import { ReactComponent as AwsIcon } from '../../../../../icons/assets/aws.svg'
import AWSBriefingContent from './AWSBriefingContent'

export default function AWSBriefing(props) {
    const { briefingData } = props

    const timeZone = useRecoilValue(timeZoneState)
    const awsSort = useRecoilValue(aerodromesSortState) // sort aerodromes and aws
    const [excludedAWS, setExcludedAWS] = useRecoilState(excludedAwsState)
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const [firstClose, setFirstClose] = useRecoilState(firstCloseState)
    const { isConfirmed } = useConfirm() // alert message handler

    const collapseDropdowns = useRecoilValue(collapseDropdownsState)

    // AWS Data
    const aws = briefingData && briefingData.res && briefingData.res.aws

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize } = useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })

    const filteredDesignators =
        aws && aws.map((value) => excludedAWS.includes(value.designator))
    const filteredItems =
        filteredDesignators && filteredDesignators.includes(false)

    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : aws && aws.map((value, i) => i)
    )

    useEffect(() => {
        setExpandedDropdowns(
            collapseDropdowns ? [] : aws && aws.map((value, i) => i)
        )
        // eslint-disable-next-line
    }, [collapseDropdowns])

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()

    const metarNotes = useBreakpointValue({
        base: { containerRef: mobile },
        lg: { containerRef: desktop },
    })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        inView && setInViewPosition('awsContainer')
    }, [inView, setInViewPosition])

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    // useEffect(() => {
    //     console.log(aws, 'aws')
    // }, [aws])

    return (
        <>
            <div id="awsContainer" style={{ marginTop: '1px' }} ref={ref}></div>
            <Box zIndex="10">
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', md: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    marginTop="25px"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#476498"
                        backgroundImage="linear-gradient(165deg, rgb(41, 73, 113) 0%, rgb(85, 129, 197) 90%)"
                        filter={{
                            base: isMobile && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="44px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="space-between"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Flex
                                my="auto"
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex marginLeft="2px" alignItems="center">
                                    <AwsIcon
                                        width="24px"
                                        height="24px"
                                        color="white"
                                    />
                                </Flex>
                                <Text
                                    paddingLeft="15px"
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    AUTOMATIC WEATHER STATIONS
                                </Text>
                            </Flex>
                            <Box ml="auto" ref={desktop}></Box>
                        </Flex>
                    </Flex>
                    {filteredItems && (
                        <>
                            <Portal
                                style={{
                                    height: '100%',
                                }}
                                {...metarNotes}
                            >
                                <Flex
                                    height="28px"
                                    transition="all ease 600ms"
                                    justifyContent="center"
                                    alignItems={{
                                        base: 'center',
                                        lg: 'center',
                                    }}
                                    flexDirection={{
                                        base: 'row',
                                        xs: 'row',
                                        lg: 'row',
                                    }}
                                    position="relative"
                                    width="100%"
                                    borderBottom={{
                                        base: '1px solid #edeeef',
                                        xs: 'none',
                                        sm: 'none',
                                    }}
                                    gap={{
                                        base: '5px',
                                        xs: '8px',
                                        sm: '10px',
                                    }}
                                    color={{
                                        base: 'light.400',
                                        lg: 'whiteAlpha.800',
                                    }}
                                    bg={{ base: 'light.20', lg: 'none' }}
                                >
                                    <Text
                                        opacity={timeZone !== 'UTC' ? '1' : '0'}
                                        visibility={
                                            timeZone !== 'UTC'
                                                ? 'visible'
                                                : 'hidden'
                                        }
                                        width={
                                            timeZone !== 'UTC' ? '142px' : '0px'
                                        }
                                        fontSize={
                                            timeZone !== 'UTC' ? '11px' : '0px'
                                        }
                                        textAlign="end"
                                        whiteSpace="pre-wrap"
                                        transition="all ease 600ms"
                                    >
                                        Time references are in UTC.
                                    </Text>

                                    <Text
                                        textAlign="end"
                                        whiteSpace="pre-wrap"
                                        fontSize="11px"
                                    >
                                        All heights in feet AGL.
                                    </Text>
                                    <Text
                                        textAlign="end"
                                        whiteSpace="pre-wrap"
                                        fontSize="11px"
                                    >
                                        Wind: °True
                                    </Text>
                                </Flex>
                            </Portal>
                            <Box ref={mobile}></Box>
                        </>
                    )}

                    <Accordion
                        border="none"
                        allowToggle
                        allowMultiple
                        index={expandedDropdowns}
                        onChange={(expandedIndex) => {
                            setExpandedDropdowns(expandedIndex)
                        }}
                    >
                        {aws &&
                            aws
                                .filter(
                                    (key) =>
                                        key.data ||
                                        (key.data?.obs_timestamp && // ensure there is more data other than just the timestamp
                                            Object.keys(key.data)?.length > 1)
                                )
                                .sort((a, b) =>
                                    awsSort
                                        ? a.name > b.name
                                        : a.lat < b.lat
                                        ? 1
                                        : -1
                                )
                                .map((aws, index, arr) => {
                                    if (excludedAWS.includes(aws.designator)) {
                                        return null
                                    } else {
                                        return (
                                            <Flex
                                                width="100%"
                                                key={`AWS${index}`}
                                            >
                                                <AccordionItem
                                                    width="100%"
                                                    borderStyle="none"
                                                    index="1"
                                                    zIndex={
                                                        arr.length + 1 - index
                                                    }
                                                    position="relative"
                                                >
                                                    {({ isExpanded }) => (
                                                        <>
                                                            <Flex
                                                                boxShadow={
                                                                    isExpanded &&
                                                                    '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                                }
                                                            >
                                                                <AccordionButton
                                                                    zIndex="2"
                                                                    height={{
                                                                        base: '50px',
                                                                        lg: '46px',
                                                                    }}
                                                                    minHeight={{
                                                                        base: '50px',
                                                                        lg: '46px',
                                                                    }}
                                                                    sx={{
                                                                        clipPath:
                                                                            'inset(0px 0px -50px 0px)',
                                                                    }}
                                                                    pl="1.2rem"
                                                                    pr={{
                                                                        base: 1,
                                                                        sm: 2,
                                                                        md: 1,
                                                                        ml: 2,
                                                                    }}
                                                                    _hover={{
                                                                        background:
                                                                            isExpanded
                                                                                ? 'light.90'
                                                                                : 'light.30',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'gray.100',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    color={
                                                                        '#1778b7'
                                                                    }
                                                                    background={
                                                                        isExpanded
                                                                            ? 'light.25'
                                                                            : 'white'
                                                                    }
                                                                    opacity={
                                                                        '1'
                                                                    }
                                                                    borderTop={
                                                                        '1px solid'
                                                                    }
                                                                    borderColor="#efefef"
                                                                >
                                                                    <Flex
                                                                        flex="2"
                                                                        textAlign="left"
                                                                        whiteSpace="pre"
                                                                        justifyContent="space-between"
                                                                        alignItems="center"
                                                                        width="100%"
                                                                        pl="5px"
                                                                        pr="10px"
                                                                    >
                                                                        <Text
                                                                            fontSize={
                                                                                adjustLabelFontSize
                                                                            }
                                                                            lineHeight="1.2"
                                                                            fontWeight={
                                                                                isExpanded
                                                                                    ? '600'
                                                                                    : '500'
                                                                            }
                                                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                            whiteSpace="pre-line"
                                                                        >
                                                                            {
                                                                                aws.name
                                                                            }
                                                                        </Text>
                                                                        <Text
                                                                            minWidth={{
                                                                                base: '44px',
                                                                                sm: '48px',
                                                                                lg: '52px',
                                                                            }}
                                                                            pl="5px"
                                                                            fontSize={
                                                                                adjustLabelFontSize
                                                                            }
                                                                            lineHeight="1.2"
                                                                            fontWeight="400"
                                                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                            whiteSpace="pre-line"
                                                                            textAlign="right"
                                                                        >
                                                                            {
                                                                                aws.designator
                                                                            }
                                                                        </Text>
                                                                    </Flex>

                                                                    <Flex>
                                                                        {isExpanded ? (
                                                                            <AccordionIcon transform="rotate(0deg)" />
                                                                        ) : (
                                                                            <AccordionIcon transform="rotate(-90deg)" />
                                                                        )}
                                                                    </Flex>
                                                                </AccordionButton>

                                                                <Tooltip
                                                                    isDisabled={
                                                                        isMobile ||
                                                                        !isDesktop
                                                                    }
                                                                    label={`Remove ${aws.name} from the briefing`}
                                                                    hasArrow
                                                                    borderRadius="7px"
                                                                    height="25px"
                                                                    fontSize="0.7rem"
                                                                    alignItems="center"
                                                                    display="flex"
                                                                    placement="left"
                                                                    m="0px"
                                                                    color="light.10"
                                                                    bgColor={
                                                                        'gray.500'
                                                                    }
                                                                >
                                                                    <Button
                                                                        zIndex="1"
                                                                        clipPath="inset(0px 0px -50px 0px)"
                                                                        color={
                                                                            'gray.300'
                                                                        }
                                                                        background={
                                                                            isExpanded
                                                                                ? 'light.25'
                                                                                : 'white'
                                                                        }
                                                                        variant="unstyled"
                                                                        fontSize="11px"
                                                                        fontWeight="600"
                                                                        height={{
                                                                            base: '50px',
                                                                            lg: '46px',
                                                                        }}
                                                                        width={{
                                                                            base: '60px',
                                                                            lg: '64px',
                                                                        }}
                                                                        borderTop={
                                                                            '1px solid'
                                                                        }
                                                                        borderColor="#efefef"
                                                                        justifyContent="initial"
                                                                        py="2px"
                                                                        borderRadius="0px"
                                                                        {...noFocus}
                                                                        onClick={async () => {
                                                                            if (
                                                                                firstClose
                                                                            ) {
                                                                                const acknowledgeExcludedAws =
                                                                                    await isConfirmed(
                                                                                        // "Excluded AWS?"
                                                                                        'confirm-ok',
                                                                                        <ExcludedAWSWarningMessage />
                                                                                    )
                                                                                if (
                                                                                    acknowledgeExcludedAws
                                                                                ) {
                                                                                    setExcludedAWS(
                                                                                        (
                                                                                            prevState
                                                                                        ) =>
                                                                                            prevState.concat(
                                                                                                aws.designator
                                                                                            )
                                                                                    )
                                                                                    setFirstClose(
                                                                                        false
                                                                                    )
                                                                                }
                                                                            } else {
                                                                                setExcludedAWS(
                                                                                    (
                                                                                        prevState
                                                                                    ) =>
                                                                                        prevState.concat(
                                                                                            aws.designator
                                                                                        )
                                                                                )
                                                                            }
                                                                        }}
                                                                        _active={{
                                                                            background:
                                                                                'gray.100',
                                                                        }}
                                                                        _focus={{
                                                                            background:
                                                                                'light.30',
                                                                        }}
                                                                        _hover={{
                                                                            color: 'gray.500',
                                                                            background:
                                                                                isExpanded
                                                                                    ? 'light.90'
                                                                                    : 'light.30',
                                                                        }}
                                                                    >
                                                                        <CloseIcon boxSize="20px" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Flex>

                                                            <AccordionPanel
                                                                pt={0}
                                                                pb={5}
                                                                pl="1.5rem"
                                                                pr="1.5rem"
                                                                boxShadow={
                                                                    index +
                                                                        1 ===
                                                                    arr.length
                                                                        ? 'none'
                                                                        : 'inset 0px -5px 15px 0px rgba(0,0,0,0.04)'
                                                                }
                                                            >
                                                                <AWSBriefingContent
                                                                    briefingData={
                                                                        briefingData
                                                                    }
                                                                    aws={aws}
                                                                />
                                                            </AccordionPanel>
                                                        </>
                                                    )}
                                                </AccordionItem>
                                            </Flex>
                                        )
                                    }
                                })}
                    </Accordion>
                    {aws && !filteredItems && (
                        <Flex
                            minHeight="80px"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            px="15px"
                            py="20px"
                            lineHeight="1.4"
                            gap={{ base: '10px', sm: '5px' }}
                        >
                            <Text
                                fontSize={calculateFontSize(0.8)}
                                fontWeight="500"
                                color="light.100"
                                textAlign="center"
                            >
                                All weather stations with weather information
                                have been excluded from the briefing
                            </Text>
                            <Text
                                fontSize={calculateFontSize(0.8)}
                                fontWeight="400"
                                color="gray.500"
                                opacity="0.8"
                                textAlign="center"
                            >
                                Reinstate weather stations from the Excluded
                                Items list
                            </Text>
                        </Flex>
                    )}
                    {!aws && (
                        <Flex
                            minHeight="80px"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            px="15px"
                            py="20px"
                            lineHeight="1.4"
                            gap={{ base: '10px', sm: '5px' }}
                        >
                            <Text
                                fontSize={calculateFontSize(0.8)}
                                fontWeight="500"
                                color="light.100"
                                textAlign="center"
                            >
                                There is no AWS information available for the
                                selected areas
                            </Text>
                        </Flex>
                    )}
                </Box>
            </Box>
        </>
    )
}
