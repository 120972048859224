import React, { useState, useEffect, useRef } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import {
    Flex,
    Box,
    IconButton,
    Button,
    Slider,
    SliderTrack,
    SliderThumb,
    SliderFilledTrack,
    useBreakpointValue,
} from '@chakra-ui/react'
import { BsZoomIn, BsZoomOut } from 'react-icons/bs'
import { ReactComponent as RecenterIcon } from '../../../../../../icons/assets/recenter.svg'

export default function ZoomWrapper({
    children,
    webcamsModal,
    minimiseOnZoom,
    setMinimiseOnZoom,
    aerodromesFullscreen,
    webcamsModalFullscreen,
    loaded,
}) {
    // setting default value
    const defaultValue = useBreakpointValue({
        base: 1.4,
        xl: webcamsModal ? 1.3 : 1.4,
    })
    const fullscreen = webcamsModalFullscreen || aerodromesFullscreen
    const initialScale = fullscreen ? 1.00001 : defaultValue
    const minScale = 1
    const maxScale = 2.5
    const [scale, setScale] = useState(initialScale)
    const [isZoomed, setIsZoomed] = useState(false)

    useEffect(() => {
        const handler = (e) => e.preventDefault()
        document.addEventListener('gesturestart', handler)
        document.addEventListener('gesturechange', handler)
        document.addEventListener('gestureend', handler)
        return () => {
            document.removeEventListener('gesturestart', handler)
            document.removeEventListener('gesturechange', handler)
            document.removeEventListener('gestureend', handler)
        }
    }, [])

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: fullscreen ? 1.00001 : defaultValue,
        rotateZ: 0,
    }))

    const ref = useRef(null)
    const contentRef = useRef(null)
    const minimiseOnZoomRef = useRef(minimiseOnZoom)
    const defaultValueRef = useRef(defaultValue)

    const aspectRatio = { width: 272, height: 135 }

    function disableY() {
        const zoomContainer = ref.current.getBoundingClientRect()
        const imageContainer = contentRef.current.getBoundingClientRect()
        const calculatedHeight =
            (imageContainer.width / aspectRatio.width) * aspectRatio.height
        return calculatedHeight < zoomContainer.height
    }

    function disableX() {
        const zoomContainer = ref.current.getBoundingClientRect()
        const imageContainer = contentRef.current.getBoundingClientRect()
        return imageContainer.width < zoomContainer.width
    }

    useGesture(
        {
            onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
                if (pinching) return cancel()

                if (disableY()) {
                    api.start({ x })
                } else if (disableX()) {
                    api.start({ y })
                } else {
                    api.start({ x, y })
                }
            },
            onDragEnd: () => {
                const zoomContainer = ref.current.getBoundingClientRect()
                const imageContainer =
                    contentRef.current.getBoundingClientRect()
                const newXOffset =
                    (imageContainer.width - zoomContainer.width) / 2

                if (imageContainer.left > zoomContainer.left) {
                    api.start({ x: 0 + newXOffset })
                } else if (imageContainer.right < zoomContainer.right) {
                    api.start({
                        x:
                            -(imageContainer.width - zoomContainer.width) +
                            newXOffset,
                    })
                }

                const calculatedHeight =
                    (imageContainer.width / aspectRatio.width) *
                    aspectRatio.height

                if (!disableY()) {
                    if (imageContainer.top > zoomContainer.top) {
                        api.start({ y: 0 })
                    } else if (
                        calculatedHeight > zoomContainer.height &&
                        imageContainer.bottom < zoomContainer.bottom
                    ) {
                        api.start({
                            y: -(calculatedHeight - zoomContainer.height),
                        })
                    }
                }
            },
            onPinch: ({
                origin: [ox, oy],
                first,
                movement: [ms],
                offset: [s, a],
                memo,
            }) => {
                const zoomContainer = ref.current.getBoundingClientRect()
                const imageContainer =
                    contentRef.current.getBoundingClientRect()
                const newXOffset =
                    (imageContainer.width - zoomContainer.width) / 2

                if (first) {
                    const { width, height, x, y } =
                        ref.current.getBoundingClientRect()
                    const tx = ox - (x + width / 2)
                    const ty = oy - (y + height / 2)
                    memo = [style.x.get(), style.y.get(), tx, ty]
                }

                const x = memo[0] - (ms - 1) * memo[2]
                const y = memo[1] - (ms - 1) * memo[3]
                const adjustedScale = s + (initialScale - 1)

                let xAxis = x
                if (imageContainer.left > zoomContainer.left) {
                    xAxis = 0 + newXOffset
                } else if (imageContainer.right < zoomContainer.right) {
                    xAxis =
                        -(imageContainer.width - zoomContainer.width) +
                        newXOffset
                }

                const calculatedHeight =
                    (imageContainer.width / aspectRatio.width) *
                    aspectRatio.height

                let yAxis = y
                if (imageContainer.top > zoomContainer.top) {
                    yAxis = 0
                } else if (
                    calculatedHeight > zoomContainer.height &&
                    imageContainer.bottom < zoomContainer.bottom
                ) {
                    yAxis = -(calculatedHeight - zoomContainer.height)
                }

                if (disableY()) {
                    api.start({ scale: adjustedScale, x: xAxis, y: 0 })
                } else if (disableX()) {
                    api.start({ scale: adjustedScale, x: 0, y: yAxis })
                } else {
                    api.start({ scale: adjustedScale, x: xAxis, y: yAxis })
                }
                setScale(adjustedScale)
                return memo
            },
        },
        {
            target: ref,
            drag: { from: () => [style.x.get(), style.y.get()] },
            pinch: {
                scaleBounds: {
                    min: minScale - (initialScale - 1),
                    max: maxScale,
                },
                rubberband: true,
            },
        }
    )

    const onScroll = (e) => {
        if (scale > minScale && scale < maxScale) {
            const delta = e.deltaY * -0.001
            let newScale = scale + delta
            if (newScale <= minScale) {
                newScale = minScale + 0.002
            } else if (newScale >= maxScale) {
                newScale = maxScale - 0.002
            }
            // Keep this incase we want to experiment with zoom to
            // const ratio = 1 - newScale / scale
            // setPos({
            //     x: pos.x + (e.clientX - pos.x) * ratio,
            //     y: pos.y + (e.clientY - pos.y) * ratio,
            // })

            if (disableX) {
                api.start({ scale: newScale, x: 0 })
            } else if (disableY) {
                api.start({ scale: newScale, y: 0 })
            } else {
                api.start({ scale: newScale })
            }
            setScale(newScale)
        }
    }

    const zoomOut = () => {
        api.start({ scale: scale - 0.1 })
        setScale((s) => s - 0.1)
    }

    const zoomIn = () => {
        api.start({ scale: scale + 0.1 })
        setScale((s) => s + 0.1)
    }

    const resetZoom = () => {
        api.start({ x: 0, y: 0, scale: initialScale, rotateZ: 0 })
        setScale(initialScale)
    }

    function handleSliderChange(v) {
        const computedScale = v / 1000
        api.start({ scale: computedScale })
        setScale(computedScale)
    }

    useEffect(() => {
        let newMinimiseOnZoom
        if (aerodromesFullscreen) {
            newMinimiseOnZoom = scale > 1.1
        } else {
            newMinimiseOnZoom = scale > defaultValueRef.current + 0.05
        }
        if (newMinimiseOnZoom !== minimiseOnZoom) {
            setMinimiseOnZoom(newMinimiseOnZoom)
        }
        const resetZoom =
            (fullscreen
                ? scale < 1.0
                : scale < defaultValueRef.current - 0.05) ||
            (fullscreen ? scale > 1.05 : scale > defaultValueRef.current + 0.05)

        if (resetZoom !== isZoomed) {
            setIsZoomed(resetZoom)
        }
        minimiseOnZoomRef.current = minimiseOnZoom
        defaultValueRef.current = defaultValue
    }, [
        scale,
        minimiseOnZoom,
        fullscreen,
        aerodromesFullscreen,
        setMinimiseOnZoom,
        isZoomed,
        defaultValue,
    ])

    // when expanding Modal to fullscreen
    useEffect(() => {
        if (fullscreen) {
            api.start({ scale: 1.0001 })
            setScale(1.0001)
        }
        if (!fullscreen) {
            api.start({ x: 0, y: 0, scale: initialScale, rotateZ: 0 })
            setScale(initialScale)
        }
    }, [fullscreen, api, initialScale])

    const recenterSize = useBreakpointValue({
        base: '22px',
        ml: '22px',
    })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <>
            <Flex
                className="zoomWrapper"
                px={{
                    base: minimiseOnZoom ? '20px' : '5px',
                    md: '0px',
                }}
                transition="all ease 400ms"
                w={{
                    base: 'auto',
                    md: !webcamsModal && '100%',
                    ml: '100%',
                }}
                maxHeight="40px"
                justifyContent="flex-end"
                mb={{ base: '10px', md: !webcamsModal && '15px', ml: '15px' }}
                alignItems={'center'}
                flexGrow={1}
            >
                <Flex
                    w={{
                        base: 'auto',
                        md: !webcamsModal && '100%',
                        ml: '100%',
                    }}
                    maxWidth={{
                        base: '100%',
                        md: !webcamsModal && '40%',
                        ml: '40%',
                    }}
                    maxHeight="36px"
                    justifyContent={{
                        base: 'flex-end',
                        ml: 'flex-end',
                    }}
                    alignItems={'center'}
                    flexGrow={1}
                >
                    <Flex
                        width="100%"
                        display={{
                            base: 'flex', // turned this on for now - will assess when view button added. Its actually helpful to show how much zoom in/out there is
                            ml: 'flex',
                        }}
                        flexDirection="row"
                        pointerEvents={loaded ? 'auto' : 'none'}
                    >
                        <Button
                            {...noFocus}
                            width="36px"
                            minWidth="36px"
                            height="36px"
                            opacity={{
                                base: '1',
                                md: !webcamsModal && '0.8',
                                ml: '0.8',
                            }}
                            bg="none"
                            paddingInlineStart="0"
                            paddingInlineEnd="0"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="10px"
                            onClick={zoomOut}
                            _hover={{
                                opacity: '1',
                                cursor: 'pointer',
                            }}
                            _active={{
                                background: 'none',
                            }}
                            disabled={scale <= 1.01}
                        >
                            <BsZoomOut fontSize="1.1rem" color="light.100" />
                        </Button>
                        <Slider
                            aria-label="slider-ex-1"
                            min={minScale * 1000}
                            max={maxScale * 1000}
                            value={scale * 1000}
                            onChange={handleSliderChange}
                            focusThumbOnChange={false}
                            mx={'5px'}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                        <Button
                            {...noFocus}
                            width="36px"
                            minWidth="36px"
                            height="36px"
                            opacity={{
                                base: '1',
                                md: !webcamsModal && '0.8',
                                ml: '0.8',
                            }}
                            bg="none"
                            paddingInlineStart="0"
                            paddingInlineEnd="0"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="10px"
                            onClick={zoomIn}
                            _hover={{
                                opacity: '1',
                                cursor: 'pointer',
                            }}
                            _active={{
                                background: 'none',
                            }}
                            disabled={scale >= 2.49}
                        >
                            <BsZoomIn fontSize="1.1rem" color="light.100" />
                        </Button>
                    </Flex>
                    <IconButton
                        {...noFocus}
                        color="light.100"
                        width={{
                            base: '28px',
                            ml: '28px',
                        }}
                        minWidth={{
                            base: '28px',
                            ml: '28px',
                        }}
                        paddingInlineStart="0"
                        paddingInlineEnd="0"
                        height={{
                            base: '36px',
                            ml: '36px',
                        }}
                        opacity={
                            !isZoomed
                                ? '0.25'
                                : {
                                      base: '0.9',
                                      md: !webcamsModal && '0.8',
                                      ml: '0.8',
                                  }
                        }
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="10px"
                        _hover={{
                            opacity: !isZoomed ? '0.4' : '1',
                            cursor: 'pointer',
                        }}
                        _active={{
                            background: 'none',
                        }}
                        icon={
                            <RecenterIcon
                                width={recenterSize}
                                height={recenterSize}
                                color="light.100"
                            />
                        }
                        onClick={resetZoom}
                        variant="outline"
                        border="none"
                        ml="10px"
                    />
                </Flex>
            </Flex>
            <Flex // IMAGE CONTAINER
                // borderTop="1px solid #E2E8F0"
                direction="column"
                position={{
                    base: 'relative',
                }}
                w={{ base: '100%' }}
                h={{
                    base: '100%',
                }}
                overflow={'hidden'}
                p="0px"
                borderRadius="0px"
                onWheelCapture={onScroll}
                zIndex="1"
                className="webcamImage"
                pointerEvents={loaded ? 'auto' : 'none'}
                // boxShadow="0px 4px 15px -1px rgba(0,0,0,0.12)"
            >
                <Box
                    pos={'absolute'}
                    top={0}
                    left={0}
                    w="100%"
                    h="100%"
                    zIndex={100}
                    ref={ref}
                    cursor="grab"
                    _active={{
                        cursor: 'grabbing',
                    }}
                />
                <animated.div style={style}>
                    <Box
                        className="bounding"
                        ref={contentRef}
                        position="relative"
                    >
                        {children}
                    </Box>
                </animated.div>
            </Flex>
        </>
    )
}
