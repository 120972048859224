import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AwsIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.1801 2.32609C3.68301 1.97813 3 2.33375 3 2.94051V6.75002C3 7.16424 3.33579 7.50002 3.75 7.50002H7.25C7.57656 7.50002 7.85438 7.29131 7.95733 7H11.25V10H10.5C9.94772 10 9.5 10.4477 9.5 11V13H7.93699C7.71497 12.1374 6.93192 11.5 6 11.5H4.5V15.5H6C6.93192 15.5 7.71497 14.8626 7.93699 14H9.5V16C9.5 16.5523 9.94772 17 10.5 17H11.25V22H12.75V17H13.5C14.0523 17 14.5 16.5523 14.5 16V14H16.063C16.285 14.8626 17.0681 15.5 18 15.5C19.1046 15.5 20 14.6046 20 13.5C20 12.3954 19.1046 11.5 18 11.5C17.0681 11.5 16.285 12.1374 16.063 13H14.5V11C14.5 10.4477 14.0523 10 13.5 10H12.75V7H16.0427C16.1456 7.2913 16.4234 7.5 16.75 7.5H19.8229C19.9394 7.5 20.0542 7.47289 20.1584 7.42082L21.1584 6.92082C21.7111 6.64443 21.7111 5.85557 21.1584 5.57918L20.1584 5.07918C20.0542 5.02711 19.9394 5 19.8229 5H16.75C16.4234 5 16.1456 5.2087 16.0427 5.5H8V5.39051C8 5.14578 7.88059 4.91644 7.6801 4.77609L4.1801 2.32609ZM4.5 4.38101V6.00002H6.5V5.78101L4.5 4.38101ZM17 13.5C17 14.0523 17.4477 14.5 18 14.5C18.5523 14.5 19 14.0523 19 13.5C19 12.9477 18.5523 12.5 18 12.5C17.4477 12.5 17 12.9477 17 13.5ZM11 11.5H13V15.5H11V11.5ZM6 12.5H5.5V14.5H6C6.55228 14.5 7 14.0523 7 13.5C7 12.9477 6.55228 12.5 6 12.5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
