import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloudInfraredIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            {/* <g opacity="0.2">
                <path
                    d="M3.72898 10.6297C3.48866 10.8699 3.00004 11.6655 3 12.4231C3 14.1103 3.68595 14.8771 4.52656 15.2225C4.54334 15.0564 4.5681 14.8906 4.60088 14.7258C4.7805 13.8228 5.19395 12.985 5.79716 12.2949C5.05863 11.7345 4.85289 11.1103 4.45796 9.86112C4.3378 9.98122 4.21564 10.1133 4.09347 10.2454C3.97131 10.3775 3.84914 10.5096 3.72898 10.6297Z"
                    fill="currentColor"
                />
                <path
                    d="M14.5351 13.59C18.145 13.3532 17.2164 10.0364 15.8787 8.06774C16.6077 7.84355 18.3301 8.2176 19.0376 8.58014C19.6991 9.01058 20.2258 9.45555 20.5436 10.2134C20.829 10.8939 21.0696 12.1754 20.9816 12.6793C20.8249 13.576 20.4315 14.0376 20.2526 14.2165C20.203 14.2661 20.1461 14.3268 20.0826 14.3946C19.6915 14.8121 19.0496 15.4975 18.3086 15.4975L14.9939 15.4974C14.9621 14.8367 14.8058 14.1897 14.5351 13.59Z"
                    fill="currentColor"
                />
            </g> */}
            <path
                d="M17.5 16.5H14.9468C14.9819 16.255 15 16.0046 15 15.75C15 15.4954 14.9819 15.245 14.9468 15H17.5C19.1569 15 20.5 13.6569 20.5 12C20.5 10.3879 19.123 9.07279 17.625 9.00292C17.5954 9.00154 17.5658 9.00065 17.5362 9.00025C17.5241 9.00008 17.5121 9 17.5 9C17.4752 9 17.4505 9.0003 17.4259 9.0009C16.5672 9.02176 15.798 9.40341 15.264 10L14.1563 8.98858C14.6065 8.48893 15.1672 8.0907 15.7998 7.83233C14.9336 6.43612 13.3735 5.5 11.75 5.5C9.21051 5.5 7.13647 7.49286 7.00647 10H6C4.61929 10 3.5 11.1193 3.5 12.5C3.5 13.3742 3.94875 14.1437 4.62847 14.5905C4.54438 14.9635 4.5 15.3516 4.5 15.75C4.5 15.9075 4.50694 16.0635 4.52053 16.2175C3.04375 15.6292 2 14.1865 2 12.5C2 10.3762 3.65521 8.63895 5.746 8.50794C6.50027 5.90385 8.9028 4 11.75 4C14.0505 4 16.2543 5.42855 17.3251 7.50334C17.3795 7.50126 17.4342 7.50014 17.489 7.50001L17.5 7.5C19.8182 7.5 22.0002 9.51472 22.0002 12C22.0002 13.1802 21.5458 14.2542 20.8026 15.0568C19.9806 15.9445 18.8051 16.5 17.5 16.5Z"
                fill="currentColor"
            />
            <path
                d="M11.2801 18.4971C10.2037 18.4873 9.12366 18.0671 8.29558 17.2389C7.4675 16.4107 7.04743 15.3306 7.03769 14.2543L7.87618 14.2619C7.88098 15.1212 8.21881 15.9835 8.88487 16.6497C9.54793 17.3128 10.4132 17.6537 11.2725 17.6586L11.2801 18.4971Z"
                fill="currentColor"
            />
            <path
                d="M11.2649 16.8142C10.6167 16.8083 9.9692 16.5556 9.47416 16.0605C8.97911 15.5654 8.72648 14.9179 8.72061 14.2697L9.5591 14.2774C9.56298 14.7055 9.73042 15.1382 10.0634 15.4712C10.3965 15.8043 10.8291 15.9718 11.2573 15.9757L11.2649 16.8142Z"
                fill="currentColor"
            />
            <path
                d="M11.8421 14.8929C11.518 15.217 10.9828 15.2121 10.6527 14.882C10.3227 14.5519 10.3179 14.0167 10.642 13.6927C10.9661 13.3686 11.5013 13.3735 11.8313 13.7036C12.1613 14.0336 12.1662 14.5688 11.8421 14.8929Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.75 20C8.90943 20 8.08773 19.7508 7.38883 19.2838C6.68992 18.8168 6.14518 18.153 5.82351 17.3764C5.50184 16.5998 5.41768 15.7453 5.58167 14.9208C5.74565 14.0964 6.15042 13.3392 6.7448 12.7448C7.33917 12.1504 8.09645 11.7457 8.92087 11.5817C9.74529 11.4177 10.5998 11.5018 11.3764 11.8235C12.153 12.1452 12.8167 12.69 13.2837 13.3889C13.7507 14.0878 14 14.9094 14 15.75C13.9987 16.8768 13.5505 17.957 12.7537 18.7537C11.957 19.5505 10.8768 19.9987 9.75 20ZM9.75 12.31C9.06963 12.31 8.40454 12.5118 7.83884 12.8898C7.27313 13.2678 6.83222 13.805 6.57185 14.4336C6.31149 15.0622 6.24337 15.7538 6.3761 16.4211C6.50883 17.0884 6.83646 17.7013 7.31755 18.1824C7.79864 18.6635 8.41159 18.9912 9.07889 19.1239C9.74618 19.2566 10.4379 19.1885 11.0664 18.9282C11.695 18.6678 12.2323 18.2268 12.6103 17.6611C12.9882 17.0954 13.19 16.4304 13.19 15.75C13.1887 14.8381 12.8258 13.9639 12.181 13.319C11.5361 12.6742 10.6619 12.3113 9.75 12.31Z"
                fill="currentColor"
            />
        </Icon>
    )
}
