import { useEffect, useState, useRef } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import {
    Flex,
    Box,
    Text,
    Divider,
    IconButton,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    useBreakpointValue,
    Portal,
    Tooltip,
} from '@chakra-ui/react'
import Image from '../../../../components/Image'
import { isMobileOnly } from 'react-device-detect'
import { useInView } from 'react-intersection-observer'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { AiOutlineFullscreen } from 'react-icons/ai'
import { CgClose } from 'react-icons/cg'
import { useSwipeable } from 'react-swipeable'

import formatDateTime from '../../../../../util/dateFormatter'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import {
    timeZoneState,
    showOutdatedErrorsState,
    inViewPositionState,
    briefingParamsState,
    collapseDropdownsState,
    fullscreenHandlerState,
} from '../../../../../globalState'

import PinchDrag, { useScale } from '../../../components/PinchDrag'
import ZoomSlider from '../../../components/ZoomSlider'
import ErrorText from '../../../components/ErrorText'
import SigmetIcon from '../../../../../icons/menu-icons/SigmetIcon'

export default function SigmetBriefing(props) {
    const { briefingData, fullscreenRef, handleReset, setHandleReset } = props
    const sigmet =
        briefingData &&
        briefingData.res &&
        briefingData.res.areamet &&
        briefingData.res.areamet.sigmet
    const sigmetCharts =
        briefingData &&
        briefingData.res &&
        briefingData.res.areamet &&
        briefingData.res.areamet.charts &&
        briefingData.res.areamet.charts.sigmet
    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const params = useRecoilValue(briefingParamsState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const [fullscreenHandler, setFullscreenHandler] = useRecoilState(
        fullscreenHandlerState
    )
    const { scale, setScale, xy, setXy } = useScale()
    const fullscreen = Boolean(fullscreenHandler === 'sigmet')
    const scrollRef = useRef()

    useEffect(() => {
        if (handleReset) {
            setScale(1)
            setXy({
                x: 0,
                y: 0,
            })
            setHandleReset(false)
            setFullscreenHandler(null)
        }
    }, [
        fullscreen,
        handleReset,
        setFullscreenHandler,
        setScale,
        setXy,
        setHandleReset,
    ])

    const headerSwipe = useSwipeable({
        onSwipedDown: () => {
            if (fullscreen) {
                setHandleReset(true)
            }
        },
    })

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize, calculateDateFontSize, calculateLabelSize } =
        useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })
    const adjustDateFontSize = useBreakpointValue({
        base: calculateDateFontSize(0.8, fullscreen),
        xs: calculateDateFontSize(0.8),
    })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    // const hideOnMobile = window.innerWidth < 860 && !fullscreen
    const hideOnMobile = !fullscreen

    const pageScale = useBreakpointValue({
        base: '95%',
        md: '60%',
        lg: '50%',
        xl: '40%',
    })

    const pageScaleNZZO = useBreakpointValue({
        base: '95%',
        md: '95%',
        lg: '95%',
        xl: '95%',
    })

    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)
    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])
    useEffect(() => {
        inView && setInViewPosition('sigmetContainer')
    }, [inView, setInViewPosition])

    const timeZone = useRecoilValue(timeZoneState)
    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : [0, 1]
    )

    const [FIR, setFIR] = useState('NZZC')
    const [FIRCharts, setFIRCharts] = useState('NZZC')

    const handleChangeFIR = () => {
        const changeFIR = FIR === 'NZZC' ? 'NZZO' : 'NZZC'
        setFIR(changeFIR)
    }

    const handleChangeFIRCharts = () => {
        const changeFIRCharts = FIRCharts === 'NZZC' ? 'NZZO' : 'NZZC'
        setFIRCharts(changeFIRCharts)
    }

    useEffect(() => {
        setExpandedDropdowns(collapseDropdowns ? [] : [0, 1])
    }, [collapseDropdowns])

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()

    const portalLevels = useBreakpointValue({
        base: { containerRef: mobile },
        lg: { containerRef: fullscreen ? desktop : mobile },
        xl: { containerRef: desktop },
    })

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <div
                id="sigmetContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            {params.sigmet && (
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', md: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    zIndex="1"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginTop="25px"
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#35ADD3"
                        backgroundImage="linear-gradient(345deg, #5CCFF1 0%, #018AB0 80%)"
                        filter={{
                            base: isMobileOnly && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="30px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="flex-start"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Box>
                                <SigmetIcon
                                    width="20px"
                                    height="20px"
                                    color="white"
                                />
                            </Box>

                            <Box my="auto" paddingLeft="12px">
                                <Text
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    SIGMET
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                    <Box>
                        <Accordion
                            border="none"
                            allowToggle
                            allowMultiple
                            index={expandedDropdowns}
                            onChange={(expandedIndex) => {
                                setExpandedDropdowns(expandedIndex)
                            }}
                        >
                            <AccordionItem
                                id="sigmetText"
                                borderStyle="none"
                                boxShadow="0px -5px 15px 0px rgba(0,0,0,0.04)"
                            >
                                {({ isExpanded }) => (
                                    <>
                                        <AccordionButton
                                            sx={{
                                                clipPath:
                                                    'inset(0px 0px -50px 0px)',
                                            }}
                                            pl="1.3rem"
                                            height="50px"
                                            pr={5}
                                            py={3}
                                            _hover={{
                                                background: 'light.30',

                                                cursor: 'pointer',
                                            }}
                                            color={
                                                !isExpanded
                                                    ? '#3fb8dd'
                                                    : '#3fb8dd'
                                            }
                                            background={
                                                isExpanded
                                                    ? 'light.25'
                                                    : 'white'
                                            }
                                            boxShadow={
                                                isExpanded &&
                                                '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                            }
                                            _focus={{
                                                boxShadow: isExpanded
                                                    ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                    : 'none',
                                            }}
                                            opacity="1"
                                        >
                                            <Box
                                                fontSize={adjustLabelFontSize}
                                                lineHeight="1.2"
                                                fontWeight={
                                                    sigmet && isExpanded
                                                        ? '600'
                                                        : '500'
                                                }
                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                flex="1"
                                                textAlign="left"
                                                paddingLeft="1"
                                            >
                                                SIGMETs
                                            </Box>
                                            {sigmet ? (
                                                <Flex>
                                                    {isExpanded ? (
                                                        <AccordionIcon transform="rotate(0deg)" />
                                                    ) : (
                                                        <AccordionIcon transform="rotate(-90deg)" />
                                                    )}
                                                </Flex>
                                            ) : (
                                                <Box
                                                    {...noFocus}
                                                    pr={1}
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                    lineHeight="1.2"
                                                    fontWeight="500"
                                                    fontFamily="'Open Sans', sans-serif"
                                                    flex="2"
                                                    textAlign="right"
                                                    color="light.400"
                                                >
                                                    No current SIGMETs
                                                </Box>
                                            )}
                                        </AccordionButton>

                                        {sigmet && (
                                            <AccordionPanel
                                                pt={0}
                                                pb={6}
                                                pl="1.5rem"
                                                pr="1.5rem"
                                            >
                                                <Flex
                                                    fontFamily="Open Sans"
                                                    paddingTop="6"
                                                    flexDirection="column"
                                                >
                                                    <Flex
                                                        justifyContent={{
                                                            base: 'center',
                                                            xl: 'center',
                                                        }}
                                                        alignItems="center"
                                                        width="100%"
                                                        pt="5px"
                                                        pb="15px"
                                                    >
                                                        <Tooltip
                                                            label="Flight Information Region"
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="26px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="top"
                                                            m="5px"
                                                            color="light.100"
                                                            bg="light.30"
                                                        >
                                                            <Text
                                                                whiteSpace="nowrap"
                                                                cursor="default"
                                                                pr="10px"
                                                                fontSize="0.8rem"
                                                                fontWeight="500"
                                                            >
                                                                FIR:
                                                            </Text>
                                                        </Tooltip>
                                                        <Button // NZZC/NZZO BUTTON
                                                            bg="light.20"
                                                            {...noFocus}
                                                            variant="outline"
                                                            fontSize="11px"
                                                            fontWeight="600"
                                                            height={{
                                                                base: '32px',
                                                                md: '28px',
                                                            }}
                                                            width="124px"
                                                            style={{
                                                                minWidth:
                                                                    '124px',
                                                            }}
                                                            justifyContent="initial"
                                                            paddingLeft="2px"
                                                            paddingRight="2px"
                                                            py="2px"
                                                            borderRadius="20px"
                                                            boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                            border="none"
                                                            {...noFocus}
                                                            index={
                                                                FIR === 'NZZC'
                                                                    ? 0
                                                                    : 1
                                                            }
                                                            onClick={() => {
                                                                handleChangeFIR(
                                                                    (
                                                                        prevState
                                                                    ) =>
                                                                        !prevState
                                                                )
                                                            }}
                                                            _active={{
                                                                bg: 'light.30',
                                                            }}
                                                            _focus={{
                                                                bg: 'light.30',
                                                            }}
                                                            _hover={{
                                                                bg: 'light.30',
                                                            }}
                                                        >
                                                            <Box // BUTTON INDICATOR
                                                                mt="0px"
                                                                ml="0px"
                                                                px="30px"
                                                                height={{
                                                                    base: '28px',
                                                                    md: '24px',
                                                                }}
                                                                width="54px"
                                                                background="white"
                                                                borderRadius="20px"
                                                                bg="#ffffff"
                                                                boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                style={{
                                                                    borderColor:
                                                                        '#E9EAEB',
                                                                    transform:
                                                                        FIR ===
                                                                        'NZZC'
                                                                            ? 'translateX(0%)'
                                                                            : 'translateX(100%)',
                                                                    transition:
                                                                        'transform 600ms',
                                                                }}
                                                                transform={
                                                                    FIR ===
                                                                    'NZZC'
                                                                        ? 'translateX(0%)'
                                                                        : 'translateX(100%)'
                                                                }
                                                                transition="transform 600ms"
                                                            ></Box>
                                                            <Flex
                                                                justifyContent="space-evenly"
                                                                marginLeft="-47px"
                                                            >
                                                                <Text
                                                                    zIndex="1"
                                                                    paddingLeft="0px"
                                                                    lineHeight="1.3"
                                                                    textTransform="uppercase"
                                                                    fontSize="0.8rem"
                                                                    fontWeight={
                                                                        FIR ===
                                                                        'NZZC'
                                                                            ? '700'
                                                                            : '600'
                                                                    }
                                                                    opacity={
                                                                        FIR ===
                                                                        'NZZC'
                                                                            ? '1'
                                                                            : '0.6'
                                                                    }
                                                                    color={
                                                                        FIR ===
                                                                        'NZZC'
                                                                            ? 'light.200'
                                                                            : 'light.100'
                                                                    }
                                                                    _selected={{
                                                                        opacity:
                                                                            '1',
                                                                    }}
                                                                    _active={{
                                                                        opacity:
                                                                            '1',
                                                                    }}
                                                                    transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                >
                                                                    NZZC
                                                                </Text>
                                                                <Text
                                                                    zIndex="1"
                                                                    paddingLeft="28px"
                                                                    lineHeight="1.3"
                                                                    textTransform="uppercase"
                                                                    fontSize="0.8rem"
                                                                    fontWeight={
                                                                        FIR ===
                                                                        'NZZO'
                                                                            ? '700'
                                                                            : '600'
                                                                    }
                                                                    opacity={
                                                                        FIR ===
                                                                        'NZZO'
                                                                            ? '1'
                                                                            : '0.6'
                                                                    }
                                                                    color={
                                                                        FIR ===
                                                                        'NZZO'
                                                                            ? 'light.200'
                                                                            : 'light.100'
                                                                    }
                                                                    _selected={{
                                                                        opacity:
                                                                            '1',
                                                                    }}
                                                                    _active={{
                                                                        opacity:
                                                                            '1',
                                                                    }}
                                                                    transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                >
                                                                    NZZO
                                                                </Text>
                                                            </Flex>
                                                        </Button>
                                                    </Flex>

                                                    <Divider
                                                        height="0px !important"
                                                        borderColor="#e5e5e5"
                                                        width="100%"
                                                        mx="auto"
                                                    />
                                                    {!sigmet
                                                        .map(
                                                            (sigmet) =>
                                                                sigmet.fir
                                                        )
                                                        .includes(FIR) && (
                                                        <Text
                                                            key={FIR}
                                                            paddingStart="0"
                                                            fontWeight="400"
                                                            pt="25px"
                                                            minWidth="140px"
                                                            fontSize={calculateFontSize(
                                                                0.8
                                                            )}
                                                            lineHeight="1.3"
                                                            pb="25px"
                                                        >
                                                            No current SIGMETs
                                                            in the{' '}
                                                            <strong>
                                                                {FIR}
                                                            </strong>{' '}
                                                            Flight Information
                                                            Region
                                                        </Text>
                                                    )}

                                                    {sigmet.map(
                                                        (sigmetData, i) => (
                                                            <div
                                                                key={`SigmetBriefing${sigmetData.fir}${sigmetData.id}`}
                                                            >
                                                                {(showOutdatedErrors ||
                                                                    sigmetData.outofdate) && (
                                                                    <Flex
                                                                        pt="15px"
                                                                        ml="-3px"
                                                                        mb="-5px"
                                                                        justifyContent="center"
                                                                    >
                                                                        <ErrorText>
                                                                            There
                                                                            has
                                                                            been
                                                                            an
                                                                            issue
                                                                            with
                                                                            the
                                                                            connection
                                                                            between
                                                                            PreFlight
                                                                            and
                                                                            MetService.
                                                                            This{' '}
                                                                            <strong>
                                                                                SIGMET
                                                                            </strong>{' '}
                                                                            was
                                                                            last
                                                                            validated
                                                                            with
                                                                            MetService
                                                                            more
                                                                            than{' '}
                                                                            <strong>
                                                                                {formatDistanceToNow(
                                                                                    parseISO(
                                                                                        sigmetData.updated
                                                                                    ),
                                                                                    {
                                                                                        addSuffix: true,
                                                                                    }
                                                                                )}
                                                                            </strong>
                                                                            .
                                                                            <br />
                                                                            There
                                                                            is a
                                                                            high
                                                                            likelihood
                                                                            that
                                                                            the
                                                                            latest
                                                                            SIGMET
                                                                            information
                                                                            is
                                                                            not
                                                                            available
                                                                            in
                                                                            PreFlight.
                                                                            Check
                                                                            back
                                                                            again
                                                                            soon,
                                                                            or
                                                                            alternatively
                                                                            visit{' '}
                                                                            <a
                                                                                style={{
                                                                                    fontWeight:
                                                                                        '500',
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                                href="https://ifis.airways.co.nz/"
                                                                            >
                                                                                IFIS
                                                                            </a>
                                                                            .
                                                                        </ErrorText>
                                                                    </Flex>
                                                                )}
                                                                <Flex
                                                                    flexDirection={{
                                                                        base: 'column',
                                                                        md: 'row',
                                                                    }}
                                                                    height={
                                                                        FIR ===
                                                                        sigmetData.fir
                                                                            ? 'max-content'
                                                                            : '0px'
                                                                    }
                                                                    visibility={
                                                                        FIR ===
                                                                        sigmetData.fir
                                                                            ? 'visible'
                                                                            : 'hidden'
                                                                    }
                                                                    opacity={
                                                                        FIR ===
                                                                        sigmetData.fir
                                                                            ? '1'
                                                                            : '0'
                                                                    }
                                                                    style={{
                                                                        transition:
                                                                            'height linear 200ms, opacity ease 300ms',
                                                                    }}
                                                                    pt={
                                                                        FIR ===
                                                                        sigmetData.fir
                                                                            ? '25px'
                                                                            : '0'
                                                                    }
                                                                    pb={
                                                                        FIR ===
                                                                        sigmetData.fir
                                                                            ? '25px'
                                                                            : '0'
                                                                    }
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Text
                                                                        paddingStart="0"
                                                                        paddingEnd="2"
                                                                        fontWeight="bold"
                                                                        py="0px"
                                                                        pb="7px"
                                                                        minWidth="140px"
                                                                        fontSize={calculateFontSize(
                                                                            0.8
                                                                        )}
                                                                        lineHeight="1.3"
                                                                        borderColor="transparent"
                                                                    >
                                                                        {
                                                                            sigmetData.id
                                                                        }
                                                                    </Text>
                                                                    <Flex
                                                                        flexDirection="column"
                                                                        width="100%"
                                                                        gap="5px"
                                                                    >
                                                                        <Flex alignItems="baseline">
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width={calculateLabelSize(
                                                                                    80
                                                                                )}
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Issued:
                                                                            </Text>
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="0"
                                                                                fontWeight="400"
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                                borderColor="transparent"
                                                                            >
                                                                                {sigmetData?.issued &&
                                                                                    formatDateTime(
                                                                                        sigmetData.issued,
                                                                                        timeZone
                                                                                    )}
                                                                            </Text>
                                                                        </Flex>

                                                                        <Flex alignItems="baseline">
                                                                            <Text
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width={calculateLabelSize(
                                                                                    80
                                                                                )}
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Valid
                                                                                from:
                                                                            </Text>
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="0"
                                                                                fontWeight="400"
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                                borderColor="transparent"
                                                                            >
                                                                                {sigmetData?.validfrom &&
                                                                                    formatDateTime(
                                                                                        sigmetData.validfrom,
                                                                                        timeZone
                                                                                    )}
                                                                            </Text>
                                                                        </Flex>
                                                                        <Flex alignItems="baseline">
                                                                            <Text
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width={calculateLabelSize(
                                                                                    80
                                                                                )}
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Valid
                                                                                to:
                                                                            </Text>
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="0"
                                                                                fontWeight="400"
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                                borderColor="transparent"
                                                                            >
                                                                                {sigmetData?.validto &&
                                                                                    formatDateTime(
                                                                                        sigmetData.validto,
                                                                                        timeZone
                                                                                    )}
                                                                            </Text>
                                                                        </Flex>

                                                                        <Divider
                                                                            display={{
                                                                                base: 'none',
                                                                                md: 'block',
                                                                            }}
                                                                            height="0px !important"
                                                                            borderColor="#e5e5e5"
                                                                            width="100%"
                                                                            mx="auto"
                                                                            my="5px"
                                                                            opacity={
                                                                                FIR ===
                                                                                sigmetData.fir
                                                                                    ? '0.6'
                                                                                    : '0'
                                                                            }
                                                                        />

                                                                        <Text
                                                                            pt={{
                                                                                base: '5px',
                                                                                md: '0px',
                                                                            }}
                                                                            textAlign="left"
                                                                            color="light.900"
                                                                            fontSize={calculateFontSize(
                                                                                0.8
                                                                            )}
                                                                            fontWeight="normal"
                                                                            lineHeight="1.3"
                                                                            whiteSpace="pre-line"
                                                                            paddingBottom="10px"
                                                                        >
                                                                            {
                                                                                sigmetData.data
                                                                            }
                                                                        </Text>
                                                                    </Flex>
                                                                </Flex>
                                                                {i + 1 !==
                                                                    sigmet.length &&
                                                                    sigmetData.fir.includes(
                                                                        FIR
                                                                    ) && (
                                                                        <Divider
                                                                            height="0px !important"
                                                                            borderColor="#e5e5e5"
                                                                            width="100%"
                                                                            mx="auto"
                                                                        />
                                                                    )}
                                                            </div>
                                                        )
                                                    )}
                                                </Flex>
                                            </AccordionPanel>
                                        )}
                                    </>
                                )}
                            </AccordionItem>
                            {sigmetCharts && (
                                <>
                                    <Divider
                                        height="0px !important"
                                        borderColor="#e5e5e5"
                                        width="100%"
                                        mx="auto"
                                    />
                                    <AccordionItem
                                        id="sigmetCharts"
                                        borderStyle="none"
                                        boxShadow="0px -5px 15px 0px rgba(0,0,0,0.04)"
                                    >
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    sx={{
                                                        clipPath:
                                                            'inset(0px 0px -50px 0px)',
                                                    }}
                                                    pl="1.3rem"
                                                    height="50px"
                                                    pr={5}
                                                    py={3}
                                                    _hover={{
                                                        background: 'light.30',

                                                        cursor: 'pointer',
                                                    }}
                                                    color={
                                                        !isExpanded
                                                            ? '#26aed7'
                                                            : '#26aed7'
                                                    }
                                                    background={
                                                        isExpanded
                                                            ? 'light.25'
                                                            : 'white'
                                                    }
                                                    boxShadow={
                                                        isExpanded &&
                                                        '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                    }
                                                    _focus={{
                                                        boxShadow: isExpanded
                                                            ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                            : 'none',
                                                    }}
                                                    opacity="1"
                                                    borderBottomRadius={
                                                        isExpanded
                                                            ? '0px'
                                                            : '20px'
                                                    }
                                                    transition="boxShadow all 800ms"
                                                >
                                                    <Box
                                                        fontSize={
                                                            adjustLabelFontSize
                                                        }
                                                        lineHeight="1.2"
                                                        fontWeight={
                                                            sigmetCharts &&
                                                            isExpanded
                                                                ? '600'
                                                                : '500'
                                                        }
                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                        flex="1"
                                                        textAlign="left"
                                                        paddingLeft="1"
                                                    >
                                                        SIGMET Charts
                                                    </Box>
                                                    {sigmetCharts ? (
                                                        <Flex>
                                                            {isExpanded ? (
                                                                <AccordionIcon transform="rotate(0deg)" />
                                                            ) : (
                                                                <AccordionIcon transform="rotate(-90deg)" />
                                                            )}
                                                        </Flex>
                                                    ) : (
                                                        <Box
                                                            {...noFocus}
                                                            pr={1}
                                                            fontSize="0.8rem"
                                                            lineHeight="1.2"
                                                            fontWeight="500"
                                                            fontFamily="'Open Sans', sans-serif"
                                                            flex="2"
                                                            textAlign="right"
                                                            color="light.400"
                                                        >
                                                            SIGMET Chart
                                                            information is not
                                                            available at this
                                                            time
                                                        </Box>
                                                    )}
                                                </AccordionButton>

                                                {sigmetCharts && (
                                                    <AccordionPanel
                                                        pt={0}
                                                        pb={6}
                                                        pl="1.5rem"
                                                        pr="1.5rem"
                                                    >
                                                        <Portal
                                                            containerRef={
                                                                fullscreen
                                                                    ? fullscreenRef
                                                                    : scrollRef
                                                            }
                                                        >
                                                            <Flex
                                                                flexDirection="column"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                height="100%"
                                                            >
                                                                <Flex
                                                                    {...headerSwipe}
                                                                    flexDirection="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    width="100%"
                                                                    pt={
                                                                        fullscreen
                                                                            ? '10px'
                                                                            : '25px'
                                                                    }
                                                                    px={
                                                                        fullscreen
                                                                            ? '20px'
                                                                            : '0px'
                                                                    }
                                                                    pb="10px"
                                                                    zIndex="2"
                                                                    boxShadow={
                                                                        fullscreen &&
                                                                        'rgba(0, 0, 0, 0.15) 0px 0px 15px -1px'
                                                                    }
                                                                    borderBottom={
                                                                        fullscreen &&
                                                                        '1px solid #E2E8F0'
                                                                    }
                                                                    background={
                                                                        fullscreen &&
                                                                        'white'
                                                                    }
                                                                >
                                                                    <Flex
                                                                        flexDirection="row"
                                                                        alignItems="center"
                                                                        width="100%"
                                                                    >
                                                                        <Flex
                                                                            className="issuedTime"
                                                                            flexDirection="row"
                                                                            alignItems="center"
                                                                            ml={{
                                                                                base: '5px',
                                                                                lg: fullscreen
                                                                                    ? '5px'
                                                                                    : '0',
                                                                            }}
                                                                            pr={{
                                                                                base: '10px',
                                                                                xs: '20px',
                                                                                lg: fullscreen
                                                                                    ? '50px'
                                                                                    : '20px',
                                                                                xl: '30px',
                                                                            }}
                                                                            minWidth={{
                                                                                base: fullscreen
                                                                                    ? '200px'
                                                                                    : '220px',
                                                                                xs: '240px',
                                                                                xl: '265px',
                                                                            }}
                                                                            width={
                                                                                fullscreen
                                                                                    ? 'auto'
                                                                                    : '100%'
                                                                            }
                                                                        >
                                                                            <Text
                                                                                whiteSpace="nowrap"
                                                                                paddingStart="0"
                                                                                paddingEnd="2"
                                                                                fontWeight="bold"
                                                                                width={calculateLabelSize(
                                                                                    55
                                                                                )}
                                                                                fontSize={
                                                                                    adjustDateFontSize
                                                                                }
                                                                                lineHeight="1.3"
                                                                            >
                                                                                Issued:
                                                                            </Text>

                                                                            {sigmetCharts.map(
                                                                                (
                                                                                    sigmet
                                                                                ) => (
                                                                                    <Text
                                                                                        whiteSpace="nowrap"
                                                                                        key={`SigmetChartsBriefing${sigmet.fir}${sigmet.url}`}
                                                                                        paddingStart="0"
                                                                                        paddingEnd="0"
                                                                                        fontWeight="400"
                                                                                        py="0px"
                                                                                        fontSize={
                                                                                            adjustDateFontSize
                                                                                        }
                                                                                        lineHeight="1.3"
                                                                                        borderColor="transparent"
                                                                                    >
                                                                                        {sigmet?.fir.includes(
                                                                                            FIRCharts
                                                                                        ) &&
                                                                                            sigmet?.issued &&
                                                                                            formatDateTime(
                                                                                                sigmet.issued,
                                                                                                timeZone
                                                                                            )}
                                                                                    </Text>
                                                                                )
                                                                            )}
                                                                        </Flex>
                                                                        <Portal
                                                                            style={{
                                                                                height: '100%',
                                                                            }}
                                                                            {...portalLevels}
                                                                        >
                                                                            <Flex
                                                                                width="100%"
                                                                                justifyContent={{
                                                                                    base: 'center',
                                                                                    lg: fullscreen
                                                                                        ? 'flex-start'
                                                                                        : 'center',
                                                                                    xl: 'flex-start',
                                                                                }}
                                                                                alignItems="center"
                                                                                mt={{
                                                                                    base: '20px',
                                                                                    lg: fullscreen
                                                                                        ? '0px'
                                                                                        : '10px',
                                                                                    xl: '0',
                                                                                }}
                                                                                mb={{
                                                                                    base: '20px',
                                                                                    lg: fullscreen
                                                                                        ? '0px'
                                                                                        : '20px',
                                                                                    xl: '0px',
                                                                                }}
                                                                                pr={{
                                                                                    base: '0',
                                                                                    lg: fullscreen
                                                                                        ? '50px'
                                                                                        : '0',
                                                                                    xl: '30px',
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    label="Flight Information Region"
                                                                                    hasArrow
                                                                                    borderRadius="7px"
                                                                                    height="26px"
                                                                                    fontSize="0.7rem"
                                                                                    alignItems="center"
                                                                                    display="flex"
                                                                                    placement="top"
                                                                                    m="5px"
                                                                                    color="light.100"
                                                                                    bg="light.30"
                                                                                >
                                                                                    <Text
                                                                                        cursor="default"
                                                                                        mr="10px"
                                                                                        fontSize="0.8rem"
                                                                                        fontWeight="500"
                                                                                    >
                                                                                        FIR:
                                                                                    </Text>
                                                                                </Tooltip>
                                                                                <Button // NZZC/NZZO BUTTON
                                                                                    bg="light.20"
                                                                                    color="#a8b2c0"
                                                                                    variant="outline"
                                                                                    fontSize="11px"
                                                                                    fontWeight="600"
                                                                                    height={{
                                                                                        base: '32px',
                                                                                        md: '28px',
                                                                                    }}
                                                                                    width="124px"
                                                                                    style={{
                                                                                        minWidth:
                                                                                            '124px',
                                                                                    }}
                                                                                    justifyContent="initial"
                                                                                    paddingLeft="2px"
                                                                                    paddingRight="2px"
                                                                                    py="2px"
                                                                                    borderRadius="20px"
                                                                                    boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                    border="none"
                                                                                    {...noFocus}
                                                                                    index={
                                                                                        FIRCharts ===
                                                                                        'NZZC'
                                                                                            ? 0
                                                                                            : 1
                                                                                    }
                                                                                    onClick={() => {
                                                                                        handleChangeFIRCharts(
                                                                                            (
                                                                                                prevState
                                                                                            ) =>
                                                                                                !prevState
                                                                                        )
                                                                                    }}
                                                                                    _active={{
                                                                                        bg: 'light.30',
                                                                                    }}
                                                                                    _focus={{
                                                                                        bg: 'light.30',
                                                                                    }}
                                                                                    _hover={{
                                                                                        bg: 'light.30',
                                                                                    }}
                                                                                >
                                                                                    <Box // BUTTON INDICATOR
                                                                                        mt="0px"
                                                                                        ml="0px"
                                                                                        px="30px"
                                                                                        height={{
                                                                                            base: '28px',
                                                                                            md: '24px',
                                                                                        }}
                                                                                        width="54px"
                                                                                        background="white"
                                                                                        borderRadius="20px"
                                                                                        bg="#ffffff"
                                                                                        boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                        style={{
                                                                                            borderColor:
                                                                                                '#E9EAEB',
                                                                                            transform:
                                                                                                FIRCharts ===
                                                                                                'NZZC'
                                                                                                    ? 'translateX(0%)'
                                                                                                    : 'translateX(100%)',
                                                                                            transition:
                                                                                                'transform 600ms',
                                                                                        }}
                                                                                        transform={
                                                                                            FIRCharts ===
                                                                                            'NZZC'
                                                                                                ? 'translateX(0%)'
                                                                                                : 'translateX(100%)'
                                                                                        }
                                                                                        transition="transform 600ms"
                                                                                    ></Box>
                                                                                    <Flex
                                                                                        justifyContent="space-evenly"
                                                                                        marginLeft="-47px"
                                                                                    >
                                                                                        <Text
                                                                                            zIndex="1"
                                                                                            paddingLeft="0px"
                                                                                            lineHeight="1.3"
                                                                                            textTransform="uppercase"
                                                                                            fontSize="0.8rem"
                                                                                            fontWeight={
                                                                                                FIRCharts ===
                                                                                                'NZZC'
                                                                                                    ? '700'
                                                                                                    : '600'
                                                                                            }
                                                                                            opacity={
                                                                                                FIRCharts ===
                                                                                                'NZZC'
                                                                                                    ? '1'
                                                                                                    : '0.6'
                                                                                            }
                                                                                            color={
                                                                                                FIRCharts ===
                                                                                                'NZZC'
                                                                                                    ? 'light.200'
                                                                                                    : 'light.100'
                                                                                            }
                                                                                            _selected={{
                                                                                                opacity:
                                                                                                    '1',
                                                                                            }}
                                                                                            _active={{
                                                                                                opacity:
                                                                                                    '1',
                                                                                            }}
                                                                                            transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                                        >
                                                                                            NZZC
                                                                                        </Text>
                                                                                        <Text
                                                                                            zIndex="1"
                                                                                            paddingLeft="28px"
                                                                                            lineHeight="1.3"
                                                                                            textTransform="uppercase"
                                                                                            fontSize="0.8rem"
                                                                                            fontWeight={
                                                                                                FIRCharts ===
                                                                                                'NZZO'
                                                                                                    ? '700'
                                                                                                    : '600'
                                                                                            }
                                                                                            opacity={
                                                                                                FIRCharts ===
                                                                                                'NZZO'
                                                                                                    ? '1'
                                                                                                    : '0.6'
                                                                                            }
                                                                                            color={
                                                                                                FIRCharts ===
                                                                                                'NZZO'
                                                                                                    ? 'light.200'
                                                                                                    : 'light.100'
                                                                                            }
                                                                                            _selected={{
                                                                                                opacity:
                                                                                                    '1',
                                                                                            }}
                                                                                            _active={{
                                                                                                opacity:
                                                                                                    '1',
                                                                                            }}
                                                                                            transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                                        >
                                                                                            NZZO
                                                                                        </Text>
                                                                                    </Flex>
                                                                                </Button>
                                                                            </Flex>
                                                                        </Portal>
                                                                        <Box
                                                                            mx="auto"
                                                                            ref={
                                                                                desktop
                                                                            }
                                                                        ></Box>
                                                                    </Flex>
                                                                    {!hideOnMobile && (
                                                                        <ZoomSlider
                                                                            scale={
                                                                                scale
                                                                            }
                                                                            xy={
                                                                                xy
                                                                            }
                                                                            setScale={
                                                                                setScale
                                                                            }
                                                                            setXy={
                                                                                setXy
                                                                            }
                                                                            maxScale={
                                                                                3
                                                                            }
                                                                            fullscreen={
                                                                                fullscreen
                                                                            }
                                                                        />
                                                                    )}

                                                                    <IconButton
                                                                        {...noFocus}
                                                                        display={{
                                                                            base: fullscreen
                                                                                ? 'none'
                                                                                : 'flex',
                                                                            md: 'flex',
                                                                        }}
                                                                        color="light.100"
                                                                        width={{
                                                                            base: '36px',
                                                                            md: '36px',
                                                                        }}
                                                                        minWidth={{
                                                                            base: '36px',
                                                                            md: '36px',
                                                                        }}
                                                                        paddingInlineStart="0"
                                                                        paddingInlineEnd="0"
                                                                        height={{
                                                                            base: '36px',
                                                                            md: '36px',
                                                                        }}
                                                                        opacity={{
                                                                            base: '0.9',
                                                                            md: '0.8',
                                                                        }}
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        borderRadius="10px"
                                                                        _hover={{
                                                                            opacity:
                                                                                '1',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        _active={{
                                                                            background:
                                                                                'none',
                                                                        }}
                                                                        icon={
                                                                            fullscreen ? (
                                                                                <CgClose
                                                                                    fontSize="1.6rem"
                                                                                    color="light.100"
                                                                                />
                                                                            ) : (
                                                                                <AiOutlineFullscreen
                                                                                    fontSize="1.4rem"
                                                                                    color="light.100"
                                                                                />
                                                                            )
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                fullscreen
                                                                            ) {
                                                                                setHandleReset(
                                                                                    true
                                                                                )
                                                                            } else {
                                                                                setFullscreenHandler(
                                                                                    'sigmet'
                                                                                )
                                                                            }
                                                                        }}
                                                                        variant="outline"
                                                                        border="none"
                                                                        ml="15px"
                                                                    />
                                                                </Flex>
                                                                {!fullscreen && (
                                                                    <Divider
                                                                        height="0px !important"
                                                                        borderColor="#e5e5e5"
                                                                        width="100%"
                                                                        mx="auto"
                                                                        mb="0px"
                                                                    />
                                                                )}
                                                                <Flex
                                                                    flexDirection={{
                                                                        base: 'column',
                                                                        md: 'column',
                                                                    }}
                                                                    pt={{
                                                                        lg: fullscreen
                                                                            ? '0px'
                                                                            : '10px',
                                                                    }}
                                                                    pb={{
                                                                        base: '0px',
                                                                        md: '15px',
                                                                    }}
                                                                    zIndex="1"
                                                                    width="100%"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                >
                                                                    <Box
                                                                        width="100%"
                                                                        minHeight={{
                                                                            base: '74px',
                                                                            lg: '0px',
                                                                        }}
                                                                        background="white"
                                                                        zIndex="1"
                                                                        mt={
                                                                            fullscreen && {
                                                                                base: minimiseOnZoom
                                                                                    ? '-75px'
                                                                                    : '0px',
                                                                                lg: '0px',
                                                                            }
                                                                        }
                                                                        transition="all ease 300ms"
                                                                        borderBottom={{
                                                                            base:
                                                                                fullscreen &&
                                                                                '1px solid #E2E8F0',
                                                                            lg: 'none',
                                                                        }}
                                                                        ref={
                                                                            mobile
                                                                        }
                                                                    ></Box>

                                                                    <Box
                                                                        mt={
                                                                            fullscreen && {
                                                                                base: minimiseOnZoom
                                                                                    ? '75px'
                                                                                    : '0px',
                                                                                lg: '0px',
                                                                            }
                                                                        }
                                                                        transition="all ease 300ms"
                                                                    >
                                                                        {sigmetCharts.map(
                                                                            (
                                                                                sigmetError
                                                                            ) => (
                                                                                <Flex
                                                                                    key={`SigmetChartsError${sigmetError.fir}`}
                                                                                >
                                                                                    {(showOutdatedErrors ||
                                                                                        sigmetError.outofdate) &&
                                                                                        sigmetError.fir ===
                                                                                            FIRCharts && (
                                                                                            <Flex
                                                                                                ml="-3px"
                                                                                                mb="20px"
                                                                                                justifyContent="center"
                                                                                            >
                                                                                                <ErrorText>
                                                                                                    There
                                                                                                    has
                                                                                                    been
                                                                                                    an
                                                                                                    issue
                                                                                                    with
                                                                                                    the
                                                                                                    connection
                                                                                                    between
                                                                                                    PreFlight
                                                                                                    and
                                                                                                    MetService.
                                                                                                    This{' '}
                                                                                                    <strong>
                                                                                                        SIGMET
                                                                                                        CHART
                                                                                                    </strong>{' '}
                                                                                                    was
                                                                                                    last
                                                                                                    validated
                                                                                                    with
                                                                                                    MetService
                                                                                                    more
                                                                                                    than{' '}
                                                                                                    <strong>
                                                                                                        {formatDistanceToNow(
                                                                                                            parseISO(
                                                                                                                sigmetError.updated
                                                                                                            ),
                                                                                                            {
                                                                                                                addSuffix: true,
                                                                                                            }
                                                                                                        )}
                                                                                                    </strong>
                                                                                                    .
                                                                                                    <br />
                                                                                                    There
                                                                                                    is
                                                                                                    a
                                                                                                    high
                                                                                                    likelihood
                                                                                                    that
                                                                                                    the
                                                                                                    latest
                                                                                                    SIGMET
                                                                                                    information
                                                                                                    is
                                                                                                    not
                                                                                                    available
                                                                                                    in
                                                                                                    PreFlight.
                                                                                                    Check
                                                                                                    back
                                                                                                    again
                                                                                                    soon,
                                                                                                    or
                                                                                                    alternatively
                                                                                                    visit{' '}
                                                                                                    <a
                                                                                                        style={{
                                                                                                            fontWeight:
                                                                                                                '500',
                                                                                                            textDecoration:
                                                                                                                'underline',
                                                                                                        }}
                                                                                                        href="https://ifis.airways.co.nz/"
                                                                                                    >
                                                                                                        IFIS
                                                                                                    </a>

                                                                                                    .
                                                                                                </ErrorText>
                                                                                            </Flex>
                                                                                        )}
                                                                                </Flex>
                                                                            )
                                                                        )}
                                                                    </Box>
                                                                    {/* TODO: Needs a container here */}
                                                                    <Flex // IMAGE CONTAINER
                                                                        direction="column"
                                                                        position={{
                                                                            base: 'relative',
                                                                        }}
                                                                        w={{
                                                                            base: '100%',
                                                                        }}
                                                                        h="100%"
                                                                        // overflow={
                                                                        //     'hidden'
                                                                        // }
                                                                        pt={{
                                                                            base: fullscreen
                                                                                ? '15px'
                                                                                : '0',
                                                                            lg: fullscreen
                                                                                ? '25px'
                                                                                : '0',
                                                                            xl: fullscreen
                                                                                ? '25px'
                                                                                : '15px',
                                                                        }}
                                                                        px="10px"
                                                                        pb={{
                                                                            base: '5px',
                                                                            md: '10px',
                                                                        }}
                                                                        onClick={
                                                                            fullscreen
                                                                                ? () => {}
                                                                                : () =>
                                                                                      setFullscreenHandler(
                                                                                          'sigmet'
                                                                                      )
                                                                        }
                                                                        cursor={
                                                                            fullscreen
                                                                                ? 'grab'
                                                                                : 'zoom-in'
                                                                        }
                                                                    >
                                                                        <PinchDrag
                                                                            scale={
                                                                                scale
                                                                            }
                                                                            setScale={
                                                                                setScale
                                                                            }
                                                                            xy={
                                                                                xy
                                                                            }
                                                                            setXy={
                                                                                setXy
                                                                            }
                                                                            gestureDisabled={
                                                                                !fullscreen
                                                                            }
                                                                            maxZoom={
                                                                                3
                                                                            }
                                                                            maxZoomNewScale={
                                                                                2.999
                                                                            }
                                                                            fullscreenComponent={
                                                                                fullscreen
                                                                            }
                                                                        >
                                                                            {sigmetCharts.map(
                                                                                (
                                                                                    sigmetImage,
                                                                                    i
                                                                                ) => {
                                                                                    if (
                                                                                        sigmetImage.fir.includes(
                                                                                            FIRCharts
                                                                                        )
                                                                                    ) {
                                                                                        return (
                                                                                            <Image
                                                                                                key={`SigmetCharts${sigmetImage.fir}`}
                                                                                                mx="auto"
                                                                                                px={{
                                                                                                    base: '0px',
                                                                                                    md: '0px',
                                                                                                }}
                                                                                                py={{
                                                                                                    base:
                                                                                                        FIRCharts ===
                                                                                                        'NZZO'
                                                                                                            ? '20px'
                                                                                                            : '0',
                                                                                                    md: '0px',
                                                                                                }}
                                                                                                maxWidth={
                                                                                                    sigmetImage.fir ===
                                                                                                    'NZZO'
                                                                                                        ? pageScaleNZZO
                                                                                                        : pageScale
                                                                                                }
                                                                                                height={
                                                                                                    FIRCharts ===
                                                                                                    sigmetImage.fir
                                                                                                        ? 'auto'
                                                                                                        : '0px'
                                                                                                }
                                                                                                opacity={
                                                                                                    FIRCharts ===
                                                                                                    sigmetImage.fir
                                                                                                        ? '1'
                                                                                                        : '0'
                                                                                                }
                                                                                                style={{
                                                                                                    scale:
                                                                                                        FIRCharts ===
                                                                                                        'NZZO'
                                                                                                            ? '1.07'
                                                                                                            : '1',
                                                                                                }}
                                                                                                transform={{
                                                                                                    base:
                                                                                                        FIRCharts ===
                                                                                                            'NZZO' &&
                                                                                                        !fullscreen
                                                                                                            ? 'translateY(0px)'
                                                                                                            : 'none',
                                                                                                    md:
                                                                                                        FIRCharts ===
                                                                                                            'NZZO' &&
                                                                                                        !fullscreen
                                                                                                            ? 'translateY(-10px)'
                                                                                                            : 'none',
                                                                                                }}
                                                                                                marginTop={{
                                                                                                    base:
                                                                                                        FIRCharts ===
                                                                                                        'NZZO'
                                                                                                            ? '20px'
                                                                                                            : '0px',
                                                                                                    md:
                                                                                                        FIRCharts ===
                                                                                                        'NZZO'
                                                                                                            ? '35px'
                                                                                                            : '0px',
                                                                                                }}
                                                                                                margin={
                                                                                                    'auto'
                                                                                                }
                                                                                                // onLoad={() => {
                                                                                                //     console.log(
                                                                                                //         'image loaded'
                                                                                                //     )
                                                                                                // }}
                                                                                                transition="opacity ease-in-out 300ms"
                                                                                                src={`${window.location.origin}/metproxy?url=${sigmetImage.url}`}
                                                                                                alt="SIGMET CHART UNAVAILABLE"
                                                                                            />
                                                                                        )
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </PinchDrag>
                                                                    </Flex>
                                                                    <Text
                                                                        position={
                                                                            fullscreen
                                                                                ? 'absolute'
                                                                                : 'relative'
                                                                        }
                                                                        bottom={
                                                                            fullscreen
                                                                                ? '25px'
                                                                                : 'unset'
                                                                        }
                                                                        paddingTop="15px"
                                                                        fontSize="11px"
                                                                        color={
                                                                            fullscreen
                                                                                ? 'light.100'
                                                                                : 'light.300'
                                                                        }
                                                                    >
                                                                        Time
                                                                        references
                                                                        on chart
                                                                        are
                                                                        issued
                                                                        in UTC
                                                                    </Text>
                                                                </Flex>
                                                            </Flex>
                                                        </Portal>
                                                        <Box
                                                            ref={scrollRef}
                                                        ></Box>
                                                    </AccordionPanel>
                                                )}
                                            </>
                                        )}
                                    </AccordionItem>
                                </>
                            )}
                        </Accordion>
                    </Box>
                </Box>
            )}
        </>
    )
}
