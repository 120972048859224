import { useEffect, useRef } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { isEqual } from 'lodash'
import {
    clickedFeaturesState,
    dataModeState,
    layerSelectionState,
    modalVisibilityState,
    mapDisabledState,
} from '../../globalState'

import AerodromeModal from './components/aerodrome'
import WebcamModal from './components/Webcam'
import AwsModal from './components/aws'
import NotamModal from './components/NOTAM'
import RpasModal from './components/RPAS'
import SigwxModal from './components/SIGWX'
import SigmetModal from './components/SIGMET'
import GraforModal from './components/GRAFOR'
import AAWModal from './components/AAW'

export default function SingleModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const dataMode = useRecoilValue(dataModeState)
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const mapDisabled = useRecoilValue(mapDisabledState)

    const { layer } = clickedFeatures[0]

    const clicked = useRef()
    const mode = useRef()
    const selection = useRef()

    useEffect(() => {
        clicked.current = clickedFeatures
        // console.log('Single Modal Clicked Features:', clickedFeatures)
    }, [clickedFeatures])

    useEffect(() => {
        if (mode.current && selection.current) {
            if (
                clicked.current.length &&
                (dataMode !== mode.current ||
                    !isEqual(layerSelection, selection.current))
            ) {
                mode.current = dataMode
                setClickedFeatures([])
            }
        } else {
            mode.current = dataMode
            selection.current = layerSelection
        }
    }, [dataMode, layerSelection, setClickedFeatures])

    function layerSelected(id) {
        return !!layerSelection[dataMode][id] && layer === id
    }

    return (
        <>
            {!modalVisibility.viewLoginMessage && (
                <>
                    {layer === 'aerodromes' && <AerodromeModal />}
                    {layer === 'webcams' && <WebcamModal />}
                    {layer === 'aws' && <AwsModal />}
                    {layerSelected('notam') && <NotamModal />}
                    {layerSelected('rpas') && <RpasModal />}
                    {(layerSelected('sigwx') ||
                        layer === 'sigwx-symbol-static' ||
                        (mapDisabled &&
                            dataMode === 'advs' &&
                            layerSelection.advs.sigwx &&
                            clickedFeatures?.layer === 'sigwx')) && (
                        <SigwxModal />
                    )}
                    {(layerSelected('sigmet') ||
                        (mapDisabled &&
                            dataMode === 'advs' &&
                            layerSelection.advs.sigmet &&
                            clickedFeatures?.layer === 'sigmet')) && (
                        <SigmetModal />
                    )}
                    {layerSelected('grafor') && <GraforModal />}
                    {layerSelected('aaw') && <AAWModal />}
                </>
            )}
        </>
    )
}
