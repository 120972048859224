export function code23Decode(value) {
    switch (value) {
        case 'AA':
            return 'MINIMUM ALTITUDE'
        case 'AC':
            return 'CONTROL ZONE'
        case 'AD':
            return 'ADIZ'
        case 'AE':
            return 'CONTROL AREA'
        case 'AF':
            return 'FLIGHT INFORMATION REGION'
        case 'AG':
            return 'GENERAL FACILITY'
        case 'AH':
            return 'UPPER CONTROL AREA'
        case 'AL':
            return 'MINIMUM USABLE FLIGHT LEVEL'
        case 'AN':
            return 'AIR NAVIGATION ROUTE'
        case 'AO':
            return 'OCEANIC CONTROL ZONE'
        case 'AP':
            return 'REPORTING POINT'
        case 'AR':
            return 'ATS ROUTE'
        case 'AT':
            return 'TERMINAL CONTROL AREA'
        case 'AU':
            return 'UPPER FLIGHT INFORMATION REGION'
        case 'AV':
            return 'UPPER ADVISORY AREA'
        case 'AX':
            return 'INTERSECTION'
        case 'AZ':
            return 'AERODROME TRAFFIC ZONE'
        case 'CA':
            return 'AIR/GROUND FACILITY'
        case 'CB':
            return 'AUTOMATIC DEPENDENT SURVEILLANCE - BROADCAST'
        case 'CC':
            return 'AUTOMATIC DEPENDENT SURVEILLANCE - CONTRACT'
        case 'CD':
            return 'CONTROLLER-PILOT DATA LINK COMMUNICATIONS'
        case 'CE':
            return 'ENROUTE SURVELLENCE RADAR'
        case 'CG':
            return 'GROUND CONTROLLED APPROACH SYSTEM'
        case 'CL':
            return 'SELECTIVE CALLING SYSTEM'
        case 'CM':
            return 'SURFACE MOVEMENT RADAR'
        case 'CP':
            return 'PAR'
        case 'CR':
            return 'SURVEILLANCE RADAR ELEMENT OF PAR SYSTEM'
        case 'CS':
            return 'SECONDARY SURVEILLANCE RADAR'
        case 'CT':
            return 'TERMINAL AREA SURVEILLANCE RADAR'
        case 'FA':
            return 'AERODROME'
        case 'FB':
            return 'BRAKING ACTION MEASUREMENT EQUIPMENT'
        case 'FC':
            return 'CEILING MEASUREMENT EQUIPMENT'
        case 'FD':
            return 'DOCKING SYSTEM'
        case 'FE':
            return 'OXYGEN'
        case 'FF':
            return 'FIRE FIGHTING AND RESCUE'
        case 'FG':
            return 'GROUND MOVEMENT CONTROL'
        case 'FH':
            return 'HELICOPTER ALIGHTING AREA/PLATFORM'
        case 'FI':
            return 'AIRCRAFT DE-ICING'
        case 'FJ':
            return 'OILS'
        case 'FL':
            return 'LANDING DIRECTION INDICATOR'
        case 'FM':
            return 'METEOROLOGICAL SERVICE'
        case 'FO':
            return 'FOG DISPERSAL SYSTEM'
        case 'FP':
            return 'HELIPORT'
        case 'FS':
            return 'SNOW REMOVAL EQUIPMENT'
        case 'FT':
            return 'TRANSMISSOMETER'
        case 'FU':
            return 'FUEL AVAILABILITY'
        case 'FW':
            return 'WIND DIRECTION INDICATOR'
        case 'FZ':
            return 'CUSTOMS'
        case 'GA':
            return 'GNSS AERODROME'
        case 'GB':
            return 'OPTICAL LANDING SYSTEM'
        case 'GC':
            return 'TRANSIENT MAINTENANCE'
        case 'GD':
            return 'STARTER UNIT'
        case 'GE':
            return 'SOAP'
        case 'GF':
            return 'DEMINERALIZED WATER'
        case 'GG':
            return 'OXYGEN'
        case 'GH':
            return 'OIL'
        case 'GI':
            return 'DRAG CHUTES'
        case 'GJ':
            return 'ASR'
        case 'GK':
            return 'PRECISION APPROACH LANDING SYSTEM'
        case 'GL':
            return 'FACSFAC'
        case 'GM':
            return 'FIRING RANGE'
        case 'GN':
            return 'NIGHT VISION GOGGLE OPERATIONS'
        case 'GO':
            return 'WARNING AREA'
        case 'GP':
            return 'ARRESTING GEAR MARKERS'
        case 'GQ':
            return 'PULSATING/STEADY VISUAL APPROACH SLOPE INDICATOR'
        case 'GR':
            return 'DIVERSE DEPARTURE'
        case 'GS':
            return 'NITROGEN'
        case 'GT':
            return 'IFR TAKE-OFF MINIMUMS AND DEPARTURE PROCEDURES'
        case 'GU':
            return 'DE-ICE'
        case 'GW':
            return 'GNSS AREA'
        case 'GV':
            return 'CLEAR ZONE'
        case 'GX':
            return 'RUNWAY DISTANCE REMAINING SIGNS'
        case 'GY':
            return 'HELO PAD'
        case 'GZ':
            return 'BASE OPERATIONS'
        case 'IC':
            return 'ILS'
        case 'ID':
            return 'DME ASSOCIATED WITH ILS'
        case 'IG':
            return 'GLIDE PATH (ILS)'
        case 'II':
            return 'INNER MARKER (ILS)'
        case 'IL':
            return 'LOCALIZER (ILS)'
        case 'IM':
            return 'MIDDLE MARKER (ILS)'
        case 'IN':
            return 'LOCALIZER'
        case 'IO':
            return 'OUTER MARKER (ILS)'
        case 'IS':
            return 'ILS CATEGORY I'
        case 'IT':
            return 'ILS CATEGORY II'
        case 'IU':
            return 'ILS CATEGORY III'
        case 'IW':
            return 'MLS'
        case 'IX':
            return 'LOCATOR, OUTER, (ILS)'
        case 'IY':
            return 'LOCATOR, MIDDLE (ILS)'
        case 'KK':
            return 'NOTAM CHECKLIST'
        case 'LA':
            return 'APPROACH LIGHTING SYSTEM'
        case 'LB':
            return 'AERODROME BEACON'
        case 'LC':
            return 'RUNWAY CENTERLINE LIGHTS'
        case 'LD':
            return 'LANDING DIRECTION INDICATOR LIGHTS'
        case 'LE':
            return 'RUNWAY EDGE LIGHTS'
        case 'LF':
            return 'SEQUENCED FLASHING LIGHTS'
        case 'LG':
            return 'PILOT-CONTROLLED LIGHTING'
        case 'LH':
            return 'HIGH INTENSITY RUNWAY LIGHTS'
        case 'LI':
            return 'RUNWAY END IDENTIFIER LIGHTS'
        case 'LJ':
            return 'RUNWAY ALIGNMENT INDICATOR LIGHTS'
        case 'LK':
            return 'CATEGORY II COMPONENTS OF APPROACH LIGHTING SYSTEM'
        case 'LL':
            return 'LOW INTENSITY RUNWAY LIGHTS'
        case 'LM':
            return 'MEDIUM INTENSITY RUNWAY LIGHTS'
        case 'LP':
            return 'PRECISION APPROACH PATH INDICATOR'
        case 'LR':
            return 'ALL LANDING AREA LIGHTING FACILITIES'
        case 'LS':
            return 'STOPWAY LIGHTS'
        case 'LT':
            return 'THRESHOLD LIGHTS'
        case 'LU':
            return 'HELICOPTER APPROACH PATH INDICATOR'
        case 'LV':
            return 'VISUAL APRROACH SLOPE INDICATOR'
        case 'LW':
            return 'HELIPORT LIGHTING'
        case 'LX':
            return 'TAXIWAY CENTER LINE LIGHTS'
        case 'LY':
            return 'TAXIWAY EDGE LIGHTS'
        case 'LZ':
            return 'RUNWAY TOUCH DOWN ZONE LIGHTS'
        case 'MA':
            return 'MOVEMENT AREA'
        case 'MB':
            return 'BEARING STRENGTH'
        case 'MC':
            return 'CLEARWAY'
        case 'MD':
            return 'DECLARED DISTANCES'
        case 'MG':
            return 'TAXIING GUIDANCE SYSTEM'
        case 'MH':
            return 'RUNWAY ARRESTING GEAR'
        case 'MK':
            return 'PARKING AREA'
        case 'MM':
            return 'DAYLIGHT MARKINGS'
        case 'MN':
            return 'APRON'
        case 'MO':
            return 'STOP BAR'
        case 'MP':
            return 'AIRCRAFT STANDS'
        case 'MR':
            return 'RUNWAY'
        case 'MS':
            return 'STOPWAY'
        case 'MT':
            return 'THRESHOLD'
        case 'MU':
            return 'RUNWAY TURNING BAY'
        case 'MW':
            return 'STRIP'
        case 'MX':
            return 'TAXIWAY'
        case 'MY':
            return 'RAPID EXIT TAXIWAY'
        case 'NA':
            return 'ALL RADIO NAVIGATION FACILITIES'
        case 'NB':
            return 'NDB'
        case 'NC':
            return 'DECCA'
        case 'ND':
            return 'DME'
        case 'NF':
            return 'FAN MARKER'
        case 'NL':
            return 'LOCATOR'
        case 'NM':
            return 'VOR/DME'
        case 'NN':
            return 'TACAN'
        case 'NT':
            return 'VORTAC'
        case 'NV':
            return 'VOR'
        case 'NX':
            return 'DIRECTION FINDING STATION'
        case 'OA':
            return 'AERONAUTICAL INFORMATION SERVICE'
        case 'OB':
            return 'OBSTACLE'
        case 'OE':
            return 'AIRCRAFT ENTRY REQUIREMENTS'
        case 'OL':
            return 'LIT OBSTACLE'
        case 'OR':
            return 'RESCUE COORDINATION CENTER'
        case 'PA':
            return 'STAR'
        case 'PB':
            return 'STANDARD VFR ARRIVAL'
        case 'PC':
            return 'CONTINGENCY PROCEDURES'
        case 'PD':
            return 'SID'
        case 'PE':
            return 'STANDARD VFR DEPARTURE'
        case 'PF':
            return 'FLOW CONTROL PROCEDURES'
        case 'PH':
            return 'HOLDING PROCEDURES'
        case 'PI':
            return 'INSTRUMENT APPROACH PROCEDURE'
        case 'PK':
            return 'VFR APPROACH PROCEDURE'
        case 'PL':
            return 'OBSTACLE CLEARANCE LIMIT'
        case 'PM':
            return 'AERODROME OPERATING MINIMA'
        case 'PN':
            return 'NOISE OPERATING RESTRICTIONS'
        case 'PO':
            return 'OBSTACLE CLEARANCE ALTITUDE'
        case 'PP':
            return 'OBSTACLE CLEARANCE HEIGHT'
        case 'PR':
            return 'RADIO FAILURE PROCEDURE'
        case 'PT':
            return 'TRANSITION ALTITUDE'
        case 'PU':
            return 'MISSED APPROACH PROCEDURE'
        case 'PX':
            return 'MINIMUM HOLDING ALTITUDE'
        case 'PZ':
            return 'ADIZ PROCEDURE'
        case 'RA':
            return 'AIRSPACE RESERVATION'
        case 'RD':
            return 'DANGER AREA'
        case 'RM':
            return 'MILITARY OPERATING AREA'
        case 'RO':
            return 'OVERFLYING OF...'
        case 'RP':
            return 'PROHIBITED AREA'
        case 'RR':
            return 'RESTRICTED AREA'
        case 'RT':
            return 'TEMPORARY RESTRICTED AREA'
        case 'SA':
            return 'ATIS'
        case 'SB':
            return 'ATS REPORT OFFICE'
        case 'SC':
            return 'AREA CONTROL CENTER'
        case 'SE':
            return 'FLIGHT INFORMATION SERVICE'
        case 'SF':
            return 'AERODROME FLIGHT INFORMATION SERVICE'
        case 'SL':
            return 'FLOW CONTROL CENTER'
        case 'SO':
            return 'OCEANIC AREA CONTROL CENTER'
        case 'SP':
            return 'APPROACH CONTROL'
        case 'SS':
            return 'FLIGHT SERVICE STATION'
        case 'ST':
            return 'AERODROME CONTROL TOWER'
        case 'SU':
            return 'UPPER AREA CONTROL CENTER'
        case 'SV':
            return 'VOLMENT BROADCAST'
        case 'SY':
            return 'UPPER ADVISORY SERVICE'
        case 'WA':
            return 'AIR DISPLAY'
        case 'WB':
            return 'AEROBATICS'
        case 'WC':
            return 'CAPTIVE BALLOON OR KITE'
        case 'WD':
            return 'DEMOLITION OF EXPLOSIVES'
        case 'WE':
            return 'EXERCISES'
        case 'WF':
            return 'AIR REFUELING'
        case 'WG':
            return 'GLIDER FLYING'
        case 'WH':
            return 'BLASTING'
        case 'WJ':
            return 'BANNER/TARGET TOWING'
        case 'WL':
            return 'ASCENT OF FREE BALLOON'
        case 'WM':
            return 'MISSLE, GUN OR ROCKET FIRING'
        case 'WP':
            return 'PARACHUTE JUMPING EXERCISE'
        case 'WR':
            return 'RADIOACTIVE MATERIALS OR TOXIC CHEMICALS'
        case 'WS':
            return 'BURNING OR BLOWING GAS'
        case 'WT':
            return 'MASS MOVEMENT OF ACFT'
        case 'WU':
            return 'UNMANNED AIRCRAFT'
        case 'WV':
            return 'FORMATION FLT'
        case 'WW':
            return 'SIGNIFICANT VOLCANIC ACTIVITY'
        case 'WY':
            return 'AERIAL SURVEY'
        case 'WZ':
            return 'MODEL FLYING'
        case 'XX':
            return 'OTHER'
        default:
            return 'UNKNOWN'
    }
}

export function code45Decode(value) {
    switch (value) {
        case 'AC':
            return 'WITHDRAWN FOR MAINTENANCE'
        case 'AD':
            return 'AVAILABLE FOR DAYLIGHT OPERATIONS'
        case 'AF':
            return 'FLIGHT CHECKED AND FOUND RELIABLE'
        case 'AG':
            return 'OPERATING BUT GROUND CHECKED ONLY, AWAITING FLIGHT CHECK'
        case 'AH':
            return 'HOURS OF SERVICE'
        case 'AK':
            return 'RESUMED NORMAL OPERATIONS'
        case 'AM':
            return 'MILITARY OPERATIONS ONLY'
        case 'AN':
            return 'AVAILABLE FOR NIGHT OPERATIONS'
        case 'AO':
            return 'OPERATIONAL'
        case 'AP':
            return 'PRIOR PERMISSION REQUIRED'
        case 'AQ':
            return 'COMPLETELY WITHDRAWN'
        case 'AR':
            return 'AVAILABLE, PRIOR PERMISSION REQUIRED'
        case 'AS':
            return 'UNSERVICEABLE'
        case 'AU':
            return 'NOT AVAILABLE'
        case 'AW':
            return 'COMPLETELY WITHDRAWN'
        case 'AX':
            return 'PREVIOUSLY PROMULGATED SHUTDOWN HAS BEEN CANCELLED'
        case 'CA':
            return 'ACTIVATION'
        case 'CC':
            return 'COMPLETE'
        case 'CD':
            return 'DEACTIVATION'
        case 'CE':
            return 'ERECTION'
        case 'CF':
            return 'FREQUENCY CHANGE'
        case 'CG':
            return 'DOWNGRADE'
        case 'CH':
            return 'CHANGE'
        case 'CI':
            return 'IDENTIFICATION OR RADIO CALL SIGN CHANGED'
        case 'CL':
            return 'REALIGNMENT'
        case 'CM':
            return 'DISPLACEMENT'
        case 'CO':
            return 'OPERATING'
        case 'CP':
            return 'OPERATING ON REDUCED POWER'
        case 'CR':
            return 'TEMPORARILY REPLACED'
        case 'CS':
            return 'INSTALLED'
        case 'CT':
            return 'ON TEST, DO NOT USE'
        case 'GA':
            return 'NOT COINCIDENTAL WITH ILS/PAR'
        case 'GB':
            return 'IN RAISED POSITION'
        case 'GC':
            return 'TAIL HOOK ONLY'
        case 'GD':
            return 'OFFICIAL BUSINESS ONLY'
        case 'GE':
            return 'EXPECT LANDING DELAY'
        case 'GF':
            return 'EXTENSIVE SERVICE DELAY'
        case 'GG':
            return 'UNUSABLE BEYOND'
        case 'GH':
            return 'UNUSABLE'
        case 'GI':
            return 'UNMONITORED'
        case 'GJ':
            return 'IN PROGRESS'
        case 'GK':
            return 'MODERATE'
        case 'GL':
            return 'SEVERE'
        case 'GM':
            return 'NOT ILLUMINATED'
        case 'GN':
            return 'FREQUENCY NOT AVAILABLE'
        case 'GO':
            return 'IS WET'
        case 'GV':
            return 'NOT AUTHORIZED'
        case 'HA':
            return 'BRAKING ACTION'
        case 'HB':
            return 'BRAKING COEFFICIENT'
        case 'HC':
            return 'COVERED BY COMPACTED SNOW TO A DEPTH'
        case 'HD':
            return 'COVERED BY DRY SNOW TO A DEPTH'
        case 'HE':
            return 'COVERED BY WATER TO A DEPTH'
        case 'HF':
            return 'TOTALLY FREE OF SNOW AND ICE'
        case 'HG':
            return 'GRASS CUTTING IN PROGRESS'
        case 'HH':
            return 'HAZARD'
        case 'HI':
            return 'COVERED BY ICE'
        case 'HJ':
            return 'LAUNCH PLANNED'
        case 'HK':
            return 'MIGRATION IN PROGRESS'
        case 'HL':
            return 'SNOW CLEARANCE COMPLETED'
        case 'HM':
            return 'MARKED'
        case 'HN':
            return 'COVERED BY WET SNOW OR SLUSH TO A DEPTH'
        case 'HO':
            return 'OBSCURED BY SNOW'
        case 'HP':
            return 'SNOW CLEARANCE IN PROGRESS'
        case 'HQ':
            return 'OPERATIONS CANCELLED'
        case 'HR':
            return 'STANDING WATER'
        case 'HS':
            return 'SANDING'
        case 'HT':
            return 'APPROACH ACCORDING TO SIGNAL AREA ONLY'
        case 'HU':
            return 'LAUNCH IN PROGRESS'
        case 'HV':
            return 'WORK COMPLETED'
        case 'HW':
            return 'WORK IN PROGRESS'
        case 'HX':
            return 'CONCENTRATION OF BIRDS'
        case 'HY':
            return 'SNOW BANKS EXIST'
        case 'HZ':
            return 'COVERED BY FROZEN RUTS AND RIDGES'
        case 'KK':
            return 'NOTAM CHECKLIST'
        case 'LA':
            return 'OPERATING ON AUXILIARY POWER SUPPLY'
        case 'LB':
            return 'RESERVED FOR AIRCRAFT BASED THEREIN'
        case 'LC':
            return 'CLOSURE'
        case 'LD':
            return 'UNSAFE'
        case 'LE':
            return 'OPERATING WITHOUT AUXILIARY POWER SUPPLY'
        case 'LF':
            return 'INTERFERENCE'
        case 'LG':
            return 'OPERATING WITHOUT IDENTIFICATION'
        case 'LH':
            return 'UNSERVICEABLE FOR AIRCRAFT HEAVIER THAN'
        case 'LI':
            return 'CLOSED TO IFR OPERATIONS'
        case 'LK':
            return 'OPERATING AS A FIXED LIGHT'
        case 'LL':
            return 'USABLE FOR LENGTH OF AND WIDTH'
        case 'LN':
            return 'CLOSED TO ALL NIGHT OPERATIONS'
        case 'LP':
            return 'PROHIBITED'
        case 'LR':
            return 'AIRCRAFT RESTRICTED TO RUNWAYS AND TAXIWAYS'
        case 'LS':
            return 'SUBJECT TO INTERRUPTION'
        case 'LT':
            return 'LIMITATION'
        case 'LV':
            return 'CLOSED TO VFR OPERATIONS'
        case 'LW':
            return 'WILL TAKE PLACE'
        case 'LX':
            return 'OPERATING BUT CAUTION ADVISED'
        case 'LY':
            return 'EFFECTIVE'
        case 'TT':
            return 'TRIGGER'
        case 'XX':
            return 'OTHER'
        default:
            return 'UNKNOWN'
    }
}

export function trafficDecode(value) {
    return {
        ifr: value.includes('I'),
        vfr: value.includes('V'),
    }
}
