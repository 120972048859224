import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useAdminRole } from '../../../auth/hooks'
import {
    Flex,
    Box,
    Text,
    Button,
    ButtonGroup,
    useBreakpointValue,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spinner,
    useDisclosure,
    useColorModeValue,
    HStack,
    Icon,
    Link,
    Portal,
} from '@chakra-ui/react'
import Image from '../../components/Image'
import { useSwipeable } from 'react-swipeable'
import { isMobileOnly, isIOS, isDesktop } from 'react-device-detect'
import { useQuery } from 'react-query'
import { isFirefox } from 'react-device-detect'
import { parseISO, formatDistanceToNow } from 'date-fns'
import { ReactComponent as sigmeticon } from '../../../icons/assets/sigmet.svg'
import { ReactComponent as InfoIcon } from '../../../icons/assets/info-nocircle.svg'
import { ReactComponent as PDFIcon } from '../../../icons/assets/pdf.svg'
import PinchDrag, { useScale } from '../components/PinchDrag'
import ZoomSlider from '../components/ZoomSlider'
import ErrorToggle from '../components/ErrorToggle'
import FullWidthError from '../components/FullWidthError'
import ErrorText from '../components/ErrorText'

import {
    timeZoneState,
    dataModeState,
    layerSelectionState,
    fullscreenModalVisibleHandler,
    pollingPaused,
    showErrorTogglesState,
} from '../../../globalState'
import formatDateTime from '../../../util/dateFormatter'

export default function SigmetModal() {
    const { getAccessTokenSilently } = useAuth0()

    const fetchSigmetCharts = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/sigmet/charts`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const isAdmin = useAdminRole()
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setPollingPaused = useSetRecoilState(pollingPaused)

    const [userGuideShow, setUserGuideShow] = useState(false)
    const [sigmetFir, setSigmetFir] = useState(null)
    const [sigmet, setSigmet] = useState([])
    const [imagesLoaded, setImagesLoaded] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const detailsShow = false // const [detailsShow, setDetailsShow] = useState(false)

    //TODO add and appropriate scale here
    const pageScale = useBreakpointValue({
        base: '95%',
        md: '60%',
        lg: '50%',
        xl: '40%',
    })

    const pageScaleNZZO = useBreakpointValue({
        base: '95%',
        md: '95%',
        lg: '95%',
        xl: '95%',
    })

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()
    const portalLevels = useBreakpointValue({
        base: { containerRef: mobile },
        md: { containerRef: desktop },
    })

    const { scale, setScale, xy, setXy } = useScale()
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0
    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)

    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    const [sigmetFirPosition, setSigmetFirPosition] = useState(0)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const timeZone = useRecoilValue(timeZoneState)
    const { data, status, refetch } = useQuery(
        `sigmet-charts`,
        fetchSigmetCharts,
        { refetchOnWindowFocus: false }
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    // MOBILE SLIDE CHANGE BY SWIPE LEFT/RIGHT
    const timeSwipe = useSwipeable({
        onSwipedLeft: () => {
            if (sigmetFirPosition > 0) {
                setSigmetFir(sigmet[sigmetFirPosition - 1].fir)
                setSigmetFirPosition(sigmetFirPosition - 1)
            }
        },
        onSwipedRight: () => {
            if (sigmetFirPosition < 2) {
                setSigmetFir(sigmet[sigmetFirPosition + 1].fir)
                setSigmetFirPosition(sigmetFirPosition + 1)
            }
        },
    })

    // USERGUIDE ON MOBILE SHOW BY SWIPE DOWN/UP
    const userGuideSwipe = useSwipeable({
        onSwipedDown: () => {
            setUserGuideShow(true)
        },
        onSwipedUp: () => {
            setUserGuideShow(false)
        },
    })

    // This pauses the network connection check until it is loaded.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    useEffect(() => {
        // console.log("sigmet", {data})
        if (data) {
            sigmetRef.current = data
            setSigmet(data)
            setSigmetFir(data[1]?.fir, 0)
        }
    }, [data])

    const sigmetRef = useRef(null)

    useEffect(() => {
        if (dataMode === 'met' && layerSelection?.met?.sigmetCharts) {
            sigmetRef.current && sigmetRef.current.length && refetch()
            setLoaded(false)
            setImagesLoaded(0)
            onOpen()
        } else {
            onClose()
        }
    }, [
        onOpen,
        onClose,
        dataMode,
        layerSelection,
        handleFullscreenModal,
        refetch,
    ])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    useEffect(() => {
        // console.log({ imagesLoaded })
        sigmet.length && setLoaded(imagesLoaded === sigmet.length)
    }, [sigmet.length, imagesLoaded])

    useEffect(() => {
        if (showNetworkErrors) {
            setImagesLoaded(0)
        }
    }, [showNetworkErrors])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    function onImageLoad() {
        setImagesLoaded((i) => i + 1)
    }

    function isOutOfDate() {
        const itemData =
            data && sigmetFir && data.filter((i) => sigmetFir === i.fir)[0]
        if (itemData) {
            return { outofdate: itemData.outofdate, updated: itemData.updated }
        } else {
            return { outofdate: false, updated: null }
        }
    }

    const errorShow = showOutdatedErrors || isOutOfDate().outofdate

    // console.log('sigmetFir', sigmetFir)
    // console.log('sigmet', sigmet)

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            md: undefined,
                        }}
                    >
                        <Flex
                            direction="column"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            minHeight={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            // added flex here as header height not sticking causing spacing issues elswhere
                        >
                            <ModalHeader
                                backgroundColor={useColorModeValue(
                                    'light.10',
                                    'light.10'
                                )}
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        isAdmin && showErrorToggle
                                            ? '100px'
                                            : '70px',
                                    md: '70px',
                                }}
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    // base: '0px 5px 10px -1px rgba(0,0,0,0.05)',
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Flex
                                        {...userGuideSwipe}
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            paddingLeft={{
                                                base: '30px',
                                                md: '0',
                                            }}
                                            paddingTop={{
                                                base: '3px',
                                                md: '0',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '32px',
                                                }}
                                                color="light.100"
                                                as={sigmeticon}
                                                ml={{ base: '0px', md: '4px' }}
                                            />
                                            <Box
                                                pl={{ base: '5px', md: '10px' }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color={useColorModeValue(
                                                        'light.100',
                                                        'light.100'
                                                    )}
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                >
                                                    SIGMET Charts
                                                </Text>
                                            </Box>
                                        </Flex>
                                        <Portal {...portalLevels}>
                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                                display={{
                                                    base: 'flex',
                                                    md:
                                                        isAdmin &&
                                                        showErrorToggle
                                                            ? 'none'
                                                            : 'flex',
                                                }}
                                            >
                                                <Link
                                                    {...noFocus}
                                                    target="_blank"
                                                    opacity={{
                                                        base: '1',
                                                        md: '0.7',
                                                    }}
                                                    _hover={{ opacity: '1' }}
                                                    href="https://www.icao.int/APAC/Documents/edocs/WS-SIGMET.pdf"
                                                >
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        width="auto"
                                                        fontSize={{
                                                            base: '0.8rem',
                                                            md: '12px',
                                                        }}
                                                        lineHeight="1.3"
                                                        color="gray.500"
                                                        fontWeight={{
                                                            base: '500',
                                                            md: '400',
                                                        }}
                                                    >
                                                        <PDFIcon
                                                            width="20px"
                                                            height="20px"
                                                        />
                                                        <Text
                                                            pl="5px"
                                                            whiteSpace="pre"
                                                            pr="10px"
                                                        >
                                                            SIGMET Quick
                                                            Reference Guide
                                                        </Text>
                                                    </Flex>
                                                </Link>
                                            </Flex>
                                        </Portal>
                                        <Box ref={desktop}></Box>
                                        <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                            display={{
                                                base: 'inline-flex',
                                                md: 'none',
                                            }}
                                            background="none"
                                            color="light.100"
                                            // opacity={detailsShow ? '1' : '0.8'}
                                            fontSize="10px"
                                            fontWeight="600"
                                            height="40px"
                                            width="40px"
                                            alignItems="center"
                                            justifyContent="center"
                                            paddingLeft="2px"
                                            paddingRight="2px"
                                            borderRadius="10px"
                                            onClick={() => {
                                                setUserGuideShow(
                                                    (prevState) => !prevState
                                                )
                                            }}
                                            _focus={{
                                                opacity: '1 !important',
                                            }}
                                            _active={{
                                                background: 'none',
                                            }}
                                            _hover={{
                                                opacity: '1 !important',
                                            }}
                                        >
                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                                borderRadius="10px"
                                                color="light.100"
                                                variant="outline"
                                                fontSize="1rem"
                                                height="30px"
                                                width="34px"
                                                style={{
                                                    minWidth: '34px',
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                    background: 'light.20',
                                                }}
                                                _active={{
                                                    background: {
                                                        base: 'none',
                                                        md: 'light.20',
                                                    },
                                                    boxShadow: {
                                                        base: 'none',
                                                        md: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                    },
                                                }}
                                            >
                                                <Icon
                                                    as={InfoIcon}
                                                    boxSize={5}
                                                />
                                            </Flex>
                                        </Button>
                                    </Flex>
                                    <Flex
                                        direction={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        w={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? '100%'
                                                    : '0',
                                            md:
                                                isAdmin && showErrorToggle
                                                    ? 'max-content'
                                                    : '0',
                                        }}
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                        pl="10px"
                                    >
                                        <HStack
                                            spacing="15px"
                                            align="center"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-end',
                                            }}
                                            whiteSpace="pre"
                                        >
                                            <ErrorToggle
                                                showErrors={showNetworkErrors}
                                                setShowErrors={
                                                    setShowNetworkErrors
                                                }
                                                label="Simulate Network Error"
                                            />
                                            <ErrorToggle
                                                showErrors={showOutdatedErrors}
                                                setShowErrors={
                                                    setShowOutdatedErrors
                                                }
                                                label="Simulate Out-of-Date Error"
                                            />
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                        </Flex>
                        <ModalBody
                            p={0}
                            height={{
                                base:
                                    isMobileOnly && isIOS
                                        ? 'calc(100% - 155px)'
                                        : 'calc(100% - 140px)',
                                md: '100%',
                            }}
                            bg={{
                                base: '#edf1f7',
                                md: '#f4f7fc',
                            }}
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            {status !== 'error' && !showNetworkErrors ? (
                                <Box
                                    id="graforContainer"
                                    height="100%"
                                    ref={sigmetRef}
                                >
                                    {sigmet && (
                                        <Box
                                            bg={{
                                                base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 100%)',
                                                md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 100%)',
                                            }}
                                            h="100%"
                                            w={{ base: '100%', md: '100%' }}
                                            fontFamily="Open Sans"
                                            zIndex="1"
                                            marginTop={0}
                                            marginBottom={0}
                                            overflow="hidden"
                                        >
                                            <Box
                                                zIndex="2"
                                                h="100%"
                                                position="relative"
                                            >
                                                {sigmet &&
                                                    sigmet.length > 0 && (
                                                        <>
                                                            <Flex // TOP TOOLBAR CONTAINER
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                width="100%"
                                                                zIndex="2"
                                                                position="relative"
                                                                pl="23px"
                                                                pr="22px"
                                                                py="5px"
                                                                bg="light.600"
                                                                boxShadow={{
                                                                    base: '0px 5px 30px -5px rgba(0,0,0,0.15)',
                                                                    lg: 'none',
                                                                }}
                                                                borderBottom="1px solid #E2E8F0"
                                                            >
                                                                <Flex // Issued Time
                                                                    flexDirection="row"
                                                                    alignItems="center"
                                                                    paddingStart={{
                                                                        base: '5px',
                                                                        md: '0',
                                                                    }}
                                                                    color="light.100"
                                                                    opacity={
                                                                        detailsShow
                                                                            ? '0'
                                                                            : '1'
                                                                    }
                                                                    width="100%"
                                                                    maxWidth={{
                                                                        base: '100%',
                                                                        md: '250px',
                                                                    }}
                                                                    transition="opacity ease-in-out 300ms"
                                                                >
                                                                    <Text
                                                                        paddingStart="0"
                                                                        paddingEnd="2"
                                                                        fontWeight="bold"
                                                                        width="65px"
                                                                        fontSize="0.8rem"
                                                                        lineHeight="1.3"
                                                                    >
                                                                        Issued:
                                                                    </Text>
                                                                    {sigmet.map(
                                                                        (
                                                                            l,
                                                                            i
                                                                        ) => (
                                                                            <Text
                                                                                key={`GraforTime${l.fir}`}
                                                                                display={
                                                                                    sigmetFir ===
                                                                                    l.fir
                                                                                        ? 'block'
                                                                                        : 'none'
                                                                                }
                                                                                paddingStart="0"
                                                                                paddingEnd="0"
                                                                                fontWeight="400"
                                                                                py="0px"
                                                                                fontSize="0.8rem"
                                                                                lineHeight="1.3"
                                                                                borderColor="transparent"
                                                                            >
                                                                                {l?.issued &&
                                                                                    formatDateTime(
                                                                                        l.issued,
                                                                                        timeZone
                                                                                    )}
                                                                            </Text>
                                                                        )
                                                                    )}
                                                                </Flex>

                                                                {sigmetFir && (
                                                                    <Flex // FIR Buttons
                                                                        {...timeSwipe}
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        position={{
                                                                            base: 'fixed',
                                                                            lg: 'relative',
                                                                        }}
                                                                        mb={{
                                                                            base: minimiseOnZoom
                                                                                ? '-90px'
                                                                                : '-1px',
                                                                            md: '-1px',
                                                                        }}
                                                                        transition="all ease 300ms"
                                                                        bottom={{
                                                                            base:
                                                                                isMobileOnly &&
                                                                                isIOS
                                                                                    ? '85px'
                                                                                    : '70px',
                                                                            md: '0px',
                                                                            lg: 'unset',
                                                                        }}
                                                                        pt={{
                                                                            base: '15px',
                                                                            lg: '0',
                                                                        }}
                                                                        pb={{
                                                                            base: '20px',
                                                                            lg: '0',
                                                                        }}
                                                                        left={{
                                                                            base: '0px',
                                                                            lg: 'unset',
                                                                        }}
                                                                        bg={{
                                                                            base: isFirefox
                                                                                ? 'rgba(255,255,255,0.97)'
                                                                                : 'light.55',
                                                                            lg: 'transparent',
                                                                        }}
                                                                        zIndex="1"
                                                                        borderBottom={{
                                                                            base: '1px solid #e9edf3',
                                                                            md: 'none',
                                                                        }}
                                                                        boxShadow={{
                                                                            base: '0px -5px 30px -5px rgba(0,0,0,0.15)',
                                                                            lg: 'none',
                                                                        }}
                                                                        clipPath="inset(-50px 0px 0px 0px)"
                                                                        backdropFilter="blur(20px)"
                                                                        width={{
                                                                            base: '100%',
                                                                            lg: 'auto',
                                                                        }}
                                                                    >
                                                                        <ButtonGroup
                                                                            borderRadius="20px"
                                                                            px="2px"
                                                                            overflow="hidden"
                                                                            bg="rgba(8, 38, 71, 0.04)"
                                                                            boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.35)"
                                                                        >
                                                                            {sigmet
                                                                                .sort(
                                                                                    (
                                                                                        a,
                                                                                        b
                                                                                    ) => {
                                                                                        const firA =
                                                                                            a.fir
                                                                                        const firB =
                                                                                            b.fir
                                                                                        if (
                                                                                            firA <
                                                                                            firB
                                                                                        ) {
                                                                                            return -1
                                                                                        }
                                                                                        if (
                                                                                            firA >
                                                                                            firB
                                                                                        ) {
                                                                                            return 1
                                                                                        } else {
                                                                                            return 0
                                                                                        }
                                                                                    }
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        l,
                                                                                        i
                                                                                    ) => (
                                                                                        <Button
                                                                                            key={`SigmetBriefing${l.fir}`}
                                                                                            bg="transparent"
                                                                                            variant="outline"
                                                                                            fontSize="0.9rem"
                                                                                            fontWeight="600"
                                                                                            height={{
                                                                                                base: '32px',
                                                                                                md: '28px',
                                                                                            }}
                                                                                            width={{
                                                                                                base: '88px',
                                                                                                md: '88px',
                                                                                            }}
                                                                                            marginInlineStart="0px !important"
                                                                                            border="none"
                                                                                            justifyContent="center"
                                                                                            paddingLeft="2px"
                                                                                            paddingRight="2px"
                                                                                            py="2px"
                                                                                            {...noFocus}
                                                                                            onClick={() => {
                                                                                                setSigmetFir(
                                                                                                    l.fir
                                                                                                )
                                                                                                setSigmetFirPosition(
                                                                                                    i
                                                                                                )
                                                                                            }}
                                                                                            zIndex="1"
                                                                                            _active={{
                                                                                                bg: 'none',
                                                                                            }}
                                                                                            _focus={{
                                                                                                bg: 'none',
                                                                                            }}
                                                                                            _hover={{
                                                                                                bg: 'none',
                                                                                                opacity:
                                                                                                    '1',
                                                                                            }}
                                                                                        >
                                                                                            <Text
                                                                                                zIndex="1"
                                                                                                paddingLeft="0px"
                                                                                                lineHeight="1.3"
                                                                                                textTransform="uppercase"
                                                                                                fontSize="0.7rem"
                                                                                                color={
                                                                                                    sigmetFir ===
                                                                                                    l.fir
                                                                                                        ? 'light.200'
                                                                                                        : 'light.100'
                                                                                                }
                                                                                                fontWeight={
                                                                                                    sigmetFir ===
                                                                                                    l.fir
                                                                                                        ? '700'
                                                                                                        : '600'
                                                                                                }
                                                                                                opacity={
                                                                                                    sigmetFir ===
                                                                                                    l.fir
                                                                                                        ? '1'
                                                                                                        : '0.6'
                                                                                                }
                                                                                                _active={{
                                                                                                    color:
                                                                                                        sigmetFir ===
                                                                                                        l.fir
                                                                                                            ? 'light.10'
                                                                                                            : 'light.100',
                                                                                                    fontWeight:
                                                                                                        sigmetFir ===
                                                                                                        l.fir
                                                                                                            ? '700'
                                                                                                            : '600',
                                                                                                    opacity:
                                                                                                        sigmetFir ===
                                                                                                        l.fir
                                                                                                            ? '1'
                                                                                                            : '0.6',
                                                                                                }}
                                                                                                _selected={{
                                                                                                    color: 'light.10',
                                                                                                    fontWeight:
                                                                                                        '700',
                                                                                                    opacity:
                                                                                                        '1',
                                                                                                }}
                                                                                                transition="opacity 400ms, color 400ms, fontWeight ease-out 400ms"
                                                                                            >
                                                                                                {
                                                                                                    l.fir
                                                                                                }
                                                                                            </Text>
                                                                                        </Button>
                                                                                    )
                                                                                )}
                                                                            <Flex // BUTTON INDICATOR
                                                                                position="absolute"
                                                                                marginInlineStart="0px !important"
                                                                                mt="2px"
                                                                                ml="0px"
                                                                                px="44px"
                                                                                height={{
                                                                                    base: '28px',
                                                                                    md: '24px',
                                                                                }}
                                                                                width="74px"
                                                                                background="white"
                                                                                borderRadius="20px"
                                                                                bg="light.10"
                                                                                boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                transform={`translateX(${sigmetFirPosition}00%)`}
                                                                                transition="transform 400ms"
                                                                            ></Flex>
                                                                        </ButtonGroup>
                                                                    </Flex>
                                                                )}

                                                                <Flex
                                                                    color="light.100"
                                                                    justifyContent="flex-end"
                                                                    alignItems="center"
                                                                    width={{
                                                                        base: '40px',
                                                                        md: '100%',
                                                                    }}
                                                                    opacity={{
                                                                        base: minimiseActive
                                                                            ? '1'
                                                                            : '0',
                                                                        md: '1',
                                                                    }}
                                                                    visibility={{
                                                                        base: minimiseActive
                                                                            ? 'visible'
                                                                            : 'hidden',
                                                                        md: 'visible',
                                                                    }}
                                                                    transition="all ease 300ms"
                                                                >
                                                                    <ZoomSlider
                                                                        scale={
                                                                            scale
                                                                        }
                                                                        xy={xy}
                                                                        setScale={
                                                                            setScale
                                                                        }
                                                                        setXy={
                                                                            setXy
                                                                        }
                                                                        maxScale={
                                                                            3
                                                                        }
                                                                    />
                                                                </Flex>
                                                            </Flex>
                                                            <Flex // IMAGE CONTAINER
                                                                direction="column"
                                                                position={{
                                                                    base: 'relative',
                                                                }}
                                                                w={{
                                                                    base: '100%',
                                                                }}
                                                                h="calc(100vh - 115px)"
                                                                overflow={
                                                                    'hidden'
                                                                }
                                                                pt={{
                                                                    base: errorShow
                                                                        ? '0'
                                                                        : '20px',
                                                                    md: errorShow
                                                                        ? '0px'
                                                                        : '25px',
                                                                }}
                                                                px="10px"
                                                                pb={{
                                                                    base: '0px',
                                                                    md: '0px',
                                                                }}
                                                                zIndex="1"
                                                            >
                                                                {data &&
                                                                    errorShow && (
                                                                        <Flex
                                                                            px="15px"
                                                                            pt="10px"
                                                                            mb="15px"
                                                                            justifyContent="center"
                                                                        >
                                                                            <ErrorText>
                                                                                There
                                                                                has
                                                                                been
                                                                                an
                                                                                issue
                                                                                with
                                                                                the
                                                                                connection
                                                                                between
                                                                                PreFlight
                                                                                and
                                                                                MetService.
                                                                                This
                                                                                SIGMET
                                                                                chart
                                                                                was
                                                                                last
                                                                                validated
                                                                                with
                                                                                MetService
                                                                                more
                                                                                than{' '}
                                                                                <strong>
                                                                                    {formatDistanceToNow(
                                                                                        parseISO(
                                                                                            isOutOfDate()
                                                                                                .updated
                                                                                        ),
                                                                                        {
                                                                                            addSuffix: true,
                                                                                        }
                                                                                    )}
                                                                                </strong>
                                                                                .
                                                                                <br />
                                                                                There
                                                                                is
                                                                                a
                                                                                possibility
                                                                                that
                                                                                this
                                                                                chart
                                                                                is
                                                                                out
                                                                                of
                                                                                date.
                                                                                Check
                                                                                back
                                                                                again
                                                                                soon,
                                                                                or
                                                                                alternatively
                                                                                visit{' '}
                                                                                <a
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            '500',
                                                                                        textDecoration:
                                                                                            'underline',
                                                                                    }}
                                                                                    href="https://ifis.airways.co.nz/"
                                                                                >
                                                                                    IFIS
                                                                                </a>

                                                                                .
                                                                            </ErrorText>{' '}
                                                                        </Flex>
                                                                    )}

                                                                <Box
                                                                    className="desktopLink"
                                                                    display="flex"
                                                                    ref={mobile}
                                                                    flexDirection={{
                                                                        base: 'row',
                                                                        md: 'column',
                                                                    }}
                                                                    alignItems={{
                                                                        base: 'center',
                                                                        md: 'flex-end',
                                                                    }}
                                                                    justifyContent={{
                                                                        base: 'center',
                                                                        md: 'flex-end',
                                                                    }}
                                                                    mt={{
                                                                        base: userGuideShow
                                                                            ? '10px'
                                                                            : '-30px',
                                                                        md: '0px',
                                                                    }}
                                                                    height={{
                                                                        base: userGuideShow
                                                                            ? '37px'
                                                                            : '0px',
                                                                        md: '0px',
                                                                    }}
                                                                    px={{
                                                                        base: '25px',
                                                                        md: '25px',
                                                                    }}
                                                                    pb={{
                                                                        base: '30px',
                                                                        md: '0px',
                                                                    }}
                                                                    transition="all ease-in-out 300ms"
                                                                    width={{
                                                                        base: '100%',
                                                                        md: 'unset',
                                                                    }}
                                                                    color="light.100"
                                                                ></Box>
                                                                <PinchDrag
                                                                    scale={
                                                                        scale
                                                                    }
                                                                    setScale={
                                                                        setScale
                                                                    }
                                                                    xy={xy}
                                                                    setXy={
                                                                        setXy
                                                                    }
                                                                    maxZoom={3}
                                                                    maxZoomNewScale={
                                                                        2.999
                                                                    }
                                                                >
                                                                    {!loaded && (
                                                                        <Flex
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                            width={
                                                                                '100%'
                                                                            }
                                                                            pt="25vh"
                                                                            px={{
                                                                                base: '10px',
                                                                                md: '30px',
                                                                            }}
                                                                        >
                                                                            <Spinner
                                                                                color={
                                                                                    'light.200'
                                                                                }
                                                                                thickness="3px"
                                                                                speed="0.45s"
                                                                                boxSize="60px"
                                                                            />
                                                                            {/* <Skeleton
                                                                            width={{
                                                                                base:
                                                                                    '100%',
                                                                                md:
                                                                                    '95%',
                                                                            }}
                                                                            h={
                                                                                '100%'
                                                                            }
                                                                            borderRadius="20px"
                                                                        /> */}
                                                                        </Flex>
                                                                    )}
                                                                    {sigmet
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) => {
                                                                                const firA =
                                                                                    a.fir
                                                                                const firB =
                                                                                    b.fir
                                                                                if (
                                                                                    firA <
                                                                                    firB
                                                                                ) {
                                                                                    return -1
                                                                                }
                                                                                if (
                                                                                    firA >
                                                                                    firB
                                                                                ) {
                                                                                    return 1
                                                                                } else {
                                                                                    return 0
                                                                                }
                                                                            }
                                                                        )
                                                                        .map(
                                                                            (
                                                                                image,
                                                                                i
                                                                            ) => (
                                                                                <Image
                                                                                    key={`SigmetImage${image.fir}`}
                                                                                    maxWidth={
                                                                                        image.fir ===
                                                                                        'NZZO'
                                                                                            ? pageScaleNZZO
                                                                                            : pageScale
                                                                                    }
                                                                                    height={
                                                                                        loaded &&
                                                                                        sigmetFir ===
                                                                                            image.fir
                                                                                            ? 'auto'
                                                                                            : '0px'
                                                                                    }
                                                                                    opacity={
                                                                                        loaded &&
                                                                                        sigmetFir ===
                                                                                            image.fir
                                                                                            ? '1'
                                                                                            : '0'
                                                                                    }
                                                                                    onLoad={
                                                                                        onImageLoad
                                                                                    }
                                                                                    margin={
                                                                                        'auto'
                                                                                    }
                                                                                    transition="width 20ms"
                                                                                    src={`${window.location.origin}/metproxy?url=${image.url}`}
                                                                                    alt="SIGMET CHART UNAVAILABLE"
                                                                                />
                                                                            )
                                                                        )}
                                                                </PinchDrag>
                                                            </Flex>
                                                        </>
                                                    )}
                                                {isDesktop && (
                                                    <Text
                                                        width="140px"
                                                        textAlign="right"
                                                        display={{
                                                            base: 'none',
                                                            md: 'block',
                                                        }}
                                                        position="absolute"
                                                        bottom={{
                                                            base: '78px',
                                                            lg: '15px',
                                                        }}
                                                        right="15px"
                                                        color="gray.400"
                                                        fontWeight="400"
                                                        fontSize="0.65rem"
                                                        lineHeight="1.3"
                                                        opacity="0.7"
                                                    >
                                                        Click and hold on the
                                                        chart to drag it around
                                                        the screen
                                                    </Text>
                                                )}

                                                {sigmet.length < 1 && (
                                                    <Flex
                                                        fontFamily="Open Sans"
                                                        flexDirection="column"
                                                        width="100%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Text
                                                            paddingStart="0"
                                                            paddingEnd="0"
                                                            fontWeight="500"
                                                            py="40px"
                                                            minWidth="130px"
                                                            fontSize="0.9rem"
                                                            lineHeight="1.3"
                                                            borderColor="transparent"
                                                            color="gray.500"
                                                        >
                                                            No current SIGMET
                                                            data
                                                        </Text>
                                                    </Flex>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <FullWidthError
                                    message={`Error downloading SIGMET Charts.<br />Please try again later, or visit <a href="https://ifis.airways.co.nz/">IFIS</a>.`}
                                />
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
