import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function GraforIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M14 14.8622H10.7981L12.0921 13.5306C10.8028 12.2556 8.71548 12.2083 7.42622 13.4833C6.13697 14.7631 6.13697 16.8267 7.42622 18.1111C8.71548 19.3861 10.8028 19.3861 12.0921 18.1111C12.7344 17.4736 13.0555 16.7322 13.0555 15.7972H14C14 16.7322 13.5844 17.9458 12.7532 18.7675C11.0956 20.4108 8.40379 20.4108 6.74618 18.7675C5.09329 17.1289 5.07912 14.4656 6.73673 12.8269C8.39434 11.1883 11.0531 11.1883 12.7107 12.8269L14 11.5V14.8622Z"
                fill="currentColor"
            />
            <path
                d="M9.98584 13.8611V15.8681L11.6387 16.8503L11.2987 17.4217L9.27746 16.2222V13.8611H9.98584Z"
                fill="currentColor"
            />
            <path
                d="M14.9468 16.5H17.5C18.2886 16.5 19.0298 16.2972 19.6743 15.9409C19.6852 15.9375 19.6903 15.9347 19.6893 15.9326C20.1055 15.7004 20.4811 15.404 20.8026 15.0568C21.5458 14.2542 22.0002 13.1802 22.0002 12C22.0002 9.51472 19.8182 7.5 17.5 7.5L17.489 7.50001C17.4342 7.50014 17.3795 7.50126 17.3251 7.50334C16.2543 5.42855 14.0505 4 11.75 4C8.9028 4 6.50027 5.90385 5.746 8.50794C3.65521 8.63895 2 10.3762 2 12.5C2 13.7522 2.57542 14.8701 3.47627 15.6035C3.78869 15.8579 4.14025 16.066 4.52053 16.2175C4.50694 16.0635 4.5 15.9075 4.5 15.75C4.5 15.3516 4.54438 14.9635 4.62847 14.5905C4.57263 14.5538 4.51834 14.5149 4.46574 14.474C4.43831 14.4526 4.41133 14.4307 4.38482 14.4083C3.84357 13.9497 3.5 13.265 3.5 12.5C3.5 11.1193 4.61929 10 6 10H7.00647C7.13647 7.49286 9.21051 5.5 11.75 5.5C13.3735 5.5 14.9336 6.43612 15.7998 7.83233C15.1672 8.0907 14.6065 8.48893 14.1563 8.98858L15.264 10C15.798 9.40341 16.5672 9.02176 17.4259 9.0009C17.4505 9.0003 17.4752 9 17.5 9L17.5068 9.00001C17.5166 9.00003 17.5264 9.00011 17.5362 9.00025C17.5658 9.00065 17.5954 9.00154 17.625 9.00292C19.123 9.07279 20.5 10.3879 20.5 12C20.5 13.1164 19.8902 14.0904 18.9854 14.607C18.5475 14.8571 18.0404 15 17.5 15H15C15 15 15 15.4954 15 15.75C15 16.0046 14.9819 16.255 14.9468 16.5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
