import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    VStack,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../globalState'
import FAQs from '../../landing-page/components/FAQs'

export default function FAQsModal() {
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'faqs', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.faqs ? onOpen() : onClose()
    }, [modalVisibility.faqs, onOpen, onClose])

    function onCloseHandler() {
        setShow(false)
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={'faqs'}
                    size="6xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="light.200"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            Frequently Asked Questions
                        </ModalHeader>
                        <ModalBody style={{ padding: 0 }} fontSize="0.8rem">
                            <VStack
                                maxHeight={{
                                    base: 'calc(100vh - 250px)',
                                    md: '75vh',
                                }}
                                overflowY="scroll"
                                overflowX="hidden"
                                px={{ base: 2, md: 5 }}
                                pt={{ base: 8, md: 0 }}
                                pb={8}
                                spacing={4}
                                alignItems="baseline"
                            >
                                <FAQs />
                            </VStack>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
