import React from 'react'
import { useRecoilValue } from 'recoil'
import { Flex, Box, Text, Divider } from '@chakra-ui/react'
import { addHours, parseISO, formatISO } from 'date-fns'
import formatDateTime from '../../../../../util/dateFormatter'

import {
    timeZoneState,
    excludedAerodromesState,
    excludedAwsState,
    selectedBriefingTimeState,
    selectedBriefingAreasState,
    briefingAreaSourceState,
    selectedBriefingAltitudeState,
    briefingParamsState,
    favouriteTitleState,
} from '../../../../../globalState'

import '../../briefing.css'

export default function BriefingInfo(props) {
    const { data: briefingData, isPremium, currentFavourite } = props
    const briefingAreaSource = useRecoilValue(briefingAreaSourceState)
    const excludedAerodromes = useRecoilValue(excludedAerodromesState)
    const excludedAws = useRecoilValue(excludedAwsState)
    const timeZone = useRecoilValue(timeZoneState)
    const selectedAreas = useRecoilValue(selectedBriefingAreasState)
    const selectedHours = useRecoilValue(selectedBriefingTimeState)
    const maxAltitude = useRecoilValue(selectedBriefingAltitudeState)
    const favouriteTitle = useRecoilValue(favouriteTitleState)
    const params = useRecoilValue(briefingParamsState)
    const issuedTime = briefingData?.time && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)

    // Compile list of Selected Contents (selected from menu)
    const filteredContents = Object.keys(
        Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'aerodromes') // Remove 'aerodromes' group param
                .filter(([key, value]) => !(isPremium && key === 'aws')) // Remove 'aws' if isPremium
                .filter(([key, value]) => value === true) // Select only those ticked
        )
    )

    const selectedMetItemsToCapitalize = [
        'aws',
        'aaw',
        'sigmet',
        'sigwx',
        'msl',
        'grafor',
    ]
    const formattedContents = filteredContents.map((key) => {
        if (key === 'aerodromesNotam') {
            return 'NOTAM'
        } else if (key === 'aerodromesMetar') {
            return 'METAR'
        } else if (key === 'aerodromesTaf') {
            return 'TAF'
        } else if (key === 'aerodromesAtis') {
            return 'ATIS'
        } else if (key === 'enroute') {
            return 'En-route NOTAM'
        } else if (key === 'spacewx') {
            return 'Space Weather'
        } else if (selectedMetItemsToCapitalize.includes(key)) {
            // capitalize each word
            return key.toUpperCase()
        } else {
            return key.join(' ')
        }
    })

    return (
        <>
            <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                marginTop="auto"
                paddingBottom="10px"
                flexDirection="column"
            >
                {briefingData && (
                    <Box pl="15px" pr="5px" width="100%">
                        <Flex
                            opacity={{ base: '0.9', md: '0.9' }}
                            width="100%"
                            flexDirection="column"
                            fontSize={{ base: '0.65rem', smd: '0.75rem' }}
                            fontFamily="Helvetica, 'Open Sans', sans-serif"
                            gap={{
                                base: isPremium ? '5px' : '3px',
                                ml: '5px',
                            }}
                        >
                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Briefing ID:
                                </Text>
                                <Text
                                    alignSelf="left"
                                    color="gray.600"
                                    fontWeight="400"
                                    pl={{
                                        base: '10px',
                                        smd: '0px',
                                        md: '10px',
                                        ml: '0px',
                                    }}
                                >
                                    {briefingData.id}
                                </Text>
                            </Flex>
                            {favouriteTitle && currentFavourite && (
                                <Flex
                                    flexDirection={{
                                        base: 'column',
                                        smd: 'row',
                                        md: 'column',
                                        ml: 'row',
                                    }}
                                >
                                    <Text
                                        alignSelf="left"
                                        pr="5px"
                                        color="light.100"
                                        fontWeight="700"
                                        whiteSpace="pre"
                                        minWidth={{
                                            base: '180px',
                                            smd: '215px',
                                        }}
                                    >
                                        Favourite Loaded:
                                    </Text>
                                    <Text
                                        alignSelf="left"
                                        color="gray.600"
                                        fontWeight="400"
                                        pl={{
                                            base: '10px',
                                            smd: '0px',
                                            md: '10px',
                                            ml: '0px',
                                        }}
                                    >
                                        {favouriteTitle}
                                    </Text>
                                </Flex>
                            )}
                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Issued:
                                </Text>
                                <Text
                                    alignSelf="left"
                                    color="gray.600"
                                    fontWeight="400"
                                    pl={{
                                        base: '10px',
                                        smd: '0px',
                                        md: '10px',
                                        ml: '0px',
                                    }}
                                >
                                    {briefingData?.time &&
                                        formatDateTime(
                                            briefingData.time,
                                            timeZone
                                        )}
                                </Text>
                            </Flex>

                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Period To:
                                </Text>
                                <Flex gap="7px" alignItems="basline">
                                    <Text
                                        alignSelf="left"
                                        color="gray.600"
                                        fontWeight="400"
                                        pl={{
                                            base: '10px',
                                            smd: '0px',
                                            md: '10px',
                                            ml: '0px',
                                        }}
                                    >
                                        {periodTo &&
                                            formatDateTime(
                                                formatISO(periodTo),
                                                timeZone
                                            )}
                                    </Text>
                                    <Text fontWeight="300" color="gray.400">
                                        (+ {selectedHours} Hours)
                                    </Text>
                                </Flex>
                            </Flex>

                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Altitude Range Selected:
                                </Text>
                                <Text
                                    alignSelf="left"
                                    color="gray.600"
                                    fontWeight="400"
                                    pl={{
                                        base: '10px',
                                        smd: '0px',
                                        md: '10px',
                                        ml: '0px',
                                    }}
                                >
                                    SFC{' '}
                                    {maxAltitude === 0
                                        ? 'only'
                                        : maxAltitude < 13500
                                        ? `– ${
                                              maxAltitude >= 10000
                                                  ? maxAltitude.toLocaleString(
                                                        'en'
                                                    )
                                                  : maxAltitude
                                          } ft`
                                        : maxAltitude === 10000
                                        ? '– 10,000 ft'
                                        : `– FL${maxAltitude / 100}`}
                                </Text>
                            </Flex>

                            {briefingAreaSource === 'briefingarea' && (
                                <Flex
                                    flexDirection={{
                                        base: 'column',
                                        smd: 'row',
                                        md: 'column',
                                        ml: 'row',
                                    }}
                                >
                                    <Text
                                        alignSelf="left"
                                        pr="5px"
                                        color="light.100"
                                        fontWeight="700"
                                        whiteSpace="pre"
                                        minWidth={{
                                            base: '180px',
                                            smd: '215px',
                                        }}
                                    >
                                        Briefing Areas Selected:
                                    </Text>
                                    <Text
                                        alignSelf="left"
                                        color="gray.600"
                                        fontWeight="400"
                                        pl={{
                                            base: '10px',
                                            smd: '0px',
                                            md: '10px',
                                            ml: '0px',
                                        }}
                                    >
                                        {selectedAreas
                                            .map((area) => area.designator)
                                            .join(', ')}
                                    </Text>
                                </Flex>
                            )}

                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    {`${
                                        briefingAreaSource === 'briefingarea'
                                            ? 'AAW Areas Included:'
                                            : 'AAW Areas Selected:'
                                    }`}
                                </Text>
                                <Text
                                    alignSelf="left"
                                    color="gray.600"
                                    fontWeight="400"
                                    pl={{
                                        base: '10px',
                                        smd: '0px',
                                        md: '10px',
                                        ml: '0px',
                                    }}
                                >
                                    {briefingData.res.areamet &&
                                        briefingData.res.areamet.aaw &&
                                        briefingData.res.areamet.aaw
                                            .map((aaw) => aaw.area)
                                            .join(', ')}
                                </Text>
                            </Flex>

                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Contents Selected:
                                </Text>
                                <Flex flexWrap="wrap">
                                    <Text
                                        alignSelf="left"
                                        color="gray.600"
                                        fontWeight="400"
                                        pl={{
                                            base: '10px',
                                            smd: '0px',
                                            md: '10px',
                                            ml: '0px',
                                        }}
                                    >
                                        {formattedContents
                                            .map((area) => area)
                                            .join(', ')}
                                    </Text>
                                </Flex>
                            </Flex>

                            {!isPremium && (
                                <Divider
                                    borderColor="gray.200"
                                    width="100%"
                                    mx="auto"
                                    mt="5px"
                                    mb="5px"
                                />
                            )}

                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Aerodromes Selected:
                                </Text>
                                <Text
                                    alignSelf="left"
                                    color="gray.600"
                                    fontWeight="400"
                                    pl={{
                                        base: '10px',
                                        smd: '0px',
                                        md: '10px',
                                        ml: '0px',
                                    }}
                                >
                                    {briefingData.res.aerodromes &&
                                    briefingData.res.aerodromes.length === 0
                                        ? 'None'
                                        : briefingData.res.aerodromes &&
                                          briefingData.res.aerodromes
                                              .sort((a, b) => {
                                                  const aerodromeA =
                                                      a.designator
                                                  const aerodromeB =
                                                      b.designator
                                                  if (aerodromeA < aerodromeB) {
                                                      return -1
                                                  }
                                                  if (aerodromeA > aerodromeB) {
                                                      return 1
                                                  } else {
                                                      return 0
                                                  }
                                              })
                                              .map(
                                                  (aerodromes) =>
                                                      aerodromes.designator
                                              )
                                              .join(', ')}
                                </Text>
                            </Flex>

                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    whiteSpace="pre"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Aerodromes Excluded:{' '}
                                    <span
                                        style={{
                                            fontWeight: '300',
                                            opacity: '0.6',
                                        }}
                                    >
                                        (deselected)
                                    </span>
                                </Text>
                                <Text
                                    alignSelf="left"
                                    color="gray.600"
                                    fontWeight="400"
                                    pl={{
                                        base: '10px',
                                        smd: '0px',
                                        md: '10px',
                                        ml: '0px',
                                    }}
                                    width={{ base: undefined, xl: '100%' }}
                                >
                                    {excludedAerodromes &&
                                    excludedAerodromes.length === 0
                                        ? 'None'
                                        : excludedAerodromes &&
                                          excludedAerodromes
                                              .map((aerodromes) => aerodromes)
                                              .join(', ')}
                                </Text>
                            </Flex>

                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                    md: 'column',
                                    ml: 'row',
                                }}
                            >
                                <Text
                                    alignSelf="left"
                                    pr="5px"
                                    color="light.100"
                                    fontWeight="700"
                                    minWidth={{ base: '180px', smd: '215px' }}
                                >
                                    Aerodromes with No Information:
                                </Text>
                                <Text
                                    alignSelf="left"
                                    color="gray.600"
                                    fontWeight="400"
                                    pl={{
                                        base: '10px',
                                        smd: '0px',
                                        md: '10px',
                                        ml: '0px',
                                    }}
                                >
                                    {briefingData.res.aerodromes &&
                                    briefingData.res.aerodromes.filter(
                                        (aerodrome) =>
                                            !aerodrome.metar &&
                                            !aerodrome.taf &&
                                            !aerodrome.atis &&
                                            !aerodrome.notam
                                    ).length === 0
                                        ? 'None'
                                        : briefingData.res.aerodromes &&
                                          briefingData.res.aerodromes
                                              .filter(
                                                  (aerodrome) =>
                                                      !aerodrome.metar &&
                                                      !aerodrome.taf &&
                                                      !aerodrome.atis &&
                                                      !aerodrome.notam
                                              )
                                              .sort((a, b) => {
                                                  const aerodromeA =
                                                      a.designator
                                                  const aerodromeB =
                                                      b.designator
                                                  if (aerodromeA < aerodromeB) {
                                                      return -1
                                                  }
                                                  if (aerodromeA > aerodromeB) {
                                                      return 1
                                                  } else {
                                                      return 0
                                                  }
                                              })
                                              .map(
                                                  (aerodrome) =>
                                                      aerodrome.designator
                                              )
                                              .join(', ')}
                                </Text>
                            </Flex>

                            {/* AWS */}
                            {!isPremium && (
                                <>
                                    <Divider
                                        borderColor="gray.200"
                                        width="100%"
                                        mx="auto"
                                        mt="5px"
                                        mb="5px"
                                    />
                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            smd: 'row',
                                            md: 'column',
                                            ml: 'row',
                                        }}
                                    >
                                        <Text
                                            alignSelf="left"
                                            pr="5px"
                                            color="light.100"
                                            fontWeight="700"
                                            whiteSpace="pre"
                                            minWidth={{
                                                base: '180px',
                                                smd: '215px',
                                            }}
                                        >
                                            AWS Selected:
                                        </Text>
                                        <Text
                                            alignSelf="left"
                                            color="gray.600"
                                            fontWeight="400"
                                            pl={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '10px',
                                                ml: '0px',
                                            }}
                                        >
                                            {briefingData.res.aws &&
                                            briefingData.res.aws.length === 0
                                                ? 'None'
                                                : briefingData.res.aws &&
                                                  briefingData.res.aws
                                                      .sort((a, b) => {
                                                          const awsA =
                                                              a.designator
                                                          const awsB =
                                                              b.designator
                                                          if (awsA < awsB) {
                                                              return -1
                                                          }
                                                          if (awsA > awsB) {
                                                              return 1
                                                          } else {
                                                              return 0
                                                          }
                                                      })
                                                      .map(
                                                          (aws) =>
                                                              aws.designator
                                                      )
                                                      .join(', ')}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            smd: 'row',
                                            md: 'column',
                                            ml: 'row',
                                        }}
                                    >
                                        <Text
                                            alignSelf="left"
                                            pr="5px"
                                            color="light.100"
                                            fontWeight="700"
                                            whiteSpace="pre"
                                            minWidth={{
                                                base: '180px',
                                                smd: '215px',
                                            }}
                                        >
                                            AWS Excluded:{' '}
                                            <span
                                                style={{
                                                    fontWeight: '300',
                                                    opacity: '0.6',
                                                }}
                                            >
                                                (deselected)
                                            </span>
                                        </Text>
                                        <Text
                                            alignSelf="left"
                                            color="gray.600"
                                            fontWeight="400"
                                            pl={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '10px',
                                                ml: '0px',
                                            }}
                                            width={{
                                                base: undefined,
                                                xl: '100%',
                                            }}
                                        >
                                            {excludedAws &&
                                            excludedAws.length === 0
                                                ? 'None'
                                                : excludedAws &&
                                                  excludedAws
                                                      .map((aws) => aws)
                                                      .join(', ')}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            smd: 'row',
                                            md: 'column',
                                            ml: 'row',
                                        }}
                                    >
                                        <Text
                                            alignSelf="left"
                                            pr="5px"
                                            color="light.100"
                                            fontWeight="700"
                                            minWidth={{
                                                base: '180px',
                                                smd: '215px',
                                            }}
                                        >
                                            AWS with No Information:
                                        </Text>
                                        <Text
                                            alignSelf="left"
                                            color="gray.600"
                                            fontWeight="400"
                                            pl={{
                                                base: '10px',
                                                smd: '0px',
                                                md: '10px',
                                                ml: '0px',
                                            }}
                                        >
                                            {briefingData &&
                                                briefingData.res &&
                                                briefingData.res.aws &&
                                                briefingData.res.aws
                                                    .sort((a, b) => {
                                                        const awsA =
                                                            a.designator
                                                        const awsB =
                                                            b.designator
                                                        if (awsA < awsB) {
                                                            return -1
                                                        }
                                                        if (awsA > awsB) {
                                                            return 1
                                                        } else {
                                                            return 0
                                                        }
                                                    })
                                                    .filter(
                                                        (key) =>
                                                            !key.data ||
                                                            (key.data
                                                                ?.obs_timestamp &&
                                                                Object.keys(
                                                                    key.data
                                                                )?.length === 1)
                                                    )
                                                    .map(
                                                        (aws) => aws.designator
                                                    )
                                                    .join(', ')}
                                        </Text>
                                    </Flex>
                                </>
                            )}
                        </Flex>
                    </Box>
                )}
            </Flex>
        </>
    )
}
