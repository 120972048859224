import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AAWIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M12.4999 3.005C11.7043 3.005 10.9412 3.32106 10.3786 3.88367C9.81602 4.44628 9.74995 4.755 9.74995 4.755C9.74995 4.755 9.70173 4.9371 9.70995 5.055C9.72321 5.2453 9.76257 5.363 9.88995 5.505C10.0302 5.66133 10.1698 5.72777 10.3786 5.75C10.5244 5.76553 10.6132 5.74784 10.7499 5.695C10.9704 5.60983 11.0577 5.4721 11.1751 5.28679C11.1993 5.24873 11.2247 5.20866 11.2527 5.16663C11.4176 4.91995 11.6518 4.72773 11.9259 4.6142C12.2 4.50067 12.5016 4.47093 12.7926 4.52881C13.0836 4.58669 13.3508 4.72955 13.5606 4.93933C13.7704 5.14911 13.9132 5.41643 13.9711 5.7074C14.029 5.99837 13.9993 6.29995 13.8858 6.57404C13.7722 6.84812 13.58 7.08237 13.3333 7.24719C13.0866 7.41201 12.7966 7.5 12.4999 7.5H3.99995V9H12.4999C13.2956 9 14.0587 8.68395 14.6213 8.12134C15.1839 7.55873 15.4999 6.79565 15.4999 6C15.4999 5.20435 15.1839 4.44127 14.6213 3.87866C14.0587 3.31605 13.2956 3 12.4999 3V3.005Z"
                fill="currentcolor"
            />
            <path
                d="M12 15.005H16.4999C16.7966 15.005 17.0866 15.093 17.3333 15.2578C17.58 15.4226 17.7722 15.6569 17.8858 15.931C17.9993 16.2051 18.029 16.5066 17.9711 16.7976C17.9132 17.0886 17.7704 17.3559 17.5606 17.5657C17.3508 17.7755 17.0836 17.9183 16.7926 17.9762C16.5016 18.0341 16.2 18.0043 15.9259 17.8908C15.6518 17.7773 15.4176 17.5851 15.2527 17.3384C15.2247 17.2963 15.1993 17.2563 15.1751 17.2182C15.0577 17.0329 14.9704 16.8952 14.7499 16.81C14.6132 16.7572 14.5244 16.7395 14.3786 16.755C14.1698 16.7772 14.0302 16.8437 13.8899 17C13.7626 17.142 13.7232 17.2597 13.7099 17.45C13.7017 17.5679 13.7499 17.75 13.7499 17.75C13.7499 17.75 13.816 18.0587 14.3786 18.6213C14.9412 19.1839 15.7043 19.5 16.4999 19.5V19.505C17.2956 19.505 18.0587 19.189 18.6213 18.6263C19.1839 18.0637 19.4999 17.3007 19.4999 16.505C19.4999 15.7094 19.1839 14.9463 18.6213 14.3837C18.0587 13.8211 17.2956 13.505 16.4999 13.505H12V15.005Z"
                fill="currentcolor"
            />
            <path
                d="M16.8786 6.88367C17.4412 6.32106 18.2043 6.005 18.9999 6.005V6C19.7956 6 20.5587 6.31605 21.1213 6.87866C21.6839 7.44127 21.9999 8.20435 21.9999 9C21.9999 9.79565 21.6839 10.5587 21.1213 11.1213C20.5587 11.6839 19.7956 12 18.9999 12H2V10.5H18.9999C19.2966 10.5 19.5866 10.412 19.8333 10.2472C20.08 10.0824 20.2722 9.84812 20.3858 9.57404C20.4993 9.29995 20.529 8.99837 20.4711 8.7074C20.4132 8.41643 20.2704 8.14911 20.0606 7.93933C19.8508 7.72955 19.5836 7.58669 19.2926 7.52881C19.0016 7.47093 18.7 7.50067 18.4259 7.6142C18.1518 7.72773 17.9176 7.91995 17.7527 8.16663C17.7247 8.20866 17.6993 8.24873 17.6751 8.28679C17.5577 8.4721 17.4704 8.60983 17.2499 8.695C17.1132 8.74784 17.0244 8.76553 16.8786 8.75C16.6698 8.72777 16.5302 8.66133 16.3899 8.505C16.2626 8.363 16.2232 8.2453 16.2099 8.05501C16.2017 7.9371 16.2499 7.755 16.2499 7.755C16.2499 7.755 16.316 7.44628 16.8786 6.88367Z"
                fill="currentcolor"
            />
            <path
                d="M7.98641 16.1644H10.9999V13L9.78653 14.2489C8.22643 12.7067 5.72404 12.7067 4.16393 14.2489C2.60382 15.7911 2.61716 18.2978 4.17282 19.84C5.73292 21.3867 8.26643 21.3867 9.82654 19.84C10.6088 19.0667 10.9999 17.9244 10.9999 17.0444H10.111C10.111 17.9244 9.80876 18.6222 9.20427 19.2222C7.99086 20.4222 6.02628 20.4222 4.81286 19.2222C3.59945 18.0133 3.59945 16.0711 4.81286 14.8667C6.02628 13.6667 7.99086 13.7111 9.20427 14.9111L7.98641 16.1644Z"
                fill="currentcolor"
            />
            <path
                d="M7.22191 17.1111V15.2222H6.5552V17.4444L8.45755 18.5733L8.77758 18.0356L7.22191 17.1111Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
