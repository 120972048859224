import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function RainForecastIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            {/* <g opacity="0.2">
                <path
                    d="M3.72898 9.12972C3.48866 9.3699 3.00004 10.1655 3 10.9231C3 12.6103 3.68595 13.3771 4.52656 13.7225C4.54334 13.5564 4.5681 13.3906 4.60088 13.2258C4.7805 12.3228 5.19395 11.485 5.79716 10.7949C5.05863 10.2345 4.85289 9.61026 4.45796 8.36112C4.3378 8.48122 4.21564 8.61332 4.09347 8.74542C3.97131 8.87752 3.84914 9.00963 3.72898 9.12972Z"
                    fill="currentColor"
                />
                <path
                    d="M14.5351 12.09C18.145 11.8532 17.2164 8.53644 15.8787 6.56774C16.6077 6.34355 18.3301 6.7176 19.0376 7.08014C19.6991 7.51058 20.2258 7.95555 20.5436 8.71338C20.829 9.39386 21.0696 10.6754 20.9816 11.1793C20.8249 12.076 20.4315 12.5376 20.2526 12.7165C20.203 12.7661 20.1461 12.8268 20.0826 12.8946C19.6915 13.3121 19.0496 13.9975 18.3086 13.9975L14.9939 13.9974C14.9621 13.3367 14.8058 12.6897 14.5351 12.09Z"
                    fill="currentColor"
                />
            </g> */}
            <path
                d="M14.9468 15H17.5C18.8051 15 19.9806 14.4445 20.8026 13.5568C21.5458 12.7542 22.0002 11.6802 22.0002 10.5C22.0002 8.01472 19.8182 6 17.5 6L17.489 6.00001C17.4342 6.00014 17.3795 6.00126 17.3251 6.00334C16.2543 3.92855 14.0505 2.5 11.75 2.5C8.9028 2.5 6.50027 4.40385 5.746 7.00794C3.65521 7.13895 2 8.87618 2 11C2 12.6865 3.04375 14.1292 4.52053 14.7175C4.50694 14.5635 4.5 14.4075 4.5 14.25C4.5 13.8516 4.54438 13.4635 4.62847 13.0905C3.94875 12.6437 3.5 11.8742 3.5 11C3.5 9.61929 4.61929 8.5 6 8.5H7.00647C7.13647 5.99286 9.21051 4 11.75 4C13.3735 4 14.9336 4.93612 15.7998 6.33233C15.1672 6.5907 14.6065 6.98893 14.1563 7.48858L15.264 8.50002C15.798 7.90341 16.5672 7.52176 17.4259 7.5009C17.4505 7.5003 17.4752 7.5 17.5 7.5C17.5121 7.5 17.5241 7.50008 17.5362 7.50025C17.5658 7.50065 17.5954 7.50154 17.625 7.50292C19.123 7.57279 20.5 8.8879 20.5 10.5C20.5 12.1569 19.1569 13.5 17.5 13.5H14.9468C14.9819 13.745 15 13.9954 15 14.25C15 14.5046 14.9819 14.755 14.9468 15Z"
                fill="currentColor"
            />
            <path
                d="M14.8975 16L14.6454 16.5169C14.4659 16.8849 14.6187 17.3288 14.9868 17.5083C15.3548 17.6878 15.7987 17.535 15.9782 17.1669L16.5473 16H14.8975Z"
                fill="currentColor"
            />
            <path
                d="M13.5954 18.6441C13.7749 18.2761 14.2188 18.1232 14.5868 18.3027C14.9548 18.4822 15.1077 18.9261 14.9282 19.2941L14.0615 21.0712C13.882 21.4392 13.4381 21.592 13.0701 21.4125C12.702 21.233 12.5492 20.7892 12.7287 20.4211L13.5954 18.6441Z"
                fill="currentColor"
            />
            <path
                d="M17.7151 18.968C17.3471 18.7885 16.9032 18.9414 16.7237 19.3094L16.182 20.42C16.0025 20.7881 16.1553 21.2319 16.5234 21.4114C16.8914 21.5909 17.3353 21.4381 17.5148 21.0701L18.0565 19.9594C18.236 19.5914 18.0831 19.1475 17.7151 18.968Z"
                fill="currentColor"
            />
            <path
                d="M6.7431 18.5542C6.72418 18.5823 6.70696 18.6121 6.69167 18.6435L5.82496 20.4205C5.64546 20.7885 5.79829 21.2324 6.16633 21.4119C6.53436 21.5914 6.97822 21.4386 7.15772 21.0705L8.02443 19.2935C8.03616 19.2695 8.04647 19.2451 8.05539 19.2205C7.58425 19.0599 7.14325 18.8342 6.7431 18.5542Z"
                fill="currentColor"
            />
            <path
                d="M9.27728 20.42L9.72602 19.4999L9.75 19.5C10.3733 19.5 10.9713 19.3914 11.526 19.192L10.61 21.07C10.4305 21.4381 9.98668 21.5909 9.61864 21.4114C9.25061 21.2319 9.09778 20.788 9.27728 20.42Z"
                fill="currentColor"
            />
            <path
                d="M18.3502 16L17.7736 17.1822C17.5941 17.5502 17.7469 17.9941 18.115 18.1736C18.483 18.3531 18.9268 18.2003 19.1063 17.8322L20 16H18.3502Z"
                fill="currentColor"
            />
            <path
                d="M9.75 17.125C9.48646 17.125 9.27083 16.9094 9.27083 16.6458C9.27083 16.3823 9.48646 16.1667 9.75 16.1667C10.0135 16.1667 10.2292 16.3823 10.2292 16.6458C10.2292 16.9094 10.0135 17.125 9.75 17.125Z"
                fill="currentColor"
            />
            <path
                d="M9.27083 11.8542V9.9375H9.75C12.1315 9.9375 14.0625 11.8685 14.0625 14.25C14.0625 16.6315 12.1315 18.5625 9.75 18.5625C7.36854 18.5625 5.4375 16.6315 5.4375 14.25C5.4375 12.8365 6.11792 11.5906 7.16729 10.8048V10.7952L10.4256 14.0535L9.75 14.7292L7.15292 12.1321C6.67854 12.7071 6.39583 13.445 6.39583 14.25C6.39583 16.1044 7.89563 17.6042 9.75 17.6042C11.6044 17.6042 13.1042 16.1044 13.1042 14.25C13.1042 12.5585 11.8535 11.169 10.2292 10.9342V11.8542H9.27083Z"
                fill="currentColor"
            />
            <path
                d="M12.1458 13.7708C12.4094 13.7708 12.625 13.9865 12.625 14.25C12.625 14.5135 12.4094 14.7292 12.1458 14.7292C11.8823 14.7292 11.6667 14.5135 11.6667 14.25C11.6667 13.9865 11.8823 13.7708 12.1458 13.7708Z"
                fill="currentColor"
            />
            <path
                d="M7.35417 14.7292C7.09062 14.7292 6.875 14.5135 6.875 14.25C6.875 13.9865 7.09062 13.7708 7.35417 13.7708C7.61771 13.7708 7.83333 13.9865 7.83333 14.25C7.83333 14.5135 7.61771 14.7292 7.35417 14.7292Z"
                fill="currentColor"
            />
        </Icon>
    )
}
