import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
    Stack,
    Flex,
    Button,
    Text,
    VStack,
    useBreakpointValue,
    Container,
    Box,
    Image,
    useColorModeValue,
    Icon,
    Divider,
} from '@chakra-ui/react'

import skybg01 from '../assets/skybg-01.png'
import devicetypesLight from '../assets/devicetypes-reflection-light.png'
import devicetypesDark from '../assets/devicetypes-reflection-dark.png'
import iOSAddToHomescreen from '../assets/ios-add-to-homescreen.png'
import androidAddToHomescreen from '../assets/android-add-to-homescreen.png'
import mobileIcon from '../assets/preflight-mobile-icon.png'
import PricingStructure from '../../pricingStructure'
import AeroMode from './AeroMode'
import MetMode from './MetMode'
import AdvsMode from './AdvsMode'
import BriefMode from './BriefMode'
import Aerodromes from './Aerodromes'
import FAQs from './FAQs'
// import VersionNumber from '../../theme/components/VersionNumber'
// import TempInfo from './TempInfo'

export default function Content(props) {
    const { setInViewPosition } = props
    const navigate = useNavigate()
    const { user, loginWithRedirect } = useAuth0()
    const faqsRef = useRef()

    return (
        <>
            <Flex w={'full'}>
                <VStack
                    zIndex="2"
                    w={'full'}
                    justify={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    pt="80px"
                    pb={{ base: '80px', md: '70px' }}
                >
                    <Stack maxW={'3xl'} align={'center'} spacing={6}>
                        <Box
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            textAlign="center"
                            color={'white'}
                            fontWeight={400}
                            lineHeight={1.2}
                            fontSize={useBreakpointValue({
                                base: '3xl',
                                md: '4xl',
                            })}
                            px="20px"
                            textShadow="2px 3px 10px rgba(0,0,0,0.35)"
                        >
                            <Text // html space added to "New Zealand" to stop it splitting across lines where possible
                            >
                                The one-stop platform to better support pilots
                                throughout New&nbsp;Zealand
                            </Text>
                        </Box>
                        <Stack direction={'row'} spacing={{ base: 4, md: 6 }}>
                            <Button
                                bg={'light.200'}
                                rounded={'full'}
                                color={'white'}
                                _hover={{ bg: 'light.201' }}
                                _active={{ bg: 'light.201' }}
                                _focus={{ bg: 'light.201' }}
                                minWidth="160px"
                                onClick={() => {
                                    user
                                        ? navigate('/app')
                                        : loginWithRedirect({
                                              redirectUri: `${window.location.origin}/app`,
                                          })
                                }}
                            >
                                {user ? (
                                    <Text
                                        fontWeight="500"
                                        letterSpacing="0.5px"
                                    >
                                        Open PreFlight
                                    </Text>
                                ) : (
                                    <Text
                                        fontWeight="500"
                                        letterSpacing="0.5px"
                                    >
                                        Log In
                                    </Text>
                                )}
                            </Button>
                            <Button
                                bg={useColorModeValue(
                                    'whiteAlpha.400',
                                    'rgb(40, 60, 80, 0.25)'
                                )}
                                rounded={'full'}
                                color={'white'}
                                _hover={{
                                    bg: useColorModeValue(
                                        'whiteAlpha.500',
                                        'rgb(40, 60, 80, 0.4)'
                                    ),
                                }}
                                _focus={{
                                    bg: useColorModeValue(
                                        'whiteAlpha.600',
                                        'rgb(40, 60, 80, 0.5)'
                                    ),
                                }}
                                _active={{
                                    bg: useColorModeValue(
                                        'whiteAlpha.600',
                                        'rgb(40, 60, 80, 0.5)'
                                    ),
                                }}
                                minWidth="160px"
                                onClick={() => {
                                    if (user) {
                                        // SIGNED IN
                                        document
                                            .getElementById('contentContainer')
                                            .scrollIntoView({
                                                behavior: 'smooth',
                                            })
                                    } else if (!user) {
                                        loginWithRedirect({
                                            action: 'signup',
                                        })
                                    }
                                }}
                            >
                                {user ? (
                                    <Text
                                        fontWeight="500"
                                        letterSpacing="0.5px"
                                    >
                                        Show me more
                                    </Text>
                                ) : (
                                    <Text
                                        fontWeight="500"
                                        letterSpacing="0.5px"
                                    >
                                        Register
                                    </Text>
                                )}
                            </Button>
                        </Stack>
                    </Stack>
                </VStack>
            </Flex>
            <Flex
                bg="linear-gradient(179deg, transparent 40%, rgba(255,255,255,0.3) calc(40% + 1px))"
                width="100%"
                zIndex="3"
                position="relative"
            >
                <Flex
                    bg="linear-gradient(176deg, transparent 48%, #FFFFFF calc(48% + 1px))"
                    width="100%"
                >
                    <Container maxW={'5xl'} zIndex="100">
                        <Image
                            zIndex="1"
                            alt={'PreFlight'}
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'auto'}
                            src={useColorModeValue(
                                devicetypesLight,
                                devicetypesDark
                            )}
                        />
                    </Container>
                </Flex>
            </Flex>

            <Flex flexDirection="column" width="100%" backgroundColor="white">
                <Flex
                    zIndex="6"
                    position="relative"
                    flexDirection="column"
                    width="100%"
                    bg="white"
                >
                    <div
                        style={{ marginTop: '-100px', marginBottom: '100px' }}
                        id="contentContainer"
                    ></div>
                    <Container
                        paddingTop="30px"
                        maxW={'5xl'}
                        zIndex="100"
                        px="30px"
                        pb={{ base: '70px', md: '90px' }}
                    >
                        <Text
                            color="gray.500"
                            fontWeight="300"
                            fontSize={{
                                base: '1.2rem',
                                sm: '1.3rem',
                                lg: '1.5rem',
                            }}
                            textAlign="center"
                        >
                            PreFlight provides a suite of benefits to the
                            aviation sector, through delivery of both safety
                            critical weather and aeronautical information
                            required for commercial and recreational pilots
                        </Text>
                    </Container>

                    {/* **** TEMP INFO IF NEEDED **** */}
                    {/* <TempInfo VersionNumber={VersionNumber} /> */}
                    {/* ****** ****** */}

                    <AeroMode
                        Blob={Blob}
                        setInViewPosition={setInViewPosition}
                    />
                    <MetMode Blob={Blob} />
                    <AdvsMode Blob={Blob} />
                    <BriefMode
                        Blob={Blob}
                        setInViewPosition={setInViewPosition}
                    />
                    <Aerodromes Blob={Blob} />
                </Flex>
                <div id="pricingContainer"></div>
                <Flex
                    width="100%"
                    height="200px"
                    zIndex="5"
                    position="relative"
                >
                    <Flex
                        bg="linear-gradient(001deg, transparent 30%, rgba(255,255,255,0.3) calc(30% + 1px))"
                        width="100%"
                        height={{ base: '120px', md: '180px' }}
                        position="relative"
                        zIndex="3"
                    >
                        <Flex
                            mt="-1px"
                            bg="linear-gradient(004deg, transparent 50%, #FFFFFF calc(50% + 1px))"
                            width="100%"
                        ></Flex>
                    </Flex>
                    <Flex
                        bg="linear-gradient(179deg, rgba(0, 50, 130, 0.1) 0%, transparent 70%)"
                        width="100%"
                        height={{ base: '200px', md: '220px' }}
                        position="absolute"
                        zIndex="1"
                        alignItems="end"
                        justifyContent="center"
                        pb={{ base: '40px', md: '0px' }}
                    >
                        <Flex
                            width="100%"
                            zIndex="1"
                            justifyContent="center"
                            alignItems="center"
                            x="100%"
                            px={{ base: 0, md: 5 }}
                            pt={8}
                            align={'center'}
                            _before={{
                                content: '""',
                                borderBottom: '1px solid',
                                borderColor: 'white',
                                opacity: '0.6',
                                flexGrow: 1,
                                ml: { base: '30%', md: '30%' },
                                mr: { base: 10, md: 10 },
                            }}
                            _after={{
                                content: '""',
                                borderBottom: '1px solid',
                                borderColor: 'white',
                                opacity: '0.6',
                                flexGrow: 1,
                                ml: { base: 10, md: 10 },
                                mr: { base: '30%', md: '30%' },
                            }}
                        >
                            <Text
                                color="rgba(255,255,255,1)"
                                fontSize="1.5rem"
                                fontWeight="300"
                                letterSpacing="6px"
                                textAlign="center"
                                pl="5px"
                            >
                                PRICING
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    marginTop="-200px"
                    bg="white"
                    width="100%"
                    height="100%"
                    pt={{ base: '200px', md: '275px', lg: '340px' }}
                    pb={{ base: '200px', md: '220px', lg: '240px' }}
                    zIndex="4"
                    position="relative"
                >
                    <Box
                        position="absolute"
                        zIndex="1"
                        height="100%"
                        left="0px"
                        top="0px"
                        width="100%"
                    >
                        <Box
                            className="bgImage"
                            position="absolute"
                            w={'full'}
                            h={'100%'}
                            // mt="-220px"
                            backgroundImage={`url(${skybg01})`}
                            backgroundSize={'cover'}
                            backgroundPosition={'center center'}
                            zIndex="0"
                        ></Box>
                        <Flex
                            opacity="0.55"
                            width="100%"
                            height="inherit"
                            backgroundImage="linear-gradient(60deg, #FFF 0%, #6293D2 28%, #4273BF 40%, #34A887 70%, rgba(255, 255, 255, 0.37) 100%)"
                            left="0px"
                            top="0px"
                            zIndex="1"
                            position="absolute"
                        ></Flex>
                        <Flex
                            width="100%"
                            height="inherit"
                            position="absolute"
                            backgroundImage="linear-gradient(0deg, rgba(255, 255, 255, 0.75) 30%, rgba(255, 255, 255, 0) 75%)"
                            left="0px"
                            top="0px"
                            zIndex="2"
                        ></Flex>
                    </Box>

                    <Flex
                        position="relative"
                        bottom="0px"
                        zIndex="2"
                        width="100%"
                    >
                        <Container
                            maxW={'6xl'}
                            zIndex="100"
                            justifyContent="center"
                        >
                            <PricingStructure />
                        </Container>
                    </Flex>
                </Flex>

                <Flex
                    width="100%"
                    // height="60px"
                    zIndex="4"
                    position="relative"
                    flexDirection="column"
                >
                    <Flex
                        mt="-180px"
                        bg="linear-gradient(179deg, transparent 30%, rgba(255,255,255,0.3) calc(30% + 1px))"
                        width="100%"
                        height="180px"
                        position="relative"
                        zIndex="3"
                    >
                        <Flex
                            bg="linear-gradient(176deg, transparent 50%, #FFFFFF calc(50% + 1px))"
                            width="100%"
                        ></Flex>
                    </Flex>

                    <Flex
                        className="add-to-homescreen"
                        bg="light.10"
                        width="100%"
                        pt={{ base: '20px', md: '0px' }}
                        pb={{ base: '0px', md: '0px' }}
                        mt={{ base: '10px', md: '0px' }}
                        mb={{ base: '0px', md: '0px' }}
                        zIndex="2"
                        position="relative"
                        flexDirection="column"
                    >
                        <Container
                            maxW={'7xl'}
                            zIndex="100"
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            flexDirection={{
                                base: 'column',
                                lg: 'row',
                            }}
                        >
                            <Flex
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                ml={{ base: '20px', md: '10px' }}
                                mr={{ base: '5px', md: '20px' }}
                                px={{ base: '20px', md: '30px' }}
                                pb={{ base: '7px', md: '8px' }}
                                borderLeft="15px solid"
                                borderColor="gray.300"
                                color="gray.500"
                                justifyContent="center"
                                alignItems="flex-start"
                                flexDirection={{
                                    base: 'column',
                                    md: 'column',
                                }}
                                ref={faqsRef}
                            >
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Image
                                        align={'center'}
                                        maxWidth={'50px'}
                                        h={'auto'}
                                        src={mobileIcon}
                                    />

                                    <Text
                                        pl="15px"
                                        className="modeLabel"
                                        fontSize={{
                                            base: '1.5rem',
                                            smd: '1.8rem',
                                            xl: '1.9rem',
                                        }}
                                        lineHeight="1.3"
                                        fontWeight="600"
                                    >
                                        Use PreFlight like an app
                                    </Text>
                                </Flex>
                                <Divider
                                    mt="25px"
                                    mb="20px"
                                    height="0px !important"
                                    borderColor="gray.300"
                                    opacity="0.5"
                                />
                                <Text>
                                    PreFlight has been built as a web-based
                                    application. Essentially it is a website
                                    that utilises dynamic data and mapping
                                    software. Currently there is no PreFlight
                                    'app' available from the Apple App Store or
                                    Google Play Store.
                                </Text>
                                <Text pt="10px">
                                    However, PreFlight has been designed in a
                                    way to be used like a dedicated application
                                    through features available in your mobile
                                    device's web browser settings.
                                </Text>
                                <Text pt="10px">
                                    From your{' '}
                                    <strong>web browser tools menu</strong> on
                                    your mobile phone or tablet, you can save
                                    PreFlight to your home screen. This will
                                    save an icon to your mobile device, and
                                    removes the web address bar to fill the
                                    whole screen, so you can use PreFlight in a
                                    similar way to a native app.
                                </Text>
                            </Flex>
                            <Flex
                                pt={{ base: '50px', md: '30px' }}
                                pb="10px"
                                px={{ base: '5px', md: '20px' }}
                                width="100%"
                                justifyContent="space-evenly"
                                gap={{
                                    base: '60px',
                                    smd: '20px',
                                    ml: '60px',
                                }}
                                flexDirection={{
                                    base: 'column',
                                    smd: 'row',
                                }}
                            >
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap="20px"
                                >
                                    <Image
                                        borderRadius="15px"
                                        boxShadow="0px 10px 30px -15px rgba(0,0,0,0.7)"
                                        alt={'iOS'}
                                        align={'center'}
                                        maxWidth={'200px'}
                                        h={'auto'}
                                        src={iOSAddToHomescreen}
                                    />
                                    <Text
                                        pt="15px"
                                        width="100%"
                                        textAlign="center"
                                        fontWeight="300"
                                        color="gray.400"
                                    >
                                        iOS
                                    </Text>
                                </Flex>
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap="20px"
                                >
                                    <Image
                                        borderRadius="15px"
                                        boxShadow="0px 10px 30px -15px rgba(0,0,0,0.7)"
                                        alt={'Android'}
                                        align={'center'}
                                        maxWidth={'205px'}
                                        h={'auto'}
                                        src={androidAddToHomescreen}
                                    />
                                    <Text
                                        pt="15px"
                                        width="100%"
                                        textAlign="center"
                                        fontWeight="300"
                                        color="gray.400"
                                    >
                                        Android
                                    </Text>
                                </Flex>
                            </Flex>
                        </Container>
                    </Flex>

                    <div id="faqsContainer"></div>

                    <Flex
                        // bg="linear-gradient(001deg, rgba(0, 50, 130, 0.1) 0%, transparent 70%)"
                        width="100%"
                        // height="200px"
                        // position="absolute"
                        zIndex="10"
                        alignItems="end"
                        justifyContent="center"
                        pb={{ base: '20px', md: '40px' }}
                        mt={{ base: '110px', md: '120px' }}
                    >
                        <Flex
                            mb="-10px"
                            width="100%"
                            zIndex="1"
                            justifyContent="center"
                            alignItems="center"
                            x="100%"
                            px={{ base: 0, md: 5 }}
                            align={'center'}
                            _before={{
                                display: { base: 'none', md: 'block' },
                                content: '""',
                                borderBottom: '1px solid',
                                borderColor: 'gray.300',
                                flexGrow: 1,
                                ml: {
                                    base: '2%',
                                    sm: '5%',
                                    md: '7%',
                                    lg: '10%',
                                    xl: '20%',
                                },
                                mr: { base: 3, sm: 5, md: 10 },
                            }}
                            _after={{
                                display: { base: 'none', md: 'block' },
                                content: '""',
                                borderBottom: '1px solid',
                                borderColor: 'gray.300',
                                flexGrow: 1,
                                ml: { base: 3, sm: 5, md: 10 },
                                mr: {
                                    base: '2%',
                                    sm: '5%',
                                    md: '7%',
                                    lg: '10%',
                                    xl: '20%',
                                },
                            }}
                        >
                            <Text
                                // color="rgba(255,255,255,1)"
                                color="gray.500"
                                fontSize="1.5rem"
                                fontWeight="300"
                                letterSpacing="5px"
                                textAlign="center"
                                pl="5px"
                                px={{ base: '30px', sm: '80px', md: '0px' }}
                                textTransform="uppercase"
                                textShadow="2px 3px 7px rgba(0,0,0,0.1)"
                            >
                                Frequently Asked Questions
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                marginTop="-200px"
                bg="white"
                width="100%"
                pt={{ base: '240px', md: '230px' }}
                pb={{ base: '20px', md: '60px' }}
                zIndex="3"
                position="relative"
                flexDirection="column"
            >
                <Flex position="relative" zIndex="2" width="100%" pb="70px">
                    <Container maxW={'6xl'} zIndex="100">
                        <Flex
                            boxShadow={{
                                base: '0px 15px 50px -20px rgba(0, 25, 50, 0.5)',
                                md: '0px 15px 50px -20px rgba(0, 25, 50, 0.4)',
                            }}
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            bg="white"
                            borderRadius="40px"
                            pt={{ base: '40px', md: '35px' }}
                            px={{ base: '15px', md: '30px' }}
                            pb={{ base: '30px', md: '20px' }}
                            color="light.100"
                            width="100%"
                            flexDirection={{
                                base: 'column',
                                md: 'column',
                            }}
                            ref={faqsRef}
                        >
                            <FAQs
                                faqsRef={faqsRef}
                                setInViewPosition={setInViewPosition}
                            />
                        </Flex>
                    </Container>
                </Flex>
            </Flex>
        </>
    )
}

// Background shaded area behind screenshot images
export const Blob = (props) => {
    return (
        <Icon
            width={'100%'}
            viewBox="0 0 578 440"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
                fill="currentColor"
            />
        </Icon>
    )
}
