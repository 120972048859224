import React from 'react'
import { useRecoilState } from 'recoil'
import { Tooltip, Center, Icon } from '@chakra-ui/react'
import { isDesktop, isIOS } from 'react-device-detect'
import { ReactComponent as SaturationIcon } from '../../../../icons/assets/saturation.svg'

import { basemapEnhanceState } from '../../../../globalState'

export default function BasemapEnhanceButton({ basemap }) {
    const [basemapEnhance, setBasemapEnhance] =
        useRecoilState(basemapEnhanceState)

    return (
        <Tooltip
            display={isDesktop ? 'flex' : 'none'}
            isDisabled={isDesktop ? false : true}
            label={
                basemapEnhance
                    ? `Reduce ${
                          basemap === 'vnc'
                              ? 'VNC '
                              : basemap === 'linztopo'
                              ? 'LINZ Topo '
                              : ''
                      }Base Map Saturation`
                    : `Increase ${
                          basemap === 'vnc'
                              ? 'VNC '
                              : basemap === 'linztopo'
                              ? 'LINZ Topo '
                              : ''
                      }Base Map Saturation`
            }
            hasArrow
            borderRadius="7px"
            height="26px"
            fontSize="0.7rem"
            alignItems="center"
            placement="left"
            m="5px"
            color="light.100"
            bg="light.10"
        >
            <Center
                w={{ base: '32px', md: '30px' }}
                h={{ base: '32px', md: '30px' }}
                pt={'10px'}
                pb={'10px'}
                borderRadius="10px"
                backgroundColor={basemapEnhance && 'rgba(255,255,255,0.4)'}
                _hover={{
                    backgroundColor:
                        (isDesktop || !isIOS) && 'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                _focus={{
                    backgroundColor:
                        basemapEnhance &&
                        (isDesktop || !isIOS) &&
                        'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setBasemapEnhance((prevState) => !prevState)
                }}
            >
                <Icon
                    as={SaturationIcon}
                    width="24px"
                    height="24px"
                    color={'light.10'}
                />
            </Center>
        </Tooltip>
    )
}
