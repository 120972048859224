import React, { useEffect, useState, useRef } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import useUserRoles, { useTier } from '../../../../../auth/hooks'

import { addHours, parseISO, formatISO } from 'date-fns'
import { usePDF } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'

import {
    timeZoneState,
    briefingMetarCountState,
    excludedAerodromesState,
    excludedAwsState,
    showOutdatedErrorsState,
    briefingParamsState,
    selectedBriefingTimeState,
    selectedBriefingAreasState,
    selectedBriefingAltitudeState,
    aerodromesSortState,
    downloadPromiseState,
    pdfShowState,
    briefingAreaSourceState,
    briefingOrderState,
    briefingSigwxLevelState,
    altAerodromeLayoutState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../../../globalState'
import ExportPDF from '../briefing-export'

export default function SavePdf(props) {
    const { briefingData } = props

    const briefingAreaSource = useRecoilValue(briefingAreaSourceState)
    const timeZone = useRecoilValue(timeZoneState)
    const selectedHours = useRecoilValue(selectedBriefingTimeState)
    const issuedTime = briefingData && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)
    const periodToString = periodTo && formatISO(periodTo)
    const userRoles = useUserRoles()
    const selectedAreas = useRecoilValue(selectedBriefingAreasState)
    const excludedAerodromes = useRecoilValue(excludedAerodromesState)
    const excludedAws = useRecoilValue(excludedAwsState)
    const briefingMetarCount = useRecoilValue(briefingMetarCountState)
    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const params = useRecoilValue(briefingParamsState)
    const maxAltitude = useRecoilValue(selectedBriefingAltitudeState)
    const setPdfState = useSetRecoilState(pdfShowState)
    const aerodromesSort = useRecoilValue(aerodromesSortState)
    const [downloadPromise, setDownloadPromise] =
        useRecoilState(downloadPromiseState)
    const [briefingPdf, setBriefingPdf] = useState(null)
    const briefingOrder = useRecoilValue(briefingOrderState)
    const sigwxLevel = useRecoilValue(briefingSigwxLevelState)
    const altAerodromeLayout = useRecoilValue(altAerodromeLayoutState)

    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    useEffect(() => {
        console.log('Generating PDF export')
        setBriefingPdf(
            <ExportPDF
                userRoles={userRoles}
                briefingData={briefingData}
                params={params}
                excludedAerodromes={excludedAerodromes}
                excludedAws={excludedAws}
                timeZone={timeZone}
                selectedAreas={selectedAreas}
                briefingAreaSource={briefingAreaSource}
                briefingMetarCount={briefingMetarCount}
                periodToString={periodToString}
                maxAltitude={maxAltitude}
                showOutdatedErrors={showOutdatedErrors}
                aerodromesSort={aerodromesSort}
                briefingOrder={briefingOrder}
                sigwxLevel={sigwxLevel}
                altAerodromeLayout={altAerodromeLayout}
                isPremium={isPremium}
            />
        )
    }, [
        userRoles,
        briefingData,
        params,
        excludedAerodromes,
        excludedAws,
        timeZone,
        selectedAreas,
        briefingAreaSource,
        briefingMetarCount,
        periodToString,
        maxAltitude,
        showOutdatedErrors,
        aerodromesSort,
        briefingOrder,
        sigwxLevel,
        altAerodromeLayout,
        isPremium,
    ])

    const [instance, updateInstance] = usePDF({
        document: briefingPdf,
    })

    const prevPdfPropsRef = useRef()

    // update pdf instance (if prevPdfPropsRef are not the same as the previously rendered pdf) when user clicks download
    useEffect(() => {
        if (
            downloadPromise &&
            briefingPdf &&
            prevPdfPropsRef.current !== briefingPdf.props
        ) {
            updateInstance()
            setPdfState(instance)
        }
    }, [downloadPromise, setPdfState, updateInstance, briefingPdf, instance])

    // download pdf instance once it has finished rendering
    useEffect(() => {
        if (
            downloadPromise &&
            briefingPdf &&
            !instance.loading &&
            instance.url
        ) {
            saveAs(instance.url, `PreflightBriefing-${briefingData.id}.pdf`)
            setDownloadPromise(false)
            setPdfState(instance)
            prevPdfPropsRef.current = briefingPdf.props
        }
    }, [
        downloadPromise,
        setDownloadPromise,
        instance,
        briefingData,
        briefingPdf,
        setPdfState,
    ])

    // reset instance when briefingPdfProps change to force generation of new pdf
    useEffect(() => {
        if (
            briefingPdf &&
            instance &&
            prevPdfPropsRef.current &&
            briefingPdf.props !== prevPdfPropsRef.current
        ) {
            instance.blob = null
            instance.url = null
        }
    }, [briefingPdf, instance])

    return null
}
