import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useAdminRole } from '../../../auth/hooks'
import {
    Flex,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Text,
    Modal,
    Heading,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Icon,
    Divider,
    useDisclosure,
    LightMode,
} from '@chakra-ui/react'

import ErrorToggle from './ErrorToggle'
import ErrorText from './ErrorText'
import { isAfter, parseISO, formatDistanceToNow } from 'date-fns'
import formatDateTime from '../../../util/dateFormatter'
import {
    clickedFeaturesState,
    showErrorTogglesState,
    timeZoneState,
    mapDisabledState,
} from '../../../globalState'

import { ReactComponent as icing } from '../../../icons/assets/icing-moderate.svg'
import { ReactComponent as wind } from '../../../icons/assets/wind.svg'
import { ReactComponent as cumulonimbus } from '../../../icons/assets/cumulonimbus.svg'
import { ReactComponent as cumulonimbusSymbol } from '../../../icons/assets/cumulonimbus-symbol.svg'
import { ReactComponent as vaa } from '../../../icons/assets/vaa.svg'
import { ReactComponent as vaaSymbol } from '../../../icons/assets/vaa-symbol.svg'
import { ReactComponent as vacSymbol } from '../../../icons/assets/vac-symbol.svg'
import { ReactComponent as ice } from '../../../icons/assets/ice.svg'
import { ReactComponent as turbulence } from '../../../icons/assets/turbulence-symbol-moderate.svg'
import { ReactComponent as mountainWaves } from '../../../icons/assets/mountain-waves-moderate.svg'
import { ReactComponent as mountainWavesSymbol } from '../../../icons/assets/mountain-waves-symbol-moderate.svg'
import { ReactComponent as radiationSymbol } from '../../../icons/assets/radiation-symbol.svg'

export default function SigwxModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const timeZone = useRecoilValue(timeZoneState)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const [showErrors, setShowErrors] = useState(false)
    const [outOfDateError, setOutOfDateError] = useState(false)
    const isAdmin = useAdminRole()
    const feat = clickedFeatures[0]

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (feat.properties.validTo) {
            if (isAfter(new Date(feat.properties.validTo), new Date())) {
                setOutOfDateError(false)
            } else {
                setOutOfDateError(true)
            }
        }
    }, [feat])

    const frequencyFormatter = (freq) => {
        const lookup = {
            isolatedEmbedded: 'Isolated Embedded',
            occasionalEmbedded: 'Occasional Embedded',
            occasional: 'Occasional',
        }

        if (lookup[freq]) {
            return lookup[freq]
        }
        return freq
    }

    const turbulenceAlt =
        feat?.properties?.turbulence && JSON.parse(feat.properties.turbulence)

    const icingAlt =
        feat?.properties?.icing && JSON.parse(feat.properties.icing)

    const sigwxAlerts =
        feat?.properties?.alerts &&
        JSON.parse(feat.properties.alerts).length &&
        JSON.parse(feat.properties.alerts)

    const hasVAAlertLevel =
        feat?.properties?.type === 'volcano' &&
        sigwxAlerts &&
        sigwxAlerts.length &&
        sigwxAlerts.some((value) => value.startsWith('Volcano Alert Level'))

    const hasVAEruptionSigmet =
        feat?.properties?.type === 'volcano' &&
        sigwxAlerts &&
        sigwxAlerts.length &&
        sigwxAlerts.some((value) => value.startsWith('VA SIGMET in force'))

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    const onCloseHandler = () => {
        setClickedFeatures([])
        onClose()
    }

    const SIGWXConfig = {
        clearAirTurbulence: {
            title: 'MODERATE CLEAR AIR TURBULENCE',
            icon: turbulence,
            watermark: wind,
            colour: '#ffcc00',
            gradient: 'linear-gradient(345deg, #ffe47b 10%, #f0bd00 90%)',
        },
        turbulence: {
            title: 'MODERATE TURBULENCE',
            icon: turbulence,
            watermark: wind,
            colour: '#e25a5a',
            gradient: 'linear-gradient(345deg, #fd7373 10%, #c63737 90%)',
        },
        icing: {
            title: 'MODERATE ICING',
            icon: icing,
            watermark: ice,
            colour: '#60a4d2',
            gradient: 'linear-gradient(345deg, #72cef2 10%, #1396ca 90%)',
        },
        mountainWaves: {
            title: 'MOUNTAIN WAVES',
            icon: mountainWavesSymbol,
            watermark: mountainWaves,
            colour: '#152238',
            gradient: 'linear-gradient(345deg, #395068 10%, #141e2f 90%)',
        },
        cumulonimbus: {
            title:
                (feat.properties.frequency
                    ? `${frequencyFormatter(feat.properties.frequency)} `
                    : '') + 'Cumulonimbus',
            icon: cumulonimbusSymbol,
            watermark: cumulonimbus,
            colour: '#008800',
            gradient: 'linear-gradient(345deg, #4ead4e 10%, #0e800e 90%)',
            comment:
                'The presence of Cumulonimbus implies severe turbulence and icing',
        },
        volcano: {
            title: `VOLCANIC ALERT ${
                feat?.properties?.alertlevel
                    ? 'LEVEL ' + feat.properties.alertlevel
                    : ''
            }`,
            icon: hasVAEruptionSigmet ? vacSymbol : vaaSymbol,
            watermark: vaa,
            colour: '#FF6CFF',
            gradient: 'linear-gradient(345deg, #e97cf8 10%, #b82fc3 90%)',
            alertlevel: feat?.properties?.alertlevel
                ? feat.properties.alertlevel
                : '',
        },
        radiation: {
            title: 'RADIATION',
            icon: radiationSymbol,
            watermark: radiationSymbol,
            colour: '#94DA00',
            gradient: 'linear-gradient(345deg, #a2ef00 10%, #5fb500 90%)',
            alertlevel: feat?.properties?.alertlevel
                ? feat.properties.alertlevel
                : '',
        },
    }

    const config = SIGWXConfig[feat.properties.type]

    // useEffect(() => {
    //     console.log(feat, 'sigwx')
    //     console.log(sigwxAlerts, 'sigwxAlerts')
    //     console.log(clickedFeatures, 'clickedFeatures')
    // }, [feat, clickedFeatures])

    return (
        <>
            <LightMode>
                <Modal
                    variant={mapDisabled ? 'weatherMapDisabled' : 'weather'}
                    size="lg"
                    centered
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    onHide={() => setClickedFeatures([])}
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton
                            color="white"
                            mr={{ base: '0px', md: '10px' }}
                            mt={{ base: '0px', md: '8px' }}
                        />
                        <ModalHeader
                            background={config.colour}
                            backgroundImage={config.gradient}
                        >
                            <Flex
                                flexDirection="row"
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Icon
                                        pointerEvents="none"
                                        transform="scale(2.3) translateX(-4px)"
                                        opacity={
                                            feat.properties.type ===
                                            'clearAirTurbulence'
                                                ? '0.2'
                                                : '0.1'
                                        }
                                        boxSize="40px"
                                        as={config.watermark}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                </Flex>
                                <Flex
                                    ml="-40px"
                                    width="100%"
                                    pr={8}
                                    alignItems="center"
                                >
                                    <Icon
                                        boxSize="40px"
                                        as={config.icon}
                                        alt="!"
                                        color="#ffffff"
                                    />

                                    {feat.properties.type.toLowerCase() ===
                                        'volcano' && ( //  *** ALERT LEVEL TEXT FOR VAA ***
                                        <Flex
                                            position="absolute"
                                            width="40px"
                                            justifyContent="center"
                                            color="white"
                                        >
                                            <Text
                                                fontSize="0.8rem"
                                                mt={
                                                    hasVAEruptionSigmet
                                                        ? '8px'
                                                        : '0px'
                                                }
                                                pb={
                                                    hasVAEruptionSigmet
                                                        ? '0px'
                                                        : '1px'
                                                }
                                                letterSpacing="-1px"
                                            >
                                                {config.alertlevel}
                                            </Text>
                                        </Flex>
                                    )}
                                    <Box pl={4}>
                                        <Heading
                                            variant="mainHeading"
                                            textTransform="uppercase"
                                        >
                                            {config.title}
                                        </Heading>
                                        <Heading variant="subHeading">
                                            Graphical NZ SIGWX
                                        </Heading>
                                    </Box>
                                </Flex>
                            </Flex>
                        </ModalHeader>
                        {(hasVAAlertLevel || hasVAEruptionSigmet) && (
                            <Flex
                                mt="-1px"
                                zIndex="8"
                                px="10px"
                                py="5px"
                                width="100%"
                                minWidth="100%"
                                flexDirection="column"
                                background="#ffff20"
                                justifyContent="center"
                                alignItems="center"
                                transition="all ease-in-out 300ms"
                                height="max-content"
                                fontSize="0.8rem"
                                borderBottom="1px solid"
                                borderColor="#f7f714"
                            >
                                <Text
                                    color="light.100"
                                    textAlign="center"
                                    whiteSpace="pre-wrap"
                                    fontWeight="700"
                                    lineHeight="1.3"
                                >
                                    {sigwxAlerts &&
                                        sigwxAlerts.length &&
                                        sigwxAlerts[0]}
                                </Text>
                            </Flex>
                        )}

                        <ModalBody>
                            {isAdmin && showErrorToggle && (
                                <Flex
                                    w="100%"
                                    pb="15px"
                                    mt="10px"
                                    align="center"
                                    justifyContent={{
                                        base: 'flex-end',
                                        md: 'flex-end',
                                    }}
                                >
                                    <ErrorToggle
                                        showErrors={showErrors}
                                        setShowErrors={setShowErrors}
                                    />
                                </Flex>
                            )}
                            {(showErrors ||
                                feat.properties.outofdate ||
                                outOfDateError) && (
                                <Flex my="15px" justifyContent="center">
                                    <ErrorText>
                                        There has been an issue with the
                                        connection between PreFlight and
                                        MetService. This SIGWX was last
                                        validated with MetService{' '}
                                        <strong>
                                            {formatDistanceToNow(
                                                parseISO(
                                                    feat.properties.updated
                                                ),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}
                                        </strong>
                                        .<br />
                                        There is a high likelihood that the
                                        latest SIGWX is not available in
                                        PreFlight. Check back again soon, or
                                        alternatively visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </ErrorText>
                                </Flex>
                            )}
                            <Table size="sm" variant="weather" marginTop="2">
                                <Tbody>
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                        >
                                            Valid From:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.validfrom &&
                                                formatDateTime(
                                                    feat.properties.validfrom,
                                                    timeZone
                                                )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                        >
                                            Valid To:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.validto &&
                                                formatDateTime(
                                                    feat.properties.validto,
                                                    timeZone
                                                )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td></Td>
                                        {/* Adds spacing */}
                                        <Td></Td>
                                    </Tr>

                                    {feat.layer !== 'sigwx-symbol-static' &&
                                        feat?.properties?.type !==
                                            'mountainWaves' &&
                                        feat?.properties?.lowerlabel &&
                                        feat?.properties?.upperlabel && (
                                            <Tr>
                                                <Td
                                                    paddingStart="0"
                                                    fontWeight="bold"
                                                    width="auto"
                                                    minWidth="90px"
                                                >
                                                    Altitudes:
                                                </Td>
                                                <Td width="100%">
                                                    <div
                                                        style={{
                                                            width: 'max-content',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {feat.properties
                                                            .upperlabel ===
                                                        'XXX'
                                                            ? 'XXX'
                                                            : feat.properties.upperlabel.substring(
                                                                  0,
                                                                  2
                                                              ) === 'FL'
                                                            ? feat.properties
                                                                  .upperlabel
                                                            : feat.properties
                                                                  .upperlabel +
                                                              ' ft'}
                                                        <Divider
                                                            variant="altitidues"
                                                            width="80px"
                                                        />
                                                        {feat?.properties
                                                            ?.lowerlabel ===
                                                        'XXX'
                                                            ? 'XXX'
                                                            : feat.properties.lowerlabel.substring(
                                                                  0,
                                                                  2
                                                              ) === 'FL' ||
                                                              feat.properties
                                                                  .lowerlabel ===
                                                                  'SFC'
                                                            ? feat.properties
                                                                  .lowerlabel
                                                            : feat.properties
                                                                  .lowerlabel +
                                                              ' ft'}
                                                    </div>
                                                </Td>
                                            </Tr>
                                        )}
                                </Tbody>
                            </Table>
                            {feat?.properties?.type === 'mountainWaves' && (
                                <Flex
                                    width="100%"
                                    justifyContent={{
                                        base: 'space-between',
                                        md: 'space-around',
                                    }}
                                    alignItems="center"
                                    pl={{
                                        base: '0px',
                                        md: '5%',
                                    }}
                                    pr={{
                                        base: '0px',
                                        md: '7%',
                                    }}
                                    gap={{
                                        base: '20px',
                                        md: '10px',
                                    }}
                                >
                                    {feat.properties.turbulence &&
                                        turbulenceAlt &&
                                        turbulenceAlt.lowerlabel &&
                                        turbulenceAlt.upperlabel && (
                                            <Flex
                                                width="100%"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    paddingStart="0"
                                                    fontWeight="bold"
                                                    width="auto"
                                                    mr={{
                                                        base: '5px',
                                                        xs: '10px',
                                                    }}
                                                    justifyContent="flex-end"
                                                >
                                                    <Icon
                                                        as={turbulence}
                                                        width="55px"
                                                        height="55px"
                                                        color="#e25a5a"
                                                    />
                                                </Flex>
                                                <Flex>
                                                    <div
                                                        style={{
                                                            width: 'max-content',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {turbulenceAlt.upperlabel.substring(
                                                            0,
                                                            2
                                                        ) === 'FL'
                                                            ? turbulenceAlt.upperlabel
                                                            : turbulenceAlt.upperlabel +
                                                              ' ft'}
                                                        <Divider
                                                            variant="altitidues"
                                                            width="80px"
                                                        />
                                                        {turbulenceAlt.lowerlabel.substring(
                                                            0,
                                                            2
                                                        ) === 'FL' ||
                                                        turbulenceAlt.lowerlabel ===
                                                            'SFC'
                                                            ? turbulenceAlt.lowerlabel
                                                            : turbulenceAlt.lowerlabel +
                                                              ' ft'}
                                                    </div>
                                                </Flex>
                                            </Flex>
                                        )}

                                    {feat.properties.icing &&
                                        icingAlt &&
                                        icingAlt.lowerlabel &&
                                        icingAlt.upperlabel && (
                                            <Flex
                                                width="100%"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    paddingStart="0"
                                                    fontWeight="bold"
                                                    width="auto"
                                                    justifyContent="flex-end"
                                                    mr={{
                                                        base: '3px',
                                                        xs: '7px',
                                                    }}
                                                >
                                                    <Icon
                                                        as={icing}
                                                        width="45px"
                                                        height="45px"
                                                        color="#58ccf1"
                                                    />
                                                </Flex>
                                                <Flex>
                                                    <div
                                                        style={{
                                                            width: 'max-content',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {icingAlt.upperlabel.substring(
                                                            0,
                                                            2
                                                        ) === 'FL'
                                                            ? icingAlt.upperlabel
                                                            : icingAlt.upperlabel +
                                                              ' ft'}
                                                        <Divider
                                                            variant="altitidues"
                                                            width="80px"
                                                        />
                                                        {icingAlt.lowerlabel.substring(
                                                            0,
                                                            2
                                                        ) === 'FL' ||
                                                        icingAlt.lowerlabel ===
                                                            'SFC'
                                                            ? icingAlt.lowerlabel
                                                            : icingAlt.lowerlabel +
                                                              ' ft'}
                                                    </div>
                                                </Flex>
                                            </Flex>
                                        )}
                                </Flex>
                            )}
                            <Flex
                                pt={
                                    !feat.properties.description &&
                                    !config.comment &&
                                    '5px'
                                }
                                width="100%"
                                flexDirection="column"
                            >
                                {feat.properties.description && (
                                    <>
                                        <Divider variant="modalFooter" />
                                        <Text
                                            marginBottom="8px"
                                            whiteSpace="pre-wrap"
                                        >
                                            {feat.properties.description}
                                        </Text>
                                    </>
                                )}
                                {config.comment && (
                                    <>
                                        <Divider variant="modalFooter" />
                                        <Flex width="100%">
                                            <Text
                                                fontSize="0.8rem"
                                                fontWeight="600"
                                                color="gray.500"
                                                marginBottom="8px"
                                                whiteSpace="pre-wrap"
                                            >
                                                Note:
                                            </Text>
                                            <Text
                                                pl="5px"
                                                fontSize="0.8rem"
                                                color="gray.500"
                                                marginBottom="8px"
                                                whiteSpace="pre-wrap"
                                            >
                                                {config.comment}
                                            </Text>
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
