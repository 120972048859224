import React, { useState, useEffect, useCallback } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import {
    Box,
    Flex,
    Center,
    IconButton,
    useColorModeValue,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'
import TimeIcon from '../../../../icons/menu-icons/TimeIcon'
import TimelapseIcon from '../../../../icons/menu-icons/TimelapseIcon'
import CloseIcon from '../../../../icons/menu-icons/CloseIcon'
import RewindIcon from '../../../../icons/menu-icons/RewindIcon'
import FastForwardIcon from '../../../../icons/menu-icons/FastForwardIcon'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import {
    LoopButton,
    PlayButton,
    FolderButton,
    SliderRail,
    Handle,
    HoverHandle,
    Track,
    Tick,
} from './components/'
import { addDays } from 'date-fns'
import { dateAndTimeFormatter } from '../../../../util/dateFormatter'
import { scaleTime } from 'd3-scale'
import {
    globalTimeState,
    dataModeState,
    timeZoneState,
    selectedTimeState,
    layerSelectionState,
    selectedWxTileTimesState,
    layersLoaded,
    sliderPlayingState,
    contrastState,
    statusData,
    tourVisibilityState,
    advisoriesMetaDataExpandState,
    mapDisabledState,
} from '../../../../globalState'

import { isMobileOnly, isIOS } from 'react-device-detect'

import './time-slider.css'

const sliderStyle = {
    position: 'relative',
    width: '100%',
}

function calcSteps(mins) {
    return mins * 60 /* s */ * 1000 /* ms */
}

function roundTimeToMins(time, mins) {
    let timeToReturn = new Date(time)

    timeToReturn.setMilliseconds(
        Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
    )
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60)
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / mins) * mins)
    return timeToReturn
}

//store satellite data as a variable and then consume it. you dont need to round to minutes as the satellite information is already to time

const sliderConfigByMapLayer = {
    all: {
        interval: 15, // defines the interval in mins for each slider step
        format: 'HH:mm',
        handleFormat: 'eee dd - HH:mm',
        ticks: 7,
        get minRange() {
            const interval = this.interval
            return (now) => roundTimeToMins(now, interval)
        },
        get maxRange() {
            const interval = this.interval
            return (now) =>
                addDays(
                    roundTimeToMins(now, interval),
                    1 /* CONFIG THIS OPTION TO ADD DAYS (or adjust to addHours etc) */
                )
        },
        get steps() {
            const interval = this.interval
            return calcSteps(interval)
        },
    },
    satellite: {
        format: 'HH:mm',
        handleFormat: 'eee dd - HH:mm',
        get ticks() {
            return (times) => times
        },
        get minRange() {
            return (times) => Date.parse(times[0])
        },
        get maxRange() {
            return (times) => Date.parse(times[times.length - 1])
        },
        get steps() {
            return (times) => {
                //create function
                const interval =
                    (Date.parse(times[times.length - 1]) -
                        Date.parse(times[0])) /
                    (times.length - 1)
                return interval
            }
        },
    },
    aaw: {
        interval: 15, // defines the interval in mins for each slider step
        format: 'HH:mm',
        handleFormat: 'eee dd - HH:mm',
        ticks: 7,
        get minRange() {
            const interval = this.interval
            return (now) => roundTimeToMins(now, interval)
        },
        get maxRange() {
            const interval = this.interval
            return (now) =>
                addDays(
                    roundTimeToMins(now, interval),
                    1 /* CONFIG THIS OPTION TO ADD DAYS (or adjust to addHours etc) */
                )
        },
        get steps() {
            const interval = this.interval
            return calcSteps(interval)
        },
    },
    sigwx: {
        interval: 15, // defines the interval in mins for each slider step
        format: 'HH:mm',
        handleFormat: 'eee dd - HH:mm',
        ticks: 7,
        get minRange() {
            const interval = this.interval
            return (now) => roundTimeToMins(now, interval)
        },
        get maxRange() {
            const interval = this.interval
            return (now) =>
                addDays(
                    roundTimeToMins(now, interval),
                    1 /* CONFIG THIS OPTION TO ADD DAYS (or adjust to addHours etc) */
                )
        },
        get steps() {
            const interval = this.interval
            return calcSteps(interval)
        },
    },
    grafor: {
        interval: 15, // defines the interval in mins for each slider step
        format: 'HH:mm',
        handleFormat: 'eee dd - HH:mm',
        ticks: 7,
        get minRange() {
            const interval = this.interval
            return (now) => roundTimeToMins(now, interval)
        },
        get maxRange() {
            const interval = this.interval
            return (now) =>
                addDays(
                    roundTimeToMins(now, interval),
                    1 /* CONFIG THIS OPTION TO ADD DAYS (or adjust to addHours etc) */
                )
        },
        get steps() {
            const interval = this.interval
            return calcSteps(interval)
        },
    },
}

function findClosest(x, arr) {
    const indexArr = arr.map(function (k) {
        return Math.abs(k - x)
    })
    const min = Math.min.apply(Math, indexArr)
    return arr[indexArr.indexOf(min)]
}

const refreshRate = 60000

export default function TimeSlider({ showFilters }) {
    const time = useRecoilValue(globalTimeState)
    const status = useRecoilValue(statusData)
    const satelliteTimes = useRecoilValue(selectedWxTileTimesState)
    const [showButtons, setShowButtons] = useState(false)
    const [refreshed, setRefreshed] = useState(null)
    const [convertedTimes, setConvertedTimes] = useState([])
    const [satTicks, setSatTicks] = useState(null)
    const [mode, setMode] = useState(3)
    const timeZone = useRecoilValue(timeZoneState)
    const [selectedTime, setSelectedTime] = useRecoilState(selectedTimeState)
    const loaded = useRecoilValue(layersLoaded)
    const dataMode = useRecoilValue(dataModeState)
    const setPlaying = useSetRecoilState(sliderPlayingState)
    const [minDateRange, setMinDateRange] = useState(0)
    const [maxDateRange, setMaxDateRange] = useState(100)
    const [nextControl, setNextControl] = useState(true)
    const [previousControl, setPreviousControl] = useState(true)
    const layerSelection = useRecoilValue(layerSelectionState)
    const [mapLayer, setMapLayer] = useState(null)
    const [isNow, setIsNow] = useState(false)
    const [steps, setSteps] = useState(calcSteps(30))
    const [sliding, setSliding] = useState(false)
    const [dateFormat, setDateFormat] = useState('HH')
    const [handleFormat, setHandleFormat] = useState('HH:mm')
    const [dateTicks, setDateTicks] = useState([])
    const [percentHovered, setPercentHovered] = useState(0)
    const [hoveredTime, setHoveredTime] = useState(0)
    const [hoverVisible, setHoverVisible] = useState(false)
    const contrastHandler = useRecoilValue(contrastState)
    const tourVisibility = useRecoilValue(tourVisibilityState)
    const [advisoriesMetaDataExpanded, setAdvisoriesMetaDataExpanded] =
        useRecoilState(advisoriesMetaDataExpandState)
    const mapDisabled = useRecoilValue(mapDisabledState)

    //Chakra light and dark mode
    const collapseButtonColor = useColorModeValue('brand.strong', 'dark.80')
    const collapseButtonHover = useColorModeValue('light.20', 'dark.305')
    const folderItemColor = useColorModeValue('light.10', 'light.10')
    const selectedItemColor = useColorModeValue('light.100', 'dark.100')
    const timeSliderIcon = useColorModeValue('brand.strong', 'dark.80')
    const collapseButtonHigh = useColorModeValue('light.10', 'dark.304')
    const collapseButtonMedium = useColorModeValue('light.10', 'dark.303')
    const collapseButtonStandard = useColorModeValue('light.10', 'dark.301')

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    //determines if play buttons should be shown - add any additional layers that should have play and loop functionality here.
    const satelliteTypeHandler = useCallback(() => {
        return (
            (layerSelection.met.rainradar ||
                layerSelection.met.rainRadarWxTiles ||
                layerSelection.met.satelliteCloudVisible ||
                layerSelection.met.satelliteCloudTops ||
                layerSelection.met.satelliteCloudInfrared ||
                layerSelection.met.satelliteCloudInfraredEnhanced) &&
            dataMode === 'met'
        )
    }, [layerSelection, dataMode])

    const setModeThreeDefault = useBreakpointValue({
        base: false,
        md: true,
        lg: false,
    })

    //This determines the loading behaviour for the controls, controls hide when rasters are loading
    useEffect(() => {
        if (mapDisabled && (showFilters || dataMode === 'advs')) {
            if (setModeThreeDefault) {
                setMode(3)
            } else {
                setMode(1)
            }
        } else if (!loaded) {
            setMode(3)
        } else if (tourVisibility) {
            setMode(1)
        } else if (
            mapLayer === 'satellite' &&
            loaded &&
            satelliteTimes.length > 2
        ) {
            setMode(1)
        } else if (
            mapLayer === 'satellite' &&
            loaded &&
            satelliteTimes.length <= 2
        ) {
            setMode(2)
        } else if (mapLayer && loaded) {
            setMode(1)
        }
    }, [
        loaded,
        mapDisabled,
        dataMode,
        showFilters,
        satelliteTimes,
        tourVisibility,
        mapLayer,
        setModeThreeDefault,
    ])

    //This determines when play and loop buttons are shown
    useEffect(() => {
        setShowButtons(mapLayer === 'satellite' && mode === 1)
    }, [mapLayer, mode])

    useEffect(() => {
        setMapLayer(
            satelliteTypeHandler()
                ? 'satellite'
                : layerSelection.met.aaw
                ? 'aaw'
                : (dataMode === 'met' && layerSelection.met.sigwx) ||
                  (dataMode === 'advs' &&
                      !layerSelection.advs.notam &&
                      layerSelection.advs.sigwx)
                ? 'sigwx'
                : layerSelection.met.grafor
                ? 'grafor'
                : 'all'
        )
    }, [layerSelection, satelliteTypeHandler, dataMode])

    //If it is a raster layer, select the last / most recent time
    useEffect(() => {
        satelliteTimes.length &&
            satelliteTypeHandler() &&
            setSelectedTime(
                Date.parse(satelliteTimes[satelliteTimes.length - 1])
            )
    }, [
        layerSelection,
        dataMode,
        satelliteTimes,
        setSelectedTime,
        satelliteTypeHandler,
    ])

    useEffect(() => {
        setSatTicks(satelliteTimes.map((t) => Date.parse(t)))
    }, [satelliteTimes])

    const onSlideHandler = () => {
        setSliding(true)
        if (isMobileOnly || window.innerWidth < 768) {
            setAdvisoriesMetaDataExpanded(false)
        }
    }

    const onSlideEndHandler = (ms) => {
        setSliding(false)
        setSelectedTime(
            mapLayer === 'satellite'
                ? findClosest(ms[0], satTicks)
                : +roundTimeToMins(
                      ms[0],
                      sliderConfigByMapLayer[mapLayer].interval
                  )
        )
        setIsNow(isNowHandler(ms[0]))
        isNow && setSelectedTime(isNow ? +time : ms[0])
    }

    const updateHandler = (ms) => {
        setSelectedTime(ms[0])
        setIsNow(isNowHandler(ms[0]))
    }

    const isNowHandler = useCallback(
        (t) => {
            return (
                mapLayer &&
                +roundTimeToMins(
                    t,
                    sliderConfigByMapLayer[mapLayer].interval
                ) ===
                    +roundTimeToMins(
                        time,
                        sliderConfigByMapLayer[mapLayer].interval
                    )
            )
        },
        [mapLayer, time]
    )

    useEffect(() => {
        isNow && !sliding && setSelectedTime(+time)
    }, [isNow, time, setSelectedTime, sliding])

    //This disables the buttons when they are at the beginning or end value in the avalable times
    useEffect(() => {
        if (mapLayer === 'satellite') {
            const index = convertedTimes.indexOf(selectedTime)
            if (index < convertedTimes.length - 1 && index > 0) {
                setNextControl(true)
                setPreviousControl(true)
            } else if (index === 0) {
                setNextControl(true)
                setPreviousControl(false)
            } else if (index === convertedTimes.length - 1) {
                setNextControl(false)
                setPreviousControl(true)
            }
        } else {
            setNextControl(selectedTime + steps < +maxDateRange)
            setPreviousControl(!isNow)
            //setPreviousControl(selectedTime - steps > +minDateRange)
        }
    }, [
        selectedTime,
        convertedTimes,
        mapLayer,
        minDateRange,
        maxDateRange,
        steps,
        isNow,
    ])

    function nextTimeHandler() {
        if (mapLayer === 'satellite') {
            const index = convertedTimes.indexOf(selectedTime)
            if (index !== convertedTimes.length - 1) {
                setSelectedTime(convertedTimes[index + 1])
                setIsNow(isNowHandler(convertedTimes[index + 1]))
            }
        } else {
            setIsNow(
                isNowHandler(
                    +roundTimeToMins(
                        selectedTime + steps,
                        sliderConfigByMapLayer[mapLayer].interval
                    )
                )
            )
            setSelectedTime(
                (t) =>
                    +roundTimeToMins(
                        t + steps,
                        sliderConfigByMapLayer[mapLayer].interval
                    )
            )
        }
    }

    function previousTimeHandler() {
        if (mapLayer === 'satellite') {
            const index = convertedTimes.indexOf(selectedTime)
            if (index) {
                setSelectedTime(convertedTimes[index - 1])
            }
        } else {
            setIsNow(
                isNowHandler(
                    +roundTimeToMins(
                        selectedTime - steps,
                        sliderConfigByMapLayer[mapLayer].interval
                    )
                )
            )
            if (selectedTime - steps < minDateRange) {
                setIsNow(true)
            } else {
                setSelectedTime(
                    (t) =>
                        +roundTimeToMins(
                            t - steps,
                            sliderConfigByMapLayer[mapLayer].interval
                        )
                )
            }
        }
    }

    //map through array of satellite times and convert to epoch date
    const resetSliderConfig = useCallback(
        ({ minRange, maxRange, steps, format, handleFormat, ticks }) => {
            const now = new Date()

            if (status) {
                //Wait until satelliteTimes is populated before settng range
                if (mapLayer === 'satellite' && satelliteTimes.length) {
                    setMinDateRange(minRange(satelliteTimes))
                    setMaxDateRange(maxRange(satelliteTimes))
                }
                if (mapLayer === 'all') {
                    setMinDateRange(minRange(now))
                    setMaxDateRange(maxRange(now))
                    setSteps(steps)
                }
                if (mapLayer === 'aaw' && status.aaw.start && status.aaw.end) {
                    const startTime = Date.parse(status.aaw.start)
                    setMinDateRange(startTime < now ? now : startTime)
                    setMaxDateRange(Date.parse(status.aaw.end))
                    setSteps(steps)
                }
                if (mapLayer === 'grafor') {
                    const startTime = Date.parse(status.grafor.start)
                    setMinDateRange(startTime < now ? now : startTime)
                    setMaxDateRange(Date.parse(status.grafor.end))
                    setSteps(steps)
                }
                if (
                    mapLayer === 'sigwx' &&
                    status.sigwx.start &&
                    status.sigwx.end
                ) {
                    const startTime = Date.parse(status.sigwx.start)
                    setMinDateRange(startTime < now ? now : startTime)
                    setMaxDateRange(Date.parse(status.sigwx.end))
                    setSteps(steps)
                }
            }
            setDateFormat(format)
            setHandleFormat(handleFormat)
            setDateTicks(
                mapLayer === 'satellite'
                    ? satTicks
                    : scaleTime()
                          .domain([minRange(now), maxRange(now)])
                          .ticks(ticks)
                          .map((d) => +d)
            )
            setConvertedTimes(satelliteTimes.map((t) => Date.parse(t)))
        },
        [mapLayer, satTicks, satelliteTimes, status]
    )

    //Checks to see when slider has been redrawn, set to one minute
    useEffect(() => {
        if (mapLayer && time - refreshed > refreshRate) {
            setIsNow(isNowHandler(+selectedTime))
            resetSliderConfig(sliderConfigByMapLayer[mapLayer])
            setRefreshed(time)
        }
    }, [
        time,
        refreshed,
        isNowHandler,
        mapLayer,
        resetSliderConfig,
        selectedTime,
    ])

    useEffect(() => {
        mapLayer && resetSliderConfig(sliderConfigByMapLayer[mapLayer])
    }, [mapLayer, resetSliderConfig])

    function handleHoverHandler(x, w) {
        const range = +maxDateRange - +minDateRange
        setPercentHovered(x / (w / 100))
        setHoveredTime((range / w) * x + +minDateRange)
    }

    // this handles the different modes of the time slider
    function modeHandler() {
        if (
            (mapLayer === 'satellite' && satelliteTimes.length > 2) ||
            mapLayer !== 'satellite'
        ) {
            setMode(mode < 3 ? mode + 1 : 1)
            mode === 1 && setPlaying(false)
        } else if (mapLayer === 'satellite' && satelliteTimes.length <= 2) {
            setMode(mode < 3 ? 3 : 2)
        }
    }

    function iconRotationHandler() {
        if (mode === 1) {
            return 'rotate(180deg)'
        } else if (mode === 2) {
            return 'rotate(270deg)'
        } else {
            return 'rotate(0deg)'
        }
    }
    const screenWidth = window.innerWidth

    const widthAdjust = useBreakpointValue({
        base: mapDisabled ? 'calc(100vw - 82px)' : 'calc(100vw - 147px)',
        md: mapDisabled ? 'calc(100vw - 132px)' : 'calc(100vw - 480px)',
        lg: mapDisabled ? 'calc(100vw - 582px)' : 'calc(100vw - 480px)',
        xl: mapDisabled ? 'calc(100vw - 583px)' : '800px',
        '2xl': '800px',
    })

    const maxWidthAdjust =
        screenWidth >= 1536
            ? '800px'
            : screenWidth >= 1280
            ? mapDisabled
                ? 'calc(100vw - 582px)'
                : '800px'
            : screenWidth >= 992
            ? mapDisabled
                ? 'calc(100vw - 582px)'
                : 'calc(100vw - 480px)'
            : screenWidth >= 768
            ? mapDisabled
                ? 'calc(100vw - 132px)'
                : 'calc(100vw - 480px)'
            : screenWidth > 50
            ? mapDisabled
                ? 'calc(100vw - 82px)'
                : 'calc(100vw - 147px)'
            : '0px !important'

    // console.log('Mode: ', mode)

    return (
        <Flex
            id={'timeSlider'}
            zIndex="1"
            position={'absolute'}
            borderRadius={'20px'}
            alignContent={'center'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            h={{ base: '40px', md: '38px' }}
            minWidth={(loaded || mapDisabled) && (isNow ? '110px' : '160px')}
            bottom={{
                base:
                    isMobileOnly && isIOS && mapDisabled
                        ? '15px'
                        : mapDisabled
                        ? '15px'
                        : isMobileOnly && isIOS
                        ? '40px'
                        : '40px',
                md: '34px',
            }}
            right={'15px'}
            bgColor={
                mapDisabled
                    ? 'rgba(20,35,50, 0.3)'
                    : mode < 3
                    ? contrastHandler === 'high'
                        ? 'rgba(20,25,30, 0.6)' // If High Contrast Layer (Modes 1 & 2)
                        : contrastHandler === 'medium'
                        ? 'rgba(20,25,30, 0.4)' // If Medium Contrast Layer (Modes 1 & 2)
                        : 'rgba(20,25,30, 0.2)' // All other layers with Time Slider (Modes 1 and 2)
                    : contrastHandler === 'high'
                    ? 'rgba(20,25,30, 0.4)' // If High Contrast Layer (Mode 3)
                    : contrastHandler === 'medium'
                    ? 'rgba(20,25,30, 0.2)' // If Medium Contrast Layer (Mode 3)
                    : 'rgba(20,25,30, 0.05)' // All other layers with Time Slider (Mode 3)
            }
            transition={'all ease 1.5s'}
            backdropFilter="blur(2px)"
            boxShadow="inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)"
        >
            <Box
                pos="absolute"
                width="100%"
                justifyContent={{
                    base: 'center',
                    md: mapDisabled && 'flex-start',
                    lg: 'flex-start',
                }}
                pl={{
                    base: '15px',
                    md: mapDisabled && '10px',
                    lg: '10px',
                }}
                pr={{
                    base: mode === 3 ? '55px' : mode === 2 ? '65px' : '60px',
                    md: mapDisabled && (mode === 3 ? '13px' : '0px'),
                    lg: mode === 3 ? '13px' : '0px',
                }}
                right="0px"
                transform={{
                    base:
                        sliding && mode === 1
                            ? 'translate(0%, -55px)'
                            : mode === 1
                            ? 'translate(0%, -35px)'
                            : 'none',
                    md: mapDisabled && 'none',
                    lg: 'none',
                }}
                transition={'all ease 0.5s'}
                display={
                    'flex'
                    // mode === 2 || !(mapDisabled || loaded) ? 'none' : 'flex'
                }
                alignItems="center"
                color="white"
                opacity={{
                    base: advisoriesMetaDataExpanded && mode === 1 ? '0' : '1',
                    md: mapDisabled && '1',
                    lg: '1',
                }}
                visibility={{
                    base:
                        advisoriesMetaDataExpanded && mode === 1
                            ? 'hidden'
                            : 'visible',
                    md: mapDisabled && 'visible',
                    lg: 'visible',
                }}
                overflow="hidden"
            >
                <Text
                    width="130px"
                    minWidth="130px"
                    ml={{
                        base: '0px',
                        md: mapDisabled && (mode === 2 ? '40px' : '0px'),
                        lg: mode === 2 ? '40px' : '0px',
                    }}
                    pr={{
                        base: '0px',
                        md:
                            mapDisabled &&
                            (mode === 3 && isNow
                                ? '80px'
                                : mode === 3
                                ? '30px'
                                : '0px'),
                        lg:
                            mode === 3 && isNow
                                ? '80px'
                                : mode === 3
                                ? '30px'
                                : '0px',
                    }}
                    display="flex"
                    justifyContent="center"
                    textShadow={
                        mode === 1
                            ? {
                                  base: '1px 1px 3px rgba(0,0,0,0.35)',
                                  md: '1px 1px 3px rgba(0,0,0,0.15)',
                              }
                            : mode === 2
                            ? '1px 1px 3px rgba(0,0,0,0.15)'
                            : '1px 1px 3px rgba(0,0,0,0.35)'
                    }
                    fontSize={{
                        base: sliding ? '1.1rem' : '0.85rem',
                        md: sliding ? '0.9rem' : '0.8rem',
                    }}
                    fontWeight={{ base: '600', md: '600' }}
                    transition={'all ease 250ms'}
                >
                    {isNow
                        ? 'Now'
                        : selectedTime &&
                          dateAndTimeFormatter(
                              selectedTime,
                              timeZone,
                              handleFormat
                          )}
                </Text>
            </Box>
            <Center
                h="auto"
                transition="all ease 0.5s"
                overflow={mode === 3 ? 'hidden' : 'visible'}
                w={
                    mode === 1
                        ? widthAdjust
                        : mode === 2
                        ? {
                              base: mapDisabled
                                  ? 'calc(100vw - 82px)'
                                  : 'calc(100vw - 147px)',
                              md: mapDisabled && '240px',
                              lg: '240px',
                          }
                        : '0px'
                }
                maxWidth={maxWidthAdjust || widthAdjust}
            >
                <PlayButton
                    selectedItemColor={selectedItemColor}
                    folderItemColor={folderItemColor}
                    noFocus={noFocus}
                    hide={!showButtons}
                />
                <Box
                    h="40px"
                    w="100%"
                    display={mode > 1 && 'none'}
                    pl={{
                        base: '0px',
                        md: mapDisabled && (mode === 1 ? '135px' : '0px'),
                        lg: mode === 1 ? '125px' : '0px',
                    }}
                    clipPath="inset(-150px -30px)"
                >
                    <Center
                        h="40px"
                        w="100%"
                        marginTop={{ base: '0', lg: '-7px' }}
                        pl={{
                            base: showButtons ? '4' : '5',
                            md: showButtons ? '7' : '7',
                        }}
                        pr={{
                            base: showButtons ? '3' : '3',
                            md: showButtons ? '7' : '7',
                        }}
                        style={{ transition: 'width ease 0.5s' }}
                    >
                        <Slider
                            mode={1}
                            domain={[+minDateRange, +maxDateRange]}
                            steps={mapLayer !== 'satellite' && steps}
                            rootStyle={sliderStyle}
                            onSlideStart={onSlideHandler}
                            onSlideEnd={onSlideEndHandler}
                            onUpdate={updateHandler}
                            values={[+selectedTime]}
                        >
                            <Rail>
                                {({ getRailProps }) => (
                                    <SliderRail
                                        getRailProps={getRailProps}
                                        hoverHandler={handleHoverHandler}
                                        setHoverVisible={setHoverVisible}
                                    />
                                )}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <>
                                        {handles.map((handle) => (
                                            <Handle
                                                sliding={sliding}
                                                key={handle.id}
                                                handle={handle}
                                                getHandleProps={getHandleProps}
                                                format={(selected) =>
                                                    dateAndTimeFormatter(
                                                        selected,
                                                        timeZone,
                                                        handleFormat
                                                    )
                                                }
                                            />
                                        ))}
                                        {hoverVisible && (
                                            <HoverHandle
                                                time={dateAndTimeFormatter(
                                                    hoveredTime,
                                                    timeZone,
                                                    handleFormat
                                                )}
                                                percent={percentHovered}
                                                isNow={isNowHandler(
                                                    hoveredTime
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            </Handles>
                            <Tracks right={false}>
                                {({ tracks, getTrackProps }) => (
                                    <div>
                                        {tracks.map(
                                            ({ id, source, target }) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={
                                                        getTrackProps
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </Tracks>
                            <Ticks values={dateTicks}>
                                {({ ticks }) => (
                                    <div>
                                        {ticks.map((tick) => (
                                            <Tick
                                                key={tick.id}
                                                tick={tick}
                                                count={ticks.length}
                                                format={(ms) =>
                                                    dateAndTimeFormatter(
                                                        new Date(ms),
                                                        timeZone,
                                                        dateFormat
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            </Ticks>
                        </Slider>
                    </Center>
                </Box>
                <LoopButton
                    selectedItemColor={selectedItemColor}
                    folderItemColor={folderItemColor}
                    noFocus={noFocus}
                    hide={!showButtons}
                />
                <Box w="100%" display={mode !== 2 && 'none'}>
                    <Flex
                        justifyContent={'space-between'}
                        alignItems="center"
                        w={'100%'}
                    >
                        <FolderButton
                            icon={<RewindIcon />}
                            selectedItemColor={selectedItemColor}
                            folderItemColor={folderItemColor}
                            isDisabled={!previousControl}
                            noFocus={noFocus}
                            onClick={() => previousTimeHandler()}
                        />
                        {/* <Text
                            color={'white'}
                            textShadow="1px 1px 3px rgba(0,0,0,0.15)"
                            fontSize="0.85rem"
                            fontWeight="600"
                        >
                            {isNow
                                ? 'Now'
                                : selectedTime &&
                                  dateAndTimeFormatter(
                                      selectedTime,
                                      timeZone,
                                      handleFormat
                                  )}
                        </Text> */}
                        <FolderButton
                            icon={<FastForwardIcon />}
                            selectedItemColor={selectedItemColor}
                            folderItemColor={folderItemColor}
                            isDisabled={!nextControl}
                            noFocus={noFocus}
                            onClick={() => nextTimeHandler()}
                        />
                    </Flex>
                </Box>
            </Center>
            <Flex
                w="40px"
                h="40px"
                borderRadius={'20px'}
                marginLeft={mode < 3 && '10px'}
                marginTop={{ base: '0px', md: '-1px' }}
                boxShadow={useColorModeValue(
                    '0px 4px 12px -5px rgba(0, 0, 0, 0.3)',
                    '0px 2px 20px -8px rgba(255,255,255,0.3)'
                )}
                _active={{
                    boxShadow: '0px 4px 12px -5px rgba(0, 0, 0, 0.25)',
                }}
                // TODO - I think this prop throws a warning in React and is not actually functional
                // isDisabled={!loaded}
                // bgColor={{
                //     base: collapseButton,
                //     md: collapseButton,
                // }}
            >
                <IconButton
                    w="40px"
                    h="40px"
                    minW="40px"
                    transform={iconRotationHandler()}
                    style={{ transition: 'transform ease 0.2s' }}
                    fontSize="1.6rem"
                    borderRadius={'20px'}
                    bgColor={
                        contrastHandler === 'high'
                            ? collapseButtonHigh // If High Contrast Layer
                            : contrastHandler === 'medium'
                            ? collapseButtonMedium // If Medium Contrast Layer
                            : collapseButtonStandard // All other layers
                    }
                    color={collapseButtonColor}
                    backdropFilter="blur(2px)"
                    _hover={{
                        backgroundColor:
                            contrastHandler === 'high'
                                ? collapseButtonHover // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? collapseButtonHigh // If Medium Contrast Layer
                                : collapseButtonMedium, // All other layers
                    }}
                    _active={{
                        backgroundColor:
                            contrastHandler === 'high'
                                ? collapseButtonHigh // If High Contrast Layer
                                : contrastHandler === 'medium'
                                ? collapseButtonMedium // If Medium Contrast Layer
                                : collapseButtonStandard, // All other layers
                    }}
                    icon={
                        mode === 1 ? (
                            <TimelapseIcon color={timeSliderIcon} />
                        ) : mode === 2 ? (
                            <CloseIcon color={timeSliderIcon} />
                        ) : (
                            <TimeIcon color={timeSliderIcon} />
                        )
                    }
                    onClick={() => modeHandler()}
                    {...noFocus}
                />
            </Flex>
        </Flex>
    )
}
