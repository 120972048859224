import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { Box, Flex, Center, useColorModeValue } from '@chakra-ui/react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { isEqual } from 'lodash'
import {
    LoopButton,
    PlayButton,
    SpeedButton,
    SliderRail,
    Handle,
    HoverHandle,
    SlideHandle,
    Track,
    Tick,
} from './components'
import { formatISO } from 'date-fns'
import { formatTimeOnly } from '../../../../../../util/dateFormatter'
import { timeZoneState } from '../../../../../../globalState'

import { isMobileOnly, isIOS, isDesktop } from 'react-device-detect'

import './time-slider.css'

const sliderStyle = {
    position: 'relative',
    width: '100%',
}

function findClosest(x, arr) {
    const indexArr = arr.map(function (k) {
        return Math.abs(k - x)
    })
    const min = Math.min.apply(Math, indexArr)
    return arr[indexArr.indexOf(min)]
}

// This component needs to receive an array of acceptable time strings in the order of [latest - most recent]
export default function TimeSlider({
    setPlaying,
    playing,
    setLooping,
    looping,
    speed,
    setSpeed,
    setSelectedTime,
    setPreviousSelectedTime,
    mostRecent,
    selectedTime,
    loaded,
    times,
    desktop,
    positionTimeStamp,
}) {
    const timeZone = useRecoilValue(timeZoneState)
    const [minDateRange, setMinDateRange] = useState(0)
    const [maxDateRange, setMaxDateRange] = useState(100)
    const [selectedConvertedTime, setSelectedConvertedTime] = useState(null)
    const [sliding, setSliding] = useState(false)
    const [dateTicks, setDateTicks] = useState([])
    const [originalTimes, setOriginalTimes] = useState([])
    const [percentHovered, setPercentHovered] = useState(0)
    const [hoveredTime, setHoveredTime] = useState(0)
    const [hoverVisible, setHoverVisible] = useState(false)
    const [handleTimeVisible, setHandleTimeVisible] = useState(false)

    // Chakra light and dark mode
    const folderItemColor = useColorModeValue('light.10', 'light.10')
    const selectedItemColor = useColorModeValue('light.100', 'dark.100')

    const cycles = useRef(null)
    const currentTimesRef = useRef(null)
    const mountedRef = useRef(null)

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    useEffect(() => {
        if (times && times.length && !isEqual(times, currentTimesRef.current)) {
            currentTimesRef.current = times
            //TODO: evaluate what is supposed to happen on a time change
            // setPlaying(false)
            const convertedTimes = times.map((t) => Date.parse(t))
            setOriginalTimes(times)
            setDateTicks(convertedTimes)
            setMinDateRange(convertedTimes[0])
            setMaxDateRange(convertedTimes[convertedTimes.length - 1])
            if (!mountedRef.current) {
                mountedRef.current = true
                setSelectedTime(mostRecent ? times[times.length - 1] : times[0])
                setSelectedConvertedTime([
                    mostRecent
                        ? convertedTimes[convertedTimes.length - 1]
                        : convertedTimes[0],
                ])
            }
        }
    }, [mostRecent, setPlaying, setSelectedTime, times])

    useEffect(() => {
        // console.log('Includes Time:', times.includes(selectedTime), selectedTime)
        if (
            selectedTime &&
            mountedRef.current &&
            !times.includes(selectedTime)
        ) {
            const convertedTimes = times.map((t) => Date.parse(t))
            setSelectedTime(times[0])
            setSelectedConvertedTime([convertedTimes[0]])
        }
    }, [selectedTime, setSelectedTime, times])

    function speedInMs(spd) {
        if (spd === '1x') {
            return 1000
        } else if (spd === '2x') {
            return 500
        } else {
            return 250
        }
    }

    useEffect(() => {
        let interval = null
        if (playing) {
            if (cycles.current <= dateTicks.length - 1) {
                const currentIndex = dateTicks.indexOf(selectedConvertedTime[0])
                const nextTimeIndex =
                    currentIndex !== dateTicks.length - 1
                        ? dateTicks.indexOf(selectedConvertedTime[0]) + 1
                        : 0
                const nextTime = dateTicks[nextTimeIndex]
                interval = setTimeout(() => {
                    setSelectedConvertedTime([nextTime])
                }, speedInMs(speed))
                if (!looping) {
                    cycles.current = cycles.current + 1
                }
            } else {
                setPlaying(false)
            }
        } else {
            cycles.current = 0
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [dateTicks, looping, playing, selectedConvertedTime, setPlaying, speed])

    // useEffect(() => {
    //     if (!mountedRef.current) {
    //         dateTicks &&
    //             dateTicks.length &&
    //             setSelectedConvertedTime([
    //                 mostRecent ? dateTicks[dateTicks.length - 1] : dateTicks[0],
    //             ])
    //     }
    // }, [dateTicks, mostRecent])

    const selectedIndex = useRef(null)

    useEffect(() => {
        // We need to check if selectedConvertedTimes exists, if not check for nearest time again
        if (selectedConvertedTime) {
            const index = dateTicks.indexOf(selectedConvertedTime[0])
            if (index !== -1) {
                setSelectedTime((t) => {
                    setPreviousSelectedTime(t)
                    return originalTimes[index]
                })
            } else {
                const correctedTime = findClosest(
                    selectedConvertedTime[0],
                    dateTicks
                )
                // Need to test that we always receive a valid selectedTime
                const newIndex = dateTicks.indexOf(correctedTime)
                if (selectedIndex.current !== newIndex) {
                    setSelectedTime((t) => {
                        setPreviousSelectedTime(t)
                        return originalTimes[newIndex]
                    })
                    selectedIndex.current = newIndex
                }
            }
        }
    }, [
        dateTicks,
        originalTimes,
        playing,
        selectedConvertedTime,
        setPreviousSelectedTime,
        setSelectedTime,
        sliding,
    ])

    // Keep these in case we need a forward and backward button
    // function nextTimeHandler() {
    //     const index = times.indexOf(selectedTime)
    //     index !== 0 && setSelectedConvertedTime(times[index + 1])
    // }

    // function previousTimeHandler() {
    //     const index = times.indexOf(selectedTime)
    //     times.length - 1 !== index && setSelectedConvertedTime(times[index - 1])
    // }

    const onSlideHandler = () => {
        setSliding(true)
    }

    const onSlideEndHandler = (ms) => {
        setSliding(false)
        setSelectedConvertedTime([findClosest(ms[0], dateTicks)])
    }

    const onChange = (ms) => {
        !sliding && setSelectedConvertedTime([findClosest(ms[0], dateTicks)])
    }

    const updateHandler = (ms) => {
        setSelectedConvertedTime(ms)
    }

    function handleHoverHandler(x, w) {
        const range = +maxDateRange - +minDateRange
        setPercentHovered(x / (w / 100))
        setHoveredTime((range / w) * x + +minDateRange)
    }

    function speedHandler() {
        setSpeed((s) => {
            if (s === '1x') {
                return '2x'
            } else if (s === '2x') {
                return '3x'
            } else {
                return '1x'
            }
        })
    }

    return (
        <Flex
            position={{ base: 'relative', lg: 'relative' }}
            borderRadius={'20px'}
            alignContent={'center'}
            alignItems={'center'}
            h={{ base: '40px', lg: '38px' }}
            width={{
                base: '100%',
                lg: '100%',
            }}
            bottom={{
                base: isMobileOnly && isIOS ? '0px' : '0px',
                lg: '0px',
            }}
            // left={'50%'}
            // transform={'translate(-50%, -50%)'}
            bgColor={'rgba(20,25,30, 0.3)'}
            transition={'bgColor 1.5s'}
            backdropFilter="blur(4px)"
            boxShadow="inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)"
        >
            <Center
                h="auto"
                style={{ transition: 'width 0.5s' }}
                w={{
                    base: '100%',
                    lg: '100%',
                }}
            >
                <PlayButton
                    setPlaying={setPlaying}
                    playing={playing}
                    selectedItemColor={selectedItemColor}
                    folderItemColor={folderItemColor}
                    noFocus={noFocus}
                />
                <Box
                    ref={desktop}
                    px={{ base: '0px', md: positionTimeStamp ? '10px' : '0px' }}
                    w={{ base: '0%', md: 'auto' }}
                ></Box>
                <Box h="40px" w={{ base: '100%', md: '100%' }}>
                    <Center
                        h="40px"
                        w="100%"
                        pl={{
                            base: '1.2rem',
                            lg: '1.2rem',
                        }}
                        pr={{
                            base: '1.2rem',
                            lg: '1.2rem',
                        }}
                        style={{ transition: 'width 0.5s' }}
                    >
                        <Slider
                            mode={1}
                            domain={[+minDateRange, +maxDateRange]}
                            rootStyle={sliderStyle}
                            onSlideStart={!playing ? onSlideHandler : () => {}}
                            onSlideEnd={!playing ? onSlideEndHandler : () => {}}
                            onUpdate={!playing ? updateHandler : () => {}}
                            onChange={!playing ? onChange : () => {}}
                            values={selectedConvertedTime}
                        >
                            <Rail>
                                {({ getRailProps }) => (
                                    <SliderRail
                                        getRailProps={getRailProps}
                                        hoverHandler={handleHoverHandler}
                                        setHoverVisible={setHoverVisible}
                                    />
                                )}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <>
                                        {handles.map((handle) => (
                                            <Handle
                                                sliding={sliding}
                                                key={handle.id}
                                                handle={handle}
                                                domain={[
                                                    +minDateRange,
                                                    +maxDateRange,
                                                ]}
                                                getHandleProps={getHandleProps}
                                                time={
                                                    selectedConvertedTime &&
                                                    formatTimeOnly(
                                                        formatISO(
                                                            selectedConvertedTime[0]
                                                        ),
                                                        timeZone
                                                    )
                                                }
                                                setTimeVisible={
                                                    setHandleTimeVisible
                                                }
                                            />
                                        ))}
                                        {hoverVisible && isDesktop && (
                                            <HoverHandle
                                                time={
                                                    hoveredTime &&
                                                    formatTimeOnly(
                                                        formatISO(hoveredTime),
                                                        timeZone
                                                    )
                                                }
                                                percent={percentHovered}
                                            />
                                        )}
                                        {handleTimeVisible && isDesktop && (
                                            <SlideHandle
                                                time={
                                                    selectedConvertedTime &&
                                                    formatTimeOnly(
                                                        formatISO(
                                                            selectedConvertedTime[0]
                                                        ),
                                                        timeZone
                                                    )
                                                }
                                                percent={handles[0].percent}
                                            />
                                        )}
                                    </>
                                )}
                            </Handles>
                            <Tracks right={false}>
                                {({ tracks, getTrackProps }) => (
                                    <div>
                                        {tracks.map(
                                            ({ id, source, target }) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={
                                                        getTrackProps
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                            </Tracks>
                            <Ticks values={dateTicks}>
                                {({ ticks }) => (
                                    <div>
                                        {ticks.map((tick) => (
                                            <Tick
                                                key={tick.id}
                                                tick={tick}
                                                count={ticks.length}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Ticks>
                        </Slider>
                    </Center>
                </Box>
                <LoopButton
                    selectedItemColor={selectedItemColor}
                    folderItemColor={folderItemColor}
                    noFocus={noFocus}
                    looping={looping}
                    setLooping={setLooping}
                />
                <SpeedButton
                    noFocus={noFocus}
                    speedHandler={speedHandler}
                    speed={speed}
                />
            </Center>
        </Flex>
    )
}
