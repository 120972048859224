export default function decodePhoneticLetter(value) {
    switch (value) {
        case 'A':
            return 'ALFA'
        case 'B':
            return 'BRAVO'
        case 'C':
            return 'CHARLIE'
        case 'D':
            return 'DELTA'
        case 'E':
            return 'ECHO'
        case 'F':
            return 'FOXTROT'
        case 'G':
            return 'GOLF'
        case 'H':
            return 'HOTEL'
        case 'I':
            return 'INDIA'
        case 'J':
            return 'JULIET'
        case 'K':
            return 'KILO'
        case 'L':
            return 'LIMA'
        case 'M':
            return 'MIKE'
        case 'N':
            return 'NOVEMBER'
        case 'O':
            return 'OSCAR'
        case 'P':
            return 'PAPA'
        case 'Q':
            return 'QUEBEC'
        case 'R':
            return 'ROMEO'
        case 'S':
            return 'SIERRA'
        case 'T':
            return 'TANGO'
        case 'U':
            return 'UNIFORM'
        case 'V':
            return 'VICTOR'
        case 'W':
            return 'WHISKEY'
        case 'X':
            return 'XRAY'
        case 'Y':
            return 'YANKEE'
        case 'Z':
            return 'ZULU'

        default:
            return 'ERROR'
    }
}
