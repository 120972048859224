import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CautionIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
           <path d="M10.6 8.49669C10.546 7.68671 11.1884 7.00001 12.0002 7.00001C12.812 7.00001 13.4544 7.68671 13.4004 8.49669L13.0667 13.5022C13.0293 14.0637 12.5629 14.5 12.0002 14.5C11.4375 14.5 10.9711 14.0637 10.9337 13.5022L10.6 8.49669Z" fill="currentColor"/>
<path d="M13.0002 16.5C13.0002 17.0523 12.5525 17.5 12.0002 17.5C11.4479 17.5 11.0002 17.0523 11.0002 16.5C11.0002 15.9477 11.4479 15.5 12.0002 15.5C12.5525 15.5 13.0002 15.9477 13.0002 16.5Z" fill="currentColor"/>
<path fillRule="evenodd" clipRule="evenodd" d="M3.16136 14.4749C1.79452 13.108 1.79452 10.892 3.16136 9.52513L9.52532 3.16117C10.8922 1.79434 13.1082 1.79434 14.4751 3.16117L20.839 9.52513C22.2059 10.892 22.2059 13.108 20.839 14.4749L14.4751 20.8388C13.1082 22.2057 10.8922 22.2057 9.52532 20.8388L3.16136 14.4749ZM4.22202 10.5858L10.586 4.22183C11.367 3.44079 12.6334 3.44079 13.4144 4.22183L19.7784 10.5858C20.5594 11.3668 20.5594 12.6332 19.7784 13.4142L13.4144 19.7782C12.6334 20.5592 11.367 20.5592 10.586 19.7782L4.22202 13.4142C3.44097 12.6332 3.44097 11.3668 4.22202 10.5858Z" fill="currentColor"/>

        </Icon>
    )
}
