import {
    Text,
    Flex,
    Box,
    Icon,
    Image,
    Divider,
    Badge,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Link,
} from '@chakra-ui/react'

import { isIOS, isAndroid } from 'react-device-detect'
import { BsDot } from 'react-icons/bs'
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa'
import { TfiMoreAlt } from 'react-icons/tfi'
import sigwxcharts from '../assets/sigwxcharts.png'
import briefinglayout from '../assets/briefinglayout.png'
import awslayer from '../assets/awslayer.png'
import mfamodal from '../assets/mfamodal.png'
import profilemodal from '../assets/profilemodal.png'
import fontsizeslider from '../assets/fontsizeslider.png'
import validityperiods from '../assets/validityperiods.png'
import chartexample from '../assets/chartexample.png'
import remarkexample from '../assets/remarkexample.png'
import filterclosed from '../assets/filter-closed.png'
import filteropen from '../assets/filter-open.png'
import sliderfree from '../assets/sliders-free.png'
import savedurls from '../assets/saved-urls.png'
import newmessages from '../assets/new-messages.png'
import readmessages from '../assets/read-messages.png'
import servicemessages from '../assets/service-messages.png'
import profilesettings from '../assets/profile-settings.png'
import sliderpremium from '../assets/sliders-premium.png'
import aerodromeinfobutton from '../assets/aerodromeinfobutton.png'
import fontsizesliderbutton from '../assets/fontsizesliderbutton.png'
import savedfavourites from '../assets/savedfavourites.png'
import savefav from '../assets/save-fav.png'
import savedfav from '../assets/saved-fav.png'
import loadfavourite1 from '../assets/load-favourite1.png'
import loadfavourite2 from '../assets/load-favourite2.png'
import loadfavourite3 from '../assets/load-favourite3.png'
import newfavourite1 from '../assets/new-favourite1.png'
import newfavourite2 from '../assets/new-favourite2.png'
import briefingonlymode from '../assets/briefing-only.png'
import profileBriefingonlymode from '../assets/profile-briefingonly.png'
// import refetchinfobutton from '../assets/refetchinfobutton.png'
import newaerodromeitemslayout from '../assets/newaerodromeitemslayout.png'
import rainradar from '../assets/rainradar.png'
import { ReactComponent as GoBackIcon } from '../../../../icons/assets/go-back.svg'
import { ReactComponent as InfoIcon } from '../../../../icons/assets/info-nocircle.svg'
import { ReactComponent as SortAlphabeticalIcon } from '../../../../icons/assets/sort-alphabetical.svg'
import { ReactComponent as SortNorthToSouthIcon } from '../../../../icons/assets/sort-northtosouth.svg'
import { ReactComponent as BriefNoItemsIcon } from '../../../../icons/assets/brief-no-items.svg'
import { ReactComponent as AerodromeStdLayoutIcon } from '../../../../icons/assets/aerodrome-std-layout.svg'
import { ReactComponent as AerodromeAltLayoutIcon } from '../../../../icons/assets/aerodrome-alt-layout.svg'
import { ReactComponent as OrderDownIcon } from '../../../../icons/assets/sort-order-down.svg'
import { ReactComponent as FontAdjust } from '../../../../icons/assets/font-adjust.svg'
import { ReactComponent as PDFIcon } from '../../../../icons/assets/pdf.svg'
import { ReactComponent as MobileSMSIcon } from '../../../../icons/assets/mobile-sms.svg'
import { ReactComponent as MobileAuthenticatorIcon } from '../../../../icons/assets/mobile-authenticator.svg'
import { ReactComponent as EmailIcon } from '../../../../icons/assets/email.svg'
import { ReactComponent as Bulletpoint } from '../../../../icons/assets/bulletpoint.svg'
import { RiStarLine } from 'react-icons/ri'

const ButtonExample = (props) => {
    const { icon, buttonLabel, newItem, recentItem } = props
    return (
        <Flex width="100%" justifyContent="flex-start" alignItems="center">
            <Flex
                alignItems="center"
                justifyContent="center"
                border={'1px solid #eaeef4'}
                borderRadius="10px"
                color="light.100"
                variant="outline"
                fontSize="1rem"
                boxShadow="0px 5px 16px -9px rgba(0,0,0,0.4)"
                height={{
                    base: '44px',
                    smd: '40px',
                }}
                width={{
                    base: '46px',
                    smd: '42px',
                }}
                minWidth={{
                    base: '46px',
                    smd: '42px',
                }}
                _hover={{
                    opacity: '1',
                    background: 'none',
                }}
                _active={{
                    background: 'none',
                }}
            >
                <Icon
                    alt=""
                    width={{
                        base:
                            icon === FaExpandAlt ||
                            icon === FaCompressAlt ||
                            icon === TfiMoreAlt
                                ? '22px'
                                : '28px',
                        md:
                            icon === FaExpandAlt ||
                            icon === FaCompressAlt ||
                            icon === TfiMoreAlt
                                ? '18px'
                                : '24px',
                    }}
                    height={{
                        base:
                            icon === FaExpandAlt ||
                            icon === FaCompressAlt ||
                            icon === TfiMoreAlt
                                ? '22px'
                                : '28px',
                        md:
                            icon === FaExpandAlt ||
                            icon === FaCompressAlt ||
                            icon === TfiMoreAlt
                                ? '18px'
                                : '24px',
                    }}
                    as={icon}
                    transform={
                        icon === FaExpandAlt || icon === FaCompressAlt
                            ? 'rotate(-45deg)'
                            : icon === TfiMoreAlt
                            ? 'rotate(90deg)'
                            : 'none'
                    }
                />
            </Flex>
            <Box
                pl="20px"
                fontSize="0.8rem"
                width="100%"
                textAlign="left"
                color="gray.600"
                fontWeight="400"
                lineHeight="1.3"
            >
                {buttonLabel}
            </Box>
            {(newItem || recentItem) && (
                <Badge
                    variant="solid"
                    background={
                        newItem
                            ? 'warning.100'
                            : recentItem
                            ? '#85D0E2'
                            : 'none'
                    }
                    ml="8px"
                    color="white"
                    paddingStart="3"
                    paddingEnd="3"
                    paddingTop="2px"
                    paddingBottom="2px"
                    borderRadius="xl"
                    marginTop="-25px"
                    minWidth="40px"
                    textAlign="center"
                    fontSize="0.7rem"
                    height="18px"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                >
                    {newItem ? 'NEW' : recentItem ? ' RECENT' : ''}
                </Badge>
            )}
        </Flex>
    )
}

// List of Available Automatic Weather Stations (for first announcement)
const awsStations = [
    {
        stationName: 'Cape Reinga',
        icao: 'NZCRX',
    },
    {
        stationName: 'Mokohinau Island',
        icao: 'NZMUX',
    },
    {
        stationName: 'Whangaparaoa',
        icao: 'NZWHX',
    },
    {
        stationName: 'Hicks Bay',
        icao: 'NZHIX',
    },
    {
        stationName: 'Port Taharoa',
        icao: 'NZTRX',
    },
    {
        stationName: 'Taumarunui',
        icao: 'NZJTX',
    },
    {
        stationName: 'Galatea',
        icao: 'NZGAX',
    },
    {
        stationName: 'Flat Hills',
        icao: 'NZFLX',
    },
    {
        stationName: 'Maui-A Platform',
        icao: 'NZUAW',
    },
    {
        stationName: 'Hawera',
        icao: 'NZHAX',
    },
    {
        stationName: 'Waiouru Airstrip',
        icao: 'NZRUA',
    },
    {
        stationName: 'Mahia',
        icao: 'NZMHX',
    },
    {
        stationName: 'Takapau Plains',
        icao: 'NZTKX',
    },
    {
        stationName: 'Ngawi',
        icao: 'NZNWX',
    },
    {
        stationName: 'Castlepoint',
        icao: 'NZCPX',
    },
    {
        stationName: 'Farewell Spit',
        icao: 'NZFSX',
    },
    {
        stationName: 'Stephens Island',
        icao: 'NZSPX',
    },
    {
        stationName: 'Brothers Island',
        icao: 'NZBRX',
    },
    {
        stationName: 'Cape Campbell',
        icao: 'NZCCX',
    },
    {
        stationName: 'Culverden',
        icao: 'NZCUA',
    },
    {
        stationName: 'Haast',
        icao: 'NZHTX',
    },
    {
        stationName: 'Fairlie',
        icao: 'NZFAX',
    },
    {
        stationName: 'Puysegur Point',
        icao: 'NZPYX',
    },
    {
        stationName: 'Lumsden',
        icao: 'NZLUX',
    },
    {
        stationName: 'Roxburgh Aerodrome',
        icao: 'NZRXX',
    },
    {
        stationName: 'Nugget Point',
        icao: 'NZNGX',
    },
    {
        stationName: 'South West Cape',
        icao: 'NZSIX',
    },
    {
        stationName: 'Enderby Island',
        icao: 'NZEDX',
    },
    {
        stationName: 'Campbell Island',
        icao: 'NZCAX',
    },
    {
        stationName: 'Raoul Island',
        icao: 'NZRNX',
    },
    {
        stationName: 'Kaitaia Hospital',
        icao: 'NZJKX',
    },
    {
        stationName: 'Whitianga',
        icao: 'NZWTA',
    },
]

const mobileView = window.innerWidth < 480

export const ANNOUNCEMENT_ITEMS = [
    // *** SAVED FAVOURITES ANNOUNCEMENT ***
    {
        title: <Text>Save Favourite Briefings</Text>,
        announcementDate: '1 February 2024',
        premiumOnly: true,
        image: savedfavourites,
        backgroundGradient: { start: '#0050a1', end: '#9cecff' },
        scrollto: 'saved-favourites-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Save a current Briefing as a favourite</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    We've listened to your feedback and are excited to introduce
                    a new feature that allows you to save customised Briefings
                    as favourites
                </Text>
                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>
                        Load a Briefing from your list of saved Favourites
                    </Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    Now you can effortlessly access your tailored Briefings with
                    any filters and excluded aerodromes or AWS settings,
                    directly from your list of saved Favourites
                </Text>
                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Text fontStyle="italic" color="gray.500">
                        Saved Favourites is available to PLUS and PRO
                        subscription plans only
                    </Text>
                </Flex>
            </>
        ),
        description: (
            <>
                <Flex flexDirection="column" gap="10px">
                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        Saving a Favourite Briefing
                    </Text>
                    <Text>
                        After you have generated a Briefing, and customised it
                        to your preferences, you can save this as a new
                        Favourite by selecting the{' '}
                        <span style={{ fontWeight: '600' }}>Favourites</span>{' '}
                        button in the menu options (located in the top right
                        corner on desktop or in the drop-down menu on mobile).
                    </Text>

                    <Box
                        marginLeft={{ base: '0px', md: '5px' }}
                        mb={{ base: '15px', md: '10px' }}
                        mt="10px"
                        fontWeight="500"
                        color="gray.500"
                        opacity="0.8"
                        textTransform={'uppercase'}
                    >
                        Favourites button and indicator:
                    </Box>
                    <Flex
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '5px', md: '5px' }}
                        pb="20px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        justifyContent="space-around"
                        alignItems="center"
                        color="gray.300"
                        fontWeight="400"
                        gap={{ base: '30px', md: '30px' }}
                        flexDirection={{ base: 'column', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                // border="1px solid"
                                // borderColor="#f2f4f7"
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={savefav}
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                Select this button to save your current Briefing
                                as a Favourite.
                            </Box>
                        </Flex>
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                // border="1px solid"
                                // borderColor="#f2f4f7"
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={savedfav}
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                <Text>
                                    The green star indicates the current
                                    Briefing is already saved as a Favourite.
                                </Text>
                                <Text>
                                    Select this button to manage your saved
                                    Favourites.
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>

                    <Text>
                        The{' '}
                        <span style={{ fontWeight: '600' }}>
                            Manage Favourites
                        </span>{' '}
                        modal window will appear, summarising your Briefing
                        criteria, including any filter selections and excluded
                        Aerodromes or AWS.
                    </Text>
                    <Text>
                        Give your saved Favourite a title and, if helpful, add a
                        reference note to describe the Briefing before saving
                        it.
                    </Text>
                    <Flex
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '5px', md: '5px' }}
                        pt="20px"
                        pb="30px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        justifyContent="space-around"
                        alignItems="center"
                        color="gray.300"
                        fontWeight="400"
                        gap={{ base: '40px', md: '40px' }}
                        flexDirection={{ base: 'column', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={newfavourite1}
                                borderRadius="15px"
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                Save as a new Favourite.
                            </Box>
                        </Flex>
                    </Flex>

                    <Text>
                        Alternatively, you can update an existing favourite.
                    </Text>
                    <Flex
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '5px', md: '5px' }}
                        pt="20px"
                        pb="40px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        justifyContent="space-around"
                        alignItems="center"
                        color="gray.300"
                        fontWeight="400"
                        gap={{ base: '40px', md: '40px' }}
                        flexDirection={{ base: 'column', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={newfavourite2}
                                borderRadius="15px"
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                Update an existing Favourite.
                            </Box>
                        </Flex>
                    </Flex>

                    <Text
                        pt="5px"
                        fontWeight="500"
                        fontSize="0.9rem"
                        color="light.100"
                    >
                        Loading a saved Favourite Briefing
                    </Text>
                    <Text>
                        Loading a saved Favourite allows you to retrieve the
                        same Briefing based on your customised criteria, with
                        up-to-date information. No need to recreate your filter
                        selections or exclude unwanted aerodromes or AWS.
                    </Text>

                    <Flex
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '5px', md: '5px' }}
                        pt="20px"
                        pb="20px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        justifyContent="space-around"
                        alignItems="center"
                        color="gray.300"
                        fontWeight="400"
                        gap={{ base: '30px', md: '30px' }}
                        flexDirection={{ base: 'column', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                // border="1px solid"
                                // borderColor="#f2f4f7"
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={loadfavourite1}
                                borderRadius="15px"
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                You can change what criteria is displayed in
                                your list of saved Favourites.
                            </Box>
                        </Flex>
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                // border="1px solid"
                                // borderColor="#f2f4f7"
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={loadfavourite2}
                                borderRadius="15px"
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                <Text>
                                    Expanding the list of saved Favourites
                                    provides a summary of the saved criteria.
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                    <Flex
                        border="1px solid"
                        borderColor="gray.300"
                        bg="light.30"
                        p="10px"
                        mb="30px"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        textAlign="center"
                    >
                        <Text
                            fontWeight="600"
                            fontSize="0.95rem"
                            color="warning.100"
                        >
                            PLEASE NOTE
                        </Text>
                        <Text pt="5px" px="5px" pb="2px" fontSize="0.85rem">
                            Currently, there is a limit of{' '}
                            <span style={{ fontWeight: '600' }}>up to 5</span>{' '}
                            Briefings that can be added to your list of saved
                            Favourites. This may increase in future.
                        </Text>
                    </Flex>
                    <Text
                        pt="5px"
                        fontWeight="500"
                        fontSize="0.9rem"
                        color="light.100"
                    >
                        Unique URL
                    </Text>
                    <Text>
                        Each Briefing has a unique URL, providing an alternative
                        way to access your "favourite" Briefing. You can use
                        this URL in your web browser's address bar to load the
                        Briefing with the latest information.
                    </Text>
                    <Box
                        pt="5px"
                        pb="15px"
                        px="0px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        color="gray.300"
                        fontWeight="400"
                    >
                        <Image
                            alt=""
                            fit={'cover'}
                            align={'center'}
                            maxWidth="600px"
                            w={'100%'}
                            h={'100%'}
                            borderRadius="12px"
                            src={savedurls}
                            boxShadow={{
                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                            }}
                        />
                    </Box>
                    <Text>
                        You could copy and save this custom URL (and others) as
                        a favourite on your browser and use this at any time
                        (assuming the filters, aerodromes, AWS and briefing
                        areas you require are the same).
                    </Text>
                    <Text>
                        This method works on both desktop and mobile devices,
                        serving as an alternative to the{' '}
                        <span style={{ fontWeight: '600' }}>
                            saved Favourites
                        </span>{' '}
                        functionality.
                    </Text>

                    <Flex
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '5px', md: '5px' }}
                        pt="20px"
                        pb="20px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        justifyContent="space-around"
                        alignItems="center"
                        color="gray.300"
                        fontWeight="400"
                        gap={{ base: '30px', md: '30px' }}
                        flexDirection={{ base: 'column', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={loadfavourite3}
                                borderRadius="15px"
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                Each saved Favourite provides it's unique URL.
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </>
        ),
    },

    // *** BRIEFING ONLY ANNOUNCEMENT ***
    {
        title: <Text>Briefing Only Mode</Text>,
        announcementDate: '1 February 2024',
        premiumOnly: false,
        image: briefingonlymode,
        backgroundGradient: { start: '#c8ffeb', end: '#002f5d' },
        scrollto: 'saved-favourites-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>
                        Enable{' '}
                        <span style={{ fontWeight: '700' }}>
                            Briefing Only Mode
                        </span>{' '}
                        to deactivate{' '}
                        <span style={{ fontWeight: '600' }}>AERO</span>,{' '}
                        <span style={{ fontWeight: '600' }}>MET</span> and{' '}
                        <span style={{ fontWeight: '600' }}>ADVS</span> modes,
                        along with their associated map functions.
                    </Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    This feature is designed to assist users encountering
                    performance issues on certain devices, especially on older
                    devices or those with limited graphics capabilities, or in
                    areas with poor cellular reception.
                </Text>
                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Text fontStyle="italic" color="gray.500">
                        This does not affect access to a comprehensive briefing
                        from standard briefing areas.
                    </Text>
                </Flex>
            </>
        ),
        description: (
            <>
                <Flex flexDirection="column" gap="10px">
                    <Text
                        pt="5px"
                        fontWeight="500"
                        fontSize="0.9rem"
                        color="light.100"
                    >
                        Purpose of Briefing Only Mode
                    </Text>
                    <Text>
                        One of the fundamental aspects of this web application
                        is to make it easier to access and interpret pre-flight
                        information. The key to this is the integration of the
                        dynamic web map that serves as a core part in
                        interpreting the aeronautical and meteorological data.
                    </Text>
                    <Text>
                        However, we understand that the advanced functionalities
                        of the map may occasionally place some demands on the
                        performance of certain devices, particularly for users
                        with older devices or those with limited graphics
                        capabilities. Typically this feature operates more
                        smoothly on newer mobile devices and computers.
                    </Text>
                    <Text>
                        To provide a seamless and responsive experience, we've
                        introduced{' '}
                        <span style={{ fontWeight: '500' }}>
                            Briefing Only Mode
                        </span>{' '}
                        ensuring essential pre-flight information remains
                        readily available for all users.
                    </Text>

                    <Text
                        pt="20px"
                        fontWeight="500"
                        fontSize="0.9rem"
                        color="light.100"
                    >
                        Enabling Briefing Only Mode
                    </Text>

                    <Text>
                        Enabling or disabling Briefing Only Mode is easy. Simply
                        navigate to the{' '}
                        <span style={{ fontWeight: '500' }}>Settings</span> tab
                        within your{' '}
                        <span style={{ fontWeight: '600' }}>Profile</span> where
                        you can find the Briefing Only Mode toggle switch.
                    </Text>
                    <Text>
                        By opting for this mode, you have the flexibility to
                        temporarily deactivate{' '}
                        <span style={{ fontWeight: '500' }}>AERO</span>,{' '}
                        <span style={{ fontWeight: '500' }}>MET</span> and{' '}
                        <span style={{ fontWeight: '500' }}>ADVS</span> modes,
                        along with their associated map functions. This option
                        may help improve perfomance on your device, should you
                        ever encounter any performance issues.
                    </Text>
                    <Text>
                        This feature is entirely optional, and you can easily
                        re-activate these modes by disabling Briefing Only Mode
                        whenever you wish.
                    </Text>
                    <Flex
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '5px', md: '5px' }}
                        pt="25px"
                        pb="40px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        justifyContent="space-around"
                        alignItems="center"
                        color="gray.300"
                        fontWeight="400"
                        gap={{ base: '30px', md: '30px' }}
                        flexDirection={{ base: 'column', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'96%'}
                                h={'auto'}
                                src={profileBriefingonlymode}
                                borderRadius="15px"
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </>
        ),
    },

    // *** MFA ANNOUNCEMENT ***
    {
        title: <Text>Changes to Multi-factor Authentication</Text>,
        announcementDate: (
            <Flex
                flexDirection={{
                    base: 'column',
                    smd: 'row',
                }}
                justifyContent="space-evenly"
                alignItems={{
                    base: '0px',
                    smd: 'flex-start',
                }}
                textAlign="left"
                gap={{
                    base: '0px',
                    smd: '10px',
                }}
            >
                <Text>4 August 2023</Text>
                <Text fontStyle="italic" fontWeight="300">
                    (updated 1 February 2024)
                </Text>
            </Flex>
        ),
        premiumOnly: false,
        linkToModal: 'About MFA',
        image: mfamodal,
        backgroundGradient: { start: '#03556e', end: '#93ffbd' },
        scrollto: 'mfa-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Additional verification methods</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    In addition to SMS, now you can verify through{' '}
                    <span style={{ fontWeight: '600' }}>email</span> or an{' '}
                    <span style={{ fontWeight: '600' }}>authenticator app</span>
                    , such as{' '}
                    <Link
                        color="light.200"
                        fontWeight="500"
                        href={
                            isIOS
                                ? 'https://apps.apple.com/us/app/google-authenticator/id388497605'
                                : isAndroid
                                ? 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
                                : 'https://www.google.com/search?q=google+authenticator'
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Google Authenticator
                    </Link>{' '}
                    or{' '}
                    <Link
                        color="light.200"
                        fontWeight="500"
                        href={
                            'https://www.microsoft.com/en-nz/security/mobile-authenticator-app'
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Microsoft Authenticator
                    </Link>
                    . Particularly helpful when WiFi is available, but limited
                    or no cellular connectivity
                </Text>
                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Improved MFA process</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    MFA is now processed within the web-app to ensure the
                    security of user subscription details and continuity of
                    premium services is maintained whilst not restricting all
                    the standard Free tier services
                </Text>

                <Flex
                    mt="20px"
                    mb="5px"
                    pt="10px"
                    pb="15px"
                    px="15px"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid"
                    borderColor="gray.200"
                    background="light.40"
                >
                    <Flex
                        width="100%"
                        justifyContent="center"
                        alignItems="flex-start"
                        flexWrap="wrap"
                        fontStyle="italic"
                        color="gray.500"
                        flexDirection="column"
                        gap="5px"
                    >
                        <Text pb="0px" pr="5px">
                            MFA is a requirement for –
                        </Text>
                        <Flex
                            marginLeft={{ base: '5px', xl: '15px' }}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            fontSize="0.8rem"
                            fontWeight="400"
                            gap="5px"
                        >
                            <Icon
                                mr="5px"
                                mt="3px"
                                color="light.200"
                                as={Bulletpoint}
                                width="0.8rem"
                                height="0.8rem"
                            />
                            <Flex
                                display={{ base: 'none', xl: 'flex' }}
                                gap="5px"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Text>Access to</Text>
                                <Flex
                                    height="18px"
                                    bg="rgba(0, 188, 248, 1)"
                                    color="#FFFFFF"
                                    fontFamily="'Open Sans', sans-serif"
                                    pl="4px"
                                    pr="6px"
                                    borderRadius="20px"
                                    fontSize="0.75rem"
                                    fontWeight="600"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap="3px"
                                    width="88px"
                                    fontStyle="normal"
                                >
                                    <RiStarLine
                                        color="#FFFFFF"
                                        fontSize="0.9rem"
                                    />
                                    PREMIUM
                                </Flex>
                            </Flex>
                            <Text
                                display={{ base: 'block', xl: 'none' }}
                                textAlign="left"
                            >
                                Access to PREMIUM features on{' '}
                                <span
                                    style={{
                                        fontWeight: '600',
                                        color: '#007DC8',
                                    }}
                                >
                                    PLUS
                                </span>{' '}
                                and{' '}
                                <span
                                    style={{
                                        fontWeight: '600',
                                        color: '#8D38E3',
                                    }}
                                >
                                    PRO
                                </span>{' '}
                                subscription plans.
                            </Text>
                            <Text
                                display={{ base: 'none', xl: 'block' }}
                                textAlign="left"
                            >
                                features on{' '}
                                <span
                                    style={{
                                        fontWeight: '600',
                                        color: '#007DC8',
                                    }}
                                >
                                    PLUS
                                </span>{' '}
                                and{' '}
                                <span
                                    style={{
                                        fontWeight: '600',
                                        color: '#8D38E3',
                                    }}
                                >
                                    PRO
                                </span>{' '}
                                subscription plans.
                            </Text>
                        </Flex>
                        <Flex
                            marginLeft={{ base: '5px', xl: '15px' }}
                            gap="10px"
                            justifyContent="center"
                            alignItems="flex-start"
                            fontSize="0.8rem"
                            fontWeight="400"
                        >
                            <Icon
                                mt="3px"
                                color="light.200"
                                as={Bulletpoint}
                                width="0.8rem"
                                height="0.8rem"
                            />
                            <Text textAlign="left">
                                Managing account details and password changes
                                (all users).
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </>
        ),
        description: (
            <>
                <Flex flexDirection="column" gap="10px">
                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        Why has MFA changed?
                    </Text>
                    <Text>
                        Previously Multi-factor Authentication (MFA) was
                        required before providing access to PreFlight which was
                        limiting to users where cellular connectivity was
                        limited or unavailable.
                    </Text>
                    <Text>
                        Now MFA is required within the web-app to ensure all the
                        standard Free tier services continue to be available,
                        whist maintaining the security of user's personal
                        details, premium subscription details, and appropriate
                        management of premium services.
                    </Text>
                    <Text>
                        With the addition of alternative verification methods,
                        the MFA process is now more user friendly and less
                        restricting.
                    </Text>

                    <Text
                        pt="15px"
                        fontWeight="500"
                        fontSize="0.9rem"
                        color="light.100"
                    >
                        Verification Options
                    </Text>
                    <Flex
                        pt={{ base: '10px', md: '10px' }}
                        pb={{ base: '20px', smd: '10px' }}
                        width={{ base: '100%', sm: '100%' }}
                        flexDirection={{
                            base: 'column',
                            smd: 'row',
                        }}
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        px={{ base: '0px', smd: '20px' }}
                        gap="30px"
                    >
                        <Flex
                            width={{ base: '100%', smd: '30%' }}
                            justifyContent="space-evenly"
                            alignItems="flex-start"
                            fontSize="0.8rem"
                            color="gray.500"
                            flexDirection="column"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="50px"
                            >
                                <Icon
                                    as={MobileSMSIcon}
                                    boxSize="10"
                                    mx="10px"
                                    mt="1px"
                                    pl="1px"
                                />
                            </Flex>
                            <Text
                                width="100%"
                                textAlign="center"
                                borderBottom="1px solid #e2e5e9"
                                pb="5px"
                                mb="10px"
                                color="light.200"
                                fontWeight="500"
                                fontSize="0.9rem"
                            >
                                SMS
                            </Text>
                            <Text
                                width="100%"
                                px={{ base: '15px', smd: '0px' }}
                                textAlign="center"
                                color="gray.500"
                                fontWeight="300"
                            >
                                Get a text message with verification code sent
                                to your mobile
                            </Text>
                        </Flex>

                        <Flex
                            width={{ base: '100%', smd: '30%' }}
                            justifyContent="space-evenly"
                            alignItems="flex-start"
                            fontSize="0.8rem"
                            color="gray.500"
                            flexDirection="column"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="50px"
                            >
                                <Icon
                                    as={MobileAuthenticatorIcon}
                                    boxSize="10"
                                    mx="10px"
                                    mt="1px"
                                    pl="1px"
                                />
                            </Flex>
                            <Text
                                width="100%"
                                textAlign="center"
                                borderBottom="1px solid #e2e5e9"
                                pb="5px"
                                mb="10px"
                                fontWeight="500"
                                color="light.200"
                                fontSize="0.9rem"
                            >
                                Authenticator
                            </Text>
                            <Text
                                width="100%"
                                px={{ base: '15px', smd: '0px' }}
                                textAlign="center"
                                color="gray.500"
                                fontWeight="300"
                            >
                                Get a time-based verification code from an
                                Authenticator app, such as Google Authenticator
                            </Text>
                        </Flex>

                        <Flex
                            width={{ base: '100%', smd: '30%' }}
                            justifyContent="space-evenly"
                            alignItems="flex-start"
                            fontSize="0.8rem"
                            color="gray.500"
                            flexDirection="column"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="50px"
                            >
                                <Icon
                                    as={EmailIcon}
                                    boxSize="10"
                                    mx="10px"
                                    mt="1px"
                                    pl="1px"
                                />
                            </Flex>
                            <Text
                                width="100%"
                                textAlign="center"
                                borderBottom="1px solid #e2e5e9"
                                pb="5px"
                                mb="10px"
                                fontWeight="500"
                                color="light.200"
                                fontSize="0.9rem"
                            >
                                Email
                            </Text>
                            <Text
                                width="100%"
                                px={{ base: '15px', smd: '0px' }}
                                textAlign="center"
                                color="gray.500"
                                fontWeight="300"
                            >
                                Get a verification code sent to your email
                                address
                            </Text>
                        </Flex>
                    </Flex>
                    <Text>
                        Based on user feedback, we've introduced the
                        Authenticator App and Email options. These options cater
                        to users in areas where WiFi is available, but limited
                        or no cellular connectivity, enabling easy access to
                        PreFlight's premium services without relying on text
                        messages.
                    </Text>

                    <Text
                        pt="15px"
                        fontWeight="500"
                        fontSize="0.9rem"
                        color="light.100"
                    >
                        Why do we need MFA?
                    </Text>
                    <Text>
                        MFA is an important and necessary requirement for users
                        primarily for the following reasons –
                    </Text>
                    <Text
                        pt="5px"
                        pl={{ base: '0px', md: '10px' }}
                        color="gray.500"
                        fontSize="0.9rem"
                        fontWeight="500"
                    >
                        Security
                    </Text>
                    <Text pl={{ base: '0px', md: '25px' }}>
                        The security of our user's accounts is paramount. By
                        integrating MFA, we ensure an additional level of
                        security to safeguard against unauthorised access. This
                        proactive approach significantly reduces the risk of
                        data breaches and ensures the confidentiality of
                        sensitive information.
                    </Text>
                    <Text pl={{ base: '0px', md: '25px' }}>
                        MFA has become a standard practice across various
                        sectors, including government agencies, in response to
                        evolving cybersecurity threats. Its integration aligns
                        with prevailing security standards, underscoring our
                        commitment to adhering to best practices in safeguarding
                        user data. Furthermore, to minimise privacy risks, we
                        collect only essential personal information.
                    </Text>
                    <Text
                        pl={{ base: '0px', md: '10px' }}
                        pt="10px"
                        color="gray.500"
                        fontSize="0.9rem"
                        fontWeight="500"
                    >
                        Managing Premium Services
                    </Text>
                    <Text pl={{ base: '0px', md: '25px' }}>
                        In tandem with security, MFA plays a pivotal role in the
                        management of premium services offered by PreFlight. Our
                        Premium subscription plan unlocks additional features
                        and functionality beyond the free services. MFA also
                        helps to address a known issue with non-paying users
                        gaining access to these premium services, particularly
                        in shared device environments. This assures that only
                        eligible subscribers enjoy the benefits of our premium
                        features, thereby ensuring the long-term sustainability
                        of PreFlight's operating model.
                    </Text>

                    <Text pt="15px">
                        Further details on MFA can be found in the{' '}
                        <span style={{ fontWeight: '600' }}>Help</span> section
                        from within the admin dashboard, or by clicking the
                        button below.
                    </Text>
                </Flex>
            </>
        ),
    },

    // *** HELPFUL TIPS ANNOUNCEMENT ***
    {
        title: <Text>User Experience Enhancements</Text>,
        announcementDate: '4 August 2023',
        premiumOnly: false,
        image: profilemodal,
        backgroundGradient: { start: '#ffac83', end: '#730161' },
        scrollto: 'mfa-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Improvements to Service Messages</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    We've introduced a number of user-friendly enhancements to
                    our Service Messages system, designed to improve your
                    overall experience
                </Text>
                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Tailored settings in your Profile</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    We've recently introduced a set of useful options in your
                    Profile settings, allowing you to customise certain features
                    and actions to better suit your preferences
                </Text>
            </>
        ),
        description: (
            <>
                <Flex flexDirection="column" gap="10px">
                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        Update to Service Messages
                    </Text>
                    <Text>
                        Previously, Service Messages that remained promulgated
                        for an extended period would repetitively display after
                        every login or page refresh. We appreciate that this
                        could be quite bothersome, especially if the message was
                        irrelevant to your flight plans or persisted for many
                        days.
                    </Text>
                    <Text>
                        To enhance your experience, the Service Messages modal
                        window will now automatically appear only when there is
                        a new message, which will be clearly marked as '
                        <span style={{ fontWeight: '600' }}>NEW</span>'. This
                        ensures you won't be inundated with repeated messages
                        anymore.
                    </Text>
                    <Text>
                        Once you close the Service Message modal window, the
                        messages will be automatically tagged as read, removing
                        the '<span style={{ fontWeight: '600' }}>NEW</span>'
                        indicator. This way, you can easily keep track of what
                        you've viewed.
                    </Text>
                    <Text>
                        We've also added a convenient notification system to
                        keep you updated on new and read messages. Whenever
                        there are new messages, a green dot indicator will
                        appear on the admin dashboard button. Once you've read
                        all the new messages, the green dot will change,
                        displaying a transparent center. The dot will remain as
                        long as there are any current messages, regardless of
                        whether they have been read or not. The dot will
                        disappear when there are no current messages.
                    </Text>

                    <Box
                        marginLeft={{ base: '0px', md: '5px' }}
                        mb={{ base: '15px', md: '10px' }}
                        mt="10px"
                        fontWeight="500"
                        color="gray.500"
                        opacity="0.8"
                        textTransform={'uppercase'}
                    >
                        Service Messages notification indicator:
                    </Box>
                    <Flex
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '5px', md: '5px' }}
                        pb="20px"
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        justifyContent="space-around"
                        alignItems="center"
                        color="gray.300"
                        fontWeight="400"
                        gap={{ base: '30px', md: '30px' }}
                        flexDirection={{ base: 'column', md: 'row' }}
                    >
                        <Flex
                            width="100%"
                            maxWidth="300px"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                // border="1px solid"
                                // borderColor="#f2f4f7"
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={newmessages}
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                There are new unread messages
                            </Box>
                        </Flex>
                        <Flex
                            width="100%"
                            maxWidth="300px"
                            alignItems="center"
                            gap="20px"
                            flexDirection="column"
                        >
                            <Image
                                // border="1px solid"
                                // borderColor="#f2f4f7"
                                alt=""
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={readmessages}
                                boxShadow={{
                                    base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                    md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                }}
                            />
                            <Box
                                width="100%"
                                textAlign="center"
                                color="gray.500"
                                opacity="0.8"
                                fontWeight="300"
                            >
                                All current messages have been read
                            </Box>
                        </Flex>
                    </Flex>
                    <Text>
                        Should you wish to review any previous Service Messages,
                        you can access them at any time by selecting the{' '}
                        <span style={{ fontWeight: '600' }}>
                            Service Messages
                        </span>{' '}
                        button from within the admin dashboard. This way,
                        important information remains accessible without causing
                        any undue annoyance during regular usage.
                    </Text>
                    <Flex
                        pt="20px"
                        pb="40px"
                        px={{ base: '5px', md: '20px' }}
                        maxWidth="600px"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Image
                            alt=""
                            fit={'cover'}
                            align={'center'}
                            maxWidth="320px"
                            w={'100%'}
                            h={'100%'}
                            src={servicemessages}
                            boxShadow={{
                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                            }}
                        />
                    </Flex>
                    <Flex
                        border="1px solid"
                        borderColor="gray.300"
                        bg="light.30"
                        p="10px"
                        mb="50px"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        textAlign="center"
                    >
                        <Text fontWeight="600" fontSize="0.9rem">
                            PLEASE NOTE
                        </Text>
                        <Text pt="5px">
                            In certain cases, particularly on mobile browsers
                            with stringent cache settings aimed at optimising
                            browser performance, the browser cache may impede
                            the prompt recognition of the updated state of{' '}
                            <span style={{ fontWeight: '500' }}>
                                Service Messages
                            </span>{' '}
                            and{' '}
                            <span style={{ fontWeight: '500' }}>
                                Announcements
                            </span>
                            .
                        </Text>
                        <Text pt="5px" pb="5px">
                            This could lead to a small delay before{' '}
                            <span style={{ fontWeight: '500' }}>
                                Service Messages
                            </span>{' '}
                            or{' '}
                            <span style={{ fontWeight: '500' }}>
                                Announcements
                            </span>{' '}
                            are recognised as read should you choose to refresh
                            the page and until the cache is updated by the
                            browser.
                        </Text>
                    </Flex>

                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        Customisable Options
                    </Text>
                    <Text>
                        Over the last few months, we have quietly introduced a
                        set of useful options in the Settings tab within your
                        Profile. These settings allow you to tailor certain
                        features and actions within the web-app, making them
                        better suit your individual preferences. With these
                        enhancements, we aim to provide you with a more tailored
                        experience as an added level of personalisation.
                    </Text>
                    <Box
                        pt="20px"
                        pb="10px"
                        px={{ base: '5px', md: '20px' }}
                        maxWidth="600px"
                        width="100%"
                        textAlign="center"
                        color="gray.300"
                        fontWeight="400"
                    >
                        <Image
                            alt=""
                            fit={'cover'}
                            align={'center'}
                            maxWidth="600px"
                            w={'100%'}
                            h={'100%'}
                            src={profilesettings}
                            boxShadow={{
                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                            }}
                        />
                    </Box>
                    <Box
                        pt="15px"
                        marginLeft={{ base: '0px', md: '20px' }}
                        fontWeight="500"
                        color="gray.500"
                        opacity="0.8"
                        textTransform={'uppercase'}
                    >
                        Settings explained:
                    </Box>
                    <Flex
                        pl={{ base: '0px', md: '20px' }}
                        pb="20px"
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                        gap="20px"
                        textAlign={{ base: 'center', md: 'start' }}
                    >
                        <Divider />
                        <Flex
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            flexDirection="column"
                            gap="5px"
                            pl={{ base: '0', md: '15px' }}
                        >
                            <Text
                                width="100%"
                                fontWeight="500"
                                fontSize="0.85rem"
                                color="light.100"
                            >
                                Set a default map location
                            </Text>
                            <Text width="100%" color="gray.500">
                                Set a favourite position for the map to zoom and
                                center to when opening PreFlight.
                            </Text>
                        </Flex>
                        <Divider />

                        <Flex
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            flexDirection="column"
                            gap="5px"
                            pl={{ base: '0', md: '15px' }}
                        >
                            <Text
                                width="100%"
                                fontWeight="500"
                                fontSize="0.85rem"
                                color="light.100"
                            >
                                Zoom map when selecting an Aerodrome, AWS, or
                                Webcam
                            </Text>
                            <Text width="100%" color="gray.500">
                                Enable/disable the map zooming in to an
                                Aerodrome, AWS, or Webcam when each are
                                selected. The map will also zoom out to the
                                previous zoom level after closing the popout
                                drawer when selecting the close button only.
                            </Text>
                            <Text width="100%" color="gray.500">
                                Enabled as standard.
                            </Text>
                            <Text
                                width="100%"
                                pt="5px"
                                pl={{ base: '0px', md: '10px' }}
                                fontStyle="italic"
                                color="gray.400"
                                opacity="0.8"
                                fontSize="0.75rem"
                            >
                                – AWS and Webcams are available to PLUS and PRO
                                subscription plans only.
                            </Text>
                        </Flex>
                        <Divider />
                        <Flex
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            flexDirection="column"
                            gap="5px"
                            pl={{ base: '0', md: '15px' }}
                        >
                            <Text
                                width="100%"
                                fontWeight="500"
                                fontSize="0.85rem"
                                color="light.100"
                            >
                                Aerodrome and AWS weather data graphics
                            </Text>
                            <Text width="100%" color="gray.500">
                                Enable/disable graphics for the weather data on
                                the Aerodrome INFO tab, and AWS. Disabling
                                simplifies the layout of the information which
                                may be preferred by some users.
                            </Text>
                            <Text width="100%" color="gray.500">
                                Enabled as standard.
                            </Text>
                            <Text
                                width="100%"
                                pt="5px"
                                pl={{ base: '0px', md: '10px' }}
                                fontStyle="italic"
                                color="gray.400"
                                opacity="0.8"
                                fontSize="0.75rem"
                            >
                                – AWS is available to PLUS and PRO subscription
                                plans only.
                            </Text>
                        </Flex>
                        <Divider />
                        <Flex
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            flexDirection="column"
                            gap="5px"
                            pl={{ base: '0', md: '15px' }}
                            pb="5px"
                        >
                            <Text
                                width="100%"
                                fontWeight="500"
                                fontSize="0.85rem"
                                color="light.100"
                            >
                                Aerodrome INFO tab background imagery
                            </Text>
                            <Text width="100%" color="gray.500">
                                Enable/disable the current weather background
                                imagery on the Aerodrome INFO tab (applicable
                                only to aerodromes that report cloud conditions
                                in a METAR).
                            </Text>
                            <Text width="100%" color="gray.500">
                                Enabled as standard.
                            </Text>
                        </Flex>
                        <Divider />
                        <Flex
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            flexDirection="column"
                            gap="5px"
                            pl={{ base: '0', md: '15px' }}
                        >
                            <Flex
                                flexDirection={{
                                    base: 'column-reverse',
                                    md: 'row',
                                }}
                                justifyContent="space-between"
                                gap="8px"
                                width="100%"
                                alignItems="center"
                            >
                                <Text
                                    width="100%"
                                    fontWeight="500"
                                    fontSize="0.85rem"
                                    color="light.100"
                                >
                                    Set decoded weather text as default
                                </Text>
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Badge
                                        variant="solid"
                                        background="brand.premium"
                                        color="white"
                                        paddingStart="6px"
                                        paddingEnd="8px"
                                        paddingTop="2px"
                                        paddingBottom="2px"
                                        borderRadius="xl"
                                        marginTop={{
                                            base: '0',
                                            md: '-10px',
                                        }}
                                        minWidth="40px"
                                        textAlign="center"
                                        fontSize="0.7rem"
                                        fontWeight="500"
                                        height="18px"
                                        justifyContent="center"
                                        alignItems="center"
                                        display="flex"
                                    >
                                        <RiStarLine
                                            color="white"
                                            fontSize="0.8rem"
                                        />
                                        <Text pl="3px" color="white">
                                            PREMIUM
                                        </Text>
                                    </Badge>
                                </Flex>
                            </Flex>
                            <Text width="100%" color="gray.500">
                                Set decoded weather text as default for METAR,
                                TAF, ATIS and AAW.
                            </Text>
                            <Text width="100%" color="gray.500">
                                Disabled as standard, and for non-premium users.
                            </Text>
                            <Text
                                width="100%"
                                pt="5px"
                                pl={{ base: '0px', md: '10px' }}
                                fontStyle="italic"
                                color="gray.400"
                                opacity="0.8"
                                fontSize="0.75rem"
                            >
                                – Decoded weather text is available to PLUS and
                                PRO subscription plans only.
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </>
        ),
    },

    // *** AWS ANNOUNCEMENT ***
    {
        title: <Text>Automatic Weather Stations</Text>,
        announcementDate: '22 June 2023',
        premiumOnly: true,
        image: awslayer,
        backgroundGradient: { start: '#006086', end: '#90fffb' },
        scrollto: 'aws-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>New AWS layer in Met mode</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    Now you can select any of the MetService's certified
                    Automatic Weather Stations around New Zealand
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>AWS added to Brief mode</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    AWS are now included as an additional item, and can be
                    filtered in the same way as Aerodromes
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Text fontStyle="italic" color="gray.500">
                        AWS is available to PLUS and PRO subscription plans only
                    </Text>
                </Flex>
            </>
        ),
        description: (
            <>
                <Flex flexDirection="column" gap="10px">
                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        Evaluation of Automatic Weather Stations
                    </Text>
                    <Text>
                        As a holder of a Part 174 certificate, it is essential
                        for the MetService to ensure that the data and products
                        it offers meet specific quality standards. In order to
                        uphold these standards, the MetService has conducted a
                        thorough evaluation of the supplementary Automatic
                        Weather Stations (AWS) accessible on it's aviation
                        platforms.
                    </Text>
                    <Text>
                        The stations included in PreFlight are in accordance
                        with what is listed in the MetService's Part 174
                        Exposition and the AIP. These stations have been
                        certified to provide data for aviation purposes. As a
                        result of this evaluation, you may notice that the
                        available station options on PreFlight have been reduced
                        compared to what had previously been available on
                        MetFlight Commercial.
                    </Text>
                    <Text>
                        If you have any feedback, please don't hesitate to reach
                        out to the MetService via the Feedback option in the
                        admin dashboard.
                    </Text>
                    <Flex flexDirection="column" gap="0px">
                        <Text
                            pt="15px"
                            fontWeight="500"
                            fontSize="0.9rem"
                            color="light.100"
                        >
                            Available Automatic Weather Stations
                        </Text>
                        <Text
                            pb="5px"
                            fontWeight="400"
                            color="gray.500"
                            opacity="0.8"
                            width="100%"
                        >
                            MetService's current Automatic Weather Stations
                            certified for aviation use
                        </Text>
                    </Flex>
                    <TableContainer pb="10px">
                        <Table variant="simple">
                            <Thead>
                                <Tr
                                    background="gray.200"
                                    style={{
                                        color: '#20b1d3',
                                        height: '30px',
                                        minHeight: '30px',
                                    }}
                                >
                                    <Th
                                        style={{
                                            color: '#20b1d3',
                                            paddingLeft: '15px',
                                        }}
                                    >
                                        STATION NAME
                                    </Th>
                                    <Th
                                        style={{
                                            color: '#20b1d3',
                                            paddingLeft: '15px',
                                            display: {
                                                base: 'none !important',
                                                md: 'table-cell',
                                            },
                                        }}
                                    >
                                        {`ICAO ${
                                            mobileView ? '' : 'DESIGNATOR'
                                        }`}
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {awsStations
                                    .sort((a, b) => {
                                        const stationA = a.stationName
                                        const stationB = b.stationName
                                        if (stationA > stationB) {
                                            return 1
                                        }
                                        if (stationA < stationB) {
                                            return -1
                                        } else {
                                            return 0
                                        }
                                    })
                                    .map((row, index) => (
                                        <Tr
                                            key={`AnnouncementItem${row}${index}`}
                                            background={
                                                index % 2 === 1
                                                    ? 'light.30'
                                                    : undefined
                                            }
                                            style={{
                                                height: '32px',
                                                minHeight: '32px',
                                            }}
                                        >
                                            <Td
                                                style={{
                                                    paddingLeft: '15px',
                                                }}
                                            >
                                                {row.stationName}
                                            </Td>
                                            <Td
                                                style={{
                                                    paddingLeft: '15px',
                                                }}
                                            >
                                                {row.icao}
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            </>
        ),
    },

    // *** RAIN RADAR ANNOUNCEMENT ***

    {
        title: <Text>Improved Rain Radar</Text>,
        announcementDate: '22 June 2023',
        premiumOnly: false,
        image: rainradar,
        backgroundGradient: { start: '#1a3aa9', end: '#c4a9ff' },
        scrollto: 'rainradar-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>New NZ wide view of radar network</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    You can now view radar across NZ in a single view improving
                    situational awareness
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Added zoom and drag functionality</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    Also included with Rain Forecast Charts, Cloud Imagery, and
                    Low Cloud Forecast Charts
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Updated layout on mobile</Text>
                </Flex>
            </>
        ),
        description: null,
    },

    // *** THIRD ANNOUNCEMENT ***
    {
        title: <Text>Enhancements to the Briefing</Text>,
        announcementDate: '22 June 2023',
        premiumOnly: false,
        image: newaerodromeitemslayout,
        backgroundGradient: { start: '#8bc5ff', end: '#6bff91' },
        scrollto: 'briefingenhancements-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Addition of AWS for Premium users</Text>
                </Flex>
                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>
                        Revised layout for aerodrome information types (NOTAM,
                        METAR, TAF, ATIS)
                    </Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    The aerodrome information types are now more easily
                    identifiable when consolidated and sorted by their
                    respective aerodromes, and each can be filtered separately
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Improved Briefing menu</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    To accommodate inclusion of AWS and improved aerodrome
                    structure. On both mobile and desktop
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>
                        Ability to refetch updated information from within the
                        existing Briefing
                    </Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    On mobile when scrolled to the top of the page, pull-down
                    the page to refetch updated information. Or on desktop
                    select the new 'Refetch Information' button in the top right
                    corner.
                </Text>
            </>
        ),
        description: null,
    },
    // *** SIGWX ANNOUNCEMENT ***
    {
        title: <Text>Enhancements to Graphical NZ SIGWX Charts</Text>,
        announcementDate: '7 March 2023',
        premiumOnly: false,
        image: sigwxcharts,
        backgroundGradient: { start: '#5024c4', end: '#ffcc98' },
        scrollto: 'sigwx-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Altering issue time and validity period</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    Validity time of charts will be 6 hours, with 2-3 charts
                    valid at any one point in time
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Differentiation between turbulence types</Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    (Clear air turbulence and low level turbulence)
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>Remark included when issuing chart amendments</Text>
                </Flex>
            </>
        ),
        description: (
            <>
                <Flex flexDirection="column" gap="10px">
                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        Introduction
                    </Text>
                    <Text>
                        Graphical New Zealand Significant Weather charts
                        (GNZSIGWX) provide a graphical display of forecast
                        information for flights within the NZZC flight
                        information region. The forecast phenomena covered in
                        these charts includes the horizontal and vertical extent
                        of turbulence, mountain waves, cumulonimbus clouds (CB)
                        and icing. They also provide awareness information for
                        hazardous phenomena such as volcanic ash and radioactive
                        cloud.
                    </Text>
                    <Text>
                        At MetService we are committed to continuous improvement
                        of our aviation products. We value feedback from our
                        customers and take it onboard as part of our product
                        review and decision-making processes.
                    </Text>
                    <Text>
                        Based on feedback received from customers, we have
                        reviewed the current state of our GNZSIGWX charts and
                        have identified three key areas for improvement which
                        are outlined below.
                    </Text>
                </Flex>
                <Flex flexDirection="column" gap="10px">
                    <Text
                        fontWeight="500"
                        fontSize="0.9rem"
                        color="light.100"
                        pt="10px"
                    >
                        Summary of Enhancements
                    </Text>
                    <Box pt="10px" marginLeft={{ base: '-2px', md: '25px' }}>
                        <ol>
                            <li
                                style={{
                                    paddingBottom: '20px',
                                    fontWeight: '500',
                                }}
                            >
                                <Box
                                    pt="0px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontSize="0.85rem"
                                    fontWeight="500"
                                >
                                    Altering issue time and validity period
                                </Box>
                                <Box
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    The current version of the GNZSIGWX is a
                                    single chart issued three times per day,
                                    with each chart having a 15-hour validity.
                                    The validity period of the GNZSIGWX chart
                                    will be reduced to 6 hours but there will be
                                    2-3 charts valid at any one point in time.
                                    These will be issued four times per day.
                                </Box>
                                <Box
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    The following table outlines the new issue
                                    times and associated validity periods:
                                </Box>
                                <Image
                                    alt=""
                                    pt="10px"
                                    pb="10px"
                                    px={{ base: '5px', md: '20px' }}
                                    fit={'cover'}
                                    align={'center'}
                                    maxWidth="600px"
                                    w={'100%'}
                                    h={'100%'}
                                    src={validityperiods}
                                />
                                <Box
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    The three standard height versions of the
                                    GNZSIGWX will be retained: SFC to FL100, SFC
                                    to FL250 and SFC to FL410. In PreFlight, the
                                    heights presented can be individually
                                    selected to customise information that is
                                    relevant for your flight.
                                </Box>
                            </li>
                            <li
                                style={{
                                    paddingBottom: '20px',
                                    fontWeight: '500',
                                }}
                            >
                                <Box
                                    pt="0px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontSize="0.85rem"
                                    fontWeight="500"
                                >
                                    Differentiation between turbulence types
                                </Box>
                                <Box
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    In the current chart, areas of both Clear
                                    Air Turbulence (CAT) and Low-level
                                    Turbulence (LLT) are indicated by the same
                                    red lined/shaded notation. CAT is often a
                                    higher-level phenomenon associated with wind
                                    changes and shear, nearly always above
                                    FL160, and often above FL240. LLT is
                                    associated with low level winds interacting
                                    with the orography and is therefore nearly
                                    always confined below FL120. The fact that
                                    both phenomena can occur in similar areas at
                                    the same time means these red areas overlap,
                                    making it difficult to identify each area.
                                </Box>
                                <Box
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    To allow differentiation between the two
                                    phenomena, LLT will retain the red
                                    lined/shaded notation, and CAT will now be
                                    represented by an orange line/shaded
                                    notation.
                                </Box>
                                <Image
                                    alt=""
                                    pt="10px"
                                    pb="10px"
                                    px={{ base: '5px', md: '20px' }}
                                    fit={'cover'}
                                    align={'center'}
                                    maxWidth="600px"
                                    w={'100%'}
                                    h={'100%'}
                                    src={chartexample}
                                />
                            </li>
                            <li
                                style={{
                                    paddingBottom: '20px',
                                    fontWeight: '500',
                                }}
                            >
                                <Box
                                    pt="0px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontSize="0.85rem"
                                    fontWeight="500"
                                >
                                    Remark included when issuing chart
                                    amendments
                                </Box>
                                <Box
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    Charts will be amended if un-forecast
                                    hazards occur or if a forecast hazard is no
                                    longer expected to occur within the validity
                                    period of the chart. In this event, the
                                    amended chart will have the same validity as
                                    the original chart but have “AMD” in the
                                    bottom right text box. Any amended charts
                                    will now include a comment specifying what
                                    has been changed.
                                </Box>
                                <Image
                                    alt=""
                                    pt="15px"
                                    pb="10px"
                                    px={{ base: '5px', md: '25px' }}
                                    fit={'cover'}
                                    align={'center'}
                                    maxWidth="400px"
                                    w={'100%'}
                                    h={'100%'}
                                    src={remarkexample}
                                />
                            </li>
                        </ol>
                    </Box>
                </Flex>
            </>
        ),
    },

    // *** ALT BRIEFING ANNOUNCEMENT ***
    {
        title: (
            <Text>
                New alternative Briefing layout and adjustable font size
            </Text>
        ),
        announcementDate: '7 March 2023',
        premiumOnly: false,
        image: briefinglayout,
        backgroundGradient: { start: '#a1faff', end: '#8c7bff' },
        scrollto: 'briefing-announcement',
        keyPoints: (
            <>
                <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>
                        Change the aerodrome information to be sorted by type
                    </Text>
                </Flex>
                <Text
                    color="gray.500"
                    opacity="0.8"
                    fontSize="0.8rem"
                    fontWeight="400"
                    pl={{ base: '0px', md: '20px' }}
                    pt="3px"
                >
                    (METAR, TAF, ATIS, NOTAM)
                </Text>

                <Flex
                    pt="15px"
                    justifyContent={{ base: 'center', md: 'flex-start' }}
                >
                    <Icon
                        as={BsDot}
                        fontSize="20px"
                        display={{ base: 'none', md: 'inline-block' }}
                    />
                    <Text>
                        Adjustable font size for easier reading in the Briefing
                    </Text>
                </Flex>
            </>
        ),
        description: (
            <>
                <Flex flexDirection="column" gap="10px">
                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        What is new
                    </Text>
                    <Text>
                        Based on feedback received from users, we have now added
                        a new alternative layout of the aerodrome information
                        which is more akin to how it was displayed in MetFlight
                        previously.
                    </Text>
                    <Text>
                        Also, we heard from some users that the briefing font
                        size can be a little small to read at times,
                        particularly when using on a mobile device and in the
                        cockpit. We have added the ability to increase the font
                        from the default size.
                    </Text>
                </Flex>
                <Flex flexDirection="column" gap="10px">
                    <Box pt="10px" marginLeft={{ base: '0px', md: '25px' }}>
                        <ul>
                            <li
                                style={{
                                    paddingBottom: '20px',
                                    fontWeight: '500',
                                }}
                            >
                                <Box
                                    pt="0px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontSize="0.85rem"
                                    fontWeight="500"
                                >
                                    Sort the aerodrome information by list of
                                    aerodromes or by type
                                </Box>
                                <Flex
                                    flexDirection="column"
                                    gap="10px"
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    <Text>
                                        Some users prefer to have information
                                        collated per aerodrome, others like to
                                        see METARs, TAFs, ATIS', and NOTAMs
                                        separated.
                                    </Text>

                                    <Text>
                                        Now you can easily change the aerodrome
                                        information to your preferred layout by
                                        clicking the Sort Aerodrome Information
                                        button in the options menu at the top of
                                        the screen.
                                    </Text>
                                </Flex>
                                <Box
                                    pt="20px"
                                    pb="10px"
                                    px={{ base: '5px', md: '20px' }}
                                    maxWidth="600px"
                                    width="100%"
                                    textAlign="center"
                                    color="gray.300"
                                    fontWeight="400"
                                >
                                    <Image
                                        alt=""
                                        fit={'cover'}
                                        align={'center'}
                                        maxWidth="600px"
                                        w={'100%'}
                                        h={'100%'}
                                        src={aerodromeinfobutton}
                                        boxShadow={{
                                            base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                            md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                        }}
                                    />
                                </Box>
                                <Box
                                    pt="20px"
                                    pb="10px"
                                    px={{ base: '5px', md: '20px' }}
                                    maxWidth="600px"
                                    width="100%"
                                    textAlign="center"
                                    color="gray.300"
                                    fontWeight="400"
                                >
                                    <Image
                                        alt=""
                                        fit={'cover'}
                                        align={'center'}
                                        maxWidth="600px"
                                        w={'100%'}
                                        h={'100%'}
                                        src={briefinglayout}
                                        boxShadow="0px 5px 30px -15px rgba(0,0,0,0.4)"
                                    />
                                </Box>
                            </li>
                            <li
                                style={{
                                    paddingBottom: '20px',
                                    fontWeight: '500',
                                }}
                            >
                                <Box
                                    pt="30px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontSize="0.85rem"
                                    fontWeight="500"
                                >
                                    Adjustable font size for easier reading in
                                    the Briefing
                                </Box>
                                <Box
                                    pt="5px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                >
                                    When selecting the Font Size button in the
                                    options menu, a slider will appear which
                                    will allow you to adjust the font size
                                    accordingly.
                                </Box>
                                <Box
                                    pt="20px"
                                    pb="10px"
                                    px={{ base: '5px', md: '20px' }}
                                    maxWidth="600px"
                                    width="100%"
                                    textAlign="center"
                                    color="gray.300"
                                    fontWeight="400"
                                >
                                    <Image
                                        alt=""
                                        fit={'cover'}
                                        align={'center'}
                                        maxWidth="600px"
                                        w={'100%'}
                                        h={'100%'}
                                        src={fontsizesliderbutton}
                                        boxShadow={{
                                            base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                            md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                        }}
                                    />
                                </Box>
                                <Box
                                    pt="20px"
                                    pb="10px"
                                    px={{ base: '5px', md: '20px' }}
                                    maxWidth="600px"
                                    width="100%"
                                    textAlign="center"
                                    color="gray.300"
                                    fontWeight="400"
                                >
                                    <Image
                                        alt=""
                                        fit={'cover'}
                                        align={'center'}
                                        maxWidth="600px"
                                        w={'100%'}
                                        h={'100%'}
                                        src={fontsizeslider}
                                        boxShadow={{
                                            base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                            md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                                        }}
                                    />
                                </Box>
                                <Box
                                    pt="25px"
                                    marginLeft={{ base: '0px', md: '5px' }}
                                    fontWeight="400"
                                    color="gray.500"
                                    opacity="0.8"
                                    fontStyle="italic"
                                >
                                    Note: the adjustable font size is only
                                    available in the Briefing (BRIEF mode). Due
                                    to the complexity of the PreFlight web-app,
                                    the adjustment of font size throughout other
                                    areas in the web-app is not available at
                                    this time.
                                </Box>
                            </li>
                        </ul>
                    </Box>
                </Flex>
                <Flex flexDirection="column" gap="10px">
                    <Text fontWeight="500" fontSize="0.9rem" color="light.100">
                        Summary of the user options
                    </Text>
                    <Text>
                        For added guidance, below is a summary of the options
                        available to users in the Briefing.
                    </Text>
                </Flex>
                <Box
                    marginLeft={{ base: '0px', md: '35px' }}
                    fontWeight="500"
                    color="gray.500"
                    opacity="0.8"
                >
                    OPTIONS MENU:
                </Box>
                <Flex
                    pl={{ base: '0px', md: '35px' }}
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    flexDirection="column"
                    gap="15px"
                >
                    <Divider />
                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                        gap="10px"
                    >
                        <ButtonExample
                            icon={FaCompressAlt}
                            buttonLabel={'Collapse items'}
                        />
                        <ButtonExample
                            icon={FaExpandAlt}
                            transform="rotate(-45deg)"
                            buttonLabel={'Expand items'}
                        />
                    </Flex>
                    <Divider />
                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                        gap="10px"
                    >
                        <ButtonExample
                            icon={AerodromeAltLayoutIcon}
                            buttonLabel={'Sort aerodrome information by type'}
                            newItem={true}
                        />
                        <ButtonExample
                            icon={AerodromeStdLayoutIcon}
                            buttonLabel={
                                'Sort aerodrome information by aerodromes'
                            }
                        />
                    </Flex>
                    <Divider />
                    <ButtonExample
                        icon={OrderDownIcon}
                        buttonLabel={
                            'Switch layout order between aerodromes and area met'
                        }
                        recentItem={true}
                    />
                    <Divider />
                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                        gap="10px"
                    >
                        <ButtonExample
                            icon={SortNorthToSouthIcon}
                            buttonLabel={'Sort aerodromes by north to south'}
                            recentItem={true}
                        />
                        <ButtonExample
                            icon={SortAlphabeticalIcon}
                            buttonLabel={'Sort aerodromes alphabetically'}
                        />
                    </Flex>
                    <Divider />
                    <ButtonExample
                        icon={BriefNoItemsIcon}
                        buttonLabel={
                            'List of excluded aerodromes from the briefing'
                        }
                    />
                    <Divider />
                    <ButtonExample
                        icon={FontAdjust}
                        buttonLabel={'Adjust font size'}
                        newItem={true}
                    />
                    <Divider />
                    <ButtonExample
                        icon={InfoIcon}
                        buttonLabel={'Information about the briefing requested'}
                    />
                    <Divider />
                    <ButtonExample
                        icon={PDFIcon}
                        buttonLabel={'Save briefing to PDF and print'}
                    />
                    <Divider />
                    <ButtonExample
                        icon={TfiMoreAlt}
                        buttonLabel={
                            'Open drawer for additional options (mobile only)'
                        }
                    />
                    <Divider />
                    <ButtonExample
                        icon={GoBackIcon}
                        buttonLabel={
                            'Go back to area selection / request a new briefing'
                        }
                    />
                    <Divider />
                </Flex>

                <Box
                    marginLeft={{ base: '0px', md: '35px' }}
                    mt="30px"
                    fontWeight="500"
                    color="gray.500"
                    opacity="0.8"
                >
                    INFORMATION TYPE FILTER:
                </Box>
                <Flex
                    pl={{ base: '5px', md: '35px' }}
                    pr={{ base: '5px', md: '20px' }}
                    maxWidth="600px"
                    width="100%"
                    textAlign="center"
                    color="gray.300"
                    fontWeight="400"
                    gap={{ base: '20px', md: '40px' }}
                >
                    <Flex
                        width="100%"
                        textAlign="center"
                        gap="20px"
                        flexDirection="column"
                    >
                        <Image
                            // border="1px solid"
                            // borderColor="#f2f4f7"
                            alt=""
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            src={filterclosed}
                            boxShadow={{
                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                            }}
                        />
                        <Box
                            width="100%"
                            textAlign="center"
                            color="gray.500"
                            opacity="0.8"
                            fontWeight="300"
                        >
                            Filter closed
                        </Box>
                    </Flex>
                    <Flex
                        width="100%"
                        textAlign="center"
                        gap="20px"
                        flexDirection="column"
                    >
                        <Image
                            // border="1px solid"
                            // borderColor="#f2f4f7"
                            alt=""
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            src={filteropen}
                            boxShadow={{
                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                            }}
                        />
                        <Box
                            width="100%"
                            textAlign="center"
                            color="gray.500"
                            opacity="0.8"
                            fontWeight="300"
                        >
                            Filter open
                        </Box>
                    </Flex>
                </Flex>

                <Box
                    marginLeft={{ base: '0px', md: '35px' }}
                    mt="30px"
                    fontWeight="500"
                    color="gray.500"
                    opacity="0.8"
                >
                    PERIOD AND ALTITUDE FILTERS:
                </Box>
                <Flex
                    pl={{ base: '5px', md: '35px' }}
                    pb="30px"
                    pr={{ base: '5px', md: '20px' }}
                    maxWidth="600px"
                    width="100%"
                    textAlign="center"
                    color="gray.300"
                    fontWeight="400"
                    gap={{ base: '20px', md: '40px' }}
                >
                    <Flex
                        width="100%"
                        textAlign="center"
                        gap="20px"
                        flexDirection="column"
                    >
                        <Image
                            // border="1px solid"
                            // borderColor="#f2f4f7"
                            alt=""
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            src={sliderfree}
                            boxShadow={{
                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                            }}
                        />
                        <Box
                            width="100%"
                            textAlign="center"
                            color="gray.500"
                            opacity="0.8"
                            fontWeight="300"
                        >
                            Free version <br />
                            (max altitude)
                        </Box>
                    </Flex>
                    <Flex
                        width="100%"
                        textAlign="center"
                        gap="20px"
                        flexDirection="column"
                    >
                        <Image
                            // border="1px solid"
                            // borderColor="#f2f4f7"
                            alt=""
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            src={sliderpremium}
                            boxShadow={{
                                base: '0px 5px 22px -10px rgba(0,0,0,0.4)',
                                md: '0px 5px 30px -15px rgba(0,0,0,0.4)',
                            }}
                        />
                        <Box
                            width="100%"
                            textAlign="center"
                            color="gray.500"
                            opacity="0.8"
                            fontWeight="300"
                        >
                            Premium version <br />
                            (max altitude)
                        </Box>
                    </Flex>
                </Flex>
            </>
        ),
    },
]
