import React from 'react'

import RadarIcon from './RadarIcon'
import NotamIcon from './NotamIcon'
import WindIcon from './WindIcon'
import MetIcon from './MetIcon'
import WeatherAdvisoryIcon from './WeatherAdvisoryIcon'
import AAWIcon from './AAWIcon'
import WindBarbsIcon from './WindBarbsIcon'
import WindParticlesIcon from './WindParticlesIcon'
import PrecipitationIcon from './PrecipitationIcon'
import CloudIcon from './CloudIcon'
import MslIcon from './MslIcon'
import CloudVisibleIcon from './CloudVisibleIcon'
import CloudTopsIcon from './CloudTopsIcon'
import CloudImageryIcon from './CloudImageryIcon'
import CloudInfraredIcon from './CloudInfraredIcon'
import CloudEnhancedInfraredIcon from './CloudEnhancedInfraredIcon'
import LowCloudForecastIcon from './LowCloudForecastIcon'
import RainRadarIcon from './RainRadarIcon'
import RainForecastIcon from './RainForecastIcon'
import SigwxIcon from './SigwxIcon'
import SigmetIcon from './SigmetIcon'
import SpaceWeatherIcon from './SpaceWeatherIcon'
import VolcanicAshIcon from './VolcanicAshIcon'
import GraforIcon from './GraforIcon'
import AeroIcon from './AeroIcon'
import HazardsIcon from './HazardsIcon'
import AirspaceIcon from './AirspaceIcon'
import VisualReportingPointIcon from './VisualReportingPointIcon'
import ObstaclesIcon from './ObstaclesIcon'
import WaypointIcaoIcon from './WaypointIcaoIcon'
import NavAidIcon from './NavAidIcon'
import RoutesIcon from './RoutesIcon'
import AdvsIcon from './AdvsIcon'
import DroneIcon from './DroneIcon'
import WebcamIcon from './WebcamIcon'
import AwsIcon from './AwsIcon'
import WipIcon from './WipIcon'
import ThunderstormForecastIcon from './ThunderstormOutlookIcon'
// import TemperatureIcon from './TemperatureIcon'
// import ThermalsIcon from './ThermalsIcon'

//TODO - @Jason - we need to add a default icon so that when an invalid id is used we return a default. Replace <HazardsIcon /> below with another icon.

// This component acts as a master for all menu icons.
const Switch = ({ id, children }) => {
    return (
        children.find((element) => {
            return element.props.id === id
        }) || <HazardsIcon />
    )
}

export default function MenuIcon({ id, ...p }) {
    return (
        <Switch id={id}>
            <RadarIcon id="radar" {...p} />
            <NotamIcon id="notam" {...p} />
            <WindIcon id="wind" {...p} />
            <MetIcon id="met" {...p} />
            <WeatherAdvisoryIcon id="advisories" {...p} />
            <SigmetIcon id="sigmet" {...p} />
            <SigmetIcon id="sigmetCharts" {...p} />
            <SigwxIcon id="sigwx" {...p} />
            <SigwxIcon id="sigwxCharts" {...p} />
            <SpaceWeatherIcon id="spacewx" {...p} />
            <VolcanicAshIcon id="vaa" {...p} />
            <ThunderstormForecastIcon id="thunderstormOutlook" {...p} />
            <CloudIcon id="cloud" {...p} />
            <CloudImageryIcon id="cloudImagery" {...p} />
            <LowCloudForecastIcon id="lowcloudforecast" {...p} />
            <CloudVisibleIcon id="satelliteCloudVisible" {...p} />
            <CloudInfraredIcon id="satelliteCloudInfrared" {...p} />
            <CloudEnhancedInfraredIcon
                id="satelliteCloudInfraredEnhanced"
                {...p}
            />
            <CloudTopsIcon id="satelliteCloudTops" {...p} />
            <PrecipitationIcon id="precipitation" {...p} />
            <RainRadarIcon id="rainRadarImagery" {...p} />
            <RainForecastIcon id="rainforecast" {...p} />
            <RainRadarIcon id="rainRadarWxTiles" {...p} />
            <RainRadarIcon id="rainradar" {...p} />
            <WindIcon id="wind" {...p} />
            <AAWIcon id="aaw" {...p} />
            <WindParticlesIcon id="windanimation" {...p} />
            <WindBarbsIcon id="windgrid" {...p} />
            <GraforIcon id="grafor" {...p} />
            <GraforIcon id="graforCharts" {...p} />
            <GraforIcon id="graphicalaviationforecast" {...p} />
            <MslIcon id="mslCharts" {...p} />
            <WebcamIcon id="webcams" {...p} />
            <AwsIcon id="aws" {...p} />
            <AeroIcon id="aero" {...p} />
            <AirspaceIcon id="airspace" {...p} />
            <HazardsIcon id="hazards" {...p} />
            <ObstaclesIcon id="obstacles" {...p} />
            <NavAidIcon id="navaids" {...p} />
            <VisualReportingPointIcon id="vrp" {...p} />
            <WaypointIcaoIcon id="icaoWaypoints" {...p} />
            <RoutesIcon id="routes" {...p} />
            <AdvsIcon id="advs" {...p} />
            <NotamIcon id="notam" {...p} />
            <DroneIcon id="rpas" {...p} />
            <WipIcon id="wip" {...p} />
            {/* <TemperatureIcon id="temperature" {...p} /> */}
            {/* <ThermalsIcon id="thermals" {...p} /> */}
        </Switch>
    )
}
