import {
    Stack,
    Flex,
    Text,
    Container,
    Box,
    Heading,
    Image,
    useColorModeValue,
} from '@chakra-ui/react'
import { isMobile, isDesktop } from 'react-device-detect'
import advsLight from '../assets/advs-light.png'
import advsDark from '../assets/advs-dark.png'

export default function AdvsMode(props) {
    const { Blob } = props

    return (
        <Container
            maxW="100%"
            bg="light.10"
            zIndex="3"
            position="relative"
            mt="-1px"
            className="landingpage-content"
        >
            <Container maxW={'7xl'}>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    py={{ base: 12, md: 16, lg: 24 }}
                    direction={{ base: 'column-reverse', md: 'row' }}
                >
                    <Stack
                        flex={1}
                        spacing={{ base: 5, md: 6 }}
                        textAlign={{ base: 'center', md: 'start' }}
                        justifyContent="flex-start"
                        alignItems={{ base: 'center', md: 'flex-start' }}
                    >
                        <Heading
                            pt={{ base: 4, sm: 6, md: 0 }}
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{
                                base: '4xl',
                                sm: '5xl',
                                lg: '6xl',
                            }}
                            width="max-content"
                            paddingRight={{ base: '0', md: '20px' }}
                        >
                            <Text
                                className="modeLabel"
                                style={{ position: 'relative', zIndex: '1' }}
                            >
                                Advisories
                            </Text>
                            <Flex
                                display={{ base: 'none', md: 'block' }}
                                justifyContent="flex-start"
                                alignItems="center"
                                width="100%"
                            >
                                <Box
                                    width="calc(100% + 20px)"
                                    height="25px"
                                    mt={{
                                        base: '-20px',
                                        lg: '-23px',
                                    }}
                                    ml="-10px"
                                    bg={useColorModeValue(
                                        'linear-gradient(90deg, #E8F9F9 10%, #F8FFFF 80%)',
                                        'linear-gradient(90deg, rgb(235, 245, 255) 10%, #F8FEFF 80%)'
                                    )}
                                    zIndex="-1"
                                    position="relative"
                                ></Box>
                            </Flex>
                        </Heading>
                        <Text
                            fontSize="1.2rem"
                            textShadow="1px 5px 40px rgba(0, 0, 0, 0.2)"
                            as={'span'}
                            color={'gray.500'}
                            lineHeight={1.4}
                            fontWeight="600"
                            pb="5px"
                        >
                            The best pre-flight situational awareness tool for
                            NZ airspace.
                        </Text>
                        <Text color={'gray.500'} mt="10px !important">
                            Aeronautical and meteorological advisory information
                            as graphical layers on the map. View NOTAMs, SIGMET
                            and SIGWX alongside other dynamic information.
                        </Text>
                    </Stack>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}
                    >
                        <Blob
                            w={'150%'}
                            h={'150%'}
                            position={'absolute'}
                            top={'-20%'}
                            left={0}
                            zIndex={-1}
                            color={useColorModeValue('#34D7B0', '#337BA6')}
                            opacity="0.04"
                        />
                        <Box
                            position={'relative'}
                            height={'auto'}
                            rounded={{ base: 'md', md: 'xl' }}
                            boxShadow={
                                '0px 20px 50px -12px rgba(0, 15, 35, 0.5)'
                            }
                            width={'full'}
                            overflow={'hidden'}
                            transform={
                                isMobile || !isDesktop
                                    ? 'none'
                                    : 'perspective(2000px) rotateY(-25deg) rotateX(5deg)'
                            }
                            _hover={{
                                transform:
                                    isMobile || !isDesktop
                                        ? 'none'
                                        : 'translateY(-10px) perspective(2000px) rotateY(-20deg) rotateX(4deg) scale(1.02)',
                                boxShadow: {
                                    base: '0px 35px 50px -25px rgba(0, 15, 35, 0.65)',
                                    md: '0px 35px 50px -25px rgba(0, 15, 35, 0.45)',
                                },
                            }}
                            transition="transform 0.25s ease-out 0s, box-shadow 0.25s ease-out 0s"
                        >
                            <Image
                                alt={'Advisories'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={useColorModeValue(advsLight, advsDark)}
                            />
                        </Box>
                    </Flex>
                </Stack>
            </Container>
        </Container>
    )
}
