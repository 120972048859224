import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    Button,
    ButtonGroup,
    Text,
    Flex,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Divider,
    TableContainer,
    Icon,
} from '@chakra-ui/react'
import {
    parseISO,
    formatDistanceToNow,
    formatDistanceToNowStrict,
} from 'date-fns'
import formatDateTime, {
    formatTimeOnly,
    formatDayAndDate,
    timeZoneAbbreviation,
} from '../../../../util/dateFormatter'
import ErrorText from '../ErrorText'
import ImprRawToggle from '../ImprRawToggle'
import FixedPosToggle from '../FixedPosToggle'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import {
    wxTranslate,
    // cloudCoverOktasDecode,
} from '../../../../util/metDecode'

import {
    timeZoneState,
    decodedTextDefaultState,
    metarFixFirstColumnState,
} from '../../../../globalState'

export default function MetarContent(props) {
    const decodeDefault = useRecoilValue(decodedTextDefaultState)

    const {
        data,
        isPremium,
        currentMetar,
        metarOutOfDateIssueTime,
        showErrors,
        mfaExpired,
        fullscreen,
    } = props

    const [index, setIndex] = useState(0)
    const [raw, setRaw] = useState(isPremium || !decodeDefault ? true : false)
    const timeZone = useRecoilValue(timeZoneState)
    const selectedMetar = data.metar[index]
    const [fixFirstColumn, setFixFirstColumn] = useRecoilState(
        metarFixFirstColumnState
    )

    const handleRewind = () => {
        setIndex((prevState) => prevState + 1)
    }
    const handleForward = () => {
        setIndex((prevState) => prevState - 1)
    }

    const metarForecast = selectedMetar?.data?.forecast?.length > 0 // METAR forecast weather is present

    const nullMetarData = selectedMetar?.data?.observation === null // METAR DATA is present but data.observation is null

    const nilMetar = selectedMetar?.data?.observation === 'NIL' // METAR DATA is present but observation is "NIL" (as reported by forecaster)

    const hasCloud =
        selectedMetar?.data?.observation?.[0]?.cloud &&
        !nullMetarData &&
        !nilMetar

    // function to reverse order stacked cloud layers so the heighest is at top
    function reverseArray(clouds) {
        const reversed = []
        for (let i = clouds && clouds.length - 1; i >= 0; i--) {
            reversed.push(clouds[i])
        }
        return reversed
    }

    // baseForecast Cloud
    const cloudlayers = hasCloud
        ? selectedMetar?.data?.observation[0]?.cloud?.layer || []
        : []
    const reversedCloudLayers = reverseArray(cloudlayers)

    const noCloudDetected =
        selectedMetar?.data?.observation?.[0]?.cloud?.noCloudDetected &&
        !nullMetarData &&
        !nilMetar

    const cloudAndVisibilityOK =
        selectedMetar?.data?.observation?.[0]?.cloudAndVisibilityOK &&
        !nullMetarData &&
        !nilMetar

    const surfaceWindData = (key) =>
        selectedMetar?.data?.observation?.[0]?.surfaceWind?.[key] || null

    const winddir = surfaceWindData('meanWindDirection')
    const windvar = surfaceWindData('variableWindDirection')
    const windspeed = surfaceWindData('meanWindSpeed')
    const windgust = surfaceWindData('windGustSpeed')
    const surfaceWind = winddir || windspeed || windgust // Check to see if any surface wind values (METAR default is to have surfacewind even if no values)

    const instanceRef = useRef()
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)
    const checkScrollbarVisibility = () => {
        if (instanceRef.current) {
            setIsScrollbarVisible(
                instanceRef.current.scrollWidth >
                    instanceRef.current.clientWidth
            )
        }
    }
    useEffect(() => {
        checkScrollbarVisibility()
        // listener to check table scrollbar visibility
        const resizeListener = () => {
            checkScrollbarVisibility()
        }
        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    useEffect(() => {
        const delay = setTimeout(() => {
            checkScrollbarVisibility()
        }, 310)

        return () => {
            clearTimeout(delay)
        }
    }, [fullscreen])

    useEffect(() => {
        // reset fixed first column if there is no overflow
        if (!isScrollbarVisible) {
            setFixFirstColumn(false)
        }
    }, [isScrollbarVisible, setFixFirstColumn, fullscreen])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    useCallback(() => {
        if (!isPremium) {
            setRaw(!selectedMetar)
        }
        if (!decodeDefault) {
            setRaw(!decodeDefault)
        }
    }, [decodeDefault, isPremium, selectedMetar])

    // useEffect(() => {
    //     console.log(data, 'data')
    //     console.log(selectedMetar, 'selectedMetar')
    // }, [raw, data, selectedMetar, nilMetar])

    return (
        <>
            <Box w="100%">
                <Flex
                    // {...tabSwipeHeader}
                    marginBottom={{ base: '20px', sm: '15px' }}
                    justifyContent="space-between"
                    gap="10px"
                >
                    <Flex>
                        <Text
                            marginBottom={2}
                            fontSize={{
                                base: '0.85rem',
                                xs: '0.9rem',
                                sm: '1rem',
                            }}
                            fontWeight="bold"
                            lineHeight="1.3"
                            minHeight={{ base: '40px', smd: '20px' }}
                        >
                            {data.metar[index].type === 'MANUAL'
                                ? 'METEOROLOGICAL AERODROME REPORT'
                                : data.metar[index].type === 'AUTO'
                                ? 'AUTOMATED METEOROLOGICAL AERODROME REPORT'
                                : data.metar[index].type === 'SPECI'
                                ? 'SPECIAL AERODROME REPORT'
                                : data.metar[index].type}
                        </Text>
                    </Flex>

                    <ImprRawToggle // FMTD/RAW Toggle
                        isPremium={isPremium}
                        selectedData={selectedMetar.data}
                        raw={raw}
                        setRaw={setRaw}
                        mfaExpired={mfaExpired}
                    />
                </Flex>

                <Box w="100%">
                    {/* ERROR MESSAGES */}
                    {!showErrors &&
                        !selectedMetar.outofdate &&
                        (metarOutOfDateIssueTime === 'CAUTION' ||
                            metarOutOfDateIssueTime === 'WARNING') && (
                            <Flex
                                mb={{
                                    base: '15px',
                                    xl: '10px',
                                }}
                                mt={{
                                    base: '5px',
                                    xl: '-20px',
                                }}
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Text
                                    color={
                                        metarOutOfDateIssueTime === 'CAUTION'
                                            ? '#ff7f00'
                                            : 'red'
                                    }
                                    fontSize="12px"
                                >
                                    This METAR was last issued{' '}
                                    <strong>
                                        {formatDistanceToNowStrict(
                                            parseISO(currentMetar.issuetime),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </strong>
                                    .
                                </Text>
                                {metarOutOfDateIssueTime === 'WARNING' && (
                                    <Text color={'red'} fontSize="12px">
                                        There is a possibility there has been an
                                        issue receiving the latest data and that
                                        the latest METAR is not available. Check
                                        back again soon, or alternatively visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </Text>
                                )}
                            </Flex>
                        )}
                    {(showErrors || selectedMetar.outofdate) && (
                        <Flex mb="15px" justifyContent="center">
                            <ErrorText>
                                There has been an issue with the connection
                                between PreFlight and MetService. This METAR was
                                last validated with MetService{' '}
                                <strong>
                                    {formatDistanceToNow(
                                        parseISO(selectedMetar.updated),
                                        {
                                            addSuffix: true,
                                        }
                                    )}
                                </strong>
                                .<br />
                                There is a high likelihood that the latest METAR
                                is not available in PreFlight. Check back again
                                soon, or alternatively visit{' '}
                                <a
                                    style={{
                                        fontWeight: '500',
                                        textDecoration: 'underline',
                                    }}
                                    href="https://ifis.airways.co.nz/"
                                >
                                    IFIS
                                </a>
                                .
                            </ErrorText>
                        </Flex>
                    )}

                    <Flex
                        flexWrap="wrap"
                        flexDirection="row"
                        alignItems={{
                            base: 'flex-start',
                            sm: 'flex-end',
                        }}
                        justifyContent={raw ? 'flex-end' : 'space-between'}
                        width="100%"
                        minHeight="45px"
                        gap="5px"
                    >
                        {!raw && selectedMetar && (
                            <Box>
                                <Table
                                    // {...tabSwipeHeader}
                                    size="sm"
                                    fontFamily="Open Sans"
                                    marginBottom="2"
                                >
                                    <Tbody verticalAlign="baseline">
                                        {selectedMetar.data &&
                                            selectedMetar?.data?.reportStatus &&
                                            selectedMetar.data.reportStatus !==
                                                'NORMAL' && (
                                                <Tr>
                                                    <Td
                                                        paddingStart="0"
                                                        textTransform="capitalize"
                                                        fontWeight="bold"
                                                        width="auto"
                                                        minWidth="120px"
                                                        fontSize="0.9rem"
                                                    >
                                                        {
                                                            selectedMetar.data
                                                                .reportStatus
                                                        }
                                                    </Td>
                                                </Tr>
                                            )}

                                        <Tr>
                                            <Td
                                                border="none"
                                                paddingStart="0"
                                                paddingEnd="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="55px"
                                                whiteSpace="pre"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                            >
                                                Issued:
                                            </Td>

                                            <Td
                                                width="100%"
                                                fontSize="0.9rem"
                                                paddingEnd="0"
                                            >
                                                <Flex
                                                    gap={{
                                                        base: '2px',
                                                        sm: '10px',
                                                    }}
                                                    alignItems="baseline"
                                                    flexDirection={{
                                                        base: 'column',
                                                        sm: 'row',
                                                    }}
                                                >
                                                    <Text>
                                                        {selectedMetar?.issuetime &&
                                                            formatDateTime(
                                                                selectedMetar.issuetime,
                                                                timeZone
                                                            )}
                                                    </Text>
                                                    <Text
                                                        color="gray.400"
                                                        fontSize="0.75rem"
                                                        fontWeight="400"
                                                    >
                                                        {selectedMetar?.issuetime &&
                                                            formatDistanceToNow(
                                                                parseISO(
                                                                    selectedMetar.issuetime
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Box>
                        )}
                        <Flex
                            pt={{
                                base: '10px',
                                smd: '8px',
                            }}
                            pb="10px"
                            gap="10px"
                            flexDirection="row"
                            alignItems="center"
                        >
                            {raw && (
                                <Text
                                    textTransform="capitalize"
                                    lineHeight="2.3"
                                    fontSize="0.75rem"
                                    color="gray.400"
                                >
                                    {formatDistanceToNow(
                                        parseISO(data.metar[index].issuetime),
                                        {
                                            addSuffix: true,
                                        }
                                    )}
                                </Text>
                            )}
                            <ButtonGroup
                                size="sm"
                                isAttached={true}
                                variant="outline"
                            >
                                <Button
                                    borderTopLeftRadius="20px"
                                    borderBottomLeftRadius="20px"
                                    onClick={handleRewind}
                                    variant="outline"
                                    size="sm"
                                    bg="light.700"
                                    width="47px"
                                    marginRight="-1px"
                                    height={{ base: '30px', md: '26px' }}
                                    isDisabled={data.metar.length === index + 1}
                                    {...noFocus}
                                >
                                    <Icon
                                        as={FaChevronLeft}
                                        fontSize="0.7rem"
                                    />
                                </Button>
                                <Button
                                    borderTopRightRadius="20px"
                                    borderBottomRightRadius="20px"
                                    variant="outline"
                                    size="sm"
                                    bg="light.700"
                                    width="47px"
                                    height={{ base: '30px', md: '26px' }}
                                    onClick={handleForward}
                                    isDisabled={index === 0}
                                    {...noFocus}
                                >
                                    <Icon
                                        as={FaChevronRight}
                                        fontSize="0.7rem"
                                    />
                                </Button>
                            </ButtonGroup>
                        </Flex>
                    </Flex>

                    {!raw &&
                        !nullMetarData &&
                        !nilMetar &&
                        selectedMetar?.data &&
                        selectedMetar?.data?.nonereceived && (
                            <Box w="100%" pl={0} pr={0}>
                                <Text
                                    size="sm"
                                    fontFamily="Open Sans"
                                    marginTop="1"
                                    marginBottom="12px"
                                    paddingTop="1"
                                    paddingBottom="2"
                                    paddingStart="0"
                                    fontWeight="bold"
                                    width="auto"
                                    minWidth="120px"
                                    fontSize="0.9rem"
                                >
                                    None Received
                                </Text>
                            </Box>
                        )}
                    {!raw &&
                        !nullMetarData &&
                        !nilMetar &&
                        selectedMetar &&
                        selectedMetar?.data &&
                        ((selectedMetar?.data?.observation &&
                            selectedMetar?.data?.observation[0]) ||
                            selectedMetar?.data?.forecast) && (
                            <Box w="100%" pl={0} pr={0}>
                                <Divider
                                    zIndex="2"
                                    borderColor="light.300"
                                    borderBottomWidth="1px"
                                    marginTop="5px"
                                    marginBottom="0px"
                                />
                                <TableContainer
                                    className="scrollHorizontal"
                                    ref={instanceRef}
                                >
                                    <Table
                                        id="metarContentScreen"
                                        size="sm"
                                        variant="simple"
                                        colorScheme="gray"
                                        fontFamily="Open Sans"
                                        marginTop="0"
                                        marginBottom="4"
                                    >
                                        <Tbody
                                            verticalAlign="top"
                                            whiteSpace="pre"
                                            borderBottom="1px solid #EAEDF0 !important"
                                            clipPath="inset(0px 0px 0px 0px)"
                                        >
                                            {selectedMetar?.data &&
                                                !selectedMetar.data
                                                    .nonereceived && (
                                                    <>
                                                        {(metarForecast ||
                                                            isScrollbarVisible) && (
                                                            <Tr>
                                                                <Td
                                                                    zIndex="2"
                                                                    border="none"
                                                                    paddingTop={
                                                                        3
                                                                    }
                                                                    paddingStart="3"
                                                                    paddingEnd="3"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    pb="0px"
                                                                    minWidth="127px"
                                                                    height={
                                                                        metarForecast &&
                                                                        isScrollbarVisible
                                                                            ? '72px'
                                                                            : metarForecast
                                                                            ? '72px'
                                                                            : isScrollbarVisible
                                                                            ? '57px'
                                                                            : '0px'
                                                                    }
                                                                    minHeight={
                                                                        metarForecast &&
                                                                        isScrollbarVisible
                                                                            ? '72px'
                                                                            : metarForecast
                                                                            ? '72px'
                                                                            : isScrollbarVisible
                                                                            ? '57px'
                                                                            : '0px'
                                                                    }
                                                                    fontSize="0.9rem"
                                                                    borderColor="transparent"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                >
                                                                    {!isPremium &&
                                                                        !raw &&
                                                                        isScrollbarVisible && (
                                                                            <Flex
                                                                                width="100%"
                                                                                justifyContent="flex-start"
                                                                                mb="-12px"
                                                                            >
                                                                                <FixedPosToggle // Fix First Column Toggle
                                                                                    fixFirstColumn={
                                                                                        fixFirstColumn
                                                                                    }
                                                                                    setFixFirstColumn={
                                                                                        setFixFirstColumn
                                                                                    }
                                                                                />
                                                                            </Flex>
                                                                        )}
                                                                </Td>
                                                                <Td
                                                                    border="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    pb="0px"
                                                                    borderColor="transparent"
                                                                    position="relative"
                                                                    height={
                                                                        metarForecast &&
                                                                        isScrollbarVisible
                                                                            ? '72px'
                                                                            : metarForecast
                                                                            ? '72px'
                                                                            : isScrollbarVisible
                                                                            ? '57px'
                                                                            : '0px'
                                                                    }
                                                                    minHeight={
                                                                        metarForecast &&
                                                                        isScrollbarVisible
                                                                            ? '72px'
                                                                            : metarForecast
                                                                            ? '72px'
                                                                            : isScrollbarVisible
                                                                            ? '57px'
                                                                            : '0px'
                                                                    }
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                ></Td>
                                                                {metarForecast &&
                                                                    selectedMetar?.data?.forecast.map(
                                                                        (
                                                                            forecast,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <Td
                                                                                    border="none"
                                                                                    key={`forecastIndicator${i}`}
                                                                                    width="auto"
                                                                                    borderColor="transparent"
                                                                                    pb="0px"
                                                                                >
                                                                                    <Flex
                                                                                        flexDirection="column"
                                                                                        pb="10px"
                                                                                    >
                                                                                        <Text
                                                                                            fontSize="0.85rem"
                                                                                            fontWeight="700"
                                                                                            textAlign="left"
                                                                                            width="100%"
                                                                                        >
                                                                                            {forecast?.changeIndicator ===
                                                                                            'TEMPORARY_FLUCTUATIONS'
                                                                                                ? forecast.changeIndicator.replace(
                                                                                                      'TEMPORARY_FLUCTUATIONS',
                                                                                                      'TEMPO'
                                                                                                  )
                                                                                                : forecast.changeIndicator}
                                                                                        </Text>
                                                                                        <Text
                                                                                            pt="5px"
                                                                                            textAlign="left"
                                                                                            width="100%"
                                                                                            fontSize="0.8rem"
                                                                                            fontWeight="400"
                                                                                        >
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.timeIndicator &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validFrom
                                                                                                ? forecast
                                                                                                      .phenomenonTime
                                                                                                      .timeIndicator
                                                                                                : ''}{' '}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validFrom
                                                                                                ? formatTimeOnly(
                                                                                                      forecast
                                                                                                          .phenomenonTime
                                                                                                          .validFrom,
                                                                                                      timeZone
                                                                                                  )
                                                                                                : ''}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.timeIndicator &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? forecast
                                                                                                      .phenomenonTime
                                                                                                      .timeIndicator
                                                                                                : ''}{' '}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? formatTimeOnly(
                                                                                                      forecast
                                                                                                          .phenomenonTime
                                                                                                          .validTo,
                                                                                                      timeZone
                                                                                                  )
                                                                                                : ''}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validFrom &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? ' – '
                                                                                                : ''}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? formatTimeOnly(
                                                                                                      forecast
                                                                                                          .phenomenonTime
                                                                                                          .validTo,
                                                                                                      timeZone
                                                                                                  )
                                                                                                : ''}
                                                                                        </Text>
                                                                                        <Text
                                                                                            textAlign="left"
                                                                                            width="100%"
                                                                                            fontSize="0.75rem"
                                                                                            color="gray.400"
                                                                                            fontWeight="300"
                                                                                        >
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.timeIndicator &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validFrom
                                                                                                ? forecast
                                                                                                      .phenomenonTime
                                                                                                      .timeIndicator
                                                                                                : ''}{' '}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validFrom
                                                                                                ? formatDayAndDate(
                                                                                                      forecast
                                                                                                          .phenomenonTime
                                                                                                          .validFrom,
                                                                                                      timeZone
                                                                                                  )
                                                                                                : ''}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.timeIndicator &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? forecast
                                                                                                      .phenomenonTime
                                                                                                      .timeIndicator
                                                                                                : ''}{' '}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? formatDayAndDate(
                                                                                                      forecast
                                                                                                          .phenomenonTime
                                                                                                          .validTo,
                                                                                                      timeZone
                                                                                                  )
                                                                                                : ''}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validFrom &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? ' – '
                                                                                                : ''}
                                                                                            {forecast?.phenomenonTime &&
                                                                                            forecast
                                                                                                ?.phenomenonTime
                                                                                                ?.validTo
                                                                                                ? formatDayAndDate(
                                                                                                      forecast
                                                                                                          .phenomenonTime
                                                                                                          .validTo,
                                                                                                      timeZone
                                                                                                  )
                                                                                                : ''}
                                                                                        </Text>
                                                                                    </Flex>
                                                                                </Td>
                                                                            )
                                                                        }
                                                                    )}
                                                            </Tr>
                                                        )}

                                                        <Tr>
                                                            <Td
                                                                zIndex="1"
                                                                borderTop={
                                                                    (metarForecast ||
                                                                        isScrollbarVisible) &&
                                                                    '1px solid #E8EBEE !important'
                                                                }
                                                                borderBottom="none"
                                                                paddingStart="3"
                                                                paddingEnd="1"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                minWidth="127px"
                                                                minHeight="120px"
                                                                fontSize="0.9rem"
                                                                textAlign="left"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                                pointerEvents={
                                                                    fixFirstColumn
                                                                        ? 'none'
                                                                        : 'initial'
                                                                }
                                                            >
                                                                Surface Wind:
                                                            </Td>
                                                            <Td
                                                                borderTop={
                                                                    (metarForecast ||
                                                                        isScrollbarVisible) &&
                                                                    '1px solid #EAEDF0'
                                                                }
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                <Flex flexDirection="row">
                                                                    {surfaceWind ? (
                                                                        <>
                                                                            <Text>
                                                                                {windvar
                                                                                    ? 'VRB '
                                                                                    : winddir &&
                                                                                      winddir.value
                                                                                    ? winddir.value
                                                                                          .toString()
                                                                                          .padStart(
                                                                                              3,
                                                                                              '0'
                                                                                          ) +
                                                                                      '°T '
                                                                                    : ''}
                                                                                {windspeed &&
                                                                                    windspeed.value +
                                                                                        ' kt'}
                                                                                {windgust
                                                                                    ? ', Gusts ' +
                                                                                      windgust.value +
                                                                                      ' kt'
                                                                                    : ''}
                                                                            </Text>
                                                                            <Text paddingLeft="15px">
                                                                                {selectedMetar
                                                                                    ?.data
                                                                                    ?.observation[0]
                                                                                    ?.surfaceWind
                                                                                    ?.extremeClockwiseWindDirection
                                                                                    ? selectedMetar.data.observation[0].surfaceWind.extremeCounterClockwiseWindDirection.value
                                                                                          .toString()
                                                                                          .padStart(
                                                                                              3,
                                                                                              '0'
                                                                                          ) +
                                                                                      'V' +
                                                                                      selectedMetar.data.observation[0].surfaceWind.extremeClockwiseWindDirection.value
                                                                                          .toString()
                                                                                          .padStart(
                                                                                              3,
                                                                                              '0'
                                                                                          ) +
                                                                                      '°T'
                                                                                    : ''}
                                                                            </Text>
                                                                        </>
                                                                    ) : (
                                                                        <Text>
                                                                            No
                                                                            wind
                                                                            reported
                                                                        </Text>
                                                                    )}
                                                                </Flex>
                                                            </Td>
                                                            {selectedMetar?.data
                                                                ?.forecast &&
                                                                selectedMetar
                                                                    .data
                                                                    .forecast
                                                                    .length >
                                                                    0 &&
                                                                selectedMetar.data.forecast.map(
                                                                    (
                                                                        forecast,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                borderTop={
                                                                                    metarForecast &&
                                                                                    '1px solid #EAEDF0'
                                                                                }
                                                                                borderBottom="none"
                                                                                key={`forecastWind${i}`}
                                                                                width="auto"
                                                                                fontSize="0.9rem"
                                                                            >
                                                                                {forecast.surfaceWind && (
                                                                                    <Flex flexDirection="row">
                                                                                        <Text>
                                                                                            {forecast
                                                                                                ?.surfaceWind
                                                                                                ?.variableWindDirection
                                                                                                ? 'VRB '
                                                                                                : forecast?.surfaceWind?.meanWindDirection.value
                                                                                                      .toString()
                                                                                                      .padStart(
                                                                                                          3,
                                                                                                          '0'
                                                                                                      ) +
                                                                                                  '°T'}{' '}
                                                                                            {
                                                                                                forecast
                                                                                                    ?.surfaceWind
                                                                                                    ?.meanWindSpeed
                                                                                                    ?.value
                                                                                            }{' '}
                                                                                            kt
                                                                                            {forecast
                                                                                                ?.surfaceWind
                                                                                                ?.windGustSpeed
                                                                                                ? ', Gusts ' +
                                                                                                  forecast
                                                                                                      ?.surfaceWind
                                                                                                      ?.windGustSpeed
                                                                                                      ?.value +
                                                                                                  ' kt'
                                                                                                : ''}
                                                                                        </Text>
                                                                                    </Flex>
                                                                                )}
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>

                                                        {(selectedMetar?.data
                                                            ?.observation[0]
                                                            ?.horizontalVisibility
                                                            ?.value ||
                                                            (selectedMetar?.data
                                                                ?.forecast &&
                                                                selectedMetar
                                                                    .data
                                                                    .forecast
                                                                    .length >
                                                                    0 &&
                                                                selectedMetar.data.forecast
                                                                    .map(
                                                                        (
                                                                            forecast,
                                                                            i
                                                                        ) =>
                                                                            forecast.visibility
                                                                    )
                                                                    .filter(
                                                                        (val) =>
                                                                            val
                                                                    ).length >
                                                                    0)) && (
                                                            <Tr
                                                                key={`observedVisibility${index}`}
                                                            >
                                                                <Td
                                                                    zIndex="1"
                                                                    borderTop="1px solid #E8EBEE !important"
                                                                    borderBottom="none"
                                                                    paddingStart="3"
                                                                    paddingEnd="1"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    minWidth="127px"
                                                                    minHeight="120px"
                                                                    fontSize="0.9rem"
                                                                    textAlign="left"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    pointerEvents={
                                                                        fixFirstColumn
                                                                            ? 'none'
                                                                            : 'initial'
                                                                    }
                                                                >
                                                                    Visibility:
                                                                </Td>
                                                                <Td
                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                    borderBottom="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    textTransform="lowercase"
                                                                    position="relative"
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                >
                                                                    {selectedMetar
                                                                        ?.data
                                                                        ?.observation[0]
                                                                        ?.horizontalVisibility
                                                                        ?.value ===
                                                                    '////' ? (
                                                                        <Text textTransform="initial">
                                                                            Not
                                                                            reported
                                                                        </Text>
                                                                    ) : selectedMetar
                                                                          ?.data
                                                                          ?.observation[0]
                                                                          ?.horizontalVisibility
                                                                          ?.value ===
                                                                      '9999' ? (
                                                                        '> ' +
                                                                        parseInt(
                                                                            selectedMetar
                                                                                ?.data
                                                                                ?.observation[0]
                                                                                ?.horizontalVisibility
                                                                                ?.value
                                                                        ) +
                                                                        ' ' +
                                                                        selectedMetar
                                                                            ?.data
                                                                            ?.observation[0]
                                                                            ?.horizontalVisibility
                                                                            ?.unit
                                                                    ) : (
                                                                        parseInt(
                                                                            selectedMetar
                                                                                ?.data
                                                                                ?.observation[0]
                                                                                ?.horizontalVisibility
                                                                                ?.value
                                                                        ) +
                                                                        ' ' +
                                                                        selectedMetar
                                                                            ?.data
                                                                            ?.observation[0]
                                                                            ?.horizontalVisibility
                                                                            ?.unit
                                                                    )}
                                                                </Td>
                                                                {selectedMetar
                                                                    ?.data
                                                                    ?.forecast &&
                                                                    selectedMetar
                                                                        .data
                                                                        .forecast
                                                                        .length >
                                                                        0 &&
                                                                    selectedMetar.data.forecast.map(
                                                                        (
                                                                            forecast,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <Td
                                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                                    borderBottom="none"
                                                                                    key={`forecastVisibility${i}`}
                                                                                    width="auto"
                                                                                    fontSize="0.9rem"
                                                                                    textTransform="lowercase"
                                                                                >
                                                                                    {forecast.visibility &&
                                                                                        forecast
                                                                                            .visibility
                                                                                            .value &&
                                                                                        forecast
                                                                                            .visibility
                                                                                            .unit && (
                                                                                            <Flex flexDirection="row">
                                                                                                <Text>
                                                                                                    {forecast
                                                                                                        .visibility
                                                                                                        .value ===
                                                                                                    9999
                                                                                                        ? '> ' +
                                                                                                          forecast
                                                                                                              ?.visibility
                                                                                                              ?.value +
                                                                                                          ' ' +
                                                                                                          forecast
                                                                                                              ?.visibility
                                                                                                              ?.unit
                                                                                                        : forecast
                                                                                                              ?.visibility
                                                                                                              ?.value +
                                                                                                          ' ' +
                                                                                                          forecast
                                                                                                              ?.visibility
                                                                                                              ?.unit}
                                                                                                </Text>
                                                                                            </Flex>
                                                                                        )}
                                                                                </Td>
                                                                            )
                                                                        }
                                                                    )}
                                                            </Tr>
                                                        )}
                                                        {selectedMetar?.data
                                                            ?.observation[0]
                                                            ?.aerodromeRunwayVisualRange && (
                                                            <Tr>
                                                                <Td
                                                                    zIndex="1"
                                                                    borderTop="1px solid #E8EBEE !important"
                                                                    borderBottom="none"
                                                                    paddingStart="3"
                                                                    paddingEnd="1"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    minWidth="127px"
                                                                    minHeight="120px"
                                                                    fontSize="0.9rem"
                                                                    textAlign="left"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    pointerEvents={
                                                                        fixFirstColumn
                                                                            ? 'none'
                                                                            : 'initial'
                                                                    }
                                                                >
                                                                    Runway
                                                                    Visual
                                                                    Range:
                                                                </Td>
                                                                <Td
                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                    borderBottom="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    position="relative"
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                >
                                                                    <Flex
                                                                        flexDirection="column"
                                                                        justifyContent="space-between"
                                                                        gap="6px"
                                                                    >
                                                                        {selectedMetar.data.observation[0].aerodromeRunwayVisualRange.map(
                                                                            (
                                                                                rwy,
                                                                                i
                                                                            ) => (
                                                                                <Text
                                                                                    key={`runwayVisualRange${i}`}
                                                                                >
                                                                                    <b>
                                                                                        RW
                                                                                        {
                                                                                            rwy.designator
                                                                                        }

                                                                                        :
                                                                                    </b>{' '}
                                                                                    {rwy
                                                                                        .runwayVisualRange
                                                                                        .operator ===
                                                                                    'ABOVE'
                                                                                        ? '> '
                                                                                        : rwy
                                                                                              .runwayVisualRange
                                                                                              .operator ===
                                                                                          'BELOW'
                                                                                        ? '< '
                                                                                        : ''}
                                                                                    {
                                                                                        rwy
                                                                                            .runwayVisualRange
                                                                                            .value
                                                                                    }{' '}
                                                                                    {
                                                                                        rwy
                                                                                            .runwayVisualRange
                                                                                            .unit
                                                                                    }
                                                                                </Text>
                                                                            )
                                                                        )}
                                                                    </Flex>
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                        {(selectedMetar?.data
                                                            ?.observation[0]
                                                            ?.presentWeather ||
                                                            (selectedMetar?.data
                                                                ?.forecast &&
                                                                selectedMetar
                                                                    .data
                                                                    .forecast
                                                                    .length >
                                                                    0 &&
                                                                selectedMetar.data.forecast
                                                                    .map(
                                                                        (
                                                                            forecast,
                                                                            i
                                                                        ) =>
                                                                            forecast.weather
                                                                                ? true
                                                                                : false
                                                                    )
                                                                    .filter(
                                                                        (wx) =>
                                                                            wx
                                                                    ).length >
                                                                    0)) && (
                                                            <Tr
                                                                key={`weather${index}`}
                                                            >
                                                                <Td
                                                                    zIndex="1"
                                                                    borderTop="1px solid #E8EBEE !important"
                                                                    borderBottom="none"
                                                                    paddingStart="3"
                                                                    paddingEnd="1"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    minWidth="127px"
                                                                    minHeight="120px"
                                                                    fontSize="0.9rem"
                                                                    textAlign="left"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    pointerEvents={
                                                                        fixFirstColumn
                                                                            ? 'none'
                                                                            : 'initial'
                                                                    }
                                                                >
                                                                    Weather:
                                                                </Td>
                                                                <Td
                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                    borderBottom="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    position="relative"
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                >
                                                                    {selectedMetar
                                                                        ?.data
                                                                        ?.observation[0]
                                                                        ?.presentWeather &&
                                                                        selectedMetar.data.observation[0].presentWeather
                                                                            .map(
                                                                                (
                                                                                    wx
                                                                                ) =>
                                                                                    wxTranslate(
                                                                                        wx
                                                                                    )
                                                                            )
                                                                            .join(
                                                                                ', '
                                                                            )}
                                                                </Td>
                                                                {selectedMetar
                                                                    ?.data
                                                                    ?.forecast &&
                                                                    selectedMetar
                                                                        .data
                                                                        .forecast
                                                                        .length >
                                                                        0 &&
                                                                    selectedMetar.data.forecast.map(
                                                                        (
                                                                            forecast,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <Td
                                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                                    borderBottom="none"
                                                                                    key={`forecastWeather${i}`}
                                                                                    width="auto"
                                                                                    fontSize="0.9rem"
                                                                                >
                                                                                    <Flex flexDirection="row">
                                                                                        <Text>
                                                                                            {forecast.weather
                                                                                                ? wxTranslate(
                                                                                                      forecast
                                                                                                          .weather[0]
                                                                                                  )
                                                                                                : ''}
                                                                                        </Text>
                                                                                    </Flex>
                                                                                </Td>
                                                                            )
                                                                        }
                                                                    )}
                                                            </Tr>
                                                        )}

                                                        {(hasCloud ||
                                                            selectedMetar?.data
                                                                ?.observation[0]
                                                                ?.verticalVisibility ||
                                                            noCloudDetected ||
                                                            cloudAndVisibilityOK ||
                                                            (hasCloud &&
                                                                selectedMetar
                                                                    ?.data
                                                                    ?.forecast &&
                                                                selectedMetar
                                                                    .data
                                                                    .forecast
                                                                    .length >
                                                                    0 &&
                                                                selectedMetar.data.forecast
                                                                    .map(
                                                                        (
                                                                            forecast,
                                                                            i
                                                                        ) =>
                                                                            forecast.cloud
                                                                    )
                                                                    .filter(
                                                                        (val) =>
                                                                            val
                                                                    ).length >
                                                                    0)) && (
                                                            <Tr
                                                                key={`sky${index}`}
                                                            >
                                                                <Td
                                                                    zIndex="1"
                                                                    borderTop="1px solid #E8EBEE !important"
                                                                    borderBottom="none"
                                                                    paddingStart="3"
                                                                    paddingEnd="1"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    minWidth="127px"
                                                                    minHeight="120px"
                                                                    fontSize="0.9rem"
                                                                    textAlign="left"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    pointerEvents={
                                                                        fixFirstColumn
                                                                            ? 'none'
                                                                            : 'initial'
                                                                    }
                                                                >
                                                                    Sky
                                                                    Condition:
                                                                </Td>
                                                                <Td
                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                    borderBottom="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    position="relative"
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                >
                                                                    <Flex
                                                                        flexDirection="column"
                                                                        justifyContent="space-between"
                                                                        gap="6px"
                                                                    >
                                                                        {selectedMetar
                                                                            ?.data
                                                                            ?.observation[0]
                                                                            ?.verticalVisibility &&
                                                                            (selectedMetar
                                                                                ?.data
                                                                                ?.observation[0]
                                                                                ?.verticalVisibility
                                                                                ?.skyClear
                                                                                ? 'Clear'
                                                                                : `Vertical Visibility ${selectedMetar.data.observation[0].verticalVisibility.value} ${selectedMetar.data.observation[0].verticalVisibility.unit}`)}
                                                                        {reversedCloudLayers &&
                                                                            reversedCloudLayers.map(
                                                                                (
                                                                                    layer,
                                                                                    i
                                                                                ) => {
                                                                                    return (
                                                                                        <Flex
                                                                                            key={`skyCondition${i}`}
                                                                                            gap="5px"
                                                                                        >
                                                                                            <Text>
                                                                                                {
                                                                                                    layer.amount
                                                                                                }
                                                                                            </Text>
                                                                                            {/* <Text
                                                                                                color="gray.500"
                                                                                                fontWeight="300"
                                                                                                pr="2px"
                                                                                            >
                                                                                                {`(${cloudCoverOktasDecode(
                                                                                                    layer.amount
                                                                                                )})`}
                                                                                            </Text> */}
                                                                                            {layer.base &&
                                                                                                layer
                                                                                                    .base
                                                                                                    .value && (
                                                                                                    <Text>
                                                                                                        {layer
                                                                                                            .base
                                                                                                            .value +
                                                                                                            ' ft AGL '}
                                                                                                    </Text>
                                                                                                )}
                                                                                            {layer.type && (
                                                                                                <Text>
                                                                                                    {
                                                                                                        layer.type
                                                                                                    }
                                                                                                </Text>
                                                                                            )}
                                                                                        </Flex>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        {(noCloudDetected ||
                                                                            cloudAndVisibilityOK) && (
                                                                            <Text>
                                                                                No
                                                                                cloud
                                                                                detected
                                                                            </Text>
                                                                        )}
                                                                    </Flex>
                                                                </Td>
                                                                {selectedMetar
                                                                    ?.data
                                                                    ?.forecast &&
                                                                    selectedMetar
                                                                        .data
                                                                        .forecast
                                                                        .length >
                                                                        0 &&
                                                                    selectedMetar.data.forecast.map(
                                                                        (
                                                                            forecast,
                                                                            i
                                                                        ) => {
                                                                            const forecastedCoudLayers =
                                                                                forecast?.cloud &&
                                                                                forecast
                                                                                    ?.cloud
                                                                                    ?.layer
                                                                            const reversedForecastedCloudLayers =
                                                                                forecastedCoudLayers
                                                                                    ? reverseArray(
                                                                                          forecastedCoudLayers
                                                                                      )
                                                                                    : []

                                                                            return (
                                                                                <Td
                                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                                    borderBottom="none"
                                                                                    key={`cloud${i}`}
                                                                                    width="100%"
                                                                                    fontSize="0.9rem"
                                                                                >
                                                                                    {forecast.cloud && (
                                                                                        <Flex
                                                                                            flexDirection="column"
                                                                                            justifyContent="space-between"
                                                                                            gap="6px"
                                                                                        >
                                                                                            {reversedForecastedCloudLayers.map(
                                                                                                (
                                                                                                    layer,
                                                                                                    i
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <Flex
                                                                                                            key={`forecastCloud${i}`}
                                                                                                            gap="5px"
                                                                                                        >
                                                                                                            <Text>
                                                                                                                {
                                                                                                                    layer.amount
                                                                                                                }
                                                                                                            </Text>
                                                                                                            {/* <Text
                                                                                                                color="gray.500"
                                                                                                                fontWeight="300"
                                                                                                                pr="2px"
                                                                                                            >
                                                                                                                {`(${cloudCoverOktasDecode(
                                                                                                                    layer.amount
                                                                                                                )})`}
                                                                                                            </Text> */}
                                                                                                            {layer.base &&
                                                                                                                layer
                                                                                                                    .base
                                                                                                                    .value && (
                                                                                                                    <Text>
                                                                                                                        {layer
                                                                                                                            .base
                                                                                                                            .value +
                                                                                                                            ' ft AGL '}
                                                                                                                    </Text>
                                                                                                                )}
                                                                                                            {layer.type && (
                                                                                                                <Text>
                                                                                                                    {
                                                                                                                        layer.type
                                                                                                                    }
                                                                                                                </Text>
                                                                                                            )}
                                                                                                        </Flex>
                                                                                                    )
                                                                                                }
                                                                                            )}
                                                                                        </Flex>
                                                                                    )}
                                                                                </Td>
                                                                            )
                                                                        }
                                                                    )}
                                                            </Tr>
                                                        )}
                                                        <Tr>
                                                            <Td
                                                                zIndex="1"
                                                                borderTop="1px solid #E8EBEE !important"
                                                                borderBottom="none"
                                                                paddingStart="3"
                                                                paddingEnd="1"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                minWidth="127px"
                                                                minHeight="60px"
                                                                fontSize="0.9rem"
                                                                textAlign="left"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                                pointerEvents={
                                                                    fixFirstColumn
                                                                        ? 'none'
                                                                        : 'initial'
                                                                }
                                                            >
                                                                Temperature:
                                                            </Td>
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                {selectedMetar
                                                                    .data
                                                                    ?.observation[0]
                                                                    ?.airTemperature
                                                                    ? `${selectedMetar.data.observation[0].airTemperature.value} °C`
                                                                    : 'Not available'}
                                                            </Td>
                                                            {selectedMetar?.data
                                                                ?.forecast &&
                                                                selectedMetar
                                                                    .data
                                                                    .forecast
                                                                    .length >
                                                                    0 &&
                                                                selectedMetar.data.forecast.map(
                                                                    (
                                                                        forecast,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                borderTop="1px solid #EAEDF0 !important"
                                                                                borderBottom="none"
                                                                                key={`temp${i}`}
                                                                                width="100%"
                                                                                fontSize="0.9rem"
                                                                            >
                                                                                {forecast.airTemperature && (
                                                                                    <Flex
                                                                                        flexDirection="column"
                                                                                        justifyContent="space-between"
                                                                                        gap="6px"
                                                                                    >
                                                                                        {forecast.airTemperature.map(
                                                                                            (
                                                                                                temp,
                                                                                                i
                                                                                            ) => {
                                                                                                return (
                                                                                                    <Text
                                                                                                        key={`forecastTemp${i}`}
                                                                                                        paddingBottom="5px"
                                                                                                    >
                                                                                                        {
                                                                                                            temp.value
                                                                                                        }
                                                                                                    </Text>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    </Flex>
                                                                                )}
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                zIndex="1"
                                                                borderTop="1px solid #E8EBEE !important"
                                                                borderBottom="none"
                                                                paddingStart="3"
                                                                paddingEnd="1"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                minWidth="127px"
                                                                minHeight="60px"
                                                                fontSize="0.9rem"
                                                                textAlign="left"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                                pointerEvents={
                                                                    fixFirstColumn
                                                                        ? 'none'
                                                                        : 'initial'
                                                                }
                                                            >
                                                                Dewpoint:
                                                            </Td>
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                {selectedMetar
                                                                    ?.data
                                                                    ?.observation[0]
                                                                    ?.dewpointTemperature
                                                                    ? `${selectedMetar.data.observation[0].dewpointTemperature.value} °C`
                                                                    : 'Not available'}
                                                            </Td>
                                                            {selectedMetar?.data
                                                                ?.forecast &&
                                                                selectedMetar
                                                                    .data
                                                                    .forecast
                                                                    .length >
                                                                    0 &&
                                                                selectedMetar.data.forecast.map(
                                                                    (
                                                                        forecast,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                borderTop="1px solid #EAEDF0 !important"
                                                                                borderBottom="none"
                                                                                key={`dewpoint${i}`}
                                                                                width="100%"
                                                                                fontSize="0.9rem"
                                                                            >
                                                                                {forecast.dewpointTemperature && (
                                                                                    <Flex
                                                                                        flexDirection="column"
                                                                                        justifyContent="space-between"
                                                                                        gap="6px"
                                                                                    >
                                                                                        {forecast.dewpointTemperature.map(
                                                                                            (
                                                                                                dewpoint,
                                                                                                i
                                                                                            ) => {
                                                                                                return (
                                                                                                    <Text
                                                                                                        paddingBottom="5px"
                                                                                                        key={`forecastDewpoint${i}`}
                                                                                                    >
                                                                                                        {
                                                                                                            dewpoint.value
                                                                                                        }
                                                                                                    </Text>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    </Flex>
                                                                                )}
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                zIndex="1"
                                                                borderTop="1px solid #E8EBEE !important"
                                                                borderBottom="none"
                                                                paddingStart="3"
                                                                paddingEnd="1"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                minWidth="127px"
                                                                minHeight="60px"
                                                                fontSize="0.9rem"
                                                                textAlign="left"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                                pointerEvents={
                                                                    fixFirstColumn
                                                                        ? 'none'
                                                                        : 'initial'
                                                                }
                                                            >
                                                                QNH:
                                                            </Td>
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                {selectedMetar
                                                                    ?.data
                                                                    ?.observation[0]
                                                                    ?.qnh?.value
                                                                    ? `${selectedMetar.data.observation[0].qnh.value} hPa`
                                                                    : 'Not reported'}
                                                            </Td>
                                                            {selectedMetar?.data
                                                                ?.forecast &&
                                                                selectedMetar
                                                                    .data
                                                                    .forecast
                                                                    .length >
                                                                    0 &&
                                                                selectedMetar.data.forecast.map(
                                                                    (
                                                                        forecast,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                borderTop="1px solid #EAEDF0 !important"
                                                                                borderBottom="none"
                                                                                key={`qnh${i}`}
                                                                                width="100%"
                                                                                fontSize="0.9rem"
                                                                            >
                                                                                {forecast.qnh && (
                                                                                    <Flex
                                                                                        flexDirection="column"
                                                                                        justifyContent="space-between"
                                                                                        gap="6px"
                                                                                    >
                                                                                        {forecast.qnh.map(
                                                                                            (
                                                                                                qnh,
                                                                                                i
                                                                                            ) => {
                                                                                                return (
                                                                                                    <Text
                                                                                                        paddingBottom="5px"
                                                                                                        key={`forecastQnh${i}`}
                                                                                                    >
                                                                                                        {
                                                                                                            qnh.value
                                                                                                        }
                                                                                                    </Text>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    </Flex>
                                                                                )}
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>
                                                        {selectedMetar?.data
                                                            ?.observation[0]
                                                            ?.recentWeather && (
                                                            <Tr>
                                                                <Td
                                                                    zIndex="1"
                                                                    borderTop="1px solid #E8EBEE !important"
                                                                    borderBottom="none"
                                                                    paddingStart="3"
                                                                    paddingEnd="1"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    minWidth="127px"
                                                                    minHeight="120px"
                                                                    fontSize="0.9rem"
                                                                    textAlign="left"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    pointerEvents={
                                                                        fixFirstColumn
                                                                            ? 'none'
                                                                            : 'initial'
                                                                    }
                                                                >
                                                                    Recent
                                                                    Weather:
                                                                </Td>
                                                                <Td
                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                    borderBottom="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    position="relative"
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                >
                                                                    {selectedMetar
                                                                        .data
                                                                        .observation[0]
                                                                        .presentWeather &&
                                                                        selectedMetar.data.observation[0].recentWeather
                                                                            .map(
                                                                                (
                                                                                    wx
                                                                                ) =>
                                                                                    wxTranslate(
                                                                                        wx
                                                                                    )
                                                                            )
                                                                            .join(
                                                                                ', '
                                                                            )}
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                        {selectedMetar?.data
                                                            ?.observation[0]
                                                            ?.trend && (
                                                            <Tr>
                                                                <Td
                                                                    zIndex="1"
                                                                    borderTop="1px solid #E8EBEE !important"
                                                                    borderBottom="none"
                                                                    paddingStart="3"
                                                                    paddingEnd="1"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    minWidth="127px"
                                                                    minHeight="120px"
                                                                    fontSize="0.9rem"
                                                                    textAlign="left"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    pointerEvents={
                                                                        fixFirstColumn
                                                                            ? 'none'
                                                                            : 'initial'
                                                                    }
                                                                >
                                                                    Trend:
                                                                </Td>
                                                                <Td
                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                    borderBottom="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    position="relative"
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                >
                                                                    {
                                                                        selectedMetar
                                                                            .data
                                                                            .observation[0]
                                                                            .trend
                                                                    }
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                        {selectedMetar?.data
                                                            ?.remark && (
                                                            <Tr>
                                                                <Td
                                                                    zIndex="1"
                                                                    borderTop="1px solid #E8EBEE !important"
                                                                    borderBottom="none"
                                                                    paddingStart="3"
                                                                    paddingEnd="1"
                                                                    fontWeight="bold"
                                                                    width="127px"
                                                                    minWidth="127px"
                                                                    minHeight="120px"
                                                                    fontSize="0.9rem"
                                                                    textAlign="left"
                                                                    bg="light.35"
                                                                    position={
                                                                        fixFirstColumn
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    pointerEvents={
                                                                        fixFirstColumn
                                                                            ? 'none'
                                                                            : 'initial'
                                                                    }
                                                                >
                                                                    Remark:
                                                                </Td>
                                                                <Td
                                                                    borderTop="1px solid #EAEDF0 !important"
                                                                    borderBottom="none"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                    position="relative"
                                                                    paddingLeft={
                                                                        fixFirstColumn
                                                                            ? '143px'
                                                                            : '4'
                                                                    }
                                                                >
                                                                    {
                                                                        selectedMetar
                                                                            .data
                                                                            .remark
                                                                    }
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                    </>
                                                )}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    {nilMetar && !raw && (
                        <Box fontSize="0.9rem" whiteSpace="pre-line">
                            NIL
                        </Box>
                    )}
                    {(raw || !selectedMetar.data) && (
                        <Box fontSize="0.9rem" whiteSpace="pre-line" pt="7px">
                            {selectedMetar.raw}
                        </Box>
                    )}
                </Box>

                <Divider
                    borderColor="light.300"
                    borderBottomWidth="1px"
                    marginTop={raw ? '20px' : '10px'}
                    marginBottom="10px"
                />

                <Box align="Right" color="gray.400">
                    {raw && (
                        <Flex flexDirection="column">
                            <Text
                                as="i"
                                fontSize="0.7rem"
                                paddingBottom="5px"
                                paddingRight="2px"
                            >
                                All times referenced in the standard METAR are{' '}
                                <strong>UTC</strong>
                            </Text>
                        </Flex>
                    )}
                    {!raw && (
                        <Flex flexDirection="column">
                            <Text
                                height="22px"
                                as="i"
                                fontSize="0.7rem"
                                paddingBottom="5px"
                                paddingRight="3px"
                            >
                                All dates and times referenced are{' '}
                                <strong>
                                    {`${
                                        timeZone === 'UTC'
                                            ? 'UTC'
                                            : timeZoneAbbreviation(
                                                  new Date(),
                                                  Intl.DateTimeFormat().resolvedOptions()
                                                      .timeZone,
                                                  'z'
                                              )
                                    }`}
                                </strong>
                            </Text>
                        </Flex>
                    )}
                    <Flex flexDirection="column">
                        <Text
                            as="i"
                            fontSize="0.7rem"
                            paddingBottom="5px"
                            paddingRight="2px"
                        >
                            All heights reference feet above aerodrome level
                        </Text>
                    </Flex>
                    {raw && (
                        <Flex flexDirection="column">
                            <Text
                                as="i"
                                fontSize="0.7rem"
                                lineHeight="1"
                                paddingBottom="2px"
                                paddingRight="2px"
                            >
                                Wind:°TRUE / Speed:KT
                            </Text>
                        </Flex>
                    )}
                </Box>
            </Box>
        </>
    )
}
