import React, { useEffect } from 'react'
import {
    Stack,
    Flex,
    Text,
    Container,
    Box,
    Heading,
    Image,
    useColorModeValue,
} from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'
import { isMobile, isDesktop } from 'react-device-detect'
import briefLight from '../assets/brief-light.png'
import briefDark from '../assets/brief-dark.png'

export default function BriefMode(props) {
    const { Blob, setInViewPosition } = props

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        inView && setInViewPosition('briefingContainer')
    }, [inView, setInViewPosition])

    return (
        <>
            <div
                id="briefingContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            <Container
                maxW="100%"
                bg="light.10"
                zIndex="3"
                position="relative"
                mt="-1px"
                className="landingpage-content"
            >
                <Container maxW={'7xl'}>
                    <Stack
                        align={'center'}
                        spacing={{ base: 8, md: 10 }}
                        py={{ base: 12, md: 16, lg: 24 }}
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Flex
                            flex={1}
                            justify={'center'}
                            align={'center'}
                            position={'relative'}
                            w={'full'}
                        >
                            <Blob
                                w={'150%'}
                                h={'150%'}
                                position={'absolute'}
                                top={'-20%'}
                                left={0}
                                zIndex={-1}
                                color={useColorModeValue('#34D7B0', '#337BA6')}
                                opacity="0.04"
                            />
                            <Box
                                position={'relative'}
                                height={'auto'}
                                rounded={{ base: 'md', md: 'xl' }}
                                boxShadow={
                                    '0px 20px 50px -12px rgba(0, 15, 35, 0.35)'
                                }
                                width={'full'}
                                overflow={'hidden'}
                                transform={
                                    isMobile || !isDesktop
                                        ? 'none'
                                        : 'perspective(2000px) rotateY(25deg) rotateX(5deg)'
                                }
                                _hover={{
                                    transform:
                                        isMobile || !isDesktop
                                            ? 'none'
                                            : 'translateY(-10px) perspective(2000px) rotateY(20deg) rotateX(4deg) scale(1.02)',
                                    boxShadow: {
                                        base: '0px 35px 50px -25px rgba(0, 15, 35, 0.65)',
                                        md: '0px 35px 50px -25px rgba(0, 15, 35, 0.45)',
                                    },
                                }}
                                transition="transform 0.25s ease-out 0s, box-shadow 0.25s ease-out 0s"
                            >
                                <Image
                                    alt={'Briefings'}
                                    fit={'cover'}
                                    align={'center'}
                                    w={'100%'}
                                    h={'100%'}
                                    src={useColorModeValue(
                                        briefLight,
                                        briefDark
                                    )}
                                />
                            </Box>
                        </Flex>
                        <Stack
                            flex={1}
                            spacing={{ base: 5, md: 6 }}
                            textAlign={{ base: 'center', md: 'start' }}
                            justifyContent="flex-start"
                            alignItems={{ base: 'center', md: 'flex-start' }}
                        >
                            <Heading
                                pt={{ base: 4, sm: 6, md: 0 }}
                                pl={{ base: '0', md: '16px' }}
                                lineHeight={1.1}
                                fontWeight={600}
                                fontSize={{
                                    base: '4xl',
                                    sm: '5xl',
                                    lg: '6xl',
                                }}
                                width="max-content"
                                paddingRight={{ base: '0', md: '20px' }}
                            >
                                <Text
                                    className="modeLabel"
                                    style={{
                                        position: 'relative',
                                        zIndex: '1',
                                    }}
                                    height={{
                                        base: '50px',
                                        sm: '62px',
                                        lg: '78px',
                                    }}
                                >
                                    Briefings
                                </Text>
                                <Flex
                                    display={{ base: 'none', md: 'block' }}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <Box
                                        width="calc(100% + 15px)"
                                        height="25px"
                                        mt={{
                                            base: '-25px',
                                            sm: '-30px',
                                            lg: '-35px',
                                        }}
                                        ml="-5px"
                                        bg={useColorModeValue(
                                            'linear-gradient(90deg, #E8F9F9 10%, #F8FFFF 80%)',
                                            'linear-gradient(90deg, rgb(235, 245, 255) 10%, #F8FEFF 80%)'
                                        )}
                                        zIndex="-1"
                                        position="relative"
                                    ></Box>
                                </Flex>
                            </Heading>
                            <Text
                                mt={{
                                    base: '12px !important',
                                    md: '30px !important',
                                }}
                                fontSize="1.2rem"
                                textShadow="1px 5px 40px rgba(0, 0, 0, 0.2)"
                                as={'span'}
                                color={'gray.500'}
                                lineHeight={1.4}
                                fontWeight="600"
                                pl={{ base: '0', md: '20px' }}
                                pb="5px"
                            >
                                A comprehensive briefing report for pre-flight
                                planning.
                            </Text>
                            <Text
                                color={'gray.500'}
                                mt="10px !important"
                                pl={{ base: '0', md: '20px' }}
                            >
                                Select from standard briefing areas, AAW areas,
                                or plot your flight route.
                            </Text>
                            <Text
                                color={'gray.500'}
                                mt="10px !important"
                                pl={{ base: '0', md: '20px' }}
                            >
                                Conveniently, the aeronautical and
                                meteorological information within the report can
                                be filtered, collapsed and zoomed to for easier
                                reading. The report can be exported as a PDF and
                                printed.
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </Container>
        </>
    )
}
