
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { globalTimeState } from '../globalState'

export default function Time() {
    const setTime = useSetRecoilState(globalTimeState)

    // tick every second
    useEffect(() => {
        const tick = () => {
            const newTime = Date.now()
            setTime(newTime)
        }

        const timer = setInterval(() => tick(), 1000)
        return () => clearInterval(timer)
    }, [setTime])

    return null
}
