import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import {
    Modal,
    Flex,
    Spacer,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Text,
    // Image,
    ButtonGroup,
    useBreakpointValue,
    Tooltip,
} from '@chakra-ui/react'
import { parse } from 'json2csv'
import fileDownload from 'js-file-download'

import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../../globalState'

export default function UserReportModal() {
    const { getAccessTokenSilently } = useAuth0()

    const [jobStatus, setJobStatus] = useState(null)
    const [allUsers, setAllUsers] = useState(null)
    const [rolesUsers, setRolesUsers] = useState(null)
    const [downloadCsv, setDownloadCsv] = useState(false)
    const [csvExported, setCsvExported] = useState(false)
    const [filteredRoles, setFilteredRoles] = useState(null)
    const [filteredTypes, setFilteredTypes] = useState('')

    const [done, setDone] = useState(false)

    const fetchJobStatus = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/userreport${
                jobStatus.id ? '?job=' + jobStatus.id : ''
            }`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const { data, remove } = useQuery('userReport', fetchJobStatus, {
        refetchOnWindowFocus: false,
        refetchInterval: 1000,
        enabled: jobStatus && jobStatus.status !== 'completed' ? true : false,
    })

    useEffect(() => {
        if (data) {
            // console.log(data)
            setJobStatus(data)
        }
    }, [setJobStatus, data])

    const fetchResult = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        fetch(`${window.location.origin}/data/userreport/data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ uri: jobStatus.location }),
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data)
                const formatted = Object.assign(
                    ...data.map((user) => ({
                        [user.user_id]: {
                            email: user.email,
                            organization: user['user_metadata.organization'],
                            creation_date: user.created_at,
                            last_login: user.last_login,
                        },
                    }))
                )
                setAllUsers(formatted)
            })
    }, [getAccessTokenSilently, jobStatus])

    useEffect(() => {
        if (jobStatus && jobStatus.status === 'completed') {
            fetchResult()
        }
    }, [jobStatus, fetchResult])

    const fetchRolesUsers = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        fetch(
            `${window.location.origin}/data/roles/users?type=${
                filteredTypes === 'Professional'
                    ? 'Professional'
                    : filteredTypes === 'Plus'
                    ? 'Plus'
                    : filteredTypes === 'Bypass MFA'
                    ? 'Bypass MFA'
                    : ''
            }`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setRolesUsers(data)
            })
    }, [getAccessTokenSilently, filteredTypes])

    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'userReport', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.userReport ? onOpen() : onClose()
    }, [modalVisibility.userReport, onOpen, onClose])

    const onCompleteHandler = useCallback(() => {
        setCsvExported(true)
        remove()
        setJobStatus(null)
        setAllUsers(null)
        setRolesUsers(null)
        setDone(false)
        setDownloadCsv(false)
        setFilteredTypes('')
        setTimeout(() => {
            setCsvExported(false)
            setFilteredRoles(null)
        }, [3000])
    }, [remove, setDownloadCsv])

    const onCloseHandler = useCallback(() => {
        onCompleteHandler()
        setShow(false)
        onClose()
    }, [onCompleteHandler, setShow, onClose])

    useEffect(() => {
        if (allUsers) {
            fetchRolesUsers()
        }
    }, [allUsers, fetchRolesUsers])

    const disableButton = Boolean(
        (jobStatus?.status === 'completed' && allUsers && rolesUsers) || done
    )

    const disabledSelect = Boolean(
        jobStatus?.status !== 'completed' ||
            (jobStatus?.status === 'completed' && (!allUsers || !rolesUsers)) ||
            done
    )

    const buttonsWidth = useBreakpointValue({
        base: 'calc(100vw - 70px)',
        sm: 'calc(100vw - 80px)',
        smd: '460px',
        md: '600px',
    })

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    const roles = rolesUsers && Object.keys(rolesUsers) // converted to an array of the Objects keys

    const formattedRoles = // map the array to an object for the Select component so it has vale and label
        rolesUsers &&
        roles
            .filter((value) => value !== filteredTypes)
            .map((key) => ({
                value: key,
                label: key,
            }))

    const filteredRolesUsers = // filter the roles to only those selected and format them back to an object, or else leave original rolesUsers
        rolesUsers && filteredRoles && filteredRoles.length > 0
            ? Object.fromEntries(
                  Object.entries(rolesUsers).filter(([role]) =>
                      filteredRoles.includes(role)
                  )
              )
            : rolesUsers

    const emailIds = [] // set the emailIds as array to allow filtering for CSV file
    filteredRolesUsers &&
        Object.values(filteredRolesUsers).forEach((roleEmail) =>
            roleEmail.forEach(({ email }) => {
                if (!emailIds.includes(email)) {
                    emailIds.push(email)
                }
            })
        )

    var usersWithRoles = // reconfiguring users to only those with roles
        allUsers &&
        Object.assign(
            {},
            ...Object.keys(allUsers).map((id) => ({
                [id]: {
                    ...allUsers[id],
                    roles: [],
                },
            }))
        )

    const filteredUsersWithRoles = // filter usersWithRoles based on the emailIds
        usersWithRoles &&
        Object.fromEntries(
            Object.entries(usersWithRoles).filter(([userId, user]) =>
                emailIds.includes(user.email)
            )
        )

    useEffect(() => {
        if (
            allUsers &&
            filteredRolesUsers &&
            filteredUsersWithRoles &&
            downloadCsv
        ) {
            const csv = parse(
                Object.keys(filteredUsersWithRoles).map((id) => ({
                    id: id,
                    ...filteredUsersWithRoles[id],
                }))
            )
            // console.log('csv', csv)
            fileDownload(csv, 'userReport.csv', 'text/csv')
            setDone(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadCsv])

    useEffect(() => {
        if (done) {
            setTimeout(() => {
                onCompleteHandler()
            }, [1000])
        }
    }, [done, onCompleteHandler])

    // useEffect(() => {
    //     if (allUsers) {
    //         console.log('allUsers: ', allUsers)
    //     }
    // }, [allUsers])

    // useEffect(() => {
    //     if (rolesUsers) {
    //         console.log('rolesUsers:', rolesUsers)
    //     }
    // }, [allUsers])

    useEffect(() => {
        if (rolesUsers) {
            console.log(
                'Roles Types:',
                Object.keys(rolesUsers).length,
                Object.keys(rolesUsers)
            )
        }
        if (
            filteredUsersWithRoles &&
            filteredRoles &&
            filteredRoles.length > 0
        ) {
            console.log('Filtered Roles:', filteredRoles)
            console.log(
                'Total Users in Filtered Roles:',
                Object.keys(filteredUsersWithRoles).length
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolesUsers, filteredUsersWithRoles])

    return (
        <LightMode>
            <Modal
                variant="dataStatus"
                size="4xl"
                isCentered
                onClose={onCloseHandler}
                isOpen={isOpen}
                motionPreset="slideInBottom"
                scrollBehavior="inside"
                closeOnOverlayClick={true}
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton color="white" />
                    <ModalHeader
                        textAlign="center"
                        backgroundColor="#2d3e4f"
                        color="white"
                        letterSpacing="0.5px"
                    >
                        User Report
                    </ModalHeader>
                    <ModalBody minHeight="300px" style={{ padding: 0 }}>
                        {/* <Image
                            src="https://i.imgflip.com/sdg2a.jpg?a461976"
                            height="250px"
                            width="250px"
                            opacity={done ? 1 : 0}
                            transition="opacity 0.3s"
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                        /> */}
                        <Flex
                            flexDir="column"
                            justifyContent="center"
                            alignItems="center"
                            pt="50px"
                            px="20px"
                            gap={{ base: '15px', sm: '15px' }}
                            mx="auto"
                            // display={done ? 'none' : undefined}
                        >
                            <Text align="center">
                                <span style={{ fontWeight: '600' }}>
                                    There is no error handling on this - so it
                                    won't stop spinning if it fails.
                                </span>{' '}
                                <br />
                                (Report usually takes around{' '}
                                <span style={{ fontWeight: '700' }}>
                                    30 seconds
                                </span>{' '}
                                to run)
                            </Text>
                            <Flex
                                flexDir="column"
                                justifyContent="center"
                                alignItems="center"
                                gap="10px"
                                pt="5px"
                                color="gray.500"
                            >
                                <Text
                                    align="center"
                                    fontSize={{ base: '0.8rem', sm: '0.85rem' }}
                                >
                                    The role lookup currently has a limit of
                                    1,000 users per role - additional coding to
                                    be done to get around this limitation, but
                                    not implimented at this time.
                                </Text>
                                <Text
                                    align="center"
                                    fontSize={{ base: '0.8rem', sm: '0.85rem' }}
                                >
                                    Filtering is also currently limited to the
                                    first 50 role types. Cause of this is yet
                                    unknown, further work on this is also
                                    required.
                                </Text>
                            </Flex>
                            <Spacer />
                            {downloadCsv ||
                            done ||
                            (jobStatus && jobStatus?.status === 'completed') ? (
                                <Flex
                                    width="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="62px"
                                    fontWeight="600"
                                    maxWidth={buttonsWidth}
                                    gap="5px"
                                    textTransform={'uppercase'}
                                >
                                    <Text>
                                        {filteredTypes === ''
                                            ? 'Subscriptions not filtered'
                                            : 'Subscriptions filtered to'}
                                    </Text>
                                    {filteredTypes !== '' && (
                                        <>
                                            <Text
                                                fontWeight="700"
                                                color="light.200"
                                            >
                                                {filteredTypes}
                                            </Text>
                                            <Text>only</Text>
                                        </>
                                    )}
                                </Flex>
                            ) : (
                                <>
                                    <Text
                                        width="100%"
                                        textAlign="center"
                                        fontSize={{
                                            base: '0.75rem',
                                            sm: '0.8rem',
                                        }}
                                        color="gray.600"
                                        fontWeight="300"
                                        mb="-5px"
                                        height="20px"
                                    >
                                        Filter Subscription type if required
                                    </Text>
                                    <ButtonGroup
                                        overflow="hidden"
                                        width="100%"
                                        spacing="0"
                                        // borderRadius="12px"
                                        // border="solid 1px #e2e8f0"
                                        maxWidth={buttonsWidth}
                                        isDisabled={
                                            downloadCsv ||
                                            done ||
                                            (jobStatus &&
                                                jobStatus?.status ===
                                                    'completed')
                                        }
                                    >
                                        <Button
                                            size="sm"
                                            variant="outline"
                                            width="100%"
                                            fontSize="0.8rem"
                                            fontWeight="600"
                                            color={
                                                filteredTypes === ''
                                                    ? 'light.10'
                                                    : 'gray.700'
                                            }
                                            borderRadius="0px"
                                            borderLeftRadius="12px"
                                            background={
                                                filteredTypes === ''
                                                    ? 'gray.500'
                                                    : 'gray.50'
                                            }
                                            borderColor={
                                                filteredTypes === ''
                                                    ? 'gray.500'
                                                    : 'gray.200'
                                            }
                                            _hover={{
                                                background:
                                                    filteredTypes === ''
                                                        ? 'gray.500'
                                                        : 'gray.200',
                                            }}
                                            _focus={{
                                                background:
                                                    filteredTypes === ''
                                                        ? 'gray.500'
                                                        : 'gray.200',
                                            }}
                                            _active={{ background: 'gray.500' }}
                                            onClick={() => setFilteredTypes('')}
                                        >
                                            All{' '}
                                            <Text
                                                display={{
                                                    base: 'none',
                                                    smd: 'block',
                                                }}
                                                pl="5px"
                                                fontSize="0.7rem"
                                                fontWeight="300"
                                            >
                                                (FREE, PLUS and PRO)
                                            </Text>
                                        </Button>
                                        <Button
                                            size="sm"
                                            variant="outline"
                                            width="100%"
                                            fontSize="0.8rem"
                                            fontWeight="600"
                                            color={
                                                filteredTypes === 'Plus'
                                                    ? 'light.10'
                                                    : 'gray.700'
                                            }
                                            borderRadius="0px"
                                            background={
                                                filteredTypes === 'Plus'
                                                    ? 'gray.500'
                                                    : 'gray.50'
                                            }
                                            borderColor={
                                                filteredTypes === 'Plus'
                                                    ? 'gray.500'
                                                    : 'gray.200'
                                            }
                                            _hover={{
                                                background:
                                                    filteredTypes === 'Plus'
                                                        ? 'gray.500'
                                                        : 'gray.200',
                                            }}
                                            _focus={{
                                                background:
                                                    filteredTypes === 'Plus'
                                                        ? 'gray.500'
                                                        : 'gray.200',
                                            }}
                                            _active={{ background: 'gray.500' }}
                                            onClick={() =>
                                                setFilteredTypes('Plus')
                                            }
                                        >
                                            PLUS Only
                                        </Button>
                                        <Button
                                            size="sm"
                                            variant="outline"
                                            width="100%"
                                            fontSize="0.8rem"
                                            fontWeight="600"
                                            color={
                                                filteredTypes === 'Professional'
                                                    ? 'light.10'
                                                    : 'gray.700'
                                            }
                                            borderRadius="0px"
                                            borderRightRadius="12px"
                                            background={
                                                filteredTypes === 'Professional'
                                                    ? 'gray.500'
                                                    : 'gray.50'
                                            }
                                            borderColor={
                                                filteredTypes === 'Professional'
                                                    ? 'gray.500'
                                                    : 'gray.200'
                                            }
                                            _hover={{
                                                background:
                                                    filteredTypes ===
                                                    'Professional'
                                                        ? 'gray.500'
                                                        : 'gray.200',
                                            }}
                                            _focus={{
                                                background:
                                                    filteredTypes ===
                                                    'Professional'
                                                        ? 'gray.500'
                                                        : 'gray.200',
                                            }}
                                            _active={{ background: 'gray.500' }}
                                            onClick={() =>
                                                setFilteredTypes('Professional')
                                            }
                                        >
                                            PRO Only
                                        </Button>
                                    </ButtonGroup>
                                </>
                            )}
                            {/* <Spacer /> */}

                            {disabledSelect ? (
                                <Text
                                    pt="5px"
                                    width="100%"
                                    textAlign="center"
                                    fontSize={{ base: '0.75rem', sm: '0.8rem' }}
                                    color="gray.600"
                                    fontWeight="300"
                                    mb="-5px"
                                >
                                    Fetch User and Role data first to then apply
                                    role filters if required
                                </Text>
                            ) : (
                                <Text
                                    pt="5px"
                                    width="100%"
                                    textAlign="center"
                                    fontSize={{ base: '0.75rem', sm: '0.8rem' }}
                                    color="gray.600"
                                    fontWeight="300"
                                    mb="-5px"
                                >
                                    Select role types to filter CSV export
                                    report if desired. Left empty returns{' '}
                                    <span style={{ fontWeight: '600' }}>
                                        ALL
                                    </span>{' '}
                                    roles.
                                </Text>
                            )}
                            <Tooltip
                                label={
                                    disabledSelect
                                        ? 'Fetch data first'
                                        : 'Select user role types if filtering needed'
                                }
                                hasArrow
                                borderRadius="7px"
                                minHeight="24px"
                                fontSize="0.85rem"
                                alignItems="center"
                                display="flex"
                                placement="top"
                                m="5px"
                                px="10px"
                                color="light.10"
                                bgColor={'gray.600'}
                            >
                                <Flex
                                    cursor={
                                        disabledSelect
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    minWidth={buttonsWidth}
                                >
                                    <Select
                                        isMulti
                                        menuPosition="fixed"
                                        {...noFocus}
                                        // isSearchable={false}
                                        controlShouldRenderValue={true}
                                        closeMenuOnSelect={false}
                                        isDisabled={disabledSelect}
                                        pointerEvents="auto"
                                        styles={{
                                            option: (styles) => ({
                                                ...styles,
                                                cursor: 'pointer',
                                                paddingTop: '6px',
                                                paddingBottom: '6px',
                                            }),
                                            control: (styles) => ({
                                                ...styles,
                                                cursor: 'pointer',
                                                borderRadius: '10px',
                                                borderColor: '#e5e5e5',
                                                minWidth: buttonsWidth,
                                                minHeight: '36px',
                                            }),
                                            dropdownIndicator: (styles) => ({
                                                ...styles,
                                                paddingTop: '4px',
                                                paddingBottom: '4px',
                                            }),
                                            menu: (styles) => ({
                                                ...styles,
                                                zIndex: '100',
                                                marginTop: '3px',
                                            }),
                                        }}
                                        value={
                                            filteredRoles &&
                                            formattedRoles &&
                                            formattedRoles.filter((option) => {
                                                return filteredRoles.includes(
                                                    option.value
                                                )
                                            })
                                        }
                                        onChange={(option) => {
                                            if (!disabledSelect) {
                                            }
                                            setFilteredRoles(
                                                option.map((v) => v.label)
                                            )
                                        }}
                                        options={formattedRoles}
                                    />
                                </Flex>
                            </Tooltip>

                            <ButtonGroup
                                pt="10px"
                                width="100%"
                                gap={{ base: '10px', sm: '15px' }}
                                maxWidth={buttonsWidth}
                            >
                                <Button
                                    size="md"
                                    width="100%"
                                    fontSize="0.9rem"
                                    fontWeight="600"
                                    borderRadius="20px"
                                    onClick={() => {
                                        if (!disableButton) {
                                            setJobStatus({ status: 'start' })
                                        }
                                    }}
                                    background={
                                        jobStatus &&
                                        jobStatus?.status === 'completed' &&
                                        disableButton
                                            ? 'light.10'
                                            : disableButton
                                            ? 'gray.50'
                                            : 'gray.100'
                                    }
                                    cursor={
                                        jobStatus &&
                                        jobStatus?.status === 'completed' &&
                                        disableButton
                                            ? 'default'
                                            : disableButton
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }
                                    isDisabled={
                                        downloadCsv ||
                                        done ||
                                        (jobStatus &&
                                            jobStatus?.status === 'completed' &&
                                            (!allUsers || !rolesUsers))
                                    }
                                    isLoading={
                                        (jobStatus &&
                                            jobStatus?.status !==
                                                'completed') ||
                                        (jobStatus &&
                                            jobStatus?.status === 'completed' &&
                                            (!allUsers || !rolesUsers))
                                    }
                                    _hover={{
                                        background:
                                            jobStatus &&
                                            jobStatus?.status === 'completed' &&
                                            disableButton
                                                ? 'light.10'
                                                : disableButton
                                                ? 'gray.50'
                                                : 'gray.200',
                                    }}
                                    _focus={{
                                        background:
                                            jobStatus &&
                                            jobStatus?.status === 'completed' &&
                                            disableButton
                                                ? 'light.10'
                                                : disableButton
                                                ? 'gray.50'
                                                : 'gray.200',
                                    }}
                                    _active={{
                                        background:
                                            jobStatus &&
                                            jobStatus?.status === 'completed' &&
                                            disableButton
                                                ? 'light.10'
                                                : disableButton
                                                ? 'gray.50'
                                                : 'gray.200',
                                    }}
                                >
                                    {jobStatus?.status === 'completed'
                                        ? 'User and Role data loaded'
                                        : 'Fetch Data'}
                                </Button>
                                <Button
                                    size="md"
                                    variant=""
                                    width="100%"
                                    fontSize="0.9rem"
                                    fontWeight="600"
                                    color="white"
                                    borderRadius="20px"
                                    background="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    onClick={() => setDownloadCsv(true)}
                                    isDisabled={
                                        jobStatus?.status !== 'completed' ||
                                        (jobStatus?.status === 'completed' &&
                                            (!allUsers || !rolesUsers)) ||
                                        done
                                    }
                                    isLoading={downloadCsv}
                                >
                                    Download CSV
                                </Button>
                            </ButtonGroup>

                            <Text
                                align="center"
                                fontWeight="600"
                                fontSize="1.1rem"
                                pb="15px"
                                mt="-5px"
                                opacity={csvExported ? 1 : 0}
                            >
                                CSV File Exported
                            </Text>
                        </Flex>
                    </ModalBody>

                    <ModalFooter pt={3.5} pb={4} borderTop="1px solid #eaeaea">
                        <Flex
                            width="100%"
                            overflow="hidden"
                            mx={{ base: 'auto', md: '25px' }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                w="100%"
                                size="sm"
                                maxWidth="320px"
                                border="1px solid"
                                borderColor=" #e5e5e5"
                                color="dark.30"
                                backgroundColor="light.40"
                                borderRadius="10px"
                                _hover={{
                                    backgroundColor: 'light.30',
                                }}
                                _active={{
                                    backgroundColor: 'light.30',
                                }}
                                onClick={onCloseHandler}
                            >
                                Close
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </LightMode>
    )

    // return <Spinner />
}
