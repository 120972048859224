import React from 'react'

import Time from './Time'
import NetworkDetector from './NetworkDetector'
import SubscribeToUsers from './SubscribeToUsers'
import ResetTimeZone from './ResetTimeZone'

export default function Services() {
    return (
        <>
            <Time />
            <NetworkDetector />
            <SubscribeToUsers />
            <ResetTimeZone />
        </>
    )
}
