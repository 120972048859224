import React, { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import Joyride from 'react-joyride' // Step, // STATUS, // EVENTS, // CallBackProps, // ACTIONS, // TooltipRenderProps, // BeaconRenderProps,
import { isMobile } from 'react-device-detect'
import AerodromeIcon from '../../../icons/menu-icons/AerodromeIcon'
import { ReactComponent as DevicesIcon } from '../../../icons/assets/devices.svg'
import { ReactComponent as SelectIcon } from '../../../icons/assets/select.svg'
import { ReactComponent as PinchZoomIcon } from '../../../icons/assets/pinch-zoom.svg'
import { ReactComponent as PremiumIcon } from '../../../icons/assets/premium-outline.svg'

import deepmerge from 'deepmerge'
import {
    Badge,
    Text,
    Flex,
    UnorderedList,
    ListItem,
    ListIcon,
} from '@chakra-ui/react'
import { GoCheck } from 'react-icons/go'
import { RiStarLine } from 'react-icons/ri'
import {
    tourVisibilityState,
    closeLayerSelectorHandler,
    dataModeState,
    setMenuExpandedState,
    mapControlsExpandState,
    briefingOptionsState,
    briefingStandardState,
    firstTimeLoggedInState,
    tourCompletedState,
} from '../../../globalState'

export default function UserIntro(stepStyles) {
    const [briefingOptionsOpen, setBriefingOptionsOpen] =
        useRecoilState(briefingOptionsState)
    const [briefingStandardOpen, setBriefingStandardOpen] = useRecoilState(
        briefingStandardState
    )
    const setFirstTimeLoggedIn = useSetRecoilState(firstTimeLoggedInState)
    const [tourVisibility, setTourVisibility] =
        useRecoilState(tourVisibilityState)
    const setTourCompleted = useSetRecoilState(tourCompletedState)
    const closeLayerSelector = useSetRecoilState(closeLayerSelectorHandler)
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const setMapControlsExpanded = useSetRecoilState(mapControlsExpandState)
    const setMenuExpanded = useSetRecoilState(setMenuExpandedState)
    const [startTour, setStartTour] = useState(false)
    const advsMode = dataMode === 'advs'

    useEffect(() => {
        if (tourVisibility) {
            setStartTour(true)
        }
        if (!tourVisibility) {
            setStartTour(false)
        }
    }, [tourVisibility, setTourCompleted])

    const premiumBadge = (
        <Badge
            display="inline-flex"
            justifyContent="space-evenly"
            alignItems="center"
            marginTop="1px"
            ml="2px"
            mr="1px"
            mt="-2px"
            variant="solid"
            bg="rgba(0, 188, 248, 0.1)"
            color="#00B6F1"
            textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
            borderRadius="10"
            pl="2px"
            pr="5px"
            pb="0px"
            pt="0px"
            fontSize="12px"
            fontWeight="600"
            // height="16px"
            width="79px"
        >
            <RiStarLine color="#00B6F1" fontSize="14px" />
            <Text ml="-1px">Premium</Text>
        </Badge>
    )

    // const [stepPosition, setStepPosition] = useState(0)
    // const stepNumber = stepPosition + 1

    // useEffect(() => {
    //     console.log(tourVisibility, 'tourVisibility:')
    //     console.log(startTour, 'startTour:')
    // }, [tourVisibility, startTour])

    const mobileView = window.innerWidth < 768
    const screenMax360 = window.innerWidth < 360
    const screenMax400 = window.innerWidth < 400
    const screenMax480 = window.innerWidth < 480
    const screenMax768 = window.innerWidth < 768

    const stepTotal = '15' // UPDATE THIS WHEN ADDING NEW SLIDES

    const stepBadge = {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: '24px',
        borderRadius: '50px',
        paddingLeft: '10px',
        paddingRight: '10px',
        background: '#00D599',
        color: '#fff',
        fontSize: '0.7rem',
        fontWeight: '600',
        top: '-5px',
        left: '-5px',
        boxShadow: '0px 2px 7px -2px rgba(0,0,0,0.5)',
        zIndex: '1',
    }

    const [steps, setSteps] = useState([
        {
            title: 'Introduction to PreFlight',
            target: '#root',
            content: (
                <div>
                    <div style={stepBadge}>
                        {/* <p>{stepNumber} / 9</p> */}
                        <p>1 / {stepTotal}</p>
                    </div>
                    <div>
                        <p
                            style={{
                                paddingTop: '5px',
                                paddingBottom: '10px',
                            }}
                        >
                            This walkthrough will give you a quick summary of
                            the various components within PreFlight.
                        </p>

                        <p>Let's begin....</p>
                    </div>
                </div>
            ),
            spotlightPadding: 10,
            disableOverlayClose: true,
            placement: 'center',
            locale: { skip: 'Cancel' },
            hideCloseButton: true,
            disableBeacon: true,
            // hideFooter: true,
            // spotlightClicks: true,
        },
        {
            title: 'Main Menu',
            target: '#modeMenu',
            content: (
                <div>
                    <div style={stepBadge}>
                        {/* <p>{stepNumber} / 9</p> */}
                        <p>2 / {stepTotal}</p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            This is the main navigation menu.
                        </p>
                        <p style={{ paddingBottom: '10px' }}>
                            From here you can select a mode to see various
                            aeronautical or meteorological information, or
                            create a consolidated briefing report.
                        </p>
                        <p>
                            Each mode has sub-menus to enable you to select
                            specific information.
                        </p>
                    </div>
                </div>
            ),
            spotlightPadding: 10,
            disableOverlayClose: true,
            placement: isMobile || mobileView ? 'top' : 'right',
            locale: { skip: 'Cancel' },
            hideCloseButton: true,
            disableBeacon: true,
            // hideFooter: true,
            // spotlightClicks: true,
        },
        {
            title: 'AERO Mode',
            target: '#aero',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>3 / {stepTotal}</p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            AERO mode provides aeronautical information as
                            graphical layers overlaid on the map.
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            These include airspace, obstacles, hazards,
                            nav-aids, and visual reporting points ....as you
                            would find on a Visual Navigation Chart.
                        </p>

                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            IFR Routes are also available to {premiumBadge}{' '}
                            users.
                        </p>
                    </div>
                </div>
            ),
            placement: isMobile || mobileView ? 'top-start' : 'right',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 0,
            hideCloseButton: true,
        },
        {
            title: 'MET Mode',
            target: '#met',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>4 / {stepTotal}</p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            MET mode provides meteorological information.
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            This is displayed as graphical layers overlaid on
                            the map, in meteorological charts or image-loopers.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            Some MET items are only available to {premiumBadge}{' '}
                            users.
                        </p>
                    </div>
                </div>
            ),
            placement: isMobile || mobileView ? 'top' : 'right',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 0,
            hideCloseButton: true,
        },
        {
            title: 'ADVS Mode',
            target: '#advs',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>5 / {stepTotal}</p>
                    </div>
                    <Badge
                        position="absolute"
                        top="10px"
                        right={
                            screenMax360
                                ? '40px'
                                : screenMax400
                                ? '60px'
                                : screenMax480
                                ? '85px'
                                : screenMax768
                                ? '128px'
                                : '184px'
                        }
                        display="inline-flex"
                        alignItems="center"
                        p="1px"
                        variant="solid"
                        bg="rgba(0, 188, 248, 1)"
                        borderRadius="10"
                        height="14px"
                        width="14px"
                    >
                        <PremiumIcon color="#FFFFFF" />
                    </Badge>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            ADVS (advisory) mode provides aeronautical and
                            meteorological advisory information as graphical
                            layers on the map.
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            These include NOTAMs, SIGMETs and SIGWX.
                        </p>
                        {/* These include NOTAMs, RPAS (drones), SIGMETs and
                            SIGWX. */}

                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                paddingBottom: '10px',
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            ADVS mode is only available to {premiumBadge} users.
                        </p>
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            The information is otherwise still available as raw
                            text in a Standard Briefing.
                        </p>
                    </div>
                </div>
            ),
            // resizeDebounce: true,
            // resizeDebounceDelay: 1.8,
            placement: isMobile || mobileView ? 'top' : 'right',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 0,
            hideCloseButton: true,
            // spotlightClicks: true,
        },

        {
            title: 'BRIEF Mode',
            target: '#brief',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>6 / {stepTotal}</p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            BRIEF (briefing) mode provides a comprehensive
                            briefing report from selected standard briefing
                            areas or AAW areas for pre-flight planning.
                        </p>
                        <p style={{ paddingBottom: '10px' }}>
                            Conveniently, the aeronautical and meteorological
                            information within the report can be filtered,
                            collapsed and zoomed to for easier reading.
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            The report can be exported as a PDF and printed.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        {/* <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            BRIEF mode is available to <strong>all</strong>{' '}
                            users.
                        </p> */}
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            Custom routes/areas, and altitude levels above
                            10,000 ft, are only available to {premiumBadge}{' '}
                            users.
                        </p>
                    </div>
                </div>
            ),
            // resizeDebounce: true,
            // resizeDebounceDelay: 1.8,
            placement: isMobile || mobileView ? 'top-end' : 'right',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 0,
            hideCloseButton: true,
        },

        {
            title: 'Map',
            target: '#root',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '9' : '8'} / {stepTotal} */}
                            7 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '2px' }}>
                            <Flex justifyContent="center" alignItems="center">
                                <PinchZoomIcon
                                    color="light.100"
                                    width="56px"
                                    height="56px"
                                />
                            </Flex>
                        </p>
                        <p style={{ paddingBottom: '10px' }}>
                            The map is contextual, so as you zoom in, you get
                            more data. Selecting an item on the map will provide
                            more detail.
                        </p>

                        <p style={{ paddingBottom: '10px' }}>
                            Information throughout this web application is up to
                            date within the previous 5 minutes.
                        </p>
                    </div>
                </div>
            ),
            placement: 'center',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },

        {
            title: 'Aerodromes',
            target: '#root',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '9' : '8'} / {stepTotal} */}
                            8 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '5px' }}>
                            <Flex
                                justifyContent="center"
                                alignItems="flex-start"
                            >
                                <Flex
                                    pt="20px"
                                    mr="-12px"
                                    flexDirection="column"
                                >
                                    <SelectIcon
                                        color="light.100"
                                        width="56px"
                                        height="56px"
                                    />
                                </Flex>
                                <Flex mt="0px" pr="15px" flexDirection="column">
                                    <AerodromeIcon
                                        color={'#90328E'}
                                        activeColor={'#90328E'}
                                    />
                                </Flex>
                            </Flex>
                        </p>
                        <p style={{ paddingBottom: '10px' }}>
                            When selecting an aerodrome, you are provided with
                            current information including NOTAMs, METAR, TAF and
                            ATIS where available.
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            <Badge
                                display="inline-flex"
                                alignItems="center"
                                marginTop="1px"
                                mr="7px"
                                mt="-3px"
                                variant="solid"
                                bg="#fa9c3e"
                                textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                borderRadius="10"
                                height="10px"
                                width="10px"
                            />
                            An orange badge on an aerodrome symbol indicates
                            NOTAMs are issued for that aerodrome.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            Additional aerodrome items including Webcams and
                            Charts are available to {premiumBadge} users.
                        </p>
                    </div>
                </div>
            ),
            placement: 'center',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },

        {
            title: 'Layer Selector',
            target: '#advsLayers',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>9 / {stepTotal}</p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            In <strong>AERO</strong> mode or{' '}
                            <strong>ADVS</strong> mode, the Layer Selector menu
                            allows layers overlaid on the map to be toggled on
                            and off separately.
                        </p>
                        <p style={{ paddingBottom: '10px' }}>
                            In <strong>MET</strong> mode, the Layer Selector
                            menu allows you to select individual layers, charts
                            or image-loopers from various meteorological
                            categories. Only one MET item is able to be
                            displayed at a time.
                        </p>
                    </div>
                </div>
            ),
            // resizeDebounce: true,
            // resizeDebounceDelay: 1.8,
            placement: isMobile || mobileView ? 'right-end' : 'right',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },
        {
            title: 'Map Controls',
            target: '#mapControls',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '8' : '7'} / {stepTotal} */}
                            10 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '15px' }}>
                            Tools from the map controls menu assist when using
                            the map. These include:
                            <UnorderedList
                                spacing="1"
                                mt="12px"
                                maxW="36ch"
                                mx="auto"
                            >
                                <ListItem
                                    alignContent={'flex-start'}
                                    // fontSize="0.8rem"
                                    display="flex"
                                    flexDirection="row"
                                    // ml="23px"
                                >
                                    <ListIcon
                                        fontSize="1.1rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="#00D599"
                                    />
                                    <Text textAlign="left">
                                        Base map selector
                                    </Text>
                                </ListItem>
                                <ListItem
                                    // fontWeight="400"
                                    alignContent={'flex-start'}
                                    // fontSize="0.8rem"
                                    display="flex"
                                    flexDirection="row"
                                    // ml="23px"
                                >
                                    <ListIcon
                                        fontSize="1.1rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="#00D599"
                                    />
                                    <Text textAlign="left">
                                        Aerodrome toggle switch to show/hide
                                        aerodromes on the map
                                    </Text>
                                </ListItem>
                                <ListItem
                                    // fontWeight="400"
                                    alignContent={'flex-start'}
                                    // fontSize="0.8rem"
                                    display="flex"
                                    flexDirection="row"
                                    // ml="23px"
                                >
                                    <ListIcon
                                        fontSize="1.1rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="#00D599"
                                    />
                                    <Text textAlign="left">Map legend</Text>
                                </ListItem>
                                <ListItem
                                    // fontWeight="400"
                                    alignContent={'flex-start'}
                                    // fontSize="0.8rem"
                                    display="flex"
                                    flexDirection="row"
                                    // ml="23px"
                                >
                                    <ListIcon
                                        fontSize="1.1rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="#00D599"
                                    />
                                    <Text textAlign="left">Ruler</Text>
                                </ListItem>
                                <ListItem
                                    // fontWeight="400"
                                    alignContent={'flex-start'}
                                    // fontSize="0.8rem"
                                    display="flex"
                                    flexDirection="row"
                                    // ml="23px"
                                >
                                    <ListIcon
                                        fontSize="1.1rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="#00D599"
                                    />
                                    <Text textAlign="left">
                                        Map zoom and rotate buttons
                                    </Text>
                                </ListItem>
                                <ListItem
                                    // fontWeight="400"
                                    alignContent={'flex-start'}
                                    // fontSize="0.8rem"
                                    display="flex"
                                    flexDirection="row"
                                    // ml="23px"
                                >
                                    <ListIcon
                                        fontSize="1.1rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="#00D599"
                                    />
                                    <Text textAlign="left">
                                        Altitude slider to filter map layers
                                    </Text>
                                </ListItem>
                            </UnorderedList>
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            VNC and Topo 50k base maps, the ruler, and flight
                            levels above 10,000 ft, are only available to{' '}
                            {premiumBadge} users.
                        </p>
                    </div>
                </div>
            ),
            placement: 'left-end',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },

        {
            title: 'Time Slider',
            target: '#timeSlider',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '9' : '8'} / {stepTotal} */}
                            11 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '15px' }}>
                            In the ADVS layer, the Time Slider can be used to
                            filter advisories by time up to 24 hours in to the
                            future.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            Time sliders are used in other components within the
                            app as well.
                        </p>
                    </div>
                </div>
            ),
            placement: 'top-end',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },
        {
            title: 'Admin Dashboard',
            target: '#admin',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '7' : '6'} / {stepTotal} */}
                            12 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '15px' }}>
                            The admin dashboard provides access to your profile
                            and some admin functions, as well as links to
                            external pages on the IFIS website.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            Here you can also find a{' '}
                            <strong>feedback button</strong> to send us any
                            comments and suggestions, or to report any bugs that
                            you may find on this web application.
                        </p>
                    </div>
                </div>
            ),
            placement: 'right-end',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },

        {
            title: 'Time Switch',
            target: isMobile || mobileView ? '#root' : '#timeSwitch',
            content: (
                <div>
                    <Badge
                        position="absolute"
                        top="10px"
                        right={
                            screenMax360
                                ? '40px'
                                : screenMax400
                                ? '60px'
                                : screenMax480
                                ? '85px'
                                : screenMax768
                                ? '128px'
                                : '184px'
                        }
                        display="inline-flex"
                        alignItems="center"
                        p="1px"
                        variant="solid"
                        bg="rgba(0, 188, 248, 1)"
                        borderRadius="10"
                        height="14px"
                        width="14px"
                    >
                        <PremiumIcon color="#FFFFFF" />
                    </Badge>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '7' : '6'} / {stepTotal} */}
                            13 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            A local time switch can be found in the admin
                            dashboard (both mobile and desktop), as well as at
                            the bottom left corner of the screen when viewed on
                            desktop only.
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            This switch allows the time throughout this web
                            application to be toggled from <strong>UTC</strong>{' '}
                            time format to <strong>NZST</strong> or{' '}
                            <strong>NZDT</strong> as applicable.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                paddingBottom: '10px',
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            The local time switch for NZST/NZDT format is only
                            available to {premiumBadge} users.
                        </p>
                        <p
                            style={{
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            All times and date references throughout this web
                            application are otherwise still available in
                            standard UTC format.
                        </p>
                    </div>
                </div>
            ),
            placement: isMobile || mobileView ? 'center' : 'right',
            locale: { skip: 'Cancel' },
            disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },

        {
            title: 'Device Requirements',
            target: '#root',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '10' : '9'} / {stepTotal} */}
                            14 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <Flex
                            justifyContent="center"
                            alignItems="flex-start"
                            display={screenMax400 ? 'none' : 'flex'}
                        >
                            <DevicesIcon
                                color={'light.100'}
                                width="80px"
                                height="80px"
                            />
                        </Flex>

                        <p style={{ paddingBottom: '10px' }}>
                            PreFlight has been designed to make it easier to
                            access and interpret aeronautical and meteorological
                            information. However, because of the amount of
                            information processed and presented, this web
                            application requires newer devices or computers to
                            run smoothly.
                        </p>
                        <p style={{ paddingBottom: '10px' }}>
                            We recommend that you use smart phones, tablets or
                            computers that are approximately{' '}
                            <strong>less than 4 years old</strong>{' '}
                            <em>
                                (this is subject to the graphics hardware of
                                your particular make and model)
                            </em>
                            .
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            Older devices may still run this web application,
                            but you may experience longer loading times or
                            potential loading errors when using older devices or
                            devices with limited graphics performance.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                paddingBottom: '10px',
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            For more information, refer to
                            <br /> <strong>Device Requirements</strong>
                            <br /> under the HELP section in the admin
                            dashboard.
                        </p>
                    </div>
                </div>
            ),
            placement: 'center',
            locale: { last: 'Done' },
            // disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },

        {
            title: 'Enjoy!',
            target: '#root',
            content: (
                <div>
                    <div style={stepBadge}>
                        <p>
                            {/* {isMobile || mobileView ? '10' : '9'} / {stepTotal} */}
                            15 / {stepTotal}
                        </p>
                    </div>
                    <div>
                        <p style={{ paddingBottom: '10px' }}>
                            That's a quick summary of PreFlight's main features.
                        </p>
                        <p style={{ paddingBottom: '15px' }}>
                            Dive in and explore.
                        </p>
                        <hr
                            style={{
                                paddingBottom: '15px',
                                width: '50%',
                                transform: 'translate(50%)',
                                borderColor: '#e5e7e9',
                            }}
                        />
                        <p
                            style={{
                                paddingBottom: '10px',
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            Feel free to send us some feedback using the{' '}
                            <strong>feedback button</strong>!
                        </p>
                        <p
                            style={{
                                paddingRight: '4px',
                                fontStyle: 'italic',
                                opacity: '0.7',
                            }}
                        >
                            All feedback will help us to improve and plan future
                            development of this web application.
                        </p>
                    </div>
                </div>
            ),
            placement: 'center',
            locale: { last: 'Done' },
            // disableOverlayClose: true,
            spotlightPadding: 5,
            hideCloseButton: true,
        },
    ])

    // const [stepIndex, setStepIndex] = useState(0)

    const defaultOptions = {
        arrowColor: '#fff',
        backgroundColor: '#fff',
        primaryColor: 'rgba(6, 208, 150, 1)',
        beaconColor: 'rgba(6, 208, 150, 0.2)',
        textColor: 'light.100',
        overlayColor: 'rgba(0, 0, 0, 0.6)',
        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        beaconSize: 36,
        zIndex: '999999',
    }

    const buttonReset = {
        backgroundColor: 'transparent',
        border: 0,
        borderRadius: 0,
        color: 'light.100',
        outline: 'none',
        fontSize: '0.8rem',
        lineHeight: 1,
        padding: 8,
        WebkitAppearance: 'none',
    }

    const spotlight = {
        borderRadius: 4,
        position: 'absolute',
    }
    const options = deepmerge(defaultOptions, stepStyles.options || {})
    let width = 250
    if (window.innerWidth < 400) {
        width = window.innerWidth - 130
    } else if (window.innerWidth >= 768) {
        width = 490
    } else if (window.innerWidth >= 480) {
        width = 380
    } else if (window.innerWidth >= 400) {
        width = 290
    }

    const overlay = {
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: options.zIndex,
    }

    const defaultStyles = {
        options: {
            textColor: 'light.100',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            width: 900,
            zIndex: 999999,
        },
        beacon: {
            ...buttonReset,
            display: 'inline-block',
            height: options.beaconSize,
            position: 'relative',
            width: options.beaconSize,
            zIndex: '1400',
        },
        beaconInner: {
            animation: 'joyride-beacon-inner 1.2s infinite ease-in-out',
            backgroundColor: options.primaryColor,
            borderRadius: '50%',
            display: 'block',
            height: '40%',
            left: '50%',
            opacity: 0.7,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
        },
        beaconOuter: {
            animation: 'joyride-beacon-outer 1.2s infinite ease-in-out',
            backgroundColor: options.beaconColor,
            border: `1px solid ${options.beaconColor}`,
            borderRadius: '50%',
            boxSizing: 'border-box',
            display: 'block',
            height: '100%',
            left: 0,
            opacity: 0.9,
            position: 'absolute',
            top: 0,
            transformOrigin: 'center',
            width: '100%',
        },
        tooltip: {
            backgroundColor: options.backgroundColor,
            boxShadow: '0 0 25px -7px rgba(0, 0, 0, 0.5)',
            borderRadius: 10,
            boxSizing: 'border-box',
            color: options.textColor,
            fontSize: '0.8rem',
            fontfamily: "'poppins', 'open sans', sans-serif",
            padding: 0,
            position: 'relative',
            width,
        },
        tooltipContainer: {
            textAlign: 'center',
            fontfamily: "'poppins', 'open sans', sans-serif",
            color: '#2A3744',
        },
        tooltipTitle: {
            fontSize: '1rem',
            fontWeight: '600',
            fontfamily: "'poppins', 'open sans', sans-serif",
            color: '#2A3744',
            padding: 15,
            boxShadow: '0px 0px 15px -1px rgba(0,0,0,0.15)',
        },
        tooltipContent: {
            padding: '20px 20px',
            fontfamily: "'poppins', 'open sans', sans-serif",
            borderTop: '1px solid #e5e7e9',
            borderBottom: '1px solid #e5e7e9',
            color: '#2A3744',
            maxHeight: 'calc(100vh - 160px)',
            overflowY: 'auto',
        },
        tooltipFooter: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 0,
            padding: 15,
            paddingTop: 14,
            color: '#2A3744',
        },
        buttonNext: {
            ...buttonReset,
            backgroundColor: options.primaryColor,
            _hover: { backgroundColor: '#333' },
            borderRadius: 10,
            fontSize: '0.8rem',
            height: '30px',
            fontWeight: '600',
            paddingLeft: '15px',
            paddingRight: '15px',
            color: '#fff',
        },
        buttonBack: {
            ...buttonReset,
            color: options.primaryColor,
            marginLeft: 'auto',
            marginRight: 5,
        },
        buttonClose: {
            ...buttonReset,
            color: options.textColor,
            opacity: '0.6',
            height: 14,
            padding: 15,
            position: 'absolute',
            right: 0,
            top: 0,
            width: 14,
        },
        buttonSkip: {
            ...buttonReset,
            color: options.textColor,
            fontSize: '0.8rem',
            opacity: '0.6',
        },
        overlay: {
            ...overlay,
            backgroundColor: options.overlayColor,
            mixBlendMode: 'hard-light',
        },
        overlayLegacy: {
            ...overlay,
        },
        spotlight: {
            ...spotlight,
            backgroundColor: 'gray',
        },
        spotlightLegacy: {
            ...spotlight,
            boxShadow: `0 0 0 9999px ${options.overlayColor}, ${options.spotlightShadow}`,
        },
        floater: {
            arrow: {
                color: options.arrowColor,
                top: '0px',
            },
            tooltip: {
                zIndex: options.zIndex,
            },
        },
    }

    // console.log(stepNumber, 'stepNumber')

    const handleJoyrideCallback = (data) => {
        const {
            status,
            action,
            index,
            // size,
            // step
        } = data

        if (
            (!advsMode || briefingOptionsOpen || briefingStandardOpen) &&
            index === 0 &&
            action === 'start'
        ) {
            setDataMode('advs')
            setMenuExpanded({ advs: false })
            setBriefingOptionsOpen(false)
            setBriefingStandardOpen(false)
            closeLayerSelector()
        }
        if (index < 4) {
            closeLayerSelector()
        }
        if (action === 'prev' && index === 7) {
            // UPDATE THIS IF ADDING A NEW SLIDE BEFORE SLIDE 7
            setMenuExpanded({ advs: false })
        }
        if (action === 'update' && index === 7) {
            // UPDATE THIS IF ADDING A NEW SLIDE BEFORE SLIDE 7
            setMenuExpanded({ advs: true })
            setMapControlsExpanded(true)
        }
        if (action === 'prev' && index === 11) {
            // UPDATE THIS IF ADDING A NEW SLIDE BEFORE SLIDE 11
            setDataMode('advs')
            setMenuExpanded({ advs: true })
            setMapControlsExpanded(true)
        }
        if (action === 'prev' && index === 12) {
            // UPDATE THIS IF ADDING A NEW SLIDE BEFORE SLIDE 12
            setDataMode('advs')
            setMapControlsExpanded(true)
        }
        if (action === 'next' && index === 14) {
            // UPDATE THIS IF ADDING A NEW SLIDE BEFORE THE END
            setDataMode('aero')
            setMapControlsExpanded(false)
        }
        if (action === 'reset' && index === 0) {
            setDataMode('aero')
            setMenuExpanded({ aero: false })
            closeLayerSelector()
            setMapControlsExpanded(false)

            // setTimeout(() => {
            //     console.log('2')
            //     // setStartTour(false)
            // })
            // // setStartTour(false)
            // console.log('1')
            // setTimeout(() => {
            //     console.log('3')
            //     // setStartTour(true)
            //     // setMenuExpanded(false)
            // }, 5000)

            // } else if (action === 'update' && index === 4 && dataMode !== 'advs') {
            //     setDataMode('advs')
            // } else if (action === 'update' && index === 6) {
            //     closeLayerSelector()
            // } else {
            // setDataMode('aero')
            // } else {
            // closeLayerSelector()
        }

        status === 'finished' &&
            // setDataMode('aero') &&
            // setMenuExpanded({ aero: false }) &&
            setTourVisibility(false) &&
            setStartTour(false) &&
            setTourCompleted(true) &&
            setFirstTimeLoggedIn(false) &&
            setSteps(0) &&
            console.log('Intro tour finished')

        status === 'skipped' &&
            setTourVisibility(false) &&
            setStartTour(false) &&
            setTourCompleted(true) &&
            setFirstTimeLoggedIn(false) &&
            setSteps(0) &&
            console.log('Intro tour skipped')

        action === 'close' &&
            setTourVisibility(false) &&
            setStartTour(false) &&
            setTourCompleted(true) &&
            setFirstTimeLoggedIn(false) &&
            setSteps(0) &&
            console.log('Intro tour closed')

        // console.log(data, 'data')
        // console.log(index, 'step')
        // console.log(step, 'step')
        // console.log(status, 'status')
        // console.log(size, 'size')

        // if (status === 'finished' || 'skipped') {
        //     // Sets running state to false, so we can restart if we click start again.
        //     setSteps(0)
        //     setTourVisibility(false)
        // } else {
        //     // Update state to advance the tour
        //     setSteps(index)
        // }

        // if (dataMode === 'met' && index === 0) {
        //     setTimeout(() => {
        //       this.setState({ run: true });
        //     }, 400);
        //   } else if (sidebarOpen && index === 1) {
        //     this.setState(
        //       {
        //         run: false,
        //         sidebarOpen: false,
        //         stepIndex,
        //       },
        //       () => {
        //         setTimeout(() => {
        //           this.setState({ run: true });
        //         }, 400);
        //       },
        //     );
        //   } else if (index === 2 && action === ACTIONS.PREV) {
        //     this.setState(
        //       {
        //         run: false,
        //         sidebarOpen: true,
        //         stepIndex,
        //       },
        //       () => {
        //         setTimeout(() => {
        //           this.setState({ run: true });
        //         }, 400);
        //       },
        //     );
        //   } else {
        //     // Update state to advance the tour
        //     this.setState({
        //       sidebarOpen: false,
        //       stepIndex,
        //     });
        //   }
    }

    return (
        <Joyride
            styles={defaultStyles}
            steps={steps}
            continuous
            run={startTour}
            showSkipButton
            callback={handleJoyrideCallback}
            // stepIndex={stepIndex}
            // showProgress
        />
    )
}
