import React from 'react'
import {
    Flex,
    Text,
    useBreakpointValue,
    Grid,
    GridItem,
} from '@chakra-ui/react'

export default function RainRadarLegend(props) {
    const { legendSwipe } = props

    const rotation = useBreakpointValue({
        base: '90',
        lg: '0',
    })

    // MOBILE RADAR INFO SHOW BY SWIPE DOWN
    return (
        <>
            <Flex
                {...legendSwipe}
                className="reflectivity-scale"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column', lg: 'row' }}
                gap={{ base: '4px', lg: '10px' }}
                pr={{ base: '1px', lg: '0px' }}
            >
                <Flex // TOP/LEFT TEXT
                    transition="all ease 300ms"
                    width={{
                        base: '100%',
                        lg: '25px',
                    }}
                    height={{ base: '10px', lg: '100%' }}
                    justifyContent="space-between"
                    alignItems={{ base: 'center', lg: 'flex-end' }}
                    mt={{ base: '-2px', lg: '0px' }}
                    flexDirection={{ base: 'row-reverse', lg: 'column' }}
                    fontSize={{ base: '8px', lg: '0.65rem' }}
                    lineHeight={{ base: '1', lg: '0.9' }}
                    textAlign={{ base: 'center', lg: 'right' }}
                    color="light.100"
                >
                    <Text
                        ml={{ base: '-12px', lg: '0px' }}
                        mb={{ base: '0px', lg: '-4px' }}
                    >
                        {'> 72'}
                    </Text>
                    <Text>60</Text>
                    <Text>55</Text>
                    <Text>50</Text>
                    <Text>45</Text>
                    <Text>40</Text>
                    <Text>30</Text>
                    <Text>20</Text>
                    <Text>16</Text>
                    <Text
                        mt={{ base: '0px', lg: '-3px' }}
                        mb={{ base: '0px', lg: '-1px' }}
                    >
                        0
                    </Text>
                </Flex>

                {/* *** COLOURED BOXES *** */}
                <Flex
                    transition="all ease 300ms"
                    width={{ base: '100%', lg: '9px' }}
                    height={{ base: '7px', lg: '100%' }}
                    justifyContent="center"
                    alignItems="center"
                    borderRadius={{ base: '7px', lg: '7px' }}
                    overflow="hidden"
                    flexDirection={{ base: 'row-reverse', lg: 'column' }}
                >
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #E700E7 0%, #E700E7 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #710071 0%, #E700E7 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #01FF00 0%, #01FF00 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #006d00 0%, #01FF00 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #ffffff 0%, #ffffff 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #c2c2c2 0%, #ffffff 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #9632FF 0%, #9632FF 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #571b8e 0%, #9632FF 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #ff0000 0%, #ff0000 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #650000 0%, #ff0000 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #01FFFF 0%, #01FFFF 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #006E9D 0%, #01FFFF 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #0050FF 0%, #0060B0 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #0033A5 0%, #0050FF 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #FF8700 0%, #FF8700 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #FFFB00 0%, #FF8700 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #a5a400 0%, #FFFB00 100%)`}
                    ></Flex>
                    <Flex
                        width="100%"
                        height="100%"
                        backgroundImage={`linear-gradient(${rotation}deg, #565600 0%, #a5a400 100%)`}
                    ></Flex>
                </Flex>

                <Flex // ALTERNATIVE TEXT
                    width={{ base: '100%', lg: '80px' }}
                    height={{ base: '15px', lg: '100%' }}
                    justifyContent="space-around"
                    alignItems={{ base: 'center', lg: 'flex-end' }}
                    flexDirection={{ base: 'row', lg: 'column' }}
                    fontSize={{ base: '0.6rem', md: '0.7rem' }}
                    fontWeight="400"
                    lineHeight={{ base: '1', lg: '0.9' }}
                    color="light.100"
                    mt={{ base: '1px', lg: '1vh', xl: '1vh' }}
                    mr={{ base: '0px', lg: '-50px' }}
                >
                    <Grid
                        alignItems={{ base: 'flex-start', lg: 'flex-start' }}
                        templateColumns={{
                            base: 'repeat(18, 1fr)',
                            lg: 'unset',
                        }}
                        templateRows={{ base: 'unset', lg: 'repeat(18, 1fr)' }}
                        gap={0}
                        width="100%"
                        height="100%"
                    >
                        <GridItem
                            gridRow={{ base: 'unset', lg: '18' }}
                            colSpan={{ base: 4, lg: 1 }}
                            w="100%"
                        >
                            Light
                        </GridItem>
                        <GridItem
                            gridRow={{ base: 'unset', lg: '14' }}
                            colSpan={{ base: 4, lg: 1 }}
                            w="100%"
                        >
                            Moderate
                        </GridItem>
                        <GridItem
                            gridRow={{ base: 'unset', lg: '10' }}
                            colSpan={{ base: 4, lg: 1 }}
                            w="100%"
                        >
                            Heavy
                        </GridItem>
                        <GridItem
                            gridRow={{ base: 'unset', lg: '6' }}
                            colSpan={{ base: 6, lg: 1 }}
                            w="100%"
                        >
                            Hail possible
                        </GridItem>
                    </Grid>
                </Flex>
            </Flex>
        </>
    )
}
