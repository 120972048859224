// This file subscribes users to the users channel on load to track how many users are online at any one time

import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { usePubNub } from 'pubnub-react'
import { showOnlineUsersButtonState } from '../globalState'

export default function SubscribeToUsers() {
    const updateOtherUserOnlineStatus = useRecoilValue(
        showOnlineUsersButtonState
    )
    const pubnub = usePubNub()

    useEffect(() => {
        const channels = ['users']
        const subscription = {
            channels: channels,
            withPresence: updateOtherUserOnlineStatus ? true : false,
        }
        pubnub.subscribe(subscription)

        return () => pubnub.unsubscribe(subscription)
    }, [pubnub, updateOtherUserOnlineStatus])

    return null
}
