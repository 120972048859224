import { useRef, useEffect, useContext, useCallback, useState } from 'react'
import { MapContext } from 'react-mapbox-gl'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    clickedFeaturesState,
    clickingState,
    dataModeState,
    selectDefaultMapLocationState,
} from '../../globalState'

const clickableLayers = [
    'aerodromes-symbol',
    'webcams-symbol',
    'aws-symbol',
    'notam-fill',
    'notam-line',
    'notam-symbol',
    'rpas-fill',
    'rpas-line',
    'rpas-symbol',
    'sigmet-fill',
    'sigmet-line',
    'sigmet-symbol',
    'sigwx-fill',
    'sigwx-line',
    'sigwx-symbol',
    'sigwx-symbol-static',
    'lightning-symbol',
    'lightning-animated-symbol',
    'grafor-fill',
    'grafor-symbol',
    'aaw-fill',
    'aaw-symbol',
]
export default function MapClickHandler() {
    const setClickedFeatures = useSetRecoilState(clickedFeaturesState)
    const clicking = useRecoilValue(clickingState)
    const dataMode = useRecoilValue(dataModeState)
    const [layers, setLayers] = useState(clickableLayers)
    const selectDefaultMapLocation = useRecoilValue(
        selectDefaultMapLocationState
    )

    useEffect(() => {
        // TODO Refactor - when in Brief mode, or when selecting default map location in Profile settings, set clicking to false.
        setLayers(
            dataMode === 'brief' || selectDefaultMapLocation
                ? []
                : clickableLayers
        )
    }, [dataMode, selectDefaultMapLocation])

    const mapInstance = useContext(MapContext)

    const filterClickableLayers = useCallback(() => {
        const mapLayers = mapInstance.getStyle().layers.map((layer) => layer.id)
        return layers.filter((item) => mapLayers.includes(item))
    }, [mapInstance, layers])

    const lastTap = useRef(0)

    const handleTouchStart = () => {
        lastTap.current = Date.now()
    }

    const handleTouchMove = () => {
        lastTap.current = 0
    }

    const handleMouseMove = useCallback(
        (e) => {
            const zoomLevel = e.target.getZoom()

            const hoveredFeatures = mapInstance.queryRenderedFeatures(e.point, {
                layers: filterClickableLayers(),
            })

            const filteredFeatures = hoveredFeatures.filter((feat) => {
                if (feat.layer.id === 'aerodromes-symbol') {
                    if (
                        zoomLevel < 6 &&
                        feat.properties.flightrule === 'IFR' &&
                        feat.properties.type === 'AD'
                    ) {
                        return feat
                    }
                    if (
                        zoomLevel >= 6 &&
                        zoomLevel < 8 &&
                        feat.properties.type === 'AD'
                    ) {
                        return feat
                    }
                    if (zoomLevel >= 8) {
                        return feat
                    }
                }
                if (feat.layer.id !== 'aerodromes-symbol') {
                    return feat
                }
                return null
            })

            filteredFeatures.length > 0
                ? (mapInstance.getCanvas().style.cursor = 'pointer')
                : (mapInstance.getCanvas().style.cursor = '')
        },
        [filterClickableLayers, mapInstance]
    )

    const handleClick = useCallback(
        (e) => {
            if (clicking) {
                const zoomLevel = e.target.getZoom()

                const clickedFeatures = mapInstance.queryRenderedFeatures(
                    e.point,
                    {
                        layers: filterClickableLayers(),
                    }
                )

                const filteredFeatures = clickedFeatures.filter((feat) => {
                    if (
                        feat.layer.id === 'aerodromes-symbol' &&
                        clickedFeatures.length === 1
                    ) {
                        if (
                            zoomLevel < 6 &&
                            feat.properties.flightrule === 'IFR' &&
                            feat.properties.type === 'AD'
                        ) {
                            return feat
                        }
                        if (
                            zoomLevel >= 6 &&
                            zoomLevel < 8 &&
                            feat.properties.type === 'AD'
                        ) {
                            return feat
                        }
                        if (zoomLevel >= 8) {
                            return feat
                        }
                    }
                    if (
                        feat.layer.id === 'aerodromes-symbol' &&
                        clickedFeatures.length > 1
                    ) {
                        return feat
                    }
                    if (feat.layer.id !== 'aerodromes-symbol') {
                        return feat
                    }
                    return null
                })

                setClickedFeatures(
                    filteredFeatures
                        .map((feat) => {
                            return {
                                layer: feat.layer.id.replace(
                                    /(-fill$)?(-line$)?(-symbol$)?/g,
                                    ''
                                ),
                                properties: feat.properties,
                                geometry: feat.geometry,
                            }
                        })
                        .filter(
                            (feat, index, self) =>
                                self.findIndex(
                                    (f) =>
                                        f.layer === feat.layer &&
                                        f.properties.id === feat.properties.id
                                ) === index
                        )
                )
            }
        },
        [clicking, filterClickableLayers, mapInstance, setClickedFeatures]
    )

    const handleTouchEnd = useCallback(
        (e) => {
            if (clicking && Date.now() - lastTap.current <= 300) {
                const clickedFeatures = mapInstance.queryRenderedFeatures(
                    e.point,
                    {
                        layers: filterClickableLayers(),
                    }
                )
                setClickedFeatures(
                    clickedFeatures
                        .map((feat) => {
                            return {
                                layer: feat.layer.id.replace(
                                    /(-fill$)?(-line$)?(-symbol$)?/g,
                                    ''
                                ),
                                properties: feat.properties,
                                geometry: feat.geometry,
                            }
                        })
                        .filter(
                            (feat, index, self) =>
                                self.findIndex(
                                    (f) =>
                                        f.layer === feat.layer &&
                                        f.properties.id === feat.properties.id
                                ) === index
                        )
                )
            }
        },
        [clicking, filterClickableLayers, mapInstance, setClickedFeatures]
    )

    useEffect(() => {
        mapInstance.on('touchstart', handleTouchStart)
        mapInstance.on('touchmove', handleTouchMove)
        mapInstance.on('mousemove', handleMouseMove)
        mapInstance.on('click', handleClick)
        mapInstance.on('touchend', handleTouchEnd)
        return () => {
            mapInstance.off('touchstart', handleTouchStart)
            mapInstance.off('touchmove', handleTouchMove)
            mapInstance.off('mousemove', handleMouseMove)
            mapInstance.off('click', handleClick)
            mapInstance.off('touchend', handleTouchEnd)
        }
    }, [clicking, mapInstance, handleClick, handleMouseMove, handleTouchEnd])

    return null
}
