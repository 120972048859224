import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function LayerErrorIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            boxSize={boxSize || 7}
            color={color || colorHandler()}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.55041 3.36563C6.24643 2.98997 5.69546 2.93186 5.3198 3.23584C4.94413 3.53983 4.88603 4.09079 5.19001 4.46646L6.78632 6.43919L2.64924 8.25325C2.54239 8.3001 2.49379 8.40196 2.50063 8.50001C2.49379 8.59806 2.54239 8.69992 2.64924 8.74677L11.5021 12.6286C11.6427 12.6903 11.767 12.7284 11.8874 12.7431L12.9292 14.0305L12.2131 14.3445C12.0929 14.3972 11.9062 14.3972 11.786 14.3445L5.03525 11.3844C4.67406 11.226 4.41297 11.2073 4.04036 11.3299L2.67144 11.7801C2.45418 11.8515 2.43972 12.1549 2.64924 12.2468L11.5021 16.1286C11.8712 16.2905 12.1279 16.2905 12.497 16.1286L14.0691 15.4393L15.0196 16.6139L12.2131 17.8445C12.0929 17.8972 11.9062 17.8972 11.786 17.8445L5.03525 14.8844C4.67406 14.726 4.41297 14.7073 4.04036 14.8299L2.67144 15.2801C2.45418 15.3515 2.43972 15.6549 2.64924 15.7468L11.5021 19.6286C11.8712 19.7905 12.1279 19.7905 12.497 19.6286L16.1595 18.0227L17.8351 20.0933C18.1391 20.469 18.6901 20.5271 19.0657 20.2231C19.4414 19.9192 19.4995 19.3682 19.1955 18.9925L17.8211 17.2941L21.3516 15.746C21.5611 15.6541 21.5449 15.3515 21.3276 15.2801L19.9587 14.8299C19.5861 14.7073 19.325 14.726 18.9638 14.8844L16.6812 15.8853L15.7307 14.7107L21.3516 12.246C21.5611 12.1541 21.5449 11.8515 21.3276 11.7801L19.9587 11.3299C19.5861 11.2073 19.325 11.226 18.9638 11.3844L14.5908 13.3019L13.6403 12.1273L21.3516 8.74601C21.4583 8.69921 21.5064 8.59775 21.4993 8.50001C21.5064 8.40227 21.4583 8.3008 21.3516 8.25401L12.497 4.37138C12.1279 4.20955 11.8712 4.20955 11.5021 4.37138L8.44793 5.71059L6.55041 3.36563ZM8.44793 5.71059L6.78632 6.43919L11.8874 12.7431C12.0831 12.767 12.2685 12.7288 12.497 12.6286L13.6403 12.1273L8.44793 5.71059ZM14.5908 13.3019L12.9292 14.0305L14.0691 15.4393L15.7307 14.7107L14.5908 13.3019ZM16.6812 15.8853L15.0196 16.6139L16.1595 18.0227L17.8211 17.2941L16.6812 15.8853Z"
                fill="currentColor"
            />
        </Icon>
    )
}
