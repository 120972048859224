import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Divider,
    ModalCloseButton,
    Link,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { ReactComponent as SunriseIcon } from '../../../../../icons/assets/sunrise.svg'
import { ReactComponent as SunsetIcon } from '../../../../../icons/assets/sunset.svg'
import { ReactComponent as CautionIcon } from '../../../../../icons/assets/caution-question.svg'
import {
    setModalVisibilityState,
    modalVisibilityState,
    usingUsnoDataState,
} from '../../../../../globalState'

export default function MctEctDisclaimer() {
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const usingUsnoData = useRecoilValue(usingUsnoDataState)

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'mctEctDisclaimer', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.mctEctDisclaimer ? onOpen() : onClose()
    }, [modalVisibility.mctEctDisclaimer, onOpen, onClose])

    function onCloseHandler() {
        setShow(false)
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={'alertMessage'}
                    size="6xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    isCentered
                    trapFocus={false}
                    motionPreset="slideInBottom"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton
                            zIndex="2"
                            color="gray.400"
                            mt="-3px"
                        />
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="gray.500"
                            pr="30px"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                            textTransform="uppercase"
                            fontSize={{
                                base: '0.85rem',
                                xs: '0.95rem',
                                sm: '1.1rem',
                            }}
                        >
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                gap={{
                                    base: '5px',
                                    sm: '7px',
                                }}
                            >
                                <CautionIcon width="28px" height="28px" />
                                About Civil Twilight Times
                            </Flex>
                        </ModalHeader>
                        <ModalBody
                            style={{ padding: 0 }}
                            fontSize="0.85rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight="75vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt={10}
                                pb={12}
                                alignItems="baseline"
                                flexDirection="column"
                                gap="30px"
                            >
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.8rem',
                                    }}
                                    color="light.100"
                                    mb="5px"
                                    pt="5px"
                                    pb="20px"
                                    px={{
                                        base: '15px',
                                        md: '30px',
                                    }}
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.40"
                                >
                                    <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                        gap="7px"
                                        mb="-2px"
                                    >
                                        <SunriseIcon
                                            width="44px"
                                            height="44px"
                                            filter="saturate(1.5)"
                                        />
                                        <Text
                                            pt="5px"
                                            textAlign="center"
                                            fontWeight="700"
                                        >
                                            Morning Civil Twilight (MCT)
                                        </Text>
                                    </Flex>

                                    <Text
                                        width="100%"
                                        textAlign="center"
                                        mb="12px"
                                    >
                                        This is the period before sunrise when
                                        the sky begins to lighten but the sun is
                                        still below the earth's horizon. It
                                        starts when the centre of the sun's disc
                                        is 6 degrees below the horizon and ends
                                        at sunrise.
                                    </Text>
                                    <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                        gap="7px"
                                        mb="-2px"
                                        pr="5px"
                                    >
                                        <SunsetIcon
                                            width="44px"
                                            height="44px"
                                            filter="saturate(1.5)"
                                        />
                                        <Text
                                            pt="5px"
                                            textAlign="center"
                                            fontWeight="700"
                                        >
                                            Evening Civil Twilight (ECT)
                                        </Text>
                                    </Flex>
                                    <Text width="100%" textAlign="center">
                                        This is the period following sunset when
                                        the sky remains illuminated even though
                                        the sun has dipped below the earth's
                                        horizon. It starts at sunset and ends
                                        when the centre of the sun's disc is 6
                                        degrees below the horizon.
                                    </Text>
                                </Flex>

                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    align={'center'}
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.85rem',
                                    }}
                                    color="light.100"
                                    gap="10px"
                                    pb="5px"
                                >
                                    <Text
                                        width="100%"
                                        textAlign="center"
                                        fontWeight="500"
                                        letterSpacing="0.2px"
                                        fontSize={{
                                            base: '0.9rem',
                                            xs: '0.95rem',
                                            sm: '1rem',
                                            md: '1.1rem',
                                        }}
                                        color="gray.500"
                                        mb="-5px"
                                    >
                                        Source of civil twilight data
                                    </Text>
                                    <Divider opacity="1" />

                                    <Text
                                        pt="10px"
                                        pb="15px"
                                        width="100%"
                                        fontWeight="600"
                                        textAlign="center"
                                    >
                                        MCT and ECT times are derived from the
                                        following data sources:
                                    </Text>
                                    <Link
                                        fontSize={{
                                            base: '0.85rem',
                                            md: '0.9rem',
                                        }}
                                        fontWeight="500"
                                        textAlign="center"
                                        color="light.200"
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://aa.usno.navy.mil/index"
                                    >
                                        Astronomical Applications Department of
                                        the U.S. Naval Observatory (USNO)
                                    </Link>
                                    <Text width="100%" textAlign="center">
                                        PreFlight utilises civil twilight data
                                        from the{' '}
                                        <span
                                            style={{
                                                fontWeight: '600',
                                            }}
                                        >
                                            USNO
                                        </span>
                                        . This data service provides a precise
                                        method for calculating the beginning and
                                        end of civil twilight times specific to
                                        the current date, geographic location
                                        and elevation of an aerodrome.
                                    </Text>

                                    <Link
                                        pt="15px"
                                        fontSize={{
                                            base: '0.85rem',
                                            md: '0.9rem',
                                        }}
                                        fontWeight="500"
                                        textAlign="center"
                                        color="light.200"
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.aip.net.nz/assets/AIP/General-GEN/2-TABLES-AND-CODES/GEN_2.7.pdf"
                                    >
                                        AIP Gen 2.7 – Daylight Tables
                                    </Link>
                                    <Text width="100%" textAlign="center">
                                        In the unlikely event of a USNO data
                                        retrieval error, civil twilight times
                                        are alternatively sourced from the{' '}
                                        <span
                                            style={{
                                                fontWeight: '600',
                                            }}
                                        >
                                            AIP Daylight Tables
                                        </span>
                                        .
                                    </Text>
                                    <Text width="100%" textAlign="center">
                                        The Daylight Table times are derived by
                                        interpolating between two columns, which
                                        represent the range of times for the
                                        current date and geographic location of
                                        an aerodrome. The current date falls
                                        between these two columns, and while
                                        this method is effective, it provides
                                        less precision than USNO data.
                                    </Text>
                                </Flex>
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    align={'center'}
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.85rem',
                                    }}
                                    color="light.100"
                                    gap="10px"
                                    pb="15px"
                                >
                                    <Text
                                        pt="10px"
                                        width="100%"
                                        textAlign="center"
                                        fontWeight="500"
                                        letterSpacing="0.2px"
                                        fontSize={{
                                            base: '0.9rem',
                                            xs: '0.95rem',
                                            sm: '1rem',
                                            md: '1.1rem',
                                        }}
                                        color="gray.500"
                                        mb="-5px"
                                    >
                                        Comparison of both civil twilight data
                                        sources
                                    </Text>
                                    <Divider opacity="1" />
                                    <Text
                                        pt="10px"
                                        width="100%"
                                        textAlign="center"
                                    >
                                        For any given aerodrome, precise civil
                                        twilight times derived from the USNO are
                                        likely to differ slightly from those in
                                        the AIP Daylight Tables, as the tables
                                        are generalised to the most conservative
                                        value in the applicable daylight zone,
                                        and do not account for precise
                                        geographic location or elevation.
                                    </Text>
                                    <Text width="100%" textAlign="center">
                                        Actual{' '}
                                        <span
                                            style={{
                                                fontWeight: '700',
                                            }}
                                        >
                                            MCT
                                        </span>{' '}
                                        times will generally be{' '}
                                        <span
                                            style={{
                                                fontWeight: '600',
                                            }}
                                        >
                                            within a few minutes of, or later
                                            than, the{' '}
                                            <span
                                                style={{
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                earliest
                                            </span>
                                        </span>{' '}
                                        interpolated time between the two
                                        applicable daylight table columns.
                                    </Text>
                                    <Text width="100%" textAlign="center">
                                        Actual{' '}
                                        <span
                                            style={{
                                                fontWeight: '700',
                                            }}
                                        >
                                            ECT
                                        </span>{' '}
                                        times will generally be{' '}
                                        <span
                                            style={{
                                                fontWeight: '600',
                                            }}
                                        >
                                            within a few minutes of, or earlier
                                            than, the{' '}
                                            <span
                                                style={{
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                latest
                                            </span>
                                        </span>{' '}
                                        interpolated time between the two
                                        applicable daylight table columns.
                                    </Text>
                                </Flex>
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    fontSize="0.8rem"
                                    color="light.100"
                                    gap="10px"
                                    mb="5px"
                                    pt="15px"
                                    pb="17px"
                                    px={{
                                        base: '15px',
                                        md: '30px',
                                    }}
                                    border="1px solid"
                                    borderColor="gray.200"
                                    background="light.30"
                                >
                                    {usingUsnoData ? (
                                        <Flex
                                            flexDirection={{
                                                base: 'column',
                                                md: 'row',
                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                            textAlign="center"
                                            width="100%"
                                            gap={{
                                                base: '5px',
                                                md: '10px',
                                            }}
                                        >
                                            <Text fontWeight="700">
                                                STATUS:
                                            </Text>
                                            <Text>
                                                Current MCT and ECT times shown
                                                are derived from the{' '}
                                                <span
                                                    style={{
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    USNO
                                                </span>
                                            </Text>
                                        </Flex>
                                    ) : (
                                        <>
                                            <Flex
                                                flexDirection={{
                                                    base: 'column',
                                                    md: 'row',
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                                textAlign="center"
                                                width="100%"
                                                gap={{
                                                    base: '5px',
                                                    md: '10px',
                                                }}
                                            >
                                                <Text fontWeight="700">
                                                    STATUS:
                                                </Text>
                                                <Text>
                                                    Current MCT and ECT times
                                                    shown are interpolated from
                                                    the{' '}
                                                    <span
                                                        style={{
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        AIP Daylight Tables
                                                    </span>
                                                    .
                                                </Text>
                                            </Flex>
                                            <Text>
                                                USNO data is currently
                                                unavailable.
                                            </Text>
                                        </>
                                    )}
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="gray.200"
                                    color="light.100"
                                    _hover={{ background: 'gray.300' }}
                                    _focus={{ background: 'gray.300' }}
                                    _active={{ background: 'gray.300' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
