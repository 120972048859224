import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Worker } from '@react-pdf-viewer/core'

import { QueryClient, QueryClientProvider } from 'react-query'
import { RecoilRoot } from 'recoil'
import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import theme from './theme'
import './theme/styles.css'

import Services from './services'
import LandingPage from './landing-page'
import Terms from './pages/Terms'
import ManageSubscription from './pages/ManageSubscription'
import App from './App'
import reportWebVitals from './init/reportWebVitals'
import Auth0ProviderWithHistory from './auth'
import PubnubClientProvider from './pubnub/PubNubClientProvider'
import ConfirmContextProvider from './map/modals/alerts/warning-message/components/ConfirmContextProvider'

import './index.css'
import 'overlayscrollbars/styles/overlayscrollbars.css' // CSS for overlayscrollbars-react

// only load Sentry on the Prod Build
if (JSON.parse(process.env.REACT_APP_DEV_ENV.toLowerCase())) {
    Sentry.init({
        dsn: JSON.parse(process.env.REACT_APP_DEV_ENV.toLowerCase())
            ? process.env.REACT_APP_DEV_SENTRY_DSN
            : process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1, // set to 0.1 or 10%
    })
}

const queryClient = new QueryClient()

const container = document.getElementById('root')

const root = createRoot(container)

root.render(
    <Router>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <Auth0ProviderWithHistory>
                <QueryClientProvider client={queryClient}>
                    <ConfirmContextProvider>
                        <RecoilRoot>
                            <ChakraProvider theme={theme}>
                                <Routes>
                                    <Route
                                        exact
                                        path="/"
                                        element={<LandingPage />}
                                    />
                                    <Route
                                        path="/app"
                                        element={
                                            <PubnubClientProvider>
                                                <Services />
                                                <App />
                                            </PubnubClientProvider>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/terms-and-conditions"
                                        element={<Terms />}
                                    />
                                    <Route
                                        exact
                                        path="/manage-subscription"
                                        element={<ManageSubscription />}
                                    />
                                </Routes>
                            </ChakraProvider>
                        </RecoilRoot>
                    </ConfirmContextProvider>
                </QueryClientProvider>
            </Auth0ProviderWithHistory>
        </Worker>
    </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
