import { Box, Img, Text, Container, Flex, Divider } from '@chakra-ui/react'
import * as React from 'react'

export const ItemDetail = (props) => {
    const { title, subTitle, description, footer, imageSrc, ...boxProps } =
        props
    return (
        <Container
            maxW={'7xl'}
            bg="none"
            paddingInlineStart="0"
            paddingInlineEnd="0"
        >
            <Box as="section">
                <Box
                    maxW="6xl"
                    mx="auto"
                    px={{
                        base: '6',
                        md: '8',
                    }}
                    pt="15px"
                >
                    <Flex
                        direction="column"
                        align="center"
                        textAlign="center"
                        fontSize="0.8rem"
                    >
                        <Box {...boxProps} pt="10px">
                            <Flex
                                width="100%"
                                px="4"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Img
                                    src={imageSrc}
                                    mx="auto"
                                    objectFit="cover"
                                    alt=""
                                    fit={'cover'}
                                    align={'center'}
                                    maxWidth="600px"
                                    w={'100%'}
                                    h={'100%'}
                                    boxShadow="0px 8px 40px -15px rgba(0,0,0,0.5)"
                                />
                            </Flex>
                            <Text
                                fontStyle="normal"
                                fontWeight="600"
                                color="light.100"
                                mt="8"
                                fontSize={{
                                    base: '1rem',
                                    md: '1.1rem',
                                }}
                            >
                                {title}
                            </Text>
                            {subTitle && (
                                <Text
                                    pt="5px"
                                    fontSize={{
                                        base: '0.9rem',
                                        md: '1rem',
                                    }}
                                    color="#687686"
                                    fontWeight="300"
                                >
                                    {subTitle}
                                </Text>
                            )}
                            <Divider mt="4" mb="4" />
                            {description && (
                                <Text
                                    fontSize={{
                                        base: '0.85rem',
                                        md: '0.9rem',
                                    }}
                                    color="#687686"
                                    fontWeight="300"
                                >
                                    {description}
                                </Text>
                            )}
                            {footer && (
                                <Text
                                    fontStyle="italic"
                                    pt="10px"
                                    fontSize={{
                                        base: '0.8rem',
                                        md: '0.85rem',
                                    }}
                                    fontWeight="300"
                                    opacity="0.7"
                                    color="gray.500"
                                >
                                    {footer}
                                </Text>
                            )}
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </Container>
    )
}
