import * as turf from '@turf/turf'
import * as polylabel from '@mapbox/polylabel'
import * as shift from 'turf-shift-longitude'
import _ from 'lodash'

const getMapViewBounds = (mapInstance) => {
    const bounds = mapInstance.getBounds()
    const mapSW = bounds._sw
    const mapNE = bounds._ne

    return [mapSW.lng, mapSW.lat, mapNE.lng, mapNE.lat]
}

const toSinglePoly = (feature) => {
    if (turf.getType(feature) === 'MultiPolygon') {
        var area = 0,
            id = 0
        _.forEach(feature.geometry.coordinates, function (value, key) {
            var _area = turf.area(turf.polygon(value))
            if (_area > area) {
                area = _area
                id = key
            }
        })
        feature = turf.polygon(feature.geometry.coordinates[id])
    }
    return feature
}

export default function calcSymbolPlacement(
    mapInstance,
    sourceLayer,
    targetLayer,
    uid
) {
    const renderedFeatures = mapInstance.queryRenderedFeatures({
        layers: [sourceLayer],
    })

    // queryRenderedFeatures returns arrays and nulls as strings. This converts them back into true arrays and nulls.
    const features = renderedFeatures.map((feat) => {
        const newFeat = { ...feat }

        Object.keys(feat.properties).map((key) => {
            const prop = feat.properties[key]
            if (
                (typeof prop === 'string' &&
                    prop.substring(0, 1) === '[' &&
                    prop.slice(prop.length - 1) === ']') ||
                prop === 'null'
            ) {
                return (newFeat.properties[key] = JSON.parse(prop))
            }
            return null
        })

        newFeat.geometry = feat.geometry

        return newFeat
    })

    const polygons = features
        .map((feat) => {
            const geomType = turf.getType(feat)
            if (geomType === 'Polygon' || geomType === 'MultiPolygon') {
                if (
                    feat.geometry.coordinates[0].length < 4 &&
                    mapInstance.getZoom() > 8
                ) {
                    console.log(
                        'Bad feature geometry being passed through dynamic symbol generator'
                    )
                    console.log('Discarding feature:')
                    // console.log(feat)
                }
                if (feat.geometry.coordinates[0].length > 4) {
                    return feat
                }
            }
            return null
        })
        .filter((feat) => feat)

    const groupedPolygons = polygons.reduce((arr, feat) => {
        arr[feat.properties[uid]] = arr[feat.properties[uid]] || []
        arr[feat.properties[uid]].push(feat)
        return arr
    }, Object.create(null))

    var union = turf.featureCollection()

    const labelFeatures = Object.keys(groupedPolygons).map((uid) => {
        try {
            union = turf.buffer(
                turf.combine(turf.featureCollection(groupedPolygons[uid])),
                0
            )
        } catch (err) {
            console.log(
                'Error with dynamic symbol - attempting to fix via longitude shifting'
            )
            try {
                union = turf.buffer(
                    shift(
                        turf.combine(
                            turf.featureCollection(groupedPolygons[uid])
                        )
                    ),
                    0
                )
            } catch (err) {
                console.log(
                    'Error with dynamic symbol - longitude shifting didnt work'
                )
            }
        }

        if (
            !union ||
            !union.features ||
            (union && union.features && union.features.length < 1)
        ) {
            return []
        }
        union = shift(toSinglePoly(union.features[0]))

        const bbox = getMapViewBounds(mapInstance)

        var clipped = union
        if (turf.booleanOverlap(union, turf.bboxPolygon(bbox))) {
            clipped = turf.bboxClip(union, bbox)
            clipped = toSinglePoly(clipped)
        }

        const polyLabelPos = polylabel(clipped.geometry.coordinates, 0.001)

        const pointOnFeat = turf.pointOnFeature(clipped)

        const polyLabelContained = turf.booleanContains(
            clipped,
            turf.point(polyLabelPos)
        )

        const pointOnFeatContained = turf.booleanContains(clipped, pointOnFeat)

        if (!polyLabelContained) {
            console.log('PolyLabel Not Contained')
        }

        if (!pointOnFeatContained) {
            console.log('Point on Feat Not Contained')
        }

        var exportFeature = groupedPolygons[uid].length > 0 &&
            polyLabelPos && {
                type: 'Feature',
                properties: groupedPolygons[uid][0].properties,
                geometry: {
                    type: 'Point',
                    coordinates: polyLabelContained
                        ? polyLabelPos
                        : pointOnFeatContained
                        ? // temp fix - improve this later - don't biff off coords to 0,0
                          pointOnFeat.geometry.coordinates
                        : [0, 0],
                },
            }

        return exportFeature
    })

    //This error kept periodically showing - this functions as a null check.
    mapInstance.getLayer(targetLayer) &&
        mapInstance
            .getSource(mapInstance.getLayer(targetLayer).source)
            .setData(turf.featureCollection(labelFeatures))
}
