import React from 'react'
import { CircularProgress, Box } from '@chakra-ui/react'

export default function MapLoading(props) {
    const { isIndeterminate } = props
    return (
        <Box className="raster-pageload" zIndex="1400">
            <CircularProgress
                isIndeterminate={isIndeterminate || true}
                color="light.200"
                thickness="4px"
                size="80px"
            />
        </Box>
    )
}
