import React from 'react'

import { Text, View, Image } from '@react-pdf/renderer'
import { ErrorIcon } from './assets/SvgImages'

import formatDateTime from '../../../../../util/dateFormatter'
import { parseISO, formatDistanceToNow } from 'date-fns'

export default function SigmetChartsExportPDF(props) {
    const { briefingData, params, styles, timeZone, showOutdatedErrors } = props

    if (briefingData) {
        return (
            // SIGMET SECTION

            <View style={styles.section} break>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '-1',
                    }}
                >
                    {params.sigmet && /// SIGMET CHARTS
                        briefingData &&
                        briefingData.res.areamet &&
                        briefingData.res.areamet.charts &&
                        briefingData.res.areamet.charts.sigmet &&
                        briefingData.res.areamet.charts.sigmet
                            .sort((a, b) => {
                                const firA = a.fir
                                const firB = b.fir
                                if (firA < firB) {
                                    return -1
                                }
                                if (firA > firB) {
                                    return 1
                                } else {
                                    return 0
                                }
                            })
                            .map((image, i) => {
                                return (
                                    <div
                                        key={`SigmetChartsBriefing${image.fir}${image.url}`}
                                        wrap={false}
                                        break
                                        style={[
                                            styles.sectionContent,
                                            {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                paddingTop: 4,
                                                paddingBottom: 15,
                                                borderBottom:
                                                    image.fir === 'NZZC'
                                                        ? '1px solid #e5e5e5'
                                                        : 'none',
                                            },
                                        ]}
                                    >
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    marginBottom: '5',
                                                    borderBottom:
                                                        '1px solid #e5e5e5',
                                                },
                                            ]}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    fontSize: '8pt',
                                                    flexGrow: '1',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    SIGMET CHART
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    fontSize: '8pt',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    FIR:{' '}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontWeight: '500',
                                                        marginRight: '15px',
                                                        textTransform:
                                                            'uppercase',
                                                    }}
                                                >
                                                    {image.fir}
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    fontSize: '8pt',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    Issued:{' '}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontWeight: '300',
                                                    }}
                                                >
                                                    {image?.issued &&
                                                        formatDateTime(
                                                            image.issued,
                                                            timeZone
                                                        )}
                                                </Text>
                                            </div>
                                        </div>
                                        {(showOutdatedErrors ||
                                            image.outofdate) && (
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        paddingTop: '2px',
                                                        paddingBottom: '5px',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    },
                                                ]}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div // ERROR ICON
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '5%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                            },
                                                        ]}
                                                    >
                                                        <ErrorIcon
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="rgb(16, 170, 112)"
                                                        />
                                                    </div>
                                                    <div // ERROR CONTENT
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '95%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={styles.error}
                                                        >
                                                            <span>
                                                                There has been
                                                                an issue with
                                                                the connection
                                                                between
                                                                PreFlight and
                                                                MetService. This{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                SIGMET Chart
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                was last
                                                                validated with
                                                                MetService more
                                                                than{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {formatDistanceToNow(
                                                                    parseISO(
                                                                        image.updated
                                                                    ),
                                                                    {
                                                                        addSuffix: true,
                                                                    }
                                                                )}
                                                            </span>
                                                            <span>
                                                                . There is a
                                                                high likelihood
                                                                that the latest
                                                                SIGMET
                                                                information is
                                                                not available in
                                                                PreFlight. Check
                                                                back again soon,
                                                                or alternatively
                                                                visit{' '}
                                                            </span>
                                                            <a
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                href="https://ifis.airways.co.nz/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                IFIS
                                                            </a>
                                                            .
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    padding: '20px',
                                                    paddingTop:
                                                        showOutdatedErrors ||
                                                        image.outofdate
                                                            ? '0'
                                                            : '40px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Image
                                                style={{
                                                    height: 'auto',
                                                    width:
                                                        showOutdatedErrors ||
                                                        image.outofdate
                                                            ? '90%'
                                                            : '95%',
                                                }}
                                                src={`${window.location.origin}/metproxy?url=${image.url}`}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                </div>
            </View>
        )
    }
    return null
}
