import React from 'react'
import { useRecoilValue } from 'recoil'
import {
    Badge,
    Text,
    Flex,
    Box,
    Image,
    Table,
    Tbody,
    Tr,
    Td,
    Divider,
} from '@chakra-ui/react'
import { parseISO, formatDistanceToNow } from 'date-fns'

import formatDateTime, {
    timeRangeInWords,
} from '../../../../util/dateFormatter'
import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../../util/notamDecode'
import { timeZoneState, globalTimeState } from '../../../../globalState'

import ErrorText from '../ErrorText'
import notam from '../../../../icons/notam-icons/notam.svg'
import notamDrone from '../../../../icons/notam-icons/notam-drone.svg'
import notamObstacle from '../../../../icons/notam-icons/notam-obstacle.svg'
import notamAerodrome from '../../../../icons/notam-icons/notam-aerodrome.svg'
import notamWarning from '../../../../icons/notam-icons/notam-warning.svg'
import notamComms from '../../../../icons/notam-icons/notam-comms.svg'

export default function NotamContent(props) {
    const { data, showErrors } = props
    const timeZone = useRecoilValue(timeZoneState)
    const now = useRecoilValue(globalTimeState)

    function notamIconFormatter(code23) {
        return ['WU', 'WZ'].includes(code23)
            ? notamDrone
            : ['OB', 'OL'].includes(code23)
            ? notamObstacle
            : code23 === 'FA'
            ? notamAerodrome
            : ['W', 'R'].includes(code23.charAt(0))
            ? notamWarning
            : ['C', 'N'].includes(code23.charAt(0))
            ? notamComms
            : notam
    }

    // console.log(data.notam, 'data.notam')

    if (data.notam) {
        return (
            <>
                {/* TODO - look into completing this correctly and if actually needed */}
                {/* <Flex width="100%" justifyContent="flex-end" px="5px">
                    <Text
                        lineHeight="2.2"
                        fontSize="0.8rem"
                        textAlign="right"
                        pb="10px"
                        pr="5px"
                    >
                        NOTAMs last updated
                    </Text>
                    <Text
                        lineHeight="2.2"
                        fontSize="0.8rem"
                        textAlign="right"
                        pb="10px"
                    >
                        {formatDistanceToNow(parseISO(data.notam[0].updated), { // this grabs first NOTAM only. May not be correct unless all NOTAMs per aerodrome are updated at same time
                            addSuffix: true,
                        })}
                    </Text>
                </Flex> */}
                {data &&
                    data.notam &&
                    data.notam.map((notam) => {
                        const code23 = code23Decode(notam.code23)
                        const code45 = code45Decode(notam.code45)
                        const traffic = trafficDecode(notam.traffic)
                        return (
                            <Box
                                key={`${notam.series}${notam.number}/${notam.year}`}
                                w="100%"
                            >
                                {(showErrors || notam.outofdate) && (
                                    <Flex mb="15px" justifyContent="center">
                                        <ErrorText>
                                            There has been an issue with the
                                            connection between PreFlight and
                                            IFIS. This NOTAM was last validated
                                            with IFIS{' '}
                                            <strong>
                                                {formatDistanceToNow(
                                                    parseISO(notam.updated),
                                                    {
                                                        addSuffix: true,
                                                    }
                                                )}
                                            </strong>
                                            .<br />
                                            There is a possibility that this
                                            NOTAM could have been deleted or
                                            amended. Additional NOTAM that have
                                            been issued since the last
                                            validation may also be missing.{' '}
                                            <br />
                                            Check back again soon, or
                                            alternatively visit{' '}
                                            <a
                                                style={{
                                                    fontWeight: '500',
                                                    textDecoration: 'underline',
                                                }}
                                                href="https://ifis.airways.co.nz/"
                                            >
                                                IFIS
                                            </a>{' '}
                                            for an up to date aerodrome
                                            briefing.
                                        </ErrorText>
                                    </Flex>
                                )}
                                <Flex>
                                    {/* ICON */}
                                    <Flex minWidth="35px">
                                        <Image
                                            boxSize="35px"
                                            pr="10px"
                                            src={notamIconFormatter(
                                                notam.code23
                                            )}
                                            alt=""
                                        />
                                    </Flex>
                                    <Box width="100%">
                                        <Table
                                            size="sm"
                                            variant="notam"
                                            marginTop="0"
                                            marginBottom="1"
                                        >
                                            <Tbody>
                                                <Tr>
                                                    <Td
                                                        paddingStart="0"
                                                        paddingTop="0.2rem"
                                                        paddingBottom="0.2rem"
                                                        width="auto"
                                                        minWidth="90px"
                                                    >
                                                        <Text
                                                            fontWeight="bold"
                                                            fontSize="1rem"
                                                        >
                                                            {code23}
                                                        </Text>

                                                        <Text
                                                            fontWeight="normal"
                                                            paddingTop="2px"
                                                        >
                                                            {code45}
                                                        </Text>
                                                    </Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                        <Table
                                            size="sm"
                                            variant="notam"
                                            marginTop="0"
                                            marginBottom="0"
                                        >
                                            <Tbody>
                                                <Tr>
                                                    <Td
                                                        paddingStart="0"
                                                        fontWeight="bold"
                                                        width="auto"
                                                        minWidth="85px"
                                                        fontSize="0.9rem"
                                                    >
                                                        NOTAM #:
                                                    </Td>
                                                    <Td
                                                        width="100%"
                                                        fontSize="0.9rem"
                                                    >
                                                        {`${notam.series}${notam.number}/${notam.year}`}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td
                                                        paddingStart="0"
                                                        fontWeight="bold"
                                                        width="auto"
                                                        minWidth="85px"
                                                        fontSize="0.9rem"
                                                    >
                                                        Affects:
                                                    </Td>
                                                    <Td
                                                        width="100%"
                                                        fontSize="0.9rem"
                                                    >
                                                        <Flex
                                                            alignItems="baseline"
                                                            height="18px"
                                                        >
                                                            {traffic.ifr && (
                                                                <Badge
                                                                    variant="solid"
                                                                    background="ifr.100"
                                                                    color="white"
                                                                    paddingStart="2"
                                                                    paddingEnd="2"
                                                                    paddingTop="2px"
                                                                    paddingBottom="2px"
                                                                    borderRadius="xl"
                                                                    marginRight="5px"
                                                                    minWidth="40px"
                                                                    textAlign="center"
                                                                    fontSize="0.75rem"
                                                                    height="18px"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    display="flex"
                                                                >
                                                                    IFR
                                                                </Badge>
                                                            )}
                                                            {traffic.ifr &&
                                                            traffic.vfr
                                                                ? ' '
                                                                : ''}
                                                            {traffic.vfr && (
                                                                <Badge
                                                                    variant="solid"
                                                                    background="vfr.100"
                                                                    color="white"
                                                                    paddingStart="2"
                                                                    paddingEnd="2"
                                                                    paddingTop="2px"
                                                                    paddingBottom="2px"
                                                                    borderRadius="xl"
                                                                    marginTop="-2px"
                                                                    marginRight="5px"
                                                                    minWidth="40px"
                                                                    textAlign="center"
                                                                    fontSize="0.75rem"
                                                                    height="18px"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    display="flex"
                                                                >
                                                                    VFR
                                                                </Badge>
                                                            )}
                                                            <Text paddingLeft="1px">
                                                                TRAFFIC
                                                            </Text>
                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td
                                                        paddingStart="0"
                                                        fontWeight="bold"
                                                        width="auto"
                                                        minWidth="85px"
                                                        fontSize="0.9rem"
                                                    >
                                                        Status:
                                                    </Td>
                                                    <Td
                                                        width="100%"
                                                        fontSize="0.9rem"
                                                    >
                                                        {notam?.startvalidity &&
                                                        notam?.endvalidity
                                                            ? timeRangeInWords(
                                                                  now,
                                                                  notam.startvalidity,
                                                                  notam.endvalidity
                                                              )
                                                            : notam?.startvalidity &&
                                                              !notam?.endvalidity
                                                            ? timeRangeInWords(
                                                                  now,
                                                                  notam.startvalidity
                                                              )
                                                            : ''}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td
                                                        paddingStart="0"
                                                        fontWeight="bold"
                                                        width="auto"
                                                        minWidth="85px"
                                                        fontSize="0.9rem"
                                                    >
                                                        From:
                                                    </Td>
                                                    <Td
                                                        width="100%"
                                                        fontSize="0.9rem"
                                                    >
                                                        {notam?.startvalidity &&
                                                            formatDateTime(
                                                                notam.startvalidity,
                                                                timeZone
                                                            )}
                                                    </Td>
                                                </Tr>

                                                {!notam.permanent && (
                                                    <Tr>
                                                        <Td
                                                            paddingStart="0"
                                                            fontWeight="bold"
                                                            width="auto"
                                                            minWidth="85px"
                                                            fontSize="0.9rem"
                                                        >
                                                            To:
                                                        </Td>
                                                        <Td
                                                            width="100%"
                                                            fontSize="0.9rem"
                                                        >
                                                            {notam?.endvalidity &&
                                                                formatDateTime(
                                                                    notam.endvalidity,
                                                                    timeZone
                                                                )}
                                                            {notam?.estimation &&
                                                                ' (Estimated)'}
                                                        </Td>
                                                    </Tr>
                                                )}
                                                {notam?.itemd &&
                                                    notam.itemd !== 'null' && (
                                                        <Tr>
                                                            <Td
                                                                paddingStart="0"
                                                                fontWeight="bold"
                                                                width="auto"
                                                                minWidth="85px"
                                                                fontSize="0.9rem"
                                                            >
                                                                Period:
                                                            </Td>
                                                            <Td
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {notam.itemd +
                                                                    ' (UTC if time shown)'}
                                                            </Td>
                                                        </Tr>
                                                    )}
                                                {notam.itemf && (
                                                    <Tr>
                                                        <Td
                                                            paddingStart="0"
                                                            fontWeight="bold"
                                                            width="auto"
                                                            minWidth="85px"
                                                            fontSize="0.9rem"
                                                        >
                                                            Altitude:
                                                        </Td>
                                                        <Td
                                                            width="100%"
                                                            fontSize="0.9rem"
                                                        >
                                                            {notam.lowerlimit +
                                                                ' – ' +
                                                                notam.upperlimit}
                                                        </Td>
                                                    </Tr>
                                                )}
                                            </Tbody>
                                        </Table>

                                        <Text
                                            marginTop={1}
                                            marginBottom={3}
                                            whiteSpace="pre-line"
                                            fontSize="0.9rem"
                                        >
                                            {notam.iteme}
                                        </Text>

                                        <Divider
                                            variant="modalFooter"
                                            marginTop={7}
                                            marginBottom={5}
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                        )
                    })}
            </>
        )
    }
    return (
        <Text textAlign="center" pt="20px">
            No current NOTAMs issued
        </Text>
    )
}
