import React from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './components/'

export default function BriefingSlider({
    format,
    min,
    max,
    value,
    setValue,
    step,
    ticks,
    baseColor,
    altPadding,
}) {
    const sliderStyle = {
        position: 'relative',
        width: '100%',
        height: '6px',
        cursor: 'pointer',
    }

    function onChangeHandler(val) {
        setValue(val)
    }

    function onUpdateHandler(val) {
        setValue(val)
    }

    function formatTick(val) {
        if (typeof format === 'function') {
            return format(val)
        } else {
            return format + val
        }
    }

    return (
        <Slider
            mode={1}
            step={step || 1}
            domain={[min, max]}
            rootStyle={sliderStyle}
            onUpdate={onUpdateHandler}
            onChange={onChangeHandler}
            values={[+value]}
        >
            <Rail>
                {({ getRailProps }) => (
                    <SliderRail getRailProps={getRailProps} />
                )}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div>
                        {handles.map((handle) => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                domain={[+min, +max]}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                    <div>
                        {tracks.map(({ id, source, target }) => (
                            <Track
                                color={baseColor}
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                            />
                        ))}
                    </div>
                )}
            </Tracks>
            <Ticks values={ticks}>
                {({ ticks }) => (
                    <div>
                        {ticks.map((tick, index) => (
                            <Tick
                                index={index}
                                key={tick.id}
                                tick={tick}
                                count={ticks.length}
                                format={formatTick}
                                altPadding={altPadding}
                            />
                        ))}
                    </div>
                )}
            </Ticks>
        </Slider>
    )
}
