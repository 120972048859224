export function obstacleType(value) {
    switch (value) {
        case 'TRANSMISSION_LINE':
            return 'Antenna'
        case 'CONTROL_TOWER':
            return 'Control Tower'
        case 'WATER_TOWER':
            return 'Water Tower'
        case 'WINDMILL':
            return 'Wind Turbine'
        case 'COOLING_TOWER':
            return 'Cooling Tower'
        case 'OTHER:MICROWAVE_TOWER':
            return 'Microwave Tower'
        case 'OTHER:OTHER:RADAR':
            return 'Radar'
        case 'OTHER:RADAR':
            return 'Radar'

        default:
            return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
    }
}
