import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function VisibilityIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 11C15 12.5878 13.6569 13.875 12 13.875C10.3431 13.875 9 12.5878 9 11C9 9.41218 10.3431 8.125 12 8.125C12.321 8.125 12.6302 8.17332 12.9202 8.26278C12.1194 8.36347 11.5 9.0469 11.5 9.875C11.5 10.7725 12.2275 11.5 13.125 11.5C14.0225 11.5 14.75 10.7725 14.75 9.875C14.75 9.86623 14.7499 9.85747 14.7498 9.84872C14.9107 10.2012 15 10.5906 15 11Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 5.5C7.45455 5.5 3.57273 8.19533 2 12C3.57273 15.8047 7.45455 18.5 12 18.5C16.5455 18.5 20.4273 15.8047 22 12C20.4273 8.19533 16.5455 5.5 12 5.5ZM3.71936 12C5.19577 14.9609 8.3517 17 12 17C15.6483 17 18.8042 14.9609 20.2806 12C19.4107 10.2553 17.9576 8.83067 16.1642 7.95468C16.8451 8.79797 17.25 9.85396 17.25 11C17.25 13.7614 14.8995 16 12 16C9.1005 16 6.75 13.7614 6.75 11C6.75 9.85396 7.15485 8.79797 7.83578 7.95468C6.04242 8.83066 4.58932 10.2553 3.71936 12ZM12 7C9.62769 7 7.70455 8.79086 7.70455 11C7.70455 13.2091 9.62769 15 12 15C14.3723 15 16.2955 13.2091 16.2955 11C16.2955 8.79086 14.3723 7 12 7Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
