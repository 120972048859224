import React, { useEffect, useState, useCallback } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { Center, Spinner, Flex } from '@chakra-ui/react'
import InitUserProfile from './auth/initUserProfile'
import DeviceWarning from './device-warning'
import Map from './map'
import {
    mfaVerifiedState,
    layerSelectionState,
    simulateFreeUserState,
    VectorLayerRefreshInfo,
    setModalVisibilityState,
    oldDeviceState,
    oldDeviceWarningState,
    oldDeviceWarningAcceptedState,
    isBriefingOnlyState,
    mapDisabledState,
} from './globalState'

import {
    browserVersion,
    osVersion,
    isFirefox,
    isChrome,
    isSafari,
    isMacOS,
    isMobileOnly,
    isIOS,
    isAndroid,
    isWinPhone,
    isWindows,
} from 'react-device-detect'
import { subDays } from 'date-fns'
import MapDisabled from './map-disabled/MapDisabled'
import BriefingOnly from './map-disabled/BriefingOnly'
import layerVisibility from './config/utils/constructLayerVisibility'
import constructVectorLayerInfo from './config/utils/constructVectorLayerInfo'
import useRoles, { useTier, useUserMetaData, useAdminRole } from './auth/hooks'
import { ReactComponent as PreflightIcon } from './icons/assets/preflight-icon-shadow.svg'
import './App.css'

export default function App() {
    const userRoles = useRoles()
    const isAdmin = useAdminRole()
    const userMetaData = useUserMetaData()
    const [mfaVerified, setMfaVerified] = useRecoilState(mfaVerifiedState)
    const setLayerSelection = useSetRecoilState(layerSelectionState)
    const setVectorData = useSetRecoilState(VectorLayerRefreshInfo)
    const [mapDataLoaded, setMapDataLoaded] = useState(false)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const setWarningAccepted = useSetRecoilState(oldDeviceWarningAcceptedState)
    const [isBriefingOnly, setIsBriefingOnly] =
        useRecoilState(isBriefingOnlyState)
    const [mapDisabled, setMapDisabled] = useRecoilState(mapDisabledState)

    const setIsOldDevice = useSetRecoilState(oldDeviceState)
    const [deviceWarning, setDeviceWarning] = useRecoilState(
        oldDeviceWarningState
    )
    const warningAccepted = useRecoilValue(oldDeviceWarningAcceptedState)

    const tier = useTier()

    // *** MFA CHECK ***
    const expiryDays = 90 /* CONFIG THIS OPTION TO ADD DAYS */
    const mfaExpired = tier > 1 && !mfaVerified

    const isPremium = mfaExpired || simulateFreeUser ? tier >= 1 : tier === 1
    const userTier = isPremium ? 1 : tier
    const { isLoading } = useAuth0()
    const lastVerifiedDate =
        userMetaData && new Date(userMetaData.mfa_last_verified)
    const bypassMFA = userRoles.includes('Bypass MFA') || userRoles.length === 0 // Bypass MFA role, or free user (with no roles)
    const recentlyVerified = lastVerifiedDate > subDays(Date.now(), expiryDays) // if mfa_last_verified within the last 90 days

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowDeviceWarningModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceWarning', value })
        },
        [setModalVisibility]
    )
    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const setShowContactUs = useCallback(
        (value) => {
            setModalVisibility({ id: 'contactus', value })
        },
        [setModalVisibility]
    )

    // This allows a user to enable/disable the map to assist in performance issues
    const mapIsDisabled = userMetaData?.profile_settings?.disable_map || false

    // This allows a user to enable/disable AERO, MET and ADVS modes to assist in performance issues
    const briefModeOnly =
        userMetaData?.profile_settings?.brief_mode_only || false

    // device versions config
    const deviceVersionConfig = {
        chrome: {
            active: true,
            label: 'Chrome',
            minVersion: 115,
            detector: isChrome,
            versionType: 'browser',
        },
        safari: {
            active: true,
            label: 'Safari',
            minVersion: 16,
            detector: isSafari,
            versionType: 'browser',
        },
        firefox: {
            active: true,
            label: 'Firefox',
            minVersion: 115,
            detector: isFirefox,
            versionType: 'browser',
        },
        windows: {
            active: true,
            label: 'Windows',
            minVersion: 10,
            detector: isWindows,
            versionType: 'os',
        },
        macOS: {
            active: true,
            label: 'MacOS',
            minVersion: 12,
            detector: isMacOS,
            versionType: 'os',
        },
        iOS: {
            active: true,
            label: 'iOS',
            minVersion: 16,
            detector: isIOS,
            versionType: 'os',
        },
        android: {
            active: false, // disabled for now
            label: 'Android',
            minVersion: 12,
            detector: isAndroid,
            versionType: 'os',
        },
        windowsPhone: {
            active: true,
            label: 'WindowsPhone',
            minVersion: 11,
            detector: isWinPhone,
            versionType: 'os',
        },
    }

    // isOldDevice check using config
    const isOldDevice = Object.keys(deviceVersionConfig).reduce((acc, key) => {
        const config = deviceVersionConfig[key]
        if (config.active && config.detector) {
            const version =
                config.versionType === 'browser' ? browserVersion : osVersion
            acc[key] = parseInt(Number(version)) < config.minVersion
        }
        return acc
    }, {})

    // trigger to enable olderdevice
    const olderDevice = Object.values(isOldDevice).some((isOld) => isOld)

    const detectorsArray = Object.keys(isOldDevice).map((key) => {
        const config = deviceVersionConfig[key]
        return config?.label ? config.label : null
    })

    useEffect(() => {
        if (olderDevice) {
            // setWarningAccepted(false) // temporarily reinstate if needing to reset Old Device Warning modal accepted
            setIsOldDevice(true)
            setDeviceWarning(true)
            if (!warningAccepted) {
                setShowDeviceWarningModal(true)
            }
        } else {
            setIsOldDevice(false)
            setDeviceWarning(false)
            setWarningAccepted(false)
            setShowDeviceWarningModal(false)
        }
    }, [
        olderDevice,
        setIsOldDevice,
        setDeviceWarning,
        warningAccepted,
        setWarningAccepted,
        setShowDeviceWarningModal,
    ])

    // This sets the visibility state of each layer.
    useEffect(() => {
        if (userTier) {
            const visibilityConfig = layerVisibility(userTier, mapDisabled)
            const vectorInfo = constructVectorLayerInfo()
            // console.log({ vectorInfo })
            setVectorData(vectorInfo)
            setLayerSelection(visibilityConfig)
            setMapDataLoaded(true)
        }
    }, [setLayerSelection, setVectorData, userTier, mapDisabled])

    useEffect(() => {
        // if Bypass MFA role, or free user (with no roles) or verified within the last 90 days, send them on their way
        if (bypassMFA || recentlyVerified) {
            console.log('MFA Verified')
            setMfaVerified(true)
        } else if (!isLoading && tier > 1) {
            setShowResetMfaModal(true)
        }
    }, [
        isLoading,
        bypassMFA,
        tier,
        recentlyVerified,
        setMfaVerified,
        setShowResetMfaModal,
    ])

    useEffect(() => {
        if (mapIsDisabled) {
            setMapDisabled(true)
            console.log('Map is disabled. Enable in Settings')
        } else {
            setMapDisabled(false)
        }
    }, [mapIsDisabled, setMapDisabled])

    useEffect(() => {
        if (briefModeOnly) {
            setIsBriefingOnly(true)
            console.log('Briefing Only Mode. Map is disabled')
        } else {
            setIsBriefingOnly(false)
        }
    }, [briefModeOnly, setIsBriefingOnly])

    useEffect(() => {
        // add class to body element
        if (mapDataLoaded) {
            document.body.classList.remove('loading')
            document.body.classList.remove('landing-page')
            document.body.classList.add('app')
            if (!isPremium && (userRoles.includes('Professional') || isAdmin)) {
                document.body.classList.add('professional')
            }
        } else {
            document.body.classList.remove('app')
            document.body.classList.remove('professional')
        }
    }, [mapDataLoaded, isPremium, isAdmin, userRoles])

    // Force document title so it is picked up by OS (i.e. iOS)
    useEffect(() => {
        document.title = 'PreFlight'
    }, [])

    // if user selects 'Contact Us' option from Userback popup modal, open the Contact Us modal
    function checkHashAndOpenModal() {
        if (
            document.body.classList.contains('app') &&
            window.location.hash === '#contact-us'
        ) {
            setShowContactUs(true)
        }
    }
    useEffect(() => {
        window.addEventListener('hashchange', checkHashAndOpenModal)
        checkHashAndOpenModal()
        return () => {
            window.removeEventListener('hashchange', checkHashAndOpenModal)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fillColor = 'light.200'
    const emptyColor = 'rgba(255,255,255,0.2)'
    const emptyColorSecond = 'rgba(255,255,255,0.2)'

    // console.log(olderDevice, 'olderDevice')
    // console.log('isOldDevice', isOldDevice)
    // console.log('deviceWarning', deviceWarning)
    // console.log('warningAccepted', warningAccepted)
    // console.log(mfaExpired, 'mfaExpired')
    // console.log(mfaVerified, 'mfaVerified')
    // console.log(lastVerifiedDate, 'lastVerifiedDate')
    // console.log(recentlyVerified, 'recentlyVerified')
    // console.log(userMetaData, 'userMetaData')

    // Return spinner animation while page is loading
    if (isLoading || (!mapDisabled && !mapDataLoaded)) {
        return (
            <Center
                flexDirection="column"
                position="fixed"
                w="100%"
                h="100%"
                pb={{
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    lg: '20px',
                }}
            >
                <Spinner
                    size="4xl"
                    color={fillColor}
                    style={{
                        borderBottomColor: emptyColorSecond,
                        borderLeftColor: emptyColor,
                    }}
                    emptyColor={emptyColor}
                    thickness="1px"
                    speed="0.8s"
                    boxSize="110px"
                />
                <PreflightIcon
                    style={{ marginTop: '-92px', zIndex: '1' }}
                    width="80px"
                    height="80px"
                />
            </Center>
        )
    }

    // ONCE MFA VERIFIED, THIS DISPLAYS OLDER DEVICE WARNING MODAL BEFORE ACCESSING APP
    if (olderDevice && deviceWarning && !warningAccepted) {
        return (
            <Flex
                width="100%"
                height="100%"
                background="radial-gradient(#ffffff 40%, #dbe0e6 100%) rgb(238, 240, 244) !important"
            >
                <DeviceWarning
                    olderDevice={olderDevice}
                    isOldDevice={isOldDevice}
                    detectorsArray={detectorsArray}
                    deviceWarning={deviceWarning}
                    setDeviceWarning={setDeviceWarning}
                />
            </Flex>
        )
    }

    // DISPLAYS APP ONCE MFA VERIFIED, AND ACCEPTED DEVICE WARNING (only if applicable)
    return (
        <>
            <InitUserProfile />
            {isBriefingOnly ? (
                <BriefingOnly />
            ) : mapDisabled ? (
                <MapDisabled />
            ) : (
                <Map />
            )}
        </>
    )
}
