import React from 'react'

import { Text, View, Image } from '@react-pdf/renderer'
import { MslIcon } from './assets/SvgImages'
import { ErrorIcon } from './assets/SvgImages'

import formatDateTime from '../../../../../util/dateFormatter'
import { parseISO, formatDistanceToNow } from 'date-fns'

export default function MSLExportPDF(props) {
    const { briefingData, styles, timeZone, periodTo, showOutdatedErrors } =
        props

    if (briefingData) {
        return (
            // MSL SECTION
            briefingData &&
            briefingData.res.areamet &&
            briefingData.res.areamet.charts &&
            briefingData.res.areamet.charts.msl && (
                <View style={styles.section} break>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '-1',
                        }}
                    >
                        <div
                            wrap={false}
                            style={[
                                styles.sectionTitle,
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                },
                            ]}
                        >
                            <MslIcon
                                width="16"
                                height="16"
                                viewBox="0 0 14 14"
                                fill="rgb(63, 184, 221)"
                            />

                            <Text
                                style={{
                                    fontWeight: '600',
                                    textAlign: 'right',
                                    paddingLeft: '7',
                                    color: 'rgb(63, 184, 221)',
                                }}
                            >
                                MEAN SEAL LEVEL
                            </Text>
                        </div>

                        {briefingData.res.areamet.charts.msl
                            .filter(
                                (chart) => parseISO(chart.validat) <= periodTo
                            )
                            .map((image, i) => {
                                return (
                                    <div
                                        key={`MslBriefing${image.validat}`}
                                        wrap={false}
                                        style={[
                                            styles.sectionContent,
                                            {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                paddingTop: 5,
                                                paddingBottom: 15,
                                                borderBottom:
                                                    i + 1 !== image.length &&
                                                    '1px solid #e5e5e5',
                                            },
                                        ]}
                                    >
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    marginBottom: '5',
                                                    borderBottom:
                                                        '1px solid #e5e5e5',
                                                },
                                            ]}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginBottom: '5',
                                                    fontSize: '8pt',
                                                    flexGrow: '1',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    MEAN SEA LEVEL CHART
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    fontSize: '8pt',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    Issued:{' '}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontWeight: '300',
                                                    }}
                                                >
                                                    {image?.issued &&
                                                        formatDateTime(
                                                            image.issued,
                                                            timeZone
                                                        )}
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    fontSize: '8pt',
                                                    paddingLeft: 12,
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    Valid at:{' '}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontWeight: '300',
                                                    }}
                                                >
                                                    {image?.validat &&
                                                        formatDateTime(
                                                            image.validat,
                                                            timeZone
                                                        )}
                                                </Text>
                                            </div>
                                        </div>
                                        {(showOutdatedErrors ||
                                            image.outofdate) && (
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        paddingTop: '2px',
                                                        paddingBottom: '4px',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    },
                                                ]}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div // ERROR ICON
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '5%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                            },
                                                        ]}
                                                    >
                                                        <ErrorIcon
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="rgb(16, 170, 112)"
                                                        />
                                                    </div>
                                                    <div // ERROR CONTENT
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '95%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={styles.error}
                                                        >
                                                            <span>
                                                                There has been
                                                                an issue with
                                                                the connection
                                                                between
                                                                PreFlight and
                                                                MetService. This{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                Mean Sea Level
                                                                Chart
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                was last
                                                                validated with
                                                                MetService more
                                                                than{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {formatDistanceToNow(
                                                                    parseISO(
                                                                        image.updated
                                                                    ),
                                                                    {
                                                                        addSuffix: true,
                                                                    }
                                                                )}
                                                            </span>
                                                            <span>
                                                                . There is a
                                                                high likelihood
                                                                that the latest
                                                                Mean Sea Level
                                                                information is
                                                                not available in
                                                                PreFlight. Check
                                                                back again soon,
                                                                or alternatively
                                                                visit{' '}
                                                            </span>
                                                            <a
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                href="https://ifis.airways.co.nz/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                IFIS
                                                            </a>
                                                            .
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    width: '100%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                },
                                            ]}
                                        >
                                            <Image
                                                style={{
                                                    height: 'auto',
                                                    width:
                                                        showOutdatedErrors ||
                                                        image.outofdate
                                                            ? '65%'
                                                            : '75%',
                                                }}
                                                src={`${window.location.origin}/metproxy?url=${image.url}`}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </View>
            )
        )
    }
    return null
}
