import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
    Stack,
    Flex,
    Button,
    Text,
    Box,
    Icon,
    IconButton,
    useColorModeValue,
    useColorMode,
    Collapse,
    Tooltip,
} from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'
import { isMobile, isDesktop } from 'react-device-detect'
import { RiLogoutCircleLine } from 'react-icons/ri'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { FiMoon, FiSun } from 'react-icons/fi'
import PreFlight from '../../icons/menu-icons/PreFlightIcon'
import { DesktopNav, MobileNav } from './Navigation'

export default function Header(props) {
    const {
        scrollStarted,
        isOpen,
        onToggle,
        setInViewPosition,
        setscrollStarted,
    } = props
    const navigate = useNavigate()
    const { user, loginWithRedirect, logout } = useAuth0()
    const hideLoggedInText = window.innerWidth < 840
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        if (inView) {
            setInViewPosition('headerContainer')
            setscrollStarted(false)
        } else if (!inView) {
            setscrollStarted(true)
        }
    }, [inView, setInViewPosition, setscrollStarted])

    const logo = useColorModeValue('brand.200', 'dark.200')
    // const logoutButton = useColorModeValue('dark.52', 'light.52')
    // const logoutButtonHover = useColorModeValue('dark.53', 'light.53')
    // const logoutButtonText = useColorModeValue('light.100', 'dark.80')
    const buttonColor = useColorModeValue('light.100', 'dark.100')
    const buttonBg = useColorModeValue(
        'rgba(231, 239, 247, 0.36)',
        'rgba(24, 37, 51, 0.36)'
    )
    const buttonActiveBase = useColorModeValue(
        'rgba(231, 239, 247, 0.3)',
        'rgba(24, 37, 51, 0.3)'
    )
    const buttonActiveMd = useColorModeValue(
        'rgba(231, 239, 247, 0.6)',
        'rgba(24, 37, 51, 0.6)'
    )
    const buttonFocusBase = useColorModeValue(
        'rgba(231, 239, 247, 0.2)',
        'rgba(24, 37, 51, 0.2)'
    )
    const buttonFocusMd = useColorModeValue(
        'rgba(231, 239, 247, 0.4)',
        'rgba(24, 37, 51, 0.4)'
    )
    const buttonHoverBase = useColorModeValue(
        'rgba(231, 239, 247, 0.3)',
        'rgba(24, 37, 51, 0.3)'
    )
    const buttonHoverMd = useColorModeValue(
        'rgba(231, 239, 247, 0.6)',
        'rgba(24, 37, 51, 0.6)'
    )
    const tooltipBg = useColorModeValue('dark.10', 'dark.30')

    const { colorMode, toggleColorMode } = useColorMode()

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <>
            <div
                id="headerContainer"
                ref={ref}
                style={{ top: '0px', height: '300px', position: 'absolute' }}
            ></div>
            <Flex // Header
                zIndex="20"
                fontFamily="'Poppins', 'Open Sans', sans-serif"
                bg={useColorModeValue(
                    scrollStarted ? 'rgba(255,255,255,0.97)' : 'white',
                    scrollStarted ? 'rgba(45, 62, 79, 0.97)' : 'dark.10'
                )}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                height={scrollStarted ? '50px' : '82px'}
                transition="all linear 300ms"
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'dark.10')}
                align={'center'}
                width="100%"
                position="fixed"
                boxShadow="0px 5px 30px -10px rgba(0,0,0,0.3)"
            >
                <Flex
                    minH={'60px'}
                    pt="5px"
                    pb="6px"
                    px={{ base: 4 }}
                    align={'center'}
                    marginInlineStart="auto"
                    marginInlineEnd="auto"
                    mx="auto"
                    justifyContent="space-between"
                    width="var(--chakra-sizes-7xl)"
                >
                    <Flex
                        order={{ base: '1', md: '2', lg: '1' }}
                        flex={{ base: 1, md: 'max-content', lg: 'auto' }}
                        ml={{ base: '0', md: '25px' }}
                        mr={{ base: -2, md: 'auto' }}
                        display={{ base: 'flex', lg: 'none' }}
                    >
                        <IconButton
                            color={useColorModeValue('gray.600', 'white')}
                            opacity="0.8"
                            onClick={onToggle}
                            icon={
                                isOpen ? (
                                    <CloseIcon w={3} h={3} />
                                ) : (
                                    <HamburgerIcon w={5} h={5} />
                                )
                            }
                            borderRadius={{ base: '7px', md: '20px' }}
                            variant={'ghost'}
                            aria-label={'Toggle Navigation'}
                            _active={{
                                opacity: '1',
                                bg: {
                                    base: useColorModeValue(
                                        'rgba(231, 239, 247, 0.3)',
                                        'rgba(24, 37, 51, 0.3)'
                                    ),
                                    md: useColorModeValue(
                                        'rgba(231, 239, 247, 0.6)',
                                        'rgba(24, 37, 51, 0.6)'
                                    ),
                                },
                            }}
                            _focus={{
                                opacity: '1',
                                bg: {
                                    base: useColorModeValue(
                                        'rgba(231, 239, 247, 0.2)',
                                        'rgba(24, 37, 51, 0.2)'
                                    ),
                                    md: useColorModeValue(
                                        'rgba(231, 239, 247, 0.4)',
                                        'rgba(24, 37, 51, 0.4)'
                                    ),
                                },
                            }}
                            _hover={{
                                opacity: '1',
                                bg: {
                                    base: useColorModeValue(
                                        'rgba(231, 239, 247, 0.3)',
                                        'rgba(24, 37, 51, 0.3)'
                                    ),
                                    md: useColorModeValue(
                                        'rgba(231, 239, 247, 0.6)',
                                        'rgba(24, 37, 51, 0.6)'
                                    ),
                                },
                            }}
                        />
                    </Flex>
                    <Flex
                        order={{ base: '2', md: '1', lg: '2' }}
                        flex={{ base: 1 }}
                        ml={{ base: '0', md: '15px', lg: '10px', xl: '0' }}
                        justify={{ base: 'center', md: 'start' }}
                        alignItems="center"
                    >
                        <Flex
                            h={{ base: '70px', md: '70px' }}
                            alignItems="center"
                            justifyContent="flex-start"
                            cursor="pointer"
                            onClick={() => {
                                document
                                    .getElementById('headerContainer')
                                    .scrollIntoView({
                                        behavior: 'smooth',
                                    })
                            }}
                        >
                            <PreFlight
                                width={scrollStarted ? '60px' : '80px'}
                                height={scrollStarted ? '60px' : '80px'}
                                transition="all linear 300ms"
                                fill={useColorModeValue('#CED9DF', 'dark.80')}
                                py="0"
                                mr="7px"
                                color={logo}
                            />
                            <Flex fontFamily="'Open Sans', sans-serif">
                                <Text
                                    fontSize={scrollStarted ? '2xl' : '4xl'}
                                    transition="all linear 300ms"
                                    color={useColorModeValue(
                                        'brand.200',
                                        'dark.80'
                                    )}
                                    fontWeight="light"
                                    letterSpacing="1px"
                                    alignSelf="center"
                                >
                                    Pre
                                </Text>
                                <Text
                                    fontSize={scrollStarted ? '2xl' : '4xl'}
                                    transition="all linear 300ms"
                                    ml="0"
                                    color={useColorModeValue(
                                        'brand.200',
                                        'dark.80'
                                    )}
                                    fontWeight="semibold"
                                    letterSpacing="1px"
                                    alignSelf="center"
                                >
                                    Flight
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex
                            display={{ base: 'none', lg: 'flex' }}
                            ml={{ base: 6, xl: 10 }}
                        >
                            <DesktopNav />
                        </Flex>
                    </Flex>

                    <Stack
                        order="3"
                        flex={{ base: 1, md: 0 }}
                        justify={'flex-end'}
                        direction={'row'}
                        alignItems="center"
                        spacing={6}
                        pr={{ base: '0px', md: '15px' }}
                    >
                        <Flex
                            mr={{ base: '0px', md: '15px' }}
                            justify={'flex-end'}
                            direction={'row'}
                            alignItems="center"
                        >
                            {/* Theme Colour Button */}
                            <Tooltip
                                label="Change Theme Colour"
                                hasArrow
                                borderRadius="7px"
                                height="26px"
                                fontSize="0.7rem"
                                alignItems="center"
                                display={
                                    isMobile || !isDesktop ? 'none' : 'flex'
                                }
                                placement="bottom"
                                m="5px"
                                color="light.10"
                                bg={tooltipBg}
                            >
                                <Button
                                    variant="outline"
                                    fontSize="11px"
                                    fontWeight="600"
                                    height={{ base: '40px', md: '30px' }}
                                    width={{ base: '40px', md: '92px' }}
                                    minWidth={{ base: '40px', md: '92px' }}
                                    justifyContent={{
                                        base: 'center',
                                        md: 'initial',
                                    }}
                                    paddingLeft="2px"
                                    paddingRight="2px"
                                    py="2px"
                                    ml="10px"
                                    borderRadius={{ base: '7px', md: '20px' }}
                                    boxShadow={{
                                        base: 'none',
                                        md: 'inset 0px 0px 1px 0px rgba(0,0,0,0.1)',
                                    }}
                                    border="none"
                                    // borderColor={
                                    //     colorMode === 'light' ? '#E9EAEB' : '#33424f'
                                    // }
                                    {...noFocus}
                                    index={colorMode === 'light' ? 0 : 1}
                                    onClick={() => {
                                        toggleColorMode(
                                            (prevState) => !prevState
                                        )
                                    }}
                                    bg={{
                                        base: 'none',
                                        md: buttonBg,
                                    }}
                                    _active={{
                                        bg: {
                                            base: buttonActiveBase,
                                            md: buttonActiveMd,
                                        },
                                    }}
                                    _focus={{
                                        bg: {
                                            base: buttonFocusBase,
                                            md: buttonFocusMd,
                                        },
                                    }}
                                    _hover={{
                                        bg: {
                                            base: buttonHoverBase,
                                            md: buttonHoverMd,
                                        },
                                    }}
                                    color={buttonColor}
                                >
                                    <Box
                                        display={{ base: 'none', md: 'block' }}
                                        mt="0px"
                                        ml="0px"
                                        px="22px"
                                        height={{ base: '26px', md: '26px' }}
                                        width="40px"
                                        borderRadius="20px"
                                        bg={useColorModeValue(
                                            '#ffffff',
                                            '#304153'
                                        )}
                                        boxShadow="0px 0px 1px 0px rgba(0,0,0,0.1)"
                                        style={{
                                            borderColor:
                                                colorMode === 'light'
                                                    ? '#E9EAEB'
                                                    : '#33424f',
                                            transform:
                                                colorMode === 'dark'
                                                    ? 'translateX(100%)'
                                                    : 'translateX(0%)',
                                            transition: 'transform 600ms',
                                        }}
                                        transform={
                                            colorMode === 'dark'
                                                ? 'translateX(100%)'
                                                : 'translateX(0%)'
                                        }
                                        transition="transform 600ms"
                                    ></Box>
                                    <Flex
                                        justifyContent="space-evenly"
                                        marginLeft={{ base: '0', md: '-34px' }}
                                        alignItems="center"
                                    >
                                        <Box
                                            display={{
                                                base:
                                                    colorMode === 'dark'
                                                        ? 'none'
                                                        : 'block',
                                                md: 'block',
                                            }}
                                            zIndex="1"
                                            paddingLeft={{
                                                base: '0',
                                                md: '2px',
                                            }}
                                            opacity={
                                                colorMode === 'dark'
                                                    ? '0.4'
                                                    : '1'
                                            }
                                            color={useColorModeValue(
                                                'light.100',
                                                'dark.80'
                                            )}
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="opacity 600ms"
                                        >
                                            <Icon
                                                mx="2px"
                                                mt="2px"
                                                width={{
                                                    base: '1.6em',
                                                    md: '1.3em',
                                                }}
                                                height={{
                                                    base: '1.6em',
                                                    md: '1.3em',
                                                }}
                                                as={FiSun}
                                            />
                                        </Box>
                                        <Box
                                            display={{
                                                base:
                                                    colorMode === 'light'
                                                        ? 'none'
                                                        : 'block',
                                                md: 'block',
                                            }}
                                            zIndex="1"
                                            paddingLeft={{
                                                base: '0',
                                                md: '26px',
                                            }}
                                            opacity={
                                                colorMode === 'light'
                                                    ? '0.3'
                                                    : '1'
                                            }
                                            color={useColorModeValue(
                                                'light.100',
                                                'dark.80'
                                            )}
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="opacity 600ms"
                                        >
                                            <Icon
                                                mx="2px"
                                                mt="2px"
                                                width={{
                                                    base: '1.6em',
                                                    md: '1.3em',
                                                }}
                                                height={{
                                                    base: '1.6em',
                                                    md: '1.3em',
                                                }}
                                                as={FiMoon}
                                            />
                                        </Box>
                                    </Flex>
                                </Button>
                            </Tooltip>

                            {/* Logout Button */}
                            {user && (
                                <Tooltip
                                    label="Log Out"
                                    hasArrow
                                    borderRadius="7px"
                                    height="26px"
                                    fontSize="0.7rem"
                                    alignItems="center"
                                    display={
                                        isMobile || !isDesktop ? 'none' : 'flex'
                                    }
                                    placement="bottom"
                                    m="5px"
                                    color="light.10"
                                    bg={tooltipBg}
                                >
                                    <Button
                                        display={{
                                            base: 'none',
                                            md: 'flex',
                                        }}
                                        {...noFocus}
                                        marginLeft="12px !important"
                                        size="sm"
                                        height={{
                                            base: '40px',
                                            md: '30px',
                                        }}
                                        width={{ base: '40px', md: '30px' }}
                                        opacity="0.8"
                                        boxShadow={{
                                            base: 'none',
                                            md: 'inset 0px 0px 1px 0px rgba(0,0,0,0.1)',
                                        }}
                                        bg={{
                                            base: 'none',
                                            md: buttonBg,
                                        }}
                                        _active={{
                                            opacity: '1',
                                            bg: {
                                                base: buttonActiveBase,
                                                md: buttonActiveMd,
                                            },
                                        }}
                                        _focus={{
                                            opacity: '0.7',
                                            bg: {
                                                base: buttonFocusBase,
                                                md: buttonFocusMd,
                                            },
                                        }}
                                        _hover={{
                                            opacity: '1',
                                            bg: {
                                                base: buttonHoverBase,
                                                md: buttonHoverMd,
                                            },
                                        }}
                                        color={buttonColor}
                                        borderRadius="100%"
                                        padding={0}
                                        onClick={() =>
                                            logout({
                                                returnTo: `${window.location.origin}`,
                                            })
                                        }
                                    >
                                        <RiLogoutCircleLine fontSize="1rem" />
                                    </Button>
                                </Tooltip>
                            )}
                        </Flex>

                        <Button
                            display={{
                                base: 'none',
                                md: 'inline-flex',
                            }}
                            borderRadius="25px"
                            as={'a'}
                            cursor={user ? 'default' : 'pointer'}
                            fontSize={'0.9rem'}
                            fontWeight={300}
                            variant={'link'}
                            _hover={{
                                color: !user && 'light.201',
                            }}
                            onClick={
                                !user
                                    ? () => {
                                          loginWithRedirect({
                                              action: 'signup',
                                          })
                                      }
                                    : // SIGNED IN
                                      undefined
                            }
                        >
                            {user ? (
                                user.given_name ? (
                                    <Text>Hi, {user.given_name}</Text>
                                ) : (
                                    <Text
                                        display={
                                            // length too long on iPad screen in portrait mode
                                            hideLoggedInText ? 'none' : 'block'
                                        }
                                    >
                                        You are logged in
                                    </Text>
                                )
                            ) : (
                                <Text fontWeight="400">Register</Text>
                            )}
                        </Button>

                        <Button
                            display={{ base: 'none', md: 'inline-flex' }}
                            fontSize={'0.9rem'}
                            borderRadius="25px"
                            fontWeight={500}
                            color={'white'}
                            px="20px"
                            bg={'light.200'}
                            href={'#'}
                            _hover={{
                                bg: 'light.201',
                            }}
                            height={scrollStarted ? '30px' : '40px'}
                            transition="all linear 300ms"
                            onClick={() => {
                                user
                                    ? navigate('/app')
                                    : loginWithRedirect({
                                          redirectUri: `${window.location.origin}/app`,
                                      })
                            }}
                        >
                            {user ? (
                                <Text fontWeight="500" letterSpacing="0.5px">
                                    Open PreFlight
                                </Text>
                            ) : (
                                <Text fontWeight="500" letterSpacing="0.5px">
                                    Log In
                                </Text>
                            )}
                        </Button>
                    </Stack>
                </Flex>
            </Flex>
            <Collapse // MOBILE NAV MENU
                style={{
                    position: 'fixed',
                    top: '0',
                    zIndex: '10',
                    width: '100%',
                    background: 'dark.20',
                }}
                in={isOpen}
                animateOpacity
            >
                <MobileNav />
            </Collapse>
        </>
    )
}
