import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useTier } from '../../../../../auth/hooks'
import {
    Flex,
    Checkbox,
    Button,
    Box,
    Text,
    useBreakpointValue,
    Divider,
    Portal,
    useColorModeValue,
    Icon,
} from '@chakra-ui/react'
import { useSwipeable } from 'react-swipeable'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { menuOptions } from './components/MenuOptions'
import TimeSelection from './TimeSelection'
import AltitudeSelection from './AltitudeSelection'
// import ExportOptions from './ExportOptions'
import MobileMenu from './MobileMenu'
import FilterCount from './components/FilterCount'
import NotamIcon from '../../../../../icons/menu-icons/NotamIcon'
import { ReactComponent as MetarIcon } from '../../../../../icons/assets/metar.svg'
import { ReactComponent as TafIcon } from '../../../../../icons/assets/taf.svg'
import { ReactComponent as AtisIcon } from '../../../../../icons/assets/atis.svg'
import { ReactComponent as DrawerTab } from '../../../../../icons/assets/drawer-tab.svg'
import { ReactComponent as DrawerChevron } from '../../../../../icons/assets/drawer-chevron.svg'
import { ReactComponent as AerodromeIcon } from '../../../../../icons/assets/aerodrome.svg'
import { ReactComponent as AWSIcon } from '../../../../../icons/assets/aws.svg'
import { ReactComponent as GraforIcon } from '../../../../../icons/assets/grafor.svg'
import { ReactComponent as MslIcon } from '../../../../../icons/assets/msl.svg'
import { ReactComponent as EnRouteIcon } from '../../../../../icons/assets/en-route.svg'
import { ReactComponent as SigmetIcon } from '../../../../../icons/assets/sigmet.svg'
import { ReactComponent as SigwxIcon } from '../../../../../icons/assets/sigwx.svg'
import { ReactComponent as AAWIcon } from '../../../../../icons/assets/aaw.svg'
import { ReactComponent as SpaceWxIcon } from '../../../../../icons/assets/spacewx.svg'
import {
    inViewPositionState,
    briefingParamsState,
    extendedHeightState,
    mobileDrawerExpandedState,
    setDetailsShowState,
    excludedItemsShowState,
    briefingOrderState,
    mobileNavButtonsShowState,
    altAerodromeLayoutState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../../../globalState'
import '../../briefing.css'
import MenuItems from './MenuItems'

export const initialMenuState = {
    aerodromes: {
        label: 'AERODROMES',
        abbreviated: 'AERODROMES',
        id: 'aerodromes',
        icon: <AerodromeIcon width="24px" height="24px" />,
        containerId: 'aerodromesContainer',
        childrenExpanded: true,
        children: {
            notam: {
                label: 'NOTAM',
                abbreviated: 'NOTAM',
                id: 'aerodromesNotam',
                icon: <NotamIcon boxSize="24px" scale="1" />,
                containerId: 'notamContainer',
            },
            metar: {
                label: 'METAR',
                abbreviated: 'METAR',
                id: 'aerodromesMetar',
                icon: <MetarIcon width="24px" height="24px" />,
                containerId: 'metarContainer',
            },
            taf: {
                label: 'TAF',
                abbreviated: 'TAF',
                id: 'aerodromesTaf',
                icon: <TafIcon width="24px" height="24px" />,
                containerId: 'tafContainer',
            },
            atis: {
                label: 'ATIS',
                abbreviated: 'ATIS',
                id: 'aerodromesAtis',
                icon: <AtisIcon width="24px" height="24px" />,
                containerId: 'atisContainer',
            },
        },
    },
    aws: {
        label: 'WEATHER STATIONS',
        abbreviated: 'AWS',
        id: 'aws',
        icon: <AWSIcon width="24px" height="24px" />,
        containerId: 'awsContainer',
        premiumOnly: true,
    },
    enroute: {
        label: 'EN-ROUTE',
        abbreviated: 'EN-ROUTE',
        id: 'enroute',
        icon: <EnRouteIcon width="24px" height="24px" />,
        containerId: 'enrouteContainer',
    },
    aaw: {
        label: 'AVIATION AREA WINDS',
        abbreviated: 'AAW',
        id: 'aaw',
        icon: <AAWIcon width="24px" height="24px" />,
        containerId: 'aawContainer',
    },
    grafor: {
        label: 'GRAFOR',
        abbreviated: 'GRAFOR',
        id: 'grafor',
        icon: <GraforIcon width="24px" height="24px" />,
        containerId: 'graforContainer',
    },
    msl: {
        label: 'MEAN SEA LEVEL',
        abbreviated: 'MSL',
        id: 'msl',
        icon: <MslIcon width="24px" height="24px" />,
        containerId: 'mslContainer',
    },
    sigmet: {
        label: 'SIGMET',
        abbreviated: 'SIGMET',
        id: 'sigmet',
        icon: <SigmetIcon width="24px" height="24px" />,
        containerId: 'sigmetContainer',
    },
    sigwx: {
        label: 'SIGWX',
        abbreviated: 'SIGWX',
        id: 'sigwx',
        icon: <SigwxIcon width="24px" height="24px" />,
        containerId: 'sigwxContainer',
    },
    spacewx: {
        label: 'SPACE WEATHER',
        abbreviated: 'SPACE WX',
        id: 'spacewx',
        icon: <SpaceWxIcon width="24px" height="24px" />,
        containerId: 'spacewxContainer',
    },
}

export function useMenuState() {
    const [menuState, setMenuState] = useState(initialMenuState)
    return [menuState, setMenuState]
}

export default function BriefingMenu(props) {
    const {
        briefingData,
        dataLoading,
        setScrollDirection,
        buttonSelected,
        setButtonSelected,
        scrollDirection,
        minimiseDesktopMenu,
        setMinimiseDesktopMenu,
        // pdfState,
        // setDownloadPromise,
        // printPromise,
        // setPrintPromise,
    } = props
    const [filtersShow, setFiltersShow] = useState(false)
    const inViewPosition = useRecoilValue(inViewPositionState)
    const [params, setParams] = useRecoilState(briefingParamsState)
    const altBriefingOrder = useRecoilValue(briefingOrderState)
    const [isScrolling, setIsScrolling] = useState(false)
    const [menuState, setMenuState] = useMenuState()
    const [extendedHeight, setExtendedHeight] =
        useRecoilState(extendedHeightState)
    const [mobileDrawerExpanded, setMobileDrawerExpanded] = useRecoilState(
        mobileDrawerExpandedState
    )
    const setMobileNavButtonsShow = useSetRecoilState(mobileNavButtonsShowState)
    const setExcludedItemsShow = useSetRecoilState(excludedItemsShowState)
    const setDetailsShow = useSetRecoilState(setDetailsShowState)
    const altAerodromeLayout = useRecoilValue(altAerodromeLayoutState)

    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const disableAerodromes = Boolean(!briefingData?.res?.aerodromes)

    useEffect(() => {
        // change order of menu items
        if (altBriefingOrder) {
            const reorderedMenuState = {
                aaw: menuState.aaw,
                grafor: menuState.grafor,
                msl: menuState.msl,
                sigmet: menuState.sigmet,
                sigwx: menuState.sigwx,
                spacewx: menuState.spacewx,
                enroute: menuState.enroute,
                aerodromes: menuState.aerodromes,
                aws: menuState.aws,
            }
            setMenuState(reorderedMenuState)
        } else {
            // revert order of menu items
            const originalMenuState = {
                aerodromes: menuState.aerodromes,
                aws: menuState.aws,
                enroute: menuState.enroute,
                aaw: menuState.aaw,
                grafor: menuState.grafor,
                msl: menuState.msl,
                sigmet: menuState.sigmet,
                sigwx: menuState.sigwx,
                spacewx: menuState.spacewx,
            }
            setMenuState(originalMenuState)
        }
    }, [altBriefingOrder, menuState, setMenuState])

    const aerodromesMenuExpanded = menuState.aerodromes.childrenExpanded

    // Open all children when showing the filter
    useEffect(() => {
        filtersShow &&
            setMenuState((prevState) =>
                Object.assign(
                    {},
                    ...Object.keys(prevState).map((parent) => {
                        if (prevState[parent].children) {
                            return {
                                [parent]: {
                                    ...prevState[parent],
                                    childrenExpanded: true,
                                },
                            }
                        } else return { [parent]: { ...prevState[parent] } }
                    })
                )
            )
    }, [filtersShow, setMenuState])

    // Collapse the aerodromes tab when hiding the filter or when !altAerodromeLayout
    useEffect(() => {
        ;((!altAerodromeLayout && !filtersShow) || !filtersShow) &&
            setMenuState((prevState) =>
                Object.assign(
                    {},
                    ...Object.keys(prevState).map((parent) => {
                        if (parent === 'aerodromes') {
                            return {
                                [parent]: {
                                    ...prevState[parent],
                                    childrenExpanded: false,
                                },
                            }
                        } else return { [parent]: { ...prevState[parent] } }
                    })
                )
            )
    }, [filtersShow, altAerodromeLayout, setMenuState])

    // Open all children when minimising menu for desktop
    useEffect(() => {
        altAerodromeLayout &&
            minimiseDesktopMenu &&
            setMenuState((prevState) =>
                Object.assign(
                    {},
                    ...Object.keys(prevState).map((parent) => {
                        if (prevState[parent].children) {
                            return {
                                [parent]: {
                                    ...prevState[parent],
                                    childrenExpanded: true,
                                },
                            }
                        } else return { [parent]: { ...prevState[parent] } }
                    })
                )
            )
    }, [altAerodromeLayout, minimiseDesktopMenu, setMenuState])

    const checkboxSize = useBreakpointValue({
        base: { size: 'xl' },
        md: { size: 'md' },
    })
    const checkboxVariant = useBreakpointValue({
        base: { variant: 'mobile' },
        md: { size: 'desktop' },
    })

    //PORTAL REFERENCES
    const mobileCheckMenu = useRef()
    const desktopCheckMenu = useRef()
    const mobileSliders = useRef()
    const desktopSliders = useRef()

    const portalMenu = useBreakpointValue({
        base: { containerRef: mobileCheckMenu },
        md: { containerRef: desktopCheckMenu },
    })

    const portalSliders = useBreakpointValue({
        base: { containerRef: mobileSliders },
        md: { containerRef: desktopSliders },
    })

    // MOBILE DRAWER SWIPE ACTIONS

    const listInnerRef = useRef()
    const [scrolledToTop, setScrolledToTop] = useState(true)
    // const [scrolledToBottom, setScrolledToBottom] = useState(false)

    // temp (remove this to enable partial slider height on initial swipe up)
    const buttonSwipe = useSwipeable({
        onSwipedUp: () => {
            if (scrollDirection === 'up') {
                setScrollDirection('')
            }
            if (scrollDirection !== 'up') {
                setMobileDrawerExpanded(true)
                setExtendedHeight(true)
            }
            setDetailsShow(false)
            setExcludedItemsShow(false)
            setMobileNavButtonsShow(false)
            setScrollDirection('')
        },
        onSwipedDown: () => {
            if (scrolledToTop && mobileDrawerExpanded && extendedHeight) {
                setExtendedHeight(false)
                setMobileDrawerExpanded(false)
            }
        },
    })

    // temp (remove this to enable partial slider height on initial swipe up)
    const drawerSwipe = useSwipeable({
        onSwipedUp: () => {
            setExtendedHeight(true)
            setMobileDrawerExpanded(true)
            setDetailsShow(false)
            setExcludedItemsShow(false)
            setMobileNavButtonsShow(false)
        },
        onSwipedDown: () => {
            if (scrolledToTop && mobileDrawerExpanded && extendedHeight) {
                setExtendedHeight(false)
                setMobileDrawerExpanded(false)
            }
        },
    })

    // const buttonSwipe = useSwipeable({
    //     onSwipedUp: () => {
    //         mobileDrawerExpanded && !extendedHeight
    //             ? setExtendedHeight(true)
    //             : !mobileDrawerExpanded
    //             ? setMobileDrawerExpanded(true)
    //             : setExtendedHeight(false)
    //     },
    //     onSwipedDown: () => {
    //         mobileDrawerExpanded && extendedHeight
    //             ? setExtendedHeight(false)
    //             : mobileDrawerExpanded &&
    //               !extendedHeight &&
    //               setMobileDrawerExpanded(false)
    //     },
    // })

    // const drawerSwipe = useSwipeable({
    //     onSwipedUp: () => {
    //         mobileDrawerExpanded && !extendedHeight
    //             ? setExtendedHeight(true)
    //             : mobileDrawerExpanded && scrolledToTop
    //             ? setExtendedHeight(true)
    //             : setMobileDrawerExpanded(true)
    //     },
    //     onSwipedDown: () => {
    //         mobileDrawerExpanded && extendedHeight && scrolledToTop
    //             ? setExtendedHeight(false)
    //             : mobileDrawerExpanded &&
    //               !extendedHeight &&
    //               scrolledToTop &&
    //               setMobileDrawerExpanded(false)
    //     },
    // })

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current
            if (scrollTop + clientHeight === scrollHeight) {
                setScrolledToTop(false)
                // setScrolledToBottom(true)
            }
            if (scrollTop === 0) {
                setScrolledToTop(true)
                // setScrolledToBottom(false)
            }
            if (scrollTop > 0) {
                setScrolledToTop(false)
            }
        }
    }

    const drawerBg = useColorModeValue('light.600', 'dark.80')
    const altPadding = '2px'

    const handleTransition = useCallback(() => {
        const buttonFiltered =
            buttonSelected &&
            menuOptions &&
            menuOptions.filter((feat) => feat.container === buttonSelected)
        const inViewPositionFiltered =
            inViewPosition &&
            menuOptions &&
            menuOptions.filter((feat) => feat.container === inViewPosition)

        const minimisedMenu =
            !altBriefingOrder && altAerodromeLayout && minimiseDesktopMenu
                ? -1
                : 0

        const altAerodromesMenu = Boolean(
            altBriefingOrder &&
                altAerodromeLayout &&
                minimiseDesktopMenu &&
                ((buttonSelected &&
                    (buttonSelected === 'notamContainer' ||
                        buttonSelected === 'metarContainer' ||
                        buttonSelected === 'tafContainer' ||
                        buttonSelected === 'atisContainer' ||
                        buttonSelected === 'awsContainer')) ||
                    (altBriefingOrder &&
                        altAerodromeLayout &&
                        minimiseDesktopMenu &&
                        !buttonSelected &&
                        inViewPosition &&
                        (inViewPosition === 'notamContainer' ||
                            inViewPosition === 'metarContainer' ||
                            inViewPosition === 'tafContainer' ||
                            inViewPosition === 'atisContainer' ||
                            inViewPosition === 'awsContainer')))
        )
            ? -1
            : 0

        const aerodromesSelected =
            altAerodromeLayout &&
            minimiseDesktopMenu &&
            buttonFiltered &&
            buttonFiltered[0] &&
            buttonFiltered[0].container === 'aerodromesContainer'
                ? 1
                : 0

        // Menu Collpased and Normal Order
        if (buttonSelected && buttonFiltered && buttonFiltered.length) {
            const menuTransition =
                altBriefingOrder &&
                altAerodromeLayout &&
                aerodromesMenuExpanded &&
                buttonFiltered[0].menuTransitionValue
                    ? buttonFiltered[0].menuTransitionValue.altBOandMenuExp // if Alt Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Expanded
                    : altBriefingOrder &&
                      altAerodromeLayout &&
                      buttonFiltered[0].menuTransitionValue
                    ? buttonFiltered[0].menuTransitionValue.altBO // if Alt Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Collapsed
                    : altBriefingOrder && buttonFiltered[0].menuTransitionValue
                    ? buttonFiltered[0].menuTransitionValue.altBO // if Alt Briefing Order only
                    : altAerodromeLayout &&
                      aerodromesMenuExpanded &&
                      buttonFiltered[0].menuTransitionValue
                    ? buttonFiltered[0].menuTransitionValue.stdBOandMenuExp // if Standard Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Expanded
                    : altAerodromeLayout &&
                      buttonFiltered[0].menuTransitionValue
                    ? buttonFiltered[0].menuTransitionValue.stdBO // if Standard Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Collapsed
                    : buttonFiltered[0].menuTransitionValue
                    ? buttonFiltered[0].menuTransitionValue.stdBO // if Standard Briefing Order only
                    : 0
            return `translateY(${
                menuTransition +
                minimisedMenu +
                aerodromesSelected +
                altAerodromesMenu
            }00%)`
            // Menu Expanded and Normal Order
        } else if (
            inViewPosition &&
            inViewPositionFiltered &&
            inViewPositionFiltered.length
        ) {
            const menuTransition =
                altBriefingOrder &&
                altAerodromeLayout &&
                aerodromesMenuExpanded &&
                inViewPositionFiltered[0].menuTransitionValue
                    ? inViewPositionFiltered[0].menuTransitionValue
                          .altBOandMenuExp // if Alt Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Expanded
                    : altBriefingOrder &&
                      altAerodromeLayout &&
                      inViewPositionFiltered[0].menuTransitionValue
                    ? inViewPositionFiltered[0].menuTransitionValue.altBO // if Alt Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Collapsed
                    : altBriefingOrder &&
                      inViewPositionFiltered[0].menuTransitionValue
                    ? inViewPositionFiltered[0].menuTransitionValue.altBO // if Alt Briefing Order only
                    : altAerodromeLayout &&
                      aerodromesMenuExpanded &&
                      inViewPositionFiltered[0].menuTransitionValue
                    ? inViewPositionFiltered[0].menuTransitionValue
                          .stdBOandMenuExp // if Standard Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Expanded
                    : altAerodromeLayout &&
                      inViewPositionFiltered[0].menuTransitionValue
                    ? inViewPositionFiltered[0].menuTransitionValue.stdBO // if Standard Briefing Order & Alt Aerodrome Layout & Aerodrome Menu Collapsed
                    : inViewPositionFiltered[0].menuTransitionValue
                    ? inViewPositionFiltered[0].menuTransitionValue.stdBO // if Standard Briefing Order only
                    : 0
            return `translateY(${
                menuTransition + minimisedMenu + altAerodromesMenu
            }00%)`
        }
    }, [
        buttonSelected,
        inViewPosition,
        aerodromesMenuExpanded,
        altBriefingOrder,
        altAerodromeLayout,
        minimiseDesktopMenu,
        // menuOptions
    ])

    const transitionColor = useCallback(() => {
        const buttonFiltered =
            buttonSelected &&
            menuOptions &&
            menuOptions.filter((feat) => feat.container === buttonSelected)
        const inViewPositionFiltered =
            inViewPosition &&
            menuOptions &&
            menuOptions.filter((feat) => feat.container === inViewPosition)
        if (buttonSelected && buttonFiltered && buttonFiltered.length) {
            const menuColor = buttonFiltered[0].menuColor
                ? buttonFiltered[0].menuColor // if Standard Briefing Order only
                : '#1778b7'
            return menuColor
        } else {
            const menuColor = inViewPositionFiltered[0].menuColor
                ? inViewPositionFiltered[0].menuColor // if Standard Briefing Order only
                : '#1778b7'
            return menuColor
        }
    }, [buttonSelected, inViewPosition])

    useEffect(() => {
        if (buttonSelected && buttonSelected === inViewPosition) {
            setButtonSelected(null)
        }
    }, [buttonSelected, inViewPosition, setButtonSelected])

    // useEffect(() => {
    //     //     // console.log('menuOptions: ', menuOptions)
    //     //     // console.log('buttonFiltered: ', buttonFiltered)
    //     //     // console.log('menuState: ', menuState)
    //     //     // console.log('menuTransition: ', menuTransition)
    //     console.log('buttonSelected: ', buttonSelected)
    //     console.log('inViewPosition: ', inViewPosition)
    //     console.log('params: ', params)
    // }, [buttonSelected, inViewPosition, params])

    return (
        <>
            <Portal style={{ height: '100%' }} {...portalMenu}>
                <Flex
                    visibility={{
                        base: mobileDrawerExpanded ? 'visible' : 'hidden',
                        md: 'visible',
                    }}
                    opacity={{
                        base: mobileDrawerExpanded ? '1' : '0',
                        md: '1',
                    }}
                    position={{
                        base: 'relative',
                        md: 'relative',
                    }}
                    pl={{
                        base: '10px',
                        md: '0px',
                    }}
                    pr={{
                        base: '20px',
                        md: '0px',
                    }}
                    pt={{
                        base: mobileDrawerExpanded ? '20px' : '0px',
                        md: '0px',
                    }}
                    pb={{
                        base: mobileDrawerExpanded ? '20px' : '0px',
                        md: '0px',
                    }}
                    mt={{
                        base: mobileDrawerExpanded ? '10px' : '0px',
                    }}
                    h="100%"
                    flexGrow="2"
                    flexDirection="column"
                    justifyContent="space-between"
                    transition="all ease 300ms"
                    color="light.400"
                    width="100%"
                    zIndex="100"
                    borderTop={{ base: '1px solid', md: 'none' }}
                    borderColor="#e9edf3"
                >
                    <Flex
                        height={{
                            base: mobileDrawerExpanded ? '580px' : '0px',
                            md: '100%',
                        }}
                        opacity={{
                            base: mobileDrawerExpanded ? '1' : '0',
                            md: '1',
                        }}
                        visibility={{
                            base: mobileDrawerExpanded ? 'visible' : 'hidden',
                            md: 'visible',
                        }}
                        flexDirection="column"
                        justifyContent="flex-start"
                        transition="all ease 300ms"
                    >
                        <Flex width="100%" zIndex="1">
                            <Flex
                                fontSize="0.65rem"
                                fontWeight="400"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                flex="1"
                                textAlign="left"
                                paddingLeft={
                                    minimiseDesktopMenu ? '0px' : '21px'
                                }
                                width={minimiseDesktopMenu ? '0px' : '100%'}
                                transition="all ease 300ms"
                                height="50px"
                                alignItems="center"
                            >
                                <Text
                                    opacity={
                                        filtersShow ||
                                        mobileDrawerExpanded ||
                                        minimiseDesktopMenu
                                            ? '0'
                                            : '0.6'
                                    }
                                    visibility={
                                        filtersShow ||
                                        mobileDrawerExpanded ||
                                        minimiseDesktopMenu
                                            ? 'hidden'
                                            : 'visible'
                                    }
                                    display={{
                                        base: 'none',
                                        md: 'initial',
                                    }}
                                    width={minimiseDesktopMenu ? '0px' : '100%'}
                                    transition="all ease 300ms"
                                    marginTop="auto"
                                    marginBottom="auto"
                                    color="light.300"
                                    fontSize="11px"
                                    fontWeight="400"
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    whiteSpace="pre"
                                >
                                    Briefing Sections
                                </Text>
                                <Text
                                    position={{
                                        base: 'relative',
                                        md: 'absolute',
                                    }}
                                    display={{
                                        base: 'initial',
                                        md: minimiseDesktopMenu
                                            ? 'none'
                                            : 'initial',
                                    }}
                                    opacity={{
                                        base: '1',
                                        md:
                                            filtersShow || mobileDrawerExpanded
                                                ? '1'
                                                : '0',
                                    }}
                                    visibility={{
                                        base: 'visible',
                                        md:
                                            filtersShow || mobileDrawerExpanded
                                                ? 'visible'
                                                : 'hidden',
                                    }}
                                    transition="opacity ease 300ms"
                                    marginTop="auto"
                                    marginBottom="auto"
                                    color="light.100"
                                    fontSize={{
                                        base: '0.9rem',
                                        md: '0.85rem',
                                    }}
                                    fontWeight="400"
                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                >
                                    ALL
                                </Text>
                            </Flex>
                            <Flex
                                gap={minimiseDesktopMenu ? '0px' : '8px'}
                                transition="all ease 300ms"
                                minWidth={minimiseDesktopMenu ? '40px' : '88px'}
                                justifyContent="flex-start"
                                position="absolute"
                                right={minimiseDesktopMenu ? '10px' : '7px'}
                                pt="5px"
                            >
                                <Button // Filter Button
                                    background="none"
                                    color="light.400"
                                    display={{
                                        base: 'none',
                                        md: 'initial',
                                    }}
                                    fontSize="10px"
                                    fontWeight="600"
                                    height="40px"
                                    width={minimiseDesktopMenu ? '0px' : '40px'}
                                    minWidth={
                                        minimiseDesktopMenu ? '0px' : '40px'
                                    }
                                    opacity={minimiseDesktopMenu ? '0' : '1'}
                                    visibility={
                                        minimiseDesktopMenu
                                            ? 'hidden'
                                            : 'visible'
                                    }
                                    justifyContent="center"
                                    paddingLeft="2px"
                                    paddingRight="0px"
                                    transition="all ease 300ms"
                                    borderRadius="10px"
                                    onClick={() => {
                                        setFiltersShow(
                                            (prevState) => !prevState
                                        )
                                    }}
                                    _focus={{
                                        bg: 'none',
                                    }}
                                    _active={{
                                        bg: 'none',
                                    }}
                                    _hover={{
                                        bg: 'none',
                                        color: 'light.100',
                                    }}
                                >
                                    <FilterCount
                                        filtersShow={filtersShow}
                                        count={
                                            Object.keys(params).filter(
                                                (i) => !params[i]
                                            ).length
                                        }
                                    />
                                </Button>

                                <Button // Minimise Button
                                    background="none"
                                    color="light.400"
                                    display={{
                                        base: 'none',
                                        md: 'initial',
                                    }}
                                    fontSize="10px"
                                    fontWeight="600"
                                    height="40px"
                                    width={filtersShow ? '0px' : '40px'}
                                    minWidth={filtersShow ? '0px' : '40px'}
                                    maxWidth={filtersShow ? '0px' : '40px'}
                                    opacity={filtersShow ? '0' : '1'}
                                    visibility={
                                        filtersShow ? 'hidden' : 'visible'
                                    }
                                    justifyContent="center"
                                    paddingLeft={filtersShow ? '0' : '2px'}
                                    paddingRight="0px"
                                    transition="all ease 300ms"
                                    borderRadius="10px"
                                    onClick={() => {
                                        setMinimiseDesktopMenu(
                                            (prevState) => !prevState
                                        )
                                    }}
                                    _focus={{
                                        bg: 'none',
                                    }}
                                    _active={{
                                        bg: 'none',
                                    }}
                                    _hover={{
                                        bg: 'none',
                                        color: 'light.100',
                                    }}
                                >
                                    <Flex
                                        color="#6D7884"
                                        alignItems="center"
                                        justifyContent="center"
                                        border={{
                                            base: 'none',
                                            md: 'none',
                                        }}
                                        borderRadius="10px"
                                        variant="outline"
                                        fontSize="1rem"
                                        height="34px"
                                        width="36px"
                                        style={{
                                            minWidth: '36px',
                                        }}
                                        _hover={{
                                            opacity: '1',
                                            background: 'light.10',
                                            color: 'light.100',
                                            boxShadow:
                                                '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                            transform: 'scale(1.08)',
                                        }}
                                        _active={{
                                            background: 'light.40',
                                            boxShadow:
                                                '0px 3px 7px -2px rgba(0,0,0,0.3)',

                                            color: '#6D7884',
                                        }}
                                        _focus={{
                                            color: 'light.100',
                                        }}
                                        flexDirection="column"
                                    >
                                        <Icon
                                            as={ChevronRightIcon}
                                            transform={
                                                minimiseDesktopMenu
                                                    ? 'rotate(0deg)'
                                                    : 'rotate(180deg)'
                                            }
                                            transition="all ease 300ms"
                                            width="22px"
                                            height="22px"
                                        />
                                    </Flex>
                                </Button>
                            </Flex>
                            <Flex
                                width={{
                                    base: '100%',
                                    md:
                                        filtersShow || mobileDrawerExpanded
                                            ? '100%'
                                            : '10px',
                                }}
                                maxWidth={{
                                    base: '44px',
                                    md:
                                        filtersShow || mobileDrawerExpanded
                                            ? '44px'
                                            : '10px',
                                }}
                                marginLeft={{
                                    base: '12px',
                                    md:
                                        filtersShow || mobileDrawerExpanded
                                            ? '12px'
                                            : '0px',
                                }}
                                visibility={
                                    filtersShow || mobileDrawerExpanded
                                        ? 'visible'
                                        : 'hidden'
                                }
                                opacity={
                                    filtersShow || mobileDrawerExpanded
                                        ? '1'
                                        : '0'
                                }
                                overflow="hidden"
                                transition="all ease 300ms"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                            >
                                <Checkbox // All Checkbox
                                    {...checkboxSize}
                                    {...checkboxVariant}
                                    marginRight={{ base: '10px', md: '20px' }}
                                    marginLeft="auto"
                                    display={
                                        filtersShow || mobileDrawerExpanded
                                            ? undefined
                                            : 'none'
                                    }
                                    opacity={
                                        filtersShow || mobileDrawerExpanded
                                            ? '1'
                                            : '0'
                                    }
                                    transition="opacity 300ms"
                                    colorScheme="teal"
                                    isChecked={
                                        !Object.keys(params)
                                            .map((i) => params[i])
                                            .every((i) => !i)
                                    }
                                    isDisabled={
                                        // if not indeterminate
                                        !Object.keys(menuState)
                                            .map((parent) => {
                                                if (
                                                    !menuState[parent].children
                                                ) {
                                                    return params[
                                                        menuState[parent]['id']
                                                    ]
                                                } else
                                                    return (
                                                        // parent checked and...
                                                        params[
                                                            menuState[parent][
                                                                'id'
                                                            ]
                                                        ] &&
                                                        // no children unchecked
                                                        !Object.keys(
                                                            menuState[parent]
                                                                .children
                                                        )
                                                            .map(
                                                                (child) =>
                                                                    params[
                                                                        menuState[
                                                                            parent
                                                                        ][
                                                                            'children'
                                                                        ][
                                                                            child
                                                                        ]['id']
                                                                    ]
                                                            )
                                                            .includes(false)
                                                    )
                                            })
                                            .includes(false)
                                    }
                                    isIndeterminate={
                                        // Simplify this to check only the current value or the children values.
                                        // Create function to tell if something is indeterminate
                                        !Object.keys(params)
                                            .map((i) => params[i])
                                            .every((i) => !i) &&
                                        Object.keys(menuState)
                                            .map((parent) => {
                                                if (
                                                    !menuState[parent].children
                                                ) {
                                                    return params[
                                                        menuState[parent]['id']
                                                    ]
                                                } else
                                                    return (
                                                        // parent checked and...
                                                        params[
                                                            menuState[parent][
                                                                'id'
                                                            ]
                                                        ] &&
                                                        // no children unchecked
                                                        !Object.keys(
                                                            menuState[parent]
                                                                .children
                                                        )
                                                            .map(
                                                                (child) =>
                                                                    params[
                                                                        menuState[
                                                                            parent
                                                                        ][
                                                                            'children'
                                                                        ][
                                                                            child
                                                                        ]['id']
                                                                    ]
                                                            )
                                                            .includes(false)
                                                    )
                                            })
                                            .includes(false)
                                    }
                                    onChange={() => {
                                        setParams((prevParams) => {
                                            let newParams = {}
                                            Object.keys(prevParams).forEach(
                                                (l) => (newParams[l] = true)
                                            )
                                            return newParams
                                        })
                                    }}
                                ></Checkbox>
                            </Flex>
                        </Flex>
                        <Divider
                            height="0px !important"
                            borderColor="#e9edf3"
                            width={{ base: 'calc(100% - 28px)', md: '100%' }}
                            mx="auto"
                            mt="0px"
                            ml={{ base: '19px', md: '0' }}
                            mb="10px"
                        />
                        <MenuItems
                            dataLoading={dataLoading}
                            altAerodromeLayout={altAerodromeLayout}
                            filtersShow={filtersShow}
                            mobileDrawerExpanded={mobileDrawerExpanded}
                            minimiseDesktopMenu={minimiseDesktopMenu}
                            setFiltersShow={setFiltersShow}
                            params={params}
                            setMinimiseDesktopMenu={setMinimiseDesktopMenu}
                            menuState={menuState}
                            setParams={setParams}
                            isPremium={isPremium}
                            setIsScrolling={setIsScrolling}
                            setButtonSelected={setButtonSelected}
                            setMenuState={setMenuState}
                            disableAerodromes={disableAerodromes}
                        />
                        <Flex // MENU SLIDING BACKGROUND
                            opacity={
                                mobileDrawerExpanded || filtersShow ? '0' : '1' // Hidden until connected to InView State
                            }
                            position="absolute"
                            top="60px"
                            width={minimiseDesktopMenu ? '66px' : '256px'}
                            transition="width ease 300ms, opacity ease 500ms"
                            display={{ base: 'none', md: 'flex' }}
                        >
                            <Box
                                mt="0px"
                                ml="0px"
                                mr="0px"
                                height={{
                                    base: mobileDrawerExpanded ? '40px' : '0',
                                    md: '40px',
                                }}
                                width="100%"
                                marginBottom="0px"
                                background="light.20"
                                borderRightRadius="7px"
                                transform={handleTransition}
                                transition="all ease 300ms"
                                boxShadow="3px 0px 11px -7px rgba(75, 84, 98, 0.5)"
                                clipPath="inset(0px -10px 0px 0px)"
                            >
                                <Box
                                    width="100%"
                                    position="absolute"
                                    height="100%"
                                    borderRightRadius="7px"
                                    border="1px solid"
                                    borderLeft="none"
                                    borderColor="#e9edf3"
                                    opacity="1"
                                ></Box>
                                <Box
                                    background={transitionColor}
                                    width="3px"
                                    position="absolute"
                                    right="5px"
                                    top="14%"
                                    height="72%"
                                    borderRadius="5px"
                                ></Box>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Portal>
            <Box
                flexGrow="2"
                style={{ height: '100%' }}
                ref={desktopCheckMenu}
            ></Box>

            <Portal {...portalSliders}>
                <Flex
                    transition="all ease 300ms"
                    height={{
                        base: mobileDrawerExpanded ? '180px' : '0px',
                        md: '200px',
                        lg: '220px',
                    }}
                    pt={{
                        base: mobileDrawerExpanded ? '10px' : '0px',
                        md: '0px',
                    }}
                    width="100%"
                    px={{ base: '10px', md: '2px' }}
                    flexDirection="column"
                    alignItems="center"
                    overflow="hidden"
                >
                    <TimeSelection
                        briefingData={briefingData}
                        dataLoading={dataLoading}
                    />
                    <AltitudeSelection altPadding={altPadding} />
                </Flex>
            </Portal>
            <Box
                opacity={{
                    base: '0',
                    md: !minimiseDesktopMenu ? '1' : '0',
                }}
                visibility={{
                    base: 'hidden',
                    md: !minimiseDesktopMenu ? 'visible' : 'hidden',
                }}
                pointerEvents={{
                    base: 'initial',
                    md: minimiseDesktopMenu ? 'none' : 'initial',
                }}
                transition="opacity ease-in-out 300ms"
                ref={desktopSliders}
            ></Box>
            <MobileMenu
                showFilters={filtersShow}
                menuPositionState={menuState}
                params={params}
                drawerBg={drawerBg}
                scrollDirection={scrollDirection}
                isScrolling={isScrolling}
                setIsScrolling={setIsScrolling}
                buttonSwipe={buttonSwipe}
                dataLoading={dataLoading}
                disableAerodromes={disableAerodromes}
            />

            <Box //// MOBILE POPUP DRAWER
                {...buttonSwipe}
                display={{
                    base: 'flex',
                    md: 'none',
                }}
                h="100%"
                maxHeight={mobileDrawerExpanded && extendedHeight && '775px'}
                bg={{
                    // base: mobileDrawerExpanded
                    //     ? 'rgba(255,255,255,1)'
                    //     : isFirefox
                    //     ? 'rgba(255,255,255,0.97)'
                    //     : 'rgba(255,255,255,0.5)',
                    // base: mobileDrawerExpanded
                    //     ? 'rgba(255,255,255,1)'
                    //     : 'light.600',
                    base: drawerBg,
                    md: 'transparent',
                }}
                position="absolute"
                flexDirection="column"
                zIndex="3"
                marginBottom="-1px"
                bottom="0px"
                width="100%"
                borderTopRadius={mobileDrawerExpanded ? '25px' : '0px'}
                transition="all ease 300ms"
                boxShadow="0px -5px 30px -5px rgba(0,0,0,0.25)"
                backdropFilter="blur(20px)"
                clipPath="inset(-100px 0px 0px 0px)"
                borderBottom={{ base: '1px solid #e9edf3', md: '0px' }}
                borderBottomColor="#e9edf3"
            >
                <Button
                    width="100%"
                    height={mobileDrawerExpanded ? '42px' : '38px'}
                    minHeight={mobileDrawerExpanded ? '42px' : '38px'}
                    marginTop={mobileDrawerExpanded ? '-14px' : '-14px'}
                    background="transparent"
                    onClick={() => {
                        if (scrollDirection === 'up') {
                            setScrollDirection('')
                        }
                        if (scrollDirection !== 'up') {
                            setExtendedHeight((prevState) => !prevState) // temp (remove for partial slider height)
                            setMobileDrawerExpanded((prevState) => !prevState)
                        }
                        setDetailsShow(false)
                        setExcludedItemsShow(false)
                        setMobileNavButtonsShow(false)
                    }}
                    {...buttonSwipe}
                    _active={{
                        background: 'transparent',
                    }}
                    _hover={{
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                    }}
                    _focus={{
                        background: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                    }}
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Flex
                        width="100%"
                        position="absolute"
                        top="-4px"
                        height="auto"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        clipPath="inset(0px 0px 0px 0px)"
                    >
                        <DrawerTab
                            style={{
                                // color: mobileDrawerExpanded
                                //     ? 'rgba(255,255,255,1)'
                                //     : isFirefox
                                //     ? 'rgba(255,255,255,0.97)'
                                //     : 'rgba(255,255,255,0.95)',
                                color: drawerBg,
                                transition: 'all ease 300ms',
                                // backdropFilter: 'blur(20px)',
                            }}
                            className="tab"
                        />
                        <DrawerChevron
                            style={{
                                marginTop: '-4px',
                                opacity: '0.5',
                                transform:
                                    mobileDrawerExpanded && extendedHeight
                                        ? 'rotate(180deg)'
                                        : 'none',
                                transition: 'transform ease 300ms',
                            }}
                        />
                    </Flex>

                    {/* <Divider // SLIDE UP INDICATOR
                        height="0px !important"
                        borderTopWidth="2px"
                        borderBottomWidth="2px"
                        borderColor="light.300"
                        opacity="0.4"
                        borderRadius="2px"
                        width="15%"
                        mx="auto"
                        mt={mobileDrawerExpanded ? '3px' : '13px'}
                        mb="7px"
                    /> */}
                </Button>
                <Flex
                    {...drawerSwipe}
                    flexDirection="column"
                    height="calc(100% - 28px)"
                    borderTop={{
                        base: mobileDrawerExpanded ? '1px solid' : 'none',
                        md: 'none',
                    }}
                    borderColor="#e9edf3"
                >
                    <Flex
                        {...drawerSwipe}
                        id="scroller"
                        flexDirection="column"
                        overflowX="hidden"
                        overflowY="auto"
                        onScroll={onScroll}
                        ref={listInnerRef}
                        transition="all ease 300ms"
                        height={mobileDrawerExpanded ? '740px' : '0'}
                    >
                        <Box ref={mobileSliders}></Box>

                        <Box ref={mobileCheckMenu}></Box>
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}
