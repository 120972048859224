import { useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import constructContrastStates from '../../config/utils/constructContrastStates'
import {
    contrastHandler,
    dataModeState,
    layerSelectionState,
} from '../../globalState'

// the config constructed below determines the contrast level of each respective layer. The Recoil selector will prioritise contrast levels in the order of high, medium and standard last.

const {base, imagery} = constructContrastStates()

export default function ContrastHandler() {
    const layerState = useRecoilValue(layerSelectionState)
    const mode = useRecoilValue(dataModeState)

    const updateContrastSettings = useSetRecoilState(contrastHandler)

    useEffect(() => {
        updateContrastSettings({
            config: layerState['constant']['imagery']
                ? imagery
                : base,
        })
    }, [layerState, mode, updateContrastSettings])

    return null
}
