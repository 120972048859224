import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function ThunderstormOutlookIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M17.5 15H11.9468C11.9819 14.755 12 14.5046 12 14.25C12 13.9954 11.9819 13.745 11.9468 13.5H17.5C19.1569 13.5 20.5 12.1569 20.5 10.5C20.5 8.8879 19.123 7.57279 17.625 7.50292C17.5954 7.50154 17.5658 7.50065 17.5362 7.50025C17.5241 7.50008 17.5121 7.5 17.5 7.5C17.4752 7.5 17.4505 7.5003 17.4259 7.5009C16.5672 7.52176 15.798 7.90341 15.264 8.50002L14.1563 7.48858C14.6065 6.98893 15.1672 6.5907 15.7998 6.33233C14.9336 4.93612 13.3735 4 11.75 4C9.21051 4 7.13647 5.99286 7.00647 8.5H6C4.9916 8.5 4.12264 9.09704 3.72734 9.95691C3.04134 10.4408 2.47649 11.0849 2.08726 11.8349C2.03008 11.5656 2 11.2863 2 11C2 8.87618 3.65521 7.13895 5.746 7.00794C6.50027 4.40385 8.9028 2.5 11.75 2.5C14.0505 2.5 16.2543 3.92855 17.3251 6.00334C17.3795 6.00126 17.4342 6.00014 17.489 6.00001L17.5 6C19.8182 6 22.0002 8.01472 22.0002 10.5C22.0002 11.6802 21.5458 12.7542 20.8026 13.5568C19.9806 14.4445 18.8051 15 17.5 15Z"
                fill="currentcolor"
            />
            <path
                d="M14.0479 18.2929C13.6814 18.1141 13.2394 18.2663 13.0607 18.6328L12.1977 20.4021C12.019 20.7686 12.1712 21.2106 12.5376 21.3893C12.9041 21.568 13.346 21.4158 13.5248 21.0494L14.3878 19.28C14.5665 18.9135 14.4143 18.4716 14.0479 18.2929Z"
                fill="currentcolor"
            />
            <path
                d="M10.1869 18.6321C10.3656 18.2657 10.8076 18.1135 11.174 18.2922C11.5405 18.471 11.6926 18.9129 11.5139 19.2794L10.6509 21.0488C10.4722 21.4152 10.0302 21.5674 9.66381 21.3887C9.29735 21.2099 9.14518 20.768 9.32391 20.4015L10.1869 18.6321Z"
                fill="currentcolor"
            />
            <path
                d="M14.1061 16.5147L14.3571 16H15.9998L15.4331 17.1619C15.2544 17.5284 14.8124 17.6806 14.446 17.5018C14.0795 17.3231 13.9274 16.8811 14.1061 16.5147Z"
                fill="currentcolor"
            />
            <path
                d="M11.4831 16L11.2323 16.5141C11.0536 16.8805 11.2058 17.3225 11.5722 17.5012C11.9387 17.68 12.3806 17.5278 12.5594 17.1613L13.1258 16H11.4831Z"
                fill="currentcolor"
            />
            <path
                d="M20.2143 16H17.2143L15.5 19.2308H17.6429L16.3571 22L21.5 17.8462H19.3571L20.2143 16Z"
                fill="currentcolor"
            />
            <path
                d="M7.79812 13.3622H11V10L9.71075 11.3269C8.05313 9.68833 5.39434 9.68833 3.73673 11.3269C2.07912 12.9656 2.09329 15.6289 3.74618 17.2675C5.40379 18.9108 8.09564 18.9108 9.75325 17.2675C10.5844 16.4458 11 15.2322 11 14.2972H10.0555C10.0555 15.2322 9.73436 15.9736 9.09209 16.6111C7.80284 17.8861 5.71548 17.8861 4.42622 16.6111C3.13697 15.3267 3.13697 13.2631 4.42622 11.9833C5.71548 10.7083 7.80284 10.7556 9.09209 12.0306L7.79812 13.3622Z"
                fill="currentcolor"
            />
            <path
                d="M6.98584 14.3681V12.3611H6.27746V14.7222L8.29871 15.9217L8.63873 15.3503L6.98584 14.3681Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
