import React from 'react'
import { ReactComponent as ErrorIcon } from '../../../icons/assets/error.svg'
import { Flex, Box } from '@chakra-ui/react'

export default function ErrorText({ children }) {
    return (
        <Flex flexDirection="row" alignItems="flex-start">
            <ErrorIcon color="#ff0000" width="40px" height="40px" />
            <Box
                color="#ff0000"
                width="100%"
                paddingTop="2px"
                paddingBottom="2px"
                mt="-1px"
                ml="5px"
                textAlign="left"
                fontWeight="400"
                fontSize={{ base: '11px', md: '12px' }}
            >
                {children}
            </Box>
        </Flex>
    )
}
