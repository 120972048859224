import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function MetIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()} {...props}>
            <path
                d="M14.9511 2L16.8513 4.24377C16.3167 4.10453 15.7414 4.0546 15.1554 4.11235C14.5694 4.17009 14.0145 4.3314 13.5166 4.57238L14.9511 2Z"
                fill="currentColor"
            />
            <path
                d="M19.1202 8.69742C18.9323 6.75407 17.217 5.33084 15.2915 5.52058C13.6011 5.68716 12.3078 7.0419 12.1444 8.68522C12.6164 8.72212 13.0806 8.80952 13.531 8.94213C13.5771 7.90325 14.3717 7.03287 15.4277 6.92881C16.5858 6.81469 17.6119 7.66609 17.7249 8.83492C17.8065 9.67933 17.3889 10.4542 16.7141 10.8624C17.0465 11.1985 17.3484 11.5658 17.6136 11.959C18.6313 11.2532 19.2485 10.0248 19.1202 8.69742Z"
                fill="currentColor"
            />
            <path
                d="M19.1675 12.5L19.1486 12.4927C19.5203 12.0957 19.8427 11.6203 20.084 11.0903C20.3273 10.5475 20.4633 9.98672 20.5071 9.42085L22 11.9678L19.1675 12.5Z"
                fill="currentColor"
            />
            <path
                d="M12.1283 5.5835L9.25 6.11592L10.7429 8.66284C10.7867 8.09696 10.9094 7.54465 11.166 6.99335C11.41 6.45752 11.7445 5.98384 12.1283 5.5835Z"
                fill="currentColor"
            />
            <path
                d="M21.3261 4.92592L20.3493 7.7162C20.1979 7.16958 19.9571 6.64597 19.6144 6.16084C19.2724 5.68275 18.8597 5.27559 18.4064 4.95774L21.3261 4.92592Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 18C2 20.2091 3.79085 22 5.99997 22H17.4999C18.805 22 19.9805 21.4445 20.8024 20.5568C21.5457 19.7542 22 18.6802 22 17.5C22 15.0147 19.818 13 17.4999 13L17.4889 13C17.434 13.0001 17.3794 13.0013 17.325 13.0033C16.2542 10.9285 14.0504 9.5 11.7499 9.5C8.90274 9.5 6.50023 11.4038 5.74597 14.0079C3.65519 14.1389 2 15.8762 2 18ZM17.5361 14.5002C17.5657 14.5006 17.5953 14.5015 17.6249 14.5029C19.1228 14.5728 20.4999 15.8879 20.4999 17.5C20.4999 19.1569 19.1567 20.5 17.4999 20.5H5.99997C4.61927 20.5 3.49999 19.3807 3.49999 18C3.49999 16.6193 4.61927 15.5 5.99997 15.5H7.00643C7.13643 12.9929 9.21045 11 11.7499 11C13.3734 11 14.9335 11.9361 15.7997 13.3323C15.1671 13.5907 14.6064 13.9889 14.1562 14.4886L15.2639 15.5C15.7979 14.9034 16.5671 14.5218 17.4258 14.5009C17.4504 14.5003 17.4751 14.5 17.4999 14.5C17.512 14.5 17.524 14.5001 17.5361 14.5002Z"
                fill="currentColor"
            />
        </Icon>
    )
}
