import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import {
    fullscreenModalVisibleState,
    selectDefaultMapLocationState,
    isBriefingOnlyState,
    mapDisabledState,
    tourVisibilityState,
} from '../../globalState'

export default function FullscreenModalHandler() {
    const fullscreenModalVisible = useRecoilValue(fullscreenModalVisibleState)
    const selectDefaultMapLocation = useRecoilValue(
        selectDefaultMapLocationState
    )
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const introTour = useRecoilValue(tourVisibilityState)

    useEffect(() => {
        ;(fullscreenModalVisible || isBriefingOnly || mapDisabled) &&
        !selectDefaultMapLocation &&
        !introTour
            ? (document.getElementsByClassName(
                  'mapboxgl-canvas-container'
              )[0].style.display = 'none')
            : (document.getElementsByClassName(
                  'mapboxgl-canvas-container'
              )[0].style.display = 'unset')
    }, [
        fullscreenModalVisible,
        isBriefingOnly,
        mapDisabled,
        introTour,
        selectDefaultMapLocation,
    ])

    return null
}
