import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function VisualReportingPointIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.3649 5L21.5 8.31525L14.3649 11.6305L8.5253 8.91724L3.79164 19L2.5 18.3988L7.22962 8.31525L14.3649 5ZM14.3648 10.4465V6.18403L9.84593 8.31525L14.3648 10.4465Z"
                fill="currentColor"
            />
        </Icon>
    )
}
