import React, { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { useAdminRole } from '../../../auth/hooks'
import {
    Flex,
    Box,
    Text,
    Modal,
    Heading,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Divider,
    useDisclosure,
    LightMode,
    Icon,
} from '@chakra-ui/react'
import ErrorToggle from './ErrorToggle'
import ErrorText from './ErrorText'
import { isAfter, parseISO, formatDistanceToNow } from 'date-fns'
import {
    clickedFeaturesState,
    showErrorTogglesState,
} from '../../../globalState'

import { ReactComponent as GraforIcon } from '../../../icons/assets/grafor.svg'
import { ReactComponent as CloudIcon } from '../../../icons/assets/cloud.svg'
import { ReactComponent as CBIcon } from '../../../icons/assets/cumulonimbus.svg'
import { ReactComponent as SunIcon } from '../../../icons/assets/sun.svg'

export default function GraforModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const [showErrors, setShowErrors] = useState(false)
    const [outOfDateError, setOutOfDateError] = useState(false)
    const isAdmin = useAdminRole()
    const feat = clickedFeatures[0]
    const clouds = feat.properties.clouds && JSON.parse(feat.properties.clouds)

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    const onCloseHandler = () => {
        setClickedFeatures([])
        onClose()
    }

    useEffect(() => {
        if (feat.properties.validTo) {
            if (isAfter(new Date(feat.properties.validTo), new Date())) {
                setOutOfDateError(false)
            } else {
                setOutOfDateError(true)
            }
        }
    }, [feat])

    // console.log(feat, 'GRAFOR feat')

    return (
        <>
            <LightMode>
                <Modal
                    variant="weather"
                    size="lg"
                    centered
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    onHide={() => setClickedFeatures([])}
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton
                            color="white"
                            mr={{ base: '0px', md: '10px' }}
                            mt={{ base: '0px', md: '8px' }}
                        />
                        <ModalHeader background="linear-gradient(345deg, rgb(116, 193, 206) 10%, rgb(35, 142, 159) 90%)">
                            <Flex
                                flexDirection="row"
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Icon
                                        pointerEvents="none"
                                        transform="scale(2.4) translateX(-4px)"
                                        opacity="0.1"
                                        boxSize="36px"
                                        as={GraforIcon}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                </Flex>
                                <Flex
                                    ml="-36px"
                                    color="white"
                                    pr={8}
                                    alignItems="center"
                                >
                                    <Icon
                                        boxSize="46px"
                                        as={GraforIcon}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                    <Box pl={4}>
                                        <Heading
                                            variant="mainHeading"
                                            justify-content="flex-start"
                                            flex-direction="row"
                                            display="flex"
                                        >
                                            Graphical Aviation Forecast
                                        </Heading>
                                    </Box>
                                </Flex>
                            </Flex>
                        </ModalHeader>

                        <ModalBody>
                            {isAdmin && showErrorToggle && (
                                <Flex
                                    w="100%"
                                    pb="15px"
                                    mt="10px"
                                    align="center"
                                    justifyContent={{
                                        base: 'flex-end',
                                        md: 'flex-end',
                                    }}
                                >
                                    <ErrorToggle
                                        showErrors={showErrors}
                                        setShowErrors={setShowErrors}
                                    />
                                </Flex>
                            )}
                            {(showErrors ||
                                feat.properties.outofdate ||
                                outOfDateError) && (
                                <Flex my="15px" justifyContent="center">
                                    <ErrorText>
                                        There has been an issue with the
                                        connection between PreFlight and
                                        MetService. This GRAFOR was last
                                        validated with MetService{' '}
                                        <strong>
                                            {formatDistanceToNow(
                                                parseISO(
                                                    feat.properties.updated
                                                ),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}
                                        </strong>
                                        .<br />
                                        There is a high likelihood that the
                                        latest GRAFOR is not available in
                                        PreFlight. Check back again soon, or
                                        alternatively visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </ErrorText>
                                </Flex>
                            )}
                            <Flex
                                size="sm"
                                flexDirection="column"
                                variant="weather"
                                marginTop="2"
                                color="light.100"
                            >
                                {/* Cloud Details */}
                                {clouds.length > 0 ? (
                                    clouds.map((layer, index) => {
                                        return (
                                            <Flex
                                                key={`cloudBox ${index}`}
                                                style={{
                                                    marginBottom: '1.2rem',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                <Flex
                                                    display="inline-flex"
                                                    key={`cloudFlex1 ${index}`}
                                                    style={{
                                                        maxWidth: 'fit-content',
                                                        paddingRight: '10px',
                                                        flex: '0',
                                                    }}
                                                >
                                                    {[
                                                        'cumulonimbus',
                                                        'toweringCumulus',
                                                    ].includes(
                                                        layer.cloudType
                                                    ) ? (
                                                        <Icon
                                                            key={`cumulonimbusIcon ${index}`}
                                                            boxSize="40px"
                                                            as={CBIcon}
                                                            alt="!"
                                                            color="light.100"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            key={`cloudIcon ${index}`}
                                                            boxSize="40px"
                                                            as={CloudIcon}
                                                            alt="!"
                                                            color="light.100"
                                                        />
                                                    )}
                                                </Flex>
                                                <Flex
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    width="100%"
                                                >
                                                    <Flex
                                                        key={`cloudFlex2 ${index}`}
                                                        style={{
                                                            maxWidth:
                                                                'fit-content',
                                                            fontWeight: '600',
                                                            textTransform:
                                                                'capitalize',
                                                            flex: '2',
                                                            justifyContent:
                                                                'start',
                                                        }}
                                                    >
                                                        {layer.cloudAmount}{' '}
                                                        {layer.embedded === true
                                                            ? 'Embedded'
                                                            : ''}{' '}
                                                        {layer.cloudType ===
                                                        'other'
                                                            ? 'Cloud '
                                                            : layer.cloudType.replace(
                                                                  'toweringCumulus',
                                                                  'Towering Cumulus'
                                                              )}
                                                    </Flex>
                                                    <Flex
                                                        key={`cloudFlex3 ${index}`}
                                                        style={{
                                                            fontWeight: '600',
                                                            flex: '2 2 0%',
                                                        }}
                                                        flexDirection="column"
                                                        alignItems="center"
                                                    >
                                                        {layer.topToFlightLevel && (
                                                            <p
                                                                className="popup-text-bold"
                                                                style={{
                                                                    margin: 0,
                                                                    fontWeight:
                                                                        '600',
                                                                    maxWidth:
                                                                        '7rem',
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                {layer.topFromFlightLevel ===
                                                                layer.topToFlightLevel
                                                                    ? `${
                                                                          layer.topFromFlightLevel ===
                                                                          999
                                                                              ? 'XXX'
                                                                              : layer.topFromFlightLevel +
                                                                                '00 ft'
                                                                      }`
                                                                    : `${
                                                                          layer.topFromFlightLevel ===
                                                                          999
                                                                              ? 'XXX'
                                                                              : layer.topFromFlightLevel +
                                                                                '00 ft'
                                                                      } – ${
                                                                          layer.topToFlightLevel ===
                                                                          999
                                                                              ? 'XXX'
                                                                              : layer.topToFlightLevel +
                                                                                '00 ft'
                                                                      }`}
                                                            </p>
                                                        )}

                                                        {layer.topFromFlightLevel && (
                                                            <p
                                                                className="popup-text-bold"
                                                                style={{
                                                                    margin: 0,
                                                                    width: '100%',
                                                                    fontWeight:
                                                                        '600',
                                                                    borderTop:
                                                                        '2px solid #52647a',
                                                                    paddingTop:
                                                                        '3px',
                                                                    marginTop:
                                                                        '4px',
                                                                    maxWidth:
                                                                        '7rem',
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                {layer.baseFromFlightLevel ===
                                                                layer.baseToFlightLevel
                                                                    ? `${layer.baseFromFlightLevel}00 ft`
                                                                    : `${layer.baseFromFlightLevel}00 ft – ${layer.baseToFlightLevel}00 ft`}
                                                            </p>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        )
                                    })
                                ) : (
                                    <Flex
                                        style={{
                                            marginTop: '0.8rem',
                                            marginBottom: '0.8rem',
                                            alignItems: 'center',
                                            width: '100%',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Flex
                                            style={{
                                                maxWidth: 'fit-content',
                                                paddingRight: '15px',
                                                flex: '0',
                                            }}
                                        >
                                            <Icon
                                                boxSize="40px"
                                                as={SunIcon}
                                                alt="!"
                                                color="light.100"
                                            />
                                        </Flex>
                                        <Flex
                                            style={{
                                                fontWeight: '600',
                                                flex: '2 2 0%',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                    marginTop: '0.2rem',
                                                    marginBottom: '0.2rem',
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                No Significant Cloud
                                            </p>
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                            <Divider variant="modalFooter" />

                            {/* GRAFOR Comments */}
                            <Text marginBottom="8px">
                                {feat.properties.comments}
                            </Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
