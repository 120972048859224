import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useTier } from '../auth/hooks'
import { timeZoneState } from '../globalState'

export default function ResetTimeZone() {
    const userTier = useTier()
    const [timeZone, setTimeZone] = useRecoilState(timeZoneState)

    useEffect(() => {
        userTier <= 1 && timeZone !== 'UTC' && setTimeZone('UTC')
    }, [userTier, timeZone, setTimeZone])

    return null
}
