import React from 'react'
import { Flex, Text, Icon, useBreakpointValue } from '@chakra-ui/react'

import PressureIcon from '../../../../../icons/menu-icons/PressureIcon'
import { QnhDial } from '../../graphics/WeatherGraphics'

export default function AwsQnh(props) {
    const { qnh, presentWeather, infotabGraphicsDisabled } = props
    const imageSize = useBreakpointValue({
        base: '100',
        md: '90',
    })

    return (
        <Flex
            width="100%"
            background="light.10"
            boxShadow={
                !infotabGraphicsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            borderTopRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            borderBottomRadius={'20px'}
            pt="10px"
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={
                (!presentWeather ||
                    (presentWeather && presentWeather.startsWith('/'))) &&
                infotabGraphicsDisabled
                    ? '17px'
                    : '10px'
            }
            direction={{
                base: 'row',
                md: 'row',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                }}
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color="#9DB2CD"
                >
                    <PressureIcon boxSize="36px" />
                </Flex>
                <Flex direction="column">
                    <Text
                        whiteSpace="pre"
                        fontSize={{
                            base: '1.4rem',
                            md: '1.2rem',
                        }}
                        lineHeight="1.2"
                        pt={{
                            base: '4px',
                            md: '5px',
                        }}
                        pl={infotabGraphicsDisabled ? '10px' : '5px'}
                        fontWeight="600"
                    >
                        {qnh} hPa
                    </Text>
                </Flex>
            </Flex>

            {!infotabGraphicsDisabled && (
                <Flex
                    pt="3px"
                    pb="0px"
                    mt={{
                        base: '5px',
                        md: '5px',
                    }}
                    pr={{
                        base: '7%',
                        md: '5%',
                    }}
                    width="100%"
                    justifyContent={{
                        base: 'flex-end',
                        md: 'flex-end',
                    }}
                    alignItems={{
                        base: 'center',
                        md: 'center',
                    }}
                >
                    <Icon
                        as={QnhDial}
                        imageSize={imageSize}
                        primaryImageColor={'#3e5e88'}
                        focusImageColor={'#00f1ab'}
                        imageColor={'#83909F'}
                        data={qnh}
                        type={'QNH'}
                    />
                </Flex>
            )}
        </Flex>
    )
}
