import React, { useEffect, useContext, useCallback, useState } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import { RiStarLine } from 'react-icons/ri'
import {
    useDisclosure,
    Flex,
    Stack,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    LightMode,
    Badge,
    useBreakpointValue,
} from '@chakra-ui/react'

import { useTier, useUserMetaData } from '../../../auth/hooks'
import { isMobileOnly } from 'react-device-detect'

import {
    briefingOptionsState,
    layerSelectionHandler,
    briefingStandardState,
    setModalVisibilityState,
    briefingAreaSourceState,
    briefingCustomAreaState,
    simulateFreeUserState,
    modalVisibilityState,
    mfaVerifiedState,
    selectedBriefingAreasState,
    mapDisabledState,
    isBriefingOnlyState,
    userProfileState,
    showFavouritesState,
    showManageFavouritesState,
    favouritesListState,
} from '../../../globalState'

import { ReactComponent as SelectAreaIcon } from '../../../icons/assets/draw-area.svg'
import { ReactComponent as SelectFavouriteIcon } from '../../../icons/assets/select-favourite.svg'
import { ReactComponent as SelectBriefIcon } from '../../../icons/assets/select-brief.svg'

import BriefingStandard from './BriefingStandard'
import BriefingCustom from './BriefingCustom'
import BriefingOnlySelection from './BriefingOnlySelection'

import './briefing.css'
import BriefingFavourites from './components/briefing-favourites'

export default function Briefing() {
    const [show, setShow] = useRecoilState(briefingOptionsState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const [briefingAreaSource, setBriefingAreaSource] = useRecoilState(
        briefingAreaSourceState
    )

    const briefingStandard = useRecoilValue(briefingStandardState)
    const setSelectedAreas = useSetRecoilState(selectedBriefingAreasState)
    const briefingCustomArea = useRecoilValue(briefingCustomAreaState)
    const mapInstance = useContext(MapContext)
    const [showFavourites, setShowFavourites] =
        useRecoilState(showFavouritesState)
    const [showBriefingOnlySelection, setShowBriefingOnlySelection] =
        useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (show && !showBriefingOnlySelection && !briefingStandard) {
            onOpen()
        } else {
            onClose()
        }
    }, [show, showBriefingOnlySelection, briefingStandard, onOpen, onClose])

    const onCloseOptionsHandler = () => {
        setShow(false)
        onClose()
    }

    const onOpenOptionsHandler = () => {
        setShow(true)
        onOpen()
    }

    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const setLayerSelection = useSetRecoilState(layerSelectionHandler)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const profileData = useRecoilValue(userProfileState)
    const userMetaData = useUserMetaData()
    const showManageFavourites = useRecoilValue(showManageFavouritesState)
    const setFavouritesList = useSetRecoilState(favouritesListState)

    useEffect(() => {
        // set initial favourites list when Briefing is rendered
        const initialFavourites = profileData?.briefingFavourites
            ? profileData.briefingFavourites
            : userMetaData?.briefing_favourites &&
              userMetaData?.briefing_favourites.length > 0
            ? userMetaData.briefing_favourites
            : []
        setFavouritesList(initialFavourites)
    }, [profileData, userMetaData, showManageFavourites, setFavouritesList])

    // detect viewport height to account for URL bar if viewing in Browser and not PWA
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const noFocus = {
        _focus: {
            boxShadow: 'none',
        },
    }
    const iconSize = useBreakpointValue({
        base: isBriefingOnly || mapDisabled ? '110px' : '50px',
        xs: isBriefingOnly || mapDisabled ? '110px' : '60px',
        sm: isBriefingOnly || mapDisabled ? '110px' : '80px',
        md: '110px',
        lg: '150px',
    })

    const standardHeading = useBreakpointValue({
        base: `Standard Briefing Areas`,
        md: `Standard \nBriefing Areas`,
    })
    const favouritesHeading = useBreakpointValue({
        base: `Load a Favourite`,
        md: `Load a \nFavourite`,
    })
    const customHeading = useBreakpointValue({
        base: `Plot a Custom Area`,
        md: `Plot a \nCustom Area`,
    })

    return (
        <>
            {!modalVisibility.pdf &&
                !modalVisibility.terms &&
                !modalVisibility.announcements && (
                    <LightMode>
                        <Modal
                            closeOnOverlayClick={false}
                            onClose={onCloseOptionsHandler}
                            isOpen={isOpen}
                            centered
                            size="5xl"
                            backgroundColor="transparent"
                            variant={
                                isBriefingOnly || mapDisabled
                                    ? 'briefingOnlyOptions'
                                    : 'briefingOptions'
                            }
                            scrollable
                            scrollBehavior="inside"
                            trapFocus={false}
                            blockScrollOnMount={false}
                        >
                            {!(isBriefingOnly || mapDisabled) && (
                                <ModalOverlay />
                            )}
                            <ModalContent
                                pointerEvents="none"
                                backgroundColor="transparent"
                                boxShadow="none"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                justifyContent="center"
                                alignItems="center"
                                height="100vh"
                                padding={{
                                    base: '10',
                                    md: '8',
                                    ml: '12',
                                    xl: '20',
                                }}
                            >
                                <Stack
                                    zIndex="2"
                                    opacity={showFavourites ? '0' : '1'}
                                    visibility={
                                        showFavourites ? 'hidden' : 'visible'
                                    }
                                    pointerEvents={
                                        showFavourites ? 'none' : 'auto'
                                    }
                                    transition="all ease 300ms"
                                    direction={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{
                                        base: '10',
                                        md: '2rem',
                                        ml: '3rem',
                                        lg: '4rem',
                                        xl: '5rem',
                                    }}
                                    justifyContent="center"
                                    alignItems={{
                                        base: 'center',
                                        md: 'initial',
                                    }}
                                >
                                    <Flex /// SELECT BRIEFING OPTION
                                        flex="1"
                                        flexShrink={0}
                                        minWidth={0}
                                        width={{
                                            base: '100%',
                                            md: '35%',
                                            lg: '28%',
                                        }}
                                        maxWidth={{
                                            base: '350px',
                                            md: '35%',
                                            lg: '28%',
                                        }}
                                        pointerEvents={
                                            showFavourites ? 'none' : 'auto'
                                        }
                                        justifyContent="center"
                                        alignContent="space-between"
                                        flexWrap="wrap"
                                        visibility={
                                            isOpen === true
                                                ? 'visible'
                                                : 'hidden'
                                        }
                                        borderRadius="25"
                                        bg="white"
                                        marginTop={{ base: '0', md: '0' }}
                                        cursor="pointer"
                                        boxShadow={
                                            isBriefingOnly || mapDisabled
                                                ? '0px 20px 60px -15px rgba(0, 15, 35, 0.4)'
                                                : '0px 10px 25px -10px rgba(0,0,0,0.7)'
                                        }
                                        _hover={{
                                            opacity: '1',
                                            transform: {
                                                base: 'none',
                                                lg: `translateY(-3px) translateX(0px) scale(1.01)`,
                                            },
                                            boxShadow:
                                                isBriefingOnly || mapDisabled
                                                    ? '0px 30px 70px -25px rgba(0, 15, 35, 0.7)'
                                                    : {
                                                          base: '0px 30px 60px -25px rgba(0, 15, 35, 0.7)',
                                                          md: '0px 25px 40px -15px rgba(0, 15, 35, 0.7)',
                                                      },
                                        }}
                                        transition="all ease 250ms"
                                    >
                                        <Flex
                                            alignContent="space-between"
                                            flexWrap="wrap"
                                            width="100%"
                                            height="calc(100% - 50px)"
                                            minHeight={{
                                                base: '3rem',
                                                md: '16rem',
                                            }}
                                            onClick={() => {
                                                if (
                                                    isBriefingOnly ||
                                                    mapDisabled
                                                ) {
                                                    setShowBriefingOnlySelection(
                                                        true
                                                    )
                                                } else {
                                                    mapInstance.flyTo({
                                                        center: isMobileOnly
                                                            ? [172.62, -42.62]
                                                            : [172.62, -41.12],
                                                        zoom: [
                                                            isMobileOnly
                                                                ? 4.2
                                                                : 4.8,
                                                        ],
                                                    })
                                                    setLayerSelection({
                                                        mode: 'brief',
                                                        layer: 'briefingareas',
                                                    })
                                                }
                                                onCloseOptionsHandler()
                                                setBriefingAreaSource(
                                                    'briefingarea'
                                                )
                                                setSelectedAreas([])
                                            }}
                                            opacity="0.9"
                                            _hover={{ opacity: '1' }}
                                        >
                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                                flexDirection={{
                                                    base:
                                                        isBriefingOnly ||
                                                        mapDisabled
                                                            ? 'column'
                                                            : 'row',
                                                    md: 'column',
                                                }}
                                                width="100%"
                                                mt={{
                                                    base:
                                                        isBriefingOnly ||
                                                        mapDisabled
                                                            ? '20px'
                                                            : '15px',
                                                    md: '6',
                                                }}
                                            >
                                                <Flex
                                                    my={{ base: '0', md: '1' }}
                                                    width={{
                                                        base: 'auto',
                                                        md: '100%',
                                                    }}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    ml={{
                                                        base:
                                                            isBriefingOnly ||
                                                            mapDisabled
                                                                ? '0px'
                                                                : '-15px',
                                                        md: '0',
                                                    }}
                                                >
                                                    <SelectBriefIcon
                                                        width={iconSize}
                                                        height={iconSize}
                                                    />
                                                </Flex>
                                                <Flex
                                                    alignItems="center"
                                                    flexWrap={{
                                                        base: 'nowrap',
                                                        md: 'wrap',
                                                    }}
                                                    width={{
                                                        base: 'auto',
                                                        md: '100%',
                                                    }}
                                                    height="100%"
                                                >
                                                    <Text
                                                        width={{
                                                            base: 'auto',
                                                            md: '100%',
                                                        }}
                                                        pt={{
                                                            base: '2px',
                                                            md: '1',
                                                        }}
                                                        pb={{
                                                            base: '4px',
                                                            md: '0',
                                                        }}
                                                        textAlign="center"
                                                        fontWeight="500"
                                                        fontSize={{
                                                            base:
                                                                isBriefingOnly ||
                                                                mapDisabled
                                                                    ? '1.1rem'
                                                                    : '0.95rem',
                                                            xs:
                                                                isBriefingOnly ||
                                                                mapDisabled
                                                                    ? '1.2rem'
                                                                    : '1.1rem',
                                                            sm:
                                                                isBriefingOnly ||
                                                                mapDisabled
                                                                    ? '1.3rem'
                                                                    : '1.2rem',
                                                            md: '1.3rem',
                                                        }}
                                                        color="light.100"
                                                        whiteSpace="pre-line"
                                                    >
                                                        {standardHeading}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                            <Text
                                                width="100%"
                                                minHeight={{
                                                    base:
                                                        isBriefingOnly ||
                                                        mapDisabled
                                                            ? '50px'
                                                            : '45px',
                                                    md: '50px',
                                                }}
                                                textAlign="center"
                                                fontSize={{
                                                    base: '0.8rem',
                                                    xs: '0.85rem',
                                                }}
                                                mx="auto"
                                                pt={{
                                                    base: '1',
                                                    md: '4',
                                                }}
                                                pb={{
                                                    base: '3',
                                                    md: '9',
                                                }}
                                                px={{
                                                    base: '4',
                                                    md: '8',
                                                }}
                                                color="#868b8e"
                                            >
                                                {isBriefingOnly || mapDisabled
                                                    ? 'Select from standard briefing areas'
                                                    : 'Select briefing areas on the map'}
                                            </Text>
                                        </Flex>
                                        <Button
                                            fontWeight="500"
                                            letterSpacing="0.3px"
                                            {...noFocus}
                                            width="100%"
                                            height="50px"
                                            borderTopRadius="0"
                                            borderBottomRadius="24"
                                            colorScheme="blue"
                                            fontSize="0.9rem"
                                            bottom="0"
                                            background="linear-gradient(345deg, #00d094 10%, #01996d 100%)"
                                            _hover={{
                                                opacity: '1',
                                                background:
                                                    'linear-gradient(345deg, #00c48b 10%, #008b62 100%)',
                                            }}
                                            _active={{
                                                background:
                                                    'linear-gradient(345deg, #00c48b 10%, #008b62 100%)',
                                            }}
                                            onClick={() => {
                                                if (
                                                    isBriefingOnly ||
                                                    mapDisabled
                                                ) {
                                                    setShowBriefingOnlySelection(
                                                        true
                                                    )
                                                } else {
                                                    mapInstance.flyTo({
                                                        center: isMobileOnly
                                                            ? [172.62, -42.62]
                                                            : [172.62, -41.12],
                                                        zoom: [
                                                            isMobileOnly
                                                                ? 4.2
                                                                : 4.8,
                                                        ],
                                                    })
                                                    setLayerSelection({
                                                        mode: 'brief',
                                                        layer: 'briefingareas',
                                                    })
                                                }
                                                onCloseOptionsHandler()
                                                setBriefingAreaSource(
                                                    'briefingarea'
                                                )
                                                setSelectedAreas([])
                                            }}
                                        >
                                            SELECT AREAS
                                        </Button>
                                    </Flex>

                                    <Flex /// LOAD FAVOURITE OPTION
                                        flex="1"
                                        flexShrink={0}
                                        minWidth={0}
                                        width={{
                                            base: '100%',
                                            md: '35%',
                                            lg: '28%',
                                        }}
                                        maxWidth={{
                                            base: '350px',
                                            md: '35%',
                                            lg: '28%',
                                        }}
                                        justifyContent="center"
                                        alignContent="space-between"
                                        flexWrap="wrap"
                                        visibility={
                                            isOpen === true
                                                ? 'visible'
                                                : 'hidden'
                                        }
                                        borderRadius="25"
                                        pointerEvents={
                                            showFavourites ? 'none' : 'auto'
                                        }
                                        bg="white"
                                        marginTop={{ base: '0', md: '0' }}
                                        cursor="pointer"
                                        opacity={isPremium ? '0.75' : '1'}
                                        boxShadow={
                                            isBriefingOnly || mapDisabled
                                                ? '0px 20px 60px -15px rgba(0, 15, 35, 0.4)'
                                                : '0px 10px 25px -10px rgba(0,0,0,0.7)'
                                        }
                                        _hover={{
                                            opacity: '1',
                                            transform: {
                                                base: 'none',
                                                lg: `translateY(-3px) translateX(0px) scale(1.01)`,
                                            },
                                            boxShadow:
                                                isBriefingOnly || mapDisabled
                                                    ? '0px 30px 70px -25px rgba(0, 15, 35, 0.7)'
                                                    : {
                                                          base: '0px 30px 60px -25px rgba(0, 15, 35, 0.7)',
                                                          md: '0px 25px 40px -15px rgba(0, 15, 35, 0.7)',
                                                      },
                                        }}
                                        transition="all ease 250ms"
                                    >
                                        <Flex
                                            alignContent="space-between"
                                            flexWrap="wrap"
                                            width="100%"
                                            height="calc(100% - 50px)"
                                            minHeight={{
                                                base: '3rem',
                                                md: '16rem',
                                            }}
                                            onClick={() => {
                                                if (isPremium) {
                                                    setShowPricingTiersModal(
                                                        true
                                                    )
                                                } else {
                                                    setShowFavourites(true)
                                                    setBriefingAreaSource(
                                                        'briefingarea'
                                                    )
                                                }
                                            }}
                                            opacity={isPremium ? '0.5' : '0.9'}
                                            _hover={{
                                                opacity: isPremium
                                                    ? '0.6'
                                                    : '1',
                                            }}
                                        >
                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                                flexDirection={{
                                                    base:
                                                        isBriefingOnly ||
                                                        mapDisabled
                                                            ? 'column'
                                                            : 'row',
                                                    md: 'column',
                                                }}
                                                width="100%"
                                                mt={{
                                                    base:
                                                        isBriefingOnly ||
                                                        mapDisabled
                                                            ? '20px'
                                                            : '15px',
                                                    md: '6',
                                                }}
                                            >
                                                <Flex
                                                    my={{ base: '0', md: '1' }}
                                                    width={{
                                                        base: 'auto',
                                                        md: '100%',
                                                    }}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    ml={{
                                                        base:
                                                            isBriefingOnly ||
                                                            mapDisabled
                                                                ? '0px'
                                                                : '-15px',
                                                        md: '0',
                                                    }}
                                                >
                                                    <SelectFavouriteIcon
                                                        width={iconSize}
                                                        height={iconSize}
                                                    />
                                                </Flex>
                                                <Flex
                                                    alignItems="center"
                                                    flexWrap={{
                                                        base: 'nowrap',
                                                        md: 'wrap',
                                                    }}
                                                    width={{
                                                        base: 'auto',
                                                        md: '100%',
                                                    }}
                                                    height="100%"
                                                >
                                                    <Text
                                                        width={{
                                                            base: 'auto',
                                                            md: '100%',
                                                        }}
                                                        pt={{
                                                            base: '2px',
                                                            md: '1',
                                                        }}
                                                        pb={{
                                                            base: '4px',
                                                            md: '0',
                                                        }}
                                                        textAlign="center"
                                                        fontWeight="500"
                                                        fontSize={{
                                                            base:
                                                                isBriefingOnly ||
                                                                mapDisabled
                                                                    ? '1.1rem'
                                                                    : '0.95rem',
                                                            xs:
                                                                isBriefingOnly ||
                                                                mapDisabled
                                                                    ? '1.2rem'
                                                                    : '1.1rem',
                                                            sm:
                                                                isBriefingOnly ||
                                                                mapDisabled
                                                                    ? '1.3rem'
                                                                    : '1.2rem',
                                                            md: '1.3rem',
                                                        }}
                                                        color="light.100"
                                                        whiteSpace="pre-line"
                                                    >
                                                        {favouritesHeading}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                            <Text
                                                width="100%"
                                                minHeight={{
                                                    base:
                                                        isBriefingOnly ||
                                                        mapDisabled
                                                            ? '50px'
                                                            : '45px',
                                                    md: '50px',
                                                }}
                                                textAlign="center"
                                                fontSize={{
                                                    base: '0.8rem',
                                                    xs: '0.85rem',
                                                }}
                                                mx="auto"
                                                pt={{
                                                    base: '1',
                                                    md: '4',
                                                }}
                                                pb={{
                                                    base: '3',
                                                    md: '9',
                                                }}
                                                px={{
                                                    base: '4',
                                                    md: '8',
                                                }}
                                                color="#868b8e"
                                            >
                                                Select from your saved
                                                favourites list
                                            </Text>
                                        </Flex>

                                        <Button
                                            fontWeight="500"
                                            letterSpacing="0.3px"
                                            {...noFocus}
                                            width="100%"
                                            _disabled={{ opacity: '0.6' }}
                                            height="50"
                                            borderTopRadius="0"
                                            borderBottomRadius="24"
                                            color="white"
                                            background="linear-gradient(345deg, #83a6bf 10%, #476f8d 100%)"
                                            _hover={{
                                                background:
                                                    'linear-gradient(345deg, #80a4bd 10%, #49728f 100%)',
                                            }}
                                            _active={{
                                                background:
                                                    'linear-gradient(345deg, #80a4bd 10%, #49728f 100%)',
                                            }}
                                            fontSize="0.9rem"
                                            onClick={() => {
                                                if (isPremium) {
                                                    setShowPricingTiersModal(
                                                        true
                                                    )
                                                } else {
                                                    setShowFavourites(true)
                                                    setBriefingAreaSource(
                                                        'briefingarea'
                                                    )
                                                }
                                            }}
                                        >
                                            {!isPremium && (
                                                <Text>SELECT FAVOURITE</Text>
                                            )}
                                            {isPremium && (
                                                <Flex
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    flexDirection={{
                                                        base: 'row',
                                                        md: 'row',
                                                        lg: 'row',
                                                        xl: 'row',
                                                    }}
                                                    marginTop={{
                                                        base: '0px',
                                                        md: '0px',
                                                        lg: '0px',
                                                        xl: '0px',
                                                    }}
                                                >
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Badge
                                                            ml="2px"
                                                            fontSize="0.8rem"
                                                            fontWeight="500"
                                                            display="inline-flex"
                                                            justifyContent="space-evenly"
                                                            alignItems="center"
                                                            variant="solid"
                                                            background="brand.premium"
                                                            color="white"
                                                            paddingStart="2"
                                                            paddingEnd="2"
                                                            paddingTop="2px"
                                                            paddingBottom="2px"
                                                            borderRadius="xl"
                                                            minWidth="40px"
                                                            textAlign="center"
                                                        >
                                                            <RiStarLine
                                                                color="#FFFFFF"
                                                                fontSize="14px"
                                                            />
                                                            <Text
                                                                ml="3px"
                                                                mr="2px"
                                                            >
                                                                Premium
                                                            </Text>
                                                        </Badge>
                                                    </Flex>
                                                    <Text
                                                        width="100%"
                                                        marginLeft="5px"
                                                    >
                                                        USERS ONLY
                                                    </Text>
                                                </Flex>
                                            )}
                                        </Button>
                                    </Flex>

                                    {!(isBriefingOnly || mapDisabled) && (
                                        <Flex /// PLOT AREA OPTION
                                            flex="1"
                                            flexShrink={0}
                                            minWidth={0}
                                            width={{
                                                base: '100%',
                                                md: '35%',
                                                lg: '28%',
                                            }}
                                            maxWidth={{
                                                base: '350px',
                                                md: '35%',
                                                lg: '28%',
                                            }}
                                            visibility={
                                                isOpen === true
                                                    ? 'visible'
                                                    : 'hidden'
                                            }
                                            justifyContent="center"
                                            alignContent="space-between"
                                            flexWrap="wrap"
                                            borderRadius="25"
                                            pointerEvents={
                                                showFavourites ? 'none' : 'auto'
                                            }
                                            bg="white"
                                            cursor="pointer"
                                            opacity={isPremium ? '0.75' : '1'}
                                            boxShadow="0px 10px 25px -10px rgba(0,0,0,0.7)"
                                            _hover={{
                                                opacity: '1',
                                                transform: {
                                                    base: 'none',
                                                    lg: `translateY(-3px) translateX(0px) scale(1.01)`,
                                                },
                                                boxShadow: {
                                                    base: '0px 35px 50px -25px rgba(0, 15, 35, 0.5)',
                                                    md: '0px 25px 40px -15px rgba(0, 15, 35, 0.7)',
                                                },
                                            }}
                                            transition="all ease 250ms"
                                        >
                                            <Flex
                                                alignContent="space-between"
                                                flexWrap="wrap"
                                                width="100%"
                                                height="calc(100% - 50px)"
                                                minHeight={{
                                                    base: '3rem',
                                                    md: '16rem',
                                                }}
                                                onClick={() => {
                                                    if (isPremium) {
                                                        setShowPricingTiersModal(
                                                            true
                                                        )
                                                    } else {
                                                        onCloseOptionsHandler()
                                                        mapInstance.flyTo({
                                                            center: isMobileOnly
                                                                ? [
                                                                      172.62,
                                                                      -42.62,
                                                                  ]
                                                                : [
                                                                      172.62,
                                                                      -41.12,
                                                                  ],
                                                            zoom: [
                                                                isMobileOnly
                                                                    ? 4.2
                                                                    : 4.8,
                                                            ],
                                                        })
                                                        setBriefingAreaSource(
                                                            'customArea'
                                                        )
                                                    }
                                                }}
                                                opacity={
                                                    isPremium ? '0.5' : '0.9'
                                                }
                                                _hover={{
                                                    opacity: isPremium
                                                        ? '0.6'
                                                        : '1',
                                                }}
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    flexDirection={{
                                                        base: 'row',
                                                        md: 'column',
                                                    }}
                                                    width="100%"
                                                    mt={{
                                                        base: '15px',
                                                        md: '6',
                                                    }}
                                                >
                                                    <Flex
                                                        my={{
                                                            base: '0',
                                                            md: '1',
                                                        }}
                                                        width={{
                                                            base: 'auto',
                                                            md: '100%',
                                                        }}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        ml={{
                                                            base: '-15px',
                                                            md: '0',
                                                        }}
                                                    >
                                                        <SelectAreaIcon
                                                            width={iconSize}
                                                            height={iconSize}
                                                        />
                                                    </Flex>
                                                    <Flex
                                                        alignItems="center"
                                                        flexWrap={{
                                                            base: 'nowrap',
                                                            md: 'wrap',
                                                        }}
                                                        width={{
                                                            base: 'auto',
                                                            md: '100%',
                                                        }}
                                                        height="100%"
                                                    >
                                                        <Text
                                                            width={{
                                                                base: 'auto',
                                                                md: '100%',
                                                            }}
                                                            pt={{
                                                                base: '2px',
                                                                md: '1',
                                                            }}
                                                            pb={{
                                                                base: '4px',
                                                                md: '0',
                                                            }}
                                                            textAlign="center"
                                                            fontWeight="500"
                                                            fontSize={{
                                                                base: '0.95rem',
                                                                xs: '1.1rem',
                                                                sm: '1.2rem',
                                                                md: '1.3rem',
                                                            }}
                                                            color="light.100"
                                                            whiteSpace="pre-line"
                                                        >
                                                            {customHeading}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                <Text
                                                    width="100%"
                                                    minHeight={{
                                                        base: '45px',
                                                        md: '50px',
                                                    }}
                                                    textAlign="center"
                                                    fontSize={{
                                                        base: '0.8rem',
                                                        xs: '0.85rem',
                                                    }}
                                                    mx="auto"
                                                    pt={{
                                                        base: '1',
                                                        md: '4',
                                                    }}
                                                    pb={{
                                                        base: '3',
                                                        md: '9',
                                                    }}
                                                    px={{
                                                        base: '4',
                                                        md: '8',
                                                    }}
                                                    color="#868b8e"
                                                >
                                                    Draw a customised area on
                                                    the map
                                                </Text>
                                            </Flex>
                                            <Button
                                                fontWeight="500"
                                                letterSpacing="0.3px"
                                                {...noFocus}
                                                width="100%"
                                                _disabled={{ opacity: '0.6' }}
                                                height="50"
                                                borderTopRadius="0"
                                                borderBottomRadius="24"
                                                color="white"
                                                background="linear-gradient(345deg, #2c7eca 10%, #175793 100%)"
                                                _hover={{
                                                    background:
                                                        'linear-gradient(345deg, #2473bb 10%, #10518c 100%)',
                                                }}
                                                _active={{
                                                    background:
                                                        'linear-gradient(345deg, #2473bb 10%, #10518c 100%)',
                                                }}
                                                fontSize="0.9rem"
                                                onClick={() => {
                                                    if (isPremium) {
                                                        setShowPricingTiersModal(
                                                            true
                                                        )
                                                    } else {
                                                        onCloseOptionsHandler()
                                                        mapInstance.flyTo({
                                                            center: isMobileOnly
                                                                ? [
                                                                      172.62,
                                                                      -42.62,
                                                                  ]
                                                                : [
                                                                      172.62,
                                                                      -41.12,
                                                                  ],
                                                            zoom: [
                                                                isMobileOnly
                                                                    ? 4.2
                                                                    : 4.8,
                                                            ],
                                                        })
                                                        setBriefingAreaSource(
                                                            'customArea'
                                                        )
                                                    }
                                                }}
                                            >
                                                {!isPremium && (
                                                    <Text>
                                                        SELECT CUSTOM AREA
                                                    </Text>
                                                )}
                                                {isPremium && (
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        flexDirection={{
                                                            base: 'row',
                                                            md: 'row',
                                                            lg: 'row',
                                                            xl: 'row',
                                                        }}
                                                        marginTop={{
                                                            base: '0px',
                                                            md: '0px',
                                                            lg: '0px',
                                                            xl: '0px',
                                                        }}
                                                    >
                                                        <Flex
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Badge
                                                                ml="2px"
                                                                fontSize="0.8rem"
                                                                fontWeight="500"
                                                                display="inline-flex"
                                                                justifyContent="space-evenly"
                                                                alignItems="center"
                                                                variant="solid"
                                                                background="brand.premium"
                                                                color="white"
                                                                paddingStart="2"
                                                                paddingEnd="2"
                                                                paddingTop="2px"
                                                                paddingBottom="2px"
                                                                borderRadius="xl"
                                                                minWidth="40px"
                                                                textAlign="center"
                                                            >
                                                                <RiStarLine
                                                                    color="#FFFFFF"
                                                                    fontSize="14px"
                                                                />
                                                                <Text
                                                                    ml="3px"
                                                                    mr="2px"
                                                                >
                                                                    Premium
                                                                </Text>
                                                            </Badge>
                                                        </Flex>
                                                        <Text
                                                            width="100%"
                                                            marginLeft="5px"
                                                        >
                                                            USERS ONLY
                                                        </Text>
                                                    </Flex>
                                                )}
                                            </Button>
                                        </Flex>
                                    )}
                                </Stack>
                                {!isPremium && (
                                    <Flex
                                        zIndex={showFavourites ? '3' : '1'}
                                        position="absolute"
                                        justifyContent="center"
                                        alignItems="center"
                                        opacity={!showFavourites ? '0' : '1'}
                                        visibility={
                                            !showFavourites
                                                ? 'hidden'
                                                : 'visible'
                                        }
                                        transition="all ease 300ms"
                                    >
                                        <BriefingFavourites
                                            onOpenOptionsHandler={
                                                onOpenOptionsHandler
                                            }
                                            onCloseOptionsHandler={
                                                onCloseOptionsHandler
                                            }
                                        />
                                    </Flex>
                                )}
                            </ModalContent>
                        </Modal>
                    </LightMode>
                )}
            {(briefingStandard || briefingCustomArea) && (
                <BriefingStandard
                    optionsOpen={isOpen}
                    viewportHeight={viewportHeight}
                />
            )}
            {(briefingAreaSource === 'customArea' ||
                briefingAreaSource === 'customRoute') &&
                !briefingStandard &&
                !briefingCustomArea && <BriefingCustom />}

            {(isBriefingOnly || mapDisabled) && (
                <BriefingOnlySelection
                    setShow={setShow}
                    showBriefingOnlySelection={showBriefingOnlySelection}
                    setShowBriefingOnlySelection={setShowBriefingOnlySelection}
                    viewportHeight={viewportHeight}
                />
            )}
        </>
    )
}
