import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WindParticlesIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M10.3786 3.88367C10.9412 3.32106 11.7043 3.005 12.4999 3.005V3C13.2956 3 14.0587 3.31605 14.6213 3.87866C15.1839 4.44127 15.4999 5.20435 15.4999 6C15.4999 6.79565 15.1839 7.55873 14.6213 8.12134C14.0587 8.68395 13.2956 9 12.4999 9H3.99995V7.5H12.4999C12.7966 7.5 13.0866 7.41201 13.3333 7.24719C13.58 7.08237 13.7722 6.84812 13.8858 6.57404C13.9993 6.29995 14.029 5.99837 13.9711 5.7074C13.9132 5.41643 13.7704 5.14911 13.5606 4.93933C13.3508 4.72955 13.0836 4.58669 12.7926 4.52881C12.5016 4.47093 12.2 4.50067 11.9259 4.6142C11.6518 4.72773 11.4176 4.91995 11.2527 5.16663C11.2247 5.20866 11.1993 5.24873 11.1751 5.28679C11.0577 5.4721 10.9704 5.60983 10.7499 5.695C10.6132 5.74784 10.5244 5.76553 10.3786 5.75C10.1698 5.72777 10.0302 5.66133 9.88995 5.505C9.76257 5.363 9.72321 5.2453 9.70995 5.05501C9.70173 4.9371 9.74995 4.755 9.74995 4.755C9.74995 4.755 9.81602 4.44628 10.3786 3.88367Z"
                fill="currentColor"
            />
            <path
                d="M16.4999 15.005H11.5861L10.5756 13.505H16.4999C17.2956 13.505 18.0587 13.8211 18.6213 14.3837C19.1839 14.9463 19.4999 15.7094 19.4999 16.505C19.4999 17.3007 19.1839 18.0637 18.6213 18.6263C18.0587 19.189 17.2956 19.505 16.4999 19.505V19.5C15.7043 19.5 14.9412 19.1839 14.3786 18.6213C13.816 18.0587 13.7499 17.75 13.7499 17.75C13.7499 17.75 13.7017 17.5679 13.7099 17.45C13.7232 17.2597 13.7626 17.142 13.8899 17C14.0302 16.8437 14.1698 16.7772 14.3786 16.755C14.5244 16.7395 14.6132 16.7572 14.7499 16.81C14.9704 16.8952 15.0577 17.0329 15.1751 17.2182C15.1993 17.2563 15.2247 17.2963 15.2527 17.3384C15.4176 17.5851 15.6518 17.7773 15.9259 17.8908C16.2 18.0043 16.5016 18.0341 16.7926 17.9762C17.0836 17.9183 17.3508 17.7755 17.5606 17.5657C17.7704 17.3559 17.9132 17.0886 17.9711 16.7976C18.029 16.5066 17.9993 16.2051 17.8858 15.931C17.7722 15.6569 17.58 15.4226 17.3333 15.2578C17.0866 15.093 16.7966 15.005 16.4999 15.005Z"
                fill="currentColor"
            />
            <path
                d="M18.9999 6.005C18.2043 6.005 17.4412 6.32106 16.8786 6.88367C16.316 7.44628 16.2499 7.755 16.2499 7.755C16.2499 7.755 16.2017 7.9371 16.2099 8.05501C16.2232 8.2453 16.2626 8.363 16.3899 8.505C16.5302 8.66133 16.6698 8.72777 16.8786 8.75C17.0244 8.76553 17.1132 8.74784 17.2499 8.69501C17.4704 8.60983 17.5577 8.4721 17.6751 8.28679C17.6993 8.24873 17.7247 8.20865 17.7527 8.16663C17.9176 7.91995 18.1518 7.72773 18.4259 7.6142C18.7 7.50067 19.0016 7.47093 19.2926 7.52881C19.5836 7.58669 19.8508 7.72955 20.0606 7.93933C20.2704 8.14911 20.4132 8.41643 20.4711 8.7074C20.529 8.99837 20.4993 9.29995 20.3858 9.57404C20.2722 9.84812 20.08 10.0824 19.8333 10.2472C19.5866 10.412 19.2966 10.5 18.9999 10.5H2V12H18.9999C19.7956 12 20.5587 11.6839 21.1213 11.1213C21.6839 10.5587 21.9999 9.79565 21.9999 9C21.9999 8.20435 21.6839 7.44127 21.1213 6.87866C20.5587 6.31605 19.7956 6 18.9999 6V6.005Z"
                fill="currentColor"
            />
            <path
                d="M3.99995 18C4.55223 18 4.99995 17.5523 4.99995 17C4.99995 16.4477 4.55223 16 3.99995 16C3.44766 16 2.99995 16.4477 2.99995 17C2.99995 17.5523 3.44766 18 3.99995 18Z"
                fill="currentColor"
            />
            <path
                d="M8.99995 17.5C8.99995 18.0523 8.55223 18.5 7.99995 18.5C7.44766 18.5 6.99995 18.0523 6.99995 17.5C6.99995 16.9477 7.44766 16.5 7.99995 16.5C8.55223 16.5 8.99995 16.9477 8.99995 17.5Z"
                fill="currentColor"
            />
            <path
                d="M8.49995 15C9.05223 15 9.49995 14.5523 9.49995 14C9.49995 13.4477 9.05223 13 8.49995 13C7.94766 13 7.49995 13.4477 7.49995 14C7.49995 14.5523 7.94766 15 8.49995 15Z"
                fill="currentColor"
            />
            <path
                d="M6.49995 14.5C6.49995 15.0523 6.05223 15.5 5.49995 15.5C4.94766 15.5 4.49995 15.0523 4.49995 14.5C4.49995 13.9477 4.94766 13.5 5.49995 13.5C6.05223 13.5 6.49995 13.9477 6.49995 14.5Z"
                fill="currentColor"
            />
            <path
                d="M10.9999 19.5C11.5522 19.5 11.9999 19.0523 11.9999 18.5C11.9999 17.9477 11.5522 17.5 10.9999 17.5C10.4477 17.5 9.99995 17.9477 9.99995 18.5C9.99995 19.0523 10.4477 19.5 10.9999 19.5Z"
                fill="currentColor"
            />
            <path
                d="M6.49995 20C6.49995 20.5523 6.05223 21 5.49995 21C4.94766 21 4.49995 20.5523 4.49995 20C4.49995 19.4477 4.94766 19 5.49995 19C6.05223 19 6.49995 19.4477 6.49995 20Z"
                fill="currentColor"
            />
        </Icon>
    )
}
