// Dynamically loads SVG icons for use in react-mapbox-gl

import React, { useState, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { Image as Icon } from 'react-mapbox-gl'
import DynamicIcons from './dynamic'
import { mapLoadingStaticIconsState } from '../../globalState'

export default function Icons() {
    const [icons, setIcons] = useState(null)
    const setMapLoadingStaticIcons = useSetRecoilState(
        mapLoadingStaticIconsState
    )

    // Load icons once only on load
    useEffect(() => {
        // Creates HTML image element and attaches svg data to it.
        const loadSvgAsHtmlImage = (svgData) => {
            return new Promise((resolve) => {
                const image = new Image(32, 32)
                image.addEventListener('load', () => {
                    image.setAttribute(
                        'width',
                        Math.max(1, Math.floor(image.naturalWidth))
                    )
                    image.setAttribute(
                        'height',
                        Math.max(1, Math.floor(image.naturalHeight))
                    )
                    return resolve(image)
                })
                image.src = svgData
            })
        }

        // Creates an svgs object that lists the name of the svg, and returns an HTML image element from loadSvgAsHtmlImage.
        const importAll = async (r) => {
            const svgs = {}

            return new Promise((resolve) => {
                r.keys().map(async (item, index) => {
                    var key = item.replace('./', '').replace('.svg', '') // remove leading ./ and trailing filetype
                    key = key.substring(key.lastIndexOf('/') + 1) // remove name of folder that the icon is in

                    // Load SVG as HTML Image, then add to svgs object, then if the last svg, resolve promise
                    loadSvgAsHtmlImage(r(item))
                        .then((data) => (svgs[key] = data))
                        .then(() => {
                            if (r.keys().length - 1 === index) {
                                resolve()
                            }
                        })
                })
            }).then(() => {
                return svgs
            })
        }

        // This imports all svg icons in the recursive directories and makes them available to mapbox (mapbox id will match filename)
        // Regex for other image types: /\.(png|jpe?g|svg)$/
        const loadSvgs = importAll(
            require.context(
                '!babel-loader!svg-url-loader?encoding=base64!./',
                true,
                /\.svg$/
            )
        )

        // Load the SVGs then setIcons with react-mapbox-gl formatted Image (renamed Icon)
        loadSvgs.then((svgs) => {
            setIcons(
                Object.keys(svgs).map((icon, index) => {
                    return (
                        <Icon
                            id={icon}
                            key={icon}
                            data={svgs[icon]}
                            onLoaded={() => {
                                if (index === Object.keys(svgs).length - 1) {
                                    setMapLoadingStaticIcons(false)
                                }
                            }} // when last icon loaded then allow layers to load
                            onError={(err) => console.log(err)}
                        />
                    )
                })
            )
        })
    }, [setMapLoadingStaticIcons])

    return (
        <>
            {icons}
            <DynamicIcons />
        </>
    )
}
