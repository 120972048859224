import React from 'react'
import WXRasterTiles from './components/WXRasterTiles'

export default function satelliteCloudTops() {
    return (
        <WXRasterTiles
            layerId={'satelliteCloudTops'}
            timesURL={
                'https://api.wxtiles.com/v1/wxtiles/layer/jma-himawari-oceania-cth/instance/him8-oceania_cth/'
            }
            rasterURL={
                'https://api.wxtiles.com/v1/wxtiles/tile/jma-himawari-oceania-cth/cloud-top-height-aviation/him8-oceania_cth/'
            }
        />
    )
}

