import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function TimeIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 6} color={color || colorHandler()}>
            <path
                d="M12.5 7V12.25L17 14.92L16.25 16.15L11 13V7H12.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25ZM12 19.75C16.2802 19.75 19.75 16.2802 19.75 12C19.75 7.71979 16.2802 4.25 12 4.25C7.71979 4.25 4.25 7.71979 4.25 12C4.25 16.2802 7.71979 19.75 12 19.75Z"
                fill="currentColor"
            />
        </Icon>
    )
}
