import React from 'react'
import { useRecoilState } from 'recoil'
import { Tooltip, Center, Icon } from '@chakra-ui/react'
import { isDesktop, isIOS } from 'react-device-detect'
import { ReactComponent as RoadIcon } from '../../../../icons/assets/road.svg'

import { displayRoadsState } from '../../../../globalState'

export default function RoadButton() {
    const [displayRoads, setDisplayRoads] = useRecoilState(displayRoadsState)

    return (
        <Tooltip
            display={isDesktop ? 'flex' : 'none'}
            isDisabled={isDesktop ? false : true}
            label={displayRoads ? 'Hide Roads' : 'Show Roads'}
            hasArrow
            borderRadius="7px"
            height="26px"
            fontSize="0.7rem"
            alignItems="center"
            placement="left"
            m="5px"
            color="light.100"
            bg="light.10"
        >
            <Center
                w={{ base: '32px', md: '30px' }}
                h={{ base: '32px', md: '30px' }}
                pt={'9px'}
                pb={'10px'}
                borderRadius="10px"
                backgroundColor={displayRoads && 'rgba(255,255,255,0.4)'}
                _hover={{
                    backgroundColor:
                        (isDesktop || !isIOS) && 'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                _focus={{
                    backgroundColor:
                        displayRoads &&
                        (isDesktop || !isIOS) &&
                        'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setDisplayRoads((prevState) => !prevState)
                }}
            >
                <Icon
                    as={RoadIcon}
                    width="22px"
                    height="22px"
                    color={'light.10'}
                />
            </Center>
        </Tooltip>
    )
}
