import React from 'react'
import { Flex, Text, useBreakpointValue, Icon } from '@chakra-ui/react'
import TemperatureIcon from '../../../../../icons/menu-icons/TemperatureIcon'
import DewpointIcon from '../../../../../icons/menu-icons/DewPointIcon'
import { TempDial } from '../../graphics/WeatherGraphics'

export default function AerodromesTempAndDewpoint(props) {
    const {
        metarActive,
        timePeriod,
        aerodromeBackgroundsDisabled,
        cloudCoverBoxColor,
        metarCloud,
        temp,
        dewpoint,
        infotabGraphicsDisabled,
    } = props

    const imageSize = useBreakpointValue({
        base: '110',
        md: '90',
    })

    const tempValue = temp || temp === 0
    const dewpointValue = dewpoint || dewpoint === 0

    return (
        <Flex
            width="100%"
            gap={
                !infotabGraphicsDisabled && {
                    base: '20px',
                    md: '10px',
                }
            }
            flexDirection={infotabGraphicsDisabled ? 'column' : 'row'}
        >
            {tempValue && (
                <Flex
                    order="1"
                    width={metarActive ? '100%' : 'auto'}
                    background={
                        !infotabGraphicsDisabled &&
                        (metarActive && !aerodromeBackgroundsDisabled
                            ? cloudCoverBoxColor(
                                  timePeriod === 'Night'
                                      ? 'Night'
                                      : timePeriod === 'Sunrise'
                                      ? 'Sunrise'
                                      : timePeriod === 'Sunset'
                                      ? 'Sunset'
                                      : metarCloud
                                      ? metarCloud
                                      : 'white'
                              )
                            : 'white')
                    }
                    border={metarActive ? 'none' : '1px solid rgb(229 235 242)'}
                    boxShadow={
                        !infotabGraphicsDisabled && aerodromeBackgroundsDisabled
                            ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                            : 'none'
                    }
                    filter={
                        infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                            ? 'none'
                            : 'contrast(102%) saturate(102%)'
                    }
                    backdropFilter={
                        infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                            ? 'none'
                            : 'blur(25px) contrast(102%) saturate(102%)'
                    }
                    borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
                    pt="10px"
                    pl={{
                        base: '15px',
                        md: '15px',
                    }}
                    pr={{
                        base: '15px',
                        md: '15px',
                    }}
                    pb="7px"
                    direction={{
                        base: 'column',
                        md: 'column',
                    }}
                >
                    <Flex
                        direction="row"
                        justifyContent={{
                            base: 'space-between',
                            md: 'flex-start',
                        }}
                    >
                        <Flex
                            width={{
                                base: '41px',
                                md: '43px',
                            }}
                            ml={infotabGraphicsDisabled ? '-2px' : '-10px'}
                            color={
                                aerodromeBackgroundsDisabled
                                    ? '#9DB2CD'
                                    : 'rgba(255,255,255,0.6)'
                            }
                        >
                            <TemperatureIcon boxSize="36px" />
                        </Flex>
                        <Flex
                            direction="column"
                            width="100%"
                            alignItems={
                                infotabGraphicsDisabled
                                    ? 'flex-start'
                                    : 'flex-end'
                            }
                            pr="3px"
                            pl={infotabGraphicsDisabled ? '15px' : '10px'}
                        >
                            <Text
                                whiteSpace={{
                                    base: 'pre',
                                    md: 'unset',
                                }}
                                fontSize={{
                                    base: tempValue ? '1.2rem' : '0.85rem',
                                    xs: tempValue ? '1.4rem' : '1rem',
                                    md: tempValue ? '1.2rem' : '0.8rem',
                                }}
                                lineHeight="1.2"
                                pt={{
                                    base: tempValue ? '4px' : '7px',
                                    md: tempValue ? '5px' : '10px',
                                }}
                                textAlign="right"
                                fontWeight="600"
                            >
                                {temp === 0 // TODO should be <= 0
                                    ? '0 °C'
                                    : temp
                                    ? `${temp} °C`
                                    : 'Not AVBL'}
                            </Text>
                        </Flex>
                    </Flex>
                    {!infotabGraphicsDisabled && (
                        <Flex
                            pt="2px"
                            width="100%"
                            justifyContent={{
                                base: 'center',
                                md: 'center',
                            }}
                            alignItems={{
                                base: 'center',
                                md: 'center',
                            }}
                        >
                            <Icon
                                as={TempDial}
                                imageSize={imageSize}
                                primaryImageColor={
                                    aerodromeBackgroundsDisabled
                                        ? '#3e5e88'
                                        : 'white'
                                }
                                focusImageColor={`hsl(${
                                    temp < 3
                                        ? 220
                                        : temp > 30
                                        ? 0
                                        : 220 - (temp - 3) * 8
                                }, 100%, 50%)`}
                                imageColor={
                                    aerodromeBackgroundsDisabled
                                        ? '#83909F'
                                        : 'white'
                                }
                                data={temp}
                                type={'TEMP'}
                            />
                        </Flex>
                    )}
                </Flex>
            )}

            {dewpointValue && (
                <Flex
                    order={{ base: '2', md: '2' }}
                    width={metarActive ? '100%' : 'auto'}
                    background={
                        !infotabGraphicsDisabled &&
                        (metarActive && !aerodromeBackgroundsDisabled
                            ? cloudCoverBoxColor(
                                  timePeriod === 'Night'
                                      ? 'Night'
                                      : timePeriod === 'Sunrise'
                                      ? 'Sunrise'
                                      : timePeriod === 'Sunset'
                                      ? 'Sunset'
                                      : metarCloud
                                      ? metarCloud
                                      : 'white'
                              )
                            : 'white')
                    }
                    border={metarActive ? 'none' : '1px solid rgb(229 235 242)'}
                    boxShadow={
                        !infotabGraphicsDisabled && aerodromeBackgroundsDisabled
                            ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                            : 'none'
                    }
                    filter={
                        infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                            ? 'none'
                            : 'contrast(102%) saturate(102%)'
                    }
                    backdropFilter={
                        infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                            ? 'none'
                            : 'blur(25px) contrast(102%) saturate(102%)'
                    }
                    borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
                    pt="10px"
                    pl={{
                        base: '15px',
                        md: '15px',
                    }}
                    pr={{
                        base: '15px',
                        md: '15px',
                    }}
                    pb="7px"
                    direction={{
                        base: 'column',
                        md: 'column',
                    }}
                >
                    <Flex
                        direction="row"
                        justifyContent={{
                            base: 'space-between',
                            md: 'flex-start',
                        }}
                    >
                        <Flex
                            width={{
                                base: '41px',
                                md: '43px',
                            }}
                            ml={infotabGraphicsDisabled ? '-2px' : '-10px'}
                            color={
                                aerodromeBackgroundsDisabled
                                    ? '#9db2cd'
                                    : 'rgba(255,255,255,0.6)'
                            }
                        >
                            <DewpointIcon boxSize="36px" />
                        </Flex>
                        <Flex
                            direction="column"
                            width="100%"
                            alignItems={
                                infotabGraphicsDisabled
                                    ? 'flex-start'
                                    : 'flex-end'
                            }
                            pr="3px"
                            pl={infotabGraphicsDisabled ? '15px' : '10px'}
                        >
                            <Text
                                whiteSpace={{
                                    base: 'pre',
                                    md: 'unset',
                                }}
                                fontSize={{
                                    base: dewpointValue ? '1.2rem' : '0.85rem',
                                    xs: dewpointValue ? '1.4rem' : '1rem',
                                    md: dewpointValue ? '1.2rem' : '0.8rem',
                                }}
                                lineHeight="1.2"
                                pt={{
                                    base: dewpointValue ? '4px' : '7px',
                                    md: dewpointValue ? '5px' : '10px',
                                }}
                                textAlign="right"
                                fontWeight="600"
                            >
                                {dewpoint === 0 // TODO should be <= 0
                                    ? '0 °C'
                                    : dewpoint
                                    ? `${dewpoint} °C`
                                    : 'Not AVBL'}
                            </Text>
                        </Flex>
                    </Flex>
                    {!infotabGraphicsDisabled && (
                        <Flex
                            pt="2px"
                            width="100%"
                            justifyContent={{
                                base: 'center',
                                md: 'center',
                            }}
                            alignItems={{
                                base: 'center',
                                md: 'center',
                            }}
                        >
                            <Icon
                                as={TempDial}
                                imageSize={imageSize}
                                primaryImageColor={
                                    aerodromeBackgroundsDisabled
                                        ? '#3e5e88'
                                        : 'white'
                                }
                                focusImageColor={`hsl(${
                                    dewpoint < 3
                                        ? 220
                                        : dewpoint > 30
                                        ? 0
                                        : 220 - (dewpoint - 3) * 8
                                }, 100%, 50%)`}
                                imageColor={
                                    aerodromeBackgroundsDisabled
                                        ? '#83909F'
                                        : 'white'
                                }
                                data={dewpoint}
                                type={'DEWPOINT'}
                            />
                        </Flex>
                    )}
                </Flex>
            )}
        </Flex>
    )
}
