import React, { useContext, useState, useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import { isEqual } from 'lodash'

import Toast from './components/Toast'
import toastDefinitions from './definitions'
import {
    dataModeState,
    layerSelectionState,
    globalTimeState,
    altitudeDisplayVisibleState,
    locationToastActiveState,
    modalVisibilityState,
} from '../../globalState'
import './index.css'

const layers = [
    'notam-fill',
    'rpas-fill',
    'rpas-symbol',
    'sigmet-fill',
    'sigwx-fill',
    'sigwx-symbol-static',
    'lightning-symbol',
    'lightning-animated-symbol',
]

export default function HoverToasts() {
    const mapInstance = useContext(MapContext)
    const [data, setData] = useState([])
    const dataRef = useRef([])
    const [trailing, setTrailing] = useState(false)
    const [currentLayers, setCurrentLayers] = useState([])
    const currentView = useRecoilValue(dataModeState)
    const selectionState = useRecoilValue(layerSelectionState)
    const altitudeDisplayVisible = useRecoilValue(altitudeDisplayVisibleState)
    const locationToastActive = useRecoilValue(locationToastActiveState)
    const modalVisible = useRecoilValue(modalVisibilityState)
    const [legendActive, setLegendActive] = useState(false)
    // const [hoverTrigger, setHoverTrigger] = useState(false)
    const now = useRecoilValue(globalTimeState)

    useEffect(() => {
        const mapLayers = mapInstance.getStyle().layers.map((layer) => layer.id)
        setCurrentLayers(layers.filter((item) => mapLayers.includes(item)))
    }, [currentView, selectionState, mapInstance])

    useEffect(() => {
        let delayID

        const handleMouseMove = (e) => {
            clearInterval(delayID)

            delayID = setTimeout(() => {
                var features = mapInstance.queryRenderedFeatures(e.point, {
                    layers: currentLayers,
                })
                // Limit the number of properties
                var displayProperties = [
                    'type',
                    'properties',
                    'id',
                    'layer',
                    'source',
                    'sourceLayer',
                    'state',
                ]

                let displayFeatures = features.map(function (feat) {
                    var displayFeat = {}
                    displayProperties.forEach(function (prop) {
                        displayFeat[prop] = feat[prop]
                    })
                    return displayFeat
                })

                //Filter reapeated items
                displayFeatures = displayFeatures.filter(
                    (thing, index, self) =>
                        index ===
                        self.findIndex(
                            (t) =>
                                (Object.keys(t.properties).length &&
                                    t.properties.id === thing.properties.id) ||
                                (t.id && t.id === thing.id)
                        )
                )

                setTrailing(displayFeatures.length > 5)
                displayFeatures =
                    displayFeatures.length > 5
                        ? displayFeatures.slice(0, 5)
                        : displayFeatures
                let filteredData = [
                    ...displayFeatures.sort(function (a, b) {
                        let first =
                            a.properties.lowerlimit ||
                            a.properties.lowerfilter ||
                            a.properties.lowerlabel
                        let second =
                            b.properties.lowerlimit ||
                            b.properties.lowerfilter ||
                            b.properties.lowerlabel
                        return second - first
                    }),
                ]
                if (
                    !isEqual(dataRef.current, filteredData) ||
                    !filteredData.length
                ) {
                    setData(filteredData)
                    dataRef.current = filteredData
                }
            }, 100)
        }

        mapInstance.on('mousemove', handleMouseMove)

        return () => mapInstance.off('mousemove', handleMouseMove)
    }, [currentLayers, mapInstance])

    // Check if Map Legend modal is open
    // const legendActive =
    //     modalVisible &&
    //     Object.entries(modalVisible).filter(
    //         (layer) => layer[0] === 'legend'
    //     )[0][1]

    useEffect(() => {
        const legendIsActive =
            modalVisible &&
            Object.entries(modalVisible).filter(
                (modal) => modal[0] === 'legend'
            )[0][1]
        if (legendIsActive) {
            // console.log('legendActive is True')
            setLegendActive(true)
        } else {
            // console.log('legendActive is False')
            setLegendActive(false)
        }
    }, [modalVisible])

    // useEffect(() => {
    //     console.log('hoverTrigger: ', hoverTrigger)
    // }, [hoverTrigger])

    return (
        <div
            className={`toast-container${
                locationToastActive
                    ? ' locationToast-visible'
                    : legendActive
                    ? ' legendActive-visible'
                    : altitudeDisplayVisible
                    ? ' altitude-display-visible'
                    : ''
            }`}
            style={{
                alignItems: 'flex-end',
            }}
        >
            {data.map(
                (i) =>
                    toastDefinitions(i, now) && (
                        <Toast
                            key={`${i.source}-${i.properties.id}`}
                            config={toastDefinitions(i, now)}
                        />
                    )
            )}
            {trailing && (
                <div
                    className="toast-item"
                    style={{
                        color: '#072f5f',
                        width: '180px',
                        opacity: 0.7,
                    }}
                >
                    <div className="toast-text-container">
                        <h3
                            className="toast-text"
                            style={{
                                marginTop: '-5px',
                                marginLeft: 'auto',
                                textShadow: '0px 1px 2px rgba(0,0,0,0.5)',
                            }}
                        >
                            . . .
                            <span
                                style={{ marginLeft: '5px', fontWeight: '500' }}
                            >
                                more not listed
                            </span>
                        </h3>
                    </div>
                </div>
            )}
        </div>
    )
}
