import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function TimelapseIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 6} color={color || colorHandler()}>
            <path
                d="M11.5 17V11.75L7 9.08L7.75 7.85L13 11V17H11.5Z"
                fill="currentColor"
            />
            <path
                d="M1.33494 12L3.5 8.625L5.66506 12H4.25C4.25 16.2802 7.71979 19.75 12 19.75C16.2802 19.75 19.75 16.2802 19.75 12C19.75 7.71979 16.2802 4.25 12 4.25C9.85704 4.25 7.91723 5.11976 6.51431 6.52554L5.42786 5.49082C7.1042 3.79836 9.42967 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.33494Z"
                fill="currentColor"
            />
        </Icon>
    )
}
