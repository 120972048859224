import React, { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useInView } from 'react-intersection-observer'
import { Flex, Text, CircularProgress, Box } from '@chakra-ui/react'
import NoAerodromeInfo, { NoAwsInfo } from './briefing-content/NoInfo'

import AerodromeBriefing from './briefing-content/AerodromeBriefing'
import AWSBriefing from './briefing-content/AWSBriefing'
import EnRouteBriefing from './briefing-content/EnRouteBriefing'
import AAWBriefing from './briefing-content/AAWBriefing'
import SigmetBriefing from './briefing-content/SigmetBriefing'
import SigwxBriefing from './briefing-content/SigwxBriefing'
import GraforBriefing from './briefing-content/GraforBriefing'
import MSLBriefing from './briefing-content/MSLBriefing'
import SpaceWxBriefing from './briefing-content/SpaceWxBriefing'
import METARBriefing from './briefing-content/METARBriefing'
import TAFBriefing from './briefing-content/TAFBriefing'
import ATISBriefing from './briefing-content/ATISBriefing'
import NOTAMBriefing from './briefing-content/NOTAMBriefing'
import { ReactComponent as NoItemsIcon } from '../../../../icons/assets/brief-all-deselected.svg'
import {
    briefingParamsState,
    briefingOrderState,
    inViewPositionState,
    altAerodromeLayoutState,
} from '../../../../globalState'

export default function BriefingStandardContent(props) {
    const {
        briefingData,
        dataLoad: dataLoading,
        dataLoadError: dataLoadingError,
        fullscreenRef,
        fullscreenHandler,
        setFullscreenHandler,
        isPremium,
        scale,
        setScale,
        xy,
        setXy,
        handleReset,
        setHandleReset,
    } = props

    const setInViewPosition = useSetRecoilState(inViewPositionState)

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        // inView && setInViewPosition('aerodromesContainer')
    }, [inView, setInViewPosition])

    const params = useRecoilValue(briefingParamsState)
    const altBriefingOrder = useRecoilValue(briefingOrderState)
    const altAerodromeLayout = useRecoilValue(altAerodromeLayoutState)

    const allItemsDeselected =
        !params.aerodromes &&
        !params.aerodromesNotam &&
        !params.aerodromesMetar &&
        !params.aerodromesTaf &&
        !params.aerodromesAtis &&
        !params.aws &&
        !params.enroute &&
        !params.aaw &&
        !params.grafor &&
        !params.msl &&
        !params.sigmet &&
        !params.sigwx &&
        !params.spacewx

    // determine if AWS has no data, or only data returned is timestamp
    const awsWithNoDataList =
        briefingData &&
        briefingData.res &&
        briefingData.res.aws &&
        briefingData.res.aws
            .sort((a, b) => {
                const awsA = a.designator
                const awsB = b.designator
                if (awsA < awsB) {
                    return -1
                }
                if (awsA > awsB) {
                    return 1
                } else {
                    return 0
                }
            })
            .filter(
                (key) =>
                    !key.data ||
                    (key.data?.obs_timestamp &&
                        Object.keys(key.data)?.length === 1)
            )
            .map((aws) => aws.designator)
            .join(', ')
    const awsWithNoData = awsWithNoDataList && awsWithNoDataList.length > 0

    return (
        <>
            <Flex
                className="briefingStandardContent"
                w="100%"
                flexDirection={altBriefingOrder ? 'column-reverse' : 'column'}
                justifyContent="space-between"
                marginBottom={{
                    base: altBriefingOrder ? '10px' : '30px',
                    md: '55px',
                }}
                py="0px"
                px="0px"
            >
                {dataLoading && (
                    <Flex
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                        pt="40px"
                    >
                        <CircularProgress
                            isIndeterminate
                            size="60px"
                            thickness="5px"
                            color="light.200"
                        ></CircularProgress>
                    </Flex>
                )}
                {!dataLoading && dataLoadingError && (
                    <Text textAlign="center" py="20px">
                        Error Loading Briefing
                    </Text>
                )}

                <Flex w="100%" flexDirection="column">
                    {/* AERODROMES BRIEFING */}
                    {!dataLoading &&
                        params.aerodromes &&
                        !altAerodromeLayout && ( // Default Aerodrome layout (individual aerodrome boxes)
                            <>
                                <AerodromeBriefing
                                    briefingData={briefingData}
                                />
                                <div
                                    id="aerodromesContainer"
                                    style={{ marginTop: '1px' }}
                                    ref={ref}
                                ></div>
                            </>
                        )}
                    {!dataLoading &&
                        params.aerodromes &&
                        altAerodromeLayout && ( // Alternative Aerodrome layout (listed by data type, i.e. METAR, TAF, ATIS, NOTAM)
                            <>
                                <div
                                    id="aerodromesContainer"
                                    style={{ marginTop: '1px' }}
                                    ref={ref}
                                ></div>
                                {params.aerodromesNotam &&
                                    briefingData?.res?.aerodromes && (
                                        <NOTAMBriefing
                                            briefingData={briefingData}
                                        />
                                    )}
                                {params.aerodromesMetar &&
                                    briefingData?.res?.aerodromes && (
                                        <METARBriefing
                                            briefingData={briefingData}
                                        />
                                    )}
                                {params.aerodromesTaf &&
                                    briefingData?.res?.aerodromes && (
                                        <TAFBriefing
                                            briefingData={briefingData}
                                        />
                                    )}
                                {params.aerodromesAtis &&
                                    briefingData?.res?.aerodromes && (
                                        <ATISBriefing
                                            briefingData={briefingData}
                                        />
                                    )}
                            </>
                        )}

                    {!dataLoading && // List of AERODROMES with no data
                        params.aerodromes &&
                        (params.aerodromesNotam ||
                            params.aerodromesMetar ||
                            params.aerodromesTaf ||
                            params.aerodromesAtis) &&
                        briefingData &&
                        briefingData.res && (
                            <NoAerodromeInfo briefingData={briefingData} />
                        )}

                    {!dataLoading &&
                        params.aerodromes &&
                        (params.aerodromesNotam ||
                            params.aerodromesMetar ||
                            params.aerodromesTaf ||
                            params.aerodromesAtis) && (
                            <Box marginBottom="30px"></Box> // adds margin to bottom of Aerodrome containers to avoid adding in between all.
                        )}

                    {/* AUTOMATIC WEATHER STATIONS BRIEFING */}
                    {!dataLoading &&
                        params.aws &&
                        briefingData &&
                        !isPremium && (
                            <>
                                <AWSBriefing // Default AWS layout (individual AWS boxes)
                                    briefingData={briefingData}
                                />
                                <div
                                    id="awsContainerEnd"
                                    style={{ marginTop: '1px' }}
                                    ref={ref}
                                ></div>
                            </>
                        )}
                    {!dataLoading && // List of AWS with no data
                        params.aws &&
                        briefingData &&
                        briefingData.res &&
                        !isPremium && (
                            <NoAwsInfo
                                briefingData={briefingData}
                                awsWithNoData={awsWithNoData}
                                awsWithNoDataList={awsWithNoDataList}
                                dataLoading={dataLoading}
                                isPremium={isPremium}
                            />
                        )}

                    {!dataLoading && params.aws && !isPremium && (
                        <Box marginBottom="30px"></Box> // adds margin to bottom of Aerodrome containers to avoid adding in between all.
                    )}
                </Flex>

                <Flex
                    w="100%"
                    flexDirection="column"
                    marginTop={altBriefingOrder ? '30px' : '0px'}
                >
                    {/* EN-ROUTE BRIEFING */}
                    {!dataLoading && params.enroute && briefingData && (
                        <EnRouteBriefing briefingData={briefingData} />
                    )}
                </Flex>

                <Flex w="100%" flexDirection="column">
                    {/* *** AREA MET BRIEFING *** */}
                    {/* AAW BRIEFING */}
                    {!dataLoading && params.aaw && briefingData && (
                        <AAWBriefing briefingData={briefingData} />
                    )}
                    {/* GRAFOR BRIEFING */}
                    {!dataLoading && params.grafor && briefingData && (
                        <GraforBriefing
                            briefingData={briefingData}
                            fullscreenRef={fullscreenRef}
                            fullscreenHandler={fullscreenHandler}
                            setFullscreenHandler={setFullscreenHandler}
                            scale={scale}
                            setScale={setScale}
                            xy={xy}
                            setXy={setXy}
                            handleReset={handleReset}
                            setHandleReset={setHandleReset}
                        />
                    )}

                    {/* MEAN SEAL LEVEL BRIEFING */}
                    {!dataLoading && params.msl && briefingData && (
                        <MSLBriefing
                            briefingData={briefingData}
                            fullscreenRef={fullscreenRef}
                            fullscreenHandler={fullscreenHandler}
                            setFullscreenHandler={setFullscreenHandler}
                            scale={scale}
                            setScale={setScale}
                            xy={xy}
                            setXy={setXy}
                            handleReset={handleReset}
                            setHandleReset={setHandleReset}
                        />
                    )}

                    {/* SIGMET BRIEFING */}
                    {!dataLoading && params.sigmet && briefingData && (
                        <SigmetBriefing
                            briefingData={briefingData}
                            fullscreenRef={fullscreenRef}
                            fullscreenHandler={fullscreenHandler}
                            setFullscreenHandler={setFullscreenHandler}
                            scale={scale}
                            setScale={setScale}
                            xy={xy}
                            setXy={setXy}
                            handleReset={handleReset}
                            setHandleReset={setHandleReset}
                        />
                    )}

                    {/* SIGWX BRIEFING */}
                    {!dataLoading && params.sigwx && briefingData && (
                        <SigwxBriefing
                            briefingData={briefingData}
                            fullscreenRef={fullscreenRef}
                            fullscreenHandler={fullscreenHandler}
                            setFullscreenHandler={setFullscreenHandler}
                            scale={scale}
                            setScale={setScale}
                            xy={xy}
                            setXy={setXy}
                            handleReset={handleReset}
                            setHandleReset={setHandleReset}
                        />
                    )}

                    {/* SPACE WEATHER BRIEFING */}
                    {!dataLoading && params.spacewx && briefingData && (
                        <SpaceWxBriefing briefingData={briefingData} />
                    )}
                </Flex>
            </Flex>

            {/* ALL ITEMS DESELECTED */}
            {!dataLoading && allItemsDeselected && (
                <Flex
                    mt="100px"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    color="light.300"
                >
                    <NoItemsIcon
                        width="100px"
                        height="100px"
                        color="light.300"
                    />

                    <Flex
                        mt="20px"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                    >
                        <Text
                            color="light.300"
                            fontSize="1.1rem"
                            fontWeight="600"
                            lineHeight="1.3"
                            pb="5px"
                        >
                            All items have been deselected
                        </Text>
                        <Flex flexDirection="row" flexWrap="wrap">
                            <Text
                                flexDirection="row"
                                color="light.300"
                                fontSize="0.9rem"
                                fontWeight="400"
                                lineHeight="1.3"
                                pt="5px"
                            >
                                Select items from the filters in the menu
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    )
}
