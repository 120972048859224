import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function NavAidIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 20H6.9933L7 20.0107H17L17.0067 20H22V3.99999H17.0067L17 3.98926H7L6.9933 3.99999H2V20ZM3.76821 12L7.82526 18.5H16.1747L20.2318 12L16.1747 5.49999H7.82526L3.76821 12ZM3.5 14.4032L6.05706 18.5H3.5V14.4032ZM20.5 18.5H17.9429L20.5 14.4032V18.5ZM20.5 5.49999V9.59677L17.9429 5.49999H20.5ZM3.5 5.49999H6.05706L3.5 9.59677V5.49999Z"
                fill="currentColor"
            />
        </Icon>
    )
}
