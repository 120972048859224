import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function MslIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M10.0383 19.656C10.6031 20.5686 11.5053 20.9319 12.3161 20.6638C13.1185 20.3985 13.6236 19.583 13.5432 18.5303C14.0223 18.2103 14.4473 17.8181 14.8494 17.3472C15.9891 17.2578 16.7282 17.2936 17.9364 17.4692C17.3076 16.3499 17.0074 15.6685 16.7004 14.5137C17.0772 13.8325 17.4793 13.0659 17.9262 12.2067C18.0937 11.8849 17.9664 11.4893 17.642 11.3232C17.3176 11.1571 16.9189 11.2833 16.7515 11.6052C15.8665 13.3063 15.1939 14.5721 14.5588 15.5321C13.9284 16.4849 13.3632 17.0915 12.7149 17.5007C12.0686 17.9088 11.2854 18.1549 10.1611 18.2948C9.02693 18.4358 7.5928 18.4639 5.66101 18.4639C5.29594 18.4639 5 18.7575 5 19.1196C5 19.4818 5.29594 19.7754 5.66101 19.7754C7.43004 19.7754 8.85146 19.7529 10.0231 19.6307C10.028 19.639 10.033 19.6475 10.0383 19.656Z"
                fill="currentcolor"
            />
            <path
                d="M2 12C2 14.2091 3.79086 16 6 16H13.0152C13.25 15.7397 13.4963 15.4163 13.7665 15.0079C13.872 14.8484 13.979 14.6794 14.0885 14.5H6C4.61929 14.5 3.5 13.3807 3.5 12C3.5 10.6193 4.61929 9.5 6 9.5H7.00647C7.13647 6.99286 9.21051 5 11.75 5C13.3735 5 14.9336 5.93612 15.7998 7.33233C15.1672 7.5907 14.6065 7.98893 14.1563 8.48858L15.264 9.50002C15.798 8.90341 16.5672 8.52176 17.4259 8.5009C17.4505 8.5003 17.4752 8.5 17.5 8.5L17.5114 8.50002L17.5362 8.50025C17.5658 8.50065 17.5954 8.50154 17.625 8.50292C19.123 8.57279 20.5 9.8879 20.5 11.5C20.5 13.0992 19.2487 14.4061 17.6718 14.4952L17.6706 14.4974C17.823 15.0466 17.9775 15.4957 18.1733 15.95C19.2065 15.795 20.1247 15.2888 20.8026 14.5568C21.5458 13.7542 22.0002 12.6802 22.0002 11.5C22.0002 9.01472 19.8182 7 17.5 7L17.489 7.00001C17.4342 7.00014 17.3795 7.00126 17.3251 7.00334C16.2543 4.92855 14.0505 3.5 11.75 3.5C8.9028 3.5 6.50027 5.40385 5.746 8.00794C3.65521 8.13895 2 9.87618 2 12Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
