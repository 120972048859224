import React, { useEffect, useState, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { useTier, useAdminRole } from '../../../auth/hooks'
import { isAfter, parseISO, formatDistanceToNow } from 'date-fns'
import formatDateTime, {
    timeZoneAbbreviation,
    formatTimeOnly,
    formatDayAndDate,
} from '../../../util/dateFormatter'
import { isMobile, isDesktop } from 'react-device-detect'
import ImprRawToggle from './ImprRawToggle'
import ErrorToggle from './ErrorToggle'
import ErrorText from './ErrorText'
import FixedPosToggle from './FixedPosToggle'

import {
    Flex,
    Box,
    Icon,
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Text,
    Modal,
    Heading,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Divider,
    useDisclosure,
    LightMode,
    Button,
    TableContainer,
    Tooltip,
    useBreakpointValue,
} from '@chakra-ui/react'

import { useRecoilState } from 'recoil'
import {
    clickedFeaturesState,
    timeZoneState,
    simulateFreeUserState,
    decodedTextDefaultState,
    showErrorTogglesState,
    mfaVerifiedState,
    aawFixFirstColumnState,
    aawReverseOrderState,
    mapDisabledState,
} from '../../../globalState'

import { ReactComponent as aawIcon } from '../../../icons/assets/aaw.svg'
import { ReactComponent as altitudeUp } from '../../../icons/assets/altitude-order-up.svg'
import { ReactComponent as altitudeDown } from '../../../icons/assets/altitude-order-down.svg'

export default function AAWModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const isAdmin = useAdminRole()
    const feat = clickedFeatures[0]
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const decodeDefault = useRecoilValue(decodedTextDefaultState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const mfaExpired = userTier > 1 && !mfaVerified
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const [showErrors, setShowErrors] = useState(false)
    const [reverseOrder, setReverseOrder] = useRecoilState(aawReverseOrderState)
    const [outOfDateError, setOutOfDateError] = useState(false)
    const [fixFirstColumn, setFixFirstColumn] = useRecoilState(
        aawFixFirstColumnState
    )
    const [raw, setRaw] = useState(isPremium || !decodeDefault ? true : false)
    const data =
        feat?.properties?.parseddata && JSON.parse(feat.properties.parseddata)
    const aawData = data && data.length
    const area = feat?.properties?.area

    const validFrom = feat?.properties?.validfrom
    const validTo = feat?.properties?.validto

    const { isOpen, onOpen, onClose } = useDisclosure()
    const timeZone = useRecoilValue(timeZoneState)

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    useEffect(() => {
        if (!decodeDefault) {
            setRaw(!decodeDefault)
        }
    }, [decodeDefault])

    useEffect(() => {
        if (feat.properties.validTo) {
            if (isAfter(new Date(feat.properties.validTo), new Date())) {
                setOutOfDateError(false)
            } else {
                setOutOfDateError(true)
            }
        }
    }, [feat])

    const onCloseHandler = () => {
        setClickedFeatures([])
        onClose()
    }

    const instanceRef = useRef()
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)
    const checkScrollbarVisibility = () => {
        if (instanceRef.current) {
            setIsScrollbarVisible(
                instanceRef.current.scrollWidth >
                    instanceRef.current.clientWidth
            )
        }
    }
    useEffect(() => {
        checkScrollbarVisibility()
        // listener to check table scrollbar visibility
        const resizeListener = () => {
            checkScrollbarVisibility()
        }
        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    useEffect(() => {
        const delay = setTimeout(() => {
            checkScrollbarVisibility()
        }, 310)

        return () => {
            clearTimeout(delay)
        }
    }, [])

    useEffect(() => {
        // reset fixed first column if there is no overflow
        if (!isScrollbarVisible) {
            setFixFirstColumn(false)
        }
    }, [isScrollbarVisible, setFixFirstColumn])

    const awwBecoming =
        data &&
        data.some(
            (feat) =>
                (feat.between_start && feat.between_end) || feat.between_start
        )

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }
    const containerSize = useBreakpointValue({
        base: 'xl',
        sm: '2xl',
        lg: '3xl',
        xl: '3xl',
        '2xl': '4xl',
    })

    // console.log(data, ' AAW data')

    return (
        <>
            <LightMode>
                <Modal
                    variant={mapDisabled ? 'weatherMapDisabled' : 'weather'}
                    size={containerSize}
                    centered
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    onHide={() => setClickedFeatures([])}
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton
                            color="white"
                            mr={{ base: '0px', md: '10px' }}
                            mt={{ base: '0px', md: '8px' }}
                        />
                        <ModalHeader background="linear-gradient(345deg, rgb(93, 164, 226) 10%, rgb(42, 113, 172) 90%)">
                            <Flex
                                flexDirection="row"
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Icon
                                        pointerEvents="none"
                                        transform="scale(2.4) translateX(-4px)"
                                        opacity="0.1"
                                        boxSize="36px"
                                        as={aawIcon}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                </Flex>
                                <Flex
                                    ml="-36px"
                                    color="white"
                                    pr={8}
                                    alignItems="center"
                                >
                                    <Icon
                                        boxSize="45px"
                                        as={aawIcon}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                    <Box pl={4}>
                                        <Heading
                                            variant="mainHeading"
                                            justify-content="flex-start"
                                            flex-direction="row"
                                            display="flex"
                                        >
                                            <Text paddingRight="5px">
                                                {feat.properties.name}
                                            </Text>
                                            <Text> ({area})</Text>
                                        </Heading>
                                        <Heading variant="subHeading">
                                            Aviation Area Winds
                                        </Heading>
                                    </Box>
                                </Flex>
                            </Flex>
                        </ModalHeader>

                        <ModalBody>
                            {isAdmin && showErrorToggle && (
                                <Flex
                                    w="100%"
                                    pb="5px"
                                    mt="10px"
                                    align="center"
                                    justifyContent={{
                                        base: 'flex-end',
                                        md: 'flex-end',
                                    }}
                                >
                                    <ErrorToggle
                                        showErrors={showErrors}
                                        setShowErrors={setShowErrors}
                                    />
                                </Flex>
                            )}
                            {(showErrors ||
                                !aawData ||
                                feat.properties.outofdate ||
                                outOfDateError) && (
                                <Flex my="15px" justifyContent="center">
                                    <ErrorText>
                                        There has been an issue with the
                                        connection between PreFlight and
                                        MetService. This AAW was last validated
                                        with MetService{' '}
                                        <strong>
                                            {formatDistanceToNow(
                                                parseISO(
                                                    feat.properties.updated
                                                ),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}
                                        </strong>
                                        .<br />
                                        There is a high likelihood that the
                                        latest AAW is not available in
                                        PreFlight. Check back again soon, or
                                        alternatively visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </ErrorText>
                                </Flex>
                            )}
                            <Flex
                                marginBottom="15px"
                                marginTop="15px"
                                alignContent="center"
                                width="100%"
                                justifyContent="space-between"
                            >
                                <Flex
                                    fontSize={{
                                        base: '0.8rem',
                                        sm: '0.85rem',
                                    }}
                                    pt="0px"
                                    pb="15px"
                                    textAlign="left"
                                    flexDirection={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                >
                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            sm: 'row',
                                        }}
                                        pb={{
                                            base: '15px',
                                            sm: '10px',
                                            md: '0px',
                                        }}
                                        pr={{
                                            base: '5px',
                                            md: '40px',
                                        }}
                                    >
                                        <Text
                                            width={{
                                                base: 'max-content',
                                                sm: '85px',
                                                md: 'max-content',
                                            }}
                                            px="5px"
                                            fontWeight="600"
                                            whiteSpace="pre"
                                        >
                                            Valid from:
                                        </Text>
                                        <Text px="5px" fontWeight="400">
                                            {validFrom &&
                                                formatDateTime(
                                                    validFrom,
                                                    timeZone
                                                )}
                                        </Text>
                                    </Flex>
                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            sm: 'row',
                                        }}
                                    >
                                        <Text
                                            width={{
                                                base: 'max-content',
                                                sm: '85px',
                                                md: 'max-content',
                                            }}
                                            px="5px"
                                            fontWeight="600"
                                        >
                                            Valid to:
                                        </Text>
                                        <Text px="5px" fontWeight="400">
                                            {validTo &&
                                                formatDateTime(
                                                    validTo,
                                                    timeZone
                                                )}
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Flex
                                    pl="10px"
                                    position="relative"
                                    width="max-content"
                                    alignContent="center"
                                >
                                    <ImprRawToggle // IMPR/RAW Toggle
                                        isPremium={isPremium}
                                        selectedData={feat}
                                        raw={raw}
                                        setRaw={setRaw}
                                        aaw={feat}
                                        mfaExpired={mfaExpired}
                                    />
                                </Flex>
                            </Flex>
                            <Box width="100%">
                                {!raw && !isPremium && (
                                    <TableContainer
                                        className="scrollHorizontal"
                                        ref={instanceRef}
                                    >
                                        <Table
                                            id="tafContentScreen"
                                            size="sm"
                                            variant="striped"
                                            colorScheme="gray"
                                            fontFamily="Open Sans"
                                            marginTop="2"
                                            marginBottom="6"
                                            width="100%"
                                        >
                                            {aawData && (
                                                <Thead>
                                                    <Tr
                                                        minHeight="30px"
                                                        fontWeight="600"
                                                        fontFamily="Open Sans"
                                                        borderBottom="2px solid #a6b4c1"
                                                    >
                                                        <Th
                                                            width="80px"
                                                            minWidth="80px"
                                                            px="0"
                                                            position={
                                                                fixFirstColumn
                                                                    ? 'absolute'
                                                                    : 'relative'
                                                            }
                                                            height={
                                                                awwBecoming &&
                                                                isScrollbarVisible
                                                                    ? '65px'
                                                                    : isScrollbarVisible
                                                                    ? '50px'
                                                                    : '0px'
                                                            }
                                                            minHeight={
                                                                awwBecoming &&
                                                                isScrollbarVisible
                                                                    ? '65px'
                                                                    : isScrollbarVisible
                                                                    ? '50px'
                                                                    : '0px'
                                                            }
                                                            borderBottomColor="transparent"
                                                            bg="light.10"
                                                        >
                                                            {!isPremium &&
                                                                !raw && (
                                                                    <Flex
                                                                        width="100%"
                                                                        height="100%"
                                                                        alignItems="flex-end"
                                                                        justifyContent="flex-start"
                                                                        pb={
                                                                            fixFirstColumn
                                                                                ? '5px'
                                                                                : '7px'
                                                                        }
                                                                        gap="12px"
                                                                    >
                                                                        <Tooltip
                                                                            label={
                                                                                reverseOrder
                                                                                    ? 'Order Up'
                                                                                    : 'Order Down'
                                                                            }
                                                                            hasArrow
                                                                            borderRadius="7px"
                                                                            height="26px"
                                                                            fontSize="0.7rem"
                                                                            alignItems="center"
                                                                            display={
                                                                                isMobile ||
                                                                                !isDesktop
                                                                                    ? 'none'
                                                                                    : 'flex'
                                                                            }
                                                                            isDisabled={
                                                                                isMobile ||
                                                                                !isDesktop
                                                                            }
                                                                            placement="top-start"
                                                                            m="5px"
                                                                            mr="7px"
                                                                            color="light.10"
                                                                            bg="dark.10"
                                                                        >
                                                                            <Button
                                                                                background="#F7FAFC"
                                                                                color="#a8b2c0"
                                                                                variant="outline"
                                                                                fontSize="11px"
                                                                                fontWeight="500"
                                                                                display="flex"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                                height={{
                                                                                    base: '32px',
                                                                                    md: '30px',
                                                                                }}
                                                                                width={{
                                                                                    base: '34px',
                                                                                    md: '32px',
                                                                                }}
                                                                                minWidth={{
                                                                                    base: '34px',
                                                                                    md: '32px',
                                                                                }}
                                                                                borderRadius="10px"
                                                                                {...noFocus}
                                                                                onClick={() => {
                                                                                    setReverseOrder(
                                                                                        (
                                                                                            prevState
                                                                                        ) =>
                                                                                            !prevState
                                                                                    )
                                                                                }}
                                                                                _hover={{
                                                                                    background:
                                                                                        'light.90',
                                                                                }}
                                                                                _active={{
                                                                                    opacity:
                                                                                        '1',
                                                                                    background:
                                                                                        'light.90',
                                                                                    boxShadow:
                                                                                        'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                                                }}
                                                                                transition="all ease 300ms"
                                                                            >
                                                                                <Icon
                                                                                    ml="1px"
                                                                                    boxSize="20px"
                                                                                    as={
                                                                                        reverseOrder
                                                                                            ? altitudeUp
                                                                                            : altitudeDown
                                                                                    }
                                                                                    color="gray.400"
                                                                                />
                                                                            </Button>
                                                                        </Tooltip>
                                                                        {isScrollbarVisible && (
                                                                            <FixedPosToggle // Fix First Column Toggle
                                                                                fixFirstColumn={
                                                                                    fixFirstColumn
                                                                                }
                                                                                setFixFirstColumn={
                                                                                    setFixFirstColumn
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Flex>
                                                                )}
                                                        </Th>
                                                        {data.map((time, i) => (
                                                            <Th
                                                                textAlign="left"
                                                                pl="15px"
                                                                pr="5px"
                                                                key={`time-${i}`}
                                                            >
                                                                <Flex
                                                                    flexDirection="column"
                                                                    pb="5px"
                                                                >
                                                                    <Text
                                                                        fontSize="0.85rem"
                                                                        fontWeight="700"
                                                                        textAlign="left"
                                                                        width="100%"
                                                                    >
                                                                        {((time?.between_start &&
                                                                            time?.between_end) ||
                                                                            time?.between_start) &&
                                                                            'BECOMING'}
                                                                    </Text>
                                                                    <Text
                                                                        pt="5px"
                                                                        textAlign="left"
                                                                        width="100%"
                                                                        fontSize="0.8rem"
                                                                        fontWeight="400"
                                                                    >
                                                                        {time?.between_start &&
                                                                        time?.between_end
                                                                            ? `${
                                                                                  time?.between_start &&
                                                                                  formatTimeOnly(
                                                                                      time.between_start,
                                                                                      timeZone
                                                                                  )
                                                                              } – ${
                                                                                  time?.between_end &&
                                                                                  formatTimeOnly(
                                                                                      time.between_end,
                                                                                      timeZone
                                                                                  )
                                                                              }`
                                                                            : time?.between_start &&
                                                                              formatTimeOnly(
                                                                                  time.between_start,
                                                                                  timeZone
                                                                              )}
                                                                    </Text>
                                                                    <Text
                                                                        textAlign="left"
                                                                        width="100%"
                                                                        fontSize="0.75rem"
                                                                        color="gray.400"
                                                                        fontWeight="300"
                                                                    >
                                                                        {time?.between_start &&
                                                                        time?.between_end
                                                                            ? `${
                                                                                  time?.between_start &&
                                                                                  formatDayAndDate(
                                                                                      time.between_start,
                                                                                      timeZone
                                                                                  )
                                                                              } – ${
                                                                                  time?.between_end &&
                                                                                  formatDayAndDate(
                                                                                      time.between_end,
                                                                                      timeZone
                                                                                  )
                                                                              }`
                                                                            : time?.between_start &&
                                                                              formatDayAndDate(
                                                                                  time.between_start,
                                                                                  timeZone
                                                                              )}
                                                                    </Text>
                                                                </Flex>
                                                            </Th>
                                                        ))}
                                                    </Tr>
                                                </Thead>
                                            )}
                                            <Tbody>
                                                {aawData &&
                                                    data[0]?.altitude.length >
                                                        0 &&
                                                    data[0].altitude
                                                        .slice()
                                                        .sort((a, b) =>
                                                            reverseOrder
                                                                ? b - a
                                                                : a - b
                                                        )
                                                        .map(
                                                            (
                                                                altitude,
                                                                altIndex
                                                            ) => {
                                                                const formattedAltIndex =
                                                                    reverseOrder
                                                                        ? data[0]
                                                                              .altitude
                                                                              .length -
                                                                          altIndex -
                                                                          1
                                                                        : altIndex

                                                                return (
                                                                    <Tr
                                                                        key={
                                                                            altitude
                                                                        }
                                                                    >
                                                                        <Td
                                                                            fontSize="0.8rem"
                                                                            maxWidth="80px"
                                                                            width="80px"
                                                                            minWidth="80px"
                                                                            pl="10px"
                                                                            fontWeight="600"
                                                                            alignContent="left"
                                                                            textAlign="left"
                                                                            borderRight="1px solid #c5d1db !important"
                                                                            position={
                                                                                fixFirstColumn
                                                                                    ? 'absolute'
                                                                                    : 'relative'
                                                                            }
                                                                            pointerEvents={
                                                                                fixFirstColumn
                                                                                    ? 'none'
                                                                                    : 'initial'
                                                                            }
                                                                            bg="light.35"
                                                                        >
                                                                            {
                                                                                altitude
                                                                            }{' '}
                                                                            ft
                                                                        </Td>
                                                                        {data.map(
                                                                            (
                                                                                time,
                                                                                timeIndex
                                                                            ) => {
                                                                                const winddir =
                                                                                    timeIndex >
                                                                                        0 &&
                                                                                    `${time.winddir[formattedAltIndex]}${time.windstr[formattedAltIndex]}` ===
                                                                                        `${
                                                                                            data[
                                                                                                timeIndex -
                                                                                                    1
                                                                                            ]
                                                                                                .winddir[
                                                                                                formattedAltIndex
                                                                                            ]
                                                                                        }${
                                                                                            data[
                                                                                                timeIndex -
                                                                                                    1
                                                                                            ]
                                                                                                .windstr[
                                                                                                formattedAltIndex
                                                                                            ]
                                                                                        }`
                                                                                        ? null
                                                                                        : time
                                                                                              .winddir[
                                                                                              formattedAltIndex
                                                                                          ]

                                                                                const windstr =
                                                                                    timeIndex >
                                                                                        0 &&
                                                                                    `${time.winddir[formattedAltIndex]}${time.windstr[formattedAltIndex]}` ===
                                                                                        `${
                                                                                            data[
                                                                                                timeIndex -
                                                                                                    1
                                                                                            ]
                                                                                                .winddir[
                                                                                                formattedAltIndex
                                                                                            ]
                                                                                        }${
                                                                                            data[
                                                                                                timeIndex -
                                                                                                    1
                                                                                            ]
                                                                                                .windstr[
                                                                                                formattedAltIndex
                                                                                            ]
                                                                                        }`
                                                                                        ? null
                                                                                        : time
                                                                                              .windstr[
                                                                                              formattedAltIndex
                                                                                          ]

                                                                                const temp =
                                                                                    timeIndex >
                                                                                        0 &&
                                                                                    time
                                                                                        .temp[
                                                                                        formattedAltIndex
                                                                                    ] ===
                                                                                        data[
                                                                                            timeIndex -
                                                                                                1
                                                                                        ]
                                                                                            .temp[
                                                                                            formattedAltIndex
                                                                                        ]
                                                                                        ? winddir &&
                                                                                          windstr
                                                                                            ? time
                                                                                                  .temp[
                                                                                                  formattedAltIndex
                                                                                              ]
                                                                                            : null
                                                                                        : time
                                                                                              .temp[
                                                                                              formattedAltIndex
                                                                                          ]

                                                                                return (
                                                                                    <Td
                                                                                        fontSize="0.8rem"
                                                                                        key={`col-${timeIndex}`}
                                                                                        paddingLeft={
                                                                                            fixFirstColumn &&
                                                                                            timeIndex ===
                                                                                                0
                                                                                                ? '85px'
                                                                                                : '5px'
                                                                                        }
                                                                                    >
                                                                                        <Flex minWidth="140px">
                                                                                            <Text pl="10px">
                                                                                                {winddir
                                                                                                    ? winddir
                                                                                                    : ''}
                                                                                                {winddir ===
                                                                                                'VRB'
                                                                                                    ? ','
                                                                                                    : winddir <
                                                                                                      1
                                                                                                    ? ''
                                                                                                    : '°T, '}{' '}
                                                                                                {windstr
                                                                                                    ? windstr
                                                                                                    : ''}{' '}
                                                                                                {windstr >
                                                                                                0
                                                                                                    ? 'kt'
                                                                                                    : ''}
                                                                                            </Text>
                                                                                            <Text
                                                                                                pl="15px"
                                                                                                pr="15px"
                                                                                                textAlign="right"
                                                                                            >
                                                                                                {
                                                                                                    temp
                                                                                                }
                                                                                                {temp ===
                                                                                                0
                                                                                                    ? '°C'
                                                                                                    : temp
                                                                                                    ? '°C'
                                                                                                    : ''}
                                                                                            </Text>
                                                                                        </Flex>
                                                                                    </Td>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </Tr>
                                                                )
                                                            }
                                                        )}
                                                {aawData && data[0].remark && (
                                                    <Tr>
                                                        <Td
                                                            fontSize="0.8rem"
                                                            maxWidth="80px"
                                                            width="80px"
                                                            minWidth="80px"
                                                            pl="10px"
                                                            fontWeight="600"
                                                            alignContent="left"
                                                            textAlign="left"
                                                            borderRight="1px solid #c5d1db !important"
                                                            position={
                                                                fixFirstColumn
                                                                    ? 'absolute'
                                                                    : 'relative'
                                                            }
                                                            pointerEvents={
                                                                fixFirstColumn
                                                                    ? 'none'
                                                                    : 'initial'
                                                            }
                                                            bg="light.35"
                                                        >
                                                            Remark
                                                        </Td>
                                                        <Td
                                                            colSpan={
                                                                data.length
                                                            }
                                                        >
                                                            <Text align>
                                                                {data[0].remark}
                                                            </Text>
                                                        </Td>
                                                    </Tr>
                                                )}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {raw && feat.properties.rawdata && (
                                    <Flex overflow="auto">
                                        <Text
                                            whiteSpace="pre"
                                            pb="20px"
                                            pl="15px"
                                            fontSize="0.8rem"
                                            fontFamily="AzeretMonoExtraLight"
                                        >
                                            {feat.properties.rawdata.replace(
                                                />/g,
                                                ' '
                                            )}
                                        </Text>
                                    </Flex>
                                )}
                            </Box>

                            {(aawData || feat.properties.rawdata) && (
                                <>
                                    <Divider variant="modalFooter" />

                                    <Flex
                                        flexDirection="column"
                                        alignItems="flex-end"
                                        width="100%"
                                    >
                                        {raw && (
                                            <Flex flexDirection="column">
                                                <Text
                                                    height="22px"
                                                    as="i"
                                                    fontSize="0.7rem"
                                                    paddingBottom="5px"
                                                    paddingRight="3px"
                                                >
                                                    All times referenced in the
                                                    standard AAW are{' '}
                                                    <strong>UTC</strong>
                                                </Text>
                                            </Flex>
                                        )}
                                        {!raw && (
                                            <Flex flexDirection="column">
                                                <Text
                                                    height="22px"
                                                    as="i"
                                                    fontSize="0.7rem"
                                                    paddingBottom="5px"
                                                    paddingRight="3px"
                                                >
                                                    All dates and times
                                                    referenced are{' '}
                                                    <strong>
                                                        {' '}
                                                        {`${
                                                            timeZone === 'UTC'
                                                                ? 'UTC'
                                                                : timeZoneAbbreviation(
                                                                      new Date(),
                                                                      Intl.DateTimeFormat().resolvedOptions()
                                                                          .timeZone,
                                                                      'z'
                                                                  )
                                                        }`}
                                                    </strong>
                                                </Text>
                                            </Flex>
                                        )}

                                        <Text
                                            marginBottom="2px"
                                            paddingRight="3px"
                                            fontSize="0.75rem"
                                            as="i"
                                        >
                                            All heights are in feet AMSL
                                        </Text>
                                        <Text
                                            marginBottom="20px"
                                            paddingRight="5px"
                                            fontSize="0.75rem"
                                            as="i"
                                        >
                                            Wind:°TRUE / Speed:KT
                                        </Text>
                                    </Flex>
                                </>
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
