import React, { useState, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer, Popup } from 'react-mapbox-gl'
import { VectorLayerRefreshInfo } from '../../../globalState'
import formatcoords from 'formatcoords'
import { obstacleType } from '../../../util/obstacleType'

import { Flex, Table, Tbody, Tr, Td, Icon } from '@chakra-ui/react'

import './assets/aero-popup.css'

import { ReactComponent as MicrowaveTowerIcon } from '../../icons/aero/microwavetower.svg'
import { ReactComponent as MicrowaveTowerLitIcon } from '../../icons/aero/microwavetower-lit.svg'
import { ReactComponent as ObstacleIcon } from '../../icons/aero/obstacle.svg'
import { ReactComponent as ObstacleLitIcon } from '../../icons/aero/obstacle-lit.svg'
import { ReactComponent as ObstacleLargeIcon } from '../../icons/aero/obstacle-large.svg'
import { ReactComponent as ObstacleLargeLitIcon } from '../../icons/aero/obstacle-large-lit.svg'
import { ReactComponent as RadarIcon } from '../../icons/aero/radar.svg'
import { ReactComponent as WindmillIcon } from '../../icons/aero/windmill.svg'
import { ReactComponent as WindmillLitIcon } from '../../icons/aero/windmill-lit.svg'
import { ReactComponent as WireIcon } from '../../icons/aero/wire.svg'

export default function ObstaclesLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    const [hoverInfo, setHoverInfo] = useState(null)

    const onHover = (e) => {
        const zoomLevel = e.target.getZoom()

        const newE = e

        const filteredFeatures = e.features.filter((feat) => {
            if (zoomLevel >= 8) {
                return feat
            }
            return null
        })

        newE.features = filteredFeatures.map((feat) => {
            const newFeat = { ...feat }
            const coords = formatcoords(
                feat.geometry.coordinates[(0, 1)],
                feat.geometry.coordinates[(0, 0)]
            )
                .format('X D M s', { latLonSeparator: ',', decimalPlaces: 0 })
                .split(',')
            const lat = coords[0]
                .split(' ')
                .map((value, index) => {
                    if (index === 0 || value.length > 1) {
                        return value
                    } else {
                        return '0' + value
                    }
                })
                .join(' ')
            const long = coords[1]
                .split(' ')
                .map((value, index) => {
                    if (index === 0 || value.length > 1) {
                        return value
                    } else {
                        return '0' + value
                    }
                })
                .join(' ')
            newFeat.properties = {
                ...newFeat.properties,
                coords: { lat: lat, long: long },
            }
            return newFeat
        })

        setHoverInfo(newE)
    }

    const offHover = useCallback((e) => {
        setHoverInfo(null)
    }, [])

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0,
        8,
        0,
        9,
        1,
    ]

    return (
        <>
            <GeoJSONLayer
                id="obstacles"
                data={layerData.obstacles.dataURL}
                before="aerodromes-symbol"
                // enable for debugging centre position of icons
                // circlePaint={{
                //     'circle-color': '#FF00FF',
                // }}
                symbolOnMouseEnter={onHover}
                symbolOnMouseLeave={offHover}
                symbolLayout={{
                    'symbol-sort-key': ['*', ['get', 'elevation'] - 1],
                    'symbol-z-order': 'source',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        10,
                        0.4,
                        16,
                        0.6,
                    ],
                    'icon-image': [
                        'case',
                        [
                            'all',
                            ['==', ['get', 'type'], 'WINDMILL'],
                            ['==', ['get', 'lit'], false],
                        ],
                        'windmill',
                        [
                            'all',
                            ['==', ['get', 'type'], 'WINDMILL'],
                            ['==', ['get', 'lit'], true],
                        ],
                        'windmill-lit',
                        ['==', ['get', 'type'], 'CATENARY'],
                        'wire',
                        [
                            'any',
                            ['==', ['get', 'type'], 'OTHER:OTHER:RADAR'], // TODO tidy up source data for this type
                            ['==', ['get', 'type'], 'OTHER:RADAR'],
                        ],
                        'radar',
                        [
                            'all',
                            ['==', ['get', 'type'], 'OTHER:MICROWAVE_TOWER'], // TODO tidy up source data for this type
                            ['==', ['get', 'lit'], false],
                        ],
                        'microwavetower',
                        [
                            'all',
                            ['==', ['get', 'type'], 'OTHER:MICROWAVE_TOWER'],
                            ['==', ['get', 'lit'], true],
                        ],
                        'microwavetower-lit',
                        [
                            'case',
                            [
                                'all',
                                ['==', ['get', 'height'], null],
                                ['==', ['get', 'lit'], false],
                            ],
                            'obstacle',
                            [
                                'case',
                                [
                                    'all',
                                    ['==', ['get', 'height'], null],
                                    ['==', ['get', 'lit'], true],
                                ],
                                'obstacle-lit',
                                [
                                    'case',
                                    [
                                        'all',
                                        ['<', ['get', 'height'], 400],
                                        ['==', ['get', 'lit'], false],
                                    ],
                                    'obstacle',
                                    [
                                        'case',
                                        [
                                            'all',
                                            ['<', ['get', 'height'], 400],
                                            ['==', ['get', 'lit'], true],
                                        ],
                                        'obstacle-lit',
                                        [
                                            'case',
                                            [
                                                'all',
                                                ['>=', ['get', 'height'], 400],
                                                ['==', ['get', 'lit'], false],
                                            ],
                                            'obstacle-large',
                                            [
                                                'case',
                                                [
                                                    'all',
                                                    [
                                                        '>=',
                                                        ['get', 'height'],
                                                        400,
                                                    ],
                                                    [
                                                        '==',
                                                        ['get', 'lit'],
                                                        true,
                                                    ],
                                                ],
                                                'obstacle-large-lit',
                                                'obstacle',
                                            ],
                                        ],
                                    ],
                                ],
                            ],
                        ],
                    ],
                    'icon-offset': [
                        'match',
                        ['get', 'type'],
                        'WINDMILL',
                        ['literal', [0, -15]],
                        'CATENARY',
                        ['literal', [0, 0]],
                        'OTHER:OTHER:RADAR', // TODO tidy up source data for this type
                        ['literal', [0, -10]],
                        'OTHER:RADAR',
                        ['literal', [0, -10]],
                        'OTHER:MICROWAVE_TOWER',
                        ['literal', [0, -15]],
                        [
                            'case',
                            [
                                'all',
                                ['==', ['get', 'height'], null],
                                ['==', ['get', 'lit'], false],
                            ],
                            ['literal', [0, -6]],
                            [
                                'case',
                                [
                                    'all',
                                    ['==', ['get', 'height'], null],
                                    ['==', ['get', 'lit'], true],
                                ],
                                ['literal', [0, -9]],

                                [
                                    'case',
                                    [
                                        'all',
                                        ['<', ['get', 'height'], 400],
                                        ['==', ['get', 'lit'], false],
                                    ],
                                    ['literal', [0, -6]],
                                    [
                                        'case',
                                        [
                                            'all',
                                            ['<', ['get', 'height'], 400],
                                            ['==', ['get', 'lit'], true],
                                        ],
                                        ['literal', [0, -9]],
                                        [
                                            'case',
                                            [
                                                'all',
                                                ['>=', ['get', 'height'], 400],
                                                ['==', ['get', 'lit'], false],
                                            ],
                                            ['literal', [0, -11]],
                                            [
                                                'case',
                                                [
                                                    'all',
                                                    [
                                                        '>=',
                                                        ['get', 'height'],
                                                        400,
                                                    ],
                                                    [
                                                        '==',
                                                        ['get', 'lit'],
                                                        true,
                                                    ],
                                                ],
                                                ['literal', [0, -15]],
                                                ['literal', [0, 0]],
                                            ],
                                        ],
                                    ],
                                ],
                            ],
                        ],
                    ],
                    'text-field': ['get', 'elevation'],

                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-offset': [
                        'match',
                        ['get', 'type'],
                        'WINDMILL',
                        ['literal', [0, -3]],
                        'CATENARY',
                        ['literal', [0, -0.3]],
                        'OTHER:MICROWAVE_TOWER',
                        ['literal', [0, -2.5]],
                        [
                            'case',
                            [
                                'all',
                                ['==', ['get', 'height'], null],
                                ['==', ['get', 'lit'], false],
                            ],
                            ['literal', [0, -1.5]],
                            [
                                'case',
                                [
                                    'all',
                                    ['==', ['get', 'height'], null],
                                    ['==', ['get', 'lit'], true],
                                ],
                                ['literal', [0, -2.25]],

                                [
                                    'case',
                                    [
                                        'all',
                                        ['<', ['get', 'height'], 400],
                                        ['==', ['get', 'lit'], false],
                                    ],
                                    ['literal', [0, -1.5]],
                                    [
                                        'case',
                                        [
                                            'all',
                                            ['<', ['get', 'height'], 400],
                                            ['==', ['get', 'lit'], true],
                                        ],
                                        ['literal', [0, -2.25]],
                                        [
                                            'case',
                                            [
                                                'all',
                                                ['>=', ['get', 'height'], 400],
                                                ['==', ['get', 'lit'], false],
                                            ],
                                            ['literal', [0, -2.5]],
                                            [
                                                'case',
                                                [
                                                    'all',
                                                    [
                                                        '>=',
                                                        ['get', 'height'],
                                                        400,
                                                    ],
                                                    [
                                                        '==',
                                                        ['get', 'lit'],
                                                        true,
                                                    ],
                                                ],
                                                ['literal', [0, -3]],
                                                ['literal', [0, 0]],
                                            ],
                                        ],
                                    ],
                                ],
                            ],
                        ],
                    ],
                    'icon-anchor': 'center',
                    'text-anchor': 'bottom',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        5,
                        10,
                        10,
                        11,
                        10,
                    ],
                }}
                symbolPaint={{
                    'text-color': [
                        'case',
                        [
                            'any',
                            ['==', ['get', 'type'], 'OTHER:OTHER:RADAR'], // TODO tidy up source data for this type
                            ['==', ['get', 'type'], 'OTHER:RADAR'],
                        ],
                        '#000000',
                        '#ff0000',
                    ],
                    'icon-opacity': zoomLevelDefinition,
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 0.8,
                    'text-opacity': zoomLevelDefinition,
                }}
                layerOptions={{
                    minzoom: 8,
                }}
            />
            {hoverInfo && hoverInfo.features.length > 0 && (
                <Popup
                    coordinates={[hoverInfo.lngLat.lng, hoverInfo.lngLat.lat]}
                    className={'aero-popup'}
                >
                    <Flex className="popup-header obstacle" alignItems="center">
                        {hoverInfo.features[0].properties.type ===
                        'CATENARY' ? (
                            <Icon as={WireIcon} boxSize={6} />
                        ) : hoverInfo.features[0].properties.type ===
                              'OTHER:OTHER:RADAR' ||
                          hoverInfo.features[0].properties.type ===
                              'OTHER:RADAR' ? (
                            <Icon as={RadarIcon} boxSize={6} />
                        ) : hoverInfo.features[0].properties.type ===
                              'WINDMILL' &&
                          !hoverInfo.features[0].properties.lit ? (
                            <Icon as={WindmillIcon} boxSize={6} />
                        ) : hoverInfo.features[0].properties.type ===
                              'WINDMILL' &&
                          hoverInfo.features[0].properties.lit ? (
                            <Icon as={WindmillLitIcon} boxSize={6} />
                        ) : hoverInfo.features[0].properties.type ===
                              'OTHER:MICROWAVE_TOWER' &&
                          !hoverInfo.features[0].properties.lit ? (
                            <Icon as={MicrowaveTowerIcon} boxSize={6} />
                        ) : hoverInfo.features[0].properties.type ===
                              'OTHER:MICROWAVE_TOWER' &&
                          hoverInfo.features[0].properties.lit ? (
                            <Icon as={MicrowaveTowerLitIcon} boxSize={6} />
                        ) : (!hoverInfo.features[0].properties.height ||
                              hoverInfo.features[0].properties.height < 400) &&
                          !hoverInfo.features[0].properties.lit ? (
                            <Icon as={ObstacleIcon} boxSize={6} />
                        ) : (!hoverInfo.features[0].properties.height ||
                              hoverInfo.features[0].properties.height < 400) &&
                          hoverInfo.features[0].properties.lit ? (
                            <Icon as={ObstacleLitIcon} boxSize={6} />
                        ) : hoverInfo.features[0].properties.height >= 400 &&
                          !hoverInfo.features[0].properties.lit ? (
                            <Icon as={ObstacleLargeIcon} boxSize={6} />
                        ) : hoverInfo.features[0].properties.height >= 400 &&
                          hoverInfo.features[0].properties.lit ? (
                            <Icon as={ObstacleLargeLitIcon} boxSize={6} />
                        ) : null}
                        <div className="popup-title">
                            <h1 className="title-type">
                                {`${
                                    hoverInfo.features[0].properties.height >=
                                        400 &&
                                    ![
                                        'CATENARY',
                                        'OTHER:OTHER:RADAR',
                                        'OTHER:RADAR',
                                        'OTHER:MICROWAVE_TOWER',
                                    ].includes(
                                        hoverInfo.features[0].properties.type
                                    )
                                        ? 'Tall '
                                        : ''
                                }${
                                    hoverInfo.features[0].properties.lit
                                        ? 'Lit '
                                        : ''
                                }${
                                    hoverInfo.features[0].properties.type ===
                                    'WINDMILL'
                                        ? 'Wind Turbine'
                                        : hoverInfo.features[0].properties
                                              .type === 'CATENARY'
                                        ? 'Wire'
                                        : hoverInfo.features[0].properties
                                              .type === 'OTHER:OTHER:RADAR' || // TODO tidy up source data for this type
                                          hoverInfo.features[0].properties
                                              .type === 'OTHER:RADAR'
                                        ? 'Radar'
                                        : hoverInfo.features[0].properties
                                              .type === 'OTHER:MICROWAVE_TOWER'
                                        ? 'Microwave Tower'
                                        : 'Obstacle'
                                }`}
                            </h1>
                        </div>
                    </Flex>
                    <div className="popup-body">
                        <Table
                            size="sm"
                            variant="notam"
                            marginTop="0"
                            marginBottom="0"
                        >
                            <Tbody>
                                <Tr>
                                    <Td
                                        paddingStart="0"
                                        paddingTop="3px"
                                        paddingBottom="3px"
                                        fontWeight="bold"
                                        width="auto"
                                        minWidth="75px"
                                        fontSize="0.8rem"
                                    >
                                        Type:
                                    </Td>
                                    <Td
                                        width="100%"
                                        paddingTop="3px"
                                        paddingBottom="3px"
                                        fontSize="0.8rem"
                                    >
                                        {obstacleType(
                                            hoverInfo.features[0].properties
                                                .type
                                        )}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td
                                        paddingStart="0"
                                        paddingTop="3px"
                                        paddingBottom="3px"
                                        fontWeight="bold"
                                        width="auto"
                                        minWidth="75px"
                                        fontSize="0.8rem"
                                    >
                                        Elevation:
                                    </Td>
                                    <Td
                                        width="100%"
                                        paddingTop="3px"
                                        paddingBottom="3px"
                                        fontSize="0.8rem"
                                    >
                                        {
                                            hoverInfo.features[0].properties
                                                .elevation
                                        }{' '}
                                        {' ft'}
                                    </Td>
                                </Tr>

                                <Tr>
                                    <Td
                                        paddingStart="0"
                                        paddingTop="3px"
                                        paddingBottom="3px"
                                        fontWeight="bold"
                                        width="auto"
                                        minWidth="75px"
                                        fontSize="0.8rem"
                                    >
                                        Height:
                                    </Td>
                                    <Td
                                        width="100%"
                                        paddingTop="3px"
                                        paddingBottom="3px"
                                        fontSize="0.8rem"
                                    >
                                        {
                                            hoverInfo.features[0].properties
                                                .height
                                        }{' '}
                                        {' ft'}
                                    </Td>
                                </Tr>

                                <Tr>
                                    <Td
                                        paddingStart="0"
                                        paddingTop="3px"
                                        paddingBottom="5px"
                                        fontWeight="bold"
                                        width="auto"
                                        minWidth="75px"
                                        fontSize="0.8rem"
                                    >
                                        Lit:
                                    </Td>
                                    <Td
                                        width="100%"
                                        paddingTop="3px"
                                        paddingBottom="5px"
                                        fontSize="0.8rem"
                                    >
                                        {hoverInfo.features[0].properties
                                            .lit === true
                                            ? 'Yes'
                                            : 'No'}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </div>
                    <Flex className="popup-body-coords" justifyContent="center">
                        <p className="coords-info first">
                            {hoverInfo.features[0].properties.coords.lat}
                        </p>
                        <p className="coords-info">
                            {hoverInfo.features[0].properties.coords.long}
                        </p>
                    </Flex>
                </Popup>
            )}
        </>
    )
}
