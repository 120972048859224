import { useCallback, useEffect, useState, useRef } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import booleanValid from '@turf/boolean-valid'
import booleanWithin from '@turf/boolean-within'
import * as turf from '@turf/turf'
import { isEqual, debounce } from 'lodash'

import { useTier, useAdminRole } from '../../auth/hooks'
import canUse from '../../config/utils/canUse'
import isValidMode from '../../config/utils/isValidMode'
import isWip from '../../config/utils/isWip'
import isMapLayer from '../../config/utils/isMapLayer'
import isUnavailable from '../../config/utils/isUnavailable'
import { useFilteredDesignators } from '../../util/filterDesignators'

import {
    clickedFeaturesState,
    dataModeState,
    layerSelectionState,
    handleQueryLayers,
    simulateFreeUserState,
    wipMenuItemsState,
    selectedBriefingAreasState,
    briefingStandardState,
    briefingOptionsState,
    excludedAerodromesState,
    excludedAwsState,
    briefingParamsState,
    selectedBriefingAltitudeState,
    selectedBriefingTimeState,
    briefingAreaSourceState,
    briefingCustomAreaState,
    mapBoundsState,
    updatedParamsState,
    loadingBriefingFavouriteState,
    loadFavouriteState,
    mapDisabledState,
    isBriefingOnlyState,
    modalVisibilityState,
    updateExistingFavouriteState,
    allAerodromesDesignatorsState,
    allAwsDesignatorsState,
    mfaVerifiedState,
} from '../../globalState'

import PremiumOnlyForwardedModal from '../modals/admin/premium-only/PremiumOnlyForwarded'

export function isValidGeometry(plgn, mapBounds) {
    const isClosedGeometry = isEqual(plgn[0], plgn[plgn.length - 1])
    if (isClosedGeometry) {
        const convertedBounds = turf.bboxPolygon(turf.square(mapBounds.flat()))
        const convertedGeometry = turf.polygon([plgn])
        const pointsAreValid = !plgn
            .map((i) => {
                const convertedPoint = turf.point(i)
                const within = booleanWithin(convertedPoint, convertedBounds)
                return within
            })
            .includes(false)
        if (!pointsAreValid) {
            return pointsAreValid
        }
        const isValid = booleanValid(convertedGeometry)
        return isValid
    } else {
        return false
    }
}

export default function RouteHandler() {
    const layersHandler = useSetRecoilState(handleQueryLayers)
    const [mode, setMode] = useRecoilState(dataModeState)
    const [maxBriefingAltitude, setMaxBriefingAltitude] = useRecoilState(
        selectedBriefingAltitudeState
    )
    const [briefingTime, setBriefingTime] = useRecoilState(
        selectedBriefingTimeState
    )
    const bounds = useRecoilValue(mapBoundsState)
    const selection = useRecoilValue(layerSelectionState)
    const [excludedAerodromes, setExcludedAerodromes] = useRecoilState(
        excludedAerodromesState
    )
    const [excludedAws, setExcludedAws] = useRecoilState(excludedAwsState)
    const [briefingParams, setBriefingParams] =
        useRecoilState(briefingParamsState)
    const [updatedParams, setUpdatedParams] = useRecoilState(updatedParamsState)
    const [briefingOptions, setBriefingOptions] =
        useRecoilState(briefingOptionsState)
    const [selectedAreas, setSelectedAreas] = useRecoilState(
        selectedBriefingAreasState
    )
    const [briefingStandard, setBriefingStandard] = useRecoilState(
        briefingStandardState
    )
    const wipMenuItems = useRecoilValue(wipMenuItemsState)
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const [briefingAreaSource, setBriefingAreaSource] = useRecoilState(
        briefingAreaSourceState
    )
    const loadingBriefingFavourite = useRecoilValue(
        loadingBriefingFavouriteState
    )
    const allAerodromesDesignators = useRecoilValue(
        allAerodromesDesignatorsState
    )
    const allAwsDesignators = useRecoilValue(allAwsDesignatorsState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const [loadFavourite, setLoadFavourite] = useRecoilState(loadFavouriteState)
    const updateExisting = useRecoilValue(updateExistingFavouriteState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const tier = useTier()
    const isAdmin = useAdminRole()
    const showWIP = wipMenuItems && isAdmin
    const mfaExpired = tier > 1 && !mfaVerified
    const userTier = mfaExpired || simulateFreeUser ? 1 : tier
    const [urlParsed, setUrlParsed] = useState(false)
    const [parsing, setParsing] = useState(false)
    const [tierWarning, setTierWarning] = useState(null)
    const [briefingCustomArea, setBriefingCustomArea] = useRecoilState(
        briefingCustomAreaState
    )
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()
    const debouncedSetSearchParams = useRef(
        debounce((params) => {
            setSearchParams(params)
        }, 300)
    ).current
    const toast = useToast()
    const toastIdRef = useRef()
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const dataMode = query.get('mode')
    const layerSelection = query.get('layers')
    const aerodrome = query.get('aerodrome')
    const aws = query.get('aws')
    const areaSelection = query.get('selected')
    const excludedAerodromesBriefing = query.get('excluded')
    const excludedAwsBriefing = query.get('excludedaws')
    const excludedParamsBriefing = query.get('subtract')
    const maxAlt = query.get('maxalt')
    const briefTime = query.get('time')
    const source = query.get('source')
    const coords = query.get('coords')

    const modeLayersRef = useRef()

    const { getAccessTokenSilently } = useAuth0()

    const fetchAerodrome = useCallback(
        async (designator) => {
            const accessToken = await getAccessTokenSilently()
            const res = await fetch(
                `${window.location.origin}/data/aerodrome/${designator}`,
                {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            if (!res.ok) {
                throw new Error('Network response was not ok')
            }
            return res.json()
        },
        [getAccessTokenSilently]
    )

    const {
        data: aerodromeData,
        status: aerodromeStatus,
        refetch: refetchAerodromes,
    } = useQuery(
        ['aerodrome', aerodrome],
        async () => {
            const data = await fetchAerodrome(aerodrome)
            return data
        },
        {
            enabled: false,
        }
    )

    const fetchAws = useCallback(
        async (designator) => {
            const accessToken = await getAccessTokenSilently()
            const res = await fetch(
                `${window.location.origin}/data/aws/${designator}`,
                {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            if (!res.ok) {
                throw new Error('Network response was not ok')
            }
            return res.json()
        },
        [getAccessTokenSilently]
    )

    const {
        data: awsData,
        status: awsStatus,
        refetch: refetchAws,
    } = useQuery(
        ['aws', aws],
        async () => {
            const data = await fetchAws(aws)
            return data
        },
        {
            enabled: false,
        }
    )

    const fetchBriefingAreas = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            source === 'aaw'
                ? `${window.location.origin}/data/aaw/areas/list`
                : `${window.location.origin}/data/briefingareas/list`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const {
        data: briefData,
        status: briefStatus,
        refetch: refetchBrief,
    } = useQuery('brief', fetchBriefingAreas, {
        enabled: false,
    })

    useEffect(() => {
        !urlParsed && aerodrome && refetchAerodromes()
        !urlParsed && aws && refetchAws()
        !!(!urlParsed && areaSelection) && refetchBrief()
    }, [
        aerodrome,
        aws,
        areaSelection,
        refetchAerodromes,
        refetchAws,
        refetchBrief,
        urlParsed,
    ])

    useEffect(() => {
        if (dataMode && selection) {
            modeLayersRef.current = dataMode && Object.keys(selection[dataMode])
        }
    }, [dataMode, selection])

    // This checks that values exist within the layers associated with a specific mode.
    const removeInvalidValues = useCallback(
        (array1, array2) => {
            return {
                layers: array1.filter(
                    (l) =>
                        array2.includes(l) &&
                        canUse(dataMode, l, userTier, showWIP, mapDisabled)
                ),
                cantUse: array1.filter(
                    (l) =>
                        array2.includes(l) &&
                        !canUse(dataMode, l, userTier, showWIP, mapDisabled) &&
                        !isWip(l) &&
                        !isMapLayer(l) &&
                        !isUnavailable(l)
                ),
            }
        },
        [dataMode, userTier, showWIP, mapDisabled]
    )

    const handleFeatureData = useCallback(
        (type, feature, featureData, featureStatus) => {
            if (feature) {
                if (featureData && featureData.geometry) {
                    setClickedFeatures([featureData])
                } else {
                    toast({
                        title: `${
                            type === 'aerodrome'
                                ? 'Aerodrome'
                                : type === 'aws'
                                ? 'Automatic Weather Station'
                                : ''
                        } ${feature} does not exist.`,
                        description:
                            'It looks as though you have attempted to use an invalid link.',
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                    })
                }

                if (featureStatus === 'error') {
                    toast({
                        title: `Error fetching data for ${feature}.`,
                        description:
                            'It looks like there was an error fetching feature data.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                }
            }
        },
        [toast, setClickedFeatures]
    )

    // This is where we want to parse url query search params. We check if the layers exists
    const parseUrl = useCallback(() => {
        if (!parsing) {
            setParsing(true)
            if (
                dataMode ||
                layerSelection ||
                aerodrome ||
                aws ||
                areaSelection
            ) {
                if (isValidMode(dataMode)) {
                    setMode(dataMode)
                }
                if (layerSelection) {
                    const { layers, cantUse } = removeInvalidValues(
                        layerSelection.split(','),
                        modeLayersRef.current
                    )
                    setTierWarning(cantUse)
                    layers.length &&
                        layersHandler({
                            mode: dataMode,
                            layers,
                        })
                }
                if (
                    dataMode === 'brief' &&
                    !modalVisibility?.profile // Do not action when changing to/from briefingOnlyMode
                ) {
                    if (excludedAerodromesBriefing) {
                        setExcludedAerodromes(
                            excludedAerodromesBriefing.split(',')
                        )
                    }
                    if (excludedAwsBriefing) {
                        setExcludedAws(excludedAwsBriefing.split(','))
                    }
                    if (excludedParamsBriefing) {
                        const parsedParams = excludedParamsBriefing.split(',')
                        setBriefingParams((p) => {
                            let newParams = p
                            parsedParams.forEach((v) => {
                                newParams = { ...newParams, [v]: false }
                            })
                            return newParams
                        })
                    }
                    if (maxAlt) {
                        const parsedAlt = parseInt(maxAlt)
                        if (parsedAlt > 10000 && userTier === 1) {
                            setMaxBriefingAltitude(10000)
                        } else {
                            setMaxBriefingAltitude(parsedAlt)
                        }
                    }
                    if (briefTime) {
                        const parsedTime = parseInt(briefTime)
                        setBriefingTime(parsedTime)
                    }
                    if (source) {
                        if (source === 'customArea' && userTier === 1) {
                            toast({
                                title: `You do not have access to this feature.`,
                                description:
                                    'Custom Area Briefing is for Premium users only.',
                                status: 'error',
                                duration: 10000,
                                isClosable: true,
                            })
                            setBriefingOptions(true)
                        } else {
                            setBriefingAreaSource(source)
                            if (coords) {
                                const allValues = coords
                                    .split(',')
                                    .map((i) => parseFloat(i))
                                const formatted = allValues
                                    .map((i, idx) => {
                                        if (idx % 2 === 0) {
                                            return [
                                                allValues[idx],
                                                allValues[idx + 1],
                                            ]
                                        } else {
                                            return null
                                        }
                                    })
                                    .filter((c) => c !== null)
                                const isValid = isValidGeometry(
                                    formatted,
                                    bounds
                                )
                                if (isValid) {
                                    const geoJson = {
                                        geometry: {
                                            coordinates: [formatted],
                                            type: 'Polygon',
                                        },
                                        properties: {
                                            type: 'customArea',
                                        },
                                        type: 'Feature',
                                    }
                                    setBriefingCustomArea(geoJson)
                                    setBriefingOptions(false)
                                } else {
                                    setBriefingOptions(false)
                                    setBriefingCustomArea(null)
                                    if (!toastIdRef.current) {
                                        toastIdRef.current = toast({
                                            duration: null,
                                            title: `The geometry in the URL provided is invalid.`,
                                            description:
                                                'It looks as though you have attempted to use an invalid link.',
                                            status: 'warning',
                                            isClosable: true,
                                        })
                                    }
                                }
                            }
                        }
                    }
                    if (areaSelection && briefingAreaSource) {
                        const briefOptions = briefData.map((i) =>
                            briefingAreaSource === 'aaw' ? i.area : i.designator
                        )
                        const briefSelection = areaSelection
                            .split(',')
                            .map((area) => {
                                return briefingAreaSource === 'aaw'
                                    ? { area }
                                    : { designator: area }
                            })
                        const acceptedAreas = briefSelection.filter((i) =>
                            briefingAreaSource === 'aaw'
                                ? briefOptions.includes(i.area)
                                : briefOptions.includes(i.designator)
                        )
                        setSelectedAreas(acceptedAreas)
                        if (!briefingStandard) {
                            setBriefingOptions(false)
                            setBriefingStandard(true)
                        }
                    } else if (
                        (isBriefingOnly || mapDisabled) &&
                        (areaSelection || coords) &&
                        source &&
                        !briefingStandard &&
                        !briefingOptions
                    ) {
                        setBriefingStandard(true)
                    } else if (!coords) {
                        setBriefingOptions(true)
                    }
                }
                if (aerodrome) {
                    const type = 'aerodrome'
                    handleFeatureData(
                        type,
                        aerodrome,
                        aerodromeData,
                        aerodromeStatus
                    )
                }
                if (aws) {
                    const type = 'aws'
                    handleFeatureData(type, aws, awsData, awsStatus)
                }

                setParsing(false)
            }
            setUrlParsed(true)
        }
    }, [
        parsing,
        dataMode,
        layerSelection,
        aerodrome,
        aws,
        areaSelection,
        setMode,
        removeInvalidValues,
        layersHandler,
        excludedAerodromesBriefing,
        excludedAwsBriefing,
        excludedParamsBriefing,
        maxAlt,
        briefTime,
        source,
        briefingAreaSource,
        coords,
        mapDisabled,
        isBriefingOnly,
        briefingOptions,
        briefingStandard,
        setExcludedAerodromes,
        setExcludedAws,
        setBriefingParams,
        userTier,
        setMaxBriefingAltitude,
        setBriefingTime,
        toast,
        setBriefingOptions,
        setBriefingAreaSource,
        bounds,
        setBriefingCustomArea,
        briefData,
        setSelectedAreas,
        setBriefingStandard,
        aerodromeData,
        awsData,
        aerodromeStatus,
        awsStatus,
        modalVisibility.profile,
        handleFeatureData,
    ])

    useEffect(() => {
        if (
            aerodrome &&
            aerodromeData &&
            aerodromeStatus !== 'error' &&
            !urlParsed &&
            !parsing
        ) {
            parseUrl()
        } else if (
            aws &&
            awsData &&
            awsStatus !== 'error' &&
            !urlParsed &&
            !parsing
        ) {
            parseUrl()
        } else if (!aerodrome && !aws && !urlParsed && !parsing) {
            if (areaSelection && briefData) {
                parseUrl()
            } else if (!areaSelection) {
                parseUrl()
            }
        }
    }, [
        parseUrl,
        urlParsed,
        aerodrome,
        aerodromeData,
        aerodromeStatus,
        aws,
        awsData,
        awsStatus,
        briefStatus,
        areaSelection,
        briefData,
        parsing,
    ])

    const {
        // check if any erroneous Aerodrome designators in the URL
        filteredExcludedDesignators: filteredExcludedAerodromes,
    } = useFilteredDesignators(allAerodromesDesignators, excludedAerodromes)

    const {
        // check if any erroneous AWS designators in the URL
        filteredExcludedDesignators: filteredExcludedAws,
    } = useFilteredDesignators(allAwsDesignators, excludedAws)

    useEffect(() => {
        // function to save URL to new params object in a state for save briefing favourite
        const saveUpdatedURL = (newParams) => {
            if (dataMode === 'brief') {
                const newSearchParams = new URLSearchParams(newParams)
                const newURL = `${
                    window.location.pathname
                }?${newSearchParams.toString()}`
                const updatedParamsObject = {
                    ...newParams,
                    url: newURL,
                }
                setUpdatedParams(updatedParamsObject)
            }
        }

        if (urlParsed) {
            const layers = Object.keys(selection[mode])
                .filter((i) => selection[mode][i])
                .join()
            if (
                // if Aerodrome
                clickedFeatures.length === 1 &&
                clickedFeatures[0].layer === 'aerodromes'
            ) {
                const aerodrome = clickedFeatures[0]?.properties?.designator
                if (mapDisabled && mode === 'aero') {
                    // if map DISABLED then do not load layers into url param if Aerodrome opened
                    debouncedSetSearchParams({ mode, aerodrome })
                } else {
                    // if map ENABLED then load layers into url param if Aerodrome opened
                    debouncedSetSearchParams({ mode, layers, aerodrome })
                }
            } else if (
                // if AWS
                clickedFeatures.length === 1 &&
                clickedFeatures[0].layer === 'aws'
            ) {
                const aws = clickedFeatures[0]?.properties?.designator
                debouncedSetSearchParams({ mode, layers, aws })
            } else if (mode === 'brief' && !modalVisibility?.profile) {
                // if Brief mode
                const selected =
                    briefingAreaSource === 'aaw'
                        ? selectedAreas.map((i) => i.area).join()
                        : selectedAreas.map((i) => i.designator).join()
                const excluded =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.excluded
                        ? loadFavourite.params.excluded
                        : [...filteredExcludedAerodromes]
                              .sort((a, b) => {
                                  // sorting to prevent issues with updating favourites
                                  if (a < b) {
                                      return -1
                                  }
                                  if (a > b) {
                                      return 1
                                  } else {
                                      return 0
                                  }
                              })
                              .join()
                const awsExcluded =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.excludedaws
                        ? loadFavourite.params.excludedaws
                        : [...filteredExcludedAws]
                              .sort((a, b) => {
                                  // sorting to prevent issues with updating favourites
                                  if (a < b) {
                                      return -1
                                  }
                                  if (a > b) {
                                      return 1
                                  } else {
                                      return 0
                                  }
                              })
                              .join()
                const subtract =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.subtract
                        ? loadFavourite.params.subtract
                        : // : loadingBriefingFavourite && updatedParams?.subtract // TODO - left in case needed later
                        // ? updatedParams.subtract
                        briefingParams
                        ? Object.keys(briefingParams)
                              .filter((p) => !briefingParams[p])
                              .join()
                        : null
                const maxalt =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.maxalt
                        ? loadFavourite.params.maxalt
                        : updatedParams?.maxalt
                        ? updatedParams.maxalt
                        : null
                const time =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.time
                        ? loadFavourite.params.time
                        : updatedParams?.time
                        ? updatedParams.time
                        : null
                const source =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.source
                        ? loadFavourite.params.source
                        : briefingAreaSource
                        ? briefingAreaSource
                        : 'briefingarea'
                const selectedBriefingAreas =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.selected
                        ? loadFavourite.params.selected
                        : updatedParams?.selected
                        ? updatedParams.selected
                        : []
                const coords =
                    loadingBriefingFavourite &&
                    !updateExisting &&
                    loadFavourite?.params?.coords
                        ? loadFavourite.params.coords
                        : updatedParams?.coords
                        ? updatedParams.coords
                        : null

                let params = {}
                mode && (params['mode'] = mode)
                if (loadingBriefingFavourite) {
                    source === 'customArea' &&
                        coords &&
                        (params['coords'] = coords)
                    source && (params['source'] = source)
                    time && (params['time'] = time)
                    source !== 'customArea' &&
                        (params['selected'] = selectedBriefingAreas)
                    excluded && (params['excluded'] = excluded)
                    awsExcluded && (params['excludedaws'] = awsExcluded)
                    subtract && (params['subtract'] = subtract)
                    maxalt && (params['maxalt'] = maxalt)
                } else {
                    briefingCustomArea &&
                        briefingCustomArea.geometry.coordinates &&
                        (params['coords'] =
                            briefingCustomArea.geometry.coordinates[0]
                                .map((i) => i.join())
                                .join())
                    briefingAreaSource &&
                        (params['source'] = briefingAreaSource)
                    briefingTime && (params['time'] = briefingTime)
                    selected &&
                        briefingAreaSource !== 'customArea' &&
                        (params['selected'] = selected)
                    excluded && (params['excluded'] = excluded)
                    awsExcluded && (params['excludedaws'] = awsExcluded)
                    subtract && (params['subtract'] = subtract)
                    maxBriefingAltitude &&
                        (params['maxalt'] = maxBriefingAltitude)
                    saveUpdatedURL(params) // trigger above function (this cannot be triggered if loading a Favourite)
                }
                if (!loadingBriefingFavourite && loadFavourite) {
                    setLoadFavourite(null)
                }
                debouncedSetSearchParams(params)
            } else if (mode === 'brief' && modalVisibility?.profile) {
                // if Profile modal is open when in Brief mode and changing to/from BriefingOnlyMode
                debouncedSetSearchParams({ mode })
            } else if (mapDisabled && mode === 'aero') {
                // if AERO mode and mapDisabled, do not load layers into url param
                debouncedSetSearchParams({ mode })
            } else {
                debouncedSetSearchParams({ mode, layers })
            }
        }
    }, [
        dataMode,
        loadFavourite,
        loadingBriefingFavourite,
        briefingAreaSource,
        briefingParams,
        briefingTime,
        briefingCustomArea,
        clickedFeatures,
        filteredExcludedAerodromes,
        filteredExcludedAws,
        maxBriefingAltitude,
        mode,
        mapDisabled,
        parseUrl,
        selectedAreas,
        selection,
        setBriefingOptions,
        urlParsed,
        debouncedSetSearchParams,
        setUpdatedParams,
        modalVisibility.profile,
        setLoadFavourite,
        updateExisting,
        updatedParams.coords,
        updatedParams.maxalt,
        updatedParams.selected,
        updatedParams.time,
        // eslint-disable-next-line
        // updatedParams, // this causes an repetitive error
    ])

    return (
        tierWarning && (
            <PremiumOnlyForwardedModal
                layers={tierWarning}
                setLayers={setTierWarning}
            />
        )
    )
}

// FUNCTION TO PARSE URL STRING FOR BRIEFING FAVOURITES //
export const parseSavedFavourites = (favs) => {
    return favs.map((obj) => {
        const url = `${window.location.origin}${obj.url}`
        const newUrl = new URL(url)
        const params = {}
        const parsedParams = new URLSearchParams(newUrl.search)

        params.mode = parsedParams.get('mode')
        params.coords = parsedParams.get('coords')
        params.source = parsedParams.get('source')
        params.time = parsedParams.get('time')
        params.selected = parsedParams.get('selected')
        params.excluded = parsedParams.get('excluded')
        params.excludedaws = parsedParams.get('excludedaws')
        params.subtract = parsedParams.get('subtract')
        params.maxalt = parsedParams.get('maxalt')

        return {
            title: obj.title,
            note: obj.note,
            aaw: obj.aaw,
            params,
            url: obj.url,
        }
    })
}

// FUNCTION TO PARSE URL STRING FOR BRIEFING FAVOURITES //
export function ParseCoords(coords) {
    const bounds = useRecoilValue(mapBoundsState)
    const allValues = coords && coords.split(',').map((i) => parseFloat(i))
    const formatted =
        coords &&
        allValues
            .map((i, idx) => {
                if (idx % 2 === 0) {
                    return [allValues[idx], allValues[idx + 1]]
                } else {
                    return null
                }
            })
            .filter((c) => c !== null)
    const isValid = coords && isValidGeometry(formatted, bounds)
    const geoJson = coords && {
        geometry: {
            coordinates: [formatted],
            type: 'Polygon',
        },
        properties: {
            type: 'customArea',
        },
        type: 'Feature',
    }
    const parsedCoords = coords && isValid && geoJson
    return parsedCoords
}
