import React, {
    useState,
    useContext,
    useEffect,
    useRef,
    useCallback,
} from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Source, Layer, Popup, MapContext } from 'react-mapbox-gl'
import { Flex, Box, Text, Heading, Icon } from '@chakra-ui/react'

import {
    selectedTimeState,
    VectorLayerRefreshInfo,
    layersLoaded,
    layerStatusState,
} from '../../../globalState'
import calcSymbolPlacement from '../functions/dynamicSymbols'

import './assets/met-popup.css'

import { ReactComponent as GraforIcon } from '../../../icons/assets/grafor.svg'
import { ReactComponent as CloudIcon } from '../../../icons/assets/cloud.svg'
import { ReactComponent as CBIcon } from '../../../icons/assets/cumulonimbus.svg'
import { ReactComponent as SunIcon } from '../../../icons/assets/sun.svg'

export default function GraforLayer() {
    const selectedTime = useRecoilValue(selectedTimeState)
    const layerData = useRecoilValue(VectorLayerRefreshInfo)
    const layerState = useRecoilValue(layerStatusState)
    const [loaded, setLoaded] = useRecoilState(layersLoaded)
    const [mounted, setMounted] = useState(false)
    const [hoverInfo, setHoverInfo] = useState(null)

    const mapInstance = useContext(MapContext)

    const layerId = 'grafor'

    // labelData is defined here as a useRef, directly using an empty FeatureCollection in the source breaks dynamic symbol placement
    const labelData = useRef({
        type: 'FeatureCollection',
        features: [],
    })

    useEffect(() => {
        if (!mounted) {
            setLoaded(!layerState[`${layerId}Loading`])
            setMounted(!layerState[`${layerId}Loading`])
        }
    }, [layerState, setLoaded, mounted, loaded])

    useEffect(() => {
        const dynamicSymbols = () => {
            calcSymbolPlacement(
                mapInstance,
                'grafor-fill',
                'grafor-symbol',
                'id'
            )
        }

        const moveEndCallback = () => {
            var tileLoad = setInterval(function () {
                if (mapInstance.loaded()) {
                    dynamicSymbols()
                    clearInterval(tileLoad)
                }
            }, 300)
        }

        mapInstance.on('moveend', moveEndCallback)

        function renderCallback(e) {
            if (e.target && e.target.loaded()) {
                dynamicSymbols()
                mapInstance.off('render', renderCallback)
            }
        }

        mapInstance.on('render', renderCallback)

        return () => {
            mapInstance.off('moveend', moveEndCallback)
        }
    }, [layerData.grafor.dataURL, mapInstance, selectedTime])

    const onHover = (e) => {
        const hoverData = {
            lngLat: e.lngLat,
            features: e.features
                .map((feat) => {
                    const newFeat = { ...feat }
                    newFeat.properties.clouds =
                        feat.properties.clouds &&
                        JSON.parse(feat.properties.clouds)
                    return newFeat
                })
                .filter((feat) => feat.properties.type === 'Area'),
        }
        if (hoverData.features.length > 0) {
            e.target.getCanvas().style.cursor = 'pointer'
            setHoverInfo(hoverData)
        } else {
            offHover(e)
        }
    }

    const offHover = useCallback((e) => {
        setHoverInfo(null)
        e.target.getCanvas().style.cursor = ''
    }, [])

    // useEffect(() => {
    //     console.log(hoverInfo, 'hoverInfo')
    // }, [hoverInfo])

    const colorDefinition = [
        'match',
        ['get', 'number'],
        '1',
        '#ff0000',
        '2',
        '#ff4400',
        '3',
        '#ff8800',
        '4',
        '#ffc800',
        '5',
        '#f2ff00',
        '6',
        '#bfff00',
        '7',
        '#81fa00',
        '8',
        '#22e000',
        '9',
        '#00e879',
        '10',
        '#00f5c0',
        '11',
        '#00dcf5',
        '12',
        '#00c4e6',
        '13',
        '#009de6',
        '14',
        '#0082e6',
        '15',
        '#006be6',
        '16',
        '#0045db',
        '17',
        '#000bd6',
        '18',
        '#6e00db',
        '19',
        '#bb00ff',
        '#ff00ff',
    ]

    // *** Another colour combination for displaying the Grafor areas. To delete once finalised after feedback. ***
    // const colorDefinition2 = [
    //     'match',
    //     ['get', 'number'],
    //     '1',
    //     '#3dff98',
    //     '2',
    //     '#00c767',
    //     '3',
    //     '#0090b8',
    //     '4',
    //     '#009961',
    //     '5',
    //     '#006152',
    //     '6',
    //     '#00f572',
    //     '7',
    //     '#409400',
    //     '8',
    //     '#00756a',
    //     '9',
    //     '#0f8500',
    //     '10',
    //     '#00d6c1',
    //     '11',
    //     '#00508a',
    //     '12',
    //     '#005205',
    //     '13',
    //     '#00e66b',
    //     '14',
    //     '#29ff8d',
    //     '15',
    //     '#007a4e',
    //     '16',
    //     '#30a800',
    //     '17',
    //     '#008060',
    //     '18',
    //     '#0f7 ',
    //     '19',
    //     '#bb00ff',
    //     '#ff00ff',
    // ]

    return (
        <>
            <Source
                id="grafor"
                geoJsonSource={{
                    type: 'geojson',
                    data: layerData.grafor.dataURL,
                }}
            />
            <Source
                id="grafor-symbol"
                geoJsonSource={{
                    type: 'geojson',
                    data: labelData.current,
                }}
            />
            <Layer
                id="grafor-fill"
                sourceId="grafor"
                type="fill"
                before="aerodromes-symbol"
                onMouseEnter={onHover}
                onMouseLeave={offHover}
                layout={{}}
                paint={{
                    'fill-color': colorDefinition,
                    'fill-opacity': 0.2,
                }}
                filter={[
                    'all',
                    ['==', ['get', 'type'], 'Area'],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />

            <Layer
                id="grafor-line"
                sourceId="grafor"
                type="line"
                before="aerodromes-symbol"
                onMouseEnter={onHover}
                onMouseLeave={offHover}
                layout={{}}
                paint={{
                    'line-color': [
                        'match',
                        ['get', 'fronttype'],
                        'Warm',
                        '#ff0000',
                        'Cold',
                        '#1A6CE7',
                        'Occluded',
                        '#800080',
                        'Stationary',
                        '#00ff00',
                        '#ffffff',
                    ],
                    'line-width': [
                        'match',
                        ['get', 'type'],
                        'Demarcation Line',
                        4,
                        2,
                    ],
                    'line-opacity': [
                        'match',
                        ['get', 'type'],
                        'Demarcation Line',
                        0.66,
                        1,
                    ],
                }}
                filter={[
                    'all',
                    ['!=', ['get', 'type'], 'Area'],
                    ['!=', ['get', 'fronttype'], 'Trough'],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />

            <Layer
                id="grafor-line-pattern"
                sourceId="grafor"
                type="line"
                before="aerodromes-symbol"
                layout={{}}
                paint={{
                    'line-pattern': [
                        'match',
                        ['get', 'fronttype'],
                        'Warm',
                        'front-warm',
                        'Cold',
                        'front-cold',
                        'Occluded',
                        'front-occluded',
                        'Stationary',
                        'front-stationary',
                        'front-null',
                    ],
                    'line-width': 17,
                }}
                filter={[
                    'all',
                    ['==', ['get', 'type'], 'Front'],
                    ['!=', ['get', 'fronttype'], 'Trough'],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />

            <Layer
                id="grafor-line-dash"
                sourceId="grafor"
                type="line"
                before="aerodromes-symbol"
                onMouseEnter={onHover}
                onMouseLeave={offHover}
                layout={{}}
                paint={{
                    'line-color': '#F07300',
                    'line-dasharray': [5, 3],
                    'line-width': 3,
                }}
                filter={[
                    'all',
                    ['==', ['get', 'type'], 'Front'],
                    ['==', ['get', 'fronttype'], 'Trough'],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />

            <Layer
                id="grafor-boundary"
                sourceId="grafor"
                type="line"
                before="aerodromes-symbol"
                onMouseEnter={onHover}
                onMouseLeave={offHover}
                layout={{}}
                paint={{
                    'line-color': '#888',
                    'line-dasharray': [3, 3],
                    'line-width': 1.5,
                    'line-opacity': 0.7,
                }}
                filter={['==', ['get', 'type'], 'Boundary']}
            />

            <Layer
                id="grafor-symbol"
                sourceId="grafor-symbol"
                type="symbol"
                onMouseEnter={onHover}
                onMouseLeave={offHover}
                layout={{
                    'text-field': '{number}',
                    'text-size': 16,
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-allow-overlap': true,
                    'text-optional': false,
                }}
                paint={{}}
                filter={[
                    'all',
                    ['==', ['get', 'type'], 'Area'],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />

            <Layer
                id="grafor-circle"
                sourceId="grafor-symbol"
                type="circle"
                onMouseEnter={onHover}
                onMouseLeave={offHover}
                before="grafor-symbol"
                layout={{}}
                paint={{
                    'circle-radius': 14,
                    'circle-color': colorDefinition,
                    'circle-opacity': 0.2,
                }}
                filter={[
                    'all',
                    ['==', ['get', 'type'], 'Area'],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />

            {hoverInfo && (
                <Popup
                    coordinates={[hoverInfo.lngLat.lng, hoverInfo.lngLat.lat]}
                    className={'met-popup'}
                    style={{
                        minWidth: 300,
                        maxWidth: 300,
                        borderRadius: '25px',
                    }}
                >
                    <Flex
                        // className="popup-header"
                        background="linear-gradient(345deg, rgb(116, 193, 206) 10%, rgb(35, 142, 159) 90%)"
                        justifyContent="flex-start"
                        alignItems="center"
                        px="15px"
                        py="10px"
                    >
                        <Flex
                            color="white"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Flex alignItems="center" pr={4}>
                                <Icon
                                    boxSize="36px"
                                    as={GraforIcon}
                                    alt="!"
                                    color="#ffffff"
                                />
                                <Box mt="-2px" pl={3}>
                                    <Heading
                                        variant="mainHeading"
                                        justify-content="flex-start"
                                        flex-direction="row"
                                        display="flex"
                                    >
                                        Graphical Aviation Forecast
                                    </Heading>
                                </Box>
                            </Flex>
                            {hoverInfo.features[0].properties && (
                                <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    border="1px solid rgba(255,255,255,0.6)"
                                    borderRadius="100%"
                                    height="24px"
                                    width="24px"
                                    bg="rgba(255,255,255,0.1)"
                                >
                                    <Text
                                        fontSize="1rem"
                                        fontWeight="500"
                                        pb="1px"
                                    >
                                        {
                                            hoverInfo.features[0].properties
                                                .number
                                        }
                                    </Text>
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                    <div
                        className="popup-body"
                        style={{
                            fontSize: '0.8rem',
                            lineHeight: '1.2',
                        }}
                    >
                        {/* Cloud Details */}
                        {hoverInfo.features[0].properties.clouds &&
                        hoverInfo.features[0].properties.clouds.length > 0 ? (
                            hoverInfo.features[0].properties.clouds.map(
                                (layer, index) => {
                                    return (
                                        <Flex
                                            color="light.100"
                                            key={`cloudBox ${index}`}
                                            style={{
                                                marginBottom: '1.2rem',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <Flex
                                                display="inline-flex"
                                                key={`cloudFlex1 ${index}`}
                                                style={{
                                                    maxWidth: 'fit-content',
                                                    paddingRight: '10px',
                                                    flex: '0',
                                                }}
                                            >
                                                {[
                                                    'cumulonimbus',
                                                    'toweringCumulus',
                                                ].includes(layer.cloudType) ? (
                                                    <Icon
                                                        key={`cumulonimbusIcon ${index}`}
                                                        boxSize="30px"
                                                        as={CBIcon}
                                                        alt="!"
                                                        color="light.100"
                                                    />
                                                ) : (
                                                    <Icon
                                                        key={`cloudIcon ${index}`}
                                                        boxSize="30px"
                                                        as={CloudIcon}
                                                        alt="!"
                                                        color="light.100"
                                                    />
                                                )}
                                            </Flex>
                                            <Flex
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                <Flex
                                                    key={`cloudFlex2 ${index}`}
                                                    style={{
                                                        maxWidth: 'fit-content',
                                                        fontWeight: '600',
                                                        textTransform:
                                                            'capitalize',
                                                        flex: '2',
                                                        justifyContent: 'start',
                                                    }}
                                                >
                                                    {layer.cloudAmount}{' '}
                                                    {layer.embedded === true
                                                        ? 'Embedded'
                                                        : ''}{' '}
                                                    {layer.cloudType === 'other'
                                                        ? 'Cloud '
                                                        : layer.cloudType.replace(
                                                              'toweringCumulus',
                                                              'Towering Cumulus'
                                                          )}
                                                </Flex>
                                                <Flex
                                                    key={`cloudFlex3 ${index}`}
                                                    style={{
                                                        fontWeight: '600',
                                                        flex: '2 2 0%',
                                                    }}
                                                    flexDirection="column"
                                                    alignItems="center"
                                                >
                                                    {layer.topToFlightLevel && (
                                                        <p
                                                            className="popup-text-bold"
                                                            style={{
                                                                margin: 0,
                                                                fontWeight:
                                                                    '600',
                                                                maxWidth:
                                                                    '7rem',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {layer.topFromFlightLevel ===
                                                            layer.topToFlightLevel
                                                                ? `${
                                                                      layer.topFromFlightLevel ===
                                                                      999
                                                                          ? 'XXX'
                                                                          : layer.topFromFlightLevel +
                                                                            '00 ft'
                                                                  }`
                                                                : `${
                                                                      layer.topFromFlightLevel ===
                                                                      999
                                                                          ? 'XXX'
                                                                          : layer.topFromFlightLevel +
                                                                            '00 ft'
                                                                  } – ${
                                                                      layer.topToFlightLevel ===
                                                                      999
                                                                          ? 'XXX'
                                                                          : layer.topToFlightLevel +
                                                                            '00 ft'
                                                                  }`}

                                                            {/* <div className="modal-altitude-divide" /> */}
                                                        </p>
                                                    )}

                                                    {layer.topFromFlightLevel && (
                                                        <p
                                                            className="popup-text-bold"
                                                            style={{
                                                                margin: 0,
                                                                width: '100%',
                                                                fontWeight:
                                                                    '600',
                                                                borderTop:
                                                                    '2px solid #52647a',
                                                                paddingTop:
                                                                    '3px',
                                                                marginTop:
                                                                    '4px',
                                                                maxWidth:
                                                                    '7rem',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {layer.baseFromFlightLevel ===
                                                            layer.baseToFlightLevel
                                                                ? `${layer.baseFromFlightLevel}00 ft`
                                                                : `${layer.baseFromFlightLevel}00 ft – ${layer.baseToFlightLevel}00 ft`}
                                                        </p>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    )
                                }
                            )
                        ) : (
                            <Flex
                                style={{
                                    marginBottom: '1.2rem',
                                    alignItems: 'center',
                                    width: '100%',
                                    flexDirection: 'row',
                                }}
                            >
                                <Flex
                                    color="light.100"
                                    style={{
                                        maxWidth: 'fit-content',
                                        paddingRight: '15px',
                                        flex: '0',
                                    }}
                                >
                                    <SunIcon style={{}} />
                                </Flex>
                                <Flex
                                    color="light.100"
                                    style={{
                                        fontWeight: '600',
                                        flex: '2 2 0%',
                                    }}
                                >
                                    <p
                                        style={{
                                            whiteSpace: 'pre-line',
                                            marginTop: '0.2rem',
                                            marginBottom: '0.2rem',
                                            display: 'flex',
                                            justifyContent: 'start',
                                            fontWeight: '600',
                                        }}
                                    >
                                        No Significant Cloud
                                    </p>
                                </Flex>
                            </Flex>
                        )}

                        <div
                            style={{
                                borderBottom: '1px solid black',
                                opacity: '0.1',
                                margin: '0px 0 15px 0',
                            }}
                        />

                        {/* GRAFOR Comments */}
                        <p
                            style={{
                                whiteSpace: 'pre-line',
                                display: 'flex',
                                justifyContent: 'start',
                                marginTop: '0.2rem',
                                marginBottom: '0.2rem',
                            }}
                        >
                            {hoverInfo.features[0].properties.comments}
                        </p>
                    </div>
                </Popup>
            )}
        </>
    )
}
