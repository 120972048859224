import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function RangeIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 10C4.5 9.72386 4.72386 9.5 5 9.5H7C7.27614 9.5 7.5 9.72386 7.5 10V11H8V12C8 12.1381 8.11193 12.25 8.25 12.25C8.38807 12.25 8.5 12.1381 8.5 12V11H9.5V12C9.5 12.1381 9.61193 12.25 9.75 12.25C9.88807 12.25 10 12.1381 10 12V11H11V12C11 12.1381 11.1119 12.25 11.25 12.25C11.3881 12.25 11.5 12.1381 11.5 12V11H12.5V12C12.5 12.1381 12.6119 12.25 12.75 12.25C12.8881 12.25 13 12.1381 13 12V11H14V12C14 12.1381 14.1119 12.25 14.25 12.25C14.3881 12.25 14.5 12.1381 14.5 12V11H15.5V12C15.5 12.1381 15.6119 12.25 15.75 12.25C15.8881 12.25 16 12.1381 16 12V11H16.5V10C16.5 9.72386 16.7239 9.5 17 9.5H19C19.2761 9.5 19.5 9.72386 19.5 10V11.5H21.5C21.7761 11.5 22 11.7239 22 12C22 12.2761 21.7761 12.5 21.5 12.5H19.5V13.5784C19.5 13.7318 19.4296 13.8768 19.3089 13.9716L18.3089 14.7573C18.1276 14.8997 17.8724 14.8997 17.6911 14.7573L16.6911 13.9716C16.5704 13.8768 16.5 13.7318 16.5 13.5784V13H7.5V13.5784C7.5 13.7318 7.42956 13.8768 7.30891 13.9716L6.30891 14.7573C6.12761 14.8997 5.8724 14.8997 5.69109 14.7573L4.69109 13.9716C4.57044 13.8768 4.5 13.7318 4.5 13.5784V12.5H2.5C2.22386 12.5 2 12.2761 2 12C2 11.7239 2.22386 11.5 2.5 11.5H4.5V10ZM5.25 13.4569V10.25H6.75V13.4569L6 14.0462L5.25 13.4569ZM17.25 10.25V13.4569L18 14.0462L18.75 13.4569V10.25H17.25Z"
                fill="currentColor"
            />
        </Icon>
    )
}
