import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WindBarbsIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M12.0001 15.005H16.5C16.7967 15.005 17.0867 15.093 17.3334 15.2578C17.58 15.4226 17.7723 15.6569 17.8858 15.931C17.9993 16.2051 18.0291 16.5066 17.9712 16.7976C17.9133 17.0886 17.7704 17.3559 17.5607 17.5657C17.3509 17.7755 17.0836 17.9183 16.7926 17.9762C16.5017 18.0341 16.2001 18.0043 15.926 17.8908C15.6519 17.7773 15.4176 17.5851 15.2528 17.3384C15.2247 17.2963 15.1993 17.2563 15.1752 17.2182C15.0577 17.0329 14.9704 16.8952 14.75 16.81C14.6133 16.7572 14.5244 16.7395 14.3787 16.755C14.1699 16.7772 14.0302 16.8437 13.89 17C13.7626 17.142 13.7233 17.2597 13.71 17.45C13.7018 17.5679 13.75 17.75 13.75 17.75C13.75 17.75 13.8161 18.0587 14.3787 18.6213C14.9413 19.1839 15.7044 19.5 16.5 19.5V19.505C17.2956 19.505 18.0587 19.189 18.6213 18.6263C19.1839 18.0637 19.5 17.3007 19.5 16.505C19.5 15.7094 19.1839 14.9463 18.6213 14.3837C18.0587 13.8211 17.2956 13.505 16.5 13.505H13.0001L12.0001 15.005Z"
                fill="currentColor"
            />
            <path
                d="M12.5 3.005C11.7044 3.005 10.9413 3.32106 10.3787 3.88367C9.81607 4.44628 9.75 4.755 9.75 4.755C9.75 4.755 9.70178 4.9371 9.71 5.055C9.72326 5.2453 9.76263 5.363 9.89 5.505C10.0302 5.66133 10.1699 5.72777 10.3787 5.75C10.5244 5.76553 10.6133 5.74784 10.75 5.695C11.0157 5.59234 11.088 5.4133 11.2528 5.16663C11.4176 4.91995 11.6519 4.72773 11.926 4.6142C12.2001 4.50067 12.5017 4.47093 12.7926 4.52881C13.0836 4.58669 13.3509 4.72955 13.5607 4.93933C13.7704 5.14911 13.9133 5.41643 13.9712 5.7074C14.0291 5.99837 13.9993 6.29995 13.8858 6.57404C13.7723 6.84812 13.58 7.08237 13.3334 7.24719C13.0867 7.41201 12.7967 7.5 12.5 7.5H4V9H12.5C13.2956 9 14.0587 8.68395 14.6213 8.12134C15.1839 7.55873 15.5 6.79565 15.5 6C15.5 5.20435 15.1839 4.44127 14.6213 3.87866C14.0587 3.31605 13.2956 3 12.5 3V3.005Z"
                fill="currentColor"
            />
            <path
                d="M19 6.005C18.2044 6.005 17.4413 6.32106 16.8787 6.88367C16.3161 7.44628 16.25 7.755 16.25 7.755C16.25 7.755 16.2018 7.9371 16.21 8.05501C16.2233 8.2453 16.2626 8.363 16.39 8.505C16.5302 8.66133 16.6699 8.72777 16.8787 8.75C17.0244 8.76553 17.1133 8.74784 17.25 8.695C17.5157 8.59234 17.588 8.4133 17.7528 8.16663C17.9176 7.91995 18.1519 7.72773 18.426 7.6142C18.7001 7.50067 19.0017 7.47093 19.2926 7.52881C19.5836 7.58669 19.8509 7.72955 20.0607 7.93933C20.2704 8.14911 20.4133 8.41643 20.4712 8.7074C20.5291 8.99837 20.4993 9.29995 20.3858 9.57404C20.2723 9.84812 20.08 10.0824 19.8334 10.2472C19.5867 10.412 19.2967 10.5 19 10.5H2.00005V12H19C19.7956 12 20.5587 11.6839 21.1213 11.1213C21.6839 10.5587 22 9.79565 22 9C22 8.20435 21.6839 7.44127 21.1213 6.87866C20.5587 6.31605 19.7956 6 19 6V6.005Z"
                fill="currentColor"
            />
            <path d="M7.5 13H12L11.25 14H6.75L7.5 13Z" fill="currentColor" />
            <path d="M6 15H10.5L9.75 16H5.25L6 15Z" fill="currentColor" />
            <path d="M4.5 17H9L8.25 18H3.75L4.5 17Z" fill="currentColor" />
            <path
                d="M7.5 13L8.75 14L3.25 20.5L2 19.5L7.5 13Z"
                fill="currentColor"
            />
        </Icon>
    )
}
