import React, { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import {
    Text,
    Box,
    Flex,
    IconButton,
    Button,
    useColorModeValue,
    useBreakpointValue,
    ButtonGroup,
    Badge,
    Spinner,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { isFirefox, isMobile } from 'react-device-detect'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import AdvisoriesListIcon from '../../../../icons/menu-icons/AdvisoriesListIcon'
import CloseIcon from '../../../../icons/menu-icons/CloseIcon'
import SigmetList from './components/SigmetList'
import SigwxList from './components/SigwxList'

import {
    dataModeState,
    layerSelectionState,
    layerStatusState,
    statusData,
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    closeLayerSelectorHandler,
    advisoriesListExpandState,
} from '../../../../globalState'

export default function AdvisoriesList() {
    const [dataLoading, setDataLoading] = useState(true)
    const [dataLoadingError, setDataLoadingError] = useState(false)
    const status = useRecoilValue(statusData)
    const layerStatus = useRecoilValue(layerStatusState)
    const [selectedList, setSelectedList] = useState('sigmet')
    const dataMode = useRecoilValue(dataModeState)
    const metMode = dataMode === 'met'
    const advsMode = dataMode === 'advs'
    const layerMode = useRecoilValue(layerSelectionState)
    const lowerAltitude = useRecoilValue(selectedLowerAltitudeState)
    const upperAltitude = useRecoilValue(selectedUpperAltitudeState)
    const closeLayerSelector = useSetRecoilState(closeLayerSelectorHandler)
    const [sigwxCount, setSigwxCount] = useState(0)
    const [sigmetCount, setSigmetCount] = useState(null)
    const [expanded, setExpanded] = useRecoilState(advisoriesListExpandState)
    const [hidden, setHidden] = useState(!expanded)
    const bothAdvisoriesSelected =
        advsMode && layerMode.advs.sigmet && layerMode.advs.sigwx

    // const [showOnlineUsersButton, setShowOnlineUsersButton] = useRecoilState(showOnlineUsersButtonState) // Buggy - to add in to hide onlineUsersButton when advisories-list opens

    const buttonColor = useColorModeValue('light.100', 'dark.80')
    const expandButton = useColorModeValue('light.10', 'dark.301')
    const expandButtonHover = useColorModeValue('light.20', 'dark.302')
    const listButton = useColorModeValue('light.10', 'dark.303')
    const listButtonHover = useColorModeValue('light.20', 'dark.304')
    const buttonPulse = useColorModeValue('rgba(255,255,255,0.5)', 'dark.306')

    useEffect(() => {
        if (advsMode && !layerMode.advs.sigwx) {
            setSelectedList('sigmet')
        }
        if (advsMode && !layerMode.advs.sigmet) {
            setSelectedList('sigwx')
        }
    }, [advsMode, layerMode])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }
    const focusShadow = {
        _focus: {
            boxShadow: useColorModeValue(
                '0px 1px 12px -5px rgba(0, 0, 0, 0.45)',
                '0px 1px 12px -5px rgba(0, 0, 0, 0.45)'
            ),
        },
    }
    const buttonShadowOpen = useColorModeValue(
        '0px 1px 12px -5px rgba(0, 0, 0, 0.5)',
        '0px 1px 12px -5px rgba(0, 0, 0, 0.5)'
    )
    const buttonShadowClosed = useColorModeValue(
        '0px 3px 12px -5px rgba(0, 0, 0, 0.3)',
        '0px 2px 20px -8px rgba(255,255,255,0.3)'
    )
    const topLeftRadius = useBreakpointValue({
        base: '20px',
        md: '25px',
    })
    const bottomLeftRadius = useBreakpointValue({
        base: '25px',
        md: '20px',
    })
    const topMargin = useBreakpointValue({
        base: '3px',
        md: 'unset',
    })

    const selectButtonIcon = (
        <AdvisoriesListIcon color={buttonColor} boxSize="20px" />
    )

    const sigmetCountTotal = sigmetCount || status.sigmet.count
    const layerSelectedCount =
        (metMode && layerMode.met.sigmet) ||
        (advsMode && selectedList === 'sigmet')
            ? sigmetCountTotal
            : sigwxCount

    const expandOnDefault =
        Number(layerSelectedCount) > 0 && window.innerWidth > 768
    useEffect(() => {
        if (expandOnDefault) {
            setExpanded(true)
        }
    }, [expandOnDefault, setExpanded])

    const drawerHeightBase = // mobile
        !dataLoading &&
        !dataLoadingError &&
        (Number(layerSelectedCount) === 1
            ? 96 + (bothAdvisoriesSelected && 0)
            : Number(layerSelectedCount) === 2
            ? 144 + (bothAdvisoriesSelected && 0)
            : Number(layerSelectedCount) === 3
            ? 192 + (bothAdvisoriesSelected && 0)
            : Number(layerSelectedCount) === 4
            ? 240 + (bothAdvisoriesSelected && 0)
            : Number(layerSelectedCount) >= 5
            ? 288 + (bothAdvisoriesSelected && 0)
            : bothAdvisoriesSelected
            ? 96
            : 96)

    const drawerHeightMd = // desktop
        !dataLoading &&
        !dataLoadingError &&
        (Number(layerSelectedCount) === 1
            ? 91 + (bothAdvisoriesSelected && 5)
            : Number(layerSelectedCount) === 2
            ? 139 + (bothAdvisoriesSelected && 5)
            : Number(layerSelectedCount) === 3
            ? 187 + (bothAdvisoriesSelected && 5)
            : Number(layerSelectedCount) === 4
            ? 235 + (bothAdvisoriesSelected && 5)
            : Number(layerSelectedCount) === 5
            ? 283 + (bothAdvisoriesSelected && 5)
            : Number(layerSelectedCount) === 6
            ? 331 + (bothAdvisoriesSelected && 5)
            : Number(layerSelectedCount) === 7
            ? 379 + (bothAdvisoriesSelected && 5)
            : Number(layerSelectedCount) >= 8
            ? 427 + (bothAdvisoriesSelected && 5)
            : bothAdvisoriesSelected
            ? 96
            : 91)

    const bottomMargin = useBreakpointValue({
        base: 'unset',
        md: expanded ? '3px' : '3px',
    })

    const drawerSize = useBreakpointValue({
        base: {
            width:
                expanded && window.innerWidth < 450
                    ? window.innerWidth - 90
                    : expanded
                    ? 330
                    : 0,
            height: expanded ? drawerHeightBase : 35,
        },
        md: {
            width: expanded ? 330 : bothAdvisoriesSelected ? 235 : 125,
            height: expanded ? drawerHeightMd : 34,
        },
    })

    const scrollHeight = useBreakpointValue({
        base: `calc(${drawerHeightBase}px - 48px)`,
        md: bothAdvisoriesSelected
            ? `calc(${drawerHeightMd}px - 55px)`
            : `calc(${drawerHeightMd}px - 48px)`,
    })

    const scrollBarHide = useBreakpointValue({
        base: 'scroll',
        md: 'leave',
    })

    const buttonBadge =
        metMode && layerMode.met.sigmet
            ? Number(sigmetCountTotal)
            : metMode && layerMode.met.sigwx
            ? Number(sigwxCount)
            : advsMode && layerMode.advs.sigmet && !bothAdvisoriesSelected
            ? Number(sigmetCountTotal)
            : advsMode && layerMode.advs.sigwx && !bothAdvisoriesSelected
            ? Number(sigwxCount)
            : bothAdvisoriesSelected
            ? Number(sigmetCountTotal) + Number(sigwxCount)
            : ''

    // MOBILE BUTTON CHANGE BY SWIPE LEFT/RIGHT
    // const buttonSwipe = useSwipeable({
    //     onSwipedLeft: () => {
    //         if (sigmetFirPosition > 0) {
    //             setSigmetFir(sigmet[sigmetFirPosition - 1].fir)
    //             setSigmetFirPosition(sigmetFirPosition - 1)
    //         }
    //     },
    //     onSwipedRight: () => {
    //         if (sigmetFirPosition < 2) {
    //             setSigmetFir(sigmet[sigmetFirPosition + 1].fir)
    //             setSigmetFirPosition(sigmetFirPosition + 1)
    //         }
    //     },
    // })

    return (
        <>
            <Flex
                className="list"
                direction={'row'}
                alignItems="center"
                justifyContent="space-between"
                position={{ base: 'fixed', md: 'fixed' }}
                left={{ base: '15px', md: '63px' }}
                top={{
                    base: '67px',
                    md: 'unset',
                }}
                bottom={{
                    base: 'unset',
                    md: '32px',
                }}
                zIndex="2"
            >
                <motion.div
                    expanded={expanded}
                    hidden={hidden}
                    initial={false}
                    transition={{ duration: 0.3 }}
                    onAnimationStart={() => setHidden(false)}
                    onAnimationComplete={() => setHidden(!expanded)}
                    animate={drawerSize}
                    style={{
                        display: 'block !important',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        position: 'absolute',
                        borderRadius: '25px',
                        borderTopLeftRadius: topLeftRadius,
                        borderBottomLeftRadius: bottomLeftRadius,
                        transition: 'background ease-in-out 300ms',
                        left: '3px',
                        minHeight: '34px',
                        top: topMargin,
                        bottom: bottomMargin,
                    }}
                >
                    <Flex
                        flexDirection={{
                            base: 'column-reverse',
                            md: 'column',
                        }}
                        background={
                            expanded
                                ? 'rgba(20,25,30, 0.16)'
                                : 'rgba(255,255,255,0)'
                        }
                        boxShadow={
                            expanded
                                ? 'inset 0px 0px 15px -7px rgba(0, 0, 0, 0.1)'
                                : '0px 5px 35px -7px rgba(0, 0, 0, 0)'
                        }
                        overflow="hidden"
                        borderRadius="25px"
                        borderTopLeftRadius={topLeftRadius}
                        borderBottomLeftRadius={bottomLeftRadius}
                        backdropFilter="blur(5px)"
                        padding={expanded ? '5px' : '0'}
                        width="100%"
                        height="100%"
                        transition="background ease-in-out 300ms"
                        justifyContent="space-between"
                        pt={{
                            base:
                                bothAdvisoriesSelected && expanded
                                    ? '5px'
                                    : '27px',
                            md: '0px',
                        }}
                        pb={{
                            base: '0px',
                            md:
                                bothAdvisoriesSelected && expanded
                                    ? '0'
                                    : '27px',
                        }}
                    >
                        <Flex
                            className="advisoriesScroll scrollVertical"
                            overflow="hidden"
                            borderTopLeftRadius={{
                                base: '0px',
                                md: '20px',
                            }}
                            borderTopRightRadius={{
                                base: '0px',
                                md: '20px',
                            }}
                            borderBottomLeftRadius={{
                                base: '20px',
                                md: '0px',
                            }}
                            borderBottomRightRadius={{
                                base: '20px',
                                md: '0px',
                            }}
                            mt={{
                                base: expanded ? '0px' : '0',
                                md: '10px',
                            }}
                            px="5px"
                            height={{
                                base: `calc(${drawerHeightBase}px - 48px)`,
                                md: bothAdvisoriesSelected
                                    ? `calc(${drawerHeightMd}px - 55px)`
                                    : `calc(${drawerHeightMd}px - 48px)`,
                            }}
                            opacity={expanded ? '1' : '0'}
                            visibility={expanded ? 'visible' : 'hidden'}
                            transition="all ease 300ms"
                            flexDirection="column"
                        >
                            <OverlayScrollbarsComponent
                                // ref={instanceRef} // used to access inner values
                                options={{
                                    scrollbars: {
                                        visibility: 'auto',
                                        autoHide: scrollBarHide,
                                        autoHideDelay: 800,
                                        dragScrolling: true,
                                        clickScrolling: true,
                                        touchSupport: true,
                                    },
                                    overflow: {
                                        x: 'hidden',
                                        y: 'scroll',
                                    },
                                }}
                                style={{
                                    height: scrollHeight,
                                }}
                            >
                                {((metMode && layerMode.met.sigmet) ||
                                    (advsMode &&
                                        layerMode.advs.sigmet &&
                                        selectedList === 'sigmet')) &&
                                    expanded && (
                                        <SigmetList
                                            setSigmetCount={setSigmetCount}
                                            expanded={expanded}
                                            dataLoading={dataLoading}
                                            setDataLoading={setDataLoading}
                                            dataLoadingError={dataLoadingError}
                                            setDataLoadingError={
                                                setDataLoadingError
                                            }
                                            lowerAltitude={lowerAltitude}
                                            upperAltitude={upperAltitude}
                                        />
                                    )}
                                {((metMode && layerMode.met.sigwx) ||
                                    (advsMode && layerMode.advs.sigwx)) && (
                                    <>
                                        <SigwxList
                                            className="sigwxbox"
                                            expanded={expanded}
                                            dataLoading={dataLoading}
                                            setDataLoading={setDataLoading}
                                            dataLoadingError={dataLoadingError}
                                            setDataLoadingError={
                                                setDataLoadingError
                                            }
                                            lowerAltitude={lowerAltitude}
                                            upperAltitude={upperAltitude}
                                            setSigwxCount={setSigwxCount}
                                            selectedList={selectedList}
                                            advsMode={advsMode}
                                        />
                                    </>
                                )}
                            </OverlayScrollbarsComponent>
                        </Flex>

                        {expanded && bothAdvisoriesSelected && (
                            <Flex
                                // {...buttonSwipe}
                                alignItems="center"
                                justifyContent="center"
                                opacity={expanded ? '1' : '0'}
                                visibility={expanded ? 'visible' : 'hidden'}
                                transition="all ease 300ms"
                                position={{
                                    base: 'relative',
                                    md: 'absolute',
                                }}
                                bottom={{
                                    base: 'unset',
                                    md: '5px',
                                }}
                                pt={{
                                    base: '3px',
                                    md: '0px',
                                }}
                                pb={{
                                    base: '8px',
                                    md: '5px',
                                }}
                                left={{
                                    base: 'unset',
                                    md: 'calc(50% - 98px)',
                                }}
                                zIndex="1"
                                width={{
                                    base: '100%',
                                    md: 'auto',
                                }}
                            >
                                <ButtonGroup // SELECTION BUTTON
                                    borderRadius="20px"
                                    px="2px"
                                    overflow="hidden"
                                    bg="rgba(10, 20, 30, 0.2)"
                                    boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.35)"
                                >
                                    <Button // SIGMET BUTTON
                                        bg="transparent"
                                        variant="outline"
                                        fontSize="0.9rem"
                                        fontWeight="600"
                                        height={{
                                            base: '32px',
                                            md: '28px',
                                        }}
                                        width={{
                                            base: '88px',
                                            md: '96px',
                                        }}
                                        marginInlineStart="0px !important"
                                        border="none"
                                        justifyContent="center"
                                        paddingLeft="2px"
                                        paddingRight="2px"
                                        py="2px"
                                        {...noFocus}
                                        onClick={() => {
                                            setSelectedList('sigmet')
                                        }}
                                        opacity={
                                            selectedList === 'sigmet'
                                                ? '1'
                                                : isFirefox
                                                ? '0.8'
                                                : '0.7'
                                        }
                                        zIndex="1"
                                        _active={{
                                            bg: 'none',
                                        }}
                                        _focus={{
                                            bg: 'none',
                                        }}
                                        _hover={{
                                            bg: 'none',
                                            opacity: '1',
                                        }}
                                    >
                                        <Text
                                            zIndex="1"
                                            paddingLeft="0px"
                                            lineHeight="1.5"
                                            textTransform="uppercase"
                                            fontSize="0.7rem"
                                            color={
                                                selectedList === 'sigmet'
                                                    ? 'light.100'
                                                    : 'light.10'
                                            }
                                            fontWeight={
                                                selectedList === 'sigmet'
                                                    ? '600'
                                                    : '600'
                                            }
                                            _active={{
                                                color:
                                                    selectedList === 'sigmet'
                                                        ? 'light.100'
                                                        : 'light.10',
                                                fontWeight:
                                                    selectedList === 'sigmet'
                                                        ? '700'
                                                        : '600',
                                                opacity:
                                                    selectedList === 'sigmet'
                                                        ? '1'
                                                        : '0.8',
                                            }}
                                            _selected={{
                                                color: 'light.10',
                                                fontWeight: '700',
                                                opacity: '1',
                                            }}
                                            transition="opacity 400ms, color 400ms, fontWeight ease-out 400ms"
                                        >
                                            SIGMET
                                        </Text>
                                        <Badge
                                            marginTop="1px"
                                            ml="5px"
                                            variant="solid"
                                            bg={
                                                selectedList === 'sigmet'
                                                    ? '#ffeb7c'
                                                    : 'light.10'
                                            }
                                            color={
                                                selectedList === 'sigmet'
                                                    ? '#666666'
                                                    : 'light.100'
                                            }
                                            textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                            borderRadius="10"
                                            px="5px"
                                            pb="0px"
                                            pt="0px"
                                            fontSize="10px"
                                            height="16px"
                                            minWidth="16px"
                                            lineHeight="1.6"
                                        >
                                            {sigmetCountTotal}
                                        </Badge>
                                    </Button>
                                    <Button // SIGWX BUTTON
                                        bg="transparent"
                                        variant="outline"
                                        fontSize="0.9rem"
                                        fontWeight="600"
                                        height={{
                                            base: '32px',
                                            md: '28px',
                                        }}
                                        width={{
                                            base: '88px',
                                            md: '96px',
                                        }}
                                        marginInlineStart="0px !important"
                                        border="none"
                                        justifyContent="center"
                                        paddingLeft="2px"
                                        paddingRight="2px"
                                        py="2px"
                                        {...noFocus}
                                        onClick={() => {
                                            setSelectedList('sigwx')
                                        }}
                                        opacity={
                                            selectedList === 'sigwx'
                                                ? '1'
                                                : isFirefox
                                                ? '0.8'
                                                : '0.7'
                                        }
                                        zIndex="1"
                                        _active={{
                                            bg: 'none',
                                        }}
                                        _focus={{
                                            bg: 'none',
                                        }}
                                        _hover={{
                                            bg: 'none',
                                            opacity: '1',
                                        }}
                                    >
                                        <Text
                                            zIndex="1"
                                            paddingLeft="0px"
                                            lineHeight="1.5"
                                            textTransform="uppercase"
                                            fontSize="0.7rem"
                                            color={
                                                selectedList === 'sigwx'
                                                    ? 'light.100'
                                                    : 'light.10'
                                            }
                                            fontWeight={
                                                selectedList === 'sigwx'
                                                    ? '600'
                                                    : '600'
                                            }
                                            _active={{
                                                color:
                                                    selectedList === 'sigwx'
                                                        ? 'light.100'
                                                        : 'light.10',
                                                fontWeight:
                                                    selectedList === 'sigwx'
                                                        ? '700'
                                                        : '600',
                                                opacity:
                                                    selectedList === 'sigwx'
                                                        ? '1'
                                                        : '0.8',
                                            }}
                                            _selected={{
                                                color: 'light.10',
                                                fontWeight: '700',
                                                opacity: '1',
                                            }}
                                            transition="opacity 400ms, color 400ms, fontWeight ease-out 400ms"
                                        >
                                            SIGWX
                                        </Text>
                                        {sigwxCount !== null && (
                                            <Badge
                                                marginTop="1px"
                                                ml="5px"
                                                variant="solid"
                                                bg={
                                                    selectedList === 'sigwx'
                                                        ? '#ffeb7c'
                                                        : 'light.10'
                                                }
                                                color={
                                                    selectedList === 'sigwx'
                                                        ? '#666666'
                                                        : 'light.100'
                                                }
                                                textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                                borderRadius="10"
                                                px="5px"
                                                pb="0px"
                                                pt="0px"
                                                fontSize="10px"
                                                height="16px"
                                                minWidth="16px"
                                                lineHeight="1.6"
                                            >
                                                {sigwxCount}
                                            </Badge>
                                        )}
                                    </Button>

                                    <Flex // BUTTON INDICATOR
                                        position="absolute"
                                        marginInlineStart="0px !important"
                                        mt="2px"
                                        ml="0px"
                                        px="44px"
                                        height={{
                                            base: '28px',
                                            md: '24px',
                                        }}
                                        width={{
                                            base: '88px',
                                            md: '96px',
                                        }}
                                        background={
                                            isFirefox
                                                ? 'rgba(255,255,255,0.8)'
                                                : 'rgba(255,255,255,0.7)'
                                        }
                                        borderRadius="20px"
                                        boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                        transform={
                                            selectedList === 'sigwx'
                                                ? 'translateX(100%)'
                                                : 'translateX(0%)'
                                        }
                                        transition="transform 400ms"
                                    ></Flex>
                                </ButtonGroup>
                            </Flex>
                        )}
                    </Flex>
                </motion.div>

                {/* *** List Expand Button  *** */}
                <Flex
                    display={{ base: 'flex', md: 'flex' }}
                    w="40px"
                    h="40px"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Badge // total number (SIGMET and SIGWX)
                        visibility={{
                            base: !expanded ? 'visible' : 'hidden',
                            md: 'hidden',
                        }}
                        opacity={{
                            base: !expanded ? '1' : '0',
                            md: '0',
                        }}
                        display={{
                            base: 'inline-block',
                            md: 'none',
                        }}
                        position="absolute"
                        zIndex="2"
                        transition="all ease-in-out 400ms"
                        marginTop="-30px"
                        mr="-30px"
                        variant="solid"
                        bg="#ffeb7c"
                        color="#666666"
                        textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                        borderRadius="10"
                        px="6px"
                        pb="0px"
                        pt="0px"
                        fontSize="10px"
                        height="18px"
                        lineHeight="1.8"
                    >
                        {buttonBadge}
                    </Badge>
                    <IconButton
                        onClick={() => {
                            setExpanded(!expanded)
                            isMobile && !expanded && closeLayerSelector()
                        }}
                        {...focusShadow}
                        w={{ base: '36px', md: '36px' }}
                        minWidth="36px"
                        maxWidth="36px"
                        h={{ base: '36px', md: '36px' }}
                        borderRadius="100%"
                        backdropFilter={expanded ? 'blur(5px)' : 'blur(2px)'}
                        boxShadow={
                            expanded ? buttonShadowOpen : buttonShadowClosed
                        }
                        background={{
                            base: expanded ? listButton : expandButton,
                            md: expanded ? listButton : expandButton,
                        }}
                        color={buttonColor}
                        _active={{
                            backgroundColor: expanded
                                ? listButtonHover
                                : expandButton,
                        }}
                        _hover={{
                            backgroundColor: expanded
                                ? listButtonHover
                                : expandButtonHover,
                        }}
                        transition="opacity ease-out 300ms, visibility ease-out 300m"
                        zIndex="1"
                        position="absolute"
                        icon={
                            metMode &&
                            ((layerMode.met.sigmet &&
                                layerStatus.sigmetLoading) ||
                                (layerMode.met.sigwx &&
                                    layerStatus.sigwxLoading)) ? (
                                <Spinner color={buttonColor} boxSize="20px" />
                            ) : expanded ? (
                                <CloseIcon color={buttonColor} />
                            ) : (
                                selectButtonIcon
                            )
                        }
                    />

                    <Box //OPENING PULSE
                        position="absolute"
                        w={expanded ? '55px' : '0px'}
                        h={expanded ? '55px' : '0px'}
                        margin="auto"
                        borderRadius="100%"
                        background={expanded ? buttonPulse : 'transparent'}
                        opacity={expanded ? '0' : '1'}
                        transition="width ease-out 100ms, height ease-out 100ms, opacity ease-in 450ms"
                    ></Box>
                    <Box // CLOSING PULSE
                        position="absolute"
                        w={!expanded ? '55px' : '0px'}
                        h={!expanded ? '55px' : '0px'}
                        margin="auto"
                        borderRadius="100%"
                        background={!expanded ? buttonPulse : 'transparent'}
                        opacity={!expanded ? '0' : '1'}
                        transition="width ease-out 100ms, height ease-out 100ms, opacity ease-in 450ms"
                    ></Box>
                </Flex>

                {/* *** Breadcrumb  *** */}

                <Flex
                    pointerEvents="none"
                    zIndex={{ base: '1', md: '0' }}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    mt={{ base: expanded ? '5px' : '-27px', md: '0px' }}
                    ml={{ base: expanded ? '5px' : '-23px', md: '-37px' }}
                    pl={{ base: '0', md: '45px' }}
                    pr={{ base: '10px', md: '10px' }}
                    height="34px"
                    minWidth={{ base: '0', md: '115px' }}
                    width={{
                        base: expanded ? 'auto' : '0px',
                        md: 'auto',
                    }}
                    transition="all ease-in 300ms"
                    background={{
                        base: 'none',
                        md: !expanded
                            ? 'rgba(255,255,255,0.18)'
                            : 'transparent',
                    }}
                    backdropFilter={{
                        base: 'none',
                        md: 'blur(2px)',
                    }}
                    visibility={
                        metMode && (layerMode.met.sigmet || layerMode.met.sigwx)
                            ? 'visible'
                            : advsMode && !expanded
                            ? 'visible'
                            : advsMode &&
                              expanded &&
                              ((layerMode.advs.sigmet &&
                                  !layerMode.advs.sigwx) ||
                                  (!layerMode.advs.sigmet &&
                                      layerMode.advs.sigwx))
                            ? 'visible'
                            : 'hidden'
                    }
                    boxShadow={{
                        base: 'none',
                        md: !expanded
                            ? '0px 5px 35px -7px rgba(0, 0, 0, 0.07)'
                            : 'none',
                    }}
                    overflow="hidden"
                    borderRadius="25px"
                    opacity={
                        metMode && (layerMode.met.sigmet || layerMode.met.sigwx)
                            ? '1'
                            : advsMode && !expanded
                            ? '1'
                            : advsMode &&
                              expanded &&
                              ((layerMode.advs.sigmet &&
                                  !layerMode.advs.sigwx) ||
                                  (!layerMode.advs.sigmet &&
                                      layerMode.advs.sigwx))
                            ? '1'
                            : '0'
                    }
                >
                    <Flex
                        mt={{
                            base:
                                expanded &&
                                advsMode &&
                                Number(layerSelectedCount) > 0
                                    ? '10px'
                                    : '0',
                            md:
                                expanded &&
                                advsMode &&
                                Number(layerSelectedCount) > 0
                                    ? '-5px'
                                    : '0',
                        }}
                        ml={{
                            base: expanded ? '85px' : '0',
                            md: expanded ? '95px' : '0',
                        }}
                        transition="all ease-out 300ms"
                        zIndex="1"
                    >
                        {((metMode && layerMode.met.sigmet) ||
                            (advsMode && layerMode.advs.sigmet)) && (
                            <Flex
                                visibility={{
                                    base: bothAdvisoriesSelected
                                        ? 'hidden'
                                        : expanded
                                        ? 'visible'
                                        : advsMode &&
                                          expanded &&
                                          !layerMode.advs.sigwx
                                        ? 'visible'
                                        : 'hidden',
                                    md: 'visible',
                                }}
                                opacity={{
                                    base: bothAdvisoriesSelected
                                        ? '0'
                                        : expanded
                                        ? '1'
                                        : advsMode &&
                                          expanded &&
                                          !layerMode.advs.sigwx
                                        ? '1'
                                        : '0',
                                    md: '1',
                                }}
                                transition="all ease-in-out 0ms"
                            >
                                <Text
                                    zIndex="1"
                                    paddingLeft="0px"
                                    lineHeight="1.5"
                                    textTransform="uppercase"
                                    fontSize={{
                                        base: expanded ? '0.8rem' : '0.8rem',
                                        md: '0.8rem',
                                    }}
                                    fontWeight={expanded ? '600' : '400'}
                                    textShadow="1px 1px 3px rgba(0,0,0,0.25)"
                                    letterSpacing="0.4px"
                                    color={'light.10'}
                                >
                                    SIGMET
                                </Text>
                                <Badge
                                    marginTop="1px"
                                    ml={2}
                                    variant="solid"
                                    bg="#ffeb7c"
                                    color="#666666"
                                    textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                    borderRadius="10"
                                    px="6px"
                                    pb="0px"
                                    pt="0px"
                                    fontSize="10px"
                                    height="18px"
                                    lineHeight="1.8"
                                >
                                    {sigmetCountTotal}
                                </Badge>
                            </Flex>
                        )}
                        {bothAdvisoriesSelected && (
                            <Text
                                mx="15px"
                                color={'light.10'}
                                fontSize={{
                                    base: expanded ? '1.1rem' : '0rem',
                                    md: '1.1rem',
                                }}
                                fontWeight={expanded ? '600' : '400'}
                                visibility={{
                                    base: 'hidden',
                                    md: !expanded ? 'visible' : 'hidden',
                                }}
                                opacity={{
                                    base: '0',
                                    md: !expanded ? '1' : '0',
                                }}
                                textShadow="1px 1px 2px rgba(0,0,0,0.2)"
                                transition="all ease-in-out 400ms"
                            >
                                /
                            </Text>
                        )}
                        {((metMode && layerMode.met.sigwx) ||
                            (advsMode && layerMode.advs.sigwx)) && (
                            <Flex
                                visibility={{
                                    base: bothAdvisoriesSelected
                                        ? 'hidden'
                                        : expanded
                                        ? 'visible'
                                        : advsMode &&
                                          expanded &&
                                          !layerMode.advs.sigmet
                                        ? 'visible'
                                        : 'hidden',
                                    md: 'visible',
                                }}
                                opacity={{
                                    base: bothAdvisoriesSelected
                                        ? '0'
                                        : expanded
                                        ? '1'
                                        : advsMode &&
                                          expanded &&
                                          !layerMode.advs.sigmet
                                        ? '1'
                                        : '0',
                                    md: '1',
                                }}
                                transition="all ease-in-out 0ms"
                            >
                                <Text
                                    zIndex="1"
                                    paddingLeft="0px"
                                    lineHeight="1.5"
                                    textTransform="uppercase"
                                    fontSize={{
                                        base: expanded ? '0.8rem' : '0.8rem',
                                        md: '0.8rem',
                                    }}
                                    fontWeight={expanded ? '600' : '400'}
                                    textShadow="1px 1px 3px rgba(0,0,0,0.25)"
                                    letterSpacing="0.4px"
                                    color={'light.10'}
                                >
                                    SIGWX
                                </Text>
                                {sigwxCount !== null && (
                                    <Badge
                                        marginTop="1px"
                                        ml={2}
                                        variant="solid"
                                        bg="#ffeb7c"
                                        color="#666666"
                                        textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                        borderRadius="10"
                                        px="6px"
                                        pb="0px"
                                        pt="0px"
                                        fontSize="10px"
                                        height="18px"
                                        lineHeight="1.8"
                                    >
                                        {sigwxCount}
                                    </Badge>
                                )}
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
