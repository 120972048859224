import React from 'react'
import { Flex, Text, Icon } from '@chakra-ui/react'
import VisibilityIcon from '../../../../../icons/menu-icons/VisibilityIcon'
import { VisibilityScale } from '../../graphics/WeatherGraphics'

export default function AwsVisibility(props) {
    const { visibility, visibilityIsKM, infotabGraphicsDisabled } = props

    return (
        <Flex
            width="100%"
            background="light.10"
            boxShadow={
                !infotabGraphicsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            pt={infotabGraphicsDisabled ? '7px' : '10px'}
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '7px' : { base: '13px', md: '10px' }}
            direction={{
                base: 'column',
                md: 'column',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                }}
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color="#9DB2CD"
                >
                    <VisibilityIcon boxSize="36px" />
                </Flex>
                <Flex direction="column">
                    <Text
                        whiteSpace="pre"
                        fontSize={{
                            base: '1.4rem',
                            md: '1.2rem',
                        }}
                        lineHeight="1.2"
                        pt={{
                            base: '5px',
                            md: '6px',
                        }}
                        pl={infotabGraphicsDisabled ? '10px' : '6px'}
                        fontWeight="600"
                    >
                        {visibility === 9999
                            ? '> ' + visibility + ' m'
                            : visibility + ' km'}
                    </Text>
                </Flex>
            </Flex>

            {!infotabGraphicsDisabled && (
                <Flex
                    pt="5px"
                    pb="5px"
                    pl="5px"
                    pr={{
                        base: '3px',
                        md: '5px',
                    }}
                    width="100%"
                    justifyContent={{
                        base: 'center',
                        md: 'flex-end',
                    }}
                    alignItems={{
                        base: 'center',
                        md: 'center',
                    }}
                    flexDirection="column"
                >
                    <Flex
                        height="16px"
                        mt="-2px"
                        width="100%"
                        textAlign="right"
                        color="gray.500"
                        fontSize={{
                            base: '0.65rem',
                            md: '0.55rem',
                        }}
                        opacity="0.8"
                        letterSpacing="0.5px"
                        fontWeight={400}
                        justifyContent="space-between"
                    >
                        <Text
                            ml={{
                                base: '0px',
                                md: '0px',
                            }}
                        >
                            {'0 m'}
                        </Text>
                        <Text
                            mr={{
                                base: '0px',
                                md: '0px',
                            }}
                        >
                            {'> 9999 m'}
                        </Text>
                    </Flex>
                    <Icon
                        as={VisibilityScale}
                        focusImageColor={'#00f1ab'}
                        imageColor={'#83909F'}
                        data={
                            visibility === 9999
                                ? 9999
                                : visibility && visibilityIsKM
                                ? visibility * 1000
                                : visibility
                                ? visibility
                                : 0
                        }
                    />
                </Flex>
            )}
        </Flex>
    )
}
