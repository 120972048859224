import MapboxDraw from '@mapbox/mapbox-gl-draw'

const Constants = MapboxDraw.constants
const { CommonSelectors, doubleClickZoom, isEventAtCoordinates } =
    MapboxDraw.lib

const BriefingAreaMode = { ...MapboxDraw.modes.draw_polygon }

const BriefingAreaStyle = [
    {
        id: 'gl-draw-polygon-fill',
        type: 'fill',
        filter: ['all', ['==', '$type', 'Polygon']],
        paint: {
            'fill-color': '#00B2FF',
            'fill-outline-color': '#00D1FF',
            'fill-opacity': 0.15,
        },
    },

    {
        id: 'gl-draw-polygon-stroke',
        type: 'line',
        filter: ['==', 'mode', 'briefing_area'],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#00D1FF',
            'line-dasharray': [0.5, 2],
            'line-width': 3,
        },
    },
]

BriefingAreaMode.onSetup = function (opts) {
    const { setPointCount } = opts
    const polygon = this.newFeature({
        type: Constants.geojsonTypes.FEATURE,
        properties: {
            ...opts.properties,
            type: 'customArea',
        },
        geometry: {
            type: Constants.geojsonTypes.POLYGON,
            coordinates: [[]],
        },
    })

    this.addFeature(polygon)

    this.clearSelectedFeatures()
    doubleClickZoom.disable(this)
    this.updateUIClasses({ mouse: Constants.cursors.ADD })
    this.activateUIButton(Constants.types.POLYGON)
    this.setActionableState({
        trash: true,
    })

    return {
        polygon,
        currentVertexPosition: 0,
        lastClick: null,
        setPointCount: setPointCount,
    }
}

BriefingAreaMode.clickAnywhere = function (state, e) {
    if (
        (state.currentVertexPosition > 0 &&
            isEventAtCoordinates(
                e,
                state.polygon.coordinates[0][state.currentVertexPosition - 1]
            )) ||
        state.currentVertexPosition === 50
    ) {
        return this.changeMode(Constants.modes.SIMPLE_SELECT, {
            featureIds: [state.polygon.id],
        })
    }
    this.updateUIClasses({ mouse: Constants.cursors.ADD })

    if (state.currentVertexPosition < 50) {
        state.polygon.updateCoordinate(
            `0.${state.currentVertexPosition}`,
            e.lngLat.lng,
            e.lngLat.lat
        )
        state.currentVertexPosition++
        state.polygon.updateCoordinate(
            `0.${state.currentVertexPosition}`,
            e.lngLat.lng,
            e.lngLat.lat
        )
    }

    if (state.currentVertexPosition) {
        state.setPointCount(state.currentVertexPosition)
    }
}

BriefingAreaMode.onMouseMove = function (state, e) {
    state.polygon.updateCoordinate(
        `0.${state.currentVertexPosition}`,
        e.lngLat.lng,
        e.lngLat.lat
    )
    this.updateUIClasses({ mouse: Constants.cursors.ADD })
}

BriefingAreaMode.onTap = BriefingAreaMode.onClick = function (state, e) {
    // simulate double clicking to end drawing
    // if last click was less than 300ms ago
    if (state.lastClick && Date.now() - state.lastClick < 300) {
        return this.changeMode('static')
    }
    state.lastClick = Date.now()

    if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e)
    return this.clickAnywhere(state, e)
}

export { BriefingAreaMode, BriefingAreaStyle }
