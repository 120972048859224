import React, { useState, useContext, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Source, Layer, MapContext } from 'react-mapbox-gl'

import {
    altitudeModeState,
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    selectedTimeState,
    layerStatusState,
    layersLoaded,
    sigWxDataState,
} from '../../../globalState'

import calcSymbolPlacement from '../functions/dynamicSymbols'

export default function SigWxLayer() {
    const [altitudeMode, setAltitudeMode] = useRecoilState(altitudeModeState)
    const selectedLowerAltitude = useRecoilValue(selectedLowerAltitudeState)
    const selectedUpperAltitude = useRecoilValue(selectedUpperAltitudeState)

    const selectedTime = useRecoilValue(selectedTimeState)

    const data = useRecoilValue(sigWxDataState)

    const [loaded, setLoaded] = useRecoilState(layersLoaded)
    const [mounted, setMounted] = useState(false)
    const layerState = useRecoilValue(layerStatusState)

    const layerId = 'sigwx'

    useEffect(() => {
        if (altitudeMode !== 'double') {
            setAltitudeMode('double')
        }
    }, [altitudeMode, setAltitudeMode])

    const mapInstance = useContext(MapContext)

    // labelData is defined here as a useRef, directly using an empty FeatureCollection in the source breaks dynamic symbol placement
    const labelData = useRef({
        type: 'FeatureCollection',
        features: [],
    })

    // trigger loading once to avoid redrawing the time slider.

    useEffect(() => {
        if (!mounted && !loaded) {
            setLoaded(!layerState[`${layerId}Loading`])
            setMounted(true)
        }
    }, [layerState, setLoaded, mounted, loaded])

    useEffect(() => {
        const dynamicSymbols = () => {
            calcSymbolPlacement(mapInstance, 'sigwx-fill', 'sigwx-symbol', 'id')
        }

        const moveEndCallback = () => {
            var tileLoad = setInterval(function () {
                if (mapInstance.loaded()) {
                    dynamicSymbols()
                    clearInterval(tileLoad)
                }
            }, 300)
        }

        mapInstance.on('moveend', moveEndCallback)

        function renderCallback(e) {
            if (e.target && e.target.loaded()) {
                dynamicSymbols()
                mapInstance.off('render', renderCallback)
            }
        }

        mapInstance.on('render', renderCallback)

        return () => {
            mapInstance.off('moveend', moveEndCallback)
        }
    }, [data, mapInstance])

    const metaData = data && data?.metadata

    const hasVAEruptionSigmet =
        metaData?.alerts &&
        metaData?.alerts.length &&
        metaData?.alerts.some((value) => value.startsWith('VA SIGMET in force'))

    const color = [
        'match',
        ['get', 'type'],
        'clearAirTurbulence',
        '#ffcc00',
        'cumulonimbus',
        '#008800',
        'icing',
        '#00BEFA',
        'mountainWaves',
        '#152238',
        'turbulence',
        '#e25a5a',
        'volcano',
        '#FF6CFF',
        'radiation',
        '#94DA00',
        '#85a0b5',
    ]

    const icon = [
        'match',
        ['get', 'type'],
        'clearAirTurbulence',
        'clearairturbulence-moderate',
        'cumulonimbus',
        'cumulonimbus',
        'icing',
        'icing-moderate',
        'mountainWaves',
        'mountainwave-moderate',
        'turbulence',
        'turbulence-moderate',
        'volcano',
        hasVAEruptionSigmet ? 'vac-symbol' : 'vaa-symbol', // if VA SIGMET in force, use VAC symbol instead
        'radiation',
        'radioactivecloud',
        'wind',
    ]

    // useEffect(() => {
    //     console.log('SIGWX Layer Data: ', data)
    // }, [data])

    return (
        <>
            <Source
                id="sigwx"
                geoJsonSource={{
                    type: 'geojson',
                    data: data,
                    // layerInfo.sigwx.dataURL,
                }}
            />
            <Source
                id="sigwx-symbol"
                geoJsonSource={{
                    type: 'geojson',
                    data: labelData,
                }}
            />
            <Layer
                id="sigwx-fill"
                sourceId="sigwx"
                before="aerodromes-symbol"
                type="fill"
                paint={{
                    'fill-color': color,
                    'fill-opacity': 0.1,
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />
            <Layer
                id="sigwx-fill-pattern"
                sourceId="sigwx"
                before="aerodromes-symbol"
                type="fill"
                paint={{
                    'fill-pattern': 'mtwhash',
                    'fill-opacity': 0.2,
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['==', ['get', 'type'], 'mountainWaves'],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />
            <Layer
                id="sigwx-line"
                sourceId="sigwx"
                before="aerodromes-symbol"
                type="line"
                paint={{
                    'line-color': color,
                    'line-width': 2,
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />
            <Layer
                id="sigwx-symbol"
                sourceId="sigwx-symbol"
                type="symbol"
                layout={{
                    'icon-image': icon,
                    'icon-size': 0.4,
                    'icon-optional': false,
                    'icon-allow-overlap': true,
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />
            <Layer
                id="sigwx-symbol-static"
                sourceId="sigwx"
                type="symbol"
                layout={{
                    'icon-image': icon,
                    'icon-size': 0.4,
                    'icon-optional': false,
                    'icon-allow-overlap': true,
                }}
                filter={[
                    'all',
                    ['==', ['geometry-type'], 'Point'],
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
            />
            <Layer
                id="sigwx-text"
                sourceId="sigwx"
                type="symbol"
                layout={{
                    'text-field': ['get', 'alertlevel'],
                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],

                    'text-allow-overlap': true,
                    'text-anchor': 'center',
                    'text-offset': [0, 0.07],
                    'text-size': 9,
                    'text-letter-spacing': -0.07,
                    'text-optional': true,
                }}
                filter={[
                    'all',
                    ['==', ['get', 'type'], 'volcano'],
                    ['==', ['geometry-type'], 'Point'],
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                    ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                ]}
                paint={{
                    'text-color': '#FFF',
                    'text-halo-color': '#fff',
                    'text-halo-width': 0.1,
                }}
            />
        </>
    )
}
