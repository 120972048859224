import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAdminRole } from '../auth/hooks'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Icon,
    Divider,
} from '@chakra-ui/react'
import { osVersion, browserVersion } from 'react-device-detect'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import {
    setModalVisibilityState,
    modalVisibilityState,
    oldDeviceWarningAcceptedState,
} from '../globalState'
import { GoCheck } from 'react-icons/go'
import { ReactComponent as CautionIcon } from '../icons/assets/caution.svg'
import DeviceRequirementsModal from './DeviceRequirements'

export default function DeviceWarning(props) {
    const {
        olderDevice,
        isOldDevice,
        detectorsArray,
        deviceWarning,
        setDeviceWarning,
    } = props
    const isAdmin = useAdminRole()
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const [doNotShowAgainSelected, setDoNotShowAgainSelected] = useState(false)
    const [warningAccepted, setWarningAccepted] = useRecoilState(
        oldDeviceWarningAcceptedState
    )
    const startTimer = useRef(null)
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceWarning', value })
        },
        [setModalVisibility]
    )

    const setShowDeviceRequirements = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceRequirements', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        if (deviceWarning)
            startTimer.current = setTimeout(() => {
                modalVisibility.deviceWarning ? onOpen() : onClose()
            }, 1500)
        else {
            modalVisibility.deviceWarning ? onOpen() : onClose()
        }
    }, [modalVisibility.deviceWarning, deviceWarning, onOpen, onClose])

    function viewRequirementsHandler() {
        setShow(false)
        setShowDeviceRequirements(true)
    }

    function acceptandContinueHandler() {
        if (deviceWarning) {
            if (doNotShowAgainSelected) {
                setWarningAccepted(true)
            }
            setShow(false)
            setDeviceWarning(false)
        } else setShow(false)
    }

    const oldDevices =
        isOldDevice &&
        Object.keys(isOldDevice)
            .filter((device) => isOldDevice[device])
            .map((device) => device)

    // console the details
    olderDevice && console.log('OS Version:', osVersion)
    olderDevice && console.log('Browser Version:', browserVersion)

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={deviceWarning && 'deviceWarning'}
                    size="2xl"
                    onClose={onClose}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                >
                    {!deviceWarning && <ModalOverlay />}
                    <ModalContent>
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="#FF4F44"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="row"
                            >
                                <Icon
                                    as={CautionIcon}
                                    width="30px"
                                    height="30px"
                                    color="#FF4F44"
                                    mr="10px"
                                />
                                <Text>Potential Performance Issues</Text>
                            </Flex>
                        </ModalHeader>
                        <ModalBody
                            background="light.600"
                            style={{ padding: 0 }}
                            fontSize="0.9rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight="65vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt={5}
                                pb={2}
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                {/* <Icon
                                        as={CautionIcon}
                                        width="50px"
                                        height="50px"
                                        color="#FF4F44"
                                    /> */}
                                <Text
                                    pt="15px"
                                    fontSize={{
                                        base: '0.9rem',
                                        md: '0.9rem',
                                    }}
                                    color="light.100"
                                    opacity="0.7"
                                    textAlign="center"
                                >
                                    We have detected that you may be running
                                    this web application on an older device, or
                                    its software may be out of date.
                                </Text>
                                <Text
                                    pt="15px"
                                    pb="25px"
                                    fontSize={{
                                        base: '0.9rem',
                                        md: '0.9rem',
                                    }}
                                    color="light.100"
                                    opacity="0.7"
                                    textAlign="center"
                                >
                                    For the best experience and to help avoid
                                    potential performance issues, we recommend
                                    using a device that’s less than 4 years old
                                    and updated to the latest software version.
                                </Text>
                                {deviceWarning && !warningAccepted && (
                                    <>
                                        <Divider
                                            variant="modalFooter"
                                            borderColor="#e2e4e8"
                                            marginTop={0}
                                            marginBottom={6}
                                        />
                                        <Flex
                                            flexDirection="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="100%"
                                            pt="10px"
                                            pb="40px"
                                        >
                                            <Flex
                                                flexDirection="column"
                                                mb={{
                                                    base: '-16px',
                                                    sm: '-16px',
                                                    md: '-17px',
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Button
                                                    width={{
                                                        base: '38px',
                                                        md: '34px',
                                                    }}
                                                    minWidth={{
                                                        base: '38px',
                                                        md: '34px',
                                                    }}
                                                    height={{
                                                        base: '38px',
                                                        md: '34px',
                                                    }}
                                                    color="white"
                                                    background={
                                                        doNotShowAgainSelected
                                                            ? 'light.200'
                                                            : 'light.10'
                                                    }
                                                    borderColor={
                                                        doNotShowAgainSelected
                                                            ? 'light.201'
                                                            : 'gray.300'
                                                    }
                                                    onClick={() =>
                                                        setDoNotShowAgainSelected(
                                                            (prevState) =>
                                                                !prevState
                                                        )
                                                    }
                                                    variant="outline"
                                                    fontSize="1.5rem"
                                                    borderRadius="10px"
                                                    fontWeight="500"
                                                    px="0px"
                                                    _hover={{
                                                        color: 'light.10',
                                                        background:
                                                            doNotShowAgainSelected
                                                                ? 'light.201'
                                                                : 'light.30',
                                                    }}
                                                >
                                                    {doNotShowAgainSelected ? (
                                                        <GoCheck />
                                                    ) : (
                                                        ''
                                                    )}
                                                </Button>
                                                <Text
                                                    pt="2px"
                                                    fontSize="0.65rem"
                                                    color="gray.300"
                                                >
                                                    Tick
                                                </Text>
                                            </Flex>
                                            <Text
                                                fontSize={{
                                                    base: '0.8rem',
                                                    sm: '0.85rem',
                                                }}
                                                color="light.100"
                                                opacity="0.7"
                                                pl="20px"
                                            >
                                                Don't show this warning again
                                            </Text>
                                        </Flex>
                                    </>
                                )}
                                {!deviceWarning && warningAccepted && (
                                    <>
                                        <Divider
                                            variant="modalFooter"
                                            borderColor="#e2e4e8"
                                            marginTop={0}
                                            marginBottom={6}
                                        />
                                        <Flex
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="100%"
                                            pb="30px"
                                        >
                                            <Text
                                                fontSize={{
                                                    base: '0.75rem',
                                                    sm: '0.8rem',
                                                }}
                                                fontWeight="300"
                                                color="gray.400"
                                            >
                                                You have previously selected to
                                                no longer show this warning when
                                                logging into PreFlight
                                            </Text>
                                        </Flex>
                                    </>
                                )}
                                {isAdmin && (
                                    <>
                                        <Divider
                                            variant="modalFooter"
                                            borderColor="#e2e4e8"
                                            marginTop={0}
                                            marginBottom={6}
                                        />
                                        <Text
                                            fontWeight="300"
                                            fontSize="0.7rem"
                                            pb="10px"
                                            color="gray.400"
                                        >
                                            Viewable by Dev Admins only:
                                        </Text>
                                        <Text fontWeight="600" pb="5px">
                                            Older Device
                                        </Text>
                                        <Flex>
                                            <Text fontWeight="500" pr="7px">
                                                Reason:
                                            </Text>
                                            {!deviceWarning && (
                                                <Text fontWeight="300">
                                                    *** TEST ***
                                                </Text>
                                            )}
                                            <Text
                                                fontWeight="300"
                                                textTransform="capitalize"
                                            >
                                                {oldDevices &&
                                                oldDevices.length > 0
                                                    ? `${oldDevices.join(', ')}`
                                                    : ''}
                                            </Text>
                                        </Flex>

                                        <Flex
                                            pt="10px"
                                            pb="2px"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            {detectorsArray &&
                                                detectorsArray.length > 0 &&
                                                detectorsArray.map((label) => {
                                                    olderDevice &&
                                                        console.log(label) // console the OS/browser
                                                    return (
                                                        <Flex
                                                            pt="2px"
                                                            key={label}
                                                        >
                                                            <Text
                                                                fontWeight="500"
                                                                pr="7px"
                                                            >
                                                                {label}:
                                                            </Text>
                                                            <Text fontWeight="300">
                                                                Yes
                                                            </Text>
                                                        </Flex>
                                                    )
                                                })}
                                        </Flex>

                                        <Flex>
                                            <Text fontWeight="500" pr="7px">
                                                OS Version:
                                            </Text>
                                            <Text fontWeight="300">
                                                {osVersion}
                                            </Text>
                                        </Flex>

                                        <Flex pb="20px">
                                            <Text fontWeight="500" pr="7px">
                                                Browser Version:
                                            </Text>
                                            <Text fontWeight="300">
                                                {browserVersion}
                                            </Text>
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection={{ base: 'column', sm: 'row' }}
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    fontWeight="400"
                                    px="30px"
                                    mb={{ base: '15px', sm: '0px' }}
                                    maxWidth="220px"
                                    onClick={viewRequirementsHandler}
                                    background="gray.500"
                                    color="light.10"
                                    borderRadius="10px"
                                    _hover={{
                                        background: 'gray.600',
                                    }}
                                    _active={{
                                        background: 'gray.600',
                                    }}
                                    _focus={{
                                        background: 'gray.600',
                                    }}
                                    {...noFocus}
                                >
                                    View Device Requirements
                                </Button>
                                <Button
                                    background={
                                        deviceWarning ? '#FF4F44' : 'light.200'
                                    }
                                    color="light.10"
                                    borderRadius="10px"
                                    size="sm"
                                    ml={{ base: '0', sm: '20px' }}
                                    fontWeight="400"
                                    _focus={{ border: 'none' }}
                                    onClick={acceptandContinueHandler}
                                    w="100%"
                                    maxWidth="220px"
                                    _hover={{
                                        background: deviceWarning
                                            ? '#E34339'
                                            : 'light.201',
                                    }}
                                    _active={{
                                        background: deviceWarning
                                            ? '#E34339'
                                            : 'light.201',
                                    }}
                                    {...noFocus}
                                >
                                    {deviceWarning
                                        ? 'Accept and Continue'
                                        : 'Close'}
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
            {deviceWarning && (
                <DeviceRequirementsModal
                    deviceWarning={deviceWarning}
                    setDeviceWarning={setDeviceWarning}
                />
            )}
        </>
    )
}
