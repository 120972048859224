import React, { useEffect, useRef } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import DrawControl from 'react-mapbox-gl-draw'
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'
import { RulerMode, RulerStyle } from './modes/ruler'
import { BriefingAreaMode, BriefingAreaStyle } from './modes/briefingArea'
import { BriefingRouteMode, BriefingRouteStyle } from './modes/briefingRoute'
import truncate from '@turf/truncate'
import {
    clickingState,
    briefingAreaSourceState,
    briefingCustomAreaState,
    briefingDrawActionState,
    briefingCustomAreaPointCountState,
    briefingCreatedState,
} from '../../../globalState'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

export default function DrawTools({ rulerMode, setRulerMode }) {
    const drawControl = useRef()
    const setClicking = useSetRecoilState(clickingState)
    const briefingAreaSource = useRecoilValue(briefingAreaSourceState)

    const [briefingDrawAction, setBriefingDrawAction] = useRecoilState(
        briefingDrawActionState
    )

    const setBriefingCustomArea = useSetRecoilState(briefingCustomAreaState)
    const setBriefingCustomAreaPointCount = useSetRecoilState(
        briefingCustomAreaPointCountState
    )
    const setBriefingCreated = useSetRecoilState(briefingCreatedState)

    useEffect(() => {
        if (briefingDrawAction === 'cancel') {
            drawControl.current.draw.deleteAll()
            drawControl.current.draw.changeMode('static')
            setBriefingDrawAction(null)
        }
    }, [briefingDrawAction, setBriefingDrawAction])

    useEffect(() => {
        if (rulerMode) {
            setClicking(false)
            drawControl.current.draw.changeMode('ruler')
        }
        if (!rulerMode) {
            setClicking(true)
            if (
                document.getElementsByClassName('mapboxgl-ctrl-ruler-distance')
                    .length > 0
            ) {
                document.getElementsByClassName(
                    'mapboxgl-ctrl-ruler-distance'
                )[0].innerHTML = '0 NM'
            }
            if (
                document.getElementsByClassName('mapboxgl-ctrl-ruler').length >
                0
            ) {
                document
                    .getElementsByClassName('mapboxgl-ctrl-ruler')[0]
                    .classList.remove('active')
            }
            drawControl.current.draw.deleteAll()
            drawControl.current.draw.changeMode('static')
        }
    }, [rulerMode, setClicking])

    useEffect(() => {
        if (
            briefingAreaSource === 'customArea' &&
            briefingDrawAction === 'draw'
        ) {
            setClicking(false)
            drawControl.current.draw.changeMode('briefing_area', {
                setPointCount: setBriefingCustomAreaPointCount,
            })
        }
        if (
            briefingAreaSource === 'customRoute' &&
            briefingDrawAction === 'draw'
        ) {
            setClicking(false)
            drawControl.current.draw.changeMode('briefing_route')
        } else if (
            !['customArea', 'customRoute'].includes(briefingAreaSource) ||
            briefingDrawAction === null
        ) {
            setBriefingCustomAreaPointCount(0)
            setClicking(true)
        }
    }, [
        briefingAreaSource,
        setClicking,
        briefingDrawAction,
        setBriefingCustomAreaPointCount,
    ])

    const onDrawCreate = ({ features }) => {
        if (
            ['customArea', 'customRoute'].includes(features[0].properties.type)
        ) {
            setBriefingCustomArea(truncate(features[0], { precision: 3 }))
            setBriefingCreated(true)
        }
    }

    const onDrawModeChange = ({ mode }) => {
        if (mode !== 'ruler') {
            setRulerMode(false)
        }
        drawControl.current.draw.deleteAll()
    }

    return (
        <DrawControl
            ref={drawControl}
            onDrawCreate={onDrawCreate}
            // onDrawUpdate={onDrawUpdate}
            onDrawModeChange={onDrawModeChange}
            displayControlsDefault={false}
            defaultMode={'static'}
            modes={{
                static: StaticMode,
                ruler: RulerMode,
                briefing_area: BriefingAreaMode,
                briefing_route: BriefingRouteMode,
            }}
            styles={[
                ...RulerStyle,
                ...BriefingAreaStyle,
                ...BriefingRouteStyle,
            ]}
            userProperties={true}
        />
    )
}
