import wxDefs from '../definitions/wx.json'
import changeIndicatorDefs from '../definitions/changeindicator.json'

export function intensityDecode(value) {
    const precipitation = precipitationDecode(value)
    if (precipitation) {
        const regex = /-|\+|(VC)/g
        const test = value.match(regex)
        const match = test && test[0]
        if (match) {
            switch (match) {
                case '-':
                    return 'Light'
                case '+':
                    return 'Heavy'
                case 'VC':
                    return 'In the vicinity'
                default:
                    break
            }
        }
        return 'Moderate'
    }
    return null
}

export function descriptorDecode(value) {
    const regex = /SH|TS|MI|BC|PR|DR|BL|FZ/g
    const test = value.match(regex)
    const match = test && test[0]
    if (match) {
        switch (match) {
            case 'SH':
                return 'Showers'
            case 'TS':
                return 'Thunderstorm'
            case 'MI':
                return 'Shallow'
            case 'BC':
                return 'Patches'
            case 'PR':
                return 'Partial'
            case 'DR':
                return 'Low Drifting'
            case 'BL':
                return 'Blowing'
            case 'FZ':
                return 'Freezing'
            default:
                break
        }
    }
    return null
}

export function precipitationDecode(value) {
    const regex = /DZ|RA|GS|GR|SN|SG|PL|IC/g
    const test = value.match(regex)
    const match = test && test[0]
    if (match) {
        switch (match) {
            case 'DZ':
                return 'Drizzle'
            case 'RA':
                return 'Rain'
            case 'GS':
                return 'Small hail and/or snow pellets'
            case 'GR':
                return 'Hail'
            case 'SN':
                return 'Snow'
            case 'SG':
                return 'Snow Grains'
            case 'PL':
                return 'Ice Pellets'
            case 'IC':
                return 'Ice Crystals'
            default:
                break
        }
    }
    return null
}

export function obscurationDecode(value) {
    const regex = /BR|FG|HZ|FU|VA|DU|SA/g
    const test = value.match(regex)
    const match = test && test[0]
    if (match) {
        switch (match) {
            case 'BR':
                return 'Mist'
            case 'FG':
                return 'Fog'
            case 'HZ':
                return 'Haze'
            case 'FU':
                return 'Smoke'
            case 'VA':
                return 'Volcanic Ash'
            case 'DU':
                return 'Widespread Dust'
            case 'SA':
                return 'Sand'
            default:
                break
        }
    }
    return null
}

export function otherPhenomenaDecode(value) {
    const regex = /SQ|FC|PO|SS|DS/g
    const test = value.match(regex)
    const match = test && test[0]
    if (match) {
        switch (match) {
            case 'SQ':
                return 'Squall'
            case 'FC':
                return 'Funnel Clouds (Tornadoes or Water Spouts)'
            case 'PO':
                return 'Dust/Sand Whirls (Dust Devils)'
            case 'SS':
                return 'Sandstorm'
            case 'DS':
                return 'Dust storm'
            default:
                break
        }
    }
    return null
}

export function weatherDecode(values) {
    if (!values) {
        return 'No present weather detected'
    }
    if (values && values[0] === '//') {
        return 'Weather unable to be detected, sensor INOP'
    }
    if (values.length > 0) {
        const weather = values.map((value) => {
            const intensity = intensityDecode(value)
            const descriptor = descriptorDecode(value)
            const precipitation = precipitationDecode(value)
            const obscuration = obscurationDecode(value)
            const otherPhenomena = otherPhenomenaDecode(value)

            const arrayOfValues = [
                intensity,
                descriptor,
                precipitation,
                obscuration,
                otherPhenomena,
            ]
                .flatMap((v) => v)
                .filter((v) => v)

            return arrayOfValues.join(' ')
        })

        return weather.join(', ')
    }
}

export function cloudCoverDecode(value) {
    const regex = /(\/\/\/\/\/\/)|SKY CLEAR|NSC|NCD|VV|FEW|SCT|BKN|OVC|NCD/g
    const test = value.match(regex)
    const match = test && test[0]
    if (match) {
        switch (match) {
            case '//////':
                return 'Cloud not reported due faulty sensor'
            case 'SKY CLEAR':
                return 'Sky Clear'
            case 'NSC':
                return 'No Significant Cloud'
            case 'NCD':
                return 'No Cloud Detected'
            case 'VV':
                return 'Vertical Visibility'
            case 'FEW':
                return 'Few'
            case 'SCT':
                return 'Scattered'
            case 'BKN':
                return 'Broken'
            case 'OVC':
                return 'Overcast'
            default:
                break
        }
    }
    return null
}

export function cloudCoverOktasDecode(value) {
    const regex =
        /(\/\/\/\/\/\/)|SKY CLEAR|NSC|NCD|VV|FEW|SCT|BKN|OVC|Few|Scattered|Broken|Overcast|NCD/g
    const test = value.match(regex)
    const match = test && test[0]
    if (match) {
        switch (match) {
            case 'FEW':
                return '1-2'
            case 'Few':
                return '1-2'
            case 'SCT':
                return '3-4'
            case 'Scattered':
                return '3-4'
            case 'BKN':
                return '5-7'
            case 'Broken':
                return '5-7'
            case 'OVC':
                return '8'
            case 'Overcast':
                return '8'
            default:
                break
        }
    }
    return null
}

export function cloudTypeDecode(value) {
    const regex = /CB|TCU/g
    const test = value.match(regex)
    const match = test && test[0]
    if (match) {
        switch (match) {
            case 'CB':
                return 'Cumulonimbus'
            case 'TCU':
                return 'Towering Cumulus'
            default:
                break
        }
    }
    return null
}

export function cloudAltitudeDecode(value) {
    const regex = /\d{3}/g
    const test = value.match(regex)
    const match = test && Number(test[0])
    if (match) {
        const altitude = match * 100
        return `${altitude} ft AGL`
    }
    return null
}

export function cloudDecode(values) {
    if (values) {
        const cloudLayer = values.map((value) => ({
            type: cloudCoverDecode(value),
            altitude: cloudAltitudeDecode(value),
        }))

        return cloudLayer
            .map(
                (value) =>
                    `${value.type}${value.altitude ? ' ' + value.altitude : ''}`
            )
            .join('\n')
    }
}

export function wxTranslate(value) {
    const translatedDefs = Object.assign(
        {},
        ...Object.keys(wxDefs).map((def) => ({
            [wxDefs[def].wmoName]: wxDefs[def].translation
                ? wxDefs[def].translation
                : wxDefs[def].wmoName,
        }))
    )
    if (translatedDefs[value]) return translatedDefs[value]
    else {
        console.log(`Value "${value}" not defined in wx definitions`)
        return value
    }
}

export function presentWeatherTranslate(value) {
    // Check if the translation exists in wxDefs
    if (wxDefs[value]) {
        return wxDefs[value].translation
    }
    // Return the default value if the translation doesn't exist
    else {
        console.log(`Value "${value}" not defined in wx definitions`)
        return value
    }
}

export function changeIndicatorTranslate(value) {
    const translatedDefs = Object.assign(
        {},
        ...Object.keys(changeIndicatorDefs).map((def) => ({
            [changeIndicatorDefs[def].wmoName]: changeIndicatorDefs[def]
                .translation
                ? changeIndicatorDefs[def].translation
                : changeIndicatorDefs[def].wmoName,
        }))
    )
    if (translatedDefs[value]) return translatedDefs[value]
    else {
        console.log(
            `Value "${value}" not defined in change indicator definitions`
        )
        return value
    }
}
