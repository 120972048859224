import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import CloudIcon from '../../../../../icons/menu-icons/CloudIcon'
import { ReactComponent as CloudsFewIcon } from '../../../../../icons/assets/clouds-few.svg'
import { ReactComponent as CloudsScatteredIcon } from '../../../../../icons/assets/clouds-scattered.svg'
import { ReactComponent as CloudsBrokenIcon } from '../../../../../icons/assets/clouds-broken.svg'
import { ReactComponent as CloudsOvercastIcon } from '../../../../../icons/assets/clouds-overcast.svg'

export default function AwsCloud(props) {
    const { cloudLayers, infotabGraphicsDisabled } = props

    return (
        <Flex
            width="100%"
            background="light.10"
            boxShadow={
                !infotabGraphicsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            pt={infotabGraphicsDisabled ? '0px' : '10px'}
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '15px' : '10px'}
            direction={{
                base: 'row',
                md: 'row',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                }}
                width="100%"
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color="#9DB2CD"
                >
                    <CloudIcon boxSize="36px" />
                </Flex>
                <Flex
                    direction="column"
                    pb="3px"
                    gap={{ base: '2px', md: '0px' }}
                    width="100%"
                    pl={infotabGraphicsDisabled ? '15px' : '10px'}
                >
                    {cloudLayers &&
                        cloudLayers.length > 0 &&
                        cloudLayers.reverse().map((layer, i) => {
                            return (
                                <Flex
                                    direction="row"
                                    key={`InfoContentCloud${i}`}
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Flex direction="column" width="100%">
                                        {layer.amount && (
                                            <Text
                                                fontSize={{
                                                    base: '1.4rem',
                                                    md: '1.2rem',
                                                }}
                                                lineHeight="1.2"
                                                pt={{
                                                    base: '4px',
                                                    md: '6px',
                                                }}
                                                fontWeight="600"
                                                whiteSpace="pre-line"
                                            >
                                                {layer.amount}
                                            </Text>
                                        )}
                                        {layer.value && (
                                            <Text
                                                fontSize={{
                                                    base: '0.9rem',
                                                    md: '0.85rem',
                                                }}
                                                fontWeight="600"
                                                whiteSpace="pre-line"
                                            >
                                                {layer.value} ft AGL
                                            </Text>
                                        )}
                                    </Flex>

                                    {!infotabGraphicsDisabled && (
                                        <Flex
                                            justifyContent={{
                                                base: 'flex-end',
                                                md: 'flex-end',
                                            }}
                                            alignItems={{
                                                base: 'center',
                                                md: 'center',
                                            }}
                                            mt="4px"
                                            color="#83909F"
                                            opacity="0.35"
                                        >
                                            {layer.amount === 'Overcast' ? (
                                                <CloudsOvercastIcon boxSize="36px" />
                                            ) : layer.amount === 'Broken' ? (
                                                <CloudsBrokenIcon boxSize="36px" />
                                            ) : layer.amount === 'Scattered' ? (
                                                <CloudsScatteredIcon boxSize="36px" />
                                            ) : layer.amount === 'Few' ? (
                                                <CloudsFewIcon boxSize="36px" />
                                            ) : (
                                                ''
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                            )
                        })}
                </Flex>
            </Flex>
        </Flex>
    )
}
