import { useRecoilValue } from 'recoil'
import { briefingFontSizeState } from '../globalState'

const useBriefingFontSize = () => {
    return useRecoilValue(briefingFontSizeState)
}

// FONT SIZE
const calculateFontSize = (value, fontSize) => {
    return fontSize >= 0
        ? `${Math.round((value - 0.1 + fontSize) * 100) / 100}rem`
        : `${value}rem`
}

const calculateDateFontSize = (value, fontSize, fullscreen) => {
    return fullscreen
        ? fontSize >= 0
            ? `${Math.round((fontSize / 2 + 0.65) * 100) / 100}rem`
            : `${value - 0.1}rem`
        : fontSize >= 0
        ? `${Math.round((fontSize / 2 + 0.75) * 100) / 100}rem`
        : `${value}rem`
}

// CONTAINER WIDTH
const calculateContainerSize = (value, fontSize) => {
    const calcContainerWidth = fontSize ? fontSize * 150 : 0
    return fontSize >= 0
        ? `${Math.round((value - 7 + calcContainerWidth) * 100) / 100}px`
        : `${value}px`
}

// LABEL WIDTH
const calculateLabelSize = (value, fontSize) => {
    const calcLabelWidth = fontSize ? fontSize * 70 : 0
    return fontSize >= 0
        ? `${Math.round((value - 5 + calcLabelWidth) * 100) / 100}px`
        : `${value}px`
}

// SECOND LABEL WIDTH
const calculateSecondLabelSize = (value, fontSize, fullscreen) => {
    const calcLabelWidth = fontSize ? fontSize * 70 : 0
    return fullscreen
        ? fontSize >= 0
            ? `${Math.round((50 + calcLabelWidth) * 10) / 10}px`
            : `${value - 10}px`
        : fontSize >= 0
        ? `${Math.round((60 + calcLabelWidth) * 10) / 10}px`
        : `${value}px`
}

// BADGE SIZE
const calculateBadgeSize = (value, fontSize) => {
    return fontSize >= 0
        ? `${Math.round((fontSize * 25 + 13.5) * 10) / 10}px`
        : `${value}px`
}

const calculateBadgeWidth = (value, fontSize) => {
    return fontSize >= 0
        ? `${Math.round((33 + fontSize * 40) * 10) / 10}px`
        : `${value}px`
}

const useAdjustedFontSize = () => {
    const fontSize = useBriefingFontSize()
    return {
        calculateFontSize: (value) => calculateFontSize(value, fontSize),
        calculateDateFontSize: (value, fullscreen) =>
            calculateDateFontSize(value, fontSize, fullscreen),
        calculateContainerSize: (value) =>
            calculateContainerSize(value, fontSize),
        calculateLabelSize: (value) => calculateLabelSize(value, fontSize),
        calculateSecondLabelSize: (value, fullscreen) =>
            calculateSecondLabelSize(value, fontSize, fullscreen),
        calculateBadgeSize: (value) => calculateBadgeSize(value, fontSize),
        calculateBadgeWidth: (value) => calculateBadgeWidth(value, fontSize),
    }
}

export default useAdjustedFontSize
