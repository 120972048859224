import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function RewindIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 5} color={color || colorHandler()}>
            <path
                d="M11.533 11.7924C11.0487 12.1447 11.0487 12.8553 11.533 13.2076L18.5652 18.3248C19.1584 18.7564 20 18.3413 20 17.6171V7.38286C20 6.65866 19.1584 6.24359 18.5652 6.67524L11.533 11.7924Z"
                fill="currentColor"
            />
            <path
                d="M2.36316 11.7924C1.87895 12.1447 1.87895 12.8553 2.36316 13.2076L9.39537 18.3248C9.98857 18.7564 10.8302 18.3413 10.8302 17.6171V7.38286C10.8302 6.65866 9.98857 6.24359 9.39537 6.67524L2.36316 11.7924Z"
                fill="currentColor"
            />
        </Icon>
    )
}
