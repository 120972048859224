import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloudVisibleIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_702:31183)">
                {/* <g opacity="0.2">
                    <path
                        d="M3 12.9231C3.00004 12.1655 3.48866 11.3699 3.72898 11.1297C3.84914 11.0096 3.97131 10.8775 4.09347 10.7454C4.21564 10.6133 4.3378 10.4812 4.45796 10.3611C4.94395 11.8983 5.14346 12.4892 6.40192 13.1793C6.68039 13.332 6.94558 13.4525 7.24174 13.5495C6.18745 14.0391 5.3131 14.8278 4.7392 15.8003C3.80479 15.4982 3 14.7506 3 12.9231Z"
                        fill="currentColor"
                    />
                    <path
                        d="M14.8717 15.9974L18.3086 15.9975C19.0496 15.9975 19.6915 15.3121 20.0826 14.8946C20.1461 14.8268 20.203 14.7661 20.2526 14.7165C20.4315 14.5376 20.8249 14.076 20.9816 13.1793C21.0696 12.6754 20.829 11.3939 20.5436 10.7134C20.2258 9.95555 19.6991 9.51058 19.0376 9.08014C18.3301 8.7176 16.6077 8.34355 15.8787 8.56774C17.3367 10.7134 18.25 14.25 13.4487 14.076L13.378 14.0734C13.3016 14.0706 13.2261 14.0679 13.1516 14.0652C13.8706 14.5706 14.4621 15.2321 14.8717 15.9974Z"
                        fill="currentColor"
                    />
                </g> */}
                <path
                    d="M17.5 17H15.2836C15.1245 16.4619 14.8819 15.9574 14.5701 15.5H17.5C19.1569 15.5 20.5 14.1569 20.5 12.5C20.5 10.8879 19.123 9.57279 17.625 9.50292C17.5954 9.50154 17.5658 9.50065 17.5362 9.50025C17.5241 9.50008 17.5121 9.5 17.5 9.5C17.4752 9.5 17.4505 9.5003 17.4259 9.5009C16.5672 9.52176 15.798 9.90341 15.264 10.5L14.1563 9.48858C14.6065 8.98893 15.1672 8.5907 15.7998 8.33233C14.9336 6.93612 13.3735 6 11.75 6C9.21051 6 7.13647 7.99286 7.00647 10.5H6C4.61929 10.5 3.5 11.6193 3.5 13C3.5 14.0482 4.14513 14.9458 5.06 15.3173C4.76006 15.7209 4.51565 16.1649 4.33743 16.6392C2.95821 16.0081 2 14.6159 2 13C2 10.8762 3.65521 9.13895 5.746 9.00794C6.50027 6.40385 8.9028 4.5 11.75 4.5C14.0505 4.5 16.2543 5.92855 17.3251 8.00334C17.3795 8.00126 17.4342 8.00014 17.489 8.00001L17.5 8C19.8182 8 22.0002 10.0147 22.0002 12.5C22.0002 13.6802 21.5458 14.7542 20.8026 15.5568C19.9806 16.4445 18.8051 17 17.5 17Z"
                    fill="currentColor"
                />
                <path
                    d="M10.4375 16.2708C10.679 16.2708 10.8914 16.1463 11.014 15.958C11.0855 16.1243 11.125 16.3075 11.125 16.5C11.125 17.2594 10.5094 17.875 9.75 17.875C8.99061 17.875 8.375 17.2594 8.375 16.5C8.375 15.7406 8.99061 15.125 9.75 15.125C9.80629 15.125 9.8618 15.1284 9.91632 15.135C9.81266 15.2553 9.75 15.412 9.75 15.5833C9.75 15.963 10.0578 16.2708 10.4375 16.2708Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.75 13.75C7.59091 13.75 5.74705 15.0977 5 17C5.74705 18.9023 7.59091 20.25 9.75 20.25C11.9091 20.25 13.753 18.9023 14.5 17C13.753 15.0977 11.9091 13.75 9.75 13.75ZM9.75 18.5C10.8546 18.5 11.75 17.6046 11.75 16.5C11.75 15.3954 10.8546 14.5 9.75 14.5C8.64543 14.5 7.75 15.3954 7.75 16.5C7.75 17.6046 8.64543 18.5 9.75 18.5ZM12.2074 15.2642C12.8387 15.6994 13.3507 16.2979 13.6833 17C12.982 18.4805 11.4829 19.5 9.75 19.5C8.01706 19.5 6.51799 18.4805 5.8167 17C6.14929 16.2979 6.66132 15.6994 7.29263 15.2642C7.10542 15.6358 7 16.0556 7 16.5C7 18.0188 8.23122 19.25 9.75 19.25C11.2688 19.25 12.5 18.0188 12.5 16.5C12.5 16.0556 12.3946 15.6358 12.2074 15.2642Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
