import React from 'react'
import { Flex, Text, Icon } from '@chakra-ui/react'
import VisibilityIcon from '../../../../../icons/menu-icons/VisibilityIcon'
import { VisibilityScale } from '../../graphics/WeatherGraphics'

export default function AerodromesVisibility(props) {
    const {
        metarActive,
        multipleCloudLayers,
        timePeriod,
        aerodromeBackgroundsDisabled,
        cloudCoverBoxColor,
        metarCloud,
        cloudCoverColor,
        horizontalVisibility,
        infotabGraphicsDisabled,
    } = props

    const visibilityValue =
        horizontalVisibility && Number(parseInt(horizontalVisibility.value, 10))

    return (
        <Flex
            width={metarActive ? '100%' : 'auto'}
            background={
                !infotabGraphicsDisabled &&
                (metarActive && !aerodromeBackgroundsDisabled
                    ? cloudCoverBoxColor(
                          timePeriod === 'Night'
                              ? 'Night'
                              : timePeriod === 'Sunrise'
                              ? 'Sunrise'
                              : timePeriod === 'Sunset'
                              ? 'Sunset'
                              : metarCloud
                              ? metarCloud
                              : 'white'
                      )
                    : 'white')
            }
            border={metarActive ? 'none' : '1px solid rgb(229 235 242)'}
            boxShadow={
                !infotabGraphicsDisabled && aerodromeBackgroundsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            backdropFilter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'blur(25px) contrast(102%) saturate(102%)'
            }
            borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            pt={infotabGraphicsDisabled ? '7px' : '10px'}
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '7px' : { base: '13px', md: '10px' }}
            direction={{
                base: 'column',
                md: multipleCloudLayers ? 'row' : 'column',
            }}
            gap={{
                base: '0px',
                md: multipleCloudLayers ? '20px' : '0px',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                }}
                flexGrow="1"
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color={
                        aerodromeBackgroundsDisabled
                            ? '#9db2cd'
                            : 'rgba(255,255,255,0.6)'
                    }
                >
                    <VisibilityIcon boxSize="36px" />
                </Flex>
                <Flex direction="column">
                    <Text
                        whiteSpace="pre"
                        fontSize={{
                            base: '1.4rem',
                            md: '1.2rem',
                        }}
                        lineHeight="1.2"
                        pt={{
                            base: '5px',
                            md: '6px',
                        }}
                        pl={infotabGraphicsDisabled ? '10px' : '3px'}
                        fontWeight="600"
                    >
                        {horizontalVisibility.value === '////' ? (
                            <Text textTransform="initial">Not reported</Text>
                        ) : horizontalVisibility.value === '9999' ? (
                            '> ' +
                            visibilityValue +
                            ' ' +
                            horizontalVisibility.unit
                        ) : (
                            visibilityValue + ' ' + horizontalVisibility.unit
                        )}
                    </Text>
                </Flex>
            </Flex>
            {!infotabGraphicsDisabled && visibilityValue >= 1 && (
                <Flex
                    pt={multipleCloudLayers ? '7px' : '5px'}
                    pb={multipleCloudLayers ? '2px' : '5px'}
                    pl={{
                        base: '5px',
                        md: multipleCloudLayers ? '3px' : '5px',
                    }}
                    pr={{
                        base: '3px',
                        md: '3px',
                    }}
                    width="100%"
                    justifyContent={{
                        base: 'center',
                        md: 'flex-end',
                    }}
                    alignItems={{
                        base: 'center',
                        md: 'center',
                    }}
                    flexDirection="column"
                >
                    <Flex
                        height="16px"
                        mt={multipleCloudLayers ? '-4px' : '-2px'}
                        width="100%"
                        textAlign="right"
                        color={
                            metarActive && !aerodromeBackgroundsDisabled
                                ? cloudCoverColor
                                : 'gray.500'
                        }
                        fontSize={{
                            base: '0.65rem',
                            md: '0.55rem',
                        }}
                        opacity="0.8"
                        letterSpacing="0.5px"
                        fontWeight={400}
                        justifyContent="space-between"
                    >
                        <Text
                            ml={{
                                base: '0px',
                                md: multipleCloudLayers ? '-1px' : '0px',
                            }}
                        >
                            {'0 m'}
                        </Text>
                        <Text
                            mr={{
                                base: '0px',
                                md: multipleCloudLayers ? '-2px' : '0px',
                            }}
                        >
                            {'> 9999 m'}
                        </Text>
                    </Flex>
                    <Icon
                        as={VisibilityScale}
                        focusImageColor={'#00f1ab'}
                        imageColor={
                            aerodromeBackgroundsDisabled ? '#83909F' : 'white'
                        }
                        data={visibilityValue}
                        unit={horizontalVisibility.unit}
                        multipleCloudLayers={multipleCloudLayers}
                    />
                </Flex>
            )}
        </Flex>
    )
}
