import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import MfaHandler from '../map/functions/MfaHandler'
import ContrastHandler from '../map/functions/ContrastHandler'
import RouteHandler from '../map/actions/RouteHandler'
import SettingsMenu from '../map/settings-menu'
import Modals from '../map/modals'
import Sidebar from '../map/controls/sidebar'
import RotationWarning from '../device-warning/RotationWarning'
import { Flex } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import {
    dataModeState,
    mapDisabledState,
    layerSelectionState,
} from '../globalState'
import AerodromesList from './lists/AerodromesList'
import AAWList from './lists/AAWList'
import AWSList from './lists/AWSList'
import WebcamsList from './lists/WebcamsList'
import Advisories from './lists/Advisories'
// import Clock from '../map/controls/Clock'

export default function MapDisabled() {
    const mapDisabled = useRecoilValue(mapDisabledState)
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)

    const [accessToken, setAccessToken] = useState(null)
    const { getAccessTokenSilently } = useAuth0()
    useEffect(() => {
        const setToken = async () => {
            setAccessToken(await getAccessTokenSilently())
        }
        setToken()
    }, [getAccessTokenSilently])

    if (mapDisabled && accessToken) {
        return (
            <Flex
                width="100%"
                height="100%"
                background="radial-gradient(#ffffff 40%, #dbe0e6 100%) rgb(238, 240, 244) !important"
            >
                <Sidebar />
                <ContrastHandler />
                <RouteHandler />
                <MfaHandler />
                <SettingsMenu />
                <Modals />
                <RotationWarning />
                {/* <Clock /> */}
                {dataMode === 'aero' && <AerodromesList />}
                {dataMode === 'met' && layerSelection?.met?.aaw && <AAWList />}
                {dataMode === 'met' && layerSelection?.met?.aws && <AWSList />}
                {dataMode === 'met' && layerSelection?.met?.webcams && (
                    <WebcamsList />
                )}
                {dataMode === 'advs' && <Advisories />}
            </Flex>
        )
    }

    return null
}
