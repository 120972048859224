import React from 'react'
import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../../../util/notamDecode'
import { Text, View } from '@react-pdf/renderer'

import formatDateTime from '../../../../../util/dateFormatter'
import {
    parseISO,
    formatDistanceToNow,
    formatDistanceToNowStrict,
    intervalToDuration,
} from 'date-fns'
import { AerodromeIcon } from './assets/SvgImages'
import { NotamIcon } from './assets/SvgImages'
import { NotamAerodromeIcon } from './assets/SvgImages'
import { NotamCommsIcon } from './assets/SvgImages'
import { NotamDroneIcon } from './assets/SvgImages'
import { NotamObstacleIcon } from './assets/SvgImages'
import { NotamWarningIcon } from './assets/SvgImages'
import { ErrorIcon } from './assets/SvgImages'

export default function AerodromesExportPDF(props) {
    const {
        briefingData,
        params,
        styles,
        aerodrome,
        timeZone,
        metarCount,
        periodTo,
        showOutdatedErrors,
    } = props

    const briefingIssueTime = briefingData && parseISO(briefingData.time)

    // METAR Out of Date Error Message Times
    const currentMetar =
        aerodrome.metar &&
        aerodrome.metar.filter((filter) => filter !== null) &&
        aerodrome.metar[0]

    const metarTimeDifference =
        currentMetar &&
        intervalToDuration({
            start: parseISO(currentMetar.validat),
            end: briefingIssueTime,
        })

    const metarOutOfDateIssueTime =
        (metarTimeDifference && metarTimeDifference.days >= 1) ||
        (metarTimeDifference &&
            metarTimeDifference.days < 1 &&
            metarTimeDifference.hours >= 1 &&
            (metarTimeDifference.hours === 1
                ? metarTimeDifference.minutes > 15
                : metarTimeDifference.minutes > 0)) // = 01:15
            ? 'WARNING' // red
            : metarTimeDifference &&
              ((metarTimeDifference.hours === 1 &&
                  metarTimeDifference.minutes < 15) ||
                  (metarTimeDifference.hours < 1 &&
                      metarTimeDifference.minutes >= 45)) && // = 00:45
              aerodrome.designator !== 'NZWP' &&
              aerodrome.designator !== 'NZOH' &&
              aerodrome.designator !== 'NZMF'
            ? 'CAUTION' // orange
            : 'NONE'

    // TAF Out of Date Error Message Times
    const tafIssuedTime =
        aerodrome.taf && aerodrome.taf.validfrom
            ? parseISO(aerodrome.taf.validfrom)
            : null
    const tafTimeDifference =
        tafIssuedTime &&
        intervalToDuration({
            start: tafIssuedTime,
            end: briefingIssueTime,
        })
    const tafOutOfDateIssueTime =
        (tafTimeDifference && tafTimeDifference.days >= 1) ||
        (tafTimeDifference &&
            tafTimeDifference.days < 1 &&
            tafTimeDifference.hours >= 21) // = 21 hours or greater (all airports)
            ? 'WARNING'
            : tafTimeDifference &&
              tafTimeDifference.hours >= 18 && // = 18 hours or greater (excludes NZTG)
              aerodrome.designator !== 'NZTG'
            ? 'WARNING'
            : tafTimeDifference && tafTimeDifference.hours >= 12 // = 12 hours or greater
            ? 'CAUTION'
            : // below are for AA, WN and CH only
            tafTimeDifference &&
              tafTimeDifference.hours >= 6 && // = 6 hours or greater (AA, WN and CH only)
              (aerodrome.designator === 'NZAA' ||
                  aerodrome.designator === 'NZWN' ||
                  aerodrome.designator === 'NZCH')
            ? 'WARNING'
            : tafTimeDifference &&
              tafTimeDifference.hours >= 3 &&
              (tafTimeDifference.hours === 3
                  ? tafTimeDifference.minutes > 30
                  : tafTimeDifference.minutes > 0) && // = 3 hours & 30 minutes or greater (AA, WN and CH only)
              (aerodrome.designator === 'NZAA' ||
                  aerodrome.designator === 'NZWN' ||
                  aerodrome.designator === 'NZCH')
            ? 'CAUTION'
            : 'NONE'

    // TEMP badge for Matamata and Wanaka when temporary ATIS service is provided, i.e. Walsh Flying School or Warbirds Over Wanaka
    const tempATIS =
        aerodrome.designator && ['NZMA', 'NZWF'].includes(aerodrome.designator)

    function notamIconFormatter(code23) {
        return ['WU', 'WZ'].includes(code23) ? (
            <NotamDroneIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['OB', 'OL'].includes(code23) ? (
            <NotamObstacleIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : code23 === 'FA' ? (
            <NotamAerodromeIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['W', 'R'].includes(code23.charAt(0)) ? (
            <NotamWarningIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['C', 'N'].includes(code23.charAt(0)) ? (
            <NotamCommsIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : (
            <NotamIcon width="20" height="20" viewBox="0 0 24 24" alt="!" />
        )
    }

    return (
        // AERODROMES SECTION
        (aerodrome.metar ||
            aerodrome.taf ||
            aerodrome.atis ||
            aerodrome.notam) && (
            <View style={styles.section}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        wrap={false}
                        style={[
                            styles.sectionTitle,
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            },
                        ]}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <AerodromeIcon
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="rgb(23, 120, 183)"
                            />

                            <div
                                style={{
                                    paddingLeft: '7',
                                    color: 'rgb(23, 120, 183)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    textAlign: 'left',
                                }}
                            >
                                <Text
                                    style={{
                                        fontWeight: '600',
                                    }}
                                >
                                    {aerodrome.designator}
                                </Text>
                            </div>
                        </div>
                        <div
                            style={{
                                color: 'rgb(23, 120, 183)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                textAlign: 'right',
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: '600',
                                    fontSize: '8pt',
                                }}
                            >
                                {aerodrome.name}
                            </Text>
                            <Text
                                style={{
                                    fontWeight: '300',
                                    fontSize: '6pt',
                                }}
                            >
                                Aerodrome
                            </Text>
                        </div>
                    </div>

                    {(params.aerodromesMetar ||
                        params.aerodromesTaf ||
                        params.aerodromesAtis) &&
                        (aerodrome.metar ||
                            aerodrome.taf ||
                            aerodrome.atis) && (
                            <div /// MET INFORMATION
                                style={[
                                    styles.sectionContent,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: params.aerodromesNotam
                                            ? '1px solid #e5e5e5'
                                            : 'none',
                                    },
                                ]}
                            >
                                <div /// MET SECTION TITLE
                                    style={[
                                        styles.boxCell,
                                        {
                                            borderRight: '1px solid #e5e5e5',
                                            flexGrow: '1',
                                            width: '20%',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '600',
                                            marginTop: '2',
                                        }}
                                    >
                                        MET INFORMATION
                                    </Text>
                                </div>
                                <div /// MET SECTION CONTENT
                                    style={[
                                        styles.boxCell,
                                        {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: '10',
                                            width: '80%',
                                        },
                                    ]}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            marginRight: '2',
                                            marginTop: '2',
                                            marginBottom: '5',
                                            justifyContent: 'flex-end',
                                            paddingBottom: '5',
                                            borderBottom: '1px solid #f5f5f5',
                                        }}
                                    >
                                        {timeZone !== 'UTC' && (
                                            <Text
                                                style={{
                                                    fontWeight: '300',
                                                    fontSize: '6pt',
                                                    textAlign: 'right',
                                                    textTransform: 'unset',
                                                    color: 'rgb(111, 127, 145)',
                                                }}
                                            >
                                                Time references for MET
                                                information are in UTC.
                                            </Text>
                                        )}
                                        <Text
                                            style={{
                                                paddingLeft: 3,
                                                fontWeight: '300',
                                                fontSize: '6pt',
                                                textAlign: 'right',
                                                textTransform: 'unset',
                                                color: 'rgb(111, 127, 145)',
                                            }}
                                        >
                                            All heights are in feet above
                                            aerodrome level.
                                        </Text>
                                    </div>
                                    {aerodrome.metar && ( /// METAR
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                            }}
                                        >
                                            {aerodrome.metar &&
                                                !showOutdatedErrors &&
                                                !aerodrome.metar[0].outofdate &&
                                                (metarOutOfDateIssueTime ===
                                                    'CAUTION' ||
                                                    metarOutOfDateIssueTime ===
                                                        'WARNING') && (
                                                    <div
                                                        wrap={false}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            width: '100%',
                                                            marginBottom: '5',
                                                        }}
                                                    >
                                                        <div
                                                            style={[
                                                                styles.sectionContent,
                                                                {
                                                                    width: '100%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={
                                                                    styles.error
                                                                }
                                                            >
                                                                <span>
                                                                    This METAR
                                                                    was last
                                                                    issued{' '}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '500',
                                                                    }}
                                                                >
                                                                    {formatDistanceToNowStrict(
                                                                        parseISO(
                                                                            currentMetar.validat
                                                                        ),
                                                                        {
                                                                            addSuffix: true,
                                                                        }
                                                                    )}
                                                                </span>
                                                                {metarOutOfDateIssueTime ===
                                                                    'WARNING' && (
                                                                    <>
                                                                        <span>
                                                                            .
                                                                            There
                                                                            is a
                                                                            possibility
                                                                            there
                                                                            has
                                                                            been
                                                                            an
                                                                            issue
                                                                            receiving
                                                                            the
                                                                            latest
                                                                            data
                                                                            and
                                                                            that
                                                                            the
                                                                            latest
                                                                            METAR
                                                                            is
                                                                            not
                                                                            available.
                                                                            <br />{' '}
                                                                            Check
                                                                            back
                                                                            again
                                                                            soon,
                                                                            or
                                                                            alternatively
                                                                            visit{' '}
                                                                        </span>
                                                                        <a
                                                                            style={{
                                                                                fontWeight:
                                                                                    '400',
                                                                                textDecoration:
                                                                                    'underline',
                                                                            }}
                                                                            href="https://ifis.airways.co.nz/"
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            IFIS
                                                                        </a>
                                                                        .
                                                                    </>
                                                                )}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                )}
                                            {(showOutdatedErrors ||
                                                aerodrome.metar.outofdate) && (
                                                <div
                                                    wrap={false}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        marginBottom: '5',
                                                    }}
                                                >
                                                    <div
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '6%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                            },
                                                        ]}
                                                    >
                                                        <ErrorIcon
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="rgb(16, 170, 112)"
                                                        />
                                                    </div>
                                                    <div
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '94%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={styles.error}
                                                        >
                                                            <span>
                                                                There has been
                                                                an issue with
                                                                the connection
                                                                between
                                                                PreFlight and
                                                                MetService. This{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                METAR
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                was last
                                                                validated with
                                                                MetService more
                                                                than{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {formatDistanceToNow(
                                                                    parseISO(
                                                                        aerodrome
                                                                            .metar[0]
                                                                            .updated
                                                                    ),
                                                                    {
                                                                        addSuffix: true,
                                                                    }
                                                                )}
                                                            </span>
                                                            <span>
                                                                . There is a
                                                                high likelihood
                                                                that the latest
                                                                METAR
                                                                information is
                                                                not available in
                                                                PreFlight. Check
                                                                back again soon,
                                                                or alternatively
                                                                visit{' '}
                                                            </span>
                                                            <a
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                href="https://ifis.airways.co.nz/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                IFIS
                                                            </a>
                                                            .
                                                        </Text>
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    marginRight: '2',
                                                    marginBottom: '4',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        paddingLeft: 3,
                                                        fontWeight: '300',
                                                        fontSize: '6pt',
                                                        textAlign: 'right',
                                                        textTransform: 'unset',
                                                        color: 'rgb(111, 127, 145)',
                                                    }}
                                                >
                                                    Wind: °True
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginTop: '2',
                                                    marginBottom: '5',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelNormal,
                                                        {
                                                            width: '45',
                                                        },
                                                    ]}
                                                >
                                                    METAR:
                                                </Text>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexGrow: '10',
                                                    }}
                                                >
                                                    {aerodrome.metar &&
                                                        aerodrome.metar
                                                            .slice(
                                                                0,
                                                                metarCount
                                                            )
                                                            .map((metar) => {
                                                                return (
                                                                    <Text
                                                                        key={`AerodromesMetar${metar.id}`}
                                                                        style={[
                                                                            styles.textNormal,
                                                                            {
                                                                                whiteSpace:
                                                                                    'pre-line',
                                                                                marginBottom:
                                                                                    '5',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        {
                                                                            metar.data
                                                                        }
                                                                    </Text>
                                                                )
                                                            })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {aerodrome.taf && ( /// TAF
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                paddingTop: '5',
                                                marginTop: '5',
                                                marginBottom: '3',
                                                borderTop: aerodrome.metar // only shows a divider above TAF if METAR is present
                                                    ? '1px solid #f5f5f5'
                                                    : 'none',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {aerodrome.taf &&
                                                !showOutdatedErrors &&
                                                !aerodrome.taf.outofdate &&
                                                (tafOutOfDateIssueTime ===
                                                    'CAUTION' ||
                                                    tafOutOfDateIssueTime ===
                                                        'WARNING') && (
                                                    <div
                                                        wrap={false}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            width: '100%',
                                                            marginBottom: '5',
                                                        }}
                                                    >
                                                        <div
                                                            style={[
                                                                styles.sectionContent,
                                                                {
                                                                    width: '100%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={
                                                                    styles.error
                                                                }
                                                            >
                                                                <span>
                                                                    This TAF was
                                                                    last issued{' '}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '500',
                                                                    }}
                                                                >
                                                                    {formatDistanceToNowStrict(
                                                                        parseISO(
                                                                            aerodrome
                                                                                .taf
                                                                                .validfrom
                                                                        ),
                                                                        {
                                                                            addSuffix: true,
                                                                        }
                                                                    )}
                                                                </span>
                                                                {tafOutOfDateIssueTime ===
                                                                    'WARNING' && (
                                                                    <>
                                                                        <span>
                                                                            .
                                                                            There
                                                                            is a
                                                                            possibility
                                                                            there
                                                                            has
                                                                            been
                                                                            an
                                                                            issue
                                                                            receiving
                                                                            the
                                                                            latest
                                                                            data
                                                                            and
                                                                            that
                                                                            the
                                                                            latest
                                                                            TAF
                                                                            is
                                                                            not
                                                                            available.
                                                                            <br />
                                                                            Check
                                                                            back
                                                                            again
                                                                            soon,
                                                                            or
                                                                            alternatively
                                                                            visit{' '}
                                                                        </span>
                                                                        <a
                                                                            style={{
                                                                                fontWeight:
                                                                                    '400',
                                                                                textDecoration:
                                                                                    'underline',
                                                                            }}
                                                                            href="https://ifis.airways.co.nz/"
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            IFIS
                                                                        </a>
                                                                        .
                                                                    </>
                                                                )}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                )}
                                            {(showOutdatedErrors ||
                                                aerodrome.taf.outofdate) && (
                                                <div
                                                    wrap={false}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        marginBottom: '5',
                                                    }}
                                                >
                                                    <div
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '6%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                            },
                                                        ]}
                                                    >
                                                        <ErrorIcon
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="rgb(16, 170, 112)"
                                                        />
                                                    </div>
                                                    <div
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '94%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={styles.error}
                                                        >
                                                            <span>
                                                                There has been
                                                                an issue with
                                                                the connection
                                                                between
                                                                PreFlight and
                                                                MetService. This{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                TAF
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                was last
                                                                validated with
                                                                MetService more
                                                                than{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {formatDistanceToNow(
                                                                    parseISO(
                                                                        aerodrome
                                                                            .taf
                                                                            .updated
                                                                    ),
                                                                    {
                                                                        addSuffix: true,
                                                                    }
                                                                )}
                                                            </span>
                                                            <span>
                                                                . There is a
                                                                high likelihood
                                                                that the latest
                                                                TAF information
                                                                is not available
                                                                in PreFlight.
                                                                Check back again
                                                                soon, or
                                                                alternatively
                                                                visit{' '}
                                                            </span>
                                                            <a
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                href="https://ifis.airways.co.nz/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                IFIS
                                                            </a>
                                                            .
                                                        </Text>
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    marginRight: '2',
                                                    marginBottom: '2',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        paddingLeft: 3,
                                                        fontWeight: '300',
                                                        fontSize: '6pt',
                                                        textAlign: 'right',
                                                        textTransform: 'unset',
                                                        color: 'rgb(111, 127, 145)',
                                                    }}
                                                >
                                                    Wind: °True
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginBottom: '5',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelNormal,
                                                        {
                                                            width: '45',
                                                        },
                                                    ]}
                                                >
                                                    TAF:
                                                </Text>
                                                <Text style={styles.textNormal}>
                                                    {aerodrome.taf &&
                                                        aerodrome.taf.data}
                                                </Text>
                                            </div>
                                        </div>
                                    )}
                                    {aerodrome.atis && ( /// ATIS
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                paddingTop: '5',
                                                marginTop: '5',
                                                marginBottom: '3',
                                                borderTop:
                                                    aerodrome.taf || // only shows a divider above ATIS if METAR and/or TAF is present
                                                    aerodrome.metar
                                                        ? '1px solid #f5f5f5'
                                                        : 'none',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {(showOutdatedErrors ||
                                                aerodrome.atis.outofdate) && (
                                                <div
                                                    wrap={false}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        marginBottom: '5',
                                                    }}
                                                >
                                                    <div
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '6%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                            },
                                                        ]}
                                                    >
                                                        <ErrorIcon
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="rgb(16, 170, 112)"
                                                        />
                                                    </div>
                                                    <div
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '94%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={styles.error}
                                                        >
                                                            <span>
                                                                There has been
                                                                an issue with
                                                                the connection
                                                                between
                                                                PreFlight and
                                                                MetService. This{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                ATIS
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                was last
                                                                validated with
                                                                MetService more
                                                                than{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {formatDistanceToNow(
                                                                    parseISO(
                                                                        aerodrome
                                                                            .atis
                                                                            .updated
                                                                    ),
                                                                    {
                                                                        addSuffix: true,
                                                                    }
                                                                )}
                                                            </span>
                                                            <span>
                                                                . There is a
                                                                high likelihood
                                                                that the latest
                                                                ATIS information
                                                                is not available
                                                                in PreFlight.
                                                                Check back again
                                                                soon, or
                                                                alternatively
                                                                visit{' '}
                                                            </span>
                                                            <a
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                href="https://ifis.airways.co.nz/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                IFIS
                                                            </a>
                                                            .
                                                        </Text>
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    marginRight: '2',
                                                    marginBottom: '2',
                                                    justifyContent: tempATIS
                                                        ? 'space-between'
                                                        : 'flex-end',
                                                }}
                                            >
                                                {tempATIS && (
                                                    <Text
                                                        style={{
                                                            fontWeight: '600',
                                                            fontSize: '7pt',
                                                            textAlign: 'left',
                                                            color: 'rgb(23, 120, 183)',
                                                        }}
                                                    >
                                                        TEMPORARY
                                                    </Text>
                                                )}
                                                <Text
                                                    style={{
                                                        paddingLeft: 3,
                                                        fontWeight: '300',
                                                        fontSize: '6pt',
                                                        textAlign: 'right',
                                                        textTransform: 'unset',
                                                        color: 'rgb(111, 127, 145)',
                                                    }}
                                                >
                                                    Wind: °Magnetic
                                                </Text>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginBottom: '5',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelNormal,
                                                        {
                                                            width: '45',
                                                        },
                                                    ]}
                                                >
                                                    ATIS:
                                                </Text>
                                                <Text style={styles.textNormal}>
                                                    {aerodrome.atis &&
                                                        aerodrome.atis.data}
                                                </Text>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                    {(params.aerodromesMetar ||
                        params.aerodromesTaf ||
                        params.aerodromesAtis) &&
                        !aerodrome.metar &&
                        !aerodrome.taf &&
                        !aerodrome.atis && (
                            <div /// NO INFORMATION AVAILABLE
                                style={[
                                    styles.sectionContent,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #e5e5e5',
                                    },
                                ]}
                            >
                                <div
                                    style={[
                                        styles.boxCell,
                                        {
                                            borderRight: '1px solid #e5e5e5',
                                            flexGrow: '1',
                                            width: '20%',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '600',
                                            opacity: '0.6',
                                        }}
                                    >
                                        MET INFORMATION
                                    </Text>
                                </div>
                                <div
                                    style={[
                                        styles.boxCell,
                                        {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexGrow: '10',
                                            width: '80%',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '300',
                                            opacity: '0.6',
                                        }}
                                    >
                                        No MET information is available
                                    </Text>
                                </div>
                            </div>
                        )}

                    {params.aerodromesNotam &&
                        aerodrome.notam && ( /// NOTAMS
                            <div
                                style={[
                                    styles.sectionContent,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                    },
                                ]}
                            >
                                <div
                                    style={[
                                        styles.boxCell,
                                        {
                                            borderRight: '1px solid #e5e5e5',
                                            flexGrow: '1',
                                            width: '20%',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '600',
                                            marginTop: '2',
                                        }}
                                    >
                                        NOTAM
                                    </Text>
                                </div>
                                <div
                                    style={[
                                        styles.boxCell,
                                        {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: '10',
                                            marginTop: '5',
                                            marginBottom: '5',
                                            width: '80%',
                                        },
                                    ]}
                                >
                                    {aerodrome.notam &&
                                        aerodrome.notam.length > 0 &&
                                        aerodrome.notam.map((notam, i) => {
                                            const code23 = code23Decode(
                                                notam.code23
                                            )
                                            const code45 = code45Decode(
                                                notam.code45
                                            )
                                            const traffic = trafficDecode(
                                                notam.traffic
                                            )
                                            if (
                                                parseISO(notam.startvalidity) <=
                                                periodTo
                                            ) {
                                                return (
                                                    <div
                                                        wrap={false}
                                                        key={`AerodromesNotam${notam.series}${notam.number}/${notam.year}`}
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        {(showOutdatedErrors ||
                                                            notam.outofdate) && (
                                                            <div
                                                                wrap={false}
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    width: '100%',
                                                                    marginBottom:
                                                                        '5',
                                                                }}
                                                            >
                                                                <div
                                                                    style={[
                                                                        styles.sectionContent,
                                                                        {
                                                                            width: '6%',
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <ErrorIcon
                                                                        width="16"
                                                                        height="16"
                                                                        viewBox="0 0 16 16"
                                                                        fill="rgb(16, 170, 112)"
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={[
                                                                        styles.sectionContent,
                                                                        {
                                                                            width: '94%',
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Text
                                                                        style={
                                                                            styles.error
                                                                        }
                                                                    >
                                                                        <span>
                                                                            There
                                                                            has
                                                                            been
                                                                            an
                                                                            issue
                                                                            with
                                                                            the
                                                                            connection
                                                                            between
                                                                            PreFlight
                                                                            and
                                                                            Airways.
                                                                            This{' '}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    '500',
                                                                            }}
                                                                        >
                                                                            NOTAM
                                                                        </span>
                                                                        <span>
                                                                            {' '}
                                                                            was
                                                                            last
                                                                            validated
                                                                            with
                                                                            Airways
                                                                            more
                                                                            than{' '}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    '500',
                                                                            }}
                                                                        >
                                                                            {formatDistanceToNow(
                                                                                parseISO(
                                                                                    notam.updated
                                                                                ),
                                                                                {
                                                                                    addSuffix: true,
                                                                                }
                                                                            )}
                                                                        </span>
                                                                        <span>
                                                                            .
                                                                            There
                                                                            is a
                                                                            high
                                                                            likelihood
                                                                            that
                                                                            the
                                                                            latest
                                                                            NOTAM
                                                                            information
                                                                            is
                                                                            not
                                                                            available
                                                                            in
                                                                            PreFlight.
                                                                            Check
                                                                            back
                                                                            again
                                                                            soon,
                                                                            or
                                                                            alternatively
                                                                            visit{' '}
                                                                        </span>
                                                                        <a
                                                                            style={{
                                                                                fontWeight:
                                                                                    '400',
                                                                                textDecoration:
                                                                                    'underline',
                                                                            }}
                                                                            href="https://ifis.airways.co.nz/"
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            IFIS
                                                                        </a>
                                                                        .
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                            }}
                                                        >
                                                            <div
                                                                style={[
                                                                    styles.sectionContent,
                                                                    {
                                                                        width: '6%',
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'column',
                                                                    },
                                                                ]}
                                                            >
                                                                {notamIconFormatter(
                                                                    notam.code23
                                                                )}
                                                            </div>

                                                            <div
                                                                style={[
                                                                    styles.sectionContent,
                                                                    {
                                                                        width: '100%',
                                                                        display:
                                                                            'flex',
                                                                        flexGrow:
                                                                            '10',
                                                                        flexDirection:
                                                                            'column',
                                                                    },
                                                                ]}
                                                            >
                                                                <div
                                                                    style={[
                                                                        styles.sectionContent,
                                                                        {
                                                                            width: '94%',
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={
                                                                                styles.labelNormal
                                                                            }
                                                                        >
                                                                            {
                                                                                code23
                                                                            }
                                                                        </Text>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={
                                                                                styles.textNormal
                                                                            }
                                                                        >
                                                                            {
                                                                                code45
                                                                            }
                                                                        </Text>
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                            paddingTop:
                                                                                '5',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={[
                                                                                styles.labelNormal,
                                                                                {
                                                                                    width: '50',
                                                                                    fontWeight:
                                                                                        '500',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            NOTAM
                                                                            #:
                                                                        </Text>
                                                                        <Text
                                                                            style={
                                                                                styles.textNormal
                                                                            }
                                                                        >
                                                                            {`${notam.series}${notam.number}/${notam.year}`}
                                                                        </Text>
                                                                    </div>

                                                                    <div
                                                                        wrap={
                                                                            false
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={[
                                                                                styles.labelNormal,
                                                                                {
                                                                                    width: '50',
                                                                                    fontWeight:
                                                                                        '500',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            Affects:
                                                                        </Text>
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            {traffic.ifr && (
                                                                                <Text
                                                                                    style={
                                                                                        styles.ifrBadge
                                                                                    }
                                                                                >
                                                                                    IFR
                                                                                </Text>
                                                                            )}
                                                                            {traffic.vfr && (
                                                                                <Text
                                                                                    style={
                                                                                        styles.vfrBadge
                                                                                    }
                                                                                >
                                                                                    VFR
                                                                                </Text>
                                                                            )}
                                                                            <Text
                                                                                style={
                                                                                    styles.textNormal
                                                                                }
                                                                            >
                                                                                TRAFFIC
                                                                            </Text>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={[
                                                                                styles.labelNormal,
                                                                                {
                                                                                    width: '50',
                                                                                    fontWeight:
                                                                                        '500',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            From:
                                                                        </Text>
                                                                        <Text
                                                                            style={
                                                                                styles.textNormal
                                                                            }
                                                                        >
                                                                            {notam?.startvalidity &&
                                                                                formatDateTime(
                                                                                    notam.startvalidity,
                                                                                    timeZone
                                                                                )}
                                                                        </Text>
                                                                    </div>

                                                                    {!notam.permanent && (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            <Text
                                                                                style={[
                                                                                    styles.labelNormal,
                                                                                    {
                                                                                        width: '50',
                                                                                        fontWeight:
                                                                                            '500',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                To:
                                                                            </Text>
                                                                            <Text
                                                                                style={
                                                                                    styles.textNormal
                                                                                }
                                                                            >
                                                                                {notam?.endvalidity &&
                                                                                    formatDateTime(
                                                                                        notam.endvalidity,
                                                                                        timeZone
                                                                                    )}
                                                                                {notam?.estimation &&
                                                                                    ' (Estimated)'}
                                                                            </Text>
                                                                        </div>
                                                                    )}

                                                                    {notam.itemd &&
                                                                        notam.itemd !==
                                                                            'null' && (
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'row',
                                                                                }}
                                                                            >
                                                                                <Text
                                                                                    style={[
                                                                                        styles.labelNormal,
                                                                                        {
                                                                                            width: '50',
                                                                                            fontWeight:
                                                                                                '500',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    Period:
                                                                                </Text>
                                                                                <Text
                                                                                    style={
                                                                                        styles.textNormal
                                                                                    }
                                                                                >
                                                                                    {notam.itemd +
                                                                                        ' (UTC if time shown)'}
                                                                                </Text>
                                                                            </div>
                                                                        )}

                                                                    {notam.itemf && (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            <Text
                                                                                style={[
                                                                                    styles.labelNormal,
                                                                                    {
                                                                                        width: '50',
                                                                                        fontWeight:
                                                                                            '500',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                Altitude:
                                                                            </Text>
                                                                            <Text
                                                                                style={
                                                                                    styles.textNormal
                                                                                }
                                                                            >
                                                                                {notam.lowerlimit +
                                                                                    ' – ' +
                                                                                    notam.upperlimit}
                                                                            </Text>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <Text
                                                                    style={[
                                                                        styles.textNormal,
                                                                        {
                                                                            marginTop:
                                                                                '5',
                                                                        },
                                                                    ]}
                                                                >
                                                                    {
                                                                        notam.iteme
                                                                    }
                                                                </Text>
                                                            </div>
                                                        </div>
                                                        {i + 1 !==
                                                            aerodrome.notam
                                                                .length && (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    borderBottom:
                                                                        '1px solid #f5f5f5',
                                                                    marginTop:
                                                                        '10',
                                                                    marginBottom:
                                                                        '10',
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                </div>
                            </div>
                        )}
                    {params.aerodromesNotam &&
                        !aerodrome.notam && ( /// NO NOTAMS
                            <div
                                style={[
                                    styles.sectionContent,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                    },
                                ]}
                            >
                                <div
                                    style={[
                                        styles.boxCell,
                                        {
                                            borderRight: '1px solid #e5e5e5',
                                            flexGrow: '1',
                                            width: '20%',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '600',
                                            opacity: '0.6',
                                        }}
                                    >
                                        NOTAM
                                    </Text>
                                </div>
                                <div
                                    style={[
                                        styles.boxCell,
                                        {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexGrow: '10',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '300',
                                            opacity: '0.6',
                                        }}
                                    >
                                        No current NOTAMs issued
                                    </Text>
                                </div>
                            </div>
                        )}
                </div>
            </View>
        )
    )
}
