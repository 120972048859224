import React, { useState, useEffect, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useQuery } from 'react-query'
import { GeoJSONLayer, Popup } from 'react-mapbox-gl'
import { addMinutes, addSeconds } from 'date-fns'
import { formatDateToTimezone } from '../../../util/dateFormatter'

import {
    mapBoundsState,
    layerErrorHandler,
    removeLayerErrorHandler,
} from '../../../globalState'

//TODO refactor to use React Query, remove timer state and refetch logic. Not driven by status object, standard and not evaluated.

export default function LightningLayer() {
    const [data, setData] = useState({
        type: 'FeatureCollection',
        features: [],
    })
    const [hoverInfo, setHoverInfo] = useState(null)
    const mapBounds = useRecoilValue(mapBoundsState)
    const setLayerError = useSetRecoilState(layerErrorHandler)
    const removeError = useSetRecoilState(removeLayerErrorHandler)
    const minutesAgo = -1 // X minutes ago to X minutes into the future
    const updateRate = 10 // seconds
    const nowUnixTime = new Date().getTime() * 0.001
    const minutesAgoUnixTime =
        addMinutes(Date.now(), minutesAgo).getTime() * 0.001

    const generateAPIUrl = () => {
        // X minutes ago to 1 minutes into the future

        const startDate =
            formatDateToTimezone(addMinutes(Date.now(), minutesAgo), 'UTC') +
            'Z'
        const endDate =
            formatDateToTimezone(addMinutes(Date.now(), 1), 'UTC') + 'Z'

        const apiBaseUrl = 'https://lightning.api.metraweather.com/v4/strikes'
        const apiTime = `time=${startDate}--${endDate}`
        const apiBbox =
            'bbox=' +
            [mapBounds[0][0], mapBounds[0][1], mapBounds[1][0], mapBounds[1][1]] // wider view for testing: [90, -90, 180, -0];
        const apiUrl = apiBaseUrl + '?' + apiTime + '&' + apiBbox

        console.log('startDate: ', startDate)
        console.log('endDate: ', endDate)

        return apiUrl
    }

    const fetchLightningData = async () => {
        const res = await fetch(generateAPIUrl(), {
            headers: {
                Authorization:
                    process.env.REACT_APP_METSERVICE_LIGHTNING_API_KEY,
            },
        })
        return res.json()
    }

    const {
        data: lightningData,
        status: lightningStatus,
        isStale,
        refetch,
    } = useQuery('lightning', fetchLightningData, {
        // Refetch the data every 30 seconds
        staleTime: 30000,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        isStale && refetch()
    }, [isStale, refetch])

    // TODO - add error setting when lightning has been refactored and updated
    // This useEffect handles errors

    useEffect(() => {
        if (lightningData && lightningStatus === 'success') {
            //removeError({ layer: layerId.toLowerCase(), type: 'fetching' })
            console.log('Lightning fetch success')
        } else if (lightningStatus === 'error') {
            // setLayerError({
            //     layer: layerId.toLowerCase(),
            //     type: 'fetching',
            // })
            console.log('Lightning fetch error')
        }
    }, [removeError, setLayerError, lightningStatus, lightningData])

    useEffect(() => {
        console.log('Changed', { lightningData })
        if (lightningData) {
            if (lightningData?.features?.length >= 100) {
                console.log('Lightning query maxed out')
            }
            setData((d) => {
                const updateRateAgoUnixTime =
                    addSeconds(Date.now(), updateRate * -1).getTime() * 0.001
                if (d.features.length) {
                    const oldDataIds =
                        d.features && d.features.map((feat) => feat.id)
                    const lightningDataIds = lightningData.features.map(
                        (feat) => feat.id
                    )
                    const newIds = lightningDataIds.filter(
                        (id) => !oldDataIds.includes(id)
                    )
                    return {
                        type: 'FeatureCollection',
                        features: lightningData.features.map((feat) =>
                            newIds.includes(feat.id)
                                ? {
                                      type: feat.type,
                                      id: feat.id,
                                      properties: {
                                          ...feat.properties,
                                          flash: true,
                                      },
                                      geometry: feat.geometry,
                                  }
                                : {
                                      type: feat.type,
                                      id: feat.id,
                                      properties: {
                                          ...feat.properties,
                                          flash: false,
                                      },
                                      geometry: feat.geometry,
                                  }
                        ),
                    }
                } else {
                    return {
                        type: 'FeatureCollection',
                        features: lightningData?.features?.map((feat) =>
                            feat.properties.unixTime >=
                            updateRateAgoUnixTime - updateRate * 2
                                ? {
                                      type: feat.type,
                                      id: feat.id,
                                      properties: {
                                          ...feat.properties,
                                          flash: true,
                                      },
                                      geometry: feat.geometry,
                                  }
                                : {
                                      type: feat.type,
                                      id: feat.id,
                                      properties: {
                                          ...feat.properties,
                                          flash: false,
                                      },
                                      geometry: feat.geometry,
                                  }
                        ),
                    }
                }
            })
        }
    }, [lightningData])

    const onHover = (e) => {
        setHoverInfo(e)
        e.target.getCanvas().style.cursor = 'pointer'
    }

    const offHover = useCallback((e) => {
        setHoverInfo(null)
        e.target.getCanvas().style.cursor = ''
    }, [])

    return (
        <>
            <GeoJSONLayer
                id="lightning-animated"
                type="symbol"
                data={data}
                symbolLayout={{
                    'symbol-sort-key': ['get', 'unixTime'],
                    'symbol-z-order': 'source',
                    'icon-image': 'pulsingdot',
                    'icon-size': 0.6,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                }}
                symbolPaint={{
                    'icon-opacity': [
                        'interpolate',
                        ['linear'],
                        ['get', 'unixTime'],
                        minutesAgoUnixTime,
                        0,
                        nowUnixTime,
                        1,
                    ],
                }}
                layerOptions={{ filter: ['==', ['get', 'flash'], true] }}
            />
            <GeoJSONLayer
                id="lightning"
                type="symbol"
                data={data}
                symbolLayout={{
                    'symbol-sort-key': ['get', 'unixTime'],
                    'symbol-z-order': 'source',
                    'icon-image': 'lightning',
                    'icon-size': 0.6,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                }}
                symbolPaint={{
                    'icon-opacity': [
                        'interpolate',
                        ['linear'],
                        ['get', 'unixTime'],
                        minutesAgoUnixTime,
                        0,
                        nowUnixTime,
                        1,
                    ],
                }}
                symbolOnMouseEnter={onHover}
                symbolOnMouseLeave={offHover}
            />
            {hoverInfo && hoverInfo.features.length > 0 && (
                <Popup
                    coordinates={[hoverInfo.lngLat.lng, hoverInfo.lngLat.lat]}
                >
                    <div className="popup-body">
                        <pre>
                            {JSON.stringify(
                                hoverInfo.features[0].properties,
                                null,
                                2
                            )}
                        </pre>
                    </div>
                </Popup>
            )}
        </>
    )
}
