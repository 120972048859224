import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function FastForwardIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 5} color={color || colorHandler()}>
            <path
                d="M12.467 11.7924C12.9513 12.1447 12.9513 12.8553 12.467 13.2076L5.43484 18.3248C4.84164 18.7564 4 18.3413 4 17.6171V7.38286C4 6.65866 4.84164 6.24359 5.43484 6.67524L12.467 11.7924Z"
                fill="currentColor"
            />
            <path
                d="M21.6368 11.7924C22.1211 12.1447 22.1211 12.8553 21.6368 13.2076L14.6046 18.3248C14.0114 18.7564 13.1698 18.3413 13.1698 17.6171V7.38286C13.1698 6.65866 14.0114 6.24359 14.6046 6.67524L21.6368 11.7924Z"
                fill="currentColor"
            />
        </Icon>
    )
}
