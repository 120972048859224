import React, { useEffect, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
// import { useAuth0 } from '@auth0/auth0-react'
import {
    Flex,
    Text,
    Link,
    Input,
    InputGroup,
    // HStack,
    // PinInput,
    // PinInputField,
} from '@chakra-ui/react'

import {
    mfaVerifiedState,
    userProfileState,
    setModalVisibilityState,
} from '../../../globalState'

export default function Verify({
    verificationMethod,
    mobileNumber,
    verificationCode,
    setVerificationCode,
    verificationStatus,
    verificationCallback,
}) {
    const setMfaVerified = useSetRecoilState(mfaVerifiedState)
    const setProfileData = useSetRecoilState(userProfileState)
    // const profileData = useRecoilValue(userProfileState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    // const { getAccessTokenSilently } = useAuth0()
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const mask = (cc, num = 4, mask = '*') =>
        ('' + cc).slice(0, -num).replace(/./g, mask) + ('' + cc).slice(-num)

    useEffect(() => {
        if (verificationStatus === 'Successful Verification') {
            const setMfaState = setTimeout(() => {
                setMfaVerified(true)
                setProfileData((prevState) => ({
                    ...prevState,
                    mfaLastVerified: Date.now(),
                    mfa_verified_active: true, // this sets a temporary state to avoid needing to reset MFA in current user session (i.e. accessing Subscription or changing password)
                    mfa_totp_set: true, // this sets a temporary state after verifying with Authenticator to avoid loading QR code again in current user session (after first setting QR code)
                }))
                console.log('Successful Verification')
                // getAccessTokenSilently({ ignoreCache: true })
                setShow(false)
            }, 2000)

            return () => {
                clearTimeout(setMfaState)
            }
        }
    }, [
        verificationStatus,
        setMfaVerified,
        setProfileData,
        // getAccessTokenSilently,
        setShow,
    ])

    // console.log('profileData: ', profileData)
    // console.log(
    //     'mfa_last_verified: ',
    //     profileData && profileData.mfaLastVerified
    // )

    return (
        <Flex
            pt="30px"
            pb="30px"
            flexDirection="column"
            gap={3}
            height="100%"
            minHeight={verificationMethod === 'sms' ? '345px' : '300px'}
            width="100%"
            px={{ base: '20px', md: '40px' }}
            justifyContent="flex-start"
            alignItems="center"
            bg="white"
        >
            <Text fontSize="0.9rem" lineHeight="1.6" px="10px">
                {verificationMethod === 'sms' &&
                    'We sent a 6-digit verification code to your mobile number '}
                {verificationMethod === 'sms' && (
                    <Text
                        fontSize="1.1rem"
                        fontWeight="500"
                        letterSpacing="1px"
                    >
                        0{mask(mobileNumber)}{' '}
                    </Text>
                )}
                {verificationMethod === 'totp' &&
                    `Enter the 6-digit verification code from your Authenticator app`}
                {verificationMethod === 'email' &&
                    `We sent a 6-digit verification code to your email address`}
            </Text>

            {/* <HStack // TODO - this needs finishing - possibly an issue using OTP on iOS devices (pasting code direct from keyboard)
                pt="10px"
            >
                <PinInput
                    otp
                    type="number"
                    size="lg"
                    onComplete={(code) => setVerificationCode(code)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            // console.log('Enter pressed')
                            verificationCallback()
                        }
                    }}
                >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                </PinInput>
            </HStack> */}

            <InputGroup width="unset" pt="15px" px="20px">
                <Input
                    size="lg"
                    borderRadius="12px"
                    textAlign="center"
                    fontSize="1.5rem"
                    letterSpacing="5px"
                    borderColor="#E2E8F0"
                    variant="outline"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            // console.log('Enter pressed')
                            verificationCallback()
                            setVerificationCode(e.target.value)
                        }
                    }}
                    _hover={{ borderColor: '#00D599' }}
                    _focus={{
                        border: '1px solid',
                        borderColor: '#00D599 !important',
                        boxShadow: 'none !important',
                    }}
                    placeholder="Enter the 6-digit code"
                    _placeholder={{
                        fontSize: { base: '0.9rem', md: '1rem' },
                        letterSpacing: '1.5px',
                    }}
                />
            </InputGroup>

            <Flex flexDirection="column" marginTop="3px" minHeight="25px">
                {(!verificationStatus ||
                    verificationStatus !== 'Incorrect Code Length') &&
                    verificationCode.length > 6 && (
                        <Text fontSize="sm" color="red">
                            Please ensure the code is 6 digits
                        </Text>
                    )}
                {verificationStatus === 'Incorrect Code Length' && (
                    <Text fontSize="sm" color="red">
                        Code must be 6 digits
                    </Text>
                )}
                {verificationStatus === 'Incorrect Code' && (
                    <Text fontSize="sm" color="red">
                        Incorrect Code
                    </Text>
                )}
                {verificationStatus === 'Server Error' && (
                    <Text fontSize="sm" color="red">
                        Server Error. Please refresh the page and try again.
                    </Text>
                )}
                {verificationStatus === 'Empty Code' && (
                    <Text fontSize="sm" color="red">
                        Please enter the code
                    </Text>
                )}
            </Flex>
            {verificationStatus === 'Successful Verification' ? (
                <>
                    <Text
                        color="light.200"
                        fontWeight="500"
                        fontSize="1.4rem"
                        mt="-25px"
                        mb="-5px"
                    >
                        Success!
                    </Text>
                    <Text fontSize="0.9rem" pb="10px" lineHeight="1.6">
                        Your MFA has been updated
                    </Text>
                </>
            ) : (
                <Text
                    fontSize="0.8rem"
                    color="gray.500"
                    mt="-10px"
                    pb="10px"
                    lineHeight="1.6"
                >
                    If you did not receive an{' '}
                    {verificationMethod === 'email' && 'email'}
                    {verificationMethod === 'sms' && 'SMS text'}, or you are
                    having issues verifiying your account, please try an
                    alternative verification method, or{' '}
                    <Link
                        color="light.200"
                        href="mailto:helpdesk@airwaysinternational.co.nz?subject=Issues with PreFlight SMS Verification"
                    >
                        contact support
                    </Link>
                </Text>
            )}
        </Flex>
    )
}
