import { intervalToDuration } from 'date-fns'

// *** OUT OF DATE TIME CONVERSIONS *** //

// METAR Out of Date Error Message Times
export const getMetarOutOfDate = (
    issueTime,
    designator,
    currentTime,
    showErrors
) => {
    const metarTimeDifference =
        issueTime &&
        intervalToDuration({
            start: issueTime,
            end: currentTime,
        })
    return showErrors ||
        (metarTimeDifference && metarTimeDifference.days >= 1) ||
        (metarTimeDifference &&
            metarTimeDifference.days < 1 &&
            metarTimeDifference.hours >= 1 &&
            (metarTimeDifference.hours === 1
                ? metarTimeDifference.minutes > 15
                : metarTimeDifference.minutes > 0)) // = 01:15
        ? 'WARNING' // red
        : metarTimeDifference &&
          ((metarTimeDifference.hours === 1 &&
              metarTimeDifference.minutes < 15) ||
              (metarTimeDifference.hours < 1 &&
                  metarTimeDifference.minutes >= 45)) && // = 00:45
          designator !== 'NZWP' &&
          designator !== 'NZOH' &&
          designator !== 'NZMF'
        ? 'CAUTION' // orange
        : 'NONE'
}

// TAF Out of Date Error Message Times
export const getTafOutOfDate = (issueTime, designator, currentTime) => {
    const tafTimeDifference =
        issueTime &&
        intervalToDuration({
            start: issueTime,
            end: currentTime,
        })
    return (tafTimeDifference && tafTimeDifference.days >= 1) ||
        (tafTimeDifference &&
            tafTimeDifference.days < 1 &&
            tafTimeDifference.hours >= 21) // = 21 hours or greater (all airports)
        ? 'WARNING' // red
        : tafTimeDifference &&
          tafTimeDifference.hours >= 18 && // = 18 hours or greater (excludes NZTG)
          designator !== 'NZTG'
        ? 'WARNING' // red
        : tafTimeDifference && tafTimeDifference.hours >= 12 // = 12 hours or greater
        ? 'CAUTION' // orange
        : // below are for AA, WN and CH only
        tafTimeDifference &&
          tafTimeDifference.hours >= 6 && // = 6 hours or greater (AA, WN and CH only)
          (designator === 'NZAA' ||
              designator === 'NZWN' ||
              designator === 'NZCH')
        ? 'WARNING' // red
        : tafTimeDifference &&
          tafTimeDifference.hours >= 3 &&
          (tafTimeDifference.hours === 3
              ? tafTimeDifference.minutes > 30
              : tafTimeDifference.minutes > 0) && // = 3 hours & 30 minutes or greater (AA, WN and CH only)
          (designator === 'NZAA' ||
              designator === 'NZWN' ||
              designator === 'NZCH')
        ? 'CAUTION' // orange
        : 'NONE'
}
