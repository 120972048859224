import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import {
    Box,
    Text,
    Button,
    Flex,
    Stack,
    LightMode,
    Checkbox,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import * as turf from '@turf/turf'
import {
    briefingStandardState,
    selectedBriefingAreasState,
    briefingAreaSourceState,
    dataModeState,
    briefingCreatedState,
    layerSelectionState,
    layerSelectionHandler,
} from '../../../globalState'
import BriefingAreasImage from '../../../map-disabled/assets/BriefingAreasImage'
import AAWViewSelectSwitch from '../../../map-disabled/components/AAWViewSelectSwitch'

export default function BriefingOnlySelection(props) {
    const {
        showBriefingOnlySelection,
        setShowBriefingOnlySelection,
        setShow,
        viewportHeight,
    } = props
    const { getAccessTokenSilently } = useAuth0()
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const [selectedAreas, setSelectedAreas] = useRecoilState(
        selectedBriefingAreasState
    )
    const [briefingCreated, setBriefingCreated] =
        useRecoilState(briefingCreatedState)

    const [briefingAreaSource, setBriefingAreaSource] = useRecoilState(
        briefingAreaSourceState
    )
    const layerSelection = useRecoilValue(layerSelectionState)
    const setLayerSelection = useSetRecoilState(layerSelectionHandler)
    const [briefingAreas, setBriefingAreas] = useState([])
    const setBriefingStandard = useSetRecoilState(briefingStandardState)
    const [briefingAreasMap, setBriefingAreasMap] = useState(true)
    const scrollbarRef = useRef(null)

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (showBriefingOnlySelection) {
            onOpen()
        } else {
            onClose()
        }
    }, [showBriefingOnlySelection, onOpen, onClose])

    const onCloseHandler = () => {
        setShowBriefingOnlySelection(false)
        setShow(true)
        onClose()
    }

    const fetchBriefingAreas = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/briefingareas`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }, [getAccessTokenSilently])

    const fetchAwwAreas = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/aaw/areas`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })

        const areas = await res.json()
        const filteredAreas = areas.features.filter(
            (area) => area.properties.area !== 'LINES'
        )

        return filteredAreas
    }, [getAccessTokenSilently])

    useEffect(() => {
        if (briefingAreaSource === 'briefingarea') {
            fetchBriefingAreas()
                .then((areas) => {
                    setBriefingAreas(areas.features)
                    console.log('Updating Briefing Areas')
                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (briefingAreaSource === 'aaw') {
            fetchAwwAreas()
                .then((filteredAreas) => {
                    setBriefingAreas(filteredAreas)
                    console.log('Updating AAW Areas')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [briefingAreaSource, setDataMode, fetchBriefingAreas, fetchAwwAreas])

    useCallback(() => {
        if (dataMode === 'brief' && briefingCreated) {
            setBriefingCreated(false)
        }
    }, [dataMode, briefingCreated, setBriefingCreated])

    useEffect(() => {
        setBriefingAreaSource('briefingarea')
    }, [setBriefingAreaSource])

    useEffect(() => {
        if (layerSelection?.brief?.briefingareas) {
            setLayerSelection({
                mode: 'brief',
                layer: 'briefingareas',
            })
        }
    }, [layerSelection, setLayerSelection])

    useEffect(() => {
        if (showBriefingOnlySelection) {
            setSelectedAreas([])
        }
    }, [briefingAreaSource, showBriefingOnlySelection, setSelectedAreas])

    const toggleCheckbox = (feat) => {
        const area =
            briefingAreaSource === 'aaw'
                ? {
                      area: feat.properties.area,
                      name: feat.properties.name,
                  }
                : {
                      designator: feat.properties.designator,
                  }

        setSelectedAreas((prevSelected) =>
            prevSelected.some(
                (item) => JSON.stringify(item) === JSON.stringify(area)
            )
                ? prevSelected.filter(
                      (item) => JSON.stringify(item) !== JSON.stringify(area)
                  )
                : [...prevSelected, area]
        )
    }

    const selectAllCheckboxes = () => {
        setSelectedAreas(
            briefingAreas.map((feat) =>
                briefingAreaSource === 'aaw'
                    ? {
                          area: feat.properties.area,
                          name: feat.properties.name,
                      }
                    : {
                          designator: feat.properties.designator,
                      }
            )
        )
    }

    const clearAllCheckboxes = () => {
        if (selectedAreas?.length) {
            setSelectedAreas([])
        }
    }

    // trigger to reset list/map switch back to default position if list was scrolled
    const scrollToTop = useCallback(() => {
        if (scrollbarRef.current) {
            const osInstance = scrollbarRef.current?.osInstance()
            if (osInstance) {
                const viewport = osInstance.elements().viewport
                viewport.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }
    }, [])

    const sortedBriefingAreas =
        briefingAreas && briefingAreaSource === 'briefingarea'
            ? briefingAreas
            : briefingAreas.sort((a, b) => {
                  const centerA = turf.centerOfMass(a.geometry)
                  const centerB = turf.centerOfMass(b.geometry)
                  const areaCenterA = centerA.geometry.coordinates[1]
                  const areaCenterB = centerB.geometry.coordinates[1]
                  if (areaCenterA < areaCenterB) {
                      return 1
                  }
                  if (areaCenterA > areaCenterB) {
                      return -1
                  } else {
                      return 0
                  }
              })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const scrollBarHide = useBreakpointValue({
        base: briefingAreasMap ? 'hidden' : 'scroll',
        md: 'leave',
    })

    const setScroll = useBreakpointValue({
        base: briefingAreasMap ? 'hidden' : 'scroll',
        md: 'scroll',
    })

    const pointerEvents = useBreakpointValue({
        base: briefingAreasMap ? 'none' : 'auto',
        md: 'auto',
    })

    const modalWidth = useBreakpointValue({
        base: 'lg',
        md: '4xl',
    })

    const checkboxSize = useBreakpointValue({
        base: { size: 'xl' },
        md: { size: 'md' },
    })
    const checkboxVariant = useBreakpointValue({
        base: { variant: 'mobile' },
        md: { size: 'desktop' },
    })

    return (
        <>
            <LightMode>
                <Modal
                    variant="briefingOnlySelection"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    centered
                    motionPreset="scale"
                    scrollBehavior="inside"
                    closeOnOverlayClick={true}
                    trapFocus={false}
                    blockScrollOnMount={false}
                    size="md"
                    backgroundColor="transparent"
                >
                    <ModalOverlay bg="blackAlpha.400" />
                    <ModalContent
                        maxHeight={{
                            base: 'calc(100% - 3rem)',
                            md: 'calc(100% - 4rem)',
                        }}
                        width={modalWidth}
                        maxWidth={modalWidth}
                        transition="all ease 300ms"
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        boxShadow={'0px 15px 60px -20px rgba(0,0,0,0.5)'}
                    >
                        <ModalCloseButton color="light.100" zIndex="11" />
                        <ModalHeader
                            textAlign="center"
                            background="light.10"
                            color="light.100"
                            fontSize="1rem"
                            fontWeight="600"
                            letterSpacing="0.5px"
                            boxShadow={'0px 3px 15px -1px rgba(0,0,0,0.15)'}
                            borderBottom="1px solid #eaeaea"
                            zIndex="10"
                            minHeight="56px"
                            alignItems="center"
                            justifyContent="center"
                            display="flex"
                        >
                            <Text px="5px" textTransform={'uppercase'}>
                                Standard Briefing Areas
                            </Text>
                        </ModalHeader>
                        <ModalBody as="section" p="0px" overflow="hidden">
                            <Flex
                                flexDirection={{ base: 'column', md: 'row' }}
                                justifyContent="center"
                                alignItems={{
                                    base: 'center',
                                    md: 'flex-start',
                                }}
                                width="100%"
                                bg="light.35"
                            >
                                <Flex
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                    borderRight={{
                                        base: 'none',
                                        md: '1px solid #e5e7e9',
                                    }}
                                    maxWidth={{ base: '100%', md: '380px' }}
                                    height="100%"
                                    minHeight={{
                                        base: 'auto',
                                        md: 'calc(100vh - 185px)',
                                    }}
                                    maxHeight={{
                                        base: 'unset',
                                        md: '800px',
                                    }}
                                    gap="0px"
                                    bg="white"
                                >
                                    <Flex
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        pt="20px"
                                        pb="25px"
                                        gap="10px"
                                        width="100%"
                                        zIndex="3"
                                    >
                                        <Text
                                            textAlign="center"
                                            fontSize="0.8rem"
                                            mx="auto"
                                            pt={{ base: '1', md: '1' }}
                                            mt={{
                                                base: '0',
                                                md: '0px',
                                            }}
                                            pb={{
                                                base: '0px',
                                                md: '5px',
                                            }}
                                            px="5"
                                            color="#868b8e"
                                        >
                                            Select from standard briefing areas
                                        </Text>

                                        <Button
                                            bg="gray.100"
                                            color="#a8b2c0"
                                            variant="outline"
                                            fontSize="0.75rem"
                                            fontWeight="500"
                                            height={{
                                                base: '34px',
                                                md: '32px',
                                            }}
                                            width="100%"
                                            maxWidth={'260px'}
                                            style={{
                                                minWidth: '260px',
                                            }}
                                            justifyContent="initial"
                                            paddingLeft="2px"
                                            paddingRight="2px"
                                            py="2px"
                                            borderRadius="20px"
                                            borderColor="#E9EAEB"
                                            {...noFocus}
                                            onClick={() =>
                                                setBriefingAreaSource(
                                                    (prevState) =>
                                                        prevState === 'aaw'
                                                            ? 'briefingarea'
                                                            : 'aaw'
                                                )
                                            }
                                            _hover={{
                                                bg: 'gray.200',
                                            }}
                                        >
                                            <Box
                                                mt="0px"
                                                ml="-1px"
                                                px="10px"
                                                height={{
                                                    base: '30px',
                                                    md: '28px',
                                                }}
                                                width={'128px'}
                                                minWidth={'128px'}
                                                borderRadius="20px"
                                                bg="#ffffff"
                                                border="1px solid"
                                                style={{
                                                    borderColor: '#E9EAEB',
                                                    transform:
                                                        briefingAreaSource ===
                                                        'briefingarea'
                                                            ? 'translateX(0%)'
                                                            : 'translateX(100%)',
                                                    transition:
                                                        'transform 400ms',
                                                }}
                                            ></Box>
                                            <Flex
                                                justifyContent="space-evenly"
                                                marginLeft={'-135px'}
                                                width={'260px'}
                                            >
                                                <Text
                                                    zIndex="1"
                                                    paddingLeft="0px"
                                                    lineHeight="1.3"
                                                    color={
                                                        briefingAreaSource ===
                                                        'briefingarea'
                                                            ? 'light.200'
                                                            : 'light.100'
                                                    }
                                                    _selected={{
                                                        opacity: '1',
                                                    }}
                                                    _active={{
                                                        opacity: '1',
                                                    }}
                                                    transition="all ease 200ms"
                                                >
                                                    Briefing Areas
                                                </Text>
                                                <Text
                                                    zIndex="1"
                                                    paddingLeft={'28px'}
                                                    lineHeight="1.3"
                                                    color={
                                                        briefingAreaSource ===
                                                        'aaw'
                                                            ? 'light.200'
                                                            : 'light.100'
                                                    }
                                                    _selected={{
                                                        opacity: '1',
                                                    }}
                                                    _active={{
                                                        opacity: '1',
                                                    }}
                                                    transition="all ease 200ms"
                                                >
                                                    AAW Areas
                                                </Text>
                                            </Flex>
                                        </Button>
                                    </Flex>

                                    <Flex
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        width="100%"
                                        height="100%"
                                        zIndex="1"
                                    >
                                        <Flex
                                            width="100%"
                                            borderTop="1px solid #eaebec"
                                            flexDirection="column"
                                            overflowX="hidden"
                                            overflowY="auto"
                                            height={{
                                                base: `calc(${viewportHeight}px - 350px)`,
                                                md: `calc(${viewportHeight}px - 361px)`,
                                            }}
                                            maxHeight={{
                                                base: `calc(${viewportHeight}px - 350px)`,
                                                md: `calc(${viewportHeight}px - 361px)`,
                                            }}
                                            transition="all ease 300ms"
                                            className="settingsScrollVertical"
                                        >
                                            <OverlayScrollbarsComponent
                                                ref={scrollbarRef}
                                                options={{
                                                    className: 'os-theme-light',
                                                    scrollbars: {
                                                        visibility: 'auto',
                                                        autoHide: scrollBarHide,
                                                        autoHideDelay: 800,
                                                        dragScrolling: true,
                                                        clickScrolling: true,
                                                        touchSupport: true,
                                                    },
                                                    overflow: {
                                                        x: 'hidden',
                                                        y: setScroll,
                                                    },
                                                }}
                                                style={{
                                                    height: '100%',
                                                    minHeight: '100%',
                                                    overflowX:
                                                        'hidden !important',
                                                    zIndex: '2',
                                                    pointerEvents:
                                                        pointerEvents,
                                                }}
                                                defer
                                            >
                                                <AAWViewSelectSwitch
                                                    briefingAreasMap={
                                                        briefingAreasMap
                                                    }
                                                    setBriefingAreasMap={
                                                        setBriefingAreasMap
                                                    }
                                                    scrollToTop={scrollToTop}
                                                />
                                                <Flex
                                                    position={{
                                                        base: 'absolute',
                                                        md: 'relative',
                                                    }}
                                                    top={{
                                                        base: '65px',
                                                        md: 'unset',
                                                    }}
                                                    opacity={{
                                                        base: briefingAreasMap
                                                            ? '0'
                                                            : '1',
                                                        md: '1',
                                                    }}
                                                    visibility={{
                                                        base: briefingAreasMap
                                                            ? 'hidden'
                                                            : 'visible',
                                                        md: 'visible',
                                                    }}
                                                    pointerEvents={
                                                        pointerEvents
                                                    }
                                                    transition="all ease 300ms"
                                                    width="100%"
                                                    pt={{
                                                        base: '10px',
                                                        md: '20px',
                                                    }}
                                                    pb={{
                                                        base:
                                                            !briefingAreasMap &&
                                                            briefingAreaSource ===
                                                                'aaw'
                                                                ? '30px'
                                                                : '10px',
                                                        md: '20px',
                                                    }}
                                                    px={{
                                                        base: '30px',
                                                        md: '50px',
                                                    }}
                                                    gap="0px"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                >
                                                    {sortedBriefingAreas.map(
                                                        (area, i) => (
                                                            <Flex
                                                                key={`briefArea-${area}-${i}`}
                                                                width="100%"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                onClick={() =>
                                                                    toggleCheckbox(
                                                                        area
                                                                    )
                                                                }
                                                                cursor="pointer"
                                                                height={{
                                                                    base: '38px',
                                                                    md: '34px',
                                                                }}
                                                                minHeight={{
                                                                    base: '38px',
                                                                    md: '34px',
                                                                }}
                                                                transition="all ease 300ms"
                                                                pl="15px"
                                                                pr="7px"
                                                                borderRadius="20px"
                                                                _hover={{
                                                                    background:
                                                                        'light.45',
                                                                }}
                                                                color="light.100"
                                                            >
                                                                <Flex>
                                                                    {briefingAreaSource ===
                                                                    'aaw' ? (
                                                                        <Flex
                                                                            key={`${area.area}-${i}`}
                                                                            marginTop="1px !important"
                                                                        >
                                                                            <Text
                                                                                fontSize={{
                                                                                    base: '1rem',
                                                                                    md: '0.9rem',
                                                                                }}
                                                                                fontWeight="500"
                                                                                minWidth="40px"
                                                                            >
                                                                                {
                                                                                    area
                                                                                        .properties
                                                                                        .area
                                                                                }
                                                                            </Text>
                                                                            <Text
                                                                                fontSize={{
                                                                                    base: '1rem',
                                                                                    md: '0.9rem',
                                                                                }}
                                                                                fontWeight="300"
                                                                                width="100%"
                                                                            >
                                                                                {
                                                                                    area
                                                                                        .properties
                                                                                        .name
                                                                                }
                                                                            </Text>
                                                                        </Flex>
                                                                    ) : (
                                                                        <Text
                                                                            key={`${area.designator}-${i}`}
                                                                            fontSize={{
                                                                                base: '1rem',
                                                                                md: '0.9rem',
                                                                            }}
                                                                            fontWeight="500"
                                                                            width="100%"
                                                                            color="light.100"
                                                                        >
                                                                            {
                                                                                area
                                                                                    .properties
                                                                                    .designator
                                                                            }
                                                                        </Text>
                                                                    )}
                                                                </Flex>
                                                                <Checkbox
                                                                    {...checkboxSize}
                                                                    {...checkboxVariant}
                                                                    minHeight="24px"
                                                                    key={
                                                                        area
                                                                            .properties
                                                                            .name ||
                                                                        area
                                                                            .properties
                                                                            .designator
                                                                    }
                                                                    isChecked={selectedAreas.some(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            JSON.stringify(
                                                                                item
                                                                            ) ===
                                                                            JSON.stringify(
                                                                                briefingAreaSource ===
                                                                                    'aaw'
                                                                                    ? {
                                                                                          area: area
                                                                                              .properties
                                                                                              .area,
                                                                                          name: area
                                                                                              .properties
                                                                                              .name,
                                                                                      }
                                                                                    : {
                                                                                          designator:
                                                                                              area
                                                                                                  .properties
                                                                                                  .designator,
                                                                                      }
                                                                            )
                                                                    )}
                                                                    onChange={() =>
                                                                        toggleCheckbox(
                                                                            area
                                                                        )
                                                                    }
                                                                ></Checkbox>
                                                            </Flex>
                                                        )
                                                    )}
                                                </Flex>
                                            </OverlayScrollbarsComponent>

                                            <Flex
                                                zIndex="1"
                                                position="absolute"
                                                width="100%"
                                                height="100%"
                                                maxHeight="inherit"
                                                opacity={
                                                    !briefingAreasMap
                                                        ? '0'
                                                        : '1'
                                                }
                                                visibility={
                                                    !briefingAreasMap
                                                        ? 'hidden'
                                                        : 'visible'
                                                }
                                                pointerEvents={pointerEvents}
                                                transition="all ease 300ms"
                                                pt={{
                                                    base: '15px',
                                                    md: '0px',
                                                }}
                                                pl="15px"
                                                pr="5px"
                                                pb={{
                                                    base: '10px',
                                                    md: '0px',
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                                display={{
                                                    base: 'flex',
                                                    md: 'none',
                                                }}
                                            >
                                                <BriefingAreasImage
                                                    transition="all ease 300ms"
                                                    briefingAreaSource={
                                                        briefingAreaSource
                                                    }
                                                    sortedBriefingAreas={
                                                        sortedBriefingAreas
                                                    }
                                                    toggleCheckbox={
                                                        toggleCheckbox
                                                    }
                                                    selectedAreas={
                                                        selectedAreas
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        height={{
                                            base: '62px',
                                            md: '60px',
                                        }}
                                        minHeight={{
                                            base: '62px',
                                            md: '60px',
                                        }}
                                        width="100%"
                                        padding={{
                                            base: '10px',
                                            md: '15px',
                                        }}
                                        justifyContent="center"
                                        borderTop="1px solid #eaebec"
                                        flexDirection="column"
                                        zIndex="2"
                                        bg="white"
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={{
                                                base: '2',
                                                md: '4',
                                            }}
                                            align="center"
                                            px={{
                                                base: '15px',
                                                md: '10px',
                                            }}
                                            gap={{
                                                base: '10px',
                                                md: '5px',
                                            }}
                                        >
                                            <Button
                                                size="sm"
                                                variant="outline"
                                                color="light.100"
                                                fontSize={{
                                                    base: '0.75rem',
                                                    md: '0.8rem',
                                                }}
                                                height={{
                                                    base: '34px',
                                                    md: '30px',
                                                }}
                                                fontWeight="400"
                                                borderRadius="10px"
                                                width="100%"
                                                bg="light.20"
                                                onClick={() =>
                                                    selectAllCheckboxes()
                                                }
                                                {...noFocus}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="outline"
                                                color="light.100"
                                                fontSize={{
                                                    base: '0.75rem',
                                                    md: '0.8rem',
                                                }}
                                                height={{
                                                    base: '34px',
                                                    md: '30px',
                                                }}
                                                width="100%"
                                                borderRadius="10px"
                                                fontWeight="400"
                                                bg="light.20"
                                                onClick={() =>
                                                    clearAllCheckboxes()
                                                }
                                                {...noFocus}
                                            >
                                                Deselect All
                                            </Button>
                                        </Stack>
                                    </Flex>
                                </Flex>
                                <Flex
                                    px="35px"
                                    pt="5px"
                                    height="100%"
                                    minHeight={{
                                        base: 'auto',
                                        md: 'calc(100vh - 185px)',
                                    }}
                                    maxHeight={{
                                        base: 'unset',
                                        md: '800px',
                                    }}
                                    width="100%"
                                    display={{ base: 'none', md: 'flex' }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <BriefingAreasImage
                                        transition="all ease 300ms"
                                        width="400px"
                                        height="auto"
                                        briefingAreaSource={briefingAreaSource}
                                        sortedBriefingAreas={
                                            sortedBriefingAreas
                                        }
                                        toggleCheckbox={toggleCheckbox}
                                        selectedAreas={selectedAreas}
                                    />
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                            justifyContent="center"
                            zIndex="4"
                            bg="white"
                        >
                            <Stack
                                direction="row"
                                spacing={{
                                    base: '20px',
                                    md: '4',
                                }}
                                width="100%"
                                maxWidth={{ base: '100%', md: '400px' }}
                                align="center"
                            >
                                <Button
                                    fontSize={{ base: '0.9rem', md: '0.8rem' }}
                                    fontWeight="500"
                                    variant="outline"
                                    color="light.100"
                                    bg="#ffffff"
                                    size="sm"
                                    borderRadius="10px"
                                    width="100%"
                                    height={{
                                        base: '38px',
                                        md: '32px',
                                    }}
                                    onClick={() => {
                                        setSelectedAreas([])
                                        onCloseHandler()
                                    }}
                                    {...noFocus}
                                    _hover={{
                                        background: 'light.20',
                                    }}
                                    _active={{
                                        background: 'light.20',
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    fontSize={{ base: '0.9rem', md: '0.8rem' }}
                                    fontWeight="500"
                                    color="white"
                                    isDisabled={selectedAreas.length < 1}
                                    width="100%"
                                    height={{
                                        base: '38px',
                                        md: '32px',
                                    }}
                                    borderRadius="10px"
                                    background="light.200"
                                    _hover={{
                                        background: 'light.201',
                                    }}
                                    _active={{
                                        background: 'light.201',
                                    }}
                                    size="sm"
                                    onClick={() => {
                                        setBriefingStandard(true)
                                        setShowBriefingOnlySelection(false)
                                        onClose()
                                    }}
                                    {...noFocus}
                                >
                                    OK
                                </Button>
                            </Stack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
