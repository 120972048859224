import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Divider,
    HStack,
    Box,
    Link,
    useBreakpointValue,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../globalState'

import { ReactComponent as AeropathLogo } from '../settings-menu/assets/aeropath.svg'
import { ReactComponent as MetServiceLogo } from '../settings-menu/assets/metservice.svg'

export default function AboutModal() {
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'about', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.about ? onOpen() : onClose()
    }, [modalVisibility.about, onOpen, onClose])

    function onCloseHandler() {
        setShow(false)
    }

    const logoSize = useBreakpointValue({
        base: '140px',
        md: '200px',
    })

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={'faqs'}
                    size="3xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="light.200"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                            paddingInlineStart="15px"
                            paddingInlineEnd="15px"
                        >
                            About PreFlight
                        </ModalHeader>
                        <ModalBody
                            background="light.600"
                            style={{ padding: 0 }}
                            fontSize="0.9rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight="65vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt={12}
                                pb={12}
                                alignItems="baseline"
                                flexDirection="column"
                            >
                                <HStack
                                    spacing={{ base: 8, md: 16 }}
                                    width="100%"
                                    justifyContent="center"
                                >
                                    <Box
                                        opacity="0.8"
                                        _hover={{
                                            opacity: '1',
                                        }}
                                    >
                                        <AeropathLogo
                                            cursor="pointer"
                                            width={logoSize}
                                            height="auto"
                                            onClick={() =>
                                                window.open(
                                                    'https://aeropath.aero',
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </Box>
                                    <Box
                                        opacity="0.8"
                                        _hover={{
                                            opacity: '1',
                                        }}
                                    >
                                        <MetServiceLogo
                                            cursor="pointer"
                                            width={logoSize}
                                            height="auto"
                                            onClick={() =>
                                                window.open(
                                                    'https://metservice.com',
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </Box>
                                </HStack>

                                <Divider mt="30px" mb="30px" />

                                <Flex
                                    px="20px"
                                    width="100%"
                                    textAlign="center"
                                    flexDirection="column"
                                    color="gray.600"
                                    fontSize="0.9rem"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="15px"
                                >
                                    <Text>
                                        PreFlight, launched in September 2022,
                                        is a comprehensive platform developed in
                                        collaboration by{' '}
                                        <Link
                                            fontSize={{
                                                base: '0.9rem',
                                                md: '0.9rem',
                                            }}
                                            fontWeight="500"
                                            color="light.100"
                                            _hover={{
                                                cursor: 'pointer',
                                                opacity: '1',
                                                color: 'light.200',
                                            }}
                                            onClick={() =>
                                                window.open(
                                                    'https://aeropath.aero',
                                                    '_blank'
                                                )
                                            }
                                        >
                                            Aeropath Ltd
                                        </Link>{' '}
                                        (a subsidiary of Airways NZ) and{' '}
                                        <Link
                                            fontSize={{
                                                base: '0.9rem',
                                                md: '0.9rem',
                                            }}
                                            fontWeight="500"
                                            color="light.100"
                                            _hover={{
                                                cursor: 'pointer',
                                                opacity: '1',
                                                color: 'light.200',
                                            }}
                                            onClick={() =>
                                                window.open(
                                                    'https://metservice.com',
                                                    '_blank'
                                                )
                                            }
                                        >
                                            MetService
                                        </Link>{' '}
                                        to meet the critical needs of New
                                        Zealand's aviation sector.
                                    </Text>
                                    <Text>
                                        As a replacement for the former
                                        Metflight service, PreFlight
                                        consolidates essential weather and
                                        aeronautical information in a
                                        mobile-friendly, interactive format
                                        tailored for both commercial and
                                        recreational pilots.
                                    </Text>
                                    <Text>
                                        This platform reflects the collaborative
                                        efforts under New Zealand’s New Southern
                                        Sky initiative, aimed at creating a
                                        safer, more efficient aviation system
                                        through innovative technologies.
                                    </Text>
                                    <Text>
                                        This partnership empowers pilots to
                                        access vital information quickly and
                                        efficiently, enhancing safety and
                                        informed decision-making across the
                                        aviation community.
                                    </Text>
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
