import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AdvsIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M10.5998 7.49668C10.5458 6.6867 11.1882 6 12 6C12.8118 6 13.4542 6.6867 13.4002 7.49668L13.0665 12.5022C13.0291 13.0637 12.5627 13.5 12 13.5C11.4373 13.5 10.9709 13.0637 10.9335 12.5022L10.5998 7.49668Z"
                fill="currentColor"
            />
            <path
                d="M13 15.5C13 16.0523 12.5523 16.5 12 16.5C11.4477 16.5 11 16.0523 11 15.5C11 14.9477 11.4477 14.5 12 14.5C12.5523 14.5 13 14.9477 13 15.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5 23V18.9093C20.0601 18.4391 22 16.1961 22 13.5V8.5C22 5.46243 19.5376 3 16.5 3H7.5C4.46243 3 2 5.46243 2 8.5V13.5C2 16.5376 4.46243 19 7.5 19H9.1L17.5 23ZM9.43891 17.5H7.5C5.29086 17.5 3.5 15.7091 3.5 13.5V8.5C3.5 6.29086 5.29086 4.5 7.5 4.5H16.5C18.7091 4.5 20.5 6.29086 20.5 8.5V13.5C20.5 15.4592 19.09 17.0922 17.229 17.434L16 17.6597V20.6243L9.43891 17.5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
