import { ReactComponent as DroneIcon } from '../../../icons/assets/drone.svg'

import { timeRangeInAbrevWordsRpas } from '../../../util/dateFormatter'

export default function rpasToastDefinition(feat, now) {
    return {
        id: feat.properties.id,
        icon: <DroneIcon width="24px" height="24px" />,
        watermark: (
            <DroneIcon
                width="55px"
                height="55px"
                className="watermark"
                style={{ padding: '4px' }}
            />
        ),
        colour: '#ff0000',
        title: 'RPAS Flight',
        description: 'SFC - ' + feat?.properties?.altitude + ' AGL',

        traffic: feat?.properties?.traffic,
        status:
            feat?.properties?.start_time &&
            feat?.properties?.end_time &&
            timeRangeInAbrevWordsRpas(
                now,
                feat.properties.start_time,
                feat.properties.end_time
            ),
    }
}
