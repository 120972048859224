import { Container } from '@chakra-ui/react'
import { ItemDetail } from './ItemDetail'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import advsModeLayout from '../assets/advs-mode.png'
import advsModeLayoutNotam from '../assets/advs-mode-notam.png'
import advsModeLayoutTurbulence from '../assets/advs-mode-turbulence.png'
import advsModeLayoutAll from '../assets/advs-mode-all.png'
import advsModeLayoutThunderstorms from '../assets/advs-mode-thunderstorms.png'

export default function AdvsDetails() {
    return (
        <Container
            maxW="100%"
            zIndex="3"
            position="relative"
            paddingInlineStart="0"
            paddingInlineEnd="0"
        >
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Navigation]}
                // crossFade={true}
                className="testimonials"
            >
                <SwiperSlide>
                    <ItemDetail // ADVS ALL
                        title="Enhance your situational awareness"
                        subTitle="ADVS mode provides the best pre-flight situational awareness tool available for NZ airspace"
                        imageSrc={advsModeLayoutAll}
                        description="Zoom and navigate the map to your intended flight route to build a detailed picture of the operational hazards and significant weather that may affect you"
                        footer=""
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <ItemDetail // ADVS NOTAMs
                        title="View En-route NOTAMs graphically on the map"
                        subTitle="Specify altitude changes and look ahead up to the next 48 hours for your pre-flight planning"
                        imageSrc={advsModeLayoutNotam}
                        description=""
                        footer="En-route NOTAMS are still otherwise available in BRIEF mode as standard text"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <ItemDetail // ADVS SIGMET and SIGWX
                        title="View SIGMET and SIGWX graphically alongside other dynamic information"
                        subTitle="Build a detailed picture of the significant weather that may affect your flight"
                        imageSrc={advsModeLayout}
                        description=""
                        footer="SIGMET and SIGWX are still otherwise available on static image charts in MET and BRIEF modes"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <ItemDetail // ADVS SIGMET and SIGWX
                        title="Instantly see what may concern you"
                        subTitle="Select an item from the list to zoom to its location on the map, and to view more details"
                        imageSrc={advsModeLayoutThunderstorms}
                        description=""
                        footer="SIGMET and SIGWX are still otherwise available on static image charts in MET and BRIEF modes"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <ItemDetail // ADVS SIGMET and SIGWX
                        title="User-friendly information"
                        subTitle="Key information is presented in an enhanced and easy to read format"
                        imageSrc={advsModeLayoutTurbulence}
                        description=""
                        footer="SIGMET and SIGWX are still otherwise available on static image charts in MET and BRIEF modes"
                    />
                </SwiperSlide>
            </Swiper>
        </Container>
    )
}
