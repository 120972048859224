import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useInView } from 'react-intersection-observer'
import {
    Flex,
    Box,
    Text,
    Button,
    Badge,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    AbsoluteCenter,
    useBreakpointValue,
    Tooltip,
    Image,
} from '@chakra-ui/react'
import { isMobile, isDesktop } from 'react-device-detect'
import formatDateTime from '../../../../../util/dateFormatter'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import { parseISO, formatDistanceToNow, addHours } from 'date-fns'
import ErrorText from '../../../components/ErrorText'
import useConfirm from '../../../alerts/warning-message/components/useConfirm'
import { AltExcludedAerodromesWarningMessage } from '../../../alerts/warning-message/components/WarningMessages'
import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../../../util/notamDecode'
import {
    excludedAerodromesState,
    inViewPositionState,
    aerodromesSortState,
    timeZoneState,
    showOutdatedErrorsState,
    selectedBriefingTimeState,
    collapseDropdownsState,
    firstCloseState,
} from '../../../../../globalState'

import CloseIcon from '../../../../../icons/menu-icons/CloseIcon'

// import { ReactComponent as AerodromeIcon } from '../../../../../icons/assets/aerodrome.svg'

import { ReactComponent as NotamIcon } from '../../../../../icons/assets/notam-outline.svg'

import notam from '../../../assets/notam.svg'
import notamDrone from '../../../assets/notam-drone.svg'
import notamObstacle from '../../../assets/notam-obstacle.svg'
import notamAerodrome from '../../../assets/notam-aerodrome.svg'
import notamWarning from '../../../assets/notam-warning.svg'
import notamComms from '../../../assets/notam-comms.svg'

export default function NOTAMBriefing(props) {
    const { briefingData } = props

    const aerodromesSort = useRecoilValue(aerodromesSortState)
    const [excludedAerodromes, setExcludedAerodromes] = useRecoilState(
        excludedAerodromesState
    )
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const [firstClose, setFirstClose] = useRecoilState(firstCloseState)
    const { isConfirmed } = useConfirm() // alert message handler
    const timeZone = useRecoilValue(timeZoneState)
    // const timeZoneSelection =
    //     timeZone === 'UTC'
    //         ? timeZone
    //         : timeZoneAbbreviation(
    //     new Date(),
    //     Intl.DateTimeFormat().resolvedOptions()
    //         .timeZone
    // )

    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const selectedHours = useRecoilValue(selectedBriefingTimeState)
    const issuedTime = briefingData && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)

    // FONT SIZE ADJUSTMENTS
    const {
        calculateFontSize,
        calculateContainerSize,
        calculateLabelSize,
        calculateBadgeSize,
        calculateBadgeWidth,
    } = useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })

    const filteredNotam =
        briefingData &&
        briefingData.res.aerodromes &&
        briefingData.res.aerodromes.filter((filter) => filter.notam !== null)
    // const aerodromeNotam = briefingData && briefingData.res.aerodromes /// replace filteredNotam above with this if wanting to list aerodromes with no NOTAMs

    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : filteredNotam?.map((value, i) => i)
    )

    useEffect(() => {
        setExpandedDropdowns(
            collapseDropdowns ? [] : filteredNotam?.map((value, i) => i)
        )
        // eslint-disable-next-line
    }, [collapseDropdowns])

    //PORTAL REFERENCES
    // const mobile = useRef()
    const desktop = useRef()

    // const notamNotes = useBreakpointValue({
    //     base: { containerRef: mobile },
    //     lg: { containerRef: desktop },
    // })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        inView && setInViewPosition('notamContainer')
    }, [inView, setInViewPosition])

    function notamIconFormatter(code23) {
        return ['WU', 'WZ'].includes(code23)
            ? notamDrone
            : ['OB', 'OL'].includes(code23)
            ? notamObstacle
            : code23 === 'FA'
            ? notamAerodrome
            : ['W', 'R'].includes(code23.charAt(0))
            ? notamWarning
            : ['C', 'N'].includes(code23.charAt(0))
            ? notamComms
            : notam
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <div
                id="notamContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            <Box>
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', lg: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    marginTop="25px"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#07578A"
                        backgroundImage="linear-gradient(345deg, #3B9EDC 0%, #004D7F 80%)"
                        filter={{
                            base: isMobile && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="44px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="space-between"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Flex
                                my="auto"
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex marginLeft="2px" alignItems="center">
                                    <NotamIcon
                                        width="26px"
                                        height="26px"
                                        color="white"
                                    />
                                </Flex>
                                <Text
                                    paddingLeft="13px"
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    NOTAM
                                </Text>
                            </Flex>
                            <Box ml="auto" ref={desktop}></Box>
                        </Flex>
                    </Flex>

                    <Accordion
                        border="none"
                        allowToggle
                        allowMultiple
                        index={expandedDropdowns}
                        onChange={(expandedIndex) => {
                            setExpandedDropdowns(expandedIndex)
                        }}
                    >
                        {filteredNotam &&
                            filteredNotam?.length > 0 &&
                            filteredNotam
                                .sort((a, b) =>
                                    aerodromesSort
                                        ? a.name > b.name
                                        : a.lat < b.lat
                                        ? 1
                                        : -1
                                )
                                .map((aerodrome, index, arr) => {
                                    if (
                                        excludedAerodromes.includes(
                                            aerodrome.designator
                                        )
                                    ) {
                                        return null
                                    } else {
                                        return (
                                            <Flex
                                                width="100%"
                                                key={`AerodromesNotam${index}`}
                                            >
                                                <AccordionItem
                                                    width="100%"
                                                    borderStyle="none"
                                                    index="1"
                                                    zIndex={
                                                        arr.length + 1 - index
                                                    }
                                                    position="relative"
                                                >
                                                    {({ isExpanded }) => (
                                                        <>
                                                            <Flex
                                                                boxShadow={
                                                                    aerodrome.notam &&
                                                                    isExpanded &&
                                                                    '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                                }
                                                            >
                                                                <AccordionButton
                                                                    zIndex="2"
                                                                    height={{
                                                                        base: '50px',
                                                                        lg: '46px',
                                                                    }}
                                                                    minHeight={{
                                                                        base: '50px',
                                                                        lg: '46px',
                                                                    }}
                                                                    sx={{
                                                                        clipPath:
                                                                            'inset(0px 0px -50px 0px)',
                                                                    }}
                                                                    pl="1.2rem"
                                                                    pr={{
                                                                        base: 1,
                                                                        sm: 2,
                                                                        md: 1,
                                                                        ml: 2,
                                                                    }}
                                                                    _hover={{
                                                                        background:
                                                                            isExpanded &&
                                                                            aerodrome.notam
                                                                                ? 'light.90'
                                                                                : aerodrome.notam
                                                                                ? 'light.30'
                                                                                : 'transparent',
                                                                        cursor: aerodrome.notam
                                                                            ? 'pointer'
                                                                            : 'initial',
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            isExpanded &&
                                                                            aerodrome.notam
                                                                                ? 'gray.100'
                                                                                : 'transparent',
                                                                        cursor: aerodrome.notam
                                                                            ? 'pointer'
                                                                            : 'initial',
                                                                    }}
                                                                    color={
                                                                        aerodrome.notam
                                                                            ? '#1778b7'
                                                                            : 'gray.600'
                                                                    }
                                                                    background={
                                                                        isExpanded &&
                                                                        aerodrome.notam
                                                                            ? 'light.25'
                                                                            : 'white'
                                                                    }
                                                                    borderTop={
                                                                        '1px solid'
                                                                    }
                                                                    borderColor="#efefef"
                                                                    style={{
                                                                        borderBottomRadius:
                                                                            isExpanded &&
                                                                            aerodrome.notam
                                                                                ? '0px'
                                                                                : '20px',
                                                                        transition:
                                                                            'boxShadow all 800ms',
                                                                    }}
                                                                >
                                                                    <Flex
                                                                        width="100%"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        opacity={
                                                                            aerodrome.notam
                                                                                ? '1'
                                                                                : '0.4'
                                                                        }
                                                                    >
                                                                        <Flex
                                                                            flex="2"
                                                                            textAlign="left"
                                                                            paddingLeft="8px"
                                                                            whiteSpace="pre"
                                                                            justifyContent="space-between"
                                                                            alignItems={{
                                                                                base: 'center',
                                                                                smd: aerodrome.notam
                                                                                    ? 'center'
                                                                                    : 'flex-end',
                                                                                md: 'center',
                                                                                ml: aerodrome.notam
                                                                                    ? 'center'
                                                                                    : 'flex-end',
                                                                            }}
                                                                            width="100%"
                                                                            pr="10px"
                                                                        >
                                                                            <Flex>
                                                                                <Flex
                                                                                    flexDirection="row"
                                                                                    justifyContent="flex-start"
                                                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                                    textAlign="center"
                                                                                    alignItems="center"
                                                                                    minWidth="35px"
                                                                                >
                                                                                    {aerodrome.notam &&
                                                                                    aerodrome.notam.filter(
                                                                                        (
                                                                                            notam
                                                                                        ) =>
                                                                                            parseISO(
                                                                                                notam.startvalidity
                                                                                            ) <=
                                                                                            periodTo
                                                                                    )
                                                                                        .length ? (
                                                                                        <Badge
                                                                                            display="flex"
                                                                                            justifyContent="center"
                                                                                            alignItems="center"
                                                                                            minWidth={calculateBadgeSize(
                                                                                                18
                                                                                            )}
                                                                                            minHeight={calculateBadgeSize(
                                                                                                18
                                                                                            )}
                                                                                            fontFamily="'Open Sans', sans-serif"
                                                                                            variant="solid"
                                                                                            bg={
                                                                                                isExpanded
                                                                                                    ? 'light.10'
                                                                                                    : 'warning.100'
                                                                                            }
                                                                                            color={
                                                                                                isExpanded
                                                                                                    ? 'warning.100'
                                                                                                    : 'light.10'
                                                                                            }
                                                                                            border="1px solid"
                                                                                            borderColor="warning.100"
                                                                                            transition="all ease 250ms"
                                                                                            borderRadius="50px"
                                                                                            px="5px"
                                                                                            py="0px"
                                                                                            fontSize={calculateFontSize(
                                                                                                0.65
                                                                                            )}
                                                                                        >
                                                                                            {
                                                                                                aerodrome.notam.filter(
                                                                                                    (
                                                                                                        notam
                                                                                                    ) =>
                                                                                                        parseISO(
                                                                                                            notam.startvalidity
                                                                                                        ) <=
                                                                                                        periodTo
                                                                                                )
                                                                                                    .length
                                                                                            }
                                                                                        </Badge>
                                                                                    ) : (
                                                                                        <Box
                                                                                            {...noFocus}
                                                                                            pr={
                                                                                                5
                                                                                            }
                                                                                            fontSize="0.75rem"
                                                                                            lineHeight="1.2"
                                                                                            fontWeight={{
                                                                                                base: '300',
                                                                                                smd: '400',
                                                                                                md: '300',
                                                                                                ml: '400',
                                                                                            }}
                                                                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                                            flex="3"
                                                                                            textAlign={{
                                                                                                base: 'right',
                                                                                                smd: 'left',
                                                                                                md: 'right',
                                                                                                ml: 'left',
                                                                                            }}
                                                                                            whiteSpace="pre-line"
                                                                                            position={{
                                                                                                base: 'absolute',
                                                                                                smd: 'relative',
                                                                                                md: 'absolute',
                                                                                                ml: 'relative',
                                                                                            }}
                                                                                            mt={{
                                                                                                base: '2px',
                                                                                                smd: '0',
                                                                                                md: '2px',
                                                                                                ml: '0',
                                                                                            }}
                                                                                            right={{
                                                                                                base: '65px',
                                                                                                smd: 'unset',
                                                                                                md: '71px',
                                                                                                ml: 'unset',
                                                                                            }}
                                                                                            opacity={{
                                                                                                base: '0.8',
                                                                                                smd: '1',
                                                                                                md: '0.8',
                                                                                                ml: '1',
                                                                                            }}
                                                                                        >
                                                                                            No
                                                                                            current
                                                                                            NOTAM
                                                                                            issued
                                                                                        </Box>
                                                                                    )}
                                                                                </Flex>
                                                                                <Text
                                                                                    paddingRight="2"
                                                                                    minWidth={{
                                                                                        base: '44px',
                                                                                        sm: '48px',
                                                                                        lg: '52px',
                                                                                    }}
                                                                                    fontSize={
                                                                                        adjustLabelFontSize
                                                                                    }
                                                                                    lineHeight="1.2"
                                                                                    fontWeight={
                                                                                        isExpanded
                                                                                            ? '600'
                                                                                            : '500'
                                                                                    }
                                                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                                    whiteSpace="pre-line"
                                                                                >
                                                                                    {
                                                                                        aerodrome.name
                                                                                    }
                                                                                </Text>
                                                                            </Flex>

                                                                            <Text
                                                                                fontSize={
                                                                                    adjustLabelFontSize
                                                                                }
                                                                                lineHeight="1.2"
                                                                                fontWeight="400"
                                                                                opacity={
                                                                                    aerodrome.notam
                                                                                        ? '1'
                                                                                        : '0.8'
                                                                                }
                                                                                pr={
                                                                                    aerodrome.notam
                                                                                        ? '0'
                                                                                        : '20px'
                                                                                }
                                                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                                whiteSpace="pre-line"
                                                                                textAlign="right"
                                                                                pb={{
                                                                                    base: aerodrome.notam
                                                                                        ? '0px'
                                                                                        : '17px',
                                                                                    smd: '0',
                                                                                    md: aerodrome.notam
                                                                                        ? '0px'
                                                                                        : '17px',
                                                                                    ml: '0',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    aerodrome.designator
                                                                                }
                                                                            </Text>
                                                                        </Flex>

                                                                        {aerodrome.notam && (
                                                                            <Flex>
                                                                                {isExpanded ? (
                                                                                    <AccordionIcon transform="rotate(0deg)" />
                                                                                ) : (
                                                                                    <AccordionIcon transform="rotate(-90deg)" />
                                                                                )}
                                                                            </Flex>
                                                                        )}
                                                                    </Flex>
                                                                </AccordionButton>

                                                                <Tooltip
                                                                    isDisabled={
                                                                        isMobile ||
                                                                        !isDesktop
                                                                    }
                                                                    label={`Remove ${aerodrome.name} from the briefing`}
                                                                    hasArrow
                                                                    borderRadius="7px"
                                                                    height="25px"
                                                                    fontSize="0.7rem"
                                                                    alignItems="center"
                                                                    display="flex"
                                                                    placement="left"
                                                                    m="0px"
                                                                    color="light.10"
                                                                    bgColor={
                                                                        'gray.500'
                                                                    }
                                                                >
                                                                    <Button
                                                                        zIndex="1"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        clipPath="inset(0px 0px -50px 0px)"
                                                                        color={
                                                                            aerodrome.notam
                                                                                ? 'gray.300'
                                                                                : 'gray.400'
                                                                        }
                                                                        background={
                                                                            isExpanded &&
                                                                            aerodrome.notam
                                                                                ? 'light.25'
                                                                                : 'white'
                                                                        }
                                                                        variant="unstyled"
                                                                        fontSize="11px"
                                                                        fontWeight="600"
                                                                        height={{
                                                                            base: '50px',
                                                                            lg: '46px',
                                                                        }}
                                                                        width={{
                                                                            base: '60px',
                                                                            lg: '64px',
                                                                        }}
                                                                        borderTop={
                                                                            '1px solid'
                                                                        }
                                                                        borderColor="#efefef"
                                                                        py="2px"
                                                                        borderRadius="0px"
                                                                        {...noFocus}
                                                                        onClick={async () => {
                                                                            if (
                                                                                firstClose
                                                                            ) {
                                                                                const acknowledgeExcludedAerodromes =
                                                                                    await isConfirmed(
                                                                                        // "Excluded aerodromes?"
                                                                                        'confirm-ok',
                                                                                        <AltExcludedAerodromesWarningMessage />
                                                                                    )
                                                                                if (
                                                                                    acknowledgeExcludedAerodromes
                                                                                ) {
                                                                                    setExcludedAerodromes(
                                                                                        (
                                                                                            prevState
                                                                                        ) =>
                                                                                            prevState.concat(
                                                                                                aerodrome.designator
                                                                                            )
                                                                                    )
                                                                                    setFirstClose(
                                                                                        false
                                                                                    )
                                                                                }
                                                                            } else {
                                                                                setExcludedAerodromes(
                                                                                    (
                                                                                        prevState
                                                                                    ) =>
                                                                                        prevState.concat(
                                                                                            aerodrome.designator
                                                                                        )
                                                                                )
                                                                            }
                                                                        }}
                                                                        _active={{
                                                                            background:
                                                                                aerodrome.notam &&
                                                                                'gray.100',
                                                                        }}
                                                                        _focus={{
                                                                            background:
                                                                                'light.30',
                                                                        }}
                                                                        _hover={{
                                                                            color: 'gray.500',
                                                                            background:
                                                                                isExpanded &&
                                                                                aerodrome.notam
                                                                                    ? 'light.90'
                                                                                    : 'light.30',
                                                                            opacity:
                                                                                '1',
                                                                        }}
                                                                    >
                                                                        <Flex
                                                                            width="100%"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                            opacity={
                                                                                aerodrome.notam
                                                                                    ? '1'
                                                                                    : '0.35'
                                                                            }
                                                                        >
                                                                            <CloseIcon boxSize="20px" />
                                                                        </Flex>
                                                                    </Button>
                                                                </Tooltip>
                                                            </Flex>

                                                            {aerodrome.notam && (
                                                                <AccordionPanel
                                                                    pt={0}
                                                                    pb={6}
                                                                    pl="1.5rem"
                                                                    pr="1.5rem"
                                                                    boxShadow={
                                                                        index +
                                                                            1 ===
                                                                        filteredNotam.length
                                                                            ? 'none'
                                                                            : 'inset 0px -5px 15px 0px rgba(0,0,0,0.04)'
                                                                    }
                                                                >
                                                                    <Flex
                                                                        fontFamily="Open Sans"
                                                                        paddingTop="3"
                                                                        flexDirection="column"
                                                                        color="light.100"
                                                                        pt={{
                                                                            base: '10px',
                                                                            xs: '4',
                                                                        }}
                                                                    >
                                                                        {aerodrome.notam &&
                                                                            aerodrome
                                                                                .notam
                                                                                .length >
                                                                                0 &&
                                                                            aerodrome.notam.map(
                                                                                (
                                                                                    notam,
                                                                                    i
                                                                                ) => {
                                                                                    const code23 =
                                                                                        code23Decode(
                                                                                            notam.code23
                                                                                        )
                                                                                    const code45 =
                                                                                        code45Decode(
                                                                                            notam.code45
                                                                                        )
                                                                                    const traffic =
                                                                                        trafficDecode(
                                                                                            notam.traffic
                                                                                        )
                                                                                    if (
                                                                                        parseISO(
                                                                                            notam.startvalidity
                                                                                        ) <=
                                                                                        periodTo
                                                                                    ) {
                                                                                        return (
                                                                                            <Box
                                                                                                key={`AerodromeBriefingNotam${notam.series}${notam.number}/${notam.year}`}
                                                                                                w="100%"
                                                                                            >
                                                                                                {(showOutdatedErrors ||
                                                                                                    notam.outofdate) && (
                                                                                                    <Flex
                                                                                                        pb="10px"
                                                                                                        ml="-8px"
                                                                                                        justifyContent="center"
                                                                                                    >
                                                                                                        <ErrorText>
                                                                                                            There
                                                                                                            has
                                                                                                            been
                                                                                                            an
                                                                                                            issue
                                                                                                            with
                                                                                                            the
                                                                                                            connection
                                                                                                            between
                                                                                                            PreFlight
                                                                                                            and
                                                                                                            Airways.
                                                                                                            This{' '}
                                                                                                            <strong>
                                                                                                                NOTAM
                                                                                                            </strong>{' '}
                                                                                                            was
                                                                                                            last
                                                                                                            validated
                                                                                                            with
                                                                                                            Airways
                                                                                                            more
                                                                                                            than{' '}
                                                                                                            <strong>
                                                                                                                {formatDistanceToNow(
                                                                                                                    parseISO(
                                                                                                                        notam.updated
                                                                                                                    ),
                                                                                                                    {
                                                                                                                        addSuffix: true,
                                                                                                                    }
                                                                                                                )}
                                                                                                            </strong>
                                                                                                            .
                                                                                                            <br />
                                                                                                            There
                                                                                                            is
                                                                                                            a
                                                                                                            high
                                                                                                            likelihood
                                                                                                            that
                                                                                                            the
                                                                                                            latest
                                                                                                            NOTAM
                                                                                                            information
                                                                                                            is
                                                                                                            not
                                                                                                            available
                                                                                                            in
                                                                                                            PreFlight.
                                                                                                            Check
                                                                                                            back
                                                                                                            again
                                                                                                            soon,
                                                                                                            or
                                                                                                            alternatively
                                                                                                            visit{' '}
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    fontWeight:
                                                                                                                        '500',
                                                                                                                    textDecoration:
                                                                                                                        'underline',
                                                                                                                }}
                                                                                                                href="https://ifis.airways.co.nz/"
                                                                                                            >
                                                                                                                IFIS
                                                                                                            </a>

                                                                                                            .
                                                                                                        </ErrorText>
                                                                                                    </Flex>
                                                                                                )}
                                                                                                <Flex>
                                                                                                    {/* ICON */}
                                                                                                    <Box
                                                                                                        minWidth="24px"
                                                                                                        mt="1px"
                                                                                                        mr="14px"
                                                                                                    >
                                                                                                        <Image
                                                                                                            boxSize="24px"
                                                                                                            src={notamIconFormatter(
                                                                                                                notam.code23
                                                                                                            )}
                                                                                                            alt="!"
                                                                                                        />
                                                                                                    </Box>
                                                                                                    <Box width="100%">
                                                                                                        <Flex
                                                                                                            paddingStart="0"
                                                                                                            paddingTop="0.2rem"
                                                                                                            paddingBottom="0.2rem"
                                                                                                            flexDirection="column"
                                                                                                            width="auto"
                                                                                                            minWidth="90px"
                                                                                                        >
                                                                                                            <Text
                                                                                                                fontWeight="bold"
                                                                                                                fontSize={calculateFontSize(
                                                                                                                    0.8
                                                                                                                )}
                                                                                                            >
                                                                                                                {
                                                                                                                    code23
                                                                                                                }
                                                                                                            </Text>

                                                                                                            <Text
                                                                                                                fontWeight="normal"
                                                                                                                paddingBottom="5px"
                                                                                                                fontSize={calculateFontSize(
                                                                                                                    0.8
                                                                                                                )}
                                                                                                            >
                                                                                                                {
                                                                                                                    code45
                                                                                                                }
                                                                                                            </Text>
                                                                                                        </Flex>

                                                                                                        <Flex
                                                                                                            display="flex"
                                                                                                            flexDirection={{
                                                                                                                base: 'column',
                                                                                                                xl: 'row',
                                                                                                            }}
                                                                                                            width="100%"
                                                                                                        >
                                                                                                            <Flex
                                                                                                                display="flex"
                                                                                                                flexDirection="column"
                                                                                                                width={{
                                                                                                                    base: '100%',
                                                                                                                    xl: '50%',
                                                                                                                }}
                                                                                                                maxWidth={{
                                                                                                                    base: '100%',
                                                                                                                    xl: calculateContainerSize(
                                                                                                                        260
                                                                                                                    ),
                                                                                                                }}
                                                                                                            >
                                                                                                                <Flex
                                                                                                                    width="100%"
                                                                                                                    display="flex"
                                                                                                                    flexDirection="row"
                                                                                                                    paddingBottom="5px"
                                                                                                                >
                                                                                                                    <Flex
                                                                                                                        paddingStart="0"
                                                                                                                        fontWeight="bold"
                                                                                                                        width="auto"
                                                                                                                        minWidth={calculateLabelSize(
                                                                                                                            75
                                                                                                                        )}
                                                                                                                        fontSize={calculateFontSize(
                                                                                                                            0.8
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        NOTAM
                                                                                                                        #:
                                                                                                                    </Flex>
                                                                                                                    <Flex
                                                                                                                        width="100%"
                                                                                                                        fontSize={calculateFontSize(
                                                                                                                            0.8
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {`${notam.series}${notam.number}/${notam.year}`}
                                                                                                                    </Flex>
                                                                                                                </Flex>
                                                                                                                <Flex
                                                                                                                    display="flex"
                                                                                                                    flexDirection="row"
                                                                                                                    width="100%"
                                                                                                                    paddingBottom="5px"
                                                                                                                >
                                                                                                                    <Flex
                                                                                                                        paddingStart="0"
                                                                                                                        fontWeight="bold"
                                                                                                                        width="auto"
                                                                                                                        minWidth={calculateLabelSize(
                                                                                                                            75
                                                                                                                        )}
                                                                                                                        fontSize={calculateFontSize(
                                                                                                                            0.8
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        Affects:
                                                                                                                    </Flex>
                                                                                                                    <Flex alignItems="baseline">
                                                                                                                        {traffic.ifr && (
                                                                                                                            <Badge
                                                                                                                                variant="solid"
                                                                                                                                background="ifr.100"
                                                                                                                                color="white"
                                                                                                                                paddingStart="2"
                                                                                                                                paddingEnd="2"
                                                                                                                                paddingTop="2px"
                                                                                                                                paddingBottom="2px"
                                                                                                                                borderRadius="20px"
                                                                                                                                marginTop="2px"
                                                                                                                                marginRight="5px"
                                                                                                                                minWidth={calculateBadgeWidth(
                                                                                                                                    40
                                                                                                                                )}
                                                                                                                                textAlign="center"
                                                                                                                                fontSize={calculateFontSize(
                                                                                                                                    0.65
                                                                                                                                )}
                                                                                                                                height={calculateBadgeSize(
                                                                                                                                    18
                                                                                                                                )}
                                                                                                                                justifyContent="center"
                                                                                                                                alignItems="center"
                                                                                                                                display="flex"
                                                                                                                            >
                                                                                                                                IFR
                                                                                                                            </Badge>
                                                                                                                        )}
                                                                                                                        {traffic.ifr &&
                                                                                                                        traffic.vfr
                                                                                                                            ? ' '
                                                                                                                            : ''}
                                                                                                                        {traffic.vfr && (
                                                                                                                            <Badge
                                                                                                                                variant="solid"
                                                                                                                                background="vfr.100"
                                                                                                                                color="white"
                                                                                                                                paddingStart="2"
                                                                                                                                paddingEnd="2"
                                                                                                                                paddingTop="2px"
                                                                                                                                paddingBottom="2px"
                                                                                                                                borderRadius="20px"
                                                                                                                                marginTop="2px"
                                                                                                                                marginRight="5px"
                                                                                                                                minWidth={calculateBadgeWidth(
                                                                                                                                    40
                                                                                                                                )}
                                                                                                                                textAlign="center"
                                                                                                                                fontSize={calculateFontSize(
                                                                                                                                    0.65
                                                                                                                                )}
                                                                                                                                height={calculateBadgeSize(
                                                                                                                                    18
                                                                                                                                )}
                                                                                                                                justifyContent="center"
                                                                                                                                alignItems="center"
                                                                                                                                display="flex"
                                                                                                                            >
                                                                                                                                VFR
                                                                                                                            </Badge>
                                                                                                                        )}
                                                                                                                    </Flex>
                                                                                                                    <Text
                                                                                                                        paddingLeft="1px"
                                                                                                                        fontSize={calculateFontSize(
                                                                                                                            0.8
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        TRAFFIC
                                                                                                                    </Text>
                                                                                                                </Flex>
                                                                                                                {/*  <Flex
                                                                                                                        display="flex"
                                                                                                                        flexDirection="row"
                                                                                                                        width="100%"   paddingBottom='2px'
                                                                                                                    > /// REMOVED FOR NOW - REQUIRES FURTHER THOUGHT
                                                                                                                        <Flex
                                                                                                                            paddingStart="0"
                                                                                                                            fontWeight="bold"
                                                                                                                            width="auto"
                                                                                                                            minWidth={
                                                                                                                             calculateLabelSize(70)
                                                                                                                        }
                                                                                                                                    fontSize={
                                                                                                                                         calculateFontSize(0.8)
                                                                                                                                    }
                                                                                                                        >
                                                                                                                            Status:
                                                                                                                        </Flex>
                                                                                                                        <Flex
                                                                                                                            width="100%"
                                                                                                                                    fontSize={
                                                                                                                                         calculateFontSize(0.8)
                                                                                                                                    }
                                                                                                                        >
                                                                                                                            {timeRangeInWords(
                                                                                                                                now,
                                                                                                                                notam.startvalidity,
                                                                                                                                notam.endvalidity
                                                                                                                            )}
                                                                                                                        </Flex>
                                                                                                                    </Flex> */}
                                                                                                                {notam.itemf && (
                                                                                                                    <Flex
                                                                                                                        display="flex"
                                                                                                                        flexDirection="row"
                                                                                                                        width="100%"
                                                                                                                        paddingBottom="5px"
                                                                                                                    >
                                                                                                                        <Flex
                                                                                                                            paddingStart="0"
                                                                                                                            fontWeight="bold"
                                                                                                                            width="auto"
                                                                                                                            minWidth={calculateLabelSize(
                                                                                                                                75
                                                                                                                            )}
                                                                                                                            fontSize={calculateFontSize(
                                                                                                                                0.8
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            Altitude:
                                                                                                                        </Flex>
                                                                                                                        <Flex
                                                                                                                            width="100%"
                                                                                                                            fontSize={calculateFontSize(
                                                                                                                                0.8
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            {notam.lowerlimit +
                                                                                                                                ' – ' +
                                                                                                                                notam.upperlimit}
                                                                                                                        </Flex>
                                                                                                                    </Flex>
                                                                                                                )}
                                                                                                            </Flex>
                                                                                                            <Flex
                                                                                                                display="flex"
                                                                                                                flexDirection="column"
                                                                                                                width={{
                                                                                                                    base: '100%',
                                                                                                                    xl: '100%',
                                                                                                                }}
                                                                                                                maxWidth={{
                                                                                                                    base: '100%',
                                                                                                                    xl: '100%',
                                                                                                                }}
                                                                                                            >
                                                                                                                <Flex
                                                                                                                    display="flex"
                                                                                                                    flexDirection="row"
                                                                                                                    width="100%"
                                                                                                                    paddingBottom="5px"
                                                                                                                >
                                                                                                                    <Flex
                                                                                                                        paddingStart="0"
                                                                                                                        fontWeight="bold"
                                                                                                                        width="auto"
                                                                                                                        minWidth={{
                                                                                                                            base: calculateLabelSize(
                                                                                                                                75
                                                                                                                            ),
                                                                                                                            xl: calculateLabelSize(
                                                                                                                                55
                                                                                                                            ),
                                                                                                                        }}
                                                                                                                        fontSize={calculateFontSize(
                                                                                                                            0.8
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        From:
                                                                                                                    </Flex>
                                                                                                                    <Flex
                                                                                                                        width="100%"
                                                                                                                        fontSize={calculateFontSize(
                                                                                                                            0.8
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {notam?.startvalidity &&
                                                                                                                            formatDateTime(
                                                                                                                                notam.startvalidity,
                                                                                                                                timeZone
                                                                                                                            )}
                                                                                                                    </Flex>
                                                                                                                </Flex>

                                                                                                                {!notam.permanent && (
                                                                                                                    <Flex
                                                                                                                        display="flex"
                                                                                                                        flexDirection="row"
                                                                                                                        width="100%"
                                                                                                                        paddingBottom="5px"
                                                                                                                    >
                                                                                                                        <Flex
                                                                                                                            paddingStart="0"
                                                                                                                            fontWeight="bold"
                                                                                                                            width="auto"
                                                                                                                            minWidth={{
                                                                                                                                base: calculateLabelSize(
                                                                                                                                    75
                                                                                                                                ),
                                                                                                                                xl: calculateLabelSize(
                                                                                                                                    55
                                                                                                                                ),
                                                                                                                            }}
                                                                                                                            fontSize={calculateFontSize(
                                                                                                                                0.8
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            To:
                                                                                                                        </Flex>
                                                                                                                        <Flex
                                                                                                                            width="100%"
                                                                                                                            fontSize={calculateFontSize(
                                                                                                                                0.8
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            {notam?.endvalidity &&
                                                                                                                                formatDateTime(
                                                                                                                                    notam.endvalidity,
                                                                                                                                    timeZone
                                                                                                                                )}
                                                                                                                            {notam?.estimation &&
                                                                                                                                ' (Estimated)'}
                                                                                                                        </Flex>
                                                                                                                    </Flex>
                                                                                                                )}
                                                                                                                {notam.itemd &&
                                                                                                                    notam.itemd !==
                                                                                                                        'null' && (
                                                                                                                        <Flex
                                                                                                                            display="flex"
                                                                                                                            flexDirection="row"
                                                                                                                            width="100%"
                                                                                                                            paddingBottom="5px"
                                                                                                                        >
                                                                                                                            <Flex
                                                                                                                                paddingStart="0"
                                                                                                                                fontWeight="bold"
                                                                                                                                width="auto"
                                                                                                                                minWidth={{
                                                                                                                                    base: calculateLabelSize(
                                                                                                                                        75
                                                                                                                                    ),
                                                                                                                                    xl: calculateLabelSize(
                                                                                                                                        55
                                                                                                                                    ),
                                                                                                                                }}
                                                                                                                                fontSize={calculateFontSize(
                                                                                                                                    0.8
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                Period:
                                                                                                                            </Flex>
                                                                                                                            <Flex
                                                                                                                                width="100%"
                                                                                                                                fontSize={calculateFontSize(
                                                                                                                                    0.8
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                {notam.itemd +
                                                                                                                                    ' (UTC if time shown)'}
                                                                                                                            </Flex>
                                                                                                                        </Flex>
                                                                                                                    )}
                                                                                                            </Flex>
                                                                                                        </Flex>

                                                                                                        <Text
                                                                                                            marginTop={
                                                                                                                1
                                                                                                            }
                                                                                                            marginBottom={
                                                                                                                3
                                                                                                            }
                                                                                                            whiteSpace="pre-line"
                                                                                                            fontSize={calculateFontSize(
                                                                                                                0.8
                                                                                                            )}
                                                                                                        >
                                                                                                            {
                                                                                                                notam.iteme
                                                                                                            }
                                                                                                        </Text>
                                                                                                        {i +
                                                                                                            1 !==
                                                                                                            aerodrome
                                                                                                                .notam
                                                                                                                .length && (
                                                                                                            <Box position="relative">
                                                                                                                <Divider
                                                                                                                    variant="modalFooter"
                                                                                                                    marginTop={
                                                                                                                        7
                                                                                                                    }
                                                                                                                    marginBottom={
                                                                                                                        5
                                                                                                                    }
                                                                                                                />
                                                                                                                <AbsoluteCenter
                                                                                                                    bg="white"
                                                                                                                    px="2"
                                                                                                                    pb="1px"
                                                                                                                    left="unset"
                                                                                                                    right="0%"
                                                                                                                    transform="translate(0%, -50%)"
                                                                                                                    fontSize="0.75rem"
                                                                                                                    color="gray.300"
                                                                                                                    opacity="0.8"
                                                                                                                    fontWeight="300"
                                                                                                                >
                                                                                                                    {
                                                                                                                        aerodrome?.designator
                                                                                                                    }
                                                                                                                </AbsoluteCenter>
                                                                                                            </Box>
                                                                                                        )}
                                                                                                    </Box>
                                                                                                </Flex>
                                                                                            </Box>
                                                                                        )
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                }
                                                                            )}
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            )}
                                                        </>
                                                    )}
                                                </AccordionItem>
                                            </Flex>
                                        )
                                    }
                                })}
                    </Accordion>
                    {filteredNotam.length > 0 &&
                        filteredNotam.filter(
                            (aerodrome) =>
                                !(
                                    excludedAerodromes &&
                                    excludedAerodromes.includes(
                                        aerodrome.designator
                                    )
                                )
                        ).length === 0 && (
                            <Flex
                                minHeight="80px"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                px="15px"
                                py="20px"
                                lineHeight="1.4"
                                gap={{ base: '10px', sm: '5px' }}
                            >
                                <Text
                                    fontSize={calculateFontSize(0.8)}
                                    fontWeight="500"
                                    color="light.100"
                                    textAlign="center"
                                >
                                    All aerodromes with NOTAMs have been
                                    excluded from the briefing
                                </Text>
                                <Text
                                    fontSize={calculateFontSize(0.8)}
                                    fontWeight="400"
                                    color="gray.500"
                                    opacity="0.8"
                                    textAlign="center"
                                >
                                    Reinstate aerodromes from the Excluded Items
                                    list
                                </Text>
                            </Flex>
                        )}
                    {filteredNotam && !filteredNotam.length && (
                        <Flex
                            pt="15px"
                            pb="18px"
                            px="15px"
                            justifyContent="center"
                        >
                            <ErrorText>
                                There has been an issue with the connection
                                between PreFlight and Airways.
                                <br />
                                There is a high likelihood that the latest NOTAM
                                information is not available in PreFlight. Check
                                back again soon, or alternatively visit{' '}
                                <a
                                    style={{
                                        fontWeight: '500',
                                        textDecoration: 'underline',
                                    }}
                                    href="https://ifis.airways.co.nz/"
                                >
                                    IFIS
                                </a>
                                .
                            </ErrorText>
                        </Flex>
                    )}
                </Box>
            </Box>
        </>
    )
}
