import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    Flex,
    Box,
    Text,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useBreakpointValue,
} from '@chakra-ui/react'
import ErrorText from '../../../components/ErrorText'
import { useInView } from 'react-intersection-observer'
import { parseISO, formatDistanceToNow } from 'date-fns'

import formatDateTime from '../../../../../util/dateFormatter'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import {
    timeZoneState,
    showOutdatedErrorsState,
    inViewPositionState,
    briefingParamsState,
    collapseDropdownsState,
} from '../../../../../globalState'
import { isMobileOnly } from 'react-device-detect'
import SpaceWeatherIcon from '../../../../../icons/menu-icons/SpaceWeatherIcon'

export default function SpaceWxBriefing(props) {
    const { briefingData } = props
    const spacewx =
        briefingData &&
        briefingData.res &&
        briefingData.res.areamet &&
        briefingData.res.areamet.spacewx
    const timeZone = useRecoilValue(timeZoneState)
    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const params = useRecoilValue(briefingParamsState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize, calculateContainerSize } = useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : [0, 1]
    )

    useEffect(() => {
        inView && setInViewPosition('spacewxContainer')
    }, [inView, setInViewPosition])

    useEffect(() => {
        setExpandedDropdowns(collapseDropdowns ? [] : [0, 1])
    }, [collapseDropdowns])

    return (
        <>
            <div
                id="spacewxContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            {params.spacewx && (
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', md: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    zIndex="1"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginTop="25px"
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#023956"
                        backgroundImage="linear-gradient(345deg, #045982 0%, #012338 80%)"
                        filter={{
                            base: isMobileOnly && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="30px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="flex-start"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Box>
                                <SpaceWeatherIcon
                                    width="20px"
                                    height="20px"
                                    color="white"
                                />
                            </Box>

                            <Box my="auto" paddingLeft="12px">
                                <Text
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    SPACE WEATHER
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>

                    <Box>
                        <Accordion
                            border="none"
                            allowToggle
                            allowMultiple
                            index={expandedDropdowns}
                            onChange={(expandedIndex) => {
                                setExpandedDropdowns(expandedIndex)
                            }}
                        >
                            {spacewx && spacewx.length > 0 ? (
                                <AccordionItem borderStyle="none" index="1">
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                sx={{
                                                    clipPath:
                                                        'inset(0px 0px -50px 0px)',
                                                }}
                                                pl="1.3rem"
                                                height="50px"
                                                pr={5}
                                                py={3}
                                                _hover={{
                                                    background: 'light.30',

                                                    cursor: 'pointer',
                                                }}
                                                color={
                                                    !isExpanded
                                                        ? '#04557d'
                                                        : '#04557d'
                                                }
                                                background={
                                                    isExpanded
                                                        ? 'light.25'
                                                        : 'white'
                                                }
                                                boxShadow={
                                                    isExpanded &&
                                                    '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                }
                                                _focus={{
                                                    boxShadow: isExpanded
                                                        ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                        : 'none',
                                                }}
                                            >
                                                <Box
                                                    fontSize={
                                                        adjustLabelFontSize
                                                    }
                                                    lineHeight="1.2"
                                                    fontWeight={
                                                        isExpanded
                                                            ? '600'
                                                            : '500'
                                                    }
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    flex="1"
                                                    textAlign="left"
                                                    paddingLeft="1"
                                                >
                                                    Advisories
                                                </Box>

                                                <Flex>
                                                    {isExpanded ? (
                                                        <AccordionIcon transform="rotate(0deg)" />
                                                    ) : (
                                                        <AccordionIcon transform="rotate(-90deg)" />
                                                    )}
                                                </Flex>
                                            </AccordionButton>

                                            {spacewx && (
                                                <AccordionPanel
                                                    pt={6}
                                                    pb={6}
                                                    pl="1.5rem"
                                                    pr="1.5rem"
                                                >
                                                    {spacewx &&
                                                        spacewx.map(
                                                            (
                                                                spacewxData,
                                                                i
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        key={`spacewxBriefing${spacewxData.fir}${spacewxData.id}-${i}`}
                                                                    >
                                                                        {(showOutdatedErrors ||
                                                                            spacewxData.outofdate) && (
                                                                            <Flex
                                                                                pb="10px"
                                                                                ml="-8px"
                                                                                justifyContent="center"
                                                                            >
                                                                                <ErrorText>
                                                                                    There
                                                                                    has
                                                                                    been
                                                                                    an
                                                                                    issue
                                                                                    with
                                                                                    the
                                                                                    connection
                                                                                    between
                                                                                    PreFlight
                                                                                    and
                                                                                    MetService.
                                                                                    This{' '}
                                                                                    <strong>
                                                                                        SPACE
                                                                                        WEATHER
                                                                                    </strong>{' '}
                                                                                    report
                                                                                    was
                                                                                    last
                                                                                    validated
                                                                                    with
                                                                                    MetService
                                                                                    more
                                                                                    than{' '}
                                                                                    <strong>
                                                                                        {formatDistanceToNow(
                                                                                            parseISO(
                                                                                                spacewxData.updated
                                                                                            ),
                                                                                            {
                                                                                                addSuffix: true,
                                                                                            }
                                                                                        )}
                                                                                    </strong>
                                                                                    .
                                                                                    <br />
                                                                                    There
                                                                                    is
                                                                                    a
                                                                                    high
                                                                                    likelihood
                                                                                    that
                                                                                    the
                                                                                    latest
                                                                                    SPACE
                                                                                    WEATHER
                                                                                    information
                                                                                    is
                                                                                    not
                                                                                    available
                                                                                    in
                                                                                    PreFlight.
                                                                                    Check
                                                                                    back
                                                                                    again
                                                                                    soon,
                                                                                    or
                                                                                    alternatively
                                                                                    visit{' '}
                                                                                    <a
                                                                                        style={{
                                                                                            fontWeight:
                                                                                                '500',
                                                                                            textDecoration:
                                                                                                'underline',
                                                                                        }}
                                                                                        href="https://ifis.airways.co.nz/"
                                                                                    >
                                                                                        IFIS
                                                                                    </a>

                                                                                    .
                                                                                </ErrorText>
                                                                            </Flex>
                                                                        )}
                                                                        <Flex>
                                                                            {/* ICON */}
                                                                            <Flex
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                                borderRadius="100%"
                                                                                minWidth="32px"
                                                                                height="32px"
                                                                                mt="3px"
                                                                                background="#afbfdb"
                                                                            >
                                                                                <SpaceWeatherIcon
                                                                                    color="white"
                                                                                    boxSize="26px"
                                                                                    mt="-1px"
                                                                                    pr="10px"
                                                                                />
                                                                            </Flex>
                                                                            <Box
                                                                                width="100%"
                                                                                paddingLeft="20px"
                                                                            >
                                                                                <Flex
                                                                                    flexDirection="column"
                                                                                    width="100%"
                                                                                    gap="5px"
                                                                                >
                                                                                    <Flex alignItems="baseline">
                                                                                        <Text
                                                                                            paddingStart="0"
                                                                                            paddingEnd="2"
                                                                                            fontWeight="bold"
                                                                                            width={calculateContainerSize(
                                                                                                100
                                                                                            )}
                                                                                            minWidth={calculateContainerSize(
                                                                                                100
                                                                                            )}
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                            lineHeight="1.3"
                                                                                        >
                                                                                            Advisory
                                                                                            #:
                                                                                        </Text>
                                                                                        <Text
                                                                                            paddingStart="0"
                                                                                            paddingEnd="0"
                                                                                            fontWeight="400"
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                            lineHeight="1.3"
                                                                                            borderColor="transparent"
                                                                                        >
                                                                                            {
                                                                                                spacewxData.nr
                                                                                            }
                                                                                        </Text>
                                                                                    </Flex>
                                                                                    <Flex alignItems="baseline">
                                                                                        <Text
                                                                                            paddingStart="0"
                                                                                            paddingEnd="2"
                                                                                            fontWeight="bold"
                                                                                            width={calculateContainerSize(
                                                                                                100
                                                                                            )}
                                                                                            minWidth={calculateContainerSize(
                                                                                                100
                                                                                            )}
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                            lineHeight="1.3"
                                                                                        >
                                                                                            Issued:
                                                                                        </Text>
                                                                                        <Text
                                                                                            paddingStart="0"
                                                                                            paddingEnd="0"
                                                                                            fontWeight="400"
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                            lineHeight="1.3"
                                                                                            borderColor="transparent"
                                                                                        >
                                                                                            {spacewxData?.issued &&
                                                                                                formatDateTime(
                                                                                                    spacewxData.issued,
                                                                                                    timeZone
                                                                                                )}
                                                                                        </Text>
                                                                                    </Flex>

                                                                                    <Flex alignItems="baseline">
                                                                                        <Text
                                                                                            paddingStart="0"
                                                                                            paddingEnd="2"
                                                                                            fontWeight="bold"
                                                                                            width={calculateContainerSize(
                                                                                                100
                                                                                            )}
                                                                                            minWidth={calculateContainerSize(
                                                                                                100
                                                                                            )}
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                            lineHeight="1.3"
                                                                                        >
                                                                                            Issuing
                                                                                            Centre:
                                                                                        </Text>
                                                                                        <Text
                                                                                            paddingStart="0"
                                                                                            paddingEnd="0"
                                                                                            fontWeight="400"
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                            lineHeight="1.3"
                                                                                            borderColor="transparent"
                                                                                        >
                                                                                            {spacewxData.swxc ===
                                                                                            'ACFJ'
                                                                                                ? 'ACFJ Consortium'
                                                                                                : spacewxData.swxc ===
                                                                                                  'NOAA'
                                                                                                ? 'NOAA Space Weather Prediction Service'
                                                                                                : spacewxData.swxc ===
                                                                                                  'PECASUS'
                                                                                                ? 'PECASUS Consortium'
                                                                                                : spacewxData.swxc}
                                                                                        </Text>
                                                                                    </Flex>

                                                                                    <Divider
                                                                                        display={{
                                                                                            base: 'none',
                                                                                            md: 'block',
                                                                                        }}
                                                                                        height="0px !important"
                                                                                        borderColor="#e5e5e5"
                                                                                        width="100%"
                                                                                        mx="auto"
                                                                                        mt="5px"
                                                                                        opacity="0.6"
                                                                                    />

                                                                                    <Text
                                                                                        textAlign="left"
                                                                                        color="light.900"
                                                                                        fontSize={calculateFontSize(
                                                                                            0.8
                                                                                        )}
                                                                                        fontWeight="normal"
                                                                                        lineHeight="1.3"
                                                                                        whiteSpace="pre-line"
                                                                                        paddingTop="5px"
                                                                                        paddingBottom="10px"
                                                                                    >
                                                                                        {
                                                                                            spacewxData.text
                                                                                        }
                                                                                    </Text>
                                                                                </Flex>
                                                                            </Box>
                                                                        </Flex>

                                                                        {i +
                                                                            1 !==
                                                                            spacewx.length && (
                                                                            <Divider
                                                                                height="0px !important"
                                                                                borderColor="#e5e5e5"
                                                                                width="100%"
                                                                                mx="auto"
                                                                                mt="25px"
                                                                                mb="25px"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                </AccordionPanel>
                                            )}
                                        </>
                                    )}
                                </AccordionItem>
                            ) : (
                                <Flex
                                    fontFamily="Open Sans"
                                    px="6"
                                    py="4"
                                    minHeight="50px"
                                    flexDirection="column"
                                >
                                    <Text
                                        fontSize={calculateFontSize(0.8)}
                                        lineHeight="1.2"
                                        fontWeight="500"
                                        fontFamily="'Open Sans', sans-serif"
                                        flex="2"
                                        color="light.400"
                                    >
                                        No current Space Weather advisories
                                    </Text>
                                </Flex>
                            )}
                        </Accordion>
                    </Box>
                </Box>
            )}
        </>
    )
}
