import { useEffect, useContext, useCallback, useRef, useState } from 'react'
import { MapContext } from 'react-mapbox-gl'
import { useUserMetaData } from '../../auth/hooks'
import * as turf from '@turf/turf'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
    clickedFeaturesState,
    mapZoomLevelState,
    layerSelectionState,
    dataModeState,
    userProfileState,
    zoomToActiveState,
    flyToActiveState,
} from '../../globalState'
import { useBreakpointValue } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'

const maxZoom = 8.5
const zoomOut = isMobileOnly ? 4 : 5
const zoomLayers = ['sigmet', 'sigwx', 'aaw']

export default function FlyTo() {
    const clickedFeatures = useRecoilValue(clickedFeaturesState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const mode = useRecoilValue(dataModeState)
    const mapInstance = useContext(MapContext)

    // Setting Zoom Level and Map Coords to zoom back out to when closing
    const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomLevelState)
    const [mapCoords, setMapCoords] = useState(null)
    const setZoomToActive = useSetRecoilState(zoomToActiveState)
    const setFlyToActive = useSetRecoilState(flyToActiveState)

    const zoomRef = useRef(null)
    const viewportCenterRef = useRef(null)
    const profileData = useRecoilValue(userProfileState)
    const userMetaData = useUserMetaData()

    const disableMapZoom =
        (profileData?.profileSettings?.disable_mapzoom !== undefined
            ? profileData.profileSettings.disable_mapzoom
            : userMetaData?.profile_settings?.disable_mapzoom) || false

    const disableZoomWithDrawerOpening =
        (profileData?.profileSettings?.disable_mapzoom_drawer !== undefined
            ? profileData.profileSettings.disable_mapzoom_drawer
            : userMetaData?.profile_settings?.disable_mapzoom_drawer) || false

    const [mapZoomWithDrawerIsDisabled, setMapZoomWithDrawerIsDisabled] =
        useState(false)
    const [mapFlyToIsDisabled, setMapFlyToIsDisabled] = useState(false)

    useEffect(() => {
        // disable FlyTo actions for clickedFeatures (excludes Aerodromes, AWS and Webcams)
        if (disableMapZoom) {
            setMapFlyToIsDisabled(true)
            setFlyToActive(false)
        } else if (!disableMapZoom) {
            setMapFlyToIsDisabled(false)
            setFlyToActive(true)
        }

        // disable ZoomTo actions for Aerodromes, AWS and Webcams (excludes other clicked features)
        if (disableZoomWithDrawerOpening) {
            setMapZoomWithDrawerIsDisabled(true)
            setZoomToActive(false)
        } else if (!disableZoomWithDrawerOpening) {
            setMapZoomWithDrawerIsDisabled(false)
            setZoomToActive(true)
        }
    }, [
        disableZoomWithDrawerOpening,
        disableMapZoom,
        setFlyToActive,
        setZoomToActive,
    ])

    const mapAlignAerodromes = useBreakpointValue({
        base: [0, 0],
        md: [-320, 0],
    })

    const mapAlignAWS = useBreakpointValue({
        base: [0, 0],
        md: [-130, 0],
    })

    const mapAlignWebcams = useBreakpointValue({
        base: [0, 0],
        md: [-420, 0],
    })

    const mapAlignAdvisory = useBreakpointValue({
        base: [0, 0],
        md: [75, 0],
    })

    const featPadding = useBreakpointValue({
        base: 25,
        md: 150,
    })

    const previousModeLayerRef = useRef(null)

    const zoom = mapInstance.getZoom()
    const zoomOnLayerChange = useCallback(() => {
        const currentLayers = layerSelection[mode]
        const containsZoomableLayers = Object.keys(currentLayers)
            .map((i) => {
                return zoomLayers.includes(i) && currentLayers[i]
            })
            .includes(true)

        if (previousModeLayerRef.current) {
            if (
                previousModeLayerRef.current.mode !== 'advs' &&
                previousModeLayerRef.current.mode !== mode &&
                mode === 'advs' &&
                zoom > (zoomLevel || maxZoom)
            ) {
                previousModeLayerRef.current = { mode, currentLayers }
                return true
            } else if (
                (mode === 'met' || mode === 'advs') &&
                containsZoomableLayers &&
                zoom > (zoomLevel || maxZoom)
            ) {
                previousModeLayerRef.current = { mode, currentLayers }
                return true
            } else {
                previousModeLayerRef.current = { mode, currentLayers }
                return false
            }
        } else {
            previousModeLayerRef.current = { mode, currentLayers }
            return false
        }
    }, [layerSelection, mode, zoomLevel, zoom])

    useEffect(() => {
        if (zoomOnLayerChange()) {
            mapInstance.flyTo({
                center: mapCoords || { lng: 172.62, lat: -41.12 },
                zoom: zoomLevel || zoomOut,
                curve: 1.2,
            })
        }
    }, [
        mode,
        layerSelection,
        mapInstance,
        mapCoords,
        zoomLevel,
        zoomOnLayerChange,
    ])

    useEffect(() => {
        // set zoom and map coords to return to after closing modal
        const resetFlyTo = () => {
            if (
                (!mapCoords || mapCoords === null) &&
                (!zoomLevel || zoomLevel === null)
            ) {
                if (viewportCenterRef.current) {
                    setMapCoords(viewportCenterRef.current)
                }
                if (zoomRef.current) {
                    setZoomLevel(zoomRef.current)
                }
            }
        }
        // trigger FlyTo action
        const handleFlyTo = () => {
            zoomRef.current = mapInstance.getZoom()
            viewportCenterRef.current = mapInstance.getCenter()
            const { geometry, bbox, layer } = clickedFeatures[0]
            const mapAlign =
                layer === 'aerodromes'
                    ? mapAlignAerodromes
                    : layer === 'aws'
                    ? mapAlignAWS
                    : layer === 'webcams'
                    ? mapAlignWebcams
                    : [0, 0]

            const minimizeZoom =
                geometry.type === 'Point' &&
                (clickedFeatures[0].layer === 'sigmet' ||
                    clickedFeatures[0].layer === 'sigmet-symbol' ||
                    clickedFeatures[0].layer === 'sigwx' ||
                    clickedFeatures[0].layer === 'sigwx-symbol')
            if (
                layer === 'aerodromes' ||
                layer === 'aws' ||
                layer === 'webcams'
            ) {
                if (!mapZoomWithDrawerIsDisabled) {
                    mapInstance.flyTo({
                        center: geometry.coordinates,
                        zoom: 13,
                        curve: 1.2,
                        offset: mapAlign,
                    })
                    resetFlyTo()
                }
            } else if (geometry.type !== 'Polygon' && !mapFlyToIsDisabled) {
                if (!bbox) {
                    mapInstance.flyTo({
                        center: geometry.coordinates,
                        zoom: minimizeZoom ? 10 : 13,
                        curve: 1.2,
                    })
                } else {
                    mapInstance.fitBounds([
                        [bbox[0], bbox[1]], // southwestern corner of the bounds
                        [bbox[2], bbox[3]], // northeastern corner of the bounds
                    ])
                }
                resetFlyTo()
            } else if (geometry.type === 'Polygon' && !mapFlyToIsDisabled) {
                mapInstance.fitBounds(turf.bbox(geometry), {
                    padding: featPadding,
                    offset: mapAlignAdvisory,
                })
                resetFlyTo()
            }
        }

        if (clickedFeatures && clickedFeatures.length === 1) {
            handleFlyTo()
        }
    }, [
        clickedFeatures,
        mapInstance,
        mapAlignAerodromes,
        mapAlignAWS,
        mapAlignWebcams,
        mapAlignAdvisory,
        mapFlyToIsDisabled,
        mapZoomWithDrawerIsDisabled,
        disableMapZoom,
        mapCoords,
        zoomLevel,
        featPadding,
        setZoomLevel,
        setMapCoords,
    ])

    useEffect(() => {
        // clear zoom and map coords after closing modal
        if (
            !clickedFeatures ||
            (clickedFeatures && clickedFeatures.length === 0)
        ) {
            setTimeout(() => {
                // wait for mapZoom to zoom out before clearing
                setZoomLevel(null)
                setMapCoords(null)
            }, 1000)
        }
    }, [clickedFeatures, setZoomLevel, setMapCoords])

    return null
}
