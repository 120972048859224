import React from 'react'
import { Flex, Button, Text } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import PremiumBadge from '../../../../controls/PremiumBadge'
import '../../briefing.css'

export default function MobileScrollMenuItems(props) {
    const {
        menuState,
        altAerodromeLayout,
        scrollDirection,
        dataLoading,
        params,
        condenseMenu,
        selectedButton,
        abbreviatedMenuLabels,
        filtersShow,
        isPremium,
        setShowPricingTiers,
        setIsScrolling,
        setSelectedButton,
        disableAerodromes,
    } = props

    //Mobile Tab Button Styles
    const mobileTabStyles = {
        display: 'grid',
        height: '40px',
        zIndex: '2',
        background: 'transparent',
        fontSize: '9px',
        lineHeight: '1.5',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        paddingTop: '2px',
        paddingEnd: '0px',
        paddingStart: '0px',
        // width: '33.3%',
        width: 'auto',
        color: 'light.100',
        _selected: {
            bg: 'transparent',
            fontWeight: '600',
            color: 'light.202',
        },
        _hover: {
            background: 'transparent',
            height: isMobileOnly ? '40px' : '36px',
            marginTop: isMobileOnly ? '0px' : '2px',
            borderRadius: '50px',
        },
        _active: {
            background: 'transparent',
            height: isMobileOnly ? '40px' : '36px',
            marginTop: isMobileOnly ? '0px' : '2px',
            borderRadius: '50px',
        },
        _focus: {
            border: 'none',
        },
        _disabled: {
            opacity: '0.3',
        },
    }

    return (
        <>
            {Object.keys(menuState).map((parent, i) => (
                <Flex
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    key={`MobileMenuItems${parent}${i}`}
                    minWidth={
                        !altAerodromeLayout && { base: '70px', smd: '75px' }
                    }
                >
                    {menuState[parent].children && (
                        <Flex
                            key={`MobileMenuParent${parent}${i}`}
                            gap={altAerodromeLayout ? '5px' : '0px'}
                            opacity={altAerodromeLayout ? '1' : '0'}
                            visibility={
                                altAerodromeLayout ? 'visible' : 'hidden'
                            }
                            width={altAerodromeLayout ? 'auto' : '0px'}
                            minWidth={altAerodromeLayout ? '220px' : '0px'}
                            marginTop={{
                                base: scrollDirection === 'up' ? '5px' : '0',
                                md: '0',
                            }}
                            transition="all ease 300ms"
                            willChange="auto"
                        >
                            {menuState[parent].children &&
                                Object.keys(menuState[parent].children).map(
                                    (child, index) => (
                                        <Button /// MOBILE BUTTONS
                                            key={`MenuParent${parent}Child${child}${index}`}
                                            {...mobileTabStyles}
                                            disabled={
                                                dataLoading ||
                                                !params[
                                                    menuState[parent].children[
                                                        child
                                                    ].id
                                                ] ||
                                                (disableAerodromes &&
                                                    (menuState[parent].children[
                                                        child
                                                    ].id ===
                                                        'aerodromesNotam' ||
                                                        menuState[parent]
                                                            .children[child]
                                                            .id ===
                                                            'aerodromesMetar' ||
                                                        menuState[parent]
                                                            .children[child]
                                                            .id ===
                                                            'aerodromesTaf' ||
                                                        menuState[parent]
                                                            .children[child]
                                                            .id ===
                                                            'aerodromesAtis'))
                                            }
                                            onClick={() => {
                                                if (
                                                    params[
                                                        menuState[parent]
                                                            .children[child].id
                                                    ]
                                                ) {
                                                    document
                                                        .getElementById(
                                                            menuState[parent]
                                                                .children[child]
                                                                .containerId
                                                        )
                                                        .scrollIntoView({
                                                            behavior: 'smooth',
                                                        })
                                                    setIsScrolling(true)
                                                    setSelectedButton(child)
                                                }
                                            }}
                                            maxWidth={{
                                                base: '70px',
                                                smd: '75px',
                                            }}
                                            width={'auto'}
                                            minWidth={{
                                                base: '70px',
                                                smd: '75px',
                                            }}
                                        >
                                            <Flex
                                                flexDirection={{
                                                    base: 'column',
                                                    md: 'row',
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                                marginTop={{
                                                    base:
                                                        !condenseMenu &&
                                                        Object.keys(
                                                            menuState[parent]
                                                                .children
                                                        ).indexOf(
                                                            selectedButton
                                                        ) === index
                                                            ? '-5px'
                                                            : '-3px',
                                                    md: '0px',
                                                }}
                                                marginLeft={{
                                                    base: '0px',
                                                    md: '-2px',
                                                }}
                                                color={
                                                    Object.keys(
                                                        menuState[parent]
                                                            .children
                                                    ).indexOf(
                                                        selectedButton
                                                    ) === index
                                                        ? 'light.200'
                                                        : 'light.100'
                                                }
                                                opacity={
                                                    !params[
                                                        menuState[parent]
                                                            .children[child].id
                                                    ] ||
                                                    (disableAerodromes &&
                                                        (menuState[parent]
                                                            .children[child]
                                                            .id ===
                                                            'aerodromesNotam' ||
                                                            menuState[parent]
                                                                .children[child]
                                                                .id ===
                                                                'aerodromesMetar' ||
                                                            menuState[parent]
                                                                .children[child]
                                                                .id ===
                                                                'aerodromesTaf' ||
                                                            menuState[parent]
                                                                .children[child]
                                                                .id ===
                                                                'aerodromesAtis'))
                                                        ? '0.4'
                                                        : '1'
                                                }
                                                height={{
                                                    base:
                                                        !condenseMenu ||
                                                        Object.keys(
                                                            menuState[parent]
                                                                .children
                                                        ).indexOf(
                                                            selectedButton
                                                        ) === index
                                                            ? '40px'
                                                            : '28px',
                                                    md: 'auto',
                                                }}
                                                transition="all ease 300ms"
                                                willChange="auto"
                                            >
                                                <Flex
                                                    mx="auto"
                                                    minHeight="24px"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    zIndex="1"
                                                    bg="light.600"
                                                    transition="all ease 300ms"
                                                    willChange="auto"
                                                    transform={{
                                                        base:
                                                            !condenseMenu &&
                                                            Object.keys(
                                                                menuState[
                                                                    parent
                                                                ].children
                                                            ).indexOf(
                                                                selectedButton
                                                            ) === index
                                                                ? 'scale(1.1)'
                                                                : 'scale(1)',
                                                        md: 'unset',
                                                    }}
                                                >
                                                    <Flex
                                                        transform={{
                                                            base:
                                                                menuState[
                                                                    parent
                                                                ].children[
                                                                    child
                                                                ].id ===
                                                                'aerodromesNotam'
                                                                    ? 'scale(0.95)'
                                                                    : 'scale(0.9)',
                                                            md: 'unset',
                                                        }}
                                                    >
                                                        {
                                                            menuState[parent]
                                                                .children[child]
                                                                .icon
                                                        }
                                                    </Flex>
                                                </Flex>
                                                <Text
                                                    alignSelf="center"
                                                    fontSize={{
                                                        base:
                                                            !condenseMenu &&
                                                            Object.keys(
                                                                menuState[
                                                                    parent
                                                                ].children
                                                            ).indexOf(
                                                                selectedButton
                                                            ) === index
                                                                ? '0.65rem'
                                                                : '9px',
                                                        md: 'initial',
                                                    }}
                                                    fontWeight={{
                                                        base:
                                                            !condenseMenu &&
                                                            Object.keys(
                                                                menuState[
                                                                    parent
                                                                ].children
                                                            ).indexOf(
                                                                selectedButton
                                                            ) === index
                                                                ? '600'
                                                                : '400',
                                                        md: 'initial',
                                                    }}
                                                    marginTop={{
                                                        base: '2px',
                                                        md: '0px',
                                                    }}
                                                    opacity={{
                                                        base:
                                                            !condenseMenu ||
                                                            Object.keys(
                                                                menuState[
                                                                    parent
                                                                ].children
                                                            ).indexOf(
                                                                selectedButton
                                                            ) === index
                                                                ? '1'
                                                                : '0',
                                                        md: '1',
                                                    }}
                                                    height={{
                                                        base:
                                                            !condenseMenu ||
                                                            Object.keys(
                                                                menuState[
                                                                    parent
                                                                ].children
                                                            ).indexOf(
                                                                selectedButton
                                                            ) === index
                                                                ? '14px'
                                                                : '0px',
                                                        md: 'auto',
                                                    }}
                                                    transition="all ease 300ms"
                                                    willChange="auto"
                                                    overflow="hidden"
                                                >
                                                    {abbreviatedMenuLabels
                                                        ? menuState[parent]
                                                              .children[child]
                                                              .abbreviated
                                                        : menuState[parent]
                                                              .children[child]
                                                              .label}
                                                </Text>
                                            </Flex>
                                        </Button>
                                    )
                                )}
                        </Flex>
                    )}
                    <Button /// MOBILE BUTTONS
                        key={`MobileMenu${parent}${i}`}
                        {...mobileTabStyles}
                        disabled={
                            dataLoading ||
                            !params[menuState[parent].id] ||
                            (isPremium && menuState[parent].premiumOnly) ||
                            (disableAerodromes &&
                                menuState[parent].id === 'aerodromes')
                        }
                        onClick={() => {
                            if (isPremium && menuState[parent].premiumOnly) {
                                setShowPricingTiers(true)
                            } else {
                                !filtersShow &&
                                    document
                                        .getElementById(
                                            menuState[parent].containerId
                                        )
                                        .scrollIntoView({
                                            behavior: 'smooth',
                                        })
                                setIsScrolling(true)
                                setSelectedButton(parent)
                            }
                        }}
                        opacity={
                            altAerodromeLayout &&
                            menuState[parent].id === 'aerodromes'
                                ? '0'
                                : disableAerodromes &&
                                  menuState[parent].id === 'aerodromes'
                                ? '0.4'
                                : '1'
                        }
                        visibility={
                            altAerodromeLayout &&
                            menuState[parent].id === 'aerodromes'
                                ? 'hidden'
                                : 'visible'
                        }
                        pointerEvents={
                            disableAerodromes &&
                            menuState[parent].id === 'aerodromes'
                                ? 'none'
                                : 'auto'
                        }
                        width={
                            altAerodromeLayout &&
                            menuState[parent].id === 'aerodromes'
                                ? '0px'
                                : 'auto'
                        }
                        minWidth={
                            altAerodromeLayout &&
                            menuState[parent].id === 'aerodromes'
                                ? '0px'
                                : { base: '70px', smd: '75px' }
                        }
                        maxWidth={{ base: '70px', smd: '75px' }}
                        marginTop={{
                            base: scrollDirection === 'up' ? '5px' : '0',
                            md: '0',
                        }}
                        transition="all ease 300ms"
                        willChange="auto"
                    >
                        <Flex
                            flexDirection={{
                                base: 'column',
                                md: 'row',
                            }}
                            marginTop={{
                                base:
                                    !condenseMenu &&
                                    Object.keys(menuState).indexOf(
                                        selectedButton
                                    ) === i
                                        ? '-5px'
                                        : '-3px',
                                md: '0px',
                            }}
                            marginLeft={{
                                base: '0px',
                                md: '-2px',
                            }}
                            color={
                                Object.keys(menuState).indexOf(
                                    selectedButton
                                ) === i
                                    ? 'light.200'
                                    : 'light.100'
                            }
                            transition="all ease 300ms"
                            willChange="auto"
                            height={{
                                base:
                                    !condenseMenu ||
                                    Object.keys(menuState).indexOf(
                                        selectedButton
                                    ) === i
                                        ? '40px'
                                        : '28px',
                                md: 'auto',
                            }}
                            alignItems="center"
                            justifyContent="center"
                        >
                            {/* *** PREMIUM BADGE *** */}
                            {isPremium && menuState[parent].premiumOnly && (
                                <Flex
                                    marginRight={{
                                        base: '-20px',
                                        ml: '23px',
                                    }}
                                    mt={{
                                        base: '-4px',
                                        ml: '-6px',
                                    }}
                                    mb={{
                                        base: '-10px',
                                        ml: '8px',
                                    }}
                                    height="14px"
                                    zIndex="9"
                                >
                                    <PremiumBadge />
                                </Flex>
                            )}
                            <Flex
                                mx="auto"
                                minHeight="24px"
                                justifyContent="center"
                                alignItems="center"
                                zIndex="1"
                                bg="light.600"
                                transition="all ease 300ms"
                                willChange="auto"
                                transform={{
                                    base:
                                        !condenseMenu &&
                                        Object.keys(menuState).indexOf(
                                            selectedButton
                                        ) === i
                                            ? 'scale(1.1)'
                                            : 'scale(1)',
                                    md: 'unset',
                                }}
                                opacity={
                                    isPremium && menuState[parent].premiumOnly
                                        ? '0.4'
                                        : '1'
                                }
                            >
                                {menuState[parent].icon}
                            </Flex>
                            <Text
                                alignSelf="center"
                                fontSize={{
                                    base:
                                        !condenseMenu &&
                                        Object.keys(menuState).indexOf(
                                            selectedButton
                                        ) === i
                                            ? '0.65rem'
                                            : '9px',
                                    md: 'initial',
                                }}
                                fontWeight={{
                                    base:
                                        !condenseMenu &&
                                        Object.keys(menuState).indexOf(
                                            selectedButton
                                        ) === i
                                            ? '600'
                                            : '400',
                                    md: 'initial',
                                }}
                                px={{
                                    base:
                                        !condenseMenu &&
                                        Object.keys(menuState).indexOf(
                                            selectedButton
                                        ) === i
                                            ? '0'
                                            : '2px',
                                    md: 'initial',
                                }}
                                marginTop={{
                                    base: '2px',
                                    md: '0px',
                                }}
                                opacity={{
                                    base:
                                        !condenseMenu ||
                                        Object.keys(menuState).indexOf(
                                            selectedButton
                                        ) === i
                                            ? '1'
                                            : '0',
                                    md: '1',
                                }}
                                height={{
                                    base:
                                        !condenseMenu ||
                                        Object.keys(menuState).indexOf(
                                            selectedButton
                                        ) === i
                                            ? '14px'
                                            : '0px',
                                    md: 'auto',
                                }}
                                transition="all ease 300ms"
                                willChange="auto"
                                overflow="hidden"
                            >
                                {abbreviatedMenuLabels
                                    ? menuState[parent].abbreviated
                                    : menuState[parent].label}
                            </Text>
                        </Flex>
                    </Button>
                </Flex>
            ))}
        </>
    )
}
