import React from 'react'

import { Text, View } from '@react-pdf/renderer'
import { AAWIcon } from './assets/SvgImages'
import { ErrorIcon } from './assets/SvgImages'

import { parseISO, formatDistanceToNow } from 'date-fns'

export default function AAWExportPDF(props) {
    const { briefingData, styles, showOutdatedErrors, timeZone } = props

    if (briefingData) {
        return (
            // AAW SECTION

            <View style={styles.section}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '-1',
                    }}
                >
                    <div
                        wrap={false}
                        style={[
                            styles.sectionTitle,
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                            },
                        ]}
                    >
                        <AAWIcon
                            width="16"
                            height="16"
                            viewBox="0 0 14 14"
                            fill="rgb(63, 184, 221)"
                        />

                        <Text
                            style={{
                                fontWeight: '600',
                                textAlign: 'right',
                                paddingLeft: '7',
                                color: 'rgb(63, 184, 221)',
                            }}
                        >
                            AVIATION AREA WIND REPORTS
                        </Text>
                        {timeZone !== 'UTC' && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginRight: '2',
                                    marginTop: '0',
                                    marginBottom: '0',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Text
                                    style={{
                                        fontWeight: '300',
                                        fontSize: '6pt',
                                        textAlign: 'right',
                                        textTransform: 'unset',
                                        color: 'rgb(111, 127, 145)',
                                    }}
                                >
                                    Time references are in UTC.
                                </Text>
                                <Text
                                    style={{
                                        paddingLeft: 3,
                                        fontWeight: '300',
                                        fontSize: '6pt',
                                        textAlign: 'right',
                                        textTransform: 'unset',
                                        color: 'rgb(111, 127, 145)',
                                    }}
                                >
                                    All heights are in feet AMSL.
                                </Text>
                                <Text
                                    style={{
                                        paddingLeft: 3,
                                        fontWeight: '300',
                                        fontSize: '6pt',
                                        textAlign: 'right',
                                        textTransform: 'unset',
                                        color: 'rgb(111, 127, 145)',
                                    }}
                                >
                                    Wind:°TRUE / Speed:KT
                                </Text>
                            </div>
                        )}
                    </div>

                    {briefingData &&
                        briefingData.res.areamet &&
                        briefingData.res.areamet.aaw &&
                        briefingData.res.areamet.aaw.map((aawData, i) => {
                            return (
                                <div
                                    wrap={false}
                                    key={`AAWBriefing${aawData.area}`}
                                    style={[
                                        styles.sectionContent,
                                        {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingTop: 4,
                                            paddingBottom: 4,
                                            borderBottom: '1px solid #e5e5e5',
                                        },
                                    ]}
                                >
                                    <div
                                        style={[
                                            styles.sectionContent,
                                            {
                                                display: 'flex',
                                                flexDirection: 'column',
                                            },
                                        ]}
                                    >
                                        {(showOutdatedErrors ||
                                            aawData.outofdate) && (
                                            <div
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        paddingTop: '2px',
                                                        paddingBottom: '5px',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    },
                                                ]}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div // ERROR ICON
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '5%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                            },
                                                        ]}
                                                    >
                                                        <ErrorIcon
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="rgb(16, 170, 112)"
                                                        />
                                                    </div>
                                                    <div // ERROR CONTENT
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '95%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        <Text
                                                            style={styles.error}
                                                        >
                                                            <span>
                                                                There has been
                                                                an issue with
                                                                the connection
                                                                between
                                                                PreFlight and
                                                                MetService. This{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                AAW
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                was last
                                                                validated with
                                                                MetService more
                                                                than{' '}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {formatDistanceToNow(
                                                                    parseISO(
                                                                        aawData.updated
                                                                    ),
                                                                    {
                                                                        addSuffix: true,
                                                                    }
                                                                )}
                                                            </span>
                                                            <span>
                                                                . There is a
                                                                high likelihood
                                                                that the latest
                                                                AAW information
                                                                is not available
                                                                in PreFlight.
                                                                Check back again
                                                                soon, or
                                                                alternatively
                                                                visit{' '}
                                                            </span>
                                                            <a
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                href="https://ifis.airways.co.nz/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                IFIS
                                                            </a>
                                                            .
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    width: '20%',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={{
                                                    fontWeight: '600',
                                                    minWidth: '30px',
                                                }}
                                            >
                                                {aawData.area}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontWeight: '600',
                                                    minWidth: '70px',
                                                }}
                                            >
                                                ({aawData.name})
                                            </Text>
                                        </div>

                                        <div
                                            style={[
                                                styles.boxCell,
                                                {
                                                    display: 'flex',
                                                    justifyContent:
                                                        'flex-start',
                                                    width: '80%',
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    styles.aawText,
                                                    {
                                                        paddingTop: 1,
                                                        fontWeight: '100',
                                                    },
                                                ]}
                                            >
                                                {
                                                    aawData.data.split('')
                                                    // WORKAROUND TO MAINTAIN EQUAL SPACING - ref: https://github.com/diegomura/react-pdf/issues/824
                                                }
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </View>
        )
    }
    return null
}
