import React from 'react'
import { useRecoilState } from 'recoil'
import { Box, Center, IconButton, Button, Text } from '@chakra-ui/react'
import {
    sliderPlayingState,
    sliderLoopingState,
} from '../../../../../globalState'
// import { isMobileOnly } from 'react-device-detect'
import PropTypes from 'prop-types'
import './index.css'
import { MdOutlineLoop } from 'react-icons/md'
import { BsStopFill, BsFillPlayFill } from 'react-icons/bs'

// *******************************************************
// Folder Button
// *******************************************************
export function FolderButton({
    folderItemColor,
    noFocus,
    hide,
    onClick,
    icon,
    isDisabled,
    color,
}) {
    return (
        <Box display={hide && 'none'}>
            <Center h="100%" w="40px">
                <IconButton
                    w="30px"
                    h="30px"
                    minW="30px"
                    isDisabled={isDisabled}
                    style={{ transition: 'transform 0.2s' }}
                    fontSize="1.5rem"
                    borderRadius={'20px'}
                    bgColor={{
                        base: isDisabled
                            ? 'rgba(255,255,255,0.1)'
                            : 'rgba(255,255,255,0.3)',
                    }}
                    color={folderItemColor}
                    _hover={
                        !isDisabled && {
                            backgroundColor: 'rgba(255,255,255,0.4)',
                        }
                    }
                    icon={icon}
                    onClick={onClick}
                    {...noFocus}
                />
            </Center>
        </Box>
    )
}

// *******************************************************
// Play Button
// *******************************************************
export function LoopButton({
    selectedItemColor,
    folderItemColor,
    noFocus,
    hide,
}) {
    const [looping, setLooping] = useRecoilState(sliderLoopingState)
    return (
        <Box display={hide && 'none'}>
            <Center h="100%" w="40px">
                <IconButton
                    w="30px"
                    h="30px"
                    minW="30px"
                    style={{ transition: 'transform 0.2s' }}
                    fontSize="1.5rem"
                    borderRadius={'20px'}
                    bgColor={
                        looping
                            ? 'rgba(255,255,255,0.3)'
                            : 'rgba(255,255,255,0)'
                    }
                    color={
                        looping
                            ? 'rgba(255,255,255,1)'
                            : 'rgba(255,255,255,0.8)'
                    }
                    _hover={{
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        color: 'rgba(255,255,255,1)',
                    }}
                    icon={<MdOutlineLoop />}
                    onClick={() => setLooping(!looping)}
                    {...noFocus}
                />
            </Center>
        </Box>
    )
}

export function PlayButton({
    selectedItemColor,
    folderItemColor,
    noFocus,
    hide,
}) {
    const [playing, setPlaying] = useRecoilState(sliderPlayingState)

    return (
        <Box display={hide && 'none'}>
            <Center h="40px" w="40px">
                <IconButton
                    w="30px"
                    h="30px"
                    minW="30px"
                    paddingLeft={playing ? '0px' : '1px'}
                    fontSize="1.5rem"
                    style={{ transition: 'transform 0.2s' }}
                    borderRadius={'20px'}
                    bgColor={
                        playing
                            ? 'rgba(255,255,255,0.3)'
                            : 'rgba(255,255,255,0.3)'
                    }
                    color={
                        playing ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)'
                    }
                    _hover={{
                        backgroundColor: 'rgba(255,255,255,0.2)',
                    }}
                    icon={playing ? <BsStopFill /> : <BsFillPlayFill />}
                    onClick={() => setPlaying(!playing)}
                    {...noFocus}
                />
            </Center>
        </Box>
    )
}

export function SpeedButton({ noFocus, hide, speedHandler, speed }) {
    return (
        <Box display={hide && 'none'}>
            <Center h="40px" w="40px">
                <Button
                    w="30px"
                    h="30px"
                    minW="30px"
                    style={{ transition: 'transform 0.2s' }}
                    borderRadius={'20px'}
                    bgColor={'rgba(255,255,255,0.3)'}
                    color={'rgba(255,255,255,1)'}
                    _hover={{
                        backgroundColor: 'rgba(255,255,255,0.2)',
                    }}
                    icon={<BsFillPlayFill />}
                    onClick={speedHandler}
                    {...noFocus}
                >
                    <Text size="xs">{speed}</Text>
                </Button>
            </Center>
        </Box>
    )
}

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
    position: 'absolute',
    height: 90,
    width: '100%',
    zIndex: 5,
    transform: 'translate(0%, -50%)',
    cursor: 'pointer',
}

const railInnerStyle = {
    position: 'absolute',
    width: '101.2%',
    left: '-2px',
    height: 6,
    transform: 'translate(0%, -50%)',
    borderRadius: 6,
    pointerEvents: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
}

export function SliderRail({ getRailProps, hoverHandler, setHoverVisible }) {
    function hover(e) {
        hoverHandler(e.nativeEvent.offsetX, e.target.offsetWidth)
    }
    return (
        <>
            <div
                id="main-rail"
                style={railOuterStyle}
                {...getRailProps()}
                onMouseMove={hover}
                onMouseEnter={(e) => setHoverVisible(true)}
                onMouseLeave={(e) => setHoverVisible(false)}
            />
            <div style={railInnerStyle} id="inner-rail" />
        </>
    )
}

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
    handle: { id, value, percent },
    getHandleProps,
    sliding,
}) {
    return (
        <div
            style={{
                left: `${percent}%`,
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                zIndex: 7,
                cursor: 'pointer',
                height: '50px',
                width: '50px',
                backgroundColor: 'none',
            }}
            {...getHandleProps(id)}
        >
            <div
                className="outside-circle"
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    width: sliding ? 40 : 12,
                    height: sliding ? 40 : 12,
                    transition: 'width 0.3s, height 0.3s',
                    borderRadius: '50%',
                }}
            />
            <div
                className="circle"
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    width: sliding ? 15 : 12,
                    height: sliding ? 15 : 12,
                    transition: 'width 0.2s, height 0.3s',
                    borderRadius: '50%',
                }}
            />
        </div>
    )
}

export function HoverHandle({ percent, time, isNow }) {
    return (
        <div
            className={'hover-handle'}
            style={{
                left: `${percent}%`,
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                zIndex: 3,
                cursor: 'pointer',
                backgroundColor: 'none',
            }}
        >
            <div
                style={{
                    left: `${percent}%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -195%)',
                    zIndex: 2,
                    width: 'auto',
                    height: 'auto',
                    minWidth: 100,
                    padding: 5,
                    borderRadius: 15,
                    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.3)',
                    color: '#FFFFFF',
                    fontWeight: 500,
                    backgroundColor: '#3e4649',
                    textAlign: 'center',
                }}
            >
                {isNow ? 'Now' : time}
                <div
                    className="pointer"
                    style={{
                        position: 'absolute',
                        bottom: -10,
                        left: '50%',
                        transform: 'translate(-50%, -50%) rotate(45deg) ',
                        backgroundColor: '#3e4649',
                        width: 10,
                        height: 10,
                    }}
                />
                <div
                    className="circle"
                    style={{
                        position: 'absolute',
                        bottom: '-136%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#3e4649',
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                    }}
                />
            </div>
        </div>
    )
}

HoverHandle.propTypes = {
    percent: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    isNow: PropTypes.bool.isRequired,
}

HoverHandle.defaultProps = {
    disabled: false,
}

Handle.propTypes = {
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    format: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

Handle.defaultProps = {
    disabled: false,
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
    return (
        <div
            style={{
                position: 'absolute',
                transform: 'translate(0%, -50%)',
                height: 6,
                zIndex: 1,
                backgroundColor: disabled
                    ? '#999'
                    : 'rgba(255, 255, 255, 0.75)',
                borderRadius: 4,
                cursor: 'pointer',
                left: `${source.percent}%` && '-2px',
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    )
}

Track.propTypes = {
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

Track.defaultProps = {
    disabled: false,
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, format }) {
    return (
        <div className="ticksDisplay">
            <div
                style={{
                    position: 'absolute',
                    marginTop: 3,
                    width: 2,
                    height: 6,
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    // boxShadow: '1px 1px 2px rgba(0,0,0,0.08)',
                    left: `${tick.percent}%`,
                }}
            />
            <div
                className="unselectable"
                style={{
                    position: 'absolute',
                    marginTop: 10,
                    lineHeight: '1.5',
                    fontSize: '9px',
                    color: 'rgba(255,255,255,0.9)',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                    fontWeight: 500,
                    textAlign: 'center',
                    transform: 'translate(-50%, 0px)',
                    fontFamily: 'Arial, san-serif',
                    width: 'auto',
                    left: `${tick.percent}%`,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    )
}

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
}

Tick.defaultProps = {
    format: (d) => d,
}
