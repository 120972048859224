import React, { useCallback, useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { Webcam } from './components'
import {
    Center,
    Spinner,
    Button,
    ButtonGroup,
    Flex,
    Text,
    IconButton,
    Icon,
} from '@chakra-ui/react'
import { isMobileOnly, isIOS } from 'react-device-detect'

import { pollingPaused, clickedFeaturesState } from '../../../../../globalState'
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa'

import { useSwipeable } from 'react-swipeable'
import FullWidthError from '../../FullWidthError'

export default function WebcamsContent(props) {
    const { getAccessTokenSilently } = useAuth0()
    const {
        data,
        elevation,
        tabIndex,
        setTabIndex,
        showErrors,
        fullscreen,
        setFullscreen,
        webcamsModalFullscreen,
        minimiseOnZoom,
        setMinimiseOnZoom,
        aerodromesFullscreen,
    } = props
    const [dataLoading, setDataLoading] = useState(true)
    const [error, setError] = useState([])
    const [webcamData, setWebcamData] = useState(null)
    const [webcams, setWebcams] = useState(null)
    const [selectedWebcam, setSelectedWebcam] = useState(null)
    const [selectedWebcamPosition, setSelectedWebcamPosition] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const clickedFeatures = useRecoilValue(clickedFeaturesState)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const [selectedTime, setSelectedTime] = useState(null)
    const webcamsModal = clickedFeatures[0].layer === 'webcams'

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    useEffect(() => {
        setPollingPaused(dataLoading)
    }, [dataLoading, setPollingPaused])

    const fetchWebcam = useCallback(
        async (id) => {
            const accessToken = await getAccessTokenSilently()
            const res = await fetch(`${window.origin}/data/webcam/${id}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            })
            return res.json()
        },
        [getAccessTokenSilently]
    )

    const loadedWebcamIds = webcams && Object.keys(webcams)
    const newWebcamIds = data?.webcams?.map((webcam) => webcam.id)
    const newWebcamsLoaded =
        (newWebcamIds && newWebcamIds.length) !==
            (loadedWebcamIds && loadedWebcamIds.length) ||
        newWebcamIds?.some((id) => !loadedWebcamIds.includes(id))

    // This has not been converted to react-query intentionally because of its complexity.
    useEffect(() => {
        // only update if data.webcams is updated
        if (data.webcams && data.webcams.length && newWebcamsLoaded) {
            setSelectedWebcam(null) // clear out selected webcam
            setWebcams({}) // clear out webcams data
            setDataLoading(true)

            data.webcams.forEach((webcam, i, arr) => {
                fetchWebcam(webcam.id)
                    .then((webcamData) => {
                        setWebcams((prevState) => ({
                            ...prevState,
                            ...{
                                [webcam.id]: {
                                    orientation: webcam.orientation,
                                    images: webcamData,
                                },
                            },
                        }))
                        if (i === arr.length - 1) {
                            setDataLoading(false)
                        }
                        if (webcamData.length < 1) {
                            setDataLoading(false)
                            setError(['No Data'])
                        }
                    })
                    .catch((error) => {
                        setError((prevError) => [...prevError, error])
                        setDataLoading(false)
                    })
            })
        }
    }, [data.webcams, newWebcamsLoaded, setWebcams, fetchWebcam])

    useEffect(() => {
        webcams && !selectedWebcam && setSelectedWebcam(Object.keys(webcams)[0])
    }, [webcams, selectedWebcam])

    // This increases button size when label title is too long
    const webcamsNumber =
        !dataLoading && webcams && Object.entries(webcams).length
    const webcam1 =
        !dataLoading &&
        webcams &&
        Object.entries(Object.entries(webcams)[0])[1][1].orientation
    const webcam2 =
        !dataLoading &&
        webcams &&
        webcamsNumber > 1 &&
        Object.entries(Object.entries(webcams)[1])[1][1].orientation
    const webcam3 =
        !dataLoading &&
        webcams &&
        webcamsNumber > 2 &&
        Object.entries(Object.entries(webcams)[2])[1][1].orientation
    const webcam4 =
        !dataLoading &&
        webcams &&
        webcamsNumber > 3 &&
        Object.entries(Object.entries(webcams)[2])[1][1].orientation
    const webcamsLabelLength =
        !dataLoading &&
        webcams &&
        ((webcam1 && webcam1.length > 10) ||
            (webcam2 && webcam2.length > 10) ||
            (webcam3 && webcam3.length > 10) ||
            (webcam4 && webcam4.length > 10))

    // TODO - @Jason add swipe functionality to webcam images.
    // MOBILE SLIDE CHANGE BY SWIPE LEFT/RIGHT
    // const timeSwipe = useSwipeable({
    //     onSwipedLeft: () => {
    //         if (selectedWebcamPosition > 0) {
    //             setSelectedWebcamPosition(selectedWebcamPosition - 1)
    //         }
    //     },
    //     onSwipedRight: () => {
    //         if (selectedWebcamPosition < 2) {
    //             setSelectedWebcamPosition(selectedWebcamPosition + 1)
    //         }
    //     },
    // })

    const tabSwipeWebcams = useSwipeable({
        onSwipedLeft: () => {
            setTabIndex(tabIndex + 1)
        },
        onSwipedRight: () => {
            setTabIndex(
                tabIndex -
                    (1 +
                        (!data.atis && 1) +
                        (!data.taf && 1) +
                        (!data.metar && 1))
            )
        },
    })

    useEffect(() => {
        if (webcams && selectedWebcam) {
            const webcamInfo = webcams[selectedWebcam]
            // console.log('FIRING selectedWebcam', selectedWebcam)
            // console.log('FIRING webcamInfo', webcamInfo)
            setWebcamData({
                key: `Webcam${selectedWebcam}${Object.keys(webcams).indexOf(
                    selectedWebcam
                )}`,
                id: selectedWebcam,
                ...webcamInfo,
            })
        }
    }, [selectedWebcam, webcams])

    // Convert elevation from meters to feet (rounded to nearest whole number)
    const convertToFeet = (elevation) => {
        const feetValue = elevation !== 0 ? Math.floor(elevation * 3.28084) : 0
        return feetValue
    }

    if (dataLoading) {
        return (
            <Center w="100%" h="100%" mt="-40px">
                <Spinner
                    color={'light.200'}
                    emptyColor="rgba(255,255,255,0.25)"
                    thickness="3px"
                    speed="0.45s"
                    boxSize="55px"
                />
            </Center>
        )
    }

    return (
        <>
            {!showErrors && !error.length && webcams && selectedWebcam ? (
                <>
                    <Flex
                        // {...timeSwipe}
                        {...tabSwipeWebcams}
                        alignItems="center"
                        justifyContent={{
                            base: 'center',
                            md:
                                !webcamsModal &&
                                (data.webcams.length > 2
                                    ? 'flex-end'
                                    : 'flex-start'),
                            ml:
                                data.webcams.length > 2
                                    ? 'flex-end'
                                    : 'flex-start',
                        }}
                        position={{
                            base: webcamsModal ? 'relative' : 'fixed',
                            ml:
                                data.webcams.length > 2
                                    ? 'relative'
                                    : 'absolute',
                        }}
                        bottom={{
                            base: webcamsModal
                                ? '0'
                                : minimiseOnZoom
                                ? '-80px'
                                : isMobileOnly && isIOS
                                ? '84px'
                                : '69px',
                            md: !webcamsModal && 'unset',
                            ml: 'unset',
                        }}
                        ml={{
                            base: webcamsModal && '-1.5rem',
                            md: !webcamsModal && '0px',
                            ml: '0px',
                        }}
                        pt={{
                            base: '12px',
                            md: !webcamsModal && '5px',
                            ml: '5px',
                        }}
                        pb={{
                            base: '13px',
                            ml: data.webcams.length > 2 ? '10px' : '0',
                        }}
                        mt={{
                            base:
                                webcamsModal && minimiseOnZoom
                                    ? '-60px'
                                    : '0px',
                            md: '0px',
                            ml: '0px',
                        }}
                        mb={{
                            base: webcamsModal ? '15px' : '0px',
                            md: !webcamsModal && '0px',
                            ml: '0px',
                        }}
                        opacity={{
                            base: !webcamsModal && minimiseOnZoom ? '0' : '1',
                            md: '1',
                        }}
                        left={{
                            base: '0px',
                            md: !webcamsModal && 'unset',
                            ml: 'unset',
                        }}
                        bg={{
                            base: 'light.600',
                            md: !webcamsModal && 'transparent',
                            ml: 'transparent',
                        }}
                        zIndex="100"
                        borderTop={
                            !webcamsModal && {
                                base: '1px solid #e9edf3',
                                md: 'none',
                            }
                        }
                        borderBottom={{
                            base: '1px solid #e9edf3',
                            md: !webcamsModal && 'none',
                            ml: 'none',
                        }}
                        transition="all ease 400ms"
                        clipPath="inset(-50px 0px 0px 0px)"
                        backdropFilter={{ base: 'blur(20px)', md: 'none' }}
                        width={{
                            base: webcamsModal ? 'calc(100% + 3rem)' : '100%',
                            md: !webcamsModal && 'auto',
                            ml: data.webcams.length > 2 ? '100%' : 'auto',
                        }}
                        gap="15px"
                    >
                        {webcamsModal && (
                            <IconButton
                                display={{
                                    base: 'none',
                                    ml: 'flex',
                                }}
                                fontWeight="400"
                                color="gray.600"
                                transition="color 0s"
                                variant="outline"
                                fontSize="1rem"
                                height="28px"
                                minHeight="28px"
                                width="34px"
                                style={{
                                    minWidth: '34px',
                                }}
                                borderColor="gray.200"
                                paddingLeft="8px"
                                paddingRight="8px"
                                pb="2px"
                                borderRadius="10px"
                                {...noFocus}
                                onClick={() => {
                                    setFullscreen((prevState) => !prevState)
                                }}
                                _hover={'#F2F7FA'}
                                _active={{
                                    background: '#F2F7FA',
                                    boxShadow:
                                        'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                }}
                                icon={
                                    <Icon
                                        mx={'2px'}
                                        mt="2px"
                                        style={{
                                            marginInlineEnd: '0.2rem',
                                            marginRight: '0.2rem',
                                        }}
                                        transform="rotate(45deg)"
                                        as={
                                            fullscreen
                                                ? FaCompressAlt
                                                : FaExpandAlt
                                        }
                                    />
                                }
                            />
                        )}
                        <ButtonGroup
                            borderRadius="20px"
                            px="2px"
                            minHeight="28px"
                            overflow="hidden"
                            bg="rgba(8, 38, 71, 0.04)"
                            boxShadow={
                                data.webcams.length > 1
                                    ? 'inset 0px 0px 3px -1px rgba(0,0,0,0.35)'
                                    : 'none'
                            }
                            border={
                                data.webcams.length > 1
                                    ? 'none'
                                    : '1px solid #e5e6e7'
                            }
                        >
                            {Object.keys(webcams).map((webcam, i) => (
                                <Button // DIRECTION BUTTON
                                    key={`WebcamButton${selectedWebcam}${i}`}
                                    bg="transparent"
                                    variant="outline"
                                    fontSize="0.9rem"
                                    fontWeight="600"
                                    height={{
                                        base: '32px',
                                        ml: '28px',
                                    }}
                                    width={{
                                        base: webcamsLabelLength
                                            ? '124px'
                                            : '88px',
                                        ml: webcamsLabelLength
                                            ? '124px'
                                            : '88px',
                                    }}
                                    cursor={
                                        data.webcams.length > 1
                                            ? 'pointer'
                                            : 'not-allowed'
                                    }
                                    marginInlineStart="0px !important"
                                    border="none"
                                    justifyContent="center"
                                    paddingLeft="2px"
                                    paddingRight="2px"
                                    py="2px"
                                    {...noFocus}
                                    onClick={() => {
                                        setSelectedWebcam(webcam)
                                        setSelectedWebcamPosition(i)
                                    }}
                                    zIndex="1"
                                    _active={{
                                        bg: 'none',
                                    }}
                                    _focus={{
                                        bg: 'none',
                                    }}
                                    _hover={{
                                        bg: 'none',
                                        opacity: '1',
                                    }}
                                >
                                    <Text
                                        zIndex="1"
                                        paddingLeft="0px"
                                        lineHeight="1.3"
                                        textTransform="uppercase"
                                        fontSize="0.7rem"
                                        color={
                                            selectedWebcam === webcam &&
                                            data.webcams.length > 1
                                                ? 'light.200'
                                                : 'light.100'
                                        }
                                        fontWeight={
                                            selectedWebcam === webcam &&
                                            data.webcams.length > 1
                                                ? '700'
                                                : '600'
                                        }
                                        opacity={
                                            selectedWebcam === webcam
                                                ? '1'
                                                : '0.6'
                                        }
                                        _active={{
                                            color:
                                                selectedWebcam === webcam &&
                                                data.webcams.length > 1
                                                    ? 'light.200'
                                                    : 'light.100',
                                            fontWeight:
                                                selectedWebcam === webcam &&
                                                data.webcams.length > 1
                                                    ? '700'
                                                    : '600',
                                            opacity:
                                                selectedWebcam === webcam
                                                    ? '1'
                                                    : '0.6',
                                        }}
                                        _selected={{
                                            color: 'light.200',
                                            fontWeight: '700',
                                            opacity: '1',
                                        }}
                                        transition="opacity 400ms, color 400ms, fontWeight ease-out 400ms"
                                    >
                                        {webcams[webcam].orientation}
                                    </Text>
                                </Button>
                            ))}
                            {data.webcams.length > 1 && (
                                <Flex // BUTTON INDICATOR
                                    position="absolute"
                                    marginInlineStart="0px !important"
                                    mt="2px"
                                    ml="0px"
                                    px="44px"
                                    height={{
                                        base: '28px',
                                        ml: '24px',
                                    }}
                                    width={
                                        webcamsLabelLength ? '124px' : '74px'
                                    }
                                    background="white"
                                    borderRadius="20px"
                                    bg="light.10"
                                    boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                    transform={`translateX(${selectedWebcamPosition}00%)`}
                                    transition="transform 400ms"
                                ></Flex>
                            )}
                        </ButtonGroup>

                        {elevation && (
                            <Flex
                                color="gray.500"
                                pl="5px"
                                pr={{
                                    base: '0px',
                                    md: !webcamsModal && '15px',
                                    ml: '15px',
                                }}
                                alignItems="center"
                                // fontStyle="italic"
                            >
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight="500"
                                    pr="5px"
                                    whiteSpace="pre"
                                >
                                    Elevation:
                                </Text>
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight="400"
                                    whiteSpace="pre"
                                >
                                    {convertToFeet(elevation)} ft
                                </Text>
                            </Flex>
                        )}
                        {/* {!loaded ? (
                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    md: !webcamsModal && 'row',
                                    ml: 'row',
                                }}
                                alignItems="center"
                                justifyContent="center"
                                display={{
                                    base: 'none',
                                    md: webcamsModal ? 'flex' : 'none',
                                }}
                            >
                                <Skeleton
                                    startColor="#F7F8F9"
                                    endColor="#DBDEE1"
                                    borderRadius="6px"
                                    height="14px"
                                    width="160px"
                                    ml="5px"
                                />
                            </Flex>
                        ) : (
                            <Flex
                                flexDirection={{
                                    base: 'column',
                                    md: !webcamsModal && 'row',
                                    ml: 'row',
                                }}
                                alignItems="center"
                                justifyContent="center"
                                display={{
                                    base: 'none',
                                    md: webcamsModal ? 'flex' : 'none',
                                }}
                                gap="5px"
                                pr="10px"
                            >
                                <Text
                                    align={'center'}
                                    color="light.100"
                                    fontSize="0.8rem"
                                    fontWeight="500"
                                    px="5px"
                                >
                                    {selectedTime && formatDateTime(selectedTime, timeZone)}
                                </Text>
                                <Text
                                    align={'center'}
                                    fontSize="0.8rem"
                                    color="light.100"
                                    fontWeight="300"
                                    px="5px"
                                >
                                    {selectedTime &&
                                        parseISO(selectedTime) &&
                                        formatDistanceToNowStrict(
                                            parseISO(selectedTime),
                                            {
                                                addSuffix: true,
                                                unit: 'minute',
                                            }
                                        )}
                                </Text>
                            </Flex>
                        )} */}
                    </Flex>
                    {webcamData && webcamData?.id === selectedWebcam && (
                        <Webcam
                            key={webcamData.key}
                            webcamsModal={webcamsModal}
                            data={webcamData}
                            loaded={loaded}
                            setLoaded={setLoaded}
                            selectedTime={selectedTime}
                            setSelectedTime={setSelectedTime}
                            minimiseOnZoom={minimiseOnZoom}
                            setMinimiseOnZoom={setMinimiseOnZoom}
                            aerodromesFullscreen={aerodromesFullscreen}
                            webcamsModalFullscreen={webcamsModalFullscreen}
                        />
                    )}
                </>
            ) : (
                <FullWidthError message="Error downloading webcam images from the MetService. Please try again later." />
            )}
        </>
    )
}
