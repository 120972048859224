import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { parseISO, formatDistanceToNow } from 'date-fns'
import formatDateTime from '../../../../../util/dateFormatter'
import { presentWeatherTranslate } from '../../../../../util/metDecode'
import { AwsIcon, ErrorIcon } from './assets/SvgImages'

export default function AWSExportPDF(props) {
    const {
        styles,
        timeZone,
        briefingData,
        showOutdatedErrors,
        awsSort,
        excludedAws,
    } = props

    const aws = briefingData && briefingData.res && briefingData.res.aws

    return (
        // AWS SECTION
        <View style={styles.section}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    wrap={false}
                    style={[
                        styles.sectionTitle,
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        },
                    ]}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <AwsIcon
                            width="16"
                            height="16"
                            viewBox="0 0 48 48"
                            fill="rgb(23, 120, 183)"
                        />

                        <div
                            style={{
                                paddingLeft: '7',
                                color: 'rgb(23, 120, 183)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                textAlign: 'left',
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: '600',
                                }}
                            >
                                AUTOMATIC WEATHER STATIONS
                            </Text>
                        </div>
                    </div>
                    <div
                        style={{
                            color: 'rgb(23, 120, 183)',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            textAlign: 'right',
                            paddingRight: 2,
                        }}
                    >
                        {timeZone !== 'UTC' && (
                            <Text
                                style={{
                                    fontWeight: '300',
                                    fontSize: '6pt',
                                    textAlign: 'right',
                                    textTransform: 'unset',
                                    color: 'rgb(111, 127, 145)',
                                }}
                            >
                                Time references are in UTC.
                            </Text>
                        )}
                        <Text
                            style={{
                                paddingLeft: 3,
                                fontWeight: '300',
                                fontSize: '6pt',
                                textAlign: 'right',
                                textTransform: 'unset',
                                color: 'rgb(111, 127, 145)',
                            }}
                        >
                            All heights are in feet AGL.
                        </Text>
                        <Text
                            style={{
                                paddingLeft: 3,
                                fontWeight: '300',
                                fontSize: '6pt',
                                textAlign: 'right',
                                textTransform: 'unset',
                                color: 'rgb(111, 127, 145)',
                            }}
                        >
                            Wind: °True
                        </Text>
                    </div>
                </div>

                {briefingData &&
                    briefingData.res.aws &&
                    briefingData.res.aws
                        .filter(
                            (key) =>
                                key.data ||
                                (key.data?.obs_timestamp && // ensure there is more data other than just the timestamp
                                    Object.keys(key.data)?.length > 1)
                        )
                        .sort((a, b) =>
                            awsSort ? a.name > b.name : a.lat < b.lat ? 1 : -1
                        )
                        .map((aws) => {
                            if (
                                excludedAws &&
                                excludedAws.includes(aws.designator)
                            ) {
                                return null
                            } else {
                                return (
                                    <div key={`AWS-${aws.designator}`}>
                                        <AWSExportPDFContent
                                            styles={styles}
                                            aws={aws}
                                            showOutdatedErrors={
                                                showOutdatedErrors
                                            }
                                            timeZone={timeZone}
                                        />
                                    </div>
                                )
                            }
                        })}

                {aws ? (
                    aws.length > 0 &&
                    aws.filter(
                        (aws) =>
                            !(
                                excludedAws &&
                                excludedAws.includes(aws.designator)
                            )
                    ).length === 0 && (
                        <div /// NO INFORMATION AVAILABLE
                            style={[
                                styles.sectionContent,
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderBottom: '1px solid #e5e5e5',
                                },
                            ]}
                        >
                            <div
                                style={[
                                    styles.boxCell,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexGrow: '10',
                                        width: '100%',
                                    },
                                ]}
                            >
                                <Text
                                    style={{
                                        fontWeight: '300',
                                        opacity: '0.6',
                                    }}
                                >
                                    All AWS have been excluded from the briefing
                                </Text>
                            </div>
                        </div>
                    )
                ) : (
                    <div /// NO AWS IN SELECTED AREAS
                        style={[
                            styles.sectionContent,
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                borderBottom: '1px solid #e5e5e5',
                            },
                        ]}
                    >
                        <div
                            style={[
                                styles.boxCell,
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexGrow: '10',
                                    width: '100%',
                                },
                            ]}
                        >
                            <Text
                                style={{
                                    fontWeight: '300',
                                    opacity: '0.6',
                                }}
                            >
                                There is no AWS information available for the
                                selected areas
                            </Text>
                        </div>
                    </div>
                )}
            </div>
        </View>
    )
}

export function AWSExportPDFContent(props) {
    const { styles, aws, showOutdatedErrors, timeZone } = props

    // AWS Data
    const awsData = aws && aws.data ? aws.data : false

    // AWS Out of Date Error Message Times
    const awsIssuedTime =
        aws && awsData && awsData.obs_timestamp ? awsData.obs_timestamp : null

    // Weather
    const winddir =
        awsData &&
        (awsData.winddir_10mnavg // DEGREES TRUE
            ? awsData.winddir_10mnavg // 10 min average
            : awsData.winddir_01mnavg
            ? awsData.winddir_01mnavg // else 1 min average
            : awsData.winddir_01hravg
            ? awsData.winddir_01hravg // else 1 hour average
            : null)

    const winddirmax =
        awsData &&
        (awsData.windcw__10mnmax // DEGREES TRUE
            ? awsData.windcw__10mnmax // 10 min average
            : awsData.windcw__01mnmax
            ? awsData.windcw__01mnmax // else 1 min average
            : awsData.windcw__01hrmax
            ? awsData.windcw__01hrmax // else 1 hour average
            : null)

    const winddirmin =
        awsData &&
        (awsData.windccw_10mnmax // DEGREES TRUE
            ? awsData.windccw_10mnmax // 10 min average
            : awsData.windccw_01mnmax
            ? awsData.windccw_01mnmax // else 1 min average
            : awsData.windccw_01hrmax
            ? awsData.windccw_01hrmax // else 1 hour average
            : null)

    const windspeed =
        awsData &&
        (awsData.windspd_10mnavg
            ? awsData.windspd_10mnavg // 10 min average
            : awsData.windspd_01mnavg
            ? awsData.windspd_01mnavg // else 1 min average
            : awsData.windspd_01hravg
            ? awsData.windspd_01hravg // else 1 hour average
            : null)

    const windgust =
        awsData &&
        (awsData && awsData.windgst_10mnmax
            ? awsData.windgst_10mnmax // 10 min average
            : awsData.windgst_01mnmax
            ? awsData.windgst_01mnmax // else 1 min average
            : awsData.windgst_01hrmax
            ? awsData.windgst_01hrmax // else 1 hour average
            : null)

    // const windlull =
    //     awsData &&
    //     (awsData && awsData.windlul_10mnmin
    //         ? awsData.windlul_10mnmin // 10 min average
    //         : awsData.windlul_01mnmin
    //         ? awsData.windlul_01mnmin // else 1 min average
    //         : null)

    const temp =
        awsData &&
        (awsData.airtemp_01mnavg === 0
            ? 0
            : awsData && awsData.airtemp_01mnavg
            ? Math.round(awsData.airtemp_01mnavg) // 1 min average
            : null)

    const dewpoint =
        awsData &&
        (awsData.dewtemp_01mnavg === 0
            ? 0
            : awsData && awsData.dewtemp_01mnavg
            ? Math.round(awsData.dewtemp_01mnavg) // 1 min average
            : null)

    // Cloud
    const cloudLayers = []

    const cloudConversion = [
        {
            label: 'Overcast',
            amount: 8,
        },
        {
            label: 'Broken',
            amount: 7,
        },
        {
            label: 'Broken',
            amount: 6,
        },
        {
            label: 'Broken',
            amount: 5,
        },
        {
            label: 'Scattered',
            amount: 4,
        },
        {
            label: 'Scattered',
            amount: 3,
        },
        {
            label: 'Few',
            amount: 2,
        },
        {
            label: 'Few',
            amount: 1,
        },
        {
            label: 'Sky Clear',
            amount: 0,
        },
    ]

    // Cloud layer conversion
    const handleCloudLayer = (awsData, [amount, value]) => {
        if (awsData && awsData[amount] && awsData[value]) {
            const cloudAmount = awsData[amount]
            const cloudLayer = cloudConversion.find(
                (layer) => layer.amount === cloudAmount
            )
            if (cloudLayer) {
                cloudLayers.push({
                    amount: cloudLayer.label,
                    value: awsData[value],
                })
            }
        }
    }

    cloudLayers.length = 0
    // if there is a cloud layer
    handleCloudLayer(awsData, ['cld1amt_30mnavg', 'cld1hgt_30mnavg'])
    // // if there is a second layer
    handleCloudLayer(awsData, ['cld2amt_30mnavg', 'cld2hgt_30mnavg'])
    // // if there is a third layer
    handleCloudLayer(awsData, ['cld3amt_30mnavg', 'cld3hgt_30mnavg'])
    // // if there is a fourth layer
    handleCloudLayer(awsData, ['cld4amt_30mnavg', 'cld4hgt_30mnavg'])

    const cloudPresent = cloudLayers && cloudLayers.length >= 1

    const presentWeather =
        awsData &&
        (awsData.prwxst1_10mnavg
            ? awsData.prwxst1_10mnavg // 10 min average (Station 1)
            : awsData.prwxst1_15mnavg
            ? awsData.prwxst1_15mnavg // else 15 min average (Station 1)
            : awsData.prwxst2_10mnavg
            ? awsData.prwxst2_10mnavg // 10 min average (Station 2)
            : awsData.prwxst2_15mnavg
            ? awsData.prwxst2_15mnavg // else 15 min average (Station 2)
            : awsData.prwxst3_10mnavg
            ? awsData.prwxst3_10mnavg // 10 min average (Station 3)
            : awsData.prwxst3_15mnavg
            ? awsData.prwxst3_15mnavg // else 15 min average (Station 3)
            : null)

    const visibilityData =
        awsData &&
        (awsData.visibil_10mnavg
            ? awsData.visibil_10mnavg // 10 min average
            : null)
    const visibility =
        visibilityData &&
        !visibilityData.startsWith('/') &&
        Number(visibilityData.slice(0, -3))

    const qnh =
        awsData &&
        (awsData.presqnh_01mnavg
            ? Math.round(awsData.presqnh_01mnavg) // 1 min average
            : null)

    // if no required data
    const filteredData = Object.fromEntries(
        Object.entries(awsData).filter(([key]) => key !== 'obs_timestamp')
    )
    const dataArray = Object.keys(filteredData)
    const hasData =
        dataArray &&
        dataArray.length > 0 &&
        (winddir || temp || dewpoint || visibility || qnh)

    return (
        // AWS SECTION
        <div /// MET INFORMATION
            style={[
                styles.sectionContent,
                {
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid #e5e5e5',
                },
            ]}
        >
            <div /// MET SECTION TITLE
                wrap={false}
                style={[
                    styles.boxCell,
                    {
                        borderRight: '1px solid #e5e5e5',
                        flexGrow: '1',
                        width: '20%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    },
                ]}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '2',
                    }}
                >
                    <div
                        style={{
                            color: 'rgb(111, 127, 145)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            textAlign: 'left',
                        }}
                    >
                        <Text
                            style={{
                                fontWeight: '700',
                                fontSize: '8pt',
                            }}
                        >
                            {aws.designator}
                        </Text>
                        <Text
                            style={{
                                fontWeight: '500',
                                fontSize: '7pt',
                            }}
                        >
                            {aws.name}
                        </Text>
                    </div>
                </div>
            </div>
            <div /// AWS SECTION CONTENT
                style={[
                    styles.boxCell,
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: '10',
                        width: '80%',
                    },
                ]}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    {(showOutdatedErrors || aws.outofdate) && (
                        <div
                            wrap={false}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                marginBottom: '5',
                            }}
                        >
                            <div
                                style={[
                                    styles.sectionContent,
                                    {
                                        width: '6%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    },
                                ]}
                            >
                                <ErrorIcon
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="rgb(16, 170, 112)"
                                />
                            </div>
                            <div
                                style={[
                                    styles.sectionContent,
                                    {
                                        width: '94%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    },
                                ]}
                            >
                                <Text style={styles.error}>
                                    <span>
                                        There has been an issue with the
                                        connection between PreFlight and
                                        MetService. This{' '}
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: '500',
                                        }}
                                    >
                                        AWS
                                    </span>
                                    <span>
                                        {' '}
                                        was last validated with MetService more
                                        than{' '}
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: '500',
                                        }}
                                    >
                                        {formatDistanceToNow(
                                            parseISO(aws.updated),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </span>
                                    <span>
                                        . There is a high likelihood that the
                                        latest AWS information is not available
                                        in PreFlight. Please check back again
                                        soon.
                                    </span>
                                </Text>
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div
                            style={[
                                styles.sectionContent,
                                {
                                    width: '100%',
                                    display: 'flex',
                                    flexGrow: '10',
                                    flexDirection: 'column',
                                },
                            ]}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '2',
                                    paddingBottom: '6',
                                }}
                            >
                                <Text
                                    style={[
                                        styles.labelNormal,
                                        {
                                            width: '75',
                                            fontWeight: '500',
                                        },
                                    ]}
                                >
                                    Observation Time:
                                </Text>
                                <Text style={styles.textNormal}>
                                    {aws &&
                                        awsData?.obs_timestamp &&
                                        formatDateTime(awsIssuedTime, timeZone)}
                                </Text>
                            </div>

                            {hasData ? (
                                <div // Content
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '6',
                                        paddingBottom: '9',
                                        borderTop: '1px solid #f5f5f5',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.labelNormal,
                                                {
                                                    width: '75',
                                                    fontWeight: '500',
                                                },
                                            ]}
                                        >
                                            Surface Wind:
                                        </Text>
                                        {winddir ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Text style={styles.textNormal}>
                                                    {winddir && windspeed
                                                        ? winddir
                                                              .toString()
                                                              .padStart(
                                                                  3,
                                                                  '0'
                                                              ) +
                                                          '°T ' +
                                                          ` ${windspeed} kt`
                                                        : 'Direction/Speed not available'}
                                                </Text>
                                                <Text
                                                    style={[
                                                        styles.textNormal,
                                                        {
                                                            paddingLeft: 8,
                                                        },
                                                    ]}
                                                >
                                                    {windgust
                                                        ? 'Gusts ' +
                                                          windgust +
                                                          ' kt'
                                                        : ''}
                                                </Text>
                                                <Text
                                                    style={[
                                                        styles.textNormal,
                                                        {
                                                            paddingLeft: 8,
                                                        },
                                                    ]}
                                                >
                                                    {winddirmin && winddirmax
                                                        ? winddirmin
                                                              .toString()
                                                              .padStart(
                                                                  3,
                                                                  '0'
                                                              ) +
                                                          '°' +
                                                          ' V ' +
                                                          winddirmax
                                                              .toString()
                                                              .padStart(
                                                                  3,
                                                                  '0'
                                                              ) +
                                                          '°T'
                                                        : ''}
                                                </Text>
                                            </div>
                                        ) : (
                                            <Text>No wind reported</Text>
                                        )}
                                    </div>

                                    {visibility && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelNormal,
                                                    {
                                                        width: '75',
                                                        fontWeight: '500',
                                                    },
                                                ]}
                                            >
                                                Visibility:
                                            </Text>
                                            <Text style={styles.textNormal}>
                                                {visibility === 9999
                                                    ? '> ' + visibility + ' m'
                                                    : visibility + ' km'}
                                            </Text>
                                        </div>
                                    )}

                                    {cloudLayers && cloudPresent && (
                                        <div
                                            wrap={false}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    styles.labelNormal,
                                                    {
                                                        width: '75',
                                                        fontWeight: '500',
                                                    },
                                                ]}
                                            >
                                                Sky Condition:
                                            </Text>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                {cloudLayers &&
                                                    cloudLayers.length > 0 &&
                                                    cloudLayers
                                                        .reverse()
                                                        .map((layer, i) => {
                                                            return (
                                                                <div
                                                                    key={`AWS-cloudLayers${layer}`}
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    }}
                                                                >
                                                                    {layer.amount && (
                                                                        <Text
                                                                            style={{
                                                                                paddingLeft: 5,
                                                                            }}
                                                                            whiteSpace="pre-line"
                                                                        >
                                                                            {
                                                                                layer.amount
                                                                            }
                                                                        </Text>
                                                                    )}
                                                                    {layer.value && (
                                                                        <Text
                                                                            style={{
                                                                                paddingLeft: 5,
                                                                            }}
                                                                            whiteSpace="pre-line"
                                                                        >
                                                                            {
                                                                                layer.value
                                                                            }{' '}
                                                                            ft
                                                                            AGL
                                                                        </Text>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.labelNormal,
                                                {
                                                    width: '75',
                                                    fontWeight: '500',
                                                },
                                            ]}
                                        >
                                            Temperature:
                                        </Text>
                                        <Text style={styles.textNormal}>
                                            {temp
                                                ? `${temp} °C`
                                                : 'Not available'}
                                        </Text>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.labelNormal,
                                                {
                                                    width: '75',
                                                    fontWeight: '500',
                                                },
                                            ]}
                                        >
                                            Dewpoint:
                                        </Text>
                                        <Text style={styles.textNormal}>
                                            {dewpoint
                                                ? `${dewpoint} °C`
                                                : 'Not available'}
                                        </Text>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.labelNormal,
                                                {
                                                    width: '75',
                                                    fontWeight: '500',
                                                },
                                            ]}
                                        >
                                            QNH:
                                        </Text>
                                        <Text style={styles.textNormal}>
                                            {qnh
                                                ? `${qnh} hPa`
                                                : 'Not available'}
                                        </Text>
                                    </div>

                                    {presentWeather &&
                                        !presentWeather.startsWith('/') && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        styles.labelNormal,
                                                        {
                                                            width: '75',
                                                            fontWeight: '500',
                                                        },
                                                    ]}
                                                >
                                                    Present Weather:
                                                </Text>
                                                <Text style={styles.textNormal}>
                                                    {presentWeatherTranslate(
                                                        presentWeather
                                                    )}
                                                </Text>
                                            </div>
                                        )}
                                </div>
                            ) : (
                                <div // No content
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '6',
                                        paddingBottom: '9',
                                        borderTop: '1px solid #f5f5f5',
                                    }}
                                >
                                    <Text style={styles.textNormal}>
                                        Weather information is not available at
                                        this time.
                                    </Text>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
