import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useAdminRole } from '../../../auth/hooks'
import {
    Flex,
    Box,
    Text,
    Button,
    ButtonGroup,
    useBreakpointValue,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spinner,
    useDisclosure,
    useColorModeValue,
    HStack,
    Icon,
} from '@chakra-ui/react'

import Image from '../../components/Image'
import formatDateTime, {
    formatTimeOnly,
    formatDayOnly,
} from '../../../util/dateFormatter'
import { useSwipeable } from 'react-swipeable'
import { isMobileOnly, isIOS, isDesktop } from 'react-device-detect'
import { useQuery } from 'react-query'
import { parseISO, formatDistanceToNow } from 'date-fns'
import { isFirefox } from 'react-device-detect'
import {
    timeZoneState,
    dataModeState,
    layerSelectionState,
    fullscreenModalVisibleHandler,
    pollingPaused,
    showErrorTogglesState,
} from '../../../globalState'
import { ReactComponent as mslIcon } from '../../../icons/assets/msl.svg'

import PinchDrag, { useScale } from '../components/PinchDrag'
import ZoomSlider from '../components/ZoomSlider'
import ErrorToggle from '../components/ErrorToggle'
import FullWidthError from '../components/FullWidthError'
import ErrorText from '../components/ErrorText'

export default function MslModal() {
    const { getAccessTokenSilently } = useAuth0()

    const fetchMslCharts = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/msl/charts`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const isAdmin = useAdminRole()
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const [mslTime, setMslTime] = useState(null)
    const [msl, setMsl] = useState([])
    const [imagesLoaded, setImagesLoaded] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)

    const pageScale = useBreakpointValue({
        base: 19,
        md: 18,
        lg: 13,
        xl: 11,
    })

    const pageScaleWidth = pageScale * 5 + '%'

    const { scale, setScale, xy, setXy } = useScale()
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0
    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)

    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    const [mslTimePosition, setMslTimePosition] = useState(3)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const timeZone = useRecoilValue(timeZoneState)
    const { data, status, refetch } = useQuery(`msl-charts`, fetchMslCharts, {
        refetchOnWindowFocus: false,
    })

    const { isOpen, onOpen, onClose } = useDisclosure()

    // MOBILE SLIDE CHANGE BY SWIPE LEFT/RIGHT
    const timeSwipe = useSwipeable({
        onSwipedLeft: () => {
            if (mslTimePosition > 0) {
                setMslTime(msl[mslTimePosition - 1].validat)
                setMslTimePosition(mslTimePosition - 1)
            }
        },
        onSwipedRight: () => {
            if (mslTimePosition < 2) {
                setMslTime(msl[mslTimePosition + 1].validat)
                setMslTimePosition(mslTimePosition + 1)
            }
        },
    })

    // This pauses the network connection check until it is loaded.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    useEffect(() => {
        if (data) {
            // console.log('MSL data:', data)
            mslRef.current = data
            setMsl(data)
            if (data.length >= 4) {
                setMslTime(data[3].validat, 3)
            }
        }
    }, [data])

    const mslRef = useRef(null)

    useEffect(() => {
        if (dataMode === 'met' && layerSelection.met.mslCharts) {
            mslRef.current && mslRef.current.length && refetch()
            setLoaded(false)
            setImagesLoaded(0)
            onOpen()
        } else {
            onClose()
        }
    }, [
        onOpen,
        onClose,
        dataMode,
        layerSelection,
        handleFullscreenModal,
        refetch,
    ])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    useEffect(() => {
        msl.length && setLoaded(imagesLoaded === msl.length)
    }, [msl.length, imagesLoaded])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    function onImageLoad() {
        setImagesLoaded((i) => i + 1)
    }

    function isOutOfDate() {
        const itemData =
            data && mslTime && data.filter((i) => mslTime === i.validat)[0]
        if (itemData) {
            return { outofdate: itemData.outofdate, updated: itemData.updated }
        } else {
            return { outofdate: false, updated: null }
        }
    }

    const errorShow = showOutdatedErrors || isOutOfDate().outofdate
    const timeOptionsLength = msl.length

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            md: undefined,
                        }}
                    >
                        <Flex
                            direction="column"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            minHeight={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            // added flex here as header height not sticking causing spacing issues elswhere
                        >
                            <ModalHeader
                                backgroundColor={useColorModeValue(
                                    'light.10',
                                    'light.10'
                                )}
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        isAdmin && showErrorToggle
                                            ? '100px'
                                            : '70px',
                                    md: '70px',
                                }}
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    // base: '0px 5px 10px -1px rgba(0,0,0,0.05)',
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Flex
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            paddingLeft={{
                                                base: '0px',
                                                md: '0',
                                            }}
                                            paddingTop={{
                                                base: '3px',
                                                md: '0',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '32px',
                                                }}
                                                color="light.100"
                                                as={mslIcon}
                                                ml={{ base: '0px', md: '4px' }}
                                            />
                                            <Box
                                                pl={{ base: '5px', md: '10px' }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color={useColorModeValue(
                                                        'light.100',
                                                        'light.100'
                                                    )}
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                >
                                                    Mean Sea Level Charts
                                                </Text>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        w={
                                            isAdmin && showErrorToggle
                                                ? '100%'
                                                : '0'
                                        }
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                    >
                                        <HStack
                                            spacing="15px"
                                            align="center"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-end',
                                            }}
                                        >
                                            <ErrorToggle
                                                showErrors={showNetworkErrors}
                                                setShowErrors={
                                                    setShowNetworkErrors
                                                }
                                                label="Simulate Network Error"
                                            />
                                            <ErrorToggle
                                                showErrors={showOutdatedErrors}
                                                setShowErrors={
                                                    setShowOutdatedErrors
                                                }
                                                label="Simulate Out-of-Date Error"
                                            />
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                        </Flex>
                        <ModalBody
                            p={0}
                            height={{
                                base:
                                    isMobileOnly && isIOS
                                        ? 'calc(100% - 155px)'
                                        : 'calc(100% - 140px)',
                                md: '100%',
                            }}
                            bg={{
                                base: '#edf1f7',
                                md: '#f4f7fc',
                            }}
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            {status !== 'error' && !showNetworkErrors ? (
                                <Box
                                    id="graforContainer"
                                    height="100%"
                                    ref={mslRef}
                                >
                                    {msl && (
                                        <Box
                                            bg={{
                                                base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 100%)',
                                                md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 100%)',
                                            }}
                                            h="100%"
                                            w={{ base: '100%', md: '100%' }}
                                            fontFamily="Open Sans"
                                            zIndex="1"
                                            marginTop={0}
                                            marginBottom={0}
                                            overflow="hidden"
                                        >
                                            <Box
                                                zIndex="2"
                                                h="100%"
                                                position="relative"
                                            >
                                                {msl && (
                                                    <>
                                                        <Flex // TOP TOOLBAR CONTAINER
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            width="100%"
                                                            zIndex="2"
                                                            position="relative"
                                                            pl="23px"
                                                            pr="22px"
                                                            py="5px"
                                                            bg="light.600"
                                                            boxShadow={{
                                                                base: '0px 5px 30px -5px rgba(0,0,0,0.15)',
                                                                lg: 'none',
                                                            }}
                                                        >
                                                            <Flex
                                                                flexDirection="row"
                                                                alignItems="center"
                                                                paddingStart={{
                                                                    base: '5px',
                                                                    md: '0',
                                                                }}
                                                                color="light.100"
                                                                // opacity={
                                                                //     detailsShow
                                                                //         ? '0'
                                                                //         : '1'
                                                                // }
                                                                width="100%"
                                                                maxWidth={{
                                                                    base: '100%',
                                                                    md: '230px',
                                                                }}
                                                                transition="opacity ease-in-out 300ms"
                                                            >
                                                                <Text
                                                                    paddingStart="0"
                                                                    paddingEnd="2"
                                                                    fontWeight="bold"
                                                                    width="65px"
                                                                    fontSize="0.8rem"
                                                                    lineHeight="1.3"
                                                                >
                                                                    Valid at:
                                                                </Text>
                                                                {msl.map(
                                                                    (
                                                                        validTime,
                                                                        i
                                                                    ) => (
                                                                        <Text
                                                                            key={`GraforTime${validTime.validat}`}
                                                                            display={
                                                                                mslTime ===
                                                                                validTime.validat
                                                                                    ? 'block'
                                                                                    : 'none'
                                                                            }
                                                                            paddingStart="0"
                                                                            paddingEnd="0"
                                                                            fontWeight="400"
                                                                            py="0px"
                                                                            fontSize="0.8rem"
                                                                            lineHeight="1.3"
                                                                            borderColor="transparent"
                                                                        >
                                                                            {validTime?.validat &&
                                                                                formatDateTime(
                                                                                    validTime.validat,
                                                                                    timeZone
                                                                                )}
                                                                        </Text>
                                                                    )
                                                                )}
                                                            </Flex>
                                                            <Flex
                                                                {...timeSwipe}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                position={{
                                                                    base: 'fixed',
                                                                    xl: 'relative',
                                                                }}
                                                                mb={{
                                                                    base: minimiseOnZoom
                                                                        ? '-90px'
                                                                        : '-1px',
                                                                    md: '-1px',
                                                                }}
                                                                transition="all ease 300ms"
                                                                bottom={{
                                                                    base:
                                                                        isMobileOnly &&
                                                                        isIOS
                                                                            ? '85px'
                                                                            : '70px',
                                                                    md: '0px',
                                                                    lg: '0px',
                                                                    xl: 'unset',
                                                                }}
                                                                pl={{
                                                                    base: '15px',
                                                                    md: '50px',
                                                                    xl: '5px',
                                                                }}
                                                                pr={{
                                                                    base: '15px',
                                                                    xl: '0',
                                                                }}
                                                                pt={{
                                                                    base: '15px',
                                                                    xl: '0',
                                                                }}
                                                                pb={{
                                                                    base: '20px',
                                                                    xl: '0',
                                                                }}
                                                                left={{
                                                                    base: '0px',
                                                                    xl: 'unset',
                                                                }}
                                                                bg={{
                                                                    base: isFirefox
                                                                        ? 'rgba(255,255,255,0.97)'
                                                                        : 'light.55',
                                                                    xl: 'transparent',
                                                                }}
                                                                zIndex="1"
                                                                borderBottom={{
                                                                    base: '1px solid #e9edf3',
                                                                    md: 'none',
                                                                }}
                                                                boxShadow={{
                                                                    base: '0px -5px 30px -5px rgba(0,0,0,0.15)',
                                                                    xl: 'none',
                                                                }}
                                                                clipPath="inset(-50px 0px 0px 0px)"
                                                                backdropFilter="blur(20px)"
                                                                width={{
                                                                    base: '100%',
                                                                    xl: 'auto',
                                                                }}
                                                            >
                                                                <ButtonGroup
                                                                    borderRadius="20px"
                                                                    px="2px"
                                                                    overflow="hidden"
                                                                    bg="rgba(8, 38, 71, 0.04)"
                                                                    boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.35)"
                                                                >
                                                                    {msl.map(
                                                                        (
                                                                            time,
                                                                            i
                                                                        ) => (
                                                                            <Button // MSL TIME
                                                                                key={`MslBriefing${time.validat}`}
                                                                                bg="transparent"
                                                                                variant="outline"
                                                                                fontSize="0.9rem"
                                                                                fontWeight="600"
                                                                                height={{
                                                                                    base: '36px',
                                                                                    md: '28px',
                                                                                }}
                                                                                width={{
                                                                                    base: `${
                                                                                        91 /
                                                                                        timeOptionsLength
                                                                                    }vw`,
                                                                                    md: '88px',
                                                                                }}
                                                                                marginInlineStart="0px !important"
                                                                                border="none"
                                                                                justifyContent="center"
                                                                                paddingLeft="2px"
                                                                                paddingRight="2px"
                                                                                py="2px"
                                                                                {...noFocus}
                                                                                onClick={() => {
                                                                                    setMslTime(
                                                                                        time.validat
                                                                                    )
                                                                                    setMslTimePosition(
                                                                                        i
                                                                                    )
                                                                                }}
                                                                                zIndex="1"
                                                                                _active={{
                                                                                    bg: 'none',
                                                                                }}
                                                                                _focus={{
                                                                                    bg: 'none',
                                                                                }}
                                                                                _hover={{
                                                                                    bg: 'none',
                                                                                    opacity:
                                                                                        '1',
                                                                                }}
                                                                            >
                                                                                <Flex
                                                                                    flexDirection={{
                                                                                        base: 'column',
                                                                                        md: 'row',
                                                                                    }}
                                                                                    color={
                                                                                        mslTime ===
                                                                                        time.validat
                                                                                            ? 'light.200'
                                                                                            : 'light.100'
                                                                                    }
                                                                                    _hover={{
                                                                                        opacity:
                                                                                            '0.8',
                                                                                    }}
                                                                                    _focus={{
                                                                                        color: 'light.200',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    _active={{
                                                                                        color: 'light.200',
                                                                                        fontWeight:
                                                                                            '700',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    _selected={{
                                                                                        color: 'light.200',
                                                                                        fontWeight:
                                                                                            '700',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    opacity={
                                                                                        mslTime ===
                                                                                        time.validat
                                                                                            ? '1'
                                                                                            : '0.6'
                                                                                    }
                                                                                >
                                                                                    <Text
                                                                                        pr={{
                                                                                            base: 'column',
                                                                                            md: '5px',
                                                                                        }}
                                                                                        mb={{
                                                                                            base: '-0.1rem',
                                                                                            md: '0',
                                                                                        }}
                                                                                        whiteSpace="initial"
                                                                                        zIndex="1"
                                                                                        paddingLeft="0px"
                                                                                        lineHeight="1.3"
                                                                                        textTransform="uppercase"
                                                                                        fontSize={{
                                                                                            base: '0.55rem',
                                                                                            md: '0.7rem',
                                                                                        }}
                                                                                        fontWeight={{
                                                                                            base: '400',
                                                                                            md:
                                                                                                mslTime ===
                                                                                                time.validat
                                                                                                    ? '700'
                                                                                                    : '600',
                                                                                        }}
                                                                                        transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                                    >
                                                                                        {time?.validat &&
                                                                                            formatDayOnly(
                                                                                                time.validat,
                                                                                                timeZone
                                                                                            )}
                                                                                    </Text>
                                                                                    <Text
                                                                                        whiteSpace="initial"
                                                                                        zIndex="1"
                                                                                        paddingLeft="0px"
                                                                                        lineHeight="1.3"
                                                                                        textTransform="uppercase"
                                                                                        fontSize={{
                                                                                            base: '0.8rem',
                                                                                            md: '0.7rem',
                                                                                        }}
                                                                                        fontWeight={
                                                                                            mslTime ===
                                                                                            time.validat
                                                                                                ? '700'
                                                                                                : '600'
                                                                                        }
                                                                                        transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                                    >
                                                                                        {time?.validat &&
                                                                                            formatTimeOnly(
                                                                                                time.validat,
                                                                                                timeZone
                                                                                            )}
                                                                                    </Text>
                                                                                </Flex>
                                                                            </Button>
                                                                        )
                                                                    )}
                                                                    <Flex // BUTTON INDICATOR
                                                                        position="absolute"
                                                                        marginInlineStart="0px !important"
                                                                        mt="2px"
                                                                        ml="0px"
                                                                        px={{
                                                                            base: 'unset',
                                                                            md: '44px',
                                                                        }}
                                                                        height={{
                                                                            base: '32px',
                                                                            md: '24px',
                                                                        }}
                                                                        width={{
                                                                            base: `${
                                                                                91 /
                                                                                timeOptionsLength
                                                                            }vw`,
                                                                            md: '74px',
                                                                        }}
                                                                        background="white"
                                                                        borderRadius="20px"
                                                                        bg="light.10"
                                                                        boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                        transform={`translateX(${mslTimePosition}00%)`}
                                                                        transition="transform 400ms"
                                                                    ></Flex>
                                                                </ButtonGroup>
                                                            </Flex>

                                                            <Flex
                                                                color="light.100"
                                                                justifyContent="flex-end"
                                                                alignItems="center"
                                                                width={{
                                                                    base: '40px',
                                                                    md: '100%',
                                                                }}
                                                                opacity={{
                                                                    base: minimiseActive
                                                                        ? '1'
                                                                        : '0',
                                                                    md: '1',
                                                                }}
                                                                visibility={{
                                                                    base: minimiseActive
                                                                        ? 'visible'
                                                                        : 'hidden',
                                                                    md: 'visible',
                                                                }}
                                                                transition="all ease 300ms"
                                                            >
                                                                <ZoomSlider
                                                                    scale={
                                                                        scale
                                                                    }
                                                                    xy={xy}
                                                                    setScale={
                                                                        setScale
                                                                    }
                                                                    setXy={
                                                                        setXy
                                                                    }
                                                                    maxScale={3}
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                        <Flex // IMAGE CONTAINER
                                                            borderTop="1px solid #E2E8F0"
                                                            direction="column"
                                                            position={{
                                                                base: 'relative',
                                                            }}
                                                            w={{ base: '100%' }}
                                                            h={{
                                                                base: 'calc(100vh - 115px)',
                                                            }}
                                                            overflow={'hidden'}
                                                            pt={{
                                                                base: errorShow
                                                                    ? '0'
                                                                    : '20px',
                                                                md: errorShow
                                                                    ? '0px'
                                                                    : '25px',
                                                            }}
                                                            px="10px"
                                                            pb={{
                                                                base: '0px',
                                                                md: '0px',
                                                            }}
                                                            zIndex="1"
                                                        >
                                                            {data &&
                                                                (showOutdatedErrors ||
                                                                    isOutOfDate()
                                                                        .outofdate) && (
                                                                    <Flex
                                                                        px="15px"
                                                                        pt="10px"
                                                                        mb="15px"
                                                                        justifyContent="center"
                                                                    >
                                                                        <ErrorText>
                                                                            There
                                                                            has
                                                                            been
                                                                            an
                                                                            issue
                                                                            with
                                                                            the
                                                                            connection
                                                                            between
                                                                            PreFlight
                                                                            and
                                                                            MetService.
                                                                            This{' '}
                                                                            <strong>
                                                                                Mean
                                                                                Sea
                                                                                Level
                                                                                Chart
                                                                            </strong>{' '}
                                                                            was
                                                                            last
                                                                            validated
                                                                            with
                                                                            MetService
                                                                            more
                                                                            than{' '}
                                                                            <strong>
                                                                                {formatDistanceToNow(
                                                                                    parseISO(
                                                                                        isOutOfDate()
                                                                                            .updated
                                                                                    ),
                                                                                    {
                                                                                        addSuffix: true,
                                                                                    }
                                                                                )}
                                                                            </strong>
                                                                            .
                                                                            <br />
                                                                            There
                                                                            is a
                                                                            possibility
                                                                            that
                                                                            this
                                                                            chart
                                                                            is
                                                                            out
                                                                            of
                                                                            date.
                                                                            Check
                                                                            back
                                                                            again
                                                                            soon,
                                                                            or
                                                                            alternatively
                                                                            visit{' '}
                                                                            <a
                                                                                style={{
                                                                                    fontWeight:
                                                                                        '500',
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                                href="https://ifis.airways.co.nz/"
                                                                            >
                                                                                IFIS
                                                                            </a>
                                                                            .
                                                                        </ErrorText>
                                                                    </Flex>
                                                                )}
                                                            <PinchDrag
                                                                scale={scale}
                                                                setScale={
                                                                    setScale
                                                                }
                                                                xy={xy}
                                                                setXy={setXy}
                                                                maxZoom={3}
                                                                maxZoomNewScale={
                                                                    2.999
                                                                }
                                                            >
                                                                {!loaded && (
                                                                    <Flex
                                                                        justifyContent="center"
                                                                        alignItems="flex-start"
                                                                        width={
                                                                            '100%'
                                                                        }
                                                                        // h={
                                                                        //     'calc(100vh - 120px)'
                                                                        // }
                                                                        pt="25vh"
                                                                        px={{
                                                                            base: '10px',
                                                                            md: '30px',
                                                                        }}
                                                                    >
                                                                        <Spinner
                                                                            color={
                                                                                'light.200'
                                                                            }
                                                                            thickness="3px"
                                                                            speed="0.45s"
                                                                            boxSize="60px"
                                                                        />
                                                                        {/* <Skeleton
                                                                            width={{
                                                                                base:
                                                                                    '100%',
                                                                                md:
                                                                                    '95%',
                                                                            }}
                                                                            h={
                                                                                '65%'
                                                                            }
                                                                            borderRadius="20px"
                                                                        /> */}
                                                                    </Flex>
                                                                )}
                                                                {msl.map(
                                                                    (
                                                                        image,
                                                                        i
                                                                    ) => (
                                                                        <Image
                                                                            key={`MslImage${image.validat}`}
                                                                            maxWidth={
                                                                                pageScaleWidth
                                                                            }
                                                                            height={
                                                                                loaded &&
                                                                                mslTime ===
                                                                                    image.validat
                                                                                    ? 'auto'
                                                                                    : '0px'
                                                                            }
                                                                            opacity={
                                                                                loaded &&
                                                                                mslTime ===
                                                                                    image.validat
                                                                                    ? '1'
                                                                                    : '0'
                                                                            }
                                                                            onLoad={
                                                                                onImageLoad
                                                                            }
                                                                            margin={
                                                                                'auto'
                                                                            }
                                                                            transition="width 20ms"
                                                                            src={`${window.location.origin}/metproxy?url=${image.url}`}
                                                                            alt="GRAFOR CHART UNAVAILABLE"
                                                                        />
                                                                    )
                                                                )}
                                                            </PinchDrag>
                                                        </Flex>
                                                    </>
                                                )}
                                                {isDesktop && (
                                                    <Text
                                                        width="140px"
                                                        textAlign="right"
                                                        display={{
                                                            base: 'none',
                                                            md: 'block',
                                                        }}
                                                        position="absolute"
                                                        bottom={{
                                                            base: '78px',
                                                            xl: '15px',
                                                        }}
                                                        right="15px"
                                                        color="gray.400"
                                                        fontWeight="400"
                                                        fontSize="0.65rem"
                                                        lineHeight="1.3"
                                                        opacity="0.7"
                                                    >
                                                        Click and hold on the
                                                        chart to drag it around
                                                        the screen
                                                    </Text>
                                                )}

                                                {msl.length < 1 && (
                                                    <Flex
                                                        fontFamily="Open Sans"
                                                        flexDirection="column"
                                                        width="100%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Text
                                                            paddingStart="0"
                                                            paddingEnd="0"
                                                            fontWeight="500"
                                                            py="40px"
                                                            minWidth="130px"
                                                            fontSize="0.9rem"
                                                            lineHeight="1.3"
                                                            borderColor="transparent"
                                                        >
                                                            No current MSL data
                                                        </Text>
                                                    </Flex>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <FullWidthError
                                    message={`Error downloading MSL Charts.<br />Please try again later, or visit <a href="https://ifis.airways.co.nz/">IFIS</a>.`}
                                />
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
