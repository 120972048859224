import { Button, Flex, Icon, Tooltip } from '@chakra-ui/react'
// import { ImCheckmark } from 'react-icons/im'
import { FaSlash } from 'react-icons/fa'

export default function AerodromesFilterButton(props) {
    const {
        sortState,
        toggleState,
        itemKey,
        itemFeat,
        iconA,
        iconB,
        tooltipA,
        tooltipB,
        optionSelected,
    } = props

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const disableOption =
        ((itemKey === 'filtering' ||
            (itemKey === 'sorting' && itemFeat === 'type')) &&
            ((optionSelected === 3 && itemFeat !== 'aerodromes') ||
                (optionSelected === 4 && itemFeat !== 'heliports') ||
                (optionSelected === 5 && itemFeat !== 'waterdromes'))) ||
        (itemFeat === 'flightRule' &&
            (optionSelected === 1 || optionSelected === 2))

    return (
        <>
            <Tooltip
                label={
                    disableOption
                        ? `${
                              itemKey === 'sorting' && itemFeat === 'type'
                                  ? 'Sort by '
                                  : ''
                          }${itemFeat} disabled`
                        : tooltipA && tooltipB
                        ? sortState
                            ? tooltipA
                            : tooltipB
                        : tooltipA
                }
                hasArrow
                borderRadius="7px"
                height="22px"
                fontSize="0.7rem"
                alignItems="center"
                display="flex"
                placement="bottom"
                m="5px"
                color="light.10"
                bgColor={'gray.500'}
                textTransform="capitalize"
            >
                <Button // SORT AERODROMES
                    {...noFocus}
                    ml={{
                        base: '0px',
                        ml: '5px',
                    }}
                    mr={{
                        base: '0px',
                        ml: '5px',
                    }}
                    background="none"
                    color={
                        (itemKey === 'sorting' &&
                            (itemFeat === 'name' || itemFeat === 'location')) ||
                        (sortState && !disableOption)
                            ? 'light.100'
                            : 'gray.400'
                    }
                    opacity={
                        (itemKey === 'sorting' &&
                            (itemFeat === 'name' || itemFeat === 'location')) ||
                        (sortState && !disableOption)
                            ? '0.9'
                            : '0.5'
                    }
                    fontSize="10px"
                    fontWeight="600"
                    height="40px"
                    width="40px"
                    minWidth="40px"
                    alignItems="center"
                    justifyContent="center"
                    paddingLeft="2px"
                    paddingRight="2px"
                    borderRadius="10px"
                    onClick={() => {
                        toggleState(itemKey, itemFeat)
                    }}
                    _focus={{
                        opacity:
                            (itemKey === 'sorting' &&
                                (itemFeat === 'name' ||
                                    itemFeat === 'location')) ||
                            (sortState && !disableOption)
                                ? '1'
                                : '0.7',
                    }}
                    _active={{
                        opacity:
                            (itemKey === 'sorting' &&
                                (itemFeat === 'name' ||
                                    itemFeat === 'location')) ||
                            (sortState && !disableOption)
                                ? '0.9'
                                : '0.8',
                    }}
                    _hover={{
                        opacity: '1 !important',
                    }}
                >
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        border={{
                            base: 'none',
                            ml: 'none',
                        }}
                        borderRadius="10px"
                        variant="outline"
                        fontSize="1rem"
                        height={{
                            base: '40px',
                            ml: '32px',
                        }}
                        width={{
                            base: '40px',
                            ml: '36px',
                        }}
                        minWidth={{
                            base: '40px',
                            ml: '36px',
                        }}
                        transition="transform ease 50ms"
                        _hover={{
                            opacity: '1',
                            background: 'light.10',
                            boxShadow: {
                                base: 'none',
                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                            },
                            transform: {
                                base: 'none',
                                md: 'scale(1.08) translateY(-1px)',
                            },
                        }}
                        _active={{
                            background: 'light.40',
                            boxShadow: '0px 3px 7px -2px rgba(0,0,0,0.3)',
                        }}
                    >
                        <Icon
                            as={
                                iconA && iconB
                                    ? sortState
                                        ? iconA
                                        : iconB
                                    : iconA
                            }
                            width={{
                                base: '24px',
                                ml: '20px',
                            }}
                            height={{
                                base: '24px',
                                ml: '20px',
                            }}
                        />
                        {/* {!(itemKey === 'sorting' && (itemFeat === 'name' || itemFeat === 'location')) &&
                            sortState && (
                                <Flex
                                    width="10px"
                                    height="10px"
                                    justifyContent="center"
                                    alignItems="center"
                                    bg="light.200"
                                    color="white"
                                    zIndex="1"
                                    marginTop="-15px"
                                    marginRight="-15px"
                                    borderRadius="20px"
                                    position="absolute"
                                >
                                    <ImCheckmark
                                        fontSize="7px"
                                        fontWeight="700"
                                    />
                                </Flex>
                            )} */}
                        {!(
                            itemKey === 'sorting' &&
                            (itemFeat === 'name' || itemFeat === 'location')
                        ) &&
                            !sortState && (
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    zIndex="1"
                                    position="absolute"
                                >
                                    <Flex
                                        width={{
                                            base: '28px',
                                            ml: '24px',
                                        }}
                                        height={{
                                            base: '28px',
                                            ml: '24px',
                                        }}
                                        justifyContent="center"
                                        alignItems="center"
                                        color="gray.400"
                                        zIndex="2"
                                        borderRadius="20px"
                                        position="absolute"
                                    >
                                        <FaSlash
                                            fontSize="28px"
                                            fontWeight="700"
                                        />
                                    </Flex>
                                    <Flex
                                        width={{
                                            base: '28px',
                                            ml: '24px',
                                        }}
                                        height={{
                                            base: '28px',
                                            ml: '24px',
                                        }}
                                        justifyContent="center"
                                        alignItems="center"
                                        color="white"
                                        zIndex="1"
                                        marginTop="-2px"
                                        marginRight="-2px"
                                        borderRadius="20px"
                                        position="absolute"
                                    >
                                        <FaSlash
                                            fontSize={{
                                                base: '28px',
                                                ml: '24px',
                                            }}
                                            fontWeight="700"
                                        />
                                    </Flex>
                                </Flex>
                            )}
                    </Flex>
                </Button>
            </Tooltip>
        </>
    )
}
