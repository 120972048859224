import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import formatDateTime from '../../../util/dateFormatter'
import { useTier, useAdminRole } from '../../../auth/hooks'
import PullToRefresh from 'react-simple-pull-to-refresh'
import useConfirm from '../alerts/warning-message/components/useConfirm'
import { ErroneousDesignatorsMessage } from '../alerts/warning-message/components/WarningMessages'
import useAdjustedFontSize from '../../../util/fontSizeAdjustment'
import {
    Flex,
    Modal,
    Button,
    Icon,
    ModalContent,
    ModalHeader,
    ModalBody,
    Box,
    useDisclosure,
    Text,
    useColorModeValue,
    LightMode,
    Divider,
    HStack,
    Tooltip,
    Portal,
    useBreakpointValue,
    Slider,
    SliderTrack,
    SliderThumb,
    Badge,
    useToast,
    Spinner,
    ScaleFade,
    SlideFade,
    CircularProgress,
    Center,
} from '@chakra-ui/react'
import { useSwipeable } from 'react-swipeable'
import { useScale } from '../components/PinchDrag'
import { isFirefox, isDesktop } from 'react-device-detect'
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa'
import { TfiMore, TfiMoreAlt } from 'react-icons/tfi'
import { ErrorBoundary } from 'react-error-boundary'
// import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import PremiumBadge from '../../controls/PremiumBadge'
import {
    briefingStandardState,
    selectedBriefingAreasState,
    briefingAreaSourceState,
    fullscreenModalVisibleHandler,
    timeZoneState,
    showErrorTogglesState,
    briefingCustomAreaState,
    briefingDrawActionState,
    briefingOptionsState,
    layerSelectionState,
    excludedAerodromesState,
    excludedAwsState,
    briefingCreatedState,
    showOutdatedErrorsState,
    extendedHeightState,
    mobileDrawerExpandedState,
    setDetailsShowState,
    mobileNavButtonsShowState,
    excludedItemsShowState,
    collapseDropdownsState,
    aerodromesSortState,
    downloadPromiseState,
    briefingOrderState,
    altAerodromeLayoutState,
    briefingFontSizeState,
    fontAdjustSliderShowState,
    simulateFreeUserState,
    briefingFullscreenChartsState,
    fullscreenHandlerState,
    mfaVerifiedState,
    mapDisabledState,
    isBriefingOnlyState,
    setModalVisibilityState,
    loadingBriefingFavouriteState,
    loadFavouriteState,
    currentFavouriteState,
    layerSelectionHandler,
    onlineState,
    hideNetworkConnectionLostState,
    updatedParamsState,
    allAerodromesDesignatorsState,
    allAwsDesignatorsState,
    showManageFavouritesState,
    favouriteTitleState,
    hasErroneousDesignatorsState,
    favouritesListState,
} from '../../../globalState'

import { ParseCoords } from '../../actions/RouteHandler'
import { useFilteredDesignators } from '../../../util/filterDesignators'
import BriefingStandardContent from './components/BriefingStandardContent'
import BriefingMenu from './components/briefing-menu/BriefingMenu'
import BriefingInfo from './components/briefing-menu/BriefingInfo'
import ExcludedItems from './components/briefing-menu/ExcludedItems'
import ExportOptions from './components/briefing-menu/ExportOptions'
import ErrorToggle from '../components/ErrorToggle'
import FullWidthError from '../components/FullWidthError'
import { ReactComponent as GoBackIcon } from '../../../icons/assets/go-back.svg'
import { ReactComponent as RefreshIcon } from '../../../icons/assets/refresh.svg'
import { ReactComponent as InfoIcon } from '../../../icons/assets/info-nocircle.svg'
import { ReactComponent as SavedFavouriteIcon } from '../../../icons/assets/favourite-saved.svg'
import { ReactComponent as FavouriteIcon } from '../../../icons/assets/favourite-load.svg'
import { ReactComponent as SortAlphabeticalIcon } from '../../../icons/assets/sort-alphabetical.svg'
import { ReactComponent as SortNorthToSouthIcon } from '../../../icons/assets/sort-northtosouth.svg'
import { ReactComponent as BriefNoItemsIcon } from '../../../icons/assets/brief-no-items.svg'
import { ReactComponent as AerodromeStdLayoutIcon } from '../../../icons/assets/aerodrome-std-layout.svg'
import { ReactComponent as AerodromeAltLayoutIcon } from '../../../icons/assets/aerodrome-alt-layout.svg'
import { ReactComponent as OrderUpIcon } from '../../../icons/assets/sort-order-up.svg'
import { ReactComponent as OrderDownIcon } from '../../../icons/assets/sort-order-down.svg'
import { ReactComponent as FontAdjust } from '../../../icons/assets/font-adjust.svg'
import { ReactComponent as FontAdjustNoLine } from '../../../icons/assets/font-adjust-noline.svg'
import { ReactComponent as CloseIcon } from '../../../icons/assets/close.svg'

import './briefing.css'
import './components/briefing-export/print-pdf.css'
// import PrintPdf from './components/briefing-export/PrintPdf'
import SavePdf from './components/briefing-export/SavePdf'
import BriefingFavourites from './components/briefing-favourites'

function ErrorFallback({ error, resetErrorBoundary }) {
    console.log('Error:', error)
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Please try again</button>
        </div>
    )
}

export default function BriefingStandard(props) {
    const { optionsOpen, viewportHeight } = props
    const isAdmin = useAdminRole()
    const [show, setShow] = useRecoilState(briefingStandardState)
    const setBriefingOptions = useSetRecoilState(briefingOptionsState)
    const [briefingCreated, setBriefingCreated] =
        useRecoilState(briefingCreatedState)
    const setHandleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const briefingChartsFullscreen = useRecoilValue(
        briefingFullscreenChartsState
    )
    const currentFavourite = useRecoilValue(currentFavouriteState)
    const [loadingBriefingFavourite, setLoadingBriefingFavourite] =
        useRecoilState(loadingBriefingFavouriteState)
    const [loadFavourite, setLoadFavourite] = useRecoilState(loadFavouriteState)

    const [showManageFavourites, setShowManageFavourites] = useRecoilState(
        showManageFavouritesState
    )
    const favouritesList = useRecoilValue(favouritesListState)
    const [favouriteTitle, setFavouriteTitle] =
        useRecoilState(favouriteTitleState)
    const { onClose } = useDisclosure()
    const [fullscreenHandler, setFullscreenHandler] = useRecoilState(
        fullscreenHandlerState
    )
    const [hasErroneousDesignators, setHasErroneousDesignators] =
        useRecoilState(hasErroneousDesignatorsState)
    const fullscreen = Boolean(fullscreenHandler)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const online = useRecoilValue(onlineState)
    const { isConfirmed } = useConfirm() // alert message handler
    const { scale, setScale, xy, setXy } = useScale()
    const [handleReset, setHandleReset] = useState(false)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setLayerSelection = useSetRecoilState(layerSelectionHandler)
    const timeZone = useRecoilValue(timeZoneState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const [mobileNavButtonsShow, setMobileNavButtonsShow] = useRecoilState(
        mobileNavButtonsShowState
    )
    const [excludedItemsShow, setExcludedItemsShow] = useRecoilState(
        excludedItemsShowState
    )
    const [excludedAerodromes, setExcludedAerodromes] = useRecoilState(
        excludedAerodromesState
    )
    const [excludedAws, setExcludedAws] = useRecoilState(excludedAwsState)
    const [detailsShow, setDetailsShow] = useRecoilState(setDetailsShowState)
    const [altAerodromeLayout, setAltAerodromeLayout] = useRecoilState(
        altAerodromeLayoutState
    )
    const [aerodromesSort, setAerodromesSort] =
        useRecoilState(aerodromesSortState)
    const [briefingOrder, setBriefingOrder] = useRecoilState(briefingOrderState)
    const [collapseDropdowns, setCollapseDropdowns] = useRecoilState(
        collapseDropdownsState
    )

    const { user, getAccessTokenSilently } = useAuth0()
    const [buttonSelected, setButtonSelected] = useState(null)
    const [selectedAreas, setSelectedAreas] = useRecoilState(
        selectedBriefingAreasState
    )
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useRecoilState(
        showOutdatedErrorsState
    )
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const [briefingAreaSource, setBriefingAreaSource] = useRecoilState(
        briefingAreaSourceState
    )
    const setBriefingDrawAction = useSetRecoilState(briefingDrawActionState)
    const [briefingCustomArea, setBriefingCustomArea] = useRecoilState(
        briefingCustomAreaState
    )
    const [mobileDrawerExpanded, setMobileDrawerExpanded] = useRecoilState(
        mobileDrawerExpandedState
    )
    const [minimiseDesktopMenu, setMinimiseDesktopMenu] = useState(false)

    const [extendedHeight, setExtendedHeight] =
        useRecoilState(extendedHeightState)
    const [fontAdjustSliderShow, setFontAdjustSliderShow] = useRecoilState(
        fontAdjustSliderShowState
    )
    const [briefingFontSize, setBriefingFontSize] = useRecoilState(
        briefingFontSizeState
    )
    const hideNetworkConnectionLostModal = useRecoilValue(
        hideNetworkConnectionLostState
    )
    const [allAerodromesDesignators, setAllAerodromesDesignators] =
        useRecoilState(allAerodromesDesignatorsState)

    const [allAwsDesignators, setAllAwsDesignators] = useRecoilState(
        allAwsDesignatorsState
    )
    const updatedParams = useRecoilValue(updatedParamsState)
    const [scrollDirection, setScrollDirection] = useState('')
    const [prevScrollPos, setPrevScrollPos] = useState(0)
    const [isScrollingUp, setIsScrollingUp] = useState(false)
    const [refetchingInfo, setRefetchingInfo] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize } = useAdjustedFontSize()

    const fontSizeLabel = Math.round(`${briefingFontSize - 0.1 + 1}` * 10) / 10

    // Add view briefingAreas layer to true if it is not.
    const onCloseHandler = () => {
        setBriefingDrawAction(null)
        setBriefingCustomArea(null)
        setBriefingAreaSource('briefingarea')
        if (layerSelection.brief.briefingareas) {
            setLayerSelection({
                mode: 'brief',
                layer: 'briefingareas',
            })
        }
        setSelectedAreas([])
        setBriefingOptions(true)
        setShow(false)
        setBriefingCreated(false)
        setHandleFullscreenModal(false)
        setFontAdjustSliderShow(false)
        setMobileDrawerExpanded(false)
        setExtendedHeight(false) // temp (remove for partial slider height)
        setDetailsShow(false)
        setExcludedItemsShow(false)
        setExcludedAerodromes([])
        setExcludedAws([])
        setScrollDirection('')
        setLoadFavourite(null)
        setFavouriteTitle('')
        setHasErroneousDesignators(false)
        setDataLoaded(false)
        onClose()
    }

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        if (!(isBriefingOnly || mapDisabled)) {
            setHandleFullscreenModal()
            return () => {
                setHandleFullscreenModal()
            }
        }
        if ((isBriefingOnly || mapDisabled) && show) {
            setHandleFullscreenModal()
            return () => {
                setHandleFullscreenModal()
            }
        }
    }, [show, isBriefingOnly, mapDisabled, setHandleFullscreenModal])

    useEffect(() => {
        if (show && !briefingCreated) setBriefingCreated(true)
    }, [show, briefingCreated, setBriefingCreated])

    const smallScreen = window.innerWidth > 576
    useEffect(() => {
        if (!smallScreen) {
            setMobileNavButtonsShow(false)
        }
    }, [smallScreen, setMobileNavButtonsShow])

    // Favourite Coords
    const coords = loadFavourite?.params?.coords
        ? loadFavourite.params.coords
        : ''
    const formattedCoords = ParseCoords(coords)

    const fetchBriefing = useCallback(async () => {
        setDataLoaded(false)
        if (!online) {
            return null
        }
        const accessToken = await getAccessTokenSilently()
        const source = loadFavourite?.params?.source
            ? loadFavourite.params.source
            : briefingAreaSource
        const selectedBriefingAreas = loadFavourite?.params?.selected
            ? loadFavourite.params.selected
            : []

        if (briefingCustomArea && source === 'customArea') {
            if (!show && !(isBriefingOnly || mapDisabled)) {
                setShow(true)
            }
            const res = await fetch(
                `${window.location.origin}/brief/custom?user=${user.email}&source=${briefingAreaSource}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(
                        loadingBriefingFavourite
                            ? formattedCoords.geometry
                            : briefingCustomArea.geometry
                    ),
                }
            )
            if (!res.ok) {
                throw new Error('Error loading custom briefing')
            }
            const jsonData = await res.json()
            setDataLoaded(true)
            return jsonData
        } else {
            const res = await fetch(
                `${window.location.origin}/brief?user=${user.email}&source=${
                    loadingBriefingFavourite ? source : briefingAreaSource
                }&areas=${
                    loadingBriefingFavourite
                        ? selectedBriefingAreas
                        : briefingAreaSource === 'aaw'
                        ? selectedAreas.map((area) => area.area).join(',')
                        : selectedAreas.map((area) => area.designator).join(',')
                }`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            if (!res.ok) {
                throw new Error('Error loading briefing')
            }
            const jsonData = await res.json()
            setDataLoaded(true)
            return jsonData
        }
    }, [
        show,
        online,
        getAccessTokenSilently,
        user.email,
        selectedAreas,
        briefingAreaSource,
        briefingCustomArea,
        loadingBriefingFavourite,
        loadFavourite,
        formattedCoords,
        isBriefingOnly,
        mapDisabled,
        setShow,
    ])

    //PORTAL REFERENCES
    const fullscreenRef = useRef()
    const mobile = useRef()
    const desktop = useRef()
    const briefingContentMobile = useRef()
    const briefingContentDesktop = useRef()

    const mobileNavButtons = useBreakpointValue({
        base: { containerRef: mobile },
        ml: { containerRef: desktop },
    })

    const briefingContentContainer = useBreakpointValue({
        base: { containerRef: briefingContentMobile },
        md: { containerRef: briefingContentDesktop },
    })

    // temp (remove for partial slider height)
    const drawerSwipe = useSwipeable({
        onSwipedUp: () => {
            setDetailsShow(false)
        },
        onSwipedDown: () => {
            setDetailsShow(true)
            setExcludedItemsShow(false)
        },
    })

    const {
        status,
        data: briefingData,
        refetch,
        isLoading,
        isRefetching,
        isSuccess,
        remove,
    } = useQuery(`briefing-areas`, fetchBriefing, {
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        // if Briefing Options open
        if (optionsOpen) {
            !(isBriefingOnly || mapDisabled) && setShow(false) // adding isBriefingOnly prevents a constant loop if trying to load a URL with briefing
            setHandleFullscreenModal(false)
            onClose()
            remove() // remove any existing briefingData before trying to load new
        }
    }, [
        optionsOpen,
        isBriefingOnly,
        mapDisabled,
        setShow,
        setHandleFullscreenModal,
        onClose,
        remove,
    ])

    useEffect(() => {
        if (
            !showManageFavourites &&
            loadingBriefingFavourite &&
            !isLoading &&
            briefingData
        ) {
            setLoadingBriefingFavourite(false)
        }
    }, [
        showManageFavourites,
        loadingBriefingFavourite,
        isLoading,
        briefingData,
        setLoadingBriefingFavourite,
    ])

    const downloadPromise = useRecoilValue(downloadPromiseState)

    const containerRef = useRef()

    // Detect scroll on mobile //
    const touchStartRef = useRef(null)

    // Scroll to top button at top of header
    const scrollRef = useRef(null)
    const scrollToTop = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
        setScrollDirection('')
    }

    // Slide mobile menu down when scrolling down the page. Slide back up when scrolling back to top
    const handleTouchStart = (event) => {
        touchStartRef.current = event.touches[0].clientY
        !briefingChartsFullscreen && setIsScrollingUp(false)
    }
    const handleTouchMove = (event) => {
        const touchEnd = event.touches[0].clientY
        const scrolledDirection =
            touchStartRef.current > touchEnd ? 'up' : 'down'
        !briefingChartsFullscreen && setScrollDirection(scrolledDirection)
    }
    const handleTouchEnd = () => {
        touchStartRef.current = null
    }

    // detect if scrolling up to expand mobile menu
    const handleScroll = (event) => {
        if (!briefingChartsFullscreen) {
            const currentScrollPos = event.target.scrollTop
            if (currentScrollPos < prevScrollPos) {
                setIsScrollingUp(true)
            } else {
                setIsScrollingUp(false)
            }
            setPrevScrollPos(currentScrollPos)
        }
    }

    useEffect(() => {
        if (scrollRef.current && !briefingChartsFullscreen) {
            setPrevScrollPos(scrollRef.current.scrollTop)
        }
    }, [briefingChartsFullscreen])

    useEffect(() => {
        if (mobileDrawerExpanded || isScrollingUp) setScrollDirection('')
    }, [mobileDrawerExpanded, isScrollingUp])

    useEffect(() => {
        if (isLoading) {
            setAllAerodromesDesignators([])
            setAllAwsDesignators([])
        }
        if (briefingData && !isLoading) {
            // set all current aerodrome designators into a state
            const aerodromeDesignators =
                briefingData?.res?.aerodromes?.map((i) => i.designator) || []
            setAllAerodromesDesignators(aerodromeDesignators)

            // set all current AWS designators into a state
            const awsDesignators =
                briefingData?.res?.aws?.map((i) => i.designator) || []
            setAllAwsDesignators(awsDesignators)
        }
    }, [
        isLoading,
        briefingData,
        setAllAerodromesDesignators,
        setAllAwsDesignators,
    ])

    const {
        // check if any erroneous Aerodrome designators in the URL
        filteredDesignators: erroneousAerodromes,
        // filter Excluded Aerodromes to remove any erroneous designators in the URL
        filteredExcludedDesignators: filteredExcludedAerodromes,
    } = useFilteredDesignators(
        briefingData?.res?.aerodromes?.map((i) => i.designator) || [],
        excludedAerodromes
    )

    const {
        // check if any erroneous AWS designators in the URL
        filteredDesignators: erroneousAws,
        // filter Excluded AWS to remove any erroneous designators in the URL
        filteredExcludedDesignators: filteredExcludedAws,
    } = useFilteredDesignators(
        briefingData?.res?.aws?.map((i) => i.designator) || [],
        excludedAws
    )

    const excludedItemsTotal =
        (filteredExcludedAerodromes && filteredExcludedAerodromes.length) +
        (filteredExcludedAws && filteredExcludedAws.length)

    // warning message pop up if any erroneous designators in the URL
    const isConfirmedRef = useRef(isConfirmed)
    useEffect(() => {
        if (dataLoaded) {
            const checkForErroneousDesignators =
                isSuccess &&
                briefingData &&
                ((briefingData?.res?.aerodromes &&
                    allAerodromesDesignators &&
                    allAerodromesDesignators.length > 0) ||
                    (briefingData?.res?.aws &&
                        allAwsDesignators &&
                        allAwsDesignators.length > 0)) &&
                (filteredExcludedAerodromes || filteredExcludedAws) &&
                ((erroneousAerodromes && erroneousAerodromes.length > 0) ||
                    (erroneousAws && erroneousAws.length > 0))

            // needs the isConfirmedRef to avoid needing the isConfirm in the dependency array
            const handleConfirmed = () => {
                isConfirmedRef.current(
                    'confirm-accept-warning',
                    <ErroneousDesignatorsMessage
                        erroneousAerodromes={erroneousAerodromes}
                        erroneousAws={erroneousAws}
                    />
                )
            }
            if (checkForErroneousDesignators && !hasErroneousDesignators) {
                setHasErroneousDesignators(true)
                handleConfirmed()
            }
        }
    }, [
        isSuccess,
        briefingData,
        dataLoaded,
        allAerodromesDesignators,
        allAwsDesignators,
        filteredExcludedAerodromes,
        filteredExcludedAws,
        hasErroneousDesignators,
        erroneousAerodromes,
        erroneousAws,
        setHasErroneousDesignators,
    ])

    useEffect(() => {
        if (excludedItemsShow || detailsShow) {
            setScrollDirection('up')
        }
        if (!excludedItemsShow && !detailsShow) {
            setScrollDirection('down')
        }
    }, [excludedItemsShow, detailsShow])

    // const [refetchLoaded, setRefetchLoaded] = useState(false)
    const handleRefresh = async () => {
        if (online) {
            setRefetchingInfo(true)
            await refetch()
        }
        // setRefetchLoaded(true)
    }

    const refetchToast = useToast()
    const id = 'refetchtoast'
    const refetchTimer = useRef(null)

    useEffect(() => {
        // useQuery states - only applies to refetch
        if (isSuccess && isRefetching && refetchingInfo) {
            if (!refetchToast.isActive(id) && online) {
                refetchTimer.current = setTimeout(() => {
                    // console.log('Briefing information updated')
                    refetchToast({
                        id,
                        position: 'top',
                        duration: 3000,
                        containerStyle: {
                            pointerEvents: 'none',
                        },
                        render: () => (
                            <Box
                                cursor="default"
                                pointerEvents="none"
                                color="white"
                                mt="75px"
                                ml={{
                                    base: '0px',
                                    md: minimiseDesktopMenu ? '110px' : '300px',
                                }}
                                px={5}
                                py={2}
                                bg={'rgba(0, 213, 153, 0.9)'}
                                fontSize="0.8rem"
                                fontWeight="400"
                                borderRadius="20px"
                                textAlign="center"
                                boxShadow="0px 5px 20px -5px rgba(20, 30, 40, 0.5)"
                            >
                                Briefing information updated
                            </Box>
                        ),
                    })
                    setRefetchingInfo(false)
                }, 1500)
            }
        }
    }, [
        isSuccess,
        isRefetching,
        refetchToast,
        refetchingInfo,
        online,
        minimiseDesktopMenu,
    ])

    const favouriteLoaded =
        (favouritesList.some(
            (item) =>
                item.title === favouriteTitle && favouriteTitle !== 'Undefined'
        ) ||
            favouritesList.some(
                (item) => item.title === updatedParams?.title
            )) &&
        currentFavourite

    const closeButtonPositionSmallScreen = useBreakpointValue({
        base: { top: '0px', right: '0px' },
        md: { display: 'none' },
    })

    const slideOffset = useBreakpointValue({
        base: '60vh',
        md: '60px',
    })

    const buttonBg = useBreakpointValue({
        base: 'none',
        md:
            isDesktop && fontAdjustSliderShow
                ? 'light.40'
                : isDesktop
                ? 'light.10'
                : 'none',
    })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    // useEffect(() => {
    //     console.log('briefingData: ', briefingData)
    //     console.log('dataLoaded: ', dataLoaded)
    // }, [briefingData, dataLoaded])

    return (
        <>
            {/* {isDesktop && <PrintPdf briefingData={briefingData} />} */}
            <div
                className="toPrint"
                style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: '100vw',
                    paddingTop: '50%',
                    textAlign: 'center',
                    fontSize: '1.25rem',
                }}
            >
                To print your briefing, first download the briefing as PDF, and
                print the PDF file.
            </div>
            {downloadPromise && <SavePdf briefingData={briefingData} />}
            <LightMode>
                <Modal
                    onClose={onCloseHandler}
                    isOpen={show}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant={
                        isBriefingOnly ? 'briefingOnly' : 'briefingStandard'
                    }
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            md: undefined,
                        }}
                        // marginBottom={{ base: '0px', md: '0px' }}
                    >
                        <ModalHeader
                            {...drawerSwipe}
                            backgroundColor={useColorModeValue(
                                'light.10',
                                'light.10'
                            )}
                            borderBottom="1px solid"
                            borderBottomColor="#E2E8F0"
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            backgroundBlendMode="overlay"
                            minHeight="70px"
                            boxShadow={{
                                base: mobileNavButtonsShow
                                    ? '0px 5px 10px -1px rgba(0,0,0,0.0)'
                                    : '0px 5px 10px -1px rgba(0,0,0,0.1)',
                                md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                            }}
                            transition="all ease 400ms"
                            flexDirection="row"
                            justifyContent="center"
                            display="flex"
                            zIndex="1000"
                            paddingLeft={{
                                base: '70px',
                                md: '20px',
                            }}
                            paddingRight={{ base: '10px', md: '20px' }}
                        >
                            <Flex
                                w="100%"
                                paddingRight={{
                                    base: '0',
                                    md: '0',
                                }}
                            >
                                <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    width="100%"
                                    paddingTop={{ base: '3px', md: '0' }}
                                    flexGrow="1"
                                >
                                    <Button
                                        className="scrolltotopbutton"
                                        display={{
                                            base: 'initial',
                                            md: 'none',
                                        }}
                                        width="100%"
                                        zIndex="2"
                                        position="absolute"
                                        top="0px"
                                        left="0px"
                                        height="20px"
                                        background="transparent !important"
                                        onClick={scrollToTop}
                                        _active={{
                                            background:
                                                'transparent !important',
                                        }}
                                        _hover={{
                                            background:
                                                'transparent !important',
                                            border: 'none',
                                            boxShadow: 'none',
                                        }}
                                        _focus={{
                                            background:
                                                'transparent !important',
                                            border: 'none',
                                            boxShadow: 'none',
                                        }}
                                    ></Button>
                                    <Flex
                                        zIndex="1"
                                        flexDirection={{
                                            base: 'column',
                                            xl: 'row',
                                        }}
                                        justifyContent={{
                                            base: 'center',
                                            xl: 'space-between',
                                        }}
                                        alignItems={{
                                            base: 'flex-start',
                                            xl: 'center',
                                        }}
                                        width="100%"
                                        paddingTop={{ base: '3px', xs: '0' }}
                                    >
                                        <Text
                                            textAlign="left"
                                            color={useColorModeValue(
                                                'light.100',
                                                'light.100'
                                            )}
                                            fontSize={{
                                                base: '0.9rem',
                                                sm: '1rem',
                                                md: '1.1rem',
                                                ml: '1.2rem',
                                            }}
                                            marginTop={{
                                                base: detailsShow
                                                    ? '-2px'
                                                    : '-6px',
                                                md: '0',
                                            }}
                                            pt="1px"
                                            transition="all 200ms"
                                            fontWeight="600"
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            lineHeight="1.3"
                                        >
                                            PreFlight Briefing
                                        </Text>
                                        {status !== 'loading' &&
                                            briefingData && (
                                                <Flex
                                                    pt={{
                                                        base: detailsShow
                                                            ? '0'
                                                            : '3px',
                                                        md: '0',
                                                    }}
                                                    pr={{
                                                        base: '0px',
                                                        md: '10px',
                                                    }}
                                                    flexDirection={{
                                                        base: 'column',
                                                        '2xl':
                                                            online &&
                                                            !hideNetworkConnectionLostModal
                                                                ? 'row-reverse'
                                                                : 'column',
                                                    }}
                                                    gap={{
                                                        base: '0px',
                                                        '2xl':
                                                            online &&
                                                            !hideNetworkConnectionLostModal
                                                                ? '40px'
                                                                : '0px',
                                                    }}
                                                    flexWrap="wrap"
                                                    justifyContent={{
                                                        base: 'center',
                                                        '2xl':
                                                            online &&
                                                            !hideNetworkConnectionLostModal
                                                                ? 'flex-end'
                                                                : 'center',
                                                    }}
                                                    alignItems={{
                                                        base: 'flex-start',
                                                        xl: 'flex-end',
                                                        '2xl':
                                                            online &&
                                                            !hideNetworkConnectionLostModal
                                                                ? 'center'
                                                                : 'flex-end',
                                                    }}
                                                    opacity={
                                                        detailsShow ? '0' : '1'
                                                    }
                                                    visibility={
                                                        detailsShow
                                                            ? 'hidden'
                                                            : 'visible'
                                                    }
                                                    transition="opacity ease-in-out 300ms, padding ease-in-out 300ms"
                                                >
                                                    <Flex
                                                        flexDirection="row"
                                                        justifyContent={{
                                                            base: 'flex-start',
                                                            md: 'flex-end',
                                                        }}
                                                        alignItems="center"
                                                        pr="2px"
                                                        transition="all 200ms"
                                                        fontSize={{
                                                            base: '0.65rem',
                                                            xs: '0.7rem',
                                                            smd: '0.75rem',
                                                        }}
                                                        height={
                                                            detailsShow
                                                                ? '0px'
                                                                : '17px'
                                                        }
                                                    >
                                                        <Text
                                                            display={{
                                                                base: 'none',
                                                                sm: 'inline-flex',
                                                                ml: 'none',
                                                                lg: 'inline-flex',
                                                            }}
                                                            alignSelf="left"
                                                            pr="5px"
                                                            color="light.300"
                                                            fontWeight="400"
                                                        >
                                                            Issued:
                                                        </Text>
                                                        <Text
                                                            alignSelf="left"
                                                            color="light.400"
                                                            fontWeight="500"
                                                        >
                                                            {briefingData?.time &&
                                                                formatDateTime(
                                                                    briefingData.time,
                                                                    timeZone
                                                                )}
                                                        </Text>
                                                    </Flex>
                                                    {favouriteLoaded && (
                                                        <Flex
                                                            flexDirection="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            pr="2px"
                                                            transition="all 200ms"
                                                            fontSize={{
                                                                base: '0.65rem',
                                                                xs: '0.7rem',
                                                                smd: '0.75rem',
                                                            }}
                                                            height={
                                                                detailsShow
                                                                    ? '0px'
                                                                    : '17px'
                                                            }
                                                            display={{
                                                                base: 'none',
                                                                xl:
                                                                    online &&
                                                                    !hideNetworkConnectionLostModal
                                                                        ? 'flex'
                                                                        : 'none',
                                                                '2xl': 'flex',
                                                            }}
                                                        >
                                                            <Text
                                                                display={{
                                                                    base: 'none',
                                                                    sm: 'inline-flex',
                                                                    ml: 'none',
                                                                    lg: 'inline-flex',
                                                                }}
                                                                alignSelf="left"
                                                                pr="5px"
                                                                color="light.300"
                                                                fontWeight="400"
                                                            >
                                                                Favourite
                                                                Loaded:
                                                            </Text>
                                                            <Text
                                                                alignSelf="left"
                                                                color="light.400"
                                                                fontWeight="500"
                                                            >
                                                                {favouriteTitle ||
                                                                    updatedParams?.title}
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            )}
                                    </Flex>
                                    <Flex
                                        zIndex="3"
                                        width="auto"
                                        marginTop={{
                                            base: '-3px',
                                            md: '0px',
                                        }}
                                        ml={{
                                            base: '0px',
                                            xs: '10px',
                                        }}
                                        mr={{
                                            base: '10px',
                                            ml: '2px',
                                        }}
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        flexGrow="2"
                                    >
                                        <Flex
                                            alignItems="center"
                                            flexDirection={{
                                                base: 'row-reverse',
                                                ml: 'row',
                                            }}
                                            // width="421px"
                                            // maxWidth="421px"
                                        >
                                            <Tooltip
                                                label={
                                                    collapseDropdowns
                                                        ? 'Expand Items'
                                                        : 'Collapse Items'
                                                }
                                                hasArrow
                                                borderRadius="7px"
                                                height="22px"
                                                fontSize="0.7rem"
                                                alignItems="center"
                                                display="flex"
                                                placement="bottom"
                                                m="5px"
                                                color="light.10"
                                                bgColor={'gray.500'}
                                            >
                                                <Button // COLLAPSE/EXPAND DROPDOWNS
                                                    {...noFocus}
                                                    mr={{
                                                        base: '0px',
                                                        ml: '5px',
                                                    }}
                                                    ml={{
                                                        base: '7px',
                                                        xs: '10px',
                                                        ml: '5px',
                                                    }}
                                                    background="none"
                                                    color="light.100"
                                                    opacity={'0.9'}
                                                    isActive={{
                                                        opacity: '0.9',
                                                    }}
                                                    fontSize="10px"
                                                    fontWeight="600"
                                                    height="40px"
                                                    width="40px"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    paddingLeft="2px"
                                                    paddingRight="2px"
                                                    borderRadius="10px"
                                                    onClick={() => {
                                                        setCollapseDropdowns(
                                                            (prevState) =>
                                                                !prevState
                                                        )
                                                    }}
                                                    _focus={{
                                                        opacity: '1 !important',
                                                    }}
                                                    _active={{
                                                        opacity: '0.9',
                                                    }}
                                                    _hover={{
                                                        opacity: '1 !important',
                                                    }}
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        border={{
                                                            base: 'none',
                                                            ml: 'none',
                                                        }}
                                                        borderRadius="10px"
                                                        color="light.100"
                                                        variant="outline"
                                                        fontSize="1rem"
                                                        height={{
                                                            base: '40px',
                                                            ml: '32px',
                                                        }}
                                                        width={{
                                                            base: '40px',
                                                            ml: '36px',
                                                        }}
                                                        minWidth={{
                                                            base: '40px',
                                                            ml: '36px',
                                                        }}
                                                        transition="transform ease 50ms"
                                                        opacity="0.9"
                                                        _hover={{
                                                            opacity: '1',
                                                            background:
                                                                'light.10',
                                                            boxShadow: {
                                                                base: 'none',
                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                            },
                                                            transform: {
                                                                base: 'none',
                                                                md: 'scale(1.08) translateY(-1px)',
                                                            },
                                                        }}
                                                        _active={{
                                                            background:
                                                                'light.40',
                                                            boxShadow:
                                                                '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                        }}
                                                    >
                                                        <Icon
                                                            as={
                                                                collapseDropdowns
                                                                    ? FaExpandAlt
                                                                    : FaCompressAlt
                                                            }
                                                            transform="rotate(-45deg)"
                                                            fontSize={{
                                                                base: '18px',
                                                                ml: '1rem',
                                                            }}
                                                        />
                                                    </Flex>
                                                </Button>
                                            </Tooltip>

                                            <Portal
                                                style={{
                                                    height: '100%',
                                                }}
                                                {...mobileNavButtons}
                                            >
                                                <Flex
                                                    flexDirection="row"
                                                    width="100%"
                                                    justifyContent={{
                                                        base: 'space-evenly',
                                                        ml: 'center',
                                                    }}
                                                    alignItems="center"
                                                >
                                                    <ScaleFade
                                                        zIndex={
                                                            fontAdjustSliderShow
                                                                ? '2'
                                                                : '1'
                                                        }
                                                        offsetY="0px"
                                                        animateOpacity
                                                        initialScale={0}
                                                        in={
                                                            !fontAdjustSliderShow
                                                        }
                                                        style={{
                                                            overflow: 'initial',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            label={
                                                                currentFavourite
                                                                    ? 'Saved Favourite'
                                                                    : 'Add to Favourites'
                                                            }
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="22px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="bottom"
                                                            m="5px"
                                                            color="light.10"
                                                            bgColor={'gray.500'}
                                                        >
                                                            <Button // MANAGE FAVOURITES
                                                                {...noFocus}
                                                                mx={{
                                                                    base: '0px',
                                                                    ml: '5px',
                                                                }}
                                                                background="none"
                                                                color="light.100"
                                                                opacity={'0.9'}
                                                                isActive={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                fontSize="10px"
                                                                fontWeight="600"
                                                                height="40px"
                                                                width="40px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                paddingLeft="2px"
                                                                paddingRight="2px"
                                                                borderRadius="10px"
                                                                onClick={() => {
                                                                    if (
                                                                        isPremium
                                                                    ) {
                                                                        setShowPricingTiersModal(
                                                                            true
                                                                        )
                                                                    } else {
                                                                        setShowManageFavourites(
                                                                            true
                                                                        )
                                                                        setExcludedItemsShow(
                                                                            false
                                                                        )
                                                                        setDetailsShow(
                                                                            false
                                                                        )
                                                                    }
                                                                }}
                                                                _focus={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                                _active={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                _hover={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                            >
                                                                <Flex
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    border={{
                                                                        base: 'none',
                                                                        ml: 'none',
                                                                    }}
                                                                    borderRadius="10px"
                                                                    color="light.100"
                                                                    variant="outline"
                                                                    fontSize="1rem"
                                                                    height={{
                                                                        base: '40px',
                                                                        ml: '32px',
                                                                    }}
                                                                    width={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    transition="transform ease 50ms"
                                                                    opacity="0.9"
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            'light.10',
                                                                        boxShadow:
                                                                            !isPremium && {
                                                                                base: 'none',
                                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                            },
                                                                        transform:
                                                                            !isPremium && {
                                                                                base: 'none',
                                                                                md: 'scale(1.08) translateY(-1px)',
                                                                            },
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'light.40',
                                                                        boxShadow:
                                                                            !isPremium &&
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        opacity={
                                                                            isPremium
                                                                                ? '0.4'
                                                                                : '1'
                                                                        }
                                                                        as={
                                                                            currentFavourite
                                                                                ? SavedFavouriteIcon
                                                                                : FavouriteIcon
                                                                        }
                                                                        width={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                        height={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                    />
                                                                </Flex>
                                                                {/* *** PREMIUM BADGE *** */}
                                                                {isPremium && (
                                                                    <Flex
                                                                        top={{
                                                                            base: '3px',
                                                                            ml: '3px',
                                                                        }}
                                                                        right={{
                                                                            base: '5px',
                                                                            ml: '5px',
                                                                        }}
                                                                        height="14px"
                                                                        position="absolute"
                                                                        transition="all ease-in 200ms"
                                                                    >
                                                                        <PremiumBadge />
                                                                    </Flex>
                                                                )}
                                                            </Button>
                                                        </Tooltip>
                                                    </ScaleFade>

                                                    <ScaleFade
                                                        initialScale={0}
                                                        zIndex={
                                                            fontAdjustSliderShow
                                                                ? '2'
                                                                : '1'
                                                        }
                                                        offsetY="0px"
                                                        animateOpacity
                                                        in={
                                                            !fontAdjustSliderShow
                                                        }
                                                        style={{
                                                            overflow: 'initial',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            label={`Excluded Aerodromes ${
                                                                isPremium
                                                                    ? ''
                                                                    : 'and AWS'
                                                            }`}
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="22px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="bottom"
                                                            m="5px"
                                                            color="light.10"
                                                            bgColor={'gray.500'}
                                                        >
                                                            <Button // EXCLUDED ITEMS DROPDOWN BUTTON
                                                                {...noFocus}
                                                                mx={{
                                                                    base: '0px',
                                                                    ml: '5px',
                                                                }}
                                                                background="none"
                                                                color={
                                                                    excludedItemsShow
                                                                        ? 'light.200'
                                                                        : 'light.100'
                                                                }
                                                                opacity={
                                                                    excludedItemsShow
                                                                        ? '1'
                                                                        : '0.7'
                                                                }
                                                                isActive={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                fontWeight="600"
                                                                height="40px"
                                                                width="40px"
                                                                minWidth="40px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                paddingLeft="2px"
                                                                paddingRight="2px"
                                                                transition="all 200ms"
                                                                borderRadius="10px"
                                                                onClick={() => {
                                                                    setExcludedItemsShow(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                    setFontAdjustSliderShow(
                                                                        false
                                                                    )
                                                                    setDetailsShow(
                                                                        false
                                                                    )
                                                                    setMobileNavButtonsShow(
                                                                        false
                                                                    )
                                                                    setMobileDrawerExpanded(
                                                                        false
                                                                    )
                                                                    setExtendedHeight(
                                                                        false
                                                                    ) // temp (remove for partial slider height)
                                                                }}
                                                                _focus={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                                _active={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                _hover={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                            >
                                                                <Flex
                                                                    border={{
                                                                        base: 'none',
                                                                        ml: 'none',
                                                                    }}
                                                                    borderRadius="10px"
                                                                    variant="outline"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    fontSize="1rem"
                                                                    height={{
                                                                        base: '40px',
                                                                        ml: '32px',
                                                                    }}
                                                                    width={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    transition="transform ease 50ms"
                                                                    opacity="0.9"
                                                                    boxShadow={{
                                                                        ml:
                                                                            excludedItemsShow &&
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.25)',
                                                                    }}
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            'light.10',
                                                                        boxShadow:
                                                                            {
                                                                                base: 'none',
                                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                            },
                                                                        transform:
                                                                            {
                                                                                base: 'none',
                                                                                md: 'scale(1.08) translateY(-1px)',
                                                                            },
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'light.40',
                                                                        boxShadow:
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            BriefNoItemsIcon
                                                                        }
                                                                        transition="all 200ms"
                                                                        width={{
                                                                            base: '28px',
                                                                            ml: '24px',
                                                                        }}
                                                                        height={{
                                                                            base: '28px',
                                                                            ml: '24px',
                                                                        }}
                                                                    />
                                                                    <Center
                                                                        opacity={
                                                                            excludedItemsTotal ===
                                                                            0
                                                                                ? '0'
                                                                                : '1'
                                                                        }
                                                                        visibility={
                                                                            excludedItemsTotal ===
                                                                            0
                                                                                ? 'hidden'
                                                                                : 'visible'
                                                                        }
                                                                        transition="all ease 300ms"
                                                                        w={
                                                                            excludedItemsTotal >
                                                                            99
                                                                                ? '22px'
                                                                                : excludedItemsTotal >
                                                                                  9
                                                                                ? '18px'
                                                                                : '14px'
                                                                        }
                                                                        minWidth={
                                                                            excludedItemsTotal >
                                                                            99
                                                                                ? '22px'
                                                                                : excludedItemsTotal >
                                                                                  9
                                                                                ? '18px'
                                                                                : '14px'
                                                                        }
                                                                        h={
                                                                            '14px'
                                                                        }
                                                                        marginTop={{
                                                                            base: '-18px',
                                                                            md: '-17px',
                                                                        }}
                                                                        marginLeft={
                                                                            excludedItemsTotal >
                                                                            99
                                                                                ? '-18px'
                                                                                : excludedItemsTotal >
                                                                                  9
                                                                                ? '-14px'
                                                                                : '-10px'
                                                                        }
                                                                        marginRight={
                                                                            '-4px'
                                                                        }
                                                                        bgColor={
                                                                            'light.105'
                                                                        }
                                                                        p={
                                                                            '2px'
                                                                        }
                                                                        borderRadius={
                                                                            '7px'
                                                                        }
                                                                        zIndex="1"
                                                                    >
                                                                        <Text
                                                                            fontSize={
                                                                                excludedItemsTotal >
                                                                                9
                                                                                    ? '0.55rem'
                                                                                    : '0.6rem'
                                                                            }
                                                                            color={
                                                                                'white'
                                                                            }
                                                                        >
                                                                            {
                                                                                excludedItemsTotal
                                                                            }
                                                                        </Text>
                                                                    </Center>
                                                                </Flex>
                                                            </Button>
                                                        </Tooltip>
                                                    </ScaleFade>

                                                    <ScaleFade
                                                        zIndex={
                                                            fontAdjustSliderShow
                                                                ? '2'
                                                                : '1'
                                                        }
                                                        offsetY="0px"
                                                        animateOpacity
                                                        initialScale={0}
                                                        in={
                                                            !fontAdjustSliderShow
                                                        }
                                                        style={{
                                                            overflow: 'initial',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            label={
                                                                altAerodromeLayout
                                                                    ? 'List by Aerodromes'
                                                                    : 'List by Information Type'
                                                            }
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="22px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="bottom"
                                                            m="5px"
                                                            color="light.10"
                                                            bgColor={'gray.500'}
                                                        >
                                                            <Button // ALTERNATIVE AERODROME INFO LAYOUT
                                                                {...noFocus}
                                                                mx={{
                                                                    base: '0px',
                                                                    ml: '5px',
                                                                }}
                                                                background="none"
                                                                color="light.100"
                                                                opacity={'0.9'}
                                                                isActive={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                fontSize="10px"
                                                                fontWeight="600"
                                                                height="40px"
                                                                width="40px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                paddingLeft="2px"
                                                                paddingRight="2px"
                                                                borderRadius="10px"
                                                                onClick={() => {
                                                                    setAltAerodromeLayout(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                }}
                                                                _focus={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                                _active={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                _hover={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                            >
                                                                <Flex
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    border={{
                                                                        base: 'none',
                                                                        ml: 'none',
                                                                    }}
                                                                    borderRadius="10px"
                                                                    color="light.100"
                                                                    variant="outline"
                                                                    fontSize="1rem"
                                                                    height={{
                                                                        base: '40px',
                                                                        ml: '32px',
                                                                    }}
                                                                    width={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    transition="transform ease 50ms"
                                                                    opacity="0.9"
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            'light.10',
                                                                        boxShadow:
                                                                            {
                                                                                base: 'none',
                                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                            },
                                                                        transform:
                                                                            {
                                                                                base: 'none',
                                                                                md: 'scale(1.08) translateY(-1px)',
                                                                            },
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'light.40',
                                                                        boxShadow:
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            altAerodromeLayout
                                                                                ? AerodromeStdLayoutIcon
                                                                                : AerodromeAltLayoutIcon
                                                                        }
                                                                        width={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                        height={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </Button>
                                                        </Tooltip>
                                                    </ScaleFade>

                                                    <ScaleFade
                                                        initialScale={0}
                                                        zIndex={
                                                            fontAdjustSliderShow
                                                                ? '2'
                                                                : '1'
                                                        }
                                                        offsetY="0px"
                                                        animateOpacity
                                                        in={
                                                            !fontAdjustSliderShow
                                                        }
                                                        style={{
                                                            overflow: 'initial',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            label={
                                                                'Rearrange Categories'
                                                            }
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="22px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="bottom"
                                                            m="5px"
                                                            color="light.10"
                                                            bgColor={'gray.500'}
                                                        >
                                                            <Button // REARRANGE CATEGORIES
                                                                {...noFocus}
                                                                mx={{
                                                                    base: '0px',
                                                                    ml: '5px',
                                                                }}
                                                                background="none"
                                                                color="light.100"
                                                                opacity={'0.9'}
                                                                isActive={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                fontSize="10px"
                                                                fontWeight="600"
                                                                height="40px"
                                                                width="40px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                paddingLeft="2px"
                                                                paddingRight="2px"
                                                                borderRadius="10px"
                                                                onClick={() => {
                                                                    setBriefingOrder(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                }}
                                                                _focus={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                                _active={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                _hover={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                            >
                                                                <Flex
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    border={{
                                                                        base: 'none',
                                                                        ml: 'none',
                                                                    }}
                                                                    borderRadius="10px"
                                                                    color="light.100"
                                                                    variant="outline"
                                                                    fontSize="1rem"
                                                                    height={{
                                                                        base: '40px',
                                                                        ml: '32px',
                                                                    }}
                                                                    width={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    transition="transform ease 50ms"
                                                                    opacity="0.9"
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            'light.10',
                                                                        boxShadow:
                                                                            {
                                                                                base: 'none',
                                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                            },
                                                                        transform:
                                                                            {
                                                                                base: 'none',
                                                                                md: 'scale(1.08) translateY(-1px)',
                                                                            },
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'light.40',
                                                                        boxShadow:
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            briefingOrder
                                                                                ? OrderDownIcon
                                                                                : OrderUpIcon
                                                                        }
                                                                        width={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                        height={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </Button>
                                                        </Tooltip>
                                                    </ScaleFade>

                                                    <ScaleFade
                                                        initialScale={0}
                                                        zIndex={
                                                            fontAdjustSliderShow
                                                                ? '2'
                                                                : '1'
                                                        }
                                                        offsetY="0px"
                                                        animateOpacity
                                                        in={
                                                            !fontAdjustSliderShow
                                                        }
                                                        style={{
                                                            overflow: 'initial',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            label={
                                                                aerodromesSort
                                                                    ? `Sort Aerodromes ${
                                                                          isPremium
                                                                              ? ''
                                                                              : 'and AWS '
                                                                      }North to South`
                                                                    : `Sort Aerodromes ${
                                                                          isPremium
                                                                              ? ''
                                                                              : 'and AWS '
                                                                      }Alphabetically`
                                                            }
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="22px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="bottom"
                                                            m="5px"
                                                            color="light.10"
                                                            bgColor={'gray.500'}
                                                        >
                                                            <Button // SORT AERODROMES
                                                                {...noFocus}
                                                                ml={{
                                                                    base: '0px',
                                                                    ml: '5px',
                                                                }}
                                                                mr={{
                                                                    base: '0px',
                                                                    ml: '5px',
                                                                }}
                                                                background="none"
                                                                color="light.100"
                                                                opacity={'0.9'}
                                                                isActive={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                fontSize="10px"
                                                                fontWeight="600"
                                                                height="40px"
                                                                width="40px"
                                                                minWidth="40px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                paddingLeft="2px"
                                                                paddingRight="2px"
                                                                borderRadius="10px"
                                                                onClick={() => {
                                                                    setAerodromesSort(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                }}
                                                                _focus={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                                _active={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                _hover={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                            >
                                                                <Flex
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    border={{
                                                                        base: 'none',
                                                                        ml: 'none',
                                                                    }}
                                                                    borderRadius="10px"
                                                                    color="light.100"
                                                                    variant="outline"
                                                                    fontSize="1rem"
                                                                    height={{
                                                                        base: '40px',
                                                                        ml: '32px',
                                                                    }}
                                                                    width={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    transition="transform ease 50ms"
                                                                    opacity="0.9"
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            'light.10',
                                                                        boxShadow:
                                                                            {
                                                                                base: 'none',
                                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                            },
                                                                        transform:
                                                                            {
                                                                                base: 'none',
                                                                                md: 'scale(1.08) translateY(-1px)',
                                                                            },
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'light.40',
                                                                        boxShadow:
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            aerodromesSort
                                                                                ? SortNorthToSouthIcon
                                                                                : SortAlphabeticalIcon
                                                                        }
                                                                        width={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                        height={{
                                                                            base: '24px',
                                                                            ml: '20px',
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </Button>
                                                        </Tooltip>
                                                    </ScaleFade>

                                                    <Flex
                                                        alignItems="center"
                                                        height={{
                                                            base: '40px',
                                                            ml: '36px',
                                                        }}
                                                        width={{
                                                            base: fontAdjustSliderShow
                                                                ? 'calc(100vw - 40px)'
                                                                : '40px',
                                                            md: fontAdjustSliderShow
                                                                ? 'calc(100% - 40px)'
                                                                : '40px',
                                                            ml: fontAdjustSliderShow
                                                                ? '383px'
                                                                : '40px',
                                                        }}
                                                        minWidth={{
                                                            base: fontAdjustSliderShow
                                                                ? 'calc(100vw - 40px)'
                                                                : '40px',
                                                            sm: fontAdjustSliderShow
                                                                ? 'calc(100vw - 120px)'
                                                                : '40px',
                                                            smd: fontAdjustSliderShow
                                                                ? 'calc(100vw - 200px)'
                                                                : '40px',
                                                            md: fontAdjustSliderShow
                                                                ? 'calc(100% - 40px)'
                                                                : '40px',
                                                            ml: fontAdjustSliderShow
                                                                ? '383px'
                                                                : '40px',
                                                        }}
                                                        maxWidth={{
                                                            base: fontAdjustSliderShow
                                                                ? 'calc(100vw - 40px)'
                                                                : '40px',
                                                            md: fontAdjustSliderShow
                                                                ? 'calc(100% - 40px)'
                                                                : '40px',
                                                            ml: fontAdjustSliderShow
                                                                ? '383px'
                                                                : '40px',
                                                        }}
                                                        pl={
                                                            fontAdjustSliderShow
                                                                ? '15px'
                                                                : '0px'
                                                        }
                                                        pr={{
                                                            base: '5px',
                                                            ml: '0px',
                                                        }}
                                                        ml={{
                                                            base: fontAdjustSliderShow
                                                                ? '-125px'
                                                                : '0px',
                                                            ml: fontAdjustSliderShow
                                                                ? '-243px'
                                                                : '5px',
                                                        }}
                                                        mr={{
                                                            base: '0px',
                                                            ml: fontAdjustSliderShow
                                                                ? '-90px'
                                                                : '5px',
                                                        }}
                                                        transition="all ease 300ms"
                                                        boxShadow={
                                                            fontAdjustSliderShow
                                                                ? 'inset 0px 0px 5px -2px rgba(0,0,0,0.3)'
                                                                : 'inset 0px 0px 5px -2px rgba(0,0,0,0)'
                                                        }
                                                        borderRadius={{
                                                            base: '14px',
                                                            ml: '12px',
                                                        }}
                                                        bg={{
                                                            base:
                                                                fontAdjustSliderShow &&
                                                                isFirefox
                                                                    ? 'light.25'
                                                                    : fontAdjustSliderShow
                                                                    ? 'rgba(248, 250, 253, 0.4)'
                                                                    : 'none',
                                                            ml: fontAdjustSliderShow
                                                                ? 'rgba(248, 250, 253, 0.4)'
                                                                : 'none',
                                                        }}
                                                        backdropFilter={{
                                                            base: fontAdjustSliderShow
                                                                ? 'blur(15px)'
                                                                : 'none',
                                                            md: 'none',
                                                        }}
                                                    >
                                                        <Flex
                                                            opacity={
                                                                fontAdjustSliderShow
                                                                    ? '1'
                                                                    : '0'
                                                            }
                                                            visibility={
                                                                fontAdjustSliderShow
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }
                                                            width="100%"
                                                            pl={
                                                                fontAdjustSliderShow
                                                                    ? '10px'
                                                                    : '0px'
                                                            }
                                                            pr={
                                                                fontAdjustSliderShow
                                                                    ? '25px'
                                                                    : '0px'
                                                            }
                                                            transition="all ease 300ms"
                                                            height="36px"
                                                        >
                                                            <Flex
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                height="100%"
                                                                width={
                                                                    fontAdjustSliderShow
                                                                        ? '60px'
                                                                        : '0px'
                                                                }
                                                                minWidth={
                                                                    fontAdjustSliderShow
                                                                        ? '60px'
                                                                        : '0px'
                                                                }
                                                                pr={
                                                                    fontAdjustSliderShow
                                                                        ? '30px'
                                                                        : '0px'
                                                                }
                                                            >
                                                                <Text
                                                                    letterSpacing="0.5px"
                                                                    color="light.100"
                                                                    transition="all ease 100ms"
                                                                    fontWeight="500"
                                                                    fontSize={calculateFontSize(
                                                                        0.8
                                                                    )}
                                                                >
                                                                    {`${fontSizeLabel}`}
                                                                </Text>
                                                                <Text
                                                                    pl="3px"
                                                                    letterSpacing="0.5px"
                                                                    color="light.100"
                                                                    transition="all ease 100ms"
                                                                    fontWeight="500"
                                                                    fontSize={calculateFontSize(
                                                                        0.8
                                                                    )}
                                                                >
                                                                    x
                                                                </Text>
                                                            </Flex>

                                                            <Slider
                                                                variant="fontAdjust"
                                                                aria-label="fontAdjustSlider"
                                                                focusThumbOnChange={
                                                                    false
                                                                }
                                                                defaultValue={
                                                                    briefingFontSize
                                                                }
                                                                min={0.0}
                                                                max={0.5}
                                                                step={0.1}
                                                                onChange={(
                                                                    val
                                                                ) =>
                                                                    setBriefingFontSize(
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <SliderTrack bg="gray.300">
                                                                    <Box
                                                                        position="relative"
                                                                        right={
                                                                            10
                                                                        }
                                                                    />
                                                                </SliderTrack>
                                                                <SliderThumb
                                                                    boxSize={5}
                                                                />
                                                            </Slider>
                                                        </Flex>
                                                        <Tooltip
                                                            label={
                                                                fontAdjustSliderShow
                                                                    ? 'Close Font Size Adjustment'
                                                                    : 'Adjust Font Size'
                                                            }
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="22px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="bottom"
                                                            m="5px"
                                                            color="light.10"
                                                            bgColor={'gray.500'}
                                                        >
                                                            <Button // INCREASE FONT SIZE
                                                                {...noFocus}
                                                                zIndex="2"
                                                                background="none"
                                                                color="light.100"
                                                                opacity={'0.9'}
                                                                isActive={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                fontSize="10px"
                                                                fontWeight="600"
                                                                height="40px"
                                                                width="40px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                paddingLeft="2px"
                                                                paddingRight="2px"
                                                                borderRadius="10px"
                                                                onClick={() => {
                                                                    setFontAdjustSliderShow(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                }}
                                                                _focus={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                                _active={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                _hover={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                            >
                                                                <Flex
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    border={{
                                                                        base: 'none',
                                                                        ml: 'none',
                                                                    }}
                                                                    background={{
                                                                        base: 'none',
                                                                        ml:
                                                                            !fontAdjustSliderShow &&
                                                                            'light.10',
                                                                    }}
                                                                    borderRadius="10px"
                                                                    color="light.100"
                                                                    variant="outline"
                                                                    fontSize="1rem"
                                                                    height={{
                                                                        base: '40px',
                                                                        ml: '32px',
                                                                    }}
                                                                    width={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    transition={
                                                                        !fontAdjustSliderShow &&
                                                                        'transform ease 50ms'
                                                                    }
                                                                    opacity="0.9"
                                                                    _focus={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            buttonBg,
                                                                    }}
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            !fontAdjustSliderShow &&
                                                                            buttonBg,
                                                                        boxShadow:
                                                                            !fontAdjustSliderShow && {
                                                                                base: 'none',
                                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                            },
                                                                        transform:
                                                                            {
                                                                                base: 'none',
                                                                                md: fontAdjustSliderShow
                                                                                    ? 'scale(1.08)'
                                                                                    : 'scale(1.08) translateY(-1px)',
                                                                            },
                                                                    }}
                                                                    _active={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            !fontAdjustSliderShow &&
                                                                            'light.40',
                                                                        boxShadow:
                                                                            !fontAdjustSliderShow &&
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            fontAdjustSliderShow
                                                                                ? CloseIcon
                                                                                : fontSizeLabel ===
                                                                                  1
                                                                                ? FontAdjust
                                                                                : FontAdjustNoLine
                                                                        }
                                                                        width={{
                                                                            base: '30px',
                                                                            ml: '26px',
                                                                        }}
                                                                        height={{
                                                                            base: '30px',
                                                                            ml: '26px',
                                                                        }}
                                                                    />
                                                                </Flex>
                                                                {fontSizeLabel !==
                                                                    1 && (
                                                                    <Badge
                                                                        opacity={
                                                                            fontAdjustSliderShow
                                                                                ? '0'
                                                                                : '1'
                                                                        }
                                                                        visibility={
                                                                            fontAdjustSliderShow
                                                                                ? 'hidden'
                                                                                : 'visible'
                                                                        }
                                                                        position="absolute"
                                                                        variant="solid"
                                                                        bg="none"
                                                                        color="white"
                                                                        borderRadius="xl"
                                                                        marginTop={{
                                                                            base: '16px',
                                                                            ml: '13px',
                                                                        }}
                                                                        minWidth="35px"
                                                                        textAlign="center"
                                                                        fontSize="0.55rem"
                                                                        height="14px"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        display="flex"
                                                                    >
                                                                        <Flex>
                                                                            <Text
                                                                                pl="1px"
                                                                                letterSpacing="0.5px"
                                                                                color="light.100"
                                                                                transition="all ease 300ms"
                                                                                fontWeight="500"
                                                                            >
                                                                                {`${fontSizeLabel}`}
                                                                            </Text>
                                                                            <Text
                                                                                textTransform="lowercase"
                                                                                pl="1px"
                                                                                letterSpacing="0.5px"
                                                                                color="light.100"
                                                                                transition="all ease 300ms"
                                                                                fontWeight="500"
                                                                            >
                                                                                x
                                                                            </Text>
                                                                        </Flex>
                                                                    </Badge>
                                                                )}
                                                            </Button>
                                                        </Tooltip>
                                                    </Flex>

                                                    <ScaleFade
                                                        initialScale={0}
                                                        zIndex={
                                                            fontAdjustSliderShow
                                                                ? '2'
                                                                : '1'
                                                        }
                                                        offsetY="0px"
                                                        animateOpacity
                                                        in={
                                                            !fontAdjustSliderShow
                                                        }
                                                        style={{
                                                            overflow: 'initial',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            label="Briefing Details"
                                                            hasArrow
                                                            borderRadius="7px"
                                                            height="22px"
                                                            fontSize="0.7rem"
                                                            alignItems="center"
                                                            display="flex"
                                                            placement="bottom"
                                                            m="5px"
                                                            color="light.10"
                                                            bgColor={'gray.500'}
                                                        >
                                                            <Button // SHOW DETAILS
                                                                {...noFocus}
                                                                ml={{
                                                                    base: '-2px',
                                                                    ml: '5px',
                                                                }}
                                                                mr={{
                                                                    base: '-4px',
                                                                    ml: '5px',
                                                                }}
                                                                display={{
                                                                    base: 'inline-flex',
                                                                    ml: 'inline-flex',
                                                                    lg: 'inline-flex',
                                                                }}
                                                                background="none"
                                                                color="light.100"
                                                                opacity={
                                                                    detailsShow
                                                                        ? '1'
                                                                        : '0.9'
                                                                }
                                                                isActive={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                fontSize="10px"
                                                                fontWeight="600"
                                                                height="40px"
                                                                width="40px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                paddingLeft="2px"
                                                                paddingRight="2px"
                                                                borderRadius="10px"
                                                                onClick={() => {
                                                                    setDetailsShow(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            !prevState
                                                                    )
                                                                    setFontAdjustSliderShow(
                                                                        false
                                                                    )
                                                                    setExcludedItemsShow(
                                                                        false
                                                                    )
                                                                    setMobileNavButtonsShow(
                                                                        false
                                                                    )
                                                                    setMobileDrawerExpanded(
                                                                        false
                                                                    )
                                                                    setExtendedHeight(
                                                                        false
                                                                    ) // temp (remove for partial slider height)
                                                                }}
                                                                _focus={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                                _active={{
                                                                    opacity:
                                                                        '0.9',
                                                                }}
                                                                _hover={{
                                                                    opacity:
                                                                        '1 !important',
                                                                }}
                                                            >
                                                                <Flex
                                                                    // borderColor="#eef1f5"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    border={{
                                                                        base: 'none',
                                                                        ml: 'none',
                                                                    }}
                                                                    borderRadius="10px"
                                                                    color={
                                                                        detailsShow
                                                                            ? 'light.200'
                                                                            : 'light.100'
                                                                    }
                                                                    variant="outline"
                                                                    fontSize="1rem"
                                                                    height={{
                                                                        base: '40px',
                                                                        ml: '32px',
                                                                    }}
                                                                    width={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '40px',
                                                                        ml: '36px',
                                                                    }}
                                                                    transition="transform ease 50ms"
                                                                    opacity="0.9"
                                                                    boxShadow={{
                                                                        ml:
                                                                            detailsShow &&
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.25)',
                                                                    }}
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        background:
                                                                            'light.10',
                                                                        boxShadow:
                                                                            {
                                                                                base: 'none',
                                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                            },
                                                                        transform:
                                                                            {
                                                                                base: 'none',
                                                                                md: 'scale(1.08) translateY(-1px)',
                                                                            },
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'light.40',
                                                                        boxShadow:
                                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            InfoIcon
                                                                        }
                                                                        width={{
                                                                            base: '28px',
                                                                            ml: '24px',
                                                                        }}
                                                                        height={{
                                                                            base: '28px',
                                                                            ml: '24px',
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </Button>
                                                        </Tooltip>
                                                    </ScaleFade>

                                                    <ScaleFade
                                                        initialScale={0}
                                                        zIndex={
                                                            fontAdjustSliderShow
                                                                ? '2'
                                                                : '1'
                                                        }
                                                        offsetY="0px"
                                                        animateOpacity
                                                        in={
                                                            !fontAdjustSliderShow
                                                        }
                                                        style={{
                                                            overflow: 'initial',
                                                        }}
                                                    >
                                                        {!mobileDrawerExpanded && (
                                                            <Flex
                                                                mx={{
                                                                    base: '0px',
                                                                    ml: '5px',
                                                                }}
                                                            >
                                                                <ExportOptions
                                                                    dataLoading={
                                                                        status ===
                                                                        'loading'
                                                                    }
                                                                />
                                                            </Flex>
                                                        )}
                                                    </ScaleFade>
                                                </Flex>
                                            </Portal>
                                            <Box ref={desktop}></Box>

                                            <Flex
                                                alignItems="center"
                                                justifyContent="center"
                                                flexDirection={{
                                                    base: 'row',
                                                    ml: 'row',
                                                }}
                                            >
                                                <Tooltip
                                                    label="Go Back"
                                                    hasArrow
                                                    borderRadius="7px"
                                                    height="22px"
                                                    fontSize="0.7rem"
                                                    alignItems="center"
                                                    display="flex"
                                                    placement="bottom"
                                                    m="5px"
                                                    color="light.10"
                                                    bgColor={'gray.500'}
                                                >
                                                    <Button // GO BACK BUTTON
                                                        {...noFocus}
                                                        ml={{
                                                            base: '0px',
                                                            ml: '5px',
                                                        }}
                                                        background="none"
                                                        color="light.100"
                                                        opacity={
                                                            online && '0.9'
                                                        }
                                                        isActive={
                                                            online && {
                                                                opacity: '0.9',
                                                            }
                                                        }
                                                        fontSize="10px"
                                                        fontWeight="600"
                                                        height="40px"
                                                        width="40px"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        paddingLeft="2px"
                                                        paddingRight="2px"
                                                        borderRadius="10px"
                                                        isDisabled={!online}
                                                        onClick={() => {
                                                            onCloseHandler()
                                                        }}
                                                        _focus={{
                                                            opacity:
                                                                '1 !important',
                                                        }}
                                                        _active={
                                                            online && {
                                                                opacity: '0.9',
                                                            }
                                                        }
                                                        _hover={{
                                                            opacity:
                                                                '1 !important',
                                                        }}
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            border={{
                                                                base: 'none',
                                                                ml: 'none',
                                                            }}
                                                            borderRadius="10px"
                                                            color="light.100"
                                                            variant="outline"
                                                            fontSize="1rem"
                                                            height={{
                                                                base: '40px',
                                                                ml: '32px',
                                                            }}
                                                            width={{
                                                                base: '40px',
                                                                ml: '36px',
                                                            }}
                                                            minWidth={{
                                                                base: '40px',
                                                                ml: '36px',
                                                            }}
                                                            transition="transform ease 50ms"
                                                            opacity="0.9"
                                                            _hover={{
                                                                opacity: '1',
                                                                background:
                                                                    'light.10',
                                                                boxShadow: {
                                                                    base: 'none',
                                                                    md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                                },
                                                                transform: {
                                                                    base: 'none',
                                                                    md: 'scale(1.08) translateY(-1px)',
                                                                },
                                                            }}
                                                            _active={{
                                                                background:
                                                                    'light.40',
                                                                boxShadow:
                                                                    '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                            }}
                                                        >
                                                            <Icon
                                                                width={{
                                                                    base: '26px',
                                                                    ml: '22px',
                                                                }}
                                                                height={{
                                                                    base: '26px',
                                                                    ml: '22px',
                                                                }}
                                                                as={GoBackIcon}
                                                            />
                                                        </Flex>
                                                    </Button>
                                                </Tooltip>
                                            </Flex>
                                        </Flex>

                                        <Flex // REFETCH BUTTON
                                            display={{
                                                base: 'none',
                                                ml: 'flex',
                                            }}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Tooltip
                                                label="Refetch Information"
                                                hasArrow
                                                borderRadius="7px"
                                                height="22px"
                                                fontSize="0.7rem"
                                                alignItems="center"
                                                display="flex"
                                                placement="bottom"
                                                m="5px"
                                                color="light.10"
                                                bgColor={'gray.500'}
                                            >
                                                <Button
                                                    {...noFocus}
                                                    ml={{
                                                        base: '0px',
                                                        ml: '10px',
                                                    }}
                                                    background="none"
                                                    color="light.100"
                                                    opacity={online && '0.9'}
                                                    isActive={
                                                        online && {
                                                            opacity: '0.9',
                                                        }
                                                    }
                                                    fontSize="10px"
                                                    fontWeight="600"
                                                    height="40px"
                                                    width="40px"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    paddingLeft="2px"
                                                    paddingRight="2px"
                                                    borderRadius="10px"
                                                    isDisabled={!online}
                                                    onClick={() => {
                                                        handleRefresh()
                                                    }}
                                                    _focus={{
                                                        opacity: '1 !important',
                                                    }}
                                                    _active={
                                                        online && {
                                                            opacity: '0.9',
                                                        }
                                                    }
                                                    _hover={{
                                                        opacity: '1 !important',
                                                    }}
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        border={{
                                                            base: 'none',
                                                            ml: 'none',
                                                        }}
                                                        borderRadius="10px"
                                                        color="light.100"
                                                        variant="outline"
                                                        fontSize="1rem"
                                                        height={{
                                                            base: '40px',
                                                            ml: '32px',
                                                        }}
                                                        width={{
                                                            base: '40px',
                                                            ml: '36px',
                                                        }}
                                                        minWidth={{
                                                            base: '40px',
                                                            ml: '36px',
                                                        }}
                                                        transition="transform ease 50ms"
                                                        opacity="0.9"
                                                        _hover={{
                                                            opacity: '1',
                                                            background:
                                                                'light.10',
                                                            boxShadow: {
                                                                base: 'none',
                                                                md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                            },
                                                            transform: {
                                                                base: 'none',
                                                                md: 'scale(1.08) translateY(-1px)',
                                                            },
                                                        }}
                                                        _active={{
                                                            background:
                                                                'light.40',
                                                            boxShadow:
                                                                '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                        }}
                                                    >
                                                        {isRefetching ? (
                                                            <Flex
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Spinner
                                                                    boxSize="20px"
                                                                    color="light.200"
                                                                    emptyColor={
                                                                        'gray.100'
                                                                    }
                                                                />
                                                            </Flex>
                                                        ) : (
                                                            <Icon
                                                                width={{
                                                                    base: '26px',
                                                                    ml: '22px',
                                                                }}
                                                                height={{
                                                                    base: '26px',
                                                                    ml: '22px',
                                                                }}
                                                                as={RefreshIcon}
                                                            />
                                                        )}
                                                    </Flex>
                                                </Button>
                                            </Tooltip>
                                        </Flex>

                                        <Tooltip
                                            label={
                                                mobileNavButtonsShow
                                                    ? 'Close Options'
                                                    : 'Open More Options'
                                            }
                                            hasArrow
                                            borderRadius="7px"
                                            height="22px"
                                            fontSize="0.7rem"
                                            alignItems="center"
                                            display="flex"
                                            placement="bottom"
                                            m="5px"
                                            color="light.10"
                                            bgColor={'gray.500'}
                                        >
                                            <Button // MOBILE NAV SHOW
                                                {...noFocus}
                                                display={{
                                                    base: 'inline-flex',
                                                    ml: 'none',
                                                }}
                                                ml={{
                                                    base: '7px',
                                                    xs: '10px',
                                                    ml: '0',
                                                }}
                                                background="none"
                                                color="light.100"
                                                opacity={
                                                    mobileNavButtonsShow
                                                        ? '1'
                                                        : '0.9'
                                                }
                                                transition="opacity ease 300ms"
                                                isActive={{ opacity: '0.9' }}
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setMobileNavButtonsShow(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                    setFontAdjustSliderShow(
                                                        false
                                                    )
                                                    setExcludedItemsShow(false)
                                                    setDetailsShow(false)
                                                    setMobileDrawerExpanded(
                                                        false
                                                    )
                                                    setExtendedHeight(false) // temp (remove for partial slider height)
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                }}
                                                _active={{
                                                    opacity: '0.9',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                }}
                                            >
                                                <Flex
                                                    // borderColor="#eef1f5"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    border={{
                                                        base: 'none',
                                                        ml: 'none',
                                                    }}
                                                    borderRadius="10px"
                                                    color="light.100"
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height={{
                                                        base: '40px',
                                                        ml: '32px',
                                                    }}
                                                    width={{
                                                        base: '40px',
                                                        ml: '36px',
                                                    }}
                                                    minWidth={{
                                                        base: '40px',
                                                        ml: '36px',
                                                    }}
                                                    transition="transform ease 50ms"
                                                    opacity="0.9"
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.10',
                                                        boxShadow: {
                                                            base: 'none',
                                                            md: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                                                        },
                                                        transform: {
                                                            base: 'none',
                                                            md: 'scale(1.08) translateY(-1px)',
                                                        },
                                                    }}
                                                    _active={{
                                                        background: 'light.40',
                                                        boxShadow:
                                                            '0px 3px 7px -2px rgba(0,0,0,0.3)',
                                                    }}
                                                >
                                                    <Icon
                                                        fontSize={{
                                                            base: '20px',
                                                            ml: '1.2rem',
                                                        }}
                                                        ml={'2px'}
                                                        style={{
                                                            marginInlineEnd:
                                                                '0.2rem',
                                                            marginRight:
                                                                '0.2rem',
                                                        }}
                                                        transform="rotate(90deg)"
                                                        transition="all 200ms"
                                                        as={
                                                            mobileNavButtonsShow
                                                                ? TfiMoreAlt
                                                                : TfiMore
                                                        }
                                                    />
                                                </Flex>
                                            </Button>
                                        </Tooltip>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </ModalHeader>
                        <ModalBody
                            p={0}
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            <Box w="100%" h="100%">
                                <Box // Shaded background behind Slide Up and Slide Down Drawers.
                                    background={{
                                        base:
                                            mobileDrawerExpanded ||
                                            detailsShow ||
                                            excludedItemsShow
                                                ? 'rgba(0,0,0,0.5)'
                                                : 'transparent',
                                        md:
                                            detailsShow || excludedItemsShow
                                                ? 'rgba(0,0,0,0.5)'
                                                : 'transparent',
                                    }}
                                    transition="all ease-in 500ms"
                                    transitionDelay="0ms"
                                    height="100%"
                                    width="100%"
                                    visibility={
                                        mobileDrawerExpanded ||
                                        detailsShow ||
                                        excludedItemsShow
                                            ? 'visible'
                                            : 'hidden'
                                    }
                                    display="flex"
                                    zIndex={{ base: '601', md: '600' }}
                                    position="absolute"
                                    top="0px"
                                    onClick={() => {
                                        setMobileDrawerExpanded(false)
                                        setFontAdjustSliderShow(false)
                                        setExtendedHeight(false) // temp (remove for partial slider height)
                                        setDetailsShow(false)
                                        setExcludedItemsShow(false)
                                    }}
                                />
                                <Box
                                    h={{
                                        base: 'initial',
                                        md: '100%',
                                    }}
                                    variant="enclosed-colored"
                                    ref={containerRef}
                                >
                                    <Flex
                                        background={{
                                            base: undefined,
                                            md: 'light.10',
                                        }}
                                        position={{
                                            base: 'absolute',
                                            md: 'relative',
                                        }}
                                        bottom={{
                                            base:
                                                scrollDirection === 'up'
                                                    ? '-50px'
                                                    : '0px',
                                            md: 'unset',
                                        }}
                                        height={{
                                            base:
                                                mobileDrawerExpanded &&
                                                extendedHeight
                                                    ? 'calc(100% - 120px)'
                                                    : mobileDrawerExpanded
                                                    ? '44vh'
                                                    : '66px',
                                            md: '100%',
                                        }}
                                        maxHeight={{
                                            base:
                                                mobileDrawerExpanded &&
                                                extendedHeight &&
                                                '740px',
                                            md: '100%',
                                        }}
                                        willChange="auto"
                                        w={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? '60px'
                                                : '250px',
                                        }}
                                        minWidth={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? '60px'
                                                : '250px',
                                        }}
                                        transition={
                                            excludedItemsShow || detailsShow
                                                ? 'all ease 100ms'
                                                : 'all ease 250ms'
                                        }
                                        borderRight={{
                                            base: 'none',
                                            md: '1px solid #E2E8F0',
                                        }}
                                        flexDirection="column"
                                        justifyContent={{
                                            base: 'center',
                                            md: 'space-between',
                                        }}
                                        zIndex={
                                            mobileDrawerExpanded ? '603' : '601'
                                        }
                                    >
                                        <ErrorBoundary
                                            FallbackComponent={ErrorFallback}
                                            onReset={() => {
                                                // reset the state of your app so the error doesn't happen again
                                            }}
                                        >
                                            <BriefingMenu
                                                style={{ height: '100%' }}
                                                minHeight={{
                                                    base:
                                                        mobileDrawerExpanded &&
                                                        extendedHeight &&
                                                        '740px',
                                                    md: '100%',
                                                }}
                                                briefingData={briefingData}
                                                dataLoading={
                                                    status === 'loading'
                                                }
                                                buttonSelected={buttonSelected}
                                                setButtonSelected={
                                                    setButtonSelected
                                                }
                                                setScrollDirection={
                                                    setScrollDirection
                                                }
                                                scrollDirection={
                                                    scrollDirection
                                                }
                                                minimiseDesktopMenu={
                                                    minimiseDesktopMenu
                                                }
                                                setMinimiseDesktopMenu={
                                                    setMinimiseDesktopMenu
                                                }
                                            />
                                        </ErrorBoundary>
                                    </Flex>

                                    <Flex // Layer over top of Briefing content to show when data is fetching
                                        opacity={isRefetching ? '1' : '0'}
                                        visibility={
                                            isRefetching ? 'visible' : 'hidden'
                                        }
                                        pointerEvents={
                                            isRefetching ? 'auto' : 'none'
                                        }
                                        cursor={isRefetching ? 'wait' : 'auto'}
                                        transition="all ease 250ms"
                                        width="100%"
                                        height="100%"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        zIndex="600"
                                        position="absolute"
                                        top="0px"
                                        pl={{
                                            base: '0px',
                                            md: minimiseDesktopMenu
                                                ? '60px'
                                                : '250px',
                                        }}
                                        pt={{
                                            base: '50%',
                                            md: '10%',
                                        }}
                                        bg="rgba(5, 10, 15, 0.2)"
                                    >
                                        <CircularProgress
                                            isIndeterminate
                                            size="60px"
                                            thickness="5px"
                                            color="light.200"
                                            display={{
                                                base: 'none', // don't nbeed this for mobile as has pull to refresh dots
                                                md: 'block',
                                            }}
                                        ></CircularProgress>
                                    </Flex>

                                    <Flex
                                        position="absolute"
                                        w={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? 'calc(100% - 60px)'
                                                : 'calc(100% - 250px)',
                                        }}
                                        minWidth={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? 'calc(100% - 60px)'
                                                : 'calc(100% - 250px)',
                                        }}
                                        maxWidth={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? 'calc(100% - 60px)'
                                                : 'calc(100% - 250px)',
                                        }}
                                        marginLeft={{
                                            base: '0px',
                                            md: minimiseDesktopMenu
                                                ? '60px'
                                                : '250px',
                                        }}
                                        top="69px"
                                        borderTop={{
                                            base: '1px solid #E2E8F0',
                                            md: '1px solid #E2E8F0',
                                        }}
                                        bg={{
                                            base: isFirefox
                                                ? '#F8fafd'
                                                : 'rgba(248, 250, 253, 0.7)',
                                            ml: 'transparent',
                                        }}
                                        backdropFilter="blur(20px)"
                                        whiteSpace="pre-line"
                                        className={'BOB'}
                                        borderTopColor="#E2E8F0"
                                        flexDirection="column"
                                        zIndex={{ base: '603', md: '600' }}
                                        transition="all ease 300ms"
                                    >
                                        <Flex // DROPDOWN DRAWER TO STORE NAV BUTTONS ON MOBILE
                                            width="100%"
                                            height={{
                                                base: mobileNavButtonsShow
                                                    ? '56px'
                                                    : '0',
                                                md: mobileNavButtonsShow
                                                    ? '50px'
                                                    : '0',
                                                ml: '0',
                                            }}
                                            minHeight={{
                                                base: mobileNavButtonsShow
                                                    ? '56px'
                                                    : '0',
                                                md: mobileNavButtonsShow
                                                    ? '50px'
                                                    : '0',
                                                ml: '0',
                                            }}
                                            px="0px"
                                            transition="all 300ms"
                                            // bg="#F8fafd"
                                            borderBottom={{
                                                base:
                                                    mobileNavButtonsShow &&
                                                    isFirefox
                                                        ? '1px solid #E2E8F0'
                                                        : 'none',
                                                ml: 'none',
                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                            borderColor="#E2E8F0"
                                            boxShadow={{
                                                base: mobileNavButtonsShow
                                                    ? '0px 5px 10px -1px rgba(0,0,0,0.1)'
                                                    : '0px 5px 10px -1px rgba(0,0,0,0.1)',
                                                ml: 'none',
                                            }}
                                            overflowY="hidden"
                                        >
                                            <Box
                                                transition="all ease 300ms"
                                                mt={
                                                    mobileNavButtonsShow
                                                        ? '0px'
                                                        : '-70px'
                                                }
                                                height={
                                                    mobileNavButtonsShow
                                                        ? '40px'
                                                        : '0px'
                                                }
                                                width="100%"
                                                ref={mobile}
                                            ></Box>
                                        </Flex>
                                        <Flex // DROPDOWN DRAWER TO STORE EXCLUDED AERODROMES
                                            width="100%"
                                            px="0px"
                                            pt={{
                                                base: '0px',
                                                md: excludedItemsShow
                                                    ? '15px'
                                                    : '0px',
                                            }}
                                            height={{
                                                base: excludedItemsShow
                                                    ? 'max-content'
                                                    : '0',
                                                md: excludedItemsShow
                                                    ? 'max-content'
                                                    : '0',
                                            }}
                                            transition="all 300ms"
                                            bg="#F8fafd"
                                            borderBottom={
                                                excludedItemsShow
                                                    ? '1px solid #E2E8F0'
                                                    : 'none'
                                            }
                                            borderColor="#E2E8F0"
                                        >
                                            <Flex
                                                opacity={
                                                    excludedItemsShow
                                                        ? '1'
                                                        : '0'
                                                }
                                                width="100%"
                                                flexDirection="column"
                                            >
                                                <ExcludedItems
                                                    briefingData={briefingData}
                                                    isBriefingOnly={
                                                        isBriefingOnly
                                                    }
                                                    viewportHeight={
                                                        viewportHeight
                                                    }
                                                    // showManageFavourites={
                                                    //     showManageFavourites
                                                    // }
                                                />
                                            </Flex>
                                        </Flex>
                                        <Flex // DROPDOWN DRAWER TO STORE BRIEFING INFO
                                            // {...drawerSwipe} // disabled for now when there is overflow on mobile
                                            width="100%"
                                            px="0px"
                                            pt={detailsShow ? '20px' : '0px'}
                                            height={{
                                                base: detailsShow
                                                    ? 'max-content'
                                                    : '0px',
                                                md: detailsShow
                                                    ? 'max-content'
                                                    : '0px',
                                            }}
                                            // maxHeight={{
                                            //     base: detailsShow
                                            //         ? '75vh'
                                            //         : '0',
                                            //     md: detailsShow ? '80vh' : '0',
                                            //     ml: detailsShow ? '70vh' : '0',
                                            // }}
                                            display="flex"
                                            transition="all ease-in-out 300ms"
                                            bg="#F8fafd"
                                            borderBottom={
                                                detailsShow
                                                    ? '1px solid #E2E8F0'
                                                    : 'none'
                                            }
                                            borderColor="#E2E8F0"
                                        >
                                            <Flex
                                                width="100%"
                                                flexDirection="column"
                                                opacity={
                                                    detailsShow ? '1' : '0'
                                                }
                                                visibility={
                                                    detailsShow
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                            >
                                                <Text
                                                    pl="20px"
                                                    alignSelf="left"
                                                    fontSize="0.9rem"
                                                    color="gray.500"
                                                    fontWeight="700"
                                                    lineHeight="1.2"
                                                >
                                                    PreFlight Information
                                                    Briefing
                                                </Text>
                                                <Divider
                                                    height="0px !important"
                                                    borderColor="#e9edf3"
                                                    width="100%"
                                                    mx="auto"
                                                    mt="7px"
                                                    mb="0px"
                                                />

                                                {status !== 'loading' && (
                                                    <Flex
                                                        py="15px"
                                                        pl="5px"
                                                        pr="20px"
                                                        overflowY="auto"
                                                        overflowX="hidden"
                                                        maxHeight={{
                                                            base: isBriefingOnly
                                                                ? `calc(${viewportHeight}px - 215px)`
                                                                : `calc(${viewportHeight}px - 245px)`,
                                                            md: '80vh',
                                                            ml: '70vh',
                                                        }}
                                                    >
                                                        <ErrorBoundary
                                                            FallbackComponent={
                                                                ErrorFallback
                                                            }
                                                            onReset={() => {
                                                                // reset the state of your app so the error doesn't happen again
                                                            }}
                                                        >
                                                            <BriefingInfo
                                                                data={
                                                                    briefingData
                                                                }
                                                                isPremium={
                                                                    isPremium
                                                                }
                                                                // favouriteTitle={
                                                                //     favouriteTitle
                                                                // }
                                                                currentFavourite={
                                                                    currentFavourite
                                                                }
                                                                viewportHeight={
                                                                    viewportHeight
                                                                }
                                                            />
                                                        </ErrorBoundary>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>

                                        <Flex
                                            w="100%"
                                            style={{
                                                transition:
                                                    'top ease-in-out 300ms',
                                            }}
                                            whiteSpace="pre-line"
                                            borderTopColor="#E2E8F0"
                                            px={{
                                                base: '15px',
                                                md: '20px',
                                                lg: '25px',
                                            }}
                                            direction={{
                                                base: 'row',
                                                md: 'row',
                                            }}
                                            bg="light.600"
                                            boxShadow={{
                                                base:
                                                    isAdmin &&
                                                    showErrorToggle &&
                                                    '0px 5px 10px -1px rgba(0,0,0,0.1)',
                                                md: 'none',
                                            }}
                                            h={
                                                isAdmin && showErrorToggle
                                                    ? '41px'
                                                    : '0'
                                            }
                                            justifyContent={{
                                                base: 'center',
                                                md: 'center',
                                            }}
                                            py={
                                                isAdmin && showErrorToggle
                                                    ? '10px'
                                                    : '0'
                                            }
                                            borderBottom={
                                                isAdmin &&
                                                showErrorToggle &&
                                                '1px solid #E2E8F0'
                                            }
                                            flexDirection="column"
                                        >
                                            <HStack
                                                spacing="15px"
                                                align="center"
                                                justifyContent={{
                                                    base: 'center',
                                                    md: 'center',
                                                }}
                                            >
                                                <Flex
                                                    bg="#ffffff"
                                                    borderRadius="20px"
                                                >
                                                    <ErrorToggle
                                                        showErrors={
                                                            showNetworkErrors
                                                        }
                                                        setShowErrors={
                                                            setShowNetworkErrors
                                                        }
                                                        label="Simulate Network Error"
                                                    />
                                                </Flex>
                                                <Flex
                                                    bg="#ffffff"
                                                    borderRadius="20px"
                                                >
                                                    <ErrorToggle
                                                        showErrors={
                                                            showOutdatedErrors
                                                        }
                                                        setShowErrors={
                                                            setShowOutdatedErrors
                                                        }
                                                        label="Simulate Out-of-Date Error"
                                                    />
                                                </Flex>
                                            </HStack>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        className="settingsScrollVertical"
                                        ref={scrollRef}
                                        onScroll={handleScroll}
                                        // className="scrollable-container"
                                        onTouchStart={handleTouchStart}
                                        onTouchMove={handleTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                        position="absolute"
                                        w={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? 'calc(100% - 60px)'
                                                : 'calc(100% - 250px)',
                                        }}
                                        minWidth={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? 'calc(100% - 60px)'
                                                : 'calc(100% - 250px)',
                                        }}
                                        maxWidth={{
                                            base: '100%',
                                            md: minimiseDesktopMenu
                                                ? 'calc(100% - 60px)'
                                                : 'calc(100% - 250px)',
                                        }}
                                        marginLeft={{
                                            base: '0px',
                                            md: minimiseDesktopMenu
                                                ? '60px'
                                                : '250px',
                                        }}
                                        mb={{
                                            base: '15px',
                                            md: '0px',
                                        }}
                                        transition="all ease 300ms !important"
                                        overflowX="hidden"
                                        overflowY="auto !important"
                                        top={
                                            isAdmin && showErrorToggle
                                                ? '110px'
                                                : '69px'
                                        }
                                        style={{
                                            transition: 'top ease-in-out 300ms',
                                        }}
                                        bg={{
                                            base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 100%)',
                                            md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 100%)',
                                        }}
                                        bottom="0"
                                        borderTop={{
                                            base: '1px solid #E2E8F0',
                                            md: '1px solid #E2E8F0',
                                        }}
                                        whiteSpace="pre-line"
                                        borderTopColor="#E2E8F0"
                                        flexDirection="column"
                                    >
                                        <Portal
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            {...briefingContentContainer}
                                        >
                                            <>
                                                {status !== 'error' &&
                                                !showNetworkErrors ? (
                                                    <Flex
                                                        w="100%"
                                                        style={{
                                                            transition:
                                                                'all ease 300ms',
                                                        }}
                                                        whiteSpace="pre-line"
                                                        borderTopColor="#E2E8F0"
                                                        px={{
                                                            base: '15px',
                                                            md: '20px',
                                                            lg: '25px',
                                                        }}
                                                        pt={{
                                                            base: mobileNavButtonsShow
                                                                ? '56px'
                                                                : '0px',
                                                            md: mobileNavButtonsShow
                                                                ? '50px'
                                                                : '0',
                                                            ml: '0px',
                                                        }}
                                                        pb="15px"
                                                        flexDirection="column"
                                                    >
                                                        <BriefingStandardContent
                                                            briefingData={
                                                                briefingData
                                                            }
                                                            dataLoad={
                                                                status ===
                                                                'loading'
                                                            }
                                                            dataLoadError={
                                                                status ===
                                                                'error'
                                                            }
                                                            briefingAreaSource={
                                                                briefingAreaSource
                                                            }
                                                            fullscreenRef={
                                                                fullscreenRef
                                                            }
                                                            fullscreenHandler={
                                                                fullscreenHandler
                                                            }
                                                            setFullscreenHandler={
                                                                setFullscreenHandler
                                                            }
                                                            isPremium={
                                                                isPremium
                                                            }
                                                            scale={scale}
                                                            setScale={setScale}
                                                            xy={xy}
                                                            setXy={setXy}
                                                            handleReset={
                                                                handleReset
                                                            }
                                                            setHandleReset={
                                                                setHandleReset
                                                            }
                                                            isRefetching={
                                                                isRefetching
                                                            }
                                                        />
                                                    </Flex>
                                                ) : (
                                                    <FullWidthError
                                                        message={`Error downloading Briefing content.<br />Please try again later, or visit <a href="https://ifis.airways.co.nz/">IFIS</a>.`}
                                                    />
                                                )}
                                            </>
                                        </Portal>

                                        {/* <OverlayScrollbarsComponent // TODO - DISABLED FOR NOW
                                            // ref={instanceRef}
                                            options={{
                                                className: 'os-theme-dark',
                                                scrollbars: {
                                                    visibility: 'auto',
                                                    autoHide: 'never',
                                                    autoHideDelay: 800,
                                                    dragScrolling: true,
                                                    clickScrolling: true,
                                                    touchSupport: true,
                                                },
                                                overflow: {
                                                    x: 'hidden',
                                                    y: 'scroll',
                                                },
                                                throttle: 0,
                                            }}
                                            style={{
                                                overflowX: 'hidden !important',
                                            }}
                                            defer
                                        > */}
                                        <Box ref={briefingContentDesktop}></Box>
                                        {/* </OverlayScrollbarsComponent> */}
                                        <PullToRefresh
                                            isPullable={
                                                isDesktop ? false : true
                                            }
                                            onRefresh={handleRefresh}
                                            resistance={3.5}
                                            pullDownThreshold={67}
                                            maxPullDownDistance={95}
                                            pullingContent={
                                                'Pull down to refetch Briefing information'
                                            }
                                            className="pulltorefresh"
                                        >
                                            <Box
                                                ref={briefingContentMobile}
                                            ></Box>
                                        </PullToRefresh>
                                    </Flex>
                                </Box>
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>

            {/* FULLSCREEN POP UP */}
            <Portal>
                <Flex
                    bg="none"
                    position="absolute"
                    width={'100vw'}
                    height={'100vh'}
                    minWidth={'100vw'}
                    minHeight={'100vh'}
                    zIndex={fullscreen ? 2002 : 1}
                    opacity={fullscreen ? '1' : '0'}
                    visibility={fullscreen ? 'visible' : 'hidden'}
                    top="0"
                    left="0"
                    py={{
                        base: '0px',
                        md: '15px',
                    }}
                    pointerEvents="none"
                    justifyContent="center"
                    alignItems={{
                        base: 'flex-end',
                        md: 'center',
                    }}
                >
                    <SlideFade
                        in={fullscreen}
                        offsetY={slideOffset}
                        width={'100%'}
                        height={'100%'}
                        minWidth={'100%'}
                        minHeight={'100%'}
                        alignItems="flex-end"
                    >
                        <Box
                            ref={fullscreenRef}
                            pointerEvents="initial"
                            bgColor={fullscreen ? 'white' : 'none'}
                            w={{
                                base: '100vw',
                                md: 'calc(100vw - 30px)',
                            }}
                            h={{
                                base: '100vw',
                                md: 'calc(100vw - 30px)',
                            }}
                            minWidth={{
                                base: '100vw',
                                md: 'calc(100vw - 30px)',
                            }}
                            minHeight={{
                                base: 'calc(100vh - 47px)',
                                md: 'calc(100vh - 30px)',
                            }}
                            maxHeight={{
                                base: 'calc(100vh - 47px)',
                                md: 'calc(100vh - 30px)',
                            }}
                            p={fullscreen ? '0px' : '0px'}
                            overflow="hidden"
                            borderRadius="20px"
                            borderBottomRadius={{
                                base: '0px',
                                md: '20px',
                            }}
                        ></Box>
                    </SlideFade>
                </Flex>
                <Flex // Close Button on Mobile
                    cursor="pointer"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    opacity="1"
                    zIndex={fullscreen ? 2002 : 1}
                    {...closeButtonPositionSmallScreen}
                    onClick={() => {
                        setHandleReset(true)
                    }}
                    {...noFocus}
                    width="48px"
                    height="48px"
                >
                    <Icon
                        as={CloseIcon}
                        width={{
                            base: '30px',
                            md: '26px',
                        }}
                        height={{
                            base: '30px',
                            md: '26px',
                        }}
                        color={{ base: 'white', md: '#444444' }}
                        boxShadow="none !important"
                    />
                </Flex>
                <Box // Shaded background
                    bgColor={fullscreen ? 'rgb(0, 0, 0, 0.6)' : 'none'}
                    top="0"
                    left="0"
                    position="absolute"
                    opacity={fullscreen ? '1' : '0'}
                    visibility={fullscreen ? 'visible' : 'hidden'}
                    transition="all ease-in 0ms"
                    w={fullscreen ? '100%' : 'auto'}
                    h={fullscreen ? '100%' : 'auto'}
                    zIndex={fullscreen ? 2001 : 1}
                    onClick={() => {
                        if (fullscreen) {
                            setHandleReset(true)
                        }
                    }}
                ></Box>
            </Portal>
            <Portal>
                <BriefingFavourites
                    briefingData={briefingData}
                    setSelectedAreas={setSelectedAreas}
                    remove={remove}
                    fetchBriefing={fetchBriefing}
                    isLoading={isLoading}
                    formattedCoords={formattedCoords}
                />
            </Portal>
        </>
    )
}
