import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloudTopsIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_702:31189)">
                {/* <path
                    opacity="0.2"
                    d="M3.72898 13.6297C3.48866 13.8699 3.00004 14.6655 3 15.4231C3 18.7537 5.67294 18.4975 6.93212 18.4943C8.85061 18.4895 11.8447 18.4943 11.8447 18.4943C12.1944 18.4362 14.2952 18.4974 14.7758 18.4974L18.3086 18.4975C19.1699 18.4975 19.8973 17.5716 20.2526 17.2165C20.4315 17.0376 20.8249 16.576 20.9816 15.6793C21.0696 15.1754 20.829 13.8939 20.5436 13.2134C20.2258 12.4556 19.6991 12.0106 19.0376 11.5801C18.3301 11.2176 16.6077 10.8435 15.8787 11.0677C17.3367 13.2134 18.25 16.75 13.4487 16.576L13.378 16.5734C8.63775 16.4016 7.65417 16.366 6.40192 15.6793C5.14346 14.9892 4.94395 14.3983 4.45796 12.8611C4.21764 13.1013 3.96931 13.3895 3.72898 13.6297Z"
                    fill="currentColor"
                /> */}
                <path
                    d="M4.96599 9.5C6.28731 7.11476 8.83005 5.5 11.7499 5.5C14.1687 5.5 16.3287 6.60807 17.7499 8.34428C18.4043 9.14365 19.6791 8.34428 18.947 7.43871C17.2512 5.34124 14.6572 4 11.7499 4C8.21956 4 5.15112 5.97777 3.59215 8.88578C3.03509 9.92498 4.38244 10.5532 4.96599 9.5Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 15.5C2 17.7091 3.79086 19.5 6 19.5H17.5C18.8051 19.5 19.9806 18.9445 20.8026 18.0568C21.5458 17.2542 22.0002 16.1802 22.0002 15C22.0002 12.5147 19.8182 10.5 17.5 10.5L17.489 10.5C17.4342 10.5001 17.3795 10.5013 17.3251 10.5033C16.2543 8.42855 14.0505 7 11.75 7C8.9028 7 6.50027 8.90385 5.746 11.5079C3.65521 11.6389 2 13.3762 2 15.5ZM17.5362 12.0002C17.5658 12.0006 17.5954 12.0015 17.625 12.0029C19.123 12.0728 20.5 13.3879 20.5 15C20.5 16.6569 19.1569 18 17.5 18H6C4.61929 18 3.5 16.8807 3.5 15.5C3.5 14.1193 4.61929 13 6 13H7.00647C7.13647 10.4929 9.21051 8.5 11.75 8.5C13.3735 8.5 14.9336 9.43612 15.7998 10.8323C15.1672 11.0907 14.6065 11.4889 14.1563 11.9886L15.264 13C15.798 12.4034 16.5672 12.0218 17.4259 12.0009C17.4505 12.0003 17.4752 12 17.5 12C17.5121 12 17.5241 12.0001 17.5362 12.0002Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
