import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import { Center, Spinner } from '@chakra-ui/react'

import { isMobileOnly, isIOS } from 'react-device-detect'

import { ReactComponent as PreflightIcon } from '../icons/assets/preflight-icon-shadow.svg'

export default function PubNubClientProvider(props) {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

    const fillColor = 'light.200'
    const emptyColor = 'rgba(255,255,255,0.2)'
    const emptyColorSecond = 'rgba(255,255,255,0.2)'
    const [res, setRes] = useState(
        <Center
            flexDirection="column"
            position="fixed"
            w="100%"
            h="100%"
            pb={{
                base: isMobileOnly && isIOS ? '85px' : '70px',
                lg: '20px',
            }}
        >
            <Spinner
                size="4xl"
                color={fillColor}
                style={{
                    borderBottomColor: emptyColorSecond,
                    borderLeftColor: emptyColor,
                }}
                emptyColor={emptyColor}
                thickness="1px"
                speed="0.8s"
                boxSize="110px"
            />
            <PreflightIcon
                style={{ marginTop: '-92px', zIndex: '1' }}
                width="80px"
                height="80px"
            />
        </Center>
    )

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect()
        }

        if (user) {
            const pubNubClient = new PubNub({
                publishKey: JSON.parse(
                    process.env.REACT_APP_DEV_ENV.toLowerCase()
                )
                    ? process.env.REACT_APP_DEV_PUBNUB_PUB_KEY
                    : process.env.REACT_APP_PUBNUB_PUB_KEY,
                subscribeKey: JSON.parse(
                    process.env.REACT_APP_DEV_ENV.toLowerCase()
                )
                    ? process.env.REACT_APP_DEV_PUBNUB_SUB_KEY
                    : process.env.REACT_APP_PUBNUB_SUB_KEY,
                uuid: user.sub,
            })

            setRes(
                <PubNubProvider client={pubNubClient}>
                    {props.children}
                </PubNubProvider>
            )
        }
    }, [user, isLoading, loginWithRedirect, isAuthenticated, props.children])

    return res
}
