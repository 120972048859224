import { code23Decode, code45Decode } from '../../../util/notamDecode'
import { Image } from '@chakra-ui/react'

import { timeRangeInAbrevWords } from '../../../util/dateFormatter'

import { ReactComponent as NotamIcon } from '../../../icons/assets/notam.svg'
import notam from '../../../icons/notam-icons/notam-header.svg'
import notamDrone from '../../../icons/notam-icons/notam-header-drone.svg'
import notamObstacle from '../../../icons/notam-icons/notam-header-obstacle.svg'
import notamAerodrome from '../../../icons/notam-icons/notam-header-aerodrome.svg'
import notamWarning from '../../../icons/notam-icons/notam-header-warning.svg'
import notamComms from '../../../icons/notam-icons/notam-header-comms.svg'

export default function notamToastDefinition(feat, now) {
    function notamIconFormatter() {
        return ['WU', 'WZ'].includes(feat.properties.code23)
            ? notamDrone
            : ['OB', 'OL'].includes(feat.properties.code23)
            ? notamObstacle
            : feat.properties.code23 === 'FA'
            ? notamAerodrome
            : ['W', 'R'].includes(feat.properties.code23.charAt(0))
            ? notamWarning
            : ['C', 'N'].includes(feat.properties.code23.charAt(0))
            ? notamComms
            : notam
    }

    return {
        id: feat?.properties?.id,
        className: 'notam-toast',
        icon: <Image src={notamIconFormatter()} width="24px" height="24px" />,
        watermark: (
            <NotamIcon
                width="55px"
                height="55px"
                className="watermark"
                style={{ padding: '4px' }}
            />
        ),
        colour: '#ff7f00',
        title: feat?.properties?.code23 && code23Decode(feat.properties.code23),
        description:
            feat?.properties?.code45 && code45Decode(feat.properties.code45),
        traffic: feat?.properties?.traffic,
        status:
            feat?.properties?.startvalidity &&
            feat?.properties?.endvalidity &&
            timeRangeInAbrevWords(
                now,
                feat.properties.startvalidity,
                feat.properties.endvalidity
            ),
        altitudes:
            feat?.properties?.lowerlabel && feat?.properties?.upperlabel
                ? `${feat.properties.lowerlabel}` +
                  ' - ' +
                  `${feat.properties.upperlabel}`
                : '',
    }
}
