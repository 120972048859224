import React from 'react'
import { Center, Text, Tooltip, Flex } from '@chakra-ui/react'
import FilterIcon from '../../../../../../icons/menu-icons/FilterIcon'
import FilterFillIcon from '../../../../../../icons/menu-icons/FilterFillIcon'

export default function FilterCount({ filtersShow, count }) {
    return (
        <Tooltip
            hasArrow
            px="10px"
            height="26px"
            fontSize="0.7rem"
            alignItems="center"
            display="flex"
            label={
                count
                    ? `${count} ${count > 1 ? 'filters' : 'filter'} applied`
                    : 'No filters applied'
            }
            placement="left"
            marginRight="5px"
            marginTop="-1px"
            borderRadius="7px"
            openDelay={300}
            bgColor={'gray.500'}
        >
            <Center pos={'relative'}>
                <Flex
                    color="light.100"
                    opacity="0.9"
                    alignItems="center"
                    justifyContent="center"
                    border={{
                        base: 'none',
                        md: 'none',
                    }}
                    // pt="1px"
                    borderRadius="10px"
                    variant="outline"
                    fontSize="1rem"
                    height="34px"
                    width="36px"
                    style={{
                        minWidth: '36px',
                    }}
                    transition="transform ease 50ms"
                    boxShadow={{
                        ml: filtersShow && '0px 3px 7px -2px rgba(0,0,0,0.25)',
                    }}
                    _hover={{
                        opacity: '1',
                        background: 'light.10',
                        color: 'light.100',
                        boxShadow: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                        transform: {
                            base: 'none',
                            md: 'scale(1.08) translateY(-1px)',
                        },
                    }}
                    _active={{
                        background: 'light.40',
                        boxShadow: '0px 3px 7px -2px rgba(0,0,0,0.3)',
                        color: 'light.100',
                    }}
                    _focus={{
                        color: 'light.100',
                    }}
                >
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        {filtersShow ? (
                            <FilterFillIcon boxSize="1.1rem" />
                        ) : (
                            <FilterIcon boxSize="1.1rem" />
                        )}
                        <Text
                            mt="-1px"
                            fontSize="7px"
                            letterSpacing="0.5px"
                            fontWeight="600"
                        >
                            FILTER
                        </Text>
                    </Flex>
                    <Center
                        opacity={count === 0 ? '0' : '1'}
                        visibility={count === 0 ? 'hidden' : 'visible'}
                        transition="all ease 300ms"
                        w={count > 9 ? '17px' : '14px'}
                        minWidth={count > 9 ? '17px' : '14px'}
                        h={'14px'}
                        marginTop={'-25px'}
                        marginLeft={count > 9 ? '-11px' : '-8px'}
                        marginRight={'-6px'}
                        bgColor={'light.105'}
                        p={'2px'}
                        borderRadius={'7px'}
                        zIndex="1"
                    >
                        <Text fontSize={'xx-small'} color={'white'}>
                            {count}
                        </Text>
                    </Center>
                </Flex>
            </Center>
        </Tooltip>
    )
}
