import React, { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import MfaHandler from '../map/functions/MfaHandler'
import ContrastHandler from '../map/functions/ContrastHandler'
import RouteHandler from '../map/actions/RouteHandler'
import SettingsMenu from '../map/settings-menu'
import Modals from '../map/modals'
import Sidebar from '../map/controls/sidebar'
import RotationWarning from '../device-warning/RotationWarning'
import { Flex } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import {
    dataModeState,
    isBriefingOnlyState,
    briefingOptionsState,
    selectedBriefingAreasState,
    briefingCustomAreaState,
} from '../globalState'

export default function BriefingOnly() {
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const setBriefingOptions = useSetRecoilState(briefingOptionsState)
    const selectedAreas = useRecoilValue(selectedBriefingAreasState)
    const briefingCustomArea = useRecoilValue(briefingCustomAreaState)

    useEffect(() => {
        if (dataMode !== 'brief') {
            setDataMode('brief')
            if (!selectedAreas?.length && !briefingCustomArea) {
                setBriefingOptions(true)
            }
        } else if (!selectedAreas?.length && !briefingCustomArea) {
            setBriefingOptions(true)
        }
    }, [
        dataMode,
        selectedAreas,
        briefingCustomArea,
        setDataMode,
        setBriefingOptions,
    ])

    const [accessToken, setAccessToken] = useState(null)
    const { getAccessTokenSilently } = useAuth0()
    useEffect(() => {
        const setToken = async () => {
            setAccessToken(await getAccessTokenSilently())
        }
        setToken()
    }, [getAccessTokenSilently])

    if (isBriefingOnly && accessToken) {
        return (
            <Flex
                width="100%"
                height="100%"
                background="radial-gradient(#ffffff 40%, #dbe0e6 100%) rgb(238, 240, 244) !important"
            >
                <Sidebar />
                <ContrastHandler />
                <RouteHandler />
                <MfaHandler />
                <SettingsMenu />
                <Modals />
                <RotationWarning />
            </Flex>
        )
    }

    return null
}
