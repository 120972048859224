import React, { useState, useEffect, useRef } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { Box } from '@chakra-ui/react'

export default function PinchDrag({
    children,
    scale,
    setScale,
    xy,
    setXy,
    gestureDisabled,
    maxZoom,
    maxZoomNewScale,
}) {
    useEffect(() => {
        const handler = (e) => e.preventDefault()
        document.addEventListener('gesturestart', handler)
        document.addEventListener('gesturechange', handler)
        document.addEventListener('gestureend', handler)
        return () => {
            document.removeEventListener('gesturestart', handler)
            document.removeEventListener('gesturechange', handler)
            document.removeEventListener('gestureend', handler)
        }
    }, [])

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: 1,
        rotateZ: 0,
    }))

    const ref = useRef(null)

    useGesture(
        {
            onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
                if (pinching || gestureDisabled) return cancel()
                setXy({ x, y })
            },
            onPinch: ({
                origin: [ox, oy],
                first,
                cancel,
                movement: [ms],
                offset: [s, a],
                memo,
            }) => {
                if (first) {
                    const { width, height, x, y } =
                        ref.current.getBoundingClientRect()
                    const tx = ox - (x + width / 2)
                    const ty = oy - (y + height / 2)
                    memo = [style.x.get(), style.y.get(), tx, ty]
                }

                // Leave here incase we want to use rotation in future.
                // const x = memo[0] - (ms - 1) * memo[2]
                // const y = memo[1] - (ms - 1) * memo[3]
                // api.start({ scale: s /*rotateZ: a, x, y*/ }) // disable rotation
                if (gestureDisabled) return cancel()
                setScale(s)
                return memo
            },
        },
        {
            target: ref,
            drag: { from: () => [style.x.get(), style.y.get()] },
            pinch: {
                scaleBounds: { min: 0.5, max: maxZoom },
                rubberband: true,
            },
        }
    )

    useEffect(() => {
        api.start({ scale: scale })
    }, [api, scale])

    useEffect(() => {
        api.start(xy)
    }, [api, xy])

    const onScroll = (e) => {
        if (scale > 0.5 && scale < maxZoom) {
            const delta = e.deltaY * -0.001
            let newScale = scale + delta
            if (newScale <= 0.5) {
                newScale = 0.501
            } else if (newScale >= maxZoom) {
                newScale = maxZoomNewScale
            }

            setScale(newScale)
        }
    }

    return (
        <>
            <Box
                pos={'relative'}
                w="100%"
                h="100%"
                onWheelCapture={gestureDisabled ? () => {} : onScroll}
            >
                <Box
                    style={{ touchAction: 'none' }}
                    pos={'absolute'}
                    top={0}
                    left={0}
                    w={gestureDisabled ? 0 : '100%'}
                    h={gestureDisabled ? 0 : '100%'}
                    zIndex={1000}
                    ref={ref}
                    cursor="grab"
                    _active={{
                        cursor: 'grabbing',
                    }}
                />
                <animated.div style={style}>{children}</animated.div>
            </Box>
        </>
    )
}

export function useScale() {
    const [scale, setScale] = useState(1)
    const [xy, setXy] = useState({ x: 0, y: 0 })

    return { scale, setScale, xy, setXy }
}
