import React from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { mapBoundsState } from '../../../globalState'

export default function MapBoundsLayer() {
    // Generates a box showing the max map bounds for debugging (ultimately available map view will be restricted to this area).
    const mapBounds = useRecoilValue(mapBoundsState)

    const geojson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [mapBounds[0][0], mapBounds[0][1]],
                            [mapBounds[1][0], mapBounds[0][1]],
                            [mapBounds[1][0], mapBounds[1][1]],
                            [mapBounds[0][0], mapBounds[1][1]],
                            [mapBounds[0][0], mapBounds[0][1]],
                        ],
                    ],
                },
            },
        ],
    }

    return (
        <GeoJSONLayer
            id="mapBounds"
            data={geojson}
            linePaint={{
                'line-color': '#ff0000',
                'line-width': 4,
                'line-dasharray': [6, 4],
                'line-opacity': 0.25,
            }}
        />
    )
}
