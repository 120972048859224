import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AdvsIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()} {...props}>
            <path
                d="M10.5998 6.49668C10.5458 5.6867 11.1882 5 12 5C12.8118 5 13.4542 5.6867 13.4002 6.49668L13.0665 11.5022C13.0291 12.0637 12.5627 12.5 12 12.5C11.4373 12.5 10.9709 12.0637 10.9335 11.5022L10.5998 6.49668Z"
                fill="currentColor"
            />
            <path
                d="M12 15.5C12.5523 15.5 13 15.0523 13 14.5C13 13.9477 12.5523 13.5 12 13.5C11.4477 13.5 11 13.9477 11 14.5C11 15.0523 11.4477 15.5 12 15.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 22L9.5 18H6C3.79086 18 2 16.2091 2 14V6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V14C22 16.2091 20.2091 18 18 18V22ZM9.83531 16.5L16.5 19.6363V16.5H18C19.3807 16.5 20.5 15.3807 20.5 14V6C20.5 4.61929 19.3807 3.5 18 3.5H6C4.61929 3.5 3.5 4.61929 3.5 6V14C3.5 15.3807 4.61929 16.5 6 16.5H9.83531Z"
                fill="currentColor"
            />
        </Icon>
    )
}
