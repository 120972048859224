import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloseIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 6}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M5.27842 5.27842C4.90719 5.64964 4.90719 6.25151 5.27842 6.62273L10.6557 12L5.27843 17.3773C4.90721 17.7485 4.90721 18.3504 5.27843 18.7216C5.64965 19.0928 6.25152 19.0928 6.62274 18.7216L12 13.3443L17.3772 18.7216C17.7484 19.0928 18.3503 19.0928 18.7215 18.7216C19.0928 18.3504 19.0928 17.7485 18.7215 17.3773L13.3443 12L18.7216 6.62273C19.0928 6.25151 19.0928 5.64964 18.7216 5.27842C18.3503 4.90719 17.7485 4.90719 17.3772 5.27842L12 10.6557L6.62273 5.27842C6.25151 4.90719 5.64964 4.90719 5.27842 5.27842Z"
                fill="currentColor"
            />
        </Icon>
    )
}
