import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useTier, useAdminRole } from '../../auth/hooks'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { isMobileOnly, isIOS } from 'react-device-detect'
import { parseISO } from 'date-fns'
import { useQuery } from 'react-query'
import * as turf from '@turf/turf'
import {
    Box,
    Text,
    ButtonGroup,
    Button,
    Flex,
    LightMode,
    useBreakpointValue,
    useDisclosure,
    Icon,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Badge,
} from '@chakra-ui/react'
import { ReactComponent as AdvsIcon } from '../../icons/assets/advs.svg'
import { TfiMore, TfiMoreAlt } from 'react-icons/tfi'
import NotamList from './NotamList'
import SigmetList from './SigmetList'
import SigwxList from './SigwxList'
import Controls from '../components/Controls'
import AltitudeDisplay from '../../map/controls/AltitudeDisplay'
import {
    dataModeState,
    fullscreenModalVisibleHandler,
    clickedFeaturesState,
    layerSelectionState,
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    selectedTimeState,
    statusData,
    simulateFreeUserState,
    mfaVerifiedState,
    wipMenuItemsState,
} from '../../globalState'

export default function AdvsList() {
    const dataMode = useRecoilValue(dataModeState)
    const { getAccessTokenSilently } = useAuth0()
    const clickedFeatures = useRecoilValue(clickedFeaturesState)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const dataStatus = useRecoilValue(statusData)
    const [selectedList, setSelectedList] = useState('notam')
    const layerSelection = useRecoilValue(layerSelectionState)
    const [buttonIndicatorPosition, setButtonIndicatorPosition] = useState(0)
    const prevSelectedListRef = useRef()
    const [dataLoading, setDataLoading] = useState(true)
    const [dataLoadingError, setDataLoadingError] = useState(false)
    const [formattedNotamData, setFormattedNotamData] = useState(null) // used so the fetch is not updating everysecond due to selectedTime
    // const [sigmetData, setSigmetData] = useState(null)
    // const [sigwxData, setSigwxData] = useState(null)
    const [formattedSigwxData, setFormattedSigwxData] = useState(null) // used so the fetch is not updating everysecond due to selectedTime
    const [showFilters, setShowFilters] = useState(false)
    const lowerAltitude = useRecoilValue(selectedLowerAltitudeState)
    const upperAltitude = useRecoilValue(selectedUpperAltitudeState)
    const selectedTime = useRecoilValue(selectedTimeState)
    const wipMenuItems = useRecoilValue(wipMenuItemsState)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const sigmetVersion = useRef()
    const userTier = useTier()
    const isAdmin = useAdminRole()
    const isHidden = !(wipMenuItems && isAdmin)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const [sigwxCount, setSigwxCount] = useState(0)
    const [sigmetCount, setSigmetCount] = useState(null)
    const [notamCount, setNotamCount] = useState(0)

    // const [supCount, setSupCount] = useState(0)
    // const [totalCount, setTotalCount] = useState(0)

    const [expanded, setExpanded] = useState(false)
    const [altitudeSliderExpanded, setAltitudeSliderExpanded] = useState(true)

    // FETCH NOTAM DATA
    const fetchNotams = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/notam`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }, [getAccessTokenSilently])

    const { data: notamData, status: notamStatus } = useQuery(
        'notam',
        fetchNotams,
        { refetchOnWindowFocus: false }
    )

    useEffect(() => {
        if (notamData) {
            const time = new Date(selectedTime)
            const filteredData =
                notamData?.features &&
                notamData.features
                    .filter(
                        (featureCollection) =>
                            featureCollection.properties?.startvalidity &&
                            parseISO(
                                featureCollection.properties.startvalidity
                            ) <= time &&
                            ((featureCollection.properties?.endvalidity &&
                                parseISO(
                                    featureCollection.properties.endvalidity
                                ) >= time) ||
                                featureCollection.properties?.permanent)
                    )
                    .map((feat) => {
                        const newFeat = {
                            ...feat,
                            properties: { ...feat.properties },
                        }
                        newFeat.properties.center =
                            turf.centerOfMass(feat).geometry.coordinates
                        newFeat.layer = 'notam'
                        return newFeat
                    })

            if (filteredData && !filteredData.length) {
                setFormattedNotamData({
                    id: 'default',
                    type: 'FeatureCollection',
                    features: [],
                })
            } else {
                setFormattedNotamData({
                    type: 'FeatureCollection',
                    features: filteredData,
                })
                setDataLoading(false)
            }
        }
    }, [notamData, setFormattedNotamData, selectedTime])

    useEffect(() => {
        if (
            formattedNotamData &&
            formattedNotamData.features &&
            formattedNotamData.id !== 'default'
        ) {
            setNotamCount(formattedNotamData.features.length)
        } else {
            setNotamCount(0)
        }
    }, [formattedNotamData, setNotamCount])

    // // FETCH SIGWX DATA
    const fetchSigwx = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/sigwx`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }, [getAccessTokenSilently])

    const {
        data: sigwxData,
        status: sigwxStatus,
        refetch: refetchSigwx,
    } = useQuery('sigwx', fetchSigwx, { refetchOnWindowFocus: false })

    useEffect(() => {
        if (
            selectedList === 'sigwx' &&
            dataStatus?.sigwx?.version !== formattedSigwxData?.version
        ) {
            refetchSigwx()
                .then(() => {
                    setDataLoading(false)
                    console.log('Updating SIGWX data')
                })
                .catch((error) => {
                    console.log(error)
                    setDataLoadingError(true)
                    setDataLoading(false)
                })
        }
    }, [
        selectedList,
        dataStatus,
        refetchSigwx,
        setDataLoadingError,
        setDataLoading,
        formattedSigwxData,
    ])

    useEffect(() => {
        if (sigwxData) {
            const time = new Date(selectedTime)
            const filteredData =
                sigwxData &&
                sigwxData[0] &&
                sigwxData[0].metadata &&
                sigwxData
                    .filter(
                        (featureCollection) =>
                            parseISO(
                                featureCollection.metadata.validFromTime
                            ) <= time &&
                            parseISO(featureCollection.metadata.validToTime) >=
                                time
                    )
                    .map((featureCollection) => ({
                        ...featureCollection,
                        features:
                            featureCollection.features &&
                            featureCollection.features.length
                                ? featureCollection.features.map((feat) => ({
                                      ...feat,
                                      properties: {
                                          ...feat.properties,
                                          center:
                                              feat.geometry &&
                                              feat.geometry.coordinates &&
                                              turf.centerOfMass(feat).geometry
                                                  .coordinates
                                                  ? JSON.stringify(
                                                        turf.centerOfMass(feat)
                                                            .geometry
                                                            .coordinates
                                                    )
                                                  : '',
                                          icing: feat.properties?.icing
                                              ? JSON.stringify(
                                                    feat.properties.icing
                                                )
                                              : '',
                                          turbulence: feat.properties
                                              ?.turbulence
                                              ? JSON.stringify(
                                                    feat.properties.turbulence
                                                )
                                              : '',
                                      },
                                      layer: 'sigwx',
                                  }))
                                : [],
                    }))

            if (
                filteredData &&
                !filteredData.length &&
                sigwxData.id !== 'default'
            ) {
                setFormattedSigwxData({
                    id: 'default',
                    type: 'FeatureCollection',
                    features: [],
                })
            } else if (
                filteredData &&
                filteredData.length &&
                sigwxData.id !== filteredData[0].id
            ) {
                setFormattedSigwxData(filteredData[0])
            }
        }
    }, [sigwxData, setFormattedSigwxData, selectedTime])

    // FETCH SIGMET DATA
    const fetchSigmets = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/sigmet`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }, [getAccessTokenSilently])

    const {
        data: sigmetData,
        status: sigmetStatus,
        refetch: refetchSigmet,
    } = useQuery('sigmet', fetchSigmets, { refetchOnWindowFocus: false })

    useEffect(() => {
        if (
            selectedList === 'sigmet' &&
            dataStatus?.sigmet?.version !== sigmetVersion.current
        ) {
            sigmetVersion.current = dataStatus?.sigmet?.version
            refetchSigmet()
                .then(() => {
                    setDataLoading(false)
                    console.log('Updating SIGMET data')
                })
                .catch((error) => {
                    console.log(error)
                    setDataLoadingError(true)
                    setDataLoading(false)
                })
        }
    }, [
        selectedList,
        dataStatus,
        setDataLoadingError,
        setDataLoading,
        refetchSigmet,
    ])

    // on dismount, reset sigWxData
    useEffect(() => {
        return setFormattedSigwxData({
            id: 'default',
            type: 'FeatureCollection',
            features: [],
        })
    }, [setFormattedSigwxData])

    useEffect(() => {
        if (
            formattedSigwxData &&
            formattedSigwxData.features &&
            formattedSigwxData.id !== 'default'
        ) {
            setSigwxCount(formattedSigwxData.features.length)
        } else {
            setSigwxCount(0)
        }
    }, [formattedSigwxData, setSigwxCount])

    useEffect(() => {
        if (dataMode === 'advs') {
            onOpen()
        } else {
            onClose()
        }
    }, [onOpen, onClose, dataMode, handleFullscreenModal])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    // calculate totals for each list when active
    const sigmetCountTotal = sigmetCount || dataStatus?.sigmet?.count
    // const sigmetTotal = layerSelection.advs.sigmet && Number(sigmetCountTotal)
    // const sigwxTotal = layerSelection.advs.sigwx && Number(sigwxCount)
    // const supTotal = layerSelection.advs.supplements && Number(supCount)
    // const notamTotal = layerSelection.advs.notam && Number(notamCount)

    const layerSelectedCount =
        selectedList === 'sigmet'
            ? sigmetCountTotal
            : // : selectedList === 'supplements'
            // ? supCount
            selectedList === 'notam'
            ? notamCount
            : sigwxCount

    const expandOnDefault =
        Number(layerSelectedCount) > 0 && window.innerWidth > 768
    useEffect(() => {
        if (expandOnDefault) {
            setExpanded(true)
        }
    }, [expandOnDefault, setExpanded])

    // select applicable array (MET or ADVS)
    const layerSelectionArray = (
        layerSelection.advs ? Object.entries(layerSelection.advs) : []
    ).map(([id, value]) => ({ id, value }))

    const advsButtons = layerSelectionArray
        .filter((item) => item.value === true && item.id !== 'rpas')
        .filter((item) => (isHidden ? item.id !== 'sigwx' : true))
        .sort((a, b) => {
            // const order = ['supplements', 'sigmet', 'sigwx', 'notam']
            const order = ['notam', 'sigmet', 'sigwx']
            return order.indexOf(a.id) - order.indexOf(b.id)
        })

    // Set initial selectedList only when listOpen changes from false to true
    useEffect(() => {
        const firstActiveItem = advsButtons.find((item) => item.value === true)
        if (prevSelectedListRef.current === false) {
            if (firstActiveItem) {
                setSelectedList(firstActiveItem.id)
            }
        }
        if (!prevSelectedListRef.current === 'notam') {
            if (firstActiveItem) {
                setSelectedList(firstActiveItem.id)
            }
        }
        // prevSelectedListRef.current
    }, [advsButtons, setSelectedList])

    // if the selectedList is no longer in the advsButtons list, update it to the first active list
    useEffect(() => {
        if (
            selectedList &&
            !advsButtons.find((item) => item.id === selectedList)
        ) {
            const firstActiveItem = advsButtons.find(
                (item) => item.value === true
            )
            if (firstActiveItem) {
                setSelectedList(firstActiveItem.id)
            }
        }
        prevSelectedListRef.current = selectedList
    }, [layerSelection, advsButtons, selectedList, setSelectedList])

    // update buttonIndicatorPosition based on the selected list
    useEffect(() => {
        const selectedListItem = advsButtons.find(
            (item) => item.id === selectedList
        )
        if (selectedListItem) {
            setButtonIndicatorPosition(advsButtons.indexOf(selectedListItem))
        }
    }, [advsButtons, selectedList, setButtonIndicatorPosition])

    // update total number for button badge // TODO - add with Sups
    // useEffect(() => {
    //     const updatedTotalCount =
    //         sigmetTotal + sigwxTotal + supTotal + notamTotal
    //     setTotalCount(updatedTotalCount)
    // }, [sigmetTotal, sigwxTotal, supTotal, notamTotal])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const scrollBarHide = useBreakpointValue({
        base: 'scroll',
        md: 'leave',
    })

    // calculate the width of the buttons to fit limited space available
    const widthCalcBase = `calc((100vw - 150px) / ${advsButtons.length})`

    // adjustable button width depending on screen size, drawer width and number of buttons
    const buttonWidth = useBreakpointValue({
        base: widthCalcBase,
        xs: '100px',
        sm: advsButtons.length > 3 ? '82px' : '100px',
        md:
            advsButtons.length > 3
                ? '92px'
                : advsButtons.length > 2
                ? '94px'
                : advsButtons.length > 1
                ? '100px'
                : 'auto',
    })

    // useEffect(() => {
    //     console.log('selectedList: ', selectedList)
    //     console.log('dataLoading: ', dataLoading)
    // }, [selectedList, dataLoading])

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            lg: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            lg: undefined,
                        }}
                    >
                        <ModalHeader
                            backgroundColor="light.10"
                            borderBottom="1px solid"
                            borderBottomColor="#E2E8F0"
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            backgroundBlendMode="overlay"
                            h={{
                                base: '70px',
                                md: '70px',
                            }}
                            minHeight="70px"
                            maxHeight="70px"
                            w="100%"
                            paddingInlineStart={4}
                            paddingInlineEnd={4}
                            flex="1"
                            boxShadow={{
                                base: 'rgba(0, 0, 0, 0.2) 0px 5px 30px -5px',
                                md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                            }}
                            zIndex="1000"
                            clipPath="inset(0px 0px -1500px)"
                        >
                            <Flex
                                h="100%"
                                w="100%"
                                direction={{
                                    base: 'column',
                                    md: 'row',
                                }}
                            >
                                <Flex
                                    // {...infoSwipe}
                                    w="100%"
                                    h="100%"
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Flex
                                        // {...infoSwipe}
                                        direction="row"
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-start',
                                        }}
                                        alignItems="center"
                                        width="100%"
                                        maxWidth={{
                                            base: '100%',
                                            ml: '260px',
                                        }}
                                        flexGrow="1"
                                    >
                                        <Icon
                                            boxSize={{
                                                base: '30px',
                                                md: '34px',
                                            }}
                                            color="light.100"
                                            as={AdvsIcon}
                                            ml={{
                                                base: '40px',
                                                md: '4px',
                                            }}
                                            transition="all ease 300ms"
                                        />
                                        <Box
                                            pl={{
                                                base: '5px',
                                                md: '10px',
                                            }}
                                        >
                                            <Text // HEADER TITLE
                                                textAlign={{
                                                    base: 'center',
                                                    md: 'left',
                                                }}
                                                alignItems={{
                                                    base: 'center',
                                                    md: 'flex-start',
                                                }}
                                                color="light.100"
                                                fontSize={{
                                                    base: '1rem',
                                                    md: '1.2rem',
                                                }}
                                                fontWeight="600"
                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                lineHeight="1.3"
                                                marginTop="-2px"
                                                ml={{
                                                    base: '2px',
                                                    md: '5px',
                                                }}
                                                pt="2px"
                                                whiteSpace="pre"
                                            >
                                                Advisories
                                            </Text>
                                        </Box>
                                    </Flex>

                                    <Flex
                                        gap="10px"
                                        minWidth={{
                                            base: '40px',
                                            ml: '500px',
                                            lg: '600px',
                                        }}
                                        transition="all ease 300ms"
                                        justifyContent="flex-end"
                                    >
                                        <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                            display={{
                                                base: 'inline-flex',
                                                lg: 'none',
                                            }}
                                            background="none"
                                            color="light.100"
                                            opacity={showFilters ? '1' : '0.8'}
                                            fontSize="10px"
                                            fontWeight="600"
                                            height="40px"
                                            width="40px"
                                            alignItems="center"
                                            justifyContent="center"
                                            paddingLeft="2px"
                                            paddingRight="2px"
                                            borderRadius="10px"
                                            onClick={() => {
                                                setShowFilters(
                                                    (prevState) => !prevState
                                                )
                                                if (!showFilters) {
                                                    setAltitudeSliderExpanded(
                                                        true
                                                    )
                                                }
                                            }}
                                            _focus={{
                                                opacity: '1 !important',
                                            }}
                                            _active={{
                                                background: 'none',
                                            }}
                                            _hover={{
                                                opacity: '1 !important',
                                            }}
                                        >
                                            <Flex
                                                // borderColor="#DFE6EE"
                                                alignItems="center"
                                                justifyContent="center"
                                                // border="1px solid #DFE6EE"
                                                borderRadius="10px"
                                                color="light.100"
                                                variant="outline"
                                                fontSize="1rem"
                                                height="30px"
                                                width="34px"
                                                style={{
                                                    minWidth: '34px',
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                    background: 'light.20',
                                                }}
                                                _active={{
                                                    background: {
                                                        base: 'none',
                                                        lg: 'light.20',
                                                    },
                                                    boxShadow: {
                                                        base: 'none',
                                                        lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                    },
                                                }}
                                            >
                                                <Icon
                                                    fontSize={{
                                                        base: '20px',
                                                        ml: '1.2rem',
                                                    }}
                                                    transition="all 200ms"
                                                    as={
                                                        showFilters
                                                            ? TfiMoreAlt
                                                            : TfiMore
                                                    }
                                                />
                                            </Flex>
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </ModalHeader>

                        <ModalBody
                            p={0}
                            height={{
                                base:
                                    isMobileOnly && isIOS
                                        ? 'calc(100% - 145px)'
                                        : 'calc(100% - 130px)',
                                lg: '100%',
                            }}
                            background="#edf1f7"
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            fontSize="12px"
                        >
                            {!isPremium && (
                                <>
                                    <Flex
                                        as="section"
                                        p="0px"
                                        overflow="hidden"
                                        flexDirection="column"
                                        height="100%"
                                        minHeight="100%"
                                        maxHeight={{
                                            base: 'calc(100% - 132px)',
                                            md: 'calc(100vh - 200px)',
                                        }}
                                    >
                                        <Flex
                                            flexDirection={{
                                                base: 'column',
                                                md: 'row',
                                            }}
                                            justifyContent="center"
                                            alignItems={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            width="100%"
                                            height="100%"
                                            bg="light.35"
                                        >
                                            <Flex
                                                flexDirection={{
                                                    base: 'column',
                                                    md: 'column',
                                                }}
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                width="100%"
                                                borderRight={{
                                                    base: 'none',
                                                    md: '1px solid #e5e7e9',
                                                }}
                                                maxWidth={{
                                                    base: '100%',
                                                    md: '380px',
                                                    ml: '450px',
                                                }}
                                                height="100%"
                                                minHeight={{
                                                    base: 'auto',
                                                    md: '100%',
                                                }}
                                                maxHeight={{
                                                    base: 'unset',
                                                    md: '100%',
                                                }}
                                                gap="0px"
                                                bg="white"
                                            >
                                                {/* <Flex
                                            height={{
                                                base: '62px',
                                                md: '60px',
                                            }}
                                            minHeight={{
                                                base: '62px',
                                                md: '60px',
                                            }}
                                            width="100%"
                                            padding={{
                                                base: '10px',
                                                md: '15px',
                                            }}
                                            borderBottom="1px solid #eaebec"
                                            justifyContent="center"
                                            flexDirection="column"
                                        >
                                            <FilterButton //Sort by North-South or Alphabetically
                                            // sortState={sortAawByArea}
                                            // toggleState={toggleAawState}
                                            // itemKey={'sorting'}
                                            // itemFeat={'name'}
                                            // iconA={SortNorthToSouthIcon}
                                            // iconB={SortAlphabeticalIcon}
                                            // tooltipA={`Sort AAW North to South`}
                                            // tooltipB={`Sort AAW Alphabetically`}
                                            />
                                        </Flex> */}
                                                <Flex
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width="100%"
                                                    height="100%"
                                                    minHeight={{
                                                        base:
                                                            advsButtons &&
                                                            advsButtons.length <
                                                                2
                                                                ? 'calc(100% - 54px)'
                                                                : 'calc(100% - 70px)',
                                                        md: 'calc(100% - 70px)',
                                                    }}
                                                    maxHeight={{
                                                        base:
                                                            advsButtons &&
                                                            advsButtons.length <
                                                                2
                                                                ? 'calc(100% - 54px)'
                                                                : 'calc(100% - 70px)',
                                                        md: 'calc(100% - 70px)',
                                                    }}
                                                    bg={{
                                                        base: 'light.35',
                                                        md:
                                                            dataLoading ===
                                                            'loading'
                                                                ? 'light.30'
                                                                : 'light.35',
                                                    }}
                                                    transition="all ease 300ms"
                                                    zIndex="1"
                                                >
                                                    <Flex
                                                        width="100%"
                                                        flexDirection="column"
                                                        overflowX="hidden"
                                                        overflowY="auto"
                                                        h="100%"
                                                        transition="all ease 300ms"
                                                        className="settingsScrollVertical"
                                                    >
                                                        <OverlayScrollbarsComponent
                                                            options={{
                                                                className:
                                                                    'os-theme-light',
                                                                scrollbars: {
                                                                    visibility:
                                                                        'auto',
                                                                    autoHide:
                                                                        scrollBarHide,
                                                                    autoHideDelay: 800,
                                                                    dragScrolling: true,
                                                                    clickScrolling: true,
                                                                    touchSupport: true,
                                                                },
                                                                overflow: {
                                                                    x: 'hidden',
                                                                    y: 'scroll',
                                                                },
                                                            }}
                                                            style={{
                                                                height: '100%',
                                                                minHeight:
                                                                    '100%',
                                                                overflowX:
                                                                    'hidden !important',
                                                            }}
                                                            defer
                                                        >
                                                            <Flex
                                                                width="100%"
                                                                minWidth="100%"
                                                                alignItems="center"
                                                                justifyContent="flex-start"
                                                                flexDirection="column"
                                                                top={{
                                                                    base: '65px',
                                                                    md: 'unset',
                                                                }}
                                                                transition="all ease 300ms"
                                                                pt={{
                                                                    base: '15px',
                                                                    md: '20px',
                                                                }}
                                                                pb="30px"
                                                                px="15px"
                                                                gap="1px"
                                                            >
                                                                {advsButtons.length ===
                                                                0 ? (
                                                                    <Text // No options selected
                                                                        pt="40px"
                                                                        px="5px"
                                                                        color="gray.500"
                                                                        zIndex="1"
                                                                        fontWeight="500"
                                                                        fontSize={{
                                                                            base: '0.8rem',
                                                                            xs: '0.85rem',
                                                                            md: '0.9rem',
                                                                        }}
                                                                        whiteSpace="pre-line"
                                                                        textAlign="center"
                                                                        lineHeight="1.2"
                                                                    >
                                                                        All
                                                                        options
                                                                        have
                                                                        been
                                                                        deselected
                                                                    </Text>
                                                                ) : (
                                                                    <>
                                                                        {layerSelection
                                                                            ?.advs
                                                                            ?.notam &&
                                                                            selectedList ===
                                                                                'notam' && (
                                                                                <NotamList
                                                                                    className="notambox"
                                                                                    data={
                                                                                        formattedNotamData
                                                                                    }
                                                                                    status={
                                                                                        notamStatus
                                                                                    }
                                                                                    expanded={
                                                                                        expanded
                                                                                    }
                                                                                    dataLoading={
                                                                                        dataLoading
                                                                                    }
                                                                                    setDataLoading={
                                                                                        setDataLoading
                                                                                    }
                                                                                    dataLoadingError={
                                                                                        dataLoadingError
                                                                                    }
                                                                                    setDataLoadingError={
                                                                                        setDataLoadingError
                                                                                    }
                                                                                    lowerAltitude={
                                                                                        lowerAltitude
                                                                                    }
                                                                                    upperAltitude={
                                                                                        upperAltitude
                                                                                    }
                                                                                    setNotamCount={
                                                                                        setNotamCount
                                                                                    }
                                                                                    selectedList={
                                                                                        selectedList
                                                                                    }
                                                                                />
                                                                            )}

                                                                        {layerSelection
                                                                            ?.advs
                                                                            ?.sigmet &&
                                                                            selectedList ===
                                                                                'sigmet' && (
                                                                                <SigmetList
                                                                                    className="sigmetbox"
                                                                                    data={
                                                                                        sigmetData
                                                                                    }
                                                                                    status={
                                                                                        sigmetStatus
                                                                                    }
                                                                                    setSigmetCount={
                                                                                        setSigmetCount
                                                                                    }
                                                                                    expanded={
                                                                                        expanded
                                                                                    }
                                                                                    dataLoading={
                                                                                        dataLoading
                                                                                    }
                                                                                    setDataLoading={
                                                                                        setDataLoading
                                                                                    }
                                                                                    dataLoadingError={
                                                                                        dataLoadingError
                                                                                    }
                                                                                    setDataLoadingError={
                                                                                        setDataLoadingError
                                                                                    }
                                                                                    lowerAltitude={
                                                                                        lowerAltitude
                                                                                    }
                                                                                    upperAltitude={
                                                                                        upperAltitude
                                                                                    }
                                                                                    selectedList={
                                                                                        selectedList
                                                                                    }
                                                                                />
                                                                            )}
                                                                        {layerSelection
                                                                            ?.advs
                                                                            ?.sigwx &&
                                                                            selectedList ===
                                                                                'sigwx' && (
                                                                                <>
                                                                                    <SigwxList
                                                                                        className="sigwxbox"
                                                                                        data={
                                                                                            formattedSigwxData
                                                                                        }
                                                                                        status={
                                                                                            sigwxStatus
                                                                                        }
                                                                                        expanded={
                                                                                            expanded
                                                                                        }
                                                                                        dataLoading={
                                                                                            dataLoading
                                                                                        }
                                                                                        setDataLoading={
                                                                                            setDataLoading
                                                                                        }
                                                                                        dataLoadingError={
                                                                                            dataLoadingError
                                                                                        }
                                                                                        setDataLoadingError={
                                                                                            setDataLoadingError
                                                                                        }
                                                                                        lowerAltitude={
                                                                                            lowerAltitude
                                                                                        }
                                                                                        upperAltitude={
                                                                                            upperAltitude
                                                                                        }
                                                                                        setSigwxCount={
                                                                                            setSigwxCount
                                                                                        }
                                                                                        selectedList={
                                                                                            selectedList
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                    </>
                                                                )}
                                                            </Flex>
                                                        </OverlayScrollbarsComponent>
                                                    </Flex>
                                                </Flex>

                                                <Flex
                                                    height={{
                                                        base:
                                                            advsButtons &&
                                                            advsButtons.length <
                                                                2
                                                                ? '54px'
                                                                : '70px',
                                                        md: '70px',
                                                    }}
                                                    minHeight={{
                                                        base:
                                                            advsButtons &&
                                                            advsButtons.length <
                                                                2
                                                                ? '54px'
                                                                : '70px',
                                                        md: '70px',
                                                    }}
                                                    width="100%"
                                                    padding={{
                                                        base: '10px',
                                                        md: '15px',
                                                    }}
                                                    justifyContent="center"
                                                    flexDirection="column"
                                                    bg={{
                                                        base: 'light.55',
                                                        md: 'white',
                                                    }}
                                                    backdrop-filter="blur(20px)"
                                                    clipPath="inset(-100px 0px 0px)"
                                                    borderTop="1px solid #eaebec"
                                                    boxShadow={{
                                                        base: 'rgba(0, 0, 0, 0.15) 0px -5px 30px -5px',
                                                        md: 'rgba(0, 0, 0, 0.05) 0px -5px 30px -5px',
                                                    }}
                                                    zIndex="2"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="flex-start"
                                                        px={{
                                                            base: '5px',
                                                            md: '0px',
                                                        }}
                                                        width="100%"
                                                    >
                                                        <Flex
                                                            // {...buttonSwipe}
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            transition="all ease 300ms"
                                                            zIndex="1"
                                                            width={{
                                                                base: '100%',
                                                                md: '100%',
                                                            }}
                                                        >
                                                            <ButtonGroup // SELECTION BUTTON
                                                                borderRadius="25px"
                                                                px="2px"
                                                                overflow="hidden"
                                                                color="#a8b2c0"
                                                                clipPath="inset(0px 0px 0px 0px)"
                                                                bg={
                                                                    advsButtons.length >=
                                                                        2 &&
                                                                    'gray.100'
                                                                }
                                                                borderColor="#E9EAEB"
                                                                transition="all ease 300ms"
                                                                boxShadow={
                                                                    advsButtons.length >=
                                                                        2 &&
                                                                    'inset 0px 0px 3px -1px rgba(10,20,30,0.1)'
                                                                }
                                                                alignItems="center"
                                                                _hover={{
                                                                    bg:
                                                                        advsButtons.length >=
                                                                            2 &&
                                                                        'gray.200',
                                                                }}
                                                            >
                                                                {advsButtons?.map(
                                                                    (
                                                                        layer,
                                                                        i
                                                                    ) => {
                                                                        const layerId =
                                                                            layer.id ===
                                                                                'supplements' &&
                                                                            advsButtons.length >
                                                                                1
                                                                                ? 'aip sup'
                                                                                : layer.id ===
                                                                                      'notam' &&
                                                                                  advsButtons.length <
                                                                                      2
                                                                                ? 'en-route notam'
                                                                                : layer.id
                                                                        const count =
                                                                            layer.id ===
                                                                            'sigwx'
                                                                                ? sigwxCount
                                                                                : // : layer.id ===
                                                                                //   'supplements'
                                                                                // ? supCount
                                                                                layer.id ===
                                                                                  'notam'
                                                                                ? notamCount
                                                                                : sigmetCountTotal ||
                                                                                  0
                                                                        const closedWidth =
                                                                            (layer.id ===
                                                                                'notam' ||
                                                                                layer.id ===
                                                                                    'supplements') &&
                                                                            advsButtons.length <
                                                                                2
                                                                                ? '200px'
                                                                                : layer.id ===
                                                                                  'sigwx'
                                                                                ? '70px'
                                                                                : layer.id ===
                                                                                  'supplements'
                                                                                ? '80px'
                                                                                : layer.id ===
                                                                                  'notam'
                                                                                ? '80px'
                                                                                : '75px'

                                                                        return (
                                                                            <Button // BUTTON
                                                                                key={`Button-${layer.id}`}
                                                                                bg="none"
                                                                                variant="outline"
                                                                                color="#a8b2c0"
                                                                                fontSize="0.9rem"
                                                                                fontWeight="500"
                                                                                height={{
                                                                                    base: '34px',
                                                                                    md: '32px',
                                                                                }}
                                                                                width={
                                                                                    buttonWidth
                                                                                }
                                                                                minWidth={{
                                                                                    base: widthCalcBase,
                                                                                    xs:
                                                                                        advsButtons.length >
                                                                                        2
                                                                                            ? widthCalcBase
                                                                                            : closedWidth,
                                                                                    sm: '75px',
                                                                                    md: '75px',
                                                                                }}
                                                                                maxWidth={
                                                                                    buttonWidth
                                                                                }
                                                                                transition="all ease 300ms"
                                                                                overflow="hidden"
                                                                                marginInlineStart="0px !important"
                                                                                border="none"
                                                                                justifyContent="center"
                                                                                paddingLeft={
                                                                                    advsButtons.length >=
                                                                                    2
                                                                                        ? '5px'
                                                                                        : '0px'
                                                                                }
                                                                                paddingRight={{
                                                                                    base:
                                                                                        advsButtons.length >=
                                                                                        2
                                                                                            ? '2px'
                                                                                            : advsButtons.length ===
                                                                                              1
                                                                                            ? '25px'
                                                                                            : '0px',
                                                                                    xs:
                                                                                        advsButtons.length >=
                                                                                        2
                                                                                            ? '2px'
                                                                                            : '0px',
                                                                                }}
                                                                                py="2px"
                                                                                {...noFocus}
                                                                                onClick={() => {
                                                                                    setSelectedList(
                                                                                        layer.id
                                                                                    )
                                                                                    setButtonIndicatorPosition(
                                                                                        i
                                                                                    )
                                                                                }}
                                                                                opacity={{
                                                                                    base:
                                                                                        selectedList ===
                                                                                        layer.id
                                                                                            ? '1'
                                                                                            : advsButtons.length >=
                                                                                              2
                                                                                            ? '0.7'
                                                                                            : '1',
                                                                                    md:
                                                                                        selectedList ===
                                                                                        layer.id
                                                                                            ? '1'
                                                                                            : advsButtons.length >=
                                                                                              2
                                                                                            ? '0.7'
                                                                                            : '1',
                                                                                }}
                                                                                zIndex="1"
                                                                                _active={{
                                                                                    bg: 'none',
                                                                                }}
                                                                                _focus={{
                                                                                    bg: 'none',
                                                                                }}
                                                                                _hover={{
                                                                                    bg: 'none',
                                                                                    opacity:
                                                                                        '1',
                                                                                }}
                                                                                gap={
                                                                                    advsButtons.length <
                                                                                    3
                                                                                        ? '2px'
                                                                                        : '0px'
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    zIndex="1"
                                                                                    paddingLeft="0px"
                                                                                    lineHeight="1.5"
                                                                                    textTransform="uppercase"
                                                                                    color={
                                                                                        advsButtons.length >=
                                                                                            2 &&
                                                                                        selectedList ===
                                                                                            layer.id
                                                                                            ? 'light.100'
                                                                                            : 'gray.600'
                                                                                    }
                                                                                    fontSize={{
                                                                                        base:
                                                                                            advsButtons.length >=
                                                                                            2
                                                                                                ? '0.8rem'
                                                                                                : '0.9rem',
                                                                                        md:
                                                                                            advsButtons.length >=
                                                                                            2
                                                                                                ? '0.8rem'
                                                                                                : '0.9rem',
                                                                                    }}
                                                                                    fontWeight={
                                                                                        advsButtons.length >=
                                                                                        2
                                                                                            ? '600'
                                                                                            : '500'
                                                                                    }
                                                                                    _active={{
                                                                                        color:
                                                                                            advsButtons.length >=
                                                                                                2 &&
                                                                                            selectedList ===
                                                                                                layer.id
                                                                                                ? 'light.100'
                                                                                                : 'gray.500',
                                                                                        fontWeight:
                                                                                            advsButtons.length >=
                                                                                                2 &&
                                                                                            selectedList ===
                                                                                                layer.id
                                                                                                ? '600'
                                                                                                : '400',
                                                                                        opacity:
                                                                                            selectedList ===
                                                                                            layer.id
                                                                                                ? '1'
                                                                                                : '0.8',
                                                                                    }}
                                                                                    _selected={{
                                                                                        color: 'gray.500',
                                                                                        fontWeight:
                                                                                            '600',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    letterSpacing={{
                                                                                        base:
                                                                                            (advsButtons.length >
                                                                                                3 &&
                                                                                                layer.id ===
                                                                                                    'notam') ||
                                                                                            layer.id ===
                                                                                                'supplements'
                                                                                                ? '-0.5px'
                                                                                                : '0px',
                                                                                        xs:
                                                                                            advsButtons.length >
                                                                                            3
                                                                                                ? '0px'
                                                                                                : layer.id ===
                                                                                                      'notam' ||
                                                                                                  layer.id ===
                                                                                                      'supplements'
                                                                                                ? '0px'
                                                                                                : '0.4px',
                                                                                        sm:
                                                                                            layer.id ===
                                                                                                'notam' ||
                                                                                            layer.id ===
                                                                                                'supplements'
                                                                                                ? '0px'
                                                                                                : '0.4px',
                                                                                    }}
                                                                                    transition="all ease 300ms"
                                                                                >
                                                                                    {
                                                                                        layerId
                                                                                    }
                                                                                </Text>
                                                                                <Badge
                                                                                    marginTop="1px"
                                                                                    ml="5px"
                                                                                    variant="solid"
                                                                                    bg={
                                                                                        selectedList ===
                                                                                        layer.id
                                                                                            ? '#ffeb7c'
                                                                                            : 'light.10'
                                                                                    }
                                                                                    color={
                                                                                        selectedList ===
                                                                                        layer.id
                                                                                            ? '#666666'
                                                                                            : 'light.100'
                                                                                    }
                                                                                    border="1px solid"
                                                                                    borderColor={
                                                                                        selectedList ===
                                                                                        layer.id
                                                                                            ? '#ffeb7c'
                                                                                            : 'gray.300'
                                                                                    }
                                                                                    transition="all ease 300ms"
                                                                                    borderRadius="10"
                                                                                    display="flex"
                                                                                    justifyContent="center"
                                                                                    alignItems="center"
                                                                                    pb="0px"
                                                                                    pt="0px"
                                                                                    fontSize="10px"
                                                                                    height="16px"
                                                                                    minWidth="16px"
                                                                                    lineHeight="1.6"
                                                                                >
                                                                                    {
                                                                                        count
                                                                                    }
                                                                                </Badge>
                                                                            </Button>
                                                                        )
                                                                    }
                                                                )}

                                                                <Flex // BUTTON INDICATOR
                                                                    position="absolute"
                                                                    opacity={
                                                                        advsButtons.length >=
                                                                        2
                                                                            ? '1'
                                                                            : '0'
                                                                    }
                                                                    visibility={
                                                                        advsButtons.length >=
                                                                        2
                                                                            ? 'visible'
                                                                            : 'hidden'
                                                                    }
                                                                    marginInlineStart="0px !important"
                                                                    height={{
                                                                        base: '30px',
                                                                        md: '28px',
                                                                    }}
                                                                    width={
                                                                        buttonWidth
                                                                    }
                                                                    bg="#ffffff"
                                                                    borderRadius="20px"
                                                                    borderColor="#E9EAEB"
                                                                    boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                    transform={`translateX(${buttonIndicatorPosition}00%)`}
                                                                    transition="transform ease 400ms, opacity ease 300ms"
                                                                ></Flex>
                                                            </ButtonGroup>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            <Flex
                                                px="5px"
                                                pt="50px"
                                                pb="100px"
                                                height="100%"
                                                width="100%"
                                                display={{
                                                    base: 'none',
                                                    md: 'flex',
                                                }}
                                                color="gray.400"
                                                justifyContent="center"
                                                alignItems="center"
                                                fontSize="0.9rem"
                                                opacity={
                                                    !(
                                                        clickedFeatures &&
                                                        clickedFeatures.length >
                                                            0
                                                    )
                                                        ? '1'
                                                        : '0'
                                                }
                                                transition="opacity ease 300ms"
                                            >
                                                {`Select ${
                                                    selectedList === 'notam'
                                                        ? 'a NOTAM'
                                                        : selectedList ===
                                                          'sigmet'
                                                        ? 'a SIGMET'
                                                        : selectedList ===
                                                          'sigwx'
                                                        ? 'a SIGWX'
                                                        : 'an item'
                                                }`}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        height="100%"
                                        width="100%"
                                        flexDirection="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        pt="20px"
                                        zIndex={{
                                            base: '100',
                                            md: '1',
                                        }}
                                        position={{
                                            base: 'absolute',
                                            md: 'unset',
                                        }}
                                        top="0px"
                                        left="0px"
                                        pointerEvents={{
                                            base: showFilters ? 'auto' : 'none',
                                            md: 'unset',
                                        }}
                                    >
                                        <AltitudeDisplay
                                            mapToolsExpanded={false}
                                            altitudeSliderExpanded={false}
                                            altitudeSliderVisible={true}
                                            showFilters={showFilters}
                                        />
                                        <Controls
                                            altitudeSliderExpanded={
                                                altitudeSliderExpanded
                                            }
                                            setAltitudeSliderExpanded={
                                                setAltitudeSliderExpanded
                                            }
                                            showFilters={showFilters}
                                            setShowFilters={setShowFilters}
                                        />
                                    </Flex>
                                </>
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
