import React, { useCallback, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Tooltip, Center, Flex } from '@chakra-ui/react'

import { isDesktop, isIOS } from 'react-device-detect'
import { useTier } from '../../../../auth/hooks'
import RulerIcon from '../../../../icons/menu-icons/RulerIcon'
import PremiumBadge from '../../PremiumBadge'
import {
    setModalVisibilityState,
    settingsMenuOpenState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../../globalState'

export default function RulerButton({ rulerMode, setRulerMode }) {
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)

    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const mfaExpired = userTier > 1 && !mfaVerified
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const [tooltipHover, setTooltipHover] = useState(false)

    return (
        <Tooltip
            label={rulerMode ? '0 NM total' : 'Ruler'}
            hasArrow={rulerMode ? false : true}
            borderRadius={rulerMode ? '0px' : '7px'}
            borderStartRadius={rulerMode ? '20px' : '7px'}
            height="26px"
            fontSize="0.7rem"
            alignItems="center"
            placement="left"
            m={rulerMode ? '0' : '5px'}
            display={isDesktop || rulerMode ? 'flex' : 'none'}
            isDisabled={isDesktop || rulerMode ? false : true}
            className="combined-distances"
            bg={rulerMode ? 'rgba(255,255,255,0.9)' : 'light.10'}
            color="light.100"
            fontWeight={rulerMode ? '600' : '500'}
            px={rulerMode ? '12px' : '10px'}
            isOpen={rulerMode ? rulerMode : tooltipHover}
        >
            <Center
                className="ruler"
                w={{ base: '32px', md: '30px' }}
                minWidth={{ base: '32px', md: '30px' }}
                h={{ base: '32px', md: '30px' }}
                // bgGradient={rulerMode && 'linear(to-br, #248AAA, #6CD2F2)'}
                // boxShadow={rulerMode && '0px 3px 15px -5px rgba(0, 0, 0, 0.5)'}
                backgroundColor={rulerMode && 'rgba(255,255,255,0.4)'}
                _hover={{
                    backgroundColor:
                        (isDesktop || !isIOS) && 'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                    opacity: '1',
                }}
                _focus={{
                    backgroundColor:
                        rulerMode &&
                        (isDesktop || !isIOS) &&
                        'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                // marginTop={{ base: '2', md: '10px !important' }}
                borderRadius="10px"
                onClick={() => {
                    if (mfaExpired) {
                        setShowResetMfaModal(true)
                        setSettingsMenuOpen(false)
                    } else if (isPremium) {
                        setShowPricingTiersModal(true)
                        setSettingsMenuOpen(false)
                    } else {
                        setRulerMode(!rulerMode)
                    }
                }}
                onMouseOver={() => setTooltipHover(true)}
                onMouseEnter={() => setTooltipHover(true)}
                onMouseLeave={() => setTooltipHover(false)}
            >
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                    opacity={isPremium ? '0.6' : '1'} // TODO - remove WIP for Prod version
                    _hover={{
                        opacity: '1',
                    }}
                >
                    <RulerIcon
                        color={!rulerMode && 'light.10'}
                        activeColor={
                            'light.10'
                            // '#FF00FF'
                        }
                        active={rulerMode}
                    />
                </Flex>

                {isPremium && ( // PREMIUM BADGE
                    <Flex
                        minWidth={{ base: '32px', md: '30px' }}
                        position="absolute"
                    >
                        <Flex position="absolute" right="0px" mt="-3px">
                            <PremiumBadge />
                        </Flex>
                    </Flex>
                )}
            </Center>
        </Tooltip>
    )
}
