import { config } from '../config'

export default function constructVectorLayerInfo() {
    const { layers } = config
    const vectorInfo = {}
    Object.keys(layers).forEach(id => {
        if (layers[id]['type'] === 'vector') {
            vectorInfo[id] = {
                dataURL: layers[id]['baseURL'],
                baseURL: layers[id]['baseURL'],
            }
        }
    })
    return vectorInfo
}
