import React from 'react'
import { useRecoilValue } from 'recoil'
import { globalTimeState, contrastState } from '../../globalState'
import { formatInTimeZone } from 'date-fns-tz'
import { Flex, Text } from '@chakra-ui/react'

export default function Clock() {
    const time = useRecoilValue(globalTimeState)
    const contrastHandler = useRecoilValue(contrastState)

    // Function to replace full timezone names with abbreviations
    const formattedTimeZone = (abbreviateTimeZone) => {
        return abbreviateTimeZone
            .replace('New Zealand Daylight Time', 'NZDT')
            .replace('New Zealand Standard Time', 'NZST')
    }

    const currentLocalTime = formattedTimeZone(
        formatInTimeZone(
            time,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'dd/MM/yy HH:mm:ss zzzz'
        )
    )

    const currentUTCTime = formatInTimeZone(
        time,
        'UTC',
        'dd/MM/yy HH:mm:ss zzz',
        {}
    )

    return (
        <Flex
            className="appclock"
            pos="absolute"
            left={{ base: 2, md: 65 }}
            bottom={{ base: '6px', md: 1 }}
            fontSize={{ base: '9px', md: '0.6rem' }}
            color={
                contrastHandler === 'high'
                    ? '#ffffff' // If High Contrast Layer
                    : contrastHandler === 'medium'
                    ? '#ffffff' // If Medium Contrast Layer
                    : '#444444' // All other layers
            }
            textShadow={
                contrastHandler === 'high'
                    ? '1px 1px 3px rgba(0,0,0,1)' // If High Contrast Layer
                    : contrastHandler === 'medium'
                    ? '1px 1px 3px rgba(0,0,0,0.8)' // If Medium Contrast Layer
                    : '1px 1px 2px rgba(255,255,255,0.5)' // All other layers
            }
        >
            {currentUTCTime} <Text px={2}>|</Text>
            {currentLocalTime}
        </Flex>
    )
}
