import React from 'react'

export default function TempDialImage(props) {
    const { data, imageSize, imageColor, focusImageColor, type } = props

    const value = data === 0 ? 0.001 : data // overrides '0' value being dismissed
    const valueTrendRotate = !value
        ? -120
        : value === 16
        ? 0
        : value <= 0 // Max low = 0 degrees or lower
        ? -120
        : value >= 32 // Max high = 32 degrees or higher
        ? 120
        : (value < 16 ? value - 16 : value > 16 ? value - 16 : 0) * 7.5

    // console.log(value, 'value')
    // console.log(valueTrendRotate, 'valueTrendRotate')

    return (
        <>
            <svg
                width={imageSize}
                height={imageSize}
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_3281_33224)">
                    <g // Ticks
                        opacity="0.4"
                    >
                        <rect
                            x="59.3477"
                            y="17.3242"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            fill={imageColor}
                        />
                        <rect
                            x="102.68"
                            y="59.3477"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(90 102.68 59.3477)"
                            fill={imageColor}
                        />
                        <rect
                            x="17.3242"
                            y="60.6543"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-90 17.3242 60.6543)"
                            fill={imageColor}
                        />
                        <rect
                            x="51.9473"
                            y="18.0859"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-10 51.9473 18.0859)"
                            fill={imageColor}
                        />
                        <rect
                            x="101.918"
                            y="51.9473"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(80 101.918 51.9473)"
                            fill={imageColor}
                        />
                        <rect
                            x="18.0859"
                            y="68.0547"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-100 18.0859 68.0547)"
                            fill={imageColor}
                        />
                        <rect
                            x="44.791"
                            y="20.1211"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-20 44.791 20.1211)"
                            fill={imageColor}
                        />
                        <rect
                            x="99.8828"
                            y="44.791"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(70 99.8828 44.791)"
                            fill={imageColor}
                        />
                        <rect
                            x="20.123"
                            y="75.2109"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-110 20.123 75.2109)"
                            fill={imageColor}
                        />
                        <rect
                            x="38.0977"
                            y="23.3672"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-30 38.0977 23.3672)"
                            fill={imageColor}
                        />
                        <rect
                            x="96.6348"
                            y="38.0977"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(60 96.6348 38.0977)"
                            fill={imageColor}
                        />
                        <rect
                            x="23.3691"
                            y="81.9043"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-120 23.3691 81.9043)"
                            fill={imageColor}
                        />
                        <rect
                            x="32.0703"
                            y="27.7285"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-40 32.0703 27.7285)"
                            fill={imageColor}
                        />
                        <rect
                            x="92.2734"
                            y="32.0684"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(50 92.2734 32.0684)"
                            fill={imageColor}
                        />
                        <rect
                            x="26.8906"
                            y="33.0684"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-50 26.8906 33.0684)"
                            fill={imageColor}
                        />
                        <rect
                            x="86.9336"
                            y="26.8887"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(40 86.9336 26.8887)"
                            fill={imageColor}
                        />
                        <rect
                            x="22.7168"
                            y="39.2285"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-60 22.7168 39.2285)"
                            fill={imageColor}
                        />
                        <rect
                            x="97.2891"
                            y="80.7754"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(120 97.2891 80.7754)"
                            fill={imageColor}
                        />
                        <rect
                            x="80.7754"
                            y="22.7148"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(30 80.7754 22.7148)"
                            fill={imageColor}
                        />
                        <rect
                            x="19.6758"
                            y="46.0176"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-70 19.6758 46.0176)"
                            fill={imageColor}
                        />
                        <rect
                            x="100.33"
                            y="73.9824"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(110 100.33 73.9824)"
                            fill={imageColor}
                        />
                        <rect
                            x="73.9844"
                            y="19.6738"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(20 73.9844 19.6738)"
                            fill={imageColor}
                        />
                        <rect
                            x="17.8594"
                            y="53.2344"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(-80 17.8594 53.2344)"
                            fill={imageColor}
                        />
                        <rect
                            x="102.145"
                            y="66.7676"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(100 102.145 66.7676)"
                            fill={imageColor}
                        />
                        <rect
                            x="66.7695"
                            y="17.8574"
                            width="1.30644"
                            height="4.66019"
                            rx="0.653219"
                            transform="rotate(10 66.7695 17.8574)"
                            fill={imageColor}
                        />
                    </g>
                    <g
                        opacity="0.5" // Bar
                    >
                        <path
                            d="M116.504 60.0009C116.504 28.7942 91.2058 3.49609 59.999 3.49609C28.7922 3.49609 3.49414 28.7942 3.49414 60.0009C3.49414 68.8807 5.55548 77.282 9.20534 84.7582C9.96163 86.3102 11.5545 87.3796 13.3971 87.3796C15.9708 87.3796 18.0572 85.2931 18.0572 82.7194C18.0572 81.9328 17.8624 81.1918 17.5183 80.5419C14.5109 74.3322 12.8145 67.3635 12.8145 60.0009C12.8145 33.9417 33.9397 12.8165 59.999 12.8165C86.0583 12.8165 107.183 33.9417 107.183 60.0009C107.183 67.3361 105.504 74.2803 102.517 80.4725C102.15 81.1388 101.941 81.9047 101.941 82.7194C101.941 85.2931 104.027 87.3796 106.601 87.3796C108.475 87.3796 110.091 86.273 110.831 84.6774C114.456 77.2215 116.504 68.8487 116.504 60.0009Z"
                            fill={imageColor}
                            fillOpacity="0.35"
                        />
                    </g>
                    <g // Pointer and Indicator
                        id="ect-ring"
                        transform={`rotate(${valueTrendRotate} 60 60)`}
                        style={{
                            opacity: '1',
                            transition: 'transform 400ms',
                            transitionDuration: '400ms',
                            transitionTimingFunction:
                                'cubic-bezier(0, 0, 0.2, 1)',
                        }}
                    >
                        {value && ( // if no value then hide pointer
                            <path
                                d="M56.002 53C59.002 52 61.002 52 64.002 53C63.9836 52.9878 64.9427 52.4757 64.9243 52.4636C64.1523 48.0492 63.1837 42.7234 62.4006 38.4604C62.0098 36.334 61.2595 32.2914 60.9858 30.8163C60.8972 30.3421 60.4827 29.9988 60.0003 30C59.518 30.0012 59.1052 30.3465 59.0189 30.8211L55.0843 52.4616C55.0648 52.4743 56.0213 52.9871 56.002 53Z"
                                fill={imageColor}
                            />
                        )}
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M60.002 67C63.8679 67 67.002 63.866 67.002 60C67.002 56.134 63.8679 53 60.002 53C56.136 53 53.002 56.134 53.002 60C53.002 63.866 56.136 67 60.002 67ZM60.002 69C64.9725 69 69.002 64.9706 69.002 60C69.002 55.0294 64.9725 51 60.002 51C55.0314 51 51.002 55.0294 51.002 60C51.002 64.9706 55.0314 69 60.002 69Z"
                            fill={imageColor}
                        />
                        {/* <path // decreasing (not currently used)
                            d="M96.3509 16.7394C86.5253 8.47486 73.8441 3.49609 60 3.49609V12.8165C71.5606 12.8165 82.1507 16.9738 90.3561 23.8765L96.3509 16.7394Z"
                            fill="url(#paint0_linear_3281_33224)"
                            fillOpacity="0.6"
                        />
                        <path // increasing (not currently used)
                            d="M23.6491 16.7394C33.4747 8.47486 46.1559 3.49609 60 3.49609V12.8165C48.4394 12.8165 37.8493 16.9738 29.6439 23.8765L23.6491 16.7394Z"
                            fill="url(#paint1_linear_3281_33224)"
                            fillOpacity="0.6"
                        /> */}
                        {value && ( // if no value then hide coloured indicator
                            <rect
                                x="58"
                                width="4.5"
                                height="16.3107"
                                rx="2"
                                fill={focusImageColor}
                            />
                        )}
                    </g>
                    <g
                        opacity="0.9" // LOW / HIGH LABELS
                        // transform="scale(0.9)"
                    >
                        <text
                            x="16"
                            y="106"
                            fill={imageColor}
                            style={{
                                textShadow: '0px 0px 6px rgba(0,0,0,0.06)',
                                transition: 'transform 400ms',
                                transitionDuration: '400ms',
                                transitionTimingFunction:
                                    'cubic-bezier(0, 0, 0.2, 1)',
                            }}
                            fontSize="12"
                            fontWeight="600"
                        >
                            LOW
                        </text>
                        <text
                            x="74"
                            y="106"
                            fill={imageColor}
                            style={{
                                textShadow: '0px 0px 6px rgba(0,0,0,0.06)',
                                transition: 'transform 400ms',
                                transitionDuration: '400ms',
                                transitionTimingFunction:
                                    'cubic-bezier(0, 0, 0.2, 1)',
                            }}
                            fontSize="12"
                            fontWeight="600"
                        >
                            HIGH
                        </text>

                        <text
                            x={type === 'TEMP' ? '47' : '36'}
                            y="86"
                            fill={imageColor}
                            style={{
                                textShadow: '0px 0px 6px rgba(0,0,0,0.06)',
                                transition: 'transform 400ms',
                                transitionDuration: '400ms',
                                transitionTimingFunction:
                                    'cubic-bezier(0, 0, 0.2, 1)',
                            }}
                            fontSize="9"
                            letterSpacing="0.5px"
                            fontWeight="300"
                            opacity="0.6"
                        >
                            {type}
                        </text>
                    </g>
                </g>
                <defs>
                    {value === 'decresing' && ( // DECREASING
                        <linearGradient
                            id="paint0_linear_3281_33224"
                            x1="66"
                            y1="7.49805"
                            x2="94.5"
                            y2="17.998"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white" stopOpacity="0.8" />
                            <stop
                                offset="1"
                                stopColor="white"
                                stopOpacity="0"
                            />
                        </linearGradient>
                    )}
                    {value === 'increasing' && ( // INCREASING
                        <linearGradient
                            id="paint1_linear_3281_33224"
                            x1="54"
                            y1="7.49805"
                            x2="25.5"
                            y2="17.998"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white" stopOpacity="0.8" />
                            <stop
                                offset="1"
                                stopColor="white"
                                stopOpacity="0"
                            />
                        </linearGradient>
                    )}
                    <clipPath id="clip0_3085_33444">
                        <rect width="40" height="40" fill={imageColor} />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
