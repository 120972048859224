import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { Flex, Image, Text, Button, Spinner } from '@chakra-ui/react'
import { useUserMetaData } from '../../../auth/hooks'
import {
    modalVisibilityState,
    setModalVisibilityState,
    userProfileState,
    mfaHandlerState,
    mfaVerifiedState,
} from '../../../globalState'

import Verify from './Verify'
import Options from './Options'
import { MfaPreviouslyVerified } from '../../functions/MfaHandler'

export default function MfaContent(props) {
    const {
        step,
        setStep,
        mobileStatus,
        setMobileStatus,
        expandedDropdowns,
        setExpandedDropdowns,
        verificationStatus,
        setVerificationStatus,
    } = props
    const { getAccessTokenSilently } = useAuth0()
    const userMetaData = useUserMetaData()
    const profileData = useRecoilValue(userProfileState)
    const [totpFactor, setTotpFactor] = useState(null)
    const [verificationMethod, setVerificationMethod] = useState(null)
    const [mobileNumber, setMobileNumber] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [delay, setDelay] = useState(false)
    const [binding, setBinding] = useState(null)
    const mfaPreviouslyVerified = MfaPreviouslyVerified()
    const mfaState = useRecoilValue(mfaHandlerState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)

    const loadingTimer = useRef(null)
    const delayTimer = useRef(null)
    const accordionCollapsed =
        expandedDropdowns.length === 0 || expandedDropdowns < 0
    const smsSelected = expandedDropdowns === 0
    const authenticatorSelected = expandedDropdowns === 1
    const emailSelected = expandedDropdowns === 2

    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowWhyMFAModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'whyMfa', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        // // if method is sms and user already has mobile, set mobile instead of input field
        if (smsSelected && userMetaData.mobile_number) {
            setMobileNumber(userMetaData.mobile_number)
        }
    }, [userMetaData, smsSelected])

    const sendTokenRequest = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()

        fetch(
            `${window.location.origin}/mfa/verify${
                verificationMethod === 'email' ? 'Email' : ''
            }${verificationMethod === 'sms' ? 'SMS' : ''}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            }
        ).then((res) => {
            if (res.status === 200) {
                console.log(
                    `Sucessfully Sent ${
                        verificationMethod === 'email' ? 'Email' : ''
                    }${verificationMethod === 'sms' ? 'SMS' : ''}`
                )
            } else {
                console.log(
                    `Error Sending ${
                        verificationMethod === 'email' ? 'Email' : ''
                    }${verificationMethod === 'sms' ? 'SMS' : ''}`
                )
            }
        })
    }, [getAccessTokenSilently, verificationMethod])

    // on load of the verify step, send an empty request to the endpoint to trigger twilio request for code
    useEffect(() => {
        if (step === 2 && ['sms', 'email'].includes(verificationMethod)) {
            sendTokenRequest()
        }
    }, [step, sendTokenRequest, verificationMethod])

    const mobileCallback = async () => {
        // console.log('Checking number is valid')
        // console.log('mobileNumber', mobileNumber)
        const phonePattern = /^\+640?2\d{7,10}$/

        if (phonePattern.test(`+64${mobileNumber}`)) {
            console.log('Processing SMS')
            setLoading(true)
            const accessToken = await getAccessTokenSilently()

            fetch(`${window.location.origin}/user/updatephone`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ mobileNumber: '+64' + mobileNumber }),
            }).then((res) => {
                console.log('SMS fetched')
                if (res.status === 200) {
                    console.log('SMS sent')
                    loadingTimer.current = setTimeout(() => {
                        setLoading(false)
                        setDelay(true)
                        // clearTimeout(loadingTimer.current)
                    }, 500)
                    delayTimer.current = setTimeout(() => {
                        setVerificationMethod('sms')
                        setStep(2)
                        setDelay(false)
                        // clearTimeout(delayTimer.current)
                    }, 1500)
                } else {
                    setLoading(false)
                }
            })
        } else {
            setMobileStatus('Validation Error')
        }
    }

    const verificationCallback = async () => {
        if (verificationCode && verificationCode.length !== 6) {
            setVerificationStatus('Incorrect Code Length')
        } else if (verificationCode) {
            setVerificationStatus('Loading')
            const accessToken = await getAccessTokenSilently()
            fetch(
                `${window.location.origin}/mfa/verify${
                    verificationMethod === 'email' ? 'Email' : ''
                }${verificationMethod === 'sms' ? 'SMS' : ''}${
                    verificationMethod === 'totp' ? 'TOTP' : ''
                }`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        verificationCode: verificationCode,
                        totpFactor: totpFactor,
                    }),
                }
            ).then((res) => {
                if (res.status === 401) {
                    setVerificationStatus('Incorrect Code')
                }
                if (res.status === 500) {
                    setVerificationStatus('Server Error')
                }
                if (res.status === 200) {
                    setVerificationStatus('Successful Verification')
                }
            })
        } else {
            setVerificationStatus('Empty Code')
        }
    }

    // console.log(totpFactor, 'totpFactor')
    // console.log(userMetaData, 'userMetaData')
    // console.log(binding, 'binding')

    if (userMetaData) {
        return (
            <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                clipPath="inset(0px 0px 0px 0px)"
                fontFamily={
                    modalVisibility.resetMfa &&
                    "'Poppins', 'Open Sans', sans-serif"
                }
                my={modalVisibility.resetMfa ? 'unset' : 'auto'}
                pt={modalVisibility.resetMfa ? 'unset' : '40px'}
                pb={modalVisibility.resetMfa ? 'unset' : '20px'}
                position={modalVisibility.resetMfa ? 'relative' : 'absolute'}
            >
                <Flex
                    width={
                        modalVisibility.resetMfa
                            ? '100%'
                            : {
                                  base: 'calc(100% - 20px)',
                                  xs: 'calc(100% - 30px)',
                                  sm: '400px',
                              }
                    }
                    maxWidth={modalVisibility.resetMfa ? '100%' : '400px'}
                    height={{ base: '100%', sm: 'auto' }}
                    minHeight={{ base: '450px', sm: '480px' }}
                    maxHeight={{ base: 'auto', sm: 'auto' }}
                    background="#ffffff"
                    borderRadius="21px !important"
                    overflow="hidden"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    alignItems="center"
                    boxShadow={
                        modalVisibility.resetMfa
                            ? 'none'
                            : '0 12px 40px -10px rgb(0 0 0 / 35%) !important'
                    }
                >
                    <Flex
                        zIndex="3"
                        p="6px"
                        borderRadius={{
                            base: '0',
                            sm: '20px 20px 0 0',
                        }}
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        minHeight="150px"
                        borderBottom={'1px solid #E5E9F0'}
                        bg="radial-gradient(#fff 30%, #f5f8f9 100%) !important"
                    >
                        <Image
                            boxSize="100px"
                            src={`${window.location.origin}/favicon.png`}
                            aling="center"
                        />

                        <Text
                            lineHeight="30px"
                            marginTop="-8px"
                            marginBottom="10px"
                            fontSize="22px"
                            padding="0 10px 5px"
                            fontFamily="'Open Sans',sans-serif"
                        >
                            Secure your account
                        </Text>
                    </Flex>

                    {step === 1 && (
                        <Options
                            profileData={profileData}
                            userMetaData={userMetaData}
                            mobileNumber={mobileNumber}
                            setMobileNumber={setMobileNumber}
                            mobileStatus={mobileStatus}
                            mobileCallback={mobileCallback}
                            expandedDropdowns={expandedDropdowns}
                            setExpandedDropdowns={setExpandedDropdowns}
                            accordionCollapsed={accordionCollapsed}
                            binding={binding}
                            setBinding={setBinding}
                            verificationCode={verificationCode}
                            setVerificationCode={setVerificationCode}
                            verificationStatus={verificationStatus}
                            verificationCallback={verificationCallback}
                            totpFactor={totpFactor}
                            setTotpFactor={setTotpFactor}
                            authenticatorSelected={authenticatorSelected}
                            setShowWhyMFAModal={setShowWhyMFAModal}
                            mfaVerified={mfaVerified}
                            mfaTimeToExpire={mfaState.state}
                            mfaPreviouslyVerified={mfaPreviouslyVerified}
                        />
                    )}
                    {step === 2 && (
                        <Verify
                            verificationMethod={verificationMethod}
                            mobileNumber={mobileNumber}
                            verificationCode={verificationCode}
                            setVerificationCode={setVerificationCode}
                            verificationStatus={verificationStatus}
                            verificationCallback={verificationCallback}
                            mobileCallback={mobileCallback}
                        />
                    )}
                    <Flex
                        height={accordionCollapsed ? '40px' : '70px'}
                        minHeight={accordionCollapsed ? '40px' : '70px'}
                        transition="all ease 300ms"
                        background="light.45"
                        borderRadius={{
                            base: '0',
                            sm: '0 0 20px 20px !important',
                        }}
                        overflow="hidden"
                        width="100%"
                        minWidth="100%"
                        borderTop={
                            accordionCollapsed ? '1px solid' : '0px solid'
                        }
                        borderColor="gray.200"
                    >
                        <>
                            <Button
                                opacity={accordionCollapsed ? '0' : '1'}
                                visibility={
                                    accordionCollapsed ? 'hidden' : 'visible'
                                }
                                transition="all ease 300ms"
                                height="70px"
                                minHeight="70px"
                                width="100%"
                                minWidth="100%"
                                fontWeight="600"
                                fontFamily="'Avenir Next', Avenir, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif"
                                letterSpacing="1px"
                                background="rgb(6, 208, 150)"
                                _hover={{
                                    background: 'rgb(5, 192, 138) !important',
                                }}
                                color="#ffffff"
                                borderRadius="0 0 20px 20px !important"
                                onClick={() => {
                                    // if method is SMS and user already has mobile, send them to Verify (step 2) - masked mobile number showing
                                    if (
                                        step === 1 &&
                                        smsSelected &&
                                        userMetaData.mobile_number
                                    ) {
                                        setVerificationMethod('sms')
                                        setMobileNumber(
                                            userMetaData.mobile_number
                                        )
                                        setStep(2)
                                    }
                                    // if method is SMS and user does not have mobile saved to profile - mobile number input showing
                                    if (
                                        step === 1 &&
                                        smsSelected &&
                                        !userMetaData.mobile_number
                                    ) {
                                        mobileCallback()
                                    }

                                    // if method is AUTHENTICATOR, send them to Verify (step 2)
                                    if (step === 1 && authenticatorSelected) {
                                        setVerificationMethod('totp')
                                        setStep(2)
                                    }

                                    // if method is EMAIL, send them to Verify (step 2)
                                    if (step === 1 && emailSelected) {
                                        setLoading(true)
                                        loadingTimer.current = setTimeout(
                                            () => {
                                                setLoading(false)
                                                setDelay(true)
                                                clearTimeout(
                                                    loadingTimer.current
                                                )
                                            },
                                            500
                                        )
                                        delayTimer.current = setTimeout(() => {
                                            setVerificationMethod('email')
                                            setStep(2)
                                            setDelay(false)
                                            clearTimeout(delayTimer.current)
                                        }, 1500)
                                    }

                                    // if Verify (step 2)
                                    if (step === 2) {
                                        verificationCallback()
                                    }
                                }}
                                isLoading={verificationStatus === 'Loading'}
                                spinner={
                                    <Spinner
                                        thickness="2px"
                                        speed="0.45s"
                                        boxSize="30px"
                                        color="white"
                                    />
                                }
                                isDisabled={
                                    verificationStatus ===
                                    'Successful Verification'
                                }
                            >
                                {verificationStatus ===
                                'Successful Verification' ? (
                                    'SUCCESS'
                                ) : loading ? (
                                    <Spinner
                                        thickness="2px"
                                        speed="0.45s"
                                        boxSize="30px"
                                        color="white"
                                    />
                                ) : delay && smsSelected ? (
                                    'SMS SENT'
                                ) : delay && emailSelected ? (
                                    'EMAIL SENT'
                                ) : step === 1 && smsSelected ? (
                                    'SEND SMS'
                                ) : step === 1 && authenticatorSelected ? (
                                    'SELECT'
                                ) : step === 1 && emailSelected ? (
                                    'SEND VERIFICATION EMAIL'
                                ) : (
                                    'VERIFY'
                                )}
                            </Button>
                        </>
                    </Flex>
                </Flex>
            </Flex>
        )
    }
    return null
}
