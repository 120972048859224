import { useMemo } from 'react'

// *** Function to process any erroneous Aerodrome or AWS designators entered in the URL address bar ***
// this is used in RouteHandler, BriefingStandard and Excluded Items
export function useFilteredDesignators(allDesignators, excludedItems) {
    // these need to be within useMemos to avoid useEffect constantly firing after page load or refresh
    const filteredDesignators = useMemo(() => {
        return allDesignators && allDesignators.length > 0 && excludedItems
            ? excludedItems.filter(
                  (designator) => !allDesignators.includes(designator)
              )
            : []
    }, [excludedItems, allDesignators])

    const filteredExcludedDesignators = useMemo(() => {
        return allDesignators && excludedItems
            ? [...excludedItems].filter((designator) =>
                  allDesignators.includes(designator)
              )
            : []
    }, [allDesignators, excludedItems])

    return {
        filteredDesignators,
        filteredExcludedDesignators,
    }
}
