import React from 'react'
import WXRasterTiles from './components/WXRasterTiles'

export default function satelliteCloudVisible() {
    return (
        <WXRasterTiles
            layerId={'satelliteCloudVisible'}
            timesURL={
                'https://api.wxtiles.com/v1/wxtiles/layer/jma-himawari-oceania_1km-vis/instance/him8-oceania_1km_vis/'
            }
            rasterURL={
                'https://api.wxtiles.com/v1/wxtiles/tile/jma-himawari-oceania_1km-vis/sat-visible/him8-oceania_1km_vis/'
            }
            limit={-9}
        />
    )
}
