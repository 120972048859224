import React from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Center, Tooltip } from '@chakra-ui/react'

import { isDesktop, isIOS } from 'react-device-detect'
import {
    layerSelectionHandler,
    layerSelectionState,
} from '../../../../globalState'
import AerodromeIcon from '../../../../icons/menu-icons/AerodromeIcon'

export default function AerodromeButton() {
    const layerHandler = useSetRecoilState(layerSelectionHandler)
    const layerSelection = useRecoilValue(layerSelectionState)
    return (
        <Tooltip
            display={isDesktop ? 'flex' : 'none'}
            isDisabled={isDesktop ? false : true}
            label="Aerodromes"
            hasArrow
            borderRadius="7px"
            height="26px"
            fontSize="0.7rem"
            alignItems="center"
            placement="left"
            m="5px"
            color="light.100"
            bg="light.10"
        >
            <Center
                w={{ base: '32px', md: '30px' }}
                h={{ base: '32px', md: '30px' }}
                pt={'10px'}
                pb={'10px'}
                borderRadius="10px"
                backgroundColor={
                    layerSelection['constant']['aerodromes'] &&
                    'rgba(255,255,255,0.4)'
                }
                _hover={{
                    backgroundColor:
                        (isDesktop || !isIOS) && 'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                _focus={{
                    backgroundColor:
                        layerSelection['constant']['aerodromes'] &&
                        (isDesktop || !isIOS) &&
                        'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    layerHandler({
                        mode: 'constant',
                        layer: 'aerodromes',
                    })
                }}
            >
                <AerodromeIcon
                    color={
                        !layerSelection['constant']['aerodromes'] && 'light.10'
                    }
                    activeColor={'light.10'}
                    active={layerSelection['constant']['aerodromes']}
                />
            </Center>
        </Tooltip>
    )
}
