import React from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
    Flex,
    Text,
    Input,
    Textarea,
    FormControl,
    FormErrorMessage,
    Tooltip,
    Button,
    Icon,
    Collapse,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from '@chakra-ui/react'
import '../../briefing.css'
import { isMobile, isDesktop } from 'react-device-detect'
import { FiEdit } from 'react-icons/fi'
import { BsQuestionCircle } from 'react-icons/bs'
import { PiAsteriskSimpleBold } from 'react-icons/pi'
import { ExcludedAerodromes, ExcludedAws } from '../briefing-menu/ExcludedItems'
import {
    updatedParamsState,
    showManageFavouritesState,
    newFavouriteTitleState,
    newFavouriteNoteState,
    excludedAerodromesState,
    excludedAwsState,
    selectAerodromesState,
    selectAwsState,
    isBriefingOnlyState,
    updatingExcludedItemsState,
    briefingAreaSourceState,
} from '../../../../../globalState'

export default function SaveNewFavourite(props) {
    const {
        briefingData,
        isPremium,
        formattedContents,
        duplicateTitle,
        duplicateUrl,
        maxLimitFavourites,
        nearingFavouritesLimit,
        reachedFavouritesLimit,
        filteredExcludedAerodromes,
        filteredExcludedAws,
        includedAerodromes,
        includedAws,
        updatingFavourites,
    } = props

    const updatedParams = useRecoilValue(updatedParamsState)
    const showManageFavourites = useRecoilValue(showManageFavouritesState)
    const [newFavouriteTitle, setNewFavouriteTitle] = useRecoilState(
        newFavouriteTitleState
    )
    const [newFavouriteNote, setNewFavouriteNote] = useRecoilState(
        newFavouriteNoteState
    )
    const setExcludedAerodromes = useSetRecoilState(excludedAerodromesState)
    const setExcludedAws = useSetRecoilState(excludedAwsState)
    const [selectAerodromes, setSelectAerodromes] = useRecoilState(
        selectAerodromesState
    )
    const [selectAws, setSelectAws] = useRecoilState(selectAwsState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const [updatingExcludedItems, setUpdatingExcludedItems] = useRecoilState(
        updatingExcludedItemsState
    )
    const briefingAreaSource = useRecoilValue(briefingAreaSourceState)

    const updatedAerodromes =
        updatingExcludedItems &&
        (updatedParams?.excluded || filteredExcludedAerodromes) &&
        updatedParams.excluded !== filteredExcludedAerodromes.join(',')

    const updatedAws =
        updatingExcludedItems &&
        (updatedParams?.excludedaws || filteredExcludedAws) &&
        updatedParams.excludedaws !== filteredExcludedAws.join(',')

    const focus = {
        _focus: {
            borderColor: duplicateTitle ? 'red.500' : 'brand.200',
        },
    }

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="center"
            flexDirection="column"
            gap={{
                base: '20px',
                md: '10px',
            }}
            width="100%"
            fontSize="0.8rem"
            px={{
                base: '0px',
                md: '15px',
            }}
        >
            <Text
                width="100%"
                pt={{
                    base: '6',
                    md: '6',
                }}
                pb={{
                    base: '3',
                    md: '4',
                }}
                textAlign="center"
                fontSize="0.8rem"
                px={{
                    base: '0px',
                    md: '30px',
                }}
                color="#868b8e"
            >
                The criteria below is based on your current Briefing selections.
                If you wish to alter these, please make any necessary
                adjustments in the Briefing and then save
            </Text>
            <Flex
                width="100%"
                border="1px solid"
                borderColor="gray.200"
                bg="gray.50"
                px={{
                    base: '15px',
                    md: '25px',
                }}
                pt={{
                    base: duplicateUrl ? '10px' : '15px',
                    md: duplicateUrl ? '20px' : '25px',
                }}
                pb={{
                    base: '25px',
                    md: '25px',
                }}
                gap={{
                    base: '20px',
                    md: '15px',
                }}
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
            >
                {(nearingFavouritesLimit ||
                    reachedFavouritesLimit ||
                    duplicateUrl) && (
                    <Flex
                        width="100%"
                        gap={{
                            base: '15px',
                            md: '10px',
                        }}
                        alignItems="flex-start"
                        justifyContent="center"
                        flexDirection="column"
                        pb={{
                            base: '0px',
                            md: '10px',
                        }}
                    >
                        {!updatingFavourites &&
                            (nearingFavouritesLimit ||
                                reachedFavouritesLimit) && (
                                <Flex
                                    gap={{
                                        base: '0px',
                                        smd: '2px',
                                    }}
                                    width="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    flexDirection={{
                                        base: 'column',
                                        smd: 'row',
                                    }}
                                >
                                    <Text
                                        pl={{
                                            base: '0px',
                                            smd: '28px',
                                        }}
                                        color={
                                            reachedFavouritesLimit
                                                ? 'red.500'
                                                : 'warning.100'
                                        }
                                        fontWeight="500"
                                        fontSize="0.8rem"
                                        textAlign="center"
                                    >
                                        {reachedFavouritesLimit
                                            ? `You have reached a maximum limit of ${maxLimitFavourites} saved favourite Briefings`
                                            : `You are reaching a maximum limit of ${maxLimitFavourites} saved favourite Briefings`}
                                    </Text>
                                    <Popover placement="top">
                                        <PopoverTrigger>
                                            <Flex
                                                width="30px"
                                                height="28px"
                                                justifyContent="center"
                                                alignItems="center"
                                                borderRadius="10px"
                                                color="gray.400"
                                                opacity="0.8"
                                                cursor="pointer"
                                            >
                                                <BsQuestionCircle fontSize="0.9rem" />
                                            </Flex>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            color="white"
                                            bg="gray.600"
                                            px="5px"
                                            borderColor="gray.600"
                                            borderRadius="20px"
                                            fontSize="0.75rem"
                                            alignItems="center"
                                            textAlign="center"
                                            lineHeight="1.4"
                                            fontFamily="Open Sans"
                                        >
                                            <PopoverArrow bg="gray.600" />
                                            <PopoverBody>
                                                <Text>
                                                    Currently there is a limit
                                                    of up to{' '}
                                                    <span
                                                        style={{
                                                            fontWeight: '700',
                                                        }}
                                                    >
                                                        5
                                                    </span>{' '}
                                                    Briefings that can be added
                                                    to your list of saved
                                                    Favourites.
                                                </Text>
                                                <Text pt="5px">
                                                    This limit may increase in
                                                    future.
                                                </Text>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </Flex>
                            )}

                        {!updatingFavourites && duplicateUrl && (
                            <Text
                                color="warning.100"
                                fontWeight="500"
                                fontSize="0.8rem"
                                width="100%"
                                textAlign="center"
                            >
                                You already have a saved favourite Briefing with
                                this criteria
                            </Text>
                        )}
                    </Flex>
                )}

                {/* Save Favourite Label */}
                <Flex
                    width="100%"
                    gap="5px"
                    pb="5px"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <Flex
                        width="100%"
                        gap={{
                            base: '10px',
                            md: '5px',
                        }}
                        alignItems={{
                            base: 'flex-start',
                            md: 'flex-start',
                        }}
                        justifyContent="flex-start"
                        flexDirection={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                        <Text
                            color={{
                                base: 'gray.500',
                                md: 'gray.600',
                            }}
                            fontWeight="600"
                            minWidth="180px"
                        >
                            Add Title:
                            <Icon
                                as={PiAsteriskSimpleBold}
                                paddingLeft="5px"
                                paddingBottom="5px"
                                fontSize="0.8rem"
                                color="red.400"
                            />
                        </Text>
                        <FormControl isInvalid={duplicateTitle}>
                            <Input
                                isRequired
                                maxWidth={{
                                    base: '100%',
                                    md: '100%',
                                    // md: '350px',
                                }}
                                cursor={'pointer'}
                                color={'light.100'}
                                _placeholder={{
                                    color: 'gray.300',
                                }}
                                variant="primary"
                                {...focus}
                                fontSize={{
                                    base: '0.85rem',
                                    md: '0.8rem',
                                }}
                                height="8"
                                size="sm"
                                id="last-name"
                                placeholder="Title of your new saved Favourite Briefing"
                                border="1px solid #cbd5e0"
                                borderColor={
                                    duplicateTitle ? 'red.500' : '#cbd5e0'
                                }
                                borderRadius={{
                                    base: '10px',
                                    md: '10px',
                                }}
                                value={newFavouriteTitle}
                                onChange={(e) =>
                                    setNewFavouriteTitle(e.target.value)
                                }
                            />
                            {!updatingFavourites && duplicateTitle && (
                                <FormErrorMessage fontSize="0.8rem">
                                    The title cannot be the same as another
                                    saved favourite
                                </FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                </Flex>

                {/* Selected Briefing Areas */}
                {briefingAreaSource === 'briefingarea' && (
                    <Flex
                        gap="5px"
                        width="100%"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        flexDirection={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                        <Text
                            color={{
                                base: 'gray.500',
                                md: 'gray.600',
                            }}
                            fontWeight="600"
                            minWidth="180px"
                        >
                            Briefing Areas Selected:
                        </Text>
                        <Text
                            pl={{
                                base: '15px',
                                md: '1px',
                            }}
                            fontSize={{
                                base: '0.85rem',
                                md: '0.8rem',
                            }}
                            fontWeight="400"
                            minWidth="180px"
                        >
                            {updatedParams && updatedParams.selected
                                ? updatedParams.selected.replace(/,/g, ', ')
                                : ''}
                        </Text>
                    </Flex>
                )}

                {/*  Selected AAW Areas */}
                <Flex
                    gap="5px"
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <Text
                        color={{
                            base: 'gray.500',
                            md: 'gray.600',
                        }}
                        fontWeight="600"
                        minWidth="180px"
                    >
                        {`${
                            briefingAreaSource === 'briefingarea'
                                ? 'AAW Areas Included:'
                                : 'AAW Areas Selected:'
                        }`}
                    </Text>
                    <Text
                        pl={{
                            base: '15px',
                            md: '1px',
                        }}
                        fontSize={{
                            base: '0.85rem',
                            md: '0.8rem',
                        }}
                        fontWeight="400"
                        minWidth="180px"
                    >
                        {briefingData?.res?.areamet &&
                            briefingData?.res?.areamet?.aaw &&
                            briefingData.res.areamet.aaw
                                .map((aaw) => aaw.area)
                                .join(', ')}
                    </Text>
                </Flex>

                {/* Selected Areas */}
                <Flex
                    gap="5px"
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <Text
                        color={{
                            base: 'gray.500',
                            md: 'gray.600',
                        }}
                        fontWeight="600"
                        minWidth="180px"
                    >
                        Contents Selected:
                    </Text>
                    <Text
                        pl={{
                            base: '15px',
                            md: '1px',
                        }}
                        fontSize={{
                            base: '0.85rem',
                            md: '0.8rem',
                        }}
                        fontWeight="400"
                        minWidth="180px"
                    >
                        {formattedContents.map((area) => area).join(', ')}
                    </Text>
                </Flex>

                {/*  Period to */}
                <Flex
                    gap="5px"
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <Text
                        color={{
                            base: 'gray.500',
                            md: 'gray.600',
                        }}
                        fontWeight="600"
                        minWidth="180px"
                    >
                        Period To Selected:
                    </Text>
                    <Text
                        pl={{
                            base: '15px',
                            md: '1px',
                        }}
                        fontSize={{
                            base: '0.85rem',
                            md: '0.8rem',
                        }}
                        fontWeight="400"
                        minWidth="180px"
                    >
                        + {updatedParams?.time} Hours
                    </Text>
                </Flex>

                {/*  Maximum Altitude */}
                <Flex
                    gap="5px"
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <Text
                        color={{
                            base: 'gray.500',
                            md: 'gray.600',
                        }}
                        fontWeight="600"
                        minWidth="180px"
                    >
                        Altitude Range Selected:
                    </Text>
                    <Text
                        pl={{
                            base: '15px',
                            md: '1px',
                        }}
                        fontSize={{
                            base: '0.85rem',
                            md: '0.8rem',
                        }}
                        fontWeight="400"
                        minWidth="180px"
                    >
                        SFC{' '}
                        {updatedParams?.maxalt === 0
                            ? 'only'
                            : updatedParams?.maxalt < 13500
                            ? `– ${
                                  updatedParams?.maxalt >= 10000
                                      ? updatedParams?.maxalt.toLocaleString(
                                            'en'
                                        )
                                      : updatedParams?.maxalt
                              } ft`
                            : updatedParams?.maxalt === 10000
                            ? '– 10,000 ft'
                            : `– FL${updatedParams?.maxalt / 100}`}
                    </Text>
                </Flex>

                {/*  Excluded Aerodromes */}
                <Flex
                    gap="10px"
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    flexDirection="row"
                >
                    <Flex
                        gap="5px"
                        width="100%"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        flexDirection={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                        <Text
                            color={{
                                base: 'gray.500',
                                md: 'gray.600',
                            }}
                            fontWeight="600"
                            minWidth="180px"
                        >
                            Aerodromes Excluded:
                        </Text>
                        <Text
                            pl={{
                                base: '15px',
                                md: '1px',
                            }}
                            fontSize={{
                                base: '0.85rem',
                                md: '0.8rem',
                            }}
                            fontWeight="400"
                            minWidth="180px"
                            opacity={updatedParams?.excluded ? '1' : '0.3'}
                        >
                            {updatedParams && updatedParams.excluded
                                ? updatedParams.excluded.replace(/,/g, ', ')
                                : 'None'}
                        </Text>
                    </Flex>
                    <Tooltip
                        label={
                            updatedAerodromes
                                ? 'Click to Update Aerodromes'
                                : selectAerodromes
                                ? 'Close'
                                : 'Reinstate or exclude Aerodromes'
                        }
                        hasArrow
                        borderRadius="7px"
                        minHeight="26px"
                        fontSize="0.7rem"
                        textAlign="center"
                        alignItems="center"
                        display={isMobile || !isDesktop ? 'none' : 'flex'}
                        isDisabled={isMobile || !isDesktop}
                        placement="left"
                        m="5px"
                        mr="2px"
                        color="light.10"
                        bg="dark.10"
                    >
                        <Button
                            variant="outline"
                            borderRadius="25px"
                            mt={{ base: '0px', md: '-4px' }}
                            fontSize="0.75rem"
                            height={{ base: '30px', md: '26px' }}
                            width="74px"
                            minWidth="74px"
                            background={
                                updatedAerodromes && selectAerodromes
                                    ? 'light.200'
                                    : 'white'
                            }
                            borderColor={
                                updatedAerodromes && selectAerodromes
                                    ? 'light.200'
                                    : 'gray.200'
                            }
                            color={
                                updatedAerodromes && selectAerodromes
                                    ? 'white'
                                    : selectAerodromes
                                    ? 'light.200'
                                    : 'gray.600'
                            }
                            fontWeight="500"
                            transition="all ease 200ms"
                            _hover={{
                                color:
                                    updatedAerodromes && selectAerodromes
                                        ? 'white'
                                        : selectAerodromes
                                        ? 'light.200'
                                        : 'gray.800',

                                opacity: '1',
                                background:
                                    updatedAerodromes && selectAerodromes
                                        ? 'light.201'
                                        : 'light.30',
                                borderColor:
                                    updatedAerodromes && selectAerodromes
                                        ? 'light.201'
                                        : 'gray.200',
                            }}
                            _active={{
                                background:
                                    updatedAerodromes && selectAerodromes
                                        ? 'light.201'
                                        : 'light.30',
                                boxShadow:
                                    'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap="5px"
                            onClick={() => {
                                if (selectAws) {
                                    setSelectAws(false)
                                    setUpdatingExcludedItems(false)
                                }
                                setSelectAerodromes((prevState) => !prevState)
                            }}
                        >
                            <Icon
                                as={FiEdit}
                                fontSize={selectAerodromes ? '0rem' : '1rem'}
                                variant="outline"
                                pb="1px"
                                transition="all ease 200ms"
                            />
                            <Text pr="4px">
                                {updatedAerodromes && selectAerodromes
                                    ? 'UPDATE'
                                    : selectAerodromes
                                    ? 'Close'
                                    : 'Edit'}
                            </Text>
                        </Button>
                    </Tooltip>
                </Flex>
                <Collapse
                    animateOpacity
                    unmountOnExit
                    in={selectAerodromes}
                    position={'relative'}
                    style={{
                        overflow: 'hidden',
                        opacity: selectAerodromes ? '1' : '0',
                        width: '100%',
                        minWidth: '100%',
                    }}
                >
                    <Flex
                        width="100%"
                        border="1px solid"
                        borderColor="gray.200"
                        background="white"
                        flexDirection="column"
                        alignItems="center"
                        gap="20px"
                        mb="15px"
                        px="15px"
                        py="10px"
                    >
                        <Text
                            textAlign="center"
                            fontSize={{
                                base: '0.75rem',
                                smd: '0.8rem',
                            }}
                            color="gray.500"
                            fontWeight="300"
                            lineHeight="1.2"
                            whiteSpace="pre-line"
                        >
                            Select{' '}
                            <span style={{ fontWeight: '500' }}>
                                Aerodromes
                            </span>{' '}
                            below to reinstate or exclude in your briefing
                        </Text>
                        <ExcludedAerodromes
                            setExcludedAerodromes={setExcludedAerodromes}
                            briefingData={briefingData}
                            filteredExcludedAerodromes={
                                filteredExcludedAerodromes
                            }
                            includedAerodromes={includedAerodromes}
                            selectAerodromes={selectAerodromes}
                            isPremium={isPremium}
                            selectAws={selectAws}
                            setSelectAerodromes={setSelectAerodromes}
                            setSelectAws={setSelectAws}
                            isBriefingOnly={isBriefingOnly}
                            showManageFavourites={showManageFavourites}
                            setUpdatingExcludedItems={setUpdatingExcludedItems}
                        />
                    </Flex>
                </Collapse>

                {/* Excluded AWS */}
                {!isPremium && (
                    <>
                        <Flex
                            gap="10px"
                            width="100%"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            flexDirection="row"
                        >
                            <Flex
                                gap="5px"
                                width="100%"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                flexDirection={{
                                    base: 'column',
                                    md: 'row',
                                }}
                            >
                                <Text
                                    color={{
                                        base: 'gray.500',
                                        md: 'gray.600',
                                    }}
                                    fontWeight="600"
                                    minWidth="180px"
                                >
                                    AWS Excluded:
                                </Text>
                                <Text
                                    pl={{
                                        base: '15px',
                                        md: '1px',
                                    }}
                                    fontSize={{
                                        base: '0.85rem',
                                        md: '0.8rem',
                                    }}
                                    fontWeight="400"
                                    minWidth="180px"
                                    opacity={
                                        updatedParams?.excludedaws ? '1' : '0.3'
                                    }
                                >
                                    {updatedParams && updatedParams.excludedaws
                                        ? updatedParams.excludedaws.replace(
                                              /,/g,
                                              ', '
                                          )
                                        : 'None'}
                                </Text>
                            </Flex>
                            <Tooltip
                                label={
                                    updatedAws && selectAws
                                        ? 'Click to Update Automatic Weather Stations'
                                        : selectAws
                                        ? 'Close'
                                        : 'Reinstate or exclude Automatic Weather Stations'
                                }
                                hasArrow
                                borderRadius="7px"
                                minHeight="26px"
                                fontSize="0.7rem"
                                textAlign="center"
                                alignItems="center"
                                display={
                                    isMobile || !isDesktop ? 'none' : 'flex'
                                }
                                isDisabled={isMobile || !isDesktop}
                                placement="left"
                                m="5px"
                                mr="2px"
                                color="light.10"
                                bg="dark.10"
                            >
                                <Button
                                    variant="outline"
                                    borderRadius="25px"
                                    mt={{ base: '0px', md: '-4px' }}
                                    fontSize="0.75rem"
                                    height={{ base: '30px', md: '26px' }}
                                    width="74px"
                                    minWidth="74px"
                                    background={
                                        updatedAws && selectAws
                                            ? 'light.200'
                                            : 'white'
                                    }
                                    borderColor={
                                        updatedAws && selectAws
                                            ? 'light.200'
                                            : 'gray.200'
                                    }
                                    color={
                                        updatedAws && selectAws
                                            ? 'white'
                                            : selectAws
                                            ? 'light.200'
                                            : 'gray.600'
                                    }
                                    fontWeight="500"
                                    transition="all ease 200ms"
                                    _hover={{
                                        color:
                                            updatedAws && selectAws
                                                ? 'white'
                                                : selectAws
                                                ? 'light.200'
                                                : 'gray.800',

                                        opacity: '1',
                                        background:
                                            updatedAws && selectAws
                                                ? 'light.201'
                                                : 'light.30',
                                        borderColor:
                                            updatedAws && selectAws
                                                ? 'light.201'
                                                : 'gray.200',
                                    }}
                                    _active={{
                                        background:
                                            updatedAws && selectAws
                                                ? 'light.201'
                                                : 'light.30',
                                        boxShadow:
                                            'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap="5px"
                                    onClick={() => {
                                        if (selectAerodromes) {
                                            setSelectAerodromes(false)
                                            setUpdatingExcludedItems(false)
                                        }
                                        setSelectAws((prevState) => !prevState)
                                    }}
                                >
                                    <Icon
                                        as={FiEdit}
                                        fontSize={selectAws ? '0rem' : '1rem'}
                                        variant="outline"
                                        pb={selectAws ? '3px' : '1px'}
                                        transition="all ease 250ms"
                                    />
                                    <Text pr="3px">
                                        {updatedAws && selectAws
                                            ? 'UPDATE'
                                            : selectAws
                                            ? 'Close'
                                            : 'Edit'}
                                    </Text>
                                </Button>
                            </Tooltip>
                        </Flex>
                        <Collapse
                            animateOpacity
                            unmountOnExit
                            in={selectAws}
                            position={'relative'}
                            style={{
                                overflow: 'hidden',
                                opacity: selectAws ? '1' : '0',
                                width: '100%',
                                minWidth: '100%',
                            }}
                        >
                            <Flex
                                width="100%"
                                border="1px solid"
                                borderColor="gray.200"
                                background="white"
                                flexDirection="column"
                                alignItems="center"
                                gap="20px"
                                mb="15px"
                                px="15px"
                                py="10px"
                            >
                                <Text
                                    textAlign="center"
                                    fontSize={{
                                        base: '0.75rem',
                                        smd: '0.8rem',
                                    }}
                                    color="gray.500"
                                    fontWeight="300"
                                    lineHeight="1.2"
                                    whiteSpace="pre-line"
                                >
                                    Select{' '}
                                    <span style={{ fontWeight: '500' }}>
                                        Automatic Weather Stations
                                    </span>{' '}
                                    below to reinstate or exclude in your
                                    briefing
                                </Text>
                                <ExcludedAws
                                    isBriefingOnly={isBriefingOnly}
                                    setExcludedAws={setExcludedAws}
                                    briefingData={briefingData}
                                    filteredExcludedAerodromes={
                                        filteredExcludedAerodromes
                                    }
                                    filteredExcludedAws={filteredExcludedAws}
                                    includedAws={includedAws}
                                    selectAerodromes={selectAerodromes}
                                    setSelectAerodromes={setSelectAerodromes}
                                    selectAws={selectAws}
                                    setSelectAws={setSelectAws}
                                    isPremium={isPremium}
                                    showManageFavourites={showManageFavourites}
                                    setUpdatingExcludedItems={
                                        setUpdatingExcludedItems
                                    }
                                />
                            </Flex>
                        </Collapse>
                    </>
                )}

                {/* Save Favourite Note */}
                <Flex
                    pt={{
                        base: '0px',
                        md: '5px',
                    }}
                    width="100%"
                    gap={{
                        base: '10px',
                        md: '5px',
                    }}
                    alignItems={{
                        base: 'flex-start',
                        md: 'flex-start',
                    }}
                    justifyContent="flex-start"
                    flexDirection={{
                        base: 'column',
                        md: 'row',
                    }}
                >
                    <Flex
                        gap="2px"
                        pr={{
                            base: '1px',
                            md: '15px',
                        }}
                        minWidth="180px"
                        width={{
                            base: '100%',
                            md: '180px',
                        }}
                        justifyContent={{
                            base: 'space-between',
                            md: 'flex-start',
                        }}
                        alignItems="flex-start"
                        flexDirection={{
                            base: 'row',
                            md: 'column',
                        }}
                    >
                        <Text
                            fontWeight="600"
                            color={{
                                base: 'gray.500',
                                md: 'gray.600',
                            }}
                        >
                            Add Reference Note:
                        </Text>
                        <Text
                            minHeight={{
                                base: 'unset',
                                md: '20px',
                            }}
                            textAlign={{
                                base: 'right',
                                md: 'left',
                            }}
                            fontSize={{
                                base: '0.75rem',
                                md: '0.7rem',
                            }}
                            fontWeight="300"
                            color="gray.400"
                            lineHeight={'1.2'}
                            fontStyle="italic"
                        >
                            Optional
                        </Text>
                    </Flex>
                    <Textarea
                        variant="primary"
                        minHeight={{
                            base: '5rem',
                            md: '3.3rem',
                        }}
                        width="100%"
                        cursor={'pointer'}
                        color={'light.100'}
                        _placeholder={{
                            color: 'gray.300',
                        }}
                        {...focus}
                        fontSize={{
                            base: '0.85rem',
                            md: '0.8rem',
                        }}
                        height="8"
                        size="sm"
                        id="last-name"
                        placeholder={`i.e. "Cross-country flight NZPP - NZWB - NZCH"`}
                        backgroundColor="light.10"
                        backgroundClip="padding-box"
                        border="1px solid"
                        transition="border-color .15s ease-in-out,box-shadow .15s ease-in-out"
                        borderColor="#cbd5e0"
                        borderRadius={{
                            base: '10px',
                            md: '10px',
                        }}
                        background="light.10"
                        _focus={{
                            borderColor: 'brand.200',
                        }}
                        value={newFavouriteNote}
                        onChange={(e) => setNewFavouriteNote(e.target.value)}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}

/* <Flex // TODO - left this here for now for packaging if needed. This allows the menu items to be toggled 
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="column"
                                        gap="3px"
                                        width="100%"
                                    >
                                        {briefingParams &&
                                            Object.entries(briefingParams).map(
                                                ([key, value]) => {
                                                    let metItem = key
                                                    const selectedMetItemsToCapitalize =
                                                        [
                                                            'aws',
                                                            'aaw',
                                                            'sigmet',
                                                            'sigwx',
                                                            'msl',
                                                            'grafor',
                                                        ]
                                                    if (
                                                        key ===
                                                        'aerodromesNotam'
                                                    ) {
                                                        metItem = 'NOTAM'
                                                    } else if (
                                                        key ===
                                                        'aerodromesMetar'
                                                    ) {
                                                        metItem = 'METAR'
                                                    } else if (
                                                        key === 'aerodromesTaf'
                                                    ) {
                                                        metItem = 'TAF'
                                                    } else if (
                                                        key === 'aerodromesAtis'
                                                    ) {
                                                        metItem = 'ATIS'
                                                    } else if (
                                                        key === 'enroute'
                                                    ) {
                                                        metItem =
                                                            'En-route NOTAM'
                                                    } else if (
                                                        key === 'spacewx'
                                                    ) {
                                                        metItem = 'SPACEWX'
                                                    } else if (
                                                        selectedMetItemsToCapitalize.includes(
                                                            key
                                                        )
                                                    ) {
                                                        metItem =
                                                            key.toUpperCase()
                                                    }

                                                    return (
                                                        <Flex
                                                            key={key}
                                                            gap="10px"
                                                        >
                                                            <Text
                                                                fontWeight="500"
                                                                textTransform="capitalize"
                                                            >
                                                                {metItem}:
                                                            </Text>
                                                            <Text fontWeight="300">
                                                                {value
                                                                    ? 'true'
                                                                    : 'false'}
                                                            </Text>
                                                        </Flex>
                                                    )
                                                }
                                            )}
                                    </Flex> */

/**** Replicating menu ****/

/* <Flex
                                        // flexDirection={'row'}
                                        zIndex="2"
                                        // h="44px"
                                        w="100%"
                                        justifyContent="space-evenly"
                                        flexDirection="column"
                                        transition="all ease 300ms"
                                        gap="5px"
                                        paddingLeft={
                                            altAerodromeLayout ? '23px' : '23px'
                                        }
                                        paddingRight={
                                            altAerodromeLayout ? '23px' : '25px'
                                        }
                                    >
                                        <Flex width="100%" zIndex="1">
                                            <Flex
                                                fontSize="0.65rem"
                                                fontWeight="400"
                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                flex="1"
                                                textAlign="left"
                                                paddingLeft={
                                                    minimiseDesktopMenu
                                                        ? '0px'
                                                        : '21px'
                                                }
                                                width={
                                                    minimiseDesktopMenu
                                                        ? '0px'
                                                        : '100%'
                                                }
                                                transition="all ease 300ms"
                                                height="40px"
                                                alignItems="center"
                                            >
                                                <Text
                                                    position={{
                                                        base: 'relative',
                                                        md: 'relative',
                                                    }}
                                                    display={{
                                                        base: 'initial',
                                                        md: minimiseDesktopMenu
                                                            ? 'none'
                                                            : 'initial',
                                                    }}
                                                    opacity={{
                                                        base: '1',
                                                        md:
                                                            filtersShow ||
                                                            mobileDrawerExpanded
                                                                ? '1'
                                                                : '0',
                                                    }}
                                                    visibility={{
                                                        base: 'visible',
                                                        md:
                                                            filtersShow ||
                                                            mobileDrawerExpanded
                                                                ? 'visible'
                                                                : 'hidden',
                                                    }}
                                                    transition="opacity ease 300ms"
                                                    marginTop="auto"
                                                    marginBottom="auto"
                                                    color="light.100"
                                                    fontSize={{
                                                        base: '0.9rem',
                                                        md: '0.85rem',
                                                    }}
                                                    fontWeight="400"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                >
                                                    ALL
                                                </Text>
                                            </Flex>

                                            <Flex
                                                width={{
                                                    base: '100%',
                                                    md:
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? '100%'
                                                            : '10px',
                                                }}
                                                maxWidth={{
                                                    base: '44px',
                                                    md:
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? '44px'
                                                            : '10px',
                                                }}
                                                marginLeft={{
                                                    base: '12px',
                                                    md:
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? '12px'
                                                            : '0px',
                                                }}
                                                visibility={
                                                    filtersShow ||
                                                    mobileDrawerExpanded
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                                opacity={
                                                    filtersShow ||
                                                    mobileDrawerExpanded
                                                        ? '1'
                                                        : '0'
                                                }
                                                overflow="hidden"
                                                transition="all ease 300ms"
                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            >
                                                <Checkbox // All Checkbox
                                                    {...checkboxSize}
                                                    {...checkboxVariant}
                                                    marginRight={{
                                                        base: '10px',
                                                        md: '20px',
                                                    }}
                                                    marginLeft="auto"
                                                    display={
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? undefined
                                                            : 'none'
                                                    }
                                                    opacity={
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? '1'
                                                            : '0'
                                                    }
                                                    transition="opacity 300ms"
                                                    colorScheme="teal"
                                                    isChecked={
                                                        !Object.keys(
                                                            briefingParams
                                                        )
                                                            .map(
                                                                (i) =>
                                                                    briefingParams[
                                                                        i
                                                                    ]
                                                            )
                                                            .every((i) => !i)
                                                    }
                                                    isDisabled={
                                                        // if not indeterminate
                                                        !Object.keys(menuState)
                                                            .map((parent) => {
                                                                if (
                                                                    !menuState[
                                                                        parent
                                                                    ].children
                                                                ) {
                                                                    return briefingParams[
                                                                        menuState[
                                                                            parent
                                                                        ]['id']
                                                                    ]
                                                                } else
                                                                    return (
                                                                        // parent checked and...
                                                                        briefingParams[
                                                                            menuState[
                                                                                parent
                                                                            ][
                                                                                'id'
                                                                            ]
                                                                        ] &&
                                                                        // no children unchecked
                                                                        !Object.keys(
                                                                            menuState[
                                                                                parent
                                                                            ]
                                                                                .children
                                                                        )
                                                                            .map(
                                                                                (
                                                                                    child
                                                                                ) =>
                                                                                    briefingParams[
                                                                                        menuState[
                                                                                            parent
                                                                                        ][
                                                                                            'children'
                                                                                        ][
                                                                                            child
                                                                                        ][
                                                                                            'id'
                                                                                        ]
                                                                                    ]
                                                                            )
                                                                            .includes(
                                                                                false
                                                                            )
                                                                    )
                                                            })
                                                            .includes(false)
                                                    }
                                                    isIndeterminate={
                                                        // Simplify this to check only the current value or the children values.
                                                        // Create function to tell if something is indeterminate
                                                        !Object.keys(
                                                            briefingParams
                                                        )
                                                            .map(
                                                                (i) =>
                                                                    briefingParams[
                                                                        i
                                                                    ]
                                                            )
                                                            .every((i) => !i) &&
                                                        Object.keys(menuState)
                                                            .map((parent) => {
                                                                if (
                                                                    !menuState[
                                                                        parent
                                                                    ].children
                                                                ) {
                                                                    return briefingParams[
                                                                        menuState[
                                                                            parent
                                                                        ]['id']
                                                                    ]
                                                                } else
                                                                    return (
                                                                        // parent checked and...
                                                                        briefingParams[
                                                                            menuState[
                                                                                parent
                                                                            ][
                                                                                'id'
                                                                            ]
                                                                        ] &&
                                                                        // no children unchecked
                                                                        !Object.keys(
                                                                            menuState[
                                                                                parent
                                                                            ]
                                                                                .children
                                                                        )
                                                                            .map(
                                                                                (
                                                                                    child
                                                                                ) =>
                                                                                    briefingParams[
                                                                                        menuState[
                                                                                            parent
                                                                                        ][
                                                                                            'children'
                                                                                        ][
                                                                                            child
                                                                                        ][
                                                                                            'id'
                                                                                        ]
                                                                                    ]
                                                                            )
                                                                            .includes(
                                                                                false
                                                                            )
                                                                    )
                                                            })
                                                            .includes(false)
                                                    }
                                                    onChange={() => {
                                                        setBriefingParams(
                                                            (
                                                                prevBriefingParams
                                                            ) => {
                                                                let newBriefingParams =
                                                                    {}
                                                                Object.keys(
                                                                    prevBriefingParams
                                                                ).forEach(
                                                                    (l) =>
                                                                        (newBriefingParams[
                                                                            l
                                                                        ] = true)
                                                                )
                                                                return newBriefingParams
                                                            }
                                                        )
                                                    }}
                                                ></Checkbox>
                                            </Flex>
                                        </Flex>
                                        <Divider
                                            height="0px !important"
                                            borderColor="#e9edf3"
                                            width={{
                                                base: 'calc(100% - 28px)',
                                                md: '100%',
                                            }}
                                            mx="auto"
                                            mt="0px"
                                            ml={{ base: '19px', md: '0' }}
                                            mb="10px"
                                        />
                                        <MobileMenuItems
                                            showManageFavourites={
                                                showManageFavourites
                                            }
                                            dataLoading={dataLoading}
                                            altAerodromeLayout={
                                                altAerodromeLayout
                                            }
                                            filtersShow={true}
                                            mobileDrawerExpanded={true}
                                            minimiseDesktopMenu={false}
                                            params={briefingParams}
                                            setMinimiseDesktopMenu={
                                                setMinimiseDesktopMenu
                                            }
                                            menuState={menuState}
                                            setParams={setBriefingParams}
                                            isPremium={isPremium}
                                            setIsScrolling={setIsScrolling}
                                            setButtonSelected={
                                                setButtonSelected
                                            }
                                            setMenuState={setMenuState}
                                        />
                                    </Flex> */
