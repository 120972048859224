import React from 'react'
import PropTypes from 'prop-types'

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
    position: 'absolute',
    width: 6,
    height: '100%',
    cursor: 'pointer',
}

const railInnerStyle = {
    position: 'absolute',
    width: 6,
    height: '100%',
    borderRadius: 6,
    pointerEvents: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    // 'rgba(101, 141, 255, 0.2)' /* MetService Bright Blue (20% Transparent) */,
}

export function SliderRail({ getRailProps }) {
    return (
        <>
            <div style={railOuterStyle} {...getRailProps()} />
            <div style={railInnerStyle} />
        </>
    )
}

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
    index,
    offset,
    domain: [min, max],
    handle: { id, value, percent },
    disabled,
    setActiveHandle,
    getHandleProps,
}) {
    function valueSetter(val) {
        return val === 0
            ? 'SFC'
            : Math.round(val) < 13500
            ? `${
                  Math.round(val) >= 10000
                      ? Math.round(val).toLocaleString('en')
                      : Math.round(val)
              } ft`
            : `FL${Math.round(val) / 100}`
    }

    function offsetHandler() {
        return offset && index === 1
    }

    function mouseUpHandler() {
        setActiveHandle(null)
        window.removeEventListener('mouseup', mouseUpHandler, true)
    }

    return (
        <>
            {/* Tooltips */}
            <div
                style={{
                    top: `${percent}%`,
                    position: 'absolute',
                    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                    zIndex: 5,
                    cursor: 'pointer',
                    backgroundColor: 'none',
                    transform: `translate(${
                        offsetHandler() ? '-75px' : '0px'
                    }, 0)`,
                }}
                {...getHandleProps(id, {
                    onMouseDown: () => {
                        setActiveHandle(id)
                        window.addEventListener('mouseup', mouseUpHandler, true)
                    },
                    onTouchStart: (e) => {
                        setActiveHandle(id)
                    },
                    onMouseUp: mouseUpHandler,
                    onTouchEnd: (e) => {
                        setActiveHandle(null)
                    },
                })}
                key={index}
            >
                <div
                    className="unselectable"
                    role="slider"
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={value}
                    style={{
                        top: `${percent}%`,
                        right: 25,
                        position: 'absolute',
                        transform: 'translate(0, -50%)',
                        zIndex: 2,
                        width: 'auto',
                        minWidth: 70,
                        height: 'auto',
                        cursor: 'pointer',
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 10,
                        paddingRight: 12,
                        borderRadius: 20,
                        boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 8px -3px',
                        color: '#566372',
                        backgroundColor: disabled
                            ? 'rgba(255, 255, 255, 0.25)'
                            : 'rgba(255, 255, 255, 1)',
                        // : '#577CE7' /* MetService Pressed Blue */,
                        textAlign: 'center',
                        fontSize: 11,
                        fontWeight: 600,
                        whiteSpace: 'pre',
                    }}
                >
                    {valueSetter(value)}
                    <div
                        className="pointer"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: -8.5,
                            transform: 'translate(-50%, -50%) rotate(45deg) ',
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            // : '#577CE7' /* MetService Pressed Blue */,
                            width: 12,
                            height: 12,
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export function MiniHandle({
    domain: [min, max],
    handle: { id, value, percent },
    disabled,
    activeHandle,
    setActiveHandle,
    getHandleProps,
}) {
    function mouseUpHandler() {
        setActiveHandle(null)
        window.removeEventListener('mouseup', mouseUpHandler, true)
    }

    const selected = id === activeHandle

    return (
        <>
            {/* Dots */}
            <div
                style={{
                    top: `${percent}%`,
                    left: '20px',
                    position: 'absolute',
                    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                    zIndex: 5,
                    cursor: 'pointer',
                    height: '40px',
                    width: '40px',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'none',
                }}
                {...getHandleProps(id, {
                    onMouseDown: () => {
                        setActiveHandle(id)
                        window.addEventListener('mouseup', mouseUpHandler, true)
                    },
                    onTouchStart: (e) => {
                        setActiveHandle(id)
                    },
                    onMouseUp: mouseUpHandler,
                    onTouchEnd: (e) => {
                        setActiveHandle(null)
                    },
                })}
            >
                <div
                    className="outside-circle"
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                        // backgroundColor: isMobileOnly
                        //     ? 'rgba(255, 255, 255, 0.15)'
                        //     : 'transparent',
                        width: selected ? 40 : 12,
                        height: selected ? 40 : 12,
                        transition: 'width 0.3s, height 0.3s',
                        borderRadius: '50%',
                    }}
                />
                <div
                    className="unselectable"
                    role="slider"
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={value}
                    style={{
                        top: `${percent}%` && '50%',
                        left: '50%',

                        position: 'absolute',
                        transform: selected
                            ? 'translate(-50%, -50%)'
                            : 'translate(-50%, -50%)',
                        transition: 'width 0.2s, height 0.3s',
                        zIndex: 2,
                        width: selected ? 15 : 12,
                        height: selected ? 15 : 12,
                        cursor: 'pointer',
                        borderRadius: 10,
                        boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 5px -2px',
                        backgroundColor: disabled
                            ? '#666'
                            : 'rgba(255, 255, 255, 1)',
                        // : '#577CE7' /* MetService Pressed Blue */,
                        textAlign: 'center',
                        fontSize: 11,
                        fontWeight: 500,
                    }}
                />
            </div>
        </>
    )
}

MiniHandle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

MiniHandle.defaultProps = {
    disabled: false,
}

MiniHandle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

MiniHandle.defaultProps = {
    disabled: false,
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
    return (
        <div
            style={{
                position: 'absolute',
                zIndex: 1,
                backgroundColor:
                    source.id === '$' || disabled
                        ? 'rgba(0, 0, 0, 0)'
                        : 'rgba(255, 255, 255, 0.75)',
                // : '#577CE7' /* MetService Pressed Blue */,
                borderRadius: 6,
                cursor: 'pointer',
                width: 6,
                top: `${source.percent}%`,
                height: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    )
}

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

Track.defaultProps = {
    disabled: false,
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, format, tickColor, tickTextShadow }) {
    return (
        <div>
            {/* <div
                style={{
                    position: 'absolute',
                    marginTop: -0.5,
                    marginLeft: -15,
                    height: 2,
                    width: 8,
                    backgroundColor: 'rgb(200,200,200)',
                    top: `${tick.percent}%`,
                }}
            /> */}
            <div
                className="unselectable"
                style={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    marginTop: -11,
                    fontSize: 10,
                    textAlign: 'right',
                    whiteSpace: 'nowrap',
                    top: `${tick.percent}%`,
                    right: 50,
                    color: tickColor,
                    textShadow: tickTextShadow,
                    fontWeight: 600,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    )
}

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
}

Tick.defaultProps = {
    format: (d) => d,
}
