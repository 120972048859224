import React, { useEffect } from 'react'
import { useSetRecoilState, useRecoilState } from 'recoil'
import {
    Flex,
    Text,
    Button,
    useBreakpointValue,
    Tooltip,
    ButtonGroup,
    Icon,
} from '@chakra-ui/react'
import { useUserMetaData } from '../../../../auth/hooks'
import {
    selectedBriefingAreasState,
    briefingCreatedState,
    briefingAreaSourceState,
    briefingStandardState,
    briefingCustomAreaState,
    clickedFeaturesState,
    dataModeState,
    briefingOptionsState,
    loadFavouriteState,
    loadingBriefingFavouriteState,
    excludedAerodromesState,
    excludedAwsState,
    layerSelectionHandler,
    fullscreenModalVisibleState,
} from '../../../../globalState'
import useConfirm from '../../alerts/warning-message/components/useConfirm'
import {
    ConfirmBriefOnlyWarningMessage,
    ConfirmDisableMapWarningMessage,
} from '../../alerts/warning-message/components/WarningMessages'
import { ReactComponent as Question } from '../../../../icons/assets/question.svg'
import SettingsToggle from './SettingsToggle'

export default function SettingsTab(props) {
    const {
        profileData,
        setSelectDefaultMapLocation,
        setSettingsTab,
        onResetHandler,
        setProfileData,
        userTier,
        isPremium,
        mfaExpired,
        isBriefingOnly,
        setIsBriefingOnly,
        onCloseHandler,
        mapDisabled,
        setMapDisabled,
        setMapDisabledUpdated,
    } = props
    const userMetaData = useUserMetaData()
    const setSelectedAreas = useSetRecoilState(selectedBriefingAreasState)
    const setBriefingCreated = useSetRecoilState(briefingCreatedState)
    const setBriefingAreaSource = useSetRecoilState(briefingAreaSourceState)
    const setBriefingStandard = useSetRecoilState(briefingStandardState)
    const setBriefingCustomArea = useSetRecoilState(briefingCustomAreaState)
    const setClickedFeatures = useSetRecoilState(clickedFeaturesState)
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const setBriefingOptions = useSetRecoilState(briefingOptionsState)
    const setLoadFavourite = useSetRecoilState(loadFavouriteState)
    const setLoadingBriefingFavourite = useSetRecoilState(
        loadingBriefingFavouriteState
    )
    const setLayerSelection = useSetRecoilState(layerSelectionHandler)
    const setExcludedAerodromes = useSetRecoilState(excludedAerodromesState)
    const setExcludedAws = useSetRecoilState(excludedAwsState)
    const [fullscreenModalVisible, setFullscreenModalVisible] = useRecoilState(
        fullscreenModalVisibleState
    )
    const { isConfirmed } = useConfirm() // alert message handler

    // This allows a user to enable/disable the zoom to behaviour when opening an aerodrome drawer
    const mapFlyToDisabled =
        (profileData?.profileSettings?.disable_mapzoom !== undefined
            ? profileData.profileSettings.disable_mapzoom
            : userMetaData?.profile_settings?.disable_mapzoom) || false

    // This allows a user to enable/disable the zoom to behaviour when opening an aerodrome drawer
    const drawerZoomToDisabled =
        (profileData?.profileSettings?.disable_mapzoom_drawer !== undefined
            ? profileData.profileSettings.disable_mapzoom_drawer
            : userMetaData?.profile_settings?.disable_mapzoom_drawer) || false

    // This allows a user to enable/disable the aerodrome INFO tab backgrounds
    const aerodromeBackgroundsDisabled =
        (profileData?.profileSettings?.disable_infotab_backgrounds !== undefined
            ? profileData.profileSettings.disable_infotab_backgrounds
            : userMetaData?.profile_settings?.disable_infotab_backgrounds) ||
        false

    // This allows a user to enable/disable the aerodrome INFO tab backgrounds
    const aerodromeGraphicsDisabled =
        (profileData?.profileSettings?.disable_infotab_graphics !== undefined
            ? profileData.profileSettings.disable_infotab_graphics
            : userMetaData?.profile_settings?.disable_infotab_graphics) || false

    // This allows a user to enable/disable decoded weather text as default (METAR, TAF, ATIS and AAW)
    const decodeDefault =
        isPremium || mfaExpired
            ? false
            : (profileData?.profileSettings?.decode_default !== undefined
                  ? profileData.profileSettings.decode_default
                  : userMetaData?.profile_settings?.decode_default) || false

    // This allows a user to enable/disable the Map to assist in performance issues
    const mapIsDisabled =
        (profileData?.profileSettings?.disable_map !== undefined
            ? profileData.profileSettings.disable_map
            : userMetaData?.profile_settings?.disable_map) || false

    // This allows a user to enable/disable AERO, MET and ADVS modes to assist in performance issues
    const briefModeOnly =
        (profileData?.profileSettings?.brief_mode_only !== undefined
            ? profileData.profileSettings.brief_mode_only
            : userMetaData?.profile_settings?.brief_mode_only) || false

    const locationSet =
        profileData &&
        profileData.profileSettings &&
        profileData.profileSettings.mapcenter

    useEffect(() => {
        if (mapIsDisabled) {
            setMapDisabled(true)
        } else if (!mapIsDisabled) {
            setMapDisabled(false)
        }
    }, [mapIsDisabled, setMapDisabled])

    useEffect(() => {
        if (briefModeOnly) {
            setIsBriefingOnly(true)
        } else if (!briefModeOnly) {
            setIsBriefingOnly(false)
        }
    }, [briefModeOnly, setIsBriefingOnly])

    const mapDisableCallback = async () => {
        const confirmDisable =
            !mapIsDisabled &&
            (await isConfirmed(
                // "Confirm Enable BRIEF Mode only? popup warning message"
                'confirm-disable',
                <ConfirmDisableMapWarningMessage />
            ))
        if (confirmDisable || mapIsDisabled) {
            setMapDisabledUpdated(true)
            setLoadingBriefingFavourite(false)
            setLoadFavourite(null)
            setClickedFeatures([])
            setBriefingAreaSource('briefingarea')
            setBriefingCreated(false)
            setBriefingStandard(false)
            setBriefingCustomArea(null)
            setSelectedAreas([])
            setExcludedAerodromes([])
            setExcludedAws([])
            if (dataMode === 'brief') {
                setBriefingOptions(true)
            } else if (
                !fullscreenModalVisible &&
                !mapIsDisabled &&
                dataMode === 'met'
            ) {
                setDataMode('aero')
            } else if (!fullscreenModalVisible && dataMode === 'met') {
                setLayerSelection({
                    mode: 'met',
                    layer: 'sigmetCharts',
                })
            } else if (mapIsDisabled) {
                setFullscreenModalVisible(false)
            }
            setProfileData((prevState) => {
                const profileSettings = prevState.profileSettings || {}
                const disable_map =
                    profileSettings.disable_map !== undefined
                        ? !profileSettings.disable_map
                        : true
                return {
                    ...prevState,
                    profileSettings: {
                        ...profileSettings,
                        disable_map,
                    },
                }
            })
        }
    }

    const briefModeOnlyCallback = async () => {
        const confirmDisable =
            !briefModeOnly &&
            (await isConfirmed(
                // "Confirm Enable BRIEF Mode only? popup warning message"
                'confirm-enable',
                <ConfirmBriefOnlyWarningMessage />
            ))
        if (confirmDisable || briefModeOnly) {
            setMapDisabledUpdated(true)
            setLoadingBriefingFavourite(false)
            setLoadFavourite(null)
            setClickedFeatures([])
            setBriefingAreaSource('briefingarea')
            setBriefingCreated(false)
            setBriefingStandard(false)
            setBriefingCustomArea(null)
            setSelectedAreas([])
            setExcludedAerodromes([])
            setExcludedAws([])
            if (dataMode === 'brief') {
                setBriefingOptions(true)
            } else if (!briefModeOnly && dataMode !== 'brief') {
                setDataMode('brief')
                setBriefingOptions(true)
            } else if (briefModeOnly) {
                setDataMode('aero')
                setBriefingOptions(false)
            }
            setProfileData((prevState) => {
                const profileSettings = prevState.profileSettings || {}
                const brief_mode_only =
                    profileSettings.brief_mode_only !== undefined
                        ? !profileSettings.brief_mode_only
                        : true
                return {
                    ...prevState,
                    profileSettings: {
                        ...profileSettings,
                        brief_mode_only,
                    },
                }
            })
        }
    }

    const questionButtonSize = useBreakpointValue({
        base: '1.2rem',
        md: '1.1rem',
    })

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    // TOGGLE SWITCH
    const toggleSwitch = {
        color: '#a8b2c0',
        variant: 'outline',
        fontSize: '10px',
        fontWeight: '600',
        height: '20px',
        width: '42px',
        style: {
            minWidth: '42px',
        },
        justifyContent: 'initial',
        paddingLeft: '2px',
        paddingRight: '2px',
        py: '2px',
        borderRadius: '20px',
        border: 'none',
        boxShadow: 'inset 0px 0px 3px -1px rgba(0,0,0,0.4)',
        _focus: {
            boxShadow: 'inset 0px 0px 3px -1px rgba(0,0,0,0.4)',
        },
        _active: {
            background: 'light.200',
            boxShadow: 'inset 0px 0px 3px -1px rgba(0,0,0,0.4)',
        },
    }

    const toggleCheck = {
        mt: '0px',
        pl: '2px',
        mr: '-18px',
        color: 'light.10',
        _selected: {
            opacity: '0.7',
        },
        _active: {
            opacity: '0.7',
        },
        transition: 'opacity 300ms',
    }

    const toggleIndicator = {
        mt: '0px',
        ml: '0px',
        mr: '0px',
        height: '16px',
        width: '18px',
        borderRadius: '20px',
        boxShadow: '0px 0px 3px -1px rgba(0,0,0,0.3)',
        transition: 'transform 300ms',
    }

    const toggleOnIcon = {
        zIndex: '1',
        mt: '1px',
        ml: '-1px',
        color: 'light.200',
        _selected: {
            opacity: '1',
        },
        _active: {
            opacity: '1',
        },
        transition: 'opacity all 300ms',
    }

    return (
        <Flex
            height="100%"
            flexDirection={{ base: 'column', lg: 'row' }}
            zIndex="2"
            position="relative"
            background="white"
        >
            <Flex
                width="100%"
                height="100%"
                minHeight="100%"
                justifyContent="space-between"
                flexDirection="column"
            >
                <Flex
                    pt={{
                        base: '25px',
                        md: '15px',
                    }}
                    pb={{
                        base: '35px',
                        md: '20px',
                    }}
                    px={{
                        base: '30px',
                        md: '20px',
                    }}
                    width="100%"
                    minHeight={{
                        base: 'auto',
                        lg: '270px',
                    }}
                    justifyContent="flex-start"
                    flexDirection="column"
                    gap={{ base: '30px', sm: '22px', md: '15px', lg: '7px' }}
                >
                    <Flex // Set Map Center Default
                        fontWeight="400"
                        color="light.80"
                        _hover={{
                            color: 'light.900',
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        fontSize={{
                            base: '0.8rem',
                            lg: '0.8rem',
                        }}
                        pt={{ base: 'unset', md: '5px' }}
                        mb={{
                            base: locationSet ? '10px' : '0px',
                            sm: locationSet ? '15px' : '5px',
                            md: locationSet ? '30px' : '10px',
                            lg: '5px',
                        }}
                        height="32px"
                    >
                        <Flex
                            justifyContent="flex-start"
                            alignItems="center"
                            lineHeight="1.4"
                            width={{
                                base: '100%',
                                sm: 'auto',
                            }}
                            height={{ base: 'auto', lg: '26px' }}
                        >
                            Set a default map location
                        </Flex>
                        <Tooltip
                            hasArrow
                            px="7px"
                            py="5px"
                            height="36px"
                            fontSize="0.7rem"
                            alignItems="center"
                            textAlign="center"
                            lineHeight="1.2"
                            display="flex"
                            whiteSpace="pre-line"
                            label={
                                'Set a favourite position for the map to center to when opening PreFlight.'
                            }
                            placement="top"
                            marginLeft="7px"
                            marginBottom="5px"
                            borderRadius="10px"
                            // openDelay={300}
                            bgColor={'gray.500'}
                        >
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                pl="8px"
                                color="gray.400"
                                opacity="0.8"
                            >
                                <Icon
                                    as={Question}
                                    width={questionButtonSize}
                                    height={questionButtonSize}
                                />
                            </Flex>
                        </Tooltip>
                        <Flex
                            my="auto"
                            ml="auto"
                            minWidth={locationSet ? '160px' : '82px'}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            flexDirection={{
                                base: 'column-reverse',
                                lg: 'row',
                            }}
                            gap={{ base: '0', lg: '10px' }}
                            zIndex="1"
                        >
                            {locationSet &&
                                !(isBriefingOnly || mapDisabled) && (
                                    <Text
                                        pt={{ base: '3px', lg: '5px' }}
                                        fontSize="0.65rem"
                                        color="gray.400"
                                        fontWeight="300"
                                        width={{
                                            base: '180px',
                                            sm: 'auto',
                                        }}
                                        height={{ base: 'auto', lg: '26px' }}
                                        whiteSpace="pre"
                                        textAlign="right"
                                    >
                                        You currently have a location set
                                    </Text>
                                )}
                            {(isBriefingOnly || mapDisabled) && (
                                <Text
                                    pt={{ base: '3px', lg: '4px' }}
                                    fontSize="0.65rem"
                                    color="gray.400"
                                    fontWeight="300"
                                    width={{
                                        base: '180px',
                                        sm: 'auto',
                                    }}
                                    height={{ base: 'auto', lg: '26px' }}
                                    whiteSpace="pre"
                                    textAlign="right"
                                >
                                    The map is currently disabled
                                </Text>
                            )}
                            <ButtonGroup spacing="1">
                                {locationSet && (
                                    <Button
                                        isDisabled={
                                            isBriefingOnly || mapDisabled
                                        }
                                        pr="6px"
                                        height={{ base: '30px', lg: '26px' }}
                                        fontSize="0.7rem"
                                        fontWeight="400"
                                        variant="outline"
                                        color="light.100"
                                        bg="#ffffff"
                                        size="xs"
                                        borderLeftRadius="20px"
                                        width="72px"
                                        onClick={() => {
                                            onResetHandler()
                                        }}
                                        {...noFocus}
                                        _hover={{
                                            background:
                                                !(
                                                    isBriefingOnly ||
                                                    mapDisabled
                                                ) && 'light.20',
                                        }}
                                        _active={{
                                            background:
                                                !(
                                                    isBriefingOnly ||
                                                    mapDisabled
                                                ) && 'light.20',
                                        }}
                                    >
                                        Remove
                                    </Button>
                                )}
                                <Button
                                    isDisabled={isBriefingOnly || mapDisabled}
                                    pl={locationSet ? '6px' : '2'}
                                    height={{ base: '30px', lg: '26px' }}
                                    fontSize="0.7rem"
                                    fontWeight="400"
                                    color="light.100"
                                    // isDisabled={selectedAreas.length < 1}
                                    width="72px"
                                    borderLeftRadius={
                                        locationSet ? '4px' : '20px'
                                    }
                                    borderRightRadius="20px"
                                    border="1px solid #ced3da"
                                    background={
                                        locationSet ? 'gray.100' : 'light.20'
                                    }
                                    _hover={{
                                        color:
                                            !(isBriefingOnly || mapDisabled) &&
                                            'light.200',
                                        background:
                                            !(isBriefingOnly || mapDisabled) &&
                                            locationSet
                                                ? 'gray.200'
                                                : 'light.30',
                                    }}
                                    _active={{
                                        color:
                                            !(isBriefingOnly || mapDisabled) &&
                                            'light.900',
                                        background:
                                            !(isBriefingOnly || mapDisabled) &&
                                            locationSet
                                                ? 'gray.200'
                                                : 'light.30',
                                    }}
                                    size="xs"
                                    onClick={() => {
                                        setSettingsTab(true)
                                        setSelectDefaultMapLocation(true)
                                    }}
                                    {...noFocus}
                                >
                                    {locationSet ? 'Change' : 'Set'}
                                </Button>
                            </ButtonGroup>
                        </Flex>
                    </Flex>

                    <SettingsToggle // Aerodrome/AWS/Webcams zoom behaviour when drawer opening/closing (excludes other clicked features)
                        profileData={profileData}
                        setProfileData={setProfileData}
                        userMetaData={userMetaData}
                        toggleActive={!drawerZoomToDisabled}
                        profileSettingsState={'disable_mapzoom_drawer'}
                        profileSettingText={`Zoom map when selecting an Aerodrome${
                            !isPremium || mfaExpired ? ', AWS, or Webcam' : ''
                        }`}
                        profileSettingSubText={null}
                        tooltipLabel={`Enable/disable map zooming into an Aerodrome${
                            !isPremium || mfaExpired ? ', AWS, or Webcam' : ''
                        } when selected. \nThe map will also zoom out to the previous zoom level after closing the popout drawer when selecting the close button only. \nEnabled as standard.`}
                        mfaExpired={mfaExpired}
                        isPremium={isPremium}
                        showPremiumBadge={false}
                        toggleSwitch={toggleSwitch}
                        toggleCheck={toggleCheck}
                        toggleIndicator={toggleIndicator}
                        toggleOnIcon={toggleOnIcon}
                        toggleColor={'light.200'}
                        toggleColorHover={'light.201'}
                    />

                    <SettingsToggle // FlyTo behaviour when selecting clicked feature (excludes Aerodrome, AWS, Webcams)
                        profileData={profileData}
                        setProfileData={setProfileData}
                        userMetaData={userMetaData}
                        toggleActive={!mapFlyToDisabled}
                        profileSettingsState={'disable_mapzoom'}
                        profileSettingText={`Zoom map when selecting a Weather Advisory or Enroute NOTAM layer item`}
                        tooltipLabel={`Enable/disable map zooming into a Weather Advisory or Enroute NOTAM layer item when selected. \nThe map will also zoom out to the previous zoom level after closing the modal window. \nEnabled as standard.`}
                        mfaExpired={mfaExpired}
                        isPremium={isPremium}
                        showPremiumBadge={false}
                        toggleSwitch={toggleSwitch}
                        toggleCheck={toggleCheck}
                        toggleIndicator={toggleIndicator}
                        toggleOnIcon={toggleOnIcon}
                        toggleColor={'light.200'}
                        toggleColorHover={'light.201'}
                    />

                    <SettingsToggle // Aerodrome INFO tab Information boxes
                        profileData={profileData}
                        setProfileData={setProfileData}
                        userMetaData={userMetaData}
                        toggleActive={!aerodromeGraphicsDisabled}
                        profileSettingsState={'disable_infotab_graphics'}
                        profileSettingText={`Aerodrome${
                            !isPremium || mfaExpired ? ' and AWS' : ' INFO tab'
                        } weather data graphics`}
                        profileSettingSubText={null}
                        tooltipLabel={`Enable/disable graphics for the weather data on the Aerodrome INFO tab${
                            !isPremium || mfaExpired ? ', and AWS' : ''
                        }. \nDisabling simplifies the layout of the information which may be preferred by some users. \nEnabled as standard.`}
                        mfaExpired={mfaExpired}
                        isPremium={isPremium}
                        showPremiumBadge={false}
                        toggleSwitch={toggleSwitch}
                        toggleCheck={toggleCheck}
                        toggleIndicator={toggleIndicator}
                        toggleOnIcon={toggleOnIcon}
                        toggleColor={'light.200'}
                        toggleColorHover={'light.201'}
                    />

                    <SettingsToggle // Aerodrome INFO tab backgrounds
                        profileData={profileData}
                        setProfileData={setProfileData}
                        userMetaData={userMetaData}
                        toggleActive={!aerodromeBackgroundsDisabled}
                        profileSettingsState={'disable_infotab_backgrounds'}
                        profileSettingText={
                            'Aerodrome INFO tab background imagery'
                        }
                        profileSettingSubText={null}
                        tooltipLabel={
                            'Enable/disable the current weather background imagery on the Aerodrome INFO tab \n(applicable only to aerodromes that report cloud conditions in a METAR). \nEnabled as standard.'
                        }
                        mfaExpired={mfaExpired}
                        isPremium={isPremium}
                        showPremiumBadge={false}
                        toggleSwitch={toggleSwitch}
                        toggleCheck={toggleCheck}
                        toggleIndicator={toggleIndicator}
                        toggleOnIcon={toggleOnIcon}
                        toggleColor={'light.200'}
                        toggleColorHover={'light.201'}
                    />

                    <SettingsToggle // Set decoded weather by default
                        profileData={profileData}
                        setProfileData={setProfileData}
                        userMetaData={userMetaData}
                        toggleActive={decodeDefault}
                        profileSettingsState={'decode_default'}
                        profileSettingText={
                            'Set decoded weather text as default'
                        }
                        profileSettingSubText={null}
                        tooltipLabel={
                            'Set decoded weather text as default for METAR, TAF, ATIS and AAW. \nDisabled as standard and for non-premium users.'
                        }
                        userTier={userTier}
                        mfaExpired={mfaExpired}
                        isPremium={isPremium}
                        showPremiumBadge={true}
                        toggleSwitch={toggleSwitch}
                        toggleCheck={toggleCheck}
                        toggleIndicator={toggleIndicator}
                        toggleOnIcon={toggleOnIcon}
                        onCloseHandler={onCloseHandler}
                        toggleColor={'light.200'}
                        toggleColorHover={'light.201'}
                    />
                </Flex>

                <Flex
                    pt={{
                        base: '20px',
                        md: '14px',
                    }}
                    pb={{
                        base: '50px',
                        md: '15px',
                    }}
                    px={{
                        base: '30px',
                        md: '20px',
                    }}
                    flexDirection="column"
                    width="100%"
                    minHeight={{
                        base: '150px',
                        md: '110px',
                    }}
                    justifyContent={{
                        base: 'flex-start',
                        md: 'center',
                    }}
                    alignItems="center"
                    borderTop="1px solid"
                    borderColor="#eaeaea"
                    gap={{ base: '30px', sm: '22px', md: '15px', lg: '7px' }}
                >
                    <SettingsToggle // Disable AERO, MET and ADVS modes (BRIEF MODE ONLY)
                        profileData={profileData}
                        setProfileData={setProfileData}
                        userMetaData={userMetaData}
                        toggleActive={mapDisabled}
                        profileSettingsState={'disable_map'}
                        profileSettingText={'Disable Map'}
                        profileSettingSubText={
                            '(disables map and all related functions)'
                        }
                        tooltipLabel={
                            'Enable/disable the map. \nDeactivating the map may be helpful for users encountering performance issues on certain devices, particularly older models or those with limited graphics capabilities.\n  This will not affect access to features that do not display information graphically on the map, or a comprehensive briefing from standard briefing areas.'
                        }
                        mfaExpired={mfaExpired}
                        isPremium={isPremium}
                        showPremiumBadge={false}
                        toggleSwitch={toggleSwitch}
                        toggleCheck={toggleCheck}
                        toggleIndicator={toggleIndicator}
                        toggleOnIcon={toggleOnIcon}
                        callbackFunction={mapDisableCallback}
                        toggleColor={'orange.300'}
                        toggleColorHover={'orange.400'}
                        isBriefingOnly={isBriefingOnly}
                    />

                    <SettingsToggle // Disable AERO, MET and ADVS modes (BRIEF MODE ONLY)
                        profileData={profileData}
                        setProfileData={setProfileData}
                        userMetaData={userMetaData}
                        toggleActive={briefModeOnly}
                        profileSettingsState={'brief_mode_only'}
                        profileSettingText={'Briefing Only Mode'}
                        profileSettingSubText={
                            '(disables AERO, MET and ADVS modes)'
                        }
                        tooltipLabel={
                            'Enable/disable AERO, MET and ADVS modes. \nDeactivating these modes may be helpful for users encountering performance issues on certain devices, particularly older models or those with limited graphics capabilities.\n  This will not affect access to a comprehensive briefing from standard briefing areas.'
                        }
                        mfaExpired={mfaExpired}
                        isPremium={isPremium}
                        showPremiumBadge={false}
                        toggleSwitch={toggleSwitch}
                        toggleCheck={toggleCheck}
                        toggleIndicator={toggleIndicator}
                        toggleOnIcon={toggleOnIcon}
                        callbackFunction={briefModeOnlyCallback}
                        toggleColor={'orange.300'}
                        toggleColorHover={'orange.400'}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
