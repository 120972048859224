import { config } from '../config'

export default function constructLayerSources(dynamicTimes) {
    const { collections, layers } = config
    const sources = {}
    collections.map((col) =>
        Object.keys(layers).map((l) =>
            layers[l]['collection'].map((layerCol) => {
                if (layerCol.id === col) {
                    if (layers[l]['sources']) {
                        if (layers[l]['sources'].includes('dynamic')) {
                            sources[col] = {
                                ...sources[col],
                                [l]: {
                                    sources: dynamicTimes.map(
                                        (time) => l + time
                                    ),
                                },
                            }
                        } else {
                            sources[col] = {
                                ...sources[col],
                                [l]: {
                                    sources: [l, ...layers[l]['sources']],
                                },
                            }
                        }
                    } else {
                        sources[col] = {
                            ...sources[col],
                            [l]: {
                                sources: [l],
                            },
                        }
                    }
                }
                return null
            })
        )
    )
    return sources
}
