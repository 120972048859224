import React from 'react'
import { useRecoilValue } from 'recoil'
import { useAdminRole } from '../../../auth/hooks'
import { showErrorTogglesState } from '../../../globalState'
import { Text, Flex, Switch } from '@chakra-ui/react'

export default function ErrorToggle({ showErrors, setShowErrors, label }) {
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const isAdmin = useAdminRole()
    return (
        <>
            {isAdmin && showErrorToggle && (
                <Flex justifyContent="flex-end">
                    <Flex
                        width="auto"
                        borderRadius="20px"
                        py="3px"
                        px="3px"
                        border="1px solid"
                        borderColor="#E5EAF1"
                        alignItems="center"
                    >
                        <Text
                            fontWeight="500"
                            pl="10px"
                            pr="10px"
                            fontSize={{ base: '10px', md: '0.7rem' }}
                            lineHeight="0.8"
                            color="#a1adba"
                        >
                            {label || 'Simulate Error Message'}
                        </Text>
                        <Switch
                            size="md"
                            isChecked={showErrors}
                            onChange={() =>
                                setShowErrors((prevState) => !prevState)
                            }
                            colorScheme="red"
                        />
                    </Flex>
                </Flex>
            )}
        </>
    )
}
