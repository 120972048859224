import React from 'react'
import { Badge } from '@chakra-ui/react'
import { trafficDecode } from '../../../util/notamDecode'

export default function Toast(props) {
    const { config } = props

    const traffic = config && config.traffic && trafficDecode(config.traffic)

    // console.log(config)

    return (
        <>
            <div
                className={`toast-item${
                    config.className ? ' ' + config.className : ''
                }${config.volcano && config.alerts ? ' volcano-alert' : ''}`}
                style={{
                    backgroundColor: `${config.colour}75
      `,
                }}
                id={config && config.id}
            >
                <div className="toast-frame">
                    <div className="toast-icon-container">
                        {config && config.icon}
                    </div>
                    {config &&
                        config.type === 'volcano' &&
                        config?.alertlevel && ( //  *** ALERT LEVEL TEXT FOR VAA ***
                            <div
                                style={{
                                    marginTop: config?.hasVAEruptionSigmet
                                        ? '2px'
                                        : '0px',
                                    position: 'absolute',
                                    width: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: 'white',
                                    fontSize: '9px',
                                    fontWeight: '600',
                                    letterSpacing: '-1px',
                                }}
                            >
                                {config.alertlevel}
                            </div>
                        )}
                    <div className="toast-text-container">
                        <h3 className="toast-text">
                            {config ? config.title : '...'}
                            {config &&
                                (config.severity || config.frequency) && (
                                    <Badge
                                        variant="solid"
                                        background="rgba(255,255,255,0.2)"
                                        color="white"
                                        paddingStart="2"
                                        paddingEnd="2"
                                        paddingTop="2px"
                                        paddingBottom="2px"
                                        borderRadius="xl"
                                        marginTop="-2px"
                                        marginRight="-1px"
                                        minWidth="40px"
                                        height="18px"
                                        textAlign="center"
                                        fontSize=".75em"
                                        fontWeight="700"
                                    >
                                        {config.severity === 'moderate' &&
                                            'MOD'}
                                        {config.severity === 'severe' && 'SEV'}
                                        {config.frequency && config.frequency}
                                    </Badge>
                                )}
                        </h3>
                        <div className="toast-text-description">
                            {config && config.className === 'sigmet-toast'
                                ? config && config.id
                                : config && config.description}
                        </div>
                        <div
                            className="hovertoast-divider"
                            style={{
                                borderColor: '#ffffff50',
                                borderWidth: 0.5,
                                borderStyle: 'solid',
                                width: 'auto',
                                paddingTop: 0,
                                marginTop: 6,
                                marginBottom: 6,
                            }}
                        />
                        {traffic && (
                            <div className="toast-text-traffic">
                                {traffic.ifr && (
                                    <Badge
                                        variant="solid"
                                        background="ifr.100"
                                        color="white"
                                        paddingStart="1"
                                        paddingEnd="1"
                                        paddingTop="1"
                                        paddingBottom="1"
                                        borderRadius="xl"
                                        marginTop="-2px"
                                        minWidth="35px"
                                        textAlign="center"
                                        fontSize=".8em"
                                        fontWeight="700"
                                        lineHeight="1"
                                    >
                                        IFR
                                    </Badge>
                                )}
                                {traffic.ifr && traffic.vfr ? ' ' : ''}
                                {traffic.vfr && (
                                    <Badge
                                        variant="solid"
                                        background="vfr.100"
                                        color="white"
                                        paddingStart="1"
                                        paddingEnd="1"
                                        paddingTop="1"
                                        paddingBottom="1"
                                        borderRadius="xl"
                                        marginTop="-2px"
                                        minWidth="35px"
                                        textAlign="center"
                                        fontSize=".8em"
                                        fontWeight="700"
                                        lineHeight="1"
                                    >
                                        VFR
                                    </Badge>
                                )}
                            </div>
                        )}

                        {config &&
                            (config.status ||
                                config.altitudes ||
                                config.level ||
                                config.movement ||
                                config.intensity) && (
                                <div className="toast-text-cont">
                                    {config && config.status && (
                                        <div className="toast-text-status">
                                            {config && config.status}
                                        </div>
                                    )}
                                    {config && config.altitudes && (
                                        <div className="toast-text-desc">
                                            {config && config.altitudes}
                                        </div>
                                    )}
                                    {config && config.level && (
                                        <div className="toast-text-desc">
                                            {config && config.level}
                                        </div>
                                    )}
                                    {config && config.level && (
                                        <div className="toast-text-desc">
                                            {config && config.movement}
                                        </div>
                                    )}
                                    {config && config.intensity && (
                                        <div className="toast-text-desc">
                                            {config && config.intensity}
                                        </div>
                                    )}
                                </div>
                            )}

                        {config && config.volcano && (
                            <div className="toast-text-volcano">
                                {config && config.volcano}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`toast-watermark-container${
                        config && config.title === 'CLEAR AIR TURBULENCE'
                            ? ' cat'
                            : ''
                    }`}
                >
                    {config && config.watermark}
                </div>
            </div>
            {config && config.volcano && config.alerts && (
                <div className="toast-text-volcano-alert">
                    {config && config.alerts}
                </div>
            )}
        </>
    )
}
