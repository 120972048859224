import React from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Text, Flex, Icon, Spinner } from '@chakra-ui/react'
import { isDesktop } from 'react-device-detect'
import { formatISO, parseISO } from 'date-fns'
import FullWidthError from '../../map/modals/components/FullWidthError'
import { ReactComponent as Notam } from '../../icons/notam-icons/notam-header.svg'
import { ReactComponent as NotamDrone } from '../../icons/notam-icons/notam-header-drone.svg'
import { ReactComponent as NotamObstacle } from '../../icons/notam-icons/notam-header-obstacle.svg'
import { ReactComponent as NotamAerodrome } from '../../icons/notam-icons/notam-header-aerodrome.svg'
import { ReactComponent as NotamWarning } from '../../icons/notam-icons/notam-header-warning.svg'
import { ReactComponent as NotamComms } from '../../icons/notam-icons/notam-header-comms.svg'
import { timeRangeInWords } from '../../util/dateFormatter'
import { code23Decode, code45Decode } from '../../util/notamDecode'
import {
    globalTimeState,
    selectedTimeState,
    clickedFeaturesState,
} from '../../globalState'

export default function NotamList(props) {
    const {
        data,
        status,
        listOpen,
        dataLoading,
        dataLoadingError,
        lowerAltitude,
        upperAltitude,
        selectedList,
    } = props

    const now = useRecoilValue(globalTimeState)
    const selectedTime = useRecoilValue(selectedTimeState)
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)

    function notamIconFormatter(feat) {
        return ['WU', 'WZ'].includes(feat.properties.code23)
            ? NotamDrone
            : ['OB', 'OL'].includes(feat.properties.code23)
            ? NotamObstacle
            : feat.properties.code23 === 'FA'
            ? NotamAerodrome
            : ['W', 'R'].includes(feat.properties.code23.charAt(0))
            ? NotamWarning
            : ['C', 'N'].includes(feat.properties.code23.charAt(0))
            ? NotamComms
            : Notam
    }

    if (
        !dataLoading &&
        !dataLoadingError &&
        data &&
        data.features &&
        data.features.length &&
        selectedList === 'notam'
    ) {
        return (
            <>
                {[...data.features]
                    .sort((a, b) => {
                        const supCenterA = a.properties.center[1]
                        const supCenterB = b.properties.center[1]
                        if (supCenterA < supCenterB) {
                            return 1
                        }
                        if (supCenterA > supCenterB) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                    .map((feat, i) => {
                        const outsideSelectedTime =
                            (feat?.properties?.startvalidity &&
                                formatISO(selectedTime) <
                                    formatISO(
                                        parseISO(feat.properties.startvalidity)
                                    )) ||
                            (feat?.properties?.endvalidity &&
                                formatISO(selectedTime) >
                                    formatISO(
                                        parseISO(feat.properties.endvalidity)
                                    ))

                        const outsideSelectedAltitudeRange =
                            lowerAltitude > feat?.properties?.upperfilter ||
                            upperAltitude < feat?.properties?.lowerfilter

                        const openModal =
                            feat.properties.id ===
                            clickedFeatures[0]?.properties?.id

                        return (
                            <Flex
                                key={`${feat.properties.id}`}
                                display={
                                    selectedList === 'notam' ? 'flex' : 'none'
                                }
                                bg={
                                    openModal
                                        ? 'white'
                                        : 'rgba(255,255,255,0.75)'
                                }
                                boxShadow={
                                    openModal
                                        ? '0px 8px 25px -7px rgba(0,15,35,0.35)'
                                        : outsideSelectedAltitudeRange ||
                                          outsideSelectedTime
                                        ? '0px 7px 25px -7px rgba(0,15,35,0.1)'
                                        : '0px 7px 25px -7px rgba(0,15,35,0.2)'
                                }
                                opacity={
                                    (clickedFeatures[0]?.properties?.id &&
                                        !openModal) ||
                                    outsideSelectedAltitudeRange ||
                                    outsideSelectedTime
                                        ? '0.5' //  if selected altitude range is outside notam levels
                                        : '1'
                                }
                                pointerEvents={
                                    outsideSelectedAltitudeRange ||
                                    outsideSelectedTime
                                        ? 'none' //  if selected altitude range is outside notam levels
                                        : 'auto'
                                }
                                transform={openModal && 'scale(1.03)'}
                                _hover={
                                    isDesktop &&
                                    !(
                                        outsideSelectedAltitudeRange ||
                                        outsideSelectedTime
                                    ) && {
                                        opacity: '1',
                                        cursor: 'pointer',
                                        background: 'rgba(255,255,255,1)',
                                        boxShadow:
                                            '0px 12px 30px -12px rgba(0,15,35,0.7)',
                                        transform:
                                            'translateY(-1px) translateX(0px) scale(1.01)',
                                    }
                                }
                                onClick={() => {
                                    if (
                                        !(
                                            outsideSelectedAltitudeRange ||
                                            outsideSelectedTime
                                        )
                                    ) {
                                        setClickedFeatures([feat])
                                    }
                                }}
                                borderRadius="20px"
                                pl="6px"
                                pr="10px"
                                transition="all ease 200ms"
                                mb={{
                                    base: '10px',
                                    md:
                                        isDesktop &&
                                        i + 1 === data.features.length
                                            ? '3px'
                                            : '10px',
                                }}
                                flexDirection="column"
                                width="100%"
                                zIndex={`calc(1000 - ${i})`}
                            >
                                <Flex
                                    my={'3px'}
                                    width="100%"
                                    flexDirection="row"
                                    minHeight="32px"
                                    alignItems="flex-start"
                                >
                                    <Flex alignItems="center" minHeight="36px">
                                        <Icon
                                            transition="all ease 200ms"
                                            bg={
                                                !outsideSelectedAltitudeRange
                                                    ? '#f08e26'
                                                    : 'gray.300'
                                            }
                                            color="white"
                                            borderRadius="100%"
                                            px="4px"
                                            boxSize="30px"
                                            alignItems="center"
                                            justifyContent="center"
                                            as={notamIconFormatter(feat)}
                                        />
                                    </Flex>
                                    <Flex
                                        pl="7px"
                                        lineHeight="1.5"
                                        minHeight="36px"
                                        width="100%"
                                        py={listOpen ? '1px' : '0'}
                                        flexDirection="column"
                                        color="light.100"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Flex
                                            width="100%"
                                            height="100%"
                                            flexDirection="row"
                                            alignItems="baseline"
                                            justifyContent="space-between"
                                            gap="5px"
                                        >
                                            <Flex
                                                width="100%"
                                                height="100%"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    whiteSpace="pre-line"
                                                    fontSize="0.75rem"
                                                    fontWeight="700"
                                                    lineHeight="1.2"
                                                    pt="1px"
                                                    textTransform="uppercase"
                                                >
                                                    {code23Decode(
                                                        feat.properties.code23
                                                    )}
                                                </Text>
                                            </Flex>
                                            {/* {outsideSelectedTime ? (
                                                <Text
                                                    fontSize="0.7rem"
                                                    fontWeight="600"
                                                    color="#c44d4d"
                                                    pr="4px"
                                                    whiteSpace="pre"
                                                >
                                                    Outside selected time
                                                </Text>
                                            ) : ( */}
                                            <Flex
                                                flexDirection="row"
                                                alignItems="center"
                                                color={'light.100'}
                                                pr="2px"
                                            >
                                                <Text
                                                    fontSize="0.75rem"
                                                    fontWeight="600"
                                                    whiteSpace="pre-line"
                                                    pr="2px"
                                                >
                                                    {`${feat.properties.series}${feat.properties.number}/${feat.properties.year}`}
                                                </Text>
                                            </Flex>
                                            {/* )} */}
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            height="100%"
                                            fontSize="0.7rem"
                                            fontWeight="400"
                                            flexDirection="row"
                                            alignItems="baseline"
                                            justifyContent="space-between"
                                            color="gray.500"
                                        >
                                            <Text
                                                whiteSpace="pre-line"
                                                fontWeight="500"
                                                lineHeight="1.2"
                                                pt="1px"
                                                textTransform="uppercase"
                                            >
                                                {code45Decode(
                                                    feat.properties.code45
                                                )}
                                            </Text>
                                            {feat?.properties?.lowerlabel &&
                                                feat?.properties
                                                    ?.upperlabel && (
                                                    <Flex
                                                        flexDirection="row"
                                                        fontWeight={
                                                            outsideSelectedAltitudeRange
                                                                ? '600'
                                                                : '400'
                                                        }
                                                        color={
                                                            outsideSelectedAltitudeRange
                                                                ? '#c44d4d'
                                                                : 'gray.500'
                                                        }
                                                    >
                                                        {outsideSelectedAltitudeRange ? (
                                                            <Text>
                                                                Outside altitude
                                                                filter range
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                <Text whiteSpace="pre-line">
                                                                    {feat
                                                                        .properties
                                                                        .lowerlabel &&
                                                                        `${
                                                                            feat.properties.lowerlabel.substring(
                                                                                0,
                                                                                2
                                                                            ) ===
                                                                                'FL' ||
                                                                            feat
                                                                                .properties
                                                                                .lowerlabel ===
                                                                                'SFC'
                                                                                ? feat
                                                                                      .properties
                                                                                      .lowerlabel
                                                                                : feat
                                                                                      .properties
                                                                                      .lowerlabel +
                                                                                  ' ft'
                                                                        }`}
                                                                </Text>
                                                                <Text px="3px">
                                                                    –
                                                                </Text>
                                                                <Text whiteSpace="pre-line">
                                                                    {feat
                                                                        .properties
                                                                        .upperlabel &&
                                                                        `${
                                                                            feat.properties.upperlabel.substring(
                                                                                0,
                                                                                2
                                                                            ) ===
                                                                            'FL'
                                                                                ? feat
                                                                                      .properties
                                                                                      .upperlabel
                                                                                : feat
                                                                                      .properties
                                                                                      .upperlabel +
                                                                                  ' ft'
                                                                        }`}
                                                                </Text>
                                                            </>
                                                        )}
                                                    </Flex>
                                                )}

                                            {feat.properties?.startvalidity &&
                                                feat.properties
                                                    ?.endvalidity && (
                                                    <Text
                                                        mr="4px"
                                                        whiteSpace="pre-line"
                                                        color="gray.500"
                                                    >
                                                        {(() => {
                                                            const status =
                                                                timeRangeInWords(
                                                                    now,
                                                                    feat
                                                                        .properties
                                                                        .startvalidity,
                                                                    feat
                                                                        .properties
                                                                        .endvalidity
                                                                )
                                                            let statusText
                                                            if (
                                                                status.startsWith(
                                                                    'ACTIVE'
                                                                )
                                                            ) {
                                                                statusText =
                                                                    'ACTIVE'
                                                            } else if (
                                                                status.startsWith(
                                                                    'Upcoming'
                                                                )
                                                            ) {
                                                                statusText =
                                                                    'UPCOMING'
                                                            } else {
                                                                statusText =
                                                                    status
                                                            }

                                                            return statusText
                                                        })()}
                                                    </Text>
                                                )}
                                            {feat?.properties?.permanent && (
                                                <Text
                                                    whiteSpace="pre-line"
                                                    mr="4px"
                                                >
                                                    PERMANENT
                                                </Text>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        )
                    })}
            </>
        )
    } else if (selectedList === 'notam') {
        return (
            <>
                {(status === 'loading' || dataLoading) && (
                    <Flex
                        w="100%"
                        pt="40px"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Spinner
                            color={'light.200'}
                            emptyColor="rgba(255,255,255,0.3)"
                            thickness="3px"
                            speed="0.45s"
                            boxSize="40px"
                        />
                    </Flex>
                )}
                {status !== 'loading' && status === 'error' && !dataLoading && (
                    <FullWidthError message={'Error fetching SIGWX data'} />
                )}
                {status === 'success' && !dataLoading && (
                    <Flex // NO DATA
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        pt="40px"
                        px="5px"
                        color="gray.600"
                        zIndex="1"
                        fontWeight="500"
                        fontSize={{
                            base: '0.8rem',
                            xs: '0.85rem',
                            md: '0.9rem',
                        }}
                        whiteSpace="pre-line"
                        textAlign="center"
                        lineHeight="1.2"
                    >
                        {data && data.features && data.features.length === 0 ? (
                            <Text>No current NOTAMs</Text>
                        ) : (
                            <Text>NO NOTAM information received</Text>
                        )}
                    </Flex>
                )}
            </>
        )
    }
}
