import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { parseISO, formatDistanceToNow } from 'date-fns'
import { TafIcon, ErrorIcon } from './assets/SvgImages'

export default function TAFExportPDF(props) {
    const {
        styles,
        timeZone,
        briefingData,
        showOutdatedErrors,
        aerodromesSort,
        excludedAerodromes,
    } = props

    const filteredAerodromes =
        briefingData &&
        briefingData.res &&
        briefingData.res.aerodromes &&
        briefingData.res.aerodromes.filter((filter) => filter.taf !== null)

    return (
        // TAF SECTION
        <View style={styles.section}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    wrap={false}
                    style={[
                        styles.sectionTitle,
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        },
                    ]}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <TafIcon
                            width="16"
                            height="16"
                            viewBox="0 0 48 48"
                            fill="rgb(23, 120, 183)"
                        />

                        <div
                            style={{
                                paddingLeft: '7',
                                color: 'rgb(23, 120, 183)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                textAlign: 'left',
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: '600',
                                }}
                            >
                                TAF
                            </Text>
                        </div>
                    </div>
                    <div
                        style={{
                            color: 'rgb(23, 120, 183)',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            textAlign: 'right',
                            paddingRight: 2,
                        }}
                    >
                        {timeZone !== 'UTC' && (
                            <Text
                                style={{
                                    fontWeight: '300',
                                    fontSize: '6pt',
                                    textAlign: 'right',
                                    textTransform: 'unset',
                                    color: 'rgb(111, 127, 145)',
                                }}
                            >
                                Time references are in UTC.
                            </Text>
                        )}
                        <Text
                            style={{
                                paddingLeft: 3,
                                fontWeight: '300',
                                fontSize: '6pt',
                                textAlign: 'right',
                                textTransform: 'unset',
                                color: 'rgb(111, 127, 145)',
                            }}
                        >
                            All heights are in feet above aerodrome level.
                        </Text>
                        <Text
                            style={{
                                paddingLeft: 3,
                                fontWeight: '300',
                                fontSize: '6pt',
                                textAlign: 'right',
                                textTransform: 'unset',
                                color: 'rgb(111, 127, 145)',
                            }}
                        >
                            Wind: °True
                        </Text>
                    </div>
                </div>

                {briefingData &&
                    briefingData.res &&
                    briefingData.res.aerodromes &&
                    briefingData.res.aerodromes
                        .filter((filter) => filter.taf !== null)
                        .sort((a, b) =>
                            aerodromesSort
                                ? a.name > b.name
                                : a.lat < b.lat
                                ? 1
                                : -1
                        )
                        .map((aerodrome) => {
                            if (
                                excludedAerodromes &&
                                excludedAerodromes.includes(
                                    aerodrome.designator
                                )
                            ) {
                                return null
                            } else {
                                return (
                                    <div
                                        key={`AerodromesTaf${aerodrome.designator}`}
                                    >
                                        <div /// MET INFORMATION
                                            style={[
                                                styles.sectionContent,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    borderBottom:
                                                        '1px solid #e5e5e5',
                                                },
                                            ]}
                                        >
                                            <div /// MET SECTION TITLE
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        borderRight:
                                                            '1px solid #e5e5e5',
                                                        flexGrow: '1',
                                                        width: '20%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems: 'center',
                                                        paddingTop: '2',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: 'rgb(111, 127, 145)',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            justifyContent:
                                                                'flex-start',
                                                            alignItems:
                                                                'flex-start',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    '700',
                                                                fontSize: '8pt',
                                                            }}
                                                        >
                                                            {
                                                                aerodrome.designator
                                                            }
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    '500',
                                                                fontSize: '7pt',
                                                            }}
                                                        >
                                                            {aerodrome.name}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                            <div /// TAF SECTION CONTENT
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexGrow: '10',
                                                        width: '80%',
                                                    },
                                                ]}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {(showOutdatedErrors ||
                                                        aerodrome.taf
                                                            .outofdate) && (
                                                        <div
                                                            wrap={false}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                width: '100%',
                                                                marginBottom:
                                                                    '5',
                                                            }}
                                                        >
                                                            <div
                                                                style={[
                                                                    styles.sectionContent,
                                                                    {
                                                                        width: '6%',
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    },
                                                                ]}
                                                            >
                                                                <ErrorIcon
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                    fill="rgb(16, 170, 112)"
                                                                />
                                                            </div>
                                                            <div
                                                                style={[
                                                                    styles.sectionContent,
                                                                    {
                                                                        width: '94%',
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'column',
                                                                    },
                                                                ]}
                                                            >
                                                                <Text
                                                                    style={
                                                                        styles.error
                                                                    }
                                                                >
                                                                    <span>
                                                                        There
                                                                        has been
                                                                        an issue
                                                                        with the
                                                                        connection
                                                                        between
                                                                        PreFlight
                                                                        and
                                                                        MetService.
                                                                        This{' '}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '500',
                                                                        }}
                                                                    >
                                                                        TAF
                                                                    </span>
                                                                    <span>
                                                                        {' '}
                                                                        was last
                                                                        validated
                                                                        with
                                                                        MetService
                                                                        more
                                                                        than{' '}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '500',
                                                                        }}
                                                                    >
                                                                        {formatDistanceToNow(
                                                                            parseISO(
                                                                                aerodrome
                                                                                    .taf
                                                                                    .updated
                                                                            ),
                                                                            {
                                                                                addSuffix: true,
                                                                            }
                                                                        )}
                                                                    </span>
                                                                    <span>
                                                                        . There
                                                                        is a
                                                                        high
                                                                        likelihood
                                                                        that the
                                                                        latest
                                                                        TAF
                                                                        information
                                                                        is not
                                                                        available
                                                                        in
                                                                        PreFlight.
                                                                        Check
                                                                        back
                                                                        again
                                                                        soon, or
                                                                        alternatively
                                                                        visit{' '}
                                                                    </span>
                                                                    <a
                                                                        style={{
                                                                            fontWeight:
                                                                                '400',
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                        href="https://ifis.airways.co.nz/"
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        IFIS
                                                                    </a>
                                                                    .
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            marginTop: '2',
                                                            marginBottom: '10',
                                                            display: 'flex',
                                                            flexGrow: '10',
                                                        }}
                                                    >
                                                        <Text
                                                            style={
                                                                styles.textNormal
                                                            }
                                                        >
                                                            {aerodrome.taf &&
                                                                aerodrome.taf
                                                                    .data}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}

                {filteredAerodromes.length > 0 &&
                    filteredAerodromes.filter(
                        (aerodrome) =>
                            !(
                                excludedAerodromes &&
                                excludedAerodromes.includes(
                                    aerodrome.designator
                                )
                            )
                    ).length === 0 && (
                        <div /// NO INFORMATION AVAILABLE
                            style={[
                                styles.sectionContent,
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderBottom: '1px solid #e5e5e5',
                                },
                            ]}
                        >
                            <div
                                style={[
                                    styles.boxCell,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexGrow: '10',
                                        width: '100%',
                                    },
                                ]}
                            >
                                <Text
                                    style={{
                                        fontWeight: '300',
                                        opacity: '0.6',
                                    }}
                                >
                                    All aerodromes with TAF have been excluded
                                    from the briefing.
                                </Text>
                            </div>
                        </div>
                    )}
            </div>
        </View>
    )
}
