import React from 'react'

import {
    Flex,
    Heading,
    List,
    ListIcon,
    ListItem,
    Text,
    VStack,
    Box,
} from '@chakra-ui/react'
import { GoCheck } from 'react-icons/go'
import { BsDash } from 'react-icons/bs'

import { ReactComponent as PremiumIcon } from '../icons/assets/premium-outline.svg'

const PricingCard = (props) => {
    const {
        data,
        included,
        button,
        colorTheme,
        isPremium,
        isFree,
        isMonthly,
        isCommercial,
    } = props
    const { features, comments, price, title, subtitle } = data

    return (
        <Box
            zIndex={isMonthly ? '2' : '1'}
            borderRadius="20px"
            mr={{ base: '0', lg: isFree && '-15px' }}
            ml={{ base: '0', lg: isCommercial && '-15px' }}
            bg="white"
            position="relative"
            px="0"
            overflow="hidden"
            shadow="2xl"
            maxW="md"
            width="100%"
            mt={{ base: '0', lg: isMonthly && '-65px' }}
            _hover={{
                transform: {
                    base: 'none',
                    lg: `translateY(-5px) translateX(${
                        isFree ? '-8px' : isCommercial ? '8px' : '0px'
                    }) scale(1.02)`,
                },
                boxShadow: {
                    base: '0px 35px 50px -25px rgba(0, 15, 35, 0.5)',
                    md: '0px 35px 50px -25px rgba(0, 15, 35, 0.5)',
                },
            }}
            transition="transform 0.25s ease-out 0s, box-shadow 0.25s ease-out 0s"
        >
            {isPremium && (
                <Flex
                    zIndex="20"
                    bg="brand.premium"
                    position="absolute"
                    right="-5rem"
                    top={5}
                    width="240px"
                    transform="rotate(38deg)"
                    py={1}
                    justifyContent="center"
                    alignItems="center"
                    boxShadow="48px 4px 15px -4px rgba(0,0,0,0.25)"
                >
                    <PremiumIcon color="white" width="18px" height="18px" />
                    <Text
                        fontSize="xs"
                        textTransform="uppercase"
                        fontWeight="bold"
                        letterSpacing="wider"
                        color="white"
                        ml="1px"
                        mr="5px"
                    >
                        PREMIUM
                    </Text>
                </Flex>
            )}

            <VStack
                minHeight="105px"
                borderBottom="1px solid #edeeef"
                boxShadow="0px 1px 15px -1px rgba(0,0,0,0.1)"
                clipPath="inset(0px 0px -50px 0px)"
            >
                <Heading
                    fontSize="2.4rem"
                    pt={{
                        base: '6',
                        md: '5',
                    }}
                    letterSpacing="wider"
                    fontWeight="700"
                    color={colorTheme}
                >
                    {title}
                </Heading>
                <Text
                    mt="5px !important"
                    fontWeight="500"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                    fontSize="1rem"
                    color="gray.400"
                >
                    {subtitle}
                </Text>
                {!isCommercial && (
                    <Text
                        pb={{
                            base: '5',
                            md: '4',
                        }}
                        mt="0px !important"
                        fontWeight="400"
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        fontSize="0.8rem"
                        color="gray.400"
                        opacity="0.8"
                    >
                        – NOT for commercial use –
                    </Text>
                )}
                {isCommercial && (
                    <Text
                        pb={{
                            base: '5',
                            md: '4',
                        }}
                        mt="0px !important"
                        fontWeight="400"
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        fontSize="0.8rem"
                        color="gray.400"
                        opacity="0.8"
                    >
                        – for commercial use –
                    </Text>
                )}
            </VStack>
            <Flex
                flexDirection="column"
                px="3"
                minHeight={{ base: '320px', lg: '350px', xl: '340px' }}
            >
                <Flex
                    align="flex-end"
                    justify="center"
                    fontWeight="extrabold"
                    color="light.100"
                    my="6"
                >
                    <Heading
                        fontSize="2.4rem"
                        fontWeight="inherit"
                        lineHeight="0.9em"
                        color="gray.600"
                        letterSpacing="wider"
                    >
                        {price}
                    </Heading>
                    {isMonthly && (
                        <Text
                            fontWeight="600"
                            fontSize="1.2rem"
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            color="gray.600"
                            opacity="0.4"
                            ml="5px"
                            lineHeight="1.3"
                        >
                            / month
                        </Text>
                    )}
                </Flex>
                <List
                    spacing="1"
                    mb="6"
                    maxW="34ch"
                    mx="auto"
                    color="#687686"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                >
                    {isMonthly && (
                        <Flex
                            alignContent={'flex-start'}
                            pl="3px"
                            pb="10px"
                            fontSize="0.9rem"
                        >
                            <Text fontWeight="400">
                                All{' '}
                                <span
                                    style={{
                                        fontWeight: '600',
                                        color: 'rgb(183, 197, 214)',
                                    }}
                                >
                                    {included}
                                </span>{' '}
                                plan features, and...
                            </Text>
                        </Flex>
                    )}
                    {isCommercial && (
                        <Flex
                            alignContent={'flex-start'}
                            pl="3px"
                            pb="10px"
                            fontSize="0.9rem"
                        >
                            <Text fontWeight="400" mx={isCommercial && '10px'}>
                                All{' '}
                                <span
                                    style={{
                                        fontWeight: '600',
                                        color: '#007DC8',
                                    }}
                                >
                                    {included}
                                </span>{' '}
                                plan features for COMMERCIAL use, and...
                            </Text>
                        </Flex>
                    )}
                    {features.map((feature, index) => (
                        <ListItem
                            fontWeight="300"
                            alignContent={'flex-start'}
                            key={index}
                            fontSize="0.8rem"
                            display="flex"
                            flexDirection="row"
                            mx={isCommercial && '10px'}
                        >
                            <ListIcon
                                fontSize="1.2rem"
                                as={GoCheck}
                                marginEnd={2}
                                color="light.200"
                            />
                            <Text lineHeight="1.5">{feature}</Text>
                        </ListItem>
                    ))}
                    {comments &&
                        isMonthly &&
                        comments.map((comment, index) => (
                            <ListItem
                                fontWeight="300"
                                alignContent={'flex-start'}
                                key={index}
                                fontSize="0.8rem"
                                display="flex"
                                flexDirection="row"
                                ml="23px"
                            >
                                <ListIcon
                                    fontSize="1.2rem"
                                    as={BsDash}
                                    marginEnd={1}
                                    color="#687686"
                                />
                                <Text lineHeight="1.5">{comment}</Text>
                            </ListItem>
                        ))}
                </List>
            </Flex>
            {button}
        </Box>
    )
}

export default PricingCard
