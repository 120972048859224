import React, { useCallback, useEffect } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { useTier } from '../../../../auth/hooks'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    LightMode,
    Flex,
    Text,
    Badge,
    List,
    ListItem,
    ListIcon,
    Box,
    ModalCloseButton,
    Tooltip,
} from '@chakra-ui/react'
import { GoCheck } from 'react-icons/go'
import { RiStarLine } from 'react-icons/ri'
import { ReactComponent as ExpiredIcon } from '../../../../icons/assets/expired.svg'
import { MfaPreviouslyVerified } from '../../../functions/MfaHandler'
import { isDesktop } from 'react-device-detect'
import {
    setModalVisibilityState,
    modalVisibilityState,
    settingsMenuOpenState,
    dataModeState,
    mfaVerifiedState,
} from '../../../../globalState'

export default function PremiumOnlyForwardedModal({ layers, setLayers }) {
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const modalsVisible = useRecoilValue(modalVisibilityState)
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const advsMode = dataMode === 'advs'
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const previouslyVerified = MfaPreviouslyVerified()
    const tier = useTier()
    const mfaExpired = tier > 1 && !mfaVerified

    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const onCloseHandler = useCallback(
        (value) => {
            if (advsMode) {
                setDataMode('aero')
            }
            setLayers(null)
        },
        [advsMode, setDataMode, setLayers]
    )

    useEffect(() => {
        setModalVisibility({
            id: 'premiumOnlyForwarded',
            value: !!layers.length,
        })
    }, [layers, setModalVisibility])

    const premiumBadge = (
        <Badge
            display="inline-flex"
            justifyContent="space-evenly"
            alignItems="center"
            ml="2px"
            mr="2px"
            mt="-1px"
            variant="solid"
            bg="rgba(0, 188, 248, 0.1)"
            color="#00B6F1"
            textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
            borderRadius="15"
            pl="7px"
            pr="9px"
            pb="0px"
            pt="0px"
            fontSize="14px"
            fontWeight="500"
        >
            <RiStarLine color="#00B6F1" fontSize="14px" />
            <Text ml="3px">Premium</Text>
        </Badge>
    )

    return (
        <LightMode>
            <Modal
                onClose={onCloseHandler}
                variant="premiumOnly"
                size="2xl"
                isCentered
                isOpen={modalsVisible.premiumOnlyForwarded}
                motionPreset="slideInBottom"
                scrollBehavior="inside"
                closeOnOverlayClick={false}
                trapFocus={false}
                blockScrollOnMount={false}
            >
                <ModalOverlay />
                <ModalContent
                    maxHeight="calc(100% - 3rem)"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                >
                    <ModalCloseButton color="light.100" zIndex="11" />
                    <ModalHeader
                        textAlign="center"
                        background="light.10"
                        color="light.100"
                        fontSize="1rem"
                        fontWeight="600"
                        letterSpacing="0.5px"
                        boxShadow={'0px 3px 15px -1px rgba(0,0,0,0.15)'}
                        borderBottom="1px solid #eaeaea"
                        zIndex="10"
                        minHeight="56px"
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                    >
                        <Text>PREMIUM FEATURE</Text>
                    </ModalHeader>
                    <ModalBody
                        as="section"
                        bg="gray.50"
                        pt="40px"
                        pb="55px"
                        px={{
                            base: '4',
                            lg: '8',
                        }}
                    >
                        <Flex
                            width="100%"
                            marginLeft="auto"
                            marginRight="auto"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                textAlign="center"
                                fontSize="15px"
                                lineHeight="1.8"
                                pb="20px"
                            >
                                You are trying to access{' '}
                                {layers && layers.length > 1 ? '' : 'the '}
                                <span
                                    style={{
                                        textTransform: 'uppercase',
                                        fontWeight: '600',
                                    }}
                                >
                                    {layers.join(', ') + ' '}
                                </span>
                                {layers && layers.length > 1
                                    ? 'layers that are only available to'
                                    : 'layer that is only available to'}
                                <br />
                                {premiumBadge} users only
                            </Box>
                            <List
                                spacing="1"
                                mb="5px"
                                maxW="34ch"
                                mx="auto"
                                fontSize="14px"
                                fontWeight="300"
                                color="#687686"
                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                            >
                                <ListItem
                                    alignContent={'flex-start'}
                                    display="flex"
                                    flexDirection="row"
                                >
                                    <ListIcon
                                        fontSize="1.2rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="light.200"
                                    />
                                    <Text lineHeight="1.5">
                                        View En-route NOTAMs graphically on the
                                        map
                                    </Text>
                                </ListItem>
                                {/* <ListItem
                                    alignContent={'flex-start'}
                                    display="flex"
                                    flexDirection="row"
                                >
                                    <ListIcon
                                        fontSize="1.2rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="light.200"
                                    />
                                    <Text lineHeight="1.5">
                                        View RPAS flights logged in Airshare
                                    </Text>
                                </ListItem> */}
                                <ListItem
                                    alignContent={'flex-start'}
                                    display="flex"
                                    flexDirection="row"
                                >
                                    <ListIcon
                                        fontSize="1.2rem"
                                        as={GoCheck}
                                        marginEnd={2}
                                        color="light.200"
                                    />
                                    <Text lineHeight="1.5">
                                        View SIGMET and SIGWX graphically
                                        alongside other dynamic information
                                    </Text>
                                </ListItem>
                            </List>
                            <Flex
                                mt="30px"
                                direction={'column'}
                                gap="15px"
                                alignItems="center"
                                textAlign="center"
                            >
                                {mfaExpired ? (
                                    <Tooltip
                                        hasArrow
                                        px="7px"
                                        py="5px"
                                        fontSize="0.7rem"
                                        alignItems="center"
                                        textAlign="center"
                                        lineHeight="1.2"
                                        display={isDesktop ? 'flex' : 'none'}
                                        whiteSpace="pre-line"
                                        label={`${
                                            !previouslyVerified
                                                ? 'Set'
                                                : 'Reset'
                                        } your Multi-factor Authentication`}
                                        placement="top"
                                        marginBottom="5px"
                                        borderRadius="10px"
                                        maxWidth="350px"
                                        bgColor={'gray.500'}
                                    >
                                        <Button
                                            {...noFocus}
                                            display="flex"
                                            justifyContent="space-evenly"
                                            alignItems="center"
                                            variant="solid"
                                            bg="brand.premium"
                                            color="#FFFFFF"
                                            textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                            borderRadius="25"
                                            pl={mfaExpired ? '12px' : '15px'}
                                            pr="17px"
                                            height={{
                                                base: '36px',
                                                md: '32px',
                                            }}
                                            width={'130px'}
                                            fontSize="0.9rem"
                                            fontWeight="400"
                                            _hover={{
                                                bg: 'brand.premiumHover',
                                            }}
                                            _active={{
                                                bg: 'brand.premiumHover',
                                            }}
                                            onClick={() => {
                                                setShowResetMfaModal(true)
                                            }}
                                        >
                                            <ExpiredIcon
                                                color="#FFFFFF"
                                                height="16px"
                                                width="16px"
                                            />

                                            {previouslyVerified
                                                ? 'RESET MFA'
                                                : 'SET MFA'}
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        {...noFocus}
                                        display="inline-flex"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        variant="solid"
                                        bg="brand.premium"
                                        color="#FFFFFF"
                                        textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                        borderRadius="50px"
                                        pl="15px"
                                        pr="17px"
                                        height={{
                                            base: '36px',
                                            md: '32px',
                                        }}
                                        fontSize="0.9rem"
                                        fontWeight="400"
                                        _hover={{
                                            bg: 'brand.premiumHover',
                                        }}
                                        _active={{
                                            bg: 'brand.premiumHover',
                                        }}
                                        onClick={() => {
                                            setLayers(null)
                                            setShowPricingTiersModal(true)
                                            setSettingsMenuOpen(false)
                                        }}
                                    >
                                        <RiStarLine
                                            color="#FFFFFF"
                                            fontSize="16px"
                                        />
                                        <Text ml="3px">Upgrade to Premium</Text>
                                    </Button>
                                )}
                                <Button
                                    variant="ghost"
                                    onClick={() => onCloseHandler()}
                                    mt="5px"
                                    fontSize="0.9rem"
                                    height={{
                                        base: '36px',
                                        md: '32px',
                                    }}
                                    fontWeight="400"
                                    borderRadius="50px"
                                >
                                    Continue using the app
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </LightMode>
    )
}
