// READ THIS FIRST
// The functionality of the map changing between light/dark mode has been disabled for now
// To re-enable, uncomment the imports at the top, remove the live handleTransition from the useEffect, and uncomment the other actions in the useEffect
import { useCallback, useContext, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useColorMode, useColorModeValue } from '@chakra-ui/react'
import { MapContext } from 'react-mapbox-gl'
import { lightLayers } from '../basemap-definitions/light'
// import { darkLayers } from '../basemap-definitions/dark'
import {
    basemapState,
    basemapEnhanceState,
    displayRoadsState,
} from '../../globalState'

export default function MapColorModeHandler() {
    const { colorMode } = useColorMode()
    const mapInstance = useContext(MapContext)
    const basemap = useRecoilValue(basemapState)
    const basemapEnhance = useRecoilValue(basemapEnhanceState)
    const displayRoads = useRecoilValue(displayRoadsState)

    const waterDepthColor = useColorModeValue(
        [
            'interpolate',
            ['linear'],
            ['zoom'],
            4,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(202, 56%, 70%, 0.35)',
                200,
                'hsla(202, 56%, 63%, 0.35)',
                7000,
                'hsla(202, 56%, 56%, 0.35)',
            ],
            8,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(202, 56%, 70%, 0.25)',
                200,
                'hsla(202, 56%, 63%, 0.25)',
                7000,
                'hsla(202, 56%, 53%, 0.25)',
            ],
            9,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(202, 56%, 70%, 0.15)',
                200,
                'hsla(202, 56%, 63%, 0.15)',
                7000,
                'hsla(202, 56%, 53%, 0.15)',
            ],
            10,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(202, 56%, 70%, 0.1)',
                200,
                'hsla(202, 56%, 63%, 0.1)',
                7000,
                'hsla(202, 56%, 53%, 0.1)',
            ],
            11,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(202, 56%, 70%, 0)',
                200,
                'hsla(202, 56%, 63%, 0)',
                7000,
                'hsla(202, 56%, 53%, 0)',
            ],
        ],
        [
            'interpolate',
            ['linear'],
            ['zoom'],
            4,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(207, 29%, 18%, 0.25)',
                200,
                'hsla(207, 29%, 15%, 0.2)',
                7000,
                'hsla(207, 29%, 10%, 0.15)',
            ],
            8,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(207, 29%, 18%, 0.2)',
                200,
                'hsla(207, 29%, 15%, 0.15)',
                7000,
                'hsla(207, 29%, 10%, 0.1)',
            ],
            9,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(207, 29%, 18%, 0.15)',
                200,
                'hsla(207, 29%, 15%, 0.1)',
                7000,
                'hsla(207, 29%, 10%, 0.05)',
            ],
            10,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(207, 29%, 18%, 0.1)',
                200,
                'hsla(207, 29%, 15%, 0.05)',
                7000,
                'hsla(207, 29%, 10%, 0)',
            ],
            11,
            [
                'interpolate',
                ['linear'],
                ['get', 'min_depth'],
                0,
                'hsla(207, 29%, 18%, 0)',
                200,
                'hsla(207, 29%, 15%, 0)',
                7000,
                'hsla(207, 29%, 10%, 0)',
            ],
        ]
    )

    // this handleTransition function maps through the various layout / paint properties of the standard mapbox light or dark maps and sets the properties accordingly
    // this strategy likely only works between comparitive maps (ie light / dark), and propbably wouldn't work with more complex map changes (ie the light style to the outdoors style)
    const handleTransition = useCallback(
        (layers) => {
            // set layout properties
            layers.forEach((layer) => {
                const { id, type, layout = {}, paint = {}, filter } = layer
                Object.keys(layout).forEach((prop) => {
                    mapInstance.setLayoutProperty(id, prop, layout[prop])
                })

                // set paint properties
                Object.keys(paint).forEach((prop) => {
                    if (
                        id.startsWith('road') ||
                        id.startsWith('street') ||
                        id.startsWith('tunnel') ||
                        id.startsWith('bridge')
                    ) {
                        // Set opacity based on the layer type
                        const roadOpacityDefinition =
                            basemap === 'satellite'
                                ? displayRoads
                                    ? [
                                          'interpolate',
                                          ['exponential', 1.5],
                                          ['zoom'],
                                          2,
                                          0,
                                          6,
                                          0,
                                          6.5,
                                          0.2,
                                          7,
                                          0.4,
                                          8,
                                          0.6,
                                          10,
                                          0.7,
                                          12,
                                          0.8,
                                          13,
                                          0.9,
                                          14,
                                          1,
                                      ]
                                    : 0
                                : [
                                      'interpolate',
                                      ['exponential', 1.5],
                                      ['zoom'],
                                      5,
                                      0,
                                      7,
                                      1,
                                  ]

                        if (type === 'line') {
                            mapInstance.setPaintProperty(
                                id,
                                'line-opacity',
                                roadOpacityDefinition
                            )
                        } else if (type === 'fill') {
                            mapInstance.setPaintProperty(
                                id,
                                'fill-opacity',
                                roadOpacityDefinition
                            )
                        }
                    }

                    // set water layer color based on basemap
                    else if (id === 'water' && prop === 'fill-color') {
                        const fillColor =
                            basemap === 'vnc' && basemapEnhance
                                ? 'hsl(204, 67%, 93%)'
                                : basemap === 'vnc'
                                ? 'hsl(204, 33%, 93%)'
                                : basemap === 'linztopo' && basemapEnhance
                                ? 'hsl(206, 52%, 89%)'
                                : basemap === 'linztopo'
                                ? 'hsl(204, 23%, 89%)'
                                : 'hsl(202, 60%, 80%)'
                        mapInstance.setPaintProperty(
                            id,
                            'fill-color',
                            fillColor
                        )
                        // set water-depth layer fill-color based on colorMode
                    } else if (
                        id === 'water-depth' &&
                        prop === 'fill-color' &&
                        basemap === 'satellite'
                    ) {
                        mapInstance.setPaintProperty(
                            id,
                            'fill-color',
                            waterDepthColor
                        )
                        // set water-depth layer opacity based on basemap
                    } else if (
                        id === 'water-depth' &&
                        prop === 'fill-opacity'
                    ) {
                        const waterDepthOpacity = ['vnc', 'linztopo'].includes(
                            basemap
                        )
                            ? 0
                            : [
                                  'interpolate',
                                  ['exponential', 1.5],
                                  ['zoom'],
                                  2,
                                  0.3,
                                  7,
                                  0.3,
                                  8,
                                  0.25,
                                  9,
                                  0.15,
                                  10,
                                  0.05,
                                  11,
                                  0,
                              ]
                        mapInstance.setPaintProperty(
                            id,
                            'fill-opacity',
                            waterDepthOpacity
                        )
                        // else just apply the config
                    } else {
                        mapInstance.setPaintProperty(id, prop, paint[prop])
                    }
                })

                // set filters
                if (filter) {
                    mapInstance.setFilter(id, filter)
                }
            })
        },
        [mapInstance, waterDepthColor, basemap, basemapEnhance, displayRoads]
    )

    // the following useEffect uses the imported default mapbox light / dark styles, and handles the transition between them
    useEffect(() => {
        // if (colorMode === 'light') {
        //     handleTransition(lightLayers)
        // }
        // if (colorMode === 'dark') {
        //     handleTransition(darkLayers)
        // }
        if (lightLayers && lightLayers.length > 0) {
            handleTransition(lightLayers, basemap)
        } else {
            console.log('lightLayers are not available')
        }
    }, [colorMode, basemap, handleTransition])

    return null
}
