import React, { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    Flex,
    Box,
    Text,
    Divider,
    Button,
    Icon,
    Collapse,
    Tooltip,
    ButtonGroup,
} from '@chakra-ui/react'
import { useTier } from '../../../../../auth/hooks'
import { isMobile, isDesktop } from 'react-device-detect'
import useConfirm from '../../../alerts/warning-message/components/useConfirm'
import {
    AltExcludedAerodromesWarningMessage,
    ExcludedAWSWarningMessage,
} from '../../../alerts/warning-message/components/WarningMessages'
import { useFilteredDesignators } from '../../../../../util/filterDesignators'
import {
    excludedAerodromesState,
    excludedAwsState,
    excludedItemsShowState,
    simulateFreeUserState,
    mfaVerifiedState,
    excludedAerodromesWarningMessageState,
    excludedAwsWarningMessageState,
    allAerodromesDesignatorsState,
    allAwsDesignatorsState,
    showManageFavouritesState,
} from '../../../../../globalState'
import { GrRedo } from 'react-icons/gr'
import { IoClose } from 'react-icons/io5'
import { MdOutlineExpandLess } from 'react-icons/md'
import { TbPlus } from 'react-icons/tb'

// EXCLUDED ITEMS
export default function ExcludedItems({
    briefingData,
    isBriefingOnly,
    viewportHeight,
}) {
    const excludedItemsShow = useRecoilValue(excludedItemsShowState)
    const [excludedAerodromes, setExcludedAerodromes] = useRecoilState(
        excludedAerodromesState
    )
    const [excludedAws, setExcludedAws] = useRecoilState(excludedAwsState)
    const [selectAerodromes, setSelectAerodromes] = useState(false)
    const [selectAws, setSelectAws] = useState(false)
    const showManageFavourites = useRecoilValue(showManageFavouritesState)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    useEffect(() => {
        if (!excludedItemsShow) {
            setSelectAerodromes(false)
            setSelectAws(false)
        }
    }, [excludedItemsShow])

    const allAerodromesDesignators = useRecoilValue(
        allAerodromesDesignatorsState
    )
    const allAwsDesignators = useRecoilValue(allAwsDesignatorsState)
    const {
        // filteredDesignators: erroneousAerodromes,
        filteredExcludedDesignators: filteredExcludedAerodromes,
    } = useFilteredDesignators(allAerodromesDesignators, excludedAerodromes)

    const {
        // filteredDesignators: erroneousAws,
        filteredExcludedDesignators: filteredExcludedAws,
    } = useFilteredDesignators(allAwsDesignators, excludedAws)

    const includedAerodromes =
        briefingData?.res?.aerodromes &&
        briefingData.res.aerodromes
            .map((i) => i.designator)
            .filter(
                (aerodrome) => !filteredExcludedAerodromes.includes(aerodrome)
            )
            .sort((a, b) => {
                if (a < b) {
                    return -1
                }
                if (a > b) {
                    return 1
                } else {
                    return 0
                }
            })

    const includedAws =
        briefingData?.res?.aws &&
        briefingData.res.aws
            .map((i) => i.designator)
            .filter((aws) => !filteredExcludedAws.includes(aws))
            .sort((a, b) => {
                if (a < b) {
                    return -1
                }
                if (a > b) {
                    return 1
                } else {
                    return 0
                }
            })

    const noExcludedItems =
        filteredExcludedAerodromes &&
        filteredExcludedAerodromes.length === 0 &&
        (!isPremium
            ? filteredExcludedAws && filteredExcludedAws.length === 0
            : null)

    const noIncludedItems =
        includedAerodromes &&
        includedAerodromes.length === 0 &&
        (!isPremium ? includedAws && includedAws.length === 0 : null)

    return (
        <Box // DROPDOWN DRAWER TO STORE EXCLUDED ITEMS
            width="100%"
            opacity={excludedItemsShow ? '1' : '0'}
            pointerEvents={excludedItemsShow ? 'auto' : 'none'}
            visibility={excludedItemsShow ? 'visible' : 'hidden'}
            transition="all 300ms"
            transitionDelay={excludedItemsShow ? '200ms' : '0s'}
            zIndex="3"
            overflowY={{ base: 'auto', md: 'hidden' }}
            maxHeight={{
                base: isBriefingOnly
                    ? `calc(${viewportHeight}px - 170px)`
                    : `calc(${viewportHeight}px - 200px)`,
                md: 'max-content',
            }}
            bg={'#F8fafd'}
        >
            <Flex // EXCLUDED AERODROMES
                width="100%"
                display="flex"
                flexDirection="column"
                pt={{
                    base: excludedItemsShow ? '5px' : '0px',
                    md: '0px',
                }}
                pb={{
                    base: excludedItemsShow ? '5px' : '0px',
                    md: '0px',
                }}
            >
                <Flex
                    width="100%"
                    alignItems="flex-start"
                    flexDirection={{
                        base: 'column',
                        smd: isPremium ? 'row' : 'column',
                        md: 'column',
                        lg: 'row',
                    }}
                    justifyContent="space-between"
                    px={'20px'}
                    marginTop={{
                        base: '10px',
                        md: '0px',
                    }}
                    marginBottom={
                        showManageFavourites
                            ? '4px'
                            : {
                                  base: '4px',
                                  smd: isPremium ? '0px' : '4px',
                                  md: '4px',
                                  lg: '0px',
                              }
                    }
                    minHeight={{
                        base: '52px',
                        sm: '52px',
                    }}
                    gap={{
                        base: '10px',
                        smd: isPremium ? '5px' : '10px',
                        md: '10px',
                        lg: '5px',
                    }}
                >
                    <Flex
                        width="100%"
                        alignItems="start"
                        flexDirection="column"
                        justifyContent="flex-start"
                        flexWrap="wrap"
                        minHeight={{
                            base: '50px',
                            sm: '48px',
                        }}
                    >
                        <Text
                            alignSelf="left"
                            fontSize={{
                                base: '0.8rem',
                                xs: '0.85rem',
                                sm: '0.95rem',
                                smd: '1rem',
                            }}
                            color="gray.500"
                            fontWeight="700"
                            lineHeight="1.5"
                            pb="2px"
                            transition="all ease 300ms"
                        >
                            {`Excluded Aerodromes ${
                                isPremium
                                    ? ''
                                    : 'and Automatic Weather Stations'
                            }`}
                        </Text>

                        <Text
                            transition="all ease 300ms"
                            textAlign={'left'}
                            fontSize={{
                                base: '0.75rem',
                                smd: '0.8rem',
                            }}
                            color="gray.500"
                            fontWeight="300"
                            lineHeight="1.2"
                            whiteSpace="pre-line"
                        >
                            {noExcludedItems
                                ? `Select Aerodromes ${
                                      isPremium
                                          ? ''
                                          : 'or Automatic Weather Stations '
                                  }to exclude from your briefing`
                                : selectAerodromes || selectAws
                                ? `Select Aerodromes ${
                                      isPremium
                                          ? ''
                                          : 'or Automatic Weather Stations '
                                  }below to reinstate or exclude in your briefing`
                                : `Select excluded Aerodromes ${
                                      isPremium
                                          ? ''
                                          : 'or Automatic Weather Stations '
                                  }below to reinstate into your briefing`}
                        </Text>
                    </Flex>

                    <Flex
                        width={{
                            base: '100%',
                            smd: isPremium ? 'auto' : '100%',
                            md: '100%',
                            lg: 'auto',
                        }}
                        alignItems="flex-start"
                        justifyContent={{
                            base: 'center',
                            smd: 'flex-end',
                        }}
                    >
                        <ButtonGroup marginTop={'5px'} isAttached>
                            <Tooltip
                                label={
                                    'Reinstate all Aerodromes and AWS into your briefing'
                                }
                                hasArrow
                                borderRadius="7px"
                                height="26px"
                                fontSize="0.7rem"
                                alignItems="center"
                                display={
                                    isMobile || !isDesktop ? 'none' : 'flex'
                                }
                                isDisabled={isMobile || !isDesktop}
                                placement="top"
                                m="5px"
                                mr="2px"
                                color="light.10"
                                bg="dark.10"
                            >
                                <Button
                                    isDisabled={noExcludedItems}
                                    color="gray.600"
                                    transition="all ease 300ms"
                                    fontSize="0.8rem"
                                    fontWeight="600"
                                    height={{
                                        base: '36px',
                                        sm: '34px',
                                    }}
                                    minWidth={{
                                        base: '140px',
                                        md: '140px',
                                    }}
                                    borderRadius="20px"
                                    onClick={() => {
                                        setExcludedAerodromes([])
                                        setExcludedAws([])
                                    }}
                                    borderColor="#DFE6EE"
                                    background="light.10"
                                    variant="outline"
                                    width="auto"
                                    justifyContent="center"
                                    display="flex"
                                    alignItems="center"
                                    _hover={{
                                        background: 'light.30',
                                        color: 'light.100',
                                    }}
                                    _active={{
                                        background: 'light.30',
                                    }}
                                >
                                    <Icon as={GrRedo} fontSize="1.2rem" />
                                    <Text pl="5px" pr="3px">
                                        Reinstate All
                                    </Text>
                                </Button>
                            </Tooltip>
                            <Tooltip
                                label={
                                    'Exclude all Aerodromes and AWS from your briefing'
                                }
                                hasArrow
                                borderRadius="7px"
                                height="26px"
                                fontSize="0.7rem"
                                alignItems="center"
                                display={
                                    isMobile || !isDesktop ? 'none' : 'flex'
                                }
                                isDisabled={isMobile || !isDesktop}
                                placement="top"
                                m="5px"
                                mr="2px"
                                color="light.10"
                                bg="dark.10"
                            >
                                <Button
                                    isDisabled={noIncludedItems}
                                    color="gray.600"
                                    transition="all ease 300ms"
                                    fontSize="0.8rem"
                                    fontWeight="600"
                                    height={{
                                        base: '36px',
                                        sm: '34px',
                                    }}
                                    minWidth={{
                                        base: '140px',
                                        md: '140px',
                                    }}
                                    borderRadius="20px"
                                    onClick={() => {
                                        setExcludedAerodromes((prevState) => [
                                            ...prevState,
                                            ...includedAerodromes,
                                        ])
                                        setExcludedAws((prevState) => [
                                            ...prevState,
                                            ...includedAws,
                                        ])
                                    }}
                                    borderColor="#DFE6EE"
                                    background="light.10"
                                    variant="outline"
                                    width="auto"
                                    justifyContent="center"
                                    display="flex"
                                    alignItems="center"
                                    _hover={{
                                        background: 'light.30',
                                        color: 'light.100',
                                    }}
                                    _active={{
                                        background: 'light.30',
                                    }}
                                >
                                    <Icon as={IoClose} fontSize="1.3rem" />
                                    <Text pl="5px" pr="3px">
                                        Exclude All
                                    </Text>
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Flex>
                </Flex>
                <Divider
                    height="0px !important"
                    borderColor="#E2E8F0"
                    opacity="1"
                    width="100%"
                    mx="auto"
                    mt="10px"
                />
                <Flex
                    pt={excludedItemsShow ? '10px' : '0px'}
                    pb={excludedItemsShow ? '12px' : '0px'}
                    px={'20px'}
                    fontSize="0.8rem"
                    minHeight={excludedItemsShow ? '36px' : '0px'}
                    color="light.100"
                    overflowY={{ base: 'hidden', md: 'auto' }}
                    overflowX="hidden"
                    maxHeight={{
                        base: 'max-content',
                        md: 'calc(100vh - 200px)',
                    }}
                    flexDirection="column"
                    flexWrap="nowrap"
                >
                    <ExcludedAerodromes
                        setExcludedAerodromes={setExcludedAerodromes}
                        briefingData={briefingData}
                        filteredExcludedAerodromes={filteredExcludedAerodromes}
                        includedAerodromes={includedAerodromes}
                        showManageFavourites={showManageFavourites}
                        selectAerodromes={selectAerodromes}
                        setSelectAerodromes={setSelectAerodromes}
                        selectAws={selectAws}
                        setSelectAws={setSelectAws}
                        isPremium={isPremium}
                    />

                    {!isPremium && (
                        <Divider
                            height="0px !important"
                            borderColor="#E2E8F0"
                            opacity="1"
                            width="100%"
                            mx="auto"
                            mt={{
                                base: '15px',
                                md: showManageFavourites ? '15px' : '5px',
                            }}
                            mb={showManageFavourites ? '15px' : '10px'}
                        />
                    )}

                    {!isPremium && (
                        <ExcludedAws
                            setExcludedAws={setExcludedAws}
                            briefingData={briefingData}
                            filteredExcludedAerodromes={
                                filteredExcludedAerodromes
                            }
                            filteredExcludedAws={filteredExcludedAws}
                            includedAws={includedAws}
                            showManageFavourites={showManageFavourites}
                            selectAerodromes={selectAerodromes}
                            setSelectAerodromes={setSelectAerodromes}
                            selectAws={selectAws}
                            setSelectAws={setSelectAws}
                            isPremium={isPremium}
                        />
                    )}
                </Flex>
            </Flex>
        </Box>
    )
}

export const ExcludedAerodromes = (props) => {
    const {
        setExcludedAerodromes,
        briefingData,
        filteredExcludedAerodromes,
        includedAerodromes,
        showManageFavourites,
        selectAerodromes,
        selectAws,
        setSelectAws,
        setSelectAerodromes,
        updateExisting,
        setUpdatingExcludedItems,
    } = props

    const [firstClose, setFirstClose] = useRecoilState(
        excludedAerodromesWarningMessageState
    )
    const excludedItemsShow = useRecoilValue(excludedItemsShowState)
    const { isConfirmed } = useConfirm() // alert message handler

    return (
        <Flex
            pl="1px"
            fontSize="0.8rem"
            color="light.100"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={{
                base: '5px',
                smd: '20px',
                md: '10px',
                lg: '20px',
            }}
            width="100%"
            minWidth="100%"
        >
            <Flex width="100%" flexDirection="column">
                <Flex
                    flexDirection={
                        showManageFavourites
                            ? 'column'
                            : { base: 'column', xl: 'row' }
                    }
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    minHeight={{
                        base: '75px',
                        md: '82px',
                    }}
                >
                    <Flex
                        height="100%"
                        pt={
                            showManageFavourites
                                ? '0px'
                                : {
                                      base: '9px',
                                      md: '7px',
                                  }
                        }
                    >
                        <Text
                            minWidth="175px"
                            pr="5px"
                            pb="4px"
                            fontWeight="600"
                            color="gray.500"
                        >
                            EXCLUDED AERODROMES:
                        </Text>
                    </Flex>
                    {filteredExcludedAerodromes &&
                    filteredExcludedAerodromes.length > 0 ? (
                        <Flex
                            flexWrap="wrap"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            minHeight={{
                                base: '75px',
                                md: '82px',
                            }}
                        >
                            {[...filteredExcludedAerodromes]
                                .sort((a, b) => {
                                    if (a < b) {
                                        return -1
                                    }
                                    if (a > b) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                })
                                .map((aerodrome, i) => (
                                    <Button
                                        key={`ExcludedAerodromes${aerodrome}-${i}`}
                                        background="none"
                                        color="light.100"
                                        opacity={{
                                            base: '1',
                                            md: '0.85',
                                        }}
                                        isActive={{
                                            opacity: '0.85',
                                        }}
                                        fontSize="0.8rem"
                                        fontWeight="600"
                                        height="36px"
                                        justifyContent="center"
                                        alignItems="center"
                                        px="0px"
                                        mr={{
                                            base: '7px',
                                            md: '10px',
                                        }}
                                        mb="5px"
                                        borderRadius="10px"
                                        onClick={async () => {
                                            if (firstClose) {
                                                const acknowledgeExcludedAerodromes =
                                                    await isConfirmed(
                                                        // "Excluded aerodromes?"
                                                        'confirm-ok',
                                                        <AltExcludedAerodromesWarningMessage
                                                            showManageFavourites={
                                                                showManageFavourites
                                                            }
                                                            excludedItemsShow={
                                                                excludedItemsShow
                                                            }
                                                        />
                                                    )
                                                if (
                                                    acknowledgeExcludedAerodromes
                                                ) {
                                                    setExcludedAerodromes(
                                                        (prevState) =>
                                                            prevState.filter(
                                                                (a) =>
                                                                    aerodrome !==
                                                                    a
                                                            )
                                                    )
                                                    setFirstClose(false)
                                                    if (showManageFavourites) {
                                                        setUpdatingExcludedItems(
                                                            true
                                                        )
                                                    }
                                                }
                                            } else {
                                                setExcludedAerodromes(
                                                    (prevState) =>
                                                        prevState.filter(
                                                            (a) =>
                                                                aerodrome !== a
                                                        )
                                                )
                                                if (showManageFavourites) {
                                                    setUpdatingExcludedItems(
                                                        true
                                                    )
                                                }
                                            }
                                        }}
                                        _focus={{
                                            opacity: '1 !important',
                                        }}
                                        _active={{
                                            opacity: {
                                                base: '1',
                                                md: '0.85',
                                            },
                                        }}
                                        _hover={{
                                            opacity: '1 !important',
                                        }}
                                    >
                                        <Box
                                            bg="#07588C"
                                            backgroundImage="linear-gradient(345deg, #a7bfcd 0%, #485c69 80%)"
                                            borderRadius="20px"
                                            color="light.10"
                                            pl="10px"
                                            pr={
                                                selectAerodromes
                                                    ? '6px'
                                                    : '10px'
                                            }
                                            variant="outline"
                                            height={{
                                                base: '30px',
                                                md: '26px',
                                            }}
                                            transition="all ease 250ms"
                                            minWidth="56px"
                                            flexDirection="row"
                                            justifyContent="center"
                                            display="flex"
                                            alignItems="center"
                                            fontWeight="600"
                                            _hover={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                        >
                                            <Text
                                                pr={
                                                    selectAerodromes
                                                        ? '4px'
                                                        : '0px'
                                                }
                                            >
                                                {aerodrome}
                                            </Text>
                                            <Icon
                                                as={GrRedo}
                                                fontSize={
                                                    selectAerodromes
                                                        ? '1rem'
                                                        : '0rem'
                                                }
                                                opacity="0.7"
                                                transition="all ease 250ms"
                                            />
                                        </Box>
                                    </Button>
                                ))}
                        </Flex>
                    ) : (
                        <Flex
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            minHeight={{
                                base: '75px',
                                md: '82px',
                            }}
                            width="100%"
                            cursor="pointer"
                            onClick={() => {
                                if (selectAws) {
                                    setSelectAws(false)
                                }
                                setSelectAerodromes((prevState) => !prevState)
                            }}
                        >
                            <Text
                                alignSelf="left"
                                fontSize="0.8rem"
                                height="26px"
                                fontWeight="400"
                                lineHeight="1.2"
                                whiteSpace="pre-line"
                                pt="9px"
                                color="gray.400"
                                fontStyle="italic"
                            >
                                None
                            </Text>
                        </Flex>
                    )}
                </Flex>
                <Collapse
                    animateOpacity
                    in={selectAerodromes || showManageFavourites}
                    position={'relative'}
                    style={{
                        overflow:
                            selectAerodromes || showManageFavourites
                                ? 'initial'
                                : 'hidden',
                        opacity:
                            selectAerodromes || showManageFavourites
                                ? '1'
                                : '0',
                    }}
                >
                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection={
                            showManageFavourites
                                ? 'column'
                                : {
                                      base: 'column',
                                      xl: 'row',
                                  }
                        }
                    >
                        <Text
                            minWidth="175px"
                            pr="5px"
                            pb="4px"
                            pt={{
                                base: '15px',
                                xl: '21px',
                            }}
                            fontWeight="700"
                            color="#2a88c3"
                        >
                            INCLUDED AERODROMES:
                        </Text>
                        <Flex
                            width={{
                                base: 'calc(100% + 42px)',
                                smd: 'calc(100% + 57px)',
                                xl: showManageFavourites
                                    ? 'calc(100% + 57px)'
                                    : '100%',
                            }}
                            mt="6px"
                            mb={{ base: '10px', md: '15px' }}
                            flexDirection="column"
                        >
                            <Flex
                                pl={showManageFavourites ? '10px' : '15px'}
                                pr={showManageFavourites ? '0px' : '10px'}
                                py="10px"
                                bg={
                                    showManageFavourites && !updateExisting
                                        ? 'light.30'
                                        : 'white'
                                }
                                width="100%"
                                minHeight="92px"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                border="1px solid"
                                borderColor="gray.200"
                            >
                                {briefingData &&
                                includedAerodromes &&
                                includedAerodromes.length > 0 ? (
                                    [...includedAerodromes].map(
                                        (aerodrome, i) => (
                                            <Button
                                                key={`IncludedAerodromes${aerodrome}-${i}`}
                                                background="none"
                                                color="light.100"
                                                opacity={{
                                                    base: '1',
                                                    md: '0.85',
                                                }}
                                                isActive={{
                                                    opacity: '0.85',
                                                }}
                                                fontSize="0.75rem"
                                                fontWeight="600"
                                                height={{
                                                    base: '34px',
                                                    md: '30px',
                                                }}
                                                justifyContent="center"
                                                alignItems="center"
                                                px="0px"
                                                mr={{
                                                    base: '7px',
                                                    md: '10px',
                                                }}
                                                mb={{
                                                    base: '8px',
                                                    md: '5px',
                                                }}
                                                borderRadius="10px"
                                                onClick={async () => {
                                                    if (firstClose) {
                                                        const acknowledgeExcludedAerodromes =
                                                            await isConfirmed(
                                                                // "Excluded aerodromes?"
                                                                'confirm-ok',
                                                                <AltExcludedAerodromesWarningMessage
                                                                    showManageFavourites={
                                                                        showManageFavourites
                                                                    }
                                                                    excludedItemsShow={
                                                                        excludedItemsShow
                                                                    }
                                                                />
                                                            )
                                                        if (
                                                            acknowledgeExcludedAerodromes
                                                        ) {
                                                            setExcludedAerodromes(
                                                                (prevState) => [
                                                                    ...prevState,
                                                                    aerodrome,
                                                                ]
                                                            )
                                                            setFirstClose(false)
                                                            if (
                                                                showManageFavourites
                                                            ) {
                                                                setUpdatingExcludedItems(
                                                                    true
                                                                )
                                                            }
                                                        }
                                                    } else {
                                                        setExcludedAerodromes(
                                                            (prevState) => [
                                                                ...prevState,
                                                                aerodrome,
                                                            ]
                                                        )
                                                        if (
                                                            showManageFavourites
                                                        ) {
                                                            setUpdatingExcludedItems(
                                                                true
                                                            )
                                                        }
                                                    }
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                }}
                                                _active={{
                                                    opacity: {
                                                        base: '1',
                                                        md: '0.85',
                                                    },
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                }}
                                            >
                                                <Box
                                                    bg="#07588C"
                                                    backgroundImage="linear-gradient(345deg, #3EA7EA 0%, #07588C 80%)"
                                                    borderRadius="20px"
                                                    color="light.10"
                                                    pl="10px"
                                                    pr="5px"
                                                    variant="outline"
                                                    height={{
                                                        base: '30px',
                                                        md: '24px',
                                                    }}
                                                    minWidth="56px"
                                                    flexDirection="row"
                                                    justifyContent="center"
                                                    display="flex"
                                                    alignItems="center"
                                                    fontWeight="600"
                                                    _hover={{
                                                        opacity: '1',
                                                    }}
                                                    _active={{
                                                        opacity: '1',
                                                    }}
                                                >
                                                    <Text pr="3px">
                                                        {aerodrome}
                                                    </Text>
                                                    <Icon
                                                        opacity="0.7"
                                                        as={IoClose}
                                                        fontSize="1.1rem"
                                                    />
                                                </Box>
                                            </Button>
                                        )
                                    )
                                ) : (
                                    <Text
                                        alignSelf="left"
                                        fontSize="0.8rem"
                                        fontWeight="400"
                                        lineHeight="1.2"
                                        whiteSpace="pre-line"
                                        color="gray.400"
                                        fontStyle="italic"
                                        pt="7px"
                                    >
                                        None
                                    </Text>
                                )}
                            </Flex>
                            <Flex gap="5px" pt="5px">
                                <Text
                                    textAlign={'left'}
                                    fontSize={{
                                        base: '0.75rem',
                                        smd: '0.75rem',
                                    }}
                                    color="gray.500"
                                    fontWeight="500"
                                    lineHeight="1.2"
                                    whiteSpace="pre-line"
                                    fontStyle="italic"
                                >
                                    NOTE:
                                </Text>
                                <Text
                                    textAlign={'left'}
                                    fontSize={{
                                        base: '0.75rem',
                                        smd: '0.75rem',
                                    }}
                                    color="gray.500"
                                    fontWeight="300"
                                    lineHeight="1.2"
                                    whiteSpace="pre-line"
                                    fontStyle="italic"
                                >
                                    Not all Aerodromes may have available
                                    information. Those without any data are
                                    listed at the bottom of the briefing.
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Collapse>
            </Flex>

            <Flex flexDirection="column">
                {!showManageFavourites && (
                    <Tooltip
                        label={
                            selectAerodromes
                                ? 'Close'
                                : 'Reinstate or exclude Aerodromes'
                        }
                        hasArrow
                        borderRadius="7px"
                        height="26px"
                        fontSize="0.7rem"
                        alignItems="center"
                        display={isMobile || !isDesktop ? 'none' : 'flex'}
                        isDisabled={isMobile || !isDesktop}
                        placement="top"
                        m="5px"
                        mr="2px"
                        color="light.10"
                        bg="dark.10"
                    >
                        <Button
                            variant="outline"
                            borderRadius="10px"
                            mt="1px"
                            height="34px"
                            width="38px"
                            minWidth="38px"
                            background="white"
                            color={selectAerodromes ? 'light.200' : 'gray.600'}
                            transition="all ease 250ms"
                            _hover={{
                                color: selectAerodromes
                                    ? 'light.200'
                                    : 'gray.800',

                                opacity: '1',
                                background: 'light.30',
                            }}
                            _active={{
                                background: 'light.30',
                                boxShadow:
                                    'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => {
                                if (selectAws) {
                                    setSelectAws(false)
                                }
                                setSelectAerodromes((prevState) => !prevState)
                            }}
                        >
                            <Icon
                                as={
                                    selectAerodromes
                                        ? MdOutlineExpandLess
                                        : TbPlus
                                }
                                fontSize={
                                    selectAerodromes ? '1.7rem' : '1.4rem'
                                }
                            />
                        </Button>
                    </Tooltip>
                )}
                <Collapse
                    animateOpacity
                    in={selectAerodromes || showManageFavourites}
                    position={'relative'}
                    style={{
                        overflow: selectAerodromes ? 'initial' : 'hidden',
                        opacity: selectAerodromes ? '1' : '0',
                    }}
                >
                    <Flex
                        flexDirection="column"
                        gap="12px"
                        pt={showManageFavourites ? '5px' : '12px'}
                    >
                        <Tooltip
                            label={'Reinstate all Aerodromes'}
                            hasArrow
                            borderRadius="7px"
                            height="26px"
                            fontSize="0.7rem"
                            alignItems="center"
                            display={isMobile || !isDesktop ? 'none' : 'flex'}
                            isDisabled={isMobile || !isDesktop}
                            placement="top"
                            m="5px"
                            mr="2px"
                            color="light.10"
                            bg="dark.10"
                        >
                            <Button
                                variant="outline"
                                borderRadius="10px"
                                height="34px"
                                width="38px"
                                minWidth="38px"
                                background="white"
                                color="gray.600"
                                _hover={{
                                    color: 'gray.800',
                                    opacity: '1',
                                    background: 'light.30',
                                }}
                                _active={{
                                    background: 'light.30',
                                    boxShadow:
                                        'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                onClick={async () => {
                                    if (firstClose) {
                                        const acknowledgeExcludedAerodromes =
                                            await isConfirmed(
                                                // "Excluded aerodromes?"
                                                'confirm-ok',
                                                <AltExcludedAerodromesWarningMessage
                                                    showManageFavourites={
                                                        showManageFavourites
                                                    }
                                                    excludedItemsShow={
                                                        excludedItemsShow
                                                    }
                                                />
                                            )
                                        if (acknowledgeExcludedAerodromes) {
                                            setExcludedAerodromes([])
                                            setFirstClose(false)
                                            if (showManageFavourites) {
                                                setUpdatingExcludedItems(true)
                                            }
                                        }
                                    } else {
                                        setExcludedAerodromes([])
                                        if (showManageFavourites) {
                                            setUpdatingExcludedItems(true)
                                        }
                                    }
                                }}
                            >
                                <Icon as={GrRedo} fontSize="1rem" />
                                <Text
                                    fontSize="0.55rem"
                                    pb="2px"
                                    fontWeight="700"
                                >
                                    ALL
                                </Text>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            label={'Exclude all Aerodromes'}
                            hasArrow
                            borderRadius="7px"
                            height="26px"
                            fontSize="0.7rem"
                            alignItems="center"
                            display={isMobile || !isDesktop ? 'none' : 'flex'}
                            isDisabled={isMobile || !isDesktop}
                            placement="top"
                            m="5px"
                            mr="2px"
                            color="light.10"
                            bg="dark.10"
                        >
                            <Button
                                variant="outline"
                                borderRadius="10px"
                                height="34px"
                                width="38px"
                                minWidth="38px"
                                background="white"
                                color="gray.600"
                                _hover={{
                                    color: 'gray.800',
                                    opacity: '1',
                                    background: 'light.30',
                                }}
                                _active={{
                                    background: 'light.30',
                                    boxShadow:
                                        'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                onClick={async () => {
                                    if (firstClose) {
                                        const acknowledgeExcludedAerodromes =
                                            await isConfirmed(
                                                // "Excluded aerodromes?"
                                                'confirm-ok',
                                                <AltExcludedAerodromesWarningMessage
                                                    showManageFavourites={
                                                        showManageFavourites
                                                    }
                                                    excludedItemsShow={
                                                        excludedItemsShow
                                                    }
                                                />
                                            )
                                        if (acknowledgeExcludedAerodromes) {
                                            setExcludedAerodromes(
                                                (prevState) => [
                                                    ...prevState,
                                                    ...includedAerodromes,
                                                ]
                                            )
                                            setFirstClose(false)
                                            if (showManageFavourites) {
                                                setUpdatingExcludedItems(true)
                                            }
                                        }
                                    } else {
                                        setExcludedAerodromes((prevState) => [
                                            ...prevState,
                                            ...includedAerodromes,
                                        ])
                                        if (showManageFavourites) {
                                            setUpdatingExcludedItems(true)
                                        }
                                    }
                                }}
                            >
                                <Icon as={IoClose} fontSize="1.1rem" />
                                <Text
                                    fontSize="0.55rem"
                                    pb="2px"
                                    fontWeight="700"
                                    mt="-2px"
                                >
                                    ALL
                                </Text>
                            </Button>
                        </Tooltip>
                    </Flex>
                </Collapse>
            </Flex>
        </Flex>
    )
}

export const ExcludedAws = (props) => {
    const {
        setExcludedAws,
        briefingData,
        filteredExcludedAws,
        filteredExcludedAerodromes,
        includedAws,
        showManageFavourites,
        selectAerodromes,
        selectAws,
        setSelectAws,
        setSelectAerodromes,
        updateExisting,
        setUpdatingExcludedItems,
    } = props

    const [firstClose, setFirstClose] = useRecoilState(
        excludedAwsWarningMessageState
    )
    const excludedItemsShow = useRecoilValue(excludedItemsShowState)
    const { isConfirmed } = useConfirm() // alert message handler

    return (
        <Flex
            pl="1px"
            fontSize="0.8rem"
            color="light.100"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={{
                base: '5px',
                smd: '20px',
                md: '10px',
                lg: '20px',
            }}
            width="100%"
            minWidth="100%"
        >
            <Flex width="100%" flexDirection="column">
                <Flex
                    flexDirection={
                        showManageFavourites
                            ? 'column'
                            : { base: 'column', xl: 'row' }
                    }
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    minHeight={{
                        base: '75px',
                        md: '82px',
                    }}
                >
                    <Flex
                        height="100%"
                        pt={
                            showManageFavourites
                                ? '0px'
                                : {
                                      base: '9px',
                                      md: '7px',
                                  }
                        }
                    >
                        <Text
                            minWidth={
                                filteredExcludedAerodromes &&
                                filteredExcludedAerodromes.length > 0
                                    ? '175px'
                                    : '110px'
                            }
                            pr="5px"
                            pb="4px"
                            fontWeight="600"
                            color="gray.500"
                        >
                            EXCLUDED AWS:
                        </Text>
                    </Flex>
                    {filteredExcludedAws && filteredExcludedAws.length > 0 ? (
                        <Flex
                            flexWrap="wrap"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            minHeight={{
                                base: '75px',
                                md: '82px',
                            }}
                        >
                            {[...filteredExcludedAws]
                                .sort((a, b) => {
                                    if (a < b) {
                                        return -1
                                    }
                                    if (a > b) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                })
                                .map((aws, i) => (
                                    <Button
                                        key={`ExcludedAws${aws}-${i}`}
                                        background="none"
                                        color="light.100"
                                        opacity={{
                                            base: '1',
                                            md: '0.85',
                                        }}
                                        isActive={{ opacity: '0.85' }}
                                        fontSize="0.8rem"
                                        fontWeight="600"
                                        height="36px"
                                        justifyContent="center"
                                        alignItems="center"
                                        px="0px"
                                        mr={{
                                            base: '7px',
                                            md: '10px',
                                        }}
                                        mb="5px"
                                        borderRadius="10px"
                                        onClick={async () => {
                                            if (firstClose) {
                                                const acknowledgeExcludedAws =
                                                    await isConfirmed(
                                                        // "Excluded aerodromes?"
                                                        'confirm-ok',
                                                        <ExcludedAWSWarningMessage
                                                            showManageFavourites={
                                                                showManageFavourites
                                                            }
                                                            excludedItemsShow={
                                                                excludedItemsShow
                                                            }
                                                        />
                                                    )
                                                if (acknowledgeExcludedAws) {
                                                    setExcludedAws(
                                                        (prevState) =>
                                                            prevState.filter(
                                                                (a) => aws !== a
                                                            )
                                                    )
                                                    setFirstClose(false)
                                                    if (showManageFavourites) {
                                                        setUpdatingExcludedItems(
                                                            true
                                                        )
                                                    }
                                                }
                                            } else {
                                                setExcludedAws((prevState) =>
                                                    prevState.filter(
                                                        (a) => aws !== a
                                                    )
                                                )
                                                if (showManageFavourites) {
                                                    setUpdatingExcludedItems(
                                                        true
                                                    )
                                                }
                                            }
                                        }}
                                        _focus={{
                                            opacity: '1 !important',
                                        }}
                                        _active={{
                                            opacity: {
                                                base: '1',
                                                md: '0.85',
                                            },
                                        }}
                                        _hover={{
                                            opacity: '1 !important',
                                        }}
                                    >
                                        <Box
                                            bg="#07588C"
                                            backgroundImage="linear-gradient(345deg, rgb(165 181 199) 0%, rgb(75 86 109) 80%)"
                                            borderRadius="20px"
                                            color="light.10"
                                            pl="10px"
                                            pr={selectAws ? '6px' : '10px'}
                                            variant="outline"
                                            height={{
                                                base: '30px',
                                                md: '26px',
                                            }}
                                            minWidth="56px"
                                            flexDirection="row"
                                            justifyContent="center"
                                            display="flex"
                                            alignItems="center"
                                            fontWeight="600"
                                            _hover={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                        >
                                            <Text
                                                pr={selectAws ? '4px' : '0px'}
                                            >
                                                {aws}
                                            </Text>
                                            <Icon
                                                as={GrRedo}
                                                fontSize={
                                                    selectAws ? '1rem' : '0rem'
                                                }
                                                opacity="0.7"
                                                transition="all ease 250ms"
                                            />
                                        </Box>
                                    </Button>
                                ))}
                        </Flex>
                    ) : (
                        <Flex
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            minHeight={{
                                base: '75px',
                                md: '82px',
                            }}
                            width="100%"
                            cursor="pointer"
                            onClick={() => {
                                if (selectAerodromes) {
                                    setSelectAerodromes(false)
                                }
                                setSelectAws((prevState) => !prevState)
                            }}
                        >
                            <Text
                                alignSelf="left"
                                fontSize="0.8rem"
                                fontWeight="400"
                                lineHeight="1.2"
                                whiteSpace="pre-line"
                                pt="9px"
                                color="gray.400"
                                fontStyle="italic"
                            >
                                None
                            </Text>
                        </Flex>
                    )}
                </Flex>
                <Collapse
                    animateOpacity
                    in={selectAws}
                    position={'relative'}
                    style={{
                        overflow: selectAws ? 'initial' : 'hidden',
                        opacity: selectAws ? '1' : '0',
                    }}
                >
                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection={
                            showManageFavourites
                                ? 'column'
                                : {
                                      base: 'column',
                                      xl: 'row',
                                  }
                        }
                    >
                        <Text
                            minWidth="175px"
                            pr="5px"
                            pb="4px"
                            pt={{
                                base: '15px',
                                xl: '21px',
                            }}
                            fontWeight="700"
                            color="#367acf"
                        >
                            INCLUDED AWS:
                        </Text>
                        <Flex
                            width={{
                                base: 'calc(100% + 42px)',
                                smd: 'calc(100% + 57px)',
                                xl: showManageFavourites
                                    ? 'calc(100% + 57px)'
                                    : '100%',
                            }}
                            mt="6px"
                            mb="15px"
                            flexDirection="column"
                        >
                            <Flex
                                pl={showManageFavourites ? '10px' : '15px'}
                                pr={showManageFavourites ? '1px' : '10px'}
                                py="10px"
                                bg={
                                    showManageFavourites && !updateExisting
                                        ? 'light.30'
                                        : 'white'
                                }
                                width="100%"
                                minHeight="92px"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                border="1px solid"
                                borderColor="gray.200"
                            >
                                {briefingData &&
                                includedAws &&
                                includedAws.length > 0 ? (
                                    [...includedAws].map((aws, i) => (
                                        <Button
                                            key={`IncludedAws${aws}-${i}`}
                                            background="none"
                                            color="light.100"
                                            opacity={{
                                                base: '1',
                                                md: '0.85',
                                            }}
                                            isActive={{
                                                opacity: '0.85',
                                            }}
                                            fontSize="0.75rem"
                                            fontWeight="600"
                                            height={{
                                                base: '34px',
                                                md: '30px',
                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                            px="0px"
                                            mr={{
                                                base: '8px',
                                                md: '10px',
                                            }}
                                            mb={{
                                                base: '7px',
                                                md: '5px',
                                            }}
                                            borderRadius="10px"
                                            onClick={async () => {
                                                if (firstClose) {
                                                    const acknowledgeExcludedAws =
                                                        await isConfirmed(
                                                            // "Excluded aerodromes?"
                                                            'confirm-ok',
                                                            <ExcludedAWSWarningMessage
                                                                showManageFavourites={
                                                                    showManageFavourites
                                                                }
                                                                excludedItemsShow={
                                                                    excludedItemsShow
                                                                }
                                                            />
                                                        )
                                                    if (
                                                        acknowledgeExcludedAws
                                                    ) {
                                                        setExcludedAws(
                                                            (prevState) => [
                                                                ...prevState,
                                                                aws,
                                                            ]
                                                        )
                                                        setFirstClose(false)
                                                        if (
                                                            showManageFavourites
                                                        ) {
                                                            setUpdatingExcludedItems(
                                                                true
                                                            )
                                                        }
                                                    }
                                                } else {
                                                    setExcludedAws(
                                                        (prevState) => [
                                                            ...prevState,
                                                            aws,
                                                        ]
                                                    )
                                                    if (showManageFavourites) {
                                                        setUpdatingExcludedItems(
                                                            true
                                                        )
                                                    }
                                                }
                                            }}
                                            _focus={{
                                                opacity: '1 !important',
                                            }}
                                            _active={{
                                                opacity: {
                                                    base: '1',
                                                    md: '0.85',
                                                },
                                            }}
                                            _hover={{
                                                opacity: '1 !important',
                                            }}
                                        >
                                            <Box
                                                bg="#07588C"
                                                backgroundImage="linear-gradient(165deg, rgb(6 48 99) 0%, rgb(88 126 199) 80%)"
                                                borderRadius="20px"
                                                color="light.10"
                                                pl="10px"
                                                pr="5px"
                                                variant="outline"
                                                height={{
                                                    base: '30px',
                                                    md: '24px',
                                                }}
                                                minWidth="56px"
                                                flexDirection="row"
                                                justifyContent="center"
                                                display="flex"
                                                alignItems="center"
                                                fontWeight="600"
                                                _hover={{
                                                    opacity: '1',
                                                }}
                                                _active={{
                                                    opacity: '1',
                                                }}
                                            >
                                                <Text pr="3px">{aws}</Text>
                                                <Icon
                                                    opacity="0.7"
                                                    as={IoClose}
                                                    fontSize="1.1rem"
                                                />
                                            </Box>
                                        </Button>
                                    ))
                                ) : (
                                    <Text
                                        alignSelf="left"
                                        fontSize="0.8rem"
                                        fontWeight="400"
                                        lineHeight="1.2"
                                        whiteSpace="pre-line"
                                        color="gray.400"
                                        fontStyle="italic"
                                        pt="7px"
                                    >
                                        None
                                    </Text>
                                )}
                            </Flex>
                            <Flex gap="5px" pt="5px">
                                <Text
                                    textAlign={'left'}
                                    fontSize={{
                                        base: '0.75rem',
                                        smd: '0.75rem',
                                    }}
                                    color="gray.500"
                                    fontWeight="500"
                                    lineHeight="1.2"
                                    whiteSpace="pre-line"
                                    fontStyle="italic"
                                >
                                    NOTE:
                                </Text>
                                <Text
                                    textAlign={'left'}
                                    fontSize={{
                                        base: '0.75rem',
                                        smd: '0.75rem',
                                    }}
                                    color="gray.500"
                                    fontWeight="300"
                                    lineHeight="1.2"
                                    whiteSpace="pre-line"
                                    fontStyle="italic"
                                >
                                    If an Automatic Weather Station has no
                                    information available, this will be listed
                                    at the bottom of the briefing.
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Collapse>
            </Flex>

            <Flex flexDirection="column">
                {!showManageFavourites && (
                    <Tooltip
                        label={selectAws ? 'Close' : 'Reinstate or exclude AWS'}
                        hasArrow
                        borderRadius="7px"
                        height="26px"
                        fontSize="0.7rem"
                        alignItems="center"
                        display={isMobile || !isDesktop ? 'none' : 'flex'}
                        isDisabled={isMobile || !isDesktop}
                        placement="top"
                        m="5px"
                        mr="2px"
                        color="light.10"
                        bg="dark.10"
                    >
                        <Button
                            variant="outline"
                            borderRadius="10px"
                            mt="1px"
                            height="34px"
                            width="38px"
                            minWidth="38px"
                            background="white"
                            color={selectAws ? 'light.200' : 'gray.600'}
                            _hover={{
                                color: selectAws ? 'light.200' : 'gray.800',

                                opacity: '1',
                                background: 'light.30',
                            }}
                            _active={{
                                background: 'light.30',
                                boxShadow:
                                    'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => {
                                if (selectAerodromes) {
                                    setSelectAerodromes(false)
                                }
                                setSelectAws((prevState) => !prevState)
                            }}
                        >
                            <Icon
                                as={selectAws ? MdOutlineExpandLess : TbPlus}
                                fontSize={selectAws ? '1.7rem' : '1.4rem'}
                            />
                        </Button>
                    </Tooltip>
                )}
                <Collapse
                    animateOpacity
                    in={selectAws}
                    position={'relative'}
                    style={{
                        overflow: selectAws ? 'initial' : 'hidden',
                        opacity: selectAws ? '1' : '0',
                    }}
                >
                    <Flex
                        flexDirection="column"
                        gap="12px"
                        pt={showManageFavourites ? '5px' : '12px'}
                    >
                        <Tooltip
                            label={'Reinstate all AWS'}
                            hasArrow
                            borderRadius="7px"
                            height="26px"
                            fontSize="0.7rem"
                            alignItems="center"
                            display={isMobile || !isDesktop ? 'none' : 'flex'}
                            isDisabled={isMobile || !isDesktop}
                            placement="top"
                            m="5px"
                            mr="2px"
                            color="light.10"
                            bg="dark.10"
                        >
                            <Button
                                variant="outline"
                                borderRadius="10px"
                                height="34px"
                                width="38px"
                                minWidth="38px"
                                background="white"
                                color="gray.600"
                                _hover={{
                                    color: 'gray.800',
                                    opacity: '1',
                                    background: 'light.30',
                                }}
                                _active={{
                                    background: 'light.30',
                                    boxShadow:
                                        'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                onClick={async () => {
                                    if (firstClose) {
                                        const acknowledgeExcludedAws =
                                            await isConfirmed(
                                                // "Excluded aerodromes?"
                                                'confirm-ok',
                                                <ExcludedAWSWarningMessage
                                                    showManageFavourites={
                                                        showManageFavourites
                                                    }
                                                    excludedItemsShow={
                                                        excludedItemsShow
                                                    }
                                                />
                                            )
                                        if (acknowledgeExcludedAws) {
                                            setExcludedAws([])
                                            setFirstClose(false)
                                            if (showManageFavourites) {
                                                setUpdatingExcludedItems(true)
                                            }
                                        }
                                    } else {
                                        setExcludedAws([])
                                        if (showManageFavourites) {
                                            setUpdatingExcludedItems(true)
                                        }
                                    }
                                }}
                            >
                                <Icon as={GrRedo} fontSize="1rem" />
                                <Text
                                    fontSize="0.55rem"
                                    pb="2px"
                                    fontWeight="700"
                                >
                                    ALL
                                </Text>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            label={'Exclude all AWS'}
                            hasArrow
                            borderRadius="7px"
                            height="26px"
                            fontSize="0.7rem"
                            alignItems="center"
                            display={isMobile || !isDesktop ? 'none' : 'flex'}
                            isDisabled={isMobile || !isDesktop}
                            placement="top"
                            m="5px"
                            mr="2px"
                            color="light.10"
                            bg="dark.10"
                        >
                            <Button
                                variant="outline"
                                borderRadius="10px"
                                height="34px"
                                width="38px"
                                minWidth="38px"
                                background="white"
                                color="gray.600"
                                _hover={{
                                    color: 'gray.800',
                                    opacity: '1',
                                    background: 'light.30',
                                }}
                                _active={{
                                    background: 'light.30',
                                    boxShadow:
                                        'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                onClick={async () => {
                                    if (firstClose) {
                                        const acknowledgeExcludedAws =
                                            await isConfirmed(
                                                // "Excluded aerodromes?"
                                                'confirm-ok',
                                                <ExcludedAWSWarningMessage
                                                    showManageFavourites={
                                                        showManageFavourites
                                                    }
                                                    excludedItemsShow={
                                                        excludedItemsShow
                                                    }
                                                />
                                            )
                                        if (acknowledgeExcludedAws) {
                                            setExcludedAws((prevState) => [
                                                ...prevState,
                                                ...includedAws,
                                            ])
                                            setFirstClose(false)
                                            if (showManageFavourites) {
                                                setUpdatingExcludedItems(true)
                                            }
                                        }
                                    } else {
                                        setExcludedAws((prevState) => [
                                            ...prevState,
                                            ...includedAws,
                                        ])
                                        if (showManageFavourites) {
                                            setUpdatingExcludedItems(true)
                                        }
                                    }
                                }}
                            >
                                <Icon as={IoClose} fontSize="1.1rem" />
                                <Text
                                    fontSize="0.55rem"
                                    pb="2px"
                                    fontWeight="700"
                                    mt="-2px"
                                >
                                    ALL
                                </Text>
                            </Button>
                        </Tooltip>
                    </Flex>
                </Collapse>
            </Flex>
        </Flex>
    )
}
