import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { isEqual } from 'lodash'
import {
    Text,
    Box,
    Divider,
    Flex,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useDisclosure,
    LightMode,
    ModalBody,
    ModalFooter,
    Button,
    UnorderedList,
    ListItem,
    Fade,
} from '@chakra-ui/react'
import LayerError from '../../../icons/menu-icons/LayerError'
import { formatDistance, parseISO } from 'date-fns'

import { config } from '../../../config/config'

import {
    statusData,
    onlineState,
    layerSelectionState,
    dataModeState,
    searchFocusState,
    showOnlineUsersButtonState,
    modalVisibilityState,
    setModalVisibilityState,
    errorBadgeState,
    hideNetworkConnectionLostState,
} from '../../../globalState'

function LayerErrorButton({ count, handleOpen, show }) {
    const searchFocused = useRecoilValue(searchFocusState)
    const onlineUsers = useRecoilValue(showOnlineUsersButtonState)
    const hideNetworkConnectionLost = useRecoilValue(
        hideNetworkConnectionLostState
    )

    return (
        <Fade in={show}>
            <Box
                display={count ? 'block' : 'none'}
                direction={'row'}
                onClick={handleOpen}
                pos={'fixed'}
                top={{
                    base: hideNetworkConnectionLost ? '115px' : '70px',
                    md: hideNetworkConnectionLost ? '60px' : '19px',
                }}
                left={{
                    base: '50vw',
                    md: onlineUsers ? '248px' : '200px',
                }}
                opacity={{
                    base: '1',
                    md: searchFocused ? '0' : '1',
                }}
                visibility={{
                    base: 'visible',
                    md: searchFocused ? 'hidden' : 'visible',
                }}
                transform={{ base: 'translate(-50%, 0)', md: 'none' }}
                transition="all ease-out 200ms"
                _hover={{ cursor: 'pointer' }}
            >
                <Flex
                    alignContent={'center'}
                    alignItems={'center'}
                    bg="rgb(241, 73, 73, .7)"
                    backdropFilter="blur(2px)"
                    px="15px"
                    height="34px"
                    borderRadius="30px"
                    width={{ base: '200px', md: 'auto' }}
                    boxShadow={'0px 3px 20px -7px rgba(0, 0, 0, 0.2)'}
                    _focus={{
                        border: 'none',
                        background: 'red.500',
                    }}
                    _hover={{ background: 'red.500' }}
                    _active={{ background: 'red.500' }}
                >
                    <Center pos="relative" w="24px" h="24px" paddingTop="3px">
                        <Box pos="relative">
                            <LayerError color={'white'} boxSize={6} />
                            <Center
                                pos={'absolute'}
                                right="-4px"
                                top="-2px"
                                h="14px"
                                w="14px"
                                borderRadius={'10px'}
                                border="1px solid #E07175"
                                bg={'white'}
                            >
                                <Text
                                    fontSize="0.6rem"
                                    fontWeight="700"
                                    color="#E07175"
                                >
                                    {count}
                                </Text>
                            </Center>
                        </Box>
                    </Center>
                    <Box pos="relative" ml="10px">
                        <Text color="white" fontWeight={'bold'}>
                            Error Loading {count > 2 ? 'Layers' : 'Layer'}
                        </Text>
                    </Box>
                </Flex>
            </Box>
        </Fade>
    )
}

export default function LayerErrorModal() {
    const status = useRecoilValue(statusData)
    const online = useRecoilValue(onlineState)
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const modalVisibility = useRecoilValue(modalVisibilityState)

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowErrorModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'error', value })
        },
        [setModalVisibility]
    )

    const [errors, setErrors] = useState([])
    const setShowErrorBadge = useSetRecoilState(errorBadgeState)

    const { layers } = config

    // this moves Altitude Selected range down if the error badge is visible
    useEffect(() => {
        if (errors && errors.length > 0 && !modalVisibility.error) {
            setShowErrorBadge(true)
        } else {
            setShowErrorBadge(false)
        }
    }, [errors, modalVisibility, setShowErrorBadge])

    const { isOpen, onOpen, onClose } = useDisclosure()

    const errorRef = useRef(null)

    function refreshPage() {
        window.location.reload(false)
    }

    const onCloseHandler = useCallback(() => {
        onClose()
        setShowErrorModal(false)
    }, [onClose, setShowErrorModal])

    // This filters all layers from the status array that have been flagged for errors.
    useEffect(() => {
        if (status) {
            const err = status
                ? Object.keys(status).filter(
                      (layer) =>
                          Array.isArray(status[layer]['error']) &&
                          status[layer]['error'].length
                  )
                : []

            if (!err.length) {
                onCloseHandler()
            }

            const layersReportedAsOutdated = status
                ? Object.keys(status).filter(
                      (layer) => status[layer]['outdated']
                  )
                : []

            let errorArray
            if (err.length) {
                errorArray = err.map((e) => {
                    return {
                        id: e,
                        error: layersReportedAsOutdated.includes(e)
                            ? [...status[e]['error'], 'server']
                            : status[e]['error'],
                    }
                })
            } else {
                errorArray = layersReportedAsOutdated.map((e) => {
                    return {
                        id: e,
                        error: ['server'],
                    }
                })
            }

            errorArray = errorArray.filter((i) =>
                Object.keys(layerSelection[dataMode])
                    .filter((lyr) => layerSelection[dataMode][lyr])
                    .map((l) => l.toLowerCase())
                    .includes(i.id)
            )

            // console.log({ errorArray }, dataMode)
            // This checks if the value has changed and if the number of errors is greater. It will only inform the user of errors if additional errors are added not subtracted.
            if (
                !Array.isArray(errorRef.current) ||
                !isEqual(errorRef.current, errorArray)
            ) {
                // console.log({ errorArray })
                setErrors(errorArray)
            }
        }
    }, [status, layerSelection, dataMode, onCloseHandler])

    function layerErrorMessage(id, str) {
        if (str === 'version') {
            return (
                <>
                    <b>{layers[id]['label']}</b> data may be out of date and the
                    App has encountered an error when loading / updating on the
                    map
                </>
            )
        } else if (str === 'fetching') {
            return (
                <>
                    <b>{layers[id]['label']}</b> data may be out of date and the
                    App has encountered an error when loading / updating on the
                    map
                </>
            )
        } else {
            return (
                <>
                    <b>{layers[id]['label']}</b> data may be out of date as the
                    last time it was updated was{' '}
                    <b>
                        {formatDistance(
                            parseISO(status[id]['oldestupdated']),
                            new Date(),
                            { addSuffix: true }
                        )}
                        .
                    </b>
                </>
            )
        }
    }

    useEffect(() => {
        online && modalVisibility.error ? onOpen() : onCloseHandler()
        if (!online || modalVisibility.statusError) {
            onCloseHandler()
        }
    }, [
        modalVisibility.error,
        onOpen,
        onCloseHandler,
        online,
        modalVisibility.statusError,
    ])

    useEffect(() => {
        if (!Array.isArray(errorRef.current)) {
            errorRef.current = []
        }

        if (errorRef.current.length < errors.length) {
            errorRef.current = errors
            setShowErrorModal(!!errors.length)
        } else {
            errorRef.current = errors
        }
    }, [errors, setShowErrorModal])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LayerErrorButton
                show={!modalVisibility.error}
                count={errors.length}
                handleOpen={() => setShowErrorModal(true)}
            />
            <LightMode>
                <Modal
                    variant="alert"
                    size="md"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    isCentered
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            backgroundColor="white"
                            fontWeight="bold"
                            textColor="#FF4F44"
                            boxShadow={'0px 0px 15px -1px rgba(0,0,0,0.15)'}
                            borderBottom="1px solid #eaeaea"
                        >
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                mx="auto"
                            >
                                <LayerError />
                                <Text pl="10px" mr="10px" color="light.100">
                                    {errors && errors.length === 1
                                        ? `${
                                              layers[
                                                  errors[0]['id'].toLowerCase()
                                              ]['label']
                                          } layer error`
                                        : 'Error Loading Layers'}
                                </Text>
                            </Flex>
                        </ModalHeader>
                        <ModalBody>
                            <UnorderedList mt="15px" color="light.100">
                                {errors.map((e) => {
                                    return e.error.map((i) => (
                                        <ListItem mb="10px">
                                            {layerErrorMessage(
                                                e.id.toLowerCase(),
                                                i
                                            )}
                                        </ListItem>
                                    ))
                                })}
                            </UnorderedList>
                        </ModalBody>
                        <Divider mt="10px" />
                        <Text
                            px="30px"
                            py="20px"
                            color="#F14949"
                            fontSize="0.9rem"
                            fontWeight="bold"
                            textAlign={'center'}
                        >
                            This message will disappear when the data has
                            loaded/updated
                        </Text>
                        <ModalFooter py={5} borderTop="1px solid #eaeaea">
                            <Flex w="100%">
                                <Button
                                    colorScheme="gray"
                                    color="light.100"
                                    variant="outline"
                                    borderRadius="10px"
                                    // border="1px solid #E1E7EE"
                                    mr="5px"
                                    size="sm"
                                    onClick={onCloseHandler}
                                    w="100%"
                                    _hover={{
                                        background: 'light.20',
                                    }}
                                    _active={{
                                        background: 'light.20',
                                    }}
                                    {...noFocus}
                                >
                                    Close
                                </Button>
                                <Button
                                    background="#FF4F44"
                                    color="light.10"
                                    borderRadius="10px"
                                    ml="5px"
                                    size="sm"
                                    _focus={{ border: 'none' }}
                                    onClick={refreshPage}
                                    w="100%"
                                    _hover={{
                                        background: '#E34339',
                                    }}
                                    _active={{
                                        background: '#E34339',
                                    }}
                                    {...noFocus}
                                >
                                    Refresh
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
