import React from 'react'
import { Flex, Text, useBreakpointValue, Icon } from '@chakra-ui/react'
import TemperatureIcon from '../../../../../icons/menu-icons/TemperatureIcon'
import DewpointIcon from '../../../../../icons/menu-icons/DewPointIcon'
import { TempDial } from '../../graphics/WeatherGraphics'

export default function AwsTempAndDewpoint(props) {
    const { temp, dewpoint, cloudLayers, infotabGraphicsDisabled } = props

    const imageSize = useBreakpointValue({
        base: '100',
        md: '90',
    })
    const tempValue = temp || temp === 0
    const dewpointValue = dewpoint || dewpoint === 0

    return (
        <Flex // TEMP AND DEWPOINT
            width="100%"
            gap={!infotabGraphicsDisabled && '20px'}
            flexDirection={infotabGraphicsDisabled ? 'column' : 'row'}
        >
            <Flex
                order="1"
                width="100%"
                background="light.10"
                boxShadow={
                    !infotabGraphicsDisabled
                        ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                        : 'none'
                }
                filter={
                    infotabGraphicsDisabled
                        ? 'none'
                        : 'contrast(102%) saturate(102%)'
                }
                borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
                pt={
                    infotabGraphicsDisabled &&
                    (!cloudLayers || (cloudLayers && cloudLayers.length < 1))
                        ? '0px'
                        : '10px'
                }
                pl={{
                    base: '15px',
                    md: '15px',
                }}
                pr={{
                    base: '15px',
                    md: '15px',
                }}
                pb="10px"
                direction={{
                    base: 'column',
                    md: 'column',
                }}
            >
                <Flex
                    direction="row"
                    justifyContent={{
                        base: 'space-between',
                        md: 'flex-start',
                    }}
                >
                    <Flex
                        width={{
                            base: '41px',
                            md: '43px',
                        }}
                        ml={infotabGraphicsDisabled ? '-2px' : '-10px'}
                        color="#9DB2CD"
                    >
                        <TemperatureIcon boxSize="36px" />
                    </Flex>
                    <Flex
                        direction="column"
                        width="100%"
                        alignItems={
                            infotabGraphicsDisabled ? 'flex-start' : 'flex-end'
                        }
                        pr="3px"
                        pl={infotabGraphicsDisabled ? '15px' : '10px'}
                    >
                        <Text
                            whiteSpace={{
                                base: 'pre',
                                md: 'unset',
                            }}
                            fontSize={{
                                base: tempValue ? '1.2rem' : '0.85rem',
                                xs: tempValue ? '1.4rem' : '1rem',
                                md: tempValue ? '1.2rem' : '0.8rem',
                            }}
                            lineHeight="1.2"
                            pt={{
                                base: tempValue ? '4px' : '7px',
                                md: tempValue ? '5px' : '10px',
                            }}
                            textAlign="right"
                            fontWeight="600"
                        >
                            {temp === 0 // TODO should be <= 0
                                ? '0 °C'
                                : temp
                                ? `${temp} °C`
                                : 'Not available'}
                        </Text>
                    </Flex>
                </Flex>
                {!infotabGraphicsDisabled && (
                    <Flex
                        pt="3px"
                        pb="0px"
                        width="100%"
                        justifyContent={{
                            base: 'center',
                            md: 'center',
                        }}
                        alignItems={{
                            base: 'center',
                            md: 'center',
                        }}
                    >
                        <Icon
                            as={TempDial}
                            imageSize={imageSize}
                            primaryImageColor={'#3e5e88'}
                            focusImageColor={`hsl(${
                                temp < 3
                                    ? 220
                                    : temp > 30
                                    ? 0
                                    : 220 - (temp - 3) * 8
                            }, 100%, 50%)`}
                            imageColor={'#83909F'}
                            data={temp}
                            type={'TEMP'}
                        />
                    </Flex>
                )}
            </Flex>

            <Flex
                order="2"
                width="100%"
                background="light.10"
                boxShadow={
                    !infotabGraphicsDisabled
                        ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                        : 'none'
                }
                filter={
                    infotabGraphicsDisabled
                        ? 'none'
                        : 'contrast(102%) saturate(102%)'
                }
                borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
                pt="10px"
                pl={{
                    base: '15px',
                    md: '15px',
                }}
                pr={{
                    base: '15px',
                    md: '15px',
                }}
                pb="10px"
                direction={{
                    base: 'column',
                    md: 'column',
                }}
            >
                <Flex
                    direction="row"
                    justifyContent={{
                        base: 'space-between',
                        md: 'flex-start',
                    }}
                >
                    <Flex
                        width={{
                            base: '41px',
                            md: '43px',
                        }}
                        ml={infotabGraphicsDisabled ? '-2px' : '-10px'}
                        color="#9DB2CD"
                    >
                        <DewpointIcon boxSize="36px" />
                    </Flex>
                    <Flex
                        direction="column"
                        width="100%"
                        alignItems={
                            infotabGraphicsDisabled ? 'flex-start' : 'flex-end'
                        }
                        pr="3px"
                        pl={infotabGraphicsDisabled ? '15px' : '10px'}
                    >
                        <Text
                            whiteSpace={{
                                base: 'pre',
                                md: 'unset',
                            }}
                            fontSize={{
                                base: dewpointValue ? '1.2rem' : '0.85rem',
                                xs: dewpointValue ? '1.4rem' : '1rem',
                                md: dewpointValue ? '1.2rem' : '0.8rem',
                            }}
                            lineHeight="1.2"
                            pt={{
                                base: dewpointValue ? '4px' : '7px',
                                md: dewpointValue ? '5px' : '10px',
                            }}
                            textAlign="right"
                            fontWeight="600"
                        >
                            {dewpoint === 0 // TODO should be <= 0
                                ? '0 °C'
                                : dewpoint
                                ? `${dewpoint} °C`
                                : 'Not Available'}
                        </Text>
                    </Flex>
                </Flex>
                {!infotabGraphicsDisabled && (
                    <Flex
                        pt="3px"
                        pb="0px"
                        width="100%"
                        justifyContent={{
                            base: 'center',
                            md: 'center',
                        }}
                        alignItems={{
                            base: 'center',
                            md: 'center',
                        }}
                    >
                        <Icon
                            as={TempDial}
                            imageSize={imageSize}
                            primaryImageColor={'#3e5e88'}
                            focusImageColor={`hsl(${
                                dewpoint < 3
                                    ? 220
                                    : dewpoint > 30
                                    ? 0
                                    : 220 - (dewpoint - 3) * 8
                            }, 100%, 50%)`}
                            imageColor={'#83909F'}
                            data={dewpoint}
                            type={'DEWPOINT'}
                        />
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}
