import React, { useEffect, useContext, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { MapContext, Source, Layer } from 'react-mapbox-gl'
import { layerSelectionState, basemapEnhanceState } from '../../../globalState'

export default function VNCLayer() {
    const layerSelection = useRecoilValue(layerSelectionState)
    const basemapEnhance = useRecoilValue(basemapEnhanceState)
    const mapInstance = useContext(MapContext)
    const layerVisibilityRef = useRef(null)

    //TODO Test - This useEffect takes a ref of the state of the mapbox layers when the VNC layer is turned on, and subsequently hides the underlying mapbox layers.
    // Just wondering if there's anything else that turns off / on visibilty of mapbox layers, and therefore this ref could mutate over time if users are using other functionality in the app
    useEffect(() => {
        const layers = mapInstance.getStyle().layers
        if (layerSelection.constant.vnc) {
            layerVisibilityRef.current = layers.map((layer) => ({
                id: layer.id,
                source: layer.source,
                layout: {
                    visibility:
                        layer.layout && layer.layout.visibility
                            ? layer.layout.visibility
                            : undefined,
                },
            }))
            layers.forEach((layer) => {
                layer.id !== 'water' &&
                    layer.source === 'composite' &&
                    mapInstance.setLayoutProperty(
                        layer.id,
                        'visibility',
                        'none'
                    )
            })
        }
        if (!layerSelection.constant.vnc && layerVisibilityRef.current) {
            layerVisibilityRef.current.forEach((layer) => {
                layer.id !== 'water' &&
                    layer.source === 'composite' &&
                    mapInstance.setLayoutProperty(
                        layer.id,
                        'visibility',
                        layer.layout.visibility
                    )
            })
        }
    }, [layerSelection.constant.vnc, mapInstance])

    if (layerSelection.constant.vnc) {
        return (
            <>
                <Source
                    id="vnc-500k"
                    tileJsonSource={{
                        type: 'raster',
                        url: 'mapbox://aeropath.NZ_eVNC_500k_24-12',
                    }}
                />
                <Source
                    id="vnc-250k"
                    tileJsonSource={{
                        type: 'raster',
                        url: 'mapbox://aeropath.NZ_eVNC_250k_24-12',
                    }}
                />
                <Layer
                    id="vnc-500k"
                    type="raster"
                    sourceId="vnc-500k"
                    before="country-label"
                    maxZoom={9}
                    paint={{
                        'raster-saturation': basemapEnhance ? -0.2 : -0.66,
                        'raster-resampling': 'nearest',
                        'raster-opacity': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            8.5,
                            1,
                            9,
                            0,
                        ],
                    }}
                />
                <Layer
                    id="vnc-250k"
                    type="raster"
                    sourceId="vnc-250k"
                    before="vnc-500k"
                    minZoom={7.5}
                    paint={{
                        'raster-saturation': basemapEnhance ? -0.2 : -0.66,
                        'raster-resampling': 'nearest',
                        'raster-opacity': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            7.5,
                            0,
                            9.5,
                            1,
                        ],
                    }}
                />
            </>
        )
    }
    return null
}
