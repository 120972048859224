import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloudEnhancedInfraredIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_702:31204)">
                {/* <g opacity="0.2">
                    <path
                        d="M3.72898 11.6297C3.48866 11.8699 3.00004 12.6655 3 13.4231C3 15.1103 3.68595 15.8771 4.52656 16.2225C4.54334 16.0564 4.5681 15.8906 4.60088 15.7258C4.7805 14.8228 5.19395 13.985 5.79716 13.2949C5.05863 12.7345 4.85289 12.1103 4.45796 10.8611C4.3378 10.9812 4.21564 11.1133 4.09347 11.2454C3.97131 11.3775 3.84914 11.5096 3.72898 11.6297Z"
                        fill="currentColor"
                    />
                    <path
                        d="M14.5351 14.59C18.145 14.3532 17.2164 11.0364 15.8787 9.06774C16.6077 8.84355 18.3301 9.2176 19.0376 9.58014C19.6991 10.0106 20.2258 10.4556 20.5436 11.2134C20.829 11.8939 21.0696 13.1754 20.9816 13.6793C20.8249 14.576 20.4315 15.0376 20.2526 15.2165C20.203 15.2661 20.1461 15.3268 20.0826 15.3946C19.6915 15.8121 19.0496 16.4975 18.3086 16.4975L14.9939 16.4974C14.9621 15.8367 14.8058 15.1897 14.5351 14.59Z"
                        fill="currentColor"
                    />
                </g> */}
                <path
                    d="M17.5 17.5H14.9468C14.9819 17.255 15 17.0046 15 16.75C15 16.4954 14.9819 16.245 14.9468 16H17.5C19.1569 16 20.5 14.6569 20.5 13C20.5 11.3879 19.123 10.0728 17.625 10.0029C17.5954 10.0015 17.5658 10.0006 17.5362 10.0002C17.5241 10.0001 17.5121 10 17.5 10C17.4752 10 17.4505 10.0003 17.4259 10.0009C16.5672 10.0218 15.798 10.4034 15.264 11L14.1563 9.98858C14.6065 9.48893 15.1672 9.0907 15.7998 8.83233C14.9336 7.43612 13.3735 6.5 11.75 6.5C9.21051 6.5 7.13647 8.49286 7.00647 11H6C4.61929 11 3.5 12.1193 3.5 13.5C3.5 14.3742 3.94875 15.1437 4.62847 15.5905C4.54438 15.9635 4.5 16.3516 4.5 16.75C4.5 16.9075 4.50694 17.0635 4.52053 17.2175C3.04375 16.6292 2 15.1865 2 13.5C2 11.3762 3.65521 9.63895 5.746 9.50794C6.50027 6.90385 8.9028 5 11.75 5C14.0505 5 16.2543 6.42855 17.3251 8.50334C17.3795 8.50126 17.4342 8.50014 17.489 8.50001L17.5 8.5C19.8182 8.5 22.0002 10.5147 22.0002 13C22.0002 14.1802 21.5458 15.2542 20.8026 16.0568C19.9806 16.9445 18.8051 17.5 17.5 17.5Z"
                    fill="currentColor"
                />
                <path
                    d="M10.25 14.5C10.25 14.7761 10.0261 15 9.75 15C9.47386 15 9.25 14.7761 9.25 14.5C9.25 14.2239 9.47386 14 9.75 14C10.0261 14 10.25 14.2239 10.25 14.5Z"
                    fill="currentColor"
                />
                <path
                    d="M8 16.75C8 17.0261 7.77614 17.25 7.5 17.25C7.22386 17.25 7 17.0261 7 16.75C7 16.4739 7.22386 16.25 7.5 16.25C7.77614 16.25 8 16.4739 8 16.75Z"
                    fill="currentColor"
                />
                <path
                    d="M12 17.25C12.2761 17.25 12.5 17.0261 12.5 16.75C12.5 16.4739 12.2761 16.25 12 16.25C11.7239 16.25 11.5 16.4739 11.5 16.75C11.5 17.0261 11.7239 17.25 12 17.25Z"
                    fill="currentColor"
                />
                <path
                    d="M10.25 19C10.25 19.2761 10.0261 19.5 9.75 19.5C9.47386 19.5 9.25 19.2761 9.25 19C9.25 18.7239 9.47386 18.5 9.75 18.5C10.0261 18.5 10.25 18.7239 10.25 19Z"
                    fill="currentColor"
                />
                <path
                    d="M8.51261 15.5126C8.70787 15.3173 8.70787 15.0007 8.51261 14.8055C8.31735 14.6102 8.00077 14.6102 7.80551 14.8055C7.61024 15.0007 7.61024 15.3173 7.80551 15.5126C8.00077 15.7078 8.31735 15.7078 8.51261 15.5126Z"
                    fill="currentColor"
                />
                <path
                    d="M8.51261 17.9874C8.70787 18.1827 8.70787 18.4993 8.51261 18.6945C8.31735 18.8898 8.00077 18.8898 7.80551 18.6945C7.61024 18.4993 7.61024 18.1827 7.80551 17.9874C8.00077 17.7922 8.31735 17.7922 8.51261 17.9874Z"
                    fill="currentColor"
                />
                <path
                    d="M11.6946 15.5126C11.8899 15.3173 11.8899 15.0007 11.6946 14.8055C11.4993 14.6102 11.1827 14.6102 10.9875 14.8055C10.7922 15.0007 10.7922 15.3173 10.9875 15.5126C11.1827 15.7078 11.4993 15.7078 11.6946 15.5126Z"
                    fill="currentColor"
                />
                <path
                    d="M11.6946 17.9874C11.8899 18.1827 11.8899 18.4993 11.6946 18.6945C11.4993 18.8898 11.1827 18.8898 10.9875 18.6945C10.7922 18.4993 10.7922 18.1827 10.9875 17.9874C11.1827 17.7922 11.4993 17.7922 11.6946 17.9874Z"
                    fill="currentColor"
                />
                <path
                    d="M9.75 17.75C10.3023 17.75 10.75 17.3023 10.75 16.75C10.75 16.1977 10.3023 15.75 9.75 15.75C9.19772 15.75 8.75 16.1977 8.75 16.75C8.75 17.3023 9.19772 17.75 9.75 17.75Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.75 21C8.90943 21 8.08773 20.7508 7.38883 20.2838C6.68992 19.8168 6.14518 19.153 5.82351 18.3764C5.50184 17.5998 5.41768 16.7453 5.58167 15.9208C5.74565 15.0964 6.15042 14.3392 6.7448 13.7448C7.33917 13.1504 8.09645 12.7457 8.92087 12.5817C9.74529 12.4177 10.5998 12.5018 11.3764 12.8235C12.153 13.1452 12.8167 13.69 13.2837 14.3889C13.7507 15.0878 14 15.9094 14 16.75C13.9987 17.8768 13.5505 18.957 12.7537 19.7537C11.957 20.5505 10.8768 20.9987 9.75 21ZM9.75 13.31C9.06963 13.31 8.40454 13.5118 7.83884 13.8898C7.27313 14.2678 6.83222 14.805 6.57185 15.4336C6.31149 16.0622 6.24337 16.7538 6.3761 17.4211C6.50883 18.0884 6.83646 18.7013 7.31755 19.1824C7.79864 19.6635 8.41159 19.9912 9.07889 20.1239C9.74618 20.2566 10.4379 20.1885 11.0664 19.9282C11.695 19.6678 12.2323 19.2268 12.6103 18.6611C12.9882 18.0954 13.19 17.4304 13.19 16.75C13.1887 15.8381 12.8258 14.9639 12.181 14.319C11.5361 13.6742 10.6619 13.3113 9.75 13.31Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
