import React from 'react'
import { useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import useUserRoles, { useTier } from '../auth/hooks'
import { SimpleGrid, Button, Text, Link } from '@chakra-ui/react'

import { simulateFreeUserState } from '../globalState'

import PricingCard from './PricingCard'

export default function PricingStructure() {
    const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0()
    const userRoles = useUserRoles()
    const userTier = useTier()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <SimpleGrid
            columns={{
                base: 1,
                lg: 3,
            }}
            spacing={{
                base: '16',
                lg: '0',
            }}
            maxW="6xl"
            mx="auto"
            justifyItems="center"
            alignItems="center"
        >
            <PricingCard // FREE PLAN
                isFree
                zIndex="1"
                data={{
                    price: 'FREE',
                    title: 'FREE',
                    subtitle: 'For Everyone',
                    features: [
                        'SFC – 10,000ft',
                        'Basic map and satellite overlay',
                        'Basic aerodrome data (incl. NOTAM, METAR, TAF, and ATIS)',
                        'Airspace, Hazards, Obstacles, Nav-Aids, Visual Reporting Points, ICAO Waypoints',
                        'SIGWX and SIGMET Charts, Space Weather Advisories',
                        'Aviation Area Winds, GRAFOR Charts, Basic MSL Charts',
                        'Standard briefing functionality',
                    ],
                }}
                colorTheme={'#C7D3E1'}
                // icon={}
                button={
                    <Button
                        {...noFocus}
                        height={{ base: '72px', md: '56px' }}
                        minHeight={{ base: '72px', md: '56px' }}
                        mt={{ base: '25px', md: '0px' }}
                        mb="-1px"
                        borderWidth="2px"
                        bg="brand.free"
                        color="#657990"
                        size="md"
                        border="none"
                        borderRadius="0px"
                        w="full"
                        letterSpacing="wider"
                        fontWeight="extrabold"
                        py={{
                            base: '9',
                            md: '7',
                        }}
                        _hover={{
                            bg:
                                user && userTier >= 1
                                    ? 'brand.free'
                                    : 'brand.freeHover',
                        }}
                        _focus={{
                            bg:
                                user && userTier >= 1
                                    ? 'brand.free'
                                    : 'brand.freeHover',
                        }}
                        _active={{
                            bg:
                                user && userTier >= 1
                                    ? 'brand.free'
                                    : 'brand.freeHover',
                        }}
                        cursor={user && userTier >= 1 ? 'initial' : 'pointer'}
                        onClick={() => {
                            !user &&
                                loginWithRedirect({
                                    action: 'signup',
                                })
                        }}
                    >
                        {user ? (
                            userTier >= 2 && !simulateFreeUser ? (
                                'INCLUDED'
                            ) : (
                                'YOUR CURRENT PLAN'
                            )
                        ) : (
                            <Text>REGISTER</Text>
                        )}
                    </Button>
                }
            />

            <PricingCard // PLUS PLAN
                included={'FREE'}
                zIndex={{
                    base: '1',
                    md: '2',
                }}
                isPremium
                isMonthly
                transform={{
                    lg: 'scale(1.05)',
                }}
                data={{
                    price: '$8.50',
                    title: 'PLUS',
                    subtitle: 'For Individuals',
                    features: [
                        'SFC – FL600',
                        'Advanced aerodrome data (incl. decoded TAF and METAR, Webcams, and Charts)',
                        'SIGMET and SIGWX on the map',
                        'Advanced rain radar / forecast',
                        'Advanced cloud imagery / forecast',
                        'Additional MSL analysis charts',
                        'Automatic Weather Stations',
                        'Custom briefing areas',
                        'Save briefing favourites',
                        'Visual Navigation Chart and LINZ Topo basemaps',
                        'Convert UTC to local NZST/NZDT time',
                        'Map Ruler',
                        'Advisory Mode - the best pre-flight situational awareness tool available for NZ airspace',
                    ],
                    comments: [
                        'view En-route NOTAMs graphically on the map',
                        // 'view RPAS flights logged in Airshare',
                        'view SIGMET and SIGWX alongside other dynamic information',
                    ],
                }}
                colorTheme={'brand.plus'}
                // icon={}
                button={
                    <Button
                        {...noFocus}
                        height={{ base: '72px', md: '56px' }}
                        minHeight={{ base: '72px', md: '56px' }}
                        mt={{ base: '0', md: '0px' }}
                        mb="-1px"
                        borderWidth="2px"
                        bg="brand.plus"
                        color="white"
                        size="md"
                        border="none"
                        borderRadius="0px"
                        w="full"
                        letterSpacing="wider"
                        fontWeight="extrabold"
                        py={{
                            base: '9',
                            md: '7',
                        }}
                        _hover={{
                            bg:
                                user && userTier >= 2 && !simulateFreeUser
                                    ? 'brand.plus'
                                    : 'brand.plusHover',
                        }}
                        _active={{
                            bg:
                                user && userTier >= 2 && !simulateFreeUser
                                    ? 'brand.plus'
                                    : 'brand.plusHover',
                        }}
                        _focus={{
                            bg:
                                user && userTier >= 2 && !simulateFreeUser
                                    ? 'brand.plus'
                                    : 'brand.plusHover',
                        }}
                        onClick={
                            user
                                ? userTier < 2
                                    ? async () => {
                                          const accessToken =
                                              await getAccessTokenSilently()
                                          fetch(
                                              `${window.location.origin}/buysubscription`,
                                              {
                                                  headers: {
                                                      authorization: `Bearer ${accessToken}`,
                                                  },
                                              }
                                          )
                                              .then((res) => res.json())
                                              .then((data) =>
                                                  window.location.replace(
                                                      data.url
                                                  )
                                              )
                                      }
                                    : undefined
                                : () => {
                                      loginWithRedirect({
                                          redirectUri: `${window.location.origin}/app`,
                                      })
                                  }
                        }
                        cursor={
                            user && userTier >= 2 && !simulateFreeUser
                                ? 'initial'
                                : 'pointer'
                        }
                    >
                        {user && userRoles ? (
                            userTier >= 3 && !simulateFreeUser ? (
                                'INCLUDED'
                            ) : userRoles.includes('Plus') &&
                              !simulateFreeUser ? (
                                'YOUR CURRENT PLAN'
                            ) : (
                                'SUBSCRIBE'
                            )
                        ) : (
                            <Text pr="5px">SUBSCRIBE</Text>
                        )}
                    </Button>
                }
            />
            <PricingCard // PRO PLAN
                included={'PLUS'}
                zIndex="1"
                isCommercial
                isPremium
                data={{
                    price: 'POA',
                    title: 'PRO',
                    subtitle: 'For Organisations',
                    features: [
                        'Multiple users',
                        // 'Lightning',
                    ],
                }}
                colorTheme={'brand.pro'}
                button={
                    <Link
                        mb="-1px"
                        {...noFocus}
                        // cursor="default"
                        borderWidth="2px"
                        bg="brand.pro"
                        color="white"
                        size="md"
                        border="none"
                        borderRadius="0px"
                        w="full"
                        height={{ base: '72px', md: '56px' }}
                        minHeight={{ base: '72px', md: '56px' }}
                        letterSpacing="wider"
                        fontWeight="extrabold"
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        _hover={{
                            bg:
                                user && userTier >= 3 && !simulateFreeUser
                                    ? 'brand.pro'
                                    : 'brand.proHover',
                            cursor:
                                user && userTier >= 3 && !simulateFreeUser
                                    ? 'default'
                                    : 'pointer',
                        }}
                        _active={{
                            bg:
                                user && userTier >= 3 && !simulateFreeUser
                                    ? 'brand.pro'
                                    : 'brand.proHover',
                        }}
                        _focus={{
                            bg:
                                user && userTier >= 3 && !simulateFreeUser
                                    ? 'brand.pro'
                                    : 'brand.proHover',
                        }}
                        href={
                            userTier < 3
                                ? 'mailto:aviationsales@metservice.com?subject=PreFlight PRO Plan Enquiry'
                                : undefined
                        }
                    >
                        {user ? (
                            userTier >= 3 && !simulateFreeUser ? (
                                'YOUR CURRENT PLAN'
                            ) : (
                                'CONTACT US'
                            )
                        ) : (
                            <Text pr="5px">CONTACT US</Text>
                        )}
                    </Link>
                }
            />
        </SimpleGrid>
    )
}
