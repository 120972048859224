import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useInView } from 'react-intersection-observer'
import {
    Flex,
    Box,
    Text,
    Button,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Portal,
    useBreakpointValue,
    Tooltip,
} from '@chakra-ui/react'
import { isMobile, isDesktop } from 'react-device-detect'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import { parseISO, formatDistanceToNow } from 'date-fns'
import Select from 'react-select'
import ErrorText from '../../../components/ErrorText'
import useConfirm from '../../../alerts/warning-message/components/useConfirm'
import { AltExcludedAerodromesWarningMessage } from '../../../alerts/warning-message/components/WarningMessages'
import {
    excludedAerodromesState,
    inViewPositionState,
    aerodromesSortState,
    showOutdatedErrorsState,
    collapseDropdownsState,
    briefingMetarCountState,
    firstCloseState,
    timeZoneState,
} from '../../../../../globalState'

import CloseIcon from '../../../../../icons/menu-icons/CloseIcon'
import { ReactComponent as MetarIcon } from '../../../../../icons/assets/metar.svg'

export default function METARBriefing(props) {
    const { briefingData } = props

    const timeZone = useRecoilValue(timeZoneState)
    const aerodromesSort = useRecoilValue(aerodromesSortState)
    const [excludedAerodromes, setExcludedAerodromes] = useRecoilState(
        excludedAerodromesState
    )
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const [firstClose, setFirstClose] = useRecoilState(firstCloseState)
    const { isConfirmed } = useConfirm() // alert message handler

    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const [briefingMetarCount, setBriefingMetarCount] = useRecoilState(
        briefingMetarCountState
    )

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize } = useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })

    const filteredMetar =
        briefingData &&
        briefingData.res.aerodromes &&
        briefingData.res.aerodromes.filter((filter) => filter.metar !== null)

    const filteredDesignators = filteredMetar?.map((value) =>
        excludedAerodromes.includes(value.designator)
    )
    const filteredItems =
        filteredDesignators && filteredDesignators.includes(false)

    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : filteredMetar.map((value, i) => i)
    )

    useEffect(() => {
        setExpandedDropdowns(
            collapseDropdowns ? [] : filteredMetar.map((value, i) => i)
        )
        // eslint-disable-next-line
    }, [collapseDropdowns])

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()

    const metarNotes = useBreakpointValue({
        base: { containerRef: mobile },
        lg: { containerRef: desktop },
    })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        inView && setInViewPosition('metarContainer')
    }, [inView, setInViewPosition])

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    const metarOptions = [
        {
            label: 'Latest',
            value: 1,
        },
        {
            label: 'Last 2',
            value: 2,
        },
        {
            label: 'Last 3',
            value: 3,
        },
        {
            label: 'Last 6',
            value: 6,
        },
        {
            label: 'Last 12',
            value: 12,
        },
        {
            label: 'Last 18',
            value: 18,
        },
        {
            label: 'Last 24',
            value: 24,
        },
    ]

    // console.log(briefingData, 'briefingData')
    // console.log(filteredMetar, 'filteredMetar')

    return (
        <>
            <div
                id="metarContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            <Box zIndex="10">
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', md: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    marginTop="25px"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#07578A"
                        backgroundImage="linear-gradient(345deg, #3B9EDC 0%, #004D7F 80%)"
                        filter={{
                            base: isMobile && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="44px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="space-between"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Flex
                                my="auto"
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex marginLeft="2px" alignItems="center">
                                    <MetarIcon
                                        width="24px"
                                        height="24px"
                                        color="white"
                                    />
                                </Flex>
                                <Text
                                    paddingLeft="15px"
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    METAR
                                </Text>
                            </Flex>
                            <Box ml="auto" ref={desktop}></Box>
                        </Flex>
                    </Flex>
                    {filteredItems && (
                        <>
                            <Portal
                                style={{
                                    height: '100%',
                                }}
                                {...metarNotes}
                            >
                                <Flex
                                    height="28px"
                                    transition="all ease 600ms"
                                    justifyContent="center"
                                    alignItems={{
                                        base: 'center',
                                        lg: 'center',
                                    }}
                                    flexDirection={{
                                        base: 'row',
                                        xs: 'row',
                                        lg: 'row',
                                    }}
                                    position="relative"
                                    width="100%"
                                    borderBottom={{
                                        base: '1px solid #edeeef',
                                        xs: 'none',
                                        sm: 'none',
                                    }}
                                    gap={{
                                        base: '5px',
                                        xs: '8px',
                                        sm: '10px',
                                    }}
                                    color={{
                                        base: 'light.400',
                                        lg: 'whiteAlpha.800',
                                    }}
                                    bg={{ base: 'light.20', lg: 'none' }}
                                >
                                    <Text
                                        opacity={timeZone !== 'UTC' ? '1' : '0'}
                                        visibility={
                                            timeZone !== 'UTC'
                                                ? 'visible'
                                                : 'hidden'
                                        }
                                        width={
                                            timeZone !== 'UTC' ? '142px' : '0px'
                                        }
                                        fontSize={
                                            timeZone !== 'UTC' ? '11px' : '0px'
                                        }
                                        textAlign="end"
                                        whiteSpace="pre-wrap"
                                        transition="all ease 600ms"
                                    >
                                        Time references are in UTC.
                                    </Text>

                                    <Text
                                        display={{
                                            base: 'block',
                                            smd: 'none',
                                            md: 'block',
                                            ml: 'none',
                                        }}
                                        textAlign="end"
                                        whiteSpace="pre-wrap"
                                        fontSize="11px"
                                    >
                                        All heights in feet AGL.
                                    </Text>
                                    <Text
                                        display={{
                                            base: 'none',
                                            smd: 'block',
                                            md: 'none',
                                            ml: 'block',
                                        }}
                                        textAlign="end"
                                        whiteSpace="pre-wrap"
                                        fontSize="11px"
                                    >
                                        All heights are in feet above aerodrome
                                        level.
                                    </Text>
                                    <Text
                                        textAlign="end"
                                        whiteSpace="pre-wrap"
                                        fontSize="11px"
                                    >
                                        Wind: °True
                                    </Text>
                                </Flex>
                            </Portal>
                            <Box ref={mobile}></Box>
                        </>
                    )}

                    <Accordion
                        border="none"
                        allowToggle
                        allowMultiple
                        index={expandedDropdowns}
                        onChange={(expandedIndex) => {
                            setExpandedDropdowns(expandedIndex)
                        }}
                    >
                        {filteredMetar
                            .sort((a, b) =>
                                aerodromesSort
                                    ? a.name > b.name
                                    : a.lat < b.lat
                                    ? 1
                                    : -1
                            )
                            .map((aerodrome, index, arr) => {
                                if (
                                    excludedAerodromes.includes(
                                        aerodrome.designator
                                    )
                                ) {
                                    return null
                                } else {
                                    return (
                                        <Flex
                                            width="100%"
                                            key={`AerodromesMetar${index}`}
                                        >
                                            <AccordionItem
                                                width="100%"
                                                borderStyle="none"
                                                index="1"
                                                zIndex={arr.length + 1 - index}
                                                position="relative"
                                            >
                                                {({ isExpanded }) => (
                                                    <>
                                                        <Flex
                                                            boxShadow={
                                                                isExpanded &&
                                                                '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                            }
                                                        >
                                                            <AccordionButton
                                                                zIndex="2"
                                                                height={{
                                                                    base: '50px',
                                                                    lg: '46px',
                                                                }}
                                                                minHeight={{
                                                                    base: '50px',
                                                                    lg: '46px',
                                                                }}
                                                                sx={{
                                                                    clipPath:
                                                                        'inset(0px 0px -50px 0px)',
                                                                }}
                                                                pl="1.2rem"
                                                                pr={{
                                                                    base: 1,
                                                                    sm: 2,
                                                                    md: 1,
                                                                    ml: 2,
                                                                }}
                                                                _hover={{
                                                                    background:
                                                                        isExpanded
                                                                            ? 'light.90'
                                                                            : 'light.30',
                                                                    cursor: 'pointer',
                                                                }}
                                                                _active={{
                                                                    background:
                                                                        'gray.100',
                                                                    cursor: 'pointer',
                                                                }}
                                                                color={
                                                                    '#1778b7'
                                                                }
                                                                background={
                                                                    isExpanded
                                                                        ? 'light.25'
                                                                        : 'white'
                                                                }
                                                                opacity={'1'}
                                                                borderTop={
                                                                    '1px solid'
                                                                }
                                                                borderColor="#efefef"
                                                            >
                                                                {/* <AerodromeIcon
                                                                    width="24px"
                                                                    height="24px"
                                                                /> */}

                                                                <Flex
                                                                    flex="2"
                                                                    textAlign="left"
                                                                    whiteSpace="pre"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    width="100%"
                                                                    pl="5px"
                                                                    pr="10px"
                                                                >
                                                                    <Text
                                                                        fontSize={
                                                                            adjustLabelFontSize
                                                                        }
                                                                        lineHeight="1.2"
                                                                        fontWeight={
                                                                            isExpanded
                                                                                ? '600'
                                                                                : '500'
                                                                        }
                                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                        whiteSpace="pre-line"
                                                                    >
                                                                        {
                                                                            aerodrome.name
                                                                        }
                                                                    </Text>
                                                                    <Text
                                                                        minWidth={{
                                                                            base: '44px',
                                                                            sm: '48px',
                                                                            lg: '52px',
                                                                        }}
                                                                        pl="5px"
                                                                        fontSize={
                                                                            adjustLabelFontSize
                                                                        }
                                                                        lineHeight="1.2"
                                                                        fontWeight="400"
                                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                        whiteSpace="pre-line"
                                                                        textAlign="right"
                                                                    >
                                                                        {
                                                                            aerodrome.designator
                                                                        }
                                                                    </Text>
                                                                </Flex>

                                                                <Flex>
                                                                    {isExpanded ? (
                                                                        <AccordionIcon transform="rotate(0deg)" />
                                                                    ) : (
                                                                        <AccordionIcon transform="rotate(-90deg)" />
                                                                    )}
                                                                </Flex>
                                                            </AccordionButton>

                                                            <Tooltip
                                                                isDisabled={
                                                                    isMobile ||
                                                                    !isDesktop
                                                                }
                                                                label={`Remove ${aerodrome.name} from the briefing`}
                                                                hasArrow
                                                                borderRadius="7px"
                                                                height="25px"
                                                                fontSize="0.7rem"
                                                                alignItems="center"
                                                                display="flex"
                                                                placement="left"
                                                                m="0px"
                                                                color="light.10"
                                                                bgColor={
                                                                    'gray.500'
                                                                }
                                                            >
                                                                <Button
                                                                    zIndex="1"
                                                                    clipPath="inset(0px 0px -50px 0px)"
                                                                    color={
                                                                        'gray.300'
                                                                    }
                                                                    background={
                                                                        isExpanded
                                                                            ? 'light.25'
                                                                            : 'white'
                                                                    }
                                                                    variant="unstyled"
                                                                    fontSize="11px"
                                                                    fontWeight="600"
                                                                    height={{
                                                                        base: '50px',
                                                                        lg: '46px',
                                                                    }}
                                                                    width={{
                                                                        base: '60px',
                                                                        lg: '64px',
                                                                    }}
                                                                    borderTop={
                                                                        '1px solid'
                                                                    }
                                                                    borderColor="#efefef"
                                                                    justifyContent="initial"
                                                                    py="2px"
                                                                    borderRadius="0px"
                                                                    {...noFocus}
                                                                    onClick={async () => {
                                                                        if (
                                                                            firstClose
                                                                        ) {
                                                                            const acknowledgeExcludedAerodromes =
                                                                                await isConfirmed(
                                                                                    // "Excluded aerodromes?"
                                                                                    'confirm-ok',
                                                                                    <AltExcludedAerodromesWarningMessage />
                                                                                )
                                                                            if (
                                                                                acknowledgeExcludedAerodromes
                                                                            ) {
                                                                                setExcludedAerodromes(
                                                                                    (
                                                                                        prevState
                                                                                    ) =>
                                                                                        prevState.concat(
                                                                                            aerodrome.designator
                                                                                        )
                                                                                )
                                                                                setFirstClose(
                                                                                    false
                                                                                )
                                                                            }
                                                                        } else {
                                                                            setExcludedAerodromes(
                                                                                (
                                                                                    prevState
                                                                                ) =>
                                                                                    prevState.concat(
                                                                                        aerodrome.designator
                                                                                    )
                                                                            )
                                                                        }
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'gray.100',
                                                                    }}
                                                                    _focus={{
                                                                        background:
                                                                            'light.30',
                                                                    }}
                                                                    _hover={{
                                                                        color: 'gray.500',
                                                                        background:
                                                                            isExpanded
                                                                                ? 'light.90'
                                                                                : 'light.30',
                                                                    }}
                                                                >
                                                                    <CloseIcon boxSize="20px" />
                                                                </Button>
                                                            </Tooltip>
                                                        </Flex>

                                                        <AccordionPanel
                                                            pt={0}
                                                            pb={5}
                                                            pl="1.5rem"
                                                            pr="1.5rem"
                                                            boxShadow={
                                                                index + 1 ===
                                                                filteredMetar.length
                                                                    ? 'none'
                                                                    : 'inset 0px -5px 15px 0px rgba(0,0,0,0.04)'
                                                            }
                                                        >
                                                            <Flex
                                                                fontFamily="Open Sans"
                                                                paddingTop="3"
                                                                flexDirection="column"
                                                                color="light.100"
                                                                pt={{
                                                                    base: '8px',
                                                                    md: '3',
                                                                }}
                                                            >
                                                                {(showOutdatedErrors ||
                                                                    aerodrome.outofdate) && (
                                                                    <Flex
                                                                        pt="10px"
                                                                        pb="5px"
                                                                        ml="-8px"
                                                                        justifyContent="center"
                                                                    >
                                                                        <ErrorText>
                                                                            There
                                                                            has
                                                                            been
                                                                            an
                                                                            issue
                                                                            with
                                                                            the
                                                                            connection
                                                                            between
                                                                            PreFlight
                                                                            and
                                                                            MetService.
                                                                            This{' '}
                                                                            <strong>
                                                                                METAR
                                                                            </strong>{' '}
                                                                            report
                                                                            was
                                                                            last
                                                                            validated
                                                                            with
                                                                            MetService
                                                                            more
                                                                            than{' '}
                                                                            <strong>
                                                                                {formatDistanceToNow(
                                                                                    parseISO(
                                                                                        aerodrome
                                                                                            .metar[0]
                                                                                            .updated
                                                                                    ),
                                                                                    {
                                                                                        addSuffix: true,
                                                                                    }
                                                                                )}
                                                                            </strong>
                                                                            .
                                                                            <br />
                                                                            There
                                                                            is a
                                                                            high
                                                                            likelihood
                                                                            that
                                                                            the
                                                                            latest
                                                                            METAR
                                                                            information
                                                                            is
                                                                            not
                                                                            available
                                                                            in
                                                                            PreFlight.
                                                                            Check
                                                                            back
                                                                            again
                                                                            soon,
                                                                            or
                                                                            alternatively
                                                                            visit{' '}
                                                                            <a
                                                                                style={{
                                                                                    fontWeight:
                                                                                        '500',
                                                                                    textDecoration:
                                                                                        'underline',
                                                                                }}
                                                                                href="https://ifis.airways.co.nz/"
                                                                            >
                                                                                IFIS
                                                                            </a>
                                                                            .
                                                                        </ErrorText>
                                                                    </Flex>
                                                                )}

                                                                <>
                                                                    <Flex
                                                                        className="metar"
                                                                        flexDirection={{
                                                                            base: 'column-reverse',
                                                                            md: 'row',
                                                                        }}
                                                                        justifyContent="space-between"
                                                                    >
                                                                        <Flex
                                                                            paddingTop={
                                                                                3
                                                                            }
                                                                            pl="2px"
                                                                            pb="5px"
                                                                        >
                                                                            <Flex flexDirection="column">
                                                                                {aerodrome.metar &&
                                                                                    aerodrome.metar
                                                                                        .slice(
                                                                                            0,
                                                                                            briefingMetarCount &&
                                                                                                briefingMetarCount[
                                                                                                    aerodrome
                                                                                                        .designator
                                                                                                ]
                                                                                                ? briefingMetarCount[
                                                                                                      aerodrome
                                                                                                          .designator
                                                                                                  ]
                                                                                                : 1
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                metar
                                                                                            ) => {
                                                                                                return (
                                                                                                    <Text
                                                                                                        key={`AerodromeBriefingMetar${metar.id}`}
                                                                                                        textAlign="left"
                                                                                                        color="light.100"
                                                                                                        fontSize={calculateFontSize(
                                                                                                            0.8
                                                                                                        )}
                                                                                                        fontWeight="normal"
                                                                                                        lineHeight="1.3"
                                                                                                        whiteSpace="pre-line"
                                                                                                        paddingBottom="10px"
                                                                                                    >
                                                                                                        {
                                                                                                            metar.data
                                                                                                        }
                                                                                                    </Text>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                            </Flex>
                                                                        </Flex>
                                                                        <Box
                                                                            zIndex="10"
                                                                            width="120px"
                                                                            minWidth="110px"
                                                                            fontSize="0.8rem"
                                                                            paddingStart="2"
                                                                            paddingEnd="0"
                                                                            pb="0px"
                                                                            borderColor="transparent"
                                                                            position="relative"
                                                                            bottom="-13px"
                                                                            right="0px"
                                                                            marginLeft="auto"
                                                                            marginBottom={{
                                                                                base: '20px',
                                                                                md: 'none',
                                                                            }}
                                                                        >
                                                                            <Select
                                                                                menuPosition="fixed" // this is needed to stop dropdown menu being contained within Accordion Item's Collapse (overflow:hidden)
                                                                                closeMenuOnScroll={() =>
                                                                                    true
                                                                                }
                                                                                {...noFocus}
                                                                                isSearchable={
                                                                                    false
                                                                                }
                                                                                controlShouldRenderValue={
                                                                                    true
                                                                                }
                                                                                styles={{
                                                                                    option: (
                                                                                        styles
                                                                                    ) => ({
                                                                                        ...styles,
                                                                                        cursor: 'pointer',
                                                                                        paddingTop:
                                                                                            '6px',
                                                                                        paddingBottom:
                                                                                            '6px',
                                                                                    }),
                                                                                    control:
                                                                                        (
                                                                                            styles
                                                                                        ) => ({
                                                                                            ...styles,
                                                                                            cursor: 'pointer',
                                                                                            borderRadius:
                                                                                                '10px',
                                                                                            borderColor:
                                                                                                '#e5e5e5',

                                                                                            minHeight:
                                                                                                '30px',
                                                                                        }),
                                                                                    dropdownIndicator:
                                                                                        (
                                                                                            styles
                                                                                        ) => ({
                                                                                            ...styles,
                                                                                            paddingTop:
                                                                                                '4px',
                                                                                            paddingBottom:
                                                                                                '4px',
                                                                                        }),
                                                                                    menu: (
                                                                                        styles
                                                                                    ) => ({
                                                                                        ...styles,
                                                                                        zIndex: '100',
                                                                                        marginTop:
                                                                                            '3px',
                                                                                    }),
                                                                                }}
                                                                                defaultValue={
                                                                                    metarOptions.filter(
                                                                                        (
                                                                                            option
                                                                                        ) =>
                                                                                            option.value ===
                                                                                                briefingMetarCount &&
                                                                                            briefingMetarCount[
                                                                                                aerodrome
                                                                                                    .designator
                                                                                            ]
                                                                                                ? briefingMetarCount[
                                                                                                      aerodrome
                                                                                                          .designator
                                                                                                  ]
                                                                                                : 1
                                                                                    )[0]
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setBriefingMetarCount(
                                                                                        (
                                                                                            prevState
                                                                                        ) => ({
                                                                                            ...prevState,
                                                                                            ...{
                                                                                                [aerodrome.designator]:
                                                                                                    e.value,
                                                                                            },
                                                                                        })
                                                                                    )
                                                                                }
                                                                                options={
                                                                                    metarOptions
                                                                                }
                                                                            />
                                                                        </Box>
                                                                    </Flex>
                                                                </>
                                                            </Flex>
                                                        </AccordionPanel>
                                                    </>
                                                )}
                                            </AccordionItem>
                                        </Flex>
                                    )
                                }
                            })}
                    </Accordion>
                    {filteredMetar.length > 0 &&
                        filteredMetar.filter(
                            (aerodrome) =>
                                !(
                                    excludedAerodromes &&
                                    excludedAerodromes.includes(
                                        aerodrome.designator
                                    )
                                )
                        ).length === 0 && (
                            <Flex
                                minHeight="80px"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                px="15px"
                                py="20px"
                                lineHeight="1.4"
                                gap={{ base: '10px', sm: '5px' }}
                            >
                                <Text
                                    fontSize={calculateFontSize(0.8)}
                                    fontWeight="500"
                                    color="light.100"
                                    textAlign="center"
                                >
                                    All aerodromes with METAR have been excluded
                                    from the briefing
                                </Text>
                                <Text
                                    fontSize={calculateFontSize(0.8)}
                                    fontWeight="400"
                                    color="gray.500"
                                    opacity="0.8"
                                    textAlign="center"
                                >
                                    Reinstate aerodromes from the Excluded Items
                                    list
                                </Text>
                            </Flex>
                        )}
                    {filteredMetar && !filteredMetar.length && (
                        <Flex
                            pt="15px"
                            pb="18px"
                            px="15px"
                            justifyContent="center"
                        >
                            <ErrorText>
                                There has been an issue with the connection
                                between PreFlight and Airways.
                                <br />
                                There is a high likelihood that the latest METAR
                                information is not available in PreFlight. Check
                                back again soon, or alternatively visit{' '}
                                <a
                                    style={{
                                        fontWeight: '500',
                                        textDecoration: 'underline',
                                    }}
                                    href="https://ifis.airways.co.nz/"
                                >
                                    IFIS
                                </a>
                                .
                            </ErrorText>
                        </Flex>
                    )}
                </Box>
            </Box>
        </>
    )
}
