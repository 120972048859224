import React from 'react'
import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../../../util/notamDecode'
import { Text, View } from '@react-pdf/renderer'
import formatDateTime from '../../../../../util/dateFormatter'
import { parseISO, formatDistanceToNow } from 'date-fns'
import {
    NotamOutlineIcon,
    NotamIcon,
    NotamAerodromeIcon,
    NotamCommsIcon,
    NotamDroneIcon,
    NotamObstacleIcon,
    NotamWarningIcon,
    ErrorIcon,
} from './assets/SvgImages'

export default function NOTAMExportPDF(props) {
    const {
        styles,
        timeZone,
        briefingData,
        periodTo,
        showOutdatedErrors,
        aerodromesSort,
        excludedAerodromes,
    } = props

    const filteredAerodromes =
        briefingData &&
        briefingData.res &&
        briefingData.res.aerodromes &&
        briefingData.res.aerodromes.filter((filter) => filter.notam !== null)

    function notamIconFormatter(code23) {
        return ['WU', 'WZ'].includes(code23) ? (
            <NotamDroneIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['OB', 'OL'].includes(code23) ? (
            <NotamObstacleIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : code23 === 'FA' ? (
            <NotamAerodromeIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['W', 'R'].includes(code23.charAt(0)) ? (
            <NotamWarningIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['C', 'N'].includes(code23.charAt(0)) ? (
            <NotamCommsIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : (
            <NotamIcon width="20" height="20" viewBox="0 0 24 24" alt="!" />
        )
    }

    return (
        // NOTAM SECTION
        <View style={styles.section}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    wrap={false}
                    style={[
                        styles.sectionTitle,
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        },
                    ]}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <NotamOutlineIcon
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="rgb(23, 120, 183)"
                        />

                        <div
                            style={{
                                paddingLeft: '7',
                                color: 'rgb(23, 120, 183)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                textAlign: 'left',
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: '600',
                                }}
                            >
                                NOTAM
                            </Text>
                        </div>
                    </div>
                    <div
                        style={{
                            color: 'rgb(23, 120, 183)',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            textAlign: 'right',
                            paddingRight: 2,
                        }}
                    >
                        <Text
                            style={{
                                paddingLeft: 3,
                                fontWeight: '300',
                                fontSize: '6pt',
                                textAlign: 'right',
                                textTransform: 'unset',
                                color: 'rgb(111, 127, 145)',
                            }}
                        >
                            All heights are in feet above aerodrome level.
                        </Text>
                        <Text
                            style={{
                                paddingLeft: 3,
                                fontWeight: '300',
                                fontSize: '6pt',
                                textAlign: 'right',
                                textTransform: 'unset',
                                color: 'rgb(111, 127, 145)',
                            }}
                        >
                            Wind: °True
                        </Text>
                    </div>
                </div>

                {briefingData &&
                    briefingData.res &&
                    briefingData.res.aerodromes &&
                    briefingData.res.aerodromes
                        .filter((filter) => filter.notam !== null)
                        .sort((a, b) =>
                            aerodromesSort
                                ? a.name > b.name
                                : a.lat < b.lat
                                ? 1
                                : -1
                        )
                        .map((aerodrome) => {
                            if (
                                excludedAerodromes &&
                                excludedAerodromes.includes(
                                    aerodrome.designator
                                )
                            ) {
                                return null
                            } else {
                                return (
                                    <div
                                        key={`AerodromesNotam${aerodrome.designator}`}
                                    >
                                        <div /// MET INFORMATION
                                            style={[
                                                styles.sectionContent,
                                                {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    borderBottom:
                                                        '1px solid #e5e5e5',
                                                },
                                            ]}
                                        >
                                            <div /// MET SECTION TITLE
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        borderRight:
                                                            '1px solid #e5e5e5',
                                                        flexGrow: '1',
                                                        width: '20%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent:
                                                            'flex-start',
                                                    },
                                                ]}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'flex-start',
                                                        alignItems: 'center',
                                                        paddingTop: '2',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: 'rgb(111, 127, 145)',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            justifyContent:
                                                                'flex-start',
                                                            alignItems:
                                                                'flex-start',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    '700',
                                                                fontSize: '8pt',
                                                            }}
                                                        >
                                                            {
                                                                aerodrome.designator
                                                            }
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    '500',
                                                                fontSize: '7pt',
                                                            }}
                                                        >
                                                            {aerodrome.name}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                            <div /// NOTAM SECTION CONTENT
                                                style={[
                                                    styles.boxCell,
                                                    {
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexGrow: '10',
                                                        width: '80%',
                                                        paddingTop: '7',
                                                        marginBottom: '10',
                                                    },
                                                ]}
                                            >
                                                {aerodrome.notam &&
                                                    aerodrome.notam.length >
                                                        0 &&
                                                    aerodrome.notam.map(
                                                        (notam, i) => {
                                                            const code23 =
                                                                code23Decode(
                                                                    notam.code23
                                                                )
                                                            const code45 =
                                                                code45Decode(
                                                                    notam.code45
                                                                )
                                                            const traffic =
                                                                trafficDecode(
                                                                    notam.traffic
                                                                )
                                                            if (
                                                                parseISO(
                                                                    notam.startvalidity
                                                                ) <= periodTo
                                                            ) {
                                                                return (
                                                                    <div
                                                                        wrap={
                                                                            false
                                                                        }
                                                                        key={`AerodromesNotam${notam.series}${notam.number}/${notam.year}`}
                                                                        style={{
                                                                            width: '100%',
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        }}
                                                                    >
                                                                        {(showOutdatedErrors ||
                                                                            notam.outofdate) && (
                                                                            <div
                                                                                wrap={
                                                                                    false
                                                                                }
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'row',
                                                                                    width: '100%',
                                                                                    marginBottom:
                                                                                        '5',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={[
                                                                                        styles.sectionContent,
                                                                                        {
                                                                                            width: '6%',
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <ErrorIcon
                                                                                        width="16"
                                                                                        height="16"
                                                                                        viewBox="0 0 16 16"
                                                                                        fill="rgb(16, 170, 112)"
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    style={[
                                                                                        styles.sectionContent,
                                                                                        {
                                                                                            width: '94%',
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'column',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Text
                                                                                        style={
                                                                                            styles.error
                                                                                        }
                                                                                    >
                                                                                        <span>
                                                                                            There
                                                                                            has
                                                                                            been
                                                                                            an
                                                                                            issue
                                                                                            with
                                                                                            the
                                                                                            connection
                                                                                            between
                                                                                            PreFlight
                                                                                            and
                                                                                            Airways.
                                                                                            This{' '}
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight:
                                                                                                    '500',
                                                                                            }}
                                                                                        >
                                                                                            NOTAM
                                                                                        </span>
                                                                                        <span>
                                                                                            {' '}
                                                                                            was
                                                                                            last
                                                                                            validated
                                                                                            with
                                                                                            Airways
                                                                                            more
                                                                                            than{' '}
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight:
                                                                                                    '500',
                                                                                            }}
                                                                                        >
                                                                                            {formatDistanceToNow(
                                                                                                parseISO(
                                                                                                    notam.updated
                                                                                                ),
                                                                                                {
                                                                                                    addSuffix: true,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                        <span>
                                                                                            .
                                                                                            There
                                                                                            is
                                                                                            a
                                                                                            high
                                                                                            likelihood
                                                                                            that
                                                                                            the
                                                                                            latest
                                                                                            NOTAM
                                                                                            information
                                                                                            is
                                                                                            not
                                                                                            available
                                                                                            in
                                                                                            PreFlight.
                                                                                            Check
                                                                                            back
                                                                                            again
                                                                                            soon,
                                                                                            or
                                                                                            alternatively
                                                                                            visit{' '}
                                                                                        </span>
                                                                                        <a
                                                                                            style={{
                                                                                                fontWeight:
                                                                                                    '400',
                                                                                                textDecoration:
                                                                                                    'underline',
                                                                                            }}
                                                                                            href="https://ifis.airways.co.nz/"
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                        >
                                                                                            IFIS
                                                                                        </a>

                                                                                        .
                                                                                    </Text>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            style={{
                                                                                width: '100%',
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={[
                                                                                    styles.sectionContent,
                                                                                    {
                                                                                        width: '6%',
                                                                                        display:
                                                                                            'flex',
                                                                                        flexDirection:
                                                                                            'column',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                {notamIconFormatter(
                                                                                    notam.code23
                                                                                )}
                                                                            </div>

                                                                            <div
                                                                                style={[
                                                                                    styles.sectionContent,
                                                                                    {
                                                                                        width: '100%',
                                                                                        display:
                                                                                            'flex',
                                                                                        flexGrow:
                                                                                            '10',
                                                                                        flexDirection:
                                                                                            'column',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <div
                                                                                    style={[
                                                                                        styles.sectionContent,
                                                                                        {
                                                                                            width: '94%',
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'column',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={
                                                                                                styles.labelNormal
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                code23
                                                                                            }
                                                                                        </Text>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={
                                                                                                styles.textNormal
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                code45
                                                                                            }
                                                                                        </Text>
                                                                                    </div>

                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                            paddingTop:
                                                                                                '5',
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={[
                                                                                                styles.labelNormal,
                                                                                                {
                                                                                                    width: '50',
                                                                                                    fontWeight:
                                                                                                        '500',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            NOTAM
                                                                                            #:
                                                                                        </Text>
                                                                                        <Text
                                                                                            style={
                                                                                                styles.textNormal
                                                                                            }
                                                                                        >
                                                                                            {`${notam.series}${notam.number}/${notam.year}`}
                                                                                        </Text>
                                                                                    </div>

                                                                                    <div
                                                                                        wrap={
                                                                                            false
                                                                                        }
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={[
                                                                                                styles.labelNormal,
                                                                                                {
                                                                                                    width: '50',
                                                                                                    fontWeight:
                                                                                                        '500',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            Affects:
                                                                                        </Text>
                                                                                        <div
                                                                                            style={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                            }}
                                                                                        >
                                                                                            {traffic.ifr && (
                                                                                                <Text
                                                                                                    style={
                                                                                                        styles.ifrBadge
                                                                                                    }
                                                                                                >
                                                                                                    IFR
                                                                                                </Text>
                                                                                            )}
                                                                                            {traffic.vfr && (
                                                                                                <Text
                                                                                                    style={
                                                                                                        styles.vfrBadge
                                                                                                    }
                                                                                                >
                                                                                                    VFR
                                                                                                </Text>
                                                                                            )}
                                                                                            <Text
                                                                                                style={
                                                                                                    styles.textNormal
                                                                                                }
                                                                                            >
                                                                                                TRAFFIC
                                                                                            </Text>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <Text
                                                                                            style={[
                                                                                                styles.labelNormal,
                                                                                                {
                                                                                                    width: '50',
                                                                                                    fontWeight:
                                                                                                        '500',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            From:
                                                                                        </Text>
                                                                                        <Text
                                                                                            style={
                                                                                                styles.textNormal
                                                                                            }
                                                                                        >
                                                                                            {notam?.startvalidity &&
                                                                                                formatDateTime(
                                                                                                    notam.startvalidity,
                                                                                                    timeZone
                                                                                                )}
                                                                                        </Text>
                                                                                    </div>

                                                                                    {!notam.permanent && (
                                                                                        <div
                                                                                            style={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                            }}
                                                                                        >
                                                                                            <Text
                                                                                                style={[
                                                                                                    styles.labelNormal,
                                                                                                    {
                                                                                                        width: '50',
                                                                                                        fontWeight:
                                                                                                            '500',
                                                                                                    },
                                                                                                ]}
                                                                                            >
                                                                                                To:
                                                                                            </Text>
                                                                                            <Text
                                                                                                style={
                                                                                                    styles.textNormal
                                                                                                }
                                                                                            >
                                                                                                {notam?.endvalidity &&
                                                                                                    formatDateTime(
                                                                                                        notam.endvalidity,
                                                                                                        timeZone
                                                                                                    )}
                                                                                                {notam?.estimation &&
                                                                                                    ' (Estimated)'}
                                                                                            </Text>
                                                                                        </div>
                                                                                    )}

                                                                                    {notam.itemd &&
                                                                                        notam.itemd !==
                                                                                            'null' && (
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        'flex',
                                                                                                    flexDirection:
                                                                                                        'row',
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    style={[
                                                                                                        styles.labelNormal,
                                                                                                        {
                                                                                                            width: '50',
                                                                                                            fontWeight:
                                                                                                                '500',
                                                                                                        },
                                                                                                    ]}
                                                                                                >
                                                                                                    Period:
                                                                                                </Text>
                                                                                                <Text
                                                                                                    style={
                                                                                                        styles.textNormal
                                                                                                    }
                                                                                                >
                                                                                                    {notam.itemd +
                                                                                                        ' (UTC if time shown)'}
                                                                                                </Text>
                                                                                            </div>
                                                                                        )}

                                                                                    {notam.itemf && (
                                                                                        <div
                                                                                            style={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                            }}
                                                                                        >
                                                                                            <Text
                                                                                                style={[
                                                                                                    styles.labelNormal,
                                                                                                    {
                                                                                                        width: '50',
                                                                                                        fontWeight:
                                                                                                            '500',
                                                                                                    },
                                                                                                ]}
                                                                                            >
                                                                                                Altitude:
                                                                                            </Text>
                                                                                            <Text
                                                                                                style={
                                                                                                    styles.textNormal
                                                                                                }
                                                                                            >
                                                                                                {notam.lowerlimit +
                                                                                                    ' – ' +
                                                                                                    notam.upperlimit}
                                                                                            </Text>
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                <Text
                                                                                    style={[
                                                                                        styles.textNormal,
                                                                                        {
                                                                                            marginTop:
                                                                                                '5',
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    {
                                                                                        notam.iteme
                                                                                    }
                                                                                </Text>
                                                                            </div>
                                                                        </div>
                                                                        {i +
                                                                            1 !==
                                                                            aerodrome
                                                                                .notam
                                                                                .length && (
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'row',
                                                                                    borderBottom:
                                                                                        '1px solid #f5f5f5',
                                                                                    marginTop:
                                                                                        '10',
                                                                                    marginBottom:
                                                                                        '10',
                                                                                }}
                                                                            ></div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}

                {filteredAerodromes.length > 0 &&
                    filteredAerodromes.filter(
                        (aerodrome) =>
                            !(
                                excludedAerodromes &&
                                excludedAerodromes.includes(
                                    aerodrome.designator
                                )
                            )
                    ).length === 0 && (
                        <div /// NO INFORMATION AVAILABLE
                            style={[
                                styles.sectionContent,
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderBottom: '1px solid #e5e5e5',
                                },
                            ]}
                        >
                            <div
                                style={[
                                    styles.boxCell,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexGrow: '10',
                                        width: '100%',
                                    },
                                ]}
                            >
                                <Text
                                    style={{
                                        fontWeight: '300',
                                        opacity: '0.6',
                                    }}
                                >
                                    All aerodromes with NOTAMs have been
                                    excluded from the briefing
                                </Text>
                            </div>
                        </div>
                    )}
            </div>
        </View>
    )
}
