export function rasterSourceCheck(source, startWord, endWord) {
    if (
        source.slice(0, startWord.length) === startWord &&
        source.slice(-endWord.length) === endWord
    ) {
        return true
    } else {
        return false
    }
}
// Takes a the layer status and two callback functions/hooks
export function handleRasterLoading(
    status,
    layerId,
    setPercentLoaded,
    setLoaded
) {
    const rasterLoadingStatus = Object.keys(status)
        .filter((i) => rasterSourceCheck(i, layerId, 'Loading'))
        .map((i) => status[i])

    // console.log({ rasterLoadingStatus })

    if (rasterLoadingStatus.length) {
        if (rasterLoadingStatus.includes(true)) {
            setLoaded(false)
            const percent = Math.round(
                rasterLoadingStatus.filter((i) => i === false).length *
                    (100 / rasterLoadingStatus.length)
            )
            setPercentLoaded(percent)
        } else {
            setPercentLoaded(100)
            setLoaded(true)
        }
    }
}

export function isRasterMounted(status, layerId) {
    const rasterMountedStatus = Object.keys(status)
        .filter((i) => rasterSourceCheck(i, layerId, 'Mounted'))
        .map((i) => status[i])

    if (rasterMountedStatus.length) {
        if (rasterMountedStatus.includes(false)) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}
