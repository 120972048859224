import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { MapContext, Layer, Source } from 'react-mapbox-gl'
import { useQuery } from 'react-query'
import { isEqual } from 'lodash'

import {
    selectedWxTileTimesState,
    selectedTimeState,
    sliderPlayingState,
    sliderLoopingState,
    layerStatusState,
    refreshRasterLayerStatus,
    layersLoaded,
    statusData,
    layerErrorHandler,
    handleStatusChange,
    removeLayerErrorHandler,
    pollingPaused,
} from '../../../../globalState'

import {
    handleRasterLoading,
    isRasterMounted,
} from '../../functions/rasterUtility'
import MapLoading from '../../../components/MapLoading'

export default function WXRasterTiles({ layerId, timesURL, rasterURL, limit }) {
    const { getAccessTokenSilently } = useAuth0()
    const mapInstance = useContext(MapContext)
    // Array of available times fetched from met server (in iso format)
    const [times, setTimes] = useRecoilState(selectedWxTileTimesState)
    const [loaded, setLoaded] = useRecoilState(layersLoaded)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const status = useRecoilValue(statusData)
    const prevTimes = useRef(times)
    const [selectedTime, setSelectedTime] = useRecoilState(selectedTimeState)
    const prevSelectedTime = useRef(selectedTime)
    const [previousSelectedTile, setPreviousSelectedTile] = useState(null)
    const [frameCount, setFrameCount] = useState(0)
    const [timesLoaded, setTimesLoaded] = useState(false)
    const [newSelectedTile, setNewSelectedTile] = useState(null)
    const [mounted, setMounted] = useState(false)
    const [cycles, setCycles] = useState(null)
    const cyclesCount = useRef(0)
    const [percentLoaded, setPercentLoaded] = useState(0)
    const [playing, setPlaying] = useRecoilState(sliderPlayingState)
    const layerState = useRecoilValue(layerStatusState)
    const prevLayerState = useRef(layerState)
    const looping = useRecoilValue(sliderLoopingState)
    const refreshLayerState = useSetRecoilState(refreshRasterLayerStatus)
    const setLayerError = useSetRecoilState(layerErrorHandler)
    const removeError = useSetRecoilState(removeLayerErrorHandler)
    const changeStatusValue = useSetRecoilState(handleStatusChange)

    // Array of Epoch times converted from iso times.
    const [convertedTimes, setConvertedTimes] = useState([])
    const [sources, setSources] = useState(null)
    const [selectedTileSet, setSelectedTileSet] = useState(null)
    const selectedTileSetRef = useRef(selectedTileSet)
    const [layers, setLayers] = useState(null)
    const statusRef = useRef(null)

    const fetchLayerTimes = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${timesURL}?apikey=${process.env.REACT_APP_WXTILES_API_KEY}`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const {
        data,
        status: timesFetchStatus,
        refetch,
    } = useQuery(`layerId`, fetchLayerTimes, { refetchOnWindowFocus: false })

    useEffect(() => {
        // This function checks the difference between old and new times and removes sources and layers of those that no longer are in the current times array.
        function removeOutdatedTimes(oldTimes, newTimes) {
            if (oldTimes.length && newTimes.length) {
                const difference = oldTimes.filter(
                    (item) => newTimes.indexOf(item) === -1
                )

                difference.length &&
                    difference.forEach((t) => {
                        const time = mapInstance.getSource(`${layerId}${t}`)
                        time && mapInstance.removeLayer(`${layerId}${t}`)
                        time && mapInstance.removeSource(`${layerId}${t}`)
                    })
            }
        }

        if (data && timesFetchStatus === 'success') {
            const availableTimes = limit ? data.times.slice(limit) : data.times
            // console.log({ availableTimes, data })
            // Check if the array of times are different, if so set new times.
            if (!isEqual(times, availableTimes)) {
                setTimes(availableTimes)
                prevTimes.current = limit ? data.times.slice(limit) : data.times
                times && removeOutdatedTimes(times, availableTimes)
                removeError({ layer: layerId.toLowerCase(), type: 'fetching' })
            }
        }
        timesFetchStatus === 'error' &&
            setLayerError({
                layer: layerId.toLowerCase(),
                type: 'fetching',
            })
        setTimesLoaded(timesFetchStatus !== 'loading')
    }, [
        data,
        layerId,
        limit,
        mapInstance,
        removeError,
        setLayerError,
        setTimes,
        timesFetchStatus,
        times,
    ])

    // Set loaded to false as default.
    useEffect(() => {
        setLoaded(false)
    }, [setLoaded])

    // Polling for network connection is paused intentionally on larger payloads.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    // Convert times for the timeslider component.
    useEffect(() => {
        setConvertedTimes(times.map((t) => Date.parse(t)))
    }, [times])

    useEffect(() => {
        //Receives selected epoch time and checks duplicate array of iso time values received from API
        if (times.length && times[convertedTimes.indexOf(selectedTime)]) {
            // Here we need to know when the previous tile is to transition between previous and current smoothly.
            if (selectedTileSetRef.current) {
                setPreviousSelectedTile(selectedTileSetRef.current)
                setNewSelectedTile(times[convertedTimes.indexOf(selectedTime)])
            }
            setSelectedTileSet(times[convertedTimes.indexOf(selectedTime)])
            selectedTileSetRef.current =
                times[convertedTimes.indexOf(selectedTime)]
        } else {
            setSelectedTileSet(times[times.length - 1])
            selectedTileSetRef.current = times[times.length - 1]
        }
    }, [selectedTime, convertedTimes, times])

    useEffect(() => {
        // This determines if layer is loading or not
        handleRasterLoading(layerState, layerId, setPercentLoaded, setLoaded)
        prevLayerState.current = layerState
    }, [layerState, layerId, setLoaded])

    useEffect(() => {
        // This determines if map is idle, if idle and if layer is not mounted, refresh data and trigger a rerender. This is intended to stop an occasional bug where the map layer was hung in a loading state.
        if (loaded && !isRasterMounted(layerState, layerId)) {
            changeStatusValue({
                layer: layerId.toLowerCase(),
                key: 'mapOutdated',
                value: true,
            })
        } else if (loaded && isRasterMounted(layerState, layerId)) {
            changeStatusValue({
                layer: layerId.toLowerCase(),
                key: 'mapOutdated',
                value: false,
            })
        }
    }, [
        layerId,
        layerState,
        loaded,
        mapInstance,
        removeError,
        changeStatusValue,
    ])

    useEffect(() => {
        if (status && status[layerId.toLowerCase()] && !mounted) {
            statusRef.current = status[layerId.toLowerCase()]['version']
            setMounted(true)
        }
    }, [status, layerId, mounted])

    useEffect(() => {
        // When version stamp in status has changed refetch layer times.
        if (
            mounted &&
            statusRef.current !== status[layerId.toLowerCase()]['version'] &&
            !status[layerId.toLowerCase()]['mapOutdated']
        ) {
            statusRef.current = status[layerId.toLowerCase()]['version']
            // This selector cleans up times set in the layerState atom that no longer exist.
            refreshLayerState({ layer: layerId })
            refetch()
        } else if (
            mounted &&
            statusRef.current !== status[layerId.toLowerCase()]['version'] &&
            status[layerId.toLowerCase()]['mapOutdated']
        ) {
            // if layer is mapOutdated trigger a hard refresh.
            setTimes([])
            refreshLayerState({ layer: layerId })
            refetch()
        }
    }, [status, mounted, refreshLayerState, layerId, refetch, setTimes])

    useEffect(() => {
        if (times.length) {
            setSources(
                times.map((time) => {
                    return (
                        <Source
                            id={`${layerId}${time}`}
                            key={`${layerId}Source${time}`}
                            tileJsonSource={{
                                type: 'raster',
                                tiles: [
                                    `${rasterURL}${time}/0/{z}/{x}/{y}.png?apikey=${process.env.REACT_APP_WXTILES_API_KEY}`, // cloud tops
                                    // `https://api.wxtiles.com/v1/wxtiles/tile/jma-himawari-oceania-cth/cloud-top-height-aviation-pcolormesh/him8-oceania_cth/${time}/0/{z}/{x}/{y}.png?apikey=${process.env.REACT_APP_WXTILES_API_KEY}`, // cloud tops pcolormesh
                                ],
                                tileSize: 256,
                                scheme: 'tms',
                                // bounds: [164, -48.5, 185, -32.5], // might be an idea to limit the bounds of the data to where the map view will ultimately be limited to
                                minzoom: 0,
                                maxzoom: 14,
                            }}
                        />
                    )
                })
            )

            setLayers(
                times.map((time) => {
                    return (
                        <Layer
                            id={`${layerId}${time}`}
                            key={`${layerId}Layer${time}`}
                            sourceId={`${layerId}${time}`}
                            before="hillshade"
                            type="raster"
                            properties={{
                                version:
                                    status[layerId.toLowerCase()]['version'],
                            }}
                            paint={{
                                'raster-brightness-max': 1,
                                'raster-brightness-min': 0.33,
                                'raster-contrast': 0.25,
                                'raster-opacity': 0,
                            }}
                        />
                    )
                })
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [times, layerId, rasterURL]) // status

    // This useEffect is used to drive the animation
    useEffect(() => {
        let interval = null

        if (playing) {
            interval = setInterval(() => {
                setFrameCount((t) => t + 1)
            }, 1000)
        } else {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [playing])

    //set an initial count to cycle through, if it is the second to last step make it cycle the adequate number to complete a cycle.
    useEffect(() => {
        if (
            convertedTimes.indexOf(prevSelectedTime.current) >=
            convertedTimes.length - convertedTimes.length / 2
        ) {
            setCycles(
                convertedTimes.length -
                    1 -
                    convertedTimes.indexOf(prevSelectedTime.current) +
                    convertedTimes.length
            )
        } else {
            setCycles(
                convertedTimes.length -
                    (convertedTimes.indexOf(prevSelectedTime.current) + 1)
            )
        }
    }, [playing, convertedTimes])

    //This useEffect determines the play and looping behaviour driven by the frameCount hook
    useEffect(() => {
        if (playing && frameCount > 0) {
            if (looping) {
                const newSelectedTime =
                    convertedTimes.indexOf(prevSelectedTime.current) <
                    convertedTimes.length - 1
                        ? convertedTimes[
                              convertedTimes.indexOf(prevSelectedTime.current) +
                                  1
                          ]
                        : convertedTimes[0]

                setSelectedTime(newSelectedTime)
                prevSelectedTime.current = newSelectedTime
            } else if (!looping && cyclesCount.current < cycles) {
                const newSelectedTime =
                    convertedTimes.indexOf(prevSelectedTime.current) <
                    convertedTimes.length - 1
                        ? convertedTimes[
                              convertedTimes.indexOf(prevSelectedTime.current) +
                                  1
                          ]
                        : convertedTimes[0]
                setSelectedTime(newSelectedTime)
                prevSelectedTime.current = newSelectedTime
                cyclesCount.current = cyclesCount.current + 1
            } else if (!looping && cyclesCount.current === cycles) {
                setPlaying(false)
                cyclesCount.current = 0
                setFrameCount(0)
            }
        }
    }, [
        frameCount,
        playing,
        times,
        mapInstance,
        cycles,
        convertedTimes,
        looping,
        setPlaying,
        setSelectedTime,
    ])

    useEffect(
        () => {
            if ((previousSelectedTile || newSelectedTile) && playing) {
                mapInstance.setPaintProperty(
                    `${layerId}${previousSelectedTile}`,
                    'raster-opacity',
                    0
                )
                mapInstance.setPaintProperty(
                    `${layerId}${newSelectedTile}`,
                    'raster-opacity',
                    1
                )
            } else if (previousSelectedTile && !playing) {
                mapInstance.setPaintProperty(
                    `${layerId}${previousSelectedTile}`,
                    'raster-opacity',
                    0
                )
                mapInstance.setPaintProperty(
                    `${layerId}${selectedTileSet}`,
                    'raster-opacity',
                    1
                )
            } else {
                mapInstance.setPaintProperty(
                    `${layerId}${times[times.length - 1]}`,
                    'raster-opacity',
                    0
                )
                mapInstance.setPaintProperty(
                    `${layerId}${selectedTileSet}`,
                    'raster-opacity',
                    1
                )
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedTileSet, mapInstance, playing, times]
        // newSelectedTile, previousSelectedTile, <-- note, including these causes a flash between times when playing
    )

    return (
        <>
            {(!loaded || !timesLoaded) && (
                <MapLoading percentLoaded={percentLoaded} />
            )}
            {sources}
            {layers}
            <Layer
                id="waterMaskOutline"
                type="line"
                sourceId="composite"
                sourceLayer="water"
                before="land-structure-polygon"
                paint={{
                    'line-color': '#a8a8a8',
                    'line-width': 1,
                }}
            />
        </>
    )
}
