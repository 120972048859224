import React from 'react'
import WXRasterTiles from './components/WXRasterTiles'

export default function RainRadarWxTiles() {
    return (
        <WXRasterTiles
            layerId={'rainRadarWxTiles'}
            timesURL={
                'https://api.wxtiles.com/v1/wxtiles/layer/nz-rainradar/instance/nz_radar_composite/'
            }
            rasterURL={
                'https://api.wxtiles.com/v1/wxtiles/tile/nz-rainradar/reflectivity/nz_radar_composite/'
            }
            limit={-9}
        />
    )
}