import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'

import { userProfileState } from '../globalState'

export default function InitUserProfile() {
    const { user } = useAuth0()
    const auth0CustomDomain = JSON.parse(
        process.env.REACT_APP_DEV_ENV.toLowerCase()
    )
        ? process.env.REACT_APP_DEV_AUTH0_CUSTOM_DOMAIN
        : process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN
    const user_metadata =
        user && user[`https://${auth0CustomDomain}/user_metadata`]

    const setUserProfile = useSetRecoilState(userProfileState)

    useEffect(() => {
        if (user && user_metadata) {
            setUserProfile({
                firstName: user_metadata.given_name || user.given_name,
                lastName: user_metadata.family_name || user.family_name,
                email: user.email,
                mobileNumber: user_metadata.mobile_number,
                mfaLastVerified: user_metadata.mfa_last_verified,
                caaId: user_metadata.caa_id,
                organization: user_metadata.organization,
                profileSettings: user_metadata.profile_settings,
                readMessages: user_metadata.read_messages,
                announcementsVersion: user_metadata.announcements_version,
                briefingFavourites: user_metadata.briefing_favourites,
            })
        }
    }, [user, setUserProfile, user_metadata])

    return null
}
