import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    Text,
    Flex,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Divider,
    TableContainer,
} from '@chakra-ui/react'
import {
    isAfter,
    parseISO,
    formatDistanceToNow,
    formatDistanceToNowStrict,
} from 'date-fns'
import formatDateTime, {
    formatTimeOnly,
    formatDayAndDate,
    timeZoneAbbreviation,
} from '../../../../util/dateFormatter'
import {
    wxTranslate,
    changeIndicatorTranslate,
    // cloudCoverOktasDecode,
} from '../../../../util/metDecode'
import {
    timeZoneState,
    decodedTextDefaultState,
    tafFixFirstColumnState,
} from '../../../../globalState'
import ErrorText from '../ErrorText'
import ImprRawToggle from '../ImprRawToggle'
import FixedPosToggle from '../FixedPosToggle'

export default function TafContent(props) {
    const [outOfDateError, setOutOfDateError] = useState(false)
    const decodeDefault = useRecoilValue(decodedTextDefaultState)
    const {
        data,
        isPremium,
        tafOutOfDateIssueTime,
        showErrors,
        mfaExpired,
        fullscreen,
    } = props

    const [raw, setRaw] = useState(isPremium || !decodeDefault ? true : false)
    const [fixFirstColumn, setFixFirstColumn] = useRecoilState(
        tafFixFirstColumnState
    )
    const timeZone = useRecoilValue(timeZoneState)

    const selectedTaf = data.taf.data

    const tafChangeForecast =
        selectedTaf &&
        selectedTaf.changeForecast &&
        selectedTaf.changeForecast.length > 0

    const nullTafData = // TAF DATA is present but data.baseForecast is null
        selectedTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] === null

    const nilTaf = // TAF DATA is present but observation is "NIL" (as reported by forecaster)
        selectedTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast === 'NIL'

    const hasCloud =
        selectedTaf &&
        !nullTafData &&
        !nilTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0].cloud

    // function to reverse order stacked cloud layers so the heighest is at top
    function reverseArray(clouds) {
        const reversed = []
        for (let i = clouds && clouds.length - 1; i >= 0; i--) {
            reversed.push(clouds[i])
        }
        return reversed
    }

    // baseForecast Cloud
    const cloudlayers = hasCloud ? selectedTaf.baseForecast[0].cloud.layer : []
    const reversedCloudLayers = reverseArray(cloudlayers)

    const noSignificantCloud =
        selectedTaf &&
        !nullTafData &&
        !nilTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0].cloud &&
        selectedTaf.baseForecast[0].cloud.noSignificantCloud
    const cloudAndVisibilityOK =
        selectedTaf &&
        !nullTafData &&
        !nilTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0].cloudAndVisibilityOK
    const winddir =
        selectedTaf &&
        !nullTafData &&
        !nilTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0].surfaceWind
            ? selectedTaf.baseForecast[0].surfaceWind.meanWindDirection
            : null
    const windvar =
        selectedTaf &&
        !nullTafData &&
        !nilTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0].surfaceWind
            ? selectedTaf.baseForecast[0].surfaceWind.variableWindDirection
            : null
    const windspeed =
        selectedTaf &&
        !nullTafData &&
        !nilTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0].surfaceWind
            ? selectedTaf.baseForecast[0].surfaceWind.meanWindSpeed
            : null
    const windgust =
        selectedTaf &&
        !nullTafData &&
        !nilTaf &&
        selectedTaf.baseForecast &&
        selectedTaf.baseForecast[0] &&
        selectedTaf.baseForecast[0].surfaceWind
            ? selectedTaf.baseForecast[0].surfaceWind.windGustSpeed
            : null

    const instanceRef = useRef()
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)
    const checkScrollbarVisibility = () => {
        if (instanceRef.current) {
            setIsScrollbarVisible(
                instanceRef.current.scrollWidth >
                    instanceRef.current.clientWidth
            )
        }
    }
    useEffect(() => {
        checkScrollbarVisibility()
        // listener to check table scrollbar visibility
        const resizeListener = () => {
            checkScrollbarVisibility()
        }
        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    useEffect(() => {
        const delay = setTimeout(() => {
            checkScrollbarVisibility()
        }, 310)

        return () => {
            clearTimeout(delay)
        }
    }, [fullscreen])

    useEffect(() => {
        // reset fixed first column if there is no overflow
        if (!isScrollbarVisible) {
            setFixFirstColumn(false)
        }
    }, [isScrollbarVisible, setFixFirstColumn, fullscreen])

    useEffect(() => {
        if (data.taf.validTo) {
            if (isAfter(new Date(data.taf.validTo), new Date())) {
                setOutOfDateError(false)
            } else {
                setOutOfDateError(true)
            }
        }
    }, [data])

    useEffect(() => {
        if (!isPremium) {
            setRaw(!selectedTaf)
        }
        if (!decodeDefault) {
            setRaw(!decodeDefault)
        }
    }, [isPremium, decodeDefault, selectedTaf])

    // useEffect(() => {
    //     // console.log(data, 'data')
    //     // console.log(selectedTaf, 'selectedTaf')
    //     console.log(isScrollbarVisible, 'isScrollbarVisible')
    //     // console.log(instanceRef, 'instanceRef')
    // }, [data, selectedTaf, isScrollbarVisible, nilTaf])

    return (
        <>
            <Box w="100%">
                <Flex
                    // {...tabSwipeHeader}
                    marginBottom="20px"
                    justifyContent="space-between"
                    gap="10px"
                >
                    <Flex>
                        <Text
                            marginBottom={2}
                            fontSize={{
                                base: '0.85rem',
                                xs: '0.9rem',
                                sm: '1rem',
                            }}
                            fontWeight="bold"
                            lineHeight="1.3"
                            minHeight={{ base: '40px', smd: '20px' }}
                        >
                            TERMINAL AERODROME FORECAST
                        </Text>
                    </Flex>

                    <ImprRawToggle // IMPR/RAW Toggle
                        isPremium={isPremium}
                        selectedData={selectedTaf}
                        raw={raw}
                        setRaw={setRaw}
                        mfaExpired={mfaExpired}
                    />
                </Flex>

                <Box w="100%">
                    {/* ERROR MESSAGES */}
                    {!showErrors &&
                        !data.taf.outofdate &&
                        !outOfDateError &&
                        (tafOutOfDateIssueTime === 'CAUTION' ||
                            tafOutOfDateIssueTime === 'WARNING') && (
                            <Flex
                                mt="5px"
                                mb={{
                                    base: '15px',
                                    xl: '10px',
                                }}
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Text
                                    color={
                                        tafOutOfDateIssueTime === 'CAUTION'
                                            ? '#ff7f00'
                                            : 'red'
                                    }
                                    fontSize="12px"
                                >
                                    This TAF was last issued{' '}
                                    <strong>
                                        {formatDistanceToNowStrict(
                                            parseISO(data.taf.validfrom),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </strong>
                                    .
                                </Text>
                                {tafOutOfDateIssueTime === 'WARNING' && (
                                    <Text color={'red'} fontSize="12px">
                                        There is a possibility there has been an
                                        issue receiving the latest data and that
                                        the latest TAF is not available. Check
                                        back again soon, or alternatively visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </Text>
                                )}
                            </Flex>
                        )}
                    {(showErrors || data.taf.outofdate || outOfDateError) && (
                        <Flex mb="15px" justifyContent="center">
                            <ErrorText>
                                There has been an issue with the connection
                                between PreFlight and MetService. This TAF was
                                last validated with MetService{' '}
                                <strong>
                                    {formatDistanceToNow(
                                        parseISO(data.taf.updated),
                                        {
                                            addSuffix: true,
                                        }
                                    )}
                                </strong>
                                .<br />
                                There is a high likelihood that the latest TAF
                                is not available in PreFlight. Check back again
                                soon, or alternatively visit{' '}
                                <a
                                    style={{
                                        fontWeight: '500',
                                        textDecoration: 'underline',
                                    }}
                                    href="https://ifis.airways.co.nz/"
                                >
                                    IFIS
                                </a>
                                .
                            </ErrorText>
                        </Flex>
                    )}
                    {!raw && selectedTaf && (
                        <Box w="100%" pl={0} pr={0}>
                            <Table
                                size="sm"
                                fontFamily="Open Sans"
                                marginTop="2"
                                // {...tabSwipeHeader}
                            >
                                <Tbody verticalAlign="top" whiteSpace="pre">
                                    <Tr>
                                        <Td
                                            border="none"
                                            paddingStart="0"
                                            paddingEnd="0"
                                            pb="5px"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                            minHeight="35px"
                                            whiteSpace="pre"
                                            fontSize="0.9rem"
                                            textAlign="left"
                                        >
                                            {selectedTaf.reportStatus !==
                                                'NORMAL' &&
                                                selectedTaf.reportStatus
                                                    .split(' ')
                                                    .map(
                                                        ([
                                                            firstChar,
                                                            ...rest
                                                        ]) =>
                                                            firstChar.toUpperCase() +
                                                            rest
                                                                .join('')
                                                                .toLowerCase()
                                                    )
                                                    .join(' ') + ' '}
                                            Issued:
                                        </Td>
                                        <Td
                                            pb="5px"
                                            border="none"
                                            width="100%"
                                            fontSize="0.9rem"
                                            minHeight="35px"
                                        >
                                            <Flex
                                                gap={{
                                                    base: '1px',
                                                    sm: '10px',
                                                }}
                                                alignItems={{
                                                    base: 'flex-start',
                                                    sm: 'baseline',
                                                }}
                                                flexDirection={{
                                                    base: 'column',
                                                    sm: 'row',
                                                }}
                                            >
                                                <Text>
                                                    {selectedTaf?.issueTime &&
                                                        formatDateTime(
                                                            selectedTaf.issueTime,
                                                            timeZone
                                                        )}
                                                </Text>
                                                <Text
                                                    color="gray.400"
                                                    fontSize="0.75rem"
                                                    fontWeight="400"
                                                >
                                                    {selectedTaf?.issueTime &&
                                                        formatDistanceToNow(
                                                            parseISO(
                                                                selectedTaf.issueTime
                                                            ),
                                                            {
                                                                addSuffix: true,
                                                            }
                                                        )}
                                                </Text>
                                            </Flex>
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td
                                            border="none"
                                            paddingStart="0"
                                            paddingEnd="0"
                                            py="5px"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="90px"
                                            whiteSpace="pre"
                                            fontSize="0.9rem"
                                            textAlign="left"
                                            minHeight="35px"
                                        >
                                            Valid From:
                                        </Td>
                                        <Td
                                            py="5px"
                                            border="none"
                                            width="100%"
                                            fontSize="0.9rem"
                                            minHeight="35px"
                                        >
                                            <Flex
                                                gap={{
                                                    base: '1px',
                                                    sm: '10px',
                                                }}
                                                alignItems="baseline"
                                                flexDirection={{
                                                    base: 'column',
                                                    sm: 'row',
                                                }}
                                            >
                                                <Text>
                                                    {selectedTaf?.validFrom &&
                                                        formatDateTime(
                                                            selectedTaf.validFrom,
                                                            timeZone
                                                        )}
                                                </Text>
                                                <Text
                                                    color="gray.400"
                                                    fontSize="0.75rem"
                                                    fontWeight="400"
                                                >
                                                    {selectedTaf?.validFrom &&
                                                        formatDistanceToNow(
                                                            parseISO(
                                                                selectedTaf.validFrom
                                                            ),
                                                            {
                                                                addSuffix: true,
                                                            }
                                                        )}
                                                </Text>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                    {!nullTafData && !nilTaf && (
                                        <Tr>
                                            <Td
                                                border="none"
                                                pt="5px"
                                                pb="15px"
                                                paddingStart="0"
                                                paddingEnd="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="90px"
                                                whiteSpace="pre"
                                                fontSize="0.9rem"
                                                textAlign="left"
                                                minHeight="35px"
                                            >
                                                Valid To:
                                            </Td>
                                            <Td
                                                border="none"
                                                pt="5px"
                                                pb="15px"
                                                width="100%"
                                                fontSize="0.9rem"
                                                minHeight="35px"
                                            >
                                                <Flex
                                                    gap={{
                                                        base: '1px',
                                                        sm: '10px',
                                                    }}
                                                    alignItems="baseline"
                                                    flexDirection={{
                                                        base: 'column',
                                                        sm: 'row',
                                                    }}
                                                >
                                                    <Text>
                                                        {selectedTaf?.validTo &&
                                                            formatDateTime(
                                                                selectedTaf.validTo,
                                                                timeZone
                                                            )}
                                                    </Text>
                                                    <Text
                                                        color="gray.400"
                                                        fontSize="0.75rem"
                                                        fontWeight="400"
                                                    >
                                                        {selectedTaf?.validTo &&
                                                            formatDistanceToNow(
                                                                parseISO(
                                                                    selectedTaf.validTo
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>

                            {selectedTaf &&
                                !nullTafData &&
                                !nilTaf &&
                                selectedTaf.baseForecast &&
                                selectedTaf.baseForecast[0] && (
                                    <Box width="100%">
                                        <Divider
                                            zIndex="2"
                                            borderColor="light.300"
                                            borderBottomWidth="1px"
                                            marginTop="5px"
                                            marginBottom="0px"
                                        />
                                        <TableContainer
                                            className="scrollHorizontal"
                                            ref={instanceRef}
                                        >
                                            <Table
                                                id="tafContentScreen"
                                                size="sm"
                                                variant="simple"
                                                colorScheme="gray"
                                                fontFamily="Open Sans"
                                                marginTop="0"
                                                marginBottom="4"
                                            >
                                                <Tbody
                                                    verticalAlign="top"
                                                    whiteSpace="pre"
                                                    borderBottom="1px solid #EAEDF0 !important"
                                                    clipPath="inset(0px 0px 0px 0px)"
                                                >
                                                    {/* TODO -  CHECK THIS */}
                                                    {/* {selectedTaf.nonereceived && (
                                                     <Tr
                                                        >
                                                        <Td
                                                            border="none"
                                                             paddingStart="3"
                                                            paddingEnd="1"
                                                            fontWeight="bold"
                                                            width="auto"
                                                            minWidth="127px"
                                                            fontSize="0.9rem"
                                                        >
                                                            None Received
                                                        </Td>
                                                    </Tr>
                                                )} */}

                                                    {(tafChangeForecast ||
                                                        isScrollbarVisible) && (
                                                        <Tr>
                                                            <Td
                                                                zIndex="2"
                                                                border="none"
                                                                paddingTop={3}
                                                                paddingStart="3"
                                                                paddingEnd="3"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                pb="0px"
                                                                minWidth="127px"
                                                                height={
                                                                    tafChangeForecast &&
                                                                    isScrollbarVisible
                                                                        ? '72px'
                                                                        : tafChangeForecast
                                                                        ? '72px'
                                                                        : isScrollbarVisible
                                                                        ? '57px'
                                                                        : '0px'
                                                                }
                                                                minHeight={
                                                                    tafChangeForecast &&
                                                                    isScrollbarVisible
                                                                        ? '72px'
                                                                        : tafChangeForecast
                                                                        ? '72px'
                                                                        : isScrollbarVisible
                                                                        ? '57px'
                                                                        : '0px'
                                                                }
                                                                fontSize="0.9rem"
                                                                borderColor="transparent"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                            >
                                                                {!isPremium &&
                                                                    !raw &&
                                                                    isScrollbarVisible && (
                                                                        <Flex
                                                                            width="100%"
                                                                            justifyContent="flex-start"
                                                                            mb="-12px"
                                                                        >
                                                                            <FixedPosToggle // Fix First Column Toggle
                                                                                fixFirstColumn={
                                                                                    fixFirstColumn
                                                                                }
                                                                                setFixFirstColumn={
                                                                                    setFixFirstColumn
                                                                                }
                                                                            />
                                                                        </Flex>
                                                                    )}
                                                            </Td>
                                                            <Td
                                                                border="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                pb="0px"
                                                                borderColor="transparent"
                                                                position="relative"
                                                                height={
                                                                    tafChangeForecast &&
                                                                    isScrollbarVisible
                                                                        ? '72px'
                                                                        : tafChangeForecast
                                                                        ? '72px'
                                                                        : isScrollbarVisible
                                                                        ? '57px'
                                                                        : '0px'
                                                                }
                                                                minHeight={
                                                                    tafChangeForecast &&
                                                                    isScrollbarVisible
                                                                        ? '72px'
                                                                        : tafChangeForecast
                                                                        ? '72px'
                                                                        : isScrollbarVisible
                                                                        ? '57px'
                                                                        : '0px'
                                                                }
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            ></Td>
                                                            {tafChangeForecast &&
                                                                selectedTaf.changeForecast.map(
                                                                    (
                                                                        forecast,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                border="none"
                                                                                key={`changeIndicator-col-${index}`}
                                                                                width="auto"
                                                                                borderColor="transparent"
                                                                                pb="0px"
                                                                            >
                                                                                <Flex
                                                                                    flexDirection="column"
                                                                                    pb="10px"
                                                                                >
                                                                                    <Text
                                                                                        fontSize="0.85rem"
                                                                                        fontWeight="700"
                                                                                        textAlign="left"
                                                                                        width="100%"
                                                                                    >
                                                                                        {changeIndicatorTranslate(
                                                                                            forecast.changeIndicator
                                                                                        )}
                                                                                    </Text>
                                                                                    <Text
                                                                                        pt="5px"
                                                                                        textAlign="left"
                                                                                        width="100%"
                                                                                        fontSize="0.8rem"
                                                                                        fontWeight="400"
                                                                                    >
                                                                                        {forecast?.validFrom &&
                                                                                            formatTimeOnly(
                                                                                                forecast.validFrom,
                                                                                                timeZone
                                                                                            )}{' '}
                                                                                        –{' '}
                                                                                        {forecast?.validTo &&
                                                                                            formatTimeOnly(
                                                                                                forecast.validTo,
                                                                                                timeZone
                                                                                            )}
                                                                                    </Text>
                                                                                    <Text
                                                                                        textAlign="left"
                                                                                        width="100%"
                                                                                        color="gray.400"
                                                                                        fontSize="0.75rem"
                                                                                        fontWeight="400"
                                                                                    >
                                                                                        {forecast?.validFrom &&
                                                                                            formatDayAndDate(
                                                                                                forecast.validFrom,
                                                                                                timeZone
                                                                                            )}{' '}
                                                                                        –{' '}
                                                                                        {forecast?.validTo &&
                                                                                            formatDayAndDate(
                                                                                                forecast.validTo,
                                                                                                timeZone
                                                                                            )}
                                                                                    </Text>
                                                                                </Flex>
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>
                                                    )}

                                                    <Tr>
                                                        <Td
                                                            zIndex="1"
                                                            borderTop={
                                                                (tafChangeForecast ||
                                                                    isScrollbarVisible) &&
                                                                '1px solid #E8EBEE !important'
                                                            }
                                                            borderBottom="none"
                                                            paddingStart="3"
                                                            paddingEnd="1"
                                                            fontWeight="bold"
                                                            width="127px"
                                                            minWidth="127px"
                                                            minHeight="120px"
                                                            fontSize="0.9rem"
                                                            textAlign="left"
                                                            bg="light.35"
                                                            position={
                                                                fixFirstColumn
                                                                    ? 'absolute'
                                                                    : 'relative'
                                                            }
                                                            pointerEvents={
                                                                fixFirstColumn
                                                                    ? 'none'
                                                                    : 'initial'
                                                            }
                                                        >
                                                            Surface Wind:
                                                        </Td>
                                                        <Td
                                                            borderTop={
                                                                (tafChangeForecast ||
                                                                    isScrollbarVisible) &&
                                                                '1px solid #E8EBEE !important'
                                                            }
                                                            borderBottom="none"
                                                            width="100%"
                                                            fontSize="0.9rem"
                                                            position="relative"
                                                            paddingLeft={
                                                                fixFirstColumn
                                                                    ? '143px'
                                                                    : '4'
                                                            }
                                                        >
                                                            <Flex flexDirection="row">
                                                                <Text>
                                                                    {windvar
                                                                        ? 'VRB '
                                                                        : winddir.value
                                                                        ? winddir.value
                                                                              .toString()
                                                                              .padStart(
                                                                                  3,
                                                                                  '0'
                                                                              ) +
                                                                          '°T '
                                                                        : ''}
                                                                    {windspeed.value +
                                                                        ' kt'}
                                                                    {windgust
                                                                        ? ', Gusts ' +
                                                                          windgust.value +
                                                                          ' kt'
                                                                        : ''}
                                                                </Text>
                                                            </Flex>
                                                        </Td>
                                                        {selectedTaf.changeForecast &&
                                                            selectedTaf
                                                                .changeForecast
                                                                .length > 0 &&
                                                            selectedTaf.changeForecast.map(
                                                                (
                                                                    forecast,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Td
                                                                            borderTop="1px solid #EAEDF0 !important"
                                                                            borderBottom="none"
                                                                            key={`wind-col-${index}`}
                                                                            width="auto"
                                                                            fontSize="0.9rem"
                                                                        >
                                                                            {forecast.surfaceWind && (
                                                                                <Flex flexDirection="row">
                                                                                    <Text>
                                                                                        {forecast
                                                                                            .surfaceWind
                                                                                            .variableWindDirection
                                                                                            ? 'VRB '
                                                                                            : forecast.surfaceWind.meanWindDirection.value
                                                                                                  .toString()
                                                                                                  .padStart(
                                                                                                      3,
                                                                                                      '0'
                                                                                                  ) +
                                                                                              '°T'}{' '}
                                                                                        {
                                                                                            forecast
                                                                                                .surfaceWind
                                                                                                .meanWindSpeed
                                                                                                .value
                                                                                        }{' '}
                                                                                        kt
                                                                                        {forecast
                                                                                            .surfaceWind
                                                                                            .windGustSpeed
                                                                                            ? ', Gusts ' +
                                                                                              forecast
                                                                                                  .surfaceWind
                                                                                                  .windGustSpeed
                                                                                                  .value +
                                                                                              ' kt'
                                                                                            : ''}
                                                                                    </Text>
                                                                                </Flex>
                                                                            )}
                                                                        </Td>
                                                                    )
                                                                }
                                                            )}
                                                    </Tr>
                                                    <Tr>
                                                        <Td
                                                            zIndex="1"
                                                            borderTop="1px solid #E8EBEE !important"
                                                            borderBottom="none"
                                                            paddingStart="3"
                                                            paddingEnd="1"
                                                            fontWeight="bold"
                                                            width="127px"
                                                            minWidth="127px"
                                                            minHeight="120px"
                                                            fontSize="0.9rem"
                                                            textAlign="left"
                                                            bg="light.35"
                                                            position={
                                                                fixFirstColumn
                                                                    ? 'absolute'
                                                                    : 'relative'
                                                            }
                                                            pointerEvents={
                                                                fixFirstColumn
                                                                    ? 'none'
                                                                    : 'initial'
                                                            }
                                                        >
                                                            Visibility:
                                                        </Td>
                                                        {selectedTaf
                                                            .baseForecast[0]
                                                            .cloudAndVisibilityOK ? (
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                Visibility OK
                                                            </Td>
                                                        ) : (
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                {selectedTaf
                                                                    .baseForecast[0]
                                                                    .horizontalVisibility
                                                                    .operator ===
                                                                'ABOVE'
                                                                    ? '> '
                                                                    : selectedTaf
                                                                          .baseForecast[0]
                                                                          .horizontalVisibility
                                                                          .operator ===
                                                                      'BELOW'
                                                                    ? ' <'
                                                                    : ''}
                                                                {
                                                                    selectedTaf
                                                                        .baseForecast[0]
                                                                        .horizontalVisibility
                                                                        .value
                                                                }{' '}
                                                                {
                                                                    selectedTaf
                                                                        .baseForecast[0]
                                                                        .horizontalVisibility
                                                                        .unit
                                                                }
                                                            </Td>
                                                        )}
                                                        {selectedTaf.changeForecast &&
                                                            selectedTaf
                                                                .changeForecast
                                                                .length > 0 &&
                                                            selectedTaf.changeForecast.map(
                                                                (
                                                                    forecast,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Td
                                                                            borderTop="1px solid #EAEDF0 !important"
                                                                            borderBottom="none"
                                                                            key={`visibility-col-${index}`}
                                                                            width="auto"
                                                                            fontSize="0.9rem"
                                                                        >
                                                                            {forecast.horizontalVisibility && (
                                                                                <Flex flexDirection="row">
                                                                                    <Text>
                                                                                        {forecast
                                                                                            .horizontalVisibility
                                                                                            .operator ===
                                                                                        'ABOVE'
                                                                                            ? '> '
                                                                                            : forecast
                                                                                                  .horizontalVisibility
                                                                                                  .operator ===
                                                                                              'BELOW'
                                                                                            ? ' <'
                                                                                            : ''}
                                                                                        {
                                                                                            forecast
                                                                                                .horizontalVisibility
                                                                                                .value
                                                                                        }{' '}
                                                                                        {
                                                                                            forecast
                                                                                                .horizontalVisibility
                                                                                                .unit
                                                                                        }
                                                                                    </Text>
                                                                                </Flex>
                                                                            )}
                                                                        </Td>
                                                                    )
                                                                }
                                                            )}
                                                    </Tr>
                                                    {((selectedTaf.baseForecast &&
                                                        selectedTaf
                                                            .baseForecast[0] &&
                                                        selectedTaf
                                                            .baseForecast[0]
                                                            .weather) ||
                                                        (selectedTaf.changeForecast &&
                                                            selectedTaf.changeForecast.filter(
                                                                (forecast) =>
                                                                    forecast.weather
                                                            ).length > 0)) && (
                                                        <Tr>
                                                            <Td
                                                                zIndex="1"
                                                                borderTop="1px solid #E8EBEE !important"
                                                                borderBottom="none"
                                                                paddingStart="3"
                                                                paddingEnd="1"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                minWidth="127px"
                                                                minHeight="120px"
                                                                fontSize="0.9rem"
                                                                textAlign="left"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                                pointerEvents={
                                                                    fixFirstColumn
                                                                        ? 'none'
                                                                        : 'initial'
                                                                }
                                                            >
                                                                Weather:
                                                            </Td>
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                {selectedTaf
                                                                    .baseForecast[0]
                                                                    .weather
                                                                    ? wxTranslate(
                                                                          selectedTaf
                                                                              .baseForecast[0]
                                                                              .weather
                                                                      )
                                                                    : 'No significant weather'}
                                                            </Td>
                                                            {selectedTaf.changeForecast &&
                                                                selectedTaf.changeForecast.map(
                                                                    (
                                                                        forecast,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                borderTop="1px solid #EAEDF0 !important"
                                                                                borderBottom="none"
                                                                                key={`weather-col-${index}`}
                                                                                width="auto"
                                                                                fontSize="0.9rem"
                                                                            >
                                                                                <Flex flexDirection="row">
                                                                                    <Text>
                                                                                        {forecast.weather &&
                                                                                            wxTranslate(
                                                                                                forecast.weather
                                                                                            )}
                                                                                    </Text>
                                                                                </Flex>
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>
                                                    )}
                                                    <Tr>
                                                        <Td
                                                            zIndex="1"
                                                            borderTop="1px solid #E8EBEE !important"
                                                            borderBottom="none"
                                                            paddingStart="3"
                                                            paddingEnd="1"
                                                            fontWeight="bold"
                                                            width="127px"
                                                            minWidth="127px"
                                                            minHeight="120px"
                                                            fontSize="0.9rem"
                                                            textAlign="left"
                                                            bg="light.35"
                                                            position={
                                                                fixFirstColumn
                                                                    ? 'absolute'
                                                                    : 'relative'
                                                            }
                                                            pointerEvents={
                                                                fixFirstColumn
                                                                    ? 'none'
                                                                    : 'initial'
                                                            }
                                                        >
                                                            Cloud:
                                                        </Td>
                                                        <Td
                                                            borderTop="1px solid #EAEDF0 !important"
                                                            borderBottom="none"
                                                            width="100%"
                                                            fontSize="0.9rem"
                                                            position="relative"
                                                            paddingLeft={
                                                                fixFirstColumn
                                                                    ? '143px'
                                                                    : '4'
                                                            }
                                                        >
                                                            <Flex
                                                                flexDirection="column"
                                                                justifyContent="space-between"
                                                                gap="6px"
                                                            >
                                                                {reversedCloudLayers &&
                                                                    reversedCloudLayers.map(
                                                                        (
                                                                            layer,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <Flex
                                                                                    key={`baseForecast-cloudLayer-${index}`}
                                                                                    gap="5px"
                                                                                >
                                                                                    <Text>
                                                                                        {
                                                                                            layer.amount
                                                                                        }
                                                                                    </Text>
                                                                                    {layer.base &&
                                                                                        layer
                                                                                            .base
                                                                                            .value && (
                                                                                            <Text>
                                                                                                {layer
                                                                                                    .base
                                                                                                    .value +
                                                                                                    ' ft AGL '}
                                                                                            </Text>
                                                                                        )}
                                                                                    {layer.type && (
                                                                                        <Text>
                                                                                            {
                                                                                                layer.type
                                                                                            }
                                                                                        </Text>
                                                                                    )}
                                                                                </Flex>
                                                                            )
                                                                        }
                                                                    )}
                                                                {noSignificantCloud &&
                                                                    !cloudAndVisibilityOK && (
                                                                        <Text>
                                                                            Sky
                                                                            clear
                                                                        </Text>
                                                                    )}
                                                                {cloudAndVisibilityOK && (
                                                                    <Text>
                                                                        Cloud OK
                                                                    </Text>
                                                                )}
                                                            </Flex>
                                                        </Td>
                                                        {selectedTaf.changeForecast &&
                                                            selectedTaf.changeForecast.map(
                                                                (
                                                                    forecast,
                                                                    index
                                                                ) => {
                                                                    const changeCoudLayers =
                                                                        forecast.cloud &&
                                                                        forecast
                                                                            .cloud
                                                                            .layer
                                                                    const reversedChangeCloudLayers =
                                                                        changeCoudLayers
                                                                            ? reverseArray(
                                                                                  changeCoudLayers
                                                                              )
                                                                            : []

                                                                    return (
                                                                        <Td
                                                                            borderTop="1px solid #EAEDF0 !important"
                                                                            borderBottom="none"
                                                                            key={`cloud-col-${index}`}
                                                                            width="100%"
                                                                            fontSize="0.9rem"
                                                                        >
                                                                            {forecast.cloud && (
                                                                                <Flex
                                                                                    flexDirection="column"
                                                                                    justifyContent="space-between"
                                                                                    gap="6px"
                                                                                >
                                                                                    {reversedChangeCloudLayers.map(
                                                                                        (
                                                                                            layer,
                                                                                            idx
                                                                                        ) => {
                                                                                            return (
                                                                                                <Text
                                                                                                    key={`cloud-col-${index}-cloudLayer-${idx}`}
                                                                                                >
                                                                                                    {
                                                                                                        layer.amount
                                                                                                    }{' '}
                                                                                                    {layer.base &&
                                                                                                    layer
                                                                                                        .base
                                                                                                        .value
                                                                                                        ? layer
                                                                                                              .base
                                                                                                              .value +
                                                                                                          ' ft AGL '
                                                                                                        : ''}
                                                                                                    {layer.type
                                                                                                        ? layer.type
                                                                                                        : ''}
                                                                                                </Text>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                                    {forecast
                                                                                        .cloud
                                                                                        .noSignificantCloud && (
                                                                                        <Text>
                                                                                            Sky
                                                                                            clear
                                                                                        </Text>
                                                                                    )}
                                                                                </Flex>
                                                                            )}
                                                                        </Td>
                                                                    )
                                                                }
                                                            )}
                                                    </Tr>
                                                    {selectedTaf.baseForecast[0]
                                                        .twoThousandFtWind && (
                                                        <Tr>
                                                            <Td
                                                                zIndex="1"
                                                                borderTop="1px solid #E8EBEE !important"
                                                                borderBottom="none"
                                                                paddingStart="3"
                                                                paddingEnd="1"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                minWidth="127px"
                                                                minHeight="120px"
                                                                fontSize="0.9rem"
                                                                textAlign="left"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                                pointerEvents={
                                                                    fixFirstColumn
                                                                        ? 'none'
                                                                        : 'initial'
                                                                }
                                                            >
                                                                2000 ft Wind:
                                                            </Td>
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                <Text>{`${
                                                                    selectedTaf
                                                                        .baseForecast[0]
                                                                        .twoThousandFtWind
                                                                        .windDirection
                                                                        .value ===
                                                                    'VRB'
                                                                        ? 'VRB'
                                                                        : selectedTaf.baseForecast[0].twoThousandFtWind.windDirection.value
                                                                              .toString()
                                                                              .padStart(
                                                                                  3,
                                                                                  '0'
                                                                              ) +
                                                                          '°T'
                                                                } ${
                                                                    selectedTaf
                                                                        .baseForecast[0]
                                                                        .twoThousandFtWind
                                                                        .windSpeed
                                                                        .value
                                                                } kt`}</Text>
                                                            </Td>
                                                            {selectedTaf.changeForecast &&
                                                                selectedTaf.changeForecast.map(
                                                                    (
                                                                        forecast,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                borderTop="1px solid #EAEDF0 !important"
                                                                                borderBottom="none"
                                                                                key={`twoThousandFtWind-col-${index}`}
                                                                                width="auto"
                                                                            >
                                                                                <Flex flexDirection="row">
                                                                                    {forecast.twoThousandFtWind && (
                                                                                        <Text>{`${
                                                                                            forecast
                                                                                                .twoThousandFtWind
                                                                                                .windDirection ===
                                                                                            null
                                                                                                ? ''
                                                                                                : forecast
                                                                                                      .twoThousandFtWind
                                                                                                      .windDirection
                                                                                                      .value ===
                                                                                                  'VRB'
                                                                                                ? 'VRB'
                                                                                                : forecast.twoThousandFtWind.windDirection.value
                                                                                                      .toString()
                                                                                                      .padStart(
                                                                                                          3,
                                                                                                          '0'
                                                                                                      ) +
                                                                                                  '°T'
                                                                                        } ${
                                                                                            forecast
                                                                                                .twoThousandFtWind
                                                                                                .windSpeed
                                                                                                .value ===
                                                                                            null
                                                                                                ? ''
                                                                                                : forecast
                                                                                                      .twoThousandFtWind
                                                                                                      .windSpeed
                                                                                                      .value
                                                                                        } ${
                                                                                            forecast
                                                                                                .twoThousandFtWind
                                                                                                .windSpeed
                                                                                                .value ===
                                                                                            null
                                                                                                ? ''
                                                                                                : 'kt'
                                                                                        }`}</Text>
                                                                                    )}
                                                                                </Flex>
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>
                                                    )}
                                                    {selectedTaf.baseForecast[0]
                                                        .qnh && (
                                                        <Tr>
                                                            <Td
                                                                zIndex="1"
                                                                borderTop="1px solid #E8EBEE !important"
                                                                borderBottom="none"
                                                                paddingStart="3"
                                                                paddingEnd="1"
                                                                fontWeight="bold"
                                                                width="127px"
                                                                minWidth="127px"
                                                                minHeight="60px"
                                                                fontSize="0.9rem"
                                                                textAlign="left"
                                                                bg="light.35"
                                                                position={
                                                                    fixFirstColumn
                                                                        ? 'absolute'
                                                                        : 'relative'
                                                                }
                                                                pointerEvents={
                                                                    fixFirstColumn
                                                                        ? 'none'
                                                                        : 'initial'
                                                                }
                                                            >
                                                                QNH:
                                                            </Td>
                                                            <Td
                                                                borderTop="1px solid #EAEDF0 !important"
                                                                borderBottom="none"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                position="relative"
                                                                paddingLeft={
                                                                    fixFirstColumn
                                                                        ? '143px'
                                                                        : '4'
                                                                }
                                                            >
                                                                <Flex>
                                                                    <Text
                                                                        fontSize="0.6rem"
                                                                        lineHeight="2"
                                                                        width="30px"
                                                                    >
                                                                        MNM:{' '}
                                                                    </Text>{' '}
                                                                    <Text>
                                                                        {' '}
                                                                        {
                                                                            selectedTaf
                                                                                .baseForecast[0]
                                                                                .qnh
                                                                                .minimum
                                                                                .value
                                                                        }
                                                                    </Text>
                                                                </Flex>
                                                                <Flex>
                                                                    <Text
                                                                        fontSize="0.6rem"
                                                                        lineHeight="2"
                                                                        width="30px"
                                                                    >
                                                                        MAX:{' '}
                                                                    </Text>{' '}
                                                                    <Text>
                                                                        {' '}
                                                                        {
                                                                            selectedTaf
                                                                                .baseForecast[0]
                                                                                .qnh
                                                                                .maximum
                                                                                .value
                                                                        }
                                                                    </Text>
                                                                </Flex>
                                                            </Td>
                                                            {selectedTaf.changeForecast &&
                                                                selectedTaf.changeForecast.map(
                                                                    (
                                                                        forecast,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Td
                                                                                borderTop="1px solid #EAEDF0 !important"
                                                                                borderBottom="none"
                                                                                key={`qnh-col-${index}`}
                                                                                width="auto"
                                                                                fontSize="0.9rem"
                                                                            >
                                                                                {forecast.qnh && (
                                                                                    <>
                                                                                        <Flex>
                                                                                            <Text
                                                                                                fontSize="0.6rem"
                                                                                                lineHeight="2"
                                                                                                width="30px"
                                                                                            >
                                                                                                Min:{' '}
                                                                                            </Text>
                                                                                            <Text>
                                                                                                {
                                                                                                    forecast
                                                                                                        .qnh
                                                                                                        .minimum
                                                                                                        .value
                                                                                                }
                                                                                            </Text>
                                                                                        </Flex>
                                                                                        <Flex>
                                                                                            <Text
                                                                                                fontSize="0.6rem"
                                                                                                lineHeight="2"
                                                                                                width="30px"
                                                                                            >
                                                                                                Max:{' '}
                                                                                            </Text>
                                                                                            <Text>
                                                                                                {
                                                                                                    forecast
                                                                                                        .qnh
                                                                                                        .maximum
                                                                                                        .value
                                                                                                }
                                                                                            </Text>
                                                                                        </Flex>
                                                                                    </>
                                                                                )}
                                                                            </Td>
                                                                        )
                                                                    }
                                                                )}
                                                        </Tr>
                                                    )}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                        </Box>
                    )}
                    {nilTaf && !raw && (
                        <Box fontSize="0.9rem" whiteSpace="pre-line" pt="5px">
                            NIL
                        </Box>
                    )}
                    <Box fontSize="0.9rem" whiteSpace="pre-line" pt="5px">
                        {raw && data.taf.raw}
                    </Box>
                </Box>

                <Divider
                    zIndex="2"
                    borderColor="light.300"
                    borderBottomWidth="1px"
                    marginTop={raw ? '20px' : '10px'}
                    marginBottom="10px"
                />

                <Box align="Right" color="gray.400" zIndex="2">
                    {raw && (
                        <Flex flexDirection="column">
                            <Text
                                height="22px"
                                as="i"
                                fontSize="0.7rem"
                                paddingBottom="5px"
                                paddingRight="3px"
                            >
                                All times referenced in the standard TAF are{' '}
                                <strong>UTC</strong>
                            </Text>
                        </Flex>
                    )}
                    {!raw && (
                        <Flex flexDirection="column">
                            <Text
                                height="22px"
                                as="i"
                                fontSize="0.7rem"
                                paddingBottom="5px"
                                paddingRight="3px"
                            >
                                All dates and times referenced are{' '}
                                <strong>
                                    {`${
                                        timeZone === 'UTC'
                                            ? 'UTC'
                                            : timeZoneAbbreviation(
                                                  new Date(),
                                                  Intl.DateTimeFormat().resolvedOptions()
                                                      .timeZone,
                                                  'z'
                                              )
                                    }`}
                                </strong>
                            </Text>
                        </Flex>
                    )}
                    <Flex flexDirection="column">
                        <Text
                            height="25px"
                            as="i"
                            fontSize="0.7rem"
                            paddingBottom="5px"
                            paddingRight="3px"
                        >
                            All heights reference feet above aerodrome level
                        </Text>
                    </Flex>
                    {raw && (
                        <Flex flexDirection="column">
                            <Text
                                height="22px"
                                as="i"
                                fontSize="0.7rem"
                                lineHeight="1"
                                paddingBottom="5px"
                                paddingRight="3px"
                            >
                                Wind:°TRUE / Speed:KT
                            </Text>
                        </Flex>
                    )}
                </Box>
            </Box>
        </>
    )
}
