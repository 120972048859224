import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function RadarIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 6}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.03081 11.25C3.39152 6.87765 6.87765 3.39152 11.25 3.03081V2H12.75V3.03081C17.1223 3.39152 20.6085 6.87765 20.9692 11.25H22V12.75H20.9692C20.6085 17.1223 17.1223 20.6085 12.75 20.9692V22H11.25V20.9692C6.87765 20.6085 3.39152 17.1223 3.03081 12.75H2V11.25H3.03081ZM12.75 7.05588V4.53703C16.2933 4.88883 19.1112 7.70669 19.463 11.25H16.9441C16.6191 9.08901 14.911 7.38094 12.75 7.05588ZM11.25 7.05588V4.53703C7.70669 4.88883 4.88883 7.70669 4.53703 11.25H7.05588C7.38094 9.08901 9.08901 7.38094 11.25 7.05588ZM8.58054 11.25C8.87113 9.91903 9.91903 8.87113 11.25 8.58054V11.25H8.58054ZM7.05588 12.75H4.53703C4.88883 16.2933 7.70669 19.1112 11.25 19.463V16.9441C9.08901 16.6191 7.38094 14.911 7.05588 12.75ZM11.25 15.4195C9.91903 15.1289 8.87113 14.081 8.58054 12.75H11.25V15.4195ZM12.75 16.9441V19.463C16.2933 19.1112 19.1112 16.2933 19.463 12.75H16.9441C16.6191 14.911 14.911 16.6191 12.75 16.9441ZM15.4195 12.75C15.1289 14.081 14.081 15.1289 12.75 15.4195V12.75H15.4195ZM15.4195 11.25H12.75V8.58054C14.081 8.87113 15.1289 9.91903 15.4195 11.25Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
