import React from 'react'
import { Button, Flex, Tooltip, Icon } from '@chakra-ui/react'
import { isMobile, isDesktop } from 'react-device-detect'
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs'

export default function FixedPosToggle(props) {
    const { fixFirstColumn, setFixFirstColumn } = props

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <Flex width="max-content">
            <Tooltip
                label={
                    fixFirstColumn ? 'Unpin first column' : 'Pin first column'
                }
                hasArrow
                borderRadius="7px"
                height="26px"
                fontSize="0.7rem"
                alignItems="center"
                display={isMobile || !isDesktop ? 'none' : 'flex'}
                isDisabled={isMobile || !isDesktop}
                placement="top"
                m="0px"
                mr="5px"
                color="light.10"
                bg="dark.10"
            >
                <Button
                    background="#F7FAFC"
                    variant="outline"
                    fontSize="11px"
                    fontWeight="600"
                    height={{ base: '32px', md: '30px' }}
                    width={{ base: '34px', md: '32px' }}
                    minWidth={{ base: '34px', md: '32px' }}
                    paddingLeft="2px"
                    paddingRight="2px"
                    py="2px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="10px"
                    {...noFocus}
                    onClick={() => {
                        setFixFirstColumn((prevState) => !prevState)
                    }}
                    color={fixFirstColumn ? 'gray.500' : 'gray.400'}
                    _selected={{
                        opacity: '1',
                    }}
                    _hover={{
                        background: 'light.90',
                    }}
                    _active={{
                        opacity: '1',
                        background: 'light.90',
                        boxShadow: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                    }}
                    transition="all ease 300ms"
                >
                    <Icon
                        as={fixFirstColumn ? BsPinAngleFill : BsPinAngle}
                        fontSize="1.1rem"
                    />
                </Button>
            </Tooltip>
        </Flex>
    )
}
