import React from 'react'
import { useRecoilValue } from 'recoil'
import { Layer, Source } from 'react-mapbox-gl'

import { VectorLayerRefreshInfo } from '../../../globalState'

export default function RunwaysLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0,
        11,
        0,
        12,
        0.5,
        13,
        1,
    ]
    const fontSizeDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        5,
        11,
        5,
        12,
        8,
        13,
        10,
    ]
    // const zoomLabelDefinition = [
    //     'interpolate',
    //     ['linear'],
    //     ['zoom'],
    //     0,
    //     0.6,
    //     11,
    //     0.6,
    //     12,
    //     0.7,
    //     13,
    //     0.9,
    //     14,
    //     1,
    // ]
    const opacityDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0,
        11,
        0,
        12,
        0.4,
        13,
        1,
    ]

    return (
        <>
            <Source
                id="runways"
                geoJsonSource={{
                    type: 'geojson',
                    data: layerData.runways.dataURL,
                }}
            />

            <Layer
                id="runways-grass-line"
                sourceId="runways"
                type="line"
                before="aerodromes-symbol"
                filter={['==', 'surface', 'GRASS']}
                paint={{
                    'line-opacity': zoomLevelDefinition,
                    'line-color': '#a7bf5f',
                    'line-width': 1.5,
                }}
            />
            <Layer
                id="runways-grass-starterextn-fill"
                sourceId="runways"
                type="fill"
                before="aerodromes-symbol"
                filter={[
                    'all',
                    ['==', 'surface', 'GRASS'],
                    ['==', 'type', 'starterextn'],
                ]}
                paint={{
                    'fill-opacity': 0.9,
                    'fill-color': '#d8e5b3',
                }}
            />
            <Layer
                id="runways-grass-fill"
                sourceId="runways"
                type="fill"
                before="aerodromes-symbol"
                filter={[
                    'all',
                    ['==', 'surface', 'GRASS'],
                    ['==', 'type', 'runway'],
                ]}
                paint={{
                    'fill-opacity': 0.8,
                    'fill-color': '#badd69',
                }}
            />
            <Layer
                id="runways-line"
                sourceId="runways"
                type="line"
                filter={['!=', 'surface', 'GRASS']}
                before="aerodromes-symbol"
                paint={{
                    'line-opacity': zoomLevelDefinition,
                    'line-color': '#41474c',
                    'line-width': 1.5,
                }}
            />
            <Layer
                id="runways-starterextn-fill"
                sourceId="runways"
                type="fill"
                before="aerodromes-symbol"
                filter={[
                    'all',
                    ['==', 'type', 'starterextn'],
                    ['!=', 'surface', 'GRASS'],
                ]}
                paint={{
                    'fill-opacity': 0.9,
                    'fill-color': '#b2bbc1',
                }}
            />
            <Layer
                id="runways-fill"
                sourceId="runways"
                type="fill"
                before="aerodromes-symbol"
                filter={[
                    'all',
                    ['==', 'type', 'runway'],
                    ['!=', 'surface', 'GRASS'],
                ]}
                paint={{
                    'fill-opacity': 0.9,
                    'fill-color': '#848e96',
                }}
            />

            <Layer
                id="runways-designator"
                sourceId="runways"
                type="symbol"
                before="aerodromes-symbol"
                layout={{
                    'text-field': [
                        'format',
                        '\n',
                        {},
                        ['get', 'designator'],
                        { 'font-scale': 1 },
                    ],
                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-allow-overlap': true,
                    'text-size': fontSizeDefinition,
                    'text-anchor': 'center',
                    'text-rotation-alignment': 'map',
                    'text-pitch-alignment': 'map',
                    'text-max-angle': 400,
                    'text-offset': [0, 0],
                    'text-line-height': 2.2,
                    'text-rotate': ['get', 'azimuth'],
                    // 'icon-image': [
                    //     'match',
                    //     ['get', 'circuitdirection'],
                    //     'RIGHT',
                    //     'rhcircuit',
                    //     '',
                    // ],
                    // 'icon-allow-overlap': true,
                    // 'icon-rotation-alignment': 'map',
                    // 'icon-size': zoomLabelDefinition,
                    // 'icon-offset': [0, 50],
                    // 'icon-rotate': ['get', 'azimuth'],
                }}
                filter={['==', 'type', 'designatorlabel']}
                paint={{
                    'text-color': '#000',
                    'text-halo-color': '#fff',
                    'text-halo-width': 1,
                    'text-opacity': zoomLevelDefinition,
                    'icon-opacity': opacityDefinition,
                }}
            />
        </>
    )
}
