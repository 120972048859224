import skyNcd from './skybg-ncd.png'
import skyNcdNight from './skybg-ncd-night.png'
import skyNcdSunrise from './skybg-ncd-sunset.png'
import skyNcdDay from './skybg-ncd.png'
import skyNcdSunset from './skybg-ncd-sunset.png'

import skyFew from './skybg-few.png'
import skyFewNight from './skybg-few-night.png'
import skyFewSunrise from './skybg-few-sunset.png'
import skyFewDay from './skybg-few.png'
import skyFewSunset from './skybg-few-sunset.png'

import skySct from './skybg-sct.png'
import skySctNight from './skybg-sct-night.png'
import skySctSunrise from './skybg-sct-sunrise.png'
import skySctDay from './skybg-sct.png'
import skySctSunset from './skybg-sct-sunset.png'

import skyBkn from './skybg-bkn.png'
import skyBknNight from './skybg-bkn-night.png'
import skyBknSunrise from './skybg-bkn-sunrise.png'
import skyBknDay from './skybg-bkn.png'
import skyBknSunset from './skybg-bkn-sunset.png'

import skyOvc from './skybg-ovc.png'
import skyOvcNight from './skybg-ovc-night.png'
import skyOvcSunrise from './skybg-ovc-sunrise.png'
import skyOvcDay from './skybg-ovc.png'
import skyOvcSunset from './skybg-ovc-sunset.png'

import skyFog from './skybg-fog.png'

import { isMobileOnly } from 'react-device-detect'

export function cloudCoverBgImage(value) {
    switch (value) {
        // FOG OR MIST (when no cloud detected)
        case 'Fog-Night':
            return `url(${skyOvcNight})` // TODO
        case 'Fog-Sunrise':
            return `url(${skyFog})` // TODO
        case 'Fog-Day':
            return `url(${skyFog})`
        case 'Fog-Sunset':
            return `url(${skyFog})` // TODO
        case 'Fog':
            return `url(${skyFog})`

        // OVERCAST (8 of 8 Octas)
        case 'Overcast-Night':
            return `url(${skyOvcNight})`
        case 'Overcast-Sunrise':
            return `url(${skyOvcSunrise})`
        case 'Overcast-Day':
            return `url(${skyOvcDay})`
        case 'Overcast-Sunset':
            return `url(${skyOvcSunset})`
        case 'Overcast':
            return `url(${skyOvc})`

        // BROKEN (5-7 of 8 Octas)
        case 'Broken-Night':
            return `url(${skyBknNight})`
        case 'Broken-Sunrise':
            return `url(${skyBknSunrise})`
        case 'Broken-Day':
            return `url(${skyBknDay})`
        case 'Broken-Sunset':
            return `url(${skyBknSunset})`
        case 'Broken':
            return `url(${skyBkn})`

        // SCATTERED (3-4 of 8 Octas)
        case 'Scattered-Night':
            return `url(${skySctNight})`
        case 'Scattered-Sunrise':
            return `url(${skySctSunrise})`
        case 'Scattered-Day':
            return `url(${skySctDay})`
        case 'Scattered-Sunset':
            return `url(${skySctSunset})`
        case 'Scattered':
            return `url(${skySct})`

        // FEW (1-2 of 8 Octas)
        case 'Few-Night':
            return `url(${skyFewNight})`
        case 'Few-Sunrise':
            return `url(${skyFewSunrise})`
        case 'Few-Day':
            return `url(${skyFewDay})`
        case 'Few-Sunset':
            return `url(${skyFewSunset})`
        case 'Few':
            return `url(${skyFew})`

        // CLEAR SKY (0 of 8 Octas)
        case 'NCD-Night':
            return `url(${skyNcdNight})`
        case 'NCD-Sunrise':
            return `url(${skyNcdSunrise})`
        case 'NCD-Day':
            return `url(${skyNcdDay})`
        case 'NCD-Sunset':
            return `url(${skyNcdSunset})`
        case 'NCD':
            return `url(${skyNcd})`

        // DEFAULT - CLEAR SKY
        default:
            return `url(${skyNcd})`
    }
}

export function cloudCoverText(value) {
    switch (value) {
        case 'Fog':
            return `#657889`
        case 'Night':
            return `rgba(255, 255, 255, 0.6)`
        case 'Sunrise':
            return `rgba(20, 60, 90, 0.45)`
        case 'Sunset':
            return `rgba(13, 39, 56, 0.45)`
        case 'Overcast':
            return `rgba(20, 60, 90, 0.45)`
        case 'Broken':
            return `rgba(20, 60, 90, 0.45)`
        case 'Scattered':
            return `rgba(70, 145, 195, 0.75)`
        case 'Few':
            return `rgba(70, 145, 195, 0.75)`
        default:
            return `rgba(70, 145, 195, 0.8)`
    }
}

export function cloudCoverDetail(value) {
    switch (value) {
        case 'Fog':
            return `#becad3`
        case 'Overcast':
            return `#becad3`
        case 'Broken':
            return `#becad3`
        case 'Scattered':
            return `light.10`
        case 'Few':
            return `light.10`
        default:
            return `light.10`
    }
}

export function cloudCoverTextHighlight(value) {
    switch (value) {
        case 'Fog':
            return `light.200`
        case 'Overcast':
            return `light.10`
        case 'Broken':
            return `light.10`
        case 'Scattered':
            return `light.10`
        case 'Few':
            return `light.10`
        default:
            return `light.10`
    }
}

export function cloudCoverBgGradient(value) {
    switch (value) {
        // FOG OR MIST (when no cloud detected)
        case 'Fog':
            return `linear-gradient(rgb(201, 209, 216) 10%, rgb(187, 199, 207) 50%, rgb(239, 245, 251) 100%)`
        case 'Fog-Night':
            return `linear-gradient(rgb(11, 26, 37) 0%, rgb(27, 57, 85) 50%, #49688f 100%)`
        case 'Fog-Sunrise':
            return `linear-gradient(rgb(201, 209, 216) 10%, rgb(187, 199, 207) 50%, rgb(239, 245, 251) 100%)` // TODO
        case 'Fog-Day':
            return `linear-gradient(rgb(201, 209, 216) 10%, rgb(187, 199, 207) 50%, rgb(239, 245, 251) 100%)`
        case 'Fog-Sunset':
            return `linear-gradient(rgb(201, 209, 216) 10%, rgb(187, 199, 207) 50%, rgb(239, 245, 251) 100%)` // TODO

        // OVERCAST (8 of 8 Octas)
        case 'Overcast':
            return `linear-gradient(rgb(85, 130, 159) 10%, rgb(180, 210, 223) 70%, rgb(210, 233, 244) 100%)`
        case 'Overcast-Night':
            return `linear-gradient(rgb(11, 26, 37) 0%, rgb(27, 57, 85) 50%, #49688f 100%)`
        case 'Overcast-Sunrise':
            return `linear-gradient(rgb(99, 141, 162) 10%, rgb(155, 185, 200) 35%, rgb(191, 212, 221) 55%, rgb(247, 241, 247) 80%, #ffeffb 90%, #fbddee 100%)`
        case 'Overcast-Day':
            return `linear-gradient(rgb(85, 130, 159) 10%, rgb(180, 210, 223) 70%, rgb(210, 233, 244) 100%)`
        case 'Overcast-Sunset':
            return `linear-gradient(rgb(65, 98, 111) 10%, rgb(147, 161, 170) 35%, rgb(175, 189, 204) 50%, rgb(213, 222, 230) 70%, #dddbd9 80%, #f7e5dc 90%, #eedec7 100%)`

        // BROKEN (5-7 of 8 Octas)
        case 'Broken':
            return 'linear-gradient(rgb(168, 201, 223) 10%, rgb(185 221 249) 50%, rgb(224 233 241) 100%)'
        case 'Broken-Night':
            return `linear-gradient(rgb(11, 21, 37) 0%, rgb(27, 52, 85) 50%, #49688f 100%)`
        case 'Broken-Sunrise':
            return `linear-gradient(rgb(61, 120, 149) 10%, rgb(136, 197, 225) 35%, rgb(188, 223, 238) 50%, rgb(235 238 249) 70%, #ffe9e5 80%, #fbdddd 90%, #ffcbc6 100%)`
        case 'Broken-Day':
            return `linear-gradient(rgb(168, 201, 223) 10%, rgb(185 221 249) 50%, rgb(224 233 241) 100%)`
        case 'Broken-Sunset':
            return `linear-gradient(rgb(57, 102, 124) 10%, rgb(130, 169, 187) 35%, rgb(176, 198, 208) 50%, rgb(225, 220, 225) 70%, #ffddd7 80%, #ffd0b6 90%, #ffd99e 100%)`

        // SCATTERED (3-4 of 8 Octas)
        case 'Scattered':
            return `linear-gradient(rgb(0, 130, 236), rgb(233, 248, 255))`
        case 'Scattered-Night':
            return `linear-gradient(rgb(11, 21, 37) 0%, rgb(27, 52, 85) 50%, #49688f 100%)`
        case 'Scattered-Sunrise':
            return `linear-gradient(rgb(61, 120, 149) 10%, rgb(136, 197, 225) 35%, rgb(188, 223, 238) 50%, rgb(235 238 249) 70%, #ffe9e5 80%, #fbdddd 90%, #ffcbc6 100%)`
        case 'Scattered-Day':
            return `linear-gradient(rgb(23, 119, 215), rgb(200, 240, 255))`
        case 'Scattered-Sunset':
            return `linear-gradient(rgb(57, 102, 124) 10%, rgb(130, 169, 187) 35%, rgb(176, 198, 208) 50%, rgb(225, 220, 225) 70%, #ffddd7 80%, #ffd0b6 90%, #ffd99e 100%)`

        // FEW (1-2 of 8 Octas)
        case 'Few':
            return `linear-gradient(rgb(74, 150, 226), rgb(219, 244, 255))`
        case 'Few-Night':
            return `linear-gradient(rgb(11, 26, 37) 0%, rgb(27, 57, 85) 50%, #49688f 100%)`
        case 'Few-Sunrise':
            return `linear-gradient(rgb(61, 120, 149) 10%, rgb(136, 197, 225) 35%, rgb(188, 223, 238) 50%, rgb(249, 238, 249) 70%, #ffe9e5 80%, #fbdddd 90%, #ffcbc6 100%)`
        case 'Few-Day':
            return `linear-gradient(rgb(23, 119, 215), rgb(200, 240, 255))`
        case 'Few-Sunset':
            return `linear-gradient(rgb(57, 102, 124) 10%, rgb(130, 169, 187) 35%, rgb(176, 198, 208) 50%, rgb(225, 220, 225) 70%, #ffddd7 80%, #ffd0b6 90%, #ffd99e 100%)`

        // CLEAR SKY (0 of 8 Octas)
        case 'NCD':
            return `linear-gradient(rgb(23, 119, 215), rgb(200, 240, 255))`
        case 'NCD-Night':
            return `linear-gradient(rgb(11, 26, 37) 0%, rgb(27, 57, 85) 50%, #49688f 100%)`
        case 'NCD-Sunrise':
            return `linear-gradient(rgb(61, 120, 149) 10%, rgb(136, 197, 225) 35%, rgb(188, 223, 238) 50%, rgb(249, 238, 249) 70%, #ffe9e5 80%, #fbdddd 90%, #ffcbc6 100%)`
        case 'NCD-Day':
            return `linear-gradient(rgb(23, 119, 215), rgb(200, 240, 255))`
        case 'NCD-Sunset':
            return `linear-gradient(rgb(57, 102, 124) 10%, rgb(130, 169, 187) 35%, rgb(176, 198, 208) 50%, rgb(225, 220, 225) 70%, #ffddd7 80%, #ffd0b6 90%, #ffd99e 100%)`

        // DEFAULT - CLEAR SKY
        default:
            return `linear-gradient(rgb(23, 119, 215), rgb(200, 240, 255))`
    }
}

export function cloudCoverBoxColor(value) {
    switch (value) {
        case 'Night':
            return isMobileOnly
                ? `linear-gradient(rgba(3, 10, 15, 0.18), rgba(3, 10, 15, 0.15))`
                : `linear-gradient(rgba(3, 10, 15, 0.22), rgba(3, 10, 15, 0.18))`
        case 'Sunrise':
            return `rgba(10, 50, 80, 0.14)`
        case 'Sunset':
            return `rgba(13, 39, 56, 0.13)`
        case 'Fog':
            return `rgba(10, 50, 80, 0.15)`
        case 'Overcast':
            return `rgba(10, 50, 80, 0.15)`
        case 'Broken':
            return `rgba(10, 50, 80, 0.14)`
        case 'Scattered':
            return `rgba(0, 75, 125, 0.13)`
        case 'Few':
            return `rgba(0, 75, 125, 0.13)`
        default:
            return `rgba(0, 75, 125, 0.13)`
    }
}
