import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import {
    Flex,
    Text,
    Stack,
    Image,
    Box,
    Icon,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Button,
    Badge,
} from '@chakra-ui/react'
import { FiChevronDown } from 'react-icons/fi'
import { RiStarLine } from 'react-icons/ri'
import { HiArrowNarrowRight } from 'react-icons/hi'

import { setModalVisibilityState } from '../../../../globalState'

import { ANNOUNCEMENT_ITEMS } from './AnnouncementsDetails'

const Announcements = (props) => {
    const {
        announcementItem,
        versionDate,
        versionOutOfDate,
        index,
        onCloseHandler,
    } = props
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowWhyMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'whyMfa', value })
        },
        [setModalVisibility]
    )

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            {announcementItem.announcementDate === versionDate &&
                versionOutOfDate && (
                    <Badge
                        zIndex="10"
                        position="relative"
                        marginBottom="-76px !important"
                        marginLeft={{
                            base: '-8px !important',
                            md: '-10px !important',
                        }}
                        pointerEvents="none"
                        variant="solid"
                        bg={'light.200'}
                        color={'light.10'}
                        borderRadius="20"
                        p="0px"
                        pl="2px"
                        fontSize="0.9rem"
                        fontWeight="500"
                        letterSpacing="0.5px"
                        height="22px"
                        width="52px"
                        minWidth="50px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        cursor="pointer"
                        lineHeight="1.2"
                    >
                        NEW
                    </Badge>
                )}
            <Flex
                key={`announcement${announcementItem.title}-${index}`}
                justifyContent="center"
                alignItems="flex-start"
                flexDirection={{ base: 'row', md: 'row-reverse' }}
                width="100%"
                borderRadius="20px"
                overflow="hidden"
                boxShadow="0px 8px 30px -8px rgba(0,0,0,0.25)"
                backgroundImage={
                    announcementItem.backgroundGradient
                        ? `linear-gradient(150deg, ${announcementItem.backgroundGradient.start} 10%, ${announcementItem.backgroundGradient.end} 70%)`
                        : 'linear-gradient(150deg, #006086 10%, #6EE4C3 70%)'
                }
            >
                {/* Premium Only Banner */}
                {announcementItem.premiumOnly && (
                    <Flex
                        zIndex="10"
                        position="relative"
                        width="220px"
                        height="24px"
                        marginTop={{ base: '0px', md: '-10px' }}
                        marginLeft={{ base: '0px', md: '-220px' }}
                        marginRight={{ base: '-220px', md: '0px' }}
                        marginBottom="4px"
                        bg="rgba(0, 188, 248, 1)"
                        _hover={{ bg: 'rgb(0, 174, 230)' }}
                        color="#FFFFFF"
                        textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                        boxShadow={{
                            base: '-22px 5px 10px -1px rgba(0,0,0,0.25)',
                            md: '20px 3px 12px -2px rgba(0,0,0,0.2)',
                        }}
                        fontFamily="'Open Sans', sans-serif"
                        p="1px"
                        pr={{ base: '35px', md: '0px' }}
                        fontSize="0.85rem"
                        fontWeight="600"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        cursor="pointer"
                        transform={{
                            base: 'rotate(-25deg) translateX(-40px)',
                            md: 'rotate(25deg) translateX(62px)',
                        }}
                        onClick={() => {
                            setShowPricingTiersModal(true)
                            onCloseHandler()
                        }}
                        gap="5px"
                    >
                        <RiStarLine color="#FFFFFF" fontSize="1rem" />
                        PREMIUM
                    </Flex>
                )}
                <Flex
                    width="100%"
                    height="100%"
                    minHeight="100%"
                    justifyContent="flex-start"
                    direction={{
                        base: 'column',
                        md: 'row',
                    }}
                    alignItems="flex-start"
                    _hover={{
                        textDecoration: 'none',
                    }}
                >
                    <Flex
                        width={{
                            base: '100%',
                            md: '450px',
                        }}
                        height="100%"
                        justifyContent="center"
                        flexDirection="row"
                        alignItems="center"
                        pt={{
                            base: '8%',
                            md: '40px',
                        }}
                        pb={{
                            base: '0px',
                            md: '40px',
                        }}
                        pl={{
                            base: '12%',
                            md: '0px',
                        }}
                        zIndex="2"
                    >
                        <Image
                            mb={{
                                base: '-30px',
                                md: '0px',
                            }}
                            mr={{
                                base: '-1px',
                                md: '-80px',
                            }}
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            alt=""
                            boxShadow="8px 8px 30px -8px rgba(0,0,0,0.2)"
                            src={announcementItem.image}
                        />
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        minHeight="250px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        bg="white"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        pb="10px"
                        marginBottom="-1px"
                    >
                        <Box
                            mr={{
                                base: '100px',
                                md: '0px',
                            }}
                            zIndex="1"
                            width={{
                                base: '100%',
                                md: '70px',
                            }}
                            height={{
                                base: '60px',
                                md: '100%',
                            }}
                            minHeight={{
                                base: '100%',
                                md: '250px',
                            }}
                            bg="gray.100"
                            backgroundImage={{
                                base: 'linear-gradient(90deg, #F2F5F9 70%, #FFF 70%)',
                                md: 'linear-gradient(180deg, #F2F5F9 65%, #FFF 65%)',
                            }}
                            opacity="0.7"
                        ></Box>
                        <Flex
                            width="100%"
                            height="100%"
                            minHeight="250px"
                            pl={{ base: '20px', md: '30px' }}
                            pr="20px"
                            pt="7px"
                            pb="10px"
                            flexDirection="column"
                            bg="white"
                            justifyContent={'space-between'}
                            alignItems="flex-end"
                        >
                            <Flex
                                width="100%"
                                height="100%"
                                minHeight="180px"
                                justifyContent={{
                                    base: 'center',
                                    md: 'flex-start',
                                }}
                                flexDirection="column"
                                alignItems={{
                                    base: 'center',
                                    md: 'flex-start',
                                }}
                                pb={{
                                    base: '10px',
                                    md: '0px',
                                }}
                            >
                                <Box
                                    fontWeight="600"
                                    pt="25px"
                                    pb="5px"
                                    px="5px"
                                    fontSize={{
                                        base: '1.1rem',
                                        md: '1.2rem',
                                    }}
                                    color="#20b1d3"
                                    lineHeight="1.5"
                                    textAlign={{
                                        base: 'center',
                                        md: 'left',
                                    }}
                                >
                                    {announcementItem.title}
                                </Box>
                                <Box
                                    fontWeight="500"
                                    pt="12px"
                                    px="5px"
                                    fontSize="0.85rem"
                                    color="light.100"
                                    textAlign={{
                                        base: 'center',
                                        md: 'left',
                                    }}
                                >
                                    {announcementItem.keyPoints}
                                </Box>
                            </Flex>

                            {/* MINIMSED SECTION */}
                            {announcementItem.description ? (
                                <Accordion
                                    borderBottom="none"
                                    width="100%"
                                    allowToggle
                                >
                                    <AccordionItem borderStyle="none">
                                        {({ isExpanded }) => (
                                            <>
                                                <AccordionButton
                                                    {...noFocus}
                                                    pl="0px"
                                                    pr="0px"
                                                    py="10px"
                                                    justifyContent={{
                                                        base: 'center',
                                                        md: 'flex-end',
                                                    }}
                                                    alignItems="center"
                                                    background={'white'}
                                                    color="gray.400"
                                                    _hover={{
                                                        background:
                                                            'transparent',
                                                        color: 'light.200',
                                                    }}
                                                    _active={{
                                                        background:
                                                            'transparent',
                                                    }}
                                                    _focus={{
                                                        background:
                                                            'transparent',
                                                    }}
                                                    transition="all ease 200ms"
                                                >
                                                    <Flex
                                                        width="100%"
                                                        minHeight={{
                                                            base: '38px',
                                                            md: '34px',
                                                        }}
                                                        justifyContent="space-between"
                                                        direction={{
                                                            base: 'row',
                                                            md: 'row',
                                                        }}
                                                        alignItems={{
                                                            base: 'center',
                                                            md: 'center',
                                                        }}
                                                        variant="outline"
                                                        fontSize="0.9rem"
                                                        borderRadius="25px"
                                                        fontWeight="400"
                                                        _hover={{
                                                            background: 'none',
                                                        }}
                                                        transition="all ease 200ms"
                                                    >
                                                        <Box
                                                            opacity={
                                                                isExpanded
                                                                    ? '0'
                                                                    : '1'
                                                            }
                                                            visibility={
                                                                isExpanded
                                                                    ? 'hidden'
                                                                    : 'visible'
                                                            }
                                                            transition="all ease 300ms"
                                                            color="gray.400"
                                                            whiteSpace="pre"
                                                            fontSize="0.75rem"
                                                            pl="5px"
                                                            pr="5px"
                                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                        >
                                                            {announcementItem.announcementDate
                                                                ? announcementItem.announcementDate
                                                                : versionDate}
                                                        </Box>
                                                        <Flex
                                                            width="100%"
                                                            justifyContent={{
                                                                base: 'flex-end',
                                                                md: 'flex-end',
                                                            }}
                                                            pr={{
                                                                base: '0',
                                                                md: '10px',
                                                            }}
                                                            alignItems="center"
                                                            variant="outline"
                                                            fontSize="0.9rem"
                                                            borderRadius="25px"
                                                            fontWeight="400"
                                                            _hover={{
                                                                background:
                                                                    'none',
                                                            }}
                                                        >
                                                            <Text
                                                                pl="5px"
                                                                pr="5px"
                                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                            >
                                                                {isExpanded
                                                                    ? 'Read less'
                                                                    : 'Read more'}
                                                            </Text>
                                                            <Icon
                                                                as={
                                                                    FiChevronDown
                                                                }
                                                                fontSize="20px"
                                                                transform={
                                                                    isExpanded &&
                                                                    'rotate(-180deg)'
                                                                }
                                                                transition="all ease 200ms"
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                </AccordionButton>
                                                <AccordionPanel
                                                    p="5px"
                                                    overflow="visible"
                                                >
                                                    <Flex
                                                        flexDirection="column"
                                                        gap="20px"
                                                        mt="10px"
                                                        pb="25px"
                                                        fontSize="0.8rem"
                                                        color="gray.600"
                                                        textAlign={{
                                                            base: 'center',
                                                            md: 'left',
                                                        }}
                                                        fontWeight="400"
                                                    >
                                                        {
                                                            announcementItem.description
                                                        }
                                                    </Flex>
                                                    {announcementItem.linkToModal && (
                                                        <Flex
                                                            mt="-20px"
                                                            pb="25px"
                                                            alignItems="center"
                                                            justifyContent={{
                                                                base: 'center',
                                                                md: 'flex-end',
                                                            }}
                                                            width="100%"
                                                        >
                                                            <Button
                                                                {...noFocus}
                                                                fontWeight="500"
                                                                fontSize="0.85rem"
                                                                height={{
                                                                    base: '36px',
                                                                    md: '30px',
                                                                }}
                                                                variant="primary"
                                                                color="light.200"
                                                                bg="transparent"
                                                                paddingInlineStart="6"
                                                                _hover={{
                                                                    color: 'light.201',
                                                                }}
                                                                _focus={{
                                                                    color: 'light.201',
                                                                }}
                                                                _active={{
                                                                    color: 'light.201',
                                                                }}
                                                                style={{
                                                                    borderRadius:
                                                                        '25px',
                                                                }}
                                                                onClick={() => {
                                                                    setShowWhyMfaModal(
                                                                        true
                                                                    )
                                                                    onCloseHandler()
                                                                }}
                                                                gap="5px"
                                                            >
                                                                {
                                                                    announcementItem.linkToModal
                                                                }
                                                                <HiArrowNarrowRight fontSize="1rem" />
                                                            </Button>
                                                        </Flex>
                                                    )}
                                                    <Box
                                                        color="gray.400"
                                                        whiteSpace="pre"
                                                        fontSize="0.75rem"
                                                        pb="20px"
                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    >
                                                        {announcementItem.announcementDate
                                                            ? announcementItem.announcementDate
                                                            : versionDate}
                                                    </Box>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                            ) : (
                                <Flex width="100%" pt="20px">
                                    <Box
                                        color="gray.400"
                                        whiteSpace="pre"
                                        fontSize="0.75rem"
                                        pl="5px"
                                        pb="20px"
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                    >
                                        {announcementItem.announcementDate
                                            ? announcementItem.announcementDate
                                            : versionDate}
                                    </Box>
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default function AnnouncementItems(props) {
    const { versionDate, versionOutOfDate, onCloseHandler } = props

    return (
        <>
            <Stack
                direction="column"
                spacing={'60px'}
                pt="20px"
                px="10px"
                width="100%"
                justifyContent="space-evenly"
                alignItems="flex-start"
            >
                {/* LATEST ANNOUNCEMENTS */}
                {ANNOUNCEMENT_ITEMS.filter(
                    (filter) => filter.announcementDate === versionDate
                ).map((announcementItem, i) => (
                    <Announcements
                        key={`latest-announcement-item-${i}`}
                        announcementItem={announcementItem}
                        versionDate={versionDate}
                        versionOutOfDate={versionOutOfDate}
                        onCloseHandler={onCloseHandler}
                        index={i}
                    />
                ))}

                {/* IF previous announcements still current */}
                {ANNOUNCEMENT_ITEMS.filter(
                    (filter) => filter.announcementDate !== versionDate
                ) && (
                    <>
                        <Text
                            pt={{ base: '40px', md: '70px' }}
                            mb="-20px !important"
                            width="100%"
                            textAlign="center"
                            color="gray.600"
                            fontSize={{
                                base: '0.9rem',
                                md: '1.1rem',
                            }}
                            fontWeight="500"
                        >
                            Other recent features we added previously
                        </Text>
                    </>
                )}
                {ANNOUNCEMENT_ITEMS.filter(
                    (filter) => filter.announcementDate !== versionDate
                ).map((announcementItem, i) => (
                    <Announcements
                        key={`announcement-item-${i}`}
                        announcementItem={announcementItem}
                        versionDate={versionDate}
                        versionOutOfDate={versionOutOfDate}
                        index={i}
                    />
                ))}
            </Stack>
        </>
    )
}
