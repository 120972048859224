import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    Flex,
    Box,
    Text,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useBreakpointValue,
} from '@chakra-ui/react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import ErrorText from '../../../components/ErrorText'
import { parseISO, formatDistanceToNow } from 'date-fns'
import { useInView } from 'react-intersection-observer'
import { isMobileOnly } from 'react-device-detect'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import {
    timeZoneState,
    showOutdatedErrorsState,
    inViewPositionState,
    briefingParamsState,
    collapseDropdownsState,
} from '../../../../../globalState'

import AAWIcon from '../../../../../icons/menu-icons/AAWIcon'

export default function AAWBriefing(props) {
    const { briefingData } = props
    const aaw =
        briefingData && briefingData.res.areamet && briefingData.res.areamet.aaw
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    const timeZone = useRecoilValue(timeZoneState)
    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const params = useRecoilValue(briefingParamsState)
    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : [0, 1]
    )

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize, calculateLabelSize } = useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })

    const adjustDataFontSize = useBreakpointValue({
        base: calculateFontSize(0.7),
        xl: calculateFontSize(0.75),
        '2xl': calculateFontSize(0.8),
    })

    useEffect(() => {
        inView && setInViewPosition('aawContainer')
    }, [inView, setInViewPosition])

    useEffect(() => {
        setExpandedDropdowns(collapseDropdowns ? [] : [0, 1])
    }, [collapseDropdowns])

    const aawContentContainer = document.getElementById('aawClientWidth') // Accessing ID of Box around Table
    const aawContentAreas = useBreakpointValue({
        // used to account for Area labels on left side in desktop view
        base: 0,
        md: 140,
        lg: 170,
    })
    const aawContentWidth =
        aawContentContainer && aawContentContainer.clientWidth - aawContentAreas

    return (
        <>
            <div id="aawContainer" style={{ marginTop: '1px' }} ref={ref}></div>
            {params.aaw && (
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', md: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    zIndex="1"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginTop="25px"
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#35ADD3"
                        backgroundImage="linear-gradient(345deg, #5CCFF1 0%, #018AB0 80%)"
                        filter={{
                            base: isMobileOnly && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="30px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="flex-start"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Box>
                                <AAWIcon
                                    width="20px"
                                    height="20px"
                                    color="white"
                                />
                            </Box>

                            <Box my="auto" paddingLeft="12px">
                                <Text
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    AVIATION AREA WINDS
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>

                    <Box>
                        <Accordion
                            border="none"
                            allowToggle
                            allowMultiple
                            index={expandedDropdowns}
                            onChange={(expandedIndex) => {
                                setExpandedDropdowns(expandedIndex)
                            }}
                        >
                            {aaw && aaw.length > 0 ? (
                                <AccordionItem borderStyle="none" index="1">
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                sx={{
                                                    clipPath:
                                                        'inset(0px 0px -50px 0px)',
                                                }}
                                                pl="1.3rem"
                                                height="50px"
                                                pr={5}
                                                py={3}
                                                _hover={{
                                                    background: 'light.30',

                                                    cursor: 'pointer',
                                                }}
                                                color={
                                                    !isExpanded
                                                        ? '#26aed7'
                                                        : '#26aed7'
                                                }
                                                background={
                                                    isExpanded
                                                        ? 'light.25'
                                                        : 'white'
                                                }
                                                boxShadow={
                                                    isExpanded &&
                                                    '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                }
                                                _focus={{
                                                    boxShadow: isExpanded
                                                        ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                        : 'none',
                                                }}
                                                opacity="1"
                                            >
                                                <Flex
                                                    width="100%"
                                                    id="aawClientWidth"
                                                    alignItems="center"
                                                >
                                                    <Box
                                                        fontSize={
                                                            adjustLabelFontSize
                                                        }
                                                        lineHeight="1.2"
                                                        fontWeight={
                                                            isExpanded
                                                                ? '600'
                                                                : '500'
                                                        }
                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                        flex="1"
                                                        textAlign="left"
                                                        paddingLeft="1"
                                                    >
                                                        AAW Forecasts
                                                    </Box>
                                                    <Flex
                                                        flexDirection={{
                                                            base: 'column',
                                                            lg: 'row',
                                                        }}
                                                        justifyContent="center"
                                                        alignItems="flex-end"
                                                    >
                                                        <Flex
                                                            opacity={
                                                                isExpanded &&
                                                                timeZone !==
                                                                    'UTC'
                                                                    ? '1'
                                                                    : '0'
                                                            }
                                                            visibility={
                                                                isExpanded &&
                                                                timeZone !==
                                                                    'UTC'
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }
                                                            height={
                                                                timeZone !==
                                                                'UTC'
                                                                    ? '17px'
                                                                    : '0'
                                                            }
                                                            transition="all ease 600ms"
                                                            justifyContent="center"
                                                            alignItems="flex-end"
                                                        >
                                                            <Text
                                                                textAlign="end"
                                                                whiteSpace="pre-wrap"
                                                                paddingRight={{
                                                                    base: '16px',
                                                                    lg: '8px',
                                                                }}
                                                                pl="15px"
                                                                fontSize={{
                                                                    base: '0.65rem',
                                                                    sm: '11px',
                                                                }}
                                                                color="light.300"
                                                            >
                                                                Time references
                                                                are in UTC.
                                                            </Text>
                                                        </Flex>
                                                        <Flex
                                                            opacity={
                                                                isExpanded
                                                                    ? '1'
                                                                    : '0'
                                                            }
                                                            visibility={
                                                                isExpanded
                                                                    ? 'visible'
                                                                    : 'hidden'
                                                            }
                                                            transition="all ease 600ms"
                                                            justifyContent="center"
                                                            alignItems="flex-end"
                                                            flexDirection={{
                                                                base: 'column',
                                                                sm: 'row',
                                                            }}
                                                        >
                                                            <Text
                                                                textAlign="end"
                                                                whiteSpace="pre-wrap"
                                                                paddingRight={{
                                                                    base: '16px',
                                                                    sm: '0px',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.65rem',
                                                                    sm: '11px',
                                                                }}
                                                                color="light.300"
                                                                mt={{
                                                                    base: '-2px',
                                                                    sm: '0',
                                                                }}
                                                            >
                                                                All heights are
                                                                in feet AMSL
                                                            </Text>
                                                            <Text
                                                                textAlign="end"
                                                                paddingRight={{
                                                                    base: '16px',
                                                                    sm: '8px',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.65rem',
                                                                    sm: '11px',
                                                                }}
                                                                color="light.300"
                                                                mt={{
                                                                    base: '-2px',
                                                                    sm: '0',
                                                                }}
                                                                display={{
                                                                    base: 'none',
                                                                    sm: 'block',
                                                                }}
                                                            >
                                                                .
                                                            </Text>
                                                            <Text
                                                                textAlign="end"
                                                                whiteSpace="pre-wrap"
                                                                paddingRight={{
                                                                    base: '16px',
                                                                    sm: '16px',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.65rem',
                                                                    sm: '11px',
                                                                }}
                                                                color="light.300"
                                                                mt={{
                                                                    base: '-2px',
                                                                    sm: '0',
                                                                }}
                                                            >
                                                                Wind:°TRUE /
                                                                Speed:KT
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    <Flex>
                                                        {isExpanded ? (
                                                            <AccordionIcon transform="rotate(0deg)" />
                                                        ) : (
                                                            <AccordionIcon transform="rotate(-90deg)" />
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </AccordionButton>

                                            {aaw && (
                                                <AccordionPanel
                                                    pt={0}
                                                    pb={6}
                                                    pl="1.5rem"
                                                    pr="1.5rem"
                                                >
                                                    {aaw &&
                                                        aaw.length > 0 &&
                                                        aaw.map(
                                                            (aawData, i) => {
                                                                return (
                                                                    <Flex
                                                                        key={`AAWBriefing${aawData.area}`}
                                                                        fontFamily="Open Sans"
                                                                        paddingTop="6"
                                                                        flexDirection="column"
                                                                    >
                                                                        {(showOutdatedErrors ||
                                                                            aawData.outofdate) && (
                                                                            <Flex
                                                                                pb="10px"
                                                                                justifyContent="center"
                                                                            >
                                                                                <ErrorText>
                                                                                    There
                                                                                    has
                                                                                    been
                                                                                    an
                                                                                    issue
                                                                                    with
                                                                                    the
                                                                                    connection
                                                                                    between
                                                                                    PreFlight
                                                                                    and
                                                                                    MetService.
                                                                                    This
                                                                                    AAW
                                                                                    report
                                                                                    was
                                                                                    last
                                                                                    validated
                                                                                    with
                                                                                    MetService
                                                                                    more
                                                                                    than{' '}
                                                                                    <strong>
                                                                                        {formatDistanceToNow(
                                                                                            parseISO(
                                                                                                aawData.updated
                                                                                            ),
                                                                                            {
                                                                                                addSuffix: true,
                                                                                            }
                                                                                        )}
                                                                                    </strong>
                                                                                    .
                                                                                    <br />
                                                                                    There
                                                                                    is
                                                                                    a
                                                                                    high
                                                                                    likelihood
                                                                                    that
                                                                                    the
                                                                                    latest
                                                                                    AAW
                                                                                    information
                                                                                    is
                                                                                    not
                                                                                    available
                                                                                    in
                                                                                    PreFlight.
                                                                                    Check
                                                                                    back
                                                                                    again
                                                                                    soon,
                                                                                    or
                                                                                    alternatively
                                                                                    visit{' '}
                                                                                    <a
                                                                                        style={{
                                                                                            fontWeight:
                                                                                                '500',
                                                                                            textDecoration:
                                                                                                'underline',
                                                                                        }}
                                                                                        href="https://ifis.airways.co.nz/"
                                                                                    >
                                                                                        IFIS
                                                                                    </a>

                                                                                    .
                                                                                </ErrorText>
                                                                            </Flex>
                                                                        )}
                                                                        <Flex
                                                                            flexDirection={{
                                                                                base: 'column',
                                                                                md: 'row',
                                                                            }}
                                                                            pt="0px"
                                                                            pb="15px"
                                                                            justifyContent="space-between"
                                                                        >
                                                                            <Flex
                                                                                fontFamily="Open Sans"
                                                                                flexDirection="row"
                                                                                paddingBottom="10px"
                                                                            >
                                                                                <Text
                                                                                    paddingStart="0"
                                                                                    paddingEnd="0"
                                                                                    fontWeight="bold"
                                                                                    width={calculateLabelSize(
                                                                                        30
                                                                                    )}
                                                                                    py="0px"
                                                                                    minWidth={calculateLabelSize(
                                                                                        30
                                                                                    )}
                                                                                    fontSize={calculateFontSize(
                                                                                        0.8
                                                                                    )}
                                                                                    lineHeight="1.3"
                                                                                    borderColor="transparent"
                                                                                >
                                                                                    {
                                                                                        aawData.area
                                                                                    }
                                                                                </Text>
                                                                                <Text
                                                                                    paddingStart="0"
                                                                                    paddingEnd="0"
                                                                                    fontWeight="bold"
                                                                                    width={calculateLabelSize(
                                                                                        130
                                                                                    )}
                                                                                    py="0px"
                                                                                    minWidth={calculateLabelSize(
                                                                                        130
                                                                                    )}
                                                                                    fontSize={calculateFontSize(
                                                                                        0.8
                                                                                    )}
                                                                                    lineHeight="1.3"
                                                                                    borderColor="transparent"
                                                                                >
                                                                                    (
                                                                                    {
                                                                                        aawData.name
                                                                                    }

                                                                                    )
                                                                                </Text>
                                                                            </Flex>
                                                                            <Flex
                                                                                // id="aawClientWidth"
                                                                                className="scrollHorizontal"
                                                                                flexDirection="column"
                                                                                width="100%"
                                                                                // height="130px"
                                                                                pt="3px"
                                                                                pb="5px"
                                                                                // overflowX="auto" // reinstate these if Overlay Scrollbars disabled
                                                                                // overflowY="hidden"
                                                                            >
                                                                                <OverlayScrollbarsComponent
                                                                                    options={{
                                                                                        scrollbars:
                                                                                            {
                                                                                                visibility:
                                                                                                    'auto',
                                                                                                autoHide:
                                                                                                    'never',
                                                                                                autoHideDelay: 800,
                                                                                                dragScrolling: true,
                                                                                                clickScrolling: true,
                                                                                                touchSupport: true,
                                                                                            },
                                                                                        overflow:
                                                                                            {
                                                                                                x: 'scroll',
                                                                                                y: 'hidden',
                                                                                            },
                                                                                    }}
                                                                                    style={{
                                                                                        height: '100%',
                                                                                        maxWidth:
                                                                                            aawContentWidth,
                                                                                    }}
                                                                                >
                                                                                    <Flex
                                                                                        id="aawScrollWidth"
                                                                                        width="100%"
                                                                                    >
                                                                                        <Text
                                                                                            textAlign="left"
                                                                                            color="light.900"
                                                                                            fontSize={
                                                                                                adjustDataFontSize
                                                                                            }
                                                                                            fontWeight="normal"
                                                                                            fontFamily="AzeretMonoExtraLight"
                                                                                            lineHeight="1.3"
                                                                                            whiteSpace="pre"
                                                                                            paddingBottom={{
                                                                                                base: '20px',
                                                                                                md: '10px',
                                                                                            }}
                                                                                            pr="20px"
                                                                                            cursor="initial"
                                                                                            marginRight="5px"
                                                                                        >
                                                                                            {
                                                                                                aawData.data
                                                                                            }
                                                                                        </Text>
                                                                                    </Flex>
                                                                                </OverlayScrollbarsComponent>
                                                                            </Flex>
                                                                        </Flex>
                                                                        {i +
                                                                            1 !==
                                                                            aaw.length && (
                                                                            <Divider
                                                                                height="0px !important"
                                                                                borderColor="#e5e5e5"
                                                                                width="100%"
                                                                                mx="auto"
                                                                            />
                                                                        )}
                                                                    </Flex>
                                                                )
                                                            }
                                                        )}
                                                </AccordionPanel>
                                            )}
                                        </>
                                    )}
                                </AccordionItem>
                            ) : (
                                <Flex
                                    fontFamily="Open Sans"
                                    px="6"
                                    py="4"
                                    minHeight="50px"
                                    flexDirection="column"
                                >
                                    <Text
                                        fontSize="0.8rem"
                                        lineHeight="1.2"
                                        fontWeight="400"
                                        fontFamily="'Open Sans', sans-serif"
                                        flex="2"
                                        color="light.400"
                                    >
                                        AAW information is not available at this
                                        time
                                    </Text>
                                </Flex>
                            )}
                        </Accordion>
                    </Box>
                </Box>
            )}
        </>
    )
}
