import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function TemperatureIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M11.5 16.5854V7H12.5V16.5854C13.0826 16.7913 13.5 17.3469 13.5 18C13.5 18.8284 12.8284 19.5 12 19.5C11.1716 19.5 10.5 18.8284 10.5 18C10.5 17.3469 10.9174 16.7913 11.5 16.5854Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C10.3431 2 9 3.34315 9 5V15.3542C8.37764 16.0593 8 16.9856 8 18C8 20.2091 9.79086 22 12 22C14.2091 22 16 20.2091 16 18C16 16.9856 15.6224 16.0593 15 15.3542V5C15 3.34315 13.6569 2 12 2ZM12 3.5C11.1716 3.5 10.5 4.17157 10.5 5V15.9998C9.89278 16.4559 9.5 17.1821 9.5 18C9.5 19.3807 10.6193 20.5 12 20.5C13.3807 20.5 14.5 19.3807 14.5 18C14.5 17.1821 14.1072 16.4559 13.5 15.9998V14.5H14.25V13.5H13.5V12H14.25V11H13.5V9.5H14.25V8.5H13.5V7H14.25V6H13.5V5C13.5 4.17157 12.8284 3.5 12 3.5Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
