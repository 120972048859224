import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useInView } from 'react-intersection-observer'
import {
    Flex,
    Box,
    Text,
    Badge,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react'
import formatDateTime from '../../../../../util/dateFormatter'
import { parseISO, formatDistanceToNow, addHours } from 'date-fns'
import { isMobileOnly } from 'react-device-detect'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'

import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../../../util/notamDecode'
import {
    timeZoneState,
    showOutdatedErrorsState,
    inViewPositionState,
    selectedBriefingTimeState,
    collapseDropdownsState,
    selectedBriefingAltitudeState,
} from '../../../../../globalState'

import ErrorText from '../../../components/ErrorText'
import { ReactComponent as EnRouteIcon } from '../../../../../icons/assets/en-route.svg'
import NotamIcon from '../../../../../icons/menu-icons/NotamIcon'
import notam from '../../../assets/notam.svg'
import notamDrone from '../../../assets/notam-drone.svg'
import notamObstacle from '../../../assets/notam-obstacle.svg'
import notamAerodrome from '../../../assets/notam-aerodrome.svg'
import notamWarning from '../../../assets/notam-warning.svg'
import notamComms from '../../../assets/notam-comms.svg'

export default function EnRouteBriefing(props) {
    const { briefingData } = props

    const enrouteNotamData =
        briefingData && briefingData.res && briefingData.res.enroutenotam

    const enroutenotam =
        enrouteNotamData &&
        enrouteNotamData.filter((item) => item.series !== 'P') // Precautionary filter to remove any mistakenly issued Pacific ("P") NOTAMs (has occured before)

    const timeZone = useRecoilValue(timeZoneState)
    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const maxAltitude = useRecoilValue(selectedBriefingAltitudeState)
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const selectedHours = useRecoilValue(selectedBriefingTimeState)
    const issuedTime = briefingData && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : [0, 1]
    )

    // FONT SIZE ADJUSTMENTS
    const {
        calculateFontSize,
        calculateContainerSize,
        calculateLabelSize,
        calculateBadgeSize,
        calculateBadgeWidth,
    } = useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        inView && setInViewPosition('enrouteContainer')
    }, [inView, setInViewPosition])

    useEffect(() => {
        setExpandedDropdowns(collapseDropdowns ? [] : [0, 1])
    }, [collapseDropdowns])

    function notamIconFormatter(code23) {
        return ['WU', 'WZ'].includes(code23)
            ? notamDrone
            : ['OB', 'OL'].includes(code23)
            ? notamObstacle
            : code23 === 'FA'
            ? notamAerodrome
            : ['W', 'R'].includes(code23.charAt(0))
            ? notamWarning
            : ['C', 'N'].includes(code23.charAt(0))
            ? notamComms
            : notam
    }

    // console.log(enroutenotam, 'EN-ROUTE NOTAMS')

    return (
        <>
            <div
                id="enrouteContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            <Box
                bg="rgba(255,255,255,1)"
                w={{ base: '100%', md: '100%' }}
                borderRadius="20px"
                fontFamily="Open Sans"
                zIndex="1"
                transition="all ease 300ms"
                boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                _hover={{
                    boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                }}
                marginTop="25px"
                marginBottom="40px"
                overflow="hidden"
            >
                <Flex
                    bg="#28A45E"
                    backgroundImage="linear-gradient(345deg, #3FCA96 0%, #028362 80%)"
                    filter={{
                        base: isMobileOnly && 'saturate(1.3)',
                        md: 'saturate(1)',
                    }}
                    w={{ base: '100%', md: '100%' }}
                    borderTopRadius="20px"
                    minHeight="30px"
                    border="none"
                    fontFamily="Open Sans"
                    zIndex="1"
                >
                    <Flex
                        direction="row"
                        justifyContent="flex-start"
                        width="100%"
                        py="8px"
                        pl="20px"
                        pr="24px"
                    >
                        <Box>
                            <EnRouteIcon
                                width="28px"
                                height="28px"
                                color="white"
                            />
                        </Box>
                        <Box my="auto" paddingLeft="12px">
                            <Text
                                textAlign="left"
                                color="white"
                                fontSize={adjustHeaderFontSize}
                                fontWeight="bold"
                                lineHeight="1.1"
                            >
                                EN-ROUTE
                            </Text>
                        </Box>
                    </Flex>
                </Flex>

                <Box>
                    <Accordion
                        border="none"
                        allowToggle
                        allowMultiple
                        index={expandedDropdowns}
                        onChange={(expandedIndex) => {
                            setExpandedDropdowns(expandedIndex)
                        }}
                    >
                        <>
                            <Divider
                                height="0px !important"
                                borderColor="#e5e5e5"
                                width="100%"
                                mx="auto"
                            />
                            {enroutenotam && enroutenotam.length > 0 ? (
                                <AccordionItem borderStyle="none" index="1">
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                sx={{
                                                    clipPath:
                                                        'inset(0px 0px -50px 0px)',
                                                }}
                                                pl="1.3rem"
                                                pr={5}
                                                py={3}
                                                _hover={{
                                                    background: enroutenotam
                                                        ? 'light.30'
                                                        : 'transparent',
                                                    cursor: enroutenotam
                                                        ? 'pointer'
                                                        : 'initial',
                                                }}
                                                color={
                                                    enroutenotam
                                                        ? '#10aa70'
                                                        : 'light.100'
                                                }
                                                background={
                                                    isExpanded && enroutenotam
                                                        ? 'light.25'
                                                        : 'white'
                                                }
                                                style={{
                                                    borderBottomRadius:
                                                        isExpanded &&
                                                        enroutenotam
                                                            ? '0px'
                                                            : '20px',
                                                    transition:
                                                        'boxShadow all 800ms',
                                                }}
                                                boxShadow={
                                                    enroutenotam &&
                                                    isExpanded &&
                                                    '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                }
                                                _focus={{
                                                    boxShadow:
                                                        enroutenotam &&
                                                        isExpanded
                                                            ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                            : 'none',
                                                }}
                                                opacity={
                                                    enroutenotam ? '1' : '0.5'
                                                }
                                            >
                                                <Flex marginTop="-1px" mx="2px">
                                                    <NotamIcon boxSize="6" />
                                                </Flex>

                                                <Flex
                                                    flexDirection="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    fontSize={
                                                        adjustLabelFontSize
                                                    }
                                                    lineHeight="1.2"
                                                    fontWeight={
                                                        enroutenotam &&
                                                        isExpanded
                                                            ? '600'
                                                            : '500'
                                                    }
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    flex="1"
                                                    textAlign="left"
                                                    paddingLeft="11px"
                                                >
                                                    NOTAM
                                                    {enroutenotam &&
                                                    enroutenotam.filter(
                                                        (notam) =>
                                                            parseISO(
                                                                notam.startvalidity
                                                            ) <= periodTo &&
                                                            maxAltitude >=
                                                                notam.lowerfilter
                                                    ).length ? (
                                                        <Badge
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            minWidth={calculateBadgeSize(
                                                                16
                                                            )}
                                                            minHeight={calculateBadgeSize(
                                                                16
                                                            )}
                                                            ml={2}
                                                            fontFamily="'Open Sans', sans-serif"
                                                            variant="solid"
                                                            bg={
                                                                isExpanded
                                                                    ? 'light.10'
                                                                    : 'warning.100'
                                                            }
                                                            color={
                                                                isExpanded
                                                                    ? 'warning.100'
                                                                    : 'light.10'
                                                            }
                                                            border="1px solid"
                                                            borderColor="warning.100"
                                                            transition="all ease 250ms"
                                                            borderRadius="50px"
                                                            px="5px"
                                                            py="0px"
                                                            fontSize={calculateFontSize(
                                                                0.65
                                                            )}
                                                        >
                                                            {
                                                                enroutenotam.filter(
                                                                    (notam) =>
                                                                        parseISO(
                                                                            notam.startvalidity
                                                                        ) <=
                                                                            periodTo &&
                                                                        maxAltitude >=
                                                                            notam.lowerfilter
                                                                ).length
                                                            }
                                                        </Badge>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Flex>

                                                <Flex>
                                                    {isExpanded ? (
                                                        <AccordionIcon transform="rotate(0deg)" />
                                                    ) : (
                                                        <AccordionIcon transform="rotate(-90deg)" />
                                                    )}
                                                </Flex>
                                            </AccordionButton>

                                            <AccordionPanel
                                                pt={5}
                                                pb={7}
                                                pl="1.4rem"
                                                pr={5}
                                            >
                                                {enroutenotam &&
                                                    enroutenotam.length > 0 &&
                                                    enroutenotam.map(
                                                        (notam, i) => {
                                                            const code23 =
                                                                code23Decode(
                                                                    notam.code23
                                                                )
                                                            const code45 =
                                                                code45Decode(
                                                                    notam.code45
                                                                )
                                                            const traffic =
                                                                trafficDecode(
                                                                    notam.traffic
                                                                )
                                                            const aerodromeAffected =
                                                                notam?.itema &&
                                                                notam?.scope
                                                                    .split('')
                                                                    .includes(
                                                                        'A'
                                                                    )
                                                            if (
                                                                parseISO(
                                                                    notam.startvalidity
                                                                ) <= periodTo &&
                                                                maxAltitude >=
                                                                    notam.lowerfilter
                                                            ) {
                                                                return (
                                                                    <Box
                                                                        key={`${notam.series}${notam.number}/${notam.year}`}
                                                                        w="100%"
                                                                    >
                                                                        {(showOutdatedErrors ||
                                                                            notam.outofdate) && (
                                                                            <Flex
                                                                                pb="10px"
                                                                                ml="-8px"
                                                                                justifyContent="center"
                                                                            >
                                                                                <ErrorText>
                                                                                    There
                                                                                    has
                                                                                    been
                                                                                    an
                                                                                    issue
                                                                                    with
                                                                                    the
                                                                                    connection
                                                                                    between
                                                                                    PreFlight
                                                                                    and
                                                                                    MetService.
                                                                                    This{' '}
                                                                                    <strong>
                                                                                        EN-ROUTE
                                                                                        NOTAM
                                                                                    </strong>{' '}
                                                                                    was
                                                                                    last
                                                                                    validated
                                                                                    with
                                                                                    MetService
                                                                                    more
                                                                                    than{' '}
                                                                                    <strong>
                                                                                        {formatDistanceToNow(
                                                                                            parseISO(
                                                                                                notam.updated
                                                                                            ),
                                                                                            {
                                                                                                addSuffix: true,
                                                                                            }
                                                                                        )}
                                                                                    </strong>
                                                                                    .
                                                                                    <br />
                                                                                    There
                                                                                    is
                                                                                    a
                                                                                    high
                                                                                    likelihood
                                                                                    that
                                                                                    the
                                                                                    latest
                                                                                    EN-ROUTE
                                                                                    NOTAM
                                                                                    information
                                                                                    is
                                                                                    not
                                                                                    available
                                                                                    in
                                                                                    PreFlight.
                                                                                    Check
                                                                                    back
                                                                                    again
                                                                                    soon,
                                                                                    or
                                                                                    alternatively
                                                                                    visit{' '}
                                                                                    <a
                                                                                        style={{
                                                                                            fontWeight:
                                                                                                '500',
                                                                                            textDecoration:
                                                                                                'underline',
                                                                                        }}
                                                                                        href="https://ifis.airways.co.nz/"
                                                                                    >
                                                                                        IFIS
                                                                                    </a>

                                                                                    .
                                                                                </ErrorText>
                                                                            </Flex>
                                                                        )}
                                                                        <Flex>
                                                                            {/* ICON */}
                                                                            <Box
                                                                                minWidth="24px"
                                                                                mt="1px"
                                                                                mr="14px"
                                                                            >
                                                                                <Image
                                                                                    boxSize="24px"
                                                                                    src={notamIconFormatter(
                                                                                        notam.code23
                                                                                    )}
                                                                                    alt="!"
                                                                                />
                                                                            </Box>
                                                                            <Box width="100%">
                                                                                <Flex
                                                                                    paddingStart="0"
                                                                                    paddingTop="0.2rem"
                                                                                    paddingBottom="0.2rem"
                                                                                    flexDirection="column"
                                                                                    width="auto"
                                                                                    minWidth="90px"
                                                                                >
                                                                                    <Text
                                                                                        fontWeight="bold"
                                                                                        fontSize={calculateFontSize(
                                                                                            0.8
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            code23
                                                                                        }
                                                                                    </Text>

                                                                                    <Text
                                                                                        fontWeight="normal"
                                                                                        paddingBottom="5px"
                                                                                        fontSize={calculateFontSize(
                                                                                            0.8
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            code45
                                                                                        }
                                                                                    </Text>
                                                                                </Flex>

                                                                                <Flex
                                                                                    display="flex"
                                                                                    flexDirection={{
                                                                                        base: 'column',
                                                                                        xl: 'row',
                                                                                    }}
                                                                                    width="100%"
                                                                                >
                                                                                    <Flex
                                                                                        display="flex"
                                                                                        flexDirection="column"
                                                                                        width={{
                                                                                            base: '100%',
                                                                                            xl: '50%',
                                                                                        }}
                                                                                        maxWidth={{
                                                                                            base: '100%',
                                                                                            xl: calculateContainerSize(
                                                                                                260
                                                                                            ),
                                                                                        }}
                                                                                    >
                                                                                        <Flex
                                                                                            width="100%"
                                                                                            display="flex"
                                                                                            flexDirection="row"
                                                                                            paddingBottom="5px"
                                                                                        >
                                                                                            <Flex
                                                                                                paddingStart="0"
                                                                                                fontWeight="bold"
                                                                                                width="auto"
                                                                                                minWidth={calculateLabelSize(
                                                                                                    aerodromeAffected
                                                                                                        ? 88
                                                                                                        : 75
                                                                                                )}
                                                                                                fontSize={calculateFontSize(
                                                                                                    0.8
                                                                                                )}
                                                                                            >
                                                                                                NOTAM
                                                                                                #:
                                                                                            </Flex>
                                                                                            <Flex
                                                                                                width="100%"
                                                                                                fontSize={calculateFontSize(
                                                                                                    0.8
                                                                                                )}
                                                                                            >
                                                                                                {`${notam.series}${notam.number}/${notam.year}`}
                                                                                            </Flex>
                                                                                        </Flex>
                                                                                        <Flex
                                                                                            display="flex"
                                                                                            flexDirection="row"
                                                                                            width="100%"
                                                                                            paddingBottom="5px"
                                                                                        >
                                                                                            <Flex
                                                                                                paddingStart="0"
                                                                                                fontWeight="bold"
                                                                                                width="auto"
                                                                                                minWidth={calculateLabelSize(
                                                                                                    aerodromeAffected
                                                                                                        ? 88
                                                                                                        : 75
                                                                                                )}
                                                                                                fontSize={calculateFontSize(
                                                                                                    0.8
                                                                                                )}
                                                                                            >
                                                                                                Affects:
                                                                                            </Flex>
                                                                                            <Flex alignItems="baseline">
                                                                                                {traffic.ifr && (
                                                                                                    <Badge
                                                                                                        variant="solid"
                                                                                                        background="ifr.100"
                                                                                                        color="white"
                                                                                                        paddingStart="2"
                                                                                                        paddingEnd="2"
                                                                                                        paddingTop="2px"
                                                                                                        paddingBottom="2px"
                                                                                                        borderRadius="20px"
                                                                                                        marginTop="2px"
                                                                                                        marginRight="5px"
                                                                                                        minWidth={calculateBadgeWidth(
                                                                                                            40
                                                                                                        )}
                                                                                                        textAlign="center"
                                                                                                        fontSize={calculateFontSize(
                                                                                                            0.65
                                                                                                        )}
                                                                                                        height={calculateBadgeSize(
                                                                                                            18
                                                                                                        )}
                                                                                                        justifyContent="center"
                                                                                                        alignItems="center"
                                                                                                        display="flex"
                                                                                                    >
                                                                                                        IFR
                                                                                                    </Badge>
                                                                                                )}
                                                                                                {traffic.ifr &&
                                                                                                traffic.vfr
                                                                                                    ? ' '
                                                                                                    : ''}
                                                                                                {traffic.vfr && (
                                                                                                    <Badge
                                                                                                        variant="solid"
                                                                                                        background="vfr.100"
                                                                                                        color="white"
                                                                                                        paddingStart="2"
                                                                                                        paddingEnd="2"
                                                                                                        paddingTop="2px"
                                                                                                        paddingBottom="2px"
                                                                                                        borderRadius="20px"
                                                                                                        marginTop="2px"
                                                                                                        marginRight="5px"
                                                                                                        minWidth={calculateBadgeWidth(
                                                                                                            40
                                                                                                        )}
                                                                                                        textAlign="center"
                                                                                                        fontSize={calculateFontSize(
                                                                                                            0.65
                                                                                                        )}
                                                                                                        height={calculateBadgeSize(
                                                                                                            18
                                                                                                        )}
                                                                                                        justifyContent="center"
                                                                                                        alignItems="center"
                                                                                                        display="flex"
                                                                                                    >
                                                                                                        VFR
                                                                                                    </Badge>
                                                                                                )}
                                                                                            </Flex>
                                                                                            <Text
                                                                                                paddingLeft="1px"
                                                                                                fontSize={calculateFontSize(
                                                                                                    0.8
                                                                                                )}
                                                                                            >
                                                                                                TRAFFIC
                                                                                            </Text>
                                                                                        </Flex>
                                                                                        {/*  <Flex
                                                                                                    display="flex"
                                                                                                    flexDirection="row"
                                                                                                      width="100%"  paddingBottom="5px"
                                                                                                > /// REMOVED FOR NOW - REQUIRES FURTHER THOUGHT
                                                                                            <Flex
                                                                                                paddingStart="0"
                                                                                                fontWeight="bold"
                                                                                                width="auto"
                                                                                                minWidth={
                                                                                                                             calculateLabelSize(70)
                                                                                                                        }
                                                                                                fontSize={
                                                                                                                     calculateFontSize(0.8)
                                                                                                                }
                                                                                            >
                                                                                                Status:
                                                                                            </Flex>
                                                                                            <Flex
                                                                                                width="100%"
                                                                                                fontSize={
                                                                                                                     calculateFontSize(0.8)
                                                                                                                }
                                                                                            >
                                                                                                {timeRangeInWords(
                                                                                                    now,
                                                                                                    notam.startvalidity,
                                                                                                    notam.endvalidity
                                                                                                )}
                                                                                            </Flex>
                                                                                        </Flex> */}
                                                                                        {notam.itemf && (
                                                                                            <Flex
                                                                                                display="flex"
                                                                                                flexDirection="row"
                                                                                                width="100%"
                                                                                                paddingBottom="5px"
                                                                                            >
                                                                                                <Flex
                                                                                                    paddingStart="0"
                                                                                                    fontWeight="bold"
                                                                                                    width="auto"
                                                                                                    minWidth={calculateLabelSize(
                                                                                                        aerodromeAffected
                                                                                                            ? 88
                                                                                                            : 75
                                                                                                    )}
                                                                                                    fontSize={calculateFontSize(
                                                                                                        0.8
                                                                                                    )}
                                                                                                >
                                                                                                    Altitude:
                                                                                                </Flex>
                                                                                                <Flex
                                                                                                    width="100%"
                                                                                                    fontSize={calculateFontSize(
                                                                                                        0.8
                                                                                                    )}
                                                                                                >
                                                                                                    {notam.lowerlimit +
                                                                                                        ' – ' +
                                                                                                        notam.upperlimit}
                                                                                                </Flex>
                                                                                            </Flex>
                                                                                        )}
                                                                                    </Flex>
                                                                                    <Flex
                                                                                        display="flex"
                                                                                        flexDirection="column"
                                                                                        width={{
                                                                                            base: '100%',
                                                                                            xl: '100%',
                                                                                        }}
                                                                                        maxWidth={{
                                                                                            base: '100%',
                                                                                            xl: '100%',
                                                                                        }}
                                                                                    >
                                                                                        <Flex
                                                                                            display="flex"
                                                                                            flexDirection="row"
                                                                                            width="100%"
                                                                                            paddingBottom="5px"
                                                                                        >
                                                                                            <Flex
                                                                                                paddingStart="0"
                                                                                                fontWeight="bold"
                                                                                                width="auto"
                                                                                                minWidth={{
                                                                                                    base: calculateLabelSize(
                                                                                                        75
                                                                                                    ),
                                                                                                    xl: calculateLabelSize(
                                                                                                        55
                                                                                                    ),
                                                                                                }}
                                                                                                fontSize={calculateFontSize(
                                                                                                    0.8
                                                                                                )}
                                                                                            >
                                                                                                From:
                                                                                            </Flex>
                                                                                            <Flex
                                                                                                width="100%"
                                                                                                fontSize={calculateFontSize(
                                                                                                    0.8
                                                                                                )}
                                                                                            >
                                                                                                {notam?.startvalidity &&
                                                                                                    formatDateTime(
                                                                                                        notam.startvalidity,
                                                                                                        timeZone
                                                                                                    )}
                                                                                            </Flex>
                                                                                        </Flex>

                                                                                        {!notam.permanent && (
                                                                                            <Flex
                                                                                                display="flex"
                                                                                                flexDirection="row"
                                                                                                width="100%"
                                                                                                paddingBottom="5px"
                                                                                            >
                                                                                                <Flex
                                                                                                    paddingStart="0"
                                                                                                    fontWeight="bold"
                                                                                                    width="auto"
                                                                                                    minWidth={{
                                                                                                        base: calculateLabelSize(
                                                                                                            75
                                                                                                        ),
                                                                                                        xl: calculateLabelSize(
                                                                                                            55
                                                                                                        ),
                                                                                                    }}
                                                                                                    fontSize={calculateFontSize(
                                                                                                        0.8
                                                                                                    )}
                                                                                                >
                                                                                                    To:
                                                                                                </Flex>
                                                                                                <Flex
                                                                                                    width="100%"
                                                                                                    fontSize={calculateFontSize(
                                                                                                        0.8
                                                                                                    )}
                                                                                                >
                                                                                                    {notam?.endvalidity &&
                                                                                                        formatDateTime(
                                                                                                            notam.endvalidity,
                                                                                                            timeZone
                                                                                                        )}
                                                                                                    {notam?.estimation &&
                                                                                                        ' (Estimated)'}
                                                                                                </Flex>
                                                                                            </Flex>
                                                                                        )}
                                                                                        {notam.itemd &&
                                                                                            notam.itemd !==
                                                                                                'null' && (
                                                                                                <Flex
                                                                                                    display="flex"
                                                                                                    flexDirection="row"
                                                                                                    width="100%"
                                                                                                    paddingBottom="5px"
                                                                                                >
                                                                                                    <Flex
                                                                                                        paddingStart="0"
                                                                                                        fontWeight="bold"
                                                                                                        width="auto"
                                                                                                        minWidth={{
                                                                                                            base: calculateLabelSize(
                                                                                                                75
                                                                                                            ),
                                                                                                            xl: calculateLabelSize(
                                                                                                                55
                                                                                                            ),
                                                                                                        }}
                                                                                                        fontSize={calculateFontSize(
                                                                                                            0.8
                                                                                                        )}
                                                                                                    >
                                                                                                        Period:
                                                                                                    </Flex>
                                                                                                    <Flex
                                                                                                        width="100%"
                                                                                                        fontSize={calculateFontSize(
                                                                                                            0.8
                                                                                                        )}
                                                                                                    >
                                                                                                        {notam.itemd +
                                                                                                            ' (UTC if time shown)'}
                                                                                                    </Flex>
                                                                                                </Flex>
                                                                                            )}
                                                                                    </Flex>
                                                                                </Flex>

                                                                                {aerodromeAffected && (
                                                                                    <Flex
                                                                                        width="100%"
                                                                                        display="flex"
                                                                                        flexDirection="row"
                                                                                        paddingBottom="5px"
                                                                                    >
                                                                                        <Flex
                                                                                            paddingStart="0"
                                                                                            fontWeight="bold"
                                                                                            width="auto"
                                                                                            minWidth={calculateLabelSize(
                                                                                                88
                                                                                            )}
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                        >
                                                                                            Aerodrome:
                                                                                        </Flex>
                                                                                        <Flex
                                                                                            width="100%"
                                                                                            fontSize={calculateFontSize(
                                                                                                0.8
                                                                                            )}
                                                                                        >
                                                                                            {
                                                                                                notam.itema
                                                                                            }
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}

                                                                                <Text
                                                                                    marginTop={
                                                                                        1
                                                                                    }
                                                                                    marginBottom={
                                                                                        3
                                                                                    }
                                                                                    whiteSpace="pre-line"
                                                                                    fontSize={calculateFontSize(
                                                                                        0.8
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        notam.iteme
                                                                                    }
                                                                                </Text>
                                                                                {i +
                                                                                    1 !==
                                                                                    enroutenotam.length && (
                                                                                    <Divider
                                                                                        variant="modalFooter"
                                                                                        marginTop={
                                                                                            7
                                                                                        }
                                                                                        marginBottom={
                                                                                            5
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                        </Flex>
                                                                    </Box>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        }
                                                    )}
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            ) : (
                                <Flex
                                    fontFamily="Open Sans"
                                    px="6"
                                    py="4"
                                    minHeight="50px"
                                    flexDirection="column"
                                >
                                    <Text
                                        fontSize="0.8rem"
                                        lineHeight="1.2"
                                        fontWeight="500"
                                        fontFamily="'Open Sans', sans-serif"
                                        flex="2"
                                        color="light.400"
                                    >
                                        No En-route NOTAM information is
                                        available for the selected areas
                                    </Text>
                                </Flex>
                            )}
                        </>
                    </Accordion>
                </Box>
            </Box>
        </>
    )
}
