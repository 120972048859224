import React from 'react'
import WindDirection from './WindDirection'
import WindSpeed from './WindSpeed'
import TempDialImage from './TempDial'
import QnhDialImage from './QnhDial'
import VisibilityImage from './VisibilityScale'

export const WindDirectionDial = (props) => {
    const {
        winddir,
        compassRoseMagVar,
        winddirDegreesMag,
        imageSize,
        imageColor,
        primaryImageColor,
        focusImageColor,
    } = props

    // space for any data manipulation

    return (
        <WindDirection
            imageSize={imageSize}
            winddir={winddir}
            compassRoseMagVar={compassRoseMagVar}
            winddirDegreesMag={winddirDegreesMag}
            imageColor={imageColor}
            primaryImageColor={primaryImageColor}
            focusImageColor={focusImageColor}
        />
    )
}

export const WindSpeedDial = (props) => {
    const {
        windspeed,
        imageSize,
        imageColor,
        primaryImageColor,
        focusImageColor,
    } = props

    // space for any data manipulation

    return (
        <WindSpeed
            windspeed={windspeed}
            imageSize={imageSize}
            imageColor={imageColor}
            primaryImageColor={primaryImageColor}
            focusImageColor={focusImageColor}
        />
    )
}
export const TempDial = (props) => {
    const {
        data,
        imageSize,
        imageColor,
        primaryImageColor,
        focusImageColor,
        type,
    } = props

    // space for any data manipulation

    return (
        <TempDialImage
            // qnhTrend={qnhTrend}
            data={data}
            imageSize={imageSize}
            imageColor={imageColor}
            primaryImageColor={primaryImageColor}
            focusImageColor={focusImageColor}
            type={type}
        />
    )
}

export const QnhDial = (props) => {
    const {
        data,
        imageSize,
        imageColor,
        primaryImageColor,
        focusImageColor,
        type,
    } = props

    // space for any data manipulation

    return (
        <QnhDialImage
            data={data}
            imageSize={imageSize}
            imageColor={imageColor}
            primaryImageColor={primaryImageColor}
            focusImageColor={focusImageColor}
            type={type}
        />
    )
}

export const VisibilityScale = (props) => {
    const {
        data,
        unit,
        imageSize,
        imageColor,
        primaryImageColor,
        focusImageColor,
        multipleCloudLayers,
    } = props

    // space for any data manipulation

    return (
        <VisibilityImage
            value={data}
            unit={unit}
            imageSize={imageSize}
            imageColor={imageColor}
            primaryImageColor={primaryImageColor}
            focusImageColor={focusImageColor}
            multipleCloudLayers={multipleCloudLayers}
        />
    )
}
