import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WeatherAdvisoryIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M3.7216 15.2881C2.6812 14.5659 2 13.3625 2 12C2 9.87618 3.65521 8.13895 5.746 8.00793C6.50027 5.40385 8.9028 3.5 11.75 3.5C14.0505 3.5 16.2543 4.92855 17.3251 7.00334C17.3795 7.00126 17.4342 7.00014 17.489 7.00001L17.5 7C19.8182 7 22.0002 9.01472 22.0002 11.5C22.0002 12.6802 21.5458 13.7542 20.8026 14.5568C19.9806 15.4445 18.8051 16 17.5 16H15.6778C15.8306 15.5125 15.8306 14.9875 15.6778 14.5H17.5C19.1569 14.5 20.5 13.1569 20.5 11.5C20.5 9.8879 19.123 8.57279 17.625 8.50292C17.5954 8.50154 17.5658 8.50065 17.5362 8.50025L17.5192 8.50007L17.5 8.5C17.4752 8.5 17.4505 8.5003 17.4259 8.5009C16.5672 8.52176 15.798 8.90341 15.264 9.50002L14.1563 8.48858C14.6065 7.98893 15.1672 7.5907 15.7998 7.33233C14.9336 5.93612 13.3735 5 11.75 5C9.21051 5 7.13647 6.99286 7.00647 9.5H6C4.61929 9.5 3.5 10.6193 3.5 12C3.5 12.6832 3.77404 13.3024 4.21821 13.7536C3.87896 14.2066 3.71343 14.7484 3.7216 15.2881Z"
                fill="currentColor"
            />
            <path
                d="M14.8279 13.25H17.5037C17.9667 13.25 18.4109 13.0656 18.7384 12.7371C19.0659 12.4087 19.25 11.9631 19.25 11.4984C19.25 11.0338 19.0659 10.5882 18.7384 10.2597C18.4355 9.95599 18.0328 9.77541 17.6077 9.75H17.5037C17.0407 9.75 16.5965 9.93442 16.269 10.2629C16.1005 10.4318 16.0092 10.5597 15.9609 10.6421C15.9367 10.6833 15.9232 10.7131 15.9161 10.731C15.9126 10.74 15.9106 10.7462 15.9096 10.7492L15.9084 10.754L15.9082 10.7546L15.9075 10.7576L15.9046 10.7696C15.9022 10.7801 15.899 10.7947 15.8959 10.8114C15.8894 10.8472 15.8854 10.8825 15.887 10.9046C15.8907 10.9581 15.8976 10.9939 15.9091 11.0237C15.9203 11.0527 15.938 11.0819 15.9707 11.1184C16.0069 11.1589 16.0395 11.1839 16.0731 11.2006C16.1069 11.2174 16.1474 11.2286 16.203 11.2345C16.2721 11.2419 16.3093 11.2351 16.3814 11.2072C16.482 11.1682 16.5156 11.1156 16.5862 11.005C16.6021 10.9801 16.62 10.9521 16.6408 10.9208C16.7548 10.7498 16.9168 10.6165 17.1065 10.5377C17.2961 10.4589 17.5048 10.4383 17.7062 10.4785C17.9075 10.5186 18.0924 10.6177 18.2375 10.7632C18.3826 10.9087 18.4814 11.094 18.5214 11.2957C18.5614 11.4973 18.5408 11.7063 18.4624 11.8963C18.3839 12.0863 18.2509 12.2487 18.0803 12.363C17.9097 12.4774 17.709 12.5384 17.5037 12.5384H14.1163L14.8279 13.25Z"
                fill="currentColor"
            />
            <path
                d="M14 21.1667C14.5523 21.1667 15 20.719 15 20.1667V17.5C15 17.5 13.3514 19.386 13.2385 19.5184C13.0898 19.693 13 19.9193 13 20.1667C13 20.719 13.4477 21.1667 14 21.1667Z"
                fill="currentColor"
            />
            <path
                d="M17.5 18.5C17.5 18.9142 17.1642 19.25 16.75 19.25C16.3358 19.25 16 18.9142 16 18.5C16 18.3145 16.0673 18.1448 16.1789 18.0138C16.2635 17.9145 17.5 16.5 17.5 16.5V18.5Z"
                fill="currentColor"
            />
            <path
                d="M9.04763 13.2485C9.02183 12.843 9.34372 12.5 9.75 12.5C10.1563 12.5 10.4782 12.843 10.4524 13.2485L10.2818 15.9296C10.2639 16.2102 10.0311 16.4286 9.75 16.4286C9.46886 16.4286 9.2361 16.2102 9.21825 15.9296L9.04763 13.2485Z"
                fill="currentColor"
            />
            <path
                d="M9.75 18.25C10.0952 18.25 10.375 17.9702 10.375 17.625C10.375 17.2798 10.0952 17 9.75 17C9.40482 17 9.125 17.2798 9.125 17.625C9.125 17.9702 9.40482 18.25 9.75 18.25Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.16064 16.3107C4.57485 15.7249 4.57485 14.7751 5.16064 14.1893L8.69617 10.6538C9.28196 10.068 10.2317 10.068 10.8175 10.6538L14.353 14.1893C14.9388 14.7751 14.9388 15.7249 14.353 16.3107L10.8175 19.8462C10.2317 20.432 9.28196 20.432 8.69617 19.8462L5.16064 16.3107ZM5.69097 14.7197L9.2265 11.1841C9.51939 10.8912 9.99427 10.8912 10.2872 11.1841L13.8227 14.7197C14.1156 15.0126 14.1156 15.4874 13.8227 15.7803L10.2872 19.3159C9.99427 19.6088 9.51939 19.6088 9.2265 19.3159L5.69097 15.7803C5.39807 15.4874 5.39807 15.0126 5.69097 14.7197Z"
                fill="currentColor"
            />
        </Icon>
    )
}
