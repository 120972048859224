import React from 'react'

export default function WindDirection(props) {
    const {
        winddir,
        imageSize,
        imageColor,
        primaryImageColor,
        focusImageColor,
    } = props

    return (
        <>
            <svg
                width={imageSize}
                height={imageSize}
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity="0.35" clipPath="url(#clip0_3093_33553)">
                    <rect
                        x="70.7734"
                        y="116.068"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(170 70.7734 116.068)"
                        fill={imageColor}
                    />
                    <rect
                        x="80.3457"
                        y="113.348"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(160 80.3457 113.348)"
                        fill={imageColor}
                    />
                    <rect
                        x="89.3008"
                        y="109.004"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(150 89.3008 109.004)"
                        fill={imageColor}
                    />
                    <rect
                        x="97.3633"
                        y="103.172"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(140 97.3633 103.172)"
                        fill={imageColor}
                    />
                    <rect
                        x="16.8301"
                        y="97.3652"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-130 16.8301 97.3652)"
                        fill={imageColor}
                    />
                    <rect
                        x="104.293"
                        y="96.0273"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(130 104.293 96.0273)"
                        fill={imageColor}
                    />
                    <rect
                        x="23.9746"
                        y="104.295"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-140 23.9746 104.295)"
                        fill={imageColor}
                    />
                    <rect
                        x="32.2129"
                        y="109.877"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-150 32.2129 109.877)"
                        fill={imageColor}
                    />
                    <rect
                        x="41.2969"
                        y="113.943"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-160 41.2969 113.943)"
                        fill={imageColor}
                    />
                    <rect
                        x="50.9473"
                        y="116.373"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-170 50.9473 116.373)"
                        fill={imageColor}
                    />
                    <rect
                        x="49.2266"
                        y="3.93164"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-10 49.2266 3.93164)"
                        fill={imageColor}
                    />
                    <rect
                        x="116.068"
                        y="49.2266"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(80 116.068 49.2266)"
                        fill={imageColor}
                    />
                    <rect
                        x="3.92969"
                        y="70.7715"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-100 3.92969 70.7715)"
                        fill={imageColor}
                    />
                    <rect
                        x="39.6543"
                        y="6.65234"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-20 39.6543 6.65234)"
                        fill={imageColor}
                    />
                    <rect
                        x="113.346"
                        y="39.6523"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(70 113.346 39.6523)"
                        fill={imageColor}
                    />
                    <rect
                        x="6.6543"
                        y="80.3457"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-110 6.6543 80.3457)"
                        fill={imageColor}
                    />
                    <rect
                        x="30.6992"
                        y="10.9961"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-30 30.6992 10.9961)"
                        fill={imageColor}
                    />
                    <rect
                        x="109.002"
                        y="30.6992"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(60 109.002 30.6992)"
                        fill={imageColor}
                    />
                    <rect
                        x="10.998"
                        y="89.2988"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-120 10.998 89.2988)"
                        fill={imageColor}
                    />
                    <rect
                        x="22.6367"
                        y="16.8281"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-40 22.6367 16.8281)"
                        fill={imageColor}
                    />
                    <rect
                        x="103.17"
                        y="22.6348"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(50 103.17 22.6348)"
                        fill={imageColor}
                    />
                    <rect
                        x="15.707"
                        y="23.9727"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-50 15.707 23.9727)"
                        fill={imageColor}
                    />
                    <rect
                        x="96.0254"
                        y="15.7051"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(40 96.0254 15.7051)"
                        fill={imageColor}
                    />
                    <rect
                        x="10.125"
                        y="32.2129"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-60 10.125 32.2129)"
                        fill={imageColor}
                    />
                    <rect
                        x="109.877"
                        y="87.7852"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(120 109.877 87.7852)"
                        fill={imageColor}
                    />
                    <rect
                        x="87.7871"
                        y="10.123"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(30 87.7871 10.123)"
                        fill={imageColor}
                    />
                    <rect
                        x="6.05664"
                        y="41.2949"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-70 6.05664 41.2949)"
                        fill={imageColor}
                    />
                    <rect
                        x="113.943"
                        y="78.7012"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(110 113.943 78.7012)"
                        fill={imageColor}
                    />
                    <rect
                        x="78.7031"
                        y="6.05664"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(20 78.7031 6.05664)"
                        fill={imageColor}
                    />
                    <rect
                        x="3.62695"
                        y="50.9473"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(-80 3.62695 50.9473)"
                        fill={imageColor}
                    />
                    <rect
                        x="116.371"
                        y="69.0508"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(100 116.371 69.0508)"
                        fill={imageColor}
                    />
                    <rect
                        x="69.0527"
                        y="3.62695"
                        width="1.5"
                        height="9"
                        rx="0.75"
                        transform="rotate(10 69.0527 3.62695)"
                        fill={imageColor}
                    />
                    <rect
                        x="60.877"
                        y="117.088"
                        width="1.74757"
                        height="11"
                        rx="0.873786"
                        transform="rotate(-180 60.877 117.088)"
                        fill={imageColor}
                    />
                    <rect
                        x="117.088"
                        y="59.125"
                        width="1.74757"
                        height="11"
                        rx="0.873786"
                        transform="rotate(90 117.088 59.125)"
                        fill={imageColor}
                    />
                    <rect
                        x="13.4004"
                        y="59.125"
                        width="1.74757"
                        height="11"
                        rx="0.873786"
                        transform="rotate(90 13.4004 59.125)"
                        fill={imageColor}
                    />
                    <path
                        d="M59.0283 3.49721C59.2759 2.47883 60.7241 2.47883 60.9717 3.49721L63.1636 12.5138C63.3166 13.1433 62.8398 13.75 62.1919 13.75H57.8081C57.1602 13.75 56.6834 13.1433 56.8364 12.5138L59.0283 3.49721Z"
                        fill={imageColor}
                    />
                </g>
                <g opacity="0.8">
                    <path
                        d="M62.0347 102.291C62.0347 102.671 61.9395 102.997 61.749 103.266C61.5615 103.533 61.2979 103.736 60.958 103.877C60.6182 104.018 60.2183 104.088 59.7583 104.088C59.5151 104.088 59.2852 104.076 59.0684 104.053C58.8516 104.029 58.6523 103.996 58.4707 103.952C58.2891 103.908 58.1294 103.854 57.9917 103.789V103.073C58.2114 103.164 58.4795 103.249 58.7959 103.328C59.1123 103.404 59.4434 103.442 59.7891 103.442C60.1113 103.442 60.3838 103.399 60.6064 103.314C60.8291 103.227 60.9976 103.102 61.1118 102.941C61.229 102.777 61.2876 102.581 61.2876 102.352C61.2876 102.132 61.2393 101.949 61.1426 101.803C61.0459 101.653 60.8848 101.519 60.6592 101.398C60.4365 101.275 60.1318 101.145 59.7451 101.007C59.4727 100.911 59.2324 100.805 59.0244 100.691C58.8164 100.574 58.6421 100.442 58.5015 100.295C58.3608 100.149 58.2539 99.979 58.1807 99.7856C58.1104 99.5923 58.0752 99.3711 58.0752 99.1221C58.0752 98.7793 58.1616 98.4863 58.3345 98.2432C58.5103 97.9971 58.752 97.8096 59.0596 97.6807C59.3701 97.5488 59.7261 97.4829 60.1274 97.4829C60.4702 97.4829 60.7866 97.5151 61.0767 97.5796C61.3696 97.644 61.6377 97.7305 61.8809 97.8389L61.6479 98.4805C61.4165 98.3838 61.1704 98.3032 60.9097 98.2388C60.6519 98.1743 60.3853 98.1421 60.1099 98.1421C59.8345 98.1421 59.6016 98.1831 59.4111 98.2651C59.2236 98.3442 59.0801 98.457 58.9805 98.6035C58.8809 98.75 58.8311 98.9243 58.8311 99.1265C58.8311 99.3521 58.8779 99.5396 58.9717 99.689C59.0684 99.8384 59.2207 99.9717 59.4287 100.089C59.6396 100.203 59.918 100.323 60.2637 100.449C60.6416 100.587 60.9624 100.733 61.2261 100.889C61.4897 101.041 61.6904 101.229 61.8281 101.451C61.9658 101.671 62.0347 101.951 62.0347 102.291Z"
                        fill={primaryImageColor}
                    />
                    <path
                        d="M23.021 56.5752L21.3027 63H20.5513L19.2769 58.6758C19.2388 58.5527 19.2021 58.4297 19.167 58.3066C19.1348 58.1807 19.104 58.062 19.0747 57.9507C19.0454 57.8364 19.0205 57.7368 19 57.6519C18.9824 57.564 18.9692 57.498 18.9604 57.4541C18.9546 57.498 18.9429 57.5625 18.9253 57.6475C18.9106 57.7324 18.8901 57.8306 18.8638 57.9419C18.8403 58.0532 18.811 58.1719 18.7759 58.2979C18.7437 58.4238 18.7085 58.5513 18.6704 58.6802L17.4312 63H16.6797L14.9746 56.5752H15.7524L16.7852 60.605C16.8203 60.7397 16.8525 60.873 16.8818 61.0049C16.9141 61.1338 16.9419 61.2598 16.9653 61.3828C16.9917 61.5059 17.0151 61.626 17.0356 61.7432C17.0562 61.8604 17.0752 61.9746 17.0928 62.0859C17.1074 61.9717 17.1265 61.853 17.1499 61.73C17.1733 61.604 17.1982 61.4766 17.2246 61.3477C17.2539 61.2188 17.2847 61.0884 17.3169 60.9565C17.3521 60.8247 17.3887 60.6929 17.4268 60.561L18.5825 56.5752H19.3516L20.5557 60.5918C20.5967 60.7295 20.6348 60.8657 20.6699 61.0005C20.7051 61.1353 20.7358 61.2671 20.7622 61.396C20.7915 61.522 20.8164 61.6436 20.8369 61.7607C20.8604 61.875 20.8809 61.9834 20.8984 62.0859C20.9189 61.9424 20.9438 61.7915 20.9731 61.6333C21.0024 61.4751 21.0361 61.3096 21.0742 61.1367C21.1152 60.9639 21.1592 60.7852 21.2061 60.6006L22.2388 56.5752H23.021Z"
                        fill={primaryImageColor}
                    />
                    <path
                        d="M102.956 63.4004H99.3784V56.9756H102.956V57.6348H100.125V59.7134H102.793V60.3638H100.125V62.7412H102.956V63.4004Z"
                        fill={primaryImageColor}
                    />
                    <path
                        d="M62.5137 22.5H61.6523L58.1499 17.1035H58.1147C58.1235 17.2471 58.1338 17.4111 58.1455 17.5957C58.1572 17.7773 58.166 17.9722 58.1719 18.1802C58.1807 18.3853 58.1851 18.5947 58.1851 18.8086V22.5H57.4907V16.0752H58.3477L61.8369 21.4541H61.8677C61.8618 21.3516 61.8545 21.2065 61.8457 21.019C61.8369 20.8286 61.8281 20.625 61.8193 20.4082C61.8135 20.1885 61.8105 19.9849 61.8105 19.7974V16.0752H62.5137V22.5Z"
                        fill={primaryImageColor}
                    />
                </g>
                <g // Direction Indicator
                    transform={winddir ? `rotate(${winddir} 60 60)` : null}
                    style={{
                        transition: 'transform 400ms',
                        transitionDuration: '400ms',
                        transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
                    }}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        opacity="0.5"
                        d="M60 95C79.33 95 95 79.33 95 60C95 40.67 79.33 25 60 25C40.67 25 25 40.67 25 60C25 79.33 40.67 95 60 95ZM60 93C78.2254 93 93 78.2254 93 60C93 41.7746 78.2254 27 60 27C41.7746 27 27 41.7746 27 60C27 78.2254 41.7746 93 60 93Z"
                        fill={imageColor}
                    />
                    {/* <path
                        d="M59.0462 32.9712C59.3399 33.904 60.6601 33.904 60.9538 32.9712L64.7866 20.8004C64.9896 20.156 64.5084 19.5 63.8328 19.5H56.1672C55.4916 19.5 55.0104 20.156 55.2134 20.8004L59.0462 32.9712Z"
                        fill="#ff8e00"
                    /> */}
                    {winddir !== 0 && (
                        <path
                            d="M59.0344 37.4163C59.3002 38.4027 60.6998 38.4027 60.9656 37.4163L65.7227 19.7602C65.8939 19.1247 65.4152 18.5 64.7571 18.5H55.2429C54.5848 18.5 54.1061 19.1247 54.2773 19.7602L59.0344 37.4163Z"
                            fill={focusImageColor}
                        />
                    )}
                </g>
                {winddir !== 0 && (
                    <g // WIN DIRECTION TEXT
                        id="winddir-text"
                        transform={'translate(-90 50)'}
                    >
                        <text
                            x="133"
                            y="16"
                            fill={primaryImageColor}
                            style={{
                                textShadow: '0px 0px 6px rgba(0,0,0,0.06)',
                                transition: 'transform 400ms',
                                transitionDuration: '400ms',
                                transitionTimingFunction:
                                    'cubic-bezier(0, 0, 0.2, 1)',
                            }}
                            fontSize="18"
                            fontWeight="600"
                        >
                            {winddir.toString().padStart(3, '0') + '°'}
                        </text>
                    </g>
                )}
                <defs>
                    <clipPath id="clip0_3093_33553">
                        <rect width="120" height="120" fill={imageColor} />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
