import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { MapContext } from 'react-mapbox-gl'
import { Image as Icon } from 'react-mapbox-gl'
import { useQuery } from 'react-query'
import pulsingDot from './PulsingDot'
import buildAerodromeIcon from './buildAerodromeIcon'
import { mapLoadingAerodromeIconsState, statusData } from '../../../globalState'
import { isEqual } from 'lodash'

export default function DynamicIcons() {
    const { getAccessTokenSilently } = useAuth0()
    const fetchAerodromes = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/aerodromes`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const map = useContext(MapContext)
    const [aerodromeIcons, setAerodromeIcons] = useState(null)
    const [mounted, setMounted] = useState(false)
    const [aerodromes, setAerodromes] = useState('')
    const setMapLoadingAerodromeIcons = useSetRecoilState(
        mapLoadingAerodromeIconsState
    )
    const dataStatus = useRecoilValue(statusData)

    const aerodromeRef = useRef(null)
    const aerodromeVersionRef = useRef(null)
    const { data, status, refetch } = useQuery('aerodromes', fetchAerodromes, {
        refetchOnWindowFocus: false,
    })

    // This sets the intial value of aerodromes when it is loaded.
    useEffect(() => {
        if (dataStatus && data && status === 'success' && !mounted) {
            const aerodromeProperties = data.features.map(
                (feat) => feat.properties
            )
            setAerodromes(aerodromeProperties)
            aerodromeVersionRef.current = dataStatus.aerodromes.version
            setMounted(true)
        }
    }, [setAerodromes, data, status, mounted, aerodromes, dataStatus])

    // This checks that aerodromes have changed before resetting them.
    useEffect(() => {
        if (status === 'success' && data && mounted) {
            const aerodromeProperties = data.features.map(
                (feat) => feat.properties
            )
            if (!isEqual(aerodromeProperties, aerodromeRef.current)) {
                setAerodromes(aerodromeProperties)
                //aerodromeRef.current = aerodromeProperties
            }
        }
    }, [setAerodromes, data, status, mounted, aerodromes])

    // This detects a change in the aerodrome version stamp, if it has changed it will trigger a refetch.
    useEffect(() => {
        if (
            dataStatus &&
            mounted &&
            aerodromeVersionRef.current !== dataStatus.aerodromes.version
        ) {
            refetch()
            aerodromeVersionRef.current = dataStatus.aerodromes.version
        }
    }, [dataStatus, mounted, refetch])

    // This function checks old aerodromes against new aerodromes and returns the difference.
    function aerodromesChanged(newAerodromes, oldAerodromes) {
        if (oldAerodromes) {
            const changes = newAerodromes.filter((a, i) => {
                const oldNotam = Number(oldAerodromes[i]?.notam) || 0
                const newNotam = Number(a?.notam) || 0
                return (
                    (oldNotam === 0 && newNotam > 0) ||
                    (oldNotam > 0 && newNotam === 0)
                )
            })
            return changes
        } else {
            return []
        }
    }

    useEffect(() => {
        if (aerodromes) {
            // Creates HTML image element and attaches svg data to it.
            const loadSvgAsHtmlImage = (
                type,
                flightRule,
                rwyAzimuth,
                notam
            ) => {
                return new Promise((resolve) => {
                    const image = new Image(32, 32) // Currently set to 32 * 32 px wide images, but can adjust this
                    image.addEventListener('load', () => resolve(image))
                    image.src = `data:image/svg+xml;base64,${buildAerodromeIcon(
                        type,
                        flightRule,
                        rwyAzimuth,
                        notam
                    )}`
                })
            }

            const getSvgs = async (ads) => {
                const svgs = {}
                return new Promise((resolve) => {
                    ads.map(async (aerodrome) => {
                        const designator = aerodrome.designator
                        const type = aerodrome.type
                        const flightRule = aerodrome.flightrule
                        const rwyAzimuth = aerodrome.rwyazimuth
                        const notam = aerodrome.notam

                        loadSvgAsHtmlImage(type, flightRule, rwyAzimuth, notam)
                            .then((data) => (svgs[designator] = data))
                            .then(() => {
                                if (ads.length === Object.keys(svgs).length) {
                                    resolve(svgs)
                                }
                            })
                    })
                })
            }

            if (aerodromeIcons) {
                const changes = aerodromesChanged(
                    aerodromes,
                    aerodromeRef.current
                )
                if (changes.length) {
                    getSvgs(changes).then((svgs) => {
                        if (svgs && Object.keys(svgs).length) {
                            Object.keys(svgs).forEach((aerodrome) => {
                                map.hasImage(aerodrome) &&
                                    map.updateImage(aerodrome, svgs[aerodrome])
                                map.triggerRepaint()
                            })
                        }
                    })
                }
                aerodromeRef.current = aerodromes
            } else {
                getSvgs(aerodromes).then((svgs) => {
                    setAerodromeIcons(
                        Object.keys(svgs).map((aerodrome, index) => {
                            aerodromeRef.current = aerodromes
                            return (
                                <Icon
                                    id={aerodrome}
                                    key={aerodrome}
                                    data={svgs[aerodrome]}
                                    onLoaded={() => {
                                        if (
                                            index ===
                                            Object.keys(svgs).length - 1
                                        ) {
                                            setMapLoadingAerodromeIcons(false)
                                        }
                                    }}
                                    onError={(error) => console.log(error)}
                                />
                            )
                        })
                    )
                })
            }
        }
    }, [
        aerodromeIcons,
        aerodromes,
        map,
        setAerodromes,
        setMapLoadingAerodromeIcons,
    ])

    return (
        <>
            <Icon id="pulsingdot" data={pulsingDot(map)} />
            {aerodromeIcons}
        </>
    )
}
