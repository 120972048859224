import React, { useCallback, useEffect } from 'react'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    Text,
    Table,
    Tbody,
    Tr,
    Td,
} from '@chakra-ui/react'

import {
    isMobile,
    getUA,
    osVersion,
    isAndroid,
    isChrome,
    isSafari,
    isFirefox,
    isMacOS,
    isMobileOnly,
    isIOS,
    isMobileSafari,
    isDesktop,
    isSamsungBrowser,
    engineVersion,
    deviceType,
    osName,
    isWindows,
    fullBrowserVersion,
    isEdge,
    engineName,
    mobileVendor,
    mobileModel,
} from 'react-device-detect'

import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    setModalVisibilityState,
    modalVisibilityState,
    orientationState,
} from '../../../globalState'
import { useAdminRole } from '../../../auth/hooks'

export default function DeviceDetails() {
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const orientation = useRecoilValue(orientationState)
    const isAdmin = useAdminRole()
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'deviceDetails', value })
        },
        [setModalVisibility]
    )
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.deviceDetails ? onOpen() : onClose()
    }, [modalVisibility.deviceDetails, onOpen, onClose])

    function onCloseHandler() {
        setShow(false)
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={'faqs'}
                    size="3xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            background="white"
                            color="light.200"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            Device Details
                        </ModalHeader>
                        <ModalBody
                            background="light.600"
                            style={{ padding: 0 }}
                            fontSize="0.9rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <Flex
                                maxHeight="65vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 6, md: 10 }}
                                pt={10}
                                pb={6}
                                alignItems="baseline"
                                flexDirection="column"
                            >
                                {isAdmin && (
                                    <>
                                        <Flex
                                            fontSize="0.8rem"
                                            color="light.100"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            textAlign="center"
                                            width="100%"
                                        >
                                            <Text
                                                mb="20px !important"
                                                pr="5px"
                                                fontSize="0.9rem"
                                                fontWeight="500"
                                                textAlign="center"
                                            >
                                                {getUA}
                                            </Text>

                                            <Flex
                                                gap={{
                                                    base: '0px',
                                                    md: '0px',
                                                }}
                                                flexDirection={{
                                                    base: 'column',
                                                    md: 'column',
                                                }}
                                            >
                                                <Table
                                                    size="sm"
                                                    fontFamily="Open Sans"
                                                    marginTop={{
                                                        base: '0px',
                                                        md: '1',
                                                    }}
                                                    marginBottom={{
                                                        base: '0px',
                                                        md: '0',
                                                    }}
                                                >
                                                    <Tbody verticalAlign="baseline">
                                                        {mobileVendor !==
                                                            'none' && (
                                                            <Tr>
                                                                <Td
                                                                    py={1}
                                                                    paddingStart="0"
                                                                    paddingEnd="0"
                                                                    fontWeight="500"
                                                                    width="auto"
                                                                    minWidth="170px"
                                                                    fontSize="0.9rem"
                                                                >
                                                                    Mobile Make:
                                                                </Td>

                                                                <Td
                                                                    py={1}
                                                                    fontWeight="300"
                                                                    paddingStart="0"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                >
                                                                    {
                                                                        mobileVendor
                                                                    }
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                        {mobileModel !==
                                                            'none' && (
                                                            <Tr>
                                                                <Td
                                                                    py={1}
                                                                    paddingStart="0"
                                                                    paddingEnd="0"
                                                                    fontWeight="500"
                                                                    width="auto"
                                                                    minWidth="170px"
                                                                    fontSize="0.9rem"
                                                                >
                                                                    Mobile
                                                                    Model:
                                                                </Td>

                                                                <Td
                                                                    py={1}
                                                                    fontWeight="300"
                                                                    paddingStart="0"
                                                                    width="100%"
                                                                    fontSize="0.9rem"
                                                                >
                                                                    {
                                                                        mobileModel
                                                                    }
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                Operating
                                                                Software:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {osName}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                OS Version:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {osVersion}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                Device Type:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                                textTransform="capitalize"
                                                            >
                                                                {deviceType}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                Browser Version:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {
                                                                    fullBrowserVersion
                                                                }
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                Engine:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {engineName}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                Engine Version:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {engineVersion}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isDesktop:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isDesktop
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isMobile:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isMobile
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isMobileOnly:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isMobileOnly
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                                <Table
                                                    size="sm"
                                                    fontFamily="Open Sans"
                                                    marginTop={{
                                                        base: '0px',
                                                        md: '0',
                                                    }}
                                                    marginBottom="3"
                                                >
                                                    <Tbody verticalAlign="baseline">
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isWindows:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isWindows
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isMacOS:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isMacOS
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>

                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isIOS:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isIOS
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isAndroid:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isAndroid
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>

                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isChrome:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isChrome
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isFirefox:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isFirefox
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isEdge:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isEdge
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>

                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isSafari:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isSafari
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isMobileSafari:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isMobileSafari
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>

                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                isSamsungBrowser:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {isSamsungBrowser
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td
                                                                py={1}
                                                                paddingStart="0"
                                                                paddingEnd="0"
                                                                fontWeight="500"
                                                                width="auto"
                                                                minWidth="170px"
                                                                fontSize="0.9rem"
                                                            >
                                                                Device
                                                                Orientation:
                                                            </Td>

                                                            <Td
                                                                py={1}
                                                                fontWeight="300"
                                                                paddingStart="0"
                                                                width="100%"
                                                                fontSize="0.9rem"
                                                            >
                                                                {orientation}
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                            </Flex>
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
