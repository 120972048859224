import { Box, Button, Flex, Icon } from '@chakra-ui/react'
import { ReactComponent as AerodromeIcon } from '../../icons/assets/aerodromes/aerodrome.svg'
import { ReactComponent as WaterdromeIcon } from '../../icons/assets/aerodromes/waterdrome.svg'
import { ReactComponent as HeliportIcon } from '../../icons/assets/aerodromes/heliport.svg'

export default function AerodromesList(props) {
    const { optionSelected, setOptionSelected } = props

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <>
            <Button
                bg="gray.100"
                color="#a8b2c0"
                variant="outline"
                fontSize="0.85rem"
                fontWeight="500"
                height={{
                    base: '34px',
                    md: '32px',
                }}
                width="100%"
                maxWidth={{
                    base: '323px',
                    xs: '359px',
                    sm: '383px',
                    md: '323px',
                    ml: '383px',
                }}
                style={{
                    minWidth: {
                        base: '323px',
                        xs: '359px',
                        sm: '383px',
                        md: '323px',
                        ml: '383px',
                    },
                }}
                justifyContent="initial"
                paddingLeft="2px"
                paddingRight="2px"
                py="2px"
                borderRadius="20px"
                borderColor="#E9EAEB"
                {...noFocus}
                _hover={{
                    bg: 'gray.200',
                }}
            >
                <Box
                    mt="0px"
                    px="10px"
                    height={{
                        base: '30px',
                        md: '28px',
                    }}
                    width={{
                        base: '53px',
                        xs: '60px',
                        sm: '63px',
                        md: '53px',
                        ml: '63px',
                    }}
                    minWidth={{
                        base: '53px',
                        xs: '59px',
                        sm: '63px',
                        md: '53px',
                        ml: '63px',
                    }}
                    borderRadius="20px"
                    bg="#ffffff"
                    border="1px solid"
                    style={{
                        borderColor: '#E9EAEB',
                        transform: optionSelected
                            ? `translateX(${optionSelected}00%)`
                            : 'translateX(0%)',
                        transition: 'transform 400ms',
                    }}
                    zIndex="1"
                ></Box>
                <Flex
                    justifyContent="space-evenly"
                    marginLeft={{
                        base: '-53px',
                        xs: '-60px',
                        sm: '-63px',
                        md: '-53px',
                        ml: '-63px',
                    }}
                    width={{
                        base: '323px',
                        xs: '359px',
                        sm: '383px',
                        md: '323px',
                        ml: '383px',
                    }}
                    zIndex="2"
                >
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        width={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        minWidth={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        height={{
                            base: '34px',
                            md: '32px',
                        }}
                        zIndex="1"
                        paddingLeft="0px"
                        lineHeight="1.3"
                        color={optionSelected === 0 ? 'light.200' : 'light.100'}
                        _selected={{
                            opacity: '1',
                        }}
                        _active={{
                            opacity: '1',
                        }}
                        transition="all ease 200ms"
                        fontSize="0.75rem"
                        fontWeight="700"
                        onClick={() => setOptionSelected(0)}
                    >
                        ALL
                    </Flex>
                    <Flex
                        ml="-1px"
                        justifyContent="center"
                        alignItems="center"
                        width={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        minWidth={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        height={{
                            base: '34px',
                            md: '32px',
                        }}
                        zIndex="1"
                        lineHeight="1.3"
                        _selected={{
                            opacity: '1',
                        }}
                        _active={{
                            opacity: '1',
                        }}
                        transition="all ease 200ms"
                        onClick={() => setOptionSelected(1)}
                    >
                        <Flex
                            width="26px"
                            height="14px"
                            justifyContent="center"
                            alignItems="center"
                            bg={optionSelected === 1 ? 'ifr.100' : 'gray.500'}
                            color="white"
                            zIndex="1"
                            borderRadius="20px"
                            fontSize="0.6rem"
                            fontWeight="600"
                        >
                            IFR
                        </Flex>
                    </Flex>
                    <Flex
                        ml="-1px"
                        justifyContent="center"
                        alignItems="center"
                        width={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        minWidth={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        height={{
                            base: '34px',
                            md: '32px',
                        }}
                        zIndex="1"
                        lineHeight="1.3"
                        _selected={{
                            opacity: '1',
                        }}
                        _active={{
                            opacity: '1',
                        }}
                        transition="all ease 200ms"
                        onClick={() => setOptionSelected(2)}
                    >
                        <Flex
                            width="26px"
                            height="14px"
                            justifyContent="center"
                            alignItems="center"
                            bg={optionSelected === 2 ? 'vfr.100' : 'gray.500'}
                            color="white"
                            borderRadius="20px"
                            fontSize="0.6rem"
                            fontWeight="600"
                        >
                            VFR
                        </Flex>
                    </Flex>
                    <Flex
                        ml="-1px"
                        justifyContent="center"
                        alignItems="center"
                        width={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        minWidth={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        height={{
                            base: '34px',
                            md: '32px',
                        }}
                        zIndex="1"
                        lineHeight="1.3"
                        color={optionSelected === 3 ? 'light.200' : 'gray.500'}
                        _selected={{
                            opacity: '1',
                        }}
                        _active={{
                            opacity: '1',
                        }}
                        transition="all ease 200ms"
                        onClick={() => setOptionSelected(3)}
                    >
                        <Icon as={AerodromeIcon} width="20px" height="20px" />
                    </Flex>
                    <Flex
                        ml="-1px"
                        justifyContent="center"
                        alignItems="center"
                        width={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        minWidth={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        height={{
                            base: '34px',
                            md: '32px',
                        }}
                        zIndex="1"
                        lineHeight="1.3"
                        color={optionSelected === 4 ? 'light.200' : 'gray.500'}
                        _selected={{
                            opacity: '1',
                        }}
                        _active={{
                            opacity: '1',
                        }}
                        transition="all ease 200ms"
                        onClick={() => setOptionSelected(4)}
                    >
                        <Icon as={HeliportIcon} width="20px" height="20px" />
                    </Flex>
                    <Flex
                        ml="-1px"
                        justifyContent="center"
                        alignItems="center"
                        width={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        minWidth={{
                            base: '54px',
                            xs: '60px',
                            sm: '64px',
                            md: '54px',
                            ml: '64px',
                        }}
                        height={{
                            base: '34px',
                            md: '32px',
                        }}
                        zIndex="1"
                        lineHeight="1.3"
                        color={optionSelected === 5 ? 'light.200' : 'gray.500'}
                        _selected={{
                            opacity: '1',
                        }}
                        _active={{
                            opacity: '1',
                        }}
                        transition="all ease 200ms"
                        onClick={() => setOptionSelected(5)}
                    >
                        <Icon as={WaterdromeIcon} width="20px" height="20px" />
                    </Flex>
                </Flex>
            </Button>
        </>
    )
}
