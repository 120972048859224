import React from 'react'
import { useRecoilValue } from 'recoil'
import {
    Flex,
    Box,
    Text,
    useBreakpointValue,
    Table,
    Tbody,
    Tr,
    Td,
} from '@chakra-ui/react'
import formatDateTime from '../../../../../util/dateFormatter'
import { presentWeatherTranslate } from '../../../../../util/metDecode'
import {
    parseISO,
    formatISO,
    formatDistanceToNow,
    formatDistanceToNowStrict,
    intervalToDuration,
} from 'date-fns'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import {
    timeZoneState,
    showOutdatedErrorsState,
} from '../../../../../globalState'

import ErrorText from '../../../components/ErrorText'

export default React.memo(function AWSBriefingContent(props) {
    const { briefingData, aws } = props

    const timeZone = useRecoilValue(timeZoneState)

    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)

    const briefingIssueTime = briefingData && parseISO(briefingData.time)

    // AWS Data
    const awsData = aws && aws.data ? aws.data : false

    // AWS Out of Date Error Message Times
    const awsIssuedTime =
        aws && awsData && awsData.obs_timestamp
            ? parseISO(awsData.obs_timestamp)
            : null
    const awsTimeDifference =
        awsIssuedTime &&
        intervalToDuration({
            start: awsIssuedTime,
            end: briefingIssueTime,
        })
    const awsOutOfDateIssueTime =
        (awsTimeDifference && awsTimeDifference.days >= 1) ||
        (awsTimeDifference &&
            awsTimeDifference.days < 1 &&
            awsTimeDifference.hours >= 1) // = 1 hour or greater
            ? 'WARNING'
            : awsTimeDifference && awsTimeDifference.minutes >= 45 // = 45 mins or greater
            ? 'CAUTION'
            : 'NONE'

    // Weather
    const winddir =
        awsData &&
        (awsData.winddir_10mnavg // DEGREES TRUE
            ? awsData.winddir_10mnavg // 10 min average
            : awsData.winddir_01mnavg
            ? awsData.winddir_01mnavg // else 1 min average
            : awsData.winddir_01hravg
            ? awsData.winddir_01hravg // else 1 hour average
            : null)

    const winddirmax =
        awsData &&
        (awsData.windcw__10mnmax // DEGREES TRUE
            ? awsData.windcw__10mnmax // 10 min average
            : awsData.windcw__01mnmax
            ? awsData.windcw__01mnmax // else 1 min average
            : awsData.windcw__01hrmax
            ? awsData.windcw__01hrmax // else 1 hour average
            : null)

    const winddirmin =
        awsData &&
        (awsData.windccw_10mnmax // DEGREES TRUE
            ? awsData.windccw_10mnmax // 10 min average
            : awsData.windccw_01mnmax
            ? awsData.windccw_01mnmax // else 1 min average
            : awsData.windccw_01hrmax
            ? awsData.windccw_01hrmax // else 1 hour average
            : null)

    const windspeed =
        awsData &&
        (awsData.windspd_10mnavg
            ? awsData.windspd_10mnavg // 10 min average
            : awsData.windspd_01mnavg
            ? awsData.windspd_01mnavg // else 1 min average
            : awsData.windspd_01hravg
            ? awsData.windspd_01hravg // else 1 hour average
            : null)

    const windgust =
        awsData &&
        (awsData && awsData.windgst_10mnmax
            ? awsData.windgst_10mnmax // 10 min average
            : awsData.windgst_01mnmax
            ? awsData.windgst_01mnmax // else 1 min average
            : awsData.windgst_01hrmax
            ? awsData.windgst_01hrmax // else 1 hour average
            : null)

    // const windlull =
    //     awsData &&
    //     (awsData && awsData.windlul_10mnmin
    //         ? awsData.windlul_10mnmin // 10 min average
    //         : awsData.windlul_01mnmin
    //         ? awsData.windlul_01mnmin // else 1 min average
    //         : null)

    const temp =
        awsData &&
        (awsData.airtemp_01mnavg === 0
            ? 0
            : awsData && awsData.airtemp_01mnavg
            ? Math.round(awsData.airtemp_01mnavg) // 1 min average
            : null)

    const dewpoint =
        awsData &&
        (awsData.dewtemp_01mnavg === 0
            ? 0
            : awsData && awsData.dewtemp_01mnavg
            ? Math.round(awsData.dewtemp_01mnavg) // 1 min average
            : null)

    // Cloud
    const cloudLayers = []

    const cloudConversion = [
        {
            label: 'Overcast',
            amount: 8,
        },
        {
            label: 'Broken',
            amount: 7,
        },
        {
            label: 'Broken',
            amount: 6,
        },
        {
            label: 'Broken',
            amount: 5,
        },
        {
            label: 'Scattered',
            amount: 4,
        },
        {
            label: 'Scattered',
            amount: 3,
        },
        {
            label: 'Few',
            amount: 2,
        },
        {
            label: 'Few',
            amount: 1,
        },
        {
            label: 'Sky Clear',
            amount: 0,
        },
    ]

    // Cloud layer conversion
    const handleCloudLayer = (awsData, [amount, value]) => {
        if (awsData && awsData[amount] && awsData[value]) {
            const cloudAmount = awsData[amount]
            const cloudLayer = cloudConversion.find(
                (layer) => layer.amount === cloudAmount
            )
            if (cloudLayer) {
                cloudLayers.push({
                    amount: cloudLayer.label,
                    value: awsData[value],
                })
            }
        }
    }

    cloudLayers.length = 0
    // if there is a cloud layer
    handleCloudLayer(awsData, ['cld1amt_30mnavg', 'cld1hgt_30mnavg'])
    // // if there is a second layer
    handleCloudLayer(awsData, ['cld2amt_30mnavg', 'cld2hgt_30mnavg'])
    // // if there is a third layer
    handleCloudLayer(awsData, ['cld3amt_30mnavg', 'cld3hgt_30mnavg'])
    // // if there is a fourth layer
    handleCloudLayer(awsData, ['cld4amt_30mnavg', 'cld4hgt_30mnavg'])

    const cloudPresent = cloudLayers && cloudLayers.length >= 1

    const presentWeather =
        awsData &&
        (awsData.prwxst1_10mnavg
            ? awsData.prwxst1_10mnavg // 10 min average (Station 1)
            : awsData.prwxst1_15mnavg
            ? awsData.prwxst1_15mnavg // else 15 min average (Station 1)
            : awsData.prwxst2_10mnavg
            ? awsData.prwxst2_10mnavg // 10 min average (Station 2)
            : awsData.prwxst2_15mnavg
            ? awsData.prwxst2_15mnavg // else 15 min average (Station 2)
            : awsData.prwxst3_10mnavg
            ? awsData.prwxst3_10mnavg // 10 min average (Station 3)
            : awsData.prwxst3_15mnavg
            ? awsData.prwxst3_15mnavg // else 15 min average (Station 3)
            : null)

    const visibilityData =
        awsData &&
        (awsData.visibil_10mnavg
            ? awsData.visibil_10mnavg // 10 min average
            : null)
    const visibility =
        visibilityData &&
        !visibilityData.startsWith('/') &&
        Number(visibilityData.slice(0, -3))

    const qnh =
        awsData &&
        (awsData.presqnh_01mnavg
            ? Math.round(awsData.presqnh_01mnavg) // 1 min average
            : null)

    // if no required data
    const filteredData = Object.fromEntries(
        Object.entries(awsData).filter(([key]) => key !== 'obs_timestamp')
    )
    const dataArray = Object.keys(filteredData)
    const hasData =
        dataArray &&
        dataArray.length > 0 &&
        (winddir || temp || dewpoint || visibility || qnh)

    // Listener to know when Table overflow has been scrolled to start and end to allow mobile swipe between tabs to operate correctly
    const awsContentContainer = document.getElementById('awsClientWidth') // Accessing ID of Box around Table
    const awsContentAreas = useBreakpointValue({
        // used to account for Area labels on left side in desktop view
        base: 0,
        md: 140,
        lg: 170,
    })
    const awsContentWidth =
        awsContentContainer && awsContentContainer.clientWidth - awsContentAreas

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize, calculateContainerSize } = useAdjustedFontSize()

    // console.log(aws, 'aws')
    // console.log(awsData, 'awsData')
    // console.log(hasData, 'hasData')

    return awsData && hasData ? (
        <>
            {/* ERROR MESSAGES */}
            {!showOutdatedErrors &&
                !aws.outofdate &&
                (awsOutOfDateIssueTime === 'CAUTION' ||
                    awsOutOfDateIssueTime === 'WARNING') && (
                    <Flex
                        mb={{
                            base: '0px',
                            smd: '-10px',
                        }}
                        mt={{
                            base: '5px',
                            xl: '20px',
                        }}
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Text
                            color={
                                awsOutOfDateIssueTime === 'CAUTION'
                                    ? '#ff7f00'
                                    : 'red'
                            }
                            fontSize="12px"
                        >
                            This AWS information was last issued{' '}
                            <strong>
                                {formatDistanceToNowStrict(
                                    parseISO(awsData.obs_timestamp),
                                    {
                                        addSuffix: true,
                                    }
                                )}
                            </strong>
                            .
                        </Text>

                        {awsOutOfDateIssueTime === 'WARNING' && (
                            <Text color={'red'} fontSize="12px">
                                There is a possibility there has been an issue
                                receiving the latest data and that the latest
                                AWS information is not available.
                                <br /> Please check back again soon.
                            </Text>
                        )}
                    </Flex>
                )}
            {(showOutdatedErrors || aws.outofdate) && (
                <Flex pt="10px" pb="5px" ml="-8px" justifyContent="center">
                    <ErrorText>
                        There has been an issue with the connection between
                        PreFlight and MetService. This <strong>AWS</strong>{' '}
                        information was last validated with MetService more than{' '}
                        <strong>
                            {formatDistanceToNow(parseISO(aws.updated), {
                                addSuffix: true,
                            })}
                        </strong>
                        .
                        <br />
                        There is a high likelihood that the latest AWS
                        information is not available in PreFlight. Please check
                        back again soon.
                    </ErrorText>
                </Flex>
            )}
            <Flex flexDirection="column" width="100%" pt="10px">
                {awsIssuedTime && (
                    <Box w="100%" pl={0} pr={0}>
                        <Table
                            // {...tabSwipeHeader}
                            size="sm"
                            fontFamily="Open Sans"
                            marginTop="1"
                            marginBottom="3"
                        >
                            <Tbody verticalAlign="baseline">
                                <Tr>
                                    <Td
                                        lineHeight="1.2"
                                        paddingStart="0"
                                        paddingEnd="1"
                                        fontWeight="bold"
                                        width={calculateContainerSize(115)}
                                        minWidth={calculateContainerSize(115)}
                                        maxWidth={calculateContainerSize(115)}
                                        fontSize={calculateFontSize(0.8)}
                                    >
                                        Observation Time:
                                    </Td>

                                    <Td
                                        lineHeight="1.2"
                                        width="100%"
                                        fontSize={calculateFontSize(0.8)}
                                    >
                                        {awsIssuedTime &&
                                            formatDateTime(
                                                formatISO(awsIssuedTime),
                                                timeZone
                                            )}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                )}
                {hasData && (
                    <Box
                        w="100%"
                        pl={0}
                        pr={0}
                        overflowX="auto"
                        id="awsContentScreen"
                        className="scrollHorizontal"
                    >
                        <OverlayScrollbarsComponent
                            options={{
                                scrollbars: {
                                    visibility: 'auto',
                                    autoHide: 'never',
                                    autoHideDelay: 800,
                                    dragScrolling: true,
                                    clickScrolling: true,
                                    touchSupport: true,
                                },
                                overflow: {
                                    x: 'scroll',
                                    y: 'hidden',
                                },
                            }}
                            style={{
                                paddingBottom: '10px',
                                height: '100%',
                                maxWidth: awsContentWidth,
                            }}
                        >
                            <Table
                                borderColor="gray.300"
                                borderTopWidth="1px"
                                size="sm"
                                variant="simple"
                                colorScheme="gray"
                                fontFamily="Open Sans"
                                marginTop="1"
                                paddingTop="3"
                            >
                                <Tbody verticalAlign="baseline">
                                    <Tr>
                                        <Td
                                            lineHeight="1.2"
                                            paddingStart="0"
                                            paddingEnd="1"
                                            fontWeight="bold"
                                            width={calculateContainerSize(115)}
                                            minWidth={calculateContainerSize(
                                                115
                                            )}
                                            maxWidth={calculateContainerSize(
                                                115
                                            )}
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            Surface Wind:
                                        </Td>
                                        <Td
                                            lineHeight="1.2"
                                            width="100%"
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            {winddir ? (
                                                <Flex
                                                    flexDirection={{
                                                        base: 'column',
                                                        sm: 'row',
                                                        md: 'column',
                                                        ml: 'row',
                                                    }}
                                                    gap={{
                                                        base: '1px',
                                                        sm: '15px',
                                                        md: '1px',
                                                        ml: '15px',
                                                    }}
                                                >
                                                    <Flex gap="7px">
                                                        <Text>
                                                            {winddir
                                                                .toString()
                                                                .padStart(
                                                                    3,
                                                                    '0'
                                                                ) + '°T '}
                                                        </Text>
                                                        <Text>
                                                            {` ${windspeed} kt`}
                                                        </Text>
                                                    </Flex>
                                                    <Text>
                                                        {windgust
                                                            ? 'Gusts ' +
                                                              windgust +
                                                              ' kt'
                                                            : ''}
                                                    </Text>
                                                    <Text>
                                                        {winddirmin &&
                                                        winddirmax
                                                            ? winddirmin
                                                                  .toString()
                                                                  .padStart(
                                                                      3,
                                                                      '0'
                                                                  ) +
                                                              '°' +
                                                              ' V ' +
                                                              winddirmax
                                                                  .toString()
                                                                  .padStart(
                                                                      3,
                                                                      '0'
                                                                  ) +
                                                              '°T'
                                                            : ''}
                                                    </Text>
                                                </Flex>
                                            ) : (
                                                <Text>No wind reported</Text>
                                            )}
                                        </Td>
                                    </Tr>

                                    {visibility && (
                                        <Tr>
                                            <Td
                                                lineHeight="1.2"
                                                paddingStart="0"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width={calculateContainerSize(
                                                    115
                                                )}
                                                minWidth={calculateContainerSize(
                                                    115
                                                )}
                                                maxWidth={calculateContainerSize(
                                                    115
                                                )}
                                                fontSize={calculateFontSize(
                                                    0.8
                                                )}
                                            >
                                                Visibility:
                                            </Td>
                                            <Td
                                                lineHeight="1.2"
                                                width="100%"
                                                fontSize={calculateFontSize(
                                                    0.8
                                                )}
                                            >
                                                {visibility === 9999
                                                    ? '> ' + visibility + ' m'
                                                    : visibility + ' km'}
                                            </Td>
                                        </Tr>
                                    )}

                                    {cloudLayers && cloudPresent && (
                                        <Tr>
                                            <Td
                                                lineHeight="1.2"
                                                paddingStart="0"
                                                paddingEnd="1"
                                                fontWeight="bold"
                                                width={calculateContainerSize(
                                                    115
                                                )}
                                                minWidth={calculateContainerSize(
                                                    115
                                                )}
                                                maxWidth={calculateContainerSize(
                                                    115
                                                )}
                                                fontSize={calculateFontSize(
                                                    0.8
                                                )}
                                            >
                                                Sky Condition:
                                            </Td>
                                            <Td
                                                lineHeight="1.2"
                                                width="100%"
                                                fontSize={calculateFontSize(
                                                    0.8
                                                )}
                                            >
                                                <Flex
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    alignItems="baseline"
                                                    width="100%"
                                                    gap="5px"
                                                >
                                                    {cloudLayers &&
                                                        cloudLayers.length >
                                                            0 &&
                                                        cloudLayers
                                                            .reverse()
                                                            .map((layer, i) => {
                                                                return (
                                                                    <Flex
                                                                        direction="row"
                                                                        key={`InfoContentCloud${i}`}
                                                                        justifyContent="flex-start"
                                                                        alignItems="baseline"
                                                                        width="100%"
                                                                        gap="5px"
                                                                    >
                                                                        {layer.amount && (
                                                                            <Text whiteSpace="pre-line">
                                                                                {
                                                                                    layer.amount
                                                                                }
                                                                            </Text>
                                                                        )}
                                                                        {layer.value && (
                                                                            <Text whiteSpace="pre-line">
                                                                                {
                                                                                    layer.value
                                                                                }{' '}
                                                                                ft
                                                                                AGL
                                                                            </Text>
                                                                        )}
                                                                    </Flex>
                                                                )
                                                            })}
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )}

                                    <Tr>
                                        <Td
                                            lineHeight="1.2"
                                            paddingStart="0"
                                            paddingEnd="1"
                                            fontWeight="bold"
                                            width={calculateContainerSize(115)}
                                            minWidth={calculateContainerSize(
                                                115
                                            )}
                                            maxWidth={calculateContainerSize(
                                                115
                                            )}
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            Temperature:
                                        </Td>
                                        <Td
                                            lineHeight="1.2"
                                            width="100%"
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            {temp
                                                ? `${temp} °C`
                                                : 'Not available'}
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td
                                            lineHeight="1.2"
                                            paddingStart="0"
                                            paddingEnd="1"
                                            fontWeight="bold"
                                            width={calculateContainerSize(115)}
                                            minWidth={calculateContainerSize(
                                                115
                                            )}
                                            maxWidth={calculateContainerSize(
                                                115
                                            )}
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            Dewpoint:
                                        </Td>
                                        <Td
                                            lineHeight="1.2"
                                            width="100%"
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            {dewpoint
                                                ? `${dewpoint} °C`
                                                : 'Not available'}
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td
                                            lineHeight="1.2"
                                            paddingStart="0"
                                            paddingEnd="1"
                                            fontWeight="bold"
                                            width={calculateContainerSize(115)}
                                            minWidth={calculateContainerSize(
                                                115
                                            )}
                                            maxWidth={calculateContainerSize(
                                                115
                                            )}
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            QNH:
                                        </Td>
                                        <Td
                                            lineHeight="1.2"
                                            width="100%"
                                            fontSize={calculateFontSize(0.8)}
                                        >
                                            {qnh
                                                ? `${qnh} hPa`
                                                : 'Not available'}
                                        </Td>
                                    </Tr>

                                    {presentWeather &&
                                        !presentWeather.startsWith('/') && (
                                            <Tr>
                                                <Td
                                                    lineHeight="1.2"
                                                    paddingStart="0"
                                                    paddingEnd="1"
                                                    fontWeight="bold"
                                                    width={calculateContainerSize(
                                                        115
                                                    )}
                                                    minWidth={calculateContainerSize(
                                                        115
                                                    )}
                                                    maxWidth={calculateContainerSize(
                                                        115
                                                    )}
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                >
                                                    Present Weather:
                                                </Td>
                                                <Td
                                                    lineHeight="1.2"
                                                    width="100%"
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                >
                                                    {presentWeatherTranslate(
                                                        presentWeather
                                                    )}
                                                </Td>
                                            </Tr>
                                        )}
                                </Tbody>
                            </Table>
                        </OverlayScrollbarsComponent>
                    </Box>
                )}
            </Flex>
        </>
    ) : (
        <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="10px"
            fontSize="0.8rem"
        >
            <Text
                textAlign="center"
                color="gray.500"
                lineHeight="1.4"
                width="100%"
                fontWeight="400"
                whiteSpace="pre-line"
                py="20px"
                px="5px"
            >
                Weather information currently unavailable
            </Text>
        </Flex>
    )
})
