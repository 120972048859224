import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function LegendIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0127 3.66595C9.45704 3.45236 8.83859 3.44469 8.27734 3.64444L3.71641 5.26767C3.28585 5.4209 3.00005 5.81547 3.00005 6.25667L3 19.2224C3 19.957 3.76191 20.4665 4.47876 20.2114L8.42982 18.8053C8.88903 18.6418 9.39503 18.6481 9.8497 18.8229L13.7813 20.334C14.328 20.5442 14.9357 20.5551 15.4902 20.3648L20.2716 18.724C20.7084 18.574 21 18.1765 21 17.7307L21 4.75736C21 4.02803 20.2481 3.51884 19.5333 3.76414L15.3426 5.20232C14.8889 5.35801 14.3917 5.34905 13.9444 5.17713L10.0127 3.66595ZM4.75609 18.3953V6.61676L8.26827 5.3418V17.0664L4.75609 18.3953ZM10.0244 17.0664V5.34213L13.7561 6.70097V18.4795L10.0244 17.0664ZM15.5122 18.4928V6.70534L19.2439 5.45975V17.2472L15.5122 18.4928Z"
                fill="currentcolor"
            />
            <path
                d="M16.1709 8.27712C16.1709 7.70055 16.5406 7.18896 17.0881 7.00804L17.7075 6.80333C18.14 6.66043 18.5855 6.98245 18.5855 7.43787V7.43787C18.5855 8.01445 18.2158 8.52604 17.6683 8.70696L17.0489 8.91167C16.6165 9.05457 16.1709 8.73255 16.1709 8.27712V8.27712Z"
                fill="currentcolor"
            />
            <path
                d="M17.0881 9.76798C16.5406 9.9489 16.1709 10.4605 16.1709 11.0371V11.0371C16.1709 11.4925 16.6165 11.8145 17.0489 11.6716L17.6683 11.4669C18.2158 11.286 18.5855 10.7744 18.5855 10.1978V10.1978C18.5855 9.74239 18.14 9.42037 17.7075 9.56327L17.0881 9.76798Z"
                fill="currentcolor"
            />
            <path
                d="M18.5855 12.9578C18.5855 12.5023 18.14 12.1803 17.7075 12.3232L17.0881 12.5279C16.5406 12.7088 16.1709 13.2204 16.1709 13.797V13.797C16.1709 14.2524 16.6165 14.5744 17.0489 14.4315L17.6683 14.2268C18.2158 14.0459 18.5855 13.5343 18.5855 12.9578V12.9578Z"
                fill="currentcolor"
            />
            <path
                d="M18.5855 15.7177C18.5855 15.2623 18.14 14.9403 17.7075 15.0832L17.0881 15.2879C16.5406 15.4688 16.1709 15.9804 16.1709 16.5569V16.5569C16.1709 17.0124 16.6165 17.3344 17.0489 17.1915L17.6683 16.9868C18.2158 16.8059 18.5855 16.2943 18.5855 15.7177V15.7177Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
