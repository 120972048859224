import MapboxDraw from '@mapbox/mapbox-gl-draw'
import buffer from '@turf/buffer'
import combine from '@turf/combine'

const Constants = MapboxDraw.constants
const { doubleClickZoom } = MapboxDraw.lib

const BriefingRouteMode = { ...MapboxDraw.modes.draw_line_string }

const BriefingRouteStyle = [
    {
        id: 'gl-draw-briefing_route-vertex',
        type: 'circle',
        filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'mode', 'briefing_route'],
        ],
        paint: {
            'circle-radius': 7,
            'circle-color': '#FF00FF',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#FFFFFF',
        },
    },
    {
        id: 'gl-draw-briefing_route-active',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'user_active', true],
            ['==', 'mode', 'briefing_route'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#FF00FF',
            'line-dasharray': [0.5, 2],
            'line-width': 3,
        },
    },
    {
        id: 'gl-draw-briefing_route-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'user_active', false],
            ['==', 'mode', 'briefing_route'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#FF00FF',
            'line-width': 2,
        },
    },
]

const dragPan = {
    enable: (ctx) => {
        setTimeout(() => {
            // First check we've got a map and some context.
            if (
                !ctx.map ||
                !ctx.map.dragPan ||
                !ctx._ctx ||
                !ctx._ctx.store ||
                !ctx._ctx.store.getInitialConfigValue
            ) {
                return
            }
            // Now check initial state wasn't false (we leave it disabled if so)
            if (!ctx._ctx.store.getInitialConfigValue('dragPan')) return
            ctx.map.dragPan.enable()
        }, 0)
    },
    disable(ctx) {
        setTimeout(() => {
            if (!ctx.map || !ctx.map.dragPan) return
            // Always disable here, as it's necessary in some cases.
            ctx.map.dragPan.disable()
        }, 0)
    },
}

const touchZoomRotate = {
    enable: (ctx) => {
        setTimeout(() => {
            // First check we've got a map and some context.
            if (
                !ctx.map ||
                !ctx.map.touchZoomRotate ||
                !ctx._ctx ||
                !ctx._ctx.store ||
                !ctx._ctx.store.getInitialConfigValue
            ) {
                return
            }
            // Now check initial state wasn't false (we leave it disabled if so)
            if (!ctx._ctx.store.getInitialConfigValue('touchZoomRotate')) return
            ctx.map.touchZoomRotate.enable()
        }, 0)
    },
    disable(ctx) {
        setTimeout(() => {
            if (!ctx.map || !ctx.map.touchZoomRotate) return
            // Always disable here, as it's necessary in some cases.
            ctx.map.touchZoomRotate.disable()
        }, 0)
    },
}

BriefingRouteMode.onSetup = function (opts) {
    this.clearSelectedFeatures()
    doubleClickZoom.disable(this)
    dragPan.disable(this)
    touchZoomRotate.disable(this)
    //disable clicking of aerodrome icons
    this.updateUIClasses({ mouse: Constants.cursors.ADD })

    var leg = {}
    leg = this.newFeature({
        type: 'Feature',
        properties: {
            active: true,
        },
        geometry: {
            type: 'LineString',
            coordinates: [],
        },
    })
    this.addFeature(leg)

    var area = {}
    area = this.newFeature({
        type: 'Feature',
        properties: {
            type: 'customRoute',
        },
        geometry: {
            type: 'Polygon',
            coordinates: [],
        },
    })
    this.addFeature(area)

    return {
        legs: [leg],
        area: area,
        lastClick: null,
        keyPress: null,
    }
}

// Whenever a user clicks on the map, Draw will call `onClick` Expand this to setInnerHtml of div when it has been clicked.
BriefingRouteMode.onClick = function (state, e) {
    // simulate double clicking to end drawing
    // if last click was less than 300ms ago
    if (state.lastClick && Date.now() - state.lastClick < 300) {
        return this.changeMode('static')
    }
    state.lastClick = Date.now()

    const clickedCoords = [e.lngLat.lng, e.lngLat.lat]

    const vertexPoint = this.newFeature({
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Point',
            coordinates: [],
        },
    })

    vertexPoint.coordinates = clickedCoords
    this.addFeature(vertexPoint)

    const lastLeg = state.legs[state.legs.length - 1]

    // add first point to first line
    if (lastLeg.coordinates.length === 0) {
        lastLeg.addCoordinate(
            lastLeg.coordinates.length,
            clickedCoords[0],
            clickedCoords[1]
        )
    } else {
        // finish off last line, and make new line with start coord of last line

        // add end coord
        lastLeg.addCoordinate(
            lastLeg.coordinates.length,
            clickedCoords[0],
            clickedCoords[1]
        )

        // remove coord from mouseMove (if exists)
        if (lastLeg.coordinates.length > 2) {
            lastLeg.removeCoordinate(2)
        }

        // set prev leg to inactive
        lastLeg.properties.active = false

        const newLeg = this.newFeature({
            type: 'Feature',
            properties: {
                active: true,
            },
            geometry: {
                type: 'LineString',
                coordinates: [],
            },
        })
        newLeg.addCoordinate(0, clickedCoords[0], clickedCoords[1])
        this.addFeature(newLeg)
        state.legs.push(newLeg)
    }
}

BriefingRouteMode.onTrash = function (state) {
    this.deleteFeature([state.rectangle.id], {
        silent: true,
    })
    this.changeMode('static')
}

BriefingRouteMode.onMouseMove = function (state, e) {
    this.updateUIClasses({ mouse: 'add' })

    // only do things if already clicked to start drawing the line
    if (state.legs[0].coordinates.length > 0) {
        const mouseCoord = [e.lngLat.lng, e.lngLat.lat]
        const lastLeg = state.legs[state.legs.length - 1]

        lastLeg.updateCoordinate(1, ...mouseCoord)

        const legGeoJSON = {
            type: 'FeatureCollection',
            features: state.legs.map((leg) => ({
                type: 'Feature',
                properties: {},
                geometry: { type: 'LineString', coordinates: leg.coordinates },
            })),
        }

        state.area.incomingCoords(
            buffer(
                combine(legGeoJSON),
                document.getElementsByName('customBriefingRouteBuffer')[0]
                    .value,
                {
                    units: 'nauticalmiles',
                }
            ).features[0].geometry.coordinates
        )
    }
}

// Whenever a user clicks on a key while focused on the map, it will be sent here
BriefingRouteMode.onKeyUp = function (state, e) {
    // Enter Key
    if (e.keyCode === 13) {
        state.keyPress = 'Enter'
        return this.changeMode('static')
    }
    // Esc Key
    if (e.keyCode === 27) {
        state.keyPress = 'Esc'
        return this.changeMode('static')
    }
}
BriefingRouteMode.onMouseDown = function (state, e) {
    // on first click, save clicked point coords as starting for  rectangle
    const startPoint = [e.lngLat.lng, e.lngLat.lat]
    state.startPoint = startPoint
}

BriefingRouteMode.onMouseUp = function (state, e) {
    this.onClick(state, e)
}
// support mobile taps
BriefingRouteMode.onTouchStart = function (state, e) {
    this.onMouseDown(state, e)
}

BriefingRouteMode.onTouchMove = function (state, e) {
    this.onMouseMove(state, e)
}

BriefingRouteMode.onDrag = function (state, e) {
    this.onMouseMove(state, e)
}

BriefingRouteMode.onTouchEnd = function (state, e) {
    this.onMouseUp(state, e)
    this.onMouseMove(state, e)
}

BriefingRouteMode.onTap = function (state, e) {
    this.onMouseMove(state, e)
    this.onClick(state, e)
}

// This is the only required function for a mode.
// It decides which features currently in Draw's data store will be rendered on the map.
// All features passed to `display` will be rendered, so you can pass multiple display features per internal feature.
// See `styling-draw` in `API.md` for advice on making display features
BriefingRouteMode.toDisplayFeatures = function (state, geojson, display) {
    display(geojson)
}

BriefingRouteMode.onStop = function (state) {
    doubleClickZoom.enable(this)
    dragPan.enable(this)
    touchZoomRotate.enable(this)
    this.updateUIClasses({ mouse: 'none' })

    // pass through a bogus feature to cancel draw mode
    if (state.keyPress === 'Esc') {
        this.map.fire('draw.create', {
            features: [{ properties: { cancel: true }, geometry: {} }],
        })
    } else {
        this.map.fire('draw.create', { features: [state.area.toGeoJSON()] })
    }
}

export { BriefingRouteMode, BriefingRouteStyle }
