import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import {
    Center,
    Divider,
    SimpleGrid,
    Image,
    Modal,
    Flex,
    Box,
    Spinner,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Text,
    LightMode,
} from '@chakra-ui/react'
import { MdErrorOutline } from 'react-icons/md'

import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../../globalState'
import buildAerodromeIcon from '../../icons/dynamic/buildAerodromeIcon'

export default function AerodromeSpritesModal() {
    const { getAccessTokenSilently } = useAuth0()
    const fetchAerodromeSprites = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/aerodromes`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const [aerodromes, setAerodromes] = useState('')
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'aerodromeSprites', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        modalVisibility.aerodromeSprites ? onOpen() : onClose()
    }, [modalVisibility.aerodromeSprites, onOpen, onClose])

    function onCloseHandler() {
        setShow(false)
        onClose()
    }

    const { data, status } = useQuery(
        'aerodromeSprites',
        fetchAerodromeSprites,
        { refetchOnWindowFocus: false }
    )

    useEffect(() => {
        if (data) {
            setAerodromes(data.features.map((feat) => feat.properties))
        }
    }, [setAerodromes, data])

    if (aerodromes) {
        const Content = () => {
            if (status !== 'error' && status !== 'loading' && data) {
                return (
                    <>
                        {aerodromes &&
                            aerodromes.map((aerodrome) => {
                                const { type, flightrule, rwyazimuth, notam } =
                                    aerodrome

                                return (
                                    <Flex
                                        key={aerodrome.designator}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        _hover={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Image
                                            w="30px"
                                            src={`data:image/svg+xml;base64,${buildAerodromeIcon(
                                                type,
                                                flightrule,
                                                rwyazimuth,
                                                notam
                                            )}`}
                                            onClick={() =>
                                                console.log(aerodrome)
                                            }
                                            alt={
                                                aerodrome.designator
                                                    ? aerodrome.designator
                                                    : ''
                                            }
                                        />
                                        <Text
                                            color={
                                                aerodrome.flightrule === 'IFR'
                                                    ? '#92278f'
                                                    : '#0072bc'
                                            }
                                            fontSize="0.7rem"
                                            fontWeight="bolder"
                                            position="relative"
                                            pb="2"
                                            top="-2px"
                                        >
                                            {aerodrome.designator}
                                        </Text>
                                    </Flex>
                                )
                            })}
                    </>
                )
            } else if (status === 'loading') {
                return (
                    <Center h="100%" w="100%">
                        <Spinner />
                    </Center>
                )
            } else if (status === 'error') {
                return (
                    <Center h="100%" w="100%">
                        <Flex
                            direction="column"
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <MdErrorOutline color="red" size="70px" />
                            <Text pt="10px" size="md" color="red">
                                Error fetching Runways
                            </Text>
                        </Flex>
                    </Center>
                )
            }
        }

        return (
            <>
                <LightMode>
                    <Modal
                        variant="dataStatus"
                        size="4xl"
                        isCentered
                        onClose={onCloseHandler}
                        isOpen={isOpen}
                        motionPreset="slideInBottom"
                        scrollBehavior="inside"
                        closeOnOverlayClick={true}
                        trapFocus={false}
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalCloseButton color="white" />
                            <ModalHeader
                                textAlign="center"
                                backgroundColor="#993A97"
                                color="white"
                                letterSpacing="0.5px"
                            >
                                Dynamic Aerodrome Sprites
                            </ModalHeader>
                            <ModalBody style={{ padding: 0 }}>
                                <Box>
                                    <Box w="100%" p="25px" pt="35px">
                                        <SimpleGrid
                                            minChildWidth="50px"
                                            spacing="5px"
                                        >
                                            <Content />
                                        </SimpleGrid>
                                        <Divider pt="20px" w="100%" />
                                        <Box w="100%">
                                            <Text fontSize="0.9rem" py="15px">
                                                The sprites above are
                                                dynamically generated from the
                                                aerodrome geojson data.
                                            </Text>
                                            <Text fontSize="0.9rem" pb="px">
                                                Click to console.log the
                                                features properties
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </ModalBody>

                            <ModalFooter
                                pt={3.5}
                                pb={4}
                                borderTop="1px solid #eaeaea"
                            >
                                <Flex
                                    width="100%"
                                    borderRadius="10px"
                                    border="1px solid"
                                    borderColor=" #e5e5e5"
                                    overflow="hidden"
                                    mx={{ base: 'auto', md: '25px' }}
                                >
                                    <Button
                                        w="100%"
                                        size="sm"
                                        variant="primary"
                                        color="dark.30"
                                        backgroundColor="light.40"
                                        _hover={{
                                            backgroundColor: 'light.30',
                                        }}
                                        _active={{
                                            backgroundColor: 'light.30',
                                        }}
                                        borderRadius={5}
                                        onClick={onCloseHandler}
                                    >
                                        Close
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </LightMode>
            </>
        )
    }

    return <Spinner />
}
