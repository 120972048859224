import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function PrecipitationIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M22.0002 11.0053C22.0002 12.1863 21.5458 13.2611 20.8026 14.0642C20.4885 14.4036 20.1228 14.6944 19.7181 14.9243L19 13.6057C19.8967 13.0867 20.5 12.1165 20.5 11.0053C20.5 9.39215 19.123 8.07616 17.625 8.00625C17.5954 8.00487 17.5658 8.00398 17.5362 8.00358L17.5 8.00333C17.4752 8.00333 17.4505 8.00363 17.4259 8.00423C16.5672 8.0251 15.798 8.40701 15.264 9.00401L14.1563 7.99191C14.6065 7.49192 15.1672 7.09343 15.7998 6.83489C14.9336 5.43774 13.3735 4.501 11.75 4.501C9.21051 4.501 7.13647 6.49518 7.00647 9.00399H6C4.61929 9.00399 3.5 10.124 3.5 11.5057C3.5 12.591 4.19076 13.515 5.15637 13.8613L4.58323 15.25C3.07338 14.6776 2 13.2171 2 11.5057C2 9.38042 3.65521 7.64204 5.746 7.51094C6.50027 4.90511 8.9028 3 11.75 3C14.0505 3 16.2543 4.4295 17.3251 6.50567C17.3795 6.50359 17.4342 6.50247 17.489 6.50234L17.5 6.50233C19.8182 6.50233 22.0002 8.51839 22.0002 11.0053Z"
                fill="currentColor"
            />
            <path
                d="M17.5 12.5C17.5 12.9142 17.1642 13.25 16.75 13.25C16.3358 13.25 16 12.9142 16 12.5C16 12.3145 16.0673 12.1448 16.1789 12.0138C16.2635 11.9145 17.5 10.5 17.5 10.5V12.5Z"
                fill="currentColor"
            />
            <path
                d="M12.5 15.1667C13.0523 15.1667 13.5 14.719 13.5 14.1667V11.5C13.5 11.5 11.8514 13.386 11.7385 13.5184C11.5898 13.693 11.5 13.9194 11.5 14.1667C11.5 14.719 11.9477 15.1667 12.5 15.1667Z"
                fill="currentColor"
            />
            <path
                d="M7.75 17.0833C8.44036 17.0833 9 16.5237 9 15.8333V12.5C9 12.5 6.93923 14.8575 6.79816 15.023C6.61223 15.2413 6.5 15.5242 6.5 15.8333C6.5 16.5237 7.05964 17.0833 7.75 17.0833Z"
                fill="currentColor"
            />
            <path
                d="M14.75 19.0833C15.4404 19.0833 16 18.5237 16 17.8333V14.5C16 14.5 13.9392 16.8575 13.7982 17.023C13.6122 17.2413 13.5 17.5242 13.5 17.8333C13.5 18.5237 14.0596 19.0833 14.75 19.0833Z"
                fill="currentColor"
            />
            <path
                d="M11.5 19.8333C11.5 20.5237 10.9404 21.0833 10.25 21.0833C9.55964 21.0833 9 20.5237 9 19.8333C9 19.5242 9.11223 19.2413 9.29816 19.0231C9.43923 18.8575 11.5 16.5 11.5 16.5V19.8333Z"
                fill="currentColor"
            />
        </Icon>
    )
}
