import { recoilPersist } from 'recoil-persist'
import { isAfter } from 'date-fns'
import { omit } from 'lodash'
import { isEqual } from 'lodash'

const { atom, selector } = require('recoil')

const { persistAtom } = recoilPersist({
    key: 'recoil-persist',
    storage: localStorage,
})

export const mfaVerifiedState = atom({
    key: 'mfaVerifiedState',
    default: false,
})

export const mfaHandlerState = atom({
    key: 'mfaHandlerState',
    default: { state: 'NONE', days: 14 }, // days
})

export const windowIdle = atom({
    key: 'windowIdle',
    default: false,
})

export const mapGlLostState = atom({
    key: 'mapGlLostState',
    default: false,
})

export const userProfileState = atom({
    key: 'userProfileState',
    default: null,
})

// Waits until Auth0 is loaded then sets true.
export const pubNubMountedState = atom({
    key: 'pubNubMountedState',
    default: false,
})

export const sliderPlayingState = atom({
    key: 'sliderPlayingState',
    default: false,
})

export const sliderLoopingState = atom({
    key: 'sliderLoopingState',
    default: false,
})

// block certain actions until login messages have been checked
export const loginMessagesCheckedState = atom({
    key: 'loginMessagesCheckedState',
    default: false,
})
export const loginMessagesState = atom({
    key: 'loginMessagesState',
    default: [],
})

export const modalVisibilityState = atom({
    key: 'modalState',
    default: {
        loginMessage: false,
        viewLoginMessage: false,
        dataStatus: false,
        userReport: false,
        aerodromeSprites: false,
        runways: false,
        pdf: false,
        profile: false,
        premiumOnly: true,
        premiumOnlyForwarded: false,
        pricingTiers: false,
        onlineUsers: false,
        faqs: false,
        deviceDetails: false,
        deviceWarning: false,
        deviceRequirements: false,
        about: false,
        announcements: false,
        terms: false,
        statusError: false,
        error: false,
        legend: false,
        resetMfa: false,
        whyMfa: false,
        mctEctDisclaimer: false,
        specialNotice: false,
        contactus: false,
    },
})

export const setModalVisibilityState = selector({
    key: 'setModalVisibilityState',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { id, value } = obj
        // console.log({ id, value })
        const visibility = get(modalVisibilityState)
        const val = visibility[id]
        if (id && value !== undefined) {
            value !== val &&
                set(modalVisibilityState, {
                    ...visibility,
                    [id]: value,
                })
        } else {
            console.warn(
                'setModalVisibilityState: Please specify an id and value.'
            )
        }
    },
})

export const pdfViewerContentState = atom({
    key: 'pdfViewerContentState',
    default: null,
})

export const showOnlineUsersButtonState = atom({
    key: 'showOnlineUsersButtonState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

export const rainRadarSelection = atom({
    key: 'rainRadarSelection',
    default: null,
    effects_UNSTABLE: [persistAtom],
})

// This sets the decoded weather text to default when enabled by the user in their Profile settings (Premium only).
export const decodedTextDefaultState = atom({
    key: 'decodedTextDefaultState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

// This allows a user to disable the zoom-to function when selecting an aerodrome.
export const zoomToActiveState = atom({
    key: 'zoomToActiveState',
    default: true,
    effects_UNSTABLE: [persistAtom],
})

// This allows a user to disable the fly-to function when selecting a fature on the map.
export const flyToActiveState = atom({
    key: 'flyToActiveState',
    default: true,
    effects_UNSTABLE: [persistAtom],
})

export const compassVarState = atom({
    key: 'compassVarState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

// This drives online users button position based on search focused state.
export const searchFocusState = atom({
    key: 'searchFocusState',
    default: false,
})

// This drives altitude range position if error Badge is visible.
export const errorBadgeState = atom({
    key: 'errorBadgeState',
    default: false,
})

// This triggers rotation warning when viewed in landscape on mobile devices.
export const orientationState = atom({
    key: 'orientationState',
    default: null,
})

export const onlineState = atom({
    key: 'onlineState',
    default: navigator.onLine,
})

export const hideNetworkConnectionLostState = atom({
    key: 'hideNetworkConnectionLostState',
    default: false,
})

export const globalTimeState = atom({
    key: 'globalTimeState',
    default: Date.now(),
})

// When true standard clicking events function and when false not. Used in drawtools/index ruler.
// TODO Refactor - @josh - map/actions flyto disable when clicking is set to false. consider renaming, get consensus in slack. refer to mapclickhandler to remove briefing logic.
export const clickingState = atom({
    key: 'clickingState',
    default: true,
})

export const selectedTimeState = atom({
    key: 'selectedTimeState',
    default: Date.now(),
})

export const clickedFeaturesState = atom({
    key: 'clickedFeaturesState',
    default: [],
})

export const timeZoneState = atom({
    key: 'timeZoneState',
    default: 'UTC',
    effects_UNSTABLE: [persistAtom],
})

export const effectiveDateState = atom({
    key: 'effectiveDateState',
    default: null,
})

export const showErrorTogglesState = atom({
    key: 'showErrorTogglesState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

// TODO - remove when User Tiers initiated
export const simulateFreeUserState = atom({
    key: 'simulateFreeUserState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

export const mapBoundsState = atom({
    key: 'mapBoundsState',
    default: [
        // NZZO
        [154, -90],
        [235, -2],
        // replace with below if limiting to NZZC
        // NZZC
        // [153.5, -50],
        // [193.5, -30],
    ],
})

export const showMapBoundsState = atom({
    key: 'showMapBoundsState',
    default: false,
})

// Used to set current Zoom Level Ref at time of click on an Aerodrome to zoom back to after close
export const mapZoomLevelState = atom({
    key: 'mapZoomLevelState',
    default: null,
})

export const limitMapBoundsState = atom({
    key: 'limitMapBoundsState',
    default: true,
})

export const toggleMapBoundsState = selector({
    key: 'toggleMapBoundsState',
    get: () => {},
    set: ({ set, get }) => {
        set(limitMapBoundsState, !get(limitMapBoundsState))
        set(showMapBoundsState, !get(showMapBoundsState))
    },
})

// Dynamic Times (All tiles that use WxTiles API and Rain Radar)
export const selectedWxTileTimesState = atom({
    key: 'selectedWxTileTimesState',
    default: [],
})

// This is a blocking state that waits until these are loaded before other layers load.
export const mapLoadingAerodromeIconsState = atom({
    key: 'mapLoadingAerodromeIconsState',
    default: true,
})

// This is a blocking state that waits until these are loaded before other layers load.
export const mapLoadingStaticIconsState = atom({
    key: 'mapLoadingStaticIconsState',
    default: true,
})

export const settingsMenuOpenState = atom({
    key: 'settingsMenuOpenState',
    default: false,
})

// This drives UI changes on the altitude selector.
export const altitudeModeState = atom({
    key: 'altitudeMode',
    default: 'double',
})

export const selectedAltitudeState = atom({
    key: 'selectedAltitudeState',
    default: 20,
})

export const selectedLowerAltitudeState = atom({
    key: 'selectedLowerAltitudeState',
    default: 20,
})

export const selectedUpperAltitudeState = atom({
    key: 'selectedUpperAltitudeState',
    default: 10000,
})

export const dataModeState = atom({
    key: 'dataModeState',
    default: 'aero',
})

// TODO Refactor - Config - refer to source-status-handler and define-datamode-layer-sources. drive changes from dataStatus and selector / constructor. This will change once we introduce config driven state. Transform this after config has been realised.
export const dataModeLayerSourcesState = atom({
    key: 'dataModeLayerSourcesState',
    default: null,
})

// This is driven by config.js and initialised in App.js
export const layerSelectionState = atom({
    key: 'layerSelectionState',
    default: null,
})

export const sigWxDataState = atom({
    key: 'sigWxDataState',
    default: { id: 'default', type: 'FeatureCollection', features: [] },
})

// This tells us a briefing has been created, so when navigating away from BRIEF mode, the briefing remains or otherwise is cleared out
export const briefingCreatedState = atom({
    key: 'briefingCreatedState',
    default: false,
})

// Sets layer selection setting.
export const layerSelectionHandler = selector({
    key: 'layerSelectionHandler',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { mode, layer } = obj
        set(layerSelectionState, {
            ...get(layerSelectionState),
            ...(mode === 'met'
                ? {
                      met: {
                          ...Object.fromEntries(
                              Object.keys(get(layerSelectionState).met).map(
                                  (key) => [key, false]
                              )
                          ),
                          [layer]: true,
                      }, // for met mode turn off all other layers - then set selected layer to true
                  }
                : {
                      [mode]: {
                          ...get(layerSelectionState)[mode],
                          [layer]: !get(layerSelectionState)[mode][layer],
                      },
                  }),
        })
    },
})

// Here we do not want to handle the click of items from the search the same as in the sidebar
export const layerSelectionSearchHandler = selector({
    key: 'layerSelectionSearchHandler',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { mode, layer } = obj
        const selection = get(layerSelectionState)
        if (!selection[mode][layer]) {
            set(layerSelectionState, {
                ...selection,
                ...(mode === 'met'
                    ? {
                          met: {
                              ...Object.fromEntries(
                                  Object.keys(get(layerSelectionState).met).map(
                                      (key) => [key, false]
                                  )
                              ),
                              [layer]: true,
                          }, // for met mode turn off all other layers - then set selected layer to true
                      }
                    : {
                          [mode]: {
                              ...get(layerSelectionState)[mode],
                              [layer]: !get(layerSelectionState)[mode][layer],
                          },
                      }),
            })
        }
    },
})

export const handleQueryLayers = selector({
    key: 'handleQueryLayers',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { mode, layers } = obj
        const state = get(layerSelectionState)
        let layerInfo = state[mode]
        mode === 'met'
            ? Object.keys(layerInfo).forEach(
                  (layer) =>
                      (layerInfo =
                          layer === layers[0]
                              ? { ...layerInfo, [layer]: true }
                              : { ...layerInfo, [layer]: false })
              )
            : Object.keys(layerInfo).forEach(
                  (layer) =>
                      (layerInfo = layers.includes(layer)
                          ? { ...layerInfo, [layer]: true }
                          : { ...layerInfo, [layer]: false })
              )

        set(layerSelectionState, {
            ...get(layerSelectionState),
            [mode]: layerInfo,
        })
    },
})

// state of subfolder menu open or closed
export const menuExpandedState = atom({
    key: 'menuExpandedState',
    default: { aero: false, met: false, advs: false, brief: false },
})

export const setMenuExpandedState = selector({
    key: 'setMenuExpandedState',
    get: () => {},
    set: ({ set, get }, obj) => {
        const menuExpanded = get(menuExpandedState)
        set(menuExpandedState, { ...menuExpanded, ...obj })
    },
})

export const closeLayerSelectorHandler = selector({
    key: 'closeLayerSelectorHandler',
    get: () => {},
    set: ({ set, get }) => {
        const menuExpanded = get(menuExpandedState)
        // set all values to false
        const newState = Object.keys(menuExpanded).reduce(
            (accumulator, key) => {
                return { ...accumulator, [key]: false }
            },
            {}
        )
        set(menuExpandedState, newState)
    },
})

// Applied to certain components to show/hide when fullscreen modals are open.
export const fullscreenModalVisibleState = atom({
    key: 'fullscreenModalVisibleState',
    default: false,
})

// TODO refactor this logic to be wedded to config.
export const fullscreenModalVisibleHandler = selector({
    key: 'fullscreenModalVisibleHandler',
    get: () => {},
    set: ({ set, get }) => {
        const dataMode = get(dataModeState)
        const layerSelection = get(layerSelectionState)
        const mapDisabled = get(mapDisabledState)

        const briefingStandard =
            dataMode === 'brief' && get(briefingStandardState)
        const briefingCustom =
            dataMode === 'brief' && get(briefingCustomAreaState)
        const mapIsDisabled = dataMode !== 'brief' && mapDisabled

        const { met } = layerSelection
        const isFullscreen =
            (dataMode === 'met' &&
                (met.rainRadarImagery ||
                    met.graforCharts ||
                    met.cloudImagery ||
                    met.sigwxCharts ||
                    met.sigmetCharts ||
                    met.mslCharts ||
                    met.spacewx ||
                    met.lowcloudforecast ||
                    met.rainforecast)) ||
            briefingStandard ||
            briefingCustom ||
            mapIsDisabled

        set(fullscreenModalVisibleState, isFullscreen)
    },
})

// TODO Refactor - Config - group with other status atoms
export const layerStatusState = atom({
    key: 'layerStatusState',
    default: {},
})

// Call this to clear old raster layer info from state, takes an object with a key of the layer, this must be called on the refresh of map times.
export const refreshRasterLayerStatus = selector({
    key: 'refreshRasterLayerStatus',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { layer } = obj
        const layerStatus = get(layerStatusState)

        function rasterSourceCheck(source, startWord, endWord) {
            if (
                source.slice(0, startWord.length) === startWord &&
                source.slice(-endWord.length) === endWord
            ) {
                return true
            } else {
                return false
            }
        }

        const omitList = Object.keys(layerStatus).filter(
            (i) =>
                rasterSourceCheck(i, `${layer}`, 'Loading') ||
                rasterSourceCheck(i, `${layer}`, 'Updated') ||
                rasterSourceCheck(i, `${layer}`, 'Error')
        )

        const filteredState = omit(layerStatus, omitList)
        set(layerStatusState, { ...filteredState })
    },
})

export const changeLayerStatusState = selector({
    key: 'changeLayerStatusState',
    get: () => {},
    set: ({ set, get }, obj) => {
        set(layerStatusState, { ...get(layerStatusState), ...obj })
    },
})

//TODO - Refactor - @Jason survey what atoms can be made local. All briefing related
// Loads first three briefing options (i.e select area or favorites)
// *** Used in Controls, Layers, Modals ***
export const briefingOptionsState = atom({
    key: 'briefingOptionsState',
    default: false,
})

export const loadingBriefingFavouriteState = atom({
    key: 'loadingBriefingFavouriteState',
    default: false,
})

export const loadFavouriteState = atom({
    key: 'loadFavouriteState',
    default: null,
})

export const currentFavouriteState = atom({
    key: 'currentFavouriteState',
    default: false,
})

export const updateExistingFavouriteState = atom({
    key: 'updateExistingFavouriteState',
    default: false,
})

export const excludedAerodromesState = atom({
    key: 'excludedAerodromesState',
    default: [],
})

export const excludedAwsState = atom({
    key: 'excludedAwsState',
    default: [],
})

export const allAerodromesDesignatorsState = atom({
    key: 'allAerodromesDesignatorsState',
    default: [],
})

export const allAwsDesignatorsState = atom({
    key: 'allAwsDesignatorsState',
    default: [],
})

export const briefingParamsState = atom({
    key: 'briefingParamsState',
    default: {
        aerodromes: true,
        aerodromesNotam: true,
        aerodromesMetar: true,
        aerodromesTaf: true,
        aerodromesAtis: true,
        aws: true,
        enroute: true,
        aaw: true,
        grafor: true,
        msl: true,
        sigmet: true,
        sigwx: true,
        spacewx: true,
    },
})

export const updatedParamsState = atom({
    key: 'updatedParamsState',
    default: {},
})

// Loads briefing area selection.
// *** Used in Controls, Layers, Modals ***
export const briefingStandardState = atom({
    key: 'briefingStandardState',
    default: false,
})

// Hides Settings button when Briefing Charts Fullscreen open.
// *** Used in Settings ***
export const briefingFullscreenChartsState = atom({
    key: 'briefingFullscreenChartsState',
    default: false,
})

// TODO Refactor - Remove and make local briefing modal.
// *** Used in Layers (BRIEF) and Modals (BRIEFING) ***
export const selectedBriefingAreasState = atom({
    key: 'selectedBriefingAreasState',
    default: [],
})

export const selectedBriefingAltitudeState = atom({
    key: 'selectedBriefingAltitudeState',
    default: null,
})

export const selectedBriefingTimeState = atom({
    key: 'selectedBriefingTimeState',
    default: null,
})

// *** Used in Layers (BRIEF) and Modals (BRIEFING) ***
export const briefingAreaSourceState = atom({
    key: 'briefingAreaSourceState',
    default: null,
})

export const briefingCustomAreaState = atom({
    key: 'briefingCustomAreaState',
    default: null,
})

export const briefingCustomAreaPointCountState = atom({
    key: 'briefingCustomAreaPointCountState',
    default: 0,
})

export const briefingDrawActionState = atom({
    key: 'briefingDrawActionState',
    default: null,
})

export const notamContentState = atom({
    key: 'notamContentState',
    default: true,
})

export const metContentState = atom({
    key: 'metContentState',
    default: true,
})

// Here we configure refresh rates, base URL and data URL for our data layers that use internal endpoints, following Josh's pattern to get MapBox to update layers.
export const VectorLayerRefreshInfo = atom({
    key: 'VectorLayerRefreshInfo',
    default: null,
})

// TODO Refactor - Config - Drive the loaded state within the relevant components such as timeslider and maploading component.
export const layersLoaded = atom({
    key: 'layersLoaded',
    default: false,
})

// Increases and decreases based on level selection to help with visibility
export const contrastState = atom({
    key: 'contrastState',
    default: 'standard',
})

export const contrastHandler = selector({
    key: 'contrastHandler',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { config } = obj
        const mode = get(dataModeState)
        let contrast = []
        for (const [key, value] of Object.entries(
            get(layerSelectionState)[mode]
        )) {
            value === true && contrast.push(config[mode][key])
        }
        const contrastMode = () => {
            if (contrast.includes('high')) {
                return 'high'
            } else if (contrast.includes('medium')) {
                return 'medium'
            } else {
                return 'standard'
            }
        }
        set(contrastState, contrastMode())
    },
})

export const statusData = atom({
    key: 'statusData',
    default: null,
})

// This selector appends any new information from the status endpoint to the statusData atom.
export const handleStatusUpdate = selector({
    key: 'handleStatusUpdate',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { data } = obj
        const status = get(statusData)
        let updatedStatus = {}
        Object.keys(data).forEach((key) => {
            if (status && status[key]) {
                updatedStatus = {
                    ...updatedStatus,
                    [key]: { ...status[key], ...data[key] },
                }
            } else {
                updatedStatus = {
                    ...updatedStatus,
                    [key]: { ...data[key] },
                }
            }
        })
        set(statusData, { ...updatedStatus })
    },
})

// This selector changes statusData values it does a check that the data it is setting is valid and has changed.
export const handleStatusChange = selector({
    key: 'handleStatusChange',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { layer, key, value } = obj
        const id = layer.toLowerCase()
        const status = get(statusData)
        // This should check if the value exists on the status object, if it does not do not set status.
        if (status && status[id] && status[id][key] !== value) {
            set(statusData, {
                ...status,
                [id]: { ...status[id], [key]: value },
            })
        }
    },
})

// This is to be called when a layer successfully loads.
export const handleLayerSuccess = selector({
    key: 'handleLayerSuccess',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { layer } = obj
        const id = layer.toLowerCase()
        const status = get(statusData)
        // This checks that it is not already setting values that already exist, preventing an unnecessary rerender.
        if (
            status[id] &&
            !isEqual(status[id], {
                ...status[id],
                mapOutdated: false,
                refreshCount: 0,
                error: [],
            })
        ) {
            set(statusData, {
                ...status,
                [id]: {
                    ...status[id],
                    mapOutdated: false,
                    refreshCount: 0,
                    error: [],
                },
            })
        }
    },
})

// This looks through any data layers that are mapOutdated and resets the url with a new time stamp
export const handleMapOutdatedCheck = selector({
    key: 'handleMapOutdatedCheck',
    get: () => {},
    set: ({ set, get }) => {
        const status = get(statusData)
        const vectorStatusData = get(VectorLayerRefreshInfo)
        const outdated = status
            ? Object.keys(status).filter(
                  (layer) => status[layer]['mapOutdated']
              )
            : []
        let updatedStatus = {}
        outdated.forEach((l) => {
            const layer = l.toLowerCase()
            const maximumReached = status[layer]['refreshCount'] >= 5
            if (status[layer]['mapOutdated']) {
                if (vectorStatusData[layer]) {
                    // Here we append a new timestamp to the URL to cause a refresh of the data.
                    set(VectorLayerRefreshInfo, {
                        ...vectorStatusData,
                        [layer]: {
                            ...vectorStatusData[layer],
                            dataURL: `${
                                vectorStatusData[layer]['baseURL']
                            }?t=${Date.now()}`,
                        },
                    })
                }
                if (!maximumReached) {
                    updatedStatus = {
                        ...updatedStatus,
                        [layer]: {
                            ...status[layer],
                            refreshCount: status[layer]['refreshCount']
                                ? status[layer]['refreshCount'] + 1
                                : 1,
                            error: Array.isArray(status[layer]['error'])
                                ? status[layer]['error'].filter(
                                      (i) => i !== 'version'
                                  )
                                : [],
                        },
                    }
                } else {
                    // When the refetch count equals the maximum of 5, throw an "version" error for the layer.
                    updatedStatus = {
                        ...updatedStatus,
                        [layer]: {
                            ...status[layer],
                            error:
                                Array.isArray(status[layer]['error']) &&
                                !status[layer]['error'].includes('version')
                                    ? [...status[layer]['error'], 'version']
                                    : ['version'],
                        },
                    }
                }
            } else {
                // If layer is not mapOutdated, remove any error or refresh count.
                updatedStatus = {
                    ...updatedStatus,
                    [layer]: {
                        ...status[layer],
                        refreshCount: 0,
                        error: Array.isArray(status[layer]['error'])
                            ? status[layer]['error'].filter(
                                  (i) => i !== 'version'
                              )
                            : [],
                    },
                }
            }
        })
        // This checks if anything has changed or is mapOutdated, if it is not status data will not be set.
        Object.keys(updatedStatus).length &&
            set(statusData, { ...status, ...updatedStatus })
    },
})

// This should check if vector layers are up-to-date and if the expected update time is after now
export const vectorLayerRefreshHandler = selector({
    key: 'vectorLayerRefreshHandler',
    get: () => {},
    set: ({ set, get }) => {
        const layerInfo = get(statusData)
        if (layerInfo) {
            let updatedInfo = {}
            const refreshInfo = get(VectorLayerRefreshInfo)
            Object.keys(layerInfo).map((layer) => {
                const id = layer.toLowerCase()
                if (refreshInfo[id]) {
                    const updateTime = layerInfo[id]['expectedupdate']
                    if (
                        updateTime &&
                        isAfter(new Date(), new Date(updateTime))
                    ) {
                        Object.assign(updatedInfo, {
                            [layer]: {
                                ...refreshInfo[id],
                                dataURL: `${
                                    refreshInfo[id]['baseURL']
                                }?t=${Date.now()}`,
                            },
                        })
                    }
                }
                return updatedInfo
            })
            set(VectorLayerRefreshInfo, {
                ...get(VectorLayerRefreshInfo),
                ...updatedInfo,
            })
        }
    },
})

// This should add an error to the array of errors for the layer.
export const layerErrorHandler = selector({
    key: 'layerErrorHandler',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { layer, type } = obj
        const id = layer.toLowerCase()
        const status = get(statusData)
        // Check that the value exists in the Status Atom before setting
        if (status[id]) {
            set(statusData, {
                ...status,
                [id]: {
                    ...status[id],
                    error: Array.isArray(status[id]['error'])
                        ? [...status[id]['error'], type]
                        : [type],
                },
            })
        }
    },
})

// This should remove the type of error from the error array.
export const removeLayerErrorHandler = selector({
    key: 'removeLayerErrorHandler',
    get: () => {},
    set: ({ set, get }, obj) => {
        const { layer, type } = obj
        const id = layer.toLowerCase()
        const status = get(statusData)
        // Check that the value exists in the Status Atom before setting
        if (status[id]) {
            if (
                !isEqual(
                    {
                        ...status,
                        [id]: {
                            ...status[id],
                            error: Array.isArray(status[id]['error'])
                                ? status[id]['error'].filter((e) => e !== type)
                                : [],
                        },
                    },
                    status
                )
            ) {
                set(statusData, {
                    ...status,
                    [id]: {
                        ...status[id],
                        error: Array.isArray(status[id]['error'])
                            ? status[id]['error'].filter((e) => e !== type)
                            : [],
                    },
                })
            }
        }
    },
})

export const statusRefreshTimeStampState = atom({
    key: 'statusRefreshTimeStampState',
    default: null,
})

// This is used to communicate with hover toast - keep.
export const altitudeDisplayVisibleState = atom({
    key: 'altitudeDisplayVisibleState',
    default: false,
})

// TODO Refactor - launch - remove before launch
export const wipMenuItemsState = atom({
    key: 'wipMenuItemsState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

// Triggers User Guided Tour
export const tourVisibilityState = atom({
    key: 'tourVisibilityState',
    default: false,
})
export const tourCompletedState = atom({
    key: 'tourCompletedState',
    default: false,
})
export const firstTimeLoggedInState = atom({
    key: 'firstTimeLoggedInState',
    default: false,
})

// Map Controls Drop-down expand state
export const mapControlsExpandState = atom({
    key: 'mapControlsExpandState',
    default: false,
})

export const pollingPaused = atom({
    key: 'pollingPaused',
    default: false,
})

// Location Toast Active State
export const locationToastActiveState = atom({
    key: 'locationToastActiveState',
    default: false,
})

// Aero Disclaimer State (used to hide visibility of messages button when aero disclaimer open)
export const displayDisclaimerState = atom({
    key: 'displayDisclaimerState',
    default: false,
})
export const displayDisclaimerShowState = atom({
    key: 'displayDisclaimerShowState',
    default: true,
})

export const oldDeviceState = atom({
    key: 'oldDeviceState',
    default: false,
})

export const oldDeviceWarningState = atom({
    key: 'oldDeviceWarningState',
    default: false,
})

export const oldDeviceWarningAcceptedState = atom({
    key: 'oldDeviceWarningAcceptedState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

// Messages Button State (used to adjust spacing between altitude slider and messages button)
export const messagesButtonState = atom({
    key: 'messagesButtonState',
    default: false,
})

// BRIEFING
export const briefingMetarCountState = atom({
    key: 'briefingMetarCount',
    default: null,
})
export const showOutdatedErrorsState = atom({
    key: 'showOutdatedErrorsState',
    default: false,
})
export const inViewPositionState = atom({
    key: 'inViewPositionState',
    default: 'aerodromesContainer',
})
export const extendedHeightState = atom({
    key: 'extendedHeightState',
    default: false,
})
export const mobileDrawerExpandedState = atom({
    key: 'mobileDrawerExpandedState',
    default: false,
})
export const setDetailsShowState = atom({
    key: 'setDetailsShowState',
    default: false,
})
export const mobileNavButtonsShowState = atom({
    key: 'mobileNavButtonsShowState',
    default: false,
})
export const excludedItemsShowState = atom({
    key: 'excludedItemsShowState',
    default: false,
})
export const excludedAerodromesWarningMessageState = atom({
    key: 'excludedAerodromesWarningMessageState',
    default: true,
})
export const excludedAwsWarningMessageState = atom({
    key: 'excludedAwsWarningMessageState',
    default: true,
})
export const collapseDropdownsState = atom({
    key: 'collapseDropdownsState',
    default: false,
})
export const altAerodromeLayoutState = atom({
    key: 'altAerodromeLayoutState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})
export const firstCloseState = atom({
    key: 'firstCloseState',
    default: true,
})

// sorts order of components in BRIEF mode
export const briefingOrderState = atom({
    key: 'briefingOrderState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})
export const aerodromesSortState = atom({
    key: 'aerodromesSortState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

// used for disabled map mode
export const aerodromesListState = atom({
    key: 'aerodromesListState',
    default: {
        sorting: { name: true, type: true, flightRule: false },
        filtering: {
            ifr: true,
            vfr: true,
            aerodromes: true,
            heliports: true,
            waterdromes: true,
        },
    },
    effects_UNSTABLE: [persistAtom],
})

// used for disabled map mode
export const aawListState = atom({
    key: 'aawListState',
    default: {
        sorting: { name: true, type: false },
    },
    effects_UNSTABLE: [persistAtom],
})

// used for disabled map mode
export const awsListState = atom({
    key: 'awsListState',
    default: {
        sorting: { name: true, type: false, flightRule: false },
        filtering: {
            ifr: true,
            vfr: true,
        },
    },
    effects_UNSTABLE: [persistAtom],
})

// used for disabled map mode
export const webcamsListState = atom({
    key: 'webcamsListState',
    default: {
        sorting: { location: true, type: false, flightRule: false },
        filtering: {
            ifr: true,
            vfr: true,
        },
    },
    effects_UNSTABLE: [persistAtom],
})

export const downloadPromiseState = atom({
    key: 'downloadPromiseState',
    default: false,
})
export const printPromiseState = atom({
    key: 'printPromiseState',
    default: false,
})
export const pdfShowState = atom({
    key: 'pdfShowState',
    default: { loading: false },
})

// Sets base map layer state
export const basemapState = atom({
    key: 'basemapState',
    default: 'standard',
    effects_UNSTABLE: [persistAtom],
})

// Sets base map layer enhance state (for VNC and LinzTopo)
export const basemapEnhanceState = atom({
    key: 'basemapEnhanceState',
    default: true,
    effects_UNSTABLE: [persistAtom],
})

// Sets the roads to display or not on Satellite basemap layer
export const displayRoadsState = atom({
    key: 'displayRoadsState',
    default: true,
    effects_UNSTABLE: [persistAtom],
})

// Sets SIGWX Level button state to allow filtering in PDF export
export const briefingSigwxLevelState = atom({
    key: 'briefingSigwxLevelState',
    default: 'fl100',
})

// Sets font size in Briefing
export const briefingFontSizeState = atom({
    key: 'briefingFontSizeState',
    default: 0.1,
    effects_UNSTABLE: [persistAtom],
})
export const fontAdjustSliderShowState = atom({
    key: 'fontAdjustSliderShowState',
    default: false,
})

export const advisoriesListExpandState = atom({
    key: 'advisoriesListExpandState',
    default: false,
})

export const advisoriesMetaDataExpandState = atom({
    key: 'advisoriesMetaDataExpandState',
    default: false,
})

export const selectDefaultMapLocationState = atom({
    key: 'selectDefaultMapLocationState',
    default: false,
})

export const fullscreenHandlerState = atom({
    key: 'fullscreenHandlerState',
    default: null,
})

export const bypassLandingPageState = atom({
    key: 'bypassLandingPageState',
    default: true,
})

export const metarFixFirstColumnState = atom({
    key: 'metarFixFirstColumnState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

export const tafFixFirstColumnState = atom({
    key: 'tafFixFirstColumnState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

export const atisFixFirstColumnState = atom({
    key: 'atisFixFirstColumnState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

export const isBriefingOnlyState = atom({
    key: 'isBriefingOnlyState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

export const mapDisabledState = atom({
    key: 'mapDisabledState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})
export const mapDisabledUpdatedState = atom({
    key: 'mapDisabledUpdatedState',
    default: false,
})

export const aawFixFirstColumnState = atom({
    key: 'aawFixFirstColumnState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})
export const aawReverseOrderState = atom({
    key: 'aawReverseOrderState',
    default: false,
    effects_UNSTABLE: [persistAtom],
})

// BRIEFING FAVOURITES
export const showFavouritesState = atom({
    key: 'showFavouritesState',
    default: false,
})

export const showManageFavouritesState = atom({
    key: 'showManageFavouritesState',
    default: false,
})

export const favouriteTitleState = atom({
    key: 'favouriteTitleState',
    default: '',
})

export const newFavouriteTitleState = atom({
    key: 'newFavouriteTitleState',
    default: '',
})

export const newFavouriteNoteState = atom({
    key: 'newFavouriteNoteState',
    default: '',
})

export const favouritesListState = atom({
    key: 'favouritesListState',
    default: [],
})

export const sortedFavouritesListState = atom({
    key: 'sortedFavouritesListState',
    default: [],
})

export const hasErroneousDesignatorsState = atom({
    key: 'hasErroneousDesignatorsState',
    default: false,
})

export const deleteExistingFavouriteState = atom({
    key: 'deleteExistingFavouriteState',
    default: false,
})

export const deletedFavouritesState = atom({
    key: 'deletedFavouritesState',
    default: [],
})

export const currentUrlState = atom({
    key: 'currentUrlState',
    default: false,
})

export const selectAerodromesState = atom({
    key: 'selectAerodromesState',
    default: false,
})

export const selectAwsState = atom({
    key: 'selectAwsState',
    default: false,
})

export const updatingExcludedItemsState = atom({
    key: 'updatingExcludedItemsState',
    default: false,
})

export const updateMainDetailsState = atom({
    key: 'updateMainDetailsState',
    default: false,
})

export const usingUsnoDataState = atom({
    key: 'usingUsnoDataState',
    default: false,
})

export const rainRadarRangeRingState = atom({
    key: 'rainRadarRangeRingState',
    default: 'km',
    effects_UNSTABLE: [persistAtom],
})

export const daylighTableSourceState = atom({
    key: 'daylighTableSourceState',
    default: false,
})
