import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

import { useSwipeable } from 'react-swipeable'
import WindBarbsCollectionIcon from '../../../../../icons/menu-icons/WindBarbsCollectionIcon'

export default function LowCloudForecastLegend(props) {
    const { setDbzShow } = props

    const smallScreen = window.innerWidth < 430
    const legendSwipe = useSwipeable({
        onSwipedLeft: () => {
            setDbzShow(true)
        },
        onSwipedRight: () => {
            setDbzShow(false)
        },
    })

    // MOBILE RADAR INFO SHOW BY SWIPE DOWN
    return (
        <>
            <Flex
                {...legendSwipe}
                className="octa-scale"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column-reverse', lg: 'row' }}
            >
                <Flex
                    className="octas"
                    justifyContent="center"
                    alignItems="center"
                    ml={{ base: '0px', lg: '15px' }}
                    mt={{ base: '12px', lg: '0px' }}
                    width={{ base: '100%', lg: 'auto' }}
                    height={{ base: 'auto', lg: '100%' }}
                    flexDirection={{ base: 'column', lg: 'row' }}
                >
                    {/* *** COLOURED BOXES *** */}
                    <Flex
                        className="coloured-boxes"
                        transition="all ease 300ms"
                        width={{ base: '100%', lg: '8px' }}
                        height={{ base: '6px', lg: '100%' }}
                        justifyContent="center"
                        alignItems="center"
                        borderRadius={{ base: '5px', lg: '3px' }}
                        overflow="hidden"
                        flexDirection={{ base: 'row-reverse', lg: 'column' }}
                    >
                        <Flex
                            width="100%"
                            height="100%"
                            background="#FFCCCC"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                            borderTopLeftRadius={{ base: '0px', lg: '5px' }}
                            borderTopRightRadius={{ base: '5px', lg: '5px' }}
                            borderBottomLeftRadius={{ base: '0px', lg: '0px' }}
                            borderBottomRightRadius={{ base: '5px', lg: '0px' }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#ffffb2"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#ffffb2"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#ffffb2"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#CCFFCC"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#CCFFCC"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#e5e5ff"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#e5e5ff"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderBottom={{ lg: 'none' }}
                            borderLeft={{
                                base: 'none',
                                lg: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="#FFFFFF"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', lg: '12px' }}
                            color="light.100"
                            borderTopLeftRadius={{ base: '5px', lg: '0px' }}
                            borderTopRightRadius={{ base: '0px', lg: '0px' }}
                            borderBottomLeftRadius={{ base: '5px', lg: '5px' }}
                            borderBottomRightRadius={{ base: '0px', lg: '5px' }}
                        ></Flex>
                    </Flex>
                    <Flex // BOTTOM/RIGHT TEXT
                        transition="all ease 300ms"
                        width={{ base: 'calc(100% + 10px)', lg: '20px' }}
                        height={{ base: '15px', lg: '100%' }}
                        justifyContent="space-around"
                        alignItems={{ base: 'center', lg: 'flex-start' }}
                        pt={{ base: '5px', lg: '0px' }}
                        pl={{ base: '5px', lg: '10px' }}
                        flexDirection={{ base: 'row-reverse', lg: 'column' }}
                        fontSize={{ base: '10px', lg: '10px' }}
                        lineHeight={{ base: '1', lg: '0.9' }}
                        color="light.100"
                    >
                        <Text
                            ml={{ base: '-3px', lg: '0px' }}
                            pr={{ base: '3px', lg: '0px' }}
                        >
                            {'8'}
                        </Text>
                        <Text>7</Text>
                        <Text>6</Text>
                        <Text>5</Text>
                        <Text>4</Text>
                        <Text>3</Text>
                        <Text>2</Text>
                        <Text>1</Text>
                        <Text>0</Text>
                    </Flex>
                </Flex>

                <Flex
                    className="barbs"
                    justifyContent="center"
                    alignItems="center"
                    width={{ base: '100%', lg: 'auto' }}
                    height={{ base: 'auto', lg: '100%' }}
                    flexDirection={{ base: 'column', lg: 'row' }}
                >
                    {/* *** WIND BARBS *** */}
                    <Flex
                        className="wind-barbs"
                        ml={{ base: '0px', lg: '60px' }}
                        transition="all ease 300ms"
                        width={{ base: '100%', lg: '30px' }}
                        height={{ base: '15px', lg: '100%' }}
                        justifyContent="center"
                        alignItems="center"
                        borderRadius={{ base: '5px', lg: '3px' }}
                        overflow="hidden"
                        flexDirection={{ base: 'row-reverse', lg: 'column' }}
                    >
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid50={true}
                                wbid45={true}
                                wbid40={true}
                                wbid30={true}
                                className="75kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid50={true}
                                className="50kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid45={true}
                                wbid40={true}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="45kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid40={true}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="40kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid35={true}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="35kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="30kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid25={true}
                                wbid20={true}
                                wbid10={true}
                                className="25kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid10={true}
                                wbid20={true}
                                className="20kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid10={true}
                                wbid5={true}
                                className="15kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                className="10kt"
                                wbid10={true}
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                className="5kt"
                                wbid5={true}
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                    </Flex>

                    <Flex // BOTTOM/RIGHT TEXT
                        transition="all ease 300ms"
                        width={{ base: 'calc(100% + 10px)', lg: '30px' }}
                        height={{ base: '15px', lg: '100%' }}
                        justifyContent="space-around"
                        alignItems={{ base: 'center', lg: 'flex-start' }}
                        pl={{ base: '10px', lg: '5px' }}
                        pr={{ base: '4px', lg: '0px' }}
                        flexDirection={{ base: 'row-reverse', lg: 'column' }}
                        fontSize={{ base: '9px', lg: '10px' }}
                        lineHeight={{ base: '1', lg: '0.9' }}
                        color="light.100"
                    >
                        <Text>75</Text>
                        <Text>50</Text>
                        <Text>45</Text>
                        <Text>40</Text>
                        <Text>35</Text>
                        <Text>30</Text>
                        <Text>25</Text>
                        <Text>20</Text>
                        <Text>15</Text>
                        <Text>10</Text>
                        <Text>5</Text>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
