import React, { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { HStack, useColorModeValue, Badge, Flex } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { ReactComponent as ProfileIcon } from '../../../icons/assets/profile.svg'

import {
    settingsMenuOpenState,
    setModalVisibilityState,
    userProfileState,
} from '../../../globalState'

export default function Profile(props) {
    const { mfaTimeToExpire, showMfaBadge } = props

    const setSettingsMenuOpen = useSetRecoilState(settingsMenuOpenState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const profileData = useRecoilValue(userProfileState)

    const setShowProfileModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'profile', value })
        },
        [setModalVisibility]
    )

    return (
        <HStack
            pl={5}
            pr={4}
            pb="1px"
            mt="20px"
            mb="8px"
            color={useColorModeValue('light.100', 'dark.100')}
            cursor="pointer"
            onClick={() => {
                setSettingsMenuOpen(false)
                setShowProfileModal(true)
            }}
            _hover={{
                color: useColorModeValue('light.900', 'dark.80'),
                background: useColorModeValue('light.20', 'dark.20'),
            }}
        >
            <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                minHeight="46px"
                gap="13px"
            >
                <Flex alignItems="center" justifyContent="center" pl="2px">
                    {showMfaBadge && ( // MFA badge shows when MFA is due to expire soon or has expired
                        <Badge
                            zIndex="1"
                            position="absolute"
                            marginTop="-22px"
                            marginLeft="-16px"
                            variant="outline"
                            border="1px solid"
                            bg={
                                mfaTimeToExpire === 'EXPIRED' ||
                                mfaTimeToExpire === 'WARNING'
                                    ? '#FFE0E0'
                                    : mfaTimeToExpire === 'CAUTION'
                                    ? '#FFEDDB'
                                    : 'gray.100'
                            }
                            boxShadow="none"
                            borderColor={
                                mfaTimeToExpire === 'EXPIRED' ||
                                mfaTimeToExpire === 'WARNING'
                                    ? 'red.500'
                                    : mfaTimeToExpire === 'CAUTION'
                                    ? 'warning.200'
                                    : 'gray.300'
                            }
                            color={
                                mfaTimeToExpire === 'EXPIRED' ||
                                mfaTimeToExpire === 'WARNING'
                                    ? 'red.500'
                                    : mfaTimeToExpire === 'CAUTION'
                                    ? 'warning.200'
                                    : 'gray.400'
                            }
                            borderRadius="10"
                            px="4px"
                            pb="0px"
                            pt="0px"
                            fontSize="0.6rem"
                            fontWeight="700"
                            height="14px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            cursor="pointer"
                        >
                            MFA
                        </Badge>
                    )}
                    <ProfileIcon opacity="1" height="26px" width="26px" />
                </Flex>
                <Flex
                    justifyContent="flex-start"
                    flexDirection="column"
                    width="100%"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                    alignItems="flex-start"
                    flexWrap="wrap"
                >
                    {!(profileData.firstName || profileData.lastName) ? (
                        <Flex
                            transition="all ease-in-out 300ms"
                            justifyContent="flex-start"
                            color="light.200"
                            fontSize="0.8rem"
                            fontWeight="600"
                            lineHeight={{
                                base: '1.3',
                                md: '1.6',
                            }}
                            width="100%"
                            textAlign={{
                                base: 'center',
                                md: 'left',
                            }}
                            whiteSpace="pre"
                        >
                            PLEASE UPDATE YOUR PROFILE
                        </Flex>
                    ) : (
                        <Flex
                            width="100%"
                            whiteSpace="pre"
                            fontSize={{ base: '14px', md: '0.85rem' }}
                            fontWeight="500"
                            mr="10px"
                        >
                            {profileData.firstName} {profileData.lastName}
                        </Flex>
                    )}

                    <Flex
                        width="100%"
                        opacity={
                            profileData.firstName || profileData.lastName
                                ? '0.6'
                                : '1'
                        }
                        fontSize="0.65rem"
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                        fontWeight="300"
                        mt="-1px !important"
                        mb="1px"
                    >
                        {profileData.email}
                    </Flex>
                </Flex>
            </Flex>
            <ChevronRightIcon
                boxSize={5}
                color={
                    (!profileData.firstName || !profileData.lastName) &&
                    'light.200'
                }
            />
        </HStack>
    )
}
