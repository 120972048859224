import { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'

import {
    onlineState,
    windowIdle,
    pollingPaused,
    dataModeState,
    layerSelectionState,
} from '../globalState'

const PING_RESOURCE = '/ping.txt'
const TIMEOUT_TIME_MS = 3000
const onlinePollingInterval = 15000
const checkInterval = 4500

export default function NetworkDetector() {
    const [pausePolling, setPausePolling] = useRecoilState(pollingPaused)
    const [pollingActive, setPollingActive] = useState(false)
    const dataMode = useRecoilValue(dataModeState)
    const layerState = useRecoilValue(layerSelectionState)
    const setOnline = useSetRecoilState(onlineState)
    const [idle, setIdle] = useRecoilState(windowIdle)

    useEffect(() => {
        setPausePolling(false)
    }, [dataMode, layerState, setPausePolling])

    const timeout = (time, promise) => {
        return new Promise(function(resolve, reject) {
            setTimeout(() => {
                reject(new Error('Request timed out.'))
            }, time)
            promise.then(resolve, reject)
        })
    }

    const checkOnlineStatus = async () => {
        const controller = new AbortController()
        const { signal } = controller

        // If the browser has no network connection return offline
        if (!navigator.onLine) return navigator.onLine
        //
        try {
            await timeout(
                TIMEOUT_TIME_MS,
                fetch(PING_RESOURCE, {
                    method: 'GET',
                    signal,
                })
            )
            return true
        } catch (error) {
            // Error Log
            console.error(error)

            // This can be because of request timed out
            // so we abort the request for any case
            controller.abort()
        }
        console.log('checkOnlineStatus:', false)
        return false
    }

    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    // This checks whether the app is online 3 times before failing
    const checkStatus = async () => {
        let online = await checkOnlineStatus()
        if (online) {
            setOnline(online)
        } else {
            await wait(checkInterval)
            online = await checkOnlineStatus()
            if (online) {
                setOnline(online)
            } else {
                await wait(checkInterval)
                online = await checkOnlineStatus()
                setOnline(online)
            }
        }
    }

    // Add polling incase of slow connection
    let _poller = null

    const poll = () => {
        return {
            start: () => {
                console.log('~ Network Polling started.')
                clearInterval(_poller)
                _poller = setInterval(() => {
                    checkStatus()
                }, onlinePollingInterval)
            },
            stop: () => {
                console.log('~ Network Polling stopped.')
                clearInterval(_poller)
            },
        }
    }

    const poller = poll()

    useEffect(() => {
        const onVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setIdle(true)
            } else {
                setIdle(false)
            }
        }

        window.addEventListener('offline', () => {
            setOnline(false)
        })

        document.addEventListener('visibilitychange', onVisibilityChange, false)

        return () => {
            window.removeEventListener('offline', () => {
                setOnline(false)
            })

            document.removeEventListener('visibilitychange', () => {
                console.log('document listener removed')
            })

            clearInterval(_poller)
        }
    }, [_poller, poller, setOnline, setIdle])

    // we pause network polling when we are not connected to the internet
    useEffect(() => {
        if (pausePolling && !idle) {
            // stop polling if polling has been programmatically paused due to large payloads.
            setPollingActive(false)
        } else if (!pausePolling && idle) {
            // stop polling if window is idle.
            setPollingActive(false)
        } else if (!pausePolling && !idle) {
            // in case poller is running already, clear interval.
            setPollingActive(true)
        }
    }, [poller, pausePolling, idle])

    useEffect(() => {
        if (pollingActive) {
            poller.start()
        } else {
            poller.stop()
        }
    }, [poller, pollingActive])

    return null
}
