import React from 'react'
import { Flex, Text, useBreakpointValue, Icon } from '@chakra-ui/react'
import WindIcon from '../../../../../icons/menu-icons/WindIcon'
import {
    WindDirectionDial,
    WindSpeedDial,
} from '../../graphics/WeatherGraphics'

export default function AwsWind(props) {
    const {
        winddir,
        winddirmax,
        winddirmin,
        windspeed,
        windgust,
        windlull,
        windRoseMagVar,
        infotabGraphicsDisabled,
    } = props

    const windImageSize = useBreakpointValue({
        base: '130',
        md: '100',
    })

    return (
        <Flex
            width="100%"
            background="light.10"
            boxShadow={
                !infotabGraphicsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            borderTopRadius={'20px'}
            borderBottomRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            pt={infotabGraphicsDisabled ? '15px' : '10px'}
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '0px' : '10px'}
            direction={{
                base: 'column',
                md: 'column',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'space-between',
                    md: 'flex-start',
                }}
                pb="5px"
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color="#9DB2CD"
                >
                    <WindIcon boxSize="36px" />
                </Flex>
                <Flex
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Flex
                        fontSize={{
                            base: '1.4rem',
                            md: '1.2rem',
                        }}
                        lineHeight="1.2"
                        pt={{
                            base: '4px',
                            md: '5px',
                        }}
                        pl={infotabGraphicsDisabled ? '15px' : '8px'}
                        fontWeight="600"
                        whiteSpace="pre-line"
                    >
                        <Text>
                            {winddir && windRoseMagVar
                                ? winddir.toString().padStart(3, '0') + '°M '
                                : winddir
                                ? winddir.toString().padStart(3, '0') + '°T '
                                : ''}
                        </Text>
                        <Text pl="13px">{windspeed + ' kt'}</Text>
                    </Flex>
                    <Flex
                        direction="column"
                        alignItems="flex-end"
                        color="gray.500"
                        pt={{
                            base: '4px',
                            md: '5px',
                        }}
                        pr="4px"
                        pb={infotabGraphicsDisabled ? '0px' : '5px'}
                        opacity="0.8"
                    >
                        <Text
                            mt={windgust || windlull ? '0px' : '11px'}
                            fontSize={{
                                base: '0.9rem',
                                md: '0.8rem',
                            }}
                            height="20px"
                            fontWeight="500"
                            whiteSpace="pre-line"
                        >
                            {winddirmin && winddirmax
                                ? winddirmin.toString().padStart(3, '0') +
                                  '°' +
                                  ' V ' +
                                  winddirmax.toString().padStart(3, '0') +
                                  (windRoseMagVar ? '°M' : '°T')
                                : ''}
                        </Text>
                        {windgust && (
                            <Text
                                mt={winddirmin && winddirmax ? '0px' : '6px'}
                                fontSize={{
                                    base: '0.9rem',
                                    md: '0.8rem',
                                }}
                                height="20px"
                                fontWeight="500"
                                whiteSpace="pre-line"
                            >
                                {windgust ? 'Gusts ' + windgust + ' kt' : ''}
                            </Text>
                        )}
                        {windlull && (
                            <Text
                                mt={
                                    windgust || (winddirmin && winddirmax)
                                        ? '0px'
                                        : '6px'
                                }
                                fontSize={{
                                    base: '0.9rem',
                                    md: '0.8rem',
                                }}
                                height="20px"
                                fontWeight="500"
                                whiteSpace="pre-line"
                            >
                                {windlull ? 'Lulls ' + windlull + ' kt' : ''}
                            </Text>
                        )}
                    </Flex>
                </Flex>
            </Flex>

            {!infotabGraphicsDisabled && (
                <Flex
                    gap={{
                        base: '15px',
                        md: '10px',
                    }}
                    pb={{
                        base: '10px',
                        md: '8px',
                    }}
                    pl={{
                        base: '23px',
                        md: '3px',
                    }}
                    pr={{
                        base: '20px',
                        md: '0px',
                    }}
                    width="100%"
                    justifyContent={{
                        base: 'space-evenly',
                        md: 'space-evenly',
                    }}
                    alignItems={{
                        base: 'center',
                        md: 'center',
                    }}
                    flexDirection={{
                        base: 'row',
                        md: 'row',
                    }}
                >
                    <Icon // WIND DIRECTION
                        as={WindDirectionDial}
                        imageSize={windImageSize}
                        primaryImageColor={'#3e5e88'}
                        focusImageColor={'#ff8e00'}
                        imageColor={'#83909F'}
                        winddir={winddir}
                        compassRoseMagVar={windRoseMagVar}
                    />
                    <Icon // WIND SPEED
                        as={WindSpeedDial}
                        imageSize={windImageSize}
                        primaryImageColor={'#3e5e88'}
                        focusImageColor={'#00f1ab'}
                        imageColor={'#83909F'}
                        windspeed={windspeed}
                    />
                </Flex>
            )}
        </Flex>
    )
}
