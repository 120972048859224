import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Flex, Text, Tooltip } from '@chakra-ui/react'
import Slider from '../briefing-slider'
import { useTier } from '../../../../../auth/hooks'
import {
    selectedBriefingAltitudeState,
    mobileDrawerExpandedState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../../../globalState'
import PremiumBadge from '../../../../controls/PremiumBadge'
export default function AltitudeSelection({ altPadding }) {
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const [maxAltitude, setMaxAltitude] = useRecoilState(
        selectedBriefingAltitudeState
    )
    useEffect(() => {
        !maxAltitude &&
            maxAltitude !== 0 &&
            setMaxAltitude(isPremium || userTier < 2 ? 10000 : 60000)
    }, [isPremium, userTier, maxAltitude, setMaxAltitude])

    const mobileDrawerExpanded = useRecoilState(mobileDrawerExpandedState)
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    function premiumFormatTick(number) {
        if (number === 0) {
            return 'SFC'
        } else {
            return numberWithCommas(number) + ' ft'
        }
    }

    function standardFormatTick(number) {
        if (number === 0) {
            return 'SFC'
        } else {
            return 'FL' + number / 100
        }
    }

    return (
        <Flex
            direction={'column'}
            px={{
                base: '20px',
                md: '22px',
            }}
            w={'100%'}
            minHeight={{
                base: mobileDrawerExpanded ? '96px' : '0px',
                md: '80px',
                lg: '100px',
            }}
        >
            <Flex w="100%" mb={3}>
                <Flex
                    fontWeight={{ base: '600', md: '700' }}
                    fontSize={{
                        base: '0.75rem',
                        md: '0.7rem',
                    }}
                    pr="8px"
                    pb={{ base: '0', md: '2px' }}
                    opacity="0.7"
                    minWidth="120px"
                    alignItems="center"
                >
                    Maximum Altitude:
                </Flex>
                <Flex
                    w={{ base: '', md: '100%' }}
                    flexDirection={{ base: 'row', md: 'row' }}
                    justifyContent={{
                        base: 'space-between',
                        md: 'flex-end',
                    }}
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Text
                        fontWeight={{ base: '400', md: '500' }}
                        fontSize={{
                            base: '0.75rem',
                            md: '0.7rem',
                        }}
                        textAlign="right"
                    >
                        {maxAltitude === 0
                            ? 'SFC'
                            : maxAltitude < 13500
                            ? `${
                                  maxAltitude >= 10000
                                      ? maxAltitude.toLocaleString('en')
                                      : maxAltitude
                              } ft`
                            : maxAltitude === 10000
                            ? '10,000 ft'
                            : `FL${maxAltitude / 100}`}
                    </Text>
                </Flex>
            </Flex>
            <Flex // ALTITUDE SLIDER FOR SELECTING BRIEFING ALTITUDE
                transition="all ease 400ms"
                position={{ base: 'relative', md: 'relative' }}
                mt={{ base: mobileDrawerExpanded ? '5px' : '0px', md: '5px' }}
                mb={{ base: mobileDrawerExpanded ? '20px' : '0px', md: '15px' }}
                height={{
                    base: mobileDrawerExpanded ? '30px' : '0px',
                    md: '30px',
                }}
                opacity={{
                    base: mobileDrawerExpanded ? '1' : '0',
                    md: '1',
                }}
                visibility={{
                    base: mobileDrawerExpanded ? 'visible' : 'hidden',
                    md: 'visible',
                }}
                w={{ base: '100%', md: '100%' }}
                borderRadius="15px"
                zIndex="1"
            >
                {isPremium && ( // PREMIUM BADGE
                    <Flex
                        className="premiumBadge"
                        position="absolute"
                        right={{
                            base: 'unset',
                            md: '-15px',
                        }}
                        left={{
                            base: '177px',
                            md: 'unset',
                        }}
                        mt={{
                            base: '-38px',
                            md: '-40px',
                        }}
                    >
                        <Tooltip
                            label="> 10,000 ft Premium Only"
                            hasArrow
                            borderRadius="7px"
                            height="26px"
                            fontSize="0.8rem"
                            alignItems="center"
                            display="flex"
                            placement="right"
                            ml="5px"
                            color="light.10"
                            bg="brand.premium"
                        >
                            <PremiumBadge />
                        </Tooltip>
                    </Flex>
                )}
                <Slider
                    baseColor={
                        'linear-gradient(to right, #136DE2 5%, #16BDFF 80%)'
                    }
                    format={isPremium ? premiumFormatTick : standardFormatTick}
                    min={0}
                    max={isPremium ? 10000 : 60000}
                    step={isPremium ? 500 : 3000}
                    ticks={
                        isPremium
                            ? [0, 2500, 5000, 7500, 10000]
                            : [0, 15000, 30000, 45000, 60000]
                    }
                    value={[maxAltitude]}
                    setValue={(values) => setMaxAltitude(values[0])}
                    altPadding={altPadding}
                />
            </Flex>
        </Flex>
    )
}
