import React from 'react'
import { VStack, Heading, LightMode, Flex, Text } from '@chakra-ui/react'
import { versionNumber, versionDate } from '../map/modals/terms-and-conditions'
import TermsAndConditions from '../map/modals/terms-and-conditions/TermsAndConditions'
import PreFlight from '../icons/menu-icons/PreFlightIcon'

export default function Terms() {
    return (
        <LightMode>
            <VStack
                className="terms"
                maxHeight="100vh"
                overflowY="auto"
                overflowX="hidden"
                px={5}
                pt="20px"
                pb="60px"
                spacing={4}
                alignItems="start"
                fontSize={{
                    base: '0.8rem',
                    md: '0.8rem',
                }}
                backgroundColor="white"
                color="black"
            >
                <Flex
                    h={{ base: '70px', md: '70px' }}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    pr="5px"
                >
                    <PreFlight
                        width={'80px'}
                        height={'80px'}
                        transition="all linear 300ms"
                        fill="#CED9DF"
                        py="0"
                        mr="7px"
                        color={'brand.200'}
                    />
                    <Flex fontFamily="'Open Sans', sans-serif">
                        <Text
                            fontSize={'4xl'}
                            transition="all linear 300ms"
                            color="brand.200"
                            fontWeight="light"
                            letterSpacing="1px"
                            alignSelf="center"
                        >
                            Pre
                        </Text>
                        <Text
                            fontSize={'4xl'}
                            transition="all linear 300ms"
                            ml="0"
                            color="brand.200"
                            fontWeight="semibold"
                            letterSpacing="1px"
                            alignSelf="center"
                        >
                            Flight
                        </Text>
                    </Flex>
                </Flex>
                <Heading
                    alignSelf="center"
                    fontSize="1.7rem"
                    fontWeight="400"
                    color="gray.500"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                >
                    Terms and Conditions
                </Heading>

                <TermsAndConditions
                    showTerms={true}
                    versionNumber={versionNumber}
                    versionDate={versionDate}
                />
            </VStack>
        </LightMode>
    )
}
