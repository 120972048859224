import React, { useState, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer, Popup } from 'react-mapbox-gl'
import { VectorLayerRefreshInfo } from '../../../globalState'
import formatcoords from 'formatcoords'

import { Flex } from '@chakra-ui/react'

import './assets/aero-popup.css'

import { ReactComponent as NDBIcon } from '../../icons/aero/ndb.svg'
import { ReactComponent as NDBDMEIcon } from '../../icons/aero/ndbdme.svg'
import { ReactComponent as DMEIcon } from '../../icons/aero/dme.svg'
import { ReactComponent as VORIcon } from '../../icons/aero/vor.svg'
import { ReactComponent as VORDMEIcon } from '../../icons/aero/vordme.svg'

export default function NavaidsLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    const [hoverInfo, setHoverInfo] = useState(null)

    const onHover = (e) => {
        const zoomLevel = e.target.getZoom()

        const newE = e

        const filteredFeatures = e.features.filter((feat) => {
            if (zoomLevel >= 8) {
                return feat
            }
            return null
        })

        newE.features = filteredFeatures.map((feat) => {
            const newFeat = { ...feat }
            const coords = formatcoords(
                feat.geometry.coordinates[(0, 1)],
                feat.geometry.coordinates[(0, 0)]
            )
                .format('X D M s', { latLonSeparator: ',', decimalPlaces: 0 })
                .split(',')
            const lat = coords[0]
                .split(' ')
                .map((value, index) => {
                    if (index === 0 || value.length > 1) {
                        return value
                    } else {
                        return '0' + value
                    }
                })
                .join(' ')
            const long = coords[1]
                .split(' ')
                .map((value, index) => {
                    if (index === 0 || value.length > 1) {
                        return value
                    } else {
                        return '0' + value
                    }
                })
                .join(' ')
            newFeat.properties = {
                ...newFeat.properties,
                coords: { lat: lat, long: long },
            }
            return newFeat
        })

        setHoverInfo(newE)
    }

    const offHover = useCallback((e) => {
        setHoverInfo(null)
    }, [])

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0,
        8,
        0,
        9,
        1,
    ]

    return (
        <>
            <GeoJSONLayer
                id="navaids"
                data={layerData.navaids.dataURL}
                before="aerodromes-symbol"
                symbolOnMouseEnter={onHover}
                symbolOnMouseLeave={offHover}
                symbolLayout={{
                    'icon-image': [
                        'match',
                        ['get', 'type'],
                        'NDB',
                        'ndb',
                        'NDB_DME',
                        'ndbdme',
                        'VOR',
                        'vor',
                        'VOR_DME',
                        'vordme',
                        'DME',
                        'dme',
                        '',
                    ],
                    'text-field': ['get', 'designator'],

                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-offset': [0, 1.4],
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        0.3,
                        10,
                        0.5,
                        11,
                        0.6,
                    ],
                    'icon-anchor': 'center',
                    'text-anchor': 'top',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        3,
                        10,
                        9,
                        11,
                        9,
                    ],
                }}
                symbolPaint={{
                    'text-color': '#000000',
                    'icon-opacity': zoomLevelDefinition,
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 0.8,
                    'text-opacity': zoomLevelDefinition,
                }}
                layerOptions={{
                    minzoom: 8,
                }}
            />
            {hoverInfo && hoverInfo.features.length > 0 && (
                <Popup
                    coordinates={[hoverInfo.lngLat.lng, hoverInfo.lngLat.lat]}
                    className={'aero-popup'}
                >
                    <Flex className="popup-header navaids" alignItems="center">
                        {hoverInfo.features[0].properties.type === 'NDB' ? (
                            <NDBIcon />
                        ) : hoverInfo.features[0].properties.type ===
                          'NDB_DME' ? (
                            <NDBDMEIcon />
                        ) : hoverInfo.features[0].properties.type === 'DME' ? (
                            <DMEIcon />
                        ) : hoverInfo.features[0].properties.type === 'VOR' ? (
                            <VORIcon />
                        ) : hoverInfo.features[0].properties.type ===
                          'VOR_DME' ? (
                            <VORDMEIcon />
                        ) : null}
                        <div className="popup-title">
                            <h1 className="title-type">
                                {hoverInfo.features[0].properties.name}
                            </h1>
                        </div>
                    </Flex>
                    <div className="popup-body">
                        <p className="ndb-vor-info">
                            {hoverInfo.features[0].properties.type === 'NDB'
                                ? [
                                      'NDB: ',
                                      ' ',
                                      hoverInfo.features[0].properties
                                          .frequency,
                                  ]
                                : hoverInfo.features[0].properties.type ===
                                  'NDB_DME'
                                ? [
                                      'NDB: ',
                                      ' ',
                                      hoverInfo.features[0].properties
                                          .frequency,
                                  ]
                                : hoverInfo.features[0].properties.type ===
                                  'VOR'
                                ? [
                                      'VOR: ',
                                      ' ',
                                      hoverInfo.features[0].properties
                                          .frequency,
                                  ]
                                : hoverInfo.features[0].properties.type ===
                                  'VOR_DME'
                                ? [
                                      'VOR: ',
                                      ' ',
                                      hoverInfo.features[0].properties
                                          .frequency,
                                  ]
                                : null}
                        </p>
                        <p className="dme-info">
                            {hoverInfo.features[0].properties.type ===
                                'NDB_DME' &&
                            hoverInfo.features[0].properties.frequency !== null
                                ? [
                                      'DME: ',
                                      ' ',
                                      hoverInfo.features[0].properties
                                          .dmefrequency,
                                  ]
                                : hoverInfo.features[0].properties.type ===
                                      'DME' &&
                                  hoverInfo.features[0].properties.frequency !==
                                      null
                                ? [
                                      'DME: ',
                                      ' ',
                                      hoverInfo.features[0].properties
                                          .frequency,
                                  ]
                                : hoverInfo.features[0].properties.type ===
                                      'DME' &&
                                  hoverInfo.features[0].properties.frequency !==
                                      null
                                ? [
                                      'DME: ',
                                      ' ',
                                      hoverInfo.features[0].properties
                                          .frequency,
                                  ]
                                : null}
                        </p>
                        <p className="aero-info">
                            <span className="designator">
                                {hoverInfo.features[0].properties.designator}{' '}
                            </span>
                            <span className="morse">
                                {hoverInfo.features[0].properties.morse
                                    .replace(
                                        '  ', // converts double space to longer space
                                        ' \xa0 '
                                    )
                                    .replace(/[-]/g, '\u2012')}
                            </span>
                        </p>
                    </div>
                    <div className="popup-body-coords">
                        <p className="coords-info">
                            {hoverInfo.features[0].properties.coords.lat}
                        </p>
                        <p className="coords-info">
                            {hoverInfo.features[0].properties.coords.long}
                        </p>
                    </div>
                </Popup>
            )}
        </>
    )
}
