import React from 'react'
import { useRecoilValue } from 'recoil'
import { Badge } from '@chakra-ui/react'

import { statusData } from '../../../../globalState'

export default function DataCountBadge(props) {
    const status = useRecoilValue(statusData)
    const { id } = props

    // console.log(status.sigwx, 'status.sigwx.count')

    return status && (id === 'sigmet' || id === 'sigwx' || id === 'spacewx') ? (
        <Badge
            marginTop="1px"
            ml={2}
            variant="solid"
            bg="#ffeb7c"
            color="#666666"
            textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
            borderRadius="10"
            px="6px"
            pb="0px"
            pt="0px"
            fontSize="10px"
            height="18px"
            minWidth="18px"
            lineHeight="1.8"
        >
            {id === 'sigmet'
                ? status.sigmet.count
                    ? status.sigmet.count
                    : 'Unavailable'
                : id === 'sigwx'
                ? status.sigwx.count
                    ? status.sigwx.count
                    : 'Unavailable'
                : id === 'spacewx'
                ? status.spacewx.count
                    ? status.spacewx.count
                    : 'Unavailable'
                : ''}
        </Badge>
    ) : null
}
