import React, { useState, useEffect, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useAdminRole, useMetAdminRole } from '../auth/hooks'
import { useRecoilValue } from 'recoil'
import {
    Flex,
    Container,
    Box,
    IconButton,
    useDisclosure,
    Center,
    Spinner,
    useColorModeValue,
    Button,
    Text,
    Link,
} from '@chakra-ui/react'
import { fullscreenModalVisibleState } from '../globalState'
import { FaArrowUp } from 'react-icons/fa'
import { isMobile, isDesktop } from 'react-device-detect'
import mapbg01 from './assets/bg-map-01.png'
import Footer from './components/Footer'
import Header from './components/Header'
import Content from './components/Content'
import { isMobileOnly, isIOS } from 'react-device-detect'
import {
    bypassLandingPageState,
    isBriefingOnlyState,
    setModalVisibilityState,
} from '../globalState'
import { ReactComponent as PreflightIcon } from '../icons/assets/preflight-icon-shadow.svg'

export default function LandingPage() {
    const devEnvironment = JSON.parse(
        process.env.REACT_APP_DEV_ENV.toLowerCase()
    )
    const bypassLandingPage = useRecoilValue(bypassLandingPageState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const isAdmin = useAdminRole()
    const isMetAdmin = useMetAdminRole()
    const navigate = useNavigate()
    const { user, loginWithRedirect } = useAuth0()
    const { isLoading } = useAuth0()
    const { isOpen, onToggle } = useDisclosure()
    const [inViewPosition, setInViewPosition] = useState('headerContainer')
    const [devSiteBypass, setDevSiteBypass] = useState(false)
    const [scrollStarted, setscrollStarted] = useState(false)
    const setFullscreenModalVisible = useSetRecoilState(
        fullscreenModalVisibleState
    )
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)

    const setShowContactUs = useCallback(
        (value) => {
            setModalVisibility({ id: 'contactus', value })
        },
        [setModalVisibility]
    )

    const hideBackgroundImage = inViewPosition === 'briefingContainer' // used to avoid flashing behind content when scrolling fast on mobile devices
    const returnToTopButton = inViewPosition !== 'headerContainer'
    const footerReveal =
        inViewPosition !== 'aeroContainer' &&
        inViewPosition !== 'headerContainer'

    const adminColor = useColorModeValue(
        'radial-gradient(#ffffff 40%, #dbe0e6 100%) rgb(238, 240, 244) !important',
        '#1A202C'
    )
    const linkColor = useColorModeValue('light.200', 'dark.200')
    const linkColorHover = useColorModeValue('light.201', 'light.203')
    const fillColor = useColorModeValue('light.200', '#00FFB7')
    const emptyColor = useColorModeValue('gray.200', 'rgba(255,255,255,0.2)')
    const emptyColorSecond = useColorModeValue(
        'gray.200',
        'rgba(255,255,255,0.2)'
    )

    useEffect(() => {
        // add class to body element
        if (isLoading) {
            document.body.classList.remove('app')
            document.body.classList.remove('landing-page')
            document.body.classList.add('loading')
        } else if (document.getElementById('landingPage')) {
            document.body.classList.remove('loading')
            document.body.classList.remove('app')
            document.body.classList.add('landing-page')
        } else {
            document.body.classList.remove('loading')
            document.body.classList.remove('landing-page')
        }
    }, [isLoading])

    // if user selects 'Contact Us' option from Userback popup modal, open the Contact Us modal
    function checkHashAndOpenModal() {
        if (window.location.hash === '#contact-us') {
            setShowContactUs(true)
        }
    }
    useEffect(() => {
        window.addEventListener('hashchange', checkHashAndOpenModal)
        checkHashAndOpenModal()
        return () => {
            window.removeEventListener('hashchange', checkHashAndOpenModal)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.log(isLoading, 'isLoading')
    // console.log(user, 'user')
    // console.log(devEnvironment, 'devEnvironment')
    // console.log(devSiteBypass, 'devSiteBypass')
    // console.log(bypassLandingPage, 'bypassLandingPage')

    if (isLoading) {
        // Load page spinner while Auth0 is loading
        return (
            <Center
                flexDirection="column"
                position="fixed"
                w="100%"
                h="100%"
                pb={{
                    base: isMobileOnly && isIOS ? '85px' : '70px',
                    lg: '20px',
                }}
            >
                <Spinner
                    size="4xl"
                    color={fillColor}
                    style={{
                        borderBottomColor: emptyColorSecond,
                        borderLeftColor: emptyColor,
                    }}
                    emptyColor={emptyColor}
                    thickness="1px"
                    speed="0.8s"
                    boxSize="110px"
                />
                <PreflightIcon
                    style={{ marginTop: '-92px', zIndex: '1' }}
                    width="80px"
                    height="80px"
                />
            </Center>
        )
    }

    if (devEnvironment && !devSiteBypass) {
        // else load Admin Only screen if on Dev site
        return (
            <Container
                px={{ base: '0', sm: '1rem' }}
                height="100vh"
                width="100%"
                maxW="unset"
                centerContent
                justifyContent="center"
                background={adminColor}
                color="#595959"
            >
                <Button
                    position="absolute"
                    right="15px"
                    top="15px"
                    fontSize={'0.75rem'}
                    borderRadius="12px"
                    fontWeight={400}
                    color={'gray.400'}
                    px="20px"
                    bg="transparent"
                    href={'#'}
                    opacity="0.3"
                    cursor="pointer"
                    _hover={{
                        opacity: '1',
                        bg: 'transparent',
                    }}
                    _active={{
                        opacity: '1',
                        bg: 'transparent',
                    }}
                    _focus={{
                        opacity: '1',
                        bg: 'transparent',
                    }}
                    zIndex="1"
                    height={scrollStarted ? '30px' : '40px'}
                    transition="all linear 300ms"
                    onClick={() => {
                        if (user) {
                            setDevSiteBypass(true)
                            if (bypassLandingPage) {
                                navigate('/app')
                            } else {
                                navigate('/')
                            }
                        } else {
                            setDevSiteBypass(true)
                            loginWithRedirect({
                                redirectUri: `${window.location.origin}/`,
                            })
                        }
                    }}
                >
                    <Text fontWeight="400" letterSpacing="0.5px">
                        Admin Only
                    </Text>
                </Button>
                <Center
                    flexDirection="column"
                    position="fixed"
                    w="100%"
                    h="100%"
                    pb={{
                        base: isMobileOnly && isIOS ? '85px' : '70px',
                        lg: '20px',
                    }}
                >
                    <Link
                        marginBottom="30px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontSize={'1.2rem'}
                        borderRadius="50px"
                        fontWeight={400}
                        color={linkColor}
                        px="25px"
                        bg="transparent"
                        href={
                            isAdmin || isMetAdmin || user
                                ? '#'
                                : 'https://gopreflight.co.nz'
                        }
                        opacity="0.9"
                        _hover={{
                            color: linkColorHover,
                            opacity: '1',
                            bg: 'transparent',
                        }}
                        _focus={{
                            color: linkColorHover,
                            opacity: '1',
                            bg: 'transparent',
                        }}
                        _active={{
                            color: linkColorHover,
                            opacity: '1',
                            bg: 'transparent',
                        }}
                        height={scrollStarted ? '30px' : '40px'}
                        transition="all linear 300ms"
                    >
                        <PreflightIcon
                            style={{
                                marginBottom: '10px',
                                zIndex: '1',
                            }}
                            width="120px"
                            height="120px"
                        />
                    </Link>
                    {isAdmin || isMetAdmin || user ? (
                        <Button
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={'1.2rem'}
                            borderRadius="50px"
                            fontWeight={400}
                            color={linkColor}
                            px="25px"
                            bg="transparent"
                            href={'#'}
                            opacity="0.9"
                            _hover={{
                                color: linkColorHover,
                                opacity: '1',
                                bg: 'transparent',
                            }}
                            _focus={{
                                color: linkColorHover,
                                opacity: '1',
                                bg: 'transparent',
                            }}
                            _active={{
                                color: linkColorHover,
                                opacity: '1',
                                bg: 'transparent',
                            }}
                            height={scrollStarted ? '30px' : '40px'}
                            transition="all linear 300ms"
                            onClick={() => {
                                setDevSiteBypass(true)
                                if (bypassLandingPage) {
                                    navigate('/app')
                                } else {
                                    navigate('/')
                                }
                            }}
                        >
                            <Text fontWeight="400" letterSpacing="0.5px">
                                Admin Access Only
                            </Text>
                        </Button>
                    ) : (
                        <Link
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={'1.2rem'}
                            borderRadius="50px"
                            fontWeight={400}
                            color={linkColor}
                            px="25px"
                            bg="transparent"
                            href="https://gopreflight.co.nz"
                            opacity="0.9"
                            _hover={{
                                color: linkColorHover,
                                opacity: '1',
                                bg: 'transparent',
                            }}
                            _focus={{
                                color: linkColorHover,
                                opacity: '1',
                                bg: 'transparent',
                            }}
                            _active={{
                                color: linkColorHover,
                                opacity: '1',
                                bg: 'transparent',
                            }}
                            height={scrollStarted ? '30px' : '40px'}
                            transition="all linear 300ms"
                        >
                            <Text fontWeight="400" letterSpacing="0.5px">
                                Click here to go to PreFlight
                            </Text>
                        </Link>
                    )}
                </Center>
            </Container>
        )
    }

    if (
        // else redirect directly to the app if already logged in
        bypassLandingPage &&
        user &&
        ((devEnvironment && devSiteBypass && (isAdmin || isMetAdmin)) ||
            !devEnvironment)
    ) {
        if (isBriefingOnly) {
            return navigate('/app?mode=brief')
        } else {
            return navigate('/app')
        }
    }

    if (
        // else load the landing page initially if not logged in
        !isLoading &&
        ((devEnvironment && devSiteBypass && (isAdmin || isMetAdmin || user)) ||
            !devEnvironment)
    ) {
        setFullscreenModalVisible(false)
        return (
            <Flex
                id="landingPage"
                zIndex="2"
                fontFamily="'Poppins', 'Open Sans', sans-serif"
                flexDirection="column"
                height="100%"
                backgroundColor="white"
                className="landingPageScroll light scrollVertical"
            >
                <Box // Fixed Background Image (Map)
                    className="bgImage"
                    position="fixed"
                    top="0px"
                    left="0px"
                    w={'full'}
                    h={'100vh'}
                    opacity={hideBackgroundImage ? '0' : '1'}
                    backgroundImage={`url(${mapbg01})`}
                    backgroundSize={'cover'}
                    backgroundPosition={'center center'}
                    zIndex="1"
                >
                    <Flex
                        w={'100%'}
                        h={'100%'}
                        bgGradient={
                            'linear(140deg, rgba(0, 60, 100, 0.8) 5%, transparent 80%)'
                        }
                    ></Flex>
                </Box>
                {/* <OverlayScrollbarsComponent
                ref={instanceRef}
                options={{
                    className: scrollBar,
                    scrollbars: {
                        visibility: 'auto',
                        autoHide: scrollBarHide,
                        autoHideDelay: 800,
                        dragScrolling: true,
                        clickScrolling: true,
                        touchSupport: true,
                        snapHandle: true,
                    },
                    overflow: {
                        x: 'hidden',
                        y: 'scroll',
                    },
                    callbacks: {
                        onScroll,
                    },
                }}
                style={{
                    height: '100%',
                }}
            > */}
                <Header // HEADER
                    scrollStarted={scrollStarted}
                    isOpen={isOpen}
                    onToggle={onToggle}
                    setInViewPosition={setInViewPosition}
                    setscrollStarted={setscrollStarted}
                />

                <Flex // Content
                    width="100%"
                    direction="column"
                    align="center"
                    // maxW={{ xl: '1200px' }}
                    m="0 auto"
                    zIndex="2"
                    position="relative"
                >
                    <IconButton // Scroll Back to Top Button
                        position="fixed"
                        bottom={isMobile || !isDesktop ? '20px' : '15px'}
                        right={isMobile || !isDesktop ? '20px' : '30px'}
                        zIndex="9999"
                        color="white"
                        opacity={returnToTopButton ? '0.7' : '0'}
                        visibility={returnToTopButton ? 'visible' : 'hidden'}
                        transition="all ease 500ms"
                        borderRadius="100%"
                        background="rgba(20, 40, 60, 0.15)"
                        _hover={{
                            background: 'rgba(20, 40, 60, 0.3)',
                            opacity: returnToTopButton ? '1' : '0',
                        }}
                        _active={{
                            background: 'rgba(20, 40, 60, 0.5)',
                            opacity: returnToTopButton ? '1' : '0',
                        }}
                        onClick={() => {
                            document
                                .getElementById('headerContainer')
                                .scrollIntoView({
                                    behavior: 'smooth',
                                })
                        }}
                        icon={<FaArrowUp w={5} h={5} />}
                    />

                    <Container zIndex="1" maxW={'100%'} p="0" marginTop="10vh">
                        <Content setInViewPosition={setInViewPosition} />
                    </Container>
                    {/* <Flex height="320px" width="100%"></Flex> */}
                    <div id="footerContainer"></div>
                    <Footer footerReveal={footerReveal} />
                </Flex>
                {/* </OverlayScrollbarsComponent> */}
            </Flex>
        )
    }
}
