import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloudIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_454:67009)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 12C2 14.2091 3.79086 16 6 16H17.5C18.8051 16 19.9806 15.4445 20.8026 14.5568C21.5458 13.7542 22.0002 12.6802 22.0002 11.5C22.0002 9.01472 19.8182 7 17.5 7L17.489 7.00001C17.4342 7.00014 17.3795 7.00126 17.3251 7.00334C16.2543 4.92855 14.0505 3.5 11.75 3.5C8.9028 3.5 6.50027 5.40385 5.746 8.00794C3.65521 8.13895 2 9.87618 2 12ZM17.5362 8.50025C17.5658 8.50065 17.5954 8.50154 17.625 8.50292C19.123 8.57279 20.5 9.8879 20.5 11.5C20.5 13.1569 19.1569 14.5 17.5 14.5H6C4.61929 14.5 3.5 13.3807 3.5 12C3.5 10.6193 4.61929 9.5 6 9.5H7.00647C7.13647 6.99286 9.21051 5 11.75 5C13.3735 5 14.9336 5.93612 15.7998 7.33233C15.1672 7.5907 14.6065 7.98893 14.1563 8.48858L15.264 9.50002C15.798 8.90341 16.5672 8.52176 17.4259 8.5009C17.4505 8.5003 17.4752 8.5 17.5 8.5L17.5362 8.50025Z"
                    fill="currentColor"
                />
                <path
                    d="M3.75 17H9.25C9.66421 17 10 17.3358 10 17.75C10 18.1642 9.66421 18.5 9.25 18.5H3.75C3.33579 18.5 3 18.1642 3 17.75C3 17.3358 3.33579 17 3.75 17Z"
                    fill="currentColor"
                />
                <path
                    d="M12.25 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H12.25C11.8358 18.5 11.5 18.1642 11.5 17.75C11.5 17.3358 11.8358 17 12.25 17Z"
                    fill="currentColor"
                />
                <path
                    d="M12.25 19.5H8.75C8.33579 19.5 8 19.8358 8 20.25C8 20.6642 8.33579 21 8.75 21H12.25C12.6642 21 13 20.6642 13 20.25C13 19.8358 12.6642 19.5 12.25 19.5Z"
                    fill="currentColor"
                />
                <path
                    d="M3.75 19.5H5.75C6.16421 19.5 6.5 19.8358 6.5 20.25C6.5 20.6642 6.16421 21 5.75 21H3.75C3.33579 21 3 20.6642 3 20.25C3 19.8358 3.33579 19.5 3.75 19.5Z"
                    fill="currentColor"
                />
                <path
                    d="M20.25 19.5H15.25C14.8358 19.5 14.5 19.8358 14.5 20.25C14.5 20.6642 14.8358 21 15.25 21H20.25C20.6642 21 21 20.6642 21 20.25C21 19.8358 20.6642 19.5 20.25 19.5Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
