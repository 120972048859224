import React from 'react'
import {
    Flex,
    Spacer,
    Text,
    InputGroup,
    Input,
    InputLeftElement,
    Link,
} from '@chakra-ui/react'
import { FaMobileAlt } from 'react-icons/fa'

export default function Mobile({
    mobileNumber,
    setMobileNumber,
    mobileStatus,
    mobileCallback,
    userMetaData,
}) {
    const mask = (cc, num = 4, mask = '*') =>
        ('' + cc).slice(0, -num).replace(/./g, mask) + ('' + cc).slice(-num)

    let modifiedMobileNumber
    if (mobileNumber.startsWith('+64')) {
        modifiedMobileNumber = mobileNumber.slice(3)
    } else {
        modifiedMobileNumber = mobileNumber
    }

    return (
        <>
            {userMetaData && userMetaData.mobile_number ? (
                <Flex
                    px="4"
                    pt="6"
                    pb="2"
                    height="100%"
                    width="100%"
                    clipPath="inset(0px 0px -50px)"
                    boxShadow={'inset rgba(0, 0, 0, 0.05) 0px 14px 11px -10px'}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    fontSize={{
                        base: '0.9rem',
                        md: '0.9rem',
                    }}
                    fontWeight="400"
                    color="gray.600"
                    gap="5px"
                >
                    <Text>Send a verification code to:</Text>
                    <Text
                        fontWeight="600"
                        fontSize={{
                            base: '1rem',
                            md: '1rem',
                        }}
                    >
                        {`0${mask(mobileNumber)}`}
                    </Text>
                    <Text pt="25px" color="gray.500" fontSize="0.8rem">
                        If you don't receive it within a couple of minutes,
                        please try again in case of any network connection
                        issues.
                    </Text>
                    <Text color="gray.500" fontSize="0.8rem" pt="20px">
                        If you are still having issues, please try an
                        alternative verification method, or{' '}
                        <Link
                            color="light.200"
                            href="mailto:helpdesk@airwaysinternational.co.nz?subject=Issues with PreFlight SMS Verification"
                        >
                            contact support
                        </Link>
                        .
                    </Text>
                </Flex>
            ) : (
                <Flex
                    mt={5}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginLeft="20px"
                    marginRight="20px"
                    gap={3}
                    color="gray.600"
                >
                    <Text fontSize={{ base: '0.9rem', md: '0.9rem' }}>
                        Please enter your mobile number below
                    </Text>
                    <Text fontSize={{ base: '0.9rem', md: '0.9rem' }} mt="-5px">
                        We will send you a 6-digit verification code to enter on
                        the next screen
                    </Text>
                    <Spacer />

                    <InputGroup width={{ base: 'unset', lg: '400px' }}>
                        <InputLeftElement
                            height="46px"
                            width="4rem"
                            marginLeft="15px"
                            children={
                                <>
                                    <FaMobileAlt fontSize="1rem" />
                                    <Text
                                        pl="5px"
                                        fontSize="1.2rem"
                                        letterSpacing="0.8px"
                                    >
                                        +64
                                    </Text>
                                </>
                            }
                        />
                        <Input
                            height="46px"
                            bg="white"
                            borderRadius="12px"
                            letterSpacing={{
                                base: '2.5px',
                                md: '3px',
                            }}
                            borderColor="gray.300"
                            paddingLeft="85px"
                            type="tel"
                            placeholder="Enter your mobile number"
                            variant="outline"
                            fontSize="1.2rem"
                            value={modifiedMobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    // console.log('Enter pressed')
                                    mobileCallback()
                                    setMobileNumber(e.target.value)
                                }
                            }}
                            _hover={{ borderColor: '#00D599' }}
                            _focus={{
                                border: '1px solid',
                                borderColor: '#00D599 !important',
                                boxShadow: 'none !important',
                            }}
                            _placeholder={{
                                fontSize: { base: '0.85rem', md: '0.95rem' },
                                letterSpacing: '0.8px',
                            }}
                        />
                    </InputGroup>

                    <Flex
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                        mt="10px"
                        flexDirection="column"
                    >
                        <Flex
                            width="100%"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection={{ base: 'column', sm: 'row' }}
                        >
                            <Text
                                fontSize="0.8rem"
                                color={
                                    mobileStatus === 'Validation Error'
                                        ? 'red'
                                        : 'light.300'
                                }
                                pr="6px"
                            >
                                Mobile number must be formatted like
                            </Text>
                            <Text
                                fontSize="0.8rem"
                                color={
                                    mobileStatus === 'Validation Error'
                                        ? 'red'
                                        : 'light.400'
                                }
                                letterSpacing="0.8px"
                            >
                                02########
                            </Text>
                        </Flex>
                        <Text
                            fontSize="0.8rem"
                            color={
                                mobileStatus === 'Validation Error'
                                    ? 'red'
                                    : 'light.300'
                            }
                        >
                            with or without the leading '0'
                        </Text>

                        {mobileStatus === 'Validation Error' && (
                            <Text fontSize="0.8rem" color="red">
                                Please ensure there are no spaces between
                                numbers and is the correct length
                            </Text>
                        )}
                    </Flex>
                </Flex>
            )}
        </>
    )
}
