import React from 'react'
import {
    Flex,
    Icon,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Link,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { ReactComponent as MobileSMSIcon } from '../../../icons/assets/mobile-sms.svg'
import { ReactComponent as MobileAuthenticatorIcon } from '../../../icons/assets/mobile-authenticator.svg'
import { ReactComponent as EmailIcon } from '../../../icons/assets/email.svg'
import { RiStarLine } from 'react-icons/ri'
import Authenticator from './Authenticator'
import Mobile from './Mobile'

export default function Options({
    profileData,
    userMetaData,
    mobileNumber,
    setMobileNumber,
    mobileStatus,
    mobileCallback,
    expandedDropdowns,
    setExpandedDropdowns,
    accordionCollapsed,
    binding,
    setBinding,
    setTotpFactor,
    authenticatorSelected,
    mfaPreviouslyVerified,
    mfaVerified,
    mfaTimeToExpire,
}) {
    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none' },
    }

    return (
        <Flex
            flexDirection="column"
            height="100%"
            width="100%"
            justifyContent="center"
            bg="white"
        >
            <Flex
                flexDirection="column"
                width="100%"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                pt={
                    accordionCollapsed && !mfaPreviouslyVerified
                        ? '20px'
                        : accordionCollapsed
                        ? '15px'
                        : '0'
                }
                pb={accordionCollapsed ? '12px' : '0'}
                minHeight={
                    accordionCollapsed &&
                    (!mfaVerified || mfaTimeToExpire === 'EXPIRED') &&
                    mfaPreviouslyVerified
                        ? '110px'
                        : accordionCollapsed && !mfaPreviouslyVerified
                        ? '85px'
                        : accordionCollapsed && mfaPreviouslyVerified
                        ? '50px'
                        : '0'
                }
                maxHeight={accordionCollapsed ? 'auto' : '0'}
                opacity={accordionCollapsed ? '1' : '0'}
                transition="all ease 300ms"
                borderBottom={
                    accordionCollapsed
                        ? '1px solid #E5E9F0'
                        : '0ps solid #E5E9F0'
                }
            >
                {mfaPreviouslyVerified ? (
                    <>
                        {(!mfaVerified || mfaTimeToExpire === 'EXPIRED') && (
                            <Flex
                                flexDirection="column"
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Text
                                    zIndex="1"
                                    pb={accordionCollapsed ? '2px' : '0'}
                                    transition="all ease 300ms"
                                    fontSize="0.9rem"
                                    color="red.500"
                                    fontWeight="500"
                                >
                                    {!mfaVerified
                                        ? 'Your MFA has expired'
                                        : 'Your MFA will expire after this session'}
                                </Text>
                                <Text
                                    zIndex="1"
                                    pb={accordionCollapsed ? '15px' : '0'}
                                    transition="all ease 300ms"
                                    fontSize="0.8rem"
                                    color="gray.400"
                                    fontWeight="400"
                                    textAlign="center"
                                >
                                    {!mfaVerified
                                        ? 'Reset it now to reactivate your premium services'
                                        : 'Reset it now to avoid disruption to your premium services'}
                                </Text>
                            </Flex>
                        )}
                    </>
                ) : (
                    <Flex
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                        cursor="default"
                        fontSize={{ base: '0.8rem', md: '0.8rem' }}
                        fontWeight="400"
                        color="gray.400"
                        px="15px"
                        pb={accordionCollapsed ? '15px' : '0'}
                        transition="all ease 300ms"
                    >
                        <Flex
                            height="18px"
                            color="rgba(0, 188, 248, 1)"
                            borderRadius="20px"
                            fontSize="0.9rem"
                            fontWeight="500"
                            justifyContent="center"
                            alignItems="center"
                            gap="2px"
                            mr="4px"
                        >
                            <RiStarLine
                                color="rgba(0, 188, 248, 1)"
                                fontSize="1rem"
                            />
                            PREMIUM
                        </Flex>
                        <Text zIndex="1" fontSize="0.9rem" textAlign="center">
                            accounts require Multi-factor Authentication
                        </Text>
                    </Flex>
                )}
                <Text
                    zIndex="1"
                    cursor="default"
                    fontSize={{ base: '0.8rem', md: '0.9rem' }}
                    fontWeight="400"
                    color="gray.500"
                >
                    Select a verification method below
                </Text>
            </Flex>

            <Accordion
                // defaultIndex={0}
                zIndex="2"
                fontSize={{
                    base: '0.8rem',
                    md: '0.8rem',
                }}
                color="light.100"
                className="terms"
                overflow="hidden"
                allowToggle
                index={expandedDropdowns}
                onChange={(expandedIndex) => {
                    setExpandedDropdowns(expandedIndex)
                }}
                // minHeight="300px"
                px={0}
                pt="0px"
                pb="0px"
                width="100%"
            >
                {/* SMS */}
                <AccordionItem border={'none'}>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton
                                {...noFocus}
                                height={{
                                    base: accordionCollapsed ? '100px' : '50px',
                                    md: accordionCollapsed ? '100px' : '50px',
                                }}
                                transition="all ease 300ms"
                                width="100%"
                                borderBottom={'1px solid'}
                                borderColor="gray.200"
                                fontSize="0.8rem"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Flex
                                        width={
                                            accordionCollapsed ? '60px' : '40px'
                                        }
                                        minWidth={
                                            accordionCollapsed ? '60px' : '40px'
                                        }
                                        justifyContent="center"
                                        alignItems="center"
                                        color={
                                            accordionCollapsed || isExpanded
                                                ? 'gray.500'
                                                : 'gray.400'
                                        }
                                        pl={
                                            !accordionCollapsed || isExpanded
                                                ? '10px'
                                                : '0px'
                                        }
                                        transition="all ease 300ms"
                                    >
                                        <Icon
                                            as={MobileSMSIcon}
                                            opacity={
                                                accordionCollapsed || isExpanded
                                                    ? '1'
                                                    : '0.9'
                                            }
                                            boxSize={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '30px'
                                                    : '45px'
                                            }
                                            transition="all ease 300ms"
                                        />
                                    </Flex>
                                    <Flex
                                        pl="10px"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                    >
                                        <Text
                                            color={
                                                accordionCollapsed || isExpanded
                                                    ? 'light.200'
                                                    : 'gray.400'
                                            }
                                            fontWeight="600"
                                            fontSize={
                                                accordionCollapsed || isExpanded
                                                    ? '1rem'
                                                    : '0.9rem'
                                            }
                                            transition="all ease 300ms"
                                            width="100%"
                                            textAlign="left"
                                            lineHeight="1.4"
                                            fontFamily={
                                                "'Poppins', 'Open Sans', sans-serif"
                                            }
                                        >
                                            SMS
                                        </Text>
                                        <Text
                                            minHeight={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0px'
                                                    : '20px'
                                            }
                                            height={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0px'
                                                    : 'auto'
                                            }
                                            opacity={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0'
                                                    : '1'
                                            }
                                            transition="all ease 300ms"
                                            color="gray.500"
                                            fontWeight="400"
                                            fontSize="0.8rem"
                                            width="100%"
                                            textAlign="left"
                                        >
                                            Get a <strong>text message</strong>{' '}
                                            with verification code
                                        </Text>
                                    </Flex>
                                </Flex>
                                <ChevronRightIcon
                                    w="40px"
                                    h="20px"
                                    minW="40px"
                                    transform={isExpanded && 'rotate(90deg)'}
                                    style={{
                                        transition: 'all 0.3s',
                                    }}
                                    fontSize="1.6rem"
                                    color={
                                        isExpanded ? 'light.200' : 'gray.400'
                                    }
                                    bgColor="transparent"
                                    {...noFocus}
                                />
                            </AccordionButton>
                            <AccordionPanel
                                borderBottom={
                                    isExpanded ? '1px solid' : '0px solid'
                                }
                                borderColor="gray.200"
                                background="light.40"
                                boxShadow={
                                    'inset rgba(0, 0, 0, 0.05) 0px -14px 11px -10px'
                                }
                                p="0px"
                            >
                                <Flex
                                    px="2"
                                    pb="6"
                                    height="100%"
                                    width="100%"
                                    clipPath="inset(0px 0px -50px)"
                                    boxShadow={
                                        isExpanded
                                            ? 'inset rgba(0, 0, 0, 0.05) 0px 14px 11px -10px'
                                            : 'none'
                                    }
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Mobile
                                        mobileNumber={mobileNumber}
                                        setMobileNumber={setMobileNumber}
                                        mobileStatus={mobileStatus}
                                        mobileCallback={mobileCallback}
                                        userMetaData={userMetaData}
                                    />
                                </Flex>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>

                {/* AUTHENTICATOR */}
                <AccordionItem border={'none'}>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton
                                {...noFocus}
                                height={{
                                    base: accordionCollapsed ? '100px' : '50px',
                                    md: accordionCollapsed ? '100px' : '50px',
                                }}
                                transition="all ease 300ms"
                                width="100%"
                                borderBottom={'1px solid'}
                                borderColor="gray.200"
                                fontSize="0.8rem"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Flex
                                        width={
                                            accordionCollapsed ? '60px' : '40px'
                                        }
                                        minWidth={
                                            accordionCollapsed ? '60px' : '40px'
                                        }
                                        justifyContent="center"
                                        alignItems="center"
                                        color={
                                            accordionCollapsed || isExpanded
                                                ? 'gray.500'
                                                : 'gray.400'
                                        }
                                        pl={
                                            !accordionCollapsed || isExpanded
                                                ? '10px'
                                                : '0px'
                                        }
                                        transition="all ease 300ms"
                                    >
                                        <Icon
                                            as={MobileAuthenticatorIcon}
                                            opacity={
                                                accordionCollapsed || isExpanded
                                                    ? '1'
                                                    : '0.9'
                                            }
                                            boxSize={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '30px'
                                                    : '45px'
                                            }
                                            transition="all ease 300ms"
                                        />
                                    </Flex>
                                    <Flex
                                        pl="10px"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                    >
                                        <Text
                                            color={
                                                accordionCollapsed || isExpanded
                                                    ? 'light.200'
                                                    : 'gray.400'
                                            }
                                            fontWeight="600"
                                            fontSize={
                                                accordionCollapsed || isExpanded
                                                    ? '1rem'
                                                    : '0.9rem'
                                            }
                                            transition="all ease 300ms"
                                            width="100%"
                                            textAlign="left"
                                            lineHeight="1.4"
                                            fontFamily={
                                                "'Poppins', 'Open Sans', sans-serif"
                                            }
                                        >
                                            Authenticator
                                        </Text>
                                        <Text
                                            minHeight={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0px'
                                                    : '20px'
                                            }
                                            height={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0px'
                                                    : 'auto'
                                            }
                                            opacity={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0'
                                                    : '1'
                                            }
                                            transition="all ease 300ms"
                                            color="gray.500"
                                            fontWeight="400"
                                            fontSize="0.8rem"
                                            width="100%"
                                            textAlign="left"
                                        >
                                            Get a verification code from an{' '}
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Authenticator app
                                            </span>
                                        </Text>
                                    </Flex>
                                </Flex>
                                <ChevronRightIcon
                                    w="40px"
                                    h="20px"
                                    minW="40px"
                                    transform={isExpanded && 'rotate(90deg)'}
                                    style={{
                                        transition: 'all 0.3s',
                                    }}
                                    fontSize="1.6rem"
                                    color={
                                        isExpanded ? 'light.200' : 'gray.400'
                                    }
                                    bgColor="transparent"
                                    {...noFocus}
                                />
                            </AccordionButton>
                            <AccordionPanel
                                p="0px"
                                borderBottom={
                                    isExpanded ? '1px solid' : '0px solid'
                                }
                                borderColor="gray.200"
                                background="light.40"
                                boxShadow={
                                    'inset rgba(0, 0, 0, 0.05) 0px -14px 11px -10px'
                                }
                            >
                                <Flex
                                    px="2"
                                    pt="6"
                                    pb="8"
                                    height="100%"
                                    width="100%"
                                    clipPath="inset(0px 0px -50px)"
                                    boxShadow={
                                        isExpanded
                                            ? 'inset rgba(0, 0, 0, 0.05) 0px 14px 11px -10px'
                                            : 'none'
                                    }
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                >
                                    {/* <Text
                                        color="gray.500"
                                        fontSize="0.8rem"
                                        px="20px"
                                        pb="15px"
                                    >
                                        Enter your Authenticator code. Or
                                        follow the set up steps below:
                                    </Text>
                                    <Flex
                                        paddingLeft="35px"
                                        width="100%"
                                        gap="5px"
                                        flexDirection="column"
                                        pr="15px"
                                        fontSize={{
                                            base: '0.8rem',
                                            md: '0.9rem',
                                        }}
                                        fontWeight="400"
                                        color="gray.600"
                                        textAlign="left"
                                    >
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Get the{' '}
                                                <strong>
                                                    Authenticator App
                                                </strong>{' '}
                                                from the{' '}
                                                <strong>App Store</strong> or{' '}
                                                <strong>
                                                    Google Play Store
                                                </strong>
                                                .
                                            </li>
                                        </ul>
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                In the Authenticator App select{' '}
                                                <strong>Set up Account</strong>.
                                            </li>
                                        </ul>
                                        <ul>
                                            <li
                                                style={{
                                                    paddingBottom: '5px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Choose{' '}
                                                <strong>Scan barcode</strong>.
                                            </li>
                                        </ul>
                                    </Flex> */}

                                    <Authenticator
                                        binding={binding}
                                        setBinding={setBinding}
                                        setTotpFactor={setTotpFactor}
                                        authenticatorSelected={
                                            authenticatorSelected
                                        }
                                    />

                                    <Text
                                        color="gray.500"
                                        fontSize="0.8rem"
                                        px="20px"
                                    >
                                        If you are having issues, please try an
                                        alternative verification method, or{' '}
                                        <Link
                                            color="light.200"
                                            href="mailto:helpdesk@airwaysinternational.co.nz?subject=Issues with PreFlight Authenticator Verification"
                                        >
                                            contact support
                                        </Link>
                                        .
                                    </Text>
                                </Flex>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>

                {/* EMAIL */}
                <AccordionItem border={'none'}>
                    {({ isExpanded }) => (
                        <>
                            <AccordionButton
                                {...noFocus}
                                height={{
                                    base: accordionCollapsed ? '100px' : '50px',
                                    md: accordionCollapsed ? '100px' : '50px',
                                }}
                                transition="all ease 300ms"
                                width="100%"
                                fontSize="0.8rem"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Flex
                                        width={
                                            accordionCollapsed ? '60px' : '40px'
                                        }
                                        minWidth={
                                            accordionCollapsed ? '60px' : '40px'
                                        }
                                        justifyContent="center"
                                        alignItems="center"
                                        color={
                                            accordionCollapsed || isExpanded
                                                ? 'gray.500'
                                                : 'gray.400'
                                        }
                                        pl={
                                            !accordionCollapsed || isExpanded
                                                ? '10px'
                                                : '0px'
                                        }
                                        transition="all ease 300ms"
                                    >
                                        <Icon
                                            as={EmailIcon}
                                            opacity={
                                                accordionCollapsed || isExpanded
                                                    ? '1'
                                                    : '0.9'
                                            }
                                            boxSize={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '30px'
                                                    : '45px'
                                            }
                                            transition="all ease 300ms"
                                        />
                                    </Flex>
                                    <Flex
                                        pl="10px"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                    >
                                        <Text
                                            color={
                                                accordionCollapsed || isExpanded
                                                    ? 'light.200'
                                                    : 'gray.400'
                                            }
                                            fontWeight="600"
                                            fontSize={
                                                accordionCollapsed || isExpanded
                                                    ? '1rem'
                                                    : '0.9rem'
                                            }
                                            transition="all ease 300ms"
                                            width="100%"
                                            textAlign="left"
                                            lineHeight="1.4"
                                            fontFamily={
                                                "'Poppins', 'Open Sans', sans-serif"
                                            }
                                        >
                                            Email
                                        </Text>
                                        <Text
                                            minHeight={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0px'
                                                    : '20px'
                                            }
                                            height={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0px'
                                                    : 'auto'
                                            }
                                            opacity={
                                                !accordionCollapsed ||
                                                isExpanded
                                                    ? '0'
                                                    : '1'
                                            }
                                            transition="all ease 300ms"
                                            color="gray.500"
                                            fontWeight="400"
                                            fontSize="0.8rem"
                                            width="100%"
                                            textAlign="left"
                                        >
                                            Get a verification code sent to your{' '}
                                            <strong>email</strong>
                                        </Text>
                                    </Flex>
                                </Flex>
                                <ChevronRightIcon
                                    w="40px"
                                    h="20px"
                                    minW="40px"
                                    transform={isExpanded && 'rotate(90deg)'}
                                    style={{
                                        transition: 'all 0.3s',
                                    }}
                                    fontSize="1.6rem"
                                    color={
                                        isExpanded ? 'light.200' : 'gray.400'
                                    }
                                    bgColor="transparent"
                                    {...noFocus}
                                />
                            </AccordionButton>
                            <AccordionPanel
                                p="0px"
                                background="light.40"
                                boxShadow={
                                    'inset rgba(0, 0, 0, 0.05) 0px -14px 11px -10px'
                                }
                                borderTop={'1px solid'}
                                borderColor="gray.200"
                            >
                                <Flex
                                    px="6"
                                    pt="6"
                                    pb="8"
                                    height="100%"
                                    width="100%"
                                    clipPath="inset(0px 0px -50px)"
                                    boxShadow={
                                        isExpanded
                                            ? 'inset rgba(0, 0, 0, 0.05) 0px 14px 11px -10px'
                                            : 'none'
                                    }
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    fontSize={{
                                        base: '0.9rem',
                                        md: '0.9rem',
                                    }}
                                    fontWeight="400"
                                    color="gray.600"
                                    gap="5px"
                                >
                                    <Text>Send a verification code to:</Text>
                                    <Text
                                        fontWeight="600"
                                        fontSize={{
                                            base: '0.85rem',
                                            md: '0.95rem',
                                        }}
                                    >
                                        {profileData && profileData.email}
                                    </Text>
                                    <Text
                                        pt="25px"
                                        color="gray.500"
                                        fontSize="0.8rem"
                                    >
                                        If you don't receive it within a couple
                                        of minutes, you may need to check your
                                        junk/spam folder.
                                    </Text>
                                    <Text color="gray.500" fontSize="0.8rem">
                                        Additionally, please note that some
                                        corporate firewalls or VPN connections
                                        may also prevent the delivery of emails
                                        from PreFlight.
                                    </Text>
                                    <Text
                                        color="gray.500"
                                        fontSize="0.8rem"
                                        pt="25px"
                                    >
                                        If you are still having issues, please
                                        try an alternative verification method,
                                        or{' '}
                                        <Link
                                            color="light.200"
                                            href="mailto:helpdesk@airwaysinternational.co.nz?subject=Issues with PreFlight Email Verification"
                                        >
                                            contact support
                                        </Link>
                                        .
                                    </Text>
                                </Flex>
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            </Accordion>
        </Flex>
    )
}
