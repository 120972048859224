import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
    Modal,
    VStack,
    Button,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
    Flex,
    useDisclosure,
    Text,
    LightMode,
    useToast,
    Portal,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useUserMetaData } from '../../../auth/hooks'
import { isDesktop } from 'react-device-detect'
import {
    setModalVisibilityState,
    modalVisibilityState,
    tourVisibilityState,
} from '../../../globalState'
import AnnouncementItems from './components/AnnouncementItems'

/**** Update version number when updating Announcements ****/
const versionNumber = '004'

/**** Update version date when updating Announcements ****/
const versionDate = '1 February 2024'

export default function AnnouncementsModal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { getAccessTokenSilently } = useAuth0()
    const userMetaData = useUserMetaData()
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const introTour = useRecoilValue(tourVisibilityState)
    const [loading, setLoading] = useState(false)
    const [versionOutOfDate, setVersionOutOfDate] = useState(false)
    const listInnerRef = useRef()

    const initialModals = // modals that may open on page load
        modalVisibility.terms || modalVisibility.loginMessage || introTour

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'announcements', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        if (userMetaData && !initialModals) {
            if (
                !userMetaData.announcements_version ||
                userMetaData.announcements_version !== versionNumber
            ) {
                console.log('New announcements')
                setVersionOutOfDate(true)
                setShow(true)
            }
        }
    }, [setShow, userMetaData, initialModals])

    const toast = useToast()

    useEffect(() => {
        if (!initialModals && modalVisibility.announcements) {
            onOpen()
        } else {
            onClose()
        }
    }, [modalVisibility, versionOutOfDate, initialModals, onOpen, onClose])

    const announcementsCallback = async () => {
        setLoading(true)
        const accessToken = await getAccessTokenSilently()
        fetch(`${window.location.origin}/user/updateannouncementsversion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ announcementsVersion: versionNumber }),
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                setVersionOutOfDate(false)
                setShow(false)
                onClose()
            } else {
                setLoading(false)
                console.log('Error setting announcements version number')
                toast({
                    title: `Error connecting with our databases.`,
                    description:
                        'It looks like there was an error connecting to our server, please try again later',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
        })
    }

    function onCloseHandler() {
        if (versionOutOfDate) {
            announcementsCallback()
        } else {
            setShow(false)
        }
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    // console.log(userMetaData && userMetaData.announcements_version, 'version')

    return (
        <>
            <Portal>
                <LightMode>
                    <Modal
                        variant="announcements"
                        size="6xl"
                        onClose={onCloseHandler}
                        isOpen={isOpen}
                        // closeOnOverlayClick={false}
                        isCentered
                        scrollBehavior="inside"
                        trapFocus={false}
                    >
                        <ModalOverlay
                            backdropFilter={!isDesktop && 'blur(3px)'}
                        />
                        <ModalContent
                            maxHeight={{
                                base: 'calc(100vh - 70px)',
                                md: 'calc(100vh - 60px)',
                            }}
                        >
                            <ModalCloseButton
                                color="light.10"
                                zIndex="11"
                                mt={{ base: '-2px', md: '0px' }}
                                mr="2px"
                            />
                            <ModalHeader
                                zIndex="1"
                                textAlign="center"
                                backgroundImage="linear-gradient(345deg, #00DB9E 10%, #008D6C 90%)"
                                color="white"
                                boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                            >
                                <Flex
                                    alignItems="baseline"
                                    justifyContent="center"
                                    pr="10px"
                                >
                                    <Text
                                        pr="6px"
                                        fontSize={{
                                            base: '1.2rem',
                                            smd: '1.4rem',
                                        }}
                                        fontWeight="400"
                                    >
                                        What's new in
                                    </Text>
                                    <Flex>
                                        <Text
                                            fontSize={{
                                                base: '1.2rem',
                                                smd: '1.4rem',
                                            }}
                                            color={'dark.80'}
                                            fontWeight="300"
                                            letterSpacing="0.5px"
                                            alignSelf="center"
                                        >
                                            Pre
                                        </Text>
                                        <Text
                                            fontSize={{
                                                base: '1.2rem',
                                                smd: '1.4rem',
                                            }}
                                            ml="0"
                                            color={'dark.80'}
                                            fontWeight="500"
                                            letterSpacing="0.5px"
                                            alignSelf="center"
                                        >
                                            Flight
                                        </Text>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                            <ModalBody
                                style={{ padding: 0 }}
                                fontSize="0.8rem"
                                fontFamily="'Poppins','Open Sans',sans-serif"
                            >
                                <VStack
                                    className="announcements"
                                    overflowY="auto"
                                    overflowX="hidden"
                                    px={{ base: 2, md: 8 }}
                                    pt={{ base: 3, md: 5 }}
                                    pb="70px"
                                    spacing={3}
                                    alignItems="baseline"
                                    // onScroll={onScroll}
                                    ref={listInnerRef}
                                >
                                    <Container
                                        width="100%"
                                        paddingInlineStart="0px"
                                        paddingInlineEnd="0px"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                        maxW={{
                                            base: '100%',
                                            '2xl': '1000px',
                                            '3xl': '1400px',
                                        }}
                                        mt="15px"
                                    >
                                        {/* {loading && (
                                        <Flex
                                            flexDirection="column"
                                            // width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            height="50px"
                                        >
                                            <Spinner
                                                color="gray.400"
                                                boxSize="35px"
                                            />
                                        </Flex>
                                    )} */}
                                        {/* {versionOutOfDate && ( // used to give indication version number is out of date (reinstate later after removing feedback message) */}
                                        <Flex
                                            flexDirection="column"
                                            mt={{ base: '0px', md: '5px' }}
                                            px={{
                                                base: '10px',
                                                xl: '30px',
                                            }}
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Flex
                                                flexDirection="column"
                                                pt="15px"
                                                pb={{
                                                    base: '20px',
                                                    md: '25px',
                                                }}
                                                px={{
                                                    base: '15px',
                                                    md: '30px',
                                                }}
                                                width="100%"
                                                justifyContent="center"
                                                alignItems="center"
                                                fontSize={{
                                                    base: '0.8rem',
                                                    md: '0.85rem',
                                                }}
                                                border="1px solid"
                                                borderColor="gray.200"
                                                background="light.40"
                                            >
                                                <Text
                                                    textAlign="center"
                                                    color="gray.600"
                                                    fontSize={{
                                                        base: '0.9rem',
                                                        md: '1.1rem',
                                                    }}
                                                    fontWeight="500"
                                                >
                                                    Your Feedback
                                                </Text>
                                                <Text
                                                    textAlign="center"
                                                    pt="10px"
                                                    color="gray.500"
                                                >
                                                    Since its launch in late
                                                    2022, PreFlight has garnered
                                                    overwhelmingly positive
                                                    feedback. Users have
                                                    particularly praised the
                                                    modern and user-friendly
                                                    features, which effectively
                                                    articulate the
                                                    meteorological and
                                                    aeronautical information.
                                                    This success reinforces the
                                                    key premise behind the
                                                    development of PreFlight.
                                                </Text>
                                                <Text
                                                    textAlign="center"
                                                    pt="10px"
                                                    color="gray.500"
                                                >
                                                    We highly value all the
                                                    feedback we've received and
                                                    have been diligently
                                                    incorporating it into our
                                                    ongoing development. Over
                                                    recent months, we've
                                                    implemented numerous small
                                                    enhancements, tweaks, and
                                                    bug fixes to enhance the
                                                    user experience. Notably,
                                                    we've also made significant
                                                    improvements to further
                                                    optimise the usability of
                                                    PreFlight.
                                                </Text>
                                            </Flex>
                                        </Flex>
                                        <Text
                                            pt={{ base: '45px', md: '60px' }}
                                            pb="20px"
                                            textAlign="center"
                                            color="gray.600"
                                            fontSize={{
                                                base: '0.9rem',
                                                md: '1.1rem',
                                            }}
                                            fontWeight="500"
                                        >
                                            See what we've recently added to
                                            PreFlight
                                        </Text>
                                        {/* )} */}
                                        <AnnouncementItems
                                            versionDate={versionDate}
                                            versionOutOfDate={versionOutOfDate}
                                            onCloseHandler={onCloseHandler}
                                        />
                                    </Container>
                                </VStack>
                            </ModalBody>
                            <ModalFooter
                                borderTop="1px solid #e5e7e9"
                                pt={4}
                                pb={4}
                            >
                                <Flex
                                    flexDirection="column"
                                    width="100%"
                                    overflow="hidden"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Button
                                        {...noFocus}
                                        w="100%"
                                        size="sm"
                                        fontSize="1rem"
                                        maxWidth="180px"
                                        variant="primary"
                                        isLoading={loading}
                                        onClick={onCloseHandler}
                                        bg="light.200"
                                        _hover={{ background: 'light.201' }}
                                        _focus={{ background: 'light.201' }}
                                        _active={{ background: 'light.201' }}
                                        style={{ borderRadius: '10px' }}
                                    >
                                        {'Close'}
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </LightMode>
            </Portal>
        </>
    )
}
