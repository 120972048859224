import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { Source, Layer, MapContext } from 'react-mapbox-gl'
import { debounce } from 'lodash'
import {
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    VectorLayerRefreshInfo,
    layerSelectionState,
} from '../../../../globalState'
import calcAirspaceLabelPlacement from './functions/calcAirspaceLabelPlacement'
import airspaceLabelDefs from './definitions/airspace-labels.json'
import buildCtaLabels from './functions/buildCtaLabels'

export default function AirspaceLayer() {
    const { getAccessTokenSilently } = useAuth0()
    const layerData = useRecoilValue(VectorLayerRefreshInfo)
    const selectedLowerAltitude = useRecoilValue(selectedLowerAltitudeState)
    const selectedUpperAltitude = useRecoilValue(selectedUpperAltitudeState)
    const selectedLayers = useRecoilValue(layerSelectionState)
    const mapInstance = useContext(MapContext)

    const [labelData, setLabelData] = useState({
        type: 'FeatureCollection',
        features: [],
    })

    const fetchAirspaceData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(layerData.airspace.dataURL, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }, [getAccessTokenSilently, layerData.airspace.dataURL])

    const { data } = useQuery('airspaceData', fetchAirspaceData, {
        refetchOnWindowFocus: false,
        initialData: {
            type: 'FeatureCollection',
            features: [],
        },
    })

    // dynamic labelling
    useEffect(() => {
        const getLabelPlacement = () => {
            return calcAirspaceLabelPlacement(mapInstance, data, 'id')
        }

        const moveEndCallback = () => {
            var tileLoad = setInterval(function () {
                if (mapInstance.loaded()) {
                    setLabelData(
                        buildCtaLabels(
                            getLabelPlacement(),
                            selectedLowerAltitude,
                            selectedUpperAltitude
                        )
                    )
                    clearInterval(tileLoad)
                }
            }, 300)
        }

        mapInstance.on('moveend', moveEndCallback)

        function renderCallback(e) {
            if (e.target && e.target.loaded()) {
                if (mapInstance.getZoom() > 7) {
                    setLabelData(
                        buildCtaLabels(
                            getLabelPlacement(),
                            selectedLowerAltitude,
                            selectedUpperAltitude
                        )
                    )
                    mapInstance.off('render', renderCallback)
                }
            }
        }

        const debounceRender = debounce(
            () => mapInstance.on('render', renderCallback),
            50
        )

        debounceRender()

        return () => {
            mapInstance.off('moveend', moveEndCallback)
        }
    }, [data, mapInstance, selectedLowerAltitude, selectedUpperAltitude])

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        3,
        ['match', ['get', 'type'], 'FIR', 0, 0],
        4,
        ['match', ['get', 'type'], 'CTA', 0.4, 'FIR', 1, 0.2],
        5,
        ['match', ['get', 'type'], 'CTA', 0.7, 'FIR', 1, 0.3],
        6,
        [
            'match',
            ['get', 'type'],
            'FIR',
            1,
            'CTA',
            0.8,
            'GAA',
            ['case', ['>=', ['get', 'upperfilter'], 9500], 0.6, 0],
            0.4,
        ],
        7,
        [
            'match',
            ['get', 'type'],
            [
                'CTA_LABEL',
                'MBZ',
                'MBZ_PART',
                'CFZ',
                'R',
                'MOA',
                'D',
                'VHZ',
                'T',
                'LFZ',
                'FIR',
                'CTA',
            ],
            1,
            'CTR',
            ['case', ['!=', ['geometry-type'], 'LineString'], 1, 0],
            'GAA',
            ['case', ['>=', ['get', 'upperfilter'], 9500], 0.8, 0.3],
            0,
        ],
        8,
        [
            'match',
            ['get', 'type'],
            ['FIR', 'CTR_SECTOR'],
            0,
            'CTR',
            ['case', ['!=', ['geometry-type'], 'LineString'], 1, 0],
            'GAA',
            ['case', ['>=', ['get', 'upperfilter'], 9500], 1, 0.8],
            1,
        ],
        10,
        ['match', ['get', 'type'], ['FIR'], 0, 1],
    ]

    const zoomLabelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        7,
        ['match', ['get', 'type'], 'FIR', 1, 0],
        8,
        ['match', ['get', 'type'], ['FISCOM', 'CTA_LABEL', 'CTR'], 1, 0],
        8.5,
        [
            'match',
            ['get', 'type'],
            [
                'FISCOM',
                'CTA_LABEL',
                'CTR',
                'MBZ',
                'MBZ_PART',
                'CFZ',
                'R',
                'MOA',
                'D',
                'VHZ',
            ],
            1,
            'GAA',
            ['case', ['>=', ['get', 'upperfilter'], 9500], 1, 0],
            0,
        ],
        9,
        ['match', ['get', 'type'], 'FIR', 0, 1],
        11,
        [
            'match',
            ['get', 'type'],
            ['FISCOM', 'CTA_LABEL', 'CTR', 'MBZ', 'MBZ_PART', 'CFZ'],
            1,
            1,
        ],
        11.5,
        [
            'match',
            ['get', 'type'],
            ['FISCOM', 'CTA_LABEL', 'CTR', 'MBZ', 'MBZ_PART', 'CFZ'],
            1,
            1,
        ],
        12,
        [
            'match',
            ['get', 'type'],
            ['MBZ', 'MBZ_PART'],
            1,
            ['FISCOM', 'CTA_LABEL'],
            0.4,
            1,
        ],
        12.5,
        [
            'match',
            ['get', 'type'],
            ['MBZ', 'MBZ_PART'],
            1,
            ['FISCOM', 'CTA_LABEL'],
            0,
            1,
        ],
        13,
        [
            'match',
            ['get', 'type'],
            ['MBZ', 'MBZ_PART'],
            0.4,
            ['FISCOM', 'CTA_LABEL'],
            0,
            1,
        ],
        13.5,
        [
            'match',
            ['get', 'type'],
            ['FISCOM', 'CTR', 'CTA_LABEL', 'MBZ', 'MBZ_PART', 'CFZ'],
            0,
            1,
        ],
        14,
        [
            'match',
            ['get', 'type'],
            [
                'FISCOM',
                'CTR',
                'CTA_LABEL',
                'MBZ',
                'MBZ_PART',
                'CFZ',
                'CTR_SECTOR',
            ],
            0,
            1,
        ],
    ]

    const lineColourDefinition = [
        'match',
        ['get', 'type'],
        ['CTA'],
        '#92278F',
        ['CTR', 'CTR_SECTOR', 'MBZ', 'MBZ_PART', 'CFZ'],
        '#0072BC',
        ['GAA', 'T', 'LFZ'],
        '#7BAFDE',
        ['R', 'MOA', 'D', 'VHZ'],
        '#FF0000',
        '#999999',
    ]

    const linePatternDefinition = [
        'match',
        ['get', 'type'],
        ['MBZ', 'MBZ_PART'],
        'mbz',
        'CFZ',
        'cfz',
        '',
    ]

    const lineOffsetDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        4,
        [
            'match',
            ['get', 'type'],
            'CTR',
            0,
            'CFZ',
            0,
            'MBZ',
            0,
            'GAA',
            0,
            'T',
            1,
            0,
        ],
        5,
        [
            'match',
            ['get', 'type'],
            'CTR',
            1,
            'CFZ',
            0,
            'MBZ',
            0,
            'GAA',
            0.5,
            'T',
            1,
            0,
        ],
        6,
        [
            'match',
            ['get', 'type'],
            'CTR',
            1,
            'CFZ',
            0,
            'MBZ',
            0,
            'GAA',
            1,
            'T',
            1,
            0,
        ],
        7,
        [
            'match',
            ['get', 'type'],
            'CTR',
            1,
            'CFZ',
            1,
            'MBZ',
            1,
            'GAA',
            1.2,
            'T',
            1.2,
            0,
        ],
        8,
        [
            'match',
            ['get', 'type'],
            'CTR',
            2,
            'CFZ',
            2,
            'MBZ',
            1.5,
            'GAA',
            2,
            'T',
            3,
            'LFZ',
            1,
            'VHZ',
            1,
            'MOA',
            0.5,
            0,
        ],
        9,
        [
            'match',
            ['get', 'type'],
            'CTR',
            2.5,
            'CFZ',
            2,
            'MBZ',
            3,
            'GAA',
            3,
            'T',
            3.5,
            'LFZ',
            2,
            'VHZ',
            2,
            'MOA',
            1,
            0,
        ],
        10,
        [
            'match',
            ['get', 'type'],
            'CTR',
            2.5,
            'CFZ',
            3,
            'MBZ',
            6,
            'GAA',
            4,
            'T',
            5,
            'LFZ',
            3,
            'VHZ',
            2,
            'MOA',
            1,
            0,
        ],
        11,
        [
            'match',
            ['get', 'type'],
            'CTR',
            2.5,
            'CFZ',
            3,
            'MBZ',
            7,
            'GAA',
            3,
            'T',
            7,
            'LFZ',
            3,
            'VHZ',
            2,
            'MOA',
            1,
            0,
        ],
    ]

    const lineWidthDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        4,
        ['match', ['get', 'type'], 'CTA', 0.2, 'FIR', 1, 0.2],
        5,
        [
            'match',
            ['get', 'type'],
            'FIR',
            1.3,
            'CTA',
            0.4,
            'CTR',
            1,
            ['MBZ', 'MBZ_PART'],
            2,
            'CFZ',
            3,
            'GAA',
            1,
            'T',
            1,
            'R',
            0.4,
            'MOA',
            0.4,
            'D',
            0.4,
            'VHZ',
            0.4,
            'LFZ',
            1,
            1,
        ],
        7,
        [
            'match',
            ['get', 'type'],
            'FIR',
            1.5,
            'CTA',
            1,
            'CTR',
            ['case', ['!=', ['geometry-type'], 'LineString'], 1, 1],
            ['MBZ', 'MBZ_PART'],
            4,
            'CFZ',
            6,
            'GAA',
            3,
            'T',
            2.5,
            'R',
            1,
            'MOA',
            1,
            'D',
            1,
            'VHZ',
            1,
            'LFZ',
            2,
            1.5,
        ],
        8,
        [
            'match',
            ['get', 'type'],
            'FIR',
            1.5,
            'CTA',
            2,
            'CTR',
            ['case', ['!=', ['geometry-type'], 'LineString'], 2, 1],
            ['MBZ', 'MBZ_PART'],
            6,
            'CFZ',
            8,
            'GAA',
            5,
            'T',
            6,
            'R',
            1.5,
            'MOA',
            1.5,
            'D',
            1.5,
            'VHZ',
            2,
            'LFZ',
            2.5,
            2,
        ],
        9,
        [
            'match',
            ['get', 'type'],
            'CTA',
            2.5,
            'CTR',
            ['case', ['!=', ['geometry-type'], 'LineString'], 3, 1.5],
            ['MBZ', 'MBZ_PART'],
            8,
            'CFZ',
            8,
            'GAA',
            6,
            'T',
            6.5,
            'R',
            2,
            'MOA',
            2,
            'D',
            2,
            'VHZ',
            2.5,
            'LFZ',
            4,
            2,
        ],
        10,
        [
            'match',
            ['get', 'type'],
            'CTA',
            2.5,
            'CTR',
            ['case', ['!=', ['geometry-type'], 'LineString'], 3, 2],
            ['MBZ', 'MBZ_PART'],
            16,
            'CFZ',
            14,
            'GAA',
            8,
            'T',
            11,
            'LFZ',
            6,
            'MOA',
            2.5,
            2,
        ],
        11,
        [
            'match',
            ['get', 'type'],
            'CTA',
            2.5,
            'CTR',
            ['case', ['!=', ['geometry-type'], 'LineString'], 3, 2],
            ['MBZ', 'MBZ_PART'],
            16,
            'CFZ',
            14,
            'GAA',
            8,
            'T',
            14,
            'LFZ',
            8,
            'MOA',
            3,
            2,
        ],
    ]

    const fontSizeDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0,
        5,
        0,
        6,
        0,
        7,
        ['match', ['get', 'type'], ['FISCOM', 'CTR'], 2, ['CTA_LABEL'], 2.5, 6],
        8,
        [
            'match',
            ['get', 'type'],
            ['FISCOM', 'CTR'],
            4.5,
            ['CTA_LABEL'],
            5,
            ['R', 'MOA', 'D', 'VHZ'],
            8,
            7,
        ],
        9,
        [
            'match',
            ['get', 'type'],
            ['FISCOM', 'CTR'],
            7,
            ['CTA_LABEL'],
            7.5,
            ['R', 'MOA', 'D', 'VHZ'],
            8,
            8,
        ],
        10,
        [
            'match',
            ['get', 'type'],
            ['FISCOM', 'CTR'],
            8,
            ['CTA_LABEL'],
            8,
            ['R', 'MOA', 'D', 'VHZ'],
            10,
            9,
        ],
        11,
        10,
    ]

    const labelColourDefinition = [
        'match',
        ['get', 'type'],
        ['CTA_LABEL'],
        '#92278F',
        ['CTR', 'CTR_SECTOR', 'MBZ', 'MBZ_PART', 'CFZ', 'FISCOM'],
        '#0072BC',
        ['GAA', 'GAA_PART', 'T', 'LFZ'],
        '#0072BC',
        ['R', 'MOA', 'D', 'VHZ'],
        '#FF0000',
        '#999999',
    ]

    return (
        <>
            <Source
                id="airspace"
                geoJsonSource={{
                    type: 'geojson',
                    data: data,
                }}
            />

            <Source
                id="airspace-symbol"
                geoJsonSource={{
                    type: 'geojson',
                    data: labelData,
                }}
            />

            {/* <Layer // AIRSPACE LABELS
                id="airspace-fill"
                sourceId="airspace"
                before="aerodromes-symbol"
                type="fill"
                layout={{
                    visibility: show ? 'visible' : 'none',
                }}
                paint={{
                    'fill-opacity': 0.5,
                }}
                filter={[
                    'all',
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['==', ['get', 'type'], 'CTA_LABEL'],
                    ['!=', ['geometry-type'], 'LineString'], // exclude sector lines
                ]}
            /> */}

            <Layer // AIRSPACE BOUNDARY LINES
                id="airspace-line"
                sourceId="airspace"
                before="airspace-line-symbol"
                type="line"
                layout={{
                    // visibility: show ? 'visible' : 'none',
                    'line-sort-key': ['*', ['to-number', ['get', 'order']], -1],
                    'line-join': 'bevel',
                    'line-round-limit': 3,
                }}
                paint={{
                    'line-opacity': zoomLevelDefinition,
                    'line-color': lineColourDefinition,
                    'line-width': lineWidthDefinition,
                    'line-offset': lineOffsetDefinition,
                }}
                filter={[
                    'all',
                    [
                        'match',
                        ['get', 'type'],
                        [
                            'CTA_LABEL',
                            'CTR_SECTOR',
                            'MBZ',
                            'MBZ_PART',
                            'CFZ',
                            'FISCOM',
                        ],
                        false,
                        true,
                    ],
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['!=', ['geometry-type'], 'LineString'], // exclude sector lines
                ]}
            />

            <Layer // CFZs and MBZs
                id="airspace-line-symbol"
                sourceId="airspace"
                before="airspace-line-dashed"
                type="line"
                layout={{
                    // visibility: show ? 'visible' : 'none',
                    'line-sort-key': ['*', ['to-number', ['get', 'order']], -1],
                    'line-join': 'round',
                    'line-round-limit': 3,
                }}
                paint={{
                    'line-pattern': linePatternDefinition,
                    'line-opacity': zoomLevelDefinition,
                    'line-width': lineWidthDefinition,
                    'line-offset': lineOffsetDefinition,
                }}
                filter={[
                    'all',
                    ['match', ['get', 'type'], ['MBZ', 'CFZ'], true, false],
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                ]}
            />

            <Layer
                id="airspace-line-dashed"
                sourceId="airspace"
                before="airspace-symbol"
                type="line"
                layout={{
                    visibility: !selectedLayers.met.grafor ? 'visible' : 'none',
                    'line-sort-key': ['*', ['to-number', ['get', 'order']], -1],
                }}
                paint={{
                    'line-opacity': zoomLevelDefinition,
                    'line-color': lineColourDefinition,
                    'line-width': lineWidthDefinition,
                    'line-offset': lineOffsetDefinition,
                    'line-dasharray': [4, 2],
                }}
                filter={[
                    'all',
                    [
                        'match',
                        ['get', 'type'],
                        'CTR',
                        [
                            'case',
                            ['==', ['geometry-type'], 'LineString'],
                            true, // show,
                            false,
                        ], // include CTR Sector Lines
                        false,
                    ],
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                ]}
            />

            <Layer
                id="airspace-symbol"
                sourceId="airspace-symbol"
                type="symbol"
                before="aerodromes-symbol"
                minZoom={7}
                layout={{
                    // visibility: show ? 'visible' : 'none',
                    'text-field': [
                        'step',
                        ['zoom'],
                        [
                            'match',
                            ['get', 'type'],
                            'FISCOM',
                            airspaceLabelDefs.fiscom.definition,
                            'CTA_LABEL',
                            airspaceLabelDefs.cta.definition,
                            'CTR',
                            airspaceLabelDefs.ctr.definition,
                            'CTR_SECTOR',
                            airspaceLabelDefs['ctr-sector'].definition,
                            ['GAA', 'GAA_PART'],
                            airspaceLabelDefs['gaa-short'].definition,
                            ['T', 'R', 'D', 'MOA', 'VHZ'],
                            airspaceLabelDefs['sua-short'].definition,
                            ['MBZ', 'MBZ_PART'],
                            airspaceLabelDefs['mbz-short'].definition,
                            'LFZ',
                            airspaceLabelDefs.lfz.definition,
                            'CFZ',
                            airspaceLabelDefs.cfz.definition,
                            'ERROR',
                        ],
                        8.5,
                        [
                            'match',
                            ['get', 'type'],
                            'FISCOM',
                            airspaceLabelDefs.fiscom.definition,
                            'CTA_LABEL',
                            airspaceLabelDefs.cta.definition,
                            'CTR',
                            airspaceLabelDefs.ctr.definition,
                            'CTR_SECTOR',
                            airspaceLabelDefs['ctr-sector'].definition,
                            ['GAA', 'GAA_PART'],
                            airspaceLabelDefs.gaa.definition,
                            ['T', 'R', 'D', 'MOA', 'VHZ'],
                            airspaceLabelDefs['sua-mid'].definition,
                            ['MBZ', 'MBZ_PART'],
                            airspaceLabelDefs.mbz.definition,
                            'LFZ',
                            airspaceLabelDefs.lfz.definition,
                            'CFZ',
                            airspaceLabelDefs.cfz.definition,
                            'ERROR',
                        ],
                        10,
                        [
                            'match',
                            ['get', 'type'],
                            'FISCOM',
                            airspaceLabelDefs.fiscom.definition,
                            'CTA_LABEL',
                            airspaceLabelDefs.cta.definition,
                            'CTR',
                            airspaceLabelDefs.ctr.definition,
                            'CTR_SECTOR',
                            airspaceLabelDefs['ctr-sector'].definition,
                            ['GAA', 'GAA_PART'],
                            airspaceLabelDefs.gaa.definition,
                            ['T', 'R', 'D', 'MOA', 'VHZ'],
                            airspaceLabelDefs.sua.definition,
                            ['MBZ', 'MBZ_PART'],
                            airspaceLabelDefs.mbz.definition,
                            'LFZ',
                            airspaceLabelDefs.lfz.definition,
                            'CFZ',
                            airspaceLabelDefs.cfz.definition,
                            'ERROR',
                        ],
                    ],
                    'text-font': [
                        'Roboto Bold',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-size': fontSizeDefinition,
                    'text-allow-overlap': true,
                    'text-optional': false,
                    'text-variable-anchor': ['bottom', 'top'],
                    'text-radial-offset': 0.5,
                    'text-letter-spacing': 0.025,
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.1,
                        10,
                        0.4,
                        16,
                        0.5,
                    ],
                }}
                paint={{
                    'text-color': labelColourDefinition,
                    'text-opacity': zoomLabelDefinition,
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 0.8,
                }}
                filter={[
                    'all',
                    [
                        'match',
                        ['get', 'type'],
                        ['MBZ', 'MBZ_PART', 'GAA', 'GAA_PART'],
                        ['match', ['get', 'sector'], 'PARENT', false, true],
                        true,
                    ],
                    ['match', ['get', 'type'], 'CTA', false, true],
                    [
                        'all',
                        ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                        ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ],
                    ['!=', ['geometry-type'], 'LineString'], // exclude sector lines
                ]}
            />
        </>
    )
}
