import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function PreFlightIcon(props) {
    const baseColor = useColorModeValue('brand.200', 'brand.300')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, width, height, color, active, fill } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            color={color || colorHandler()}
            width={width}
            height={height}
            {...props}
        >
            <g clipPath="url(#clip0_1927_33519)">
                <path
                    d="M10.6036 16.6116L10.7838 13.639L15.7261 8.77596C15.9449 8.55811 15.681 8.4527 15.3914 8.64244L9.29083 12.8519L6.65239 11.9383C6.0861 11.7626 6.07966 11.3339 6.78108 11.0248L17.0582 6.69583C17.5279 6.46392 17.9784 6.82232 17.7982 7.6094L16.0478 16.6116C15.9256 17.2511 15.5716 17.4057 15.0825 17.1105L12.4183 14.9601L11.1377 16.3165C10.9897 16.4781 10.8675 16.6116 10.6036 16.6116Z"
                    fill="currentColor"
                />
                <path
                    d="M12.0292 14.9667C18.4636 14.9667 23.6796 13.6685 23.6796 12.0671C23.6796 11.0563 21.6016 10.1663 18.4508 9.64732L18.3665 10.0763C19.8929 10.4555 20.8317 10.9714 20.8317 11.5399C20.8317 12.7045 16.8907 13.6487 12.0292 13.6487C7.16777 13.6487 3.22677 12.7045 3.22677 11.5399C3.22677 10.7364 5.10246 10.0379 7.86207 9.68189L8.81748 9.27905C3.94511 9.62609 0.378906 10.7429 0.378906 12.0671C0.378906 13.6685 5.59494 14.9667 12.0292 14.9667Z"
                    fill={fill ? fill : '#CED9DF'}
                />
            </g>
            <defs>
                <clipPath id="clip0_1927_33519">
                    <rect
                        width="24"
                        height="10.8632"
                        fill="white"
                        transform="translate(0 6.5)"
                    />
                </clipPath>
            </defs>
        </Icon>
    )
}
