import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import {
    Center,
    SimpleGrid,
    Spinner,
    Modal,
    Flex,
    Box,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Text,
    LightMode,
} from '@chakra-ui/react'
import { MdErrorOutline } from 'react-icons/md'
import RunwayGenerator from '../../icons/dynamic/RunwayGenerator'
import { setModalVisibilityState } from '../../../globalState'

export default function RunwaysModal() {
    const { getAccessTokenSilently } = useAuth0()
    const fetchRunways = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/dev/runways`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'runways', value })
        },
        [setModalVisibility]
    )

    const { onClose } = useDisclosure()

    function onCloseHandler() {
        setShow(false)
        onClose()
    }

    const { data, status } = useQuery('runways', fetchRunways, {
        refetchOnWindowFocus: false,
    })

    const Content = () => {
        if (status !== 'error' && status !== 'loading' && data) {
            return (
                <>
                    {data.map((runwaySet) => (
                        <Flex
                            key={runwaySet.aerodrome}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            _hover={{
                                cursor: 'pointer',
                            }}
                        >
                            <RunwayGenerator
                                runwayGeoJson={runwaySet.geojson}
                                height={150}
                                width={200}
                            />
                            <Text fontWeight="600">{runwaySet.aerodrome} </Text>
                            <Text>
                                {runwaySet.geojson.features
                                    .map(
                                        (runway) => runway.properties.designator
                                    )
                                    .join(' ')}
                            </Text>
                        </Flex>
                    ))}
                </>
            )
        } else if (status === 'loading') {
            return (
                <Center h="100%" w="100%">
                    <Spinner />
                </Center>
            )
        } else if (status === 'error') {
            return (
                <Center h="100%" w="100%">
                    <Flex
                        direction="column"
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <MdErrorOutline color="red" size="70px" />
                        <Text pt="10px" size="md" color="red">
                            Error fetching Runways
                        </Text>
                    </Flex>
                </Center>
            )
        }
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant="dataStatus"
                    size="4xl"
                    isCentered
                    onClose={onCloseHandler}
                    isOpen={true}
                    motionPreset="slideInBottom"
                    scrollBehavior="inside"
                    closeOnOverlayClick={true}
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton color="white" />
                        <ModalHeader
                            textAlign="center"
                            backgroundColor="#83C65D"
                            color="white"
                            letterSpacing="0.5px"
                        >
                            Runways
                        </ModalHeader>
                        <ModalBody style={{ padding: 0 }}>
                            <Box>
                                <Box w="100%" p="20px" pt="35px">
                                    <SimpleGrid
                                        minChildWidth="200px"
                                        spacing="20px"
                                        minHeight="300px"
                                        w="100%"
                                        m="20px"
                                        ml="0"
                                    >
                                        <Content />
                                    </SimpleGrid>
                                </Box>
                            </Box>
                        </ModalBody>
                        <ModalFooter
                            pt={3.5}
                            pb={4}
                            borderTop="1px solid #eaeaea"
                        >
                            <Flex
                                width="100%"
                                maxWidth="160px"
                                borderRadius="10px"
                                border="1px solid"
                                borderColor=" #e5e5e5"
                                overflow="hidden"
                                mx={{ base: 'auto', md: '25px' }}
                            >
                                <Button
                                    w="100%"
                                    size="sm"
                                    variant="primary"
                                    color="dark.30"
                                    backgroundColor="light.40"
                                    _hover={{
                                        backgroundColor: 'light.30',
                                    }}
                                    _active={{
                                        backgroundColor: 'light.30',
                                    }}
                                    borderRadius={5}
                                    onClick={onCloseHandler}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
