import React from 'react'

import { Flex, Text } from '@chakra-ui/react'

export const SourceComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
}) => {
    const updatedSource =
        isChecked &&
        updateExistingFav &&
        updatedParams?.source &&
        briefingComponents?.source &&
        updatedParams.source !== briefingComponents.source
    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    Briefing Areas:
                </Text>

                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedSource && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            {updatedParams?.source &&
                            updatedParams.source === 'aaw'
                                ? 'AAW Areas'
                                : updatedParams?.selected
                                ? updatedParams.selected.replace(/,/g, ', ')
                                : ''}
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedSource ? '300' : '400'}
                        textDecoration={
                            updatedSource ? 'line-through' : 'normal'
                        }
                        fontStyle={updatedSource ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedSource ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedSource
                                ? '0.8'
                                : briefingComponents?.selected ||
                                  briefingComponents?.coords
                                ? '1'
                                : '0.4'
                        }
                    >
                        {briefingComponents?.coords
                            ? 'Custom Area'
                            : briefingComponents?.source === 'aaw'
                            ? 'AAW Areas'
                            : briefingComponents?.selected
                            ? briefingComponents.selected.replace(/,/g, ', ')
                            : ''}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const SelectedComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
}) => {
    const updatedSelected =
        isChecked &&
        updateExistingFav &&
        updatedParams?.aaw &&
        briefingComponents?.aaw &&
        updatedParams.aaw !== briefingComponents.aaw

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    {`${
                        briefingComponents?.source === 'briefingarea' ||
                        briefingComponents?.coords ||
                        (updatedParams?.source &&
                            updatedParams.source !== 'aaw')
                            ? 'AAW Areas Included:'
                            : 'AAW Areas Selected:'
                    }`}
                </Text>
                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedSelected && updatedParams?.aaw && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            {updatedParams.aaw.replace(/,/g, ', ')}
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedSelected ? '300' : '400'}
                        textDecoration={
                            updatedSelected ? 'line-through' : 'normal'
                        }
                        fontStyle={updatedSelected ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedSelected ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedSelected
                                ? '0.8'
                                : briefingComponents?.aaw
                                ? '1'
                                : '0.4'
                        }
                    >
                        {briefingComponents?.aaw
                            ? briefingComponents.aaw.replace(/,/g, ', ')
                            : ''}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const CoordsComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
}) => {
    const updatedCoords =
        isChecked &&
        updateExistingFav &&
        updatedParams?.coords &&
        briefingComponents?.coords &&
        updatedParams.coords !== briefingComponents.coords

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    Area Coordinates:
                </Text>
                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedCoords && updatedParams?.coords && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            {updatedParams.coords.replace(/,/g, ', ')}
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedCoords ? '300' : '400'}
                        textDecoration={
                            updatedCoords ? 'line-through' : 'normal'
                        }
                        fontStyle={updatedCoords ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedCoords ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedCoords
                                ? '0.8'
                                : briefingComponents?.coords
                                ? '1'
                                : '0.4'
                        }
                    >
                        {briefingComponents?.coords
                            ? briefingComponents.coords
                                  .split(',')
                                  .map((coord) => parseFloat(coord).toFixed(2))
                                  .join(', ')
                            : ''}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const ContentsComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
    menuContents,
    formattedKeys,
}) => {
    const updatedContent =
        isChecked &&
        updateExistingFav &&
        (updatedParams?.subtract || briefingComponents?.subtract) &&
        updatedParams.subtract !== briefingComponents.subtract

    const content = briefingComponents?.subtract
        ? briefingComponents.subtract.split(',')
        : null

    const filteredContent = content
        ? menuContents.filter((items) => !content.includes(items))
        : menuContents

    const formattedContent = filteredContent
        ? filteredContent.map(formattedKeys).join(', ')
        : 'None'

    const revisedContent = updatedParams?.subtract
        ? updatedParams.subtract.split(',')
        : null

    const revisedFilteredContent = revisedContent
        ? menuContents.filter((items) => !revisedContent.includes(items))
        : menuContents

    const revisedFormattedContent = revisedFilteredContent
        ? revisedFilteredContent.map(formattedKeys).join(', ')
        : 'None'

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    Contents Selected:
                </Text>
                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedContent && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            {revisedFormattedContent}
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedContent ? '300' : '400'}
                        textDecoration={
                            updatedContent ? 'line-through' : 'normal'
                        }
                        fontStyle={updatedContent ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedContent ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedContent
                                ? '0.8'
                                : formattedContent !== 'None'
                                ? '1'
                                : '0.4'
                        }
                    >
                        {formattedContent}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const PeriodToComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
}) => {
    const updatedTime =
        isChecked &&
        updateExistingFav &&
        updatedParams?.time &&
        briefingComponents?.time &&
        Number(updatedParams.time) !== Number(briefingComponents.time)

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    Period To Selected:
                </Text>
                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedTime && updatedParams?.time && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            + {updatedParams.time} Hours
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedTime ? '300' : '400'}
                        textDecoration={updatedTime ? 'line-through' : 'normal'}
                        fontStyle={updatedTime ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedTime ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedTime
                                ? '0.8'
                                : briefingComponents?.time
                                ? '1'
                                : '0.4'
                        }
                    >
                        + {briefingComponents?.time} Hours
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const MaxaltComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
}) => {
    const updatedMaxalt =
        isChecked &&
        updateExistingFav &&
        updatedParams?.maxalt &&
        briefingComponents?.maxalt &&
        Number(updatedParams.maxalt) !== Number(briefingComponents.maxalt)

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    Altitude Range Selected:
                </Text>
                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedMaxalt && updatedParams?.maxalt && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            SFC{' '}
                            {updatedParams.maxalt === 0
                                ? 'only'
                                : updatedParams.maxalt < 13500
                                ? `– ${
                                      updatedParams.maxalt >= 10000
                                          ? updatedParams.maxalt.toLocaleString(
                                                'en'
                                            )
                                          : updatedParams.maxalt
                                  } ft`
                                : updatedParams.maxalt === 10000
                                ? '– 10,000 ft'
                                : `– FL${updatedParams.maxalt / 100}`}
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedMaxalt ? '300' : '400'}
                        textDecoration={
                            updatedMaxalt ? 'line-through' : 'normal'
                        }
                        fontStyle={updatedMaxalt ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedMaxalt ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedMaxalt
                                ? '0.8'
                                : briefingComponents?.maxalt
                                ? '1'
                                : '0.4'
                        }
                    >
                        SFC{' '}
                        {briefingComponents.maxalt === 0
                            ? 'only'
                            : briefingComponents.maxalt < 13500
                            ? `– ${
                                  briefingComponents.maxalt >= 10000
                                      ? briefingComponents.maxalt.toLocaleString(
                                            'en'
                                        )
                                      : briefingComponents.maxalt
                              } ft`
                            : briefingComponents.maxalt === 10000
                            ? '– 10,000 ft'
                            : `– FL${briefingComponents.maxalt / 100}`}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

// function to convert param item (string) into an array, sort, then turn back to a string if it is not sorted previously alphabetically
const sortParamData = (feat) => {
    if (!feat) {
        return []
    }
    const param = feat.split(',').map((item) => item.trim())
    return param.sort((a, b) => {
        if (a < b) {
            return -1
        }
        if (a > b) {
            return 1
        } else {
            return 0
        }
    })
}
const convertToString = (arr) => arr.join(', ')

export const AerodromesComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
}) => {
    const updatedExcluded = sortParamData(updatedParams?.excluded)
    const briefingExcluded = sortParamData(briefingComponents?.excluded)

    const updatedAerodromes =
        isChecked &&
        updateExistingFav &&
        (updatedParams?.excluded || briefingComponents?.excluded) &&
        convertToString(updatedExcluded) !== convertToString(briefingExcluded)

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    Aerodromes Excluded:
                </Text>
                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedAerodromes && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            {updatedExcluded.length > 0
                                ? convertToString(updatedExcluded)
                                : 'None'}
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedAerodromes ? '300' : '400'}
                        textDecoration={
                            updatedAerodromes ? 'line-through' : 'normal'
                        }
                        fontStyle={updatedAerodromes ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedAerodromes ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedAerodromes
                                ? '0.8'
                                : briefingComponents?.excluded
                                ? '1'
                                : '0.4'
                        }
                    >
                        {briefingExcluded.length > 0
                            ? convertToString(briefingExcluded)
                            : 'None'}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const AwsComponent = ({
    updateExistingFav,
    isChecked,
    updatedParams,
    briefingComponents,
}) => {
    const updatedExcludedAws = sortParamData(updatedParams?.excludedaws)
    const briefingExcludedAws = sortParamData(briefingComponents?.excludedaws)

    const updatedAws =
        isChecked &&
        updateExistingFav &&
        (updatedParams?.excludedaws || briefingComponents?.excludedaws) &&
        convertToString(updatedExcludedAws) !==
            convertToString(briefingExcludedAws)

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
        >
            <Flex
                gap={{
                    base: '2px',
                    md: '5px',
                }}
                width="100%"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection={{
                    base: 'column',
                    md: 'row',
                }}
            >
                <Text
                    color={{
                        base: 'gray.500',
                        md: 'gray.600',
                    }}
                    fontWeight="600"
                    minWidth="160px"
                >
                    AWS Excluded:
                </Text>
                <Flex
                    pl={{
                        base: '15px',
                        md: '1px',
                    }}
                    width="100%"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    fontSize={{
                        base: '0.85rem',
                        md: '0.8rem',
                    }}
                >
                    {updatedAws && (
                        <Text
                            fontWeight="400"
                            width="100%"
                            overflowWrap="anywhere"
                            color="light.201"
                        >
                            {updatedAws && updatedParams?.excludedaws
                                ? updatedParams.excludedaws.replace(/,/g, ', ')
                                : 'None'}
                        </Text>
                    )}
                    <Text
                        width="100%"
                        fontWeight={updatedAws ? '300' : '400'}
                        textDecoration={updatedAws ? 'line-through' : 'normal'}
                        fontStyle={updatedAws ? 'italic' : 'normal'}
                        overflowWrap="anywhere"
                        color={updatedAws ? 'gray.400' : 'light.100'}
                        opacity={
                            updatedAws
                                ? '0.8'
                                : briefingComponents?.excludedaws
                                ? '1'
                                : '0.4'
                        }
                    >
                        {briefingComponents?.excludedaws
                            ? briefingComponents.excludedaws.replace(/,/g, ', ')
                            : 'None'}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
