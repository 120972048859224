import { useAuth0 } from '@auth0/auth0-react'
import {
    Flex,
    Text,
    Stack,
    useColorModeValue,
    Link,
    Box,
} from '@chakra-ui/react'
import { RiLogoutCircleLine } from 'react-icons/ri'

export const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200')
    const linkHoverColor = useColorModeValue('gray.800', 'white')

    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem, i) => (
                <Box key={`NavItem${navItem.label}${i}`}>
                    <Flex
                        cursor="pointer"
                        p={2}
                        // href={navItem.scrollto ?? '#'}
                        fontSize={'0.9rem'}
                        fontWeight={400}
                        color={linkColor}
                        _hover={{
                            textDecoration: 'none',
                            color: linkHoverColor,
                        }}
                        onClick={() => {
                            document
                                .getElementById(navItem.scrollto)
                                .scrollIntoView({
                                    behavior: 'smooth',
                                })
                        }}
                    >
                        {navItem.label}
                    </Flex>
                </Box>
            ))}
        </Stack>
    )
}

export const MobileNav = () => {
    const { user, logout } = useAuth0()
    const textColor = useColorModeValue('gray.600', 'gray.200')
    const borderColor = useColorModeValue(
        'rgba(74, 85, 104, 0.15)',
        'rgba(255,255,255,0.2)'
    )

    return (
        <Stack
            bg={useColorModeValue('light.30', 'dark.20')}
            p={4}
            paddingTop="95px"
            display={{ lg: 'none' }}
        >
            {NAV_ITEMS.map((navItem, i) => (
                <Stack
                    key={`NavItemStack${navItem.label}${i}`}
                    spacing={4}
                    {...navItem}
                >
                    <Flex
                        py={2}
                        as={Link}
                        // href={navItem.scrollto ?? '#'}
                        justify={'space-between'}
                        align={'center'}
                        _hover={{
                            textDecoration: 'none',
                        }}
                        onClick={() => {
                            document
                                .getElementById(navItem.scrollto)
                                .scrollIntoView({
                                    behavior: 'smooth',
                                })
                        }}
                    >
                        <Text fontWeight={400} px="20px" color={textColor}>
                            {navItem.label}
                        </Text>
                    </Flex>
                </Stack>
            ))}
            {user && (
                <Flex
                    mt="15px !important"
                    borderTop="1px solid"
                    borderColor={borderColor}
                    paddingTop={5}
                    pb={2}
                    px="19px"
                    as={Link}
                    color={textColor}
                    justify={'flex-start'}
                    align={'center'}
                    _hover={{
                        textDecoration: 'none',
                    }}
                    onClick={() =>
                        logout({
                            returnTo: `${window.location.origin}`,
                        })
                    }
                >
                    <RiLogoutCircleLine fontSize="1rem" />

                    <Text fontWeight={400} px="15px">
                        Log Out
                    </Text>
                </Flex>
            )}
        </Stack>
    )
}

const NAV_ITEMS = [
    {
        label: 'About',
        scrollto: 'contentContainer',
    },
    {
        label: 'Pricing',
        scrollto: 'pricingContainer',
    },
    {
        label: 'FAQs',
        scrollto: 'faqsContainer',
    },
    {
        label: 'Contact',
        scrollto: 'footerContainer',
    },
]
