import { config } from '../config'

export default function returnModesFromQuery(query) {
    const { modes } = config
    const filteredModes = modes.filter(
        (m) => m.toLowerCase().search(query.toLowerCase()) !== -1
    )
    return filteredModes.length
        ? filteredModes.map((m) => {
              return { type: 'mode', mode: m, label: m.toUpperCase() }
          })
        : []
}
