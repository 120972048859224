import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function ReflectivityIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 5}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M15.5916 3.50261L19.109 3.01009C19.1501 3.00339 19.1921 2.99995 19.2347 3C19.325 2.99989 19.4125 3.01544 19.4941 3.04435C19.6021 3.08234 19.6976 3.14299 19.7758 3.21975C19.854 3.29621 19.9157 3.38963 19.9545 3.49513C19.9842 3.57545 20.0002 3.66158 20 3.75041C20 3.7921 19.9965 3.83318 19.9897 3.87335L19.4872 7.32098C19.4275 7.73104 19.0399 8.01596 18.6215 7.95738C18.2032 7.8988 17.9125 7.5189 17.9723 7.10885L18.1526 5.87142L11.8042 12.094C11.517 12.3755 11.0582 12.3864 10.7577 12.1267C10.7437 12.1149 10.73 12.1024 10.7168 12.0894L4.22411 5.72545C3.9253 5.43255 3.9253 4.95768 4.22411 4.66479C4.52293 4.37189 5.0074 4.37189 5.30622 4.66479L11.2628 10.5033L17.0705 4.81076L15.8081 4.98753C15.3897 5.04611 15.0021 4.76119 14.9424 4.35114C14.8826 3.94109 15.1733 3.56119 15.5916 3.50261Z"
                fill="currentcolor"
            />
            <path
                d="M2 14.25C2 13.8358 2.33579 13.5 2.75 13.5H21.25C21.6642 13.5 22 13.8358 22 14.25C22 14.6642 21.6642 15 21.25 15H2.75C2.33579 15 2 14.6642 2 14.25Z"
                fill="currentcolor"
            />
            <path
                d="M12.5954 18.6441C12.7749 18.2761 13.2188 18.1232 13.5868 18.3027C13.9549 18.4823 14.1077 18.9261 13.9282 19.2941L13.0615 21.0712C12.882 21.4392 12.4381 21.592 12.0701 21.4125C11.7021 21.233 11.5492 20.7892 11.7287 20.4211L12.5954 18.6441Z"
                fill="currentcolor"
            />
            <path
                d="M5.69175 18.6435C5.87125 18.2755 6.31512 18.1226 6.68315 18.3021C7.05118 18.4816 7.20402 18.9255 7.02452 19.2935L6.15781 21.0706C5.9783 21.4386 5.53444 21.5914 5.16641 21.4119C4.79838 21.2324 4.64554 20.7886 4.82504 20.4205L5.69175 18.6435Z"
                fill="currentcolor"
            />
            <path
                d="M8.81907 19.3094C8.99858 18.9414 9.44244 18.7885 9.81047 18.968C10.1785 19.1475 10.3313 19.5914 10.1518 19.9594L9.61015 21.0701C9.43064 21.4381 8.98678 21.5909 8.61875 21.4114C8.25071 21.2319 8.09788 20.7881 8.27738 20.42L8.81907 19.3094Z"
                fill="currentcolor"
            />
            <path
                d="M10.4455 16L9.86894 17.1822C9.68944 17.5502 9.84227 17.9941 10.2103 18.1736C10.5783 18.3531 11.0222 18.2003 11.2017 17.8322L12.0953 16H10.4455Z"
                fill="currentcolor"
            />
            <path
                d="M6.99343 16L6.74161 16.5163C6.56211 16.8843 6.71495 17.3282 7.08298 17.5077C7.45101 17.6872 7.89488 17.5344 8.07438 17.1663L8.64324 16H6.99343Z"
                fill="currentcolor"
            />
            <path
                d="M13.8975 16L13.6454 16.5169C13.4659 16.8849 13.6187 17.3288 13.9868 17.5083C14.3548 17.6878 14.7987 17.535 14.9782 17.1669L15.5473 16H13.8975Z"
                fill="currentcolor"
            />
            <path
                d="M15.7237 19.3094C15.9032 18.9414 16.3471 18.7885 16.7151 18.968C17.0832 19.1475 17.236 19.5914 17.0565 19.9594L16.5148 21.0701C16.3353 21.4381 15.8914 21.5909 15.5234 21.4114C15.1554 21.2319 15.0025 20.7881 15.182 20.42L15.7237 19.3094Z"
                fill="currentcolor"
            />
            <path
                d="M17.3502 16L16.7736 17.1822C16.5941 17.5502 16.7469 17.9941 17.115 18.1736C17.483 18.3531 17.9269 18.2003 18.1064 17.8322L19 16H17.3502Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
