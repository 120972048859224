import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    Flex,
    Box,
    Text,
    Badge,
    Divider,
    AbsoluteCenter,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Image,
    useBreakpointValue,
    Icon,
} from '@chakra-ui/react'
import formatDateTime from '../../../../../util/dateFormatter'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import {
    parseISO,
    formatDistanceToNow,
    formatDistanceToNowStrict,
    addHours,
    intervalToDuration,
} from 'date-fns'

import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../../../util/notamDecode'
import {
    timeZoneState,
    showOutdatedErrorsState,
    briefingParamsState,
    selectedBriefingTimeState,
    collapseDropdownsState,
    briefingMetarCountState,
} from '../../../../../globalState'
import ErrorText from '../../../components/ErrorText'
import { ReactComponent as NotamIcon } from '../../../../../icons/assets/notam-outline.svg'
import { ReactComponent as MetarIcon } from '../../../../../icons/assets/metar.svg'
import { ReactComponent as TafIcon } from '../../../../../icons/assets/taf.svg'
import { ReactComponent as AtisIcon } from '../../../../../icons/assets/atis.svg'

import notam from '../../../assets/notam.svg'
import notamDrone from '../../../assets/notam-drone.svg'
import notamObstacle from '../../../assets/notam-obstacle.svg'
import notamAerodrome from '../../../assets/notam-aerodrome.svg'
import notamWarning from '../../../assets/notam-warning.svg'
import notamComms from '../../../assets/notam-comms.svg'

export default React.memo(function AerodromeBriefingContent(props) {
    const { briefingData, aerodrome } = props
    const params = useRecoilValue(briefingParamsState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : [0, 1, 2, 3]
    )

    useEffect(() => {
        setExpandedDropdowns(collapseDropdowns ? [] : [0, 1, 2, 3])
    }, [collapseDropdowns])

    return (
        <Box>
            <Accordion
                border="none"
                allowToggle
                allowMultiple
                index={expandedDropdowns}
                onChange={(expandedIndex) => {
                    setExpandedDropdowns(expandedIndex)
                }}
            >
                {(params.aerodromesMetar ||
                    params.aerodromesTaf ||
                    params.aerodromesAtis ||
                    params.aerodromesNotam) && (
                    <AerodromeItem
                        briefingData={briefingData}
                        aerodrome={aerodrome}
                    />
                )}
            </Accordion>
        </Box>
    )
})

export const AerodromeItem = (props) => {
    const { briefingData, aerodrome } = props

    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const selectedHours = useRecoilValue(selectedBriefingTimeState)
    const issuedTime = briefingData && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)
    const timeZone = useRecoilValue(timeZoneState)
    const [briefingMetarCount, setBriefingMetarCount] = useRecoilState(
        briefingMetarCountState
    )
    const params = useRecoilValue(briefingParamsState)

    function notamIconFormatter(code23) {
        return ['WU', 'WZ'].includes(code23)
            ? notamDrone
            : ['OB', 'OL'].includes(code23)
            ? notamObstacle
            : code23 === 'FA'
            ? notamAerodrome
            : ['W', 'R'].includes(code23.charAt(0))
            ? notamWarning
            : ['C', 'N'].includes(code23.charAt(0))
            ? notamComms
            : notam
    }

    const briefingIssueTime = briefingData && parseISO(briefingData.time)

    // METAR Out of Date Error Message Times
    const currentMetar =
        aerodrome.metar &&
        aerodrome.metar.filter((filter) => filter !== null) &&
        aerodrome.metar[0]

    const metarTimeDifference =
        currentMetar &&
        intervalToDuration({
            start: parseISO(currentMetar.validat),
            end: briefingIssueTime,
        })
    const metarOutOfDateIssueTime =
        (metarTimeDifference && metarTimeDifference.days >= 1) ||
        (metarTimeDifference &&
            metarTimeDifference.days < 1 &&
            metarTimeDifference.hours >= 1 &&
            (metarTimeDifference.hours === 1
                ? metarTimeDifference.minutes > 15
                : metarTimeDifference.minutes > 0)) // = 01:15
            ? 'WARNING' // red
            : metarTimeDifference &&
              ((metarTimeDifference.hours === 1 &&
                  metarTimeDifference.minutes < 15) ||
                  (metarTimeDifference.hours < 1 &&
                      metarTimeDifference.minutes >= 45)) && // = 00:45
              aerodrome.designator !== 'NZWP' &&
              aerodrome.designator !== 'NZOH' &&
              aerodrome.designator !== 'NZMF'
            ? 'CAUTION' // orange
            : 'NONE'

    // TAF Out of Date Error Message Times
    const tafIssuedTime =
        aerodrome.taf && aerodrome.taf.validfrom
            ? parseISO(aerodrome.taf.validfrom)
            : null
    const tafTimeDifference =
        tafIssuedTime &&
        intervalToDuration({
            start: tafIssuedTime,
            end: briefingIssueTime,
        })
    const tafOutOfDateIssueTime =
        (tafTimeDifference && tafTimeDifference.days >= 1) ||
        (tafTimeDifference &&
            tafTimeDifference.days < 1 &&
            tafTimeDifference.hours >= 21) // = 21 hours or greater (all airports)
            ? 'WARNING'
            : tafTimeDifference &&
              tafTimeDifference.hours >= 18 && // = 18 hours or greater (excludes NZTG)
              aerodrome.designator !== 'NZTG'
            ? 'WARNING'
            : tafTimeDifference && tafTimeDifference.hours >= 12 // = 12 hours or greater
            ? 'CAUTION'
            : // below are for AA, WN and CH only
            tafTimeDifference &&
              tafTimeDifference.hours >= 6 && // = 6 hours or greater (AA, WN and CH only)
              (aerodrome.designator === 'NZAA' ||
                  aerodrome.designator === 'NZWN' ||
                  aerodrome.designator === 'NZCH')
            ? 'WARNING'
            : tafTimeDifference &&
              tafTimeDifference.hours >= 3 &&
              (tafTimeDifference.hours === 3
                  ? tafTimeDifference.minutes > 30
                  : tafTimeDifference.minutes > 0) && // = 3 hours & 30 minutes or greater (AA, WN and CH only)
              (aerodrome.designator === 'NZAA' ||
                  aerodrome.designator === 'NZWN' ||
                  aerodrome.designator === 'NZCH')
            ? 'CAUTION'
            : 'NONE'

    // TEMP badge for Matamata and Wanaka when temporary ATIS service is provided, i.e. Walsh Flying School or Warbirds Over Wanaka
    const tempATIS =
        aerodrome.designator && ['NZMA', 'NZWF'].includes(aerodrome.designator)

    const metarOptions = [
        {
            label: 'Latest',
            value: 1,
        },
        {
            label: 'Last 2',
            value: 2,
        },
        {
            label: 'Last 3',
            value: 3,
        },
        {
            label: 'Last 6',
            value: 6,
        },
        {
            label: 'Last 12',
            value: 12,
        },
        {
            label: 'Last 18',
            value: 18,
        },
        {
            label: 'Last 24',
            value: 24,
        },
    ]

    // FONT SIZE ADJUSTMENTS
    const {
        calculateFontSize,
        calculateContainerSize,
        calculateLabelSize,
        calculateBadgeSize,
        calculateBadgeWidth,
    } = useAdjustedFontSize()

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    const AERODROME_ITEMS = [
        {
            data: aerodrome.notam,
            display: params.aerodromesNotam,
            id: 'NOTAM',
            label: 'NOTAM',
            sublabel: null,
            scrollto: 'notamContainer',
            icon: NotamIcon,
            content: (
                <>
                    {aerodrome.notam &&
                        aerodrome.notam.length > 0 &&
                        aerodrome.notam.map((notam, i) => {
                            const code23 = code23Decode(notam.code23)
                            const code45 = code45Decode(notam.code45)
                            const traffic = trafficDecode(notam.traffic)
                            if (parseISO(notam.startvalidity) <= periodTo) {
                                return (
                                    <Box
                                        key={`AerodromeBriefingNotam${notam.series}${notam.number}/${notam.year}`}
                                        w="100%"
                                        pr="5px"
                                    >
                                        {(showOutdatedErrors ||
                                            notam.outofdate) && (
                                            <Flex
                                                pb="10px"
                                                ml="-8px"
                                                justifyContent="center"
                                            >
                                                <ErrorText>
                                                    There has been an issue with
                                                    the connection between
                                                    PreFlight and MetService.
                                                    This <strong>NOTAM</strong>{' '}
                                                    was last validated with
                                                    MetService more than{' '}
                                                    <strong>
                                                        {formatDistanceToNow(
                                                            parseISO(
                                                                notam.updated
                                                            ),
                                                            {
                                                                addSuffix: true,
                                                            }
                                                        )}
                                                    </strong>
                                                    .
                                                    <br />
                                                    There is a high likelihood
                                                    that the latest NOTAM
                                                    information is not available
                                                    in PreFlight. Check back
                                                    again soon, or alternatively
                                                    visit{' '}
                                                    <a
                                                        style={{
                                                            fontWeight: '500',
                                                            textDecoration:
                                                                'underline',
                                                        }}
                                                        href="https://ifis.airways.co.nz/"
                                                    >
                                                        IFIS
                                                    </a>
                                                    .
                                                </ErrorText>
                                            </Flex>
                                        )}
                                        <Flex>
                                            {/* ICON */}
                                            <Box
                                                minWidth="24px"
                                                mt="1px"
                                                mr="14px"
                                            >
                                                <Image
                                                    boxSize="24px"
                                                    src={notamIconFormatter(
                                                        notam.code23
                                                    )}
                                                    alt="!"
                                                />
                                            </Box>
                                            <Box width="100%">
                                                <Flex
                                                    paddingStart="0"
                                                    paddingTop="0.2rem"
                                                    paddingBottom="0.2rem"
                                                    flexDirection="column"
                                                    width="auto"
                                                    minWidth="90px"
                                                >
                                                    <Text
                                                        fontWeight="bold"
                                                        fontSize={calculateFontSize(
                                                            0.8
                                                        )}
                                                    >
                                                        {code23}
                                                    </Text>

                                                    <Text
                                                        fontWeight="normal"
                                                        paddingBottom="5px"
                                                        fontSize={calculateFontSize(
                                                            0.8
                                                        )}
                                                    >
                                                        {code45}
                                                    </Text>
                                                </Flex>

                                                <Flex
                                                    display="flex"
                                                    flexDirection={{
                                                        base: 'column',
                                                        xl: 'row',
                                                    }}
                                                    width="100%"
                                                >
                                                    <Flex
                                                        display="flex"
                                                        flexDirection="column"
                                                        width={{
                                                            base: '100%',
                                                            xl: '50%',
                                                        }}
                                                        maxWidth={{
                                                            base: '100%',
                                                            xl: calculateContainerSize(
                                                                260
                                                            ),
                                                        }}
                                                    >
                                                        <Flex
                                                            width="100%"
                                                            display="flex"
                                                            flexDirection="row"
                                                            paddingBottom="5px"
                                                        >
                                                            <Flex
                                                                paddingStart="0"
                                                                fontWeight="bold"
                                                                width="auto"
                                                                minWidth={calculateLabelSize(
                                                                    75
                                                                )}
                                                                fontSize={calculateFontSize(
                                                                    0.8
                                                                )}
                                                            >
                                                                NOTAM #:
                                                            </Flex>
                                                            <Flex
                                                                width="100%"
                                                                fontSize={calculateFontSize(
                                                                    0.8
                                                                )}
                                                            >
                                                                {`${notam.series}${notam.number}/${notam.year}`}
                                                            </Flex>
                                                        </Flex>
                                                        <Flex
                                                            display="flex"
                                                            flexDirection="row"
                                                            width="100%"
                                                            paddingBottom="5px"
                                                        >
                                                            <Flex
                                                                paddingStart="0"
                                                                fontWeight="bold"
                                                                width="auto"
                                                                minWidth={calculateLabelSize(
                                                                    75
                                                                )}
                                                                fontSize={calculateFontSize(
                                                                    0.8
                                                                )}
                                                            >
                                                                Affects:
                                                            </Flex>
                                                            <Flex width="100%">
                                                                {traffic.ifr && (
                                                                    <Badge
                                                                        variant="solid"
                                                                        background="ifr.100"
                                                                        color="white"
                                                                        paddingStart="2"
                                                                        paddingEnd="2"
                                                                        paddingTop="2px"
                                                                        paddingBottom="2px"
                                                                        borderRadius="20px"
                                                                        marginTop="2px"
                                                                        marginRight="5px"
                                                                        minWidth={calculateBadgeWidth(
                                                                            40
                                                                        )}
                                                                        textAlign="center"
                                                                        fontSize={calculateFontSize(
                                                                            0.65
                                                                        )}
                                                                        height={calculateBadgeSize(
                                                                            18
                                                                        )}
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        display="flex"
                                                                    >
                                                                        IFR
                                                                    </Badge>
                                                                )}
                                                                {traffic.ifr &&
                                                                traffic.vfr
                                                                    ? ' '
                                                                    : ''}
                                                                {traffic.vfr && (
                                                                    <Badge
                                                                        variant="solid"
                                                                        background="vfr.100"
                                                                        color="white"
                                                                        paddingStart="2"
                                                                        paddingEnd="2"
                                                                        paddingTop="2px"
                                                                        paddingBottom="2px"
                                                                        borderRadius="20px"
                                                                        marginTop="2px"
                                                                        marginRight="5px"
                                                                        minWidth={calculateBadgeWidth(
                                                                            40
                                                                        )}
                                                                        textAlign="center"
                                                                        fontSize={calculateFontSize(
                                                                            0.65
                                                                        )}
                                                                        height={calculateBadgeSize(
                                                                            18
                                                                        )}
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        display="flex"
                                                                    >
                                                                        VFR
                                                                    </Badge>
                                                                )}
                                                                <Text
                                                                    paddingLeft="1px"
                                                                    fontSize={calculateFontSize(
                                                                        0.8
                                                                    )}
                                                                >
                                                                    TRAFFIC
                                                                </Text>
                                                            </Flex>
                                                        </Flex>
                                                        {/*  <Flex
                                                        display="flex"
                                                        flexDirection="row"
                                                        width="100%"   paddingBottom='2px'
                                                    > /// REMOVED FOR NOW - REQUIRES FURTHER THOUGHT
                                                            <Flex
                                                                paddingStart="0"
                                                                fontWeight="bold"
                                                                width="auto"
                                                                minWidth="65px"
                                                                fontSize="0.8rem"
                                                            >
                                                                Status:
                                                            </Flex>
                                                            <Flex
                                                                width="100%"
                                                                fontSize="0.8rem"
                                                            >
                                                                {timeRangeInWords(
                                                                    now,
                                                                    notam.startvalidity,
                                                                    notam.endvalidity
                                                                )}
                                                            </Flex>
                                                        </Flex> */}
                                                        {notam.itemf && (
                                                            <Flex
                                                                display="flex"
                                                                flexDirection="row"
                                                                width="100%"
                                                                paddingBottom="5px"
                                                            >
                                                                <Flex
                                                                    paddingStart="0"
                                                                    fontWeight="bold"
                                                                    width="auto"
                                                                    minWidth={calculateLabelSize(
                                                                        75
                                                                    )}
                                                                    fontSize={calculateFontSize(
                                                                        0.8
                                                                    )}
                                                                >
                                                                    Altitude:
                                                                </Flex>
                                                                <Flex
                                                                    width="100%"
                                                                    fontSize={calculateFontSize(
                                                                        0.8
                                                                    )}
                                                                >
                                                                    {notam.lowerlimit +
                                                                        ' – ' +
                                                                        notam.upperlimit}
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                        display="flex"
                                                        flexDirection="column"
                                                        width={{
                                                            base: '100%',
                                                            xl: '100%',
                                                        }}
                                                        maxWidth={{
                                                            base: '100%',
                                                            xl: '100%',
                                                        }}
                                                    >
                                                        <Flex
                                                            display="flex"
                                                            flexDirection="row"
                                                            width="100%"
                                                            paddingBottom="5px"
                                                        >
                                                            <Flex
                                                                paddingStart="0"
                                                                fontWeight="bold"
                                                                width="auto"
                                                                minWidth={{
                                                                    base: calculateLabelSize(
                                                                        75
                                                                    ),
                                                                    xl: calculateLabelSize(
                                                                        55
                                                                    ),
                                                                }}
                                                                fontSize={calculateFontSize(
                                                                    0.8
                                                                )}
                                                            >
                                                                From:
                                                            </Flex>
                                                            <Flex
                                                                width="100%"
                                                                fontSize={calculateFontSize(
                                                                    0.8
                                                                )}
                                                            >
                                                                {notam?.startvalidity &&
                                                                    formatDateTime(
                                                                        notam.startvalidity,
                                                                        timeZone
                                                                    )}
                                                            </Flex>
                                                        </Flex>

                                                        {!notam.permanent && (
                                                            <Flex
                                                                display="flex"
                                                                flexDirection="row"
                                                                width="100%"
                                                                paddingBottom="5px"
                                                            >
                                                                <Flex
                                                                    paddingStart="0"
                                                                    fontWeight="bold"
                                                                    width="auto"
                                                                    minWidth={{
                                                                        base: calculateLabelSize(
                                                                            75
                                                                        ),
                                                                        xl: calculateLabelSize(
                                                                            55
                                                                        ),
                                                                    }}
                                                                    fontSize={calculateFontSize(
                                                                        0.8
                                                                    )}
                                                                >
                                                                    To:
                                                                </Flex>
                                                                <Flex
                                                                    width="100%"
                                                                    fontSize={calculateFontSize(
                                                                        0.8
                                                                    )}
                                                                >
                                                                    {notam?.endvalidity &&
                                                                        formatDateTime(
                                                                            notam.endvalidity,
                                                                            timeZone
                                                                        )}
                                                                    {notam?.estimation &&
                                                                        ' (Estimated)'}
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                        {notam.itemd &&
                                                            notam.itemd !==
                                                                'null' && (
                                                                <Flex
                                                                    display="flex"
                                                                    flexDirection="row"
                                                                    width="100%"
                                                                    paddingBottom="5px"
                                                                >
                                                                    <Flex
                                                                        paddingStart="0"
                                                                        fontWeight="bold"
                                                                        width="auto"
                                                                        minWidth={{
                                                                            base: calculateLabelSize(
                                                                                75
                                                                            ),
                                                                            xl: calculateLabelSize(
                                                                                55
                                                                            ),
                                                                        }}
                                                                        fontSize={calculateFontSize(
                                                                            0.8
                                                                        )}
                                                                    >
                                                                        Period:
                                                                    </Flex>
                                                                    <Flex
                                                                        width="100%"
                                                                        fontSize={calculateFontSize(
                                                                            0.8
                                                                        )}
                                                                    >
                                                                        {notam.itemd +
                                                                            ' (UTC if time shown)'}
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                    </Flex>
                                                </Flex>

                                                <Text
                                                    marginTop={1}
                                                    marginBottom={3}
                                                    whiteSpace="pre-line"
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                >
                                                    {notam.iteme}
                                                </Text>
                                                {/* {i + 1 !==
                                                    aerodrome.notam.length && (
                                                    <Divider
                                                        variant="modalFooter"
                                                        marginTop={7}
                                                        marginBottom={5}
                                                    />
                                                )} */}
                                                {i + 1 !==
                                                    aerodrome.notam.length && (
                                                    <Box position="relative">
                                                        <Divider
                                                            variant="modalFooter"
                                                            marginTop={7}
                                                            marginBottom={5}
                                                        />
                                                        <AbsoluteCenter
                                                            bg="white"
                                                            px="2"
                                                            pb="1px"
                                                            left="unset"
                                                            right="0%"
                                                            transform="translate(0%, -50%)"
                                                            fontSize="0.75rem"
                                                            color="gray.300"
                                                            opacity="0.8"
                                                            fontWeight="300"
                                                        >
                                                            {
                                                                aerodrome?.designator
                                                            }
                                                        </AbsoluteCenter>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Flex>
                                    </Box>
                                )
                            } else {
                                return null
                            }
                        })}
                </>
            ),
            noinfo: 'No current NOTAMs issued',
            error: null,
        },
        {
            data: aerodrome.metar,
            display: params.aerodromesMetar,
            id: 'METAR',
            label: 'METAR',
            sublabel: 'Wind: °True',
            scrollto: 'metarContainer',
            icon: MetarIcon,
            content: (
                <>
                    {/* ERROR MESSAGES */}
                    {aerodrome.metar &&
                        aerodrome.metar[0] &&
                        !showOutdatedErrors &&
                        !aerodrome.metar[0].outofdate &&
                        (metarOutOfDateIssueTime === 'CAUTION' ||
                            metarOutOfDateIssueTime === 'WARNING') && (
                            <Flex
                                mt="5px"
                                mb={{
                                    base: '15px',
                                    xl: '10px',
                                }}
                                pl="5px"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Text
                                    color={
                                        metarOutOfDateIssueTime === 'CAUTION'
                                            ? '#ff7f00'
                                            : 'red'
                                    }
                                    fontSize="12px"
                                >
                                    This METAR was last issued{' '}
                                    <strong>
                                        {aerodrome.metar[0] &&
                                            aerodrome.metar[0].validat &&
                                            formatDistanceToNowStrict(
                                                parseISO(
                                                    aerodrome.metar[0].validat
                                                ),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}
                                    </strong>
                                    .
                                </Text>
                                {metarOutOfDateIssueTime === 'WARNING' && (
                                    <Text color={'red'} fontSize="12px">
                                        There is a possibility there has been an
                                        issue receiving the latest data and that
                                        the latest METAR is not available.
                                        <br />
                                        Check back again soon, or alternatively
                                        visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </Text>
                                )}
                            </Flex>
                        )}
                    {aerodrome.metar &&
                        aerodrome.metar[0] &&
                        (showOutdatedErrors ||
                            aerodrome.metar[0].outofdate) && (
                            <Flex pb="10px" ml="-8px" justifyContent="center">
                                <ErrorText>
                                    There has been an issue with the connection
                                    between PreFlight and MetService. This{' '}
                                    <strong>METAR</strong> report was last
                                    validated with MetService more than{' '}
                                    <strong>
                                        {aerodrome.metar[0].updated &&
                                            formatDistanceToNow(
                                                parseISO(
                                                    aerodrome.metar[0].updated
                                                ),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}
                                    </strong>
                                    .
                                    <br />
                                    There is a high likelihood that the latest
                                    METAR information is not available in
                                    PreFlight. Check back again soon, or
                                    alternatively visit{' '}
                                    <a
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                        href="https://ifis.airways.co.nz/"
                                    >
                                        IFIS
                                    </a>
                                    .
                                </ErrorText>
                            </Flex>
                        )}
                    <Flex
                        className="metar"
                        flexDirection={{
                            base: 'column-reverse',
                            md: 'row',
                        }}
                        px="5px"
                        justifyContent="space-between"
                    >
                        <Flex paddingTop={1}>
                            <Flex flexDirection="column">
                                {aerodrome.metar &&
                                    aerodrome.metar
                                        .slice(
                                            0,
                                            briefingMetarCount &&
                                                briefingMetarCount[
                                                    aerodrome.designator
                                                ]
                                                ? briefingMetarCount[
                                                      aerodrome.designator
                                                  ]
                                                : 1
                                        )
                                        .map((metar, i) => {
                                            return (
                                                <Text
                                                    key={`AerodromeBriefingMetar${metar.id}${i}`}
                                                    textAlign="left"
                                                    color="light.100"
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                    fontWeight="normal"
                                                    lineHeight="1.3"
                                                    whiteSpace="pre-line"
                                                    paddingBottom="10px"
                                                >
                                                    {metar.data}
                                                </Text>
                                            )
                                        })}
                            </Flex>
                        </Flex>
                        <Box
                            width="120px"
                            minWidth="110px"
                            fontSize="0.8rem"
                            paddingStart="2"
                            paddingEnd="0"
                            pb="0px"
                            borderColor="transparent"
                            position="relative"
                            marginTop="5px"
                            right="0px"
                            marginLeft="auto"
                            marginBottom={{
                                base: '20px',
                                md: '0px',
                            }}
                        >
                            <Select
                                menuPosition="fixed" // this is needed to stop dropdown menu being contained within Accordion Item's Collapse (overflow:hidden)
                                closeMenuOnScroll={() => true}
                                {...noFocus}
                                isSearchable={false}
                                controlShouldRenderValue={true}
                                styles={{
                                    option: (styles) => ({
                                        ...styles,
                                        cursor: 'pointer',
                                        paddingTop: '6px',
                                        paddingBottom: '6px',
                                    }),
                                    control: (styles) => ({
                                        ...styles,
                                        cursor: 'pointer',
                                        borderRadius: '10px',
                                        borderColor: '#e5e5e5',

                                        minHeight: '30px',
                                    }),
                                    dropdownIndicator: (styles) => ({
                                        ...styles,
                                        paddingTop: '4px',
                                        paddingBottom: '4px',
                                    }),
                                    menu: (styles) => ({
                                        ...styles,
                                        zIndex: '100',
                                        marginTop: '3px',
                                    }),
                                }}
                                defaultValue={
                                    metarOptions.filter((option) =>
                                        option.value === briefingMetarCount &&
                                        briefingMetarCount[aerodrome.designator]
                                            ? briefingMetarCount[
                                                  aerodrome.designator
                                              ]
                                            : 1
                                    )[0]
                                }
                                onChange={(e) =>
                                    setBriefingMetarCount((prevState) => ({
                                        ...prevState,
                                        ...{
                                            [aerodrome.designator]: e.value,
                                        },
                                    }))
                                }
                                options={metarOptions}
                            />
                        </Box>
                    </Flex>
                </>
            ),
            noinfo: null,
            error: null,
        },
        {
            data: aerodrome.taf,
            display: params.aerodromesTaf,
            id: 'TAF',
            label: 'TAF',
            sublabel: 'Wind: °True',
            scrollto: 'tafContainer',
            icon: TafIcon,
            content: (
                <>
                    {/* ERROR MESSAGES */}
                    {aerodrome.taf &&
                        !showOutdatedErrors &&
                        !aerodrome.taf.outofdate &&
                        (tafOutOfDateIssueTime === 'CAUTION' ||
                            tafOutOfDateIssueTime === 'WARNING') && (
                            <Flex
                                mt="5px"
                                mb={{
                                    base: '15px',
                                    xl: '10px',
                                }}
                                pl="5px"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Text
                                    color={
                                        tafOutOfDateIssueTime === 'CAUTION'
                                            ? '#ff7f00'
                                            : 'red'
                                    }
                                    fontSize="12px"
                                >
                                    This TAF was last issued{' '}
                                    <strong>
                                        {formatDistanceToNowStrict(
                                            parseISO(aerodrome.taf.validfrom),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </strong>
                                    .
                                </Text>
                                {tafOutOfDateIssueTime === 'WARNING' && (
                                    <Text color={'red'} fontSize="12px">
                                        There is a possibility there has been an
                                        issue receiving the latest data and that
                                        the latest TAF is not available.
                                        <br />
                                        Check back again soon, or alternatively
                                        visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </Text>
                                )}
                            </Flex>
                        )}
                    {aerodrome.taf &&
                        (showOutdatedErrors || aerodrome.taf.outofdate) && (
                            <Flex pb="10px" ml="-8px" justifyContent="center">
                                <ErrorText>
                                    There has been an issue with the connection
                                    between PreFlight and MetService. This{' '}
                                    <strong>TAF</strong> report was last
                                    validated with MetService more than{' '}
                                    <strong>
                                        {formatDistanceToNow(
                                            parseISO(aerodrome.taf.updated),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </strong>
                                    .
                                    <br />
                                    There is a high likelihood that the latest
                                    TAF information is not available in
                                    PreFlight. Check back again soon, or
                                    alternatively visit{' '}
                                    <a
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                        href="https://ifis.airways.co.nz/"
                                    >
                                        IFIS
                                    </a>
                                    .
                                </ErrorText>
                            </Flex>
                        )}
                    <Flex paddingTop={1} px="5px">
                        <Text
                            textAlign="left"
                            color="light.100"
                            fontSize={calculateFontSize(0.8)}
                            fontWeight="normal"
                            lineHeight="1.3"
                            whiteSpace="pre-line"
                            paddingBottom="10px"
                        >
                            {aerodrome.taf && aerodrome.taf.data}
                        </Text>
                    </Flex>
                </>
            ),
            noinfo: null,
            error: null,
        },
        {
            data: aerodrome.atis,
            display: params.aerodromesAtis,
            id: 'ATIS',
            label: 'ATIS',
            sublabel: 'Wind: °Magnetic',
            scrollto: 'atisContainer',
            icon: AtisIcon,
            content: (
                <>
                    {aerodrome.atis &&
                        (showOutdatedErrors || aerodrome.atis.outofdate) && (
                            <Flex pt="20px" ml="-8px" justifyContent="center">
                                <ErrorText>
                                    There has been an issue with the connection
                                    between PreFlight and MetService. This{' '}
                                    <strong>ATIS</strong> report was last
                                    validated with MetService more than{' '}
                                    <strong>
                                        {formatDistanceToNow(
                                            parseISO(aerodrome.atis.updated),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </strong>
                                    .
                                    <br />
                                    There is a high likelihood that the latest
                                    ATIS information is not available in
                                    PreFlight. Check back again soon, or
                                    alternatively visit{' '}
                                    <a
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                        href="https://ifis.airways.co.nz/"
                                    >
                                        IFIS
                                    </a>
                                    .
                                </ErrorText>
                            </Flex>
                        )}
                    <Flex paddingTop={1} px="5px">
                        <Text
                            textAlign="left"
                            color="light.100"
                            fontSize={calculateFontSize(0.8)}
                            fontWeight="normal"
                            lineHeight="1.3"
                            whiteSpace="pre-line"
                            paddingBottom="10px"
                        >
                            {aerodrome.atis && aerodrome.atis.data}
                        </Text>
                    </Flex>
                </>
            ),
            noinfo: null,
            error: null,
        },
    ]

    return (
        <>
            {AERODROME_ITEMS.map((aerodromeItem, index, arr) => {
                if (aerodromeItem.data || aerodromeItem.id === 'NOTAM') {
                    return (
                        aerodromeItem.display && (
                            <AccordionItem
                                key={`AerodromeItem${aerodromeItem.id}${index}`}
                                borderStyle="none"
                                index="1"
                                boxShadow="0px -5px 15px 0px rgba(0,0,0,0.04)"
                                borderBottom={
                                    index + 1 !== arr.length &&
                                    '1px solid #efefef'
                                }
                                zIndex={arr.length + 1 - index}
                                position="relative"
                            >
                                {({ isExpanded }) => (
                                    <>
                                        <AccordionButton
                                            minHeight="44px"
                                            sx={{
                                                clipPath:
                                                    'inset(0px 0px -50px 0px)',
                                            }}
                                            pl="1.3rem"
                                            pr={5}
                                            py={2}
                                            _hover={{
                                                background: aerodromeItem.data
                                                    ? 'light.30'
                                                    : 'white',
                                                cursor: aerodromeItem.data
                                                    ? 'pointer'
                                                    : 'initial',
                                            }}
                                            color={
                                                aerodromeItem.data
                                                    ? '#1778b7'
                                                    : 'light.100'
                                            }
                                            background={
                                                isExpanded && aerodromeItem.data
                                                    ? // ? 'light.20'
                                                      '#F4F6F8'
                                                    : 'white'
                                            }
                                            style={{
                                                borderBottomRadius:
                                                    isExpanded &&
                                                    aerodromeItem.data
                                                        ? '0px'
                                                        : '20px',
                                                transition:
                                                    'boxShadow all 800ms',
                                            }}
                                            boxShadow={
                                                aerodromeItem.data &&
                                                isExpanded &&
                                                '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                            }
                                            _focus={{
                                                boxShadow:
                                                    aerodromeItem.data &&
                                                    isExpanded
                                                        ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                        : 'none',
                                            }}
                                            opacity={
                                                aerodromeItem.data ? '1' : '0.5'
                                            }
                                        >
                                            <Flex marginTop="-1px" mx="2px">
                                                <Icon
                                                    as={aerodromeItem.icon}
                                                    boxSize="6"
                                                />
                                            </Flex>

                                            <Flex
                                                flexDirection="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                fontSize={adjustLabelFontSize}
                                                lineHeight="1.2"
                                                fontWeight={
                                                    aerodromeItem.data &&
                                                    isExpanded
                                                        ? '600'
                                                        : '500'
                                                }
                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                flex="1"
                                                textAlign="left"
                                                paddingLeft="11px"
                                            >
                                                {aerodromeItem.label}

                                                {/* if NOTAM then add badge */}
                                                {aerodromeItem.id === 'NOTAM' &&
                                                aerodrome.notam &&
                                                aerodrome.notam.filter(
                                                    (notam) =>
                                                        parseISO(
                                                            notam.startvalidity
                                                        ) <= periodTo
                                                ).length ? (
                                                    <Badge
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        minWidth={calculateBadgeSize(
                                                            18
                                                        )}
                                                        minHeight={calculateBadgeSize(
                                                            18
                                                        )}
                                                        height={calculateBadgeSize(
                                                            18
                                                        )}
                                                        fontSize={calculateFontSize(
                                                            0.65
                                                        )}
                                                        ml={2}
                                                        fontFamily="'Open Sans', sans-serif"
                                                        variant="solid"
                                                        bg={
                                                            isExpanded
                                                                ? 'light.10'
                                                                : 'warning.100'
                                                        }
                                                        color={
                                                            isExpanded
                                                                ? 'warning.100'
                                                                : 'light.10'
                                                        }
                                                        border="1px solid"
                                                        borderColor="warning.100"
                                                        transition="all ease 250ms"
                                                        borderRadius="50px"
                                                        px="5px"
                                                        py="0px"
                                                    >
                                                        {
                                                            aerodrome.notam.filter(
                                                                (notam) =>
                                                                    parseISO(
                                                                        notam.startvalidity
                                                                    ) <=
                                                                    periodTo
                                                            ).length
                                                        }
                                                    </Badge>
                                                ) : (
                                                    ''
                                                )}

                                                {/* if TEMP ATIS then add badge */}
                                                {aerodromeItem.id === 'ATIS' &&
                                                    tempATIS && (
                                                        <Badge
                                                            ml="13px"
                                                            height="16px"
                                                            minWidth="80px"
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            variant="solid"
                                                            bg="brand.plus"
                                                            borderRadius="10"
                                                            px="4px"
                                                            py="0px"
                                                            fontSize="0.65rem"
                                                        >
                                                            TEMPORARY
                                                        </Badge>
                                                    )}
                                            </Flex>
                                            {aerodromeItem.data &&
                                                isExpanded && (
                                                    <Text
                                                        textAlign="end"
                                                        whiteSpace="pre-wrap"
                                                        fontSize="0.75rem"
                                                        color="light.300"
                                                        opacity="0.8"
                                                        mr="22px"
                                                    >
                                                        {aerodromeItem.sublabel}
                                                    </Text>
                                                )}
                                            {aerodromeItem.data ? (
                                                <Flex>
                                                    {isExpanded ? (
                                                        <AccordionIcon transform="rotate(0deg)" />
                                                    ) : (
                                                        <AccordionIcon transform="rotate(-90deg)" />
                                                    )}
                                                </Flex>
                                            ) : (
                                                <Box
                                                    {...noFocus}
                                                    pr={1}
                                                    fontSize={calculateFontSize(
                                                        0.8
                                                    )}
                                                    lineHeight="1.2"
                                                    fontWeight="400"
                                                    flex="3"
                                                    textAlign="right"
                                                    color="light.100"
                                                >
                                                    {aerodromeItem.noinfo}
                                                </Box>
                                            )}
                                        </AccordionButton>
                                        {aerodromeItem.data && (
                                            <AccordionPanel
                                                pt={5}
                                                pb={6}
                                                pl="1.4rem"
                                                pr={5}
                                            >
                                                {aerodromeItem.content}
                                            </AccordionPanel>
                                        )}
                                    </>
                                )}
                            </AccordionItem>
                        )
                    )
                } else {
                    return null
                }
            })}
        </>
    )
}
