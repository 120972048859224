import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function NotamIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5Z"
                fill="currentColor"
            />
            <path
                d="M10.1268 7.36729C10.0585 6.49531 10.7476 5.75 11.6223 5.75H12.3777C13.2524 5.75 13.9415 6.49531 13.8732 7.36729L13.4376 12.9207C13.3787 13.6711 12.7527 14.25 12 14.25C11.2473 14.25 10.6213 13.6711 10.5624 12.9207L10.1268 7.36729Z"
                fill="currentColor"
            />
            <path
                d="M13.25 17C13.25 17.6904 12.6904 18.25 12 18.25C11.3096 18.25 10.75 17.6904 10.75 17C10.75 16.3096 11.3096 15.75 12 15.75C12.6904 15.75 13.25 16.3096 13.25 17Z"
                fill="currentColor"
            />
        </Icon>
    )
}
