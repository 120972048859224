import React, { useEffect } from 'react'

import {
    Flex,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Modal,
    Heading,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Icon,
    Divider,
    useDisclosure,
    LightMode,
} from '@chakra-ui/react'

import { useRecoilState, useRecoilValue } from 'recoil'
import {
    clickedFeaturesState,
    timeZoneState,
    globalTimeState,
} from '../../../globalState'

import { ReactComponent as drone } from '../../../icons/assets/drone.svg'
import airshare from '../assets/airshare_logo_blue.jpg'

import formatDateTime, { timeRangeInWords } from '../../../util/dateFormatter'

export default function RpasModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const timeZone = useRecoilValue(timeZoneState)
    const now = useRecoilValue(globalTimeState)

    const feat = clickedFeatures[0]

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    const onCloseHandler = () => {
        setClickedFeatures([])
        onClose()
    }
    return (
        <>
            <LightMode>
                <Modal
                    variant="rpas"
                    size="lg"
                    isCentered
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    // show={clickedFeatures && clickedFeatures.length === 1}
                    onHide={() => setClickedFeatures([])}
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton color="white" />
                        <ModalHeader backgroundImage="linear-gradient(345deg, #F66 10%, #E42727 90%)">
                            <Flex
                                flexDirection="row"
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Icon
                                        pointerEvents="none"
                                        transform="scale(2.2) translateX(-4px)"
                                        opacity="0.1"
                                        boxSize="36px"
                                        as={drone}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                </Flex>
                                <Flex
                                    ml="-36px"
                                    color="white"
                                    pr={8}
                                    alignItems="center"
                                >
                                    <Icon
                                        boxSize="32px"
                                        as={drone}
                                        alt="!"
                                        color="#ffffff"
                                    />
                                    <Box pl={4}>
                                        <Heading variant="rpas">
                                            {'RPAS Flight #' +
                                                feat.properties.id}
                                        </Heading>
                                    </Box>
                                </Flex>
                            </Flex>
                        </ModalHeader>

                        <ModalBody>
                            <Table size="sm" variant="normal" marginTop="2">
                                <Tbody>
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="100px"
                                        >
                                            Status:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.start_time &&
                                            feat?.properties?.end_time
                                                ? timeRangeInWords(
                                                      now,
                                                      feat.properties
                                                          .start_time,
                                                      feat.properties.end_time
                                                  )
                                                : feat?.properties
                                                      ?.start_time &&
                                                  !feat?.properties?.end_time
                                                ? timeRangeInWords(
                                                      now,
                                                      feat.properties.start_time
                                                  )
                                                : ''}
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="100px"
                                        >
                                            Start Time:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.start_time &&
                                                formatDateTime(
                                                    feat.properties.start_time,
                                                    timeZone
                                                )}
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="100px"
                                        >
                                            End Time:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.end_time &&
                                                formatDateTime(
                                                    feat.properties.end_time,
                                                    timeZone
                                                )}
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="100px"
                                        >
                                            Altitude:
                                        </Td>
                                        <Td width="100%">
                                            SFC –{' '}
                                            {feat.properties.altitude +
                                                ' ft AGL'}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="100px"
                                        >
                                            Radio:
                                        </Td>
                                        <Td width="100%">
                                            {feat.properties.radio === 'true'
                                                ? 'Yes'
                                                : 'No'}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                            <Divider variant="modalFooter" />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    float: 'right',
                                    fontSize: '0.7rem',
                                    marginBottom: '4px',
                                }}
                            >
                                <p>Powered by</p>
                                <a
                                    href="https://www.airshare.co.nz/"
                                    target="_blank"
                                    without="true"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={airshare}
                                        style={{
                                            width: 100,
                                            marginTop: '-4px',
                                            marginLeft: 10,
                                        }}
                                        alt="Airshare Logo"
                                    />
                                </a>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
