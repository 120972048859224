import React, { useEffect } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Fade, Flex, Text, useBreakpointValue } from '@chakra-ui/react'

import {
    contrastState,
    altitudeModeState,
    selectedAltitudeState,
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    altitudeDisplayVisibleState,
    modalVisibilityState,
    errorBadgeState,
    locationToastActiveState,
    mapDisabledState,
} from '../../globalState'

import AltitudeRangeIcon from '../../icons/menu-icons/AltitudeRangeWhite'
export default function AltitudeDisplay({
    mapToolsExpanded,
    altitudeSliderExpanded,
    altitudeSliderVisible,
    showFilters,
}) {
    const contrastHandler = useRecoilValue(contrastState)
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const errorMessageModal = modalVisibility.error
    const errorBadgeVisible = useRecoilValue(errorBadgeState)
    const altitudeMode = useRecoilValue(altitudeModeState)
    const selectedAltitude = useRecoilValue(selectedAltitudeState)
    const selectedLowerAltitude = useRecoilValue(selectedLowerAltitudeState)
    const selectedUpperAltitude = useRecoilValue(selectedUpperAltitudeState)
    const locationToastActive = useRecoilValue(locationToastActiveState)
    const mapDisabled = useRecoilValue(mapDisabledState)

    const [altitudeDisplayVisible, setAltitudeDisplayVisible] = useRecoilState(
        altitudeDisplayVisibleState
    )

    useEffect(() => {
        setAltitudeDisplayVisible(
            (mapToolsExpanded &&
                altitudeSliderVisible &&
                !altitudeSliderExpanded) ||
                (!mapToolsExpanded && altitudeSliderVisible)
        )
    }, [
        mapToolsExpanded,
        altitudeSliderExpanded,
        altitudeSliderVisible,
        setAltitudeDisplayVisible,
    ])

    function altitudeFormatter(val) {
        return val === 0
            ? 'SFC'
            : Math.round(val) < 13500
            ? `${
                  Math.round(val) >= 10000
                      ? Math.round(val).toLocaleString('en')
                      : Math.round(val)
              } ft`
            : `FL${Math.round(val) / 100}`
    }

    const iconSize = useBreakpointValue({
        base: mapDisabled ? 5 : 4,
        md: mapDisabled ? 5 : 4,
    })

    const iconColor = useBreakpointValue({
        base: '#ffffff',
        md: mapDisabled ? 'gray.400' : '#ffffff',
    })

    const disableShadow = useBreakpointValue({
        base: false,
        md: mapDisabled ? true : false,
    })

    return (
        <Fade in={altitudeDisplayVisible}>
            <Flex justifyContent="center">
                <Flex
                    pointerEvents="none"
                    className="altitude-range"
                    pos={{
                        base: mapDisabled ? 'absolute' : 'relative',
                        md: 'absolute',
                    }}
                    zIndex={mapDisabled && '1100'}
                    right={{
                        base: mapDisabled ? '65px' : 0,
                        md: mapDisabled ? 8 : 65,
                    }}
                    top={{
                        base: mapDisabled
                            ? '90px'
                            : locationToastActive
                            ? '115px'
                            : '64px',
                        md: mapDisabled ? '20px' : '23px',
                    }}
                    mt={{
                        base: !errorMessageModal && errorBadgeVisible && '45px',
                        md: '0',
                    }}
                    transition="all 400ms"
                    fontSize={mapDisabled ? '0.9rem' : '11px'}
                    fontWeight="600"
                    justifyContent="center"
                    display="inline-flex"
                    opacity={{
                        base: mapDisabled && !showFilters ? '0' : '1',
                        md: locationToastActive ? '0' : '1',
                    }}
                    visibility={{
                        base:
                            mapDisabled && !showFilters ? 'hidden' : 'visible',
                        md: locationToastActive ? 'hidden' : 'visible',
                    }}
                    pl="3px"
                    pr="3px"
                    pt="5px"
                    pb="3px"
                    borderRadius="10px"
                    color={{
                        base: '#ffffff',
                        md: mapDisabled ? 'gray.400' : '#ffffff',
                    }}
                    textShadow={{
                        base: '1px 1px 2px rgba(0,0,0,0.3)',
                        md: mapDisabled
                            ? 'none'
                            : contrastHandler === 'high'
                            ? '1px 1px 3px rgba(0,0,0,1)' // If High Contrast Layer
                            : contrastHandler === 'medium'
                            ? '1px 1px 3px rgba(0,0,0,0.5)' // If Medium Contrast Layer
                            : '1px 1px 2px rgba(0,0,0,0.3)', // All other layers
                    }}
                >
                    <AltitudeRangeIcon
                        boxSize={iconSize}
                        color={iconColor}
                        disableShadow={disableShadow}
                    />

                    <Flex px="4px" lineHeight={'1.3'}>
                        {altitudeMode === 'single' ? (
                            altitudeFormatter(selectedAltitude)
                        ) : (
                            <>
                                {altitudeFormatter(selectedLowerAltitude)}
                                <Text px={1}>–</Text>
                                {altitudeFormatter(selectedUpperAltitude)}
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Fade>
    )
}
