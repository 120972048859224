import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { wxTranslate } from '../../../../../util/metDecode'
import PrecipitationIcon from '../../../../../icons/menu-icons/PrecipitationIcon'
import FogIcon from '../../../../../icons/menu-icons/FogIcon'

export default function AerodromesPresentWeather(props) {
    const {
        metarActive,
        timePeriod,
        aerodromeBackgroundsDisabled,
        cloudCoverBoxColor,
        metarCloud,
        presentWeather,
        infotabGraphicsDisabled,
        fog,
        precipitationPresent,
    } = props

    return (
        <Flex
            width={metarActive ? '100%' : 'auto'}
            background={
                !infotabGraphicsDisabled &&
                (metarActive && !aerodromeBackgroundsDisabled
                    ? cloudCoverBoxColor(
                          timePeriod === 'Night'
                              ? 'Night'
                              : timePeriod === 'Sunrise'
                              ? 'Sunrise'
                              : timePeriod === 'Sunset'
                              ? 'Sunset'
                              : metarCloud
                              ? metarCloud
                              : 'white'
                      )
                    : 'white')
            }
            border={metarActive ? 'none' : '1px solid rgb(229 235 242)'}
            boxShadow={
                !infotabGraphicsDisabled && aerodromeBackgroundsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            backdropFilter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'blur(25px) contrast(102%) saturate(102%)'
            }
            borderRadius="20px"
            pt={'10px'}
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '25px' : '10px'}
            direction={{
                base: 'column',
                md: 'column',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                }}
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color={
                        aerodromeBackgroundsDisabled
                            ? '#9db2cd'
                            : 'rgba(255,255,255,0.6)'
                    }
                >
                    {presentWeather &&
                    !precipitationPresent &&
                    (metarCloud === 'Fog' ||
                        fog ||
                        (presentWeather.length === 1 &&
                            presentWeather.some(
                                (item) => item === 'Mist' || item === 'Haze'
                            ))) ? (
                        <FogIcon boxSize="36px" />
                    ) : (
                        <PrecipitationIcon boxSize="36px" />
                    )}
                </Flex>
                <Flex direction="column">
                    <Text
                        fontSize={{
                            base: '1.1rem',
                            md: '1.1rem',
                        }}
                        lineHeight="1.2"
                        pt={{
                            base: '6px',
                            md: '6px',
                        }}
                        pb={{
                            base: '8px',
                            md: '8px',
                        }}
                        pl={infotabGraphicsDisabled ? '12px' : '5px'}
                        fontWeight="600"
                    >
                        {presentWeather.map((wx) => wxTranslate(wx)).join(', ')}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
