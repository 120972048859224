import React, { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import * as turf from '@turf/turf'
import { Text, Flex, Icon, Spinner } from '@chakra-ui/react'
import { isDesktop } from 'react-device-detect'
import FullWidthError from '../../map/modals/components/FullWidthError'
import { ReactComponent as turbulence } from '../../icons/assets/turbulence-symbol-severe.svg'
import { ReactComponent as mountainWaves } from '../../icons/assets/mountain-waves-severe.svg'
import { ReactComponent as mountainWavesSymbol } from '../../icons/assets/mountain-waves-symbol-severe.svg'
import { ReactComponent as icing } from '../../icons/assets/icing-severe.svg'
import { ReactComponent as dust } from '../../icons/assets/dust.svg'
import { ReactComponent as radioactive } from '../../icons/assets/atomic-cloud.svg'
import { ReactComponent as unknown } from '../../icons/assets/unknown.svg'
import { ReactComponent as tropicalcyclone } from '../../icons/assets/tropical-cyclone.svg'
import { ReactComponent as tropicalcycloneSymbol } from '../../icons/assets/tropical-cyclone-symbol.svg'
import { ReactComponent as wind } from '../../icons/assets/wind.svg'
import { ReactComponent as ice } from '../../icons/assets/ice.svg'
import { ReactComponent as vac } from '../../icons/assets/vac-symbol.svg'
import { ReactComponent as volcano } from '../../icons/assets/vaa.svg'
import { ReactComponent as snow } from '../../icons/assets/snow.svg'
import { ReactComponent as thunderstorm } from '../../icons/assets/thunderstorm.svg'
import { ReactComponent as thunderstormSymbol } from '../../icons/assets/thunderstorm-symbol.svg'

import {
    selectedTimeState,
    dataModeState,
    clickedFeaturesState,
} from '../../globalState'
import { formatISO, parseISO } from 'date-fns'

export default function SigmetList(props) {
    const {
        setSigmetCount,
        dataLoading,
        dataLoadingError,
        selectedList,
        lowerAltitude,
        upperAltitude,
        data,
        status,
    } = props
    const selectedTime = useRecoilValue(selectedTimeState)
    const dataMode = useRecoilValue(dataModeState)
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const [formattedData, setFormattedData] = useState()

    useEffect(() => {
        if (data?.features) {
            const currentTime = Date.now() / 1000
            const filteredFeatures = data?.features
                .filter((feat) =>
                    feat?.properties?.end_epoch
                        ? feat.properties.end_epoch > currentTime
                        : true
                )
                // check if any duplicate sigmets with same id and remove the duplicate (will select first one)
                .filter(
                    (feat, index, self) =>
                        index ===
                        self.findIndex(
                            (f) => f?.properties?.id === feat?.properties?.id
                        )
                )
                .map((feat) => {
                    var newFeat = feat
                    newFeat.properties.center =
                        turf.centerOfMass(feat).geometry.coordinates
                    newFeat.layer = 'sigmet'
                    return newFeat
                })

            setFormattedData({
                type: 'FeatureCollection',
                features: filteredFeatures,
            })
        }
    }, [data, dataMode])

    useEffect(() => {
        if (formattedData?.features && formattedData.features.length > 0) {
            setSigmetCount(formattedData.features.length)
        } else {
            setSigmetCount(0)
        }
    }, [formattedData, setSigmetCount])

    const SIGMETConfig = {
        'ISOL TS': {
            title: 'ISOLATED THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'OBSC TS': {
            title: 'OBSCURE THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'EMBD TS': {
            title: 'EMBEDDED THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'FRQ TS': {
            title: 'FREQUENT THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'SQL TS': {
            title: 'SQUALL LINE THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'OBSC TSGR': {
            title: 'OBSCURE THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'EMBD TSGR': {
            title: 'EMBEDDED THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'FRQ TSGR': {
            title: 'FREQUENT THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'SQL TSGR': {
            title: 'SQUALL LINE THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
        },
        'SEV TURB': {
            title: 'SEVERE TURBULENCE',
            icon: turbulence,
            watermark: wind,
            colour: '#e76f6f',
        },
        'SEV ICE': {
            title: 'SEVERE ICING',
            icon: icing,
            watermark: ice,
            colour: '#58ccf1',
        },
        'SEV ICE (FZRA)': {
            title: 'SEVERE ICING WITH FREEZING RAIN',
            icon: ice,
            watermark: ice,
            colour: '#58ccf1',
        },
        'SEV MTW': {
            title: 'MOUNTAIN WAVES',
            icon: mountainWavesSymbol,
            watermark: mountainWaves,
            colour: '#152238',
        },
        'HVY DS': {
            title: 'HEAVY DUST STORM',
            icon: dust,
            watermark: wind,
            colour: '#989898',
        },
        'HVY SS': {
            title: 'HEAVY SNOW STORM',
            icon: snow,
            watermark: snow,
            colour: '#152238',
        },
        'RDOACT CLD': {
            title: 'RADIOACTIVE CLOUD',
            icon: radioactive,
            watermark: radioactive,
            colour: '#9fd923',
        },
        TC: {
            title: 'TROPICAL CYCLONE',
            icon: tropicalcycloneSymbol,
            watermark: tropicalcyclone,
            colour: '#1261A0',
        },
        'VA ERUPTION': {
            title: 'VOLCANIC ASH ERUPTION',
            icon: vac,
            watermark: volcano,
            colour: '#eb6aeb',
        },
        'VA CLD': {
            title: 'VOLCANIC ASH CLOUD',
            icon: vac,
            watermark: volcano,
            colour: '#eb6aeb',
        },
        '': {
            title: 'UNDEFINED',
            icon: unknown,
            colour: '#85a0b5',
        },
        null: {
            title: 'UNDEFINED',
            icon: unknown,
            colour: '#85a0b5',
        },
    }

    const SIGMETObsfcst = {
        FCS: {
            title: 'FORECAST',
        },
        OBS: {
            title: 'OBSERVED',
        },

        '': {
            title: '',
        },
    }

    // useEffect(() => {
    //     console.log('SIGMET Data:', data)
    //     console.log('SIGMET formattedData:', formattedData)
    // }, [data, formattedData])

    if (
        !dataLoading &&
        !dataLoadingError &&
        formattedData &&
        formattedData.features &&
        formattedData.features.length
    ) {
        return (
            <>
                {[...formattedData.features]
                    .sort((a, b) => {
                        const sigmetCenterA = a.properties.center[1]
                        const sigmetCenterB = b.properties.center[1]
                        if (sigmetCenterA < sigmetCenterB) {
                            return 1
                        }
                        if (sigmetCenterA > sigmetCenterB) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                    .map((feat, i) => {
                        const config = SIGMETConfig[feat.properties.phenomenon]

                        const configObsfcst =
                            SIGMETObsfcst[feat.properties.obsfcst.slice(0, 3)]

                        const outsideSelectedTime =
                            formatISO(selectedTime) <
                                formatISO(
                                    parseISO(feat.properties.validfrom)
                                ) ||
                            formatISO(selectedTime) >
                                formatISO(parseISO(feat.properties.validto))

                        const outsideSelectedAltitudeRange =
                            lowerAltitude > feat?.properties?.upperfilter ||
                            upperAltitude < feat?.properties?.lowerfilter

                        const openModal =
                            feat.properties.id ===
                            clickedFeatures[0]?.properties?.id

                        return (
                            <Flex
                                key={`${feat.properties.id}-${i}`}
                                display={
                                    selectedList === 'sigmet' ? 'flex' : 'none'
                                }
                                bg={
                                    openModal
                                        ? 'white'
                                        : 'rgba(255,255,255,0.75)'
                                }
                                boxShadow={
                                    openModal
                                        ? '0px 8px 25px -7px rgba(0,15,35,0.35)'
                                        : outsideSelectedTime ||
                                          outsideSelectedAltitudeRange
                                        ? '0px 7px 25px -7px rgba(0,15,35,0.1)'
                                        : '0px 7px 25px -7px rgba(0,15,35,0.2)'
                                }
                                opacity={
                                    (clickedFeatures[0]?.properties?.id &&
                                        !openModal) ||
                                    outsideSelectedTime ||
                                    outsideSelectedAltitudeRange
                                        ? '0.5' // if selected time range is outside sigmet levels
                                        : '1'
                                }
                                pointerEvents={
                                    outsideSelectedAltitudeRange ||
                                    outsideSelectedTime
                                        ? 'none' //  if selected altitude range is outside sigmet levels
                                        : 'auto'
                                }
                                transform={openModal && 'scale(1.03)'}
                                _hover={
                                    isDesktop &&
                                    !(
                                        outsideSelectedAltitudeRange ||
                                        outsideSelectedTime
                                    ) && {
                                        opacity: '1',
                                        cursor: 'pointer',
                                        background: 'rgba(255,255,255,1)',
                                        boxShadow:
                                            '0px 12px 30px -12px rgba(0,15,35,0.7)',
                                        transform:
                                            'translateY(-1px) translateX(0px) scale(1.01)',
                                    }
                                }
                                onClick={() => {
                                    if (
                                        !(
                                            outsideSelectedTime ||
                                            outsideSelectedAltitudeRange
                                        )
                                    ) {
                                        setClickedFeatures([feat])
                                    }
                                }}
                                borderRadius="20px"
                                pl="6px"
                                pr="9px"
                                transition="all ease 200ms"
                                mt={{ base: '0px', md: '0px' }}
                                mb={{
                                    base: '10px',
                                    md:
                                        isDesktop &&
                                        i + 1 === formattedData.features.length
                                            ? '3px'
                                            : '10px',
                                }}
                                flexDirection="column"
                                width="100%"
                                zIndex={`calc(1000 - ${i})`}
                            >
                                <Flex
                                    my="3px"
                                    width="100%"
                                    flexDirection="row"
                                    minHeight="36px"
                                >
                                    <Flex alignItems="center">
                                        <Icon
                                            bg={
                                                !(
                                                    outsideSelectedAltitudeRange ||
                                                    outsideSelectedTime
                                                ) && config?.colour
                                                    ? config.colour
                                                    : 'gray.300'
                                            }
                                            color="white"
                                            borderRadius="100%"
                                            px="2px"
                                            boxSize="30px"
                                            alignItems="center"
                                            justifyContent="center"
                                            as={config && config.icon}
                                        />
                                    </Flex>
                                    <Flex
                                        pl="7px"
                                        lineHeight="1.5"
                                        width="100%"
                                        py="1px"
                                        flexDirection="column"
                                        color="light.100"
                                        alignItems="center"
                                    >
                                        <Flex
                                            width="100%"
                                            height="100%"
                                            flexDirection="row"
                                            alignItems="flex-start"
                                            justifyContent="space-between"
                                            // borderBottom="1px solid rgba(0,0,0,0.05)"
                                        >
                                            <Flex
                                                width="100%"
                                                height="100%"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                justifyContent="center"
                                            >
                                                <Text
                                                    whiteSpace="pre-line"
                                                    fontSize="0.75rem"
                                                    fontWeight="700"
                                                    lineHeight="1.3"
                                                    pt="1px"
                                                    textTransform="uppercase"
                                                >
                                                    {config && config.title}
                                                </Text>
                                                {/* {feat?.properties
                                                        ?.phenomenon ===
                                                        'VA ERUPTION' && (
                                                        <Text
                                                            fontSize="10px"
                                                            fontWeight="600"
                                                            whiteSpace="pre-line"
                                                            color={'red'}
                                                        >
                                                            EXERCISE ONLY
                                                        </Text>
                                                    )} */}
                                            </Flex>
                                            {outsideSelectedTime ? (
                                                <Text
                                                    fontSize="0.65rem"
                                                    fontWeight="600"
                                                    color="#c44d4d"
                                                    whiteSpace="pre"
                                                >
                                                    Outside selected time
                                                </Text>
                                            ) : (
                                                <Flex
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    color={'light.100'}
                                                >
                                                    <Text
                                                        fontSize="0.7rem"
                                                        fontWeight="500"
                                                        whiteSpace="pre-line"
                                                    >
                                                        {configObsfcst.title}
                                                    </Text>
                                                </Flex>
                                            )}
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            height="100%"
                                            fontSize="0.7rem"
                                            fontWeight="400"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            color="gray.500"
                                        >
                                            <Flex flexDirection="row">
                                                {feat.properties.level ? (
                                                    <Text whiteSpace="pre-line">
                                                        {feat.properties.level}
                                                    </Text>
                                                ) : (
                                                    feat?.properties
                                                        ?.lowerlabel &&
                                                    feat?.properties
                                                        ?.upperlabel && (
                                                        <Flex
                                                            flexDirection="row"
                                                            fontWeight={
                                                                outsideSelectedAltitudeRange
                                                                    ? '600'
                                                                    : '400'
                                                            }
                                                            color={
                                                                outsideSelectedAltitudeRange
                                                                    ? '#c44d4d'
                                                                    : 'gray.500'
                                                            }
                                                        >
                                                            {outsideSelectedAltitudeRange ? (
                                                                <Text>
                                                                    Outside
                                                                    altitude
                                                                    filter range
                                                                </Text>
                                                            ) : (
                                                                <>
                                                                    <Text whiteSpace="pre-line">
                                                                        {feat
                                                                            .properties
                                                                            .lowerlabel &&
                                                                            `${
                                                                                feat.properties.lowerlabel.substring(
                                                                                    0,
                                                                                    2
                                                                                ) ===
                                                                                    'FL' ||
                                                                                feat
                                                                                    .properties
                                                                                    .lowerlabel ===
                                                                                    'SFC'
                                                                                    ? feat
                                                                                          .properties
                                                                                          .lowerlabel
                                                                                    : feat
                                                                                          .properties
                                                                                          .lowerlabel +
                                                                                      ' ft'
                                                                            }`}
                                                                    </Text>
                                                                    <Text px="3px">
                                                                        –
                                                                    </Text>
                                                                    <Text whiteSpace="pre-line">
                                                                        {feat
                                                                            .properties
                                                                            .upperlabel &&
                                                                            `${
                                                                                feat.properties.upperlabel.substring(
                                                                                    0,
                                                                                    2
                                                                                ) ===
                                                                                'FL'
                                                                                    ? feat
                                                                                          .properties
                                                                                          .upperlabel
                                                                                    : feat
                                                                                          .properties
                                                                                          .upperlabel +
                                                                                      ' ft'
                                                                            }`}
                                                                    </Text>
                                                                </>
                                                            )}
                                                        </Flex>
                                                    )
                                                )}
                                            </Flex>

                                            {/* {feat.properties.intensity && (
                                                    <Flex flexDirection="row">
                                                        <Text whiteSpace="pre-line">
                                                            {
                                                                feat.properties
                                                                    .intensity
                                                            }
                                                        </Text>
                                                    </Flex>
                                                )} */}

                                            {feat.properties.movement && (
                                                <Flex flexDirection="row">
                                                    <Text whiteSpace="pre-line">
                                                        {
                                                            feat.properties
                                                                .movement
                                                        }
                                                    </Text>
                                                </Flex>
                                            )}

                                            {/* <Flex flexDirection="row">
                                                    <Text whiteSpace="pre-line">
                                                        {feat.properties.id}
                                                    </Text>
                                                </Flex> */}
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        height="auto"
                                        width="40px"
                                        minWidth="40px"
                                        overflow="hidden"
                                        mr="-3px"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <Icon
                                            sx={{ transform: 'scale(1.2)' }}
                                            color="rgba(20,25,30, 0.07)"
                                            py="2px"
                                            boxSize="36px"
                                            alignItems="center"
                                            justifyContent="center"
                                            as={config && config.watermark}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        )
                    })}
            </>
        )
    } else {
        return (
            <>
                {(status === 'loading' || dataLoading) && (
                    <Flex
                        w="100%"
                        pt="40px"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Spinner
                            color={'light.200'}
                            emptyColor="rgba(255,255,255,0.3)"
                            thickness="3px"
                            speed="0.45s"
                            boxSize="40px"
                        />
                    </Flex>
                )}
                {status !== 'loading' && status === 'error' && !dataLoading && (
                    <FullWidthError message={'Error fetching SIGMET data'} />
                )}
                {status === 'success' && !dataLoading && (
                    <Flex // NO DATA
                        display={selectedList === 'sigmet' ? 'flex' : 'none'}
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        pt="40px"
                        px="5px"
                        color="gray.500"
                        zIndex="1"
                        fontWeight="500"
                        fontSize={{
                            base: '0.8rem',
                            xs: '0.85rem',
                            md: '0.9rem',
                        }}
                        whiteSpace="pre-line"
                        textAlign="center"
                        lineHeight="1.2"
                    >
                        {formattedData &&
                        ((formattedData.features &&
                            formattedData.features.length === 0) ||
                            formattedData.metadata) ? (
                            <Text>No current SIGMET advisories</Text>
                        ) : (
                            <Text>NO SIGMET information received</Text>
                        )}
                    </Flex>
                )}
            </>
        )
    }
}
