import React, { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Center, Tooltip } from '@chakra-ui/react'

import { isDesktop, isIOS } from 'react-device-detect'
import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../../../globalState'
import LegendIcon from '../../../../icons/menu-icons/LegendIcon'

export default function LegendButton() {
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const modalVisible = useRecoilValue(modalVisibilityState)
    const setShowLegend = useCallback(
        (value) => {
            setModalVisibility({ id: 'legend', value })
        },
        [setModalVisibility]
    )

    const legendActive =
        modalVisible &&
        Object.entries(modalVisible).filter(
            (layer) => layer[0] === 'legend'
        )[0][1]

    return (
        <Tooltip
            display={isDesktop ? 'flex' : 'none'}
            isDisabled={isDesktop ? false : true}
            label="Map Legend"
            hasArrow
            borderRadius="7px"
            height="26px"
            fontSize="0.7rem"
            alignItems="center"
            placement="left"
            m="5px"
            color="light.100"
            bg="light.10"
        >
            <Center
                w={{ base: '32px', md: '30px' }}
                h={{ base: '32px', md: '30px' }}
                pt={'10px'}
                pb={'10px'}
                borderRadius="10px"
                backgroundColor={legendActive && 'rgba(255,255,255,0.4)'}
                _hover={{
                    backgroundColor:
                        (isDesktop || !isIOS) && 'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                _focus={{
                    backgroundColor:
                        legendActive &&
                        (isDesktop || !isIOS) &&
                        'rgba(255,255,255,0.3)',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setShowLegend(!legendActive)
                }}
            >
                <LegendIcon
                    color={!legendActive && 'light.10'}
                    activeColor={'light.10'}
                    active={legendActive}
                />
            </Center>
        </Tooltip>
    )
}
