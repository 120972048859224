import { useCallback, useEffect, useRef, useState } from 'react'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import {
    Modal,
    Flex,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ButtonGroup,
    Button,
    LightMode,
    Text,
    useDisclosure,
    Spinner,
    Box,
    Icon,
} from '@chakra-ui/react'
import { isIOS, isMobileOnly } from 'react-device-detect'

import {
    statusData,
    handleStatusUpdate,
    statusRefreshTimeStampState,
    handleMapOutdatedCheck,
    vectorLayerRefreshHandler,
    onlineState,
    setModalVisibilityState,
    modalVisibilityState,
    hideNetworkConnectionLostState,
    briefingStandardState,
    briefingCreatedState,
} from '../../../globalState'
import { constructStatusData } from '../../../util/constructStatusData'

import MapLoading from '../../components/MapLoading'
import RefreshIcon from '../../../icons/menu-icons/RefreshIcon'
import { useQuery } from 'react-query'

export default function Status() {
    const { getAccessTokenSilently } = useAuth0()
    const fetchStatus = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/source/status`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const statDat = useRecoilValue(statusData)

    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setError = useCallback(
        (value) => {
            setModalVisibility({ id: 'statusError', value })
        },
        [setModalVisibility]
    )

    const [hide, setHide] = useRecoilState(hideNetworkConnectionLostState)
    const briefingStandard = useRecoilValue(briefingStandardState)
    const briefingCreated = useRecoilValue(briefingCreatedState)
    const online = useRecoilValue(onlineState)
    const setStatusUpdate = useSetRecoilState(handleStatusUpdate)
    const vectorLayerRefresh = useSetRecoilState(vectorLayerRefreshHandler)
    const [statusLoading, setStatusLoading] = useState(false)
    const outdatedCheck = useSetRecoilState(handleMapOutdatedCheck)
    const setStatusRefreshTimeStamp = useSetRecoilState(
        statusRefreshTimeStampState
    )
    const { isOpen, onOpen, onClose } = useDisclosure()

    // TODO - Refactor - change staleTime to refetchInterval. Clear refetch logic
    const { data, status, isStale, refetch } = useQuery('status', fetchStatus, {
        // Refetch the status data every 30 seconds by setting stale time.
        staleTime: 30000,
    })

    const versionChecked = useRef(true)

    useEffect(() => {
        isStale && refetch()
        function setDataHandler(responseData) {
            // console.log('Data Fetch', { responseData })
            const newStatusData = constructStatusData(responseData, 'type')
            setStatusUpdate({ data: newStatusData })
            setStatusRefreshTimeStamp(new Date())
            setError(false)
        }
        status === 'success' && data && setDataHandler(data)
        status === 'error' && setError(true)
        setStatusLoading(status === 'loading')
    }, [
        status,
        data,
        isStale,
        setError,
        setStatusUpdate,
        setStatusRefreshTimeStamp,
        setStatusLoading,
        refetch,
    ])

    useEffect(() => {
        // This checks layers that are mapOutdated and triggers a refresh of (data) layers that are. This also handles errors based on when a layer has refreshed a maximum amount of times. Check source-status-handler idleHandler to observe functionality.

        // This should ensure that the check does not run multiple times.
        if (versionChecked.current) {
            versionChecked.current = false
            // The below refreshes vector layers when the current time is past expectedupdate.
            vectorLayerRefresh()
            setTimeout(() => {
                // The below refreshes layers that have been marked as outdated by the map status-source-handler.
                outdatedCheck()
                versionChecked.current = true
            }, 3000)
        }
    }, [outdatedCheck, statDat, vectorLayerRefresh])

    useEffect(() => {
        if (online && modalVisibility.statusError) {
            onOpen()
        } else if (
            (online && !modalVisibility.statusError) ||
            (!online && isOpen)
        ) {
            onClose()
            setHide(false)
        } else if (!isOpen) {
            setHide(false)
        }
    }, [modalVisibility.statusError, isOpen, online, onClose, onOpen, setHide])

    function refreshPage() {
        window.location.reload(false)
    }

    return (
        <>
            {!modalVisibility.statusError && statusLoading && <MapLoading />}
            <LightMode>
                <Modal
                    variant="alert"
                    isOpen={isOpen}
                    isCentered
                    closeOnOverlayClick={false}
                    trapFocus={false}
                >
                    <ModalOverlay
                        borderWidth={hide ? 3 : 4}
                        borderColor="#FF4F44"
                        borderTopRadius={{
                            // reset these values to '0' for now pending further testing on mobile devices
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        borderBottomRadius={{
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        bg={hide ? 'transparent' : 'blackAlpha.600'}
                        pointerEvents={hide ? 'none' : 'initial'}
                    />
                    {hide && (
                        <Button
                            zIndex="99999"
                            position="absolute"
                            left={{
                                base: '50%',
                                xl: '260px',
                                '2xl': '325px',
                            }}
                            transform={{
                                base: 'translate(-50%, -50%)',
                                xl: 'none',
                            }}
                            top={{ base: '70px', xl: '20px' }}
                            background={{
                                base: 'rgba(255, 0, 0, 0.4)',
                                xl: 'red.400',
                            }}
                            color="white"
                            variant="solid"
                            leftIcon={
                                <Icon // Icon used directly in file to prevent source break when in connection error
                                    boxSize={6}
                                    viewBox="0 0 24 24"
                                    color="red.500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21.7305 7.51986C16.086 2.82671 7.91402 2.82671 2.26948 7.51986C1.95356 7.77354 1.91143 8.23863 2.16417 8.55573L11.4313 20.2252C11.5788 20.3943 11.7894 20.5 12 20.5C12.2317 20.5 12.4423 20.3943 12.5687 20.2252L21.8358 8.55573C22.0886 8.23863 22.0464 7.79468 21.7305 7.51986ZM12.125 6C11.7475 6.02723 11.3971 6.19059 11.1275 6.46287C10.8578 6.76238 10.723 7.17079 10.777 7.55198L11.5049 13.2153C11.6936 13.1609 11.9093 13.1337 12.125 13.1337C12.3407 13.1337 12.5564 13.1609 12.7721 13.2153L13.473 7.55198V7.36139C13.473 6.59901 12.8799 6 12.125 6ZM10.75 15.6386C10.75 16.3738 11.3701 17 12.125 17C12.8799 17 13.473 16.401 13.5 15.6114C13.5 14.8762 12.8799 14.25 12.125 14.25C11.3701 14.25 10.75 14.8762 10.75 15.6386Z"
                                        fill="white"
                                    />
                                </Icon>
                            }
                            borderRadius="30px"
                            onClick={() => {
                                setHide(false)
                            }}
                            pl="10px"
                            pr="13px"
                            height={{ base: '28px', xl: '30px' }}
                            fontSize="0.75rem"
                            _focus={{
                                border: 'none',
                                background: 'red.500',
                            }}
                            _hover={{ background: 'red.500' }}
                            _active={{ background: 'red.500' }}
                        >
                            Network Connection Lost
                        </Button>
                    )}
                    {!hide && (
                        <ModalContent borderRadius="15" bg="white">
                            <ModalHeader
                                bg="white"
                                color="light.100"
                                boxShadow={'0px 0px 15px -1px rgba(0,0,0,0.15)'}
                                borderBottom="1px solid #eaeaea"
                                marginBottom="3"
                                paddingStart="6"
                                textAlign="center"
                            >
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    mx="auto"
                                    pr={2}
                                >
                                    <Icon // Icon used directly in file to prevent source break when in connection error
                                        boxSize={8}
                                        viewBox="0 0 24 24"
                                        color="red.500"
                                    >
                                        <path
                                            d="M21.7305 7.51986C16.086 2.82671 7.91402 2.82671 2.26948 7.51986C1.95356 7.77354 1.91143 8.23863 2.16417 8.55573L11.4313 20.2252C11.5788 20.3943 11.7894 20.5 12 20.5C12.2317 20.5 12.4423 20.3943 12.5687 20.2252L21.8358 8.55573C22.0886 8.23863 22.0464 7.79468 21.7305 7.51986ZM11.2207 7.20275C11.4313 6.99135 11.7051 6.86451 12 6.84337C12.5897 6.84337 13.0531 7.30846 13.0531 7.90038V8.04837L12.5055 12.4455C12.337 12.4033 12.1685 12.3821 12 12.3821C11.8315 12.3821 11.663 12.4033 11.5156 12.4455L10.9469 8.04837C10.9048 7.7524 11.0101 7.4353 11.2207 7.20275ZM12 15.384C11.4103 15.384 10.9259 14.8978 10.9259 14.327C10.9259 13.7351 11.4103 13.2489 12 13.2489C12.5897 13.2489 13.0741 13.7351 13.0741 14.3059C13.0531 14.919 12.5897 15.384 12 15.384Z"
                                            fill="#E53E3E"
                                        />
                                    </Icon>
                                    <Box px={2} fontFamily="Open Sans">
                                        Failed connection to servers
                                    </Box>
                                </Flex>
                            </ModalHeader>
                            <ModalBody
                                textAlign="center"
                                bg="white"
                                px={5}
                                pb={0}
                                fontSize={{ base: '0.8rem', md: '0.9rem' }}
                            >
                                <Text pb="10px">
                                    We are having trouble connecting you to our
                                    server. We cannot guarantee that the data
                                    you are seeing is up-to-date.
                                </Text>

                                <Text pb="15px">
                                    Please find a stable network connection,
                                    refresh the app or try again later.
                                </Text>
                                {statusLoading && (
                                    <Spinner mt="10px" color="#FF4F44" />
                                )}
                                <Text mt="5px" color="#FF4F44">
                                    ....attempting to reconnect
                                </Text>
                            </ModalBody>
                            <ModalFooter
                                p="6"
                                mt="20px"
                                justifyContent="center"
                                borderTop="1px solid #eaeaea"
                            >
                                {' '}
                                <ButtonGroup
                                    gap="10px"
                                    width="100%"
                                    justifyContent="center"
                                    mt="10px"
                                >
                                    {briefingStandard && briefingCreated && (
                                        <Button
                                            variant="outline"
                                            color="gray.600"
                                            borderRadius="30px"
                                            onClick={() => {
                                                if (
                                                    briefingStandard &&
                                                    briefingCreated
                                                ) {
                                                    setHide(true)
                                                }
                                            }}
                                            w="60%"
                                            _focus={{ border: 'none' }}
                                        >
                                            Close
                                        </Button>
                                    )}
                                    <Button
                                        colorScheme="red"
                                        variant="solid"
                                        leftIcon={
                                            <RefreshIcon
                                                color="white"
                                                boxSize={5}
                                            />
                                        }
                                        borderRadius="30px"
                                        onClick={refreshPage}
                                        w="60%"
                                        _focus={{ border: 'none' }}
                                    >
                                        Refresh
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </ModalContent>
                    )}
                </Modal>
            </LightMode>
        </>
    )
}
