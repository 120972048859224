import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function CloudIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_454:67009)">
                {/* <path
                    opacity="0.2"
                    d="M3.72898 12.1297C3.48866 12.3699 3.00004 13.1655 3 13.9231C3 17.2537 5.67294 16.9975 6.93212 16.9943C8.85061 16.9895 11.8447 16.9943 11.8447 16.9943C12.1944 16.9362 14.2952 16.9974 14.7758 16.9974L18.3086 16.9975C19.1699 16.9975 19.8973 16.0716 20.2526 15.7165C20.4315 15.5376 20.8249 15.076 20.9816 14.1793C21.0696 13.6754 20.829 12.3939 20.5436 11.7134C20.2258 10.9556 19.6991 10.5106 19.0376 10.0801C18.3301 9.7176 16.6077 9.34355 15.8787 9.56774C17.3367 11.7134 18.3086 15.4603 13.4487 15.076C8.58886 14.6917 7.66037 14.8694 6.40192 14.1793C5.14346 13.4892 4.94395 12.8983 4.45796 11.3611C4.21764 11.6013 3.96931 11.8895 3.72898 12.1297Z"
                    fill="currentColor"
                /> */}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 18C3.79086 18 2 16.2091 2 14C2 11.8762 3.65521 10.1389 5.746 10.0079C6.50027 7.40385 8.9028 5.5 11.75 5.5C14.0505 5.5 16.2543 6.92855 17.3251 9.00334C17.3795 9.00126 17.4342 9.00014 17.489 9.00001C17.4927 9 17.4963 9 17.5 9V9C19.8182 9 22.0002 11.0147 22.0002 13.5C22.0002 14.6802 21.5458 15.7542 20.8026 16.5568C19.9806 17.4445 18.8051 18 17.5 18H6ZM17.625 10.5029C17.5954 10.5015 17.5658 10.5006 17.5362 10.5002C17.5241 10.5001 17.5121 10.5 17.5 10.5C17.4752 10.5 17.4505 10.5003 17.4259 10.5009C16.5672 10.5218 15.798 10.9034 15.264 11.5L14.1563 10.4886C14.6065 9.98893 15.1672 9.5907 15.7998 9.33233C14.9336 7.93612 13.3735 7 11.75 7C9.21051 7 7.13647 8.99286 7.00647 11.5H6C4.61929 11.5 3.5 12.6193 3.5 14C3.5 15.3807 4.61929 16.5 6 16.5H17.5C19.1569 16.5 20.5 15.1569 20.5 13.5C20.5 11.8879 19.123 10.5728 17.625 10.5029Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
