import React, { useCallback, useRef } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
    Flex,
    Text,
    useBreakpointValue,
    Stack,
    Container,
    useColorModeValue,
    Divider,
    HStack,
    Link,
    Box,
    // Popover,
    // PopoverTrigger,
    // PopoverContent,
    // PopoverHeader,
    // PopoverBody,
    // PopoverArrow,
    // PopoverCloseButton,
    // Portal,
    // LightMode,
    // useDisclosure,
} from '@chakra-ui/react'
import { getYear } from 'date-fns'

import { isMobileOnly, isIOS } from 'react-device-detect'
import { ReactComponent as AeropathLogo } from '../assets/aeropath.svg'
import { ReactComponent as MetServiceLogo } from '../assets/metservice.svg'

import {
    modalVisibilityState,
    setModalVisibilityState,
} from '../../globalState'
import TermsModal from '../../map/modals/terms-and-conditions'
import ContactUsModal from '../../map/modals/ContactUsModal'
import VersionNumber /*, { VersionDate }*/ from '../../theme/components/VersionNumber'

export default function Footer(props) {
    // const { footerReveal } = props
    const termsRef = useRef()
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const modalVisible = useRecoilValue(modalVisibilityState)

    // *** COMMENTED OUT ITEMS LEFT IN CASE ADDITIONAL METHODS WANTED FOR CONTACT US ***
    // const initRef = useRef()
    // const { isOpen, onToggle, onClose } = useDisclosure()

    const setShowTerms = useCallback(
        (value) => {
            setModalVisibility({ id: 'terms', value })
        },
        [setModalVisibility]
    )

    const logoSize = useBreakpointValue({
        base: '140px',
        md: '200px',
    })

    return (
        <Flex
            // display={footerReveal ? 'flex' : 'none'}
            // position="fixed"
            bottom="0px"
            // pt="80px"
            // height="400px"
            height="320px"
            minHeight="320px"
            bg="linear-gradient(#202f3e  0%,  #273747 100%)"
            width="100%"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
        >
            <Container maxW={'5xl'} zIndex="100" height="100%" px="0">
                <Flex
                    x="100%"
                    justifyContent="space-evenly"
                    px={{ base: 0, md: 5 }}
                    pt="50px"
                    pb={{ base: 6, md: 8 }}
                    align={'center'}
                    _before={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: 'gray.600',
                        flexGrow: 1,
                        mr: { base: 10, md: 16 },
                        display: { base: 'none', md: 'inline-block' },
                    }}
                    _after={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: 'gray.600',
                        flexGrow: 1,
                        ml: { base: 10, md: 16 },
                        display: { base: 'none', md: 'inline-block' },
                    }}
                >
                    <HStack spacing={{ base: 8, md: 16 }}>
                        <Box
                            opacity="0.8"
                            _hover={{
                                opacity: '1',
                            }}
                        >
                            <AeropathLogo
                                cursor="pointer"
                                width={logoSize}
                                height="auto"
                                onClick={() =>
                                    window.open(
                                        'https://aeropath.aero',
                                        '_blank'
                                    )
                                }
                            />
                        </Box>
                        <Box
                            opacity="0.8"
                            _hover={{
                                opacity: '1',
                            }}
                        >
                            <MetServiceLogo
                                cursor="pointer"
                                width={logoSize}
                                height="auto"
                                onClick={() =>
                                    window.open(
                                        'https://metservice.com',
                                        '_blank'
                                    )
                                }
                            />
                        </Box>
                    </HStack>
                </Flex>
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    width="100%"
                    justifyContent="center"
                    align={'center'}
                    pb="20px"
                    px="20px"
                    letterSpacing="0.5px"
                >
                    <Text
                        fontSize={{
                            base: '0.75rem',
                            md: '0.75rem',
                        }}
                        fontWeight="300"
                        color="light.10"
                        opacity="0.5"
                        pr="5px"
                    >
                        PreFlight is a collaboration between
                    </Text>
                    <Flex flexDirection="row">
                        <Text
                            fontSize={{
                                base: '0.75rem',
                                md: '0.75rem',
                            }}
                            fontWeight="300"
                            color="light.10"
                            opacity="0.8"
                            pr="5px"
                            _hover={{
                                cursor: 'pointer',
                                opacity: '1',
                                color: 'light.203',
                            }}
                            onClick={() =>
                                window.open('https://aeropath.aero', '_blank')
                            }
                        >
                            Aeropath
                        </Text>
                        <Text
                            fontSize={{
                                base: '0.75rem',
                                md: '0.75rem',
                            }}
                            fontWeight="300"
                            color="light.10"
                            opacity="0.5"
                            pr="5px"
                        >
                            and
                        </Text>
                        <Text
                            fontSize={{
                                base: '0.75rem',
                                md: '0.75rem',
                            }}
                            fontWeight="300"
                            color="light.10"
                            opacity="0.8"
                            _hover={{
                                cursor: 'pointer',
                                opacity: '1',
                                color: 'light.203',
                            }}
                            onClick={() =>
                                window.open('https://metservice.com', '_blank')
                            }
                        >
                            MetService
                        </Text>
                    </Flex>
                </Flex>

                <Container
                    as={Stack}
                    maxW={'6xl'}
                    pb={6}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Stack
                        align={'center'}
                        fontWeight="300"
                        color="white"
                        ref={termsRef}
                    >
                        {modalVisible.terms && (
                            <TermsModal className={'termsLandingPage'} />
                        )}
                        {modalVisible.contactus && (
                            <ContactUsModal
                                className={'contactUsLandingPage'}
                            />
                        )}

                        <Text
                            opacity="0.8"
                            fontSize={'0.9rem'}
                            _hover={{
                                cursor: 'pointer',
                                opacity: '1',
                                color: 'light.203',
                            }}
                            onClick={() => {
                                setShowTerms(true)
                            }}
                        >
                            Terms and Conditions
                        </Text>
                        <Link
                            opacity="0.8"
                            fontSize={'0.9rem'}
                            _hover={{
                                cursor: 'pointer',
                                opacity: '1',
                                color: 'light.203',
                            }}
                            onClick={() => {
                                window.Userback.open()
                            }}
                        >
                            Contact Us
                        </Link>

                        {/* <Link // TESTING ONLY
                            opacity="0.8"
                            fontSize={'0.9rem'}
                            _hover={{
                                cursor: 'pointer',
                                opacity: '1',
                                color: 'light.203',
                            }}
                            href="#contact-us"
                        >
                            Contact Us TEST
                        </Link> */}
                    </Stack>
                </Container>
            </Container>

            <Divider
                height="0px !important"
                borderColor="dark.100"
                opacity={useColorModeValue('0.15', '0.2')}
            />
            <Flex width="100%" bg="dark.10">
                <Container maxW={'6xl'} zIndex="100">
                    <Flex
                        justifyContent={{
                            base: 'center',
                            md: 'space-between',
                        }}
                        alignItems="center"
                        pt={{ base: 4, md: 4 }}
                        pb={{ base: isIOS && isMobileOnly ? 10 : 7, md: 4 }}
                        fontWeight="300"
                        width="100%"
                        fontSize={{
                            base: '0.8rem',
                            md: '0.85rem',
                        }}
                        color="dark.100"
                        flexDirection={{ base: 'column', md: 'row' }}
                        gap="10px"
                    >
                        <Flex>
                            <Text opacity="0.7" pr="5px">
                                © {getYear(Date.now())}
                            </Text>
                            <Text
                                opacity="0.7"
                                _hover={{
                                    opacity: '1',
                                    cursor: 'pointer',
                                    color: 'light.203',
                                }}
                                pr="7px"
                                onClick={() =>
                                    window.open(
                                        'https://aeropath.aero',
                                        '_blank'
                                    )
                                }
                            >
                                Aeropath Ltd.
                            </Text>
                            <Text opacity="0.7">All rights reserved</Text>
                        </Flex>
                        <Flex opacity="0.7" gap="5px">
                            <Text>Version: </Text>

                            <VersionNumber />

                            {/* <VersionDate /> */}
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
        </Flex>
    )
}
