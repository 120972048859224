import React from 'react'

import { Text, View } from '@react-pdf/renderer'
import { SigmetIcon } from './assets/SvgImages'
import { ErrorIcon } from './assets/SvgImages'

import formatDateTime from '../../../../../util/dateFormatter'
import { parseISO, formatDistanceToNow } from 'date-fns'

export default function SigmetExport(props) {
    const { briefingData, params, styles, timeZone, showOutdatedErrors } = props

    if (briefingData) {
        return (
            // SIGMET SECTION

            <View style={styles.section}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '-1',
                    }}
                >
                    <div
                        wrap={false}
                        style={[
                            styles.sectionTitle,
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                            },
                        ]}
                    >
                        <SigmetIcon
                            width="16"
                            height="16"
                            viewBox="0 0 14 14"
                            fill="rgb(63, 184, 221)"
                        />

                        <Text
                            style={{
                                fontWeight: '600',
                                textAlign: 'right',
                                paddingLeft: '7',
                                color: 'rgb(63, 184, 221)',
                            }}
                        >
                            SIGMET
                        </Text>
                    </div>

                    {params.sigmet &&
                        briefingData &&
                        briefingData.res.areamet &&
                        briefingData.res.areamet.sigmet && ( /// SIGMETs
                            <div
                                style={[
                                    styles.sectionContent,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #e5e5e5',
                                    },
                                ]}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: '10',
                                        width: '80%',
                                        marginBottom: '-1',
                                    }}
                                >
                                    {briefingData.res.areamet.sigmet.length >
                                        0 &&
                                        briefingData.res.areamet.sigmet.map(
                                            (sigmet, i) => {
                                                return (
                                                    <div
                                                        key={`SigmetBriefing${sigmet.id}${sigmet.issued}${i}`}
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',

                                                                borderBottom:
                                                                    i + 1 !==
                                                                        sigmet.length &&
                                                                    '1px solid #e5e5e5',
                                                            },
                                                        ]}
                                                    >
                                                        <div
                                                            style={[
                                                                styles.boxCell,
                                                                {
                                                                    borderRight:
                                                                        '1px solid #e5e5e5',
                                                                    flexGrow:
                                                                        '1',
                                                                    width: '20%',
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={{
                                                                    fontWeight:
                                                                        '600',
                                                                    marginTop:
                                                                        '2',
                                                                }}
                                                            >
                                                                {sigmet.id}
                                                            </Text>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                flexGrow: '10',
                                                                width: '80%',
                                                            }}
                                                        >
                                                            <div
                                                                style={[
                                                                    styles.boxCell,
                                                                    {
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'column',
                                                                        paddingLeft:
                                                                            '10px',
                                                                        paddingRight:
                                                                            '10px',
                                                                        marginTop:
                                                                            '2',
                                                                    },
                                                                ]}
                                                            >
                                                                {(showOutdatedErrors ||
                                                                    sigmet.outofdate) && (
                                                                    <div
                                                                        wrap={
                                                                            false
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                            width: '100%',
                                                                            marginBottom:
                                                                                '4',
                                                                        }}
                                                                    >
                                                                        <div // NOTAM ICON
                                                                            style={[
                                                                                styles.sectionContent,
                                                                                {
                                                                                    width: '5%',
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'row',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <ErrorIcon
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 16 16"
                                                                                fill="rgb(16, 170, 112)"
                                                                            />
                                                                        </div>
                                                                        <div // NOTAM CONTENT
                                                                            style={[
                                                                                styles.sectionContent,
                                                                                {
                                                                                    width: '95%',
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'column',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Text
                                                                                style={
                                                                                    styles.error
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    There
                                                                                    has
                                                                                    been
                                                                                    an
                                                                                    issue
                                                                                    with
                                                                                    the
                                                                                    connection
                                                                                    between
                                                                                    PreFlight
                                                                                    and
                                                                                    MetService.
                                                                                    This{' '}
                                                                                </span>
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            '500',
                                                                                    }}
                                                                                >
                                                                                    SIGMET
                                                                                </span>
                                                                                <span>
                                                                                    {' '}
                                                                                    was
                                                                                    last
                                                                                    validated
                                                                                    with
                                                                                    MetService
                                                                                    more
                                                                                    than{' '}
                                                                                </span>
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            '500',
                                                                                    }}
                                                                                >
                                                                                    {formatDistanceToNow(
                                                                                        parseISO(
                                                                                            sigmet.updated
                                                                                        ),
                                                                                        {
                                                                                            addSuffix: true,
                                                                                        }
                                                                                    )}
                                                                                </span>
                                                                                <span>
                                                                                    .
                                                                                    There
                                                                                    is
                                                                                    a
                                                                                    high
                                                                                    likelihood
                                                                                    that
                                                                                    the
                                                                                    latest
                                                                                    SIGMET
                                                                                    information
                                                                                    is
                                                                                    not
                                                                                    available
                                                                                    in
                                                                                    PreFlight.
                                                                                    Check
                                                                                    back
                                                                                    again
                                                                                    soon,
                                                                                    or
                                                                                    alternatively
                                                                                    visit{' '}
                                                                                </span>
                                                                                <a
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            '400',
                                                                                        textDecoration:
                                                                                            'underline',
                                                                                    }}
                                                                                    href="https://ifis.airways.co.nz/"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    IFIS
                                                                                </a>

                                                                                .
                                                                            </Text>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            styles.labelNormal,
                                                                            {
                                                                                width: '50',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        Issued:
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            styles.textNormal
                                                                        }
                                                                    >
                                                                        {sigmet?.issued &&
                                                                            formatDateTime(
                                                                                sigmet.issued,
                                                                                timeZone
                                                                            )}
                                                                    </Text>
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            styles.labelNormal,
                                                                            {
                                                                                width: '50',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        Valid
                                                                        from:
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            styles.textNormal
                                                                        }
                                                                    >
                                                                        {sigmet?.validfrom &&
                                                                            formatDateTime(
                                                                                sigmet.validfrom,
                                                                                timeZone
                                                                            )}
                                                                    </Text>
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            styles.labelNormal,
                                                                            {
                                                                                width: '50',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        Valid
                                                                        to:
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            styles.textNormal
                                                                        }
                                                                    >
                                                                        {sigmet?.validto &&
                                                                            formatDateTime(
                                                                                sigmet.validto,
                                                                                timeZone
                                                                            )}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={
                                                                    styles.boxCell
                                                                }
                                                            >
                                                                <Text
                                                                    style={[
                                                                        styles.textNormal,
                                                                        {
                                                                            marginTop:
                                                                                '-5',
                                                                            marginBottom:
                                                                                '5',
                                                                        },
                                                                    ]}
                                                                >
                                                                    {
                                                                        sigmet.data
                                                                    }
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                </div>
                            </div>
                        )}
                    {params.sigmet &&
                        !briefingData.res.areamet.sigmet && ( /// NO SIGMETS
                            <div
                                style={[
                                    styles.sectionContent,
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                    },
                                ]}
                            >
                                <div
                                    style={[
                                        styles.boxCell,
                                        {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexGrow: '10',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '300',
                                            opacity: '0.6',
                                        }}
                                    >
                                        No SIGMET information is available
                                    </Text>
                                </div>
                            </div>
                        )}
                </div>
            </View>
        )
    }
    return null
}
