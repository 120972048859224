import { config } from '../config'

export default function constructLayerVisibility(tier, mapDisabled) {
    const { collections, layers } = config
    let layerVisibility = {}

    collections.map((i) => {
        const opts = {}
        Object.keys(layers).map((l) =>
            layers[l]['collection'].map(
                (c) =>
                    c.id === i &&
                    (opts[l] =
                        c.displayOnDefault && c.tier >= 2 && c.tier > tier
                            ? tier === 1 && false
                            : c.displayOnDefault &&
                              c.tier <= tier &&
                              !mapDisabled
                            ? c.displayOnDefault
                            : ((tier === 1 || mapDisabled) &&
                                  c.displayOnDefaultFree) ||
                              false)
            )
        )
        layerVisibility[i] = opts
        return null
    })

    return layerVisibility
}
