import React from 'react'

import {
    Flex,
    Checkbox,
    Button,
    Box,
    Text,
    useBreakpointValue,
    Tooltip,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import PremiumBadge from '../../../../controls/PremiumBadge'

import '../../briefing.css'

export default function MenuItems(props) {
    const {
        dataLoading,
        altAerodromeLayout,
        filtersShow,
        mobileDrawerExpanded,
        minimiseDesktopMenu,
        params,
        menuState,
        setParams,
        isPremium,
        setIsScrolling,
        setButtonSelected,
        setMenuState,
        showManageFavourites,
        disableAerodromes,
    } = props

    function setChildren(parent, value) {
        const { id } = menuState[parent]
        setParams((prevParams) => {
            const children = Object.keys(menuState[parent].children).map(
                (i) => menuState[parent]['children'][i]['id']
            )
            let newParams = {}
            children.forEach((c) => (newParams[c] = value))
            return {
                ...prevParams,
                ...newParams,
                [id]: value,
            }
        })
    }

    const checkboxSize = useBreakpointValue({
        base: { size: 'xl' },
        md: { size: 'md' },
    })
    const checkboxVariant = useBreakpointValue({
        base: { variant: 'mobile' },
        md: { size: 'desktop' },
    })
    const selectedButtonColor = useBreakpointValue({
        base: 'light.100',
        md: 'light.200',
    })
    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <>
            <Flex
                borderBottom="none"
                flexDirection={'column'}
                zIndex="1"
                transition="height ease 300ms"
                pb="20px"
                overflow="hidden"
            >
                {Object.keys(menuState).map((parent, i) => (
                    <Box
                        key={`MenuParent${parent}`}
                        borderStyle="none"
                        index="2"
                        transition="height ease 300ms"
                    >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            color="light.100"
                            _hover={{
                                color:
                                    dataLoading ||
                                    !params[menuState[parent].id] ||
                                    (isPremium &&
                                        menuState[parent].premiumOnly) ||
                                    (disableAerodromes &&
                                        menuState[parent].id === 'aerodromes')
                                        ? 'light.100'
                                        : selectedButtonColor,
                            }}
                            opacity={
                                altAerodromeLayout &&
                                minimiseDesktopMenu &&
                                menuState[parent].id === 'aerodromes'
                                    ? '0'
                                    : '1'
                            }
                            height={
                                altAerodromeLayout &&
                                minimiseDesktopMenu &&
                                menuState[parent].id === 'aerodromes'
                                    ? '0px'
                                    : '40px'
                            }
                            transition="all ease 300ms"
                        >
                            <Tooltip
                                isDisabled={{
                                    base: true,
                                    md:
                                        filtersShow ||
                                        params[menuState[parent].id],
                                }}
                                label="This item is filtered"
                                openDelay={200}
                                m="5px"
                                bgColor={'gray.500'}
                                color="light.10"
                                hasArrow
                                px="10px"
                                height="26px"
                                fontSize="0.7rem"
                                alignItems="center"
                                display="flex"
                                placement="right"
                                borderRadius="7px"
                                marginLeft="-40px"
                            >
                                <Button
                                    disabled={
                                        (!mobileDrawerExpanded &&
                                            !showManageFavourites &&
                                            (dataLoading ||
                                                !params[menuState[parent].id] ||
                                                (isPremium &&
                                                    menuState[parent]
                                                        .premiumOnly))) ||
                                        (disableAerodromes &&
                                            menuState[parent].id ===
                                                'aerodromes')
                                    }
                                    {...noFocus}
                                    opacity={
                                        !params[menuState[parent].id] ||
                                        (isPremium &&
                                            menuState[parent].premiumOnly) ||
                                        (disableAerodromes &&
                                            menuState[parent].id ===
                                                'aerodromes')
                                            ? 0.4
                                            : 1
                                    }
                                    bg="transparent"
                                    borderRadius="0px"
                                    pl="18px"
                                    pr="0px"
                                    py="0"
                                    color="light.100"
                                    height={{
                                        base: mobileDrawerExpanded
                                            ? '40px'
                                            : showManageFavourites
                                            ? '36px'
                                            : '0',
                                        md: showManageFavourites
                                            ? '36px'
                                            : '40px',
                                    }}
                                    width={{
                                        base: '100%',
                                        md: '250px',
                                    }}
                                    minWidth={{
                                        base: '100%',
                                        md: '250px',
                                    }}
                                    // pointerEvents={{
                                    //     base: 'none',
                                    //     md: 'auto',
                                    // }}
                                    marginRight="auto"
                                    transition="all ease 300ms"
                                    display="flex"
                                    flexDirection="row"
                                    variant="unstyled"
                                    fontSize="10px"
                                    fontWeight="700"
                                    justifyContent="initial"
                                    mx="0px"
                                    border="none"
                                    _hover={{
                                        boxShadow: 'none',
                                        background: 'none',
                                        color:
                                            dataLoading ||
                                            !params[menuState[parent].id] ||
                                            (isPremium &&
                                                menuState[parent]
                                                    .premiumOnly) ||
                                            (disableAerodromes &&
                                                menuState[parent].id ===
                                                    'aerodromes')
                                                ? 'light.100'
                                                : selectedButtonColor,
                                    }}
                                    _active={{
                                        boxShadow: 'none',
                                        background: 'none',
                                    }}
                                    _focus={{
                                        boxShadow: 'none',
                                        background: 'none',
                                    }}
                                    onClick={() => {
                                        if (
                                            showManageFavourites ||
                                            mobileDrawerExpanded
                                        ) {
                                            if (
                                                // if indeterminate
                                                menuState[parent].children &&
                                                Object.keys(
                                                    menuState[parent].children
                                                )
                                                    .map(
                                                        (child) =>
                                                            params[
                                                                menuState[
                                                                    parent
                                                                ].children[
                                                                    child
                                                                ]['id']
                                                            ]
                                                    )
                                                    .includes(false)
                                            ) {
                                                setChildren(parent, true)
                                            } else if (
                                                menuState[parent].children
                                            ) {
                                                setChildren(parent, false)
                                            } else if (
                                                !menuState[parent].children
                                            ) {
                                                setParams((prevParams) => {
                                                    return {
                                                        ...prevParams,
                                                        [menuState[parent][
                                                            'id'
                                                        ]]:
                                                            !prevParams[
                                                                menuState[
                                                                    parent
                                                                ]['id']
                                                            ],
                                                    }
                                                })
                                            }
                                        } else {
                                            !dataLoading &&
                                                document
                                                    .getElementById(
                                                        menuState[parent]
                                                            .containerId
                                                    )
                                                    .scrollIntoView({
                                                        behavior: 'smooth',
                                                    })
                                            setIsScrolling(true)
                                            setButtonSelected(
                                                menuState[parent].containerId
                                            )
                                        }
                                    }}
                                >
                                    {/* {menuState[parent].id === 'aerodromes' &&
                                    menuState[parent].childrenExpanded &&
                                    minimiseDesktopMenu ? (
                                        <ChevronRightIcon
                                            ml="2px"
                                            w="20px"
                                            h="20px"
                                            minW="20px"
                                            display={{
                                                base: 'none',
                                                md: 'initial',
                                            }}
                                            transform="rotate(90deg)"
                                            fontSize="1.6rem"
                                            bgColor="transparent"
                                            {...noFocus}
                                        />
                                    ) : ( */}
                                    {menuState[parent].icon}
                                    <Box
                                        opacity={{
                                            base: '1',
                                            md: minimiseDesktopMenu ? '0' : '1',
                                        }}
                                        visibility={{
                                            base: 'visible',
                                            md: minimiseDesktopMenu
                                                ? 'hidden'
                                                : 'visible',
                                        }}
                                        alignSelf="center"
                                        fontSize={{
                                            base: '0.9rem',
                                            md: '0.8rem',
                                        }}
                                        transition="opacity ease-in-out 400ms"
                                        fontWeight="400"
                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        flex="1"
                                        textAlign="left"
                                        paddingLeft="2"
                                    >
                                        {menuState[parent].label}
                                    </Box>
                                </Button>
                            </Tooltip>
                            {((!filtersShow && menuState[parent].children) ||
                                filtersShow ||
                                mobileDrawerExpanded) &&
                                menuState[parent].children &&
                                !disableAerodromes && (
                                    <Flex
                                        opacity={altAerodromeLayout ? '1' : '0'}
                                        visibility={
                                            altAerodromeLayout
                                                ? 'visible'
                                                : 'hidden'
                                        }
                                        transition="opacity ease 300ms"
                                        zIndex="1"
                                        cursor="pointer"
                                        height={
                                            showManageFavourites
                                                ? '36px'
                                                : '40px'
                                        }
                                        width="50px"
                                        justifyContent="center"
                                        alignItems="center"
                                        ml="-60px"
                                        mr="-12px"
                                        paddingRight="8px"
                                        onClick={() => {
                                            !filtersShow &&
                                                !dataLoading &&
                                                menuState[parent].children &&
                                                setMenuState((prevState) => ({
                                                    ...prevState,
                                                    ...{
                                                        [parent]: {
                                                            ...menuState[
                                                                parent
                                                            ],
                                                            childrenExpanded:
                                                                !prevState[
                                                                    parent
                                                                ]
                                                                    .childrenExpanded,
                                                        },
                                                    },
                                                }))
                                        }}
                                    >
                                        <ChevronRightIcon
                                            w="20px"
                                            h="20px"
                                            minW="20px"
                                            opacity={filtersShow ? 0 : 1}
                                            display={{
                                                base: 'none',
                                                md: 'initial',
                                            }}
                                            transform={
                                                menuState[parent]
                                                    .childrenExpanded &&
                                                'rotate(90deg)'
                                            }
                                            style={{
                                                transition: 'all 0.3s',
                                            }}
                                            mr={
                                                menuState[parent]
                                                    .childrenExpanded
                                                    ? '0px'
                                                    : '0px'
                                            }
                                            fontSize="1.6rem"
                                            bgColor="transparent"
                                            _hover={{
                                                color: 'light.200',
                                            }}
                                            _active={{
                                                color: 'light.200',
                                            }}
                                            {...noFocus}
                                        />
                                    </Flex>
                                )}

                            <Flex
                                width={{
                                    base: '100%',
                                    md:
                                        filtersShow || mobileDrawerExpanded
                                            ? '100%'
                                            : '20px',
                                }}
                                maxWidth={{
                                    base: '44px',
                                    md:
                                        filtersShow || mobileDrawerExpanded
                                            ? '44px'
                                            : '20px',
                                }}
                                marginLeft={{
                                    base: '-44px',
                                    md:
                                        filtersShow || mobileDrawerExpanded
                                            ? '-44px'
                                            : '-20px',
                                }}
                                visibility={
                                    filtersShow || mobileDrawerExpanded
                                        ? 'visible'
                                        : 'hidden'
                                }
                                opacity={
                                    filtersShow || mobileDrawerExpanded
                                        ? '1'
                                        : '0'
                                }
                                transition="all ease 300ms"
                            >
                                <Checkbox // parent checkbox
                                    {...checkboxSize}
                                    {...checkboxVariant}
                                    className="briefingmenu-checkbox"
                                    marginRight={{
                                        base: '10px',
                                        md: '20px',
                                    }}
                                    marginLeft="auto"
                                    transition="all ease 300ms"
                                    colorScheme="teal"
                                    disabled={
                                        (isPremium &&
                                            menuState[parent].premiumOnly) ||
                                        (disableAerodromes &&
                                            menuState[parent].id ===
                                                'aerodromes')
                                    }
                                    isChecked={
                                        params[parent] &&
                                        !(
                                            menuState[parent].children &&
                                            Object.keys(
                                                menuState[parent].children
                                            )
                                                .map(
                                                    (i) =>
                                                        params[
                                                            menuState[parent]
                                                                .children[i][
                                                                'id'
                                                            ]
                                                        ]
                                                )
                                                .every((i) => !i)
                                        )
                                    }
                                    isIndeterminate={
                                        // some children are unchecked
                                        // Create function to return if children are unchecked
                                        menuState[parent].children &&
                                        Object.keys(menuState[parent].children)
                                            .map(
                                                (child) =>
                                                    params[
                                                        menuState[parent][
                                                            'children'
                                                        ][child]['id']
                                                    ]
                                            )
                                            .includes(false) &&
                                        // but not all children are unchecked
                                        !Object.keys(menuState[parent].children)
                                            .map(
                                                (child) =>
                                                    params[
                                                        menuState[parent][
                                                            'children'
                                                        ][child]['id']
                                                    ]
                                            )
                                            .every((v) => v === false)
                                    }
                                    onChange={() => {
                                        if (
                                            // if indeterminate
                                            menuState[parent].children &&
                                            Object.keys(
                                                menuState[parent].children
                                            )
                                                .map(
                                                    (child) =>
                                                        params[
                                                            menuState[parent]
                                                                .children[
                                                                child
                                                            ]['id']
                                                        ]
                                                )
                                                .includes(false)
                                        ) {
                                            setChildren(parent, true)
                                        } else if (menuState[parent].children) {
                                            setChildren(parent, false)
                                        } else if (
                                            !menuState[parent].children
                                        ) {
                                            setParams((prevParams) => {
                                                return {
                                                    ...prevParams,
                                                    [menuState[parent]['id']]:
                                                        !prevParams[
                                                            menuState[parent][
                                                                'id'
                                                            ]
                                                        ],
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Flex>

                            {isPremium &&
                                menuState[parent].premiumOnly && ( // PREMIUM BADGE
                                    <Flex
                                        cursor="not-allowed"
                                        position="absolute"
                                        minWidth={{
                                            base: '40px',
                                            md: '50px',
                                        }}
                                        pr="6px"
                                        left={{
                                            base: '193px',
                                            md: 'unset',
                                        }}
                                        mt={{
                                            base: '-1px',
                                            md: minimiseDesktopMenu
                                                ? '-15px'
                                                : 'unset',
                                        }}
                                        right={{
                                            base: 'unset',
                                            md: minimiseDesktopMenu
                                                ? '-12px'
                                                : filtersShow
                                                ? '25px'
                                                : '-1px',
                                        }}
                                        transition="all ease 300ms"
                                        justifyContent="center"
                                    >
                                        <PremiumBadge />
                                    </Flex>
                                )}
                        </Flex>
                        {menuState[parent].children && !disableAerodromes && (
                            <Box // DROPDOWN DRAWER
                                width="100%"
                                overflow="hidden"
                                padding={
                                    menuState[parent].childrenExpanded
                                        ? '15px'
                                        : '0'
                                }
                                height={
                                    showManageFavourites
                                        ? '145px'
                                        : menuState[parent].childrenExpanded
                                        ? i ===
                                          Object.keys(menuState).length - 1
                                            ? 'auto'
                                            : '160px'
                                        : mobileDrawerExpanded
                                        ? '160px'
                                        : '0px'
                                }
                                opacity={
                                    menuState[parent].childrenExpanded
                                        ? '1'
                                        : mobileDrawerExpanded
                                        ? '1'
                                        : '0'
                                }
                                visibility={
                                    menuState[parent].childrenExpanded
                                        ? 'visible'
                                        : mobileDrawerExpanded
                                        ? 'visible'
                                        : 'hidden'
                                }
                                transition="all ease 300ms"
                                pt={0}
                                pb={0}
                                px={0}
                            >
                                {Object.keys(menuState[parent].children).map(
                                    (child) => (
                                        <Flex
                                            color="light.100"
                                            _hover={{
                                                color: !params[
                                                    menuState[parent].children[
                                                        child
                                                    ].id
                                                ]
                                                    ? 'light.100'
                                                    : selectedButtonColor,
                                            }}
                                            key={`MenuParent${parent}Child${child}`}
                                        >
                                            <Tooltip
                                                isDisabled={{
                                                    base: true,
                                                    md:
                                                        filtersShow ||
                                                        params[
                                                            menuState[parent]
                                                                .children[child]
                                                                .id
                                                        ],
                                                }}
                                                label="This item is filtered"
                                                openDelay={200}
                                                m="5px"
                                                bgColor={'gray.500'}
                                                color="light.10"
                                                hasArrow
                                                px="10px"
                                                height="26px"
                                                fontSize="0.7rem"
                                                alignItems="center"
                                                display="flex"
                                                placement="right"
                                                borderRadius="7px"
                                                marginLeft="-40px"
                                            >
                                                <Button
                                                    disabled={dataLoading}
                                                    isDisabled={
                                                        !params[
                                                            menuState[parent]
                                                                .children[child]
                                                                .id
                                                        ]
                                                    }
                                                    pl={
                                                        minimiseDesktopMenu
                                                            ? '19px'
                                                            : '12'
                                                    }
                                                    pr="0px"
                                                    background="none"
                                                    display="flex"
                                                    flexDirection="row"
                                                    opacity={
                                                        !params[
                                                            menuState[parent]
                                                                .children[child]
                                                                .id
                                                        ]
                                                            ? 0.4
                                                            : 1
                                                    }
                                                    alignItems="center"
                                                    variant="unstyled"
                                                    fontSize="10px"
                                                    fontWeight="600"
                                                    height={{
                                                        base: mobileDrawerExpanded
                                                            ? '40px'
                                                            : showManageFavourites
                                                            ? '36px'
                                                            : '0',
                                                        md: showManageFavourites
                                                            ? '36px'
                                                            : '40px',
                                                    }}
                                                    transition="all ease 300ms"
                                                    width="100%"
                                                    minWidth={{
                                                        base: '100%',
                                                        md: '250px',
                                                    }}
                                                    justifyContent="initial"
                                                    mx="0px"
                                                    border="none"
                                                    borderRadius="0px"
                                                    _hover={{
                                                        boxShadow: 'none',
                                                        background: 'none',
                                                        color: !params[
                                                            menuState[parent]
                                                                .children[child]
                                                                .id
                                                        ]
                                                            ? 'light.100'
                                                            : selectedButtonColor,
                                                    }}
                                                    _active={{
                                                        boxShadow: 'none',
                                                        background: 'none',
                                                    }}
                                                    _focus={{
                                                        boxShadow: 'none',
                                                        background: 'none',
                                                    }}
                                                    pointerEvents={{
                                                        base: 'none',
                                                        md: 'auto',
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            params[
                                                                menuState[
                                                                    parent
                                                                ].children[
                                                                    child
                                                                ].id
                                                            ]
                                                        ) {
                                                            document
                                                                .getElementById(
                                                                    menuState[
                                                                        parent
                                                                    ].children[
                                                                        child
                                                                    ]
                                                                        .containerId
                                                                )
                                                                .scrollIntoView(
                                                                    {
                                                                        behavior:
                                                                            'smooth',
                                                                    }
                                                                )
                                                            setButtonSelected(
                                                                menuState[
                                                                    parent
                                                                ].children[
                                                                    child
                                                                ].containerId
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <Box
                                                        ml="0px"
                                                        mr="8px"
                                                        marginTop="-1px"
                                                        transform={
                                                            menuState[parent]
                                                                .children[child]
                                                                .id ===
                                                            'aerodromesNotam'
                                                                ? 'scale(0.9)'
                                                                : 'scale(0.85)'
                                                        }
                                                    >
                                                        {
                                                            menuState[parent]
                                                                .children[child]
                                                                .icon
                                                        }
                                                    </Box>
                                                    <Text
                                                        cursor={
                                                            filtersShow ||
                                                            params[
                                                                menuState[
                                                                    parent
                                                                ].children[
                                                                    child
                                                                ].id
                                                            ]
                                                                ? 'pointer'
                                                                : 'not-allowed'
                                                        }
                                                        opacity={{
                                                            base: '1',
                                                            md: minimiseDesktopMenu
                                                                ? '0'
                                                                : '1',
                                                        }}
                                                        visibility={{
                                                            base: 'visible',
                                                            md: minimiseDesktopMenu
                                                                ? 'hidden'
                                                                : 'visible',
                                                        }}
                                                        transition="opacity ease-in-out 400ms"
                                                        alignSelf="center"
                                                        textAlign="left"
                                                        fontSize={{
                                                            base: '0.85rem',
                                                            md: '0.8rem',
                                                        }}
                                                        fontWeight="400"
                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                        width="100%"
                                                        marginTop={{
                                                            base: '-1px',
                                                            md: '0px',
                                                        }}
                                                    >
                                                        {
                                                            menuState[parent]
                                                                .children[child]
                                                                .label
                                                        }
                                                    </Text>
                                                </Button>
                                            </Tooltip>
                                            <Flex
                                                width={{
                                                    base: '100%',
                                                    md:
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? '100%'
                                                            : '20px',
                                                }}
                                                maxWidth={{
                                                    base: '44px',
                                                    md:
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? '44px'
                                                            : '20px',
                                                }}
                                                marginLeft={{
                                                    base: '-44px',
                                                    md:
                                                        filtersShow ||
                                                        mobileDrawerExpanded
                                                            ? '-44px'
                                                            : '-20px',
                                                }}
                                                visibility={
                                                    (filtersShow &&
                                                        menuState[parent]
                                                            .childrenExpanded) ||
                                                    mobileDrawerExpanded
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                                opacity={
                                                    (filtersShow &&
                                                        menuState[parent]
                                                            .childrenExpanded) ||
                                                    mobileDrawerExpanded
                                                        ? '1'
                                                        : '0'
                                                }
                                                transition="all ease 300ms"
                                            >
                                                <Checkbox // Child Checkbox
                                                    {...checkboxSize}
                                                    {...checkboxVariant}
                                                    className="briefingmenu-checkbox"
                                                    transition="all ease 300ms"
                                                    marginRight={{
                                                        base: '10px',
                                                        md: '20px',
                                                    }}
                                                    marginLeft="auto"
                                                    colorScheme="teal"
                                                    isChecked={
                                                        params[
                                                            menuState[parent][
                                                                'children'
                                                            ][child]['id']
                                                        ]
                                                    }
                                                    onChange={() => {
                                                        setParams(
                                                            (prevParams) => {
                                                                const {
                                                                    id: childId,
                                                                } =
                                                                    menuState[
                                                                        parent
                                                                    ][
                                                                        'children'
                                                                    ][child]
                                                                return {
                                                                    ...prevParams,
                                                                    [parent]:
                                                                        !prevParams[
                                                                            childId
                                                                        ] &&
                                                                        !prevParams[
                                                                            parent
                                                                        ]
                                                                            ? true
                                                                            : prevParams[
                                                                                  parent
                                                                              ],
                                                                    [childId]:
                                                                        !prevParams[
                                                                            childId
                                                                        ],
                                                                }
                                                            }
                                                        )
                                                    }}
                                                />
                                            </Flex>
                                        </Flex>
                                    )
                                )}
                            </Box>
                        )}
                    </Box>
                ))}
            </Flex>
        </>
    )
}
