import sigmetToastDefinition from './sigmet'
import sigwxToastDefinition from './sigwx'
import rpasToastDefinition from './rpas'
import notamToastDefinition from './notam'

export default function toastDefinitions(feat, now) {
    return feat.source === 'sigmet'
        ? sigmetToastDefinition(feat)
        : feat.source === 'sigwx'
        ? sigwxToastDefinition(feat, now)
        : feat.source === 'notam'
        ? notamToastDefinition(feat, now)
        : feat.source === 'rpas'
        ? rpasToastDefinition(feat, now)
        : null
}
