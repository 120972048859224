import React from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer } from 'react-mapbox-gl'

import { VectorLayerRefreshInfo } from '../../../globalState'

export default function HazardsLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0,
        8,
        0,
        9,
        1,
    ]

    return (
        <GeoJSONLayer
            id="hazards"
            before="aerodromes-symbol"
            data={layerData.hazards.dataURL}
            layerOptions={{
                minzoom: 8,
            }}
            symbolLayout={{
                'icon-image': [
                    'match',
                    ['get', 'type'],
                    'KITE FLYING',
                    'kite',
                    'MET BALLOON',
                    'metballoon-large',
                    'FLIGHT TRAINING',
                    'flighttraining',
                    'HANG GLIDING',
                    'hangglider',
                    'MODEL ACFT',
                    'modelacft-large',
                    'GLIDER ACTIVITY',
                    'glider',
                    'PARASAILING',
                    'parasailing',
                    'SENSITIVE FAUNA',
                    'sensitivefauna-large',
                    'WINCH',
                    [
                        'match',
                        ['get', 'note'],
                        'HANG GLIDING',
                        'hangglider',
                        'glider',
                    ],
                    'PLA',
                    'parachute',
                    '',
                ],
                'icon-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    8,
                    0.2,
                    10,
                    0.4,
                    16,
                    0.6,
                ],
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-anchor': 'center',
                'text-field': [
                    'match',
                    ['get', 'type'],
                    'WINCH',
                    ['concat', 'W ', ['get', 'elevation']],
                    'PARASAILING',
                    ['concat', 'PS\n', ['get', 'elevation']],
                    'PLA',
                    ['get', 'designator'],
                    ['get', 'elevation'],
                ],
                'text-font': [
                    'Roboto Medium',
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold',
                ],
                'text-anchor': [
                    'match',
                    ['get', 'type'],
                    'PLA',
                    'top',
                    'bottom',
                ],
                'text-offset': [
                    'match',
                    ['get', 'type'],
                    'WINCH',
                    ['literal', [0, 2]],
                    'PARASAILING',
                    ['literal', [0, 3.2]],
                    'PLA',
                    ['literal', [0, 1.5]],
                    ['literal', [0, 0]],
                ],
                'text-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    7,
                    5,
                    10,
                    10,
                    11,
                    10,
                ],
            }}
            symbolPaint={{
                'text-color': '#ff0000',
                'text-halo-color': '#fff',
                'text-halo-width': 1.8,
                'icon-opacity': zoomLevelDefinition,
                'text-opacity': zoomLevelDefinition,
            }}
        />
    )
}
