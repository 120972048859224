import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
import faqLottie from '../../landing-page/assets/faq.json'
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Flex,
    VStack,
    Text,
    Icon,
    Box,
    Link,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Portal,
    useBreakpointValue,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { ReactComponent as SupportLogo } from '../../icons/assets/preflight-support.svg'
import {
    setModalVisibilityState,
    modalVisibilityState,
} from '../../globalState'

export default function ContactUsModal(props) {
    const { className } = props
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const navigate = useNavigate()

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'contactus', value })
        },
        [setModalVisibility]
    )

    const setShowFaqs = useCallback(
        (value) => {
            setModalVisibility({ id: 'faqs', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()
    const landingPage = className === 'contactUsLandingPage'

    useEffect(() => {
        modalVisibility.contactus ? onOpen() : onClose()
    }, [modalVisibility.contactus, onOpen, onClose])

    function onCloseHandler() {
        document.body.classList.remove('contact-us')
        navigate('#')
        setShow(false)
    }

    useEffect(() => {
        // add class to body element to hide 'Contact Us' option in Userback modal - when Contact us modal already open
        if (isOpen) {
            document.body.classList.add('contact-us')
        }
    }, [isOpen])

    const largeScreen = window.innerHeight > 720

    useEffect(() => {
        if (isOpen && window.Userback) {
            window.Userback?.close()
        }
    }, [isOpen])

    //PORTAL REFERENCES
    const mobileInfo = useRef()
    const desktopInfo = useRef()

    const portalInfo = useBreakpointValue({
        base: { containerRef: mobileInfo },
        lg: { containerRef: largeScreen ? desktopInfo : mobileInfo },
    })
    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={landingPage ? 'contactUsLandingPage' : 'contactUs'}
                    size="6xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                    // scrollBehavior={'inside'}
                    motionPreset="slideInBottom"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            zIndex="5"
                            textAlign="center"
                            background="white"
                            color="light.200"
                            pb="5px"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            <Icon
                                my="5px"
                                mr="15px"
                                as={SupportLogo}
                                width="260px"
                                height="auto"
                            />
                        </ModalHeader>
                        <ModalBody style={{ padding: 0 }} fontSize="0.8rem">
                            <VStack
                                maxHeight={{
                                    base: 'calc(100vh - 220px)',
                                    md: '75vh',
                                }}
                                overflowY="auto"
                                overflowX="hidden"
                                pt="20px"
                                px={{
                                    base: '15px',
                                    md: '30px',
                                    lg: '40px',
                                }}
                                pb="40px"
                                width="100%"
                                spacing={4}
                                alignItems="baseline"
                            >
                                <Flex
                                    flexDirection={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                    className="faq-content"
                                    width="100%"
                                    fontFamily="'Poppins','Open Sans',sans-serif"
                                    fontSize="1rem"
                                >
                                    <Flex
                                        position={{
                                            base: 'relative',
                                            md: 'absolute',
                                        }}
                                        justifyContent={{
                                            base: 'flex-end',
                                            md: 'flex-start',
                                        }}
                                        opacity="0.04"
                                        color="dark.10"
                                        top={{
                                            base: '0px',
                                            md: '105px',
                                            lg: largeScreen ? '75px' : '85px',
                                            xl: largeScreen ? '70px' : '85px',
                                        }}
                                        right={{ base: '15px', md: 'unset' }}
                                        marginLeft={{
                                            base: 'auto',
                                            md: '-30px',
                                        }}
                                        width={{
                                            base: '150px',
                                            md: '250px',
                                            lg: '310px',
                                            xl: '360px',
                                        }}
                                        height={{ base: '30px', md: '370px' }}
                                    >
                                        <Lottie
                                            className="faq-icon"
                                            loop={true}
                                            autoplay={true}
                                            animationData={faqLottie}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                minWidth: '100%',
                                                minHeight: 'auto',
                                                position: 'absolute',
                                                top: '0px',
                                                pointerEvents: 'none',
                                                opacity: '1',
                                            }}
                                        />
                                    </Flex>
                                    <Flex
                                        width={{ base: '100%', md: '35%' }}
                                        px={{ base: '15px', md: '10px' }}
                                        pt={{
                                            base: '30px',
                                            md: '70px',
                                            lg: largeScreen ? '70px' : '90px',
                                            xl: largeScreen ? '100px' : '120px',
                                        }}
                                        flexDirection="column"
                                    >
                                        <Flex
                                            flexDirection="column"
                                            width={{
                                                base: '100%',
                                                md: 'calc(35% - 50px)',
                                            }}
                                            position={{
                                                base: 'relative',
                                                md: 'absolute',
                                            }}
                                        >
                                            <Text
                                                className="modeLabel"
                                                fontSize={{
                                                    base: '1.5rem',
                                                    md: '1.9rem',
                                                }}
                                                fontWeight="600"
                                                px={{ base: '0px', md: '10px' }}
                                                lineHeight="1.2"
                                            >
                                                Get in touch
                                            </Text>

                                            <Text
                                                pt="7px"
                                                color="gray.500"
                                                px={{ base: '1px', md: '11px' }}
                                                fontSize={{
                                                    base: '0.85rem',
                                                    md: '0.95rem',
                                                }}
                                                minHeight={{
                                                    base: '54px',
                                                    md: '54px',
                                                }}
                                            >
                                                Please select the applicable
                                                option
                                            </Text>

                                            <Portal {...portalInfo}>
                                                <Flex
                                                    mt={{
                                                        base: '0px',
                                                        md: '20px',
                                                        lg: largeScreen
                                                            ? '40px'
                                                            : '0px',
                                                        xl: largeScreen
                                                            ? '80px'
                                                            : '0px',
                                                    }}
                                                    flexDirection="column"
                                                    py="15px"
                                                    px="15px"
                                                    mb="10px"
                                                    width={{
                                                        base: '100%',
                                                        lg: largeScreen
                                                            ? 'calc(100% - 20px)'
                                                            : '100%',
                                                    }}
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    fontSize={{
                                                        base: '0.75rem',
                                                        md: '0.8rem',
                                                    }}
                                                    border="1px solid"
                                                    borderColor="gray.200"
                                                    background="light.40"
                                                    gap="15px"
                                                >
                                                    <Text
                                                        fontWeight="400"
                                                        textAlign="center"
                                                        color="gray.500"
                                                    >
                                                        Please be aware that
                                                        PreFlight Support
                                                        operates during{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '600',
                                                                color: '#4A5568',
                                                            }}
                                                        >
                                                            regular business
                                                            hours only
                                                        </span>
                                                        , from{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '600',
                                                                color: '#4A5568',
                                                            }}
                                                        >
                                                            Monday to Friday
                                                        </span>
                                                        . <br />
                                                        We strive to address
                                                        your messages promptly.
                                                    </Text>
                                                    <Text
                                                        fontWeight="400"
                                                        textAlign="center"
                                                        color="gray.500"
                                                    >
                                                        For urgent or critical
                                                        meteorological-related
                                                        issues that cannot wait
                                                        until business hours for
                                                        resolution, please
                                                        contact the <br />
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '600',
                                                                color: '#4A5568',
                                                            }}
                                                        >
                                                            MetService Service
                                                            Desk
                                                        </span>{' '}
                                                        at{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '600',
                                                                color: '#4A5568',
                                                            }}
                                                        >
                                                            +64 4 470 0775
                                                        </span>
                                                        .
                                                    </Text>
                                                </Flex>
                                            </Portal>

                                            <Box
                                                width="100%"
                                                ref={desktopInfo}
                                            ></Box>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        minHeight="450px"
                                        width={{ base: '100%', md: '65%' }}
                                        pt={{
                                            base: '20px',
                                            md: '0px',
                                            lg: largeScreen ? '0px' : '20px',
                                        }}
                                        zIndex="2"
                                        flexDirection="column"
                                        pl={{ base: '0px', md: '10px' }}
                                        pr={{ base: '0px', md: '0px' }}
                                        gap="20px"
                                    >
                                        <Box
                                            width="100%"
                                            ref={mobileInfo}
                                        ></Box>
                                        <Flex
                                            width="100%"
                                            flexDirection="column"
                                            pt={{
                                                base: '10px',
                                                md: '0px',
                                                lg: '0px',
                                            }}
                                            pb="10px"
                                            gap="10px"
                                            fontSize="0.85rem"
                                            fontWeight="400"
                                            color="gray.500"
                                            textAlign={{
                                                base: 'center',
                                                md: 'left',
                                            }}
                                        >
                                            <Text>
                                                For the most efficient way to
                                                reach us, we recommend using the{' '}
                                                <Link
                                                    onClick={() => {
                                                        window.Userback.open()
                                                        // setTimeout(() => {
                                                        //     onCloseHandler()
                                                        // }, 1500)
                                                    }}
                                                    color="light.200"
                                                    fontWeight="700"
                                                    textTransform="uppercase"
                                                >
                                                    Send us feedback
                                                </Link>{' '}
                                                tool within the PreFlight
                                                web-app. This ensures that your
                                                request is promptly received and
                                                addressed.
                                            </Text>
                                            <Text>
                                                Alternatively, you can reach out
                                                to the appropriate support
                                                channel using the contact
                                                details provided below:
                                            </Text>
                                        </Flex>

                                        <Accordion
                                            allowToggle
                                            width="100%"
                                            mt={{
                                                base: '10px',
                                                md: '0px',
                                            }}
                                            mb="5px"
                                        >
                                            <AccordionItem
                                                border="none"
                                                marginBottom={{
                                                    base: '15px',
                                                    md: '20px',
                                                }}
                                            >
                                                {({ isExpanded }) => (
                                                    <>
                                                        <h2>
                                                            <AccordionButton
                                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                fontWeight="500"
                                                                fontSize={{
                                                                    base: '0.85rem',
                                                                    md: '0.95rem',
                                                                }}
                                                                py={{
                                                                    base: '4',
                                                                    md: '5',
                                                                    xl: '6',
                                                                }}
                                                                border="1px solid"
                                                                borderColor="gray.200"
                                                                borderRadius="15px"
                                                                boxShadow="0px 10px 20px -15px rgba(20,40,60,0.2)"
                                                                _expanded={{
                                                                    bg: 'light.20',
                                                                    color: 'light.200',
                                                                    boxShadow:
                                                                        '0px 10px 20px -15px rgba(20,40,60,0.4)',
                                                                }}
                                                                _hover={{
                                                                    background:
                                                                        'light.30',
                                                                    boxShadow:
                                                                        '0px 10px 20px -15px rgba(20,40,60,0.4)',
                                                                }}
                                                            >
                                                                <Box
                                                                    flex="1"
                                                                    textAlign="left"
                                                                    pl="5px"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '700',
                                                                            color: isExpanded
                                                                                ? '#00D599'
                                                                                : '#A0AEC0',
                                                                        }}
                                                                    >
                                                                        FREE
                                                                    </span>{' '}
                                                                    or{' '}
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '700',
                                                                            color: isExpanded
                                                                                ? '#00D599'
                                                                                : '#007DC8',
                                                                        }}
                                                                    >
                                                                        PLUS
                                                                    </span>{' '}
                                                                    account
                                                                    enquiries{' '}
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '300',
                                                                            fontSize:
                                                                                '0.8rem',
                                                                            color: '#718096',
                                                                        }}
                                                                    >
                                                                        –
                                                                        individuals
                                                                        only
                                                                        (excludes
                                                                        commercial
                                                                        customers)
                                                                    </span>
                                                                </Box>
                                                                <AccordionIcon fontSize="1.3rem" />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel
                                                            pt="20px"
                                                            pb={6}
                                                            px="30px"
                                                            color="gray.500"
                                                            fontSize={{
                                                                base: '0.8rem',
                                                                md: '0.9rem',
                                                            }}
                                                        >
                                                            <Text pt="10px">
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    All
                                                                </span>{' '}
                                                                enquiries for
                                                                individuals with
                                                                a{' '}
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '700',
                                                                        color: '#A0AEC0',
                                                                    }}
                                                                >
                                                                    FREE
                                                                </span>{' '}
                                                                account, or a{' '}
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '700',
                                                                        color: '#007DC8',
                                                                    }}
                                                                >
                                                                    PLUS
                                                                </span>{' '}
                                                                subscription
                                                                (excludes
                                                                organisations),
                                                                are managed by
                                                                Aeropath's
                                                                PreFlight
                                                                Support team
                                                                through the{' '}
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    Airways
                                                                    Service Desk
                                                                </span>
                                                                , including:
                                                            </Text>
                                                            <Box
                                                                pt="10px"
                                                                marginLeft="25px"
                                                            >
                                                                <ul>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        General
                                                                        enquiries
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Individuals
                                                                        wanting
                                                                        to set
                                                                        up a
                                                                        PLUS
                                                                        subscription
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Updates
                                                                        to an
                                                                        existing
                                                                        PLUS
                                                                        subscription
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        All
                                                                        billing
                                                                        enquiries
                                                                        related
                                                                        to PLUS
                                                                        subscriptions
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Any
                                                                        technical
                                                                        issues
                                                                        (all
                                                                        users)
                                                                    </li>
                                                                </ul>
                                                            </Box>
                                                            <Text pt="20px">
                                                                To get in touch
                                                                with the
                                                                PreFlight
                                                                Support team:
                                                            </Text>
                                                            <Box
                                                                pt="10px"
                                                                marginLeft="25px"
                                                            >
                                                                <ul>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Submit a
                                                                        request
                                                                        using
                                                                        the{' '}
                                                                        <strong>
                                                                            Send
                                                                            us
                                                                            feedback
                                                                        </strong>{' '}
                                                                        tool
                                                                        within
                                                                        the
                                                                        PreFlight
                                                                        web-app;
                                                                        or
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Contact:{' '}
                                                                        <Link
                                                                            color="light.200"
                                                                            href="mailto:helpdesk@airwaysinternational.co.nz?subject=PreFlight%20Enquiry&cc=support@aeropath.aero"
                                                                        >
                                                                            helpdesk@airwaysinternational.co.nz
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </Box>
                                                        </AccordionPanel>
                                                    </>
                                                )}
                                            </AccordionItem>

                                            <AccordionItem
                                                border="none"
                                                marginBottom={{
                                                    base: '15px',
                                                    md: '20px',
                                                }}
                                            >
                                                {({ isExpanded }) => (
                                                    <>
                                                        <h2>
                                                            <AccordionButton
                                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                fontWeight="500"
                                                                fontSize={{
                                                                    base: '0.85rem',
                                                                    md: '0.95rem',
                                                                }}
                                                                py={{
                                                                    base: '4',
                                                                    md: '5',
                                                                    xl: '6',
                                                                }}
                                                                border="1px solid"
                                                                borderColor="gray.200"
                                                                borderRadius="15px"
                                                                boxShadow="0px 10px 20px -15px rgba(20,40,60,0.2)"
                                                                _expanded={{
                                                                    bg: 'light.20',
                                                                    color: 'light.200',
                                                                    boxShadow:
                                                                        '0px 10px 20px -15px rgba(20,40,60,0.4)',
                                                                }}
                                                                _hover={{
                                                                    background:
                                                                        'light.30',
                                                                    boxShadow:
                                                                        '0px 10px 20px -15px rgba(20,40,60,0.4)',
                                                                }}
                                                            >
                                                                <Box
                                                                    flex="1"
                                                                    textAlign="left"
                                                                    pl="5px"
                                                                >
                                                                    <Box
                                                                        flex="1"
                                                                        textAlign="left"
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    '700',
                                                                                color: isExpanded
                                                                                    ? '#00D599'
                                                                                    : '#8D38E3',
                                                                            }}
                                                                        >
                                                                            PRO
                                                                        </span>{' '}
                                                                        account
                                                                        enquiries{' '}
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    '300',
                                                                                fontSize:
                                                                                    '0.8rem',
                                                                                color: '#718096',
                                                                            }}
                                                                        >
                                                                            –
                                                                            organisations
                                                                            only
                                                                            (commercial
                                                                            customers)
                                                                        </span>
                                                                    </Box>
                                                                </Box>
                                                                <AccordionIcon fontSize="1.3rem" />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel
                                                            pt="20px"
                                                            pb={6}
                                                            px="30px"
                                                            color="gray.500"
                                                            fontSize={{
                                                                base: '0.8rem',
                                                                md: '0.9rem',
                                                            }}
                                                        >
                                                            <Text pt="10px">
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    All
                                                                </span>{' '}
                                                                enquiries for
                                                                organisations
                                                                with a{' '}
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '700',
                                                                        color: '#8D38E3',
                                                                    }}
                                                                >
                                                                    PRO
                                                                </span>{' '}
                                                                (commercial)
                                                                account are
                                                                managed by the{' '}
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '600',
                                                                    }}
                                                                >
                                                                    MetService
                                                                    Aviation
                                                                    Sales
                                                                </span>{' '}
                                                                team, including:
                                                            </Text>
                                                            <Box
                                                                pt="10px"
                                                                marginLeft="25px"
                                                            >
                                                                <ul>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        New
                                                                        enquiries
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Organisations
                                                                        wanting
                                                                        to set
                                                                        up a PRO
                                                                        account
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Updates
                                                                        to
                                                                        existing
                                                                        staff
                                                                        under an
                                                                        organisation's
                                                                        PRO
                                                                        account
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        All
                                                                        billing
                                                                        enquiries
                                                                        related
                                                                        to PRO
                                                                        subscriptions
                                                                    </li>
                                                                </ul>
                                                            </Box>
                                                            <Text pt="20px">
                                                                To get in touch
                                                                with the
                                                                MetService
                                                                Aviation Sales
                                                                team:
                                                            </Text>
                                                            <Box
                                                                pt="10px"
                                                                marginLeft="25px"
                                                            >
                                                                <ul>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Contact:{' '}
                                                                        <Link
                                                                            color="light.200"
                                                                            href="mailto:aviationsales@metservice.com?subject=PRO Account Enquiry for PreFlight"
                                                                        >
                                                                            aviationsales@metservice.com
                                                                        </Link>
                                                                        ; or
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Submit a
                                                                        request
                                                                        using
                                                                        the{' '}
                                                                        <strong>
                                                                            Send
                                                                            us
                                                                            feedback
                                                                        </strong>{' '}
                                                                        tool
                                                                        within
                                                                        the
                                                                        PreFlight
                                                                        web-app.
                                                                    </li>
                                                                </ul>
                                                            </Box>

                                                            <Flex
                                                                flexDirection="column"
                                                                mt="25px"
                                                                pt="10px"
                                                                pb="12px"
                                                                px="15px"
                                                                width="100%"
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                                fontSize={{
                                                                    base: '0.75rem',
                                                                    md: '0.8rem',
                                                                }}
                                                                border="1px solid"
                                                                borderColor="gray.200"
                                                                background="light.40"
                                                                gap="3px"
                                                            >
                                                                <Text
                                                                    fontStyle="italic"
                                                                    opacity="0.8"
                                                                    fontWeight="600"
                                                                >
                                                                    Please note:
                                                                </Text>
                                                                <Text
                                                                    fontStyle="italic"
                                                                    opacity="0.8"
                                                                >
                                                                    If you have
                                                                    a technical
                                                                    issue,
                                                                    please
                                                                    report this
                                                                    through the{' '}
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '600',
                                                                        }}
                                                                    >
                                                                        Send Us
                                                                        Feedback
                                                                    </span>{' '}
                                                                    tool within
                                                                    the
                                                                    PreFlight
                                                                    web-app.
                                                                </Text>
                                                            </Flex>
                                                        </AccordionPanel>
                                                    </>
                                                )}
                                            </AccordionItem>

                                            <AccordionItem
                                                border="none"
                                                marginBottom={{
                                                    base: '10px',
                                                    md: '0px',
                                                }}
                                            >
                                                {({ isExpanded }) => (
                                                    <>
                                                        <h2>
                                                            <AccordionButton
                                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                fontWeight="500"
                                                                fontSize={{
                                                                    base: '0.85rem',
                                                                    md: '0.95rem',
                                                                }}
                                                                py={{
                                                                    base: '4',
                                                                    md: '5',
                                                                    xl: '6',
                                                                }}
                                                                border="1px solid"
                                                                borderColor="gray.200"
                                                                borderRadius="15px"
                                                                boxShadow="0px 10px 20px -15px rgba(20,40,60,0.2)"
                                                                _expanded={{
                                                                    bg: 'light.20',
                                                                    color: 'light.200',
                                                                    boxShadow:
                                                                        '0px 10px 20px -15px rgba(20,40,60,0.4)',
                                                                }}
                                                                _hover={{
                                                                    background:
                                                                        'light.30',
                                                                    boxShadow:
                                                                        '0px 10px 20px -15px rgba(20,40,60,0.4)',
                                                                }}
                                                            >
                                                                <Box
                                                                    flex="1"
                                                                    textAlign="left"
                                                                    pl="5px"
                                                                >
                                                                    ...or,
                                                                    something
                                                                    else
                                                                </Box>
                                                                <AccordionIcon fontSize="1.3rem" />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel
                                                            pt="20px"
                                                            pb={6}
                                                            px="30px"
                                                            color="gray.500"
                                                            fontSize={{
                                                                base: '0.8rem',
                                                                md: '0.9rem',
                                                            }}
                                                        >
                                                            <Text pt="10px">
                                                                If you have any
                                                                other enquiries
                                                                not related to
                                                                the first two
                                                                options above,
                                                                or you have a
                                                                technical issue,
                                                                reach out to the
                                                                PreFlight
                                                                Support team:
                                                            </Text>
                                                            <Box
                                                                pt="10px"
                                                                marginLeft="25px"
                                                            >
                                                                <ul>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Submit a
                                                                        request
                                                                        using
                                                                        the{' '}
                                                                        <strong>
                                                                            Send
                                                                            us
                                                                            feedback
                                                                        </strong>{' '}
                                                                        tool
                                                                        within
                                                                        the
                                                                        PreFlight
                                                                        web-app;
                                                                        or
                                                                    </li>
                                                                    <li
                                                                        style={{
                                                                            paddingBottom:
                                                                                '5px',
                                                                            paddingLeft:
                                                                                '5px',
                                                                        }}
                                                                    >
                                                                        Contact:{' '}
                                                                        <Link
                                                                            color="light.200"
                                                                            href="mailto:helpdesk@airwaysinternational.co.nz?subject=PreFlight%20Enquiry&cc=support@aeropath.aero"
                                                                        >
                                                                            helpdesk@airwaysinternational.co.nz
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </Box>
                                                        </AccordionPanel>
                                                    </>
                                                )}
                                            </AccordionItem>
                                        </Accordion>
                                        <Flex
                                            width="100%"
                                            flexDirection="column"
                                            py={{
                                                base: '10px',
                                                md: largeScreen && '10px',
                                            }}
                                            gap="10px"
                                            fontSize="0.85rem"
                                            fontWeight="400"
                                            color="gray.500"
                                            textAlign={{
                                                base: 'center',
                                                md: 'left',
                                            }}
                                        >
                                            <Text>
                                                Many enquiries can also be
                                                answered through our{' '}
                                                <Link
                                                    onClick={() => {
                                                        if (landingPage) {
                                                            document
                                                                .getElementById(
                                                                    'faqsContainer'
                                                                )
                                                                .scrollIntoView(
                                                                    {
                                                                        behavior:
                                                                            'smooth',
                                                                    }
                                                                )
                                                        } else {
                                                            setShowFaqs(true)
                                                        }
                                                        onCloseHandler()
                                                    }}
                                                    color="light.200"
                                                    fontWeight="600"
                                                >
                                                    Frequently Asked Questions
                                                </Link>
                                                .
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </VStack>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    variant="primary"
                                    onClick={() => {
                                        onCloseHandler()
                                    }}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    Close
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
