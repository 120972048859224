import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function SpaceWeatherIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M12.1029 3L16.9543 7.86L15.1371 9.67714L13.32 7.86L12.1029 9.07714L14.1 11.0657L13.1057 12.0686L13.4914 12.4629C14.2886 12.0943 15.2657 12.2314 15.9171 12.8914L12.8914 15.9171C12.2314 15.2657 12.0943 14.2886 12.4629 13.4914L12.0686 13.1057L11.0657 14.1L9.07714 12.1029L7.86 13.32L9.67714 15.1371L7.86 16.9543L3 12.1029L4.83429 10.2857L6.65143 12.1029L7.86 10.8943L5.43429 8.46857C4.76571 7.8 4.76571 6.71143 5.43429 6.04286L6.04286 5.43429C6.71143 4.76571 7.8 4.76571 8.46857 5.43429L10.8943 7.86L12.1029 6.65143L10.2857 4.83429L12.1029 3ZM17.5714 14.1429C17.5714 16.0371 16.0371 17.5714 14.1429 17.5714V15.8571C15.0857 15.8571 15.8571 15.0857 15.8571 14.1429H17.5714ZM21 14.1429C21 17.9314 17.9314 21 14.1429 21V19.2857C16.98 19.2857 19.2857 16.98 19.2857 14.1429H21Z"
                fill="currentColor"
            />
        </Icon>
    )
}
