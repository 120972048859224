import React, { useEffect } from 'react'
import * as d3 from 'd3'

export default function RunwayGenerator(props) {
    const {
        runwayGeoJson,
        height,
        width,
        paddingLeft,
        paddingTop,
        paddingRight,
        paddingBtm,
        svgContentsOnly,
    } = props

    const pl = paddingLeft ? paddingLeft : 10
    const pt = paddingTop ? paddingTop : 10
    const pr = paddingRight ? paddingRight : 10
    const pb = paddingBtm ? paddingBtm : 10

    useEffect(() => {
        // create a first guess for the projection
        let projection = d3.geoMercator().fitExtent(
            [
                [pl, pt], // buffer in px
                [width - pr, height - pb],
            ],
            runwayGeoJson
        )

        let geoGenerator = d3.geoPath().projection(projection)

        // Join the FeatureCollection's features array to path elements
        d3.select(
            svgContentsOnly
                ? `g.runway`
                : `#${runwayGeoJson.features[0].properties.aerodrome}-runway-layout g.runway`
        )
            .selectAll('path')
            .data(runwayGeoJson.features)
            .join('path')
            .attr('d', geoGenerator)
            .attr('fill', (feat) =>
                feat.properties.surface === 'GRASS' ? '#badd69' : '#828489'
            )
    }, [height, runwayGeoJson, width, pl, pt, pr, pb, svgContentsOnly])

    if (svgContentsOnly) {
        return <g className="runway" />
    }

    return (
        <svg
            id={`${runwayGeoJson.features[0].properties.aerodrome}-runway-layout`}
            style={{
                height: height,
                width: width,
            }}
        >
            <g className="runway" />
        </svg>
    )
}
