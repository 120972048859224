import { useRecoilValue } from 'recoil'

import {
    mapLoadingAerodromeIconsState,
    dataModeState,
} from '../../../globalState'

import FIRLayer from './FIR'
import AerodromeLayer from './Aerodromes'
import RunwaysLayer from './Runways'
import SearchedLocation from './SearchedFeature'

export default function ConstantLayers() {
    const mapLoadingAerodromeIcons = useRecoilValue(
        mapLoadingAerodromeIconsState
    )
    const dataMode = useRecoilValue(dataModeState)

    if (!mapLoadingAerodromeIcons) {
        return (
            <>
                {dataMode !== 'brief' && <FIRLayer />}
                <AerodromeLayer />
                <SearchedLocation />
                <RunwaysLayer />
            </>
        )
    }

    return <FIRLayer />
}
