import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function LayersLineIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 6}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M4.66869 11.3645C8.95786 13.2089 10.7375 13.9741 11.4759 14.2917C11.6045 14.347 11.7015 14.3887 11.7747 14.4201C11.9012 14.4745 12.0978 14.4745 12.2243 14.4201C12.2975 14.3887 12.3945 14.347 12.5231 14.2917C13.2615 13.9741 15.0411 13.2089 19.3303 11.3645C19.7105 11.2011 19.9854 11.1818 20.3776 11.3083C20.9017 11.4773 21.4257 11.6463 21.8186 11.773C22.0473 11.8467 22.0643 12.1591 21.8438 12.2539C20.0677 13.0176 14.9807 15.205 12.5231 16.2618C12.1346 16.4289 11.8644 16.4289 11.4759 16.2618C11.1482 16.1209 4.29366 13.1734 2.1571 12.2547C1.93655 12.1599 1.95176 11.8467 2.18046 11.773C2.57329 11.6463 3.09736 11.4773 3.62143 11.3083C4.01365 11.1818 4.28849 11.201 4.66869 11.3645Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.9993 8.3871C22.0068 8.2862 21.9561 8.18146 21.8438 8.13316L12.5231 4.12529C12.1346 3.95824 11.8644 3.95824 11.4759 4.12529L2.1571 8.13237C2.04462 8.18074 1.99346 8.28588 2.00067 8.3871C1.99346 8.48831 2.04462 8.59346 2.1571 8.64182L11.4759 12.6489C11.8644 12.816 12.1346 12.816 12.5231 12.6489L21.8438 8.64103C21.9561 8.59273 22.0068 8.48799 21.9993 8.3871ZM6.14651 8.3871L11.7747 10.8072C11.9012 10.8616 12.0978 10.8616 12.2243 10.8072L17.8525 8.3871L12.2243 5.96696C12.0978 5.91255 11.9012 5.91255 11.7747 5.96695L6.14651 8.3871Z"
                fill="currentColor"
            />
            <path
                d="M4.66869 14.9774C8.95786 16.8218 10.7375 17.587 11.4759 17.9046C11.6045 17.9599 11.7015 18.0016 11.7747 18.033C11.9012 18.0874 12.0978 18.0874 12.2243 18.033C12.2975 18.0016 12.3945 17.9599 12.5231 17.9046C13.2615 17.5871 15.0411 16.8218 19.3303 14.9775C19.7105 14.814 19.9854 14.7947 20.3776 14.9212C20.9017 15.0902 21.4257 15.2592 21.8186 15.3859C22.0473 15.4596 22.0643 15.772 21.8438 15.8668C20.0677 16.6305 14.9807 18.8179 12.5231 19.8747C12.1346 20.0418 11.8644 20.0418 11.4759 19.8747C11.1482 19.7338 4.29366 16.7864 2.1571 15.8676C1.93655 15.7728 1.95176 15.4596 2.18046 15.3859C2.57329 15.2592 3.09736 15.0902 3.62143 14.9212C4.01365 14.7947 4.28849 14.8139 4.66869 14.9774Z"
                fill="currentColor"
            />
        </Icon>
    )
}
