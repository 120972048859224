import React from 'react'
import { Text } from '@chakra-ui/react'

// VERSION NUMBER AND DATE

export default function VersionNumber() {
    // *** Update CURRENT VERSION NUMBER after any updates ***

    return <Text>1.5.14</Text>
}

export function VersionDate() {
    // *** Update to CURRENT VERSION DATE ***

    return <Text>12-12-2024</Text>
}
