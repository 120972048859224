import React, { useState, useEffect, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useTier, useAdminRole } from '../../../../auth/hooks'
import { isFirefox } from 'react-device-detect'
import {
    Box,
    Button,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Spinner,
    Text,
    Flex,
    Fade,
    Icon,
    useDisclosure,
    useColorModeValue,
    useBreakpointValue,
    CircularProgress,
    CircularProgressLabel,
    HStack,
} from '@chakra-ui/react'
import Image from '../../../components/Image'
import { useSwipeable } from 'react-swipeable'
import { useQuery } from 'react-query'
import { parseISO, formatDistanceToNow } from 'date-fns'
import PinchDrag, { useScale } from '../../components/PinchDrag'
import ZoomSlider from '../../components/ZoomSlider'
import LowCloudForecastLegend from './components/lowCloudForecastLegend'
import PremiumBadge from '../../../controls/PremiumBadge'

import ErrorToggle from '../../components/ErrorToggle'
import FullWidthError from '../../components/FullWidthError'
import ErrorText from '../../components/ErrorText'

import {
    timeZoneState,
    dataModeState,
    layerSelectionState,
    fullscreenModalVisibleHandler,
    pollingPaused,
    showErrorTogglesState,
    simulateFreeUserState,
    setModalVisibilityState,
    mfaVerifiedState,
} from '../../../../globalState'

import { isMobileOnly, isIOS } from 'react-device-detect'
import formatDateTime from '../../../../util/dateFormatter'
import TimeSlider from './components/TimeSlider'
import { ReactComponent as CloudOctasIcon } from '../../../../icons/assets/cloud-octas.svg'
import { ReactComponent as WindIcon } from '../../../../icons/assets/wind.svg'
import { ReactComponent as lowCloudIcon } from '../../../../icons/assets/lowcloud-forecast.svg'
import { ReactComponent as InfoIcon } from '../../../../icons/assets/info-nocircle.svg'

export default function LowCloudForecastImageryLooper() {
    const { getAccessTokenSilently } = useAuth0()
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const isAdmin = useAdminRole()
    const [selectedType, setSelectedType] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [previousSelectedTime, setPreviousSelectedTime] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [looping, setLooping] = useState(false)
    const [speed, setSpeed] = useState('1x')
    const [selectedSetTimes, setSelectedSetTimes] = useState([])
    const [imageSet, setImageSet] = useState([])
    const [imagesLoaded, setImagesLoaded] = useState(0)
    const [percentLoaded, setPercentLoaded] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [types, setTypes] = useState([])
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    // const [detailsShow, setDetailsShow] = useState(false)
    const [legendShow, setLegendShow] = useState(false)
    const timeZone = useRecoilValue(timeZoneState)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    // PinchZoom
    const { scale, setScale, xy, setXy } = useScale()
    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0

    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    // This pauses the network connection check until it is loaded.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    useEffect(() => {
        if (dataMode === 'met' && layerSelection.met.lowcloudforecast) {
            setImagesLoaded(0)
            setPercentLoaded(0)
            onOpen()
        } else {
            onClose()
        }
    }, [onOpen, onClose, dataMode, layerSelection, handleFullscreenModal])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    const fetchLowCloudImagery = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/cloudforecast`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const { data, status } = useQuery(`lowCloudImagery`, fetchLowCloudImagery, {
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        // console.log({ data, status })
        if (data) {
            setTypes(Object.keys(data))
        }
    }, [data, status])

    useEffect(() => {
        if (data && types) {
            setSelectedType(types[0])
        }
    }, [data, types])

    // This acts as a reset for selected type change
    useEffect(() => {
        if (data && types && selectedType) {
            setImagesLoaded(0)
            setPercentLoaded(0)
            const images = data[selectedType]
            setSelectedSetTimes(images.map((i) => i.validat))
            setImageSet(images)
        }
    }, [data, types, selectedType])

    useEffect(() => {
        data &&
            selectedSetTimes &&
            setLoaded(imagesLoaded === selectedSetTimes.length)
        const percent = 100 / selectedSetTimes.length
        setPercentLoaded(imagesLoaded * percent)
    }, [data, imagesLoaded, selectedSetTimes])

    useEffect(() => {
        if (showNetworkErrors) {
            setImagesLoaded(0)
        }
    }, [showNetworkErrors])

    function onMainImageLoad() {
        setImagesLoaded((i) => i + 1)
    }

    const singleTypeOnly = selectedType && selectedType.length < 2

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const [selectedSourcePosition, setSelectedSourcePosition] = useState(0)
    const smallFrame = window.innerWidth < 768

    // MOBILE SOURCE CHANGE BY SWIPE LEFT/RIGHT
    const levelSwipe = useSwipeable({
        onSwipedLeft: () => {
            if (smallFrame && selectedSourcePosition > 0) {
                setSelectedType(types[0])
                setSelectedSourcePosition(selectedSourcePosition - 1)
                setScale(1)
                setXy({
                    x: 0,
                    y: 0,
                })
            }
        },
        onSwipedRight: () => {
            if (isPremium && data && selectedType && selectedType === 'mid') {
                setShowPricingTiers(true)
            } else if (smallFrame && selectedSourcePosition < 1) {
                setSelectedType(types[1])
                setSelectedSourcePosition(selectedSourcePosition + 1)
                setScale(1)
                setXy({
                    x: 0,
                    y: 0,
                })
            }
        },
    })

    // const legendSwipe = useSwipeable({
    //     // LEGEND ON MOBILE SHOW BY SWIPE DOWN/UP
    //     onSwipedDown: () => {
    //         setLegendShow(true)
    //     },
    //     onSwipedUp: () => {
    //         setLegendShow(false)
    //     },
    // })

    function isOutOfDate() {
        const itemData =
            data &&
            selectedTime &&
            imageSet.filter((i) => selectedTime === i.validat)[0]
        if (itemData) {
            return { outofdate: itemData.outofdate, updated: itemData.updated }
        } else {
            return { outofdate: false, updated: null }
        }
    }

    const errorShow = showOutdatedErrors || isOutOfDate().outofdate

    const errorHeight = useBreakpointValue({
        base: 380 + (isAdmin && showErrorToggle && 30) + (errorShow && 70),
        md: 290 + (errorShow && 70),
        lg: 290 + (errorShow && 70),
        xl: 290 + (errorShow && 40),
        '2xl': 290 + (errorShow && 40),
        '3xl': 290 + (errorShow && 40),
    })

    // useEffect(() => {
    //     console.log('Data: ', data)
    //     console.log('types: ', types)
    //     console.log('selectedType: ', selectedType)
    //     console.log('selectedSourcePosition: ', selectedSourcePosition)
    // }, [data, types, selectedType, selectedSourcePosition])

    return (
        <>
            <LightMode>
                <Modal
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    size="full"
                    backgroundColor="transparent"
                    scrollable
                    variant="imageLooper"
                    zIndex="888"
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalContent
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                        boxShadow={{
                            base: 'none',
                            lg: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                        }}
                        overflow={{
                            base: 'clip',
                            lg: undefined,
                        }}
                    >
                        <Flex
                            direction="column"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }} // added flex here as header height not sticking causing spacing issues elsewhere
                        >
                            <ModalHeader
                                backgroundColor={useColorModeValue(
                                    'light.10',
                                    'light.10'
                                )}
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        isAdmin && showErrorToggle
                                            ? '100px'
                                            : '70px',
                                    md: '70px',
                                }}
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Flex
                                        // {...infoSwipe}
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            // {...infoSwipe}
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            maxWidth={{
                                                base: '100%',
                                                ml: '260px',
                                            }}
                                            paddingLeft={{
                                                base: '30px',
                                                md: '0',
                                            }}
                                            paddingTop={{
                                                base: '3px',
                                                md: '0',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '32px',
                                                }}
                                                color="light.100"
                                                as={lowCloudIcon}
                                                ml={{
                                                    base: minimiseActive
                                                        ? '0px'
                                                        : '40px',
                                                    md: '4px',
                                                }}
                                                transition="all ease 300ms"
                                            />
                                            <Box
                                                pl={{ base: '5px', md: '10px' }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color={useColorModeValue(
                                                        'light.100',
                                                        'light.100'
                                                    )}
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                    whiteSpace="pre"
                                                >
                                                    Low Cloud Forecast
                                                </Text>
                                            </Box>
                                        </Flex>

                                        <Flex
                                            gap="10px"
                                            minWidth={{
                                                base: minimiseActive
                                                    ? '90px'
                                                    : '40px',
                                                ml: '500px',
                                                lg: '600px',
                                            }}
                                            transition="all ease 300ms"
                                            justifyContent="flex-end"
                                        >
                                            <Flex
                                                color="light.100"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                pr={{
                                                    base: '0px',
                                                    md: '10px',
                                                    ml: '10px',
                                                }}
                                                pt={{
                                                    base: '1px',
                                                    lg: '0px',
                                                }}
                                                width={{
                                                    base: '40px',
                                                    ml: '100%',
                                                }}
                                                opacity={{
                                                    base: minimiseActive
                                                        ? '1'
                                                        : '0',
                                                    ml: '1',
                                                }}
                                                visibility={{
                                                    base: minimiseActive
                                                        ? 'visible'
                                                        : 'hidden',
                                                    ml: 'visible',
                                                }}
                                                transition="all ease 300ms"
                                            >
                                                <ZoomSlider
                                                    scale={scale}
                                                    xy={xy}
                                                    setScale={setScale}
                                                    setXy={setXy}
                                                    maxScale={8}
                                                />
                                            </Flex>
                                            <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                                display={{
                                                    base: 'inline-flex',
                                                    lg: 'none',
                                                }}
                                                background="none"
                                                color="light.100"
                                                // opacity={detailsShow ? '1' : '0.8'}
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setLegendShow(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                }}
                                                _active={{
                                                    background: 'none',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                }}
                                            >
                                                <Flex
                                                    // borderColor="#DFE6EE"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    // border="1px solid #DFE6EE"
                                                    borderRadius="10px"
                                                    color="light.100"
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height="30px"
                                                    width="34px"
                                                    style={{
                                                        minWidth: '34px',
                                                    }}
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.20',
                                                    }}
                                                    _active={{
                                                        background: {
                                                            base: 'none',
                                                            lg: 'light.20',
                                                        },
                                                        boxShadow: {
                                                            base: 'none',
                                                            lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                        },
                                                    }}
                                                >
                                                    <Icon
                                                        as={InfoIcon}
                                                        boxSize={5}
                                                    />
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction={{
                                            base: 'row',
                                            md: 'row',
                                        }}
                                        w={
                                            isAdmin && showErrorToggle
                                                ? '100%'
                                                : '0'
                                        }
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                    >
                                        <HStack
                                            spacing="15px"
                                            align="center"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-end',
                                            }}
                                        >
                                            <ErrorToggle
                                                showErrors={showNetworkErrors}
                                                setShowErrors={
                                                    setShowNetworkErrors
                                                }
                                                label="Simulate Network Error"
                                            />
                                            <ErrorToggle
                                                showErrors={showOutdatedErrors}
                                                setShowErrors={
                                                    setShowOutdatedErrors
                                                }
                                                label="Simulate Out-of-Date Error"
                                            />
                                        </HStack>
                                    </Flex>
                                </Flex>
                            </ModalHeader>
                        </Flex>
                        <ModalBody
                            p={0}
                            height={{
                                base:
                                    isMobileOnly && isIOS
                                        ? 'calc(100% - 145px)'
                                        : 'calc(100% - 130px)',
                                lg: '100%',
                            }}
                            background="#edf1f7"
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                        >
                            {status !== 'error' && !showNetworkErrors ? (
                                <Flex
                                    // {...legendSwipe}
                                    flexDirection={{
                                        base: 'column-reverse',
                                        md: 'row',
                                    }}
                                    w="100%"
                                    h="100%"
                                    bg={{
                                        base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 80%)',
                                        md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 80%)',
                                    }}
                                >
                                    <Flex // LEFT MENU //
                                        colSpan={2}
                                        mb={{
                                            base: minimiseOnZoom
                                                ? '-68px'
                                                : '0px',
                                            md: '0px',
                                        }}
                                        transition="all ease 300ms"
                                        position="relative"
                                        width={{ base: '100%', md: '200px' }}
                                        minWidth={{ base: '100%', md: '200px' }}
                                        minHeight={{
                                            base: '67px',
                                            md: '100%',
                                        }}
                                        background={{
                                            base: 'light.55',
                                            md: 'light.10',
                                        }}
                                        backdropFilter={{
                                            base: 'blur(20px)',
                                            ml: 'none',
                                        }}
                                        borderRight={{
                                            base: 'none',
                                            md: '1px solid #E2E8F0',
                                        }}
                                        flexDirection={{
                                            base: 'row',
                                            md: 'column',
                                        }}
                                        pb={{ base: '3px', md: '0px' }}
                                        zIndex="102"
                                    >
                                        <Flex
                                            flexDirection={{
                                                base: 'row',
                                                md: 'column',
                                            }}
                                            alignItems={{
                                                base: 'center',
                                                md: 'unset',
                                            }}
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            // This needs to be set in order to allow scrolling
                                            h={{
                                                base: '100%',
                                                md: '100%',
                                            }}
                                            width="100%"
                                            overflowX={{
                                                base: 'auto',
                                                md: 'hidden',
                                            }}
                                            overflowY={{
                                                base: 'hidden',
                                                md: 'auto',
                                            }}
                                            pl={{
                                                base: '20px',
                                                md: '0px',
                                            }}
                                            pr={{
                                                base: '20px',
                                                md: '0px',
                                            }}
                                            borderTop={{
                                                base: '1px solid #E2E8F0',
                                                md: 'none',
                                            }}
                                        >
                                            <Flex
                                                display={{
                                                    base: 'none',
                                                    md: 'flex',
                                                }}
                                                fontSize="0.65rem"
                                                flex="1"
                                                textAlign="left"
                                                paddingLeft="21px"
                                                maxHeight="40px"
                                                alignItems="center"
                                                borderBottom={{
                                                    base: 'none',
                                                    md: '1px solid #E2E8F0',
                                                }}
                                                marginBottom={{
                                                    base: '0px',
                                                    md: '15px',
                                                }}
                                            >
                                                <Text
                                                    marginTop="auto"
                                                    marginBottom="auto"
                                                    color="light.300"
                                                    fontSize="11px"
                                                    fontWeight="400"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                >
                                                    Cloud Level
                                                </Text>
                                            </Flex>
                                            <Flex
                                                flexDirection={{
                                                    base: 'row',
                                                    md: 'column',
                                                }}
                                                {...levelSwipe}
                                                borderRadius={{
                                                    base: '17px',
                                                    md: 'initial',
                                                }}
                                                px="2px"
                                                width={{
                                                    base: '100%',
                                                    md: '200px',
                                                }}
                                                maxWidth={{
                                                    base: '430px',
                                                    md: '100%',
                                                }}
                                                overflow="hidden"
                                                bg={{
                                                    base: singleTypeOnly
                                                        ? 'none'
                                                        : 'rgba(8, 38, 71, 0.04)',
                                                    md: 'none',
                                                }}
                                                boxShadow={{
                                                    base: singleTypeOnly
                                                        ? 'none'
                                                        : 'inset 0px 0px 3px -1px rgba(0,0,0,0.35)',
                                                    md: 'none',
                                                }}
                                                justifyContent={
                                                    singleTypeOnly && 'center'
                                                }
                                            >
                                                {types.map((type, i) => (
                                                    <Flex
                                                        key={`typeButton-${type}`}
                                                        {...noFocus}
                                                        zIndex="2"
                                                        as="button"
                                                        size="sm"
                                                        width={{
                                                            base: '100%',
                                                            lg: '100%',
                                                        }}
                                                        maxWidth={{
                                                            base: '213px',
                                                            lg: '100%',
                                                        }}
                                                        borderRadius={{
                                                            base: '18px',
                                                            md: '12',
                                                        }}
                                                        height={{
                                                            base: '32px',
                                                            md: '40px',
                                                        }}
                                                        minHeight={{
                                                            base: '34px',
                                                            lg: '32px',
                                                        }}
                                                        bg="transparent"
                                                        variant="outline"
                                                        marginInlineStart="0px !important"
                                                        border="none"
                                                        justifyContent={{
                                                            base: 'center',
                                                            md: 'flex-start',
                                                        }}
                                                        whiteSpace="pre-line"
                                                        pl={{
                                                            base: '5px',
                                                            md: '20px',
                                                        }}
                                                        pr={{
                                                            base: '5px',

                                                            md: '0px',
                                                        }}
                                                        py="2px"
                                                        _active={{
                                                            bg: 'none',
                                                            color: {
                                                                base: 'light.900',
                                                                md: 'light.200',
                                                            },
                                                        }}
                                                        _focus={{
                                                            bg: 'none',
                                                            color: 'light.100',
                                                        }}
                                                        _hover={{
                                                            bg: 'none',
                                                            opacity: '1',
                                                            cursor: 'pointer',
                                                            color:
                                                                isPremium &&
                                                                type === 'mid'
                                                                    ? 'light.100'
                                                                    : 'light.200',
                                                        }}
                                                        color={
                                                            selectedType ===
                                                            type
                                                                ? 'light.200'
                                                                : 'light.100'
                                                        }
                                                        fontSize={{
                                                            base:
                                                                selectedType ===
                                                                type
                                                                    ? '0.8rem'
                                                                    : '0.8rem',
                                                            md: '13px',
                                                        }}
                                                        isDisabled={
                                                            singleTypeOnly
                                                        }
                                                        opacity="1 !important"
                                                        cursor={
                                                            singleTypeOnly
                                                                ? 'default !important'
                                                                : 'pointer'
                                                        }
                                                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                        transition="all ease 300ms"
                                                        fontWeight="400"
                                                        textAlign={{
                                                            base: 'center',
                                                            md: 'left',
                                                        }}
                                                        letterSpacing={{
                                                            base:
                                                                selectedType ===
                                                                type
                                                                    ? '0.1px'
                                                                    : '0.1px',
                                                            md: '0.1px',
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                isPremium &&
                                                                type === 'mid'
                                                            ) {
                                                                setShowPricingTiers(
                                                                    true
                                                                )
                                                                setSelectedType(
                                                                    'low'
                                                                )
                                                            } else {
                                                                setSelectedType(
                                                                    type
                                                                )
                                                                setSelectedSourcePosition(
                                                                    i
                                                                )
                                                            }
                                                            setScale(1) // Reset the zoom scale to 1
                                                            setXy({
                                                                x: 0,
                                                                y: 0,
                                                            }) // Reset x and y to 0
                                                        }}
                                                        alignItems="center"
                                                    >
                                                        <Text
                                                            ml="5px"
                                                            textTransform="capitalize"
                                                            opacity={
                                                                isPremium &&
                                                                type === 'mid'
                                                                    ? '0.4'
                                                                    : '1'
                                                            }
                                                        >
                                                            {type} Level
                                                        </Text>
                                                        {/* *** PREMIUM BADGE *** */}
                                                        {isPremium &&
                                                            type === 'mid' && (
                                                                <Flex
                                                                    ml="7px"
                                                                    mr="-2px"
                                                                >
                                                                    <PremiumBadge />
                                                                </Flex>
                                                            )}
                                                    </Flex>
                                                ))}
                                                {!singleTypeOnly && (
                                                    <Flex // BUTTON INDICATOR
                                                        position="absolute"
                                                        display={{
                                                            base: 'flex',
                                                            md: 'none',
                                                        }}
                                                        marginInlineStart="0px !important"
                                                        mt="2px"
                                                        ml="0px"
                                                        px="44px"
                                                        height={{
                                                            base: '30px',
                                                            md: '30px',
                                                        }}
                                                        width={{
                                                            base: 'calc(50% - 22px)',
                                                            lg: '205px',
                                                        }}
                                                        maxWidth={{
                                                            base: '213px',
                                                            lg: '100%',
                                                        }}
                                                        background="white"
                                                        borderRadius="20px"
                                                        bg="light.10"
                                                        boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                        transform={{
                                                            base: `translateX(${selectedSourcePosition}00%)`,
                                                            lg: 'none',
                                                        }}
                                                        transition="transform 400ms"
                                                    ></Flex>
                                                )}
                                                <Flex // MENU SLIDING BACKGROUND
                                                    position="absolute"
                                                    width="206px"
                                                    display={{
                                                        base: 'none',
                                                        md: 'flex',
                                                    }}
                                                    transform={`translateY(${types.indexOf(
                                                        selectedType
                                                    )}00%)`}
                                                    transition="transform ease 0.4s"
                                                >
                                                    <Box
                                                        mt="0px"
                                                        ml="0px"
                                                        mr="0px"
                                                        height="40px"
                                                        width="100%"
                                                        marginBottom="0px"
                                                        background="light.20"
                                                        borderRightRadius="7px"
                                                    >
                                                        <Box
                                                            width="100%"
                                                            position="absolute"
                                                            height="100%"
                                                            borderRightRadius="7px"
                                                            border="1px solid"
                                                            borderLeft="none"
                                                            borderColor="#e2e8f0"
                                                            opacity="1"
                                                        ></Box>
                                                        <Box
                                                            background="light.200"
                                                            width="3px"
                                                            position="absolute"
                                                            right="5px"
                                                            top="14%"
                                                            height="72%"
                                                            borderRadius="5px"
                                                        ></Box>
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        flexDirection={{
                                            base: 'column-reverse',
                                            lg: 'row',
                                        }}
                                        position={{
                                            base: 'absolute',
                                            md: 'relative',
                                        }}
                                        top={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? '100px'
                                                    : '70px',
                                            md: '0px',
                                        }}
                                        w="100%"
                                        h={{
                                            base:
                                                isAdmin && showErrorToggle
                                                    ? 'calc(100% - 100px)'
                                                    : 'calc(100% - 70px)',
                                            md: '100%',
                                        }}
                                        transition="all ease-in-out 300ms"
                                    >
                                        <Flex // IMAGE DISPLAY AND TIME SLIDER
                                            width="100%"
                                            h="100%"
                                            position={'relative'}
                                            flexDirection="column"
                                            alignItems="center"
                                            whiteSpace="pre-line"
                                            overflowX="hidden"
                                            overflowY="hidden"
                                        >
                                            {data && errorShow && (
                                                <Flex
                                                    px="15px"
                                                    pt="5px"
                                                    mb="-10px"
                                                    width="100%"
                                                    justifyContent="center"
                                                    zIndex="1"
                                                    backdropFilter="blur(4px)"
                                                    textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                    background={{
                                                        base: isFirefox
                                                            ? 'rgba(241, 246, 251, 0.95)'
                                                            : 'rgba(241, 246, 251, 0.85)',
                                                        md: isFirefox
                                                            ? 'rgba(241, 246, 251, 0.95)'
                                                            : 'rgba(241, 246, 251, 0.85)',
                                                    }}
                                                >
                                                    <ErrorText>
                                                        There has been an issue
                                                        with the connection
                                                        between PreFlight and
                                                        MetService. This imagery
                                                        was last validated with
                                                        MetService{' '}
                                                        <strong>
                                                            {formatDistanceToNow(
                                                                parseISO(
                                                                    isOutOfDate()
                                                                        .updated
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                        </strong>
                                                        .<br />
                                                        There is a possibility
                                                        that we are unable to
                                                        display imagery that is
                                                        up to date. Please check
                                                        back again soon.
                                                    </ErrorText>
                                                </Flex>
                                            )}
                                            <Flex
                                                w="100%"
                                                maxWidth={`calc(100vh - ${errorHeight}px)`}
                                                h="100%"
                                                maxHeight="100%"
                                                minHeight={{
                                                    base: '100%',
                                                    lg: '100%',
                                                }}
                                                pos={'relative'}
                                                alignItems="baseline"
                                                justifyContent="center"
                                                padding="20px"
                                                paddingBottom={{
                                                    base: errorShow
                                                        ? '220px'
                                                        : '155px',
                                                    md: errorShow
                                                        ? '220px'
                                                        : '105px',
                                                    lg: errorShow
                                                        ? '180px'
                                                        : '105px',
                                                    xl: errorShow
                                                        ? '135px'
                                                        : '105px',
                                                }}
                                                transition="all ease-in-out 300ms"
                                            >
                                                <Box
                                                    w="100%"
                                                    h="100%"
                                                    pos="relative"
                                                    // {...levelSwipe}
                                                >
                                                    {status !== 'loading' &&
                                                        status === 'error' && (
                                                            <Text
                                                                pb="10px"
                                                                mt="-10px"
                                                                textAlign="center"
                                                                fontWeight="500"
                                                            >
                                                                Error loading
                                                                data
                                                            </Text>
                                                        )}

                                                    {status === 'loading' && (
                                                        <Flex
                                                            zIndex="1"
                                                            position="absolute"
                                                            top="0px"
                                                            left="0px"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            width={'100%'}
                                                            pt="34vh"
                                                            px={{
                                                                base: '10px',
                                                                md: '30px',
                                                            }}
                                                        >
                                                            <Spinner
                                                                color={
                                                                    'light.200'
                                                                }
                                                                thickness="3px"
                                                                speed="0.45s"
                                                                boxSize="60px"
                                                            />
                                                        </Flex>
                                                    )}
                                                    {status !== 'loading' &&
                                                        !loaded &&
                                                        !!percentLoaded && (
                                                            <>
                                                                <Flex
                                                                    w="100%"
                                                                    h="100%"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    position="absolute"
                                                                >
                                                                    <CircularProgress
                                                                        value={
                                                                            percentLoaded
                                                                        }
                                                                        size="80px"
                                                                        thickness="4px"
                                                                        color="light.200"
                                                                    >
                                                                        <CircularProgressLabel>
                                                                            {`${Math.round(
                                                                                percentLoaded
                                                                            )}%`}
                                                                        </CircularProgressLabel>
                                                                    </CircularProgress>
                                                                </Flex>
                                                            </>
                                                        )}

                                                    <PinchDrag
                                                        scale={scale}
                                                        setScale={setScale}
                                                        xy={xy}
                                                        setXy={setXy}
                                                        style={{
                                                            zIndex: '101',
                                                        }}
                                                        maxZoom={8}
                                                        maxZoomNewScale={7.999}
                                                    >
                                                        <Box
                                                            w="100%"
                                                            h="0"
                                                            borderRadius="10px"
                                                            pt={
                                                                loaded
                                                                    ? '100%'
                                                                    : 0
                                                            }
                                                            pos="relative"
                                                            zIndex={101}
                                                        >
                                                            {imageSet.map(
                                                                (i, idx) => (
                                                                    <Fade
                                                                        key={`cloudImg${idx}`}
                                                                        in={
                                                                            loaded &&
                                                                            (selectedTime ===
                                                                                i.validat ||
                                                                                previousSelectedTime ===
                                                                                    i.validat)
                                                                        }
                                                                    >
                                                                        <Box
                                                                            pos="absolute"
                                                                            zIndex={
                                                                                selectedTime ===
                                                                                i.validat
                                                                                    ? 5
                                                                                    : 4
                                                                            }
                                                                            top="0px"
                                                                            left="0px"
                                                                            transition="all ease 200ms"
                                                                            w="100%"
                                                                            h="0"
                                                                            borderRadius="10px"
                                                                            overflow="hidden"
                                                                            pt="calc(100% * (130 / 100))"
                                                                            opacity={
                                                                                loaded
                                                                                    ? 1
                                                                                    : 0.7
                                                                            }
                                                                        >
                                                                            {i.url && (
                                                                                <Flex
                                                                                    overflow="hidden"
                                                                                    border="1px solid #e2e8f0"
                                                                                    pos="absolute"
                                                                                    top="0px"
                                                                                    w={{
                                                                                        base: '100%',
                                                                                        md: '100%',
                                                                                    }}
                                                                                    h={{
                                                                                        base: '100%',
                                                                                        md: '100%',
                                                                                    }}
                                                                                    transition="all ease 200ms"
                                                                                    fit="cover"
                                                                                    objectPosition={
                                                                                        'left top'
                                                                                    }
                                                                                    borderRadius="10px"
                                                                                >
                                                                                    <Image
                                                                                        src={`${window.location.origin}/metproxy?url=${i.url}`}
                                                                                        key={`lowCloudForecastImg-${idx}`}
                                                                                        sx={{
                                                                                            transform:
                                                                                                'scale(1.38)',
                                                                                        }}
                                                                                        margin="-4% 0 0% 0%"
                                                                                        w="100%"
                                                                                        h="100%"
                                                                                        fit="cover"
                                                                                        objectPosition="51% 0%"
                                                                                        onLoad={
                                                                                            onMainImageLoad
                                                                                        }
                                                                                    />
                                                                                </Flex>
                                                                            )}
                                                                        </Box>
                                                                    </Fade>
                                                                )
                                                            )}
                                                        </Box>
                                                    </PinchDrag>
                                                </Box>
                                            </Flex>
                                            <Flex
                                                w="100%"
                                                pb="20px"
                                                height={{
                                                    base: '85px',
                                                    lg: '85px',
                                                }}
                                                bottom={{
                                                    base: minimiseOnZoom
                                                        ? '7px'
                                                        : '74px',
                                                    md: '0px',
                                                }}
                                                alignItems="center"
                                                flexDirection="column"
                                                justifyContent={{
                                                    base: 'flex-start',
                                                    lg: 'flex-start',
                                                }}
                                                opacity={!loaded ? '0.15' : '1'}
                                                transition="all ease 300ms"
                                                position="absolute"
                                                zIndex="101"
                                            >
                                                <Flex // IMAGE TIME
                                                    h="30px"
                                                    mb="7px"
                                                    pt="3px"
                                                    pb="3px"
                                                    px="15px"
                                                    justify={'center'}
                                                    alignItems="center"
                                                    fontWeight="bold"
                                                    fontSize="0.8rem"
                                                    textAlign="center"
                                                    color="light.100"
                                                    backdropFilter="blur(4px)"
                                                    textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                    background={{
                                                        base: isFirefox
                                                            ? 'rgba(223, 232, 241, 0.8)'
                                                            : 'rgba(223, 232, 241, 0.3)',
                                                        md: isFirefox
                                                            ? 'rgba(240, 248, 253, 0.8)'
                                                            : 'rgba(240, 248, 253, 0.3)',
                                                    }}
                                                    borderRadius="20px"
                                                >
                                                    {selectedTime &&
                                                        formatDateTime(
                                                            selectedTime,
                                                            timeZone
                                                        )}
                                                </Flex>
                                                {!loaded && (
                                                    <Box
                                                        position="absolute"
                                                        height="80px"
                                                        width="100%"
                                                        zIndex="1"
                                                        marginTop="0px"
                                                    />
                                                )}
                                                {selectedSetTimes && (
                                                    <Flex // TIME SLIDER
                                                        w="100%"
                                                        px="20px"
                                                        maxWidth={{
                                                            base: '700px',
                                                            md: '800px',
                                                            lg: '900px',
                                                        }}
                                                        justify={'center'}
                                                    >
                                                        <TimeSlider
                                                            style={{
                                                                width: 'calc(100% - 10px)',
                                                            }}
                                                            times={
                                                                selectedSetTimes
                                                            }
                                                            selectedTime={
                                                                selectedTime
                                                            }
                                                            setSelectedTime={
                                                                setSelectedTime
                                                            }
                                                            setPreviousSelectedTime={
                                                                setPreviousSelectedTime
                                                            }
                                                            speed={speed}
                                                            setSpeed={setSpeed}
                                                            playing={playing}
                                                            setPlaying={
                                                                setPlaying
                                                            }
                                                            looping={looping}
                                                            setLooping={
                                                                setLooping
                                                            }
                                                            mostRecent={false}
                                                        />
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                        <Flex // RIGHT MENU (CONTROLS AND LEGEND)
                                            pos="relative"
                                            flexDirection={{
                                                base: 'row',
                                                lg: 'column',
                                            }}
                                            h={{
                                                base: '0px',
                                                lg: '100%',
                                            }}
                                            minHeight={{
                                                base: '0px',
                                                lg: '100%',
                                            }}
                                            width={{
                                                base: '100%',
                                                lg: '250px',
                                            }}
                                            minWidth={{
                                                base: '100%',
                                                lg: '250px',
                                            }}
                                            marginBottom={{
                                                base: legendShow
                                                    ? '135px'
                                                    : '0px',
                                                lg: '0',
                                            }}
                                            transition="all ease-in-out 300ms"
                                            mt={{
                                                base: '-1px',
                                                lg: '0',
                                            }}
                                            background={{
                                                base: 'light.55',
                                                lg: 'light.10',
                                            }}
                                            borderLeft={{
                                                base: 'none',
                                                lg: '1px solid #E2E8F0',
                                            }}
                                            boxShadow={{
                                                base: '0px 5px 30px -5px rgba(0,0,0,0.2)',
                                                lg: 'none',
                                            }}
                                            zIndex="101"
                                        >
                                            <Flex
                                                // {...legendSwipe}
                                                className="legend"
                                                position={{
                                                    base: 'absolute',
                                                    lg: 'relative',
                                                }}
                                                flexDirection={{
                                                    base: 'row',
                                                    lg: 'column',
                                                }}
                                                justifyContent={{
                                                    base: 'center',
                                                    md: 'flex-start',
                                                }}
                                                top={{
                                                    base: '13px',
                                                    lg: 'unset',
                                                }}
                                                w="100%"
                                                height={{
                                                    base: legendShow
                                                        ? '140px'
                                                        : '0px',
                                                    lg: '100%',
                                                }}
                                                marginTop={{
                                                    base: legendShow
                                                        ? '0'
                                                        : '-60px',
                                                    lg: '20px',
                                                }}
                                                transition="all ease-in-out 300ms"
                                                marginBottom={{
                                                    base: legendShow
                                                        ? '0'
                                                        : '0px',
                                                    lg: '20px',
                                                }}
                                            >
                                                <Flex
                                                    flexDirection={{
                                                        base: 'column',
                                                        lg: 'column',
                                                    }}
                                                    justifyContent={{
                                                        base: 'space-evenly',
                                                        lg: 'space-evenly',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        lg: 'center',
                                                    }}
                                                    width="100%"
                                                >
                                                    <Flex
                                                        flexDirection={{
                                                            base: 'row',
                                                            lg: 'column',
                                                        }}
                                                        alignItems={{
                                                            base: 'flex-start',
                                                            lg: 'flex-start',
                                                        }}
                                                        px={{
                                                            base: '20px',
                                                            lg: '25px',
                                                        }}
                                                        minHeight={{
                                                            base: '0px',
                                                            lg: '60px',
                                                        }}
                                                        pb={{
                                                            base: legendShow
                                                                ? '20px'
                                                                : '0px',
                                                            lg: '0px',
                                                        }}
                                                        transition="all ease-in-out 300ms"
                                                        width="100%"
                                                        color="light.100"
                                                        borderBottom={{
                                                            base: 'none',
                                                            lg: '1px solid',
                                                        }}
                                                        borderBottomColor={{
                                                            base: 'none',
                                                            lg: '#E2E8F0',
                                                        }}
                                                    >
                                                        <Text
                                                            paddingStart="0"
                                                            paddingEnd="1"
                                                            pb={{
                                                                base: '0',
                                                                lg: '5px',
                                                            }}
                                                            minWidth={{
                                                                base: '100px',
                                                                lg: 'auto',
                                                            }}
                                                            fontSize="0.8rem"
                                                            lineHeight="1.3"
                                                            alignSelf="left"
                                                            color="gray.500"
                                                            fontWeight={{
                                                                base: '600',
                                                                lg: '600',
                                                            }}
                                                        >
                                                            Cloud Levels:
                                                        </Text>
                                                        <Flex
                                                            flexDirection="column"
                                                            alignItems={{
                                                                base: 'flex-start',
                                                                lg: 'flex-start',
                                                            }}
                                                            width="100%"
                                                        >
                                                            <Flex
                                                                flexDirection="row"
                                                                alignItems={{
                                                                    base: 'flex-start',
                                                                    lg: 'flex-start',
                                                                }}
                                                                width="100%"
                                                            >
                                                                {legendShow && (
                                                                    <Text
                                                                        display={{
                                                                            base: 'block',
                                                                            lg: 'none',
                                                                        }}
                                                                        letterSpacing={
                                                                            smallFrame
                                                                                ? '-0.2px'
                                                                                : '1px'
                                                                        }
                                                                        paddingStart="0"
                                                                        paddingEnd="2"
                                                                        opacity="0.7"
                                                                        fontWeight="400"
                                                                        py="0px"
                                                                        fontSize="0.8rem"
                                                                        lineHeight="1.3"
                                                                        borderColor="transparent"
                                                                    >
                                                                        Low:
                                                                    </Text>
                                                                )}
                                                                {(selectedType ===
                                                                    'low' ||
                                                                    legendShow) && (
                                                                    <Text
                                                                        letterSpacing={
                                                                            smallFrame
                                                                                ? '-0.2px'
                                                                                : '1px'
                                                                        }
                                                                        paddingStart="0"
                                                                        paddingEnd="0"
                                                                        opacity="0.7"
                                                                        fontWeight="400"
                                                                        py="0px"
                                                                        fontSize="0.8rem"
                                                                        lineHeight="1.3"
                                                                        borderColor="transparent"
                                                                    >
                                                                        SFC –
                                                                        6,500 ft
                                                                    </Text>
                                                                )}
                                                            </Flex>
                                                            <Flex
                                                                flexDirection="row"
                                                                alignItems={{
                                                                    base: 'flex-start',
                                                                    lg: 'flex-start',
                                                                }}
                                                                width="100%"
                                                            >
                                                                {legendShow && (
                                                                    <Text
                                                                        display={{
                                                                            base: 'block',
                                                                            lg: 'none',
                                                                        }}
                                                                        letterSpacing={
                                                                            smallFrame
                                                                                ? '-0.2px'
                                                                                : '1px'
                                                                        }
                                                                        paddingStart="0"
                                                                        paddingEnd="2"
                                                                        opacity="0.7"
                                                                        fontWeight="400"
                                                                        py="0px"
                                                                        fontSize="0.8rem"
                                                                        lineHeight="1.3"
                                                                        borderColor="transparent"
                                                                    >
                                                                        Mid:
                                                                    </Text>
                                                                )}
                                                                {(selectedType ===
                                                                    'mid' ||
                                                                    legendShow) && (
                                                                    <Text
                                                                        letterSpacing={
                                                                            smallFrame
                                                                                ? '-0.2px'
                                                                                : '1px'
                                                                        }
                                                                        paddingStart="0"
                                                                        paddingEnd="0"
                                                                        opacity="0.7"
                                                                        fontWeight="400"
                                                                        py="0px"
                                                                        fontSize="0.8rem"
                                                                        lineHeight="1.3"
                                                                        borderColor="transparent"
                                                                    >
                                                                        6,500 ft
                                                                        – 18,000
                                                                        ft
                                                                    </Text>
                                                                )}
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                    <Flex
                                                        flexDirection={{
                                                            base: 'row',
                                                            lg: 'column',
                                                        }}
                                                        alignItems={{
                                                            base: 'center',
                                                            lg: 'flex-start',
                                                        }}
                                                        px={{
                                                            base: '20px',
                                                            lg: '25px',
                                                        }}
                                                        minHeight={{
                                                            base: '0px',
                                                            lg: '80px',
                                                        }}
                                                        pt={{
                                                            base: legendShow
                                                                ? '5px'
                                                                : '0px',
                                                            lg: '20px',
                                                        }}
                                                        pb={{
                                                            base: legendShow
                                                                ? '5px'
                                                                : '0px',
                                                            lg: '20px',
                                                        }}
                                                        transition="all ease-in-out 300ms"
                                                        width="100%"
                                                        color="light.100"
                                                        borderBottom={{
                                                            base: 'none',
                                                            lg: '1px solid',
                                                        }}
                                                        borderBottomColor={{
                                                            base: 'none',
                                                            lg: '#E2E8F0',
                                                        }}
                                                    >
                                                        <Text
                                                            paddingStart="0"
                                                            paddingEnd="1"
                                                            pb={{
                                                                base: '0',
                                                                lg: '5px',
                                                            }}
                                                            minWidth={{
                                                                base: '100px',
                                                                lg: 'auto',
                                                            }}
                                                            fontSize="0.8rem"
                                                            lineHeight="1.3"
                                                            alignSelf="left"
                                                            color="gray.500"
                                                            fontWeight={{
                                                                base: '600',
                                                                lg: '600',
                                                            }}
                                                        >
                                                            Analysis Time:
                                                        </Text>
                                                        {imageSet.map(
                                                            (validTime, i) => (
                                                                <Text
                                                                    key={`GraforTime${validTime.validat}`}
                                                                    display={
                                                                        selectedTime ===
                                                                        validTime.validat
                                                                            ? 'block'
                                                                            : 'none'
                                                                    }
                                                                    paddingStart="0"
                                                                    paddingEnd="0"
                                                                    opacity="0.7"
                                                                    fontWeight="400"
                                                                    py="0px"
                                                                    fontSize="0.8rem"
                                                                    lineHeight="1.3"
                                                                    borderColor="transparent"
                                                                >
                                                                    {validTime?.analysistime &&
                                                                        formatDateTime(
                                                                            validTime.analysistime,
                                                                            timeZone
                                                                        )}
                                                                </Text>
                                                            )
                                                        )}
                                                    </Flex>

                                                    <Flex
                                                        transition="all ease 300ms"
                                                        width={{
                                                            base: '100px',
                                                            lg: '100%',
                                                        }}
                                                        position={{
                                                            base: 'absolute',
                                                            lg: 'relative',
                                                        }}
                                                        height={{
                                                            base: '100%',
                                                            lg: 'unset',
                                                        }}
                                                        maxHeight={{
                                                            base: 'unset',
                                                            lg: '60px',
                                                        }}
                                                        left={{
                                                            base: '20px',
                                                            lg: 'unset',
                                                        }}
                                                        top={{
                                                            base: '30px',
                                                            lg: 'unset',
                                                        }}
                                                        minWidth={{
                                                            base: '100px',
                                                            lg: '100%',
                                                        }}
                                                        pt={{
                                                            base: '0px',
                                                            lg: '20px',
                                                        }}
                                                        pb={{
                                                            base: '0px',
                                                            lg: '20px',
                                                        }}
                                                        pl={{
                                                            base: '0px',
                                                            lg: '10px',
                                                        }}
                                                        pr={{
                                                            base: '0px',
                                                            lg: '10px',
                                                        }}
                                                        ml={{
                                                            base: '-2px',
                                                            lg: '0px',
                                                        }}
                                                        flexGrow="1"
                                                        flexDirection={{
                                                            base: 'column-reverse',
                                                            lg: 'row',
                                                        }}
                                                        justifyContent={{
                                                            base: 'space-evenly',
                                                            lg: 'space-evenly',
                                                        }}
                                                        alignItems={{
                                                            base: 'flex-start',
                                                            lg: 'center',
                                                        }}
                                                    >
                                                        <Flex
                                                            flexDirection={{
                                                                base: 'row',
                                                                lg: 'row',
                                                            }}
                                                            alignItems={{
                                                                base: 'center',
                                                                lg: 'center',
                                                            }}
                                                            marginTop={{
                                                                base: '-32px',
                                                                lg: '0px',
                                                            }}
                                                        >
                                                            <CloudOctasIcon color="#718096" />
                                                            <Text
                                                                opacity={{
                                                                    base: '1',
                                                                    lg: '1',
                                                                }}
                                                                marginRight={{
                                                                    base: '3px',
                                                                    lg: '3px',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    lg: 'center',
                                                                }}
                                                                ml="5px"
                                                                textAlign={{
                                                                    base: 'left',
                                                                    lg: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.8rem',
                                                                    lg: '0.8rem',
                                                                }}
                                                                color="gray.500"
                                                                fontWeight={{
                                                                    base: '600',
                                                                    lg: '600',
                                                                }}
                                                                lineHeight="1.2"
                                                            >
                                                                Octas
                                                            </Text>
                                                        </Flex>
                                                        <Flex
                                                            flexDirection={{
                                                                base: 'row',
                                                                lg: 'row',
                                                            }}
                                                            alignItems={{
                                                                base: 'center',
                                                                lg: 'center',
                                                            }}
                                                        >
                                                            <WindIcon color="#718096" />
                                                            <Text
                                                                opacity={{
                                                                    base: '1',
                                                                    lg: '1',
                                                                }}
                                                                ml="5px"
                                                                marginRight={{
                                                                    base: '3px',
                                                                    lg: '3px',
                                                                }}
                                                                alignSelf={{
                                                                    base: 'left',
                                                                    lg: 'center',
                                                                }}
                                                                textAlign={{
                                                                    base: 'left',
                                                                    lg: 'center',
                                                                }}
                                                                fontSize={{
                                                                    base: '0.8rem',
                                                                    lg: '0.8rem',
                                                                }}
                                                                color="gray.500"
                                                                fontWeight={{
                                                                    base: '600',
                                                                    lg: '600',
                                                                }}
                                                                lineHeight="1.2"
                                                            >
                                                                Wind (kts)
                                                            </Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        pl={{
                                                            base: '120px',
                                                            lg: '25px',
                                                        }}
                                                        pr={{
                                                            base: '20px',
                                                            lg: '25px',
                                                        }}
                                                        flexDirection={{
                                                            base: 'row',
                                                            lg: 'column',
                                                        }}
                                                        justifyContent="center"
                                                        w="100%"
                                                        height="100%"
                                                        maxHeight="400px"
                                                        transition="all ease 300ms"
                                                    >
                                                        <LowCloudForecastLegend />
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ) : (
                                <FullWidthError
                                    message={`Error downloading Low Cloud Forecast.<br />Please try again later.`}
                                />
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
