import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WindIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_699:30945)">
                <path
                    d="M12.5 3.505C11.7044 3.505 10.9413 3.82106 10.3787 4.38367C9.81607 4.94628 9.75 5.255 9.75 5.255C9.75 5.255 9.70178 5.4371 9.71 5.555C9.72326 5.7453 9.76263 5.863 9.89 6.005C10.0302 6.16133 10.1699 6.22777 10.3787 6.25C10.5244 6.26553 10.6133 6.24784 10.75 6.195C10.9704 6.10983 11.0577 5.9721 11.1752 5.78679C11.1993 5.74873 11.2247 5.70866 11.2528 5.66663C11.4176 5.41995 11.6519 5.22773 11.926 5.1142C12.2001 5.00067 12.5017 4.97093 12.7926 5.02881C13.0836 5.08669 13.3509 5.22955 13.5607 5.43933C13.7704 5.64911 13.9133 5.91643 13.9712 6.2074C14.0291 6.49837 13.9993 6.79995 13.8858 7.07404C13.7723 7.34812 13.58 7.58237 13.3334 7.74719C13.0867 7.91201 12.7967 8 12.5 8H4V9.5H12.5C13.2956 9.5 14.0587 9.18395 14.6213 8.62134C15.1839 8.05873 15.5 7.29565 15.5 6.5C15.5 5.70435 15.1839 4.94127 14.6213 4.37866C14.0587 3.81605 13.2956 3.5 12.5 3.5V3.505Z"
                    fill="currentColor"
                />
                <path
                    d="M16.5 20C15.7044 20 14.9413 19.6839 14.3787 19.1213C13.8161 18.5587 13.75 18.25 13.75 18.25C13.75 18.25 13.7018 18.0679 13.71 17.95C13.7233 17.7597 13.7626 17.642 13.89 17.5C14.0302 17.3437 14.1699 17.2772 14.3787 17.255C14.5244 17.2395 14.6133 17.2572 14.75 17.31C14.9704 17.3952 15.0577 17.5329 15.1752 17.7182C15.1993 17.7563 15.2247 17.7963 15.2528 17.8384C15.4176 18.0851 15.6519 18.2773 15.926 18.3908C16.2001 18.5043 16.5017 18.5341 16.7926 18.4762C17.0836 18.4183 17.3509 18.2755 17.5607 18.0657C17.7704 17.8559 17.9133 17.5886 17.9712 17.2976C18.0291 17.0066 17.9993 16.7051 17.8858 16.431C17.7723 16.1569 17.58 15.9226 17.3334 15.7578C17.0867 15.593 16.7967 15.505 16.5 15.505H6V14.005H16.5C17.2956 14.005 18.0587 14.3211 18.6213 14.8837C19.1839 15.4463 19.5 16.2094 19.5 17.005C19.5 17.8007 19.1839 18.5637 18.6213 19.1263C18.0587 19.689 17.2956 20.005 16.5 20.005V20Z"
                    fill="currentColor"
                />
                <path
                    d="M16.8786 7.38367C17.4412 6.82106 18.2043 6.505 18.9999 6.505V6.5C19.7956 6.5 20.5587 6.81605 21.1213 7.37866C21.6839 7.94127 21.9999 8.70435 21.9999 9.5C21.9999 10.2956 21.6839 11.0587 21.1213 11.6213C20.5587 12.1839 19.7956 12.5 18.9999 12.5H2V11H18.9999C19.2966 11 19.5866 10.912 19.8333 10.7472C20.08 10.5824 20.2722 10.3481 20.3858 10.074C20.4993 9.79995 20.529 9.49837 20.4711 9.2074C20.4132 8.91643 20.2704 8.64911 20.0606 8.43933C19.8508 8.22955 19.5836 8.08669 19.2926 8.02881C19.0016 7.97093 18.7 8.00067 18.4259 8.1142C18.1518 8.22773 17.9176 8.41995 17.7527 8.66663C17.7247 8.70866 17.6993 8.74873 17.6751 8.78679C17.5577 8.9721 17.4704 9.10983 17.2499 9.195C17.1132 9.24784 17.0244 9.26553 16.8786 9.25C16.6698 9.22777 16.5302 9.16133 16.3899 9.005C16.2626 8.863 16.2232 8.7453 16.2099 8.55501C16.2017 8.4371 16.2499 8.255 16.2499 8.255C16.2499 8.255 16.316 7.94628 16.8786 7.38367Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
