import React, { useEffect, useState, useRef } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import {
    Flex,
    Box,
    Text,
    IconButton,
    Button,
    ButtonGroup,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useBreakpointValue,
    Portal,
} from '@chakra-ui/react'
import Image from '../../../../components/Image'
import { isMobileOnly } from 'react-device-detect'
import { useInView } from 'react-intersection-observer'
import { useSwipeable } from 'react-swipeable'

import formatDateTime, {
    formatTimeAndTimezone,
    formatTimeOnly,
    timeZoneAbbreviation,
} from '../../../../../util/dateFormatter'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import { parseISO, formatDistanceToNow, addHours } from 'date-fns'
import { AiOutlineFullscreen } from 'react-icons/ai'
import { CgClose } from 'react-icons/cg'
import {
    showOutdatedErrorsState,
    inViewPositionState,
    briefingParamsState,
    selectedBriefingTimeState,
    collapseDropdownsState,
    timeZoneState,
    fullscreenHandlerState,
} from '../../../../../globalState'
import PinchDrag, { useScale } from '../../../components/PinchDrag'
import ZoomSlider from '../../../components/ZoomSlider'
import ErrorText from '../../../components/ErrorText'

import MslIcon from '../../../../../icons/menu-icons/MslIcon'

export default function MSLBriefing(props) {
    const { briefingData, fullscreenRef, handleReset, setHandleReset } = props
    const msl =
        briefingData &&
        briefingData.res &&
        briefingData.res.areamet &&
        briefingData.res.areamet.charts &&
        briefingData.res.areamet.charts.msl
    const showOutdatedErrors = useRecoilValue(showOutdatedErrorsState)
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const params = useRecoilValue(briefingParamsState)
    const collapseDropdowns = useRecoilValue(collapseDropdownsState)
    const selectedHours = useRecoilValue(selectedBriefingTimeState)
    const issuedTime = briefingData && parseISO(briefingData.time)
    const periodTo = issuedTime && addHours(issuedTime, selectedHours || 48)
    const [fullscreenHandler, setFullscreenHandler] = useRecoilState(
        fullscreenHandlerState
    )

    const { scale, setScale, xy, setXy } = useScale()
    const scrollRef = useRef()
    const fullscreen = Boolean(fullscreenHandler === 'msl')

    useEffect(() => {
        if (handleReset) {
            setScale(1)
            setXy({
                x: 0,
                y: 0,
            })
            setHandleReset(false)
            setFullscreenHandler(null)
        }
    }, [
        fullscreen,
        handleReset,
        setFullscreenHandler,
        setScale,
        setXy,
        setHandleReset,
    ])

    const headerSwipe = useSwipeable({
        onSwipedDown: () => {
            if (fullscreen) {
                setHandleReset(true)
            }
        },
    })

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize, calculateDateFontSize, calculateLabelSize } =
        useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const adjustLabelFontSize = useBreakpointValue({
        base: calculateFontSize(0.8),
        xs: calculateFontSize(0.85),
        lg: calculateFontSize(0.9),
    })
    const adjustDateFontSize = useBreakpointValue({
        base: calculateDateFontSize(0.8, fullscreen),
        sm: calculateDateFontSize(0.8),
    })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.1,
    })

    useEffect(() => {
        inView && setInViewPosition('mslContainer')
    }, [inView, setInViewPosition])

    // const hideOnMobile = window.innerWidth < 860 && !fullscreen
    const hideOnMobile = !fullscreen

    const pageScale = useBreakpointValue({
        base: fullscreen ? 20 : 20,
    })

    const pageScaleWidth = pageScale * 5 + '%'

    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)
    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    const [mslTime, setMslTime] = useState(msl && msl[0].validat, 0)
    const [mslTimePosition, setMslTimePosition] = useState(0)
    const fiveTimeSelections = msl && msl.length > 4
    const withinPeriodTo = parseISO(mslTime) < periodTo

    // const timeZone = useRecoilValue(timeZoneState)
    // const timeZone = 'Pacific/Auckland' // used to override international timezones if app viewed overseas
    const timeZoneSelection = useRecoilValue(timeZoneState)
    const timeZone =
        useRecoilValue(timeZoneState) === 'UTC'
            ? timeZoneSelection
            : 'Pacific/Auckland'

    const [expandedDropdowns, setExpandedDropdowns] = useState(
        collapseDropdowns ? [] : [0, 1]
    )

    useEffect(() => {
        setExpandedDropdowns(collapseDropdowns ? [] : [0, 1])
    }, [collapseDropdowns])

    useEffect(() => {
        if (parseISO(mslTime) > periodTo) {
            const filteredCharts = msl.filter(
                (chart) => parseISO(chart.validat) <= periodTo
            )
            setMslTime(
                filteredCharts.length > 0
                    ? filteredCharts[filteredCharts.length - 1]?.validat
                    : msl[0]?.validat
            )
            setMslTimePosition(
                filteredCharts.length > 0 ? filteredCharts.length - 1 : 0
            )
        }
    }, [periodTo, mslTime, msl])

    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()

    const portalLevels = useBreakpointValue({
        base: { containerRef: mobile },
        xl: { containerRef: fullscreen ? desktop : mobile },
        '2xl': { containerRef: desktop },
    })
    const timeZoneAddition = useBreakpointValue({
        base: true,
        sm: false,
        md: fullscreen ? false : true,
        lg: false,
    })

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    // console.log(msl, 'MSL Data')

    return (
        <div id="mslContainer" ref={ref}>
            {params.msl && (
                <Box
                    bg="rgba(255,255,255,1)"
                    w={{ base: '100%', md: '100%' }}
                    borderRadius="20px"
                    fontFamily="Open Sans"
                    zIndex="1"
                    transition="all ease 300ms"
                    boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                    _hover={{
                        boxShadow: '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                    }}
                    marginTop="25px"
                    marginBottom="10px"
                    overflow="hidden"
                >
                    <Flex
                        bg="#35ADD3"
                        backgroundImage="linear-gradient(345deg, #5CCFF1 0%, #018AB0 80%)"
                        filter={{
                            base: isMobileOnly && 'saturate(1.3)',
                            md: 'saturate(1)',
                        }}
                        w={{ base: '100%', md: '100%' }}
                        borderTopRadius="20px"
                        minHeight="30px"
                        border="none"
                        fontFamily="Open Sans"
                        zIndex="1"
                    >
                        <Flex
                            direction="row"
                            justifyContent="flex-start"
                            width="100%"
                            py="8px"
                            pl="21px"
                            pr="24px"
                        >
                            <Box>
                                <MslIcon
                                    width="20px"
                                    height="20px"
                                    color="white"
                                />
                            </Box>

                            <Box my="auto" paddingLeft="12px">
                                <Text
                                    textAlign="left"
                                    color="white"
                                    fontSize={adjustHeaderFontSize}
                                    fontWeight="bold"
                                    lineHeight="1.1"
                                >
                                    MEAN SEA LEVEL
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>

                    <Box>
                        <Accordion
                            border="none"
                            allowToggle
                            allowMultiple
                            index={expandedDropdowns}
                            onChange={(expandedIndex) => {
                                setExpandedDropdowns(expandedIndex)
                            }}
                        >
                            {msl && msl.length > 0 ? (
                                <AccordionItem
                                    borderStyle="none"
                                    index="1"
                                    boxShadow="0px -5px 15px 0px rgba(0,0,0,0.04)"
                                >
                                    {({ isExpanded }) => (
                                        <>
                                            <AccordionButton
                                                sx={{
                                                    clipPath:
                                                        'inset(0px 0px -50px 0px)',
                                                }}
                                                pl="1.3rem"
                                                height="50px"
                                                pr={5}
                                                py={3}
                                                _hover={{
                                                    background: 'light.30',

                                                    cursor: 'pointer',
                                                }}
                                                color={
                                                    !isExpanded
                                                        ? '#26aed7'
                                                        : '#26aed7'
                                                }
                                                background={
                                                    isExpanded
                                                        ? 'light.25'
                                                        : 'white'
                                                }
                                                boxShadow={
                                                    isExpanded &&
                                                    '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                }
                                                _focus={{
                                                    boxShadow: isExpanded
                                                        ? '0px 5px 10px 0px rgba(0,0,0,0.09)'
                                                        : 'none',
                                                }}
                                                opacity="1"
                                                borderBottomRadius={
                                                    isExpanded ? '0px' : '20px'
                                                }
                                                transition="boxShadow all 800ms"
                                            >
                                                <Box
                                                    fontSize={
                                                        adjustLabelFontSize
                                                    }
                                                    lineHeight="1.2"
                                                    fontWeight={
                                                        isExpanded
                                                            ? '600'
                                                            : '500'
                                                    }
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    flex="1"
                                                    textAlign="left"
                                                    paddingLeft="1"
                                                >
                                                    Mean Sea Level Charts
                                                </Box>

                                                <Flex>
                                                    {isExpanded ? (
                                                        <AccordionIcon transform="rotate(0deg)" />
                                                    ) : (
                                                        <AccordionIcon transform="rotate(-90deg)" />
                                                    )}
                                                </Flex>
                                            </AccordionButton>

                                            <AccordionPanel
                                                pt={0}
                                                pb={6}
                                                pl="1.5rem"
                                                pr="1.5rem"
                                            >
                                                <Portal
                                                    containerRef={
                                                        fullscreen
                                                            ? fullscreenRef
                                                            : scrollRef
                                                    }
                                                >
                                                    <Flex
                                                        flexDirection="column"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        height="100%"
                                                    >
                                                        {withinPeriodTo && (
                                                            <Flex
                                                                {...headerSwipe}
                                                                flexDirection="row"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                width="100%"
                                                                pt={
                                                                    fullscreen
                                                                        ? '10px'
                                                                        : '25px'
                                                                }
                                                                px={
                                                                    fullscreen
                                                                        ? '20px'
                                                                        : '0px'
                                                                }
                                                                pb="10px"
                                                                zIndex="2"
                                                                boxShadow={
                                                                    fullscreen &&
                                                                    'rgba(0, 0, 0, 0.15) 0px 0px 15px -1px'
                                                                }
                                                                borderBottom={
                                                                    fullscreen &&
                                                                    '1px solid #E2E8F0'
                                                                }
                                                                background={
                                                                    fullscreen &&
                                                                    'white'
                                                                }
                                                            >
                                                                <Flex
                                                                    flexDirection="row"
                                                                    width="100%"
                                                                    alignItems="center"
                                                                >
                                                                    <Flex
                                                                        className="issuedTime"
                                                                        flexDirection="row"
                                                                        alignItems="center"
                                                                        ml={{
                                                                            base: '5px',
                                                                            lg: fullscreen
                                                                                ? '5px'
                                                                                : '0',
                                                                        }}
                                                                        pr={{
                                                                            base: '10px',
                                                                            xs: '20px',
                                                                            xl: fullscreen
                                                                                ? '50px'
                                                                                : '20px',
                                                                            '2xl': '30px',
                                                                        }}
                                                                        minWidth={{
                                                                            base: fullscreen
                                                                                ? '200px'
                                                                                : '230px',
                                                                            xs: '230px',
                                                                            xl: '260px',
                                                                        }}
                                                                        width={
                                                                            fullscreen
                                                                                ? 'auto'
                                                                                : '100%'
                                                                        }
                                                                    >
                                                                        <Text
                                                                            whiteSpace="nowrap"
                                                                            paddingStart="0"
                                                                            paddingEnd="2"
                                                                            fontWeight="bold"
                                                                            width={calculateLabelSize(
                                                                                70
                                                                            )}
                                                                            fontSize={
                                                                                adjustDateFontSize
                                                                            }
                                                                            lineHeight="1.3"
                                                                        >
                                                                            Valid
                                                                            at:
                                                                        </Text>
                                                                        {msl.map(
                                                                            (
                                                                                validTime,
                                                                                i
                                                                            ) => (
                                                                                <Text
                                                                                    whiteSpace="nowrap"
                                                                                    key={`MslTime${validTime.validat}`}
                                                                                    display={
                                                                                        mslTime ===
                                                                                        validTime.validat
                                                                                            ? 'block'
                                                                                            : 'none'
                                                                                    }
                                                                                    paddingStart="0"
                                                                                    paddingEnd="0"
                                                                                    fontWeight="400"
                                                                                    py="0px"
                                                                                    fontSize={
                                                                                        adjustDateFontSize
                                                                                    }
                                                                                    lineHeight="1.3"
                                                                                    borderColor="transparent"
                                                                                >
                                                                                    {validTime?.validat &&
                                                                                        formatDateTime(
                                                                                            validTime.validat,
                                                                                            timeZone
                                                                                        )}
                                                                                </Text>
                                                                            )
                                                                        )}
                                                                    </Flex>

                                                                    <Portal
                                                                        style={{
                                                                            height: '100%',
                                                                        }}
                                                                        {...portalLevels}
                                                                    >
                                                                        <Flex
                                                                            width="100%"
                                                                            justifyContent={{
                                                                                base: 'center',
                                                                                xl: fullscreen
                                                                                    ? 'flex-start'
                                                                                    : 'center',
                                                                                '2xl': 'flex-start',
                                                                            }}
                                                                            alignItems="center"
                                                                            mt={{
                                                                                base: '20px',
                                                                                xl: fullscreen
                                                                                    ? '0px'
                                                                                    : '10px',
                                                                                '2xl': '0',
                                                                            }}
                                                                            mb={{
                                                                                base: '20px',
                                                                                xl: fullscreen
                                                                                    ? '0px'
                                                                                    : '20px',
                                                                                '2xl': '0px',
                                                                            }}
                                                                            pr={{
                                                                                base: '0',
                                                                                xl: fullscreen
                                                                                    ? '30px'
                                                                                    : '0',
                                                                                '2xl': '30px',
                                                                            }}
                                                                        >
                                                                            <ButtonGroup
                                                                                borderRadius="20px"
                                                                                px="2px"
                                                                                overflow="hidden"
                                                                                bg="light.20"
                                                                                boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                            >
                                                                                {msl
                                                                                    .filter(
                                                                                        (
                                                                                            chart
                                                                                        ) =>
                                                                                            parseISO(
                                                                                                chart.validat
                                                                                            ) <=
                                                                                            periodTo
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            time,
                                                                                            i
                                                                                        ) => (
                                                                                            <Button // MSL TIME
                                                                                                key={`MslBriefing${time.validat}`}
                                                                                                bg="transparent"
                                                                                                variant="outline"
                                                                                                fontSize="0.9rem"
                                                                                                fontWeight="600"
                                                                                                height={{
                                                                                                    base: '32px',
                                                                                                    md: '28px',
                                                                                                }}
                                                                                                width={{
                                                                                                    base:
                                                                                                        mslTime ===
                                                                                                        time.validat
                                                                                                            ? '76px'
                                                                                                            : '60px',
                                                                                                    xs: fiveTimeSelections
                                                                                                        ? mslTime ===
                                                                                                          time.validat
                                                                                                            ? '76px'
                                                                                                            : '60px'
                                                                                                        : '82px',
                                                                                                    sm: '88px',
                                                                                                    md:
                                                                                                        mslTime ===
                                                                                                            time.validat ||
                                                                                                        fullscreen
                                                                                                            ? '82px'
                                                                                                            : '60px',
                                                                                                    lg: '88px',
                                                                                                }}
                                                                                                transition="all ease 500ms"
                                                                                                marginInlineStart="0px !important"
                                                                                                border="none"
                                                                                                justifyContent="center"
                                                                                                alignItems="center"
                                                                                                paddingLeft="2px"
                                                                                                paddingRight="2px"
                                                                                                py="2px"
                                                                                                {...noFocus}
                                                                                                onClick={() => {
                                                                                                    setMslTime(
                                                                                                        time.validat
                                                                                                    )
                                                                                                    setMslTimePosition(
                                                                                                        i
                                                                                                    )
                                                                                                }}
                                                                                                zIndex="1"
                                                                                                _active={{
                                                                                                    bg: 'none',
                                                                                                }}
                                                                                                _focus={{
                                                                                                    bg: 'none',
                                                                                                }}
                                                                                                _hover={{
                                                                                                    bg: 'none',
                                                                                                    opacity:
                                                                                                        '1',
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    zIndex="1"
                                                                                                    paddingLeft="0px"
                                                                                                    lineHeight="1.3"
                                                                                                    textTransform="uppercase"
                                                                                                    fontSize="0.7rem"
                                                                                                    color={
                                                                                                        mslTime ===
                                                                                                        time.validat
                                                                                                            ? 'light.200'
                                                                                                            : 'light.100'
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        mslTime ===
                                                                                                        time.validat
                                                                                                            ? '700'
                                                                                                            : '600'
                                                                                                    }
                                                                                                    opacity={
                                                                                                        mslTime ===
                                                                                                        time.validat
                                                                                                            ? '1'
                                                                                                            : '0.6'
                                                                                                    }
                                                                                                    _active={{
                                                                                                        color: 'light.200',
                                                                                                        fontWeight:
                                                                                                            '700',
                                                                                                        opacity:
                                                                                                            '1',
                                                                                                    }}
                                                                                                    _focus={{
                                                                                                        color: 'light.200',
                                                                                                        fontWeight:
                                                                                                            '700',
                                                                                                        opacity:
                                                                                                            '1',
                                                                                                    }}
                                                                                                    _selected={{
                                                                                                        color: 'light.200',
                                                                                                        fontWeight:
                                                                                                            '700',
                                                                                                        opacity:
                                                                                                            '1',
                                                                                                    }}
                                                                                                    transition="opacity 600ms, fontWeight ease-out 500ms"
                                                                                                >
                                                                                                    {timeZoneAddition
                                                                                                        ? time?.validat &&
                                                                                                          formatTimeOnly(
                                                                                                              time.validat,
                                                                                                              timeZone
                                                                                                          )
                                                                                                        : time?.validat &&
                                                                                                          formatTimeAndTimezone(
                                                                                                              time.validat,
                                                                                                              timeZone
                                                                                                          )}
                                                                                                </Text>
                                                                                                {timeZoneAddition && (
                                                                                                    <Text
                                                                                                        ml="3px"
                                                                                                        zIndex="1"
                                                                                                        paddingLeft="0px"
                                                                                                        lineHeight="1.3"
                                                                                                        textTransform="uppercase"
                                                                                                        fontSize={{
                                                                                                            base:
                                                                                                                mslTime ===
                                                                                                                time.validat
                                                                                                                    ? '0.7rem'
                                                                                                                    : '0rem',
                                                                                                            xs: fiveTimeSelections
                                                                                                                ? mslTime ===
                                                                                                                  time.validat
                                                                                                                    ? '0.7rem'
                                                                                                                    : '0rem'
                                                                                                                : '0.7rem',
                                                                                                            sm: '0.7rem',
                                                                                                            md:
                                                                                                                mslTime ===
                                                                                                                    time.validat ||
                                                                                                                fullscreen
                                                                                                                    ? '0.7rem'
                                                                                                                    : '0rem',
                                                                                                            lg: '0.7rem',
                                                                                                        }}
                                                                                                        color={
                                                                                                            mslTime ===
                                                                                                            time.validat
                                                                                                                ? 'light.200'
                                                                                                                : 'light.100'
                                                                                                        }
                                                                                                        fontWeight={
                                                                                                            mslTime ===
                                                                                                            time.validat
                                                                                                                ? '700'
                                                                                                                : '600'
                                                                                                        }
                                                                                                        opacity={{
                                                                                                            base:
                                                                                                                mslTime ===
                                                                                                                time.validat
                                                                                                                    ? '1'
                                                                                                                    : '0',
                                                                                                            xs: fiveTimeSelections
                                                                                                                ? mslTime ===
                                                                                                                  time.validat
                                                                                                                    ? '1'
                                                                                                                    : '0'
                                                                                                                : '0.6',
                                                                                                            sm:
                                                                                                                mslTime ===
                                                                                                                time.validat
                                                                                                                    ? '1'
                                                                                                                    : '0.6',
                                                                                                            md:
                                                                                                                mslTime ===
                                                                                                                time.validat
                                                                                                                    ? '1'
                                                                                                                    : fullscreen &&
                                                                                                                      mslTime !==
                                                                                                                          time.validat
                                                                                                                    ? '0.6'
                                                                                                                    : '0',
                                                                                                            lg:
                                                                                                                mslTime ===
                                                                                                                time.validat
                                                                                                                    ? '1'
                                                                                                                    : '0.6',
                                                                                                        }}
                                                                                                        visibility={{
                                                                                                            base:
                                                                                                                mslTime ===
                                                                                                                time.validat
                                                                                                                    ? 'visible'
                                                                                                                    : 'hidden',
                                                                                                            xs: fiveTimeSelections
                                                                                                                ? mslTime ===
                                                                                                                  time.validat
                                                                                                                    ? 'visible'
                                                                                                                    : 'hidden'
                                                                                                                : 'visible',
                                                                                                            sm: 'visible',
                                                                                                            md:
                                                                                                                mslTime ===
                                                                                                                    time.validat ||
                                                                                                                fullscreen
                                                                                                                    ? 'visible'
                                                                                                                    : 'hidden',
                                                                                                            lg: 'visible',
                                                                                                        }}
                                                                                                        width={{
                                                                                                            base:
                                                                                                                mslTime ===
                                                                                                                time.validat
                                                                                                                    ? 'auto'
                                                                                                                    : '0px',
                                                                                                            xs: fiveTimeSelections
                                                                                                                ? mslTime ===
                                                                                                                  time.validat
                                                                                                                    ? 'auto'
                                                                                                                    : '0px'
                                                                                                                : 'auto',
                                                                                                            sm: 'auto',
                                                                                                            md:
                                                                                                                mslTime ===
                                                                                                                    time.validat ||
                                                                                                                fullscreen
                                                                                                                    ? 'auto'
                                                                                                                    : '0px',
                                                                                                            lg: 'auto',
                                                                                                        }}
                                                                                                        _active={{
                                                                                                            color: 'light.200',
                                                                                                            fontWeight:
                                                                                                                '700',
                                                                                                            opacity:
                                                                                                                '1',
                                                                                                        }}
                                                                                                        _focus={{
                                                                                                            color: 'light.200',
                                                                                                            fontWeight:
                                                                                                                '700',
                                                                                                            opacity:
                                                                                                                '1',
                                                                                                        }}
                                                                                                        _selected={{
                                                                                                            color: 'light.200',
                                                                                                            fontWeight:
                                                                                                                '700',
                                                                                                            opacity:
                                                                                                                '1',
                                                                                                        }}
                                                                                                        transition="all 500ms"
                                                                                                    >
                                                                                                        {time?.validat &&
                                                                                                            timeZoneAbbreviation(
                                                                                                                time.validat,
                                                                                                                timeZone
                                                                                                            )}
                                                                                                    </Text>
                                                                                                )}
                                                                                            </Button>
                                                                                        )
                                                                                    )}

                                                                                <Flex // BUTTON INDICATOR
                                                                                    position="absolute"
                                                                                    marginInlineStart="0px !important"
                                                                                    mt="2px"
                                                                                    ml="0px"
                                                                                    // px="44px"
                                                                                    height={{
                                                                                        base: '28px',
                                                                                        md: '24px',
                                                                                    }}
                                                                                    width={{
                                                                                        base: '76px',
                                                                                        xs: fiveTimeSelections
                                                                                            ? '76px'
                                                                                            : '82px',
                                                                                        sm: '88px',
                                                                                        md: '82px',
                                                                                        lg: '88px',
                                                                                    }}
                                                                                    background="white"
                                                                                    borderRadius="20px"
                                                                                    bg="light.10"
                                                                                    boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                                                    transform={{
                                                                                        base: `translateX(calc(${mslTimePosition}00% - ${
                                                                                            mslTimePosition *
                                                                                            16
                                                                                        }px))`,
                                                                                        xs: fiveTimeSelections
                                                                                            ? `translateX(calc(${mslTimePosition}00% - ${
                                                                                                  mslTimePosition *
                                                                                                  16
                                                                                              }px))`
                                                                                            : `translateX(${mslTimePosition}00%)`,
                                                                                        sm: `translateX(${mslTimePosition}00%)`,
                                                                                        md: fullscreen
                                                                                            ? `translateX(${mslTimePosition}00%)`
                                                                                            : `translateX(calc(${mslTimePosition}00% - ${
                                                                                                  mslTimePosition *
                                                                                                  22
                                                                                              }px))`,
                                                                                        lg: `translateX(${mslTimePosition}00%)`,
                                                                                    }}
                                                                                    transition="transform 500ms"
                                                                                ></Flex>
                                                                            </ButtonGroup>
                                                                        </Flex>
                                                                    </Portal>

                                                                    <Box
                                                                        mx="auto"
                                                                        ref={
                                                                            desktop
                                                                        }
                                                                    ></Box>
                                                                </Flex>

                                                                {!hideOnMobile && (
                                                                    <ZoomSlider
                                                                        scale={
                                                                            scale
                                                                        }
                                                                        xy={xy}
                                                                        setScale={
                                                                            setScale
                                                                        }
                                                                        setXy={
                                                                            setXy
                                                                        }
                                                                        maxScale={
                                                                            3
                                                                        }
                                                                        fullscreen={
                                                                            fullscreen
                                                                        }
                                                                    />
                                                                )}
                                                                <IconButton
                                                                    {...noFocus}
                                                                    display={{
                                                                        base: fullscreen
                                                                            ? 'none'
                                                                            : 'flex',
                                                                        md: 'flex',
                                                                    }}
                                                                    color="light.100"
                                                                    width={{
                                                                        base: '36px',
                                                                        md: '36px',
                                                                    }}
                                                                    minWidth={{
                                                                        base: '36px',
                                                                        md: '36px',
                                                                    }}
                                                                    paddingInlineStart="0"
                                                                    paddingInlineEnd="0"
                                                                    height={{
                                                                        base: '36px',
                                                                        md: '36px',
                                                                    }}
                                                                    opacity={{
                                                                        base: '0.9',
                                                                        md: '0.8',
                                                                    }}
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    borderRadius="10px"
                                                                    _hover={{
                                                                        opacity:
                                                                            '1',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    _active={{
                                                                        background:
                                                                            'none',
                                                                    }}
                                                                    icon={
                                                                        fullscreen ? (
                                                                            <CgClose
                                                                                fontSize="1.6rem"
                                                                                color="light.100"
                                                                            />
                                                                        ) : (
                                                                            <AiOutlineFullscreen
                                                                                fontSize="1.4rem"
                                                                                color="light.100"
                                                                            />
                                                                        )
                                                                    }
                                                                    onClick={() => {
                                                                        if (
                                                                            fullscreen
                                                                        ) {
                                                                            setHandleReset(
                                                                                true
                                                                            )
                                                                        } else {
                                                                            setFullscreenHandler(
                                                                                'msl'
                                                                            )
                                                                        }
                                                                    }}
                                                                    variant="outline"
                                                                    border="none"
                                                                    ml="15px"
                                                                />
                                                            </Flex>
                                                        )}

                                                        {!fullscreen && (
                                                            <Divider
                                                                height="0px !important"
                                                                borderColor="#e5e5e5"
                                                                width="100%"
                                                                mx="auto"
                                                                mb="0px"
                                                            />
                                                        )}
                                                        <Flex
                                                            flexDirection={{
                                                                base: 'column',
                                                                md: 'column',
                                                            }}
                                                            pt={{
                                                                lg: fullscreen
                                                                    ? '0px'
                                                                    : '10px',
                                                            }}
                                                            pb={{
                                                                base: '0px',
                                                                md: '15px',
                                                            }}
                                                            width="100%"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            height="100%"
                                                        >
                                                            <Box
                                                                width="100%"
                                                                minHeight={{
                                                                    base: '74px',
                                                                    lg: '0px',
                                                                }}
                                                                background="white"
                                                                zIndex="1"
                                                                mt={
                                                                    fullscreen && {
                                                                        base: minimiseOnZoom
                                                                            ? '-75px'
                                                                            : '0px',
                                                                        xl: '0px',
                                                                    }
                                                                }
                                                                transition="all ease 300ms"
                                                                borderBottom={{
                                                                    base:
                                                                        fullscreen &&
                                                                        '1px solid #E2E8F0',
                                                                    xl: 'none',
                                                                }}
                                                                ref={mobile}
                                                            ></Box>

                                                            <Box
                                                                mt={
                                                                    fullscreen && {
                                                                        base: minimiseOnZoom
                                                                            ? '75px'
                                                                            : '0px',
                                                                        xl: '0px',
                                                                    }
                                                                }
                                                                transition="all ease 300ms"
                                                            >
                                                                {msl.map(
                                                                    (
                                                                        mslError
                                                                    ) => (
                                                                        <Flex
                                                                            key={`MslCharts${mslError.validat}`}
                                                                        >
                                                                            {(showOutdatedErrors ||
                                                                                mslError.outofdate) &&
                                                                                mslError.validat ===
                                                                                    mslTime && (
                                                                                    <Flex
                                                                                        ml="-3px"
                                                                                        mb="20px"
                                                                                        justifyContent="center"
                                                                                    >
                                                                                        <ErrorText>
                                                                                            There
                                                                                            has
                                                                                            been
                                                                                            an
                                                                                            issue
                                                                                            with
                                                                                            the
                                                                                            connection
                                                                                            between
                                                                                            PreFlight
                                                                                            and
                                                                                            MetService.
                                                                                            This{' '}
                                                                                            <strong>
                                                                                                Mean
                                                                                                Sea
                                                                                                Level
                                                                                                Chart
                                                                                            </strong>{' '}
                                                                                            was
                                                                                            last
                                                                                            validated
                                                                                            with
                                                                                            MetService
                                                                                            more
                                                                                            than{' '}
                                                                                            <strong>
                                                                                                {formatDistanceToNow(
                                                                                                    parseISO(
                                                                                                        mslError.updated
                                                                                                    ),
                                                                                                    {
                                                                                                        addSuffix: true,
                                                                                                    }
                                                                                                )}
                                                                                            </strong>
                                                                                            .
                                                                                            <br />
                                                                                            There
                                                                                            is
                                                                                            a
                                                                                            high
                                                                                            likelihood
                                                                                            that
                                                                                            the
                                                                                            latest
                                                                                            SIGWX
                                                                                            information
                                                                                            is
                                                                                            not
                                                                                            available
                                                                                            in
                                                                                            PreFlight.
                                                                                            Check
                                                                                            back
                                                                                            again
                                                                                            soon,
                                                                                            or
                                                                                            alternatively
                                                                                            visit{' '}
                                                                                            <a
                                                                                                style={{
                                                                                                    fontWeight:
                                                                                                        '500',
                                                                                                    textDecoration:
                                                                                                        'underline',
                                                                                                }}
                                                                                                href="https://ifis.airways.co.nz/"
                                                                                            >
                                                                                                IFIS
                                                                                            </a>

                                                                                            .
                                                                                        </ErrorText>
                                                                                    </Flex>
                                                                                )}
                                                                        </Flex>
                                                                    )
                                                                )}
                                                            </Box>

                                                            <Flex // IMAGE CONTAINER
                                                                direction="column"
                                                                position={{
                                                                    base: 'relative',
                                                                }}
                                                                w={{
                                                                    base: '100%',
                                                                }}
                                                                h="100%"
                                                                pt={{
                                                                    base: fullscreen
                                                                        ? '15px'
                                                                        : '0',
                                                                    lg: fullscreen
                                                                        ? '25px'
                                                                        : '0',
                                                                    '2xl': fullscreen
                                                                        ? '25px'
                                                                        : '15px',
                                                                }}
                                                                px="10px"
                                                                pb={{
                                                                    base: '5px',
                                                                    md: '10px',
                                                                }}
                                                                onClick={
                                                                    fullscreen
                                                                        ? () => {}
                                                                        : () =>
                                                                              setFullscreenHandler(
                                                                                  'msl'
                                                                              )
                                                                }
                                                                cursor={
                                                                    fullscreen
                                                                        ? 'grab'
                                                                        : 'zoom-in'
                                                                }
                                                            >
                                                                <PinchDrag
                                                                    scale={
                                                                        scale
                                                                    }
                                                                    setScale={
                                                                        setScale
                                                                    }
                                                                    xy={xy}
                                                                    setXy={
                                                                        setXy
                                                                    }
                                                                    gestureDisabled={
                                                                        !fullscreen
                                                                    }
                                                                    maxZoom={3}
                                                                    maxZoomNewScale={
                                                                        2.999
                                                                    }
                                                                    fullscreenComponent={
                                                                        fullscreen
                                                                    }
                                                                >
                                                                    {msl
                                                                        .filter(
                                                                            (
                                                                                chart
                                                                            ) =>
                                                                                parseISO(
                                                                                    chart.validat
                                                                                ) <=
                                                                                periodTo
                                                                        )
                                                                        .map(
                                                                            (
                                                                                image,
                                                                                i
                                                                            ) => (
                                                                                <Image
                                                                                    key={`MslImage${image.validat}`}
                                                                                    mx="auto"
                                                                                    px={{
                                                                                        base: '0px',
                                                                                        md: '20px',
                                                                                    }}
                                                                                    maxWidth={
                                                                                        pageScaleWidth
                                                                                    }
                                                                                    visibility={
                                                                                        mslTime ===
                                                                                        image.validat
                                                                                            ? 'visible'
                                                                                            : 'hidden'
                                                                                    }
                                                                                    height={
                                                                                        mslTime ===
                                                                                        image.validat
                                                                                            ? 'auto'
                                                                                            : '0px'
                                                                                    }
                                                                                    opacity={
                                                                                        mslTime ===
                                                                                        image.validat
                                                                                            ? '1'
                                                                                            : '0'
                                                                                    }
                                                                                    transition="all ease-in-out 0ms"
                                                                                    src={`${window.location.origin}/metproxy?url=${image.url}`}
                                                                                    alt="MEAN SEA LEVEL CHART UNAVAILABLE"
                                                                                />
                                                                            )
                                                                        )}
                                                                </PinchDrag>
                                                            </Flex>
                                                            {!withinPeriodTo ? (
                                                                <Text
                                                                    paddingTop="15px"
                                                                    paddingBottom="15px"
                                                                    fontSize="0.8rem"
                                                                    color={
                                                                        'light.500'
                                                                    }
                                                                    width="100%"
                                                                    textAlign="center"
                                                                >
                                                                    There are no
                                                                    MSL Charts
                                                                    valid within
                                                                    the time
                                                                    period
                                                                    selected.
                                                                    Please
                                                                    increase the{' '}
                                                                    <span
                                                                        style={{
                                                                            fontWeight:
                                                                                '700',
                                                                        }}
                                                                    >
                                                                        Period
                                                                        To
                                                                    </span>{' '}
                                                                    slider.
                                                                </Text>
                                                            ) : (
                                                                <Text
                                                                    position={
                                                                        fullscreen
                                                                            ? 'absolute'
                                                                            : 'relative'
                                                                    }
                                                                    bottom={
                                                                        fullscreen
                                                                            ? '25px'
                                                                            : 'unset'
                                                                    }
                                                                    paddingTop="15px"
                                                                    fontSize="11px"
                                                                    color={
                                                                        fullscreen
                                                                            ? 'light.100'
                                                                            : 'light.300'
                                                                    }
                                                                >
                                                                    Time
                                                                    references
                                                                    on chart are
                                                                    issued in
                                                                    UTC
                                                                </Text>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                </Portal>
                                                <Box ref={scrollRef}></Box>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            ) : (
                                <Flex
                                    fontFamily="Open Sans"
                                    px="6"
                                    py="4"
                                    minHeight="50px"
                                    flexDirection="column"
                                >
                                    <Text
                                        fontSize="0.8rem"
                                        lineHeight="1.2"
                                        fontWeight="500"
                                        fontFamily="'Open Sans', sans-serif"
                                        flex="2"
                                        color="light.400"
                                    >
                                        Mean Sea Level Chart information is not
                                        available at this time
                                    </Text>
                                </Flex>
                            )}
                        </Accordion>
                    </Box>
                </Box>
            )}
        </div>
    )
}
