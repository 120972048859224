import React from 'react'
import { useRecoilValue } from 'recoil'

import { dataModeState, layerSelectionState } from '../../../globalState'

import BriefingAreasLayer from './BriefingAreas'

export default function BriefLayers() {
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)

    if (dataMode === 'brief') {
        return (
            <>{layerSelection.brief.briefingareas && <BriefingAreasLayer />}</>
        )
    } else {
        return null
    }
}
