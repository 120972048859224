import React from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer } from 'react-mapbox-gl'

import { VectorLayerRefreshInfo } from '../../../globalState'

export default function VrpLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0,
        9,
        0,
        10,
        1,
    ]

    return (
        <>
            <GeoJSONLayer
                id="vrp"
                data={layerData.vrp.dataURL}
                before="aerodromes-symbol"
                symbolLayout={{
                    'icon-image': 'vrp',
                    'text-field': ['get', 'name'],
                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-offset': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        ['literal', [3, -0.7]],
                        10,
                        ['literal', [3.2, -1]],
                        16,
                        ['literal', [4, -1.5]],
                    ],
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.2,
                        10,
                        0.5,
                        16,
                        0.7,
                    ],
                    'icon-anchor': 'bottom-left',
                    'text-anchor': 'bottom-left',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        7,
                        3,
                        10,
                        10,
                        11,
                        10,
                    ],
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                }}
                symbolPaint={{
                    'icon-opacity': zoomLevelDefinition,
                    'text-opacity': zoomLevelDefinition,
                    'text-color': '#0066BC',
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 0.8,
                }}
                layerOptions={{
                    minzoom: 9,
                }}
            />
        </>
    )
}
