import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { Flex, Button, Box, Text, VStack } from '@chakra-ui/react'
import { useUserMetaData } from '../../../../auth/hooks'
import { parseISO, formatDistanceToNow } from 'date-fns'
import formatDateTime from '../../../../util/dateFormatter'
import { isMobileOnly, isIOS } from 'react-device-detect'
import { userProfileState, timeZoneState } from '../../../../globalState'

import AwsPresentWeather from './components/PresentWeather'
import AwsTempAndDewpoint from './components/TempAndDewpoint'
import AwsQnh from './components/Qnh'
import AwsCloud from './components/Cloud'
import AwsVisibility from './components/Visibility'
import AwsWind from './components/Wind'

export default function InfoContent(props) {
    const { awsData, awsIssuedTime, awsOutOfDateIssueTime } = props
    const [windRoseMagVar, setWindRoseMagVar] = useState(false)
    const timeZone = useRecoilValue(timeZoneState)
    const profileData = useRecoilValue(userProfileState)
    const userMetaData = useUserMetaData()
    const [infotabGraphicsDisabled, setInfotabGraphicsDisabled] =
        useState(false)

    const disableGrpahics =
        (profileData?.profileSettings?.disable_infotab_graphics !== undefined
            ? profileData.profileSettings.disable_infotab_graphics
            : userMetaData?.profile_settings?.disable_infotab_graphics) || false

    useEffect(() => {
        if (disableGrpahics) {
            setInfotabGraphicsDisabled(true)
        } else if (!disableGrpahics) {
            setInfotabGraphicsDisabled(false)
        }
    }, [disableGrpahics])

    const windMagAvailable = !!(
        awsData?.wnddirm_10mnavg ||
        awsData?.wnddirm_01mnavg ||
        awsData?.wnddirm_01hravg
    )

    // Weather
    const winddir =
        awsData &&
        (windRoseMagVar && windMagAvailable
            ? awsData.wnddirm_10mnavg // DEGREES MAGNETIC
                ? awsData.wnddirm_10mnavg // 10 min average
                : awsData.wnddirm_01mnavg
                ? awsData.wnddirm_01mnavg // else 1 min average
                : awsData.wnddirm_01hravg
                ? awsData.wnddirm_01hravg // else 1 hour average
                : null
            : awsData.winddir_10mnavg // DEGREES TRUE
            ? awsData.winddir_10mnavg // 10 min average
            : awsData.winddir_01mnavg
            ? awsData.winddir_01mnavg // else 1 min average
            : awsData.winddir_01hravg
            ? awsData.winddir_01hravg // else 1 hour average
            : null)

    const winddirmax =
        awsData &&
        (windRoseMagVar
            ? awsData.wndcwm__10mnmax // DEGREES MAGNETIC
                ? awsData.wndcwm__10mnmax // 10 min average
                : awsData.wndcwm__01mnmax
                ? awsData.wndcwm__01mnmax // else 1 min average
                : awsData.wndcwm__01hrmax
                ? awsData.wndcwm__01hrmax // else 1 hour average
                : null
            : awsData.windcw__10mnmax // DEGREES TRUE
            ? awsData.windcw__10mnmax // 10 min average
            : awsData.windcw__01mnmax
            ? awsData.windcw__01mnmax // else 1 min average
            : awsData.windcw__01hrmax
            ? awsData.windcw__01hrmax // else 1 hour average
            : null)

    const winddirmin =
        awsData &&
        (windRoseMagVar
            ? awsData.wndccwm_10mnmax // DEGREES MAGNETIC
                ? awsData.wndccwm_10mnmax // 10 min average
                : awsData.wndccwm_01mnmax
                ? awsData.wndccwm_01mnmax // else 1 min average
                : awsData.wndccwm_01hrmax
                ? awsData.wndccwm_01hrmax // else 1 hour average
                : null
            : awsData.windccw_10mnmax // DEGREES TRUE
            ? awsData.windccw_10mnmax // 10 min average
            : awsData.windccw_01mnmax
            ? awsData.windccw_01mnmax // else 1 min average
            : awsData.windccw_01hrmax
            ? awsData.windccw_01hrmax // else 1 hour average
            : null)

    const windspeed =
        awsData &&
        (awsData.windspd_10mnavg
            ? awsData.windspd_10mnavg // 10 min average
            : awsData.windspd_01mnavg
            ? awsData.windspd_01mnavg // else 1 min average
            : awsData.windspd_01hravg
            ? awsData.windspd_01hravg // else 1 hour average
            : null)

    const windgust =
        awsData &&
        (awsData && awsData.windgst_10mnmax
            ? awsData.windgst_10mnmax // 10 min average
            : awsData.windgst_01mnmax
            ? awsData.windgst_01mnmax // else 1 min average
            : awsData.windgst_01hrmax
            ? awsData.windgst_01hrmax // else 1 hour average
            : null)

    const windlull =
        awsData &&
        (awsData && awsData.windlul_10mnmin
            ? awsData.windlul_10mnmin // 10 min average
            : awsData.windlul_01mnmin
            ? awsData.windlul_01mnmin // else 1 min average
            : null)

    const temp =
        awsData &&
        (awsData.airtemp_01mnavg === 0
            ? 0
            : awsData && awsData.airtemp_01mnavg
            ? Math.round(awsData.airtemp_01mnavg) // 1 min average
            : null)

    const dewpoint =
        awsData &&
        (awsData.dewtemp_01mnavg === 0
            ? 0
            : awsData && awsData.dewtemp_01mnavg
            ? Math.round(awsData.dewtemp_01mnavg) // 1 min average
            : null)

    // Cloud
    const cloudLayers = []

    const cloudConversion = [
        {
            label: 'Overcast',
            amount: 8,
        },
        {
            label: 'Broken',
            amount: 7,
        },
        {
            label: 'Broken',
            amount: 6,
        },
        {
            label: 'Broken',
            amount: 5,
        },
        {
            label: 'Scattered',
            amount: 4,
        },
        {
            label: 'Scattered',
            amount: 3,
        },
        {
            label: 'Few',
            amount: 2,
        },
        {
            label: 'Few',
            amount: 1,
        },
        {
            label: 'Sky Clear',
            amount: 0,
        },
    ]

    // Cloud layer conversion
    const handleCloudLayer = (awsData, [amount, value]) => {
        if (awsData && awsData[amount] && awsData[value]) {
            const cloudAmount = awsData[amount]
            const cloudLayer = cloudConversion.find(
                (layer) => layer.amount === cloudAmount
            )
            if (cloudLayer) {
                cloudLayers.push({
                    amount: cloudLayer.label,
                    value: awsData[value],
                })
            }
        }
    }

    cloudLayers.length = 0
    let noCloudDetected = false
    // if there is a cloud layer
    handleCloudLayer(awsData, ['cld1amt_30mnavg', 'cld1hgt_30mnavg'])
    // // if there is a second layer
    handleCloudLayer(awsData, ['cld2amt_30mnavg', 'cld2hgt_30mnavg'])
    // // if there is a third layer
    handleCloudLayer(awsData, ['cld3amt_30mnavg', 'cld3hgt_30mnavg'])
    // // if there is a fourth layer
    handleCloudLayer(awsData, ['cld4amt_30mnavg', 'cld4hgt_30mnavg'])

    // If there is No Cloud Detected (NCD), then set cloudLayer with NCD just the once
    if (!noCloudDetected && awsData['cld1amt_30mnavg'] === 'NCD') {
        cloudLayers.push({
            amount: 'No Cloud Detected',
            value: null,
        })
        noCloudDetected = true
    }

    const presentWeather =
        awsData &&
        (awsData.prwxst1_10mnavg
            ? awsData.prwxst1_10mnavg // 10 min average (Station 1)
            : awsData.prwxst1_15mnavg
            ? awsData.prwxst1_15mnavg // else 15 min average (Station 1)
            : awsData.prwxst2_10mnavg
            ? awsData.prwxst2_10mnavg // 10 min average (Station 2)
            : awsData.prwxst2_15mnavg
            ? awsData.prwxst2_15mnavg // else 15 min average (Station 2)
            : awsData.prwxst3_10mnavg
            ? awsData.prwxst3_10mnavg // 10 min average (Station 3)
            : awsData.prwxst3_15mnavg
            ? awsData.prwxst3_15mnavg // else 15 min average (Station 3)
            : null)

    const visibilityData =
        awsData &&
        (awsData.visibil_10mnavg
            ? awsData.visibil_10mnavg // 10 min average
            : null)
    const visibility =
        visibilityData &&
        !visibilityData.startsWith('/') &&
        Number(visibilityData.slice(0, -3))

    const visibilityIsKM = // detect if vis is in kms
        visibilityData &&
        !visibilityData.startsWith('/') &&
        visibilityData.slice(-2) === 'km'

    const qnh =
        awsData &&
        (awsData.presqnh_01mnavg
            ? Math.round(awsData.presqnh_01mnavg) // 1 min average
            : null)

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    const filteredData = Object.fromEntries(
        Object.entries(awsData).filter(([key]) => key !== 'obs_timestamp')
    )
    const dataArray = Object.keys(filteredData)
    const hasData =
        dataArray &&
        dataArray.length > 0 &&
        (winddir || temp || dewpoint || visibility || qnh)

    useEffect(() => {
        if (!windMagAvailable) {
            setWindRoseMagVar(false)
        }
    }, [windMagAvailable, setWindRoseMagVar])

    // useEffect(() => {
    //     console.log('awsData: ', awsData)
    // }, [awsData])

    return (
        <>
            <Flex
                w="100%"
                flexDirection={{
                    base: 'column',
                    md: 'column',
                }}
                justifyContent="space-between"
                height="100%"
            >
                {awsData && hasData ? (
                    <Flex
                        direction="column"
                        borderRadius="20px"
                        width="100%"
                        pt="20px"
                        pl={{
                            base: '20px',
                            md: '20px',
                        }}
                        pr={{
                            base: '20px',
                            md: '20px',
                        }}
                        pb={{
                            base: '20px',
                            md: '20px',
                        }}
                    >
                        <Flex
                            flexDirection="row"
                            width="100%"
                            pt="0px"
                            pb="20px"
                            justifyContent="space-between"
                            alignItems={
                                windMagAvailable ? 'flex-end' : 'flex-start'
                            }
                        >
                            <Flex
                                mt={{
                                    base: '-3px',
                                    md: '-3px',
                                }}
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                flexDirection={{
                                    base: 'row',
                                    md: 'row',
                                }}
                                gap="3px"
                                fontSize={{
                                    base: '0.75rem',
                                    xs: '0.75rem',
                                    md: '0.65rem',
                                }}
                            >
                                <Text
                                    textAlign="center"
                                    color={
                                        // awsData && timePeriod === 'Night'
                                        //     ? 'light.10' :
                                        'gray.600'
                                    }
                                    lineHeight="1.4"
                                    mb="-2px"
                                    fontWeight="500"
                                    whiteSpace="pre-line"
                                    opacity="0.8"
                                    letterSpacing="0.2px"
                                    textTransform="uppercase"
                                >
                                    Observation time:
                                </Text>
                                <Flex
                                    flexDirection="column"
                                    pl="3px"
                                    height="26px"
                                    justifyContent="space-between"
                                >
                                    <Text
                                        opacity="0.8"
                                        letterSpacing="0.2px"
                                        textAlign="left"
                                        color={'gray.600'}
                                        lineHeight="1.4"
                                        mb="-2px"
                                        fontWeight="500"
                                        whiteSpace="pre-line"
                                        textTransform="uppercase"
                                    >
                                        {awsData?.obs_timestamp &&
                                            formatDateTime(
                                                awsData.obs_timestamp,
                                                timeZone
                                            )}
                                    </Text>
                                    {awsOutOfDateIssueTime !== 'WARNING' &&
                                        awsOutOfDateIssueTime !== 'CAUTION' && (
                                            <Text
                                                opacity="0.8"
                                                letterSpacing="0.2px"
                                                textAlign="left"
                                                color={
                                                    awsOutOfDateIssueTime ===
                                                    'WARNING'
                                                        ? 'red.500'
                                                        : awsOutOfDateIssueTime ===
                                                          'CAUTION'
                                                        ? '#ff8a23'
                                                        : 'gray.400'
                                                }
                                                lineHeight="1.4"
                                                mb="-2px"
                                                fontWeight="400"
                                                whiteSpace="pre-line"
                                            >
                                                {formatDistanceToNow(
                                                    parseISO(
                                                        awsData.obs_timestamp
                                                    ),
                                                    {
                                                        addSuffix: true,
                                                    }
                                                )}
                                            </Text>
                                        )}
                                </Flex>
                            </Flex>
                            {windMagAvailable ? (
                                <Button
                                    index={windRoseMagVar ? 1 : 0}
                                    background={'#eef1f5'}
                                    filter="contrast(102%) saturate(102%)"
                                    backdropFilter="blur(15px) contrast(102%) saturate(102%)"
                                    boxShadow="inset 0px 0px 3px -1px rgba(0,0,0,0.3)"
                                    fontSize="11px"
                                    fontWeight="600"
                                    height="22px"
                                    width="72px"
                                    style={{
                                        minWidth: '72px',
                                    }}
                                    justifyContent="initial"
                                    paddingLeft="2px"
                                    paddingRight="2px"
                                    py="2px"
                                    borderRadius="20px"
                                    border="none"
                                    {...noFocus}
                                    onClick={() => {
                                        setWindRoseMagVar(
                                            (prevState) => !prevState
                                        )
                                    }}
                                    _focus={{
                                        background: '#eaeef2',
                                    }}
                                    _active={{
                                        background: '#eaeef2',
                                    }}
                                    _hover={{
                                        background: 'gray.200',
                                    }}
                                >
                                    <Box
                                        zIndex="1"
                                        mt="0px"
                                        ml="0px"
                                        px="17px"
                                        height="18px"
                                        width="34px"
                                        borderRadius="20px"
                                        background={'white'}
                                        boxShadow={
                                            '0px 0px 3px -1px rgba(0,0,0,0.3)'
                                        }
                                        border="0px solid rgba(255,255,255,0.5)"
                                        borderColor="#E5EAF1"
                                        _hover={{
                                            background: 'white',
                                        }}
                                        transform={
                                            windRoseMagVar
                                                ? 'translateX(100%)'
                                                : 'translateX(0%)'
                                        }
                                        transition="transform 600ms"
                                    ></Box>
                                    <Flex
                                        justifyContent="space-evenly"
                                        marginLeft="-32px"
                                    >
                                        <Text
                                            zIndex="2"
                                            paddingLeft="7px"
                                            opacity={
                                                windRoseMagVar ? '0.6' : '1'
                                            }
                                            color="light.400"
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="opacity 600ms"
                                        >
                                            °T
                                        </Text>
                                        <Text
                                            zIndex="2"
                                            paddingLeft="22px"
                                            opacity={
                                                windRoseMagVar ? '1' : '0.6'
                                            }
                                            color="light.400"
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="opacity 600ms"
                                        >
                                            °M
                                        </Text>
                                    </Flex>
                                </Button>
                            ) : (
                                <Text
                                    mt="-4px"
                                    height="26px"
                                    fontSize={{
                                        base: '0.75rem',
                                        xs: '0.75rem',
                                        md: '0.65rem',
                                    }}
                                    letterSpacing="0.5px"
                                    fontWeight="600"
                                    zIndex="2"
                                    paddingLeft="5px"
                                    opacity="0.6"
                                    color="light.400"
                                    whiteSpace="pre"
                                    lineHeight="1.4"
                                >
                                    °T ONLY
                                </Text>
                            )}
                        </Flex>
                        {awsData && (
                            <Flex
                                flexDirection="column"
                                width="100%"
                                height="100%"
                                justifyContent={
                                    infotabGraphicsDisabled
                                        ? 'flex-start'
                                        : 'space-between'
                                }
                                background={infotabGraphicsDisabled && 'white'}
                                boxShadow={
                                    infotabGraphicsDisabled
                                        ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                                        : 'none'
                                }
                                filter={
                                    infotabGraphicsDisabled
                                        ? 'contrast(102%) saturate(102%)'
                                        : 'none'
                                }
                                borderRadius="20px"
                            >
                                {/* COMPONENTS */}
                                <VStack
                                    spacing={
                                        !infotabGraphicsDisabled && {
                                            base: '20px',
                                            md: '20px',
                                        }
                                    }
                                    pb={{
                                        base: '5px',
                                        md: '0',
                                    }}
                                    direction={'column'}
                                    alignItems="start"
                                    px={infotabGraphicsDisabled && '5px'}
                                >
                                    {winddir && ( // WIND
                                        <AwsWind
                                            winddir={winddir}
                                            winddirmax={winddirmax}
                                            winddirmin={winddirmin}
                                            windspeed={windspeed}
                                            windgust={windgust}
                                            windlull={windlull}
                                            windRoseMagVar={windRoseMagVar}
                                            infotabGraphicsDisabled={
                                                infotabGraphicsDisabled
                                            }
                                        />
                                    )}

                                    {visibility && ( // VISIBILITY
                                        <AwsVisibility
                                            visibility={visibility}
                                            visibilityIsKM={visibilityIsKM}
                                            infotabGraphicsDisabled={
                                                infotabGraphicsDisabled
                                            }
                                        />
                                    )}

                                    {cloudLayers && // CLOUD
                                        cloudLayers.length &&
                                        cloudLayers.length > 0 && (
                                            <AwsCloud
                                                cloudLayers={cloudLayers}
                                                infotabGraphicsDisabled={
                                                    infotabGraphicsDisabled
                                                }
                                            />
                                        )}

                                    <AwsTempAndDewpoint
                                        temp={temp}
                                        dewpoint={dewpoint}
                                        cloudLayers={cloudLayers}
                                        infotabGraphicsDisabled={
                                            infotabGraphicsDisabled
                                        }
                                    />

                                    {qnh && ( // QNH
                                        <AwsQnh
                                            qnh={qnh}
                                            presentWeather={presentWeather}
                                            infotabGraphicsDisabled={
                                                infotabGraphicsDisabled
                                            }
                                        />
                                    )}

                                    {presentWeather && // PRESENT WEATHER
                                        !presentWeather.startsWith('/') && (
                                            <AwsPresentWeather
                                                presentWeather={presentWeather}
                                                infotabGraphicsDisabled={
                                                    infotabGraphicsDisabled
                                                }
                                            />
                                        )}
                                </VStack>
                            </Flex>
                        )}
                    </Flex>
                ) : (
                    <Flex
                        mt="40px"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        gap="10px"
                        fontSize={{
                            base: '0.8rem',
                            xs: '0.8rem',
                            md: '0.8rem',
                        }}
                    >
                        <Text
                            textAlign="center"
                            color={'gray.500'}
                            lineHeight="1.4"
                            width="100%"
                            fontWeight="500"
                            whiteSpace="pre-line"
                            py="30px"
                        >
                            No weather information available
                        </Text>
                        {awsIssuedTime && (
                            <Flex
                                mb="-1px"
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection={{
                                    base: 'row',
                                    md: 'row',
                                }}
                                gap="3px"
                                textTransform="uppercase"
                                fontSize={{
                                    base: '0.75rem',
                                    xs: '0.75rem',
                                    md: '0.65rem',
                                }}
                            >
                                <Text
                                    textAlign="center"
                                    color={'gray.400'}
                                    lineHeight="1.4"
                                    mb="-2px"
                                    fontWeight="500"
                                    whiteSpace="pre-line"
                                    opacity="0.7"
                                    letterSpacing="0.5px"
                                >
                                    Data last received{' '}
                                </Text>
                                <Text
                                    opacity="0.7"
                                    letterSpacing="0.5px"
                                    textAlign="center"
                                    color={
                                        awsOutOfDateIssueTime === 'WARNING'
                                            ? 'red.500'
                                            : awsOutOfDateIssueTime ===
                                              'CAUTION'
                                            ? '#ff8a23'
                                            : 'gray.400'
                                    }
                                    lineHeight="1.4"
                                    mb="-2px"
                                    fontWeight={{
                                        base:
                                            awsOutOfDateIssueTime !== 'NONE'
                                                ? '600'
                                                : '500',
                                        md:
                                            awsOutOfDateIssueTime !== 'NONE'
                                                ? '600'
                                                : '500',
                                    }}
                                    whiteSpace="pre-line"
                                >
                                    {formatDistanceToNow(
                                        parseISO(awsData.obs_timestamp),
                                        {
                                            addSuffix: true,
                                        }
                                    )}
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                )}
                <Text
                    textAlign="center"
                    color="gray.500"
                    fontSize={{
                        base: '0.7rem',
                        xs: '0.75rem',
                        smd: '0.7rem',
                    }}
                    lineHeight="1.4"
                    pt={awsData && hasData ? '0px' : '15px'}
                    pb={isMobileOnly && isIOS ? '40px' : '25px'}
                    fontWeight={{
                        base: '300',
                        md: '300',
                    }}
                    whiteSpace="pre-line"
                >
                    Weather information updated every 30 minutes
                </Text>
            </Flex>
        </>
    )
}
