import React from 'react'
import { useRecoilValue } from 'recoil'
import { Source, Layer } from 'react-mapbox-gl'
import { VectorLayerRefreshInfo } from '../../../globalState'

export default function RoutesLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    return (
        <>
            <Source
                id="routes"
                geoJsonSource={{
                    type: 'geojson',
                    data: layerData.routes.dataURL,
                }}
            />
            <Layer
                id="routes-line"
                sourceId="routes"
                type="line"
                paint={{
                    'line-color': '#000',
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        0.25,
                        5,
                        0.25,
                        9,
                        1,
                    ],
                    'line-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 0],
                        5,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 0],
                        9,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 1],
                    ],
                }}
            />
            <Layer
                id="routes-symbol"
                sourceId="routes"
                type="symbol"
                layout={{
                    'icon-image': [
                        'match',
                        ['get', 'direction'],
                        ['BACKWARD', 'FORWARD'],
                        'route-designator-oneway',
                        'route-designator-twoway',
                    ],
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        ['match', ['get', 'category'], ['OCEANIC'], 0.5, 0],
                        5,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 0],
                        9,
                        ['match', ['get', 'category'], ['OCEANIC'], 1.25, 1.25],
                    ],
                    'icon-rotate': [
                        'match',
                        ['get', 'direction'],
                        ['BACKWARD'],
                        180,
                        0,
                    ],
                    'symbol-placement': 'line',
                    'text-field': '{designator}',
                    'text-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        ['match', ['get', 'category'], ['OCEANIC'], 5, 0],
                        5,
                        ['match', ['get', 'category'], ['OCEANIC'], 8, 0],
                        9,
                        ['match', ['get', 'category'], ['OCEANIC'], 10, 10],
                    ],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                }}
                paint={{
                    'text-color': '#fff',
                    'text-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 0],
                        5,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 0],
                        9,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 1],
                    ],
                    'icon-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 0],
                        5,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 0],
                        9,
                        ['match', ['get', 'category'], ['OCEANIC'], 1, 1],
                    ],
                }}
            />
        </>
    )
}
