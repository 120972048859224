import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
    Modal,
    VStack,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Flex,
    useDisclosure,
    Text,
    LightMode,
    useToast,
    Spinner,
} from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import TermsAndConditions from './TermsAndConditions'
import { GoCheck } from 'react-icons/go'
import { useUserMetaData } from '../../../auth/hooks'
import {
    setModalVisibilityState,
    modalVisibilityState,
    tourVisibilityState,
    firstTimeLoggedInState,
    tourCompletedState,
} from '../../../globalState'

/**** Update version number when updating Terms ****/
export const versionNumber = '002'

/**** Update version date when updating Terms ****/
export const versionDate = '26 August 2022'

export default function TermsModal(props) {
    const { className } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const landingPage = className === 'termsLandingPage'
    const { getAccessTokenSilently } = useAuth0()
    const userMetaData = useUserMetaData()
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setStartIntroTour = useSetRecoilState(tourVisibilityState)
    const [tosRead, setTosRead] = useState(false)
    const [loading, setLoading] = useState(false)
    const [versionOutOfDate, setVersionOutOfDate] = useState(false)
    const [firstTimeLoggedIn, setFirstTimeLoggedIn] = useRecoilState(
        firstTimeLoggedInState
    )
    const tourCompleted = useRecoilValue(tourCompletedState)
    const [showTerms, setShowTerms] = useState(false)
    const [agreeTerms, setAgreeTerms] = useState(false)
    const listInnerRef = useRef()

    useEffect(() => {
        if (versionOutOfDate || firstTimeLoggedIn) {
            setShowTerms(false)
        }
        if (!firstTimeLoggedIn && !versionOutOfDate && isOpen) {
            // incl isOpen so Terms doesn't show momentarily if user logged in and Terms are updated during User's session
            setShowTerms(true)
        }
    }, [landingPage, versionOutOfDate, firstTimeLoggedIn, isOpen, setShowTerms])

    useCallback(() => {
        if (isOpen && tosRead) {
            setShowTerms(true)
        }
    }, [tosRead, isOpen, setShowTerms])

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setTosRead(true)
            }
        }
    }

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'terms', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        if (userMetaData && !landingPage) {
            if (
                (!userMetaData.tos_version ||
                    userMetaData.tos_version === '' ||
                    userMetaData.tos_version === '0') &&
                !tourCompleted
            ) {
                setFirstTimeLoggedIn(true)
                setShow(true)
            } else if (
                userMetaData.tos_version !== versionNumber &&
                !tourCompleted
            ) {
                setVersionOutOfDate(true)
                setShow(true)
            } else if (userMetaData.tos_version === versionNumber) {
                setTosRead(true)
            }
        }
    }, [
        userMetaData,
        landingPage,
        tourCompleted,
        setShow,
        setFirstTimeLoggedIn,
    ])

    const toast = useToast()

    useEffect(() => {
        modalVisibility.terms ? onOpen() : onClose()
    }, [modalVisibility.terms, onOpen, onClose])

    const tosCallback = async () => {
        setLoading(true)
        const accessToken = await getAccessTokenSilently()
        fetch(`${window.location.origin}/user/updatetosversion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ tosVersion: versionNumber }),
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                if (firstTimeLoggedIn) {
                    setFirstTimeLoggedIn(false)
                    setVersionOutOfDate(false)
                    setShow(false)
                    onClose()
                    setStartIntroTour(true)
                    setTosRead(true)
                } else {
                    setFirstTimeLoggedIn(false)
                    setVersionOutOfDate(false)
                    setShow(false)
                    onClose()
                    setTosRead(true)
                }
            } else {
                setLoading(false)
                console.log('Error setting tos version number')
                toast({
                    title: `Error connecting with our databases.`,
                    description:
                        'It looks like there was an error connecting to our server, please try again later',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
        })
    }

    const startTimer = useRef(null)

    function onCloseHandler() {
        if (firstTimeLoggedIn || versionOutOfDate) {
            tosCallback()
        } else {
            setShow(false)
            if (landingPage) {
                setShowTerms(false)
            } else {
                startTimer.current = setTimeout(() => {
                    setShowTerms(false)
                }, 2000) // Set to false after 1 second to prevent spinner from showing on close
            }
        }
    }

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant={landingPage ? 'termsLandingPage' : 'terms'}
                    size="4xl"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    closeOnOverlayClick={false}
                    isCentered
                    trapFocus={false}
                    blockScrollOnMount={true}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            zIndex="1"
                            textAlign="center"
                            backgroundImage="linear-gradient(345deg, #00DB9E 10%, #00A27C 90%)"
                            color="white"
                            boxShadow="0px 0px 15px -1px rgba(0,0,0,0.25)"
                        >
                            PreFlight Terms and Conditions
                        </ModalHeader>
                        <ModalBody
                            style={{ padding: 0 }}
                            fontSize="0.8rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <VStack
                                className="terms-list"
                                maxHeight="65vh"
                                overflowY="auto"
                                overflowX="hidden"
                                px={{ base: 5, md: 8 }}
                                pt={5}
                                pb={8}
                                spacing={3}
                                alignItems="baseline"
                                onScroll={onScroll}
                                ref={listInnerRef}
                            >
                                {versionOutOfDate && (
                                    <Flex
                                        flexDirection="column"
                                        mt="15px"
                                        mb="10px"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        background="#F1F5F9"
                                        // border="1px solid #f1f3f6"
                                        // borderRadius="10px"
                                        // boxShadow="0px 3px 20px -8px rgba(0,0,0,0.15)"
                                    >
                                        <Text
                                            py={3}
                                            color="gray.600"
                                            fontSize={{
                                                base: '0.9rem',
                                                md: '1rem',
                                            }}
                                            fontWeight="600"
                                        >
                                            Terms and Conditions have been
                                            updated
                                        </Text>
                                    </Flex>
                                )}

                                {loading && (
                                    <Flex
                                        flexDirection="column"
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        height="150px"
                                    >
                                        <Spinner
                                            color="gray.400"
                                            boxSize="40px"
                                        />
                                    </Flex>
                                )}

                                {/* MINIMSED SECTION */}
                                <TermsAndConditions
                                    showTerms={showTerms}
                                    versionDate={versionDate}
                                />

                                {!loading &&
                                    (firstTimeLoggedIn || versionOutOfDate) && (
                                        <Flex
                                            flexDirection="column"
                                            width="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                            px={{ base: '10px', md: '20px' }}
                                            py="20px"
                                        >
                                            <Flex
                                                flexDirection="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                width="100%"
                                                pb="40px"
                                            >
                                                <Flex
                                                    flexDirection="column"
                                                    mb={{
                                                        base: '-16px',
                                                        sm: '-16px',
                                                        md: '-17px',
                                                    }}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Button
                                                        width={{
                                                            base: '38px',
                                                            md: '34px',
                                                        }}
                                                        minWidth={{
                                                            base: '38px',
                                                            md: '34px',
                                                        }}
                                                        height={{
                                                            base: '38px',
                                                            md: '34px',
                                                        }}
                                                        color="white"
                                                        background={
                                                            agreeTerms
                                                                ? 'light.200'
                                                                : 'light.10'
                                                        }
                                                        borderColor={
                                                            agreeTerms
                                                                ? 'light.201'
                                                                : 'gray.300'
                                                        }
                                                        onClick={() =>
                                                            setAgreeTerms(
                                                                (prevState) =>
                                                                    !prevState
                                                            )
                                                        }
                                                        variant="outline"
                                                        fontSize="1.5rem"
                                                        borderRadius="10px"
                                                        fontWeight="500"
                                                        px="0px"
                                                        _hover={{
                                                            color: 'light.10',
                                                            background:
                                                                agreeTerms
                                                                    ? 'light.201'
                                                                    : 'light.30',
                                                        }}
                                                    >
                                                        {agreeTerms ? (
                                                            <GoCheck />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Button>
                                                    <Text
                                                        pt="2px"
                                                        fontSize="0.65rem"
                                                        color="gray.300"
                                                    >
                                                        Tick
                                                    </Text>
                                                </Flex>
                                                <Text
                                                    fontSize={{
                                                        base: '0.85rem',
                                                        sm: '0.9rem',
                                                    }}
                                                    pl="20px"
                                                >
                                                    By using this web
                                                    application I agree to the
                                                    current Terms and Conditions
                                                </Text>
                                            </Flex>
                                            {!showTerms && (
                                                <Button
                                                    size="sm"
                                                    color="gray.600"
                                                    background="light.20"
                                                    onClick={() =>
                                                        setShowTerms(true)
                                                    }
                                                    variant="outline"
                                                    fontSize="0.9rem"
                                                    borderRadius="10px"
                                                    fontWeight="400"
                                                    px="25px"
                                                    _hover={{
                                                        color: 'gray.700',
                                                        background: 'light.30',
                                                    }}
                                                >
                                                    View the current Terms and
                                                    Conditions
                                                </Button>
                                            )}
                                        </Flex>
                                    )}
                            </VStack>
                        </ModalBody>
                        <ModalFooter
                            borderTop="1px solid #e5e7e9"
                            pt={4}
                            pb={4}
                        >
                            <Flex
                                flexDirection="column"
                                width="100%"
                                overflow="hidden"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    {...noFocus}
                                    w="100%"
                                    size="sm"
                                    maxWidth="180px"
                                    isDisabled={
                                        !landingPage
                                            ? !showTerms
                                                ? !agreeTerms
                                                : showTerms &&
                                                  (firstTimeLoggedIn ||
                                                      versionOutOfDate)
                                                ? !agreeTerms || !tosRead
                                                : !tosRead
                                            : false
                                    }
                                    variant="primary"
                                    isLoading={loading}
                                    onClick={onCloseHandler}
                                    bg="light.200"
                                    _hover={{ background: 'light.201' }}
                                    _focus={{ background: 'light.201' }}
                                    _active={{ background: 'light.201' }}
                                    style={{ borderRadius: '10px' }}
                                >
                                    {firstTimeLoggedIn || versionOutOfDate
                                        ? 'Agree'
                                        : 'Close'}
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
