import React from 'react'
import Lottie from 'lottie-react'
import { useBreakpointValue } from '@chakra-ui/react'
import starsLottie from '../backgrounds/stars.json'
import shootingStarLottie from '../backgrounds/shooting-star.json'
import rainLottie from '../backgrounds/rain.json'
import lightRainLottie from '../backgrounds/light-rain.json'
import windLottie from '../backgrounds/wind.json'

export default function Animations(props) {
    const {
        timePeriod,
        infoTab,
        lightRain,
        rain,
        heavyRain,
        windgusting,
        clearsky,
    } = props

    const rainHeight = useBreakpointValue({
        base: 'calc(100vh - 40px)',
        md: '100vh',
    })

    const rainAlignment = useBreakpointValue({
        base: '-20vh',
        sm: '-10vh',
        smd: '-2vh',
        md: '0px',
    })

    const shootingStarAlignment = useBreakpointValue({
        base: '-80px',
        sm: '-100px',
        smd: '-140px',
        md: '-190px',
    })

    const starsSizing = useBreakpointValue({
        base: '30px',
        md: '50px',
    })

    const windAlignTop = useBreakpointValue({
        base: '-50px',
        md: '-135px',
    })
    const windAlignRight = useBreakpointValue({
        base: '0px',
        md: '20px',
    })
    const windScale = useBreakpointValue({
        base: 'scale(1.2)',
        md: 'scale(1.15)',
    })
    const windOpacity = useBreakpointValue({
        base: '0.16',
        md: '0.1',
    })

    // console.log(latestMetar.observation, 'animations')

    return (
        <>
            {/* LIGHT RAIN */}
            {(lightRain || heavyRain) && (
                <Lottie
                    className="light-rain"
                    loop={true}
                    autoplay={true}
                    animationData={lightRainLottie}
                    style={{
                        width: '100%',
                        height: 'auto',
                        minWidth: rainHeight,
                        minHeight: 'auto',
                        position: 'absolute',
                        pointerEvents: 'none',
                        transform: heavyRain && `scaleX(-1) rotate(10deg)`,
                        opacity: timePeriod === 'Night' ? '0.3' : '0.6',
                        left: rainAlignment,
                        bottom: '0px',
                    }}
                />
            )}

            {/* MODERATE RAIN */}
            {(rain || heavyRain) && (
                <Lottie
                    className="rain"
                    loop={true}
                    autoplay={true}
                    animationData={rainLottie}
                    style={{
                        width: '100%',
                        height: 'auto',
                        minWidth: rainHeight,
                        minHeight: 'auto',
                        position: 'absolute',
                        pointerEvents: 'none',
                        opacity: timePeriod === 'Night' ? '0.35' : '0.6',
                        left: rainAlignment,
                        bottom: '0px',
                    }}
                />
            )}

            {/* HEAVY RAIN */}
            {heavyRain && (
                <Lottie
                    className="rain"
                    loop={true}
                    autoplay={true}
                    animationData={rainLottie}
                    style={{
                        width: '100%',
                        height: 'auto',
                        minWidth: rainHeight,
                        minHeight: 'auto',
                        position: 'absolute',
                        pointerEvents: 'none',
                        opacity: timePeriod === 'Night' ? '0.3' : '0.7',
                        left: `calc(${rainAlignment} + 120px)`,
                        bottom: '70px',
                        transform: `scaleX(1.05) rotate(2deg)`,
                    }}
                />
            )}

            {/* WIND GUSTS */}
            {windgusting && (
                <Lottie
                    className="wind"
                    loop={true}
                    autoplay={true}
                    animationData={windLottie}
                    style={{
                        width: '100%',
                        height: 'auto',
                        minWidth: '100%',
                        minHeight: 'auto',
                        marginLeft: windAlignRight,
                        transform: `translateY(${windAlignTop}) ${windScale}`,
                        position: 'absolute',
                        pointerEvents: 'none',
                        opacity: windOpacity,
                        filter: 'blur(2px)',
                    }}
                />
            )}

            {/* NIGHT STARS */}
            {timePeriod === 'Night' && clearsky && (
                <>
                    <Lottie
                        className="stars"
                        loop={true}
                        autoplay={true}
                        animationData={starsLottie}
                        style={{
                            width: '100%',
                            height: 'auto',
                            minWidth: '100%',
                            minHeight: 'auto',
                            top: starsSizing,
                            transform: 'scale(1.5)',
                            position: 'absolute',
                            pointerEvents: 'none',
                            opacity: '1',
                        }}
                    />
                    <Lottie
                        className="stars"
                        loop={true}
                        autoplay={true}
                        animationData={starsLottie}
                        style={{
                            width: '100%',
                            height: 'auto',
                            minWidth: '100%',
                            minHeight: 'auto',
                            top: starsSizing,
                            transform: 'rotate(90deg) scale(2)',
                            position: 'absolute',
                            pointerEvents: 'none',
                            opacity: '1',
                        }}
                    />
                    <Lottie
                        className="stars"
                        loop={true}
                        autoplay={true}
                        animationData={starsLottie}
                        style={{
                            width: '100%',
                            height: 'auto',
                            minWidth: '100%',
                            minHeight: 'auto',
                            top: starsSizing,
                            transform: 'rotate(-90deg) scale(2)',
                            position: 'absolute',
                            pointerEvents: 'none',
                            opacity: '1',
                        }}
                    />

                    {infoTab && (
                        <Lottie // shooting star
                            className="shooting-star"
                            loop={true}
                            autoplay={true}
                            animationData={shootingStarLottie}
                            style={{
                                width: '100%',
                                height: 'auto',
                                minWidth: '100%',
                                minHeight: 'auto',
                                transform: 'rotate(45deg) scale(1)',
                                position: 'absolute',
                                top: shootingStarAlignment,
                                pointerEvents: 'none',
                                opacity: '0.28',
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
