import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WindBarbsCollectionIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const {
        boxSize,
        color,
        active,
        wbid5,
        wbid10,
        wbid20,
        wbid25,
        wbid30,
        wbid35,
        wbid40,
        wbid45,
        wbid50,
    } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 5} color={color || colorHandler()}>
            <path
                d="M12.1719 3.06654C11.863 3.22055 11.7375 3.59583 11.8915 3.90474L19.6993 19.5649C19.6357 19.6964 19.6 19.844 19.6 19.9999C19.6 20.5522 20.0477 20.9999 20.6 20.9999C21.1523 20.9999 21.6 20.5522 21.6 19.9999C21.6 19.5259 21.2701 19.1288 20.8274 19.0259L13.0102 3.347C12.8561 3.03808 12.4809 2.91252 12.1719 3.06654Z"
                fill="currentcolor"
            />
            <path
                display={wbid5 ? 'initial' : 'none'}
                d="M8.5 6C8.5 5.72386 8.72386 5.5 9 5.5H14V6.5H9C8.72386 6.5 8.5 6.27614 8.5 6V6Z"
                fill="currentcolor"
            />
            <rect
                display={wbid10 ? 'initial' : 'none'}
                x="2.5"
                y="3"
                width="10.4"
                height="1"
                rx="0.5"
                fill="currentcolor"
            />
            <path
                display={wbid20 ? 'initial' : 'none'}
                d="M3.75 6C3.75 5.72386 3.97386 5.5 4.25 5.5L13.75 5.5V6.5H4.25C3.97386 6.5 3.75 6.27614 3.75 6V6Z"
                fill="currentcolor"
            />
            <path
                display={wbid25 ? 'initial' : 'none'}
                d="M9.5 8.5C9.5 8.22386 9.72386 8 10 8H15V9H10C9.72386 9 9.5 8.77614 9.5 8.5V8.5Z"
                fill="currentcolor"
            />
            <path
                display={wbid30 ? 'initial' : 'none'}
                d="M5 8.5C5 8.22386 5.22386 8 5.5 8H15V9H5.5C5.22386 9 5 8.77614 5 8.5V8.5Z"
                fill="currentcolor"
            />
            <path
                display={wbid35 ? 'initial' : 'none'}
                d="M11 11C11 10.7239 11.2239 10.5 11.5 10.5H16.5V11.5H11.5C11.2239 11.5 11 11.2761 11 11V11Z"
                fill="currentcolor"
            />
            <path
                display={wbid40 ? 'initial' : 'none'}
                d="M6.5 11C6.5 10.7239 6.72386 10.5 7 10.5H16.5V11.5H7C6.72386 11.5 6.5 11.2761 6.5 11V11Z"
                fill="currentcolor"
            />
            <path
                display={wbid45 ? 'initial' : 'none'}
                d="M12 13.5C12 13.2239 12.2239 13 12.5 13H17.5V14H12.5C12.2239 14 12 13.7761 12 13.5V13.5Z"
                fill="currentcolor"
            />
            <path
                display={wbid50 ? 'initial' : 'none'}
                d="M3.95756 6.40564C3.83183 6.31483 3.75 6.16698 3.75 6.00003C3.75 5.75625 3.92446 5.55322 4.15534 5.50898L12.27 3.02808C12.5341 2.94735 12.8136 3.09597 12.8943 3.36005C12.9751 3.62413 12.8264 3.90365 12.5624 3.98439L7.60491 5.50003H13.75V6.50003H4.29865C4.17517 6.51154 4.05421 6.47621 3.95756 6.40564Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
