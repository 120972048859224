import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import constructMenu from '../../../../config/utils/constructMenu'
import { useTier } from '../../../../auth/hooks'
import {
    isBriefingOnlyState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../../globalState'
import Brief from '../components/Brief'
import SidebarCollection from './SidebarCollection'

export default function SidebarCollections({ menu, setMenu }) {
    const tier = useTier()
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const simulateFree = useRecoilValue(
        simulateFreeUserState
        // To remove when User Tiers initiated
    )
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const mfaExpired = tier > 1 && !mfaVerified

    useEffect(() => {
        if (tier) {
            const userTier = mfaExpired || simulateFree ? 1 : tier
            const { menuStructure } = constructMenu(userTier)
            setMenu(menuStructure)
        }
    }, [setMenu, mfaExpired, simulateFree, tier])

    return (
        <>
            {menu && !isBriefingOnly && (
                <>
                    {Object.keys(menu).map((i) => (
                        <SidebarCollection
                            key={`SidebarCollections${menu}${i}`}
                            items={menu[i]}
                            id={i}
                        />
                    ))}
                </>
            )}
            <Brief />
        </>
    )
}
