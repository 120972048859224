import React, { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useInView } from 'react-intersection-observer'
import { Flex, Box, Text, Button, useBreakpointValue } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import useAdjustedFontSize from '../../../../../util/fontSizeAdjustment'
import useConfirm from '../../../alerts/warning-message/components/useConfirm'
import { ExcludedAerodromesWarningMessage } from '../../../alerts/warning-message/components/WarningMessages'
import {
    excludedAerodromesState,
    inViewPositionState,
    briefingParamsState,
    aerodromesSortState,
} from '../../../../../globalState'

import CloseIcon from '../../../../../icons/menu-icons/CloseIcon'
import { ReactComponent as AerodromeIcon } from '../../../../../icons/assets/aerodrome.svg'
import AerodromeBriefingContent from './AerodromeBriefingContent'

export default function AerodromeBriefing(props) {
    const { briefingData } = props

    const aerodromesSort = useRecoilValue(aerodromesSortState)
    const [excludedAerodromes, setExcludedAerodromes] = useRecoilState(
        excludedAerodromesState
    )
    const setInViewPosition = useSetRecoilState(inViewPositionState)
    const params = useRecoilValue(briefingParamsState)
    const [firstClose, setFirstClose] = useState(true)
    const { isConfirmed } = useConfirm() // alert message handler

    // FONT SIZE ADJUSTMENTS
    const { calculateFontSize } = useAdjustedFontSize()

    const adjustHeaderFontSize = useBreakpointValue({
        base: calculateFontSize(0.9),
        xs: calculateFontSize(0.95),
        lg: calculateFontSize(1),
    })

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 1,
    })

    useEffect(() => {
        inView && setInViewPosition('aerodromesContainer')
    }, [inView, setInViewPosition])

    const noFocus = {
        _focus: { boxShadow: 'none !important' },
    }

    return (
        <>
            <div
                id="aerodromesContainer"
                style={{ marginTop: '1px' }}
                ref={ref}
            ></div>
            {(params.aerodromesNotam ||
                params.aerodromesMetar ||
                params.aerodromesTaf ||
                params.aerodromesAtis) &&
                briefingData &&
                briefingData.res &&
                briefingData.res.aerodromes &&
                briefingData.res.aerodromes
                    .filter(
                        (feat) =>
                            (params.aerodromesMetar && feat.metar) ||
                            (params.aerodromesTaf && feat.taf) ||
                            (params.aerodromesAtis && feat.atis) ||
                            (params.aerodromesNotam && feat.notam)
                    )
                    .sort((a, b) =>
                        aerodromesSort
                            ? a.name > b.name
                            : a.lat < b.lat
                            ? 1
                            : -1
                    )
                    .map((aerodrome, index) => {
                        if (excludedAerodromes.includes(aerodrome.designator)) {
                            return null
                        } else {
                            return (
                                <Box
                                    key={`AerodromeBriefing${aerodrome.designator}`}
                                    zIndex={500 - index}
                                >
                                    <Box
                                        bg="rgba(255,255,255,1)"
                                        w={{ base: '100%', md: '100%' }}
                                        borderRadius="20px"
                                        fontFamily="Open Sans"
                                        marginTop="25px"
                                        marginBottom="10px"
                                        overflow="hidden"
                                        transition="all ease 300ms"
                                        boxShadow="0px 5px 30px -15px rgba(20, 30, 40, 0.4)"
                                        _hover={{
                                            boxShadow:
                                                '0px 7px 35px -15px rgba(20, 30, 40, 0.6)',
                                        }}
                                    >
                                        <Flex
                                            bg="#07578A"
                                            backgroundImage="linear-gradient(345deg, #3B9EDC 0%, #004D7F 80%)"
                                            filter={{
                                                base:
                                                    isMobileOnly &&
                                                    'saturate(1.3)',
                                                md: 'saturate(1)',
                                            }}
                                            w={{ base: '100%', md: '100%' }}
                                            borderTopRadius="20px"
                                            minHeight="30px"
                                            border="none"
                                            fontFamily="Open Sans"
                                            zIndex="1"
                                        >
                                            <Flex
                                                direction="row"
                                                justifyContent="space-between"
                                                width="100%"
                                                py="8px"
                                                pl="21px"
                                                pr="5px"
                                            >
                                                <Flex
                                                    my="auto"
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Flex
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        width="28px"
                                                        height="28px"
                                                        minWidth="28px"
                                                        minHeight="28px"
                                                    >
                                                        <AerodromeIcon
                                                            width="28px"
                                                            height="28px"
                                                            color="white"
                                                        />
                                                    </Flex>
                                                    <Text
                                                        textAlign="left"
                                                        color="white"
                                                        fontSize={
                                                            adjustHeaderFontSize
                                                        }
                                                        fontWeight="bold"
                                                        lineHeight="1.1"
                                                        paddingLeft="11px"
                                                    >
                                                        {aerodrome.name}
                                                    </Text>
                                                </Flex>

                                                <Box my="auto" width="85px">
                                                    <Text
                                                        textAlign="right"
                                                        color="white"
                                                        fontSize={
                                                            adjustHeaderFontSize
                                                        }
                                                        fontWeight="normal"
                                                        lineHeight="1.1"
                                                    >
                                                        {aerodrome.designator}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                            <Button
                                                opacity="0.6"
                                                bg="none"
                                                variant="unstyled"
                                                fontSize="11px"
                                                fontWeight="600"
                                                height="44px"
                                                width="50px"
                                                justifyContent="initial"
                                                paddingLeft="4px"
                                                paddingRight="2px"
                                                marginRight="8px"
                                                py="2px"
                                                borderRadius="8px"
                                                {...noFocus}
                                                onClick={async () => {
                                                    if (firstClose) {
                                                        const acknowledgeExcludedAerodromes =
                                                            await isConfirmed(
                                                                // "Excluded aerodromes?"
                                                                'confirm-ok',
                                                                <ExcludedAerodromesWarningMessage />
                                                            )
                                                        if (
                                                            acknowledgeExcludedAerodromes
                                                        ) {
                                                            setExcludedAerodromes(
                                                                (prevState) =>
                                                                    prevState.concat(
                                                                        aerodrome.designator
                                                                    )
                                                            )
                                                            setFirstClose(false)
                                                        }
                                                    } else {
                                                        setExcludedAerodromes(
                                                            (prevState) =>
                                                                prevState.concat(
                                                                    aerodrome.designator
                                                                )
                                                        )
                                                    }
                                                }}
                                                _active={{
                                                    opacity: '1',
                                                }}
                                                _focus={{
                                                    opacity: '1',
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                }}
                                            >
                                                <CloseIcon
                                                    color="white"
                                                    mx="2px"
                                                    mt="2px"
                                                    width="1.1em"
                                                    height="1.1em"
                                                />
                                            </Button>
                                        </Flex>

                                        <AerodromeBriefingContent
                                            briefingData={briefingData}
                                            aerodrome={aerodrome}
                                        />
                                    </Box>
                                </Box>
                            )
                        }
                    })}
        </>
    )
}
