import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useTier } from '../../../../../auth/hooks'
import { Flex, Box, useBreakpointValue } from '@chakra-ui/react'
import { useSwipeable } from 'react-swipeable'
import {
    inViewPositionState,
    extendedHeightState,
    mobileDrawerExpandedState,
    altAerodromeLayoutState,
    simulateFreeUserState,
    setModalVisibilityState,
    mfaVerifiedState,
} from '../../../../../globalState'
import '../../briefing.css'
import MobileScrollMenuItems from './MobileScrollMenuItems'

export default function MobileMenu(props) {
    const {
        menuPositionState: menuState,
        showFilters: filtersShow,
        params,
        drawerBg,
        scrollDirection,
        isScrolling,
        setIsScrolling,
        buttonSwipe,
        dataLoading,
        disableAerodromes,
    } = props

    const inViewPosition = useRecoilValue(inViewPositionState)
    const setExtendedHeight = useSetRecoilState(extendedHeightState)
    // const briefingOrder = useRecoilValue(briefingOrderState)
    const altAerodromeLayout = useRecoilValue(altAerodromeLayoutState)
    const [mobileDrawerExpanded, setMobileDrawerExpanded] = useRecoilState(
        mobileDrawerExpandedState
    )
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const abbreviatedMenuLabels = useBreakpointValue({
        base: true,
        md: false,
    })

    const condenseMenu = false // TODO - add long-touch behaviour to change mode (will come back to this another time)

    const [selectedButton, setSelectedButton] = useState(
        altAerodromeLayout ? 'notam' : 'aerodromes'
    )
    useEffect(() => {
        if (selectedButton === 'aerodromes') {
            if (altAerodromeLayout) {
                setSelectedButton('notam')
            }
        }
        if (
            selectedButton === 'notam' ||
            selectedButton === 'metar' ||
            selectedButton === 'taf' ||
            selectedButton === 'atis'
        ) {
            if (!altAerodromeLayout) {
                setSelectedButton('aerodromes')
            }
        }
    }, [selectedButton, altAerodromeLayout])

    const drawerSwipe = useSwipeable({
        onSwipedUp: () => {
            setExtendedHeight(true)
            setMobileDrawerExpanded(true)
        },
        onSwipedDown: () => {
            setExtendedHeight(false)
            setMobileDrawerExpanded(false)
        },
    })

    const inViewCancel =
        (selectedButton === 'aerodromes' &&
            inViewPosition !== 'aerodromesContainer') ||
        (selectedButton === 'notam' &&
            inViewPosition !== 'aerodromesContainer' &&
            inViewPosition !== 'notamContainer') ||
        (selectedButton === 'metar' &&
            inViewPosition !== 'aerodromesContainer' &&
            inViewPosition !== 'metarContainer') ||
        (selectedButton === 'taf' &&
            inViewPosition !== 'aerodromesContainer' &&
            inViewPosition !== 'tafContainer') ||
        (selectedButton === 'atis' &&
            inViewPosition !== 'aerodromesContainer' &&
            inViewPosition !== 'atisContainer') ||
        (selectedButton === 'aws' && inViewPosition !== 'awsContainer') ||
        (selectedButton === 'enroute' &&
            inViewPosition !== 'enrouteContainer') ||
        (selectedButton === 'aaw' && inViewPosition !== 'aawContainer') ||
        (selectedButton === 'grafor' && inViewPosition !== 'graforContainer') ||
        (selectedButton === 'msl' && inViewPosition !== 'mslContainer') ||
        (selectedButton === 'sigmet' && inViewPosition !== 'sigmetContainer') ||
        (selectedButton === 'sigwx' && inViewPosition !== 'sigwxContainer') ||
        (selectedButton === 'spacewx' && inViewPosition !== 'spacewxContainer')

    useEffect(() => {
        if (window.innerWidth < 768) {
            !isScrolling && inViewCancel && setSelectedButton(null)
        }
    }, [selectedButton, inViewCancel, isScrolling])

    const containerInView =
        selectedButton && `${selectedButton}Container` === inViewPosition

    const startTimer = useRef(null)

    useEffect(() => {
        if (containerInView) {
            startTimer.current = setTimeout(() => {
                setIsScrolling(false)
            }, 500)
        } else {
            clearTimeout(startTimer.current)
        }
    }, [containerInView, setIsScrolling])

    // useEffect(() => {
    //     console.log('selectedButton: ', selectedButton)
    //     console.log('inViewPosition: ', inViewPosition)
    //     console.log('containerInView: ', containerInView)
    // }, [selectedButton, inViewPosition, containerInView])

    return (
        <>
            <Box
                {...buttonSwipe}
                marginBottom={{
                    base:
                        scrollDirection === 'up'
                            ? '-3px'
                            : mobileDrawerExpanded
                            ? '-60px'
                            : '0px',
                    md: '0px',
                }}
                transition="all ease-in 300ms"
                zIndex="5"
                height={{ base: '58px', md: '0px' }}
                minHeight={{ base: '58px', md: '0px' }}
                bg={{
                    base: drawerBg,
                    md: 'transparent',
                }}
            >
                <Flex
                    justifyContent="flex-start"
                    alignItems="center"
                    position="relative"
                    width="calc(100vw - 0px)"
                    overflow="scroll hidden"
                    height="100%"
                >
                    <Flex
                        flexDirection={'row'}
                        {...drawerSwipe}
                        {...buttonSwipe}
                        zIndex="2"
                        h="44px"
                        w="auto"
                        justifyContent="space-evenly"
                        mb={condenseMenu ? '0px' : '-1px'}
                        display={{
                            base: 'flex',
                            md: 'none',
                        }}
                        transition="all ease 300ms"
                        gap="5px"
                        paddingLeft={altAerodromeLayout ? '23px' : '23px'}
                        paddingRight={altAerodromeLayout ? '23px' : '25px'}
                    >
                        <MobileScrollMenuItems
                            menuState={menuState}
                            altAerodromeLayout={altAerodromeLayout}
                            scrollDirection={scrollDirection}
                            dataLoading={dataLoading}
                            params={params}
                            condenseMenu={condenseMenu}
                            selectedButton={selectedButton}
                            abbreviatedMenuLabels={abbreviatedMenuLabels}
                            filtersShow={filtersShow}
                            isPremium={isPremium}
                            setShowPricingTiers={setShowPricingTiers}
                            setIsScrolling={setIsScrolling}
                            setSelectedButton={setSelectedButton}
                            disableAerodromes={disableAerodromes}
                        />
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}
