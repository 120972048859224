import React, { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { GeoJSONLayer } from 'react-mapbox-gl'
import {
    selectedTimeState,
    VectorLayerRefreshInfo,
    layersLoaded,
    layerStatusState,
} from '../../../globalState'

//Create global clock, create a last updated atom, test how much time it has been and when each layer has been refreshed.
export default function RpasLayer() {
    const selectedTime = useRecoilValue(selectedTimeState)
    const layerData = useRecoilValue(VectorLayerRefreshInfo)
    const layerState = useRecoilValue(layerStatusState)
    const [loaded, setLoaded] = useRecoilState(layersLoaded)
    const [mounted, setMounted] = useState(false)

    const layerId = 'rpas'

    // This needs more complexity
    useEffect(() => {
        if (!mounted) {
            setLoaded(!layerState[`${layerId}Loading`])
            setMounted(!layerState[`${layerId}Mounted`])
        }
    }, [layerState, setLoaded, mounted, loaded])

    useEffect(() => {
        // console.log({ layerState })
    }, [layerState])

    return (
        <>
            <GeoJSONLayer
                id="rpas"
                data={layerData.rpas.dataURL}
                before="aerodromes-symbol"
                symbolLayout={{
                    'icon-image': 'drone',
                    'icon-anchor': 'center',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        0.4,
                        10,
                        0.5,
                        12,
                        0.6,
                        16,
                        0.7,
                    ],
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-field': [
                        'format',
                        ['concat', ['get', 'altitude'], ' AGL'],
                        {},
                        '\n—————\n',
                        {
                            'font-scale': 0.5,
                            'text-font': [
                                'literal',
                                ['Roboto Black', 'Arial Unicode MS Bold'],
                            ],
                        },
                        'SFC',
                        {},
                    ],
                    'text-line-height': 1,
                    'text-font': ['Roboto Bold', 'Arial Unicode MS Bold'],
                    'text-offset': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        8,
                        ['literal', [1, -0.4]],
                        10,
                        ['literal', [1.2, -0.6]],
                        12,
                        ['literal', [1.4, -0.8]],
                        16,
                        ['literal', [1.6, -1]],
                    ],
                    'text-anchor': 'left',
                    'text-size': 10,
                    //"text-allow-overlap": true,
                }}
                symbolPaint={{
                    'text-color': '#ff0000',
                }}
                linePaint={{
                    'line-color': '#ff0000',
                    'line-width': 2,
                    'line-dasharray': [6, 4],
                    'line-opacity': 1,
                }}
                fillPaint={{
                    'fill-antialias': true,
                    'fill-color': '#ff0000',
                    'fill-opacity': 0.1,
                }}
                layerOptions={
                    ({ minzoom: 9 },
                    {
                        filter: [
                            'all',
                            ['<=', ['get', 'start_epoch'], selectedTime / 1000],
                            ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                        ],
                    })
                }
            />
        </>
    )
}
