import React from 'react'
import {
    Flex,
    Button,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    IconButton,
    useBreakpointValue,
} from '@chakra-ui/react'
import { BsZoomIn, BsZoomOut } from 'react-icons/bs'
import { ReactComponent as RecenterIcon } from '../../../icons/assets/recenter.svg'

export default function ZoomSlider({
    scale,
    xy,
    setScale,
    setXy,
    maxScale,
    fullscreen,
}) {
    const minScale = 0.5
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0

    function handleSliderChange(v) {
        const computedScale = v / 1000
        //api.start({ scale: computedScale })
        setScale(computedScale)
    }

    const zoomIn = () => {
        setScale((s) => s + 0.1)
    }

    const zoomOut = () => {
        setScale((s) => s - 0.1)
    }

    const resetZoom = () => {
        setScale(1)
        setXy({ x: 0, y: 0 })
    }
    const recenterSize = useBreakpointValue({
        base: '24px',
        md: '22px',
    })
    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <Flex
            w={{
                base: 'auto',
                md: '100%',
            }}
            maxWidth={{
                base: '350px',
            }}
            justifyContent={{
                base: 'flex-end',
                md: 'space-between',
            }}
            alignItems={'center'}
        >
            <Flex
                width="100%"
                display={{
                    base: 'none',
                    md: maxScale === 8 ? 'none' : 'flex',
                    ml: 'flex',
                }}
                flexDirection="row"
            >
                <Button
                    {...noFocus}
                    width="36px"
                    minWidth="36px"
                    height="36px"
                    opacity={{
                        base: '1',
                        md: '0.8',
                    }}
                    bg="none"
                    paddingInlineStart="0"
                    paddingInlineEnd="0"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="10px"
                    onClick={zoomOut}
                    _hover={{
                        opacity: '1',
                        cursor: 'pointer',
                    }}
                    _active={{
                        background: 'none',
                    }}
                    disabled={scale <= 0.51}
                >
                    <BsZoomOut fontSize="1.1rem" color="light.100" />
                </Button>
                <Slider
                    aria-label="slider-ex-1"
                    min={minScale * 1000}
                    max={maxScale * 1000}
                    value={scale * 1000}
                    onChange={handleSliderChange}
                    focusThumbOnChange={false}
                    mx={'5px'}
                >
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>
                <Button
                    {...noFocus}
                    width="36px"
                    minWidth="36px"
                    height="36px"
                    opacity={{
                        base: '1',
                        md: '0.8',
                    }}
                    bg="none"
                    paddingInlineStart="0"
                    paddingInlineEnd="0"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="10px"
                    onClick={zoomIn}
                    _hover={{
                        opacity: '1',
                        cursor: 'pointer',
                    }}
                    _active={{
                        background: 'none',
                    }}
                    disabled={scale >= maxScale}
                >
                    <BsZoomIn fontSize="1.1rem" color="light.100" />
                </Button>
            </Flex>
            <IconButton
                {...noFocus}
                color="light.100"
                width={{
                    base: '28px',
                    md: '28px',
                }}
                minWidth={{
                    base: '28px',
                    md: '28px',
                }}
                paddingInlineStart="0"
                paddingInlineEnd="0"
                height={{
                    base: '36px',
                    md: '36px',
                }}
                opacity={{
                    base: minimiseActive ? '0.9' : '0.3',
                    md: minimiseActive ? '0.8' : '0.25',
                }}
                transition="opacity ease 300ms"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
                _hover={{
                    opacity: minimiseActive ? '1' : '0.35',
                    cursor: 'pointer',
                }}
                _active={{
                    background: 'none',
                }}
                icon={
                    <RecenterIcon
                        width={recenterSize}
                        height={recenterSize}
                        color="light.100"
                    />
                }
                onClick={resetZoom}
                variant="outline"
                border="none"
                ml={{ base: '0px', md: '10px' }}
                mr={{ base: fullscreen ? '0px' : '10px', md: '0px' }}
            />
        </Flex>
    )
}
