import React from 'react'
import {
    code23Decode,
    code45Decode,
    trafficDecode,
} from '../../../../../util/notamDecode'
import { Text, View } from '@react-pdf/renderer'

import { EnrouteIcon } from './assets/SvgImages'

import formatDateTime from '../../../../../util/dateFormatter'
import { parseISO, formatDistanceToNow } from 'date-fns'

import { NotamIcon } from './assets/SvgImages'
import { NotamAerodromeIcon } from './assets/SvgImages'
import { NotamCommsIcon } from './assets/SvgImages'
import { NotamDroneIcon } from './assets/SvgImages'
import { NotamObstacleIcon } from './assets/SvgImages'
import { NotamWarningIcon } from './assets/SvgImages'
import { ErrorIcon } from './assets/SvgImages'

export default function EnrouteNotamExportPDF(props) {
    const {
        briefingData,
        styles,
        timeZone,
        periodTo,
        maxAltitude,
        showOutdatedErrors,
    } = props

    function notamIconFormatter(code23) {
        return ['WU', 'WZ'].includes(code23) ? (
            <NotamDroneIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['OB', 'OL'].includes(code23) ? (
            <NotamObstacleIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : code23 === 'FA' ? (
            <NotamAerodromeIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['W', 'R'].includes(code23.charAt(0)) ? (
            <NotamWarningIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : ['C', 'N'].includes(code23.charAt(0)) ? (
            <NotamCommsIcon
                width="20"
                height="20"
                viewBox="0 0 24 24"
                alt="!"
            />
        ) : (
            <NotamIcon width="20" height="20" viewBox="0 0 24 24" alt="!" />
        )
    }

    if (briefingData) {
        return (
            // ENROUTE NOTAM SECTION

            <View style={styles.section}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        wrap={false}
                        style={[
                            styles.sectionTitle,
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                            },
                        ]}
                    >
                        <EnrouteIcon
                            width="16"
                            height="16"
                            viewBox="0 0 14 14"
                            fill="rgb(16, 170, 112)"
                        />

                        <Text
                            style={{
                                fontWeight: '600',
                                textAlign: 'right',
                                paddingLeft: '7',
                                color: 'rgb(16, 170, 112)',
                            }}
                        >
                            ENROUTE NOTAM
                        </Text>
                    </div>

                    <div
                        style={[
                            styles.sectionContent,
                            {
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '10',
                                marginRight: '10',
                                marginTop: '10',
                                marginBottom: '-1',
                            },
                        ]}
                    >
                        {briefingData &&
                            briefingData.res.enroutenotam &&
                            briefingData.res.enroutenotam.map(
                                (enroutenotam, i) => {
                                    const code23 = code23Decode(
                                        enroutenotam.code23
                                    )
                                    const code45 = code45Decode(
                                        enroutenotam.code45
                                    )
                                    const traffic = trafficDecode(
                                        enroutenotam.traffic
                                    )
                                    if (
                                        parseISO(enroutenotam.startvalidity) <=
                                            periodTo &&
                                        maxAltitude >= enroutenotam.lowerfilter
                                    ) {
                                        return (
                                            <div
                                                // wrap={false}
                                                key={`EnrouteNotamBriefing${enroutenotam.series}${enroutenotam.number}/${enroutenotam.year}`}
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {(showOutdatedErrors ||
                                                    enroutenotam.outofdate) && (
                                                    <div
                                                        // wrap={false}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            width: '100%',
                                                            marginBottom: '5',
                                                        }}
                                                    >
                                                        <div // NOTAM ICON
                                                            style={[
                                                                styles.sectionContent,
                                                                {
                                                                    width: '6%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                },
                                                            ]}
                                                        >
                                                            <ErrorIcon
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="rgb(16, 170, 112)"
                                                            />
                                                        </div>
                                                        <div // NOTAM CONTENT
                                                            style={[
                                                                styles.sectionContent,
                                                                {
                                                                    width: '94%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                },
                                                            ]}
                                                        >
                                                            <Text
                                                                style={
                                                                    styles.error
                                                                }
                                                            >
                                                                <span>
                                                                    There has
                                                                    been an
                                                                    issue with
                                                                    the
                                                                    connection
                                                                    between
                                                                    PreFlight
                                                                    and
                                                                    MetService.
                                                                    This{' '}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '500',
                                                                    }}
                                                                >
                                                                    EN-ROUTE
                                                                    NOTAM
                                                                </span>
                                                                <span>
                                                                    {' '}
                                                                    was last
                                                                    validated
                                                                    with
                                                                    MetService{' '}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            '500',
                                                                    }}
                                                                >
                                                                    {formatDistanceToNow(
                                                                        parseISO(
                                                                            enroutenotam.updated
                                                                        ),
                                                                        {
                                                                            addSuffix: true,
                                                                        }
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    . There is a
                                                                    high
                                                                    likelihood
                                                                    that the
                                                                    latest
                                                                    EN-ROUTE
                                                                    NOTAM
                                                                    information
                                                                    is not
                                                                    available in
                                                                    PreFlight.
                                                                    Check back
                                                                    again soon,
                                                                    or
                                                                    alternatively
                                                                    visit{' '}
                                                                </span>
                                                                <a
                                                                    style={{
                                                                        fontWeight:
                                                                            '400',
                                                                        textDecoration:
                                                                            'underline',
                                                                    }}
                                                                    href="https://ifis.airways.co.nz/"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    IFIS
                                                                </a>
                                                                .
                                                            </Text>
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <div // NOTAM ICON
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '6%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        {notamIconFormatter(
                                                            enroutenotam.code23
                                                        )}
                                                    </div>

                                                    <div // NOTAM CONTENT
                                                        style={[
                                                            styles.sectionContent,
                                                            {
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            },
                                                        ]}
                                                    >
                                                        <div
                                                            style={[
                                                                styles.sectionContent,
                                                                {
                                                                    width: '94%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                },
                                                            ]}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={
                                                                        styles.labelNormal
                                                                    }
                                                                >
                                                                    {code23}
                                                                </Text>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={
                                                                        styles.textNormal
                                                                    }
                                                                >
                                                                    {code45}
                                                                </Text>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    paddingTop:
                                                                        '5',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={[
                                                                        styles.labelNormal,
                                                                        {
                                                                            width: '50',
                                                                            fontWeight:
                                                                                '500',
                                                                        },
                                                                    ]}
                                                                >
                                                                    NOTAM #:
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        styles.textNormal
                                                                    }
                                                                >
                                                                    {`${enroutenotam.series}${enroutenotam.number}/${enroutenotam.year}`}
                                                                </Text>
                                                            </div>

                                                            <div
                                                                // wrap={false}
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={[
                                                                        styles.labelNormal,
                                                                        {
                                                                            width: '50',
                                                                            fontWeight:
                                                                                '500',
                                                                        },
                                                                    ]}
                                                                >
                                                                    Affects:
                                                                </Text>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    }}
                                                                >
                                                                    {traffic.ifr && (
                                                                        <Text
                                                                            style={
                                                                                styles.ifrBadge
                                                                            }
                                                                        >
                                                                            IFR
                                                                        </Text>
                                                                    )}
                                                                    {traffic.vfr && (
                                                                        <Text
                                                                            style={
                                                                                styles.vfrBadge
                                                                            }
                                                                        >
                                                                            VFR
                                                                        </Text>
                                                                    )}
                                                                    <Text
                                                                        style={
                                                                            styles.textNormal
                                                                        }
                                                                    >
                                                                        TRAFFIC
                                                                    </Text>
                                                                </div>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={[
                                                                        styles.labelNormal,
                                                                        {
                                                                            width: '50',
                                                                            fontWeight:
                                                                                '500',
                                                                        },
                                                                    ]}
                                                                >
                                                                    From:
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        styles.textNormal
                                                                    }
                                                                >
                                                                    {enroutenotam?.startvalidity &&
                                                                        formatDateTime(
                                                                            enroutenotam.startvalidity,
                                                                            timeZone
                                                                        )}
                                                                </Text>
                                                            </div>

                                                            {!enroutenotam.permanent && (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            styles.labelNormal,
                                                                            {
                                                                                width: '50',
                                                                                fontWeight:
                                                                                    '500',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        To:
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            styles.textNormal
                                                                        }
                                                                    >
                                                                        {enroutenotam?.endvalidity &&
                                                                            formatDateTime(
                                                                                enroutenotam.endvalidity,
                                                                                timeZone
                                                                            )}

                                                                        {enroutenotam?.estimation &&
                                                                            ' (Estimated)'}
                                                                    </Text>
                                                                </div>
                                                            )}

                                                            {enroutenotam.itemd &&
                                                                enroutenotam.itemd !==
                                                                    'null' && (
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={[
                                                                                styles.labelNormal,
                                                                                {
                                                                                    width: '50',
                                                                                    fontWeight:
                                                                                        '500',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            Period:
                                                                        </Text>
                                                                        <Text
                                                                            style={
                                                                                styles.textNormal
                                                                            }
                                                                        >
                                                                            {enroutenotam.itemd +
                                                                                ' (UTC if time shown)'}
                                                                        </Text>
                                                                    </div>
                                                                )}

                                                            {enroutenotam.itemf && (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            styles.labelNormal,
                                                                            {
                                                                                width: '50',
                                                                                fontWeight:
                                                                                    '500',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        Altitude:
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            styles.textNormal
                                                                        }
                                                                    >
                                                                        {enroutenotam.lowerlimit +
                                                                            ' – ' +
                                                                            enroutenotam.upperlimit}
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {enroutenotam.itema &&
                                                            enroutenotam.scope
                                                                .split('')
                                                                .includes(
                                                                    'A'
                                                                ) && (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        paddingTop:
                                                                            '5',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={[
                                                                            styles.labelNormal,
                                                                            {
                                                                                width: '50',
                                                                                fontWeight:
                                                                                    '500',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        Aerodrome:
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            styles.textNormal
                                                                        }
                                                                    >
                                                                        {
                                                                            enroutenotam.itema
                                                                        }
                                                                    </Text>
                                                                </div>
                                                            )}

                                                        <Text
                                                            style={[
                                                                styles.textNormal,
                                                                {
                                                                    marginTop:
                                                                        '5',
                                                                },
                                                            ]}
                                                        >
                                                            {enroutenotam.iteme}
                                                        </Text>
                                                    </div>
                                                </div>
                                                {i + 1 !==
                                                    enroutenotam.length && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            borderBottom:
                                                                '1px solid #f5f5f5',
                                                            marginTop: '10',
                                                            marginBottom: '10',
                                                        }}
                                                    ></div>
                                                )}
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                }
                            )}
                        {briefingData && !briefingData.res.enroutenotam && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Text
                                    style={[
                                        styles.textNormal,
                                        { paddingTop: '10pt' },
                                    ]}
                                >
                                    No En-route NOTAM information is available
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </View>
        )
    }
    return null
}
