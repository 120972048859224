import React from 'react'
import { Flex, Text, useBreakpointValue, Icon } from '@chakra-ui/react'
import WindIcon from '../../../../../icons/menu-icons/WindIcon'
import {
    WindDirectionDial,
    WindSpeedDial,
} from '../../graphics/WeatherGraphics'

export default function AerodromesWind(props) {
    const {
        latestMetar,
        metarActive,
        timePeriod,
        aerodromeBackgroundsDisabled,
        cloudCoverBoxColor,
        metarCloud,
        compassRoseMagVar,
        magvar,
        windvrb,
        winddirDegreesMag,
        winddir,
        windspeed,
        windgust,
        infotabGraphicsDisabled,
    } = props

    const windImageSize = useBreakpointValue({
        base: '130',
        md: '96',
    })

    return (
        <Flex
            width={metarActive ? '100%' : 'auto'}
            background={
                !infotabGraphicsDisabled &&
                (metarActive && !aerodromeBackgroundsDisabled
                    ? cloudCoverBoxColor(
                          timePeriod === 'Night'
                              ? 'Night'
                              : timePeriod === 'Sunrise'
                              ? 'Sunrise'
                              : timePeriod === 'Sunset'
                              ? 'Sunset'
                              : metarCloud
                              ? metarCloud
                              : 'white'
                      )
                    : 'white')
            }
            border={metarActive ? 'none' : '1px solid rgb(229 235 242)'}
            boxShadow={
                !infotabGraphicsDisabled && aerodromeBackgroundsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            backdropFilter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'blur(25px) contrast(102%) saturate(102%)'
            }
            borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            pt="10px"
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '7px' : '10px'}
            direction={{
                base: 'column',
                md: 'column',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'space-between',
                    md: 'flex-start',
                }}
                pb="4px"
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color={
                        aerodromeBackgroundsDisabled
                            ? '#9db2cd'
                            : 'rgba(255,255,255,0.6)'
                    }
                >
                    <WindIcon boxSize="36px" />
                </Flex>
                <Flex
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Flex
                        fontSize={{
                            base: '1.4rem',
                            md: '1.2rem',
                        }}
                        lineHeight="1.2"
                        pt={{
                            base: '4px',
                            md: '5px',
                        }}
                        pl={infotabGraphicsDisabled ? '15px' : '8px'}
                        fontWeight="600"
                        whiteSpace="pre-line"
                    >
                        <Text>
                            {windvrb
                                ? 'VRB '
                                : compassRoseMagVar
                                ? winddirDegreesMag
                                      .toString()
                                      .padStart(3, '0') + '°M '
                                : winddir && winddir.value
                                ? winddir.value.toString().padStart(3, '0') +
                                  '°T '
                                : ''}
                        </Text>
                        <Text pl="13px">
                            {windspeed && windspeed.value + ' kt'}
                        </Text>
                    </Flex>
                    <Flex
                        direction="column"
                        alignItems="flex-end"
                        color="light.10"
                        pt="5px"
                        pb="5px"
                        pr="5px"
                        opacity="0.8"
                    >
                        {latestMetar.observation[0].surfaceWind
                            .extremeClockwiseWindDirection &&
                            latestMetar.observation[0].surfaceWind
                                .extremeClockwiseWindDirection && (
                                <Text
                                    fontSize={{
                                        base: '0.9rem',
                                        md: '0.8rem',
                                    }}
                                    height="20px"
                                    fontWeight="500"
                                    whiteSpace="pre-line"
                                    opacity="0.7"
                                    mt={windgust ? '-2px' : '2px'}
                                >
                                    {latestMetar.observation[0].surfaceWind
                                        .extremeClockwiseWindDirection
                                        ? (
                                              latestMetar.observation[0]
                                                  .surfaceWind
                                                  .extremeCounterClockwiseWindDirection
                                                  .value +
                                              (compassRoseMagVar
                                                  ? latestMetar.observation[0]
                                                        .surfaceWind
                                                        .extremeCounterClockwiseWindDirection
                                                        .value <= magvar
                                                      ? -magvar + 360
                                                      : -magvar
                                                  : 0)
                                          )
                                              .toString()
                                              .padStart(3, '0') +
                                          '°' +
                                          ' V ' +
                                          (
                                              latestMetar.observation[0]
                                                  .surfaceWind
                                                  .extremeClockwiseWindDirection
                                                  .value +
                                              (compassRoseMagVar
                                                  ? latestMetar.observation[0]
                                                        .surfaceWind
                                                        .extremeClockwiseWindDirection
                                                        .value <= magvar
                                                      ? -magvar + 360
                                                      : -magvar
                                                  : 0)
                                          )
                                              .toString()
                                              .padStart(3, '0') +
                                          (compassRoseMagVar ? '°M' : '°T')
                                        : ''}
                                </Text>
                            )}
                        {windgust && (
                            <Text
                                mt={
                                    latestMetar.observation[0].surfaceWind
                                        .extremeClockwiseWindDirection
                                        ? '0px'
                                        : '2px'
                                }
                                fontSize={{
                                    base: '0.9rem',
                                    md: '0.8rem',
                                }}
                                height="20px"
                                fontWeight="500"
                                whiteSpace="pre-line"
                                opacity="0.7"
                            >
                                {windgust
                                    ? 'Gusts ' + windgust.value + ' kt'
                                    : ''}
                            </Text>
                        )}
                    </Flex>
                </Flex>
            </Flex>

            {!infotabGraphicsDisabled && (
                <Flex
                    gap={{
                        base: '20px',
                        xs: '28px',
                        md: '10px',
                    }}
                    pb={{
                        base: '10px',
                        md: '5px',
                    }}
                    pl={{
                        base: '23px',
                        md: '3px',
                    }}
                    pr={{
                        base: '20px',
                        md: '0px',
                    }}
                    width="100%"
                    justifyContent={{
                        base: 'space-evenly',
                        md: 'space-evenly',
                    }}
                    alignItems={{
                        base: 'center',
                        md: 'center',
                    }}
                    flexDirection={{
                        base: 'row',
                        md: 'row',
                    }}
                >
                    <Icon // WIND DIRECTION
                        as={WindDirectionDial}
                        imageSize={windImageSize}
                        primaryImageColor={
                            aerodromeBackgroundsDisabled ? '#3e5e88' : 'white'
                        }
                        focusImageColor={'#ff8e00'}
                        imageColor={
                            aerodromeBackgroundsDisabled ? '#83909F' : 'white'
                        }
                        winddir={
                            compassRoseMagVar
                                ? winddirDegreesMag
                                : winddir.value
                        }
                        compassRoseMagVar={compassRoseMagVar}
                    />
                    <Icon // WIND SPEED
                        as={WindSpeedDial}
                        imageSize={windImageSize}
                        primaryImageColor={
                            aerodromeBackgroundsDisabled ? '#3e5e88' : 'white'
                        }
                        focusImageColor={'#00f1ab'}
                        imageColor={
                            aerodromeBackgroundsDisabled ? '#83909F' : 'white'
                        }
                        windspeed={windspeed.value}
                    />
                </Flex>
            )}
        </Flex>
    )
}
