import { useEffect, useRef, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Marker } from 'react-mapbox-gl'
import { useToast, useBreakpointValue, Box, Icon } from '@chakra-ui/react'

import { ReactComponent as POIIcon } from './assets/POI.svg'

import {
    clickedFeaturesState,
    locationToastActiveState,
    modalVisibilityState,
    errorBadgeState,
} from '../../../globalState'
import './assets/aerodrome-popup.css'

import locationIcon from './assets/location.svg'

export default function SearchedLocationFeatureLayer() {
    const clickedFeatures = useRecoilValue(clickedFeaturesState)
    const toast = useToast()
    const toastIdRef = useRef()

    const modalVisibility = useRecoilValue(modalVisibilityState)
    const errorMessageModal = modalVisibility.error
    const errorBadgeVisible = useRecoilValue(errorBadgeState)
    const errorBadge = !errorMessageModal && errorBadgeVisible
    const setToastActive= useSetRecoilState(
        locationToastActiveState
    )

    const close = useCallback(() => {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current)
            setToastActive(false)
        }
    }, [toast, setToastActive])

    const closeAll = useCallback(() => {
        toast.closeAll()
        setToastActive(false)
    }, [toast, setToastActive])

    const toastAlignment = useBreakpointValue({
        base: 'top',
        md: 'top-right',
    })

    const addToast = useCallback(
        (title) => {
            toastIdRef.current = toast({
                duration: null,
                position: toastAlignment,
                containerStyle: {
                    minWidth: '200px',
                    zIndex: '1501',
                },
                render: () => (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        py={1}
                        pl={2}
                        pr={4}
                        borderRadius="12px"
                        backdropFilter="blur(5px)"
                        bg="rgba(0, 45, 220, 0.5)"
                        marginTop={{
                            base: errorBadge ? '110px' : '62px',
                            md: '12px',
                        }}
                        marginLeft={{ base: '62px', md: 'unset' }}
                        marginRight={{ base: '62px', md: '60px' }}
                        fontSize="0.8rem"
                        overflow="hidden"
                    >
                        <Icon as={POIIcon} color="white" boxSize="6" pr="5px" />
                        {title}
                    </Box>
                ),
            })
            setToastActive(true)
        },
        [toast, toastAlignment, setToastActive, errorBadge]
    )

    useEffect(() => {
        if (
            clickedFeatures &&
            clickedFeatures.length === 1 &&
            clickedFeatures[0].type === 'Feature' &&
            clickedFeatures[0].layer !== 'aerodromes'
        ) {
            close()
            addToast(clickedFeatures[0].place_name)
        } else {
            closeAll()
        }
    }, [addToast, clickedFeatures, close, closeAll])

    return (
        <>
            {clickedFeatures &&
                clickedFeatures.length === 1 &&
                clickedFeatures[0].type === 'Feature' &&
                clickedFeatures[0].layer !== 'aerodromes' && (
                    <Marker
                        coordinates={clickedFeatures[0].geometry.coordinates}
                        anchor="bottom"
                    >
                        <img src={locationIcon} alt="location-icon" />
                    </Marker>
                )}
        </>
    )
}
