import React from 'react'
import { useRecoilValue } from 'recoil'

import {
    dataModeState,
    layerSelectionState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../globalState'

import { useTier } from '../../../auth/hooks'

import SigmetLayer from './SIGMET'
import SigWxLayer from './SIGWX'
import GraforLayer from './GRAFOR'
import AawLayer from './AAW'
import RainRadarLayer from './RainRadar'
import RainRadarWxTilesLayer from './RainRadarWxTiles'
import LightningLayer from './Lightning'
import WebcamsLayer from './Webcams'
import AwsLayer from './AWS'
import SatelliteInfraredCloudLayer from './SatelliteInfraredCloud'
import SatelliteCloudTopsLayer from './SatelliteCloudTops'
import SatelliteInfraredEnhancedLayer from './SatelliteInfraredEnhancedCloud'
import SatelliteVisibleCloudLayer from './SatelliteVisibleCloud'
import AdvisoriesList from '../components/advisoriesList'
import AdvisoriesMetaData from '../components/AdvisoriesMetaData'

export default function MetLayers() {
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const userTier = useTier()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    if (dataMode === 'met') {
        return (
            <>
                {layerSelection.met.sigwx && !isPremium && <SigWxLayer />}
                {layerSelection.met.sigmet && !isPremium && <SigmetLayer />}
                {layerSelection.met.grafor && <GraforLayer />}
                {layerSelection.met.aaw && <AawLayer />}
                {layerSelection.met.rainradar && <RainRadarLayer />}
                {layerSelection.met.rainRadarWxTiles && (
                    <RainRadarWxTilesLayer />
                )}
                {(layerSelection.met.lightning ||
                    layerSelection.met.rainradar ||
                    layerSelection.met.rainRadarWxTiles) && <LightningLayer />}
                {layerSelection.met.satelliteCloudVisible && (
                    <SatelliteVisibleCloudLayer />
                )}
                {layerSelection.met.satelliteCloudTops && (
                    <SatelliteCloudTopsLayer />
                )}
                {layerSelection.met.satelliteCloudInfrared && (
                    <SatelliteInfraredCloudLayer />
                )}
                {layerSelection.met.satelliteCloudInfraredEnhanced && (
                    <SatelliteInfraredEnhancedLayer />
                )}
                {(layerSelection.met.sigmet || layerSelection.met.sigwx) &&
                    !isPremium && <AdvisoriesList />}
                {layerSelection.met.sigwx && !isPremium && (
                    <AdvisoriesMetaData layerSelection={layerSelection} />
                )}
                {layerSelection.met.webcams && !isPremium && <WebcamsLayer />}
                {layerSelection.met.aws && !isPremium && <AwsLayer />}
            </>
        )
    } else {
        return null
    }
}
