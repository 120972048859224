import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { usePubNub } from 'pubnub-react'
import {
    Box,
    Center,
    Checkbox,
    Divider,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Flex,
    LightMode,
    Stack,
    Spinner,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    Badge,
} from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useQuery } from 'react-query'
import parse from 'html-react-parser'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useSetRecoilState } from 'recoil'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { FiRefreshCcw } from 'react-icons/fi'
import { MdOutlineCancel, MdPublish } from 'react-icons/md'
import { TiTick } from 'react-icons/ti'
import { MdOutlineMessage, MdOutlineSmsFailed } from 'react-icons/md'
import { format, isAfter } from 'date-fns'

import formatDateTime from '../../../util/dateFormatter'
import { setModalVisibilityState, timeZoneState } from '../../../globalState'

const noFocus = {
    _focus: { boxShadow: 'none' },
    _focusVisible: { boxShadow: 'none !important' },
}

function returnHTML(eState) {
    return (
        eState !== null && draftToHtml(convertToRaw(eState.getCurrentContent()))
    )
}

function submitMessage(item, user, accessToken) {
    return fetch(`${window.location.origin}/data/dev/submitmessage`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            action: 'new',
            starttime: format(item.starttime, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
            endtime:
                item.endtime &&
                format(item.endtime, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
            permanent: !item.endtime ? true : false,
            user: user.name.replaceAll(`'`, `"`),
            content: {
                text: returnHTML(item.message),
            },
        }),
    })
}

function editMessage(item, user, accessToken) {
    return fetch(`${window.location.origin}/data/dev/submitmessage`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            action: 'edit',
            id: item.id,
            starttime: format(item.starttime, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
            endtime:
                item.endtime &&
                format(item.endtime, "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
            permanent: !item.endtime ? true : false,
            user: user.name.replaceAll(`'`, `"`),
            content: {
                text: returnHTML(item.message),
            },
        }),
    })
}

function deleteMessage(id, user, accessToken) {
    return fetch(`${window.location.origin}/data/dev/submitmessage`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            action: 'delete',
            id: id,
            user: user.name.replaceAll(`'`, `"`),
        }),
    })
}

function republishMessage(id, user, accessToken) {
    return fetch(`${window.location.origin}/data/dev/submitmessage`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            action: 'republish',
            id: id,
            user: user.name.replaceAll(`'`, `"`),
        }),
    })
}

function EditView({
    editingItem,
    user,
    accessToken,
    saveItemHandler,
    saveItemFailure,
    deleteItemHandler,
    cancelHandler,
    activeMessage,
}) {
    const [editorState, setEditorState] = useState(null)
    const [starttime, setStarttime] = useState(null)
    const [endtime, setEndtime] = useState(null)
    const [checked, setChecked] = useState(true)

    useEffect(() => {
        setEditorState(editingItem.message)
        editingItem.starttime && setStarttime(new Date(editingItem.starttime))
        editingItem.endtime && setEndtime(new Date(editingItem.endtime))
    }, [editingItem])

    // Setup PubNub to send message to refresh channel
    const pubnub = usePubNub()

    // publish
    const sendMessage = useCallback(
        async (message) => {
            const channels = ['loginMessage']
            await pubnub.publish({
                channel: channels[0],
                message,
            })
        },
        [pubnub]
    )

    function saveItem() {
        if (editingItem.id) {
            console.log(
                'Updating service message: ',
                user.name.replaceAll(`'`, `"`) === editingItem.user
            )
            const newMessage = {
                id: editingItem.id,
                message: editorState,
                starttime,
                endtime,
            }
            editMessage(newMessage, user, accessToken)
                .then((data) => {
                    // console.log({ data })
                    console.log('Service message update success!')
                    sendMessage('update')
                    saveItemHandler()
                })
                .catch((err) => {
                    console.log('Service message update failure!', err)
                    saveItemFailure(err)
                })
        } else {
            console.log('Saving service message')
            const newMessage = { message: editorState, starttime, endtime }
            submitMessage(newMessage, user, accessToken)
                .then((data) => {
                    // console.log({ data })
                    saveItemHandler()
                    sendMessage('update')
                })
                .catch((err) => {
                    saveItemFailure(err)
                })
        }
    }

    function isValid() {
        return (
            editorState &&
            starttime &&
            editorState.getCurrentContent().hasText()
        )
    }

    const showDateError =
        checked && starttime && endtime && starttime >= endtime

    // console.log('editingItem.id', editingItem)

    return (
        <Flex
            direction="column"
            w="100%"
            minH={'480px'}
            justifyContent="space-between"
            p="0px"
        >
            <Flex
                direction="column"
                w="100%"
                justifyContent="space-evenly"
                p="0px"
                pb="5px"
            >
                <Box
                    bg="white"
                    border="1px solid #e2e4e8"
                    boxShadow="0px 5px 20px -12px rgba(0,0,0,0.5)"
                    borderRadius="15px"
                    padding="10px"
                    margin="20px"
                    minHeight="260px"
                >
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={setEditorState}
                        stripPastedStyles={true}
                        toolbar={{
                            options: [
                                'blockType',
                                'fontSize',
                                'colorPicker',
                                'inline',
                                'list',
                                'textAlign',
                                'link',
                                // 'image',
                                'remove',
                                'history',
                            ],
                            inline: {
                                inDropdown: false,
                                className: 'font-style-buttons',
                                component: undefined,
                                options: ['bold', 'italic', 'underline'],
                            },
                            blockType: {
                                inDropdown: true,
                                options: ['Normal', 'H1', 'H2', 'H3'],
                            },
                            fontSize: {
                                options: [10, 11, 12, 14, 16, 18],
                            },
                            colorPicker: {
                                colors: [
                                    'rgb(42, 55, 68)',
                                    'rgb(0 213 153)',
                                    'rgb(174 185 193)',
                                    'rgb(209,213,216)',
                                    'rgb(231 235 237)',
                                    'rgb(101 132 163)',
                                    'rgb(71,85,119)',
                                    'rgb(235,107,86)',
                                    'rgb(226,80,65)',
                                    'rgb(247,218,100)',
                                    'rgb(251,160,38)',
                                    'rgb(84,172,210)',
                                    'rgb(44,130,201)',
                                    'rgb(147,101,184)',
                                ],
                            },
                        }}
                    />
                </Box>
                <Flex
                    background="light.10"
                    direction={{ base: 'column-reverse', md: 'row' }}
                    justifyContent={{ base: 'flex-start', lg: 'space-between' }}
                    alignItems={{ base: 'flex-start', lg: 'flex-start' }}
                    border="1px solid #e2e4e8"
                    boxShadow="0px 5px 20px -12px rgba(0,0,0,0.5)"
                    borderRadius="15px"
                    px="15px"
                    py="14px"
                    mx="20px"
                    mb="20px"
                    gap={{ base: '10px', md: '5px' }}
                >
                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        justifyContent={{
                            base: 'flex-start',
                            lg: 'flex-start',
                        }}
                        alignItems={{ base: 'flex-start', lg: 'center' }}
                        flexWrap="wrap"
                    >
                        <Flex
                            flexWrap="wrap"
                            direction={{ base: 'column', sm: 'row' }}
                            justifyContent={{
                                base: 'flex-start',
                                md: 'flex-start',
                            }}
                            alignItems={{
                                base: 'flex-start',
                                md: 'center',
                            }}
                            gap="15px"
                        >
                            <Box p="5px" pt="0px">
                                <Text
                                    mb="5px"
                                    fontSize="0.8rem"
                                    fontWeight="600"
                                    minHeight="20px"
                                >
                                    Start:
                                </Text>
                                <DatePicker
                                    selected={starttime}
                                    onChange={(date) => setStarttime(date)}
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </Box>
                            <Box p="5px" pt="0px">
                                <Text
                                    mb="5px"
                                    fontSize="0.8rem"
                                    fontWeight="600"
                                    minHeight="20px"
                                    opacity={!checked && 0.3}
                                >
                                    End:
                                </Text>
                                <Flex
                                    direction={{ base: 'column', md: 'row' }}
                                    justifyContent={{
                                        base: 'flex-start',
                                        md: 'flex-start',
                                    }}
                                    alignItems={{
                                        base: 'flex-start',
                                        md: 'center',
                                    }}
                                    gap="10px"
                                >
                                    <Flex>
                                        <DatePicker
                                            selected={endtime}
                                            onChange={(date) =>
                                                setEndtime(date)
                                            }
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            disabled={!checked}
                                            minDate={starttime}
                                            style={{
                                                opacity: !checked && 0.3,
                                            }}
                                        />
                                    </Flex>
                                    <Checkbox
                                        p="5px"
                                        size="lg"
                                        minHeight="24px"
                                        colorScheme="teal"
                                        isChecked={checked}
                                        onChange={(e) =>
                                            setChecked(e.target.checked)
                                        }
                                    >
                                        <Text
                                            fontSize="0.8rem"
                                            fontWeight="500"
                                        >
                                            {'End Date and Time'}
                                        </Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                        {showDateError && (
                            <Text
                                pl={{ base: '6px', md: '230px', lg: '20px' }}
                                pr={{ base: '6px', md: '15px' }}
                                pt={{ base: '5px', lg: '20px' }}
                                pb={{ base: '5px', lg: '0px' }}
                                fontSize="0.8rem"
                                fontWeight="600"
                                color="red.500"
                                minHeight="20px"
                                width={{ base: '100%', md: 'auto' }}
                                textAlign={{ base: 'center', md: 'left' }}
                            >
                                End time must be after Start time
                            </Text>
                        )}
                    </Flex>
                    {activeMessage && (
                        <Flex
                            width={{ base: '100%', md: 'auto' }}
                            justifyContent="flex-end"
                        >
                            <Badge
                                transition="all ease 200ms, opacity ease 300ms, visibility ease 300ms"
                                variant="solid"
                                background="light.200"
                                color="white"
                                borderRadius="xl"
                                height="22px"
                                maxHeight="22px"
                                width="70px"
                                minWidth="20px"
                                textAlign="center"
                                fontSize="0.8rem"
                                fontWeight="600"
                                letterSpacing="0.5px"
                                justifyContent="center"
                                overflow="hidden"
                                alignItems="center"
                                display="flex"
                                paddingStart="3px"
                                paddingEnd="3px"
                            >
                                <Text>ACTIVE</Text>
                            </Badge>
                        </Flex>
                    )}
                </Flex>
            </Flex>
            <Flex
                zIndex="4"
                px="15px"
                py="15px"
                borderTop="1px solid #eaeaea"
                mx={{
                    base: 'auto',
                    md: '0px',
                }}
                justifyContent={{
                    base: 'center',
                    md: 'flex-end',
                }}
                alignItems="center"
                transition="all 300ms"
                height={{ base: '105px', md: '65px' }}
                bg="white"
                width="100%"
            >
                {!isValid() && (
                    <Text
                        color="light.100"
                        fontSize="0.85rem"
                        fontWeight="500"
                        px="15px"
                    >
                        Add a Message and Start Date
                    </Text>
                )}
                <Stack
                    direction={{
                        base: isValid() ? 'row' : 'column',
                        md: 'row',
                    }}
                    transition="all 300ms"
                    spacing={{
                        base: '2',
                        md: '3',
                    }}
                    align="center"
                    px="5px"
                >
                    <Button
                        width="100%"
                        minWidth="100px"
                        pl="15px"
                        pr="20px"
                        transition="all 300ms"
                        borderRadius="10px"
                        variant="outline"
                        color="gray.600"
                        borderColor="gray.200"
                        background="gray.100"
                        _hover={{
                            background: 'gray.200',
                            borderColor: 'gray.300',
                        }}
                        _active={{
                            background: 'gray.200',
                            borderColor: 'gray.300',
                        }}
                        size="sm"
                        leftIcon={<MdOutlineCancel fontSize="1rem" />}
                        colorScheme="gray"
                        onClick={() => cancelHandler()}
                        {...noFocus}
                    >
                        Cancel
                    </Button>
                    {editingItem?.id && (
                        <Button
                            color="white"
                            width="100%"
                            minWidth="100px"
                            pl="15px"
                            pr="20px"
                            transition="all 300ms"
                            borderRadius="10px"
                            background="#FF4F44"
                            _hover={{
                                background: '#E34339',
                            }}
                            _active={{
                                background: '#E34339',
                            }}
                            size="sm"
                            leftIcon={<AiFillDelete />}
                            colorScheme="red"
                            onClick={() => deleteItemHandler(editingItem)}
                            {...noFocus}
                        >
                            Delete
                        </Button>
                    )}
                    {isValid() && (
                        <Button
                            variant="primary"
                            background={isValid() ? 'light.200' : 'light.10'}
                            color={isValid() ? 'light.10' : 'light.100'}
                            size="sm"
                            pl="15px"
                            pr="20px"
                            // transition="all 300ms"
                            border={'none'}
                            style={{
                                borderRadius: '10px',
                            }}
                            width="100%"
                            minWidth={isValid() ? '100px' : '270px'}
                            isDisabled={
                                !isValid() ||
                                showDateError ||
                                (checked && !endtime)
                            }
                            onClick={() => saveItem(accessToken)}
                            mr={{ base: '0px', md: '10px' }}
                            leftIcon={
                                isValid() && <MdPublish fontSize="1.2rem" />
                            }
                            {...noFocus}
                            _hover={{
                                background: isValid()
                                    ? 'light.201'
                                    : 'light.201',
                            }}
                            _active={{
                                background: isValid()
                                    ? 'light.201'
                                    : 'light.201',
                            }}
                        >
                            {activeMessage ? 'Update' : 'Publish'}
                        </Button>
                    )}
                </Stack>
            </Flex>
        </Flex>
    )
}

function Message({
    index,
    info,
    edit,
    deleteItem,
    republishItem,
    republishSuccess,
    deleteSuccess,
    loading,
    current,
    setActiveMessage,
}) {
    // console.log({ republishItem })
    const timeZone = useRecoilValue(timeZoneState)
    const isActive = Boolean(current)

    return (
        <Flex
            bg="light.10"
            direction={'column'}
            mx="25px"
            mt="0px"
            mb="25px"
            p="0px"
            overflow="hidden"
            border="1px solid #e2e4e8"
            boxShadow="0px 5px 20px -12px rgba(0,0,0,0.5)"
            borderRadius="20px"
        >
            <Flex
                bg="light.30"
                w="100%"
                px="20px"
                py="10px"
                fontSize="0.9rem"
                fontWeight="600"
                borderBottom="1px solid #e2e4e8"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text>{`Message ${index + 1}`}</Text>
                {isActive && (
                    <Badge
                        transition="all ease 200ms, opacity ease 300ms, visibility ease 300ms"
                        variant="solid"
                        background="light.200"
                        color="white"
                        borderRadius="xl"
                        height="22px"
                        maxHeight="22px"
                        width="70px"
                        minWidth="20px"
                        textAlign="center"
                        fontSize="0.8rem"
                        fontWeight="600"
                        letterSpacing="0.5px"
                        justifyContent="center"
                        overflow="hidden"
                        alignItems="center"
                        display="flex"
                        paddingStart="3px"
                        paddingEnd="3px"
                    >
                        <Text>ACTIVE</Text>
                    </Badge>
                )}
            </Flex>
            <Flex direction="column" justifyContent={'space-between'}>
                <Flex
                    direction="column"
                    alignItems="flex-start"
                    gap="10px"
                    w="100%"
                    px="20px"
                    py="15px"
                    fontSize="0.8rem"
                >
                    {info.message && parse(returnHTML(info.message))}
                </Flex>
                <Divider />
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    fontSize="0.8rem"
                    w="100%"
                >
                    {info.starttime && (
                        <Flex
                            py="10px"
                            px="20px"
                            w={{ base: '100%', md: 'auto' }}
                            minWidth="250px"
                        >
                            <Text pr="5px" fontWeight="600">
                                Starts:
                            </Text>
                            <Text>
                                {info?.starttime &&
                                    formatDateTime(info.starttime, timeZone)}
                            </Text>
                        </Flex>
                    )}

                    {info.endtime && (
                        <>
                            <Flex
                                pt={{ base: '0', md: '10px' }}
                                pb="10px"
                                px="20px"
                                w="100%"
                            >
                                <Text pr="5px" fontWeight="600">
                                    Ends:
                                </Text>
                                <Text>
                                    {info?.endtime &&
                                        formatDateTime(info.endtime, timeZone)}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <Flex
                    zIndex="4"
                    px="15px"
                    pt="15px"
                    pb="15px"
                    borderTop="1px solid #eaeaea"
                    mx={{
                        base: 'auto',
                        md: '0px',
                    }}
                    justifyContent={{
                        base: 'center',
                        md: 'flex-end',
                    }}
                    height="65px"
                    bg="white"
                    width="100%"
                >
                    <Stack
                        direction="row"
                        spacing={{
                            base: '4',
                            md: '4',
                        }}
                        align="center"
                        px="5px"
                    >
                        <Button
                            variant="primary"
                            color="light.100"
                            background="light.10"
                            size="sm"
                            transition="all 300ms"
                            px="20px"
                            border="1px solid #E1E7EE"
                            style={{
                                borderRadius: '10px',
                            }}
                            width="100%"
                            minWidth="100px"
                            colorScheme={info.editing ? 'blue' : 'orange'}
                            onClick={() => {
                                edit(info)
                                if (current) {
                                    setActiveMessage(true)
                                }
                            }}
                            mr="5px"
                            leftIcon={<AiFillEdit />}
                            {...noFocus}
                            _hover={{
                                background: info.editing
                                    ? 'light.20'
                                    : 'light.20',
                            }}
                            _active={{
                                background: info.editing
                                    ? 'light.20'
                                    : 'light.20',
                            }}
                        >
                            EDIT
                        </Button>
                        <Button
                            color="white"
                            width="100%"
                            isLoading={loading}
                            isDisabled={deleteSuccess}
                            minWidth="100px"
                            px="20px"
                            borderRadius="10px"
                            background={info.deleted ? 'light.200' : '#FF4F44'}
                            _hover={{
                                background: info.deleted
                                    ? '#00C599'
                                    : '#E34339',
                            }}
                            _active={{
                                background: info.deleted
                                    ? '#00C599'
                                    : '#E34339',
                            }}
                            size="sm"
                            leftIcon={
                                info.deleted ? (
                                    <FiRefreshCcw />
                                ) : deleteSuccess ? (
                                    <TiTick />
                                ) : (
                                    <AiFillDelete />
                                )
                            }
                            colorScheme={info.deleted ? 'green' : 'red'}
                            onClick={() =>
                                info.deleted
                                    ? republishItem(info)
                                    : deleteItem(info)
                            }
                            {...noFocus}
                        >
                            {info.deleted
                                ? republishSuccess
                                    ? 'Success'
                                    : 'Republish'
                                : deleteSuccess
                                ? 'Success'
                                : 'Delete'}
                        </Button>
                    </Stack>
                </Flex>
            </Flex>
        </Flex>
    )
}

function NoMessages({ message }) {
    return (
        <Center w="100%" h="200px">
            <Flex direction="column" alignItems={'center'}>
                <MdOutlineMessage size={50} color={'#e2e4e8'} />
                <Text mt="10px" color={'#e2e4e8'} fontWeight={'bold'}>
                    {message}
                </Text>
            </Flex>
        </Center>
    )
}

export default function ManageNotifications() {
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setModalVisible = useCallback(
        (value) => {
            setModalVisibility({ id: 'loginMessage', value })
        },
        [setModalVisibility]
    )

    const [messages, setMessages] = useState([])
    const [expiredMessages, setExpiredMessages] = useState([])
    const [deletedMessages, setDeletedMessages] = useState([])
    const [currentMessages, setCurrentMessages] = useState([])
    const [activeMessage, setActiveMessage] = useState(false)
    const [newMessage, setNewMessage] = useState(false)
    const { user, getAccessTokenSilently } = useAuth0()
    const [deleteError, setDeleteError] = useState(false)
    const [deleteSuccess, setDeleteSuccess] = useState(false)
    const [deleteInProgress, setDeleteInProgress] = useState(false)
    const [republishSuccess, setRepublishSuccess] = useState(false)
    const [republishInProgress, setRepublishInProgress] = useState(false)
    const [messageError, setMessageError] = useState(false)
    const [selectedEditingItem, setSelectedEditingItem] = useState(null)
    const { onClose } = useDisclosure()

    const [accessToken, setAccessToken] = useState(null)

    useEffect(() => {
        const fetchAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        fetchAccessToken()
    }, [getAccessTokenSilently])

    const fetchMessages = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/dev/viewmessages`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const { data, refetch, isLoading, isLoadingError } = useQuery(
        'messages',
        fetchMessages,
        {
            refetchOnWindowFocus: false,
        }
    )

    useEffect(() => {
        const itemInEdit = messages.filter((i) => i.editing)[0]
        itemInEdit && setSelectedEditingItem(itemInEdit)
    }, [messages])

    function htmlToDraftJSHandler(html) {
        const blocksFromHtml = htmlToDraft(html)
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    }

    useEffect(() => {
        if (data) {
            setDeleteInProgress(null)
            setRepublishInProgress(null)
            const expired = data.filter(
                (m) => m.endtime && isAfter(new Date(), new Date(m.endtime))
            )
            const deleted = data.filter((m) => m.deleted)
            const current = data.filter(
                (m) =>
                    !m.deleted &&
                    (!m.endtime ||
                        (m.endtime &&
                            !isAfter(new Date(), new Date(m.endtime))))
            )
            expired.length &&
                setExpiredMessages(
                    expired.map((obj) => ({
                        ...obj,
                        message: htmlToDraftJSHandler(obj.content.text),
                        savedOnDatabase: true,
                    }))
                )
            deleted.length &&
                setDeletedMessages(
                    deleted.map((obj) => ({
                        ...obj,
                        message: htmlToDraftJSHandler(obj.content.text),
                        savedOnDatabase: true,
                    }))
                )
            current.length &&
                setCurrentMessages(
                    current.map((obj) => ({
                        ...obj,
                        message: htmlToDraftJSHandler(obj.content.text),
                        savedOnDatabase: true,
                    }))
                )
        }
    }, [data])

    const addMessageToList = () => {
        setNewMessage(true)
        setMessages((m) => [
            ...m,
            {
                temporaryId: uuidv4(),
                message: null,
                editing: true,
                savedOnDatabase: false,
                isDirty: true,
            },
        ])
        const itemInEdit = messages.filter((i) => i.editing)[0]
        itemInEdit && setSelectedEditingItem(itemInEdit)
    }

    const deleteMessageFromList = (val) => {
        if (val.temporaryId) {
            setMessages((m) =>
                m.filter((i) => i.temporaryId !== val.temporaryId)
            )
            setSelectedEditingItem(null)
        } else {
            setDeleteInProgress(val.id)
            deleteMessage(val.id, user, accessToken)
                .then((data) => {
                    setDeleteSuccess(val.id)
                    setTimeout(() => setDeleteSuccess(null), 5000)
                    setTimeout(() => refetch(), 800)
                })
                .catch((err) => {
                    setDeleteError(true)
                    setTimeout(() => {
                        setDeleteError(false)
                        setDeleteInProgress(null)
                    }, 5000)
                })
        }
    }

    const republishMessageFromList = (val) => {
        setRepublishInProgress(val.id)
        republishMessage(val.id, user, accessToken)
            .then((data) => {
                setRepublishSuccess(val.id)
                setTimeout(() => setRepublishSuccess(null), 5000)
                setTimeout(() => refetch(), 800)
            })
            .catch((err) => {
                // setRepublishError(true)
                setTimeout(() => {
                    // setRepublishError(false)
                    setRepublishInProgress(null)
                }, 5000)
            })
    }

    const setMessageToEditingState = (val) => {
        setMessages((m) =>
            m.map((i) => {
                return i.id === val.id
                    ? { ...val, editing: true }
                    : { ...i, editing: false }
            })
        )
        val && setSelectedEditingItem(val)
    }

    const saveMessage = () => {
        setActiveMessage(false)
        setNewMessage(false)
        refetch()
        setSelectedEditingItem(null)
        // setMessageSuccess(true)
        setMessageError(false)
        // setLoaded(true)
        // setTimeout(() => setMessageSuccess(false), 5000)
    }

    const saveFailure = (err) => {
        setActiveMessage(false)
        setNewMessage(false)
        setSelectedEditingItem(null)
        setMessageError(true)
        // setLoaded(true)
        console.error(err)
        setTimeout(() => setMessageError(false), 5000)
    }

    const cancelHandler = (err) => {
        setActiveMessage(false)
        setNewMessage(false)
        setSelectedEditingItem(null)
        setMessages([])
    }

    function onCloseHandler() {
        setModalVisible(false)
        cancelHandler()
        onClose()
    }

    return (
        <LightMode>
            <Modal
                isCentered
                variant="primary"
                size="5xl"
                my="30px"
                onClose={onCloseHandler}
                isOpen={true}
                motionPreset="slideInBottom"
                trapFocus={false}
                scrollBehavior={'inside'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton color="white" />
                    <ModalHeader
                        backgroundColor="gray.600"
                        color="white"
                        width="100%"
                        textAlign="center"
                    >
                        {selectedEditingItem && newMessage
                            ? 'Create New Service Message'
                            : selectedEditingItem
                            ? 'Edit Service Message'
                            : 'Manage Service Messages'}
                    </ModalHeader>
                    {messageError ? (
                        <ModalBody p={'0px'} bg="light.20">
                            <Flex
                                w="100%"
                                mt="10px"
                                direction={'column'}
                                alignItems={'center'}
                            >
                                <Center w="100%" h="200px">
                                    <Flex
                                        direction="column"
                                        alignItems={'center'}
                                    >
                                        <MdOutlineSmsFailed
                                            size={50}
                                            color="red"
                                        />
                                        <Text
                                            mt="10px"
                                            color="red"
                                            fontWeight={'bold'}
                                        >
                                            Error Registering Messages
                                        </Text>
                                    </Flex>
                                </Center>
                                <Button
                                    colorScheme="red"
                                    onClick={() => setMessageError(false)}
                                    mx="10px"
                                    my="5px"
                                    w="250px"
                                >
                                    Try Again
                                </Button>
                            </Flex>
                        </ModalBody>
                    ) : (
                        <ModalBody p={'0px'} bg="light.20">
                            {deleteError && (
                                <ModalBody p={'0px'}>
                                    <Flex
                                        w="100%"
                                        mt="10px"
                                        direction={'column'}
                                        alignItems={'center'}
                                    >
                                        <Center w="100%" h="200px">
                                            <Flex
                                                direction="column"
                                                alignItems={'center'}
                                            >
                                                <MdOutlineSmsFailed
                                                    size={50}
                                                    color="red"
                                                />
                                                <Text
                                                    mt="10px"
                                                    color="red"
                                                    fontWeight={'bold'}
                                                >
                                                    Error Deleting Message
                                                </Text>
                                            </Flex>
                                        </Center>
                                        <Button
                                            colorScheme="red"
                                            onClick={() =>
                                                setDeleteError(false)
                                            }
                                            mx="10px"
                                            my="5px"
                                            w="250px"
                                        >
                                            Try Again
                                        </Button>
                                    </Flex>
                                </ModalBody>
                            )}
                            {selectedEditingItem ? (
                                <EditView
                                    editingItem={selectedEditingItem}
                                    saveItemHandler={saveMessage}
                                    saveItemFailure={saveFailure}
                                    deleteItemHandler={deleteMessageFromList}
                                    user={user}
                                    accessToken={accessToken}
                                    cancelHandler={cancelHandler}
                                    activeMessage={activeMessage}
                                />
                            ) : (
                                <Flex
                                    w="100%"
                                    direction={'column'}
                                    alignContent={'center'}
                                    bg="light.20"
                                >
                                    <Tabs variant="enclosed">
                                        <TabList
                                            mb="0px"
                                            pt="10px"
                                            px="25px"
                                            borderColor="#e9edf2"
                                            boxShadow="inset 0px -5px 10px -1px rgba(0,0,0,0.03)"
                                        >
                                            <Tab
                                                fontSize="0.9rem"
                                                fontWeight="600"
                                                {...noFocus}
                                                _selected={{
                                                    borderTopLeftRadius: '10px',
                                                    borderTopRightRadius:
                                                        '10px',
                                                    fontSize: '0.9rem',
                                                    color: 'light.200',
                                                    bg: 'light.10',
                                                    border: '1px solid #e5eaf0',
                                                    borderBottom: 'none',
                                                }}
                                            >
                                                Current
                                            </Tab>
                                            <Tab
                                                fontSize="0.9rem"
                                                fontWeight="600"
                                                {...noFocus}
                                                _selected={{
                                                    borderTopLeftRadius: '10px',
                                                    borderTopRightRadius:
                                                        '10px',
                                                    color: 'light.200',
                                                    bg: 'light.10',
                                                    border: '1px solid #e5eaf0',
                                                    borderBottom: 'none',
                                                }}
                                            >
                                                Expired
                                            </Tab>
                                            <Tab
                                                fontSize="0.9rem"
                                                fontWeight="600"
                                                {...noFocus}
                                                _selected={{
                                                    borderTopLeftRadius: '10px',
                                                    borderTopRightRadius:
                                                        '10px',
                                                    color: 'light.200',
                                                    bg: 'light.10',
                                                    border: '1px solid #e5eaf0',
                                                    borderBottom: 'none',
                                                }}
                                            >
                                                Deleted
                                            </Tab>
                                        </TabList>
                                        <TabPanels bg="light.10" pt="25px">
                                            <TabPanel padding="0">
                                                {isLoading &&
                                                    !isLoadingError && (
                                                        <Center
                                                            w="100%"
                                                            h="200px"
                                                        >
                                                            <Spinner />
                                                        </Center>
                                                    )}
                                                {!isLoading &&
                                                    isLoadingError && (
                                                        <Text color="red">
                                                            Error Loading Data
                                                        </Text>
                                                    )}
                                                {!isLoading &&
                                                    !isLoadingError &&
                                                    !currentMessages.length && (
                                                        <NoMessages message="ADD A MESSAGE" />
                                                    )}
                                                {currentMessages
                                                    .sort((a, b) =>
                                                        a.starttime <
                                                        b.starttime
                                                            ? 1
                                                            : -1
                                                    )
                                                    .map((i, idx) => {
                                                        return (
                                                            <Message
                                                                key={`currentMsg-${idx}`}
                                                                current={true}
                                                                info={i}
                                                                index={idx}
                                                                deleteItem={
                                                                    deleteMessageFromList
                                                                }
                                                                republishItem={
                                                                    republishMessageFromList
                                                                }
                                                                edit={
                                                                    setMessageToEditingState
                                                                }
                                                                deleteSuccess={
                                                                    deleteSuccess ===
                                                                    i.id
                                                                }
                                                                republishSuccess={
                                                                    republishSuccess ===
                                                                    i.id
                                                                }
                                                                loading={
                                                                    deleteInProgress ===
                                                                        i.id ||
                                                                    republishInProgress ===
                                                                        i.id
                                                                }
                                                                setActiveMessage={
                                                                    setActiveMessage
                                                                }
                                                            />
                                                        )
                                                    })}
                                                <Flex
                                                    zIndex="4"
                                                    px="15px"
                                                    pt="15px"
                                                    pb="15px"
                                                    borderTop="1px solid #eaeaea"
                                                    mx={{
                                                        base: 'auto',
                                                        md: '0px',
                                                    }}
                                                    justifyContent={{
                                                        base: 'center',
                                                        md: 'center',
                                                    }}
                                                    bg="white"
                                                    width="100%"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        spacing={{
                                                            base: '4',
                                                            md: '4',
                                                        }}
                                                        align="center"
                                                    >
                                                        <Button
                                                            variant="primary"
                                                            color="light.10"
                                                            bg="light.200"
                                                            size="sm"
                                                            px="20px"
                                                            style={{
                                                                borderRadius:
                                                                    '10px',
                                                            }}
                                                            width="100%"
                                                            minWidth="100px"
                                                            onClick={
                                                                addMessageToList
                                                            }
                                                            {...noFocus}
                                                            _hover={{
                                                                background:
                                                                    'light.201',
                                                            }}
                                                            _active={{
                                                                background:
                                                                    'light.201',
                                                            }}
                                                        >
                                                            Add New Message
                                                        </Button>
                                                    </Stack>
                                                </Flex>
                                            </TabPanel>
                                            <TabPanel p="0">
                                                {isLoading &&
                                                    !isLoadingError && (
                                                        <Spinner />
                                                    )}
                                                {!isLoading &&
                                                    isLoadingError && (
                                                        <Text color="red">
                                                            Error Loading Data
                                                        </Text>
                                                    )}
                                                {!isLoading &&
                                                    !isLoadingError &&
                                                    !expiredMessages.length && (
                                                        <NoMessages message="NO EXPIRED MESSAGES" />
                                                    )}
                                                {expiredMessages
                                                    .sort((a, b) =>
                                                        a.starttime <
                                                        b.starttime
                                                            ? 1
                                                            : -1
                                                    )
                                                    .map((i, idx) => {
                                                        return (
                                                            <Message
                                                                key={`expiredMsg-${idx}`}
                                                                info={i}
                                                                index={idx}
                                                                deleteItem={
                                                                    deleteMessageFromList
                                                                }
                                                                republishItem={
                                                                    republishMessageFromList
                                                                }
                                                                edit={
                                                                    setMessageToEditingState
                                                                }
                                                                deleteSuccess={
                                                                    deleteSuccess ===
                                                                    i.id
                                                                }
                                                                republishSuccess={
                                                                    republishSuccess ===
                                                                    i.id
                                                                }
                                                                loading={
                                                                    deleteInProgress ===
                                                                        i.id ||
                                                                    republishInProgress ===
                                                                        i.id
                                                                }
                                                            />
                                                        )
                                                    })}
                                            </TabPanel>
                                            <TabPanel p="0">
                                                {isLoading &&
                                                    !isLoadingError && (
                                                        <Spinner />
                                                    )}
                                                {!isLoading &&
                                                    isLoadingError && (
                                                        <Text color="red">
                                                            Error Loading Data
                                                        </Text>
                                                    )}
                                                {!isLoading &&
                                                    !isLoadingError &&
                                                    !deletedMessages.length && (
                                                        <NoMessages message="NO DELETED MESSAGES" />
                                                    )}
                                                {deletedMessages
                                                    .sort((a, b) =>
                                                        a.starttime <
                                                        b.starttime
                                                            ? 1
                                                            : -1
                                                    )
                                                    .map((i, idx) => {
                                                        return (
                                                            <Message
                                                                key={`deletedMsg-${idx}`}
                                                                info={i}
                                                                index={idx}
                                                                deleteItem={
                                                                    deleteMessageFromList
                                                                }
                                                                republishItem={
                                                                    republishMessageFromList
                                                                }
                                                                edit={
                                                                    setMessageToEditingState
                                                                }
                                                                deleteSuccess={
                                                                    deleteSuccess ===
                                                                    i.id
                                                                }
                                                                republishSuccess={
                                                                    republishSuccess ===
                                                                    i.id
                                                                }
                                                                loading={
                                                                    deleteInProgress ===
                                                                        i.id ||
                                                                    republishInProgress ===
                                                                        i.id
                                                                }
                                                            />
                                                        )
                                                    })}
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </Flex>
                            )}
                        </ModalBody>
                    )}
                </ModalContent>
            </Modal>
        </LightMode>
    )
}
