import React from 'react'
import { useRecoilValue } from 'recoil'

import { Icon, useColorModeValue } from '@chakra-ui/react'

import { menuExpandedState } from '../../../../globalState'

export default function MainNavBG(props) {
    const { fullscreenModalOpen, color, active } = props

    const subMenuExpanded = useRecoilValue(menuExpandedState)
    const menuExpanded = subMenuExpanded.met

    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')
    const border = useColorModeValue('#e9edf3', 'none')

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            width="65px"
            height="auto"
            viewBox="0 0 82 95"
            fill="none"
            // boxSize={boxSize || 7}
            // viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            {!fullscreenModalOpen && (
                <g filter="url(#filter0_d_387_15849)">
                    <ellipse
                        cx="41"
                        cy="51.4207"
                        rx="37"
                        ry="37.5"
                        fill="currentcolor"
                    />
                </g>
            )}
            <path
                d="M0 100H82.0455V24.4319H76.3144C69.4651 23.9811 64.9301 21.5895 60.3453 19.1715C60.0803 19.0318 59.8152 18.8919 59.5494 18.7524C58.8773 18.3946 58.1907 18.0388 57.4857 17.691C53.1019 15.504 48.2524 13.6365 41.0258 13.6365C33.7992 13.6365 28.9497 15.504 24.566 17.6909C23.8609 18.0387 23.1742 18.3946 22.5021 18.7524C22.2363 18.8919 21.9712 19.0318 21.7062 19.1715C17.1214 21.5895 12.5864 23.9811 5.73712 24.4319H0V100Z"
                fill="currentcolor"
            />
            {fullscreenModalOpen &&
                !menuExpanded && ( // grey border line
                    <path
                        d="M0 24.4319H5.73712C12.5864 23.9811 17.1214 21.5894 21.7062 19.1715C21.9712 19.0317 22.2363 18.8919 22.5021 18.7524C23.1742 18.3946 23.8609 18.0387 24.566 17.6909C28.9497 15.5039 33.7992 13.6364 41.0258 13.6364C48.2524 13.6364 53.1019 15.504 57.4857 17.691C58.1907 18.0387 58.8773 18.3946 59.5494 18.7523C59.8152 18.8919 60.0803 19.0317 60.3453 19.1715C64.9301 21.5894 69.4651 23.9811 76.3144 24.4319H82.0455V23.3523H76.3502C69.7686 22.9121 65.4181 20.6263 60.8489 18.2166C60.585 18.0774 60.3198 17.9376 60.0539 17.7979C59.3765 17.4373 58.6812 17.077 57.9653 16.7238C53.4894 14.491 48.4722 12.5569 41.0258 12.5569C33.5794 12.5569 28.5622 14.491 24.0863 16.7238C23.3703 17.077 22.675 17.4374 21.9975 17.798C21.7317 17.9376 21.4666 18.0774 21.2026 18.2166C16.6334 20.6263 12.2829 22.9121 5.70135 23.3523H0V24.4319Z"
                        fill={border}
                    />
                )}
            {!fullscreenModalOpen && (
                <defs>
                    <filter
                        id="filter0_d_387_15849"
                        x="4"
                        y="0.920654"
                        width="74"
                        height="88"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feMorphology
                            radius="20"
                            operator="erode"
                            in="SourceAlpha"
                            result="effect1_dropShadow_387_15849"
                        />
                        <feOffset dy="-13" />
                        <feGaussianBlur stdDeviation="10" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_387_15849"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_387_15849"
                            result="shape"
                        />
                    </filter>
                </defs>
            )}
        </Icon>
    )
}
