import React, { useState, useEffect, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useTier, useAdminRole } from '../../../../auth/hooks'
import { isFirefox } from 'react-device-detect'
import {
    Box,
    Button,
    ButtonGroup,
    Center,
    Divider,
    LightMode,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Flex,
    Fade,
    Icon,
    useDisclosure,
    useColorModeValue,
    CircularProgress,
    CircularProgressLabel,
    HStack,
    useBreakpointValue,
    Spinner,
} from '@chakra-ui/react'
import Image from '../../../components/Image'
// import { useSwipeable } from 'react-swipeable'
import { useQuery } from 'react-query'
import { parseISO, formatISO, addMinutes, formatDistanceToNow } from 'date-fns'
import PinchDrag, { useScale } from '../../components/PinchDrag'
import ZoomSlider from '../../components/ZoomSlider'
import PremiumBadge from '../../../controls/PremiumBadge'
import ErrorToggle from '../../components/ErrorToggle'
import FullWidthError from '../../components/FullWidthError'
import ErrorText from '../../components/ErrorText'
import {
    timeZoneState,
    dataModeState,
    layerSelectionState,
    fullscreenModalVisibleHandler,
    pollingPaused,
    showErrorTogglesState,
    simulateFreeUserState,
    setModalVisibilityState,
    mfaVerifiedState,
} from '../../../../globalState'
import { isMobileOnly, isIOS } from 'react-device-detect'
import formatDateTime from '../../../../util/dateFormatter'
import TimeSlider from './components/TimeSlider'
import { ReactComponent as cloudImageryIcon } from '../../../../icons/assets/cloud-imagery.svg'
import { ReactComponent as InfoIcon } from '../../../../icons/assets/info-nocircle.svg'
import { ReactComponent as CloudVisibleIcon } from '../../../../icons/assets/cloud-visible.svg'
import { ReactComponent as CloudTopsIcon } from '../../../../icons/assets/cloud-tops.svg'
import { ReactComponent as CloudInfraredIcon } from '../../../../icons/assets/cloud-infrared.svg'
import { ReactComponent as CloudEnhancedInfraredIcon } from '../../../../icons/assets/cloud-enhanced-infrared.svg'

export default function CloudImageryLooper() {
    const { getAccessTokenSilently } = useAuth0()
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setPollingPaused = useSetRecoilState(pollingPaused)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const isAdmin = useAdminRole()
    const [selectedType, setSelectedType] = useState(null)
    const [regions, setRegions] = useState([])
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [resolutions, setResolutions] = useState([])
    const [description, setDescription] = useState(null)
    const [selectedResolution, setSelectedResolution] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [previousSelectedTime, setPreviousSelectedTime] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [looping, setLooping] = useState(false)
    const [speed, setSpeed] = useState('1x')
    const [selectedSetTimes, setSelectedSetTimes] = useState([])
    const [imageSet, setImageSet] = useState([])
    const [imagesLoaded, setImagesLoaded] = useState([])
    const [percentLoaded, setPercentLoaded] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [types, setTypes] = useState([])
    const [showNetworkErrors, setShowNetworkErrors] = useState(false)
    const [showOutdatedErrors, setShowOutdatedErrors] = useState(false)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const [descriptionShow, setDescriptionShow] = useState(false)
    const timeZone = useRecoilValue(timeZoneState)

    const { isOpen, onOpen, onClose } = useDisclosure()

    // Portrait Images
    const image944x1104 =
        (selectedType === 'vis' && selectedRegion === 'nz') || // L: 7.25% / W: 85.5% / H: 100%
        selectedType === 'ire' ||
        (selectedType === 'ct' && selectedRegion === 'nz')
    const image988x1104 = selectedType === 'ire' // L: 5.25% / W: 89.5% / H: 100%

    // Landscape Images
    const image1332x925 =
        (selectedType === 'vis' && selectedRegion === 'nz-au') ||
        selectedType === 'ir' // W: 100% / H: 69.5%
    const image1200x880 = selectedType === 'ct' && selectedRegion === 'nz-au' // W: 100% / H: 73.3%
    const image1008x768 = selectedType === 'ct' && selectedRegion === 'nz-ts' // W: 100% / H: 76.2%

    // Image Resizing
    const imageSize = {
        left: image988x1104 ? '5.25%' : image944x1104 ? '7.25%' : '0%',
        width: image988x1104 ? '89.5%' : image944x1104 ? '85.5%' : '100%',
        height: image1008x768
            ? '76.2%'
            : image1200x880
            ? '73.3%'
            : image1332x925
            ? '69.5%'
            : '100%',
    }

    // PinchZoom
    const { scale, setScale, xy, setXy } = useScale()
    const [minimiseOnZoom, setMinimiseOnZoom] = useState(false)
    const minimiseActive =
        scale < 0.998 || scale > 1.02 || xy.x !== 0 || xy.y !== 0

    useEffect(() => {
        if (scale > 1.1) {
            setMinimiseOnZoom(true)
        } else if (scale <= 1.1) {
            setMinimiseOnZoom(false)
        }
    }, [scale, setMinimiseOnZoom])

    // This pauses the network connection check until it is loaded.
    useEffect(() => {
        setPollingPaused(!loaded)
    }, [loaded, setPollingPaused])

    useEffect(() => {
        if (dataMode === 'met' && layerSelection.met.cloudImagery) {
            setImagesLoaded([])
            setPercentLoaded(0)
            onOpen()
        } else {
            onClose()
        }
    }, [onOpen, onClose, dataMode, layerSelection, handleFullscreenModal])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    const fetchCloudImagery = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/satelliteimages`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const { data, status } = useQuery(`cloudImagery`, fetchCloudImagery, {
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (data) {
            setTypes(Object.keys(data))
        }
    }, [data])

    useEffect(() => {
        if (data && types) {
            setSelectedType(types[0])
        }
    }, [data, types])

    // This acts as a reset for selected type change
    useEffect(() => {
        if (data && types && selectedType) {
            const regionsSet = Object.keys(data[selectedType].regions)
            const regionsInfo = regionsSet.map((r) => {
                return { id: r, ...data[selectedType].regions[r] }
            })
            setRegions(regionsInfo)
            setSelectedRegion(regionsSet[0].id)
        }
    }, [data, types, selectedType])

    // Change the resolutions if region changes.
    useEffect(() => {
        if (
            regions.length &&
            regions.filter((i) => i.id === selectedRegion).length
        ) {
            const resolutionIds = Object.keys(
                regions.filter((i) => i.id === selectedRegion)[0].resolutions
            )
            const resolutionInfo = resolutionIds.map((r) => {
                return {
                    id: r,
                    ...regions.filter((i) => i.id === selectedRegion)[0]
                        .resolutions[r],
                }
            })
            setResolutions(resolutionInfo)
            setSelectedResolution(resolutionInfo[0].id)
            setDescription(
                regions.filter((i) => i.id === selectedRegion)[0].description
            )
        } else if (regions.length) {
            setSelectedRegion(regions[0].id)
        }
    }, [regions, selectedRegion])

    // Change the available times if resolutions change.
    useEffect(() => {
        if (
            resolutions.length &&
            resolutions.filter((i) => i.id === selectedResolution).length
        ) {
            setImagesLoaded([])
            setPercentLoaded(0)
            const availableTimes = resolutions.filter(
                (i) => i.id === selectedResolution
            )[0].images
            setImageSet(availableTimes)
            setSelectedSetTimes(availableTimes.map((t) => t.validat))
        } else if (resolutions.length) {
            setSelectedResolution(resolutions[0].id)
        }
    }, [resolutions, selectedResolution])

    // Check if value exists and if not set to first value
    useEffect(() => {
        if (
            selectedSetTimes.length &&
            !selectedSetTimes.includes(selectedTime)
        ) {
            setSelectedTime(selectedSetTimes[selectedSetTimes.length - 1])
        }
    }, [selectedSetTimes, selectedTime])

    useEffect(() => {
        data &&
            selectedSetTimes &&
            setLoaded(imagesLoaded === selectedSetTimes.length)
        const percent = 100 / selectedSetTimes.length
        setPercentLoaded(imagesLoaded * percent)
    }, [data, imagesLoaded, selectedSetTimes])

    useEffect(() => {
        if (showNetworkErrors) {
            setImagesLoaded([])
        }
    }, [showNetworkErrors])

    const singleRegionOnly = regions && regions.length < 2

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const [selectedSourcePosition, setselectedSourcePosition] = useState(0)

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const [screenDifference, setScreenDifference] = useState(0)
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
            setScreenHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // adjust landscape images to fill space properly depending on screen viewport dimensions
    useEffect(() => {
        const calculatedDifference = screenWidth - 1.75 * screenHeight
        if (calculatedDifference > 0) {
            setScreenDifference(calculatedDifference)
        } else {
            setScreenDifference(0)
        }
    }, [screenWidth, screenHeight])

    // const screenWidthSmall = window.innerWidth < 992
    // MOBILE SOURCE CHANGE BY SWIPE LEFT/RIGHT
    // const sourceSwipe = useSwipeable({
    //     onSwipedLeft: () => {
    //         if (
    //             isPremium &&
    //             selectedRegion &&
    //             data &&
    //             data[selectedRegion] === 'radar'
    //         ) {
    //             setShowPricingTiers(true)
    //         } else if (screenWidthSmall && !isPremium) {
    //             setSelectedRegion( // TODO  - this needs fixing
    //                 data[selectedType][regions][selectedSourcePosition - 1].id
    //             )
    //             setselectedSourcePosition(selectedSourcePosition - 1)
    //             setScale(1)
    //             setXy({
    //                 x: 0,
    //                 y: 0,
    //             })
    //         }
    //     },
    //     onSwipedRight: () => {
    //         if (screenWidthSmall && selectedSourcePosition < 2) {
    //             setSelectedRegion( // TODO  - this needs fixing
    //                 data[selectedType][regions][selectedSourcePosition + 1].id
    //             )
    //             setselectedSourcePosition(selectedSourcePosition + 1)
    //             setScale(1)
    //             setXy({
    //                 x: 0,
    //                 y: 0,
    //             })
    //         }
    //     },
    // })

    // // MOBILE RADAR INFO SHOW BY SWIPE DOWN
    // const infoSwipe = useSwipeable({
    //     onSwipedDown: () => {
    //         setDescriptionShow(true)
    //     },
    //     onSwipedUp: () => {
    //         setDescriptionShow(false)
    //     },
    // })

    // check for if images are out of date
    function isOutOfDate() {
        const itemData =
            data &&
            selectedTime &&
            imageSet.filter((i) => selectedTime === i.validat)[0]

        const latestIssueTime =
            data &&
            imageSet &&
            imageSet.length &&
            imageSet[imageSet.length - 1]?.validat

        const formattedLatestIssueTime =
            latestIssueTime && formatDateTime(latestIssueTime, timeZone)

        const minutesSince =
            selectedType === 'vis' || selectedType === 'ir' ? -60 : -90 // VIS and IR max 60 mins. IRE and CT max 90 mins

        const maxTimeDelay = addMinutes(Date.now(), minutesSince)

        const formattedMaxTimeDelay =
            maxTimeDelay && formatDateTime(formatISO(maxTimeDelay), timeZone)

        const latestOutOfDate = Boolean(
            data &&
                formattedLatestIssueTime &&
                formattedMaxTimeDelay &&
                formattedLatestIssueTime < formattedMaxTimeDelay
        )

        if (itemData) {
            return {
                outofdate: itemData.outofdate,
                updated: itemData.updated,
                latest: latestOutOfDate,
                latestoutofdate: latestIssueTime,
            }
        } else {
            return {
                outofdate: false,
                updated: null,
                latest: false,
                latestoutofdate: null,
            }
        }
    }

    const errorShow =
        showOutdatedErrors || isOutOfDate().outofdate || isOutOfDate().latest

    const errorHeight = useBreakpointValue({
        base: 375 + (isAdmin && showErrorToggle && 30) + (errorShow && 80),
        md: 200 + (isAdmin && showErrorToggle && 30) + (errorShow && 75),
        ml: 250 + (isAdmin && showErrorToggle && 30) + (errorShow && 75),
        lg: 150 + (errorShow && 70),
        xl: 150 + (errorShow && 40),
    })

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiers = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    useEffect(() => {
        if (imageSet) {
            const loadedArray = imageSet.map((i) =>
                imagesLoaded.includes(i.url)
            )
            const loaded = loadedArray.filter((i) => i)
            const percent = Math.round((100 / imageSet.length) * loaded.length)
            setPercentLoaded(percent)
        }
    }, [imageSet, imagesLoaded])

    useEffect(() => {
        if (imageSet && imageSet.length) {
            setLoaded(Math.round(percentLoaded) === 100)
        }
    }, [imageSet, percentLoaded])

    function firstImageLoaded() {
        return (
            !!imageSet.length &&
            imagesLoaded.includes(imageSet[imageSet.length - 1].url)
        )
    }

    //TODO: reset image load count to reset when the modal is shown again.
    function onMainImageLoad(id) {
        setImagesLoaded((i) => [...i, id])
    }

    // useEffect(() => {
    //     // console.log('Data: ', data)
    //     // console.log('selectedType: ', selectedType)
    //     // console.log('selectedRegion: ', selectedRegion)
    // console.log('formattedLatestIssueTime', formattedLatestIssueTime)
    // console.log('formattedMaxTimeDelay', formattedMaxTimeDelay)
    // console.log('maxTimeDelay', maxTimeDelay)
    // console.log('latestOutOfDate', latestOutOfDate)
    // }, [data, selectedRegion])

    return (
        <LightMode>
            <Modal
                isOpen={isOpen}
                closeOnOverlayClick={false}
                isCentered
                size="full"
                backgroundColor="transparent"
                scrollable
                variant="imageLooper"
                zIndex="888"
                trapFocus={false}
                blockScrollOnMount={false}
            >
                <ModalContent
                    backgroundSize="100% auto"
                    backgroundRepeat="no-repeat"
                    boxShadow={{
                        base: 'none',
                        lg: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                    }}
                    overflow={{
                        base: 'clip',
                        lg: undefined,
                    }}
                >
                    <Flex
                        direction="column"
                        h={{
                            base: isAdmin && showErrorToggle ? '100px' : '70px',
                            md: '70px',
                        }} // added flex here as header height not sticking causing spacing issues elswhere
                    >
                        <ModalHeader
                            backgroundColor={useColorModeValue(
                                'light.10',
                                'light.10'
                            )}
                            borderBottom="1px solid"
                            borderBottomColor="#E2E8F0"
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            backgroundBlendMode="overlay"
                            h={{
                                base:
                                    isAdmin && showErrorToggle
                                        ? '100px'
                                        : '70px',
                                md: '70px',
                            }}
                            w="100%"
                            paddingInlineStart={4}
                            paddingInlineEnd={4}
                            flex="1"
                            boxShadow={{
                                base: 'none',
                                md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                            }}
                            zIndex="1000"
                        >
                            <Flex
                                h="100%"
                                w="100%"
                                direction={{ base: 'column', md: 'row' }}
                            >
                                <Flex
                                    // {...infoSwipe}
                                    w="100%"
                                    h="100%"
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Flex
                                        // {...infoSwipe}
                                        direction="row"
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-start',
                                        }}
                                        alignItems="center"
                                        width="100%"
                                        maxWidth={{
                                            base: '100%',
                                            ml: '220px',
                                        }}
                                        paddingLeft={{
                                            base: '40px',
                                            md: '0',
                                        }}
                                        paddingTop={{
                                            base: '3px',
                                            md: '0',
                                        }}
                                        flexGrow="1"
                                    >
                                        <Icon
                                            boxSize={{
                                                base: '28px',
                                                md: '32px',
                                            }}
                                            color="light.100"
                                            as={cloudImageryIcon}
                                            ml={{
                                                base: minimiseActive
                                                    ? '0px'
                                                    : '25px',
                                                md: '4px',
                                            }}
                                            transition="all ease 300ms"
                                        />
                                        <Box pl={{ base: '5px', md: '10px' }}>
                                            <Text // HEADER TITLE
                                                textAlign={{
                                                    base: 'center',
                                                    md: 'left',
                                                }}
                                                alignItems={{
                                                    base: 'center',
                                                    md: 'flex-start',
                                                }}
                                                color={useColorModeValue(
                                                    'light.100',
                                                    'light.100'
                                                )}
                                                fontSize={{
                                                    base: '1rem',
                                                    md: '1.2rem',
                                                }}
                                                fontWeight="600"
                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                lineHeight="1.3"
                                                marginTop="-2px"
                                                ml={{
                                                    base: '2px',
                                                    md: '5px',
                                                }}
                                            >
                                                Cloud Imagery
                                            </Text>
                                        </Box>
                                    </Flex>

                                    <Flex
                                        gap="10px"
                                        minWidth={{
                                            base: minimiseActive
                                                ? '90px'
                                                : '40px',
                                            ml: '500px',
                                            lg: '600px',
                                        }}
                                        transition="all ease 300ms"
                                        justifyContent="flex-end"
                                    >
                                        <Flex
                                            color="light.100"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            pr={{
                                                base: '0px',
                                                md: '10px',
                                                ml: '10px',
                                            }}
                                            pt={{
                                                base: '1px',
                                                lg: '0px',
                                            }}
                                            width={{
                                                base: '40px',
                                                ml: '100%',
                                            }}
                                            opacity={{
                                                base: minimiseActive
                                                    ? '1'
                                                    : '0',
                                                ml: '1',
                                            }}
                                            visibility={{
                                                base: minimiseActive
                                                    ? 'visible'
                                                    : 'hidden',
                                                ml: 'visible',
                                            }}
                                            transition="all ease 300ms"
                                        >
                                            <ZoomSlider
                                                scale={scale}
                                                xy={xy}
                                                setScale={setScale}
                                                setXy={setXy}
                                                maxScale={8}
                                            />
                                        </Flex>

                                        <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                            display={{
                                                base: 'inline-flex',
                                                lg: 'none',
                                            }}
                                            background="none"
                                            color="light.100"
                                            opacity={
                                                descriptionShow ? '1' : '0.8'
                                            }
                                            fontSize="10px"
                                            fontWeight="600"
                                            height="40px"
                                            width="40px"
                                            alignItems="center"
                                            justifyContent="center"
                                            paddingLeft="2px"
                                            paddingRight="2px"
                                            borderRadius="10px"
                                            onClick={() => {
                                                setDescriptionShow(
                                                    (prevState) => !prevState
                                                )
                                            }}
                                            _focus={{
                                                opacity: '1 !important',
                                            }}
                                            _active={{
                                                background: 'none',
                                            }}
                                            _hover={{
                                                opacity: '1 !important',
                                            }}
                                        >
                                            <Flex
                                                // borderColor="#DFE6EE"
                                                alignItems="center"
                                                justifyContent="center"
                                                // border="1px solid #DFE6EE"
                                                borderRadius="10px"
                                                color="light.100"
                                                variant="outline"
                                                fontSize="1rem"
                                                height="30px"
                                                width="34px"
                                                style={{
                                                    minWidth: '34px',
                                                }}
                                                _hover={{
                                                    opacity: '1',
                                                    background: 'light.20',
                                                }}
                                                _active={{
                                                    background: {
                                                        base: 'none',
                                                        lg: 'light.20',
                                                    },
                                                    boxShadow: {
                                                        base: 'none',
                                                        lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                    },
                                                }}
                                            >
                                                <Icon
                                                    as={InfoIcon}
                                                    boxSize={5}
                                                />
                                            </Flex>
                                        </Button>
                                    </Flex>
                                </Flex>
                                <Flex
                                    direction={{
                                        base: 'row',
                                        md: 'row',
                                    }}
                                    w={
                                        isAdmin && showErrorToggle
                                            ? '100%'
                                            : '0'
                                    }
                                    justifyContent={{
                                        base: 'center',
                                        md: 'flex-end',
                                    }}
                                >
                                    <HStack
                                        spacing="15px"
                                        align="center"
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-end',
                                        }}
                                    >
                                        <ErrorToggle
                                            showErrors={showNetworkErrors}
                                            setShowErrors={setShowNetworkErrors}
                                            label="Simulate Network Error"
                                        />
                                        <ErrorToggle
                                            showErrors={showOutdatedErrors}
                                            setShowErrors={
                                                setShowOutdatedErrors
                                            }
                                            label="Simulate Out-of-Date Error"
                                        />
                                    </HStack>
                                </Flex>
                            </Flex>
                        </ModalHeader>
                    </Flex>
                    <ModalBody
                        p={0}
                        height={{
                            base:
                                isMobileOnly && isIOS
                                    ? 'calc(100% - 145px)'
                                    : 'calc(100% - 130px)',
                            lg: '100%',
                        }}
                        background="#edf1f7"
                        backgroundSize="100% auto"
                        backgroundRepeat="no-repeat"
                    >
                        {status !== 'error' && !showNetworkErrors ? (
                            <Flex
                                flexDirection={{
                                    base: 'column-reverse',
                                    md: 'row',
                                }}
                                w="100%"
                                h="100%"
                                bg={{
                                    base: 'linear-gradient(to bottom, #ECF2F9 30%, #DFE8F1 70%)',
                                    md: 'linear-gradient(to bottom, #F1F6FB 30%, #F0F8FD 80%)',
                                }}
                            >
                                <Flex // LEFT MENU //
                                    colSpan={2}
                                    mb={{
                                        base: minimiseOnZoom ? '-90px' : '0px',
                                        md: '0px',
                                    }}
                                    transition="all ease 300ms"
                                    position="relative"
                                    width={{ base: '100%', md: '200px' }}
                                    minWidth={{ base: '100%', md: '200px' }}
                                    minHeight={{
                                        base: '55px',
                                        md: '100%',
                                    }}
                                    background={{
                                        base: 'light.55',
                                        md: 'light.10',
                                    }}
                                    backdropFilter={{
                                        base: 'blur(20px)',
                                        ml: 'none',
                                    }}
                                    borderRight={{
                                        base: 'none',
                                        md: '1px solid #E2E8F0',
                                    }}
                                    flexDirection={{
                                        base: 'row',
                                        md: 'column',
                                    }}
                                    pb={{ base: '1px', md: '0px' }}
                                    zIndex="102"
                                >
                                    <Flex
                                        flexDirection={{
                                            base: 'row',
                                            md: 'column',
                                        }}
                                        alignItems={{
                                            base: 'center',
                                            md: 'unset',
                                        }}
                                        // This needs to be set in order to allow scrolling
                                        h={{
                                            base: '100%',
                                            md: '100%',
                                        }}
                                        width="100%"
                                        overflowX={{
                                            base: 'auto',
                                            md: 'hidden',
                                        }}
                                        overflowY={{
                                            base: 'hidden',
                                            md: 'auto',
                                        }}
                                        pl={{
                                            base: '15px',
                                            md: '0px',
                                        }}
                                        pr={{
                                            base: '30px',
                                            md: '0px',
                                        }}
                                        borderTop={{
                                            base: '1px solid #E2E8F0',
                                            md: 'none',
                                        }}
                                    >
                                        <Flex
                                            display={{
                                                base: 'none',
                                                md: 'flex',
                                            }}
                                            fontSize="0.65rem"
                                            flex="1"
                                            textAlign="left"
                                            paddingLeft="21px"
                                            maxHeight="40px"
                                            alignItems="center"
                                            borderBottom={{
                                                base: 'none',
                                                md: '1px solid #E2E8F0',
                                            }}
                                            marginBottom={{
                                                base: '0px',
                                                md: '15px',
                                            }}
                                        >
                                            <Text
                                                marginTop="auto"
                                                marginBottom="auto"
                                                color="light.300"
                                                fontSize="11px"
                                                fontWeight="400"
                                                fontFamily="'Poppins', 'Open Sans', sans-serif"
                                            >
                                                Types
                                            </Text>
                                        </Flex>
                                        <Flex
                                            flexDirection={{
                                                base: 'row',
                                                md: 'column',
                                            }}
                                            width="100%"
                                        >
                                            {types.map((type, i) => (
                                                <Flex
                                                    key={`typeButton-${type}`}
                                                    {...noFocus}
                                                    zIndex="2"
                                                    as="button"
                                                    color={
                                                        isPremium &&
                                                        (data[type]['label'] ===
                                                            'Infrared Enhanced' ||
                                                            data[type][
                                                                'label'
                                                            ] === 'Cloud Top')
                                                            ? 'light.100'
                                                            : selectedType ===
                                                              type
                                                            ? 'light.200'
                                                            : 'light.100'
                                                    }
                                                    fontSize={{
                                                        base:
                                                            selectedType ===
                                                            type
                                                                ? '0.8rem'
                                                                : '0.8rem',
                                                        md: '13px',
                                                    }}
                                                    fontWeight={{
                                                        base: '500',
                                                        md: '400',
                                                    }}
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    pl={{
                                                        base: '20px',
                                                        md: '20px',
                                                    }}
                                                    pr={{
                                                        base:
                                                            i === type.length
                                                                ? '40px'
                                                                : '20px',

                                                        md: '0px',
                                                    }}
                                                    mx="auto"
                                                    letterSpacing={{
                                                        base:
                                                            selectedType ===
                                                            type
                                                                ? '0.1px'
                                                                : '0.1px',
                                                        md: '0.1px',
                                                    }}
                                                    onClick={() => {
                                                        isPremium &&
                                                        (data[type]['label'] ===
                                                            'Infrared Enhanced' ||
                                                            data[type][
                                                                'label'
                                                            ] === 'Cloud Top')
                                                            ? setShowPricingTiers(
                                                                  true
                                                              )
                                                            : setSelectedType(
                                                                  type
                                                              )
                                                        setScale(1)
                                                        setXy({
                                                            x: 0,
                                                            y: 0,
                                                        })
                                                        setselectedSourcePosition(
                                                            0
                                                        )
                                                    }}
                                                    transition="all ease 300ms"
                                                    w={{
                                                        base: 'max-content',
                                                        md: '100%',
                                                    }}
                                                    minWidth={{
                                                        base: 'max-content',
                                                        md: '100%',
                                                    }}
                                                    height="40px"
                                                    _hover={{
                                                        cursor: 'pointer',
                                                        color:
                                                            isPremium &&
                                                            (data[type][
                                                                'label'
                                                            ] ===
                                                                'Infrared Enhanced' ||
                                                                data[type][
                                                                    'label'
                                                                ] ===
                                                                    'Cloud Top')
                                                                ? 'light.100'
                                                                : 'light.200',
                                                    }}
                                                    _active={{
                                                        color: {
                                                            base:
                                                                selectedType ===
                                                                type
                                                                    ? 'light.200'
                                                                    : 'light.900',
                                                            md: 'light.200',
                                                        },
                                                    }}
                                                    alignItems="center"
                                                >
                                                    <Icon
                                                        opacity={
                                                            isPremium &&
                                                            (data[type][
                                                                'label'
                                                            ] ===
                                                                'Infrared Enhanced' ||
                                                                data[type][
                                                                    'label'
                                                                ] ===
                                                                    'Cloud Top')
                                                                ? '0.4'
                                                                : '1'
                                                        }
                                                        width="24px"
                                                        height="24px"
                                                        boxSize="6"
                                                        as={
                                                            data[type][
                                                                'label'
                                                            ] === 'Cloud Top'
                                                                ? CloudTopsIcon
                                                                : data[type][
                                                                      'label'
                                                                  ] ===
                                                                  'Infrared'
                                                                ? CloudInfraredIcon
                                                                : data[type][
                                                                      'label'
                                                                  ] ===
                                                                  'Infrared Enhanced'
                                                                ? CloudEnhancedInfraredIcon
                                                                : data[type][
                                                                      'label'
                                                                  ] ===
                                                                  'Visible'
                                                                ? CloudVisibleIcon
                                                                : CloudVisibleIcon
                                                        }
                                                    />
                                                    <Text
                                                        ml="5px"
                                                        opacity={
                                                            isPremium &&
                                                            (data[type][
                                                                'label'
                                                            ] ===
                                                                'Infrared Enhanced' ||
                                                                data[type][
                                                                    'label'
                                                                ] ===
                                                                    'Cloud Top')
                                                                ? '0.4'
                                                                : '1'
                                                        }
                                                    >
                                                        {data[type]['label']}
                                                    </Text>
                                                    {/* *** PREMIUM BADGE *** */}
                                                    {isPremium &&
                                                        (data[type]['label'] ===
                                                            'Infrared Enhanced' ||
                                                            data[type][
                                                                'label'
                                                            ] ===
                                                                'Cloud Top') && (
                                                            <Flex
                                                                marginLeft="6px"
                                                                mt="0px"
                                                            >
                                                                <PremiumBadge />
                                                            </Flex>
                                                        )}
                                                </Flex>
                                            ))}
                                            <Flex // MENU SLIDING BACKGROUND
                                                // opacity={
                                                //     mobileDrawerExpanded || filtersShow ? '0' : '1' // Hidden until connected to InView State
                                                // }
                                                // transition="opacity 500ms"
                                                position="absolute"
                                                width="206px"
                                                display={{
                                                    base: 'none',
                                                    md: 'flex',
                                                }}
                                                transform={`translateY(${types.indexOf(
                                                    selectedType
                                                )}00%)`}
                                                transition="transform ease 0.4s"
                                            >
                                                <Box
                                                    mt="0px"
                                                    ml="0px"
                                                    mr="0px"
                                                    height="40px"
                                                    width="100%"
                                                    marginBottom="0px"
                                                    background="light.20"
                                                    borderRightRadius="7px"
                                                >
                                                    <Box
                                                        width="100%"
                                                        position="absolute"
                                                        height="100%"
                                                        borderRightRadius="7px"
                                                        border="1px solid"
                                                        borderLeft="none"
                                                        borderColor="#e2e8f0"
                                                        opacity="1"
                                                    ></Box>
                                                    <Box
                                                        background="light.200"
                                                        width="3px"
                                                        position="absolute"
                                                        right="5px"
                                                        top="14%"
                                                        height="72%"
                                                        borderRadius="5px"
                                                    ></Box>
                                                </Box>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex
                                    flexDirection={{
                                        base: 'column-reverse',
                                        lg: 'row',
                                    }}
                                    position={{
                                        base: 'absolute',
                                        md: 'relative',
                                    }}
                                    top={{
                                        base:
                                            isAdmin && showErrorToggle
                                                ? '100px'
                                                : '70px',
                                        md: '0px',
                                    }}
                                    w="100%"
                                    h={{
                                        base:
                                            isAdmin && showErrorToggle
                                                ? 'calc(100% - 100px)'
                                                : 'calc(100% - 70px)',
                                        md: '100%',
                                    }}
                                >
                                    <Flex // IMAGE DISPLAY AND TIME SLIDER
                                        width="100%"
                                        h="100%"
                                        position={'relative'}
                                        flexDirection="column"
                                        alignItems="center"
                                        whiteSpace="pre-line"
                                        overflowX="hidden"
                                        overflowY="hidden"
                                        pt={{
                                            base: minimiseOnZoom
                                                ? '50px'
                                                : '0px',
                                            ml: '0px',
                                        }}
                                        transition="all ease 300ms"
                                    >
                                        {data && errorShow && (
                                            <Flex
                                                px="15px"
                                                pt="5px"
                                                pb="5px"
                                                mt="-1px"
                                                width="100%"
                                                justifyContent="center"
                                                zIndex="3"
                                                backdropFilter="blur(4px)"
                                                textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                background={{
                                                    base: isFirefox
                                                        ? 'rgba(241, 246, 251, 0.95)'
                                                        : 'rgba(241, 246, 251, 0.85)',
                                                    md: isFirefox
                                                        ? 'rgba(241, 246, 251, 0.95)'
                                                        : 'rgba(241, 246, 251, 0.85)',
                                                }}
                                            >
                                                {isOutOfDate().latest ? (
                                                    <ErrorText>
                                                        There has been an issue
                                                        with the connection
                                                        between PreFlight and
                                                        MetService. This imagery
                                                        was last received from
                                                        the MetService{' '}
                                                        <strong>
                                                            {formatDistanceToNow(
                                                                parseISO(
                                                                    isOutOfDate()
                                                                        .latestoutofdate
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                        </strong>
                                                        .<br />
                                                        There is a possibility
                                                        that we are unable to
                                                        display imagery that is
                                                        up to date. Please check
                                                        back again soon.
                                                    </ErrorText>
                                                ) : (
                                                    isOutOfDate().updated && (
                                                        <ErrorText>
                                                            There has been an
                                                            issue with the
                                                            connection between
                                                            PreFlight and
                                                            MetService. This
                                                            imagery was last
                                                            validated with
                                                            MetService{' '}
                                                            <strong>
                                                                {formatDistanceToNow(
                                                                    parseISO(
                                                                        isOutOfDate()
                                                                            .updated
                                                                    ),
                                                                    {
                                                                        addSuffix: true,
                                                                    }
                                                                )}
                                                            </strong>
                                                            .<br />
                                                            There is a
                                                            possibility that we
                                                            are unable to
                                                            display imagery that
                                                            is up to date.
                                                            Please check back
                                                            again soon.
                                                        </ErrorText>
                                                    )
                                                )}
                                            </Flex>
                                        )}
                                        <Flex
                                            w="100%"
                                            maxWidth={
                                                image1332x925 ||
                                                image1200x880 ||
                                                image1008x768
                                                    ? `calc(100% - ${
                                                          (screenDifference > 0
                                                              ? screenDifference +
                                                                (image1200x880
                                                                    ? 40
                                                                    : 0) +
                                                                (image1008x768
                                                                    ? 70
                                                                    : 0)
                                                              : 0) +
                                                          (errorShow ? 40 : 0)
                                                      }px)`
                                                    : `calc(100vh - ${errorHeight}px)`
                                            }
                                            h="100%"
                                            maxHeight={{
                                                base: 'calc(100vh - 120px)',
                                                lg: '100%',
                                            }}
                                            minHeight={{
                                                base: '0',
                                                lg: '100%',
                                            }}
                                            top={errorShow ? '40px' : '0px'}
                                            pos="absolute"
                                            alignItems="baseline"
                                            justifyContent="center"
                                            padding="20px"
                                            paddingBottom={{
                                                base: '210px',
                                                md: '155px',
                                                lg: '100px',
                                            }}
                                            transition="all ease-in-out 200ms"
                                        >
                                            <Box
                                                w="100%"
                                                h="100%"
                                                pos="relative"
                                                // {...infoSwipe}
                                            >
                                                {status !== 'loading' &&
                                                    status === 'error' && (
                                                        <Text
                                                            pb="10px"
                                                            mt="-10px"
                                                            textAlign="center"
                                                            fontWeight="500"
                                                        >
                                                            Error loading data
                                                        </Text>
                                                    )}
                                                {status === 'loading' && (
                                                    <Flex
                                                        zIndex="1"
                                                        position="absolute"
                                                        top="0px"
                                                        left="0px"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        width={'100%'}
                                                        pt="34vh"
                                                        px={{
                                                            base: '10px',
                                                            md: '30px',
                                                        }}
                                                    >
                                                        <Spinner
                                                            color={'light.200'}
                                                            thickness="3px"
                                                            speed="0.45s"
                                                            boxSize="60px"
                                                        />
                                                    </Flex>
                                                )}
                                                {status !== 'loading' &&
                                                    !loaded &&
                                                    !!percentLoaded && (
                                                        <>
                                                            <Center
                                                                top={0}
                                                                left={0}
                                                                w="100%"
                                                                h="100%"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                position="absolute"
                                                                zIndex={107}
                                                            >
                                                                <CircularProgress
                                                                    value={
                                                                        percentLoaded
                                                                    }
                                                                    size="80px"
                                                                    thickness="4px"
                                                                    color="light.200"
                                                                >
                                                                    <CircularProgressLabel
                                                                        color="white"
                                                                        textShadow="0px 1px 3px rgba(0,0,0,0.5)"
                                                                    >
                                                                        {`${Math.round(
                                                                            percentLoaded
                                                                        )}%`}
                                                                    </CircularProgressLabel>
                                                                </CircularProgress>
                                                            </Center>
                                                        </>
                                                    )}

                                                <PinchDrag
                                                    scale={scale}
                                                    setScale={setScale}
                                                    xy={xy}
                                                    setXy={setXy}
                                                    style={{
                                                        zIndex: '101',
                                                    }}
                                                    maxZoom={8}
                                                    maxZoomNewScale={7.999}
                                                >
                                                    <Box
                                                        w="100%"
                                                        h="0"
                                                        borderRadius="10px"
                                                        pt={loaded ? '100%' : 0}
                                                        pos="relative"
                                                        zIndex={101}
                                                    >
                                                        {imageSet.length >
                                                            0 && (
                                                            <Fade
                                                                key={`cloudImg${
                                                                    imageSet.length -
                                                                    1
                                                                }`}
                                                                in={
                                                                    (loaded &&
                                                                        selectedTime ===
                                                                            imageSet[
                                                                                imageSet.length -
                                                                                    1
                                                                            ]
                                                                                .validat) ||
                                                                    previousSelectedTime ===
                                                                        imageSet[
                                                                            imageSet.length -
                                                                                1
                                                                        ]
                                                                            .validat
                                                                }
                                                            >
                                                                <Box
                                                                    pos="absolute"
                                                                    zIndex={
                                                                        selectedTime ===
                                                                        imageSet[
                                                                            imageSet.length -
                                                                                1
                                                                        ]
                                                                            .validat
                                                                            ? 5
                                                                            : 4
                                                                    }
                                                                    top="0px"
                                                                    left={{
                                                                        base: '0px',
                                                                        sm: imageSize.left,
                                                                    }}
                                                                    transition="all ease 200ms"
                                                                    w="100%"
                                                                    h="0"
                                                                    pt="calc(100% * (100 / 100))"
                                                                    opacity={
                                                                        loaded
                                                                            ? 1
                                                                            : 0.7
                                                                    }
                                                                >
                                                                    {imageSet[
                                                                        imageSet.length -
                                                                            1
                                                                    ].url && (
                                                                        <Image
                                                                            src={`${
                                                                                window
                                                                                    .location
                                                                                    .origin
                                                                            }/metproxy?url=${
                                                                                imageSet[
                                                                                    imageSet.length -
                                                                                        1
                                                                                ]
                                                                                    .url
                                                                            }`}
                                                                            key={`cloudImg-${
                                                                                imageSet.length -
                                                                                1
                                                                            }`}
                                                                            pos="absolute"
                                                                            top="0px"
                                                                            w={{
                                                                                base: '100%',
                                                                                sm: imageSize.width,
                                                                            }}
                                                                            h={{
                                                                                base: 'auto',
                                                                                sm: imageSize.height,
                                                                            }}
                                                                            transition="all ease 200ms"
                                                                            fit="cover"
                                                                            objectPosition={
                                                                                'left top'
                                                                            }
                                                                            borderRadius="10px"
                                                                            onLoad={() =>
                                                                                onMainImageLoad(
                                                                                    imageSet[
                                                                                        imageSet.length -
                                                                                            1
                                                                                    ]
                                                                                        .url
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </Fade>
                                                        )}
                                                        {firstImageLoaded() &&
                                                            imageSet
                                                                //TODO - add filter function to prevent unwanted sourcing of Premium images (if needed?)
                                                                .map(
                                                                    (
                                                                        i,
                                                                        idx
                                                                    ) => (
                                                                        <Fade
                                                                            key={`cloudImg${idx}`}
                                                                            in={
                                                                                // (loaded &&
                                                                                //     selectedTime ===
                                                                                //         i.validat) ||
                                                                                // (!loaded &&
                                                                                //     idx ===
                                                                                //         0) ||
                                                                                // previousSelectedTime ===
                                                                                //     i.validat
                                                                                (loaded &&
                                                                                    (selectedTime ===
                                                                                        i.validat ||
                                                                                        previousSelectedTime ===
                                                                                            i.validat)) ||
                                                                                (!loaded &&
                                                                                    idx ===
                                                                                        0)
                                                                            }
                                                                        >
                                                                            <Box
                                                                                pos="absolute"
                                                                                zIndex={
                                                                                    (loaded &&
                                                                                        selectedTime ===
                                                                                            i.validat) ||
                                                                                    (!loaded &&
                                                                                        idx ===
                                                                                            0)
                                                                                        ? 5
                                                                                        : 4
                                                                                }
                                                                                top="0px"
                                                                                left={{
                                                                                    base: '0px',
                                                                                    sm: imageSize.left,
                                                                                }}
                                                                                transition="all ease 200ms"
                                                                                w="100%"
                                                                                h="0"
                                                                                pt="calc(100% * (100 / 100))"
                                                                                opacity={
                                                                                    loaded
                                                                                        ? 1
                                                                                        : 0.7
                                                                                }
                                                                            >
                                                                                {i.url && (
                                                                                    <Image
                                                                                        src={`${window.location.origin}/metproxy?url=${i.url}`}
                                                                                        key={`rainRadarImg-${idx}`}
                                                                                        pos="absolute"
                                                                                        top="0px"
                                                                                        w={{
                                                                                            base: '100%',
                                                                                            sm: imageSize.width,
                                                                                        }}
                                                                                        h={{
                                                                                            base: 'auto',
                                                                                            sm: imageSize.height,
                                                                                        }}
                                                                                        transition="all ease 200ms"
                                                                                        fit="cover"
                                                                                        objectPosition={
                                                                                            'left top'
                                                                                        }
                                                                                        borderRadius="10px"
                                                                                        onLoad={() =>
                                                                                            onMainImageLoad(
                                                                                                i.url
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                        </Fade>
                                                                    )
                                                                )}
                                                    </Box>
                                                </PinchDrag>
                                            </Box>
                                        </Flex>
                                        <Flex
                                            w="100%"
                                            pb="20px"
                                            height={{
                                                base: '140px',
                                                lg: '85px',
                                            }}
                                            bottom={{
                                                base: minimiseOnZoom
                                                    ? '-45px'
                                                    : '60px',
                                                md: minimiseOnZoom
                                                    ? '-45px'
                                                    : '0px',
                                                ml: '0px',
                                            }}
                                            alignItems="center"
                                            flexDirection="column"
                                            justifyContent={{
                                                base: 'flex-start',
                                                lg: 'flex-start',
                                            }}
                                            opacity={!loaded ? '0.15' : '1'}
                                            transition="all ease 300ms"
                                            position="absolute"
                                            zIndex="101"
                                        >
                                            <Flex // IMAGE TIME
                                                h="30px"
                                                mb="5px"
                                                pt="3px"
                                                pb="3px"
                                                px="15px"
                                                justify={'center'}
                                                alignItems="center"
                                                fontWeight="bold"
                                                fontSize="0.8rem"
                                                textAlign="center"
                                                color="light.100"
                                                backdropFilter="blur(4px)"
                                                textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                                background={{
                                                    base: isFirefox
                                                        ? 'rgba(223, 232, 241, 0.8)'
                                                        : 'rgba(223, 232, 241, 0.3)',
                                                    md: isFirefox
                                                        ? 'rgba(240, 248, 253, 0.8)'
                                                        : 'rgba(240, 248, 253, 0.3)',
                                                }}
                                                borderRadius="20px"
                                            >
                                                {selectedTime &&
                                                    formatDateTime(
                                                        selectedTime,
                                                        timeZone
                                                    )}
                                            </Flex>
                                            {!loaded && (
                                                <Box
                                                    position="absolute"
                                                    height="80px"
                                                    width="100%"
                                                    zIndex="1"
                                                    marginTop="0px"
                                                ></Box>
                                            )}
                                            {selectedSetTimes && (
                                                <Flex // TIME SLIDER
                                                    w="100%"
                                                    px="20px"
                                                    maxWidth={{
                                                        base: '700px',
                                                        md: '800px',
                                                        lg: '900px',
                                                    }}
                                                    justify={'center'}
                                                >
                                                    <TimeSlider
                                                        style={{
                                                            width: 'calc(100% - 10px)',
                                                        }}
                                                        times={selectedSetTimes}
                                                        selectedTime={
                                                            selectedTime
                                                        }
                                                        setSelectedTime={
                                                            setSelectedTime
                                                        }
                                                        setPreviousSelectedTime={
                                                            setPreviousSelectedTime
                                                        }
                                                        speed={speed}
                                                        setSpeed={setSpeed}
                                                        playing={playing}
                                                        setPlaying={setPlaying}
                                                        looping={looping}
                                                        setLooping={setLooping}
                                                        mostRecent={true}
                                                    />
                                                </Flex>
                                            )}
                                        </Flex>
                                    </Flex>
                                    <Flex // RIGHT MENU (CONTROLS AND LEGEND)
                                        pos="relative"
                                        flexDirection={{
                                            base: 'row',
                                            lg: 'column',
                                        }}
                                        h={{
                                            base: '50px',
                                            lg: '100%',
                                        }}
                                        minHeight={{
                                            base: '50px',
                                            lg: '100%',
                                        }}
                                        width={{
                                            base: '100%',
                                            lg: '250px',
                                        }}
                                        minWidth={{
                                            base: '100%',
                                            lg: '250px',
                                        }}
                                        transition="all ease 300ms"
                                        mt={{
                                            base: minimiseOnZoom
                                                ? '-50px'
                                                : status !== 'loading' &&
                                                  regions
                                                ? '-1px'
                                                : '-50px',
                                            lg: '0',
                                        }}
                                        background={{
                                            base: 'light.55',
                                            lg: 'light.10',
                                        }}
                                        borderLeft={{
                                            base: 'none',
                                            lg: '1px solid #E2E8F0',
                                        }}
                                        boxShadow={{
                                            base: '0px 5px 30px -5px rgba(0,0,0,0.2)',
                                            lg: 'none',
                                        }}
                                        zIndex="101"
                                        clipPath="inset(0px 0px -1500px 0px)"
                                    >
                                        <Flex
                                            w={{
                                                base: '100%',
                                                md: 'calc(100vw - 250px)',
                                                lg: '100%',
                                            }}
                                            h={{
                                                base: '54px',
                                                lg: 'auto',
                                            }}
                                            minHeight={{
                                                base: '54px',
                                                lg: 'auto',
                                            }}
                                            position={{
                                                base: 'fixed',
                                                lg: 'relative',
                                            }}
                                            bottom={{
                                                base:
                                                    isMobileOnly && isIOS
                                                        ? '140px'
                                                        : '125px',
                                                md: '0px',
                                                lg: '0px',
                                            }}
                                            background={{
                                                base: 'light.55',
                                                lg: 'light.10',
                                            }}
                                            backdropFilter={{
                                                base: 'blur(20px)',
                                                lg: 'none',
                                            }}
                                            clipPath={{
                                                base: 'inset(-100px 0px 0px 0px)',
                                                lg: 'inset(0px 0px 0px 0px)',
                                            }}
                                            boxShadow={{
                                                base: '0px -5px 30px -5px rgba(0,0,0,0.2)',
                                                lg: 'none',
                                            }}
                                            direction={{
                                                base: 'row',
                                                lg: 'column',
                                            }}
                                            justifyContent={{
                                                base: 'center',
                                                lg: 'center',
                                            }}
                                            alignItems={{
                                                base: 'center',
                                                lg: 'center',
                                            }}
                                            mt={{ base: '3', lg: '5' }}
                                            mb={{
                                                base: minimiseOnZoom
                                                    ? '-120px'
                                                    : '-1px',
                                                md: minimiseOnZoom
                                                    ? '-65px'
                                                    : '-1px',
                                                ml: '-1px',
                                            }}
                                            transition="all ease 300ms"
                                            px={{
                                                base: '20px',
                                                lg: '20px',
                                            }}
                                            pb={{ base: '1px', lg: '3' }}
                                        >
                                            <Flex
                                                // {...sourceSwipe}
                                                flexDirection={{
                                                    base: 'row',
                                                    lg: 'column',
                                                }}
                                                gap={{
                                                    base: '0px',
                                                    lg: '0px',
                                                }}
                                                borderRadius="17px"
                                                px="2px"
                                                width={{
                                                    base: '100%',
                                                    lg: '210px',
                                                }}
                                                maxWidth={{
                                                    base: '430px',
                                                    smd:
                                                        regions.length > 2
                                                            ? '580px'
                                                            : '530px',
                                                    lg: '100%',
                                                }}
                                                height={{
                                                    base: '34px',
                                                    lg: singleRegionOnly
                                                        ? '32px'
                                                        : `${
                                                              regions.length *
                                                                  30 +
                                                              4
                                                          }px`,
                                                }}
                                                minHeight={{
                                                    base: '34px',
                                                    lg: singleRegionOnly
                                                        ? '32px'
                                                        : `${
                                                              regions.length *
                                                                  30 +
                                                              4
                                                          }px`,
                                                }}
                                                overflow="hidden"
                                                bg={
                                                    singleRegionOnly
                                                        ? 'none'
                                                        : 'rgba(8, 38, 71, 0.04)'
                                                }
                                                boxShadow={
                                                    singleRegionOnly
                                                        ? 'none'
                                                        : 'inset 0px 0px 3px -1px rgba(0,0,0,0.35)'
                                                }
                                                justifyContent={
                                                    singleRegionOnly && 'center'
                                                }
                                            >
                                                <Flex
                                                    flexDirection={{
                                                        base: 'row',
                                                        lg: 'column',
                                                    }}
                                                    pt={{
                                                        base: '0px',
                                                        lg: '2px',
                                                    }}
                                                    width="100%"
                                                    justifyContent="center"
                                                >
                                                    {selectedType &&
                                                        regions &&
                                                        regions.map(
                                                            (reg, i) => (
                                                                <Button
                                                                    key={`reg-${reg.id}`}
                                                                    size="sm"
                                                                    width={{
                                                                        base: '100%',
                                                                        lg: '100%',
                                                                    }}
                                                                    maxWidth={{
                                                                        base: '213px',
                                                                        smd: '263px',
                                                                        lg: '100%',
                                                                    }}
                                                                    borderRadius="18px"
                                                                    height={{
                                                                        base: '34px',
                                                                        lg: '30px',
                                                                    }}
                                                                    minHeight={{
                                                                        base: '34px',
                                                                        lg: '30px',
                                                                    }}
                                                                    px="5px"
                                                                    bg="transparent"
                                                                    variant="outline"
                                                                    whiteSpace="pre-line"
                                                                    marginInlineStart="0px !important"
                                                                    border="none"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    paddingLeft="5px"
                                                                    paddingRight="5px"
                                                                    {...noFocus}
                                                                    zIndex="1"
                                                                    _active={{
                                                                        bg: 'none',
                                                                    }}
                                                                    _focus={{
                                                                        bg: 'none',
                                                                    }}
                                                                    _hover={{
                                                                        bg: 'none',
                                                                        opacity:
                                                                            '1',
                                                                    }}
                                                                    transition="all ease 300ms"
                                                                    color={
                                                                        singleRegionOnly
                                                                            ? 'gray.500'
                                                                            : selectedRegion &&
                                                                              selectedRegion ===
                                                                                  reg.id &&
                                                                              regions.length >
                                                                                  1
                                                                            ? 'light.200'
                                                                            : 'light.100'
                                                                    }
                                                                    disabled={
                                                                        singleRegionOnly
                                                                    }
                                                                    opacity="1 !important"
                                                                    cursor={
                                                                        singleRegionOnly
                                                                            ? 'default !important'
                                                                            : 'pointer'
                                                                    }
                                                                    fontWeight={
                                                                        singleRegionOnly
                                                                            ? '400'
                                                                            : selectedRegion &&
                                                                              selectedRegion ===
                                                                                  reg.id
                                                                            ? '400'
                                                                            : '400'
                                                                    }
                                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                    fontSize={{
                                                                        base:
                                                                            regions.length >
                                                                                2 &&
                                                                            reg
                                                                                .label
                                                                                .length >
                                                                                15
                                                                                ? '0.6rem'
                                                                                : '0.75rem',
                                                                        smd:
                                                                            regions.length >
                                                                                2 &&
                                                                            reg
                                                                                .label
                                                                                .length >
                                                                                15
                                                                                ? '0.7rem'
                                                                                : '0.8rem',
                                                                        lg: '13px',
                                                                    }}
                                                                    isDisabled={
                                                                        singleRegionOnly
                                                                    }
                                                                    onClick={() => {
                                                                        setSelectedRegion(
                                                                            reg.id
                                                                        )
                                                                        setScale(
                                                                            1
                                                                        ) // Reset the zoom scale to 1
                                                                        setXy({
                                                                            x: 0,
                                                                            y: 0,
                                                                        }) // Reset x and y to 0
                                                                        setselectedSourcePosition(
                                                                            i
                                                                        )
                                                                    }}
                                                                    userSelect="none"
                                                                >
                                                                    {reg.label}
                                                                </Button>
                                                            )
                                                        )}
                                                </Flex>
                                                {!singleRegionOnly && (
                                                    <Flex // BUTTON INDICATOR
                                                        position="absolute"
                                                        marginInlineStart="0px !important"
                                                        mt="2px"
                                                        ml="0px"
                                                        px="44px"
                                                        height={{
                                                            base: '30px',
                                                            md: '30px',
                                                        }}
                                                        width={{
                                                            base: `${
                                                                (screenWidth -
                                                                    44) /
                                                                regions.length
                                                            }px`,
                                                            lg: '205px',
                                                        }}
                                                        maxWidth={{
                                                            base:
                                                                regions.length >
                                                                2
                                                                    ? '142px'
                                                                    : '213px',
                                                            smd:
                                                                regions.length >
                                                                2
                                                                    ? '192px'
                                                                    : '263px',
                                                            lg: '100%',
                                                        }}
                                                        background="white"
                                                        borderRadius="20px"
                                                        bg="light.10"
                                                        boxShadow="0px 0px 3px -1px rgba(0,0,0,0.25)"
                                                        transform={{
                                                            base: `translateX(${selectedSourcePosition}00%)`,
                                                            lg: `translateY(${selectedSourcePosition}00%)`,
                                                        }}
                                                        transition="transform 400ms"
                                                    ></Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                        <Divider
                                            display={{
                                                base: 'none',
                                                lg: 'flex',
                                            }}
                                            mt="10px"
                                            mb="10px"
                                        />
                                        <Flex
                                            position={{
                                                base: 'absolute',
                                                lg: 'relative',
                                            }}
                                            flexDirection={{
                                                base: 'row',
                                                lg: 'column',
                                            }}
                                            justifyContent="center"
                                            top={{
                                                base: '50px',
                                                lg: 'unset',
                                            }}
                                            w="100%"
                                            height={{
                                                base: '100%',
                                                lg: 'auto',
                                            }}
                                            marginTop={{
                                                base: descriptionShow
                                                    ? '0px'
                                                    : '-50px',
                                                lg: '10px',
                                            }}
                                            transition="margin ease-in-out 300ms"
                                            marginBottom={{
                                                base: descriptionShow
                                                    ? 5
                                                    : '0px',
                                                lg: '5px',
                                            }}
                                            px="25px"
                                            backdropFilter={{
                                                base: 'blur(10px)',
                                                lg: 'none',
                                            }}
                                            textShadow="0px 1px 15px rgba(255, 255, 255, 0.3)"
                                            background={{
                                                base: isFirefox
                                                    ? 'rgba(247, 249, 251, 0.95)'
                                                    : 'rgba(247, 249, 251, 0.85)',
                                                lg: 'none',
                                            }}
                                            boxShadow={{
                                                base: '0px 5px 30px -5px rgba(0,0,0,0.2)',
                                                lg: 'none',
                                            }}
                                        >
                                            <Flex
                                                width={{
                                                    base: '100%',
                                                    md: '100%',
                                                }}
                                                justifyContent="center"
                                                alignItems={{
                                                    base: 'center',
                                                    lg: 'flex-start',
                                                }}
                                                pb={{
                                                    base: '0px',
                                                    lg: '10px',
                                                }}
                                                flexGrow="1"
                                                opacity={{
                                                    base: '1',
                                                    lg: '1',
                                                }}
                                                fontSize={{
                                                    base: '11px',
                                                    md: '0.8rem',
                                                }}
                                                color={{
                                                    base: 'gray.600',
                                                    lg: 'gray.500',
                                                }}
                                                fontWeight={{
                                                    base: '500',
                                                    md: '300',
                                                }}
                                                lineHeight="1.2"
                                            >
                                                {regions.length > 0 && (
                                                    <Text
                                                        textAlign="center"
                                                        letterSpacing="0.3px"
                                                        px={{
                                                            base: '0',
                                                            md: '17px',
                                                        }}
                                                        lineHeight="1.6"
                                                        userSelect="none"
                                                    >
                                                        {description}
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Flex>

                                        <Flex
                                            w="100%"
                                            flexDirection={{
                                                base: 'row',
                                                md: 'column',
                                            }}
                                            justify={'center'}
                                            pt={{ base: '0', lg: '10px' }}
                                            height={{
                                                base: '50px',
                                                lg: 'max-content',
                                            }}
                                            background={{
                                                base: 'light.600',
                                                lg: 'none',
                                            }}
                                            position={{
                                                base: 'absolute',
                                                lg: 'relative',
                                            }}
                                            zIndex="2"
                                            pb={{ base: '0px', lg: '12px' }}
                                            borderBottom={{
                                                base: descriptionShow
                                                    ? '1px solid'
                                                    : '0px solid',
                                                lg: 'none',
                                            }}
                                            borderColor="gray.200"
                                        >
                                            <Flex
                                                // {...infoSwipe}
                                                w="100%"
                                                alignItems="center"
                                                flexDirection={{
                                                    base: 'row',
                                                    lg: 'column',
                                                }}
                                                justifyContent={{
                                                    base: 'space-between',
                                                    lg: 'center',
                                                }}
                                            >
                                                <Flex
                                                    w={{
                                                        base: '100%',
                                                        lg: '100%',
                                                    }}
                                                    alignItems="center"
                                                    justifyContent={{
                                                        base: 'space-evenly',
                                                        md: 'flex-start',
                                                        lg: 'center',
                                                    }}
                                                    flexDirection={{
                                                        base: 'row-reverse',
                                                        lg: 'column',
                                                    }}
                                                >
                                                    <Flex
                                                        w={{
                                                            base: '100%',
                                                            lg: '100%',
                                                        }}
                                                        px="20px"
                                                        minWidth={{
                                                            base: '135px',
                                                            lg: '190px',
                                                        }}
                                                        justifyContent={{
                                                            base: 'center',
                                                            md:
                                                                resolutions.length <
                                                                2
                                                                    ? 'center'
                                                                    : 'left',
                                                        }}
                                                        alignItems="center"
                                                        direction={{
                                                            base: 'row',
                                                            mid: 'column',
                                                        }}
                                                        mt={{
                                                            base: '0px',
                                                            lg: '0px',
                                                        }}
                                                    >
                                                        <Text
                                                            alignSelf="left"
                                                            fontSize="0.7rem"
                                                            color="gray.500"
                                                            pb={{
                                                                base: '0px',
                                                                mid: '15px',
                                                            }}
                                                            mr="10px"
                                                            fontWeight="600"
                                                            lineHeight="1.2"
                                                        >
                                                            Resolution:
                                                        </Text>
                                                        <ButtonGroup
                                                            size={{
                                                                base: 'sm',
                                                                lg: 'xs',
                                                            }}
                                                            height={{
                                                                base: '28px',
                                                                lg: '26px',
                                                            }}
                                                            fontSize={{
                                                                base: '0.8rem',
                                                                lg: '0.7rem',
                                                            }}
                                                            isAttached={true}
                                                            variant="outline"
                                                            borderRadius={10}
                                                        >
                                                            {resolutions.length >
                                                                0 &&
                                                                resolutions.map(
                                                                    (r) => (
                                                                        <Button
                                                                            key={`rangeBtn-${r.id}`}
                                                                            {...noFocus}
                                                                            size="xs"
                                                                            width={{
                                                                                base: '55px',
                                                                                md: '60px',
                                                                            }}
                                                                            height={{
                                                                                base: '28px',
                                                                                lg: '26px',
                                                                            }}
                                                                            isDisabled={
                                                                                resolutions.length <
                                                                                2
                                                                            }
                                                                            cursor="not-allowed"
                                                                            variant="outline"
                                                                            color="light.100"
                                                                            bg="light.10"
                                                                            _hover={{
                                                                                background:
                                                                                    'light.30',
                                                                            }}
                                                                            _active={{
                                                                                background:
                                                                                    'light.30',
                                                                            }}
                                                                            // boxShadow='inset 0px 1px 5px -1px rgba(0,0,0,0.3)'
                                                                            _focus={{
                                                                                boxShadow:
                                                                                    'none',
                                                                            }}
                                                                            borderRadius={
                                                                                10
                                                                            }
                                                                            onClick={() =>
                                                                                setSelectedResolution(
                                                                                    r.id
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                r.id
                                                                            }
                                                                        </Button>
                                                                    )
                                                                )}
                                                        </ButtonGroup>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ) : (
                            <FullWidthError
                                message={`Error downloading Cloud Imagery.<br />Please try again later.`}
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </LightMode>
    )
}
