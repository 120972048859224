import React, { useCallback, useEffect, useContext } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import {
    Box,
    Text,
    Button,
    Flex,
    Stack,
    VStack,
    LightMode,
} from '@chakra-ui/react'

import {
    briefingStandardState,
    selectedBriefingAreasState,
    briefingAreaSourceState,
    layerSelectionState,
    layerSelectionHandler,
    briefingOptionsState,
    dataModeState,
    briefingCreatedState,
    isBriefingOnlyState,
    mapDisabledState,
} from '../../../globalState'

export default function BriefingSelection() {
    const dataMode = useRecoilValue(dataModeState)
    const [selectedAreas, setSelectedAreas] = useRecoilState(
        selectedBriefingAreasState
    )
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const setBriefingOptions = useSetRecoilState(briefingOptionsState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const setLayerSelection = useSetRecoilState(layerSelectionHandler)
    const [briefingCreated, setBriefingCreated] =
        useRecoilState(briefingCreatedState)
    const mapInstance = useContext(MapContext)

    const [briefingAreaSource, setBriefingAreaSource] = useRecoilState(
        briefingAreaSourceState
    )

    const setBriefingStandard = useSetRecoilState(briefingStandardState)

    useCallback(() => {
        if (dataMode === 'brief' && briefingCreated) {
            setBriefingCreated(false)
        }
    }, [dataMode, briefingCreated, setBriefingCreated])

    useEffect(() => {
        setBriefingAreaSource('briefingarea')
    }, [setBriefingAreaSource])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <>
            <LightMode>
                <Box
                    bg="rgba(255,255,255,0.95)"
                    position="absolute"
                    w={{
                        base:
                            isBriefingOnly || mapDisabled
                                ? 'calc(100vw - 30px)'
                                : '200px',
                        md:
                            isBriefingOnly || mapDisabled
                                ? 'calc(100vw - 190px)'
                                : '250px',
                    }}
                    maxWidth="400px"
                    minHeight={{ base: '235px', md: '295px' }}
                    top={isBriefingOnly || mapDisabled ? '50px' : 'auto'}
                    bottom={{ base: '40px', md: '60px' }}
                    right={
                        isBriefingOnly || mapDisabled
                            ? 'unset'
                            : { base: '15px', md: '70px' }
                    }
                    mx={(isBriefingOnly || mapDisabled) && 'auto'}
                    pl={
                        (isBriefingOnly || mapDisabled) && {
                            base: '0px',
                            md: '70px',
                        }
                    }
                    borderRadius="20px"
                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                    zIndex="1300"
                    boxShadow={
                        !(isBriefingOnly || mapDisabled) &&
                        '0px 10px 20px -5px rgba(0,0,0,0.3)'
                    }
                >
                    <Box
                        width="100%"
                        background="rgba(255,255,255,1)"
                        borderBottom="1px solid #eaebec"
                        px={{ base: '10px', md: '15px' }}
                        pt={{ base: '7px', md: '10px' }}
                        pb={{ base: '6px', md: '10px' }}
                        borderTopRadius="20px"
                    >
                        <Text
                            textAlign="center"
                            color={'light.102'}
                            fontSize={{
                                base: '0.7rem',
                                md: '0.7rem',
                            }}
                            fontWeight="300"
                            lineHeight="1.3"
                            marginTop="2px"
                            marginBottom="3px"
                        >
                            PreFlight Information Briefing
                        </Text>
                        <Text
                            textAlign="center"
                            color={'light.100'}
                            fontSize={{
                                base: '0.8rem',
                                md: '0.9rem',
                            }}
                            fontWeight={500}
                        >
                            SELECT STANDARD AREAS
                        </Text>
                    </Box>
                    <Flex flexDirection="column" justifyContent="space-between">
                        <Box
                            width="100%"
                            padding={{ base: '10px', md: '15px' }}
                            paddingBottom="0px !important"
                        >
                            <Flex
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    bg="gray.100"
                                    color="#a8b2c0"
                                    variant="outline"
                                    fontSize={{
                                        base: '0.65rem',
                                        md: '11px',
                                    }}
                                    fontWeight="500"
                                    height={{
                                        base: '32px',
                                        md: '30px',
                                    }}
                                    width="100%"
                                    maxWidth={{
                                        base: '196px',
                                        md: '220px',
                                    }}
                                    style={{
                                        minWidth: '180px',
                                    }}
                                    justifyContent="initial"
                                    paddingLeft="2px"
                                    paddingRight="2px"
                                    py="2px"
                                    borderRadius="20px"
                                    borderColor="#E9EAEB"
                                    {...noFocus}
                                    onClick={() => {
                                        setBriefingAreaSource((prevState) =>
                                            prevState === 'aaw'
                                                ? 'briefingarea'
                                                : 'aaw'
                                        )
                                        setSelectedAreas([])
                                    }}
                                    _hover={{ bg: 'gray.200' }}
                                >
                                    <Box
                                        mt="0px"
                                        px="10px"
                                        height={{
                                            base: '28px',
                                            md: '26px',
                                        }}
                                        width={{
                                            base:
                                                briefingAreaSource ===
                                                'briefingarea'
                                                    ? '96px'
                                                    : '80px',
                                            md: '108px',
                                        }}
                                        minWidth={{
                                            base: '80px',
                                            md: '108px',
                                        }}
                                        ml={{
                                            base:
                                                briefingAreaSource ===
                                                'briefingarea'
                                                    ? '-1px'
                                                    : '95px',
                                            md:
                                                briefingAreaSource ===
                                                'briefingarea'
                                                    ? '-1px'
                                                    : '107px',
                                        }}
                                        borderRadius="20px"
                                        bg="#ffffff"
                                        border="1px solid"
                                        borderColor="#E9EAEB"
                                        transition="all ease 300ms"
                                    ></Box>
                                    <Flex
                                        zIndex="1"
                                        ml="-2px"
                                        position="absolute"
                                        justifyContent="space-evenly"
                                        width={{
                                            base: '180px',
                                            md: '220px',
                                        }}
                                        transition="all ease 300ms"
                                        pointerEvents="none"
                                    >
                                        <Text
                                            width={{
                                                base: '98px',
                                                md: '110px',
                                            }}
                                            minWidth={{
                                                base: '98px',
                                                md: '106px',
                                            }}
                                            lineHeight="1.3"
                                            color={
                                                briefingAreaSource ===
                                                'briefingarea'
                                                    ? 'light.200'
                                                    : 'light.100'
                                            }
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="all ease 300ms"
                                        >
                                            Briefing Areas
                                        </Text>
                                        <Text
                                            ml={{
                                                base: '-3px',
                                                md: '0px',
                                            }}
                                            width={{
                                                base: '86px',
                                                md: '110px',
                                            }}
                                            minWidth={{
                                                base: '86px',
                                                md: '106px',
                                            }}
                                            lineHeight="1.3"
                                            color={
                                                briefingAreaSource === 'aaw'
                                                    ? 'light.200'
                                                    : 'light.100'
                                            }
                                            _selected={{
                                                opacity: '1',
                                            }}
                                            _active={{
                                                opacity: '1',
                                            }}
                                            transition="all ease 300ms"
                                        >
                                            AAW Areas
                                        </Text>
                                    </Flex>
                                </Button>
                            </Flex>
                        </Box>
                        <Flex
                            width="100%"
                            padding={{ base: '10px', md: '15px' }}
                            marginBottom={{ base: '90px', md: '105px' }}
                        >
                            <VStack
                                color="light.100"
                                alignItems="flex-start"
                                paddingLeft={{ base: '10px', md: '15px' }}
                            >
                                {selectedAreas.map((area, i) =>
                                    briefingAreaSource === 'aaw' ? (
                                        <Flex
                                            key={`${area.area}-${i}`}
                                            marginTop="1px !important"
                                        >
                                            <Text
                                                fontSize="0.8rem"
                                                fontWeight="500"
                                                minWidth="35px"
                                            >
                                                {area.area}
                                            </Text>
                                            <Text
                                                fontSize="0.8rem"
                                                fontWeight="300"
                                                width="100%"
                                            >
                                                {area.name}
                                            </Text>
                                        </Flex>
                                    ) : (
                                        <Text
                                            key={`${area.designator}-${i}`}
                                            fontSize="0.8rem"
                                            fontWeight="500"
                                            width="100%"
                                            color="light.100"
                                        >
                                            {area.designator}
                                        </Text>
                                    )
                                )}
                            </VStack>
                        </Flex>
                        <Flex
                            width="100%"
                            padding="0px"
                            position="absolute"
                            bottom="0"
                            flexDirection="column"
                        >
                            <Box>
                                <Box
                                    width="100%"
                                    padding={{ base: '10px', md: '15px' }}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={{ base: '2', md: '4' }}
                                        align="center"
                                    >
                                        <Button
                                            size="xs"
                                            variant="outline"
                                            color="light.100"
                                            fontSize={{
                                                base: '0.65rem',
                                                md: '0.7rem',
                                            }}
                                            fontWeight="400"
                                            borderRadius="10px"
                                            width="100%"
                                            height={{
                                                base: '28px',
                                                md: '26px',
                                            }}
                                            bg="#ffffff"
                                            onClick={() =>
                                                setSelectedAreas(
                                                    mapInstance
                                                        .querySourceFeatures(
                                                            'briefingareas'
                                                        )
                                                        .map((feat) =>
                                                            briefingAreaSource ===
                                                            'aaw'
                                                                ? {
                                                                      area: feat
                                                                          .properties
                                                                          .area,
                                                                      name: feat
                                                                          .properties
                                                                          .name,
                                                                  }
                                                                : {
                                                                      designator:
                                                                          feat
                                                                              .properties
                                                                              .designator,
                                                                  }
                                                        )
                                                        .reduce((acc, cur) => {
                                                            if (
                                                                briefingAreaSource ===
                                                                'aaw'
                                                            ) {
                                                                cur.area !==
                                                                    'LINES' &&
                                                                    !acc
                                                                        .map(
                                                                            (
                                                                                area
                                                                            ) =>
                                                                                area.area
                                                                        )
                                                                        .includes(
                                                                            cur.area
                                                                        ) &&
                                                                    acc.push(
                                                                        cur
                                                                    )
                                                            } else {
                                                                !acc
                                                                    .map(
                                                                        (
                                                                            area
                                                                        ) =>
                                                                            area.designator
                                                                    )
                                                                    .includes(
                                                                        cur.designator
                                                                    ) &&
                                                                    acc.push(
                                                                        cur
                                                                    )
                                                            }
                                                            return acc
                                                        }, [])
                                                )
                                            }
                                            {...noFocus}
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            size="xs"
                                            variant="outline"
                                            color="light.100"
                                            fontSize={{
                                                base: '0.65rem',
                                                md: '0.7rem',
                                            }}
                                            height={{
                                                base: '28px',
                                                md: '26px',
                                            }}
                                            width="100%"
                                            borderRadius="10px"
                                            fontWeight="400"
                                            bg="#ffffff"
                                            onClick={() => setSelectedAreas([])}
                                            {...noFocus}
                                        >
                                            Deselect All
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box
                                width="100%"
                                padding={{ base: '10px', md: '15px' }}
                                borderTop="1px solid #eaebec"
                            >
                                <Stack
                                    direction="row"
                                    spacing={{ base: '2', md: '4' }}
                                    align="center"
                                >
                                    <Button
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        variant="outline"
                                        color="light.100"
                                        bg="#ffffff"
                                        size="sm"
                                        borderRadius="10px"
                                        width="100%"
                                        onClick={() => {
                                            setSelectedAreas([])
                                            if (
                                                layerSelection.brief
                                                    .briefingareas
                                            ) {
                                                setLayerSelection({
                                                    mode: 'brief',
                                                    layer: 'briefingareas',
                                                })
                                            }
                                            setBriefingAreaSource(null)
                                            setBriefingOptions(true)
                                        }}
                                        {...noFocus}
                                        _hover={{
                                            background: 'light.20',
                                        }}
                                        _active={{
                                            background: 'light.20',
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        fontSize="0.8rem"
                                        fontWeight="500"
                                        color="white"
                                        isDisabled={selectedAreas.length < 1}
                                        width="100%"
                                        borderRadius="10px"
                                        background="light.200"
                                        _hover={{
                                            background: 'light.201',
                                        }}
                                        _active={{
                                            background: 'light.201',
                                        }}
                                        size="sm"
                                        onClick={() => {
                                            setBriefingStandard(true)
                                        }}
                                        {...noFocus}
                                    >
                                        OK
                                    </Button>
                                </Stack>
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
            </LightMode>
        </>
    )
}
