import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useTier, useUserMetaData } from '../../../../../auth/hooks'
import {
    useDisclosure,
    Flex,
    Stack,
    Text,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    LightMode,
    useBreakpointValue,
    ModalFooter,
    ModalOverlay,
    Icon,
    Tabs,
    TabPanels,
    TabPanel,
    Spinner,
    Tab,
    TabList,
    ButtonGroup,
    Link,
    Popover,
    PopoverTrigger,
    PopoverAnchor,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    useClipboard,
    Box,
    Tooltip,
} from '@chakra-ui/react'
import { isMobile, isDesktop } from 'react-device-detect'
import {
    ParseCoords,
    parseSavedFavourites,
} from '../../../../actions/RouteHandler'
import { useFilteredDesignators } from '../../../../../util/filterDesignators'
import useConfirm from '../../../alerts/warning-message/components/useConfirm'
import {
    ConfirmDeleteWarningMessage,
    ConfirmDuplicateWarningMessage,
} from '../../../alerts/warning-message/components/WarningMessages'
import {
    briefingAreaSourceState,
    briefingParamsState,
    userProfileState,
    updatedParamsState,
    mfaVerifiedState,
    simulateFreeUserState,
    briefingStandardState,
    loadFavouriteState,
    selectedBriefingTimeState,
    selectedBriefingAltitudeState,
    loadingBriefingFavouriteState,
    excludedAerodromesState,
    excludedAwsState,
    selectedBriefingAreasState,
    currentFavouriteState,
    briefingCustomAreaState,
    briefingCreatedState,
    mapDisabledState,
    isBriefingOnlyState,
    updateExistingFavouriteState,
    favouriteTitleState,
    showFavouritesState,
    showManageFavouritesState,
    newFavouriteTitleState,
    newFavouriteNoteState,
    favouritesListState,
    sortedFavouritesListState,
    hasErroneousDesignatorsState,
    allAerodromesDesignatorsState,
    allAwsDesignatorsState,
    deleteExistingFavouriteState,
    deletedFavouritesState,
    currentUrlState,
    selectAerodromesState,
    selectAwsState,
    updatingExcludedItemsState,
    updateMainDetailsState,
} from '../../../../../globalState'
import { ReactComponent as AddFavouriteIcon } from '../../../../../icons/assets/favourite-add.svg'
import { ReactComponent as LoadFavouriteIcon } from '../../../../../icons/assets/favourite-load.svg'
import SaveNewFavourite from './SaveNewFavourite'
import LoadBriefingFavourite from './LoadBriefingFavourite'
import '../../briefing.css'

// *** MAXIMUM LIMIT FOR AMOUNT OF FAVOURITES - Adjust this if required *** //
const maxLimitFavourites = 5

export default function BriefingFavourites(props) {
    const {
        briefingData,
        remove,
        fetchBriefing,
        isLoading,
        onCloseOptionsHandler,
        formattedCoords,
    } = props

    const [updatedParams, setUpdatedParams] = useRecoilState(updatedParamsState)
    const setBriefingStandard = useSetRecoilState(briefingStandardState)
    const { getAccessTokenSilently } = useAuth0()

    const userMetaData = useUserMetaData()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const userTier = useTier()
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const { isConfirmed } = useConfirm() // alert message handler
    const [showFavourites, setShowFavourites] =
        useRecoilState(showFavouritesState)
    const [showManageFavourites, setShowManageFavourites] = useRecoilState(
        showManageFavouritesState
    )
    const [favouritesList, setFavouritesList] =
        useRecoilState(favouritesListState)
    const [profileData, setProfileData] = useRecoilState(userProfileState)
    const [loadFavourite, setLoadFavourite] = useRecoilState(loadFavouriteState)
    const setSelectedAreas = useSetRecoilState(selectedBriefingAreasState)
    const setLoadingBriefingFavourite = useSetRecoilState(
        loadingBriefingFavouriteState
    )
    const [favouriteTitle, setFavouriteTitle] =
        useRecoilState(favouriteTitleState)
    const [newFavouriteTitle, setNewFavouriteTitle] = useRecoilState(
        newFavouriteTitleState
    )
    const updateMainDetails = useRecoilValue(updateMainDetailsState)
    const setHasErroneousDesignators = useSetRecoilState(
        hasErroneousDesignatorsState
    )
    const allAerodromesDesignators = useRecoilValue(
        allAerodromesDesignatorsState
    )

    const allAwsDesignators = useRecoilValue(allAwsDesignatorsState)
    const setBriefingAreaSource = useSetRecoilState(briefingAreaSourceState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)
    const setBriefingCustomArea = useSetRecoilState(briefingCustomAreaState)
    const [briefingParams, setBriefingParams] =
        useRecoilState(briefingParamsState)
    const setCurrentFavourite = useSetRecoilState(currentFavouriteState)
    const setBriefingCreated = useSetRecoilState(briefingCreatedState)
    const setSelectedHours = useSetRecoilState(selectedBriefingTimeState)
    const setMaxAltitude = useSetRecoilState(selectedBriefingAltitudeState)
    const [excludedAerodromes, setExcludedAerodromes] = useRecoilState(
        excludedAerodromesState
    )
    const [excludedAws, setExcludedAws] = useRecoilState(excludedAwsState)
    const [selectAerodromes, setSelectAerodromes] = useRecoilState(
        selectAerodromesState
    )
    const [selectAws, setSelectAws] = useRecoilState(selectAwsState)
    const setUpdatingExcludedItems = useSetRecoilState(
        updatingExcludedItemsState
    )
    const [sortedFavouritesList, setSortedFavouritesList] = useRecoilState(
        sortedFavouritesListState
    )

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [newFavouriteNote, setNewFavouriteNote] = useRecoilState(
        newFavouriteNoteState
    )
    const [savingFavourite, setSavingFavourite] = useState(false)
    const [savedFavourite, setSavedFavourite] = useState(false)
    const [deleteExistingFav, setDeleteExistingFav] = useRecoilState(
        deleteExistingFavouriteState
    )
    const [currentUrl, setCurrentUrl] = useRecoilState(currentUrlState)
    const [copyUrl, setCopyUrl] = useState(false)
    const [updateExistingFav, setUpdateExistingFav] = useRecoilState(
        updateExistingFavouriteState
    )

    useEffect(() => {
        showManageFavourites || showFavourites ? onOpen() : onClose()
    }, [showManageFavourites, showFavourites, onOpen, onClose])

    useEffect(() => {
        // clear these when modal opens
        if (isOpen) {
            setLoadingBriefingFavourite(true)
            setLoadFavourite(null)
            setNewFavouriteTitle('')
            setNewFavouriteNote('')
        }
    }, [
        isOpen,
        setLoadingBriefingFavourite,
        setLoadFavourite,
        setNewFavouriteTitle,
        setNewFavouriteNote,
    ])

    // *** Adjust these to suit (per above) *** //
    const nearingFavouritesLimit =
        favouritesList?.length === maxLimitFavourites - 1
    const reachedFavouritesLimit = favouritesList?.length >= maxLimitFavourites

    const lowerCaseNewFavouriteTitle =
        newFavouriteTitle && newFavouriteTitle.toLowerCase()

    // const duplicateTitle = Boolean(
    //     favouritesList &&
    //         newFavouriteTitle &&
    //         favouritesList?.filter(
    //             (fav) => fav.title.toLowerCase() === lowerCaseNewFavouriteTitle
    //         ).length > 0
    // )

    const duplicateTitle = Boolean(
        favouritesList &&
            lowerCaseNewFavouriteTitle &&
            favouritesList
                ?.filter(
                    (fav) =>
                        fav.title.toLowerCase() === lowerCaseNewFavouriteTitle
                )
                .filter((fav) =>
                    updateExistingFav
                        ? loadFavourite &&
                          fav.title.toLowerCase() !==
                              loadFavourite.title.toLowerCase()
                        : true
                ).length > 0
    )

    const emptyTitle =
        updateExistingFav &&
        updateMainDetails &&
        !newFavouriteTitle &&
        !(newFavouriteTitle && newFavouriteTitle.length >= 1)

    const [disableButton, setDisableButton] = useState(false) // disable Save Button

    const duplicateUrl = Boolean(
        favouritesList &&
            updatedParams?.url &&
            favouritesList?.filter((fav) => fav.url === updatedParams.url)
                .length > 0
    )

    const currentSelectedUrl =
        duplicateUrl && updatedParams?.url === loadFavourite?.url

    const updatingFavourites = savingFavourite || savedFavourite || isLoading

    const [tabIndex, setTabIndex] = useState(0)
    const [deletedFavourites, setDeletedFavourites] = useRecoilState(
        deletedFavouritesState
    ) // selected favourite to be deleted

    // *** filtering Aerodromes and AWS to remove any erroneous content from the URL ***
    const { filteredExcludedDesignators: filteredExcludedAerodromes } =
        useFilteredDesignators(allAerodromesDesignators, excludedAerodromes)

    const { filteredExcludedDesignators: filteredExcludedAws } =
        useFilteredDesignators(allAwsDesignators, excludedAws)

    const includedAerodromes =
        filteredExcludedAerodromes &&
        briefingData?.res?.aerodromes &&
        briefingData.res.aerodromes
            .map((i) => i.designator)
            .filter(
                (aerodrome) => !filteredExcludedAerodromes.includes(aerodrome)
            )

    const includedAws =
        filteredExcludedAws &&
        briefingData?.res?.aws &&
        briefingData.res.aws
            .map((i) => i.designator)
            .filter((aws) => !filteredExcludedAws.includes(aws))

    useEffect(() => {
        // If user tried to update title to same as another, reset updatedParams to existing loadedFav title
        if (tabIndex === 0 && newFavouriteTitle && duplicateTitle) {
            setUpdatedParams((prevData) => ({
                ...prevData,
                title: loadFavourite && loadFavourite.title,
            }))
        }
        // Update params with title and note
        else if (tabIndex === 0 && newFavouriteTitle && !duplicateTitle) {
            setUpdatedParams((prevData) => ({
                ...prevData,
                title: newFavouriteTitle,
            }))
        }
        if (tabIndex === 1 && favouriteTitle) {
            setUpdatedParams((prevData) => ({
                ...prevData,
                title: favouriteTitle,
            }))
        }
        if (newFavouriteNote) {
            setUpdatedParams((prevData) => ({
                ...prevData,
                note: newFavouriteNote,
            }))
        }
    }, [
        duplicateTitle,
        newFavouriteTitle,
        favouriteTitle,
        newFavouriteNote,
        tabIndex,
        loadFavourite,
        setUpdatedParams,
    ])

    useEffect(() => {
        // Update params with revised Excluded Aerodromes
        if (!selectAerodromes) {
            const sortedExcludedAerodromes = [...filteredExcludedAerodromes]
                .filter((designator) =>
                    allAerodromesDesignators.includes(designator)
                )
                .sort((a, b) => {
                    if (a < b) {
                        return -1
                    }
                    if (a > b) {
                        return 1
                    } else {
                        return 0
                    }
                })
            const { pathname, search } = window.location
            const updatedUrl = pathname + search
            setUpdatedParams((prevData) => ({
                ...prevData,
                excluded: sortedExcludedAerodromes.join(','),
                url: updatedUrl,
            }))
        }
    }, [
        selectAerodromes,
        filteredExcludedAerodromes,
        allAerodromesDesignators,
        setUpdatedParams,
    ])

    useEffect(() => {
        // Update params with revised Excluded AWS
        if (!selectAws) {
            const sortedExcludedAws = [...filteredExcludedAws]
                .filter((designator) => allAwsDesignators.includes(designator))
                .sort((a, b) => {
                    if (a < b) {
                        return -1
                    }
                    if (a > b) {
                        return 1
                    } else {
                        return 0
                    }
                })
            const { pathname, search } = window.location
            const updatedUrl = pathname + search
            setUpdatedParams((prevData) => ({
                ...prevData,
                excludedaws: sortedExcludedAws.join(','),
                url: updatedUrl,
            }))
        }
    }, [selectAws, filteredExcludedAws, allAwsDesignators, setUpdatedParams])

    useEffect(() => {
        if (duplicateUrl) {
            // set to Save Favourite tab on default if current Briefing is not already a saved favourite
            setCurrentFavourite(true)
            if (!isOpen) {
                setTabIndex(1)
            }
        } else {
            setCurrentFavourite(false)
            if (!isOpen) {
                setTabIndex(0)
            }
        }
    }, [isOpen, duplicateUrl, showFavourites, setTabIndex, setCurrentFavourite])

    // DISABLE SAVE/LOAD BUTTON
    useEffect(() => {
        if (
            // loading Favourites from Briefing Options screen
            (showFavourites && !loadFavourite) ||
            // Save as Favourite Tab
            (!showFavourites &&
                ((tabIndex === 0 &&
                    ((deleteExistingFav && !deletedFavourites?.length > 0) ||
                        // Save as New selection
                        (!deleteExistingFav &&
                            !updateExistingFav &&
                            (newFavouriteTitle === '' ||
                                !newFavouriteTitle ||
                                duplicateTitle ||
                                reachedFavouritesLimit ||
                                selectAerodromes ||
                                selectAws)) ||
                        // Update Existing selection
                        (!deleteExistingFav &&
                            updateExistingFav &&
                            (!loadFavourite ||
                                duplicateTitle ||
                                emptyTitle ||
                                selectAerodromes ||
                                selectAws)))) ||
                    // Load a Favourite tab
                    (tabIndex === 1 &&
                        ((deleteExistingFav &&
                            !deletedFavourites?.length > 0) ||
                            (!deleteExistingFav &&
                                (!loadFavourite || currentUrl))))))
        ) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }, [
        showFavourites,
        tabIndex,
        loadFavourite,
        updateExistingFav,
        newFavouriteTitle,
        deleteExistingFav,
        deletedFavourites,
        duplicateTitle,
        emptyTitle,
        reachedFavouritesLimit,
        selectAerodromes,
        selectAws,
        currentUrl,
        setDisableButton,
        isOpen,
    ])

    const getButtonLabel = () => {
        if (savingFavourite || isLoading) {
            return <Spinner boxSize="20px" color="white" />
        } else if (!showFavourites && deleteExistingFav) {
            return 'Delete'
        } else if (
            !showFavourites &&
            savedFavourite &&
            tabIndex === 0 &&
            updateExistingFav
        ) {
            return 'Updated'
        } else if (!showFavourites && savedFavourite) {
            return 'Saved'
        } else if (!showFavourites && tabIndex === 0 && updateExistingFav) {
            return 'Update'
        } else if (!showFavourites && tabIndex === 0) {
            return 'Save'
        } else
            return showFavourites || (!showFavourites && tabIndex !== 0)
                ? 'Load'
                : 'Select'
    }

    useEffect(() => {
        setUpdateExistingFav(false)
        setDeleteExistingFav(false)
        setDeletedFavourites([])
        setSelectAerodromes(false)
        setSelectAws(false)
        setLoadFavourite(null)
        setCurrentUrl(false)
        setUpdatingExcludedItems(false)
    }, [
        tabIndex,
        setUpdateExistingFav,
        setDeleteExistingFav,
        setDeletedFavourites,
        setSelectAerodromes,
        setSelectAws,
        setLoadFavourite,
        setCurrentUrl,
        setUpdatingExcludedItems,
    ])

    useEffect(() => {
        if (!isLoading && briefingData && showManageFavourites) {
            setLoadFavourite(null)
        }
    }, [isLoading, briefingData, showManageFavourites, setLoadFavourite])

    const onCloseHandler = () => {
        if (!showManageFavourites) {
            setShowFavourites(false)
        } else {
            setShowManageFavourites(false)
            setDeletedFavourites([])
            setDeleteExistingFav(false)
            setSavingFavourite(false)
            setSelectAerodromes(false)
            setSelectAws(false)
            setUpdatingExcludedItems(false)
            setNewFavouriteTitle('')
            setNewFavouriteNote('')
            setCurrentUrl(false)
            setUpdatedParams({})
            setTimeout(() => {
                setSavedFavourite(false)
            }, 500)
        }
        onClose()
    }

    const selectedMetItemsToCapitalise = [
        'aws',
        'aaw',
        'sigmet',
        'sigwx',
        'msl',
        'grafor',
    ]
    // this formats items in an array to provide better labels
    const formattedKeys = (key) => {
        if (key === 'aerodromesNotam') {
            return 'NOTAM'
        } else if (key === 'aerodromesMetar') {
            return 'METAR'
        } else if (key === 'aerodromesTaf') {
            return 'TAF'
        } else if (key === 'aerodromesAtis') {
            return 'ATIS'
        } else if (key === 'enroute') {
            return 'En-route NOTAM'
        } else if (key === 'spacewx') {
            return 'Space Weather'
        } else if (selectedMetItemsToCapitalise.includes(key)) {
            // capitalise each word
            return key.toUpperCase()
        } else {
            return key.join(', ')
        }
    }

    const filteredKeys = (entries, isPremium, isFiltered) =>
        entries
            .filter(([key, value]) => key !== 'aerodromes')
            .filter(([key, value]) => !(isPremium && key === 'aws'))
            .filter(([key, value]) => (isFiltered ? value === true : true))
            .map(([key]) => key)

    // this provides all menu items in an array and DOES NOT remove out filtered items (for comparison in Load Favourite)
    const menuContents = filteredKeys(
        Object.entries(briefingParams),
        isPremium,
        false
    )
    // this provides all menu items in an array and DOES remove out filtered items
    const filteredMenuItems = filteredKeys(
        Object.entries(briefingParams),
        isPremium,
        true
    )
    // this formats all the menu items in an array to provide better labels
    const formattedContents = filteredMenuItems.map(formattedKeys)

    const coords = loadFavourite?.params?.coords
    const formattedFavCoords = ParseCoords(coords)

    const handleLoad = () => {
        if (loadFavourite) {
            console.log('Loading Saved Favourite')
            setHasErroneousDesignators(false)
            setLoadingBriefingFavourite(true)
            const favouriteAreas = loadFavourite?.params?.selected
            const source = loadFavourite?.params?.source
            setBriefingAreaSource(source)
            if (
                loadFavourite?.params?.selected &&
                (source === 'briefingarea' || source === 'aaw')
            ) {
                setSelectedAreas(
                    favouriteAreas
                        .split(',')
                        .map((feat) =>
                            source === 'aaw'
                                ? {
                                      area: feat,
                                  }
                                : {
                                      designator: feat,
                                  }
                        )
                        .reduce((acc, cur) => {
                            if (source === 'aaw') {
                                cur.area !== 'LINES' &&
                                    !acc
                                        .map((area) => area.area)
                                        .includes(cur.area) &&
                                    acc.push(cur)
                            } else {
                                !acc
                                    .map((area) => area.designator)
                                    .includes(cur.designator) && acc.push(cur)
                            }
                            return acc
                        }, [])
                )
                setBriefingCustomArea(null)
                setBriefingCreated(true)
            } else if (source === 'customArea') {
                setBriefingCustomArea(formattedFavCoords)
                setBriefingCreated(true)
                setSelectedAreas([])
            }
            if (loadFavourite?.title) {
                setFavouriteTitle(loadFavourite.title)
            }
            if (loadFavourite?.params?.time) {
                setSelectedHours(loadFavourite.params.time)
            }
            if (loadFavourite?.params?.maxalt) {
                setMaxAltitude(loadFavourite.params.maxalt)
            }

            // Set menu filters
            const subtractedSections = loadFavourite?.params?.subtract
                ? loadFavourite.params.subtract.split(',')
                : []
            const newParams =
                briefingParams &&
                Object.fromEntries(
                    Object.keys(briefingParams).map((item) => [
                        item,
                        loadFavourite?.params?.subtract
                            ? !subtractedSections.includes(item) // set filters from favourite
                            : true, // else set as blank
                    ])
                )
            setBriefingParams(newParams)

            if (loadFavourite?.params?.excluded) {
                const updatedExcludedAerodromes = loadFavourite.params.excluded
                    .split(',')
                    .map((item) => item)
                setExcludedAerodromes([...updatedExcludedAerodromes])
            } else {
                setExcludedAerodromes([]) // set to empty array if excluded is null
            }
            if (loadFavourite?.params?.excludedaws) {
                const updatedExcludedAws = loadFavourite.params.excludedaws
                    .split(',')
                    .map((item) => item)
                setExcludedAws([...updatedExcludedAws])
            } else {
                setExcludedAws([]) // set to empty array if excludedaws is null
            }
            if (showManageFavourites) {
                remove()
                fetchBriefing()
            }
            if (showFavourites) {
                onCloseOptionsHandler()
            }
            if (!formattedCoords) {
                setBriefingStandard(true)
            }
            onCloseHandler()
        }
    }

    const aawAreas =
        briefingData?.res?.areamet &&
        briefingData?.res?.areamet?.aaw &&
        briefingData.res.areamet.aaw.map((aaw) => aaw.area).join(', ')

    const saveCallback = async () => {
        const confirmUpdate =
            duplicateUrl && !currentSelectedUrl
                ? await isConfirmed(
                      // "Confirm Duplicate Criteria? popup warning message"
                      'confirm-save-favourite',
                      <ConfirmDuplicateWarningMessage />
                  )
                : true
        if (confirmUpdate && (updatedParams?.title || updateExistingFav)) {
            setSavingFavourite(true)
            const accessToken = await getAccessTokenSilently()

            const favTitle =
                updateExistingFav && loadFavourite?.title
                    ? loadFavourite.title
                    : newFavouriteTitle
                    ? newFavouriteTitle
                    : 'Undefined'
            // new favourite items (object)
            const newFavouriteItems = [
                {
                    name: 'title',
                    default: favTitle,
                },
                { name: 'aaw', default: aawAreas },
                {
                    name: 'note',
                    default:
                        updateExistingFav && loadFavourite?.note
                            ? loadFavourite.note
                            : newFavouriteNote
                            ? newFavouriteNote
                            : null,
                },
                {
                    name: 'url',
                    default: null,
                    // updateExistingFav && updatedParams?.url
                    //     ? updatedParams.url
                    //     : null,
                },
            ]
            const newFavourite = {}
            newFavouriteItems.forEach((property) => {
                newFavourite[property.name] = updatedParams[property.name]
                    ? updatedParams[property.name]
                    : property.default
            })

            let updatedFavourites = []
            if (
                Array.isArray(profileData?.briefingFavourites) ||
                Array.isArray(userMetaData?.briefing_favourites)
            ) {
                const existingFavourites =
                    profileData?.briefingFavourites ||
                    userMetaData?.briefing_favourites ||
                    []

                if (updateExistingFav) {
                    // if updating an existing favourite, find and update it
                    const existingFavIndex = existingFavourites.findIndex(
                        (fav) => fav.title === loadFavourite.title
                    )

                    if (existingFavIndex !== -1) {
                        let newFavouritesArray = [...existingFavourites] // need to spread this as a copy to prevent a previous save creating a read-only property
                        newFavouritesArray[existingFavIndex] = {
                            ...existingFavourites[existingFavIndex],
                            ...newFavourite,
                        }
                        updatedFavourites = newFavouritesArray
                    } else {
                        console.log(
                            'Error trying to update a non-existing Favourite'
                        )
                        return
                    }
                } else {
                    // else save the new favourite
                    updatedFavourites = [...existingFavourites, newFavourite]
                }
            } else {
                updatedFavourites = [newFavourite]
            }

            // Updated profile data with new/updated favourite
            try {
                const res = await fetch(
                    `${window.location.origin}/user/updatebriefingfavourites`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify({
                            briefingFavourites: updatedFavourites,
                        }),
                    }
                )

                if (res.status === 200) {
                    console.log('Saved favourite')
                    setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        briefingFavourites: updatedFavourites,
                    }))
                    setSavingFavourite(false)
                    setSavedFavourite(true)

                    if (favTitle) {
                        setFavouriteTitle(favTitle)
                    }

                    const delay = setTimeout(() => {
                        onCloseHandler()
                        setFavouritesList(updatedFavourites)
                    }, 1000)

                    return () => {
                        clearTimeout(delay)
                    }
                } else {
                    setSavingFavourite(false)
                    console.log('Error saving favourite')
                }
            } catch (error) {
                console.error('Error trying to save the Favourite: ', error)
            }
        }
    }

    const deleteCallback = async () => {
        const confirmDelete =
            deleteExistingFav &&
            (await isConfirmed(
                // "Confirm Delete? popup warning message"
                'confirm-delete',
                <ConfirmDeleteWarningMessage />
            ))
        if (confirmDelete) {
            setSavingFavourite(true)
            const accessToken = await getAccessTokenSilently()
            const reducedFavourites = favouritesList.filter(
                (fav) =>
                    !deletedFavourites.find(
                        (deletedFav) =>
                            deletedFav.title.toLowerCase() ===
                            fav.title.toLowerCase()
                    )
            )
            const updatedReducedFavourites =
                reducedFavourites.length > 0 ? reducedFavourites : []

            fetch(`${window.location.origin}/user/updatebriefingfavourites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    briefingFavourites: updatedReducedFavourites,
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        setFavouritesList(updatedReducedFavourites)
                        setProfileData((prevProfileData) => ({
                            ...prevProfileData,
                            briefingFavourites: updatedReducedFavourites,
                        }))
                        setDeleteExistingFav(false)
                        setLoadFavourite(null)
                        setSavingFavourite(false)
                        setDeletedFavourites([])
                    } else {
                        setLoadFavourite(null)
                        setSavingFavourite(false)
                        console.log('Error deleting favourite')
                    }
                })
                .catch((error) => {
                    console.error('Error during deletion:', error)
                })
        }
    }

    // update favouritesList when the favs have changed
    useEffect(() => {
        // parse the objects through routehandler to get params from URL
        const parsedParams =
            favouritesList?.length > 0
                ? parseSavedFavourites(favouritesList)
                : []
        setSortedFavouritesList(parsedParams)
    }, [favouritesList, setSortedFavouritesList])

    const { onCopy, hasCopied } = useClipboard(
        tabIndex === 0
            ? updatedParams?.url
                ? `${window.location.origin}${updatedParams.url}`
                : ''
            : tabIndex === 1
            ? loadFavourite?.url
                ? `${window.location.origin}${loadFavourite.url}`
                : ''
            : ''
    )

    useEffect(() => {
        const delay = setTimeout(() => {
            setCopyUrl(false)
        }, 1500)
        return () => {
            clearTimeout(delay)
        }
    }, [hasCopied])

    useEffect(() => {
        setCopyUrl(false)
    }, [tabIndex, setCopyUrl])

    const noFocus = {
        _focus: {
            boxShadow: 'none',
        },
    }
    const tab0Text = useBreakpointValue({
        base: 'Save Favourite',
        md: 'Save as a Favourite',
    })

    const tab1Text = useBreakpointValue({
        base: 'Load Favourite',
        md: 'Load a Favourite',
    })

    const menuButtonStyle = {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontWeight: '400',
        alignContent: 'center',
        borderColor: '#E2E8F0',
        opacity: '0.6',
        height: {
            base: '40px',
            md: '40px',
        },
        minHeight: {
            base: '40px',
            md: '40px',
        },
        mb: {
            base: '-1px',
            md: '-1px',
        },
        pb: '5px',
        pl: '5px',
        pr: '10px',
        borderRadius: '0px',
        borderTopLeftRadius: {
            base: '15px',
            md: '20px',
        },
        borderTopRightRadius: {
            base: '15px',
            md: '20px',
        },
        transition: 'all ease 200ms',
        width: '100%',
        bg: 'none',
        _hover: {
            opacity: '1',
            bg: 'none',
        },
        _active: {
            opacity: '1',
            color: 'light.200',
            bg: 'light.10',
        },
        _focus: {
            opacity: '1',
            color: 'light.200',
            bg: 'light.10',
        },
        _selected: {
            opacity: '1',
            color: 'light.200',
            bg: 'white !important',
            borderTop: '1px solid #eaeaea',
            borderBottom: '2px solid #ffffff',
            borderLeft: '1px solid #eaeaea',
            borderRight: '1px solid #eaeaea',
            pt: '8px',
        },
    }

    // useEffect(() => {
    //     console.log('loadFavourite', loadFavourite)
    // }, [loadFavourite])

    return (
        <>
            {!isPremium && (
                <LightMode>
                    <Modal
                        variant={
                            showFavourites
                                ? 'showFavourites'
                                : 'manageFavourites'
                        }
                        isCentered
                        onClose={onCloseHandler}
                        isOpen={isOpen}
                        motionPreset="scale"
                        scrollBehavior="inside"
                        closeOnOverlayClick={true}
                        trapFocus={false}
                        blockScrollOnMount={false}
                    >
                        <ModalOverlay
                            bg={
                                showManageFavourites
                                    ? 'blackAlpha.600'
                                    : 'blackAlpha.400'
                            }
                        />
                        <ModalContent
                            maxHeight={{
                                base: 'calc(100% - 3rem)',
                                md: 'calc(100% - 4rem)',
                            }}
                            width={'3xl'}
                            maxWidth={'3xl'}
                            transition="all ease 300ms"
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            boxShadow={
                                isBriefingOnly || mapDisabled
                                    ? '0px 15px 60px -20px rgba(0,0,0,0.5)'
                                    : '0px 10px 30px -10px rgba(0,0,0,0.5)'
                            }
                        >
                            <ModalCloseButton
                                color="light.100"
                                zIndex="11"
                                onClick={() => {
                                    onCloseHandler()
                                    setLoadingBriefingFavourite(false)
                                    if (!showManageFavourites) {
                                        // clear out these when cancelling only (not when onClosehandler fired and Briefing loaded)
                                        setExcludedAerodromes([])
                                        setSelectedAreas([])
                                        setExcludedAws([])
                                    }
                                }}
                            />
                            <ModalHeader
                                textAlign="center"
                                background="light.10"
                                color="light.100"
                                fontSize="1rem"
                                fontWeight="600"
                                letterSpacing="0.5px"
                                boxShadow={'0px 3px 15px -1px rgba(0,0,0,0.15)'}
                                borderBottom="1px solid #eaeaea"
                                zIndex="10"
                                minHeight="56px"
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                            >
                                <Text px="5px">
                                    {showFavourites
                                        ? 'LOAD A FAVOURITE'
                                        : 'MANAGE FAVOURITES'}
                                </Text>
                            </ModalHeader>
                            <ModalBody
                                as="section"
                                p="0px"
                                backgroundImage={
                                    showManageFavourites
                                        ? 'linear-gradient(0deg, #ffffff 0%, #ffffff 30%, #f5f8fb 70%, #f5f8fb 100%)'
                                        : 'none'
                                }
                            >
                                {showFavourites ? (
                                    <LoadBriefingFavourite
                                        duplicateUrl={false}
                                        menuContents={menuContents}
                                        formattedKeys={formattedKeys}
                                        setDisableButton={setDisableButton}
                                        mapDisabled={mapDisabled}
                                    />
                                ) : (
                                    <Tabs
                                        defaultIndex={tabIndex}
                                        isLazy={true}
                                        onChange={(index) => {
                                            setTabIndex(index)
                                        }}
                                        h="100%"
                                        w="100%"
                                        orientation={'horizontal'}
                                        isFitted
                                        variant="soft-rounded"
                                        align={{
                                            base: 'center',
                                            md: 'initial',
                                        }}
                                        color="light.100"
                                    >
                                        <TabList
                                            alignItems="center"
                                            pt={{
                                                base: '20px',
                                                md: '20px',
                                            }}
                                            px={{
                                                base: '15px',
                                                md: '60px',
                                            }}
                                            borderBottom="1px solid #E2E8F0"
                                            bg="light.30"
                                            color="light.100"
                                        >
                                            <Tab
                                                {...noFocus}
                                                {...menuButtonStyle}
                                                fontSize="0.85rem"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    flexDirection="row"
                                                    flexWrap={{
                                                        base: 'nowrap',
                                                        md: 'wrap',
                                                    }}
                                                    mx="auto"
                                                    mb={
                                                        tabIndex === 1
                                                            ? '2px'
                                                            : '0px'
                                                    }
                                                >
                                                    <Icon
                                                        as={AddFavouriteIcon}
                                                        width={{
                                                            base: '24px',
                                                            md: '22px',
                                                        }}
                                                        height={{
                                                            base: '24px',
                                                            md: '22px',
                                                        }}
                                                    />
                                                    <Text
                                                        pl="8px"
                                                        textAlign="center"
                                                        pb="0"
                                                        fontWeight="500"
                                                        fontSize={{
                                                            base: '0.85rem',
                                                            sm: '0.9rem',
                                                            md: '0.95rem',
                                                        }}
                                                        color="light.100"
                                                    >
                                                        {tab0Text}
                                                    </Text>
                                                </Flex>
                                            </Tab>
                                            <Tab
                                                {...noFocus}
                                                {...menuButtonStyle}
                                                fontSize="0.85rem"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    flexDirection="row"
                                                    flexWrap={{
                                                        base: 'nowrap',
                                                        md: 'wrap',
                                                    }}
                                                    mx="auto"
                                                    mb={
                                                        tabIndex === 0
                                                            ? '2px'
                                                            : '0px'
                                                    }
                                                >
                                                    <Icon
                                                        as={LoadFavouriteIcon}
                                                        width={{
                                                            base: '24px',
                                                            md: '22px',
                                                        }}
                                                        height={{
                                                            base: '24px',
                                                            md: '22px',
                                                        }}
                                                    />
                                                    <Text
                                                        pl="8px"
                                                        textAlign="center"
                                                        pb="0"
                                                        fontWeight="500"
                                                        fontSize={{
                                                            base: '0.85rem',
                                                            sm: '0.9rem',
                                                            md: '0.95rem',
                                                        }}
                                                        color="light.100"
                                                    >
                                                        {tab1Text}
                                                    </Text>
                                                </Flex>
                                            </Tab>
                                        </TabList>
                                        <TabPanels //Content
                                            position="relative"
                                            // overflowX="hidden"
                                            // overflowY="auto"
                                            width="100%"
                                            color="light.100"
                                            bg="white"
                                            pt="0px"
                                            pb="20px"
                                            px={{
                                                base: '15px',
                                                md: '45px',
                                            }}
                                        >
                                            <TabPanel // Save New Favourite (#1)
                                                w="100%"
                                                height="100%"
                                                p="0px"
                                            >
                                                {updatingFavourites && (
                                                    <Flex
                                                        width="100%"
                                                        height="100%"
                                                        justifyContent="center"
                                                        alignItems="flex-start"
                                                        zIndex="100"
                                                        position="absolute"
                                                        top="0px"
                                                        ml={{
                                                            base: '-15px',
                                                            md: '-45px',
                                                        }}
                                                        pt="30vh"
                                                        bg="rgba(255,255,255,0.7)"
                                                    >
                                                        <Spinner
                                                            color={'light.200'}
                                                            emptyColor="rgba(255,255,255,0.25)"
                                                            thickness="3px"
                                                            speed="0.45s"
                                                            boxSize="50px"
                                                        />
                                                    </Flex>
                                                )}
                                                <Flex
                                                    justifyContent="center"
                                                    alignItems="space-between"
                                                    flexWrap="wrap"
                                                    width="100%"
                                                    minHeight={{
                                                        base: '3rem',
                                                        md: '240px',
                                                    }}
                                                    filter={
                                                        !deleteExistingFav &&
                                                        updatingFavourites &&
                                                        'blur(20px)'
                                                    }
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        width={{
                                                            base: '100%',
                                                            md: '100%',
                                                        }}
                                                        gap="15px"
                                                        fontSize="0.8rem"
                                                        pt={{
                                                            base: '8',
                                                            md: '8',
                                                        }}
                                                    >
                                                        <Button
                                                            background="#F7FAFC"
                                                            color="#a8b2c0"
                                                            variant="outline"
                                                            fontSize="1rem"
                                                            fontWeight="500"
                                                            height={{
                                                                base: '34px',
                                                                md: '30px',
                                                            }}
                                                            width={{
                                                                base: '284px',
                                                                md: '324px',
                                                            }}
                                                            minWidth={{
                                                                base: '284px',
                                                                md: '324px',
                                                            }}
                                                            justifyContent="initial"
                                                            paddingLeft="2px"
                                                            paddingRight="2px"
                                                            py="2px"
                                                            borderRadius="20px"
                                                            {...noFocus}
                                                            _active={{
                                                                background:
                                                                    '#F2F7FA',
                                                                boxShadow:
                                                                    'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                            }}
                                                            _hover={{
                                                                background:
                                                                    '#F2F7FA',
                                                            }}
                                                        >
                                                            <Box
                                                                mt="0px"
                                                                ml="-1px"
                                                                height={{
                                                                    base: '30px',
                                                                    md: '26px',
                                                                }}
                                                                width={{
                                                                    base: '140px',
                                                                    md: '160px',
                                                                }}
                                                                minWidth={{
                                                                    base: '140px',
                                                                    md: '160px',
                                                                }}
                                                                background="white"
                                                                borderRadius="20px"
                                                                bg="#ffffff"
                                                                boxShadow="0px 0px 1px 0px rgba(0,0,0,0.35)"
                                                                transform={
                                                                    updateExistingFav
                                                                        ? 'translateX(100%)'
                                                                        : 'translateX(0%)'
                                                                }
                                                                transition="all ease 300ms"
                                                            ></Box>

                                                            <Flex
                                                                zIndex="1"
                                                                justifyContent="space-evenly"
                                                                marginLeft={{
                                                                    base: '-140px',
                                                                    md: '-160px',
                                                                }}
                                                                width={{
                                                                    base: '280px',
                                                                    md: '320px',
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    label={
                                                                        'Save as a new Favourite'
                                                                    }
                                                                    display={
                                                                        isMobile ||
                                                                        !isDesktop
                                                                            ? 'none'
                                                                            : 'flex'
                                                                    }
                                                                    isDisabled={
                                                                        isMobile ||
                                                                        !isDesktop
                                                                    }
                                                                    hasArrow
                                                                    borderRadius="7px"
                                                                    height="26px"
                                                                    fontSize="0.7rem"
                                                                    alignItems="center"
                                                                    placement="top"
                                                                    m="5px"
                                                                    mr="2px"
                                                                    color="light.10"
                                                                    bg="dark.10"
                                                                    justifyContent="center"
                                                                >
                                                                    <Flex
                                                                        zIndex="1"
                                                                        height={{
                                                                            base: '26px',
                                                                            md: '24px',
                                                                        }}
                                                                        paddingLeft="2px"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        width={{
                                                                            base: '140px',
                                                                            md: '160px',
                                                                        }}
                                                                        minWidth={{
                                                                            base: '140px',
                                                                            md: '160px',
                                                                        }}
                                                                        fontSize={{
                                                                            base: '0.75rem',
                                                                            md: '0.8rem',
                                                                        }}
                                                                        fontWeight="600"
                                                                        opacity={
                                                                            !updateExistingFav
                                                                                ? '1'
                                                                                : '0.6'
                                                                        }
                                                                        color={
                                                                            !updateExistingFav
                                                                                ? 'light.200'
                                                                                : 'light.100'
                                                                        }
                                                                        _hover={{
                                                                            opacity:
                                                                                !updateExistingFav
                                                                                    ? '1'
                                                                                    : '0.8',
                                                                        }}
                                                                        transition="all ease 300ms"
                                                                        onClick={() => {
                                                                            setUpdateExistingFav(
                                                                                false
                                                                            )
                                                                            setUpdatedParams(
                                                                                (
                                                                                    prevData
                                                                                ) => ({
                                                                                    ...prevData,
                                                                                })
                                                                            )
                                                                            setLoadFavourite(
                                                                                null
                                                                            )
                                                                            setDeleteExistingFav(
                                                                                false
                                                                            )
                                                                            setDeletedFavourites(
                                                                                []
                                                                            )
                                                                            setNewFavouriteTitle(
                                                                                ''
                                                                            )
                                                                            setNewFavouriteNote(
                                                                                ''
                                                                            )
                                                                        }}
                                                                    >
                                                                        SAVE AS
                                                                        NEW
                                                                    </Flex>
                                                                </Tooltip>

                                                                <Tooltip
                                                                    label={
                                                                        'Update an existing Favourite'
                                                                    }
                                                                    display={
                                                                        isMobile ||
                                                                        !isDesktop
                                                                            ? 'none'
                                                                            : 'flex'
                                                                    }
                                                                    isDisabled={
                                                                        isMobile ||
                                                                        !isDesktop
                                                                    }
                                                                    hasArrow
                                                                    borderRadius="7px"
                                                                    height="26px"
                                                                    fontSize="0.7rem"
                                                                    alignItems="center"
                                                                    placement="top"
                                                                    m="5px"
                                                                    mr="2px"
                                                                    color="light.10"
                                                                    bg="dark.10"
                                                                    justifyContent="center"
                                                                >
                                                                    <Flex
                                                                        zIndex="1"
                                                                        height={{
                                                                            base: '26px',
                                                                            md: '24px',
                                                                        }}
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        width={{
                                                                            base: '140px',
                                                                            md: '160px',
                                                                        }}
                                                                        minWidth={{
                                                                            base: '140px',
                                                                            md: '160px',
                                                                        }}
                                                                        fontSize={{
                                                                            base: '0.75rem',
                                                                            md: '0.8rem',
                                                                        }}
                                                                        fontWeight="600"
                                                                        opacity={
                                                                            updateExistingFav
                                                                                ? '1'
                                                                                : '0.6'
                                                                        }
                                                                        color={
                                                                            updateExistingFav
                                                                                ? 'light.200'
                                                                                : 'light.100'
                                                                        }
                                                                        _hover={{
                                                                            opacity:
                                                                                updateExistingFav
                                                                                    ? '1'
                                                                                    : '0.8',
                                                                        }}
                                                                        transition="all ease 300ms"
                                                                        onClick={() => {
                                                                            setUpdateExistingFav(
                                                                                true
                                                                            )
                                                                            setUpdatedParams(
                                                                                (
                                                                                    prevData
                                                                                ) => ({
                                                                                    ...prevData,
                                                                                    title:
                                                                                        loadFavourite &&
                                                                                        loadFavourite.title,
                                                                                })
                                                                            )
                                                                            setNewFavouriteTitle(
                                                                                ''
                                                                            )
                                                                            setNewFavouriteNote(
                                                                                ''
                                                                            )
                                                                        }}
                                                                    >
                                                                        UPDATE
                                                                        EXISTING
                                                                    </Flex>
                                                                </Tooltip>
                                                            </Flex>
                                                        </Button>
                                                    </Flex>

                                                    {updateExistingFav ? (
                                                        <LoadBriefingFavourite
                                                            briefingData={
                                                                briefingData
                                                            }
                                                            duplicateUrl={
                                                                duplicateUrl
                                                            }
                                                            tab0Text={tab0Text}
                                                            maxLimitFavourites={
                                                                maxLimitFavourites
                                                            }
                                                            nearingFavouritesLimit={
                                                                nearingFavouritesLimit
                                                            }
                                                            reachedFavouritesLimit={
                                                                reachedFavouritesLimit
                                                            }
                                                            menuContents={
                                                                menuContents
                                                            }
                                                            formattedKeys={
                                                                formattedKeys
                                                            }
                                                            duplicateTitle={
                                                                duplicateTitle
                                                            }
                                                            includedAerodromes={
                                                                includedAerodromes
                                                            }
                                                            includedAws={
                                                                includedAws
                                                            }
                                                            filteredExcludedAerodromes={
                                                                filteredExcludedAerodromes
                                                            }
                                                            filteredExcludedAws={
                                                                filteredExcludedAws
                                                            }
                                                            updatingFavourites={
                                                                updatingFavourites
                                                            }
                                                            setDisableButton={
                                                                setDisableButton
                                                            }
                                                            emptyTitle={
                                                                emptyTitle
                                                            }
                                                            mapDisabled={
                                                                mapDisabled
                                                            }
                                                        />
                                                    ) : (
                                                        <SaveNewFavourite
                                                            briefingData={
                                                                briefingData
                                                            }
                                                            isPremium={
                                                                isPremium
                                                            }
                                                            formattedContents={
                                                                formattedContents
                                                            }
                                                            duplicateTitle={
                                                                duplicateTitle
                                                            }
                                                            duplicateUrl={
                                                                duplicateUrl
                                                            }
                                                            maxLimitFavourites={
                                                                maxLimitFavourites
                                                            }
                                                            nearingFavouritesLimit={
                                                                nearingFavouritesLimit
                                                            }
                                                            reachedFavouritesLimit={
                                                                reachedFavouritesLimit
                                                            }
                                                            includedAerodromes={
                                                                includedAerodromes
                                                            }
                                                            includedAws={
                                                                includedAws
                                                            }
                                                            filteredExcludedAws={
                                                                filteredExcludedAws
                                                            }
                                                            filteredExcludedAerodromes={
                                                                filteredExcludedAerodromes
                                                            }
                                                            updatingFavourites={
                                                                updatingFavourites
                                                            }
                                                        />
                                                    )}
                                                </Flex>
                                            </TabPanel>

                                            <TabPanel // Load Favourite (#2)
                                                w="100%"
                                                height="100%"
                                                p="0px"
                                                pt="1"
                                                filter={
                                                    !deleteExistingFav &&
                                                    updatingFavourites &&
                                                    'blur(20px)'
                                                }
                                            >
                                                {updatingFavourites && (
                                                    <Flex
                                                        width="100%"
                                                        height="100%"
                                                        justifyContent="center"
                                                        alignItems="flex-start"
                                                        zIndex="100"
                                                        position="absolute"
                                                        top="0px"
                                                        ml={{
                                                            base: '-15px',
                                                            md: '-45px',
                                                        }}
                                                        pt="30vh"
                                                        bg="rgba(255,255,255,0.7)"
                                                    >
                                                        <Spinner
                                                            color={'light.200'}
                                                            emptyColor="rgba(255,255,255,0.25)"
                                                            thickness="3px"
                                                            speed="0.45s"
                                                            boxSize="50px"
                                                        />
                                                    </Flex>
                                                )}
                                                <LoadBriefingFavourite
                                                    duplicateUrl={duplicateUrl}
                                                    tab0Text={tab0Text}
                                                    maxLimitFavourites={
                                                        maxLimitFavourites
                                                    }
                                                    nearingFavouritesLimit={
                                                        nearingFavouritesLimit
                                                    }
                                                    reachedFavouritesLimit={
                                                        reachedFavouritesLimit
                                                    }
                                                    menuContents={menuContents}
                                                    formattedKeys={
                                                        formattedKeys
                                                    }
                                                    mapDisabled={mapDisabled}
                                                />
                                            </TabPanel>

                                            {/* Unique URL */}
                                            {(tabIndex === 0 ||
                                                (tabIndex === 1 &&
                                                    !showFavourites &&
                                                    sortedFavouritesList?.length >
                                                        0)) && (
                                                <Flex
                                                    pt="20px"
                                                    pb="20px"
                                                    width="100%"
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    flexDirection={{
                                                        base: 'column',
                                                        md: 'column',
                                                    }}
                                                    opacity={
                                                        deleteExistingFav
                                                            ? '0'
                                                            : '1'
                                                    }
                                                    visibility={
                                                        deleteExistingFav
                                                            ? 'hidden'
                                                            : 'visible'
                                                    }
                                                    transition="all ease 200ms"
                                                    filter={
                                                        !deleteExistingFav &&
                                                        updatingFavourites &&
                                                        'blur(20px)'
                                                    }
                                                >
                                                    <Flex
                                                        gap="5px"
                                                        width={{
                                                            base: '100%',
                                                            md: '100%',
                                                        }}
                                                        minHeight={
                                                            loadFavourite
                                                                ? '40px'
                                                                : '20px'
                                                        }
                                                        fontSize="0.8rem"
                                                        alignItems="center"
                                                        justifyContent="flex-start"
                                                        flexDirection="column"
                                                        mb={{
                                                            base: '15px',
                                                            md: '15px',
                                                        }}
                                                    >
                                                        <Text
                                                            fontWeight="600"
                                                            color={{
                                                                base: 'gray.500',
                                                                md: 'gray.600',
                                                            }}
                                                        >
                                                            Unique URL
                                                        </Text>
                                                        {(tabIndex === 0 ||
                                                            (tabIndex === 1 &&
                                                                loadFavourite?.url)) && (
                                                            <Text
                                                                fontSize={{
                                                                    base: '0.75rem',
                                                                    md: '0.75rem',
                                                                }}
                                                                fontWeight="300"
                                                                color="gray.400"
                                                                lineHeight={
                                                                    '1.2'
                                                                }
                                                                fontStyle="italic"
                                                                textAlign="center"
                                                            >
                                                                You can copy and
                                                                paste this
                                                                directly into
                                                                your web
                                                                browser's URL
                                                                address bar
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                    <Popover
                                                        //   initialFocusRef={initialFocusRef}
                                                        placement="top"
                                                        closeOnBlur={false}
                                                        isOpen={copyUrl}
                                                        onClose={!copyUrl}
                                                        isLazy
                                                    >
                                                        <PopoverAnchor>
                                                            <Flex></Flex>
                                                        </PopoverAnchor>
                                                        <PopoverTrigger>
                                                            <>
                                                                {tabIndex ===
                                                                    0 && (
                                                                    <Link
                                                                        textAlign="center"
                                                                        minHeight="20px"
                                                                        fontSize={{
                                                                            base: '0.75rem',
                                                                            md: '0.7rem',
                                                                        }}
                                                                        fontWeight="400"
                                                                        color={
                                                                            copyUrl
                                                                                ? 'light.201'
                                                                                : 'gray.500'
                                                                        }
                                                                        minWidth="180px"
                                                                        wordBreak="break-all"
                                                                        _hover={{
                                                                            color: 'light.200',
                                                                        }}
                                                                        onClick={() => {
                                                                            setCopyUrl(
                                                                                (
                                                                                    prevState
                                                                                ) =>
                                                                                    !prevState
                                                                            )
                                                                        }}
                                                                    >
                                                                        {`${window.location.origin}${updatedParams?.url}`}
                                                                    </Link>
                                                                )}

                                                                {tabIndex ===
                                                                    1 &&
                                                                    (loadFavourite?.url ? (
                                                                        <Link
                                                                            textAlign="center"
                                                                            minHeight="40px"
                                                                            fontSize={{
                                                                                base: '0.75rem',
                                                                                md: '0.7rem',
                                                                            }}
                                                                            mb="0px"
                                                                            fontWeight="400"
                                                                            color={
                                                                                copyUrl
                                                                                    ? 'light.201'
                                                                                    : 'gray.500'
                                                                            }
                                                                            minWidth="180px"
                                                                            wordBreak="break-all"
                                                                            _hover={{
                                                                                color: 'light.200',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                setCopyUrl(
                                                                                    (
                                                                                        prevState
                                                                                    ) =>
                                                                                        !prevState
                                                                                )
                                                                            }}
                                                                        >
                                                                            {`${window.location.origin}${loadFavourite.url}`}
                                                                        </Link>
                                                                    ) : (
                                                                        <Text
                                                                            textAlign="center"
                                                                            minHeight="40px"
                                                                            fontSize={{
                                                                                base: '0.75rem',
                                                                                md: '0.75rem',
                                                                            }}
                                                                            mb="20px"
                                                                            fontWeight="400"
                                                                            color="gray.500"
                                                                            minWidth="180px"
                                                                            wordBreak="break-all"
                                                                            _hover={{
                                                                                color: 'gray.500',
                                                                                cursor: 'default',
                                                                            }}
                                                                        >
                                                                            Select
                                                                            a
                                                                            Briefing
                                                                            above...
                                                                        </Text>
                                                                    ))}
                                                            </>
                                                        </PopoverTrigger>
                                                        <PopoverContent
                                                            bg="white"
                                                            maxWidth="12rem"
                                                            color="light.100"
                                                            borderRadius="20px"
                                                            boxShadow="0px 3px 15px -1px rgba(0,0,0,0.15)"
                                                        >
                                                            <PopoverArrow
                                                                bg="white"
                                                                top="2"
                                                                right="3"
                                                                opacity="0.5"
                                                                fontSize="0.8rem"
                                                            />

                                                            <PopoverBody
                                                                border="0"
                                                                p="3"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="space-between"
                                                            >
                                                                <ButtonGroup
                                                                    width="100%"
                                                                    color="light.100"
                                                                    gap="1"
                                                                >
                                                                    <Button
                                                                        onClick={
                                                                            onCopy
                                                                        }
                                                                        width="100%"
                                                                        fontWeight="500"
                                                                        size="sm"
                                                                        borderRadius="10px"
                                                                        border="1px solid #cbd5e0"
                                                                    >
                                                                        {hasCopied
                                                                            ? 'Copied!'
                                                                            : 'Copy URL'}
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </PopoverBody>
                                                        </PopoverContent>
                                                    </Popover>
                                                </Flex>
                                            )}
                                        </TabPanels>
                                    </Tabs>
                                )}
                            </ModalBody>
                            <ModalFooter
                                borderTop="1px solid #eaebec"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    maxWidth="400px"
                                    width="100%"
                                >
                                    <Stack
                                        direction="row"
                                        spacing={{ base: '20px', md: '4' }}
                                        align="center"
                                        width="100%"
                                    >
                                        <Button
                                            fontSize={{
                                                base: '0.9rem',
                                                md: '0.8rem',
                                            }}
                                            fontWeight="500"
                                            variant="outline"
                                            color="light.100"
                                            bg="#ffffff"
                                            size="sm"
                                            borderRadius="10px"
                                            width="100%"
                                            height={{
                                                base: '38px',
                                                md: '32px',
                                            }}
                                            onClick={() => {
                                                onCloseHandler()
                                                setLoadingBriefingFavourite(
                                                    false
                                                )
                                                if (!showManageFavourites) {
                                                    // clear out these when cancelling only (not when onClosehandler fired and Briefing loaded)
                                                    setExcludedAerodromes([])
                                                    setSelectedAreas([])
                                                    setExcludedAws([])
                                                }
                                            }}
                                            {...noFocus}
                                            _hover={{
                                                background: 'light.20',
                                            }}
                                            _active={{
                                                background: 'light.20',
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        {!(
                                            tabIndex === 0 &&
                                            !showFavourites &&
                                            !updateExistingFav &&
                                            !updatingFavourites &&
                                            reachedFavouritesLimit
                                        ) &&
                                            !(
                                                tabIndex === 1 &&
                                                !showFavourites &&
                                                sortedFavouritesList?.length < 1
                                            ) && (
                                                <Button
                                                    fontSize={{
                                                        base: '0.9rem',
                                                        md: '0.8rem',
                                                    }}
                                                    fontWeight="500"
                                                    color="white"
                                                    isDisabled={disableButton}
                                                    width="100%"
                                                    height={{
                                                        base: '38px',
                                                        md: '32px',
                                                    }}
                                                    borderRadius="10px"
                                                    background={
                                                        deleteExistingFav
                                                            ? 'red.500'
                                                            : updateExistingFav
                                                            ? 'cyan.400'
                                                            : 'light.200'
                                                    }
                                                    _hover={{
                                                        background:
                                                            deleteExistingFav
                                                                ? 'red.600'
                                                                : updateExistingFav
                                                                ? 'cyan.500'
                                                                : 'light.201',
                                                    }}
                                                    _active={{
                                                        background:
                                                            deleteExistingFav
                                                                ? 'red.600'
                                                                : updateExistingFav
                                                                ? 'cyan.500'
                                                                : 'light.201',
                                                    }}
                                                    size="sm"
                                                    onClick={async () => {
                                                        // if (hasErroneousDesignators) { // disabled as not needed at the moment
                                                        //     isConfirmed(
                                                        //         'confirm-accept-warning',
                                                        //         <ErroneousDesignatorsMessage
                                                        //             erroneousAerodromes={
                                                        //                 erroneousAerodromes
                                                        //             }
                                                        //             erroneousAws={
                                                        //                 erroneousAws
                                                        //             }
                                                        //             showManageFavourites={
                                                        //                 showManageFavourites
                                                        //             }
                                                        //         />
                                                        //     )
                                                        // } else
                                                        if (
                                                            !showFavourites &&
                                                            deleteExistingFav
                                                        ) {
                                                            deleteCallback()
                                                        } else if (
                                                            !selectAerodromes &&
                                                            !selectAws &&
                                                            !showFavourites &&
                                                            tabIndex === 0 &&
                                                            !(
                                                                !updateExistingFav &&
                                                                reachedFavouritesLimit
                                                            )
                                                        ) {
                                                            saveCallback()
                                                        } else if (
                                                            showFavourites ||
                                                            (tabIndex === 1 &&
                                                                loadFavourite &&
                                                                !currentUrl)
                                                        ) {
                                                            handleLoad()
                                                        }
                                                    }}
                                                    {...noFocus}
                                                >
                                                    {getButtonLabel()}
                                                </Button>
                                            )}
                                    </Stack>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </LightMode>
            )}
        </>
    )
}
