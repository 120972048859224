import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { isMobileOnly, isIOS } from 'react-device-detect'
import formatAAWData from '../../map/layers/functions/formatAawData'
import FullWidthError from '../../map/modals/components/FullWidthError'
import * as turf from '@turf/turf'
import { isDesktop } from 'react-device-detect'
import {
    Box,
    Text,
    Button,
    Flex,
    LightMode,
    useBreakpointValue,
    useDisclosure,
    Icon,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Center,
    Spinner,
} from '@chakra-ui/react'
import { ReactComponent as AawIcon } from '../../icons/assets/aaw.svg'
import { ReactComponent as SortAlphabeticalIcon } from '../../icons/assets/sort-alphabetical.svg'
import { ReactComponent as SortNorthToSouthIcon } from '../../icons/assets/sort-northtosouth.svg'
import { TfiMore, TfiMoreAlt } from 'react-icons/tfi'
import BriefingAreasImage from '../assets/BriefingAreasImage'
import {
    dataModeState,
    fullscreenModalVisibleHandler,
    statusData,
    clickedFeaturesState,
    aawListState,
    layerSelectionState,
    timeZoneState,
} from '../../globalState'
import FilterButton from '../components/FilterButton'
import AAWViewSelectSwitch from '../components/AAWViewSelectSwitch'

export default function AAWList() {
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const { getAccessTokenSilently } = useAuth0()
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const timeZone = useRecoilValue(timeZoneState)
    const [aawList, setAawList] = useRecoilState(aawListState)
    const [geomData, setGeomData] = useState(null)
    const dataStatus = useRecoilValue(statusData)
    const [showFilters, setShowFilters] = useState(false)

    const [briefingAreasMap, setBriefingAreasMap] = useState(true)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const feat = clickedFeatures[0]
    const scrollbarRef = useRef(null)
    const sortAawByArea = aawList?.sorting?.name
    const aawArea = feat?.properties?.area
    const selectedArea = [
        {
            area: feat?.properties?.area,
            name: feat?.properties?.name,
        },
    ]
    const [aawData, setAawData] = useState({
        type: 'FeatureCollection',
        features: [],
    })

    const toggleAawState = (key, feat) => {
        setAawList((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [feat]: !prevState[key][feat],
            },
        }))
    }

    const fetchAAWData = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(
            `${window.location.origin}/data/aaw/data?t=${Date.now()}`,
            {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }
        )
        return res.json()
    }

    const fetchGeomData = useCallback(async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/aaw/areas`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })

        return res.json()
    }, [getAccessTokenSilently])

    const { data, status } = useQuery('aaw', fetchAAWData, {
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (dataMode === 'met' && layerSelection?.met?.aaw) {
            onOpen()
        } else {
            onClose()
        }
    }, [
        onOpen,
        onClose,
        dataMode,
        layerSelection.met.aaw,
        handleFullscreenModal,
    ])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    // GeomData is fetched once initially.
    useEffect(() => {
        fetchGeomData().then((json) => setGeomData(json))
    }, [fetchGeomData])

    useEffect(
        () => {
            if (dataStatus && data && status === 'success' && geomData) {
                const now = Date.now()
                setAawData(formatAAWData(geomData, data, now, timeZone))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [timeZone, data, geomData]
    )
    const filteredAaw = useMemo(() => {
        if (layerSelection?.met?.aaw) {
            // need useMemo as turf logic causing consistent rerender
            if (!aawData?.features || !aawData.features.length) return []
            return aawData.features
                .filter((feat) => feat?.properties?.area !== 'LINES')
                .map((aaw, i) => {
                    const { properties, geometry } = aaw
                    const modifiedFeature = {
                        // needed so it still works with existing format for modal (when clicked from map in normal mode)
                        layer: 'aaw',
                        ...aaw,
                        properties: {
                            ...properties,
                            altitude: properties?.altitude
                                ? JSON.stringify(properties.altitude)
                                : '',
                            parseddata: properties?.parseddata
                                ? JSON.stringify(properties.parseddata)
                                : '',
                            temp: properties?.temp
                                ? JSON.stringify(properties.temp)
                                : '',
                            winddir: properties?.winddir
                                ? JSON.stringify(properties.winddir)
                                : '',
                            windstr: properties?.windstr
                                ? JSON.stringify(properties.windstr)
                                : '',
                        },
                        geometry: geometry,
                    }
                    return modifiedFeature
                })
        }
    }, [aawData, layerSelection?.met?.aaw])

    const sortedAawForList = useMemo(() => {
        // filteredAaw data sorted by sortAawByArea state
        return [...filteredAaw].sort((a, b) => {
            const centerA = turf.centerOfMass(a.geometry)
            const centerB = turf.centerOfMass(b.geometry)
            const areaCenterA = centerA?.geometry?.coordinates[1]
            const areaCenterB = centerB?.geometry?.coordinates[1]
            if (sortAawByArea) {
                return a?.properties?.area.localeCompare(b?.properties?.area)
            } else {
                return areaCenterA < areaCenterB ? 1 : -1
            }
        })
    }, [filteredAaw, sortAawByArea])

    const sortedAawForImage = useMemo(() => {
        // filteredAaw Data sorted by north to south only (for use in SVG image)
        return [...filteredAaw].sort((a, b) => {
            const centerA = turf.centerOfMass(a.geometry)
            const centerB = turf.centerOfMass(b.geometry)
            const areaCenterA = centerA?.geometry?.coordinates[1]
            const areaCenterB = centerB?.geometry?.coordinates[1]
            return areaCenterA < areaCenterB ? 1 : -1
        })
    }, [filteredAaw])

    const handleMapImageSelection = (feat) => {
        if (sortedAawForImage && sortedAawForImage.length) {
            setClickedFeatures(() => [feat])
        }
    }

    // trigger to reset list/map switch back to default position if list was scrolled
    const scrollToTop = useCallback(() => {
        if (scrollbarRef.current) {
            const osInstance = scrollbarRef.current?.osInstance()
            if (osInstance) {
                const viewport = osInstance.elements().viewport
                viewport.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }
    }, [])

    const aawOptions =
        sortedAawForList &&
        sortedAawForList.map((aaw, i) => {
            return (
                <Flex
                    key={`selected-${aaw?.properties?.area}-${i}`}
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => {
                        setClickedFeatures([aaw])
                    }}
                    cursor="pointer"
                    height={{
                        base: '40px',
                        md: aawArea === aaw?.properties?.area ? '40px' : '36px',
                    }}
                    minHeight={{
                        base: '40px',
                        md: aawArea === aaw?.properties?.area ? '40px' : '36px',
                    }}
                    my={{
                        base: '0px',
                        md: aawArea === aaw?.properties?.area ? '-2px' : '0px',
                    }}
                    opacity={{
                        base: briefingAreasMap ? '0' : '1',
                        md:
                            aawArea && aawArea !== aaw?.properties?.area
                                ? '0.5'
                                : '1',
                    }}
                    transition="all ease 250ms"
                    pl="15px"
                    pr="18px"
                    borderRadius="25px"
                    bg={
                        aawArea === aaw?.properties?.area
                            ? 'white'
                            : 'rgba(255,255,255,0.75)'
                    }
                    boxShadow={
                        aawArea === aaw?.properties?.area
                            ? '0px 8px 25px -7px rgba(0,15,35,0.35)'
                            : '0px 5px 20px -6px rgba(0,15,35,0.2)'
                    }
                    border={aawArea === aaw?.properties?.area && '1px solid'}
                    borderColor="gray.100"
                    transform={
                        aawArea === aaw?.properties?.area && 'scale(1.03)'
                    }
                    color={
                        aawArea === aaw?.properties?.area
                            ? 'light.200'
                            : 'light.100'
                    }
                    _hover={
                        isDesktop &&
                        aawArea !== aaw?.properties?.area && {
                            opacity: '1',
                            cursor: 'pointer',
                            background: 'rgba(255,255,255,1)',
                            boxShadow: '0px 12px 30px -12px rgba(0,15,35,0.7)',
                            transform:
                                'translateY(-1px) translateX(0px) scale(1.01)',
                        }
                    }
                    zIndex={`calc(1000 - ${i})`}
                    gap="10px"
                >
                    <Icon
                        color="#2D778E"
                        width="24px"
                        height="24px"
                        as={AawIcon}
                        alt={''}
                    />

                    <Text
                        pl="2px"
                        fontSize={{
                            base: '0.85rem',
                            md: '0.9rem',
                        }}
                        fontWeight="600"
                        width="100%"
                        transition="all ease 250ms"
                    >
                        {aaw?.properties?.name}
                    </Text>
                    <Text
                        fontSize={{
                            base: '0.85rem',
                            md: '0.9rem',
                        }}
                        fontWeight={
                            aawArea === aaw?.properties?.area ? '400' : '300'
                        }
                        minWidth="60px"
                        textAlign="right"
                        transition="all ease 250ms"
                    >
                        {aaw?.properties?.area}
                    </Text>
                </Flex>
            )
        })

    const scrollBarHide = useBreakpointValue({
        base: 'scroll',
        md: 'leave',
    })

    const setScroll = useBreakpointValue({
        base: briefingAreasMap ? 'hidden' : 'scroll',
        md: 'scroll',
    })

    const pointerEvents = useBreakpointValue({
        base: briefingAreasMap ? 'none' : 'auto',
        md: 'auto',
    })

    const filterNote = useBreakpointValue({
        base: true,
        md: false,
    })

    // useEffect(() => {
    //     console.log('sortedAawForList: ', sortedAawForList)
    // }, [sortedAawForList])

    if (aawData) {
        return (
            <>
                <LightMode>
                    <Modal
                        isOpen={isOpen}
                        closeOnOverlayClick={false}
                        isCentered
                        size="full"
                        backgroundColor="transparent"
                        scrollable
                        variant="imageLooper"
                        zIndex="888"
                        trapFocus={false}
                        blockScrollOnMount={false}
                    >
                        <ModalContent
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            boxShadow={{
                                base: 'none',
                                lg: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                            }}
                            overflow={{
                                base: 'clip',
                                lg: undefined,
                            }}
                        >
                            <ModalHeader
                                backgroundColor="light.10"
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        // showErrorToggle ? '100px' :
                                        '70px',
                                    md: '70px',
                                }}
                                minHeight="70px"
                                maxHeight="70px"
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                >
                                    <Flex
                                        // {...infoSwipe}
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            // {...infoSwipe}
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            maxWidth={{
                                                base: '100%',
                                                ml: '260px',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '30px',
                                                    md: '34px',
                                                }}
                                                color="light.100"
                                                as={AawIcon}
                                                ml={{
                                                    base: '40px',
                                                    md: '4px',
                                                }}
                                                transition="all ease 300ms"
                                            />
                                            <Box
                                                pl={{
                                                    base: '5px',
                                                    md: '10px',
                                                }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color="light.100"
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                    pt="2px"
                                                    whiteSpace="pre"
                                                >
                                                    Aviation Area Winds
                                                </Text>
                                            </Box>
                                        </Flex>

                                        <Flex
                                            gap="10px"
                                            minWidth={{
                                                base: '40px',
                                                ml: '500px',
                                                lg: '600px',
                                            }}
                                            transition="all ease 300ms"
                                            justifyContent="flex-end"
                                        >
                                            <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                                display={{
                                                    base: 'inline-flex',
                                                    lg: 'none',
                                                }}
                                                background="none"
                                                color="light.100"
                                                opacity={
                                                    showFilters ? '1' : '0.8'
                                                }
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setShowFilters(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                }}
                                                _active={{
                                                    background: 'none',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                }}
                                            >
                                                <Flex
                                                    // borderColor="#DFE6EE"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    // border="1px solid #DFE6EE"
                                                    borderRadius="10px"
                                                    color="light.100"
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height="30px"
                                                    width="34px"
                                                    style={{
                                                        minWidth: '34px',
                                                    }}
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.20',
                                                    }}
                                                    _active={{
                                                        background: {
                                                            base: 'none',
                                                            lg: 'light.20',
                                                        },
                                                        boxShadow: {
                                                            base: 'none',
                                                            lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                        },
                                                    }}
                                                >
                                                    <Icon
                                                        fontSize={{
                                                            base: '20px',
                                                            ml: '1.2rem',
                                                        }}
                                                        transition="all 200ms"
                                                        as={
                                                            showFilters
                                                                ? TfiMoreAlt
                                                                : TfiMore
                                                        }
                                                    />
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </ModalHeader>

                            <ModalBody
                                p={0}
                                height={{
                                    base:
                                        isMobileOnly && isIOS
                                            ? 'calc(100% - 145px)'
                                            : 'calc(100% - 130px)',
                                    lg: '100%',
                                }}
                                background="#edf1f7"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                            >
                                <Flex
                                    as="section"
                                    p="0px"
                                    overflow="hidden"
                                    flexDirection="column"
                                    height="100%"
                                    minHeight="100%"
                                    maxHeight={{
                                        base: 'calc(100% - 132px)',
                                        md: 'calc(100vh - 200px)',
                                    }}
                                >
                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            md: 'row',
                                        }}
                                        justifyContent="center"
                                        alignItems={{
                                            base: 'center',
                                            md: 'flex-start',
                                        }}
                                        width="100%"
                                        height="100%"
                                        bg="light.35"
                                    >
                                        <Flex
                                            flexDirection={{
                                                base: 'column',
                                                md: 'column',
                                            }}
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            width="100%"
                                            borderRight={{
                                                base: 'none',
                                                md: '1px solid #e5e7e9',
                                            }}
                                            maxWidth={{
                                                base: '100%',
                                                md: '380px',
                                                ml: '450px',
                                            }}
                                            height="100%"
                                            minHeight={{
                                                base: 'auto',
                                                md: '100%',
                                            }}
                                            maxHeight={{
                                                base: 'unset',
                                                md: '100%',
                                            }}
                                            gap="0px"
                                            bg="white"
                                        >
                                            <Flex
                                                mt={{
                                                    base: showFilters
                                                        ? '0px'
                                                        : '-64px',
                                                    md: '0px',
                                                }}
                                                transition="all ease 300ms"
                                                height={{
                                                    base: '62px',
                                                    md: '60px',
                                                }}
                                                minHeight={{
                                                    base: '62px',
                                                    md: '60px',
                                                }}
                                                width="100%"
                                                padding={{
                                                    base: '10px',
                                                    md: '15px',
                                                }}
                                                justifyContent="center"
                                                flexDirection="column"
                                                bg={{
                                                    base: 'light.55',
                                                    md: 'white',
                                                }}
                                                backdropFilter="blur(20px)"
                                                clipPath="inset(0px 0px -1500px)"
                                                boxShadow={{
                                                    base: 'rgba(0, 0, 0, 0.2) 0px 5px 30px -5px',
                                                    md: 'rgba(0, 0, 0, 0.05) 0px 5px 30px -5px',
                                                }}
                                                zIndex="3"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    px={{
                                                        base: '5px',
                                                        md: '0px',
                                                    }}
                                                    width="100%"
                                                    opacity={{
                                                        base: showFilters
                                                            ? '1'
                                                            : '0',
                                                        md: '1',
                                                    }}
                                                    transition="opacity ease 300ms"
                                                >
                                                    {/* Sort Aaw by North-South or Alphabetically */}
                                                    {briefingAreasMap &&
                                                    filterNote ? (
                                                        <Text
                                                            color="gray.400"
                                                            textAlign="center"
                                                            width="100%"
                                                            fontSize="0.75rem"
                                                            fontWeight="300"
                                                        >
                                                            Sort available in
                                                            list view only
                                                        </Text>
                                                    ) : (
                                                        <FilterButton
                                                            sortState={
                                                                sortAawByArea
                                                            }
                                                            toggleState={
                                                                toggleAawState
                                                            }
                                                            itemKey={'sorting'}
                                                            itemFeat={'name'}
                                                            iconA={
                                                                SortNorthToSouthIcon
                                                            }
                                                            iconB={
                                                                SortAlphabeticalIcon
                                                            }
                                                            tooltipA={`Sort AAW North to South`}
                                                            tooltipB={`Sort AAW Alphabetically`}
                                                        />
                                                    )}
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                flexDirection="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                width="100%"
                                                height="100%"
                                                minHeight={{
                                                    base: showFilters
                                                        ? 'calc(100% - 60px)'
                                                        : 'calc(100% + 4px)',
                                                    md: 'calc(100% - 60px)',
                                                }}
                                                maxHeight={{
                                                    base: showFilters
                                                        ? 'calc(100% - 60px)'
                                                        : 'calc(100% + 4px)',
                                                    md: 'calc(100% - 60px)',
                                                }}
                                                bg={{
                                                    base: 'light.35',
                                                    md:
                                                        status === 'loading'
                                                            ? 'light.30'
                                                            : 'light.35',
                                                }}
                                                transition="all ease 300ms"
                                                zIndex="1"
                                                boxShadow={{
                                                    base: 'inset rgba(0, 0, 0, 0.15) 0px -5px 30px -5px',
                                                    md: 'none',
                                                }}
                                            >
                                                <Flex
                                                    width="100%"
                                                    borderTop="1px solid #eaebec"
                                                    flexDirection="column"
                                                    overflow="hidden"
                                                    // overflowY="auto"
                                                    h="100%"
                                                    transition="all ease 300ms"
                                                    className="settingsScrollVertical"
                                                >
                                                    <OverlayScrollbarsComponent
                                                        ref={scrollbarRef}
                                                        options={{
                                                            className:
                                                                'os-theme-light',
                                                            scrollbars: {
                                                                visibility:
                                                                    'auto',
                                                                autoHide:
                                                                    scrollBarHide,
                                                                autoHideDelay: 800,
                                                                dragScrolling: true,
                                                                clickScrolling: true,
                                                                touchSupport: true,
                                                            },
                                                            overflow: {
                                                                x: 'hidden',
                                                                y: setScroll,
                                                            },
                                                            // callbacks: {
                                                            //     onScroll,
                                                            // },
                                                        }}
                                                        style={{
                                                            minHeight: '100%',
                                                            height: '100%',
                                                            overflowX:
                                                                'hidden !important',
                                                            zIndex: '2',
                                                            pointerEvents:
                                                                pointerEvents,
                                                        }}
                                                        defer
                                                    >
                                                        <AAWViewSelectSwitch
                                                            briefingAreasMap={
                                                                briefingAreasMap
                                                            }
                                                            setBriefingAreasMap={
                                                                setBriefingAreasMap
                                                            }
                                                            scrollToTop={
                                                                scrollToTop
                                                            }
                                                        />
                                                        <Flex
                                                            position={{
                                                                base: 'absolute',
                                                                md: 'relative',
                                                            }}
                                                            top={{
                                                                base: '65px',
                                                                md: 'unset',
                                                            }}
                                                            opacity={{
                                                                base: briefingAreasMap
                                                                    ? '0'
                                                                    : '1',
                                                                md: '1',
                                                            }}
                                                            visibility={{
                                                                base: briefingAreasMap
                                                                    ? 'hidden'
                                                                    : 'visible',
                                                                md: 'visible',
                                                            }}
                                                            pointerEvents={
                                                                pointerEvents
                                                            }
                                                            transition="all ease 300ms"
                                                            width="100%"
                                                            pt={{
                                                                base: '10px',
                                                                md: '20px',
                                                            }}
                                                            pb={{
                                                                base: !briefingAreasMap
                                                                    ? '30px'
                                                                    : '10px',
                                                                md: '30px',
                                                            }}
                                                            px={{
                                                                base: '15px',
                                                                md: '20px',
                                                            }}
                                                            gap="10px"
                                                            flexDirection="column"
                                                            justifyContent="flex-start"
                                                        >
                                                            {status ===
                                                                'loading' && (
                                                                <div className="pageload">
                                                                    <Center
                                                                        w="100%"
                                                                        h="100%"
                                                                        pt="65px"
                                                                    >
                                                                        <Spinner
                                                                            color={
                                                                                'light.200'
                                                                            }
                                                                            emptyColor="rgba(255,255,255,0.3)"
                                                                            thickness="3px"
                                                                            speed="0.45s"
                                                                            boxSize="40px"
                                                                        />
                                                                    </Center>
                                                                </div>
                                                            )}
                                                            {status !==
                                                                'loading' &&
                                                                status ===
                                                                    'error' && (
                                                                    <FullWidthError
                                                                        message={
                                                                            'Error fetching Aviation Area Wind data'
                                                                        }
                                                                    />
                                                                )}
                                                            {status ===
                                                                'success' &&
                                                                dataStatus &&
                                                                (aawOptions &&
                                                                aawOptions.length >
                                                                    0 ? (
                                                                    aawOptions
                                                                ) : (
                                                                    <Text
                                                                        textAlign="center"
                                                                        width="100%"
                                                                        fontSize="0.8rem"
                                                                        color="gray.500"
                                                                        px="10px"
                                                                        py="10px"
                                                                    >
                                                                        All AAW
                                                                        areas
                                                                        have
                                                                        been
                                                                        excluded.
                                                                        <br />
                                                                        Please
                                                                        adjust
                                                                        your
                                                                        filters.
                                                                    </Text>
                                                                ))}
                                                        </Flex>
                                                    </OverlayScrollbarsComponent>
                                                    <Flex
                                                        zIndex="1"
                                                        position="absolute"
                                                        width="100%"
                                                        height="100%"
                                                        opacity={
                                                            !briefingAreasMap
                                                                ? '0'
                                                                : '1'
                                                        }
                                                        visibility={
                                                            !briefingAreasMap
                                                                ? 'hidden'
                                                                : 'visible'
                                                        }
                                                        pointerEvents={
                                                            pointerEvents
                                                        }
                                                        transition="all ease 300ms"
                                                        pt={{
                                                            base: '10px',
                                                            md: '0px',
                                                        }}
                                                        pl="10px"
                                                        pb={{
                                                            base:
                                                                showFilters &&
                                                                isMobileOnly &&
                                                                isIOS
                                                                    ? '142px'
                                                                    : isMobileOnly &&
                                                                      isIOS
                                                                    ? '80px'
                                                                    : showFilters
                                                                    ? '127px'
                                                                    : '65px',
                                                            md: '0px',
                                                        }}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        display={{
                                                            base: 'flex',
                                                            md: 'none',
                                                        }}
                                                    >
                                                        <BriefingAreasImage
                                                            transition="all ease 300ms"
                                                            briefingAreaSource={
                                                                'aaw'
                                                            }
                                                            sortedBriefingAreas={
                                                                sortedAawForImage
                                                            }
                                                            toggleCheckbox={
                                                                handleMapImageSelection
                                                            }
                                                            selectedAreas={
                                                                selectedArea
                                                            }
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex
                                            px="5px"
                                            pt="5px"
                                            height="100%"
                                            minHeight={{
                                                base: 'auto',
                                                // md: 'calc(100vh - 225px)',
                                            }}
                                            maxHeight={{
                                                base: 'unset',
                                                // md: '800px',
                                            }}
                                            width="100%"
                                            display={{
                                                base: 'none',
                                                md: 'flex',
                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <BriefingAreasImage
                                                transition="all ease 300ms"
                                                width="400px"
                                                height="auto"
                                                briefingAreaSource={'aaw'}
                                                sortedBriefingAreas={
                                                    sortedAawForImage
                                                }
                                                toggleCheckbox={
                                                    handleMapImageSelection
                                                }
                                                selectedAreas={selectedArea}
                                                mapDisabled={true}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </LightMode>
            </>
        )
    }

    return null
}
