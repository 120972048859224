import React, { useMemo, useEffect } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { Spinner, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { parseISO } from 'date-fns'
import { formatDateAndTimezone } from '../../../util/dateFormatter'
import { useQuery } from 'react-query'

import { timeZoneState, effectiveDateState } from '../../../globalState'

export default function EffectiveDate() {
    const { getAccessTokenSilently } = useAuth0()
    const timeZone = useRecoilValue(timeZoneState)

    const [effectiveDate, setEffectiveDate] = useRecoilState(effectiveDateState)

    const fetchEffectiveDate = async () => {
        if (!effectiveDate) {
            const accessToken = await getAccessTokenSilently()
            const res = await fetch(
                `${window.location.origin}/source/effectivedate`,
                {
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            return res.json()
        }
    }

    const { data, status } = useQuery(`effectiveDate`, fetchEffectiveDate, {
        refetchOnWindowFocus: false,
    })
    // only fetch the effective dates once
    useEffect(() => {
        if (!effectiveDate && data) {
            setEffectiveDate(data)
        }
    }, [data, effectiveDate, setEffectiveDate])

    const currentAiracDate = useMemo(() => {
        if (effectiveDate && effectiveDate?.current?.date) {
            return formatDateAndTimezone(effectiveDate.current.date, timeZone)
        }
        return ''
    }, [effectiveDate, timeZone])

    const dateColor = useColorModeValue('light.100', 'dark.100')

    const nextAiracDate = useMemo(() => {
        if (effectiveDate && effectiveDate?.next?.date) {
            const color =
                Date.now() > parseISO(effectiveDate.next.date)
                    ? 'red'
                    : dateColor
            return {
                formattedDate: formatDateAndTimezone(
                    effectiveDate.next.date,
                    timeZone
                ),
                color,
            }
        }
        return ''
    }, [effectiveDate, timeZone, dateColor])

    return (
        <Flex flexDir="column" pl="26px" pr="2" color={dateColor}>
            <Flex flexDir="row" textAlign="center" mt="0.9rem" mb={0}>
                <Text fontSize="0.7rem" fontWeight="400" mb={1} pr="8px">
                    AIRAC Effective Date:
                </Text>
                {status === 'loading' && (
                    <Spinner
                        width="15px"
                        height="15px"
                        marginBottom="-2px"
                        marginLeft="10px"
                    />
                )}
                {status !== 'loading' && effectiveDate && (
                    <Text fontSize="0.7rem" opacity="0.7" fontWeight="300">
                        {currentAiracDate}
                    </Text>
                )}
            </Flex>
            <Flex flexDir="row" textAlign="center" mt={0} mb="0.9rem">
                <Text fontSize="0.7rem" pr="8px" fontWeight="400">
                    Next AIRAC Date:
                </Text>
                {status === 'loading' && (
                    <Spinner
                        width="15px"
                        height="15px"
                        marginLeft="10px"
                        marginBottom="-3px"
                    />
                )}
                {status !== 'loading' && effectiveDate && (
                    <Text
                        fontSize="0.7rem"
                        opacity="0.7"
                        fontWeight="300"
                        color={nextAiracDate.color}
                    >
                        {nextAiracDate.formattedDate}
                    </Text>
                )}
            </Flex>
        </Flex>
    )
}
