import React from 'react'
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { useBreakpointValue } from '@chakra-ui/react'

const PDFViewer = ({ fileUrl, aerodromeCharts }) => {
    const sideMargin = useBreakpointValue({
        base: '4px',
        md: '10px',
    })
    const sidePadding = useBreakpointValue({
        base: '0px',
        md: '0 2px',
    })
    const inputWidth = useBreakpointValue({
        base: '2rem',
        md: '3rem',
    })
    const pagesNumberWidth = useBreakpointValue({
        base: '0px 2px 0px 8px',
        md: '0px 5px 0px 6px',
    })
    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput,
                    Download,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                    Rotate,
                } = slots
                return (
                    <div
                        className="pdfViewer"
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        {/* <div style={{ padding: sidePadding }}>
                            <ShowSearchPopover />
                        </div> */}

                        <div
                            className="pdf-button"
                            style={{
                                padding: sidePadding,
                                marginLeft: sideMargin,
                            }}
                        >
                            <GoToPreviousPage />
                        </div>
                        <div
                            style={{ padding: sidePadding, width: inputWidth }}
                        >
                            <CurrentPageInput />
                        </div>
                        <div style={{ padding: pagesNumberWidth }}>
                            of <NumberOfPages />
                        </div>
                        <div
                            className="pdf-button"
                            style={{ padding: sidePadding }}
                        >
                            <GoToNextPage />
                        </div>
                        <div
                            className="pdf-button"
                            style={{ padding: sidePadding, marginLeft: 'auto' }}
                        >
                            <ZoomOut />
                        </div>
                        <div style={{ padding: sidePadding }}>
                            <Zoom />
                        </div>
                        <div
                            className="pdf-button"
                            style={{ padding: sidePadding }}
                        >
                            <ZoomIn />
                        </div>
                        <div
                            className="pdf-button rotate"
                            style={{ padding: sidePadding, marginLeft: 'auto' }}
                        >
                            <Rotate />
                        </div>
                        <div
                            className="pdf-button"
                            style={{
                                padding: sidePadding,
                            }}
                        >
                            <Download />
                        </div>
                        <div
                            className="pdf-button fullscreen"
                            style={{
                                padding: sidePadding,
                                marginRight: sideMargin,
                            }}
                        >
                            <EnterFullScreen />
                        </div>
                    </div>
                )
            }}
        </Toolbar>
    )

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        toolbarPlugin: {
            fullScreenPlugin: {
                getFullScreenTarget: (pagesContainer) =>
                    pagesContainer.closest(
                        '[data-testid="default-layout__body"]'
                    ),
                renderExitFullScreenButton: (props) => <></>,
            },
        },
        sidebarTabs: (defaultTabs) => [],
    })

    return (
        <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance]}
            theme={'light'}
            defaultScale={
                aerodromeCharts
                    ? SpecialZoomLevel.PageWidth
                    : SpecialZoomLevel.PageFit
            }
        />
    )
}

export default PDFViewer
