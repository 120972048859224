import { config } from '../config'
import canUse from './canUse'
import isWip from './isWip'

export default function returnUsableLayers(tier, showWIP, mapDisabled) {
    const { menu, layers } = config
    const layerInfo = {}

    // check through the menus
    Object.keys(menu).forEach((l) => {
        menu[l].forEach(({ id, items }) => {
            // if the menu item has no sub-items
            if (!items) {
                const layerId = id + '-' + l
                const layer = layers[id]
                const isWipLayer =
                    layer && layer.id ? isWip(layer.id) : undefined
                layerInfo[layerId] = {
                    type: 'layer',
                    canUse: canUse(l, id, tier, showWIP, mapDisabled),
                    isWip: isWipLayer,
                    mode: l,
                    label: layer ? layer.label : undefined,
                    id,
                }
            } else {
                // if the menu item has sub-items
                items.forEach((itm) => {
                    const layerId = itm + '-' + l
                    const layer = layers[itm]
                    const isWipLayer =
                        layer && layer.id ? isWip(layer.id) : undefined
                    layerInfo[layerId] = {
                        type: 'layer',
                        canUse: canUse(l, itm, tier, showWIP, mapDisabled),
                        isWip: isWipLayer,
                        mode: l,
                        label: layer ? layer.label : undefined,
                        id: itm,
                    }
                })
            }
        })
    })

    // check for WIP items separately if returning undefined above
    Object.keys(layerInfo).forEach((key) => {
        const layer = layerInfo[key]
        if (
            !layer.isWip &&
            config.menu?.wip?.items &&
            config.menu.wip.items.includes(layer.id)
        ) {
            layer.isWip = true
        }
    })

    return layerInfo
}

// if (m.toLowerCase().search(query.toLowerCase()) !== -1) {
//     return m
// }
