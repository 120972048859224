import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AerodromeIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.7838 4.7282C7.36868 5.46331 5.46338 7.36859 4.72823 9.78369H2.5V14.2162H4.7282C5.46331 16.6313 7.36859 18.5366 9.78369 19.2718V21.5H14.2162V19.2718C16.6313 18.5367 18.5366 16.6314 19.2718 14.2163H21.5V9.7838H19.2718C18.5367 7.36865 16.6313 5.46332 14.2162 4.7282V2.5H9.7838V4.7282ZM12 17.5C15.0376 17.5 17.5 15.0376 17.5 12C17.5 8.96243 15.0376 6.5 12 6.5C8.96243 6.5 6.5 8.96243 6.5 12C6.5 15.0376 8.96243 17.5 12 17.5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
