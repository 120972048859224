import React, { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useTier } from '../../auth/hooks'
import { useQuery } from 'react-query'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { isMobileOnly, isIOS } from 'react-device-detect'
import FullWidthError from '../../map/modals/components/FullWidthError'
import { isDesktop } from 'react-device-detect'
import {
    Box,
    Text,
    Button,
    Flex,
    LightMode,
    useBreakpointValue,
    useDisclosure,
    Icon,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Center,
    Spinner,
} from '@chakra-ui/react'
import { ReactComponent as WebcamIcon } from '../../icons/assets/camera.svg'
import { ReactComponent as WebcamsSymbol } from '../../map/icons/met/camera.svg'
import { ReactComponent as SortAlphabeticalIcon } from '../../icons/assets/sort-alphabetical.svg'
import { ReactComponent as SortNorthToSouthIcon } from '../../icons/assets/sort-northtosouth.svg'
import { TfiMore, TfiMoreAlt } from 'react-icons/tfi'
import {
    dataModeState,
    fullscreenModalVisibleHandler,
    statusData,
    clickedFeaturesState,
    webcamsListState,
    layerSelectionState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../globalState'
import FilterButton from '../components/FilterButton'

export default function WebcamsList() {
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const { getAccessTokenSilently } = useAuth0()
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const [webcamsList, setWebcamsList] = useRecoilState(webcamsListState)
    const [mounted, setMounted] = useState(false)
    const [webcams, setWebcams] = useState([])
    const dataStatus = useRecoilValue(statusData)
    const [showFilters, setShowFilters] = useState(false)
    const userTier = useTier()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const sortWebcamsByLocation = webcamsList?.sorting?.location

    const toggleWebcamsState = (key, feat) => {
        setWebcamsList((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [feat]: !prevState[key][feat],
            },
        }))
    }

    const fetchWebcams = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/webcams`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }

    const { data, status } = useQuery('webcams', fetchWebcams, {
        refetchOnWindowFocus: false,
    })
    const { isOpen, onOpen, onClose } = useDisclosure()
    const feat = clickedFeatures[0]
    const webcamsLocation = feat?.properties?.location

    useEffect(() => {
        if (dataMode === 'met' && layerSelection?.met?.webcams) {
            onOpen()
        } else {
            onClose()
        }
    }, [
        onOpen,
        onClose,
        dataMode,
        layerSelection.met.webcams,
        handleFullscreenModal,
    ])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    // This sets the intial value of webcams when it is loaded.
    useEffect(() => {
        if (dataStatus && data && status === 'success' && !mounted) {
            const webcamsProperties = data.features.map((feat) => ({
                ...feat.properties,
                lat: feat.geometry.coordinates[1],
                lng: feat.geometry.coordinates[0],
            }))
            setWebcams(webcamsProperties)
            // webcamsVersionRef.current = dataStatus.webcams.version
            setMounted(true)
        }
    }, [setWebcams, data, status, mounted, dataStatus])

    const filteredWebcams =
        webcams &&
        webcams.sort((a, b) => {
            // sort by location or north/south
            if (sortWebcamsByLocation) {
                return a.location.localeCompare(b.location)
            } else {
                return a.lat < b.lat ? 1 : -1
            }
        })

    const webcamsOptions =
        filteredWebcams &&
        filteredWebcams.map((webcam, i) => {
            const { location, webcams } = webcam

            const modifiedFeature = {
                layer: 'webcams',
                properties: {
                    ...webcam,
                    webcams: webcams ? JSON.stringify(webcams) : '',
                },
                geometry: webcam.geometry,
            }

            return (
                <Flex
                    key={`selected-${location}-${i}`}
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => {
                        setClickedFeatures([modifiedFeature])
                    }}
                    cursor="pointer"
                    height={{
                        base: '40px',
                        md: webcamsLocation === location ? '40px' : '36px',
                    }}
                    minHeight={{
                        base: '40px',
                        md: webcamsLocation === location ? '40px' : '36px',
                    }}
                    my={{
                        base: '0px',
                        md: webcamsLocation === location ? '-2px' : '0px',
                    }}
                    opacity={{
                        base: '1',
                        md:
                            webcamsLocation && webcamsLocation !== location
                                ? '0.5'
                                : '1',
                    }}
                    transition="all ease 250ms"
                    pl="15px"
                    pr="18px"
                    borderRadius="25px"
                    bg={
                        webcamsLocation === location
                            ? 'white'
                            : 'rgba(255,255,255,0.75)'
                    }
                    boxShadow={
                        webcamsLocation === location
                            ? '0px 8px 25px -7px rgba(0,15,35,0.35)'
                            : '0px 5px 20px -6px rgba(0,15,35,0.2)'
                    }
                    border={webcamsLocation === location && '1px solid'}
                    borderColor="gray.100"
                    transform={webcamsLocation === location && 'scale(1.03)'}
                    color={
                        webcamsLocation === location ? 'light.200' : 'light.100'
                    }
                    _hover={
                        isDesktop &&
                        webcamsLocation !== location && {
                            opacity: '1',
                            cursor: 'pointer',
                            background: 'rgba(255,255,255,1)',
                            boxShadow: '0px 12px 30px -12px rgba(0,15,35,0.7)',
                            transform:
                                'translateY(-1px) translateX(0px) scale(1.01)',
                        }
                    }
                    zIndex={`calc(1000 - ${i})`}
                    gap="10px"
                >
                    <Icon
                        width="24px"
                        height="24px"
                        as={WebcamsSymbol}
                        alt={location ? location : ''}
                    />

                    <Text
                        pl="2px"
                        fontSize={{
                            base: '0.85rem',
                            md: '0.9rem',
                        }}
                        fontWeight="600"
                        width="100%"
                        transition="all ease 250ms"
                    >
                        {location}
                    </Text>
                </Flex>
            )
        })

    const scrollBarHide = useBreakpointValue({
        base: 'scroll',
        md: 'leave',
    })

    // console.log('WEBCAMS List: ', webcams)
    // console.log('FEAT: ', feat)

    if (webcams) {
        return (
            <>
                <LightMode>
                    <Modal
                        isOpen={isOpen}
                        closeOnOverlayClick={false}
                        isCentered
                        size="full"
                        backgroundColor="transparent"
                        scrollable
                        variant="imageLooper"
                        zIndex="888"
                        trapFocus={false}
                        blockScrollOnMount={false}
                    >
                        <ModalContent
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            boxShadow={{
                                base: 'none',
                                lg: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                            }}
                            overflow={{
                                base: 'clip',
                                lg: undefined,
                            }}
                        >
                            <ModalHeader
                                backgroundColor="light.10"
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        // showErrorToggle ? '100px' :
                                        '70px',
                                    md: '70px',
                                }}
                                minHeight="70px"
                                maxHeight="70px"
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                >
                                    <Flex
                                        // {...infoSwipe}
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            // {...infoSwipe}
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            maxWidth={{
                                                base: '100%',
                                                ml: '260px',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '30px',
                                                    md: '34px',
                                                }}
                                                color="light.100"
                                                as={WebcamIcon}
                                                ml={{
                                                    base: '40px',
                                                    md: '4px',
                                                }}
                                                transition="all ease 300ms"
                                            />
                                            <Box
                                                pl={{
                                                    base: '5px',
                                                    md: '10px',
                                                }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color="light.100"
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                    pt="2px"
                                                    whiteSpace="pre"
                                                >
                                                    Webcams
                                                </Text>
                                            </Box>
                                        </Flex>

                                        <Flex
                                            gap="10px"
                                            minWidth={{
                                                base: '40px',
                                                ml: '500px',
                                                lg: '600px',
                                            }}
                                            transition="all ease 300ms"
                                            justifyContent="flex-end"
                                        >
                                            <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                                display={{
                                                    base: 'inline-flex',
                                                    lg: 'none',
                                                }}
                                                background="none"
                                                color="light.100"
                                                opacity={
                                                    showFilters ? '1' : '0.8'
                                                }
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setShowFilters(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                }}
                                                _active={{
                                                    background: 'none',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                }}
                                            >
                                                <Flex
                                                    // borderColor="#DFE6EE"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    // border="1px solid #DFE6EE"
                                                    borderRadius="10px"
                                                    color="light.100"
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height="30px"
                                                    width="34px"
                                                    style={{
                                                        minWidth: '34px',
                                                    }}
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.20',
                                                    }}
                                                    _active={{
                                                        background: {
                                                            base: 'none',
                                                            lg: 'light.20',
                                                        },
                                                        boxShadow: {
                                                            base: 'none',
                                                            lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                        },
                                                    }}
                                                >
                                                    <Icon
                                                        fontSize={{
                                                            base: '20px',
                                                            ml: '1.2rem',
                                                        }}
                                                        transition="all 200ms"
                                                        as={
                                                            showFilters
                                                                ? TfiMoreAlt
                                                                : TfiMore
                                                        }
                                                    />
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </ModalHeader>

                            <ModalBody
                                p={0}
                                height={{
                                    base:
                                        isMobileOnly && isIOS
                                            ? 'calc(100% - 145px)'
                                            : 'calc(100% - 130px)',
                                    lg: '100%',
                                }}
                                background="#edf1f7"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                            >
                                {!isPremium && (
                                    <>
                                        <Flex
                                            as="section"
                                            p="0px"
                                            overflow="hidden"
                                            flexDirection="column"
                                            height="100%"
                                            minHeight="100%"
                                            maxHeight={{
                                                base: 'calc(100% - 132px)',
                                                md: 'calc(100vh - 200px)',
                                            }}
                                        >
                                            <Flex
                                                flexDirection={{
                                                    base: 'column',
                                                    md: 'row',
                                                }}
                                                justifyContent="center"
                                                alignItems={{
                                                    base: 'center',
                                                    md: 'flex-start',
                                                }}
                                                width="100%"
                                                height="100%"
                                                bg="light.35"
                                            >
                                                <Flex
                                                    flexDirection={{
                                                        base: 'column',
                                                        md: 'column',
                                                    }}
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    width="100%"
                                                    borderRight={{
                                                        base: 'none',
                                                        md: '1px solid #e5e7e9',
                                                    }}
                                                    maxWidth={{
                                                        base: '100%',
                                                        md: '380px',
                                                        ml: '450px',
                                                    }}
                                                    height="100%"
                                                    minHeight={{
                                                        base: 'auto',
                                                        md: '100%',
                                                    }}
                                                    maxHeight={{
                                                        base: 'unset',
                                                        md: '100%',
                                                    }}
                                                    gap="0px"
                                                    bg="white"
                                                >
                                                    <Flex
                                                        mt={{
                                                            base: showFilters
                                                                ? '0px'
                                                                : '-64px',
                                                            md: '0px',
                                                        }}
                                                        height={{
                                                            base: '62px',
                                                            md: '60px',
                                                        }}
                                                        minHeight={{
                                                            base: '62px',
                                                            md: '60px',
                                                        }}
                                                        width="100%"
                                                        padding={{
                                                            base: '10px',
                                                            md: '15px',
                                                        }}
                                                        justifyContent="center"
                                                        flexDirection="column"
                                                        transition="all ease 300ms"
                                                        bg={{
                                                            base: 'light.55',
                                                            md: 'white',
                                                        }}
                                                        backdropFilter="blur(20px)"
                                                        clipPath="inset(0px 0px -1500px)"
                                                        boxShadow={{
                                                            base: 'rgba(0, 0, 0, 0.2) 0px 5px 30px -5px',
                                                            md: 'rgba(0, 0, 0, 0.05) 0px 5px 30px -5px',
                                                        }}
                                                        zIndex="3"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            justifyContent="flex-start"
                                                            px={{
                                                                base: '5px',
                                                                md: '0px',
                                                            }}
                                                            width="100%"
                                                            opacity={{
                                                                base: showFilters
                                                                    ? '1'
                                                                    : '0',
                                                                md: '1',
                                                            }}
                                                            transition="opacity ease 300ms"
                                                        >
                                                            {/* Sort Webcams by North-South or Alphabetically */}
                                                            <FilterButton
                                                                sortState={
                                                                    sortWebcamsByLocation
                                                                }
                                                                toggleState={
                                                                    toggleWebcamsState
                                                                }
                                                                itemKey={
                                                                    'sorting'
                                                                }
                                                                itemFeat={
                                                                    'location'
                                                                }
                                                                iconA={
                                                                    SortNorthToSouthIcon
                                                                }
                                                                iconB={
                                                                    SortAlphabeticalIcon
                                                                }
                                                                tooltipA={`Sort Webcams North to South`}
                                                                tooltipB={`Sort Webcams Alphabetically`}
                                                            />
                                                        </Flex>
                                                    </Flex>

                                                    <Flex
                                                        flexDirection="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        width="100%"
                                                        height="100%"
                                                        minHeight={{
                                                            base: showFilters
                                                                ? 'calc(100% - 60px)'
                                                                : 'calc(100% + 4px)',
                                                            md: 'calc(100% - 60px)',
                                                        }}
                                                        maxHeight={{
                                                            base: showFilters
                                                                ? 'calc(100% - 60px)'
                                                                : 'calc(100% + 4px)',
                                                            md: 'calc(100% - 60px)',
                                                        }}
                                                        bg={{
                                                            base: 'light.35',
                                                            md:
                                                                status ===
                                                                'loading'
                                                                    ? 'light.30'
                                                                    : 'light.35',
                                                        }}
                                                        transition="all ease 300ms"
                                                        zIndex="1"
                                                        boxShadow={{
                                                            base: 'inset rgba(0, 0, 0, 0.15) 0px -5px 30px -5px',
                                                            md: 'none',
                                                        }}
                                                    >
                                                        <Flex
                                                            width="100%"
                                                            borderTop="1px solid #eaebec"
                                                            flexDirection="column"
                                                            overflowX="hidden"
                                                            overflowY="auto"
                                                            h="100%"
                                                            transition="all ease 300ms"
                                                            className="settingsScrollVertical"
                                                        >
                                                            <OverlayScrollbarsComponent
                                                                options={{
                                                                    className:
                                                                        'os-theme-light',
                                                                    scrollbars:
                                                                        {
                                                                            visibility:
                                                                                'auto',
                                                                            autoHide:
                                                                                scrollBarHide,
                                                                            autoHideDelay: 800,
                                                                            dragScrolling: true,
                                                                            clickScrolling: true,
                                                                            touchSupport: true,
                                                                        },
                                                                    overflow: {
                                                                        x: 'hidden',
                                                                        y: 'scroll',
                                                                    },
                                                                }}
                                                                style={{
                                                                    height: '100%',
                                                                    minHeight:
                                                                        '100%',
                                                                    overflowX:
                                                                        'hidden !important',
                                                                }}
                                                                defer
                                                            >
                                                                <Flex
                                                                    transition="all ease 300ms"
                                                                    width="100%"
                                                                    pt="20px"
                                                                    pb="30px"
                                                                    px={{
                                                                        base: '15px',
                                                                        md: '20px',
                                                                    }}
                                                                    gap="10px"
                                                                    flexDirection="column"
                                                                    justifyContent="flex-start"
                                                                >
                                                                    {status ===
                                                                        'loading' && (
                                                                        <Center
                                                                            w="100%"
                                                                            pt="65px"
                                                                        >
                                                                            <Spinner
                                                                                color={
                                                                                    'light.200'
                                                                                }
                                                                                emptyColor="rgba(255,255,255,0.3)"
                                                                                thickness="3px"
                                                                                speed="0.45s"
                                                                                boxSize="40px"
                                                                            />
                                                                        </Center>
                                                                    )}
                                                                    {status !==
                                                                        'loading' &&
                                                                        status ===
                                                                            'error' && (
                                                                            <FullWidthError
                                                                                message={
                                                                                    'Error fetching Webcams data'
                                                                                }
                                                                            />
                                                                        )}
                                                                    {status ===
                                                                        'success' &&
                                                                        dataStatus &&
                                                                        (webcamsOptions &&
                                                                        webcamsOptions.length >
                                                                            0 ? (
                                                                            webcamsOptions
                                                                        ) : (
                                                                            <Text
                                                                                textAlign="center"
                                                                                width="100%"
                                                                                fontSize="0.8rem"
                                                                                color="gray.500"
                                                                                px="10px"
                                                                                py="10px"
                                                                            >
                                                                                All
                                                                                Webcams
                                                                                have
                                                                                been
                                                                                excluded.
                                                                                <br />
                                                                                Please
                                                                                adjust
                                                                                your
                                                                                filters.
                                                                            </Text>
                                                                        ))}
                                                                </Flex>
                                                            </OverlayScrollbarsComponent>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Flex
                                                    px="5px"
                                                    pt="50px"
                                                    pb="100px"
                                                    height="100%"
                                                    width="100%"
                                                    display={{
                                                        base: 'none',
                                                        md: 'flex',
                                                    }}
                                                    color="gray.400"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    fontSize="0.9rem"
                                                    opacity={
                                                        !(
                                                            clickedFeatures &&
                                                            clickedFeatures.length >
                                                                0
                                                        )
                                                            ? '1'
                                                            : '0'
                                                    }
                                                    transition="opacity ease 300ms"
                                                >
                                                    Select a Webcam
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </>
                                )}
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </LightMode>
            </>
        )
    }

    return null
}
