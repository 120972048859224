import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
// import { isIOS } from 'react-device-detect'

const Auth0ProviderWithHistory = ({ children }) => {
    const customDomain = JSON.parse(process.env.REACT_APP_DEV_ENV.toLowerCase())
        ? process.env.REACT_APP_DEV_AUTH0_CUSTOM_DOMAIN
        : process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN
    const domain = JSON.parse(process.env.REACT_APP_DEV_ENV.toLowerCase())
        ? process.env.REACT_APP_DEV_AUTH0_DOMAIN
        : process.env.REACT_APP_AUTH0_DOMAIN
    const clientId = JSON.parse(process.env.REACT_APP_DEV_ENV.toLowerCase())
        ? process.env.REACT_APP_DEV_AUTH0_CLIENT_ID
        : process.env.REACT_APP_AUTH0_CLIENT_ID

    return (
        <Auth0Provider
            // domain={customDomain} // can't use custom domain here as it breaks updating the profile. Can re-enable when profile updates are moved to backend
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.href}
            authorizationParams={{ audience: `https://${customDomain}` }}
            // bottom two props are to overcome issue in iOS with landing page showing as logged out on initial page load, when actually still logged in
            // useRefeshTokens={isIOS ? true : undefined}
            // cacheLocation={isIOS ? 'localstorage' : undefined}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
