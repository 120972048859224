import React, { useContext, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Source, Layer, MapContext } from 'react-mapbox-gl'
import calcSymbolPlacement from '../functions/dynamicSymbols'
import {
    dataModeState,
    altitudeModeState,
    selectedLowerAltitudeState,
    selectedUpperAltitudeState,
    selectedTimeState,
    VectorLayerRefreshInfo,
    globalTimeState,
} from '../../../globalState'

export default function SigmetLayer() {
    const dataMode = useRecoilValue(dataModeState)
    const [altitudeMode, setAltitudeMode] = useRecoilState(altitudeModeState)
    const selectedLowerAltitude = useRecoilValue(selectedLowerAltitudeState)
    const selectedUpperAltitude = useRecoilValue(selectedUpperAltitudeState)
    const now = useRecoilValue(globalTimeState)
    const selectedTime = useRecoilValue(selectedTimeState)
    const layerInfo = useRecoilValue(VectorLayerRefreshInfo)
    const mapInstance = useContext(MapContext)

    useEffect(() => {
        if (altitudeMode !== 'double') {
            setAltitudeMode('double')
        }
    }, [altitudeMode, setAltitudeMode])

    // labelData is defined here as a useRef, directly using an empty FeatureCollection in the source breaks dynamic symbol placement
    const labelData = useRef({
        type: 'FeatureCollection',
        features: [],
    })

    useEffect(() => {
        const dynamicSymbols = () => {
            calcSymbolPlacement(
                mapInstance,
                'sigmet-fill',
                'sigmet-arrow-symbol',
                'id'
            )
        }

        const moveEndCallback = () => {
            var tileLoad = setInterval(function () {
                if (mapInstance.loaded()) {
                    dynamicSymbols()
                    clearInterval(tileLoad)
                }
            }, 300)
        }

        mapInstance.on('moveend', moveEndCallback)

        function renderCallback(e) {
            if (e.target && e.target.loaded()) {
                dynamicSymbols()
                mapInstance.off('render', renderCallback)
            }
        }

        mapInstance.on('render', renderCallback)

        return () => {
            mapInstance.off('moveend', moveEndCallback)
        }
    }, [layerInfo.sigmet.dataURL, mapInstance])

    const color = [
        'match',
        ['get', 'phenomenon'],
        [
            'ISOL TS',
            'OBSC TS',
            'EMBD TS',
            'FRQ TS',
            'SQL TS',
            'OBSC TSGR',
            'EMBD TSGR',
        ],
        '#8000FF',
        'SEV TURB',
        '#FF0000',
        ['SEV ICE', 'SEV ICE (FZRA)'],
        '#00BEFA',
        'SEV MTW',
        '#000000',
        ['HVY DS', 'HVY SS'],
        '#C0A080',
        'RDOACT CLD',
        '#80B425',
        'VA ERUPTION',
        '#eb6aeb',
        'VA CLD',
        '#eb6aeb',
        'TC',
        '#1261A0',
        '#85a0b5',
    ]

    const icon = [
        'match',
        ['get', 'phenomenon'],
        [
            'ISOL TS',
            'OBSC TS',
            'EMBD TS',
            'FRQ TS',
            'SQL TS',
            'OBSC TSGR',
            'EMBD TSGR',
        ],
        'thunderstorm',
        'SEV TURB',
        'turbulence-severe',
        ['SEV ICE', 'SEV ICE (FZRA)'],
        'icing-severe',
        'SEV MTW',
        'mountainwave-severe',
        ['HVY DS', 'HVY SS'],
        'sigmet-duststorm',
        'RDOACT CLD',
        'sigmet-radioactivecloud',
        'VA ERUPTION',
        'vac-symbol',
        'VA CLD',
        'vac-symbol', // update to vaa-symbol later once geojson format added
        'TC',
        'sigmet-tropical-cyclone',
        'sigmet-unknown',
    ]

    return (
        <>
            <Source
                id="sigmet"
                geoJsonSource={{
                    type: 'geojson',
                    data: layerInfo.sigmet.dataURL,
                }}
            />
            <Source
                id="sigmet-symbol"
                geoJsonSource={{
                    type: 'geojson',
                    data: labelData,
                }}
            />
            <Layer
                id="sigmet-fill"
                sourceId="sigmet"
                before="aerodromes-symbol"
                type="fill"
                paint={{
                    'fill-color': color,
                    'fill-opacity': 0.1,
                }}
                filter={[
                    'all',
                    ...(dataMode === 'met'
                        ? [
                              ['<=', ['get', 'start_epoch'], now / 1000],
                              ['>=', ['get', 'end_epoch'], now / 1000],
                          ]
                        : []),
                    ...(dataMode === 'advs'
                        ? [
                              [
                                  '<=',
                                  ['get', 'start_epoch'],
                                  selectedTime / 1000,
                              ],
                              ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                          ]
                        : []),
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                ]}
            />
            <Layer
                id="sigmet-fill-pattern"
                sourceId="sigmet"
                before="aerodromes-symbol"
                type="fill"
                paint={{
                    'fill-pattern': 'mtwhash',
                    'fill-opacity': 0.2,
                }}
                filter={[
                    'all',
                    ...(dataMode === 'met'
                        ? [
                              ['<=', ['get', 'start_epoch'], now / 1000],
                              ['>=', ['get', 'end_epoch'], now / 1000],
                          ]
                        : []),
                    ...(dataMode === 'advs'
                        ? [
                              [
                                  '<=',
                                  ['get', 'start_epoch'],
                                  selectedTime / 1000,
                              ],
                              ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                          ]
                        : []),
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                    ['==', ['get', 'phenomenon'], 'SEV MTW'],
                ]}
            />
            <Layer
                id="sigmet-line"
                sourceId="sigmet"
                before="aerodromes-symbol"
                type="line"
                paint={{
                    'line-color': color,
                    'line-width': 2,
                }}
                filter={[
                    'all',
                    ...(dataMode === 'met'
                        ? [
                              ['<=', ['get', 'start_epoch'], now / 1000],
                              ['>=', ['get', 'end_epoch'], now / 1000],
                          ]
                        : []),
                    ...(dataMode === 'advs'
                        ? [
                              [
                                  '<=',
                                  ['get', 'start_epoch'],
                                  selectedTime / 1000,
                              ],
                              ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                          ]
                        : []),
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                ]}
            />
            <Layer
                id="sigmet-symbol"
                sourceId="sigmet-symbol"
                type="symbol"
                layout={{
                    'icon-image': icon,
                    'icon-size': 0.5,
                    'icon-optional': false,
                    'icon-allow-overlap': true,
                }}
                filter={[
                    'all',
                    ...(dataMode === 'met'
                        ? [
                              ['<=', ['get', 'start_epoch'], now / 1000],
                              ['>=', ['get', 'end_epoch'], now / 1000],
                          ]
                        : []),
                    ...(dataMode === 'advs'
                        ? [
                              [
                                  '<=',
                                  ['get', 'start_epoch'],
                                  selectedTime / 1000,
                              ],
                              ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                          ]
                        : []),
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                ]}
            />
            <Layer
                id="sigmet-arrow-symbol"
                sourceId="sigmet-symbol"
                type="symbol"
                layout={{
                    'icon-image': [
                        'case',
                        ['to-boolean', ['get', 'azimuth']],
                        [
                            'match',
                            ['get', 'phenomenon'],
                            [
                                'ISOL TS',
                                'OBSC TS',
                                'EMBD TS',
                                'FRQ TS',
                                'SQL TS',
                                'OBSC TSGR',
                                'EMBD TSGR',
                            ],
                            'sigmet-lightning-arrow',
                            'SEV TURB',
                            'sigmet-turbulence-arrow',
                            ['SEV ICE', 'SEV ICE (FZRA)'],
                            'sigmet-icing-arrow',
                            'SEV MTW',
                            'sigmet-mountainwave-arrow',
                            '',
                        ],
                        '',
                    ],
                    'icon-anchor': 'bottom',
                    'icon-rotate': ['get', 'azimuth'],
                    'icon-size': 0.48,
                    'icon-optional': false,
                    'icon-allow-overlap': true,
                }}
                filter={[
                    'all',
                    ...(dataMode === 'met'
                        ? [
                              ['<=', ['get', 'start_epoch'], now / 1000],
                              ['>=', ['get', 'end_epoch'], now / 1000],
                          ]
                        : []),
                    ...(dataMode === 'advs'
                        ? [
                              [
                                  '<=',
                                  ['get', 'start_epoch'],
                                  selectedTime / 1000,
                              ],
                              ['>=', ['get', 'end_epoch'], selectedTime / 1000],
                          ]
                        : []),
                    ['<=', ['get', 'lowerfilter'], selectedUpperAltitude],
                    ['>=', ['get', 'upperfilter'], selectedLowerAltitude],
                ]}
            />
        </>
    )
}
