import { useContext, useState, useEffect, useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { MapContext } from 'react-mapbox-gl'
import {
    dataModeLayerSourcesState,
    dataModeState,
    layerSelectionState,
    changeLayerStatusState,
    statusData,
    handleStatusChange,
    handleLayerSuccess,
    layerErrorHandler,
    removeLayerErrorHandler,
} from '../../globalState'

export default function SourceStatusHandler() {
    const mapInstance = useContext(MapContext)
    const changeStatusValue = useSetRecoilState(handleStatusChange)
    const setLayerError = useSetRecoilState(layerErrorHandler)
    const removeLayerError = useSetRecoilState(removeLayerErrorHandler)
    const layerSuccess = useSetRecoilState(handleLayerSuccess)
    const dataModeLayerSources = useRecoilValue(dataModeLayerSourcesState)
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)
    const dataStatus = useRecoilValue(statusData)
    const changeLayerStatus = useSetRecoilState(changeLayerStatusState)
    const [sourcesToCheck, setSourcesToCheck] = useState([])

    const sourceChecker = useCallback(
        (id) => {
            return sourcesToCheck.includes(id)
        },
        [sourcesToCheck]
    )

    //This useEffect compiles an array of relevant layers to check the status of. Refer to define-datamode-layer-sources for full list.
    // TODO - Refactor drive this change through changes to dataStatus and selector/constructor
    useEffect(() => {
        if (dataModeLayerSources) {
            const layersToCheck = Object.keys(layerSelection[dataMode]).filter(
                (layer) => layerSelection[dataMode][layer]
            )
            setSourcesToCheck(
                ['aerodromes'].concat(
                    Object.keys(dataModeLayerSources[dataMode])
                        .filter((layer) => layersToCheck.includes(layer)) // only return the layers that we need to check
                        .flatMap(
                            (layer) =>
                                dataModeLayerSources[dataMode][layer].sources
                        ) // get the sources from the layers we need to check
                )
            )
        }
    }, [dataMode, layerSelection, dataModeLayerSources])

    useEffect(() => {
        const handleSourceDataLoading = (event) => {
            if (sourceChecker(event.sourceId) && !event.tile) {
                // console.log('event.sourceId', event.sourceId)
                changeLayerStatus({
                    [`${event.sourceId}Loading`]: true,
                    [`${event.sourceId}Mounted`]: false,
                })
            }
        }

        // TODO Confirm that this error works as intended.
        const handleError = (event) => {
            if (sourceChecker(event.sourceId) && event.error) {
                changeLayerStatus({
                    [`${event.sourceId}Loading`]: false,
                    [`${event.sourceId}Error`]: true,
                    [`${event.sourceId}Mounted`]: false,
                })
                setLayerError({
                    layer: event.sourceId,
                    type: 'fetching',
                })
            }
        }

        const handleSourceData = (event) => {
            if (
                sourceChecker(event.sourceId) &&
                event.isSourceLoaded &&
                !event.sourceCacheId
            ) {
                changeLayerStatus({
                    [`${event.sourceId}Loading`]: false,
                    [`${event.sourceId}Error`]: false,
                })
            }
        }

        // Update changeStatusValue to only set atom value if it has changed. Check if we need updated times on the layer status below also.
        const handleIdle = (event) => {
            sourcesToCheck.map((source) => {
                // This should check if the geojson layer version is the same as that in the status object, if not, this sets the value mapOutdated value to true.

                if (
                    dataStatus &&
                    dataStatus[source.toLowerCase()] &&
                    mapInstance.isSourceLoaded(source) &&
                    mapInstance.getSource(source) &&
                    mapInstance.getSource(source).type === 'geojson' &&
                    mapInstance.querySourceFeatures(source)[0] &&
                    mapInstance.querySourceFeatures(source)[0]['properties'][
                        'version'
                    ] !== dataStatus[source.toLowerCase()]['version']
                ) {
                    console.log(
                        'Mismatched Versions:',
                        mapInstance.querySourceFeatures(source)[0][
                            'properties'
                        ]['version'],
                        dataStatus[source.toLowerCase()]['version'],
                        source
                    )

                    changeStatusValue({
                        layer: source,
                        key: 'mapOutdated',
                        value: true,
                    })
                } else if (
                    dataStatus &&
                    dataStatus[source.toLowerCase()] &&
                    mapInstance.getSource(source) &&
                    mapInstance.getSource(source).type === 'geojson' &&
                    mapInstance.querySourceFeatures(source)[0] &&
                    mapInstance.querySourceFeatures(source)[0]['properties'][
                        'version'
                    ] === dataStatus[source.toLowerCase()]['version']
                ) {
                    layerSuccess({ layer: source })
                }

                // TODO evaluate if we still need layer + Updated key value anywhere
                if (mapInstance.isSourceLoaded(source)) {
                    changeLayerStatus({
                        [`${source}Loading`]: false,
                        [`${source}Mounted`]: true,
                        //[`${source}Error`]: false, // only allow to set if loading has completed or not, not if there was a failure
                    })
                    mapInstance.getSource(source).type !== 'geojson' &&
                        layerSuccess({ layer: source })
                } else {
                    changeLayerStatus({
                        [`${source}Loading`]: false,
                        [`${source}Mounted`]: false,
                        //[`${source}Error`]: false, // only allow to set if loading has completed or not, not if there was a failure
                    })
                }
                return null
            })
        }

        // fires when loading source
        mapInstance.on('sourcedataloading', handleSourceDataLoading)
        // fires if source loading had an error
        mapInstance.on('error', handleError)
        // fires when data loaded (sometimes this is missed - particularly raster sources)
        mapInstance.on('sourcedata', handleSourceData)
        // fires when dataloading is complete (this does pick up the raster sources)
        mapInstance.on('idle', handleIdle)

        // cleanup
        return () => {
            mapInstance.off('sourcedataloading', handleSourceDataLoading)
            mapInstance.off('error', handleError)
            mapInstance.off('sourcedata', handleSourceData)
            mapInstance.off('idle', handleIdle)
        }
    }, [
        sourcesToCheck,
        changeLayerStatus,
        mapInstance,
        sourceChecker,
        dataStatus,
        changeStatusValue,
        layerSuccess,
        setLayerError,
        removeLayerError,
    ])

    return null
}
