import React from 'react'
import WXRasterTiles from './components/WXRasterTiles'

export default function satelliteCloudInfraredEnhanced() {
    return (
        <WXRasterTiles
            layerId={'satelliteCloudInfraredEnhanced'}
            timesURL={
                'https://api.wxtiles.com/v1/wxtiles/layer/jma-himawari-fulldisk-enhancedir/instance/him8-disk_2km_ir/'
            }
            rasterURL={
                'https://api.wxtiles.com/v1/wxtiles/tile/jma-himawari-fulldisk-enhancedir/infrared-dtmp/him8-disk_2km_ir/'
            }
            limit={-9}
        />
    )
}

