import React, { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    LightMode,
} from '@chakra-ui/react'

import { setModalVisibilityState } from '../../../globalState'
import PricingStructure from '../../../pricingStructure'

export default function PricingTiers() {
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const { onClose } = useDisclosure()
    function onCloseHandler() {
        setShow(false)
        onClose()
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant="pricingTiers"
                    size="6xl"
                    isCentered
                    onClose={onCloseHandler}
                    isOpen={true}
                    motionPreset="slideInBottom"
                    scrollBehavior="inside"
                    closeOnOverlayClick={true}
                    trapFocus={false}
                >
                    <ModalOverlay />
                    <ModalContent maxHeight="calc(100% - 3rem)">
                        <ModalCloseButton color="light.100" zIndex="11" />
                        <ModalHeader
                            textAlign="center"
                            background="light.10"
                            color="light.100"
                            fontSize="1rem"
                            minHeight="55px"
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                            fontWeight="600"
                            letterSpacing="0.5px"
                            boxShadow={'0px 3px 15px -1px rgba(0,0,0,0.15)'}
                            borderBottom="1px solid #eaeaea"
                            zIndex="10"
                        >
                            SUBSCRIPTION OPTIONS
                        </ModalHeader>
                        <ModalBody
                            as="section"
                            bg={{ base: '#edf1f7', md: 'gray.50' }}
                            pt={{
                                base: '40px',
                                lg: '100px',
                                // xl: '60px',
                            }}
                            pb={{
                                base: '14',
                                lg: '35px',
                                // xl: '10',
                            }}
                            px={{
                                base: '4',
                                lg: '8',
                            }}
                        >
                            <PricingStructure />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
