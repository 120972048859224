import { config } from '../config'

export default function constructContrastStates() {
    const { collections, layers } = config
    const base = {}
    collections.map((col) =>
        Object.keys(layers).map((l) =>
            layers[l]['collection'].map((layerCol) => {
                if (layerCol.id === col) {
                    if (layers[l]['contrast']) {
                        base[col] = {
                            ...base[col],
                            [l]: layers[l]['contrast']['base'],
                        }
                    }
                }
                return null
            })
        )
    )
    const imagery = {}
    collections.map((col) =>
        Object.keys(layers).map((l) =>
            layers[l]['collection'].map((layerCol) => {
                if (layerCol.id === col) {
                    if (layers[l]['contrast']) {
                        imagery[col] = {
                            ...imagery[col],
                            [l]: layers[l]['contrast']['base'],
                        }
                    }
                }
                return null
            })
        )
    )
    return { base, imagery }
}
