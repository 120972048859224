import { ReactComponent as ThunderstormIcon } from '../../../icons/assets/thunderstorm.svg'
import { ReactComponent as ThunderstormSymbol } from '../../../icons/assets/thunderstorm-symbol.svg'
import { ReactComponent as IceIcon } from '../../../icons/assets/ice.svg'
import { ReactComponent as IcingIcon } from '../../../icons/assets/icing-severe.svg'
import { ReactComponent as TurbulenceIcon } from '../../../icons/assets/turbulence-symbol-severe.svg'
import { ReactComponent as WindIcon } from '../../../icons/assets/wind.svg'
import { ReactComponent as MountainWavesIcon } from '../../../icons/assets/mountain-waves-severe.svg'
import { ReactComponent as MountainWavesSymbol } from '../../../icons/assets/mountain-waves-symbol-severe.svg'
import { ReactComponent as TropicalCycloneIcon } from '../../../icons/assets/tropical-cyclone.svg'
import { ReactComponent as TropicalCycloneSymbol } from '../../../icons/assets/tropical-cyclone-symbol.svg'
import { ReactComponent as VolcanicAshIcon } from '../../../icons/assets/vaa.svg'
import { ReactComponent as VolcanicAshSymbol } from '../../../icons/assets/vac-symbol.svg'

export default function sigmetToastDefinition(feat) {
    // TODO - ALSO NEED TO ACCOMODATE SIGMET TOAST DEFINITIONS
    // ["HVY DS", "HVY SS"] (dust storm, sand storm)
    // "RDOACT CLD" (radioactive cloud)

    if (
        [
            'ISOL TS',
            'OBSC TS',
            'EMBD TS',
            'FRQ TS',
            'SQL TS',
            'OBSC TSGR',
            'EMBD TSGR',
        ].some((phenomenon) => phenomenon === feat.properties.phenomenon)
    ) {
        return {
            id: feat.properties.id,
            severity: 'severe',
            className: 'sigmet-toast',
            icon: <ThunderstormSymbol width="24px" height="24px" />,
            watermark: (
                <ThunderstormIcon
                    width="55px"
                    height="55px"
                    className="watermark"
                />
            ),
            colour: '#8000FF',
            title: feat.properties.phenomenon,
            description: '',
            altitudes:
                feat.properties.lowerlabel && feat.properties.upperlabel
                    ? `${
                          feat.properties.lowerlabel.substring(0, 2) === 'FL' ||
                          feat.properties.lowerlabel === 'SFC'
                              ? feat.properties.lowerlabel
                              : feat.properties.lowerlabel + ' ft'
                      }` -
                      `${
                          feat.properties.upperlabel.substring(0, 2) === 'FL'
                              ? feat.properties.upperlabel
                              : feat.properties.upperlabel + ' ft'
                      }`
                    : '',
            level: feat.properties.level
                ? `${'Height: ' + feat.properties.level}`
                : '',
            movement: feat.properties.movement
                ? `${'Movement: ' + feat.properties.movement}`
                : '',
            intensity: feat.properties.intensity
                ? `${'Intensity: ' + feat.properties.intensity}`
                : '',
        }
    }

    if (
        ['SEV ICE', 'SEV ICE (FZRA)'].some(
            (phenomenon) => phenomenon === feat.properties.phenomenon
        )
    ) {
        return {
            id: feat.properties.id,
            severity: 'severe',
            className: 'sigmet-toast',
            icon: <IcingIcon width="24px" height="24px" />,
            watermark: (
                <IceIcon width="55px" height="55px" className="watermark" />
            ),
            colour: '#40b1e8',
            title: feat.properties.phenomenon,
            description: '',
            altitudes:
                feat.properties.lowerlabel && feat.properties.upperlabel
                    ? `${
                          feat.properties.lowerlabel.substring(0, 2) === 'FL' ||
                          feat.properties.lowerlabel === 'SFC'
                              ? feat.properties.lowerlabel
                              : feat.properties.lowerlabel + ' ft'
                      } – ${
                          feat.properties.upperlabel.substring(0, 2) === 'FL'
                              ? feat.properties.upperlabel
                              : feat.properties.upperlabel + ' ft'
                      }`
                    : '',
            level: feat.properties.level
                ? `${'Height: ' + feat.properties.level}`
                : '',
            movement: feat.properties.movement
                ? `${'Movement: ' + feat.properties.movement}`
                : '',
            intensity: feat.properties.intensity
                ? `${'Intensity: ' + feat.properties.intensity}`
                : '',
        }
    }

    if (feat.properties.phenomenon === 'SEV TURB') {
        return {
            id: feat.properties.id,
            severity: 'severe',
            className: 'sigmet-toast',
            icon: <TurbulenceIcon width="24px" height="24px" />,
            watermark: (
                <WindIcon width="55px" height="55px" className="watermark" />
            ),
            colour: '#F21010',
            title: feat.properties.phenomenon,
            description: '',
            altitudes:
                feat.properties.lowerlabel && feat.properties.upperlabel
                    ? `${
                          feat.properties.lowerlabel.substring(0, 2) === 'FL' ||
                          feat.properties.lowerlabel === 'SFC'
                              ? feat.properties.lowerlabel
                              : feat.properties.lowerlabel + ' ft'
                      } – ${
                          feat.properties.upperlabel.substring(0, 2) === 'FL'
                              ? feat.properties.upperlabel
                              : feat.properties.upperlabel + ' ft'
                      }`
                    : '',
            level: feat.properties.level
                ? `${'Height: ' + feat.properties.level}`
                : '',
            movement: feat.properties.movement
                ? `${'Movement: ' + feat.properties.movement}`
                : '',
            intensity: feat.properties.intensity
                ? `${'Intensity: ' + feat.properties.intensity}`
                : '',
        }
    }

    if (feat.properties.phenomenon === 'SEV MTW') {
        return {
            id: feat.properties.id,
            severity: 'severe',
            className: 'sigmet-toast',
            icon: <MountainWavesSymbol width="24px" height="24px" />,
            watermark: (
                <MountainWavesIcon
                    width="55px"
                    height="55px"
                    className="watermark"
                />
            ),
            colour: '#158cdb',
            title: feat.properties.phenomenon,
            description: '',
            altitudes:
                feat.properties.lowerlabel && feat.properties.upperlabel
                    ? `${
                          feat.properties.lowerlabel.substring(0, 2) === 'FL' ||
                          feat.properties.lowerlabel === 'SFC'
                              ? feat.properties.lowerlabel
                              : feat.properties.lowerlabel + ' ft'
                      } – ${
                          feat.properties.upperlabel.substring(0, 2) === 'FL'
                              ? feat.properties.upperlabel
                              : feat.properties.upperlabel + ' ft'
                      }`
                    : '',
            level: feat.properties.level
                ? `${'Height: ' + feat.properties.level}`
                : '',
            movement: feat.properties.movement
                ? `${'Movement: ' + feat.properties.movement}`
                : '',
            intensity: feat.properties.intensity
                ? `${'Intensity: ' + feat.properties.intensity}`
                : '',
        }
    }

    if (feat.properties.phenomenon === 'TC') {
        return {
            id: feat.properties.id,
            severity: 'severe',
            className: 'sigmet-toast',
            icon: <TropicalCycloneSymbol width="24px" height="24px" />,
            watermark: (
                <TropicalCycloneIcon
                    width="55px"
                    height="55px"
                    className="watermark"
                />
            ),
            colour: '#1261A0',
            title: feat.properties.phenomenon,
            description: '',
            altitudes:
                feat.properties.lowerlabel && feat.properties.upperlabel
                    ? `${
                          feat.properties.lowerlabel.substring(0, 2) === 'FL' ||
                          feat.properties.lowerlabel === 'SFC'
                              ? feat.properties.lowerlabel
                              : feat.properties.lowerlabel + ' ft'
                      } – ${
                          feat.properties.upperlabel.substring(0, 2) === 'FL'
                              ? feat.properties.upperlabel
                              : feat.properties.upperlabel + ' ft'
                      }`
                    : '',
            level: feat.properties.level
                ? `${'Height: ' + feat.properties.level}`
                : '',
            movement: feat.properties.movement
                ? `${'Movement: ' + feat.properties.movement}`
                : '',
            intensity: feat.properties.intensity
                ? `${'Intensity: ' + feat.properties.intensity}`
                : '',
        }
    }

    if (
        ['VA CLD', 'VA ERUPTION'].some(
            (phenomenon) => phenomenon === feat.properties.phenomenon
        )
    ) {
        return {
            id: feat.properties.id,
            severity: 'severe',
            className: 'sigmet-toast',
            icon: <VolcanicAshSymbol width="24px" height="24px" />,
            watermark: (
                <VolcanicAshIcon
                    width="55px"
                    height="55px"
                    className="watermark"
                />
            ),
            colour: '#FF00FF',
            title: feat.properties.phenomenon,
            description: feat.properties.alertlevel
                ? feat.properties.alertlevel
                : '',
            altitudes:
                feat.properties.lowerlabel && feat.properties.upperlabel
                    ? `${
                          feat.properties.lowerlabel.substring(0, 2) === 'FL' ||
                          feat.properties.lowerlabel === 'SFC'
                              ? feat.properties.lowerlabel
                              : feat.properties.lowerlabel + ' ft'
                      } – ${
                          feat.properties.upperlabel.substring(0, 2) === 'FL'
                              ? feat.properties.upperlabel
                              : feat.properties.upperlabel + ' ft'
                      }`
                    : '',
            level: feat.properties.level
                ? `${'Height: ' + feat.properties.level}`
                : '',
            movement: feat.properties.movement
                ? `${'Movement: ' + feat.properties.movement}`
                : '',
            intensity: feat.properties.intensity
                ? `${'Intensity: ' + feat.properties.intensity}`
                : '',
        }
    }

    return null
}
