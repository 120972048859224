import React, { useCallback, useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { TiTick, TiTimes } from 'react-icons/ti'
import { FaQuestion } from 'react-icons/fa'
import {
    formatDistanceToNow,
    formatDistanceToNowStrict,
    parseISO,
    addSeconds,
} from 'date-fns'

import formatDateTime from '../../../util/dateFormatter'
import {
    Modal,
    Flex,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Text,
    LightMode,
} from '@chakra-ui/react'

import {
    globalTimeState,
    setModalVisibilityState,
    statusRefreshTimeStampState,
    timeZoneState,
    statusData,
} from '../../../globalState'

export default function DataStatusModal() {
    const { onClose } = useDisclosure()

    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'dataStatus', value })
        },
        [setModalVisibility]
    )

    const timeZone = useRecoilValue(timeZoneState)
    function onCloseHandler() {
        setShow(false)
        onClose()
    }

    const data = useRecoilValue(statusData)
    const statusRefreshTimeStamp = useRecoilValue(statusRefreshTimeStampState)
    const [countdown, setCountdown] = useState(null)
    const time = useRecoilValue(globalTimeState)

    const [status, setStatus] = useState([])

    useEffect(() => {
        const expectRefreshTime = addSeconds(statusRefreshTimeStamp, 30)

        setCountdown(
            formatDistanceToNowStrict(expectRefreshTime, {
                addSuffix: true,
                unit: 'second',
            })
        )
    }, [statusRefreshTimeStamp, setCountdown, time])

    useEffect(() => {
        // Add an additional .filter to ensure that the value is a valid layer.
        const dataArray = Object.values(data).filter((i) => i.id)
        setStatus(dataArray)
    }, [data, setStatus])

    if (status && status.length) {
        return (
            <>
                <LightMode>
                    <Modal
                        variant="dataStatus"
                        size="6xl"
                        isCentered
                        onClose={onCloseHandler}
                        isOpen={true}
                        motionPreset="slideInBottom"
                        scrollBehavior="inside"
                        closeOnOverlayClick={true}
                        trapFocus={false}
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalCloseButton color="white" />
                            <ModalHeader
                                textAlign="center"
                                backgroundColor="dark.10"
                                color="white"
                                letterSpacing="0.5px"
                            >
                                PreFlight Data Status
                            </ModalHeader>
                            <ModalBody style={{ padding: 0 }}>
                                <Box>
                                    <Box w="100%" p="30px" pt="25px">
                                        <Box minHeight="532px">
                                            <Text
                                                w="100%"
                                                textAlign="right"
                                                fontSize="0.8rem"
                                                fontWeight="600"
                                                color="#666666"
                                                pb="15px"
                                            >
                                                {`Status refresh ${countdown}`}
                                            </Text>

                                            <Table
                                                size="sm"
                                                variant="striped"
                                                colorScheme="gray"
                                                fontFamily="Open Sans"
                                                marginTop="2"
                                            >
                                                <Thead>
                                                    <Tr
                                                        height="30px"
                                                        fontWeight="600"
                                                        fontFamily="Open Sans"
                                                        borderBottom="1.5px solid #a6b4c1"
                                                    >
                                                        <Th>Source</Th>
                                                        <Th>Type</Th>
                                                        <Th>Status</Th>
                                                        <Th>Count</Th>
                                                        <Th>Schedule</Th>
                                                        <Th>Oldest Updated</Th>
                                                        <Th>Newest Updated</Th>
                                                        <Th>Oldest Time</Th>
                                                        <Th>Newest Time</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {status &&
                                                        status.map((row) => {
                                                            const rowStatus =
                                                                row.count ===
                                                                    0 ||
                                                                row.oldestupdated ===
                                                                    null
                                                                    ? {
                                                                          status: 'unknown',
                                                                          color: '#FFA50050 !important',
                                                                          icon: (
                                                                              <FaQuestion color="orange" />
                                                                          ),
                                                                      }
                                                                    : row.outdated
                                                                    ? {
                                                                          status: 'outdated',
                                                                          color: '#FF000050 !important',
                                                                          icon: (
                                                                              <TiTimes color="red" />
                                                                          ),
                                                                      }
                                                                    : {
                                                                          status: 'ok',
                                                                          color: '',
                                                                          icon: (
                                                                              <TiTick
                                                                                  color="green"
                                                                                  fontSize="1rem"
                                                                              />
                                                                          ),
                                                                      }

                                                            return (
                                                                <Tr
                                                                    key={
                                                                        row.type
                                                                    }
                                                                >
                                                                    <Td
                                                                        fontWeight="600"
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {
                                                                            row.source
                                                                        }
                                                                    </Td>
                                                                    <Td
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {
                                                                            row.type
                                                                        }
                                                                    </Td>
                                                                    <Td
                                                                        paddingLeft="22px"
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {
                                                                            rowStatus.icon
                                                                        }
                                                                    </Td>
                                                                    <Td
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {
                                                                            row.count
                                                                        }
                                                                    </Td>
                                                                    <Td
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {
                                                                            row
                                                                                .schedule
                                                                                .minutes
                                                                        }{' '}
                                                                        mins
                                                                    </Td>
                                                                    <Td
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {row.oldestupdated &&
                                                                            formatDistanceToNow(
                                                                                parseISO(
                                                                                    row.oldestupdated
                                                                                ),
                                                                                {
                                                                                    addSuffix: true,
                                                                                }
                                                                            ).replace(
                                                                                'less than a',
                                                                                '< 1'
                                                                            )}
                                                                    </Td>
                                                                    <Td
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {row.newestupdated &&
                                                                            formatDistanceToNow(
                                                                                parseISO(
                                                                                    row.newestupdated
                                                                                ),
                                                                                {
                                                                                    addSuffix: true,
                                                                                }
                                                                            ).replace(
                                                                                'less than a',
                                                                                '< 1'
                                                                            )}
                                                                    </Td>
                                                                    <Td
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {row?.start &&
                                                                            formatDateTime(
                                                                                row.start,
                                                                                timeZone
                                                                            )}
                                                                    </Td>
                                                                    <Td
                                                                        backgroundColor={
                                                                            rowStatus.color
                                                                        }
                                                                    >
                                                                        {row?.end &&
                                                                            formatDateTime(
                                                                                row.end,
                                                                                timeZone
                                                                            )}
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                </Tbody>
                                            </Table>
                                        </Box>
                                    </Box>
                                </Box>
                            </ModalBody>

                            <ModalFooter
                                pt={3.5}
                                pb={4}
                                borderTop="1px solid #eaeaea"
                            >
                                <Flex
                                    width="100%"
                                    maxWidth="160px"
                                    borderRadius="10px"
                                    border="1px solid"
                                    borderColor=" #e5e5e5"
                                    overflow="hidden"
                                    mx={{ base: 'auto', md: '25px' }}
                                >
                                    <Button
                                        w="100%"
                                        size="sm"
                                        variant="primary"
                                        color="dark.30"
                                        backgroundColor="light.40"
                                        _hover={{
                                            backgroundColor: 'light.30',
                                        }}
                                        _active={{
                                            backgroundColor: 'light.30',
                                        }}
                                        borderRadius={5}
                                        onClick={onCloseHandler}
                                    >
                                        Close
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </LightMode>
            </>
        )
    }

    return null
}
