import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WaypointIcaoIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 6}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
            {...props}
        >
            <path
                d="M12.25 5L20.2607 18.5H4.23927L12.25 5Z"
                fill="currentColor"
            />
        </Icon>
    )
}
