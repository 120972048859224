import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer, Popup, MapContext } from 'react-mapbox-gl'
import {
    layerSelectionState,
    VectorLayerRefreshInfo,
    dataModeState,
} from '../../../globalState'
import { Text, Flex, Center } from '@chakra-ui/react'
import './assets/met-popup.css'
import { ReactComponent as WindsockIcon } from './assets/windsock-white.svg'
import { ReactComponent as VisibilityIcon } from './assets/visibility.svg'
import { ReactComponent as QnhIcon } from './assets/qnh-white.svg'

export default function AWSLayer() {
    const layerSelection = useRecoilValue(layerSelectionState)
    const layerData = useRecoilValue(VectorLayerRefreshInfo)
    const dataMode = useRecoilValue(dataModeState)
    const mapInstance = useContext(MapContext)

    const [hoverInfo, setHoverInfo] = useState(null)

    const onHover = (e) => {
        const newE = e
        e.features = e.features.map((feat) => {
            var newFeat = feat
            newFeat.properties.windvar = feat.properties.windvar
                ? JSON.parse(feat.properties.windvar)
                : null

            return newFeat
        })
        // console.log(newE)
        setHoverInfo(newE)
    }

    const offHover = useCallback((e) => {
        setHoverInfo(null)
    }, [])

    const feat =
        hoverInfo &&
        hoverInfo.features &&
        hoverInfo.features[0] &&
        hoverInfo.features[0].properties &&
        hoverInfo.features[0].properties.data &&
        JSON.parse(hoverInfo.features[0].properties.data)

    // AWS Data
    const awsData = feat ? feat : false

    // Weather
    const winddir =
        awsData &&
        (awsData.winddir_10mnavg // DEGREES TRUE
            ? awsData.winddir_10mnavg // 10 min average
            : awsData.winddir_01mnavg
            ? awsData.winddir_01mnavg // else 1 min average
            : awsData.winddir_01hravg
            ? awsData.winddir_01hravg // else 1 hour average
            : null)

    const winddirmax =
        awsData &&
        (awsData.windcw__10mnmax // DEGREES TRUE
            ? awsData.windcw__10mnmax // 10 min average
            : awsData.windcw__01mnmax
            ? awsData.windcw__01mnmax // else 1 min average
            : awsData.windcw__01hrmax
            ? awsData.windcw__01hrmax // else 1 hour average
            : null)

    const winddirmin =
        awsData &&
        (awsData.windccw_10mnmax // DEGREES TRUE
            ? awsData.windccw_10mnmax // 10 min average
            : awsData.windccw_01mnmax
            ? awsData.windccw_01mnmax // else 1 min average
            : awsData.windccw_01hrmax
            ? awsData.windccw_01hrmax // else 1 hour average
            : null)

    const windspeed =
        awsData &&
        (awsData.windspd_10mnavg
            ? awsData.windspd_10mnavg // 10 min average
            : awsData.windspd_01mnavg
            ? awsData.windspd_01mnavg // else 1 min average
            : awsData.windspd_01hravg
            ? awsData.windspd_01hravg // else 1 hour average
            : null)

    const windgust =
        awsData &&
        (awsData && awsData.windgst_10mnmax
            ? awsData.windgst_10mnmax // 10 min average
            : awsData.windgst_01mnmax
            ? awsData.windgst_01mnmax // else 1 min average
            : awsData.windgst_01hrmax
            ? awsData.windgst_01hrmax // else 1 hour average
            : null)

    const visibilityData =
        awsData &&
        (awsData.visibil_10mnavg
            ? awsData.visibil_10mnavg // 10 min average
            : null)
    const visibility = visibilityData && Number(visibilityData.slice(0, -3))

    const qnh =
        awsData &&
        (awsData.presqnh_01mnavg
            ? Math.round(awsData.presqnh_01mnavg) // 1 min average
            : null)

    // used to calculate rotation for WDI icon when map rotates
    const [mapTrueBearing, setMapTrueBearing] = useState(0)
    useEffect(() => {
        const handleMove = (e) => {
            setMapTrueBearing(e.target.getBearing())
        }
        mapInstance.on('move', handleMove)
        return () => mapInstance.off('move', handleMove)
    }, [mapInstance])

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.1,
        2,
        0.1,
        3,
        0.2,
        4,
        0.3,
        5,
        0.4,
        6,
        0.45,
        7,
        0.5,
        8,
        0.55,
        9,
        0.6,
        10,
        0.65,
        11,
        0.7,
        12,
        0.75,
        13,
        0.75,
        14,
        0.8,
        15,
        0.8,
    ]

    const zoomLabelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        2,
        0,
        3,
        7,
        4,
        9,
        6,
        10,
        7,
        10,
        8,
        10,
        10,
        11,
        11,
        12,
    ]

    const zoomOffsetDefinition = {
        stops: [
            [4, [0, 1.3]],
            [5, [0, 1.3]],
            [6, [0, 1.4]],
            [8, [0, 1.4]],
            [9, [0, 1.6]],
            [11, [0, 1.5]],
            [12, [0, 1.6]],
            [13, [0, 1.7]],
            [14, [0, 1.8]],
        ],
    }

    const zoomLabelOpacity = [
        'interpolate',
        ['linear'],
        ['zoom'],
        4,
        0,
        4.5,
        0.5,
        5,
        1,
        6,
        1,
    ]

    // console.log(hoverInfo, 'hoverInfo')
    // console.log(feat, 'feat')
    // console.log(mapBearing, 'mapBearing')

    return (
        <>
            <GeoJSONLayer
                id="aws"
                // before="aerodromes-symbol"
                data={layerData.aws.dataURL}
                symbolOnMouseEnter={dataMode !== 'brief' ? onHover : null}
                symbolOnMouseLeave={dataMode !== 'brief' ? offHover : null}
                symbolLayout={{
                    visibility: layerSelection.met.aws ? 'visible' : 'none',
                    'symbol-sort-key': ['to-number', ['get', 'order']],
                    'symbol-z-order': 'source',
                    'icon-image': 'aws',
                    'icon-size': zoomLevelDefinition,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': false,
                    'icon-optional': false,
                    'text-field': [
                        'case',
                        ['!=', ['get', 'designator'], null],
                        ['get', 'designator'],
                        ['get', 'name'],
                    ],
                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-offset': zoomOffsetDefinition,
                    'text-anchor': 'top',
                    'text-size': zoomLabelDefinition,
                    'text-letter-spacing': 0.025,
                    'text-allow-overlap': true,
                    'text-ignore-placement': true,
                }}
                symbolPaint={{
                    'text-halo-color': '#fff',
                    'text-halo-width': 1,
                    'text-color': '#10576d',
                    'text-opacity': zoomLabelOpacity,
                }}
            />
            {hoverInfo &&
                hoverInfo.features &&
                hoverInfo.features.length > 0 && (
                    <Popup
                        coordinates={[
                            hoverInfo.lngLat.lng,
                            hoverInfo.lngLat.lat,
                        ]}
                        className={'aws-popup '}
                    >
                        <div className="popup-header">
                            <h1>
                                {hoverInfo.features[0].properties.designator}
                            </h1>
                            <h2>{hoverInfo.features[0].properties.name}</h2>
                            <h3>Automatic Weather Station</h3>
                        </div>
                        <div
                            className="popup-divider"
                            style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                                borderBottom: '1px solid rgba(255,255,255,0.2)',
                            }}
                        ></div>
                        {awsData && (
                            <div
                                className="popup-body"
                                style={{ paddingLeft: '5px' }}
                            >
                                {winddir && (
                                    <Flex
                                        justifyContent="center"
                                        flexDirection="column"
                                        marginTop="4px"
                                        marginBottom="2px"
                                        marginLeft="-5px"
                                    >
                                        <Center>
                                            <WindsockIcon
                                                style={{
                                                    height: '22px',
                                                    width: '22px',
                                                    transform: `rotate(${
                                                        winddir +
                                                        90 -
                                                        mapTrueBearing
                                                    }deg)`,
                                                }}
                                            />
                                            <Flex flexDirection="column">
                                                <Text
                                                    paddingLeft="7px"
                                                    lineHeight="1.4"
                                                >
                                                    {`${winddir}°T, `}
                                                    {`${windspeed} kt`}
                                                </Text>
                                                {windgust && (
                                                    <Text
                                                        paddingLeft="7px"
                                                        lineHeight="1.4"
                                                    >
                                                        {`Gusts ${windgust} kt`}
                                                    </Text>
                                                )}
                                                {winddirmin && winddirmax && (
                                                    <Text
                                                        paddingLeft="7px"
                                                        lineHeight="1.4"
                                                    >
                                                        {' ' +
                                                            winddirmin
                                                                .toString()
                                                                .padStart(
                                                                    3,
                                                                    '0'
                                                                ) +
                                                            '°' +
                                                            ' V ' +
                                                            winddirmax
                                                                .toString()
                                                                .padStart(
                                                                    3,
                                                                    '0'
                                                                ) +
                                                            '°T'}
                                                    </Text>
                                                )}
                                            </Flex>
                                        </Center>
                                    </Flex>
                                )}

                                {visibility && (
                                    <Center py="2px">
                                        <VisibilityIcon
                                            style={{
                                                height: '22px',
                                                width: '22px',
                                            }}
                                        />
                                        <Text
                                            paddingLeft="7px"
                                            lineHeight="1.2"
                                        >
                                            {visibility === '//' ||
                                            visibility === '////' ? (
                                                <Text textTransform="initial">
                                                    Not reported
                                                </Text>
                                            ) : visibility === '9999' ? (
                                                '> ' + visibility + ' m'
                                            ) : (
                                                visibility + ' km'
                                            )}
                                        </Text>
                                    </Center>
                                )}

                                {qnh && (
                                    <Center pt={visibility ? '0px' : '3px'}>
                                        <QnhIcon
                                            style={{
                                                height: '22px',
                                                width: '22px',
                                            }}
                                        />
                                        <Text
                                            paddingLeft="7px"
                                            lineHeight="1.2"
                                        >
                                            {Math.round(qnh)} hPa
                                        </Text>
                                    </Center>
                                )}
                            </div>
                        )}
                        {!awsData && (
                            <div className="popup-body">
                                <Text
                                    px="5px"
                                    pt="5px"
                                    pb="2px"
                                    fontWeight="400"
                                    lineHeight="1.2"
                                >
                                    No weather information available
                                </Text>
                            </div>
                        )}
                    </Popup>
                )}
        </>
    )
}
