import React from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Text, Flex, Icon, Spinner } from '@chakra-ui/react'
import { parseISO, formatISO } from 'date-fns'
import { isDesktop } from 'react-device-detect'
import FullWidthError from '../../map/modals/components/FullWidthError'
import { ReactComponent as turbulence } from '../../icons/assets/turbulence-symbol-moderate.svg'
import { ReactComponent as wind } from '../../icons/assets/wind.svg'
import { ReactComponent as mountainWaves } from '../../icons/assets/mountain-waves-moderate.svg'
import { ReactComponent as mountainWavesSymbol } from '../../icons/assets/mountain-waves-symbol-moderate.svg'
import { ReactComponent as icing } from '../../icons/assets/icing-moderate.svg'
import { ReactComponent as ice } from '../../icons/assets/ice.svg'
import { ReactComponent as cumulonimbus } from '../../icons/assets/cumulonimbus.svg'
import { ReactComponent as cumulonimbusSymbol } from '../../icons/assets/cumulonimbus-symbol.svg'
import { ReactComponent as vaa } from '../../icons/assets/vaa-symbol.svg'
import { ReactComponent as vac } from '../../icons/assets/vac-symbol.svg'
import { ReactComponent as volcano } from '../../icons/assets/vaa.svg'
import { ReactComponent as radiationSymbol } from '../../icons/assets/radiation-symbol.svg'

import { selectedTimeState, clickedFeaturesState } from '../../globalState'

export default function SigwxList(props) {
    const {
        data,
        dataLoading,
        dataLoadingError,
        lowerAltitude,
        upperAltitude,
        selectedList,
        status,
    } = props
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const selectedTime = useRecoilValue(selectedTimeState)

    const frequencyFormatter = (freq) => {
        const lookup = {
            isolatedEmbedded: 'Isolated Embedded',
            occasionalEmbedded: 'Occasional Embedded',
            occasional: 'Occasional',
        }
        if (lookup[freq]) {
            return lookup[freq]
        }
        return freq
    }

    const metaData = data && data?.metadata
    const sigwxValidTo =
        metaData?.validToTime && formatISO(parseISO(metaData.validToTime))
    const formattedSelectedTime = selectedTime && formatISO(selectedTime)
    const selectedTimePastValidTo = formattedSelectedTime > sigwxValidTo

    // useEffect(() => {
    //     console.log('SIGWX:', data)
    // }, [data])

    if (
        !dataLoading &&
        !dataLoadingError &&
        data &&
        data?.features &&
        data?.features.length
    ) {
        return (
            <>
                {[...data.features]
                    .sort((a, b) => {
                        const sigwxCenterA = JSON.parse(a.properties.center)[1]
                        const sigwxCenterB = JSON.parse(b.properties.center)[1]
                        if (sigwxCenterA < sigwxCenterB) {
                            return 1
                        }
                        if (sigwxCenterA > sigwxCenterB) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                    .map((feat, i) => {
                        const hasVAEruptionSigmet = Boolean(
                            feat?.properties?.alerts &&
                                JSON.parse(feat?.properties?.alerts).length &&
                                JSON.parse(feat?.properties?.alerts).some(
                                    (value) =>
                                        value.startsWith('VA SIGMET in force')
                                )
                        )
                        const SIGWXConfig = {
                            clearAirTurbulence: {
                                title: 'MODERATE CAT',
                                icon: turbulence,
                                watermark: wind,
                                colour: '#ffcc00',
                            },
                            turbulence: {
                                title: 'MODERATE TURBULENCE',
                                icon: turbulence,
                                watermark: wind,
                                colour: '#e76f6f',
                            },
                            icing: {
                                title: 'MODERATE ICING',
                                icon: icing,
                                watermark: ice,
                                colour: '#58ccf1',
                            },
                            mountainWaves: {
                                title: 'MOUNTAIN WAVES',
                                icon: mountainWavesSymbol,
                                watermark: mountainWaves,
                                colour: '#152238',
                            },
                            cumulonimbus: {
                                title:
                                    (feat.properties.frequency
                                        ? `${frequencyFormatter(
                                              feat.properties.frequency
                                          )} `
                                        : '') + 'Cumulonimbus',
                                icon: cumulonimbusSymbol,
                                watermark: cumulonimbus,
                                colour: '#008800',
                            },
                            volcano: {
                                title: `VOLCANIC ALERT ${
                                    feat?.properties?.alertlevel
                                        ? 'LEVEL ' + feat.properties.alertlevel
                                        : ''
                                }`,
                                icon: hasVAEruptionSigmet ? vac : vaa,
                                watermark: volcano,
                                colour: '#eb6aeb',
                                alertlevel: feat?.properties?.alertlevel
                                    ? feat.properties.alertlevel
                                    : '',
                            },
                            radiation: {
                                title: 'RADIATION',
                                icon: radiationSymbol,
                                watermark: radiationSymbol,
                                colour: '#9fd923',
                                radiationcomment: null, // To be updated if new data field added
                                alertlevel: feat?.properties?.alertlevel
                                    ? feat.properties.alertlevel
                                    : '',
                            },
                        }
                        const config = SIGWXConfig[feat?.properties?.type]

                        const outsideSelectedAltitudeRange =
                            lowerAltitude > feat?.properties?.upperfilter ||
                            upperAltitude < feat?.properties?.lowerfilter

                        const openModal =
                            feat.properties.id ===
                            clickedFeatures[0]?.properties?.id

                        return (
                            <Flex
                                key={`${feat.properties.id}-${i}`}
                                display={
                                    selectedList === 'sigwx' ? 'flex' : 'none'
                                }
                                bg={
                                    openModal
                                        ? 'white'
                                        : 'rgba(255,255,255,0.75)'
                                }
                                boxShadow={
                                    openModal
                                        ? '0px 8px 25px -7px rgba(0,15,35,0.35)'
                                        : outsideSelectedAltitudeRange
                                        ? '0px 7px 25px -7px rgba(0,15,35,0.1)'
                                        : '0px 7px 25px -7px rgba(0,15,35,0.2)'
                                }
                                opacity={
                                    (clickedFeatures[0]?.properties?.id &&
                                        !openModal) ||
                                    outsideSelectedAltitudeRange
                                        ? '0.5' //  if selected altitude range is outside sigwx levels
                                        : '1'
                                }
                                pointerEvents={
                                    outsideSelectedAltitudeRange
                                        ? 'none' //  if selected altitude range is outside sigwx levels
                                        : 'auto'
                                }
                                transform={openModal && 'scale(1.03)'}
                                _hover={
                                    isDesktop &&
                                    !outsideSelectedAltitudeRange && {
                                        opacity: '1',
                                        cursor: 'pointer',
                                        background: 'rgba(255,255,255,1)',
                                        boxShadow:
                                            '0px 12px 30px -12px rgba(0,15,35,0.7)',
                                        transform:
                                            'translateY(-1px) translateX(0px) scale(1.01)',
                                    }
                                }
                                onClick={() => {
                                    if (!outsideSelectedAltitudeRange) {
                                        setClickedFeatures([feat])
                                    }
                                }}
                                borderRadius="20px"
                                pl="6px"
                                pr="9px"
                                transition="all ease 200ms"
                                mb={{
                                    base: '10px',
                                    md:
                                        isDesktop &&
                                        i + 1 === data.features.length
                                            ? '3px'
                                            : '10px',
                                }}
                                flexDirection="column"
                                width="100%"
                                zIndex={`calc(1000 - ${i})`}
                            >
                                <Flex
                                    my="3px"
                                    width="100%"
                                    flexDirection="row"
                                    minHeight="36px"
                                >
                                    <Flex alignItems="center">
                                        {config && (
                                            <Icon
                                                bg={
                                                    !outsideSelectedAltitudeRange
                                                        ? config.colour
                                                        : 'gray.300'
                                                }
                                                color="white"
                                                borderRadius="100%"
                                                px="2px"
                                                pb={
                                                    feat?.properties?.type ===
                                                        'volcano' &&
                                                    hasVAEruptionSigmet
                                                        ? '1px'
                                                        : '0px'
                                                }
                                                boxSize="30px"
                                                alignItems="center"
                                                justifyContent="center"
                                                as={config.icon}
                                            />
                                        )}
                                        {feat?.properties?.type === 'volcano' &&
                                            feat?.properties?.alertlevel && ( //  *** ALERT LEVEL TEXT FOR VAA ***
                                                <Flex
                                                    position="absolute"
                                                    width="32px"
                                                    justifyContent="center"
                                                    color={
                                                        !outsideSelectedAltitudeRange
                                                            ? 'white'
                                                            : 'light.100'
                                                    }
                                                >
                                                    {config && (
                                                        <Text
                                                            mt={
                                                                hasVAEruptionSigmet
                                                                    ? '5px'
                                                                    : '0px'
                                                            }
                                                            pb={
                                                                hasVAEruptionSigmet
                                                                    ? '0px'
                                                                    : '1px'
                                                            }
                                                            lineHeight="1.4"
                                                            fontSize="0.6rem"
                                                            fontWeight="600"
                                                            letterSpacing="-1px"
                                                        >
                                                            {config.alertlevel}
                                                        </Text>
                                                    )}
                                                </Flex>
                                            )}
                                    </Flex>
                                    <Flex
                                        pl="7px"
                                        lineHeight="1.5"
                                        width="100%"
                                        py="1px"
                                        flexDirection="column"
                                        color="light.100"
                                        alignItems="center"
                                    >
                                        <Flex
                                            width="100%"
                                            height="100%"
                                            flexDirection="row"
                                            alignItems="flex-start"
                                            justifyContent="space-between"
                                            // borderBottom="1px solid rgba(0,0,0,0.05)"
                                        >
                                            {config && (
                                                <Text
                                                    whiteSpace="pre-line"
                                                    fontSize="0.75rem"
                                                    fontWeight="700"
                                                    lineHeight="1.3"
                                                    pt="1px"
                                                    textTransform="uppercase"
                                                >
                                                    {config.title}
                                                </Text>
                                            )}
                                        </Flex>

                                        <Flex
                                            height="100%"
                                            width="100%"
                                            fontSize="0.7rem"
                                            fontWeight="400"
                                            minHeight="15px"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            color="gray.500"
                                        >
                                            <Flex flexDirection="row">
                                                {feat.properties.type ===
                                                    'volcano' &&
                                                feat.properties.volcano ? (
                                                    <Text whiteSpace="pre-line">
                                                        {
                                                            feat.properties
                                                                .volcano
                                                        }
                                                    </Text>
                                                ) : feat.properties.type ===
                                                  'radiation' ? (
                                                    <Text whiteSpace="pre-line">
                                                        {
                                                            config.radiationcomment
                                                        }
                                                    </Text>
                                                ) : feat.properties.level ? (
                                                    <Text whiteSpace="pre-line">
                                                        {feat.properties.level}
                                                    </Text>
                                                ) : (
                                                    feat.properties
                                                        .lowerlabel &&
                                                    feat.properties
                                                        .upperlabel && (
                                                        <Flex
                                                            flexDirection="row"
                                                            fontWeight={
                                                                outsideSelectedAltitudeRange
                                                                    ? '600'
                                                                    : '400'
                                                            }
                                                            color={
                                                                outsideSelectedAltitudeRange
                                                                    ? '#c44d4d'
                                                                    : 'gray.500'
                                                            }
                                                        >
                                                            {outsideSelectedAltitudeRange ? (
                                                                <Text>
                                                                    Outside
                                                                    altitude
                                                                    filter range
                                                                </Text>
                                                            ) : (
                                                                <>
                                                                    <Text whiteSpace="pre-line">
                                                                        {feat
                                                                            .properties
                                                                            .lowerlabel &&
                                                                            `${
                                                                                feat
                                                                                    .properties
                                                                                    .lowerlabel ===
                                                                                'XXX'
                                                                                    ? 'XXX'
                                                                                    : feat.properties.lowerlabel.substring(
                                                                                          0,
                                                                                          2
                                                                                      ) ===
                                                                                          'FL' ||
                                                                                      feat
                                                                                          .properties
                                                                                          .lowerlabel ===
                                                                                          'SFC'
                                                                                    ? feat
                                                                                          .properties
                                                                                          .lowerlabel
                                                                                    : feat
                                                                                          .properties
                                                                                          .lowerlabel +
                                                                                      ' ft'
                                                                            }`}
                                                                    </Text>
                                                                    <Text px="3px">
                                                                        –
                                                                    </Text>
                                                                    <Text whiteSpace="pre-line">
                                                                        {feat
                                                                            .properties
                                                                            .upperlabel &&
                                                                            `${
                                                                                feat
                                                                                    .properties
                                                                                    .upperlabel ===
                                                                                'XXX'
                                                                                    ? 'XXX'
                                                                                    : feat.properties.upperlabel.substring(
                                                                                          0,
                                                                                          2
                                                                                      ) ===
                                                                                      'FL'
                                                                                    ? feat
                                                                                          .properties
                                                                                          .upperlabel
                                                                                    : feat
                                                                                          .properties
                                                                                          .upperlabel +
                                                                                      ' ft'
                                                                            }`}
                                                                    </Text>
                                                                </>
                                                            )}
                                                        </Flex>
                                                    )
                                                )}
                                            </Flex>
                                            <Flex flexDirection="row">
                                                <Text whiteSpace="pre-line">
                                                    {/* {feat.properties.movement} */}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        height="auto"
                                        width="40px"
                                        minWidth="40px"
                                        overflow="hidden"
                                        mr="-3px"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        {config && (
                                            <Icon
                                                sx={{
                                                    transform:
                                                        feat.properties.type ===
                                                        'radiation'
                                                            ? 'scale(1.4)'
                                                            : 'scale(1.2)',
                                                }}
                                                color="rgba(20,25,30, 0.07)"
                                                py="2px"
                                                boxSize="36px"
                                                alignItems="center"
                                                justifyContent="center"
                                                as={config.watermark}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        )
                    })}
            </>
        )
    } else {
        return (
            <>
                {(status === 'loading' || dataLoading) && (
                    <Flex
                        w="100%"
                        pt="40px"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Spinner
                            color={'light.200'}
                            emptyColor="rgba(255,255,255,0.3)"
                            thickness="3px"
                            speed="0.45s"
                            boxSize="40px"
                        />
                    </Flex>
                )}
                {status !== 'loading' && status === 'error' && !dataLoading && (
                    <FullWidthError message={'Error fetching SIGWX data'} />
                )}
                {status === 'success' && !dataLoading && (
                    <Flex // NO DATA
                        display={selectedList === 'sigwx' ? 'flex' : 'none'}
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        pt="40px"
                        px="5px"
                        color="gray.500"
                        zIndex="1"
                        fontWeight="500"
                        fontSize={{
                            base: '0.8rem',
                            xs: '0.85rem',
                            md: '0.9rem',
                        }}
                        whiteSpace="pre-line"
                        textAlign="center"
                        lineHeight="1.2"
                    >
                        {selectedTimePastValidTo ? (
                            <Text>
                                SIGWX forecast does not cover the selected time
                            </Text>
                        ) : data &&
                          ((data?.features && data.features.length === 0) ||
                              data?.metadata) ? (
                            <Text>
                                No SIGWX forecasts issued for selected time
                            </Text>
                        ) : (
                            <Text>NO SIGWX information received</Text>
                        )}
                    </Flex>
                )}
            </>
        )
    }
}
