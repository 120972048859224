import { ReactComponent as IceIcon } from '../../../icons/assets/ice.svg'
import { ReactComponent as IcingIcon } from '../../../icons/assets/icing-moderate.svg'
import { ReactComponent as TurbulenceIcon } from '../../../icons/assets/turbulence-symbol-moderate.svg'
import { ReactComponent as MountainWavesIcon } from '../../../icons/assets/mountain-waves-moderate.svg'
import { ReactComponent as CumulonimbusIcon } from '../../../icons/assets/cumulonimbus.svg'
import { ReactComponent as CumulonimbusSymbolIcon } from '../../../icons/assets/cumulonimbus-symbol.svg'
import { ReactComponent as VAAIcon } from '../../../icons/assets/vaa-symbol.svg'
import { ReactComponent as VACIcon } from '../../../icons/assets/vac-symbol.svg'
import { ReactComponent as VolcanoIcon } from '../../../icons/assets/vaa.svg'
import { ReactComponent as WindIcon } from '../../../icons/assets/wind.svg'
import { ReactComponent as MountainWavesSymbolIcon } from '../../../icons/assets/mountain-waves-symbol-moderate.svg'
import { ReactComponent as RadiationIcon } from '../../../icons/assets/radiation-symbol.svg'

import { timeRangeInAbrevWordsSigwx } from '../../../util/dateFormatter'

export default function sigwxToastDefinition(feat, now) {
    const frequencyFormatter = (freq) => {
        const lookup = {
            isolatedEmbedded: 'ISOL EMBD',
            occasionalEmbedded: 'OCNL EMBD',
            occasional: 'OCNL',
        }

        if (lookup[freq]) {
            return lookup[freq]
        }
        return freq
    }
    const sigwxAlerts =
        feat?.properties?.alerts &&
        JSON.parse(feat.properties.alerts).length &&
        JSON.parse(feat.properties.alerts)

    const hasVAEruptionSigmet =
        feat.properties.type === 'volcano' &&
        sigwxAlerts &&
        sigwxAlerts.length &&
        sigwxAlerts.some((value) => value.startsWith('VA SIGMET in force'))

    const volcanoAlertText =
        feat.properties.type === 'volcano' &&
        sigwxAlerts &&
        sigwxAlerts.length &&
        sigwxAlerts.filter(
            (value) =>
                value.startsWith('VA SIGMET in force') ||
                value.startsWith('Volcano Alert Level')
        )

    if (
        feat?.properties?.type === 'turbulence' ||
        feat?.properties?.type === 'clearAirTurbulence'
    ) {
        return {
            type: 'turbulence',
            id: feat?.properties?.id,
            severity: 'moderate',
            className: 'sigwx-toast',
            icon: <TurbulenceIcon width="24px" height="24px" />,
            watermark: (
                <WindIcon width="55px" height="55px" className="watermark" />
            ),
            colour:
                feat.properties.type === 'clearAirTurbulence'
                    ? '#ffcc00'
                    : '#F21010',
            title:
                feat.properties.type === 'clearAirTurbulence'
                    ? 'CLEAR AIR TURBULENCE'
                    : 'TURBULENCE',
            altitudes: `${
                feat?.properties?.lowerlabel.substring(0, 2) === 'FL' ||
                feat?.properties?.lowerlabel === 'SFC'
                    ? feat.properties.lowerlabel
                    : feat.properties.lowerlabel + ' ft'
            } - ${
                feat?.properties?.upperlabel.substring(0, 2) === 'FL'
                    ? feat.properties.upperlabel
                    : feat.properties.upperlabel + ' ft'
            }`,
        }
    }

    if (feat?.properties?.type === 'icing') {
        return {
            type: 'icing',
            id: feat?.properties?.id,
            severity: 'moderate',
            className: 'sigwx-toast',
            icon: <IcingIcon width="24px" height="24px" />,
            watermark: (
                <IceIcon width="55px" height="55px" className="watermark" />
            ),
            colour: '#40b1e8',
            title: feat?.properties?.type.toUpperCase(),
            // description: `${feat.properties.lowerlabel} - ${feat.properties.upperlabel}`,
            altitudes: `${
                feat?.properties?.lowerlabel.substring(0, 2) === 'FL' ||
                feat?.properties?.lowerlabel === 'SFC'
                    ? feat.properties.lowerlabel
                    : feat.properties.lowerlabel + ' ft'
            } - ${
                feat?.properties?.upperlabel.substring(0, 2) === 'FL'
                    ? feat.properties.upperlabel
                    : feat.properties.upperlabel + ' ft'
            }`,
            status:
                feat?.properties?.startvalidity &&
                feat?.properties?.endvalidity &&
                timeRangeInAbrevWordsSigwx(
                    now,
                    feat.properties.startvalidity,
                    feat.properties.endvalidity
                ),
        }
    }

    if (feat?.properties?.type.toLowerCase() === 'mountainwaves') {
        return {
            type: 'mountainWaves',
            id: feat?.properties?.id,
            className: 'sigwx-toast',
            icon: <MountainWavesSymbolIcon width="24px" height="24px" />,
            watermark: (
                <MountainWavesIcon
                    width="55px"
                    height="55px"
                    className="watermark"
                />
            ),
            colour: '#152238',
            title: feat?.properties?.type.toUpperCase(),
            altitudes: `${
                feat?.properties?.lowerlabel.substring(0, 2) === 'FL' ||
                feat?.properties?.lowerlabel === 'SFC'
                    ? feat.properties.lowerlabel
                    : feat.properties.lowerlabel + ' ft'
            } - ${
                feat?.properties?.upperlabel.substring(0, 2) === 'FL'
                    ? feat.properties.upperlabel
                    : feat.properties.upperlabel + ' ft'
            }`,
        }
    }

    if (feat?.properties?.type.toLowerCase() === 'cumulonimbus') {
        return {
            type: 'cumulonimbus',
            id: feat?.properties?.id,
            className: 'sigwx-toast',
            icon: <CumulonimbusSymbolIcon width="24px" height="24px" />,
            watermark: (
                <CumulonimbusIcon
                    width="55px"
                    height="55px"
                    className="watermark"
                />
            ),
            colour: '#008800',
            title: feat?.properties?.type.toUpperCase(),
            frequency: feat?.properties?.frequency
                ? frequencyFormatter(feat.properties.frequency)
                : null,
            altitudes: `${
                feat?.properties?.lowerlabel.substring(0, 2) === 'FL' ||
                feat?.properties?.lowerlabel === 'SFC'
                    ? feat.properties.lowerlabel
                    : feat.properties.lowerlabel + ' ft'
            } - ${
                feat?.properties?.upperlabel.substring(0, 2) === 'FL'
                    ? feat.properties.upperlabel
                    : feat.properties.upperlabel + ' ft'
            }`,
        }
    }

    if (feat?.properties?.type.toLowerCase() === 'volcano') {
        return {
            type: 'volcano',
            id: feat?.properties?.id,
            className: 'sigwx-toast',
            icon: hasVAEruptionSigmet ? (
                <VACIcon width="24px" height="24px" />
            ) : (
                <VAAIcon width="24px" height="24px" />
            ),
            watermark: (
                <VolcanoIcon width="55px" height="55px" className="watermark" />
            ),
            colour: '#FF00FF',
            title: `VOLCANIC ALERT ${
                feat?.properties?.alertlevel
                    ? 'LEVEL ' + feat.properties.alertlevel
                    : ''
            }`,
            volcano: feat?.properties?.volcano,
            alertlevel: feat?.properties?.alertlevel
                ? feat.properties.alertlevel
                : '',
            hasVAEruptionSigmet: hasVAEruptionSigmet,
            alerts: volcanoAlertText,
        }
    }

    if (feat?.properties?.type.toLowerCase() === 'radiation') {
        return {
            type: 'radiation',
            id: feat?.properties?.id,
            className: 'sigwx-toast',
            icon: <RadiationIcon width="24px" height="24px" />,
            watermark: (
                <RadiationIcon
                    width="55px"
                    height="55px"
                    className="watermark"
                />
            ),
            colour: '#94DA00',
            title: feat?.properties?.type.toUpperCase(),
            alertlevel: feat?.properties?.alertlevel
                ? feat.properties.alertlevel
                : '',
        }
    }

    return null
}
