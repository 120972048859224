import React, { useState, useCallback, useContext, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer, Popup, MapContext } from 'react-mapbox-gl'
import { parseISO } from 'date-fns'
import { getMetarOutOfDate } from '../../../util/metDataOutOfDate'
import {
    layerSelectionState,
    VectorLayerRefreshInfo,
    dataModeState,
    globalTimeState,
} from '../../../globalState'
import { Text, Flex, Center, Box } from '@chakra-ui/react'
import './assets/aerodrome-popup.css'

import { ReactComponent as WindsockIcon } from './assets/windsock-white.svg'
import { ReactComponent as VisibilityIcon } from './assets/visibility.svg'
import { ReactComponent as QnhIcon } from './assets/qnh-white.svg'

export default function AerodromesLayer() {
    const layerSelection = useRecoilValue(layerSelectionState)
    const layerData = useRecoilValue(VectorLayerRefreshInfo)
    const dataMode = useRecoilValue(dataModeState)
    const mapInstance = useContext(MapContext)
    const currentTime = useRecoilValue(globalTimeState)

    const [hoverInfo, setHoverInfo] = useState(null)

    const onHover = (e) => {
        const zoomLevel = e.target.getZoom()

        const newE = e

        const filteredFeatures = e.features.filter((feat) => {
            if (
                zoomLevel < 6 &&
                feat.properties.flightrule === 'IFR' &&
                feat.properties.type === 'AD'
            ) {
                return feat
            }
            if (
                zoomLevel >= 6 &&
                zoomLevel < 8 &&
                feat.properties.type === 'AD'
            ) {
                return feat
            }
            if (zoomLevel >= 8) {
                return feat
            }
            return null
        })

        newE.features = filteredFeatures.map((feat) => {
            var newFeat = feat
            newFeat.properties.windvar = feat.properties.windvar
                ? JSON.parse(feat.properties.windvar)
                : null

            return newFeat
        })
        // console.log(newE)
        setHoverInfo(newE)
    }

    // used to calculate rotation for WDI icon when map rotates
    const [mapTrueBearing, setMapTrueBearing] = useState(0)
    useEffect(() => {
        const handleMove = (e) => {
            setMapTrueBearing(e.target.getBearing())
        }
        mapInstance.on('move', handleMove)
        return () => mapInstance.off('move', handleMove)
    }, [mapInstance])

    const offHover = useCallback((e) => {
        setHoverInfo(null)
    }, [])

    const zoomLevelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.0,
        2,
        0.4,
        3,
        0.5,
        4,
        0.6,
        5,
        0.7,
        6,
        0.7,
        7,
        0.8,
        8,
        0.8,
        9,
        0.9,
        10,
        0.9,
        11,
        0.8,
        12,
        0.8,
    ]

    const zoomLabelDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        2,
        0,
        3,
        7,
        4,
        9,
        6,
        10,
        7,
        10,
        8,
        10,
        10,
        11,
        11,
        12,
    ]

    const zoomOffsetDefinition = {
        stops: [
            [4, [0, 1.3]],
            [5, [0, 1.3]],
            [6, [0, 1.4]],
            [8, [0, 1.4]],
            [9, [0, 1.6]],
            [11, [0, 1.3]],
        ],
    }

    const designator =
        hoverInfo &&
        hoverInfo.features &&
        hoverInfo.features.length > 0 &&
        hoverInfo.features[0]?.properties?.designator

    const issueTime =
        hoverInfo &&
        hoverInfo.features &&
        hoverInfo.features.length > 0 &&
        hoverInfo.features[0]?.properties?.metarissuetime

    const metarIssuedTime = issueTime ? parseISO(issueTime) : null

    const metarOutOfDateIssueTime =
        metarIssuedTime &&
        getMetarOutOfDate(metarIssuedTime, designator, currentTime)

    const metarOutOfDate =
        metarOutOfDateIssueTime && metarOutOfDateIssueTime !== 'NONE'

    // console.log(hoverInfo, 'aerodromehoverInfo')
    // console.log(metarOutOfDate, 'metarOutOfDate')

    return (
        <>
            <GeoJSONLayer
                id="aerodromes"
                data={layerData.aerodromes.dataURL}
                symbolOnMouseEnter={dataMode !== 'brief' ? onHover : null}
                symbolOnMouseLeave={dataMode !== 'brief' ? offHover : null}
                symbolLayout={{
                    visibility: layerSelection.constant.aerodromes
                        ? 'visible'
                        : 'none',
                    'symbol-sort-key': ['to-number', ['get', 'order']],
                    'symbol-z-order': 'source',
                    'icon-image': [
                        'case',
                        ['!=', ['get', 'designator'], null],
                        ['get', 'designator'],
                        [
                            'match',
                            ['get', 'type'],
                            'HP',
                            'hp-nonpub',
                            'ad-nonpub',
                        ],
                    ],
                    'icon-size': zoomLevelDefinition,
                    'icon-rotation-alignment': 'map',
                    'text-field': [
                        'case',
                        ['!=', ['get', 'designator'], null],
                        ['get', 'designator'],
                        ['get', 'name'],
                    ],
                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-offset': zoomOffsetDefinition,
                    'text-anchor': 'top',
                    'text-size': zoomLabelDefinition,
                }}
                symbolPaint={{
                    'icon-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        6,
                        [
                            'match',
                            ['get', 'flightrule'],
                            'IFR',
                            ['match', ['get', 'type'], 'AD', 1, 'HP', 0, 0],
                            0,
                        ],
                        8,
                        [
                            'match',
                            ['get', 'flightrule'],
                            'IFR',
                            ['match', ['get', 'type'], 'AD', 1, 'HP', 0, 0],
                            'VFR',
                            [
                                'match',
                                ['get', 'type'],
                                'AD',
                                1,
                                'HP',
                                0,
                                'WD',
                                0,
                                0,
                            ],
                            0,
                        ],
                        9,
                        1,
                    ],
                    'text-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        4,
                        [
                            'match',
                            ['get', 'flightrule'],
                            'IFR',
                            ['match', ['get', 'type'], 'AD', 0, 'HP', 0, 0],
                            0,
                        ],
                        4.5,
                        [
                            'match',
                            ['get', 'flightrule'],
                            'IFR',
                            ['match', ['get', 'type'], 'AD', 0, 'HP', 0, 0],
                            0,
                        ],
                        5,
                        [
                            'match',
                            ['get', 'flightrule'],
                            'IFR',
                            ['match', ['get', 'type'], 'AD', 1, 'HP', 0, 0],
                            0,
                        ],
                        6,
                        [
                            'match',
                            ['get', 'flightrule'],
                            'IFR',
                            ['match', ['get', 'type'], 'AD', 1, 'HP', 0, 0],
                            0,
                        ],
                        8,
                        [
                            'match',
                            ['get', 'flightrule'],
                            'IFR',
                            ['match', ['get', 'type'], 'AD', 1, 'HP', 0, 0],
                            'VFR',
                            [
                                'match',
                                ['get', 'type'],
                                'AD',
                                1,
                                'HP',
                                0,
                                'WD',
                                0,
                                0,
                            ],
                            0,
                        ],
                        9,
                        1,
                    ],
                    'text-color': [
                        'match',
                        ['get', 'flightrule'],
                        'IFR',
                        '#92278f',
                        'VFR',
                        '#0072bc',
                        '#000000',
                    ],
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 0.8,
                }}
            />
            {hoverInfo &&
                hoverInfo.features &&
                hoverInfo.features.length > 0 && (
                    <Popup
                        coordinates={[
                            hoverInfo.lngLat.lng,
                            hoverInfo.lngLat.lat,
                        ]}
                        className={
                            'aerodrome-popup ' +
                            hoverInfo.features[0].properties.flightrule.toLowerCase()
                        }
                    >
                        <div className="popup-header">
                            <h1>
                                {hoverInfo.features[0].properties.designator}
                            </h1>
                            <h2>{hoverInfo.features[0].properties.name}</h2>
                            {/* <h3>Aerodrome</h3> */}
                        </div>
                        <div
                            className="popup-divider"
                            style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                                borderBottom: '1px solid rgba(255,255,255,0.2)',
                            }}
                        ></div>
                        {(hoverInfo.features[0].properties.elevation ||
                            hoverInfo.features[0].properties.rwylength) && (
                            <>
                                <p
                                    className="aerodrome-info"
                                    style={{
                                        marginTop: '6px',
                                    }}
                                >
                                    {hoverInfo.features[0].properties
                                        .elevation &&
                                        `ELEV ${hoverInfo.features[0].properties.elevation} ft`}
                                </p>
                                <p className="aerodrome-info">
                                    {hoverInfo.features[0].properties
                                        .rwylength &&
                                        ` RWY ${hoverInfo.features[0].properties.rwylength} m`}
                                </p>
                                {!metarOutOfDate &&
                                    !hoverInfo.features[0].properties
                                        .outdated &&
                                    (hoverInfo.features[0].properties.winddir ||
                                        hoverInfo.features[0].properties
                                            .windvar ||
                                        hoverInfo.features[0].properties
                                            .visibility ||
                                        hoverInfo.features[0].properties
                                            .qnh) && (
                                        <div
                                            className="popup-divider"
                                            style={{
                                                paddingLeft: '0',
                                                paddingRight: '0',
                                                paddingTop: '6px',
                                                marginBottom: '0px',
                                                borderBottom:
                                                    '1px solid rgba(255,255,255,0.2)',
                                            }}
                                        ></div>
                                    )}
                            </>
                        )}

                        <div
                            className="popup-body"
                            style={{
                                paddingTop:
                                    !metarOutOfDate &&
                                    !hoverInfo.features[0].properties
                                        .outdated &&
                                    (hoverInfo.features[0].properties.winddir ||
                                        hoverInfo.features[0].properties
                                            .windvar ||
                                        hoverInfo.features[0].properties
                                            .visibility ||
                                        hoverInfo.features[0].properties.qnh)
                                        ? '6px'
                                        : '0px',
                            }}
                        >
                            {((winddir, windstr, windgust, windvar) => {
                                if (winddir) {
                                    return (
                                        <Flex
                                            justifyContent="center"
                                            flexDirection="column"
                                            marginTop="4px"
                                            marginBottom="2px"
                                            marginLeft="-5px"
                                        >
                                            <Center>
                                                <WindsockIcon
                                                    style={{
                                                        height: '22px',
                                                        width: '22px',
                                                        transform: `rotate(${
                                                            (Math.round(
                                                                Number(
                                                                    winddir
                                                                ) +
                                                                    90 -
                                                                    mapTrueBearing
                                                            ) +
                                                                360) %
                                                            360
                                                        }deg)`,
                                                    }}
                                                />
                                                <Flex flexDirection="column">
                                                    <Text
                                                        paddingLeft="7px"
                                                        lineHeight="1.4"
                                                    >
                                                        {`${winddir}°T, `}
                                                        {`${parseInt(
                                                            windstr,
                                                            10
                                                        ).toString()} kt`}
                                                    </Text>
                                                    {windgust && (
                                                        <Text
                                                            paddingLeft="7px"
                                                            lineHeight="1.4"
                                                        >
                                                            {`Gusts ${windgust} kt`}
                                                        </Text>
                                                    )}
                                                    {windvar && (
                                                        <Text
                                                            paddingLeft="7px"
                                                            paddingBottom="2px"
                                                            lineHeight="1.4"
                                                        >
                                                            {' ' +
                                                                windvar[0] +
                                                                'V' +
                                                                windvar[1] +
                                                                '°T'}
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </Center>
                                        </Flex>
                                    )
                                }

                                return null
                            })(
                                !metarOutOfDate &&
                                    !hoverInfo.features[0].properties
                                        .outdated &&
                                    hoverInfo.features[0].properties.winddir,
                                !hoverInfo.features[0].properties.outdated &&
                                    hoverInfo.features[0].properties.windstr,
                                !hoverInfo.features[0].properties.outdated &&
                                    hoverInfo.features[0].properties.windgust,
                                !hoverInfo.features[0].properties.outdated &&
                                    hoverInfo.features[0].properties.windvar
                            )}

                            {((visibility) => {
                                if (visibility) {
                                    return (
                                        <Center pb="2px">
                                            <VisibilityIcon
                                                style={{
                                                    height: '22px',
                                                    width: '22px',
                                                }}
                                            />
                                            <Text
                                                paddingLeft="7px"
                                                lineHeight="1.2"
                                            >
                                                {visibility === '9999'
                                                    ? '> 9999 m'
                                                    : visibility.slice(-2) ===
                                                      'KM'
                                                    ? `${visibility.replace(
                                                          'KM',
                                                          ' km'
                                                      )}`
                                                    : `${
                                                          visibility.slice(
                                                              0,
                                                              1
                                                          ) === '0'
                                                              ? visibility.slice(
                                                                    1
                                                                )
                                                              : visibility
                                                      } m`}
                                            </Text>
                                        </Center>
                                    )
                                }

                                return null
                            })(
                                !metarOutOfDate &&
                                    !hoverInfo.features[0].properties
                                        .outdated &&
                                    hoverInfo.features[0].properties.visibility
                            )}

                            {((qnh) => {
                                if (qnh) {
                                    return (
                                        <Center pb="2px">
                                            <QnhIcon
                                                style={{
                                                    height: '20px',
                                                    width: '20px',
                                                }}
                                            />
                                            <Text
                                                paddingLeft="7px"
                                                lineHeight="1.2"
                                            >
                                                {qnh} hPa
                                            </Text>
                                        </Center>
                                    )
                                }

                                return null
                            })(
                                !metarOutOfDate &&
                                    !hoverInfo.features[0].properties
                                        .outdated &&
                                    hoverInfo.features[0].properties.qnh
                            )}

                            {!metarOutOfDate &&
                                !hoverInfo.features[0].properties.outdated &&
                                (hoverInfo.features[0].properties.winddir ||
                                    hoverInfo.features[0].properties.windvar ||
                                    hoverInfo.features[0].properties
                                        .visibility ||
                                    hoverInfo.features[0].properties.qnh) && (
                                    <div
                                        className="popup-metar-text"
                                        style={{
                                            paddingLeft: '0',
                                            paddingRight: '0',
                                            marginBottom: '0px',
                                            fontWeight: '300',
                                            fontSize: '0.5rem',
                                            opacity: '0.55',
                                            height: '10px',
                                            letterSpacing: '0.5px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        From latest METAR
                                    </div>
                                )}
                        </div>
                        <div
                            className="popup-divider"
                            style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                                paddingTop: '8px',
                                marginBottom: '7px',
                                borderBottom: '1px solid rgba(255,255,255,0.2)',
                            }}
                        ></div>
                        {hoverInfo.features[0].properties.notam !== '0' ? (
                            <Flex
                                width="100%"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box // Orange Dot
                                    width="8px"
                                    height="8px"
                                    borderRadius="10px"
                                    mr="7px"
                                    bg="#FC7D00"
                                ></Box>
                                <Text pr="5px">
                                    {hoverInfo.features[0].properties.notam}
                                </Text>
                                <Text>
                                    NOTAM
                                    {hoverInfo.features[0].properties.notam >
                                        1 && 's'}
                                </Text>
                            </Flex>
                        ) : (
                            <Text>No current NOTAMs</Text>
                        )}
                    </Popup>
                )}
        </>
    )
}
