import { Center, Flex, Text } from '@chakra-ui/react'
import parse from 'html-react-parser'
import { ReactComponent as ErrorIcon } from '../../../icons/assets/error.svg'

export default function FullWidthError({ message }) {
    return (
        <Center h="100%" w="100%" minHeight={"500px"}>
            <Flex
                direction="column"
                alignItems={'center'}
                justifyContent={'center'}
                pb="20vh"
            >
                <Flex transform="rotate(90deg)">
                    <ErrorIcon color="red" width="50px" height="50px" />
                </Flex>
                <Text align={'center'} pt="10px" size="md" color="red">
                    {parse(message)}
                </Text>
            </Flex>
        </Center>
    )
}
