export const menuOptions = [
    {
        label: 'Aerodromes',
        container: 'aerodromesContainer',
        menuColor: '#1778b7',
        menuTransitionValue: {
            stdBO: 0,
            stdBOandMenuExp: 0,
            altBO: 7,
            altBOandMenuExp: 7,
        },
    },
    {
        label: 'NOTAM',
        container: 'notamContainer',
        menuColor: '#1778b7',
        menuTransitionValue: {
            stdBO: 0,
            stdBOandMenuExp: 1,
            altBO: 7,
            altBOandMenuExp: 8,
        },
    },
    {
        label: 'METAR',
        container: 'metarContainer',
        menuColor: '#1778b7',
        menuTransitionValue: {
            stdBO: 0,
            stdBOandMenuExp: 2,
            altBO: 7,
            altBOandMenuExp: 9,
        },
    },
    {
        label: 'TAF',
        container: 'tafContainer',
        menuColor: '#1778b7',
        menuTransitionValue: {
            stdBO: 0,
            stdBOandMenuExp: 3,
            altBO: 7,
            altBOandMenuExp: 10,
        },
    },
    {
        label: 'ATIS',
        container: 'atisContainer',
        menuColor: '#1778b7',
        menuTransitionValue: {
            stdBO: 0,
            stdBOandMenuExp: 4,
            altBO: 7,
            altBOandMenuExp: 11,
        },
    },
    {
        label: 'AWS',
        container: 'awsContainer',
        menuColor: '#86a4d3',
        menuTransitionValue: {
            stdBO: 1,
            stdBOandMenuExp: 5,
            altBO: 8,
            altBOandMenuExp: 12,
        },
    },
    {
        label: 'En-route NOTAM',
        container: 'enrouteContainer',
        menuColor: '#10aa70',
        menuTransitionValue: {
            stdBO: 2,
            stdBOandMenuExp: 6,
            altBO: 6,
            altBOandMenuExp: 6,
        },
    },
    {
        label: 'AAW',
        container: 'aawContainer',
        menuColor: '#53c8eb',
        menuTransitionValue: {
            stdBO: 3,
            stdBOandMenuExp: 7,
            altBO: 0,
            altBOandMenuExp: 0,
        },
    },
    {
        label: 'GRAFOR',
        container: 'graforContainer',
        menuColor: '#53c8eb',
        menuTransitionValue: {
            stdBO: 4,
            stdBOandMenuExp: 8,
            altBO: 1,
            altBOandMenuExp: 1,
        },
    },
    {
        label: 'MSL',
        container: 'mslContainer',
        menuColor: '#53c8eb',
        menuTransitionValue: {
            stdBO: 5,
            stdBOandMenuExp: 9,
            altBO: 2,
            altBOandMenuExp: 2,
        },
    },
    {
        label: 'SIGMET',
        container: 'sigmetContainer',
        menuColor: '#53c8eb',
        menuTransitionValue: {
            stdBO: 6,
            stdBOandMenuExp: 10,
            altBO: 3,
            altBOandMenuExp: 3,
        },
    },
    {
        label: 'SIGWX',
        container: 'sigwxContainer',
        menuColor: '#53c8eb',
        menuTransitionValue: {
            stdBO: 7,
            stdBOandMenuExp: 11,
            altBO: 4,
            altBOandMenuExp: 4,
        },
    },
    {
        label: 'SpaceWX',
        container: 'spacewxContainer',
        menuColor: '#04557d',
        menuTransitionValue: {
            stdBO: 8,
            stdBOandMenuExp: 12,
            altBO: 5,
            altBOandMenuExp: 5,
        },
    },
]
