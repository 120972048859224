import React, { useEffect } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    onlineState,
    windowIdle,
    briefingStandardState,
    hideNetworkConnectionLostState,
    briefingCreatedState,
    showOnlineUsersButtonState,
    searchFocusState,
    fullscreenModalVisibleState,
    layerSelectionState,
} from '../../../globalState'
import {
    Modal,
    Flex,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button,
    LightMode,
    Text,
    Icon,
    Box,
    ButtonGroup,
} from '@chakra-ui/react'

import { isIOS, isMobileOnly } from 'react-device-detect'
import RefreshIcon from '../../../icons/menu-icons/RefreshIcon'

export default function OfflineModal() {
    const idle = useRecoilValue(windowIdle)
    const online = useRecoilValue(onlineState)
    const [hide, setHide] = useRecoilState(hideNetworkConnectionLostState)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const briefingStandard = useRecoilValue(briefingStandardState)
    const briefingCreated = useRecoilValue(briefingCreatedState)
    const searchFocused = useRecoilValue(searchFocusState)
    const onlineUsers = useRecoilValue(showOnlineUsersButtonState)
    const fullscreenModalOpen = useRecoilValue(fullscreenModalVisibleState)
    const layerSelection = useRecoilValue(layerSelectionState)

    const fullScreenModalWithHeaderBar =
        layerSelection &&
        (layerSelection?.met?.cloudImagery ||
            layerSelection?.met?.graforCharts ||
            layerSelection?.met?.mslCharts)

    const showingBriefing = briefingStandard && briefingCreated

    useEffect(() => {
        if (!idle && !online && !hide) {
            onOpen()
        } else if (!idle && online && (isOpen || hide)) {
            onClose()
            setHide(false)
        } else if (!isOpen) {
            setHide(false)
        }
    }, [online, isOpen, idle, hide, onOpen, onClose, setHide])

    useEffect(() => {
        if (isOpen) {
            setHide(false)
        }
    }, [isOpen, showingBriefing, setHide])

    function refreshPage() {
        setTimeout(() => window.location.reload())
    }

    return (
        <>
            <LightMode>
                <Modal
                    variant="alert"
                    isOpen={isOpen}
                    isCentered
                    closeOnOverlayClick={false}
                    trapFocus={false}
                >
                    <ModalOverlay
                        borderWidth={hide ? 3 : 4}
                        borderColor="#FF4F44"
                        borderTopRadius={{
                            // reset these values to '0' for now pending further testing on mobile devices
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        borderBottomRadius={{
                            base: isIOS && isMobileOnly ? '0px' : '0px',
                            md: '0px',
                        }}
                        bg={hide ? 'transparent' : 'blackAlpha.600'}
                        pointerEvents={hide ? 'none' : 'initial'}
                    />
                    {hide && (
                        <Button
                            zIndex="1500"
                            position={'fixed'}
                            opacity={{
                                base: '1',
                                md:
                                    !showingBriefing && searchFocused
                                        ? '0'
                                        : '1',
                            }}
                            visibility={{
                                base: 'visible',
                                md:
                                    !showingBriefing && searchFocused
                                        ? 'hidden'
                                        : 'visible',
                            }}
                            transform={{
                                base: 'translate(-50%, 0)',
                                md:
                                    showingBriefing || fullscreenModalOpen
                                        ? 'translate(-50%, 0)'
                                        : 'none',
                                '2xl': fullscreenModalOpen
                                    ? 'translate(-50%, 0)'
                                    : 'none',
                            }}
                            transition="all ease-out 200ms"
                            left={{
                                base: '50%',
                                md: showingBriefing
                                    ? 'calc(50% + 150px)'
                                    : fullscreenModalOpen
                                    ? 'calc(50% + 25px)'
                                    : onlineUsers
                                    ? '248px'
                                    : '200px',
                                '2xl': showingBriefing
                                    ? '325px'
                                    : fullscreenModalOpen
                                    ? 'calc(50% + 25px)'
                                    : onlineUsers
                                    ? '248px'
                                    : '200px',
                            }}
                            top={{
                                base: showingBriefing
                                    ? '79px'
                                    : fullScreenModalWithHeaderBar
                                    ? '52px'
                                    : fullscreenModalOpen
                                    ? '75px'
                                    : '70px',
                                md: showingBriefing ? '79px' : '19px',
                                '2xl': '19px',
                            }}
                            background={{
                                base: 'rgb(241, 73, 73, .7)',
                                '2xl': showingBriefing
                                    ? 'red.400'
                                    : 'rgb(241, 73, 73, .7)',
                            }}
                            backdropFilter="blur(2px)"
                            boxShadow={
                                !showingBriefing &&
                                !fullScreenModalWithHeaderBar &&
                                !fullscreenModalOpen &&
                                '0px 3px 20px -7px rgba(0, 0, 0, 0.2)'
                            }
                            color="white"
                            variant="solid"
                            leftIcon={
                                <Icon // Icon used directly in file to prevent source break when in connection error
                                    boxSize={6}
                                    viewBox="0 0 24 24"
                                    color="red.500"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21.7305 7.51986C16.086 2.82671 7.91402 2.82671 2.26948 7.51986C1.95356 7.77354 1.91143 8.23863 2.16417 8.55573L11.4313 20.2252C11.5788 20.3943 11.7894 20.5 12 20.5C12.2317 20.5 12.4423 20.3943 12.5687 20.2252L21.8358 8.55573C22.0886 8.23863 22.0464 7.79468 21.7305 7.51986ZM12.125 6C11.7475 6.02723 11.3971 6.19059 11.1275 6.46287C10.8578 6.76238 10.723 7.17079 10.777 7.55198L11.5049 13.2153C11.6936 13.1609 11.9093 13.1337 12.125 13.1337C12.3407 13.1337 12.5564 13.1609 12.7721 13.2153L13.473 7.55198V7.36139C13.473 6.59901 12.8799 6 12.125 6ZM10.75 15.6386C10.75 16.3738 11.3701 17 12.125 17C12.8799 17 13.473 16.401 13.5 15.6114C13.5 14.8762 12.8799 14.25 12.125 14.25C11.3701 14.25 10.75 14.8762 10.75 15.6386Z"
                                        fill="white"
                                    />
                                </Icon>
                            }
                            borderRadius="30px"
                            onClick={() => {
                                setHide(false)
                            }}
                            pl="10px"
                            pr="14px"
                            height={{ base: '34px', xl: '34px' }}
                            fontSize="0.75rem"
                            _focus={{
                                border: 'none',
                                background: 'red.500',
                            }}
                            _hover={{ background: 'red.500' }}
                            _active={{ background: 'red.500' }}
                        >
                            <Flex
                                flexDirection="column"
                                alignItems="flex-start"
                            >
                                <Text>Network Connection Lost</Text>
                                <Text
                                    fontSize="0.6rem"
                                    fontWeight="400"
                                    mt="-1px"
                                >
                                    Data cannot be assured as reliable and up to
                                    date
                                </Text>
                            </Flex>
                        </Button>
                    )}
                    {!hide && (
                        <ModalContent borderRadius="15" bg="white">
                            <ModalHeader
                                bg="white"
                                color="light.100"
                                boxShadow={'0px 0px 15px -1px rgba(0,0,0,0.15)'}
                                borderBottom="1px solid #eaeaea"
                                marginBottom="3"
                                paddingStart="6"
                                textAlign="center"
                            >
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    mx="auto"
                                    pr={2}
                                >
                                    <Icon // Icon used directly in file to prevent source break when in connection error
                                        boxSize={8}
                                        viewBox="0 0 24 24"
                                        color="red.500"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M21.7305 7.51986C16.086 2.82671 7.91402 2.82671 2.26948 7.51986C1.95356 7.77354 1.91143 8.23863 2.16417 8.55573L11.4313 20.2252C11.5788 20.3943 11.7894 20.5 12 20.5C12.2317 20.5 12.4423 20.3943 12.5687 20.2252L21.8358 8.55573C22.0886 8.23863 22.0464 7.79468 21.7305 7.51986ZM12.125 6C11.7475 6.02723 11.3971 6.19059 11.1275 6.46287C10.8578 6.76238 10.723 7.17079 10.777 7.55198L11.5049 13.2153C11.6936 13.1609 11.9093 13.1337 12.125 13.1337C12.3407 13.1337 12.5564 13.1609 12.7721 13.2153L13.473 7.55198V7.36139C13.473 6.59901 12.8799 6 12.125 6ZM10.75 15.6386C10.75 16.3738 11.3701 17 12.125 17C12.8799 17 13.473 16.401 13.5 15.6114C13.5 14.8762 12.8799 14.25 12.125 14.25C11.3701 14.25 10.75 14.8762 10.75 15.6386Z"
                                            fill="#E53E3E"
                                        />
                                    </Icon>
                                    <Box pl={2} pr={3} fontFamily="Open Sans">
                                        Network Connection Lost
                                    </Box>
                                </Flex>
                            </ModalHeader>
                            <ModalBody
                                textAlign="center"
                                bg="white"
                                fontSize={{ base: '0.8rem', md: '0.9rem' }}
                            >
                                <Text pb="15px">
                                    Please find a stable network connection
                                    otherwise we cannot provide you with
                                    reliable and updated data
                                </Text>
                            </ModalBody>
                            <ModalFooter pb="7" justifyContent="center">
                                <ButtonGroup
                                    gap="10px"
                                    width="100%"
                                    justifyContent="center"
                                    mt="10px"
                                >
                                    <Button
                                        variant="outline"
                                        color="gray.600"
                                        borderRadius="30px"
                                        onClick={() => {
                                            setHide(true)
                                        }}
                                        w="60%"
                                        _focus={{ border: 'none' }}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        colorScheme="red"
                                        variant="solid"
                                        leftIcon={
                                            <RefreshIcon
                                                color="white"
                                                boxSize={5}
                                            />
                                        }
                                        borderRadius="30px"
                                        onClick={refreshPage}
                                        w="60%"
                                        _focus={{ border: 'none' }}
                                    >
                                        Refresh
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </ModalContent>
                    )}
                </Modal>
            </LightMode>
        </>
    )
}
