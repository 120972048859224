import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import buildAerodromeIcon from '../../map/icons/dynamic/buildAerodromeIcon'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { isMobileOnly, isIOS } from 'react-device-detect'
import FullWidthError from '../../map/modals/components/FullWidthError'
import { isDesktop } from 'react-device-detect'
import {
    Box,
    Text,
    Button,
    Flex,
    LightMode,
    useBreakpointValue,
    useDisclosure,
    Icon,
    Image,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Center,
    Spinner,
} from '@chakra-ui/react'
import { isEqual } from 'lodash'
import { ReactComponent as AerodromeIcon } from '../../icons/assets/aerodromes/aerodrome.svg'
import { ReactComponent as WaterdromeIcon } from '../../icons/assets/aerodromes/waterdrome.svg'
import { ReactComponent as HeliportIcon } from '../../icons/assets/aerodromes/heliport.svg'
import { ReactComponent as SortAlphabeticalIcon } from '../../icons/assets/sort-alphabetical.svg'
import { ReactComponent as SortNorthToSouthIcon } from '../../icons/assets/sort-northtosouth.svg'
import { ReactComponent as SortIfrVfrIcon } from '../../icons/assets/sort-ifr-vfr.svg'
import { ReactComponent as SortTypeIcon } from '../../icons/assets/sort-ad-type.svg'
import { TfiMore, TfiMoreAlt } from 'react-icons/tfi'
import {
    dataModeState,
    fullscreenModalVisibleHandler,
    statusData,
    clickedFeaturesState,
    aerodromesListState,
} from '../../globalState'
import FilterButton from '../components/FilterButton'
import SelectTypeButton from '../components/SelectTypeButton'

export default function AerodromesList() {
    const dataMode = useRecoilValue(dataModeState)
    const { getAccessTokenSilently } = useAuth0()
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const handleFullscreenModal = useSetRecoilState(
        fullscreenModalVisibleHandler
    )
    const [aerodromesList, setAerodromesList] =
        useRecoilState(aerodromesListState)
    const [mounted, setMounted] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [aerodromes, setAerodromes] = useState([])
    const dataStatus = useRecoilValue(statusData)
    const [optionSelected, setOptionSelected] = useState(0)
    const sortAerodromesByName = aerodromesList?.sorting?.name
    const sortAerodromesByFlightRule = aerodromesList?.sorting?.flightRule
    const sortAerodromesByType = aerodromesList?.sorting?.type
    const aerodromesActive = aerodromesList?.filtering?.aerodromes
    const heliportsActive = aerodromesList?.filtering?.heliports
    const waterdromesActive = aerodromesList?.filtering?.waterdromes

    const toggleAerodromeState = (key, feat) => {
        setAerodromesList((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [feat]: !prevState[key][feat],
            },
        }))
    }

    const fetchAerodromes = async () => {
        const accessToken = await getAccessTokenSilently()
        const res = await fetch(`${window.location.origin}/data/aerodromes`, {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        })
        return res.json()
    }
    const aerodromeRef = useRef(null)
    const aerodromeVersionRef = useRef(null)
    const { data, status, refetch } = useQuery('aerodromes', fetchAerodromes, {
        refetchOnWindowFocus: false,
    })
    const { isOpen, onOpen, onClose } = useDisclosure()
    const feat = clickedFeatures[0]
    const aerodromeId = feat?.properties?.id

    useEffect(() => {
        if (dataMode === 'aero') {
            onOpen()
        } else {
            onClose()
        }
    }, [onOpen, onClose, dataMode, handleFullscreenModal])

    // set fullscreen modal to true on load, and unset on unload
    useEffect(() => {
        handleFullscreenModal()
        return () => {
            handleFullscreenModal()
        }
    }, [handleFullscreenModal])

    // This sets the intial value of aerodromes when it is loaded.
    useEffect(() => {
        if (dataStatus && data && status === 'success' && !mounted) {
            const aerodromes = data.features.map((feat) => ({
                ...feat,
                properties: {
                    ...feat.properties,
                    lat: feat.geometry.coordinates[1],
                    lng: feat.geometry.coordinates[0],
                },
            }))
            setAerodromes(aerodromes)
            aerodromeVersionRef.current = dataStatus.aerodromes.version
            setMounted(true)
        }
    }, [setAerodromes, data, status, mounted, dataStatus])

    // This checks that aerodromes have changed before resetting them.
    useEffect(() => {
        if (status === 'success' && data && mounted) {
            const aerodromes = data.features.map((feat) => ({
                ...feat,
                properties: {
                    ...feat.properties,
                    lat: feat.geometry.coordinates[1],
                    lng: feat.geometry.coordinates[0],
                },
            }))
            if (!isEqual(aerodromes, aerodromeRef.current)) {
                setAerodromes(aerodromes)
                //aerodromeRef.current = aerodromes
            }
        }
    }, [setAerodromes, data, status, mounted])

    // This detects a change in the aerodrome version stamp, if it has changed it will trigger a refetch.
    useEffect(() => {
        if (
            dataStatus &&
            mounted &&
            aerodromeVersionRef.current !== dataStatus.aerodromes.version
        ) {
            refetch()
            aerodromeVersionRef.current = dataStatus.aerodromes.version
        }
    }, [dataStatus, mounted, refetch])

    const filteredAerodromes =
        aerodromes &&
        aerodromes
            .filter((feat) => {
                if (optionSelected === 1)
                    return feat?.properties?.flightrule === 'IFR'
                if (optionSelected === 2)
                    return feat?.properties?.flightrule === 'VFR'
                if (optionSelected === 3) return feat?.properties?.type === 'AD'
                if (optionSelected === 4) return feat?.properties?.type === 'HP'
                if (optionSelected === 5) return feat?.properties?.type === 'WD'
                return true
            })
            .filter((feat) =>
                aerodromesActive ? true : feat?.properties?.type !== 'AD'
            )
            .filter((feat) =>
                heliportsActive ? true : feat?.properties?.type !== 'HP'
            )
            .filter((feat) =>
                waterdromesActive ? true : feat?.properties?.type !== 'WD'
            )
            .sort((a, b) => {
                // sort by flightrule
                if (sortAerodromesByFlightRule) {
                    if (a?.properties?.flightrule < b?.properties?.flightrule)
                        return -1
                    if (a?.properties?.flightrule > b?.properties?.flightrule)
                        return 1
                }
                // sort by type
                if (sortAerodromesByType) {
                    if (a?.properties?.type < b?.properties?.type) return -1
                    if (a?.properties?.type > b?.properties?.type) return 1
                }
                // sort by name or north/south
                if (sortAerodromesByName) {
                    return a?.properties?.name.localeCompare(
                        b?.properties?.name
                    )
                } else {
                    return a?.properties?.lat < b?.properties?.lat ? 1 : -1
                }
            })

    // This function checks old aerodromes against new aerodromes and returns the difference.
    // function aerodromesChanged(newAerodromes, oldAerodromes) {
    //     if (oldAerodromes) {
    //         const changes = newAerodromes.filter((a, i) => {
    //             const oldNotam = Number(oldAerodromes[i]?.notam) || 0
    //             const newNotam = Number(a?.notam) || 0
    //             return (
    //                 (oldNotam === 0 && newNotam > 0) ||
    //                 (oldNotam > 0 && newNotam === 0)
    //             )
    //         })
    //         return changes
    //     } else {
    //         return []
    //     }
    // }

    const aerodromeOptions =
        filteredAerodromes &&
        filteredAerodromes.map((aerodrome, i) => {
            const {
                id,
                type,
                flightrule,
                rwyazimuth,
                notam,
                designator,
                name,
            } = aerodrome?.properties

            const modifiedFeature = {
                ...aerodrome,
                layer: 'aerodromes',
            }

            return (
                <Flex
                    key={`selected-${designator}-${i}`}
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => {
                        setClickedFeatures([modifiedFeature])
                    }}
                    cursor="pointer"
                    height={{
                        base: '40px',
                        md: aerodromeId === id ? '40px' : '36px',
                    }}
                    minHeight={{
                        base: '40px',
                        md: aerodromeId === id ? '40px' : '36px',
                    }}
                    my={{
                        base: '0px',
                        md: aerodromeId === id ? '-2px' : '0px',
                    }}
                    opacity={{
                        base: '1',
                        md: aerodromeId && aerodromeId !== id ? '0.5' : '1',
                    }}
                    transition="all ease 250ms"
                    pl={{
                        base: '10px',
                        md: '8px',
                    }}
                    pr="18px"
                    borderRadius="25px"
                    bg={aerodromeId === id ? 'white' : 'rgba(255,255,255,0.75)'}
                    boxShadow={
                        aerodromeId === id
                            ? '0px 8px 25px -7px rgba(0,15,35,0.35)'
                            : '0px 5px 20px -6px rgba(0,15,35,0.2)'
                    }
                    border={aerodromeId === id && '1px solid'}
                    borderColor="gray.100"
                    transform={aerodromeId === id && 'scale(1.03)'}
                    color={aerodromeId === id ? 'light.200' : 'light.100'}
                    _hover={
                        isDesktop &&
                        aerodromeId !== id && {
                            opacity: '1',
                            cursor: 'pointer',
                            background: 'rgba(255,255,255,1)',
                            boxShadow: '0px 12px 30px -12px rgba(0,15,35,0.7)',
                            transform:
                                'translateY(-1px) translateX(0px) scale(1.01)',
                        }
                    }
                    zIndex={`calc(1000 - ${i})`}
                    gap="10px"
                >
                    <Image
                        w="36px"
                        src={`data:image/svg+xml;base64,${buildAerodromeIcon(
                            type,
                            flightrule,
                            rwyazimuth,
                            notam
                        )}`}
                        alt={designator ? designator : ''}
                    />

                    <Text
                        fontSize={{
                            base: '0.85rem',
                            md: '0.9rem',
                        }}
                        fontWeight="600"
                        width="100%"
                        transition="all ease 250ms"
                    >
                        {name}
                    </Text>
                    <Text
                        fontSize={{
                            base: '0.85rem',
                            md: '0.9rem',
                        }}
                        fontWeight={aerodromeId === id ? '400' : '300'}
                        minWidth="60px"
                        textAlign="right"
                        transition="all ease 250ms"
                    >
                        {designator}
                    </Text>
                </Flex>
            )
        })

    const scrollBarHide = useBreakpointValue({
        base: 'scroll',
        md: 'leave',
    })

    // console.log('dataStatus: ', dataStatus)
    // console.log('status: ', status)
    // console.log('Aerodromes data: ', data)
    // console.log('Aerodromes List: ', aerodromes)
    // console.log('aerodromeOptions: ', aerodromeOptions)
    // console.log('Clicked Features: ', clickedFeatures)

    if (aerodromes) {
        return (
            <>
                <LightMode>
                    <Modal
                        isOpen={isOpen}
                        closeOnOverlayClick={false}
                        isCentered
                        size="full"
                        backgroundColor="transparent"
                        scrollable
                        variant="imageLooper"
                        zIndex="888"
                        trapFocus={false}
                        blockScrollOnMount={false}
                    >
                        <ModalContent
                            backgroundSize="100% auto"
                            backgroundRepeat="no-repeat"
                            boxShadow={{
                                base: 'none',
                                lg: 'inset 9px 0px 15px -15px rgba(0,0,0,0.2)',
                            }}
                            overflow={{
                                base: 'clip',
                                lg: undefined,
                            }}
                        >
                            <ModalHeader
                                backgroundColor="light.10"
                                borderBottom="1px solid"
                                borderBottomColor="#E2E8F0"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                                backgroundBlendMode="overlay"
                                h={{
                                    base:
                                        // showErrorToggle ? '100px' :
                                        '70px',
                                    md: '70px',
                                }}
                                minHeight="70px"
                                maxHeight="70px"
                                w="100%"
                                paddingInlineStart={4}
                                paddingInlineEnd={4}
                                flex="1"
                                boxShadow={{
                                    base: 'none',
                                    md: 'inset 9px 0px 15px -15px rgba(0,0,0,0.05)',
                                }}
                                zIndex="1000"
                            >
                                <Flex
                                    h="100%"
                                    w="100%"
                                    direction={{
                                        base: 'column',
                                        md: 'row',
                                    }}
                                >
                                    <Flex
                                        // {...infoSwipe}
                                        w="100%"
                                        h="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Flex
                                            // {...infoSwipe}
                                            direction="row"
                                            justifyContent={{
                                                base: 'center',
                                                md: 'flex-start',
                                            }}
                                            alignItems="center"
                                            width="100%"
                                            maxWidth={{
                                                base: '100%',
                                                ml: '260px',
                                            }}
                                            flexGrow="1"
                                        >
                                            <Icon
                                                boxSize={{
                                                    base: '28px',
                                                    md: '30px',
                                                }}
                                                color="light.100"
                                                as={AerodromeIcon}
                                                ml={{
                                                    base: '40px',
                                                    md: '4px',
                                                }}
                                                transition="all ease 300ms"
                                            />
                                            <Box
                                                pl={{
                                                    base: '5px',
                                                    md: '10px',
                                                }}
                                            >
                                                <Text // HEADER TITLE
                                                    textAlign={{
                                                        base: 'center',
                                                        md: 'left',
                                                    }}
                                                    alignItems={{
                                                        base: 'center',
                                                        md: 'flex-start',
                                                    }}
                                                    color="light.100"
                                                    fontSize={{
                                                        base: '1rem',
                                                        md: '1.2rem',
                                                    }}
                                                    fontWeight="600"
                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                    lineHeight="1.3"
                                                    marginTop="-2px"
                                                    ml={{
                                                        base: '2px',
                                                        md: '5px',
                                                    }}
                                                    pt="2px"
                                                    whiteSpace="pre"
                                                >
                                                    Aerodromes
                                                </Text>
                                            </Box>
                                        </Flex>

                                        <Flex
                                            gap="10px"
                                            minWidth={{
                                                base: '40px',
                                                ml: '500px',
                                                lg: '600px',
                                            }}
                                            transition="all ease 300ms"
                                            justifyContent="flex-end"
                                        >
                                            <Button // SHOW DETAILS (SMALLER DEVICES ONLY)
                                                display={{
                                                    base: 'inline-flex',
                                                    lg: 'none',
                                                }}
                                                background="none"
                                                color="light.100"
                                                opacity={
                                                    showFilters ? '1' : '0.8'
                                                }
                                                fontSize="10px"
                                                fontWeight="600"
                                                height="40px"
                                                width="40px"
                                                alignItems="center"
                                                justifyContent="center"
                                                paddingLeft="2px"
                                                paddingRight="2px"
                                                borderRadius="10px"
                                                onClick={() => {
                                                    setShowFilters(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }}
                                                _focus={{
                                                    opacity: '1 !important',
                                                }}
                                                _active={{
                                                    background: 'none',
                                                }}
                                                _hover={{
                                                    opacity: '1 !important',
                                                }}
                                            >
                                                <Flex
                                                    // borderColor="#DFE6EE"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    // border="1px solid #DFE6EE"
                                                    borderRadius="10px"
                                                    color="light.100"
                                                    variant="outline"
                                                    fontSize="1rem"
                                                    height="30px"
                                                    width="34px"
                                                    style={{
                                                        minWidth: '34px',
                                                    }}
                                                    _hover={{
                                                        opacity: '1',
                                                        background: 'light.20',
                                                    }}
                                                    _active={{
                                                        background: {
                                                            base: 'none',
                                                            lg: 'light.20',
                                                        },
                                                        boxShadow: {
                                                            base: 'none',
                                                            lg: 'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                                        },
                                                    }}
                                                >
                                                    <Icon
                                                        fontSize={{
                                                            base: '20px',
                                                            ml: '1.2rem',
                                                        }}
                                                        transition="all 200ms"
                                                        as={
                                                            showFilters
                                                                ? TfiMoreAlt
                                                                : TfiMore
                                                        }
                                                    />
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </ModalHeader>

                            <ModalBody
                                p={0}
                                height={{
                                    base:
                                        isMobileOnly && isIOS
                                            ? 'calc(100% - 145px)'
                                            : 'calc(100% - 130px)',
                                    lg: '100%',
                                }}
                                background="#edf1f7"
                                backgroundSize="100% auto"
                                backgroundRepeat="no-repeat"
                            >
                                <Flex
                                    as="section"
                                    p="0px"
                                    overflow="hidden"
                                    flexDirection="column"
                                    height="100%"
                                    minHeight="100%"
                                    maxHeight={{
                                        base: 'calc(100% - 132px)',
                                        md: 'calc(100vh - 200px)',
                                    }}
                                >
                                    <Flex
                                        flexDirection={{
                                            base: 'column',
                                            md: 'row',
                                        }}
                                        justifyContent="center"
                                        alignItems={{
                                            base: 'center',
                                            md: 'flex-start',
                                        }}
                                        width="100%"
                                        height="100%"
                                        bg="light.35"
                                    >
                                        <Flex
                                            flexDirection={{
                                                base: 'column',
                                                md: 'column',
                                            }}
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            width="100%"
                                            borderRight={{
                                                base: 'none',
                                                md: '1px solid #e5e7e9',
                                            }}
                                            maxWidth={{
                                                base: '100%',
                                                md: '380px',
                                                ml: '450px',
                                            }}
                                            height="100%"
                                            minHeight={{
                                                base: 'auto',
                                                md: '100%',
                                            }}
                                            maxHeight={{
                                                base: 'unset',
                                                md: '100%',
                                            }}
                                            gap="0px"
                                            bg="white"
                                        >
                                            <Flex
                                                mt={{
                                                    base: showFilters
                                                        ? '0px'
                                                        : '-64px',
                                                    md: '0px',
                                                }}
                                                height={{
                                                    base: '62px',
                                                    md: '60px',
                                                }}
                                                minHeight={{
                                                    base: '62px',
                                                    md: '60px',
                                                }}
                                                width="100%"
                                                padding={{
                                                    base: '10px',
                                                    md: '15px',
                                                }}
                                                justifyContent="center"
                                                flexDirection="column"
                                                transition="all ease 300ms"
                                                bg={{
                                                    base: 'light.55',
                                                    md: 'white',
                                                }}
                                                backdropFilter="blur(20px)"
                                                clipPath="inset(0px 0px -1500px)"
                                                boxShadow={{
                                                    base: 'rgba(0, 0, 0, 0.2) 0px 5px 30px -5px',
                                                    md: 'rgba(0, 0, 0, 0.05) 0px 5px 30px -5px',
                                                }}
                                                zIndex="3"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="space-around"
                                                    px={{
                                                        base: '15px',
                                                        md: '10px',
                                                    }}
                                                    width="100%"
                                                    opacity={{
                                                        base: showFilters
                                                            ? '1'
                                                            : '0',
                                                        md: '1',
                                                    }}
                                                    transition="opacity ease 300ms"
                                                >
                                                    {/* Sort Aerodromes by North-South or Alphabetically */}
                                                    <FilterButton
                                                        sortState={
                                                            sortAerodromesByName
                                                        }
                                                        toggleState={
                                                            toggleAerodromeState
                                                        }
                                                        itemKey={'sorting'}
                                                        itemFeat={'name'}
                                                        iconA={
                                                            SortNorthToSouthIcon
                                                        }
                                                        iconB={
                                                            SortAlphabeticalIcon
                                                        }
                                                        tooltipA={`Sort Aerodromes North to South`}
                                                        tooltipB={`Sort Aerodromes Alphabetically`}
                                                        optionSelected={
                                                            optionSelected
                                                        }
                                                    />

                                                    {/* Sort Aerodromes by Type*/}
                                                    <FilterButton
                                                        sortState={
                                                            sortAerodromesByType
                                                        }
                                                        toggleState={
                                                            toggleAerodromeState
                                                        }
                                                        itemKey={'sorting'}
                                                        itemFeat={'type'}
                                                        iconA={SortTypeIcon}
                                                        tooltipA={`Disable sort by Type`}
                                                        tooltipB={`Sort Aerodromes by Type`}
                                                        optionSelected={
                                                            optionSelected
                                                        }
                                                    />

                                                    {/* Sort Aerodromes by FlightRule */}
                                                    <FilterButton
                                                        sortState={
                                                            sortAerodromesByFlightRule
                                                        }
                                                        toggleState={
                                                            toggleAerodromeState
                                                        }
                                                        itemKey={'sorting'}
                                                        itemFeat={'flightRule'}
                                                        iconA={SortIfrVfrIcon}
                                                        tooltipA={`Disable sort by Flight Rule`}
                                                        tooltipB={`Sort Aerodromes by Flight Rule`}
                                                        optionSelected={
                                                            optionSelected
                                                        }
                                                    />

                                                    {/* Filter Aerodromes */}
                                                    <FilterButton
                                                        sortState={
                                                            aerodromesActive
                                                        }
                                                        toggleState={
                                                            toggleAerodromeState
                                                        }
                                                        itemKey={'filtering'}
                                                        itemFeat={'aerodromes'}
                                                        iconA={AerodromeIcon}
                                                        iconB={false}
                                                        tooltipA={`Hide Aerodromes`}
                                                        tooltipB={`Show Aerodromes`}
                                                        optionSelected={
                                                            optionSelected
                                                        }
                                                    />

                                                    {/* Filter Heliports */}
                                                    <FilterButton
                                                        sortState={
                                                            heliportsActive
                                                        }
                                                        toggleState={
                                                            toggleAerodromeState
                                                        }
                                                        itemKey={'filtering'}
                                                        itemFeat={'heliports'}
                                                        iconA={HeliportIcon}
                                                        iconB={false}
                                                        tooltipA={`Hide Heliports`}
                                                        tooltipB={`Show Heliports`}
                                                        optionSelected={
                                                            optionSelected
                                                        }
                                                    />

                                                    {/* Filter Waterdromes */}
                                                    <FilterButton
                                                        sortState={
                                                            waterdromesActive
                                                        }
                                                        toggleState={
                                                            toggleAerodromeState
                                                        }
                                                        itemKey={'filtering'}
                                                        itemFeat={'waterdromes'}
                                                        iconA={WaterdromeIcon}
                                                        iconB={false}
                                                        tooltipA={`Hide Waterdromes`}
                                                        tooltipB={`Show Waterdromes`}
                                                        optionSelected={
                                                            optionSelected
                                                        }
                                                    />
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                flexDirection="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                width="100%"
                                                height="100%"
                                                minHeight={{
                                                    base: showFilters
                                                        ? 'calc(100% - 132px)'
                                                        : 'calc(100% - 68px)',
                                                    md: 'calc(100% - 132px)',
                                                }}
                                                maxHeight={{
                                                    base: showFilters
                                                        ? 'calc(100% - 132px)'
                                                        : 'calc(100% - 68px)',
                                                    md: 'calc(100% - 132px)',
                                                }}
                                                bg={{
                                                    base: 'light.35',
                                                    md:
                                                        status === 'loading'
                                                            ? 'light.30'
                                                            : 'light.35',
                                                }}
                                                transition="all ease 300ms"
                                                zIndex="1"
                                            >
                                                <Flex
                                                    width="100%"
                                                    borderTop="1px solid #eaebec"
                                                    flexDirection="column"
                                                    overflowX="hidden"
                                                    overflowY="auto"
                                                    h="100%"
                                                    transition="all ease 300ms"
                                                    className="settingsScrollVertical"
                                                >
                                                    <OverlayScrollbarsComponent
                                                        options={{
                                                            className:
                                                                'os-theme-light',
                                                            scrollbars: {
                                                                visibility:
                                                                    'auto',
                                                                autoHide:
                                                                    scrollBarHide,
                                                                autoHideDelay: 800,
                                                                dragScrolling: true,
                                                                clickScrolling: true,
                                                                touchSupport: true,
                                                            },
                                                            overflow: {
                                                                x: 'hidden',
                                                                y: 'scroll',
                                                            },
                                                        }}
                                                        style={{
                                                            height: '100%',
                                                            minHeight: '100%',
                                                            overflowX:
                                                                'hidden !important',
                                                        }}
                                                        defer
                                                    >
                                                        <Flex
                                                            transition="all ease 300ms"
                                                            width="100%"
                                                            pt="20px"
                                                            pb="30px"
                                                            px={{
                                                                base: '15px',
                                                                md: '20px',
                                                            }}
                                                            gap="10px"
                                                            flexDirection="column"
                                                            justifyContent="flex-start"
                                                        >
                                                            {status ===
                                                                'loading' && (
                                                                <Center
                                                                    w="100%"
                                                                    pt="65px"
                                                                >
                                                                    <Spinner
                                                                        color={
                                                                            'light.200'
                                                                        }
                                                                        emptyColor="rgba(255,255,255,0.3)"
                                                                        thickness="3px"
                                                                        speed="0.45s"
                                                                        boxSize="40px"
                                                                    />
                                                                </Center>
                                                            )}
                                                            {status !==
                                                                'loading' &&
                                                                status ===
                                                                    'error' && (
                                                                    <FullWidthError
                                                                        message={
                                                                            'Error fetching Aerodromes data'
                                                                        }
                                                                    />
                                                                )}
                                                            {status ===
                                                                'success' &&
                                                                dataStatus &&
                                                                (aerodromeOptions &&
                                                                aerodromeOptions.length >
                                                                    0 ? (
                                                                    aerodromeOptions
                                                                ) : (
                                                                    <Text
                                                                        textAlign="center"
                                                                        width="100%"
                                                                        fontSize="0.8rem"
                                                                        color="gray.500"
                                                                        px="10px"
                                                                        py="10px"
                                                                    >
                                                                        {optionSelected ===
                                                                        3
                                                                            ? 'Aerodromes'
                                                                            : optionSelected ===
                                                                              4
                                                                            ? 'Heliports'
                                                                            : optionSelected ===
                                                                              5
                                                                            ? 'Waterdromes'
                                                                            : 'All aerodromes'}{' '}
                                                                        have
                                                                        been
                                                                        excluded.
                                                                        <br />
                                                                        Please
                                                                        adjust
                                                                        your
                                                                        filters.
                                                                    </Text>
                                                                ))}
                                                        </Flex>
                                                    </OverlayScrollbarsComponent>
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                flexDirection="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                gap="10px"
                                                width="100%"
                                                height="70px"
                                                minHeight="70px"
                                                borderTop={{
                                                    base: '1px solid #eaebec',
                                                    md: '1px solid #eaebec',
                                                }}
                                                bg={{
                                                    base: 'light.55',
                                                    md: 'white',
                                                }}
                                                backdropFilter="blur(20px)"
                                                boxShadow={{
                                                    base: 'rgba(0, 0, 0, 0.15) 0px -5px 30px -5px',
                                                    md: 'rgba(0, 0, 0, 0.05) 0px -5px 30px -5px',
                                                }}
                                                clipPath="inset(-100px 0px 0px)"
                                                zIndex="2"
                                            >
                                                <SelectTypeButton
                                                    optionSelected={
                                                        optionSelected
                                                    }
                                                    setOptionSelected={
                                                        setOptionSelected
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                        <Flex
                                            px="5px"
                                            pt="50px"
                                            pb="100px"
                                            height="100%"
                                            width="100%"
                                            display={{
                                                base: 'none',
                                                md: 'flex',
                                            }}
                                            color="gray.400"
                                            justifyContent="center"
                                            alignItems="center"
                                            fontSize="0.9rem"
                                            opacity={
                                                !(
                                                    clickedFeatures &&
                                                    clickedFeatures.length > 0
                                                )
                                                    ? '1'
                                                    : '0'
                                            }
                                            transition="opacity ease 300ms"
                                        >
                                            Select an Aerodrome
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </LightMode>
            </>
        )
    }

    return null
}
