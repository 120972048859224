import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

import { useSwipeable } from 'react-swipeable'
import WindBarbsCollectionIcon from '../../../../../icons/menu-icons/WindBarbsCollectionIcon'

export default function RainForecastLegend(props) {
    const { setDbzShow } = props

    const smallScreen = window.innerWidth < 430
    const legendSwipe = useSwipeable({
        onSwipedLeft: () => {
            setDbzShow(true)
        },
        onSwipedRight: () => {
            setDbzShow(false)
        },
    })

    // MOBILE RADAR INFO SHOW BY SWIPE DOWN
    return (
        <>
            <Flex
                {...legendSwipe}
                className="octa-scale"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: 'column-reverse', md: 'row' }}
            >
                <Flex
                    className="octas"
                    justifyContent="center"
                    alignItems="center"
                    ml={{ base: '0px', md: '15px' }}
                    mt={{ base: '12px', md: '0px' }}
                    width={{ base: '100%', md: 'auto' }}
                    height={{ base: 'auto', md: '100%' }}
                    flexDirection={{ base: 'column', md: 'row' }}
                    py={{ base: '0px', md: '5px' }}
                >
                    {/* *** COLOURED BOXES *** */}
                    <Flex
                        className="coloured-boxes"
                        transition="all ease 300ms"
                        width={{ base: '100%', md: '8px' }}
                        height={{ base: '6px', md: '100%' }}
                        justifyContent="center"
                        alignItems="center"
                        borderRadius={{ base: '5px', md: '3px' }}
                        overflow="hidden"
                        flexDirection={{ base: 'row-reverse', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(253, 72, 2)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                            borderTopLeftRadius={{ base: '0px', md: '5px' }}
                            borderTopRightRadius={{ base: '5px', md: '5px' }}
                            borderBottomLeftRadius={{ base: '0px', md: '0px' }}
                            borderBottomRightRadius={{ base: '5px', md: '0px' }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(252, 125, 107)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(252, 204, 200)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(188, 63, 0)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(221, 108, 0)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(255, 168, 56)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(255, 249, 5)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(255, 253, 146)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(255, 255, 175)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(86, 170, 0)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(105, 210, 0)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(160, 252, 70)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(107, 218, 255)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(198, 240, 255)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderBottom={{ md: 'none' }}
                            borderLeft={{
                                base: 'none',
                                md: '1px solid #acb7c4',
                            }}
                        ></Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            background="rgb(254, 254, 254)"
                            border="1px solid #acb7c4"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={{ base: '10px', md: '12px' }}
                            color="light.100"
                            borderTopLeftRadius={{ base: '5px', md: '0px' }}
                            borderTopRightRadius={{ base: '0px', md: '0px' }}
                            borderBottomLeftRadius={{ base: '5px', md: '5px' }}
                            borderBottomRightRadius={{ base: '0px', md: '5px' }}
                        ></Flex>
                    </Flex>
                    <Flex // BOTTOM/RIGHT TEXT
                        transition="all ease 300ms"
                        width={{ base: 'calc(100% + 10px)', md: '20px' }}
                        height={{ base: '15px', md: '100%' }}
                        justifyContent="space-around"
                        alignItems={{ base: 'center', md: 'flex-start' }}
                        pt={{ base: '3px', md: '0px' }}
                        pl={{ base: '5px', md: '10px' }}
                        flexDirection={{ base: 'row-reverse', md: 'column' }}
                        fontSize={{ base: '10px', md: '10px' }}
                        lineHeight={{ base: '1', md: '0.9' }}
                        color="light.100"
                    >
                        <Text
                            ml={{ base: '-3px', md: '0px' }}
                            pr={{ base: '3px', md: '0px' }}
                        >
                            {'50'}
                        </Text>
                        <Text>40</Text>
                        <Text>30</Text>
                        <Text>25</Text>
                        <Text>20</Text>
                        <Text>15</Text>
                        <Text>10</Text>
                        <Text>7</Text>
                        <Text>5</Text>
                        <Text>3</Text>
                        <Text>2</Text>
                        <Text>1</Text>
                        <Text>.5</Text>
                        <Text>.2</Text>
                        <Text>.1</Text>
                    </Flex>
                </Flex>

                <Flex
                    className="barbs"
                    justifyContent="center"
                    alignItems="center"
                    width={{ base: '100%', md: 'auto' }}
                    height={{ base: 'auto', md: '100%' }}
                    flexDirection={{ base: 'column', md: 'row' }}
                >
                    {/* *** WIND BARBS *** */}
                    <Flex
                        className="wind-barbs"
                        ml={{ base: '0px', md: '60px' }}
                        transition="all ease 300ms"
                        width={{ base: '100%', md: '30px' }}
                        height={{ base: '15px', md: '100%' }}
                        justifyContent="center"
                        alignItems="center"
                        borderRadius={{ base: '5px', md: '3px' }}
                        overflow="hidden"
                        flexDirection={{ base: 'row-reverse', md: 'column' }}
                    >
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            pr={{ base: '1px', md: '5px' }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid50={true}
                                wbid45={true}
                                wbid40={true}
                                wbid30={true}
                                className="75kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid50={true}
                                className="50kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid45={true}
                                wbid40={true}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="45kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid40={true}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="40kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid35={true}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="35kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid30={true}
                                wbid20={true}
                                wbid10={true}
                                className="30kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid25={true}
                                wbid20={true}
                                wbid10={true}
                                className="25kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid10={true}
                                wbid20={true}
                                className="20kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                wbid10={true}
                                wbid5={true}
                                className="15kt"
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                className="10kt"
                                wbid10={true}
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                        <Flex
                            width="100%"
                            height="100%"
                            p="5px"
                            pl={{ base: '1px', md: '5px' }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <WindBarbsCollectionIcon
                                boxSize={{ base: smallScreen ? 3 : 4, md: 5 }}
                                className="5kt"
                                wbid5={true}
                                width="100%"
                                height="100%"
                            />
                        </Flex>
                    </Flex>

                    <Flex // BOTTOM/RIGHT TEXT
                        transition="all ease 300ms"
                        width={{ base: 'calc(100% + 10px)', md: '30px' }}
                        height={{ base: '15px', md: '100%' }}
                        justifyContent="space-around"
                        alignItems={{ base: 'center', md: 'flex-start' }}
                        pl={{ base: '5px', md: '5px' }}
                        pr={{ base: '0px', md: '0px' }}
                        flexDirection={{ base: 'row-reverse', md: 'column' }}
                        fontSize={{ base: '9px', md: '10px' }}
                        lineHeight={{ base: '1', md: '0.9' }}
                        color="light.100"
                    >
                        <Text>75</Text>
                        <Text>50</Text>
                        <Text>45</Text>
                        <Text>40</Text>
                        <Text>35</Text>
                        <Text>30</Text>
                        <Text>25</Text>
                        <Text>20</Text>
                        <Text>15</Text>
                        <Text>10</Text>
                        <Text>5</Text>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
