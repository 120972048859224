import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function FilterIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 5}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M15.3744 20.753C15.4194 21.0862 15.307 21.4417 15.0482 21.675C14.6096 22.1083 13.9009 22.1083 13.4623 21.675L8.95175 17.2201C8.69305 16.9646 8.58057 16.6202 8.62556 16.298V10.6099L3.2377 3.79975C2.85526 3.32204 2.94525 2.62214 3.42892 2.24441C3.64263 2.08888 3.87884 2 4.1263 2H19.8737C20.1212 2 20.3574 2.08888 20.5711 2.24441C21.0548 2.62214 21.1447 3.32204 20.7623 3.79975L15.3744 10.6099V20.753ZM6.42092 4.22191L10.8752 9.84336V15.9758L13.1248 18.1978V9.83225L17.5791 4.22191H6.42092Z"
                fill="currentColor"
            />
        </Icon>
    )
}
