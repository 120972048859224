import React from 'react'
import { useBreakpointValue } from '@chakra-ui/react'

export default function VisibilityImage(props) {
    const { value, unit, imageColor, focusImageColor, multipleCloudLayers } =
        props

    const indicatorThickness = useBreakpointValue({
        base: 'thin',
        md: 'wide',
    })

    const valueCorrected =
        value && value === '//' ? 0 : unit === 'KM' ? 9999 : value
    const valueEquation = valueCorrected / 34
    // const valueEquation = (valueCorrected / 2) * 60

    const scaleMove =
        valueEquation < 12
            ? 7
            : valueEquation >= 12 && valueEquation < 24
            ? 12
            : valueEquation >= 24 && valueEquation < 30
            ? 24
            : valueEquation >= 30 && valueEquation < 36
            ? 30
            : valueEquation >= 36 && valueEquation < 42
            ? 36
            : valueEquation >= 42 && valueEquation < 48
            ? 42
            : valueEquation >= 48 && valueEquation < 54
            ? 48
            : valueEquation >= 54 && valueEquation < 60
            ? 54
            : valueEquation >= 60 && valueEquation < 66
            ? 60
            : valueEquation >= 66 && valueEquation < 72
            ? 66
            : valueEquation >= 72 && valueEquation < 78
            ? 72
            : valueEquation >= 78 && valueEquation < 84
            ? 78
            : valueEquation >= 84 && valueEquation < 90
            ? 84
            : valueEquation >= 90 && valueEquation < 96
            ? 90
            : valueEquation >= 96 && valueEquation < 102
            ? 96
            : valueEquation >= 102 && valueEquation < 108
            ? 102
            : valueEquation >= 108 && valueEquation < 114
            ? 108
            : valueEquation >= 114 && valueEquation < 120
            ? 114
            : valueEquation >= 120 && valueEquation < 126
            ? 120
            : valueEquation >= 126 && valueEquation < 132
            ? 126
            : valueEquation >= 132 && valueEquation < 138
            ? 132
            : valueEquation >= 138 && valueEquation < 144
            ? 138
            : valueEquation >= 144 && valueEquation < 150
            ? 144
            : valueEquation >= 150 && valueEquation < 156
            ? 150
            : valueEquation >= 156 && valueEquation < 162
            ? 156
            : valueEquation >= 162 && valueEquation < 168
            ? 162
            : valueEquation >= 168 && valueEquation < 174
            ? 168
            : valueEquation >= 174 && valueEquation < 180
            ? 174
            : valueEquation >= 180 && valueEquation < 186
            ? 180
            : valueEquation >= 186 && valueEquation < 192
            ? 186
            : valueEquation >= 192 && valueEquation < 198
            ? 192
            : valueEquation >= 198 && valueEquation < 204
            ? 198
            : valueEquation >= 204 && valueEquation < 210
            ? 204
            : valueEquation >= 210 && valueEquation < 216
            ? 210
            : valueEquation >= 216 && valueEquation < 222
            ? 216
            : valueEquation >= 222 && valueEquation < 228
            ? 222
            : valueEquation >= 228 && valueEquation < 234
            ? 228
            : valueEquation >= 234 && valueEquation < 240
            ? 234
            : valueEquation >= 240 && valueEquation < 246
            ? 240
            : valueEquation >= 246 && valueEquation < 252
            ? 246
            : valueEquation >= 252 && valueEquation < 258
            ? 252
            : valueEquation >= 258 && valueEquation < 264
            ? 258
            : valueEquation >= 264 && valueEquation < 270
            ? 264
            : valueEquation >= 270 && valueEquation < 276
            ? 270
            : valueEquation >= 276 && valueEquation < 282
            ? 276
            : valueEquation >= 282 && valueEquation < 288
            ? 282
            : valueEquation >= 288 && valueEquation < 294
            ? 288
            : valueEquation >= 294 && valueEquation < 300
            ? 294
            : valueEquation >= 300
            ? 294
            : 0

    // console.log(valueCorrected, 'valueCorrected')
    // console.log(scaleMove, 'scaleMove')

    return (
        <>
            <svg
                width="100%"
                height="auto"
                viewBox="0 0 297 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g // Small Ticks
                    opacity="0.3"
                >
                    <rect
                        display={scaleMove >= 6 ? 'none' : 'initial'}
                        x="0.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 12 ? 'none' : 'initial'}
                        x="6.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 18 ? 'none' : 'initial'}
                        x="12.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 24 ? 'none' : 'initial'}
                        x="18.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 30 ? 'none' : 'initial'}
                        x="24.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 36 ? 'none' : 'initial'}
                        x="30.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 42 ? 'none' : 'initial'}
                        x="36.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 48 ? 'none' : 'initial'}
                        x="42.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 54 ? 'none' : 'initial'}
                        x="48.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 60 ? 'none' : 'initial'}
                        x="54.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 66 ? 'none' : 'initial'}
                        x="60.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 72 ? 'none' : 'initial'}
                        x="66.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 78 ? 'none' : 'initial'}
                        x="72.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 84 ? 'none' : 'initial'}
                        x="78.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 90 ? 'none' : 'initial'}
                        x="84.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 96 ? 'none' : 'initial'}
                        x="90.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 102 ? 'none' : 'initial'}
                        x="96.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 108 ? 'none' : 'initial'}
                        x="102.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 114 ? 'none' : 'initial'}
                        x="108.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 120 ? 'none' : 'initial'}
                        x="114.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 126 ? 'none' : 'initial'}
                        x="120.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 132 ? 'none' : 'initial'}
                        x="126.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 138 ? 'none' : 'initial'}
                        x="132.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 144 ? 'none' : 'initial'}
                        x="138.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 150 ? 'none' : 'initial'}
                        x="144.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 156 ? 'none' : 'initial'}
                        x="150.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 162 ? 'none' : 'initial'}
                        x="156.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 168 ? 'none' : 'initial'}
                        x="162.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 174 ? 'none' : 'initial'}
                        x="168.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 180 ? 'none' : 'initial'}
                        x="174.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 186 ? 'none' : 'initial'}
                        x="180.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 192 ? 'none' : 'initial'}
                        x="186.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 198 ? 'none' : 'initial'}
                        x="192.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 204 ? 'none' : 'initial'}
                        x="198.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 210 ? 'none' : 'initial'}
                        x="204.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 216 ? 'none' : 'initial'}
                        x="210.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 222 ? 'none' : 'initial'}
                        x="216.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 228 ? 'none' : 'initial'}
                        x="222.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 234 ? 'none' : 'initial'}
                        x="228.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 240 ? 'none' : 'initial'}
                        x="234.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 246 ? 'none' : 'initial'}
                        x="240.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 252 ? 'none' : 'initial'}
                        x="246.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 258 ? 'none' : 'initial'}
                        x="252.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 264 ? 'none' : 'initial'}
                        x="258.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 270 ? 'none' : 'initial'}
                        x="264.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 276 ? 'none' : 'initial'}
                        x="270.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 282 ? 'none' : 'initial'}
                        x="276.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 288 ? 'none' : 'initial'}
                        x="282.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 294 ? 'none' : 'initial'}
                        x="288.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove >= 300 ? 'none' : 'initial'}
                        x="294.25"
                        y="7"
                        width="1.5"
                        height="6"
                        rx="0.75"
                        fill={imageColor}
                    />
                </g>
                <g // Large Ticks
                    opacity="0.6"
                >
                    <rect
                        display={scaleMove < 6 ? 'none' : 'initial'}
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 12 ? 'none' : 'initial'}
                        x="6"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 18 ? 'none' : 'initial'}
                        x="12"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 24 ? 'none' : 'initial'}
                        x="18"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 30 ? 'none' : 'initial'}
                        x="24"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 36 ? 'none' : 'initial'}
                        x="30"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 42 ? 'none' : 'initial'}
                        x="36"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 48 ? 'none' : 'initial'}
                        x="42"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 54 ? 'none' : 'initial'}
                        x="48"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 60 ? 'none' : 'initial'}
                        x="54"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 66 ? 'none' : 'initial'}
                        x="60"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 72 ? 'none' : 'initial'}
                        x="66"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 78 ? 'none' : 'initial'}
                        x="72"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 84 ? 'none' : 'initial'}
                        x="78"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 90 ? 'none' : 'initial'}
                        x="84"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 96 ? 'none' : 'initial'}
                        x="90"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 102 ? 'none' : 'initial'}
                        x="96"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 108 ? 'none' : 'initial'}
                        x="102"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 114 ? 'none' : 'initial'}
                        x="108"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 120 ? 'none' : 'initial'}
                        x="114"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 126 ? 'none' : 'initial'}
                        x="120"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 132 ? 'none' : 'initial'}
                        x="126"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 138 ? 'none' : 'initial'}
                        x="132"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 144 ? 'none' : 'initial'}
                        x="138"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 150 ? 'none' : 'initial'}
                        x="144"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 156 ? 'none' : 'initial'}
                        x="150"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 162 ? 'none' : 'initial'}
                        x="156"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 168 ? 'none' : 'initial'}
                        x="162"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 174 ? 'none' : 'initial'}
                        x="168"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 180 ? 'none' : 'initial'}
                        x="174"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 186 ? 'none' : 'initial'}
                        x="180"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 192 ? 'none' : 'initial'}
                        x="186"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 198 ? 'none' : 'initial'}
                        x="192"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 204 ? 'none' : 'initial'}
                        x="198"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 210 ? 'none' : 'initial'}
                        x="204"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 216 ? 'none' : 'initial'}
                        x="210"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 222 ? 'none' : 'initial'}
                        x="216"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 228 ? 'none' : 'initial'}
                        x="222"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 234 ? 'none' : 'initial'}
                        x="228"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 240 ? 'none' : 'initial'}
                        x="234"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 246 ? 'none' : 'initial'}
                        x="240"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 252 ? 'none' : 'initial'}
                        x="246"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 258 ? 'none' : 'initial'}
                        x="252"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 264 ? 'none' : 'initial'}
                        x="258"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 270 ? 'none' : 'initial'}
                        x="264"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 276 ? 'none' : 'initial'}
                        x="270"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 282 ? 'none' : 'initial'}
                        x="276"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 288 ? 'none' : 'initial'}
                        x="282"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 294 ? 'none' : 'initial'}
                        x="288"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                    <rect
                        display={scaleMove < 300 ? 'none' : 'initial'}
                        x="294"
                        y="3"
                        width="2"
                        height="14"
                        rx="1"
                        fill={imageColor}
                    />
                </g>
                <g // Focus Ticks
                    opacity="1"
                    transform={`translate(${scaleMove - 7})`}
                >
                    <rect
                        width={
                            multipleCloudLayers && indicatorThickness === 'wide'
                                ? '4'
                                : '3'
                        }
                        height="22"
                        y="-1"
                        rx="3"
                        fill={focusImageColor}
                    />
                    <rect
                        x="6"
                        y="5"
                        width={
                            multipleCloudLayers && indicatorThickness === 'wide'
                                ? '4'
                                : '3'
                        }
                        height="10"
                        rx="2"
                        fill={focusImageColor}
                    />
                </g>
            </svg>
        </>
    )
}
