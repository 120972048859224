import React from 'react'
import { useRecoilValue } from 'recoil'
import MapBoundsLayer from './MapBounds'

import { showMapBoundsState } from '../../../globalState'

export default function AdminLayers() {
    const showMapBounds = useRecoilValue(showMapBoundsState)
    return showMapBounds && <MapBoundsLayer />
}
