import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useAdminRole } from '../../../auth/hooks'
import {
    Flex,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Text,
    Modal,
    Heading,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Icon,
    Divider,
    useDisclosure,
    LightMode,
} from '@chakra-ui/react'
import ErrorToggle from './ErrorToggle'
import ErrorText from './ErrorText'
import { isAfter, parseISO, formatDistanceToNow } from 'date-fns'
import formatDateTime from '../../../util/dateFormatter'
import {
    clickedFeaturesState,
    showErrorTogglesState,
    timeZoneState,
    mapDisabledState,
} from '../../../globalState'

import { ReactComponent as unknown } from '../../../icons/assets/unknown.svg'
import { ReactComponent as radioactive } from '../../../icons/assets/atomic-cloud.svg'
import { ReactComponent as thunderstorm } from '../../../icons/assets/thunderstorm.svg'
import { ReactComponent as thunderstormSymbol } from '../../../icons/assets/thunderstorm-symbol.svg'
import { ReactComponent as ice } from '../../../icons/assets/ice.svg'
import { ReactComponent as icing } from '../../../icons/assets/icing-severe.svg'
import { ReactComponent as wind } from '../../../icons/assets/wind.svg'
import { ReactComponent as dust } from '../../../icons/assets/dust.svg'
import { ReactComponent as turbulence } from '../../../icons/assets/turbulence-symbol-severe.svg'
import { ReactComponent as mountainWaves } from '../../../icons/assets/mountain-waves-severe.svg'
import { ReactComponent as mountainWavesSymbol } from '../../../icons/assets/mountain-waves-symbol-severe.svg'
import { ReactComponent as snow } from '../../../icons/assets/snow.svg'
import { ReactComponent as volcano } from '../../../icons/assets/vaa.svg'
import { ReactComponent as vaaSymbol } from '../../../icons/assets/vaa-symbol.svg'
import { ReactComponent as vacSymbol } from '../../../icons/assets/vac-symbol.svg'
import { ReactComponent as tropicalCyclone } from '../../../icons/assets/tropical-cyclone.svg'
import { ReactComponent as tropicalCycloneSymbol } from '../../../icons/assets/tropical-cyclone-symbol.svg'

//Set up the config to render different colour and symbol based on what layer it is.

export default function SigmetModal() {
    const [clickedFeatures, setClickedFeatures] =
        useRecoilState(clickedFeaturesState)
    const timeZone = useRecoilValue(timeZoneState)
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const [showErrors, setShowErrors] = useState(false)
    const [outOfDateError, setOutOfDateError] = useState(false)
    const isAdmin = useAdminRole()
    const feat = clickedFeatures[0]
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (feat.properties.validTo) {
            if (isAfter(new Date(feat.properties.validTo), new Date())) {
                setOutOfDateError(false)
            } else {
                setOutOfDateError(true)
            }
        }
    }, [feat])

    const SIGMETConfig = {
        'ISOL TS': {
            title: 'ISOLATED THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'OBSC TS': {
            title: 'OBSCURE THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'EMBD TS': {
            title: 'EMBEDDED THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'FRQ TS': {
            title: 'FREQUENT THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'SQL TS': {
            title: 'SQUALL LINE THUNDERSTORMS',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'OBSC TSGR': {
            title: 'OBSCURE THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'EMBD TSGR': {
            title: 'EMBEDDED THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'FRQ TSGR': {
            title: 'FREQUENT THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'SQL TSGR': {
            title: 'SQUALL LINE THUNDERSTORMS WITH HAIL',
            icon: thunderstormSymbol,
            watermark: thunderstorm,
            colour: '#8d39f8',
            gradient: 'linear-gradient(345deg, #b275ef 10%, #780ce3 90%)',
        },
        'SEV TURB': {
            title: 'SEVERE TURBULENCE',
            icon: turbulence,
            watermark: wind,
            colour: '#e25a5a',
            gradient: 'linear-gradient(345deg, #fd7373 10%, #c63737 90%)',
        },
        'SEV ICE': {
            title: 'SEVERE ICING',
            icon: icing,
            watermark: ice,
            colour: '#60a4d2',
            gradient: 'linear-gradient(345deg, #72cef2 10%, #1396ca 90%)',
        },
        'SEV ICE (FZRA)': {
            title: 'SEVERE ICING WITH FREEZING RAIN',
            icon: icing,
            watermark: ice,
            colour: '#60a4d2',
            gradient: 'linear-gradient(345deg, #72cef2 10%, #1396ca 90%)',
        },
        'SEV MTW': {
            title: 'MOUNTAIN WAVES',
            icon: mountainWavesSymbol,
            watermark: mountainWaves,
            colour: '#152238',
            gradient: 'linear-gradient(345deg, #395068 10%, #141e2f 90%)',
        },
        'HVY DS': {
            title: 'HEAVY DUST STORM',
            icon: dust,
            watermark: wind,
            colour: '#989898',
            gradient: 'linear-gradient(345deg, #989898 10%, #6d6d6d 90%)',
        },
        'HVY SS': {
            title: 'HEAVY SNOW STORM',
            icon: snow,
            watermark: snow,
            colour: '#60a4d2',
            gradient: 'linear-gradient(345deg, #72cef2 10%, #1396ca 90%)',
        },
        'RDOACT CLD': {
            title: 'RADIOACTIVE CLOUD',
            icon: radioactive,
            watermark: radioactive,
            colour: '#94DA00',
            gradient: 'linear-gradient(345deg, #a2ef00 10%, #5fb500 90%)',
        },
        TC: {
            title: 'TROPICAL CYCLONE',
            icon: tropicalCycloneSymbol,
            watermark: tropicalCyclone,
            colour: '#1261A0',
            gradient: 'linear-gradient(345deg, #2c7eca 10%, #175793 90%)',
        },
        'VA ERUPTION': {
            title: 'VOLCANIC ASH ERUPTION',
            icon: vacSymbol,
            watermark: volcano,
            colour: '#FF6CFF',
            gradient: 'linear-gradient(345deg, #e97cf8 10%, #b82fc3 90%)',
        },
        'VA CLD': {
            title: 'VOLCANIC ASH CLOUD',
            icon: vaaSymbol,
            watermark: volcano,
            colour: '#FF6CFF',
            gradient: 'linear-gradient(345deg, #e97cf8 10%, #b82fc3 90%)',
        },

        '': {
            title: 'UNDEFINED',
            icon: unknown,
            watermark: unknown,
            colour: '#85a0b5',
            gradient: 'linear-gradient(345deg, #2c7eca 10%, #175793 90%)',
        },

        null: {
            title: 'UNDEFINED',
            icon: unknown,
            watermark: unknown,
            colour: '#85a0b5',
            gradient: 'linear-gradient(345deg, #6d8191 10%, #4e6171 90%)',
        },
    }

    const config =
        feat?.properties?.phenomenon && SIGMETConfig[feat.properties.phenomenon]

    useEffect(() => {
        clickedFeatures && clickedFeatures.length === 1 ? onOpen() : onClose()
    }, [clickedFeatures, onOpen, onClose])

    const onCloseHandler = () => {
        setClickedFeatures([])
        onClose()
    }

    // useEffect(() => {
    //     console.log('SIGMET Data: ', feat)
    // }, [feat])

    return (
        <>
            <LightMode>
                <Modal
                    variant={mapDisabled ? 'weatherMapDisabled' : 'weather'}
                    size="lg"
                    centered
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    motionPreset="slideInBottom"
                    onHide={() => setClickedFeatures([])}
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalOverlay zIndex={mapDisabled ? '1399' : 'unset'} />
                    <ModalContent>
                        <ModalCloseButton
                            color="white"
                            mr={{ base: '0px', md: '10px' }}
                            mt={{ base: '0px', md: '8px' }}
                        />
                        <ModalHeader
                            background={
                                config?.colour ? config.colour : '#85a0b5'
                            }
                            backgroundImage={
                                config?.gradient ? config.gradient : 'none'
                            }
                        >
                            <Flex
                                flexDirection="row"
                                width="100%"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Flex
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Icon
                                        pointerEvents="none"
                                        transform="scale(2.3) translateX(-4px)"
                                        opacity="0.1"
                                        boxSize="40px"
                                        as={
                                            config?.watermark
                                                ? config.watermark
                                                : unknown
                                        }
                                        alt="!"
                                        color="#ffffff"
                                    />
                                </Flex>
                                <Flex
                                    ml="-40px"
                                    color="white"
                                    pr={8}
                                    alignItems="center"
                                >
                                    <Icon
                                        boxSize="40px"
                                        as={
                                            config?.icon ? config.icon : unknown
                                        }
                                        alt="!"
                                        color="#ffffff"
                                    />

                                    <Box pl={4}>
                                        <Heading variant="mainHeading">
                                            {config?.title
                                                ? config.title +
                                                  ' ' +
                                                  (feat?.properties?.obsfcst.startsWith(
                                                      'FCST'
                                                  )
                                                      ? feat.properties.obsfcst.replace(
                                                            /^FCST/,
                                                            'FORECAST'
                                                        )
                                                      : feat.properties.obsfcst.startsWith(
                                                            'OBS'
                                                        )
                                                      ? feat.properties.obsfcst.replace(
                                                            /^OBS/,
                                                            'OBSERVED'
                                                        )
                                                      : '')
                                                : 'Undefined'}
                                        </Heading>
                                        <Heading variant="subHeading">
                                            {feat.properties.id}
                                        </Heading>
                                    </Box>
                                </Flex>
                            </Flex>
                        </ModalHeader>

                        <ModalBody>
                            {isAdmin && showErrorToggle && (
                                <Flex
                                    w="100%"
                                    pb="15px"
                                    mt="10px"
                                    align="center"
                                    justifyContent={{
                                        base: 'flex-end',
                                        md: 'flex-end',
                                    }}
                                >
                                    <ErrorToggle
                                        showErrors={showErrors}
                                        setShowErrors={setShowErrors}
                                    />
                                </Flex>
                            )}
                            {(showErrors ||
                                feat.properties.outofdate ||
                                outOfDateError) && (
                                <Flex my="15px" justifyContent="center">
                                    <ErrorText>
                                        There has been an issue with the
                                        connection between PreFlight and
                                        MetService. This SIGMET was last
                                        validated with MetService{' '}
                                        <strong>
                                            {formatDistanceToNow(
                                                parseISO(
                                                    feat.properties.updated
                                                ),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}
                                        </strong>
                                        .<br />
                                        There is a high likelihood that the
                                        latest SIGMET is not available in
                                        PreFlight. Check back again soon, or
                                        alternatively visit{' '}
                                        <a
                                            style={{
                                                fontWeight: '500',
                                                textDecoration: 'underline',
                                            }}
                                            href="https://ifis.airways.co.nz/"
                                        >
                                            IFIS
                                        </a>
                                        .
                                    </ErrorText>
                                </Flex>
                            )}
                            <Table size="sm" variant="weather" marginTop="2">
                                <Tbody>
                                    {/* {feat?.properties?.phenomenon ===
                                        'VA ERUPTION' && (
                                        <Tr>
                                            <Td
                                                paddingStart="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="100px"
                                                pb="15px"
                                            >
                                                IMPORTANT:
                                            </Td>
                                            <Td width="100%" pb="15px">
                                                <Text
                                                    fontWeight={
                                                        feat?.properties
                                                            ?.phenomenon ===
                                                        'VA ERUPTION'
                                                            ? '600'
                                                            : '500'
                                                    }
                                                    whiteSpace="pre-line"
                                                    color={
                                                        feat?.properties
                                                            ?.phenomenon ===
                                                        'VA ERUPTION'
                                                            ? 'red'
                                                            : 'inherit'
                                                    }
                                                >
                                                    {feat?.properties
                                                        ?.phenomenon ===
                                                    'VA ERUPTION'
                                                        ? 'EXERCISE ONLY'
                                                        : configObsfcst.title}
                                                </Text>
                                            </Td>
                                        </Tr>
                                    )} */}
                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="100px"
                                        >
                                            From:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.validfrom &&
                                                formatDateTime(
                                                    feat.properties.validfrom,
                                                    timeZone
                                                )}
                                        </Td>
                                    </Tr>

                                    <Tr>
                                        <Td
                                            paddingStart="0"
                                            fontWeight="bold"
                                            width="auto"
                                            minWidth="100px"
                                        >
                                            To:
                                        </Td>
                                        <Td width="100%">
                                            {feat?.properties?.validto &&
                                                formatDateTime(
                                                    feat.properties.validto,
                                                    timeZone
                                                )}
                                        </Td>
                                    </Tr>
                                    {feat.properties.level ? (
                                        <Tr>
                                            <Td
                                                paddingStart="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="100px"
                                            >
                                                Height:
                                            </Td>
                                            <Td width="100%">
                                                {feat.properties.level}
                                            </Td>
                                        </Tr>
                                    ) : (
                                        <Tr>
                                            <Td
                                                paddingStart="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="100px"
                                            >
                                                Altitudes:
                                            </Td>
                                            <Td width="100%">
                                                <div
                                                    style={{
                                                        width: 'max-content',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {feat.properties
                                                        .upperlabel === 'XXX'
                                                        ? 'XXX'
                                                        : feat.properties.upperlabel.substring(
                                                              0,
                                                              2
                                                          ) === 'FL'
                                                        ? feat.properties
                                                              .upperlabel
                                                        : feat.properties
                                                              .upperlabel +
                                                          ' ft'}
                                                    <Divider
                                                        variant="altitidues"
                                                        width="80px"
                                                    />
                                                    {feat.properties
                                                        .lowerlabel === 'XXX'
                                                        ? 'XXX'
                                                        : feat.properties.lowerlabel.substring(
                                                              0,
                                                              2
                                                          ) === 'FL' ||
                                                          feat.properties
                                                              .lowerlabel ===
                                                              'SFC'
                                                        ? feat.properties
                                                              .lowerlabel
                                                        : feat.properties
                                                              .lowerlabel +
                                                          ' ft'}
                                                </div>
                                            </Td>
                                        </Tr>
                                    )}

                                    {feat?.properties?.movement && (
                                        <Tr>
                                            <Td
                                                paddingStart="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="100px"
                                            >
                                                Movement:
                                            </Td>
                                            <Td width="100%">
                                                {feat.properties.movement}
                                            </Td>
                                        </Tr>
                                    )}

                                    {feat?.properties?.intensity && (
                                        <Tr>
                                            <Td
                                                paddingStart="0"
                                                fontWeight="bold"
                                                width="auto"
                                                minWidth="100px"
                                            >
                                                Intensity:
                                            </Td>
                                            <Td width="100%">
                                                {feat.properties.intensity}
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>

                            {feat?.properties?.body && (
                                <>
                                    <Divider variant="modalFooter" />
                                    <Flex>{feat.properties.body}</Flex>
                                </>
                            )}

                            <Divider variant="modalFooter" />

                            <Text
                                justifyContent="right"
                                float="right"
                                fontSize="0.8rem"
                                marginBottom="8px"
                                color="#0d6efd"
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <a
                                    href="https://www.icao.int/APAC/Documents/edocs/WS-SIGMET.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: '#0d6efd' }}
                                >
                                    SIGMET Quick Reference Guide
                                </a>
                            </Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
