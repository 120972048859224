import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WebcamIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M17.9751 9C17.6299 9 17.3501 9.27982 17.3501 9.625C17.3501 9.97018 17.6299 10.25 17.9751 10.25H18.8751C19.2203 10.25 19.5001 9.97018 19.5001 9.625C19.5001 9.27982 19.2203 9 18.8751 9H17.9751Z"
                fill="currentcolor"
            />
            <path
                d="M11.25 14.6651L14.25 12.933C14.5833 12.7406 14.5833 12.2594 14.25 12.067L11.25 10.3349C10.9167 10.1425 10.5 10.383 10.5 10.7679V14.2321C10.5 14.617 10.9167 14.8575 11.25 14.6651Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 17.5C14.7614 17.5 17 15.2614 17 12.5C17 9.73858 14.7614 7.5 12 7.5C9.23858 7.5 7 9.73858 7 12.5C7 15.2614 9.23858 17.5 12 17.5ZM12 16.25C14.0711 16.25 15.75 14.5711 15.75 12.5C15.75 10.4289 14.0711 8.75 12 8.75C9.92893 8.75 8.25 10.4289 8.25 12.5C8.25 14.5711 9.92893 16.25 12 16.25Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 6.5C17.2597 6.5 16.6134 6.0978 16.2676 5.5H16.2658C15.9293 4.86927 15.2648 4.44 14.5 4.44H9.5C8.73521 4.44 8.07069 4.86927 7.73422 5.5H7.73244C7.38663 6.0978 6.74028 6.5 6 6.5H5C3.34315 6.5 2 7.84315 2 9.5V16.5C2 18.1569 3.34315 19.5 5 19.5H19C20.6569 19.5 22 18.1569 22 16.5V9.5C22 7.84315 20.6569 6.5 19 6.5H18ZM6 8C6.95464 8 7.81896 7.61738 8.4492 7C8.7 6.75 8.97153 6.36751 9.05768 6.20601C9.14383 6.04451 9.31088 5.94 9.5 5.94H14.5C14.6891 5.94 14.8562 6.04451 14.9423 6.20601C15.0285 6.36751 15.3 6.75 15.5508 7C16.181 7.61738 17.0454 8 18 8H19C19.8284 8 20.5 8.67157 20.5 9.5V16.5C20.5 17.3284 19.8284 18 19 18H5C4.17157 18 3.5 17.3284 3.5 16.5V9.5C3.5 8.67157 4.17157 8 5 8H6Z"
                fill="currentcolor"
            />
        </Icon>
    )
}
