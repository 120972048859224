import React from 'react'
import { Box, Button, Flex, Icon } from '@chakra-ui/react'
import { ReactComponent as nz } from '../../icons/assets/nz.svg'
import { ReactComponent as list } from '../../icons/assets/list.svg'

export default function AAWViewSelectSwitch(props) {
    const { briefingAreasMap, setBriefingAreasMap, scrollToTop } = props

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <Flex
            position={briefingAreasMap ? 'absolute' : 'relative'}
            width="100%"
            px="15px"
            pt={{
                base: '25px',
                md: '15px',
            }}
            pb={{
                base: '0px',
                md: '15px',
            }}
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
            display={{
                base: 'flex',
                md: 'none',
            }}
        >
            <Flex
                width="100%"
                alignItems="center"
                justifyContent="flex-start"
                pointerEvents="none"
                px={{
                    base: '0px',
                    md: '10px',
                }}
                marginBottom={{
                    base: briefingAreasMap ? '-70px' : '35px',
                    xs: briefingAreasMap ? '-55px' : '0px',
                    xsm: briefingAreasMap ? '-30px' : '0px',
                    md: '0px',
                }}
                transition="all ease 300ms"
            >
                <Button
                    pointerEvents="auto"
                    bg={'#F2F5F8'}
                    variant="outline"
                    fontSize="11px"
                    fontWeight="600"
                    height={{
                        base: '26px',
                        md: '24px',
                    }}
                    width="92px"
                    style={{
                        minWidth: '92px',
                    }}
                    justifyContent="initial"
                    paddingLeft="2px"
                    paddingRight="2px"
                    py="2px"
                    borderRadius="20px"
                    boxShadow="inset 0px 0px 1px 0px rgba(0,0,0,0.35)"
                    border="none"
                    {...noFocus}
                    index={0}
                    onClick={() => {
                        setBriefingAreasMap((prevState) => !prevState)
                        scrollToTop()
                    }}
                    _active={{
                        bg: '#F2F5F8',
                    }}
                    _focus={{
                        bg: '#F2F5F8',
                    }}
                    _hover={{
                        bg: '#EDF0F2',
                    }}
                >
                    <Box
                        mt="0px"
                        ml="0px"
                        px="22px"
                        height={{
                            base: '22px',
                            md: '20px',
                        }}
                        width="40px"
                        borderRadius="20px"
                        bg={'#ffffff'}
                        boxShadow="0px 0px 1px 0px rgba(0,0,0,0.35)"
                        style={{
                            borderColor: !briefingAreasMap
                                ? '#E9EAEB'
                                : '#33424f',
                            transform: !briefingAreasMap
                                ? 'translateX(100%)'
                                : 'translateX(0%)',
                            transition: 'transform 600ms',
                        }}
                        transform={
                            !briefingAreasMap
                                ? 'translateX(100%)'
                                : 'translateX(0%)'
                        }
                        transition="transform 600ms"
                    ></Box>
                    <Flex justifyContent="space-evenly" marginLeft="-34px">
                        <Flex
                            zIndex="1"
                            paddingLeft="3px"
                            opacity={!briefingAreasMap ? '0.4' : '1'}
                            color={'light.100'}
                            _selected={{
                                opacity: '1',
                            }}
                            _active={{
                                opacity: '1',
                            }}
                            transition="opacity 600ms"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Icon width="1.7em" height="1.7em" as={nz} />
                        </Flex>
                        <Flex
                            zIndex="1"
                            paddingLeft="28px"
                            opacity={briefingAreasMap ? '0.4' : '1'}
                            color={'light.100'}
                            _selected={{
                                opacity: '1',
                            }}
                            _active={{
                                opacity: '1',
                            }}
                            transition="opacity 600ms"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Icon width="1.4em" height="1.4em" as={list} />
                        </Flex>
                    </Flex>
                </Button>
            </Flex>
        </Flex>
    )
}
