import React, { useCallback, useState, useEffect } from 'react'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { useTier } from '../../../../auth/hooks'

import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    LightMode,
    Flex,
    Text,
    Badge,
    List,
    ListItem,
    ListIcon,
    ModalCloseButton,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Icon,
} from '@chakra-ui/react'

import AdvsDetails from './components/AdvsDetails'

import { GoCheck } from 'react-icons/go'
import { RiStarLine } from 'react-icons/ri'
import { FiChevronDown } from 'react-icons/fi'
import { ReactComponent as ExpiredIcon } from '../../../../icons/assets/expired.svg'

import {
    modalVisibilityState,
    setModalVisibilityState,
    dataModeState,
    simulateFreeUserState,
    tourVisibilityState,
    mfaVerifiedState,
} from '../../../../globalState'
import { MfaPreviouslyVerified } from '../../../functions/MfaHandler'

export default function PremiumOnlyModal() {
    const userTier = useTier()
    const [dataMode, setDataMode] = useRecoilState(dataModeState)
    const advsMode = dataMode === 'advs'
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)

    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1
    const mfaExpired = userTier > 1 && !mfaVerified
    const previouslyVerified = MfaPreviouslyVerified()
    const tourVisibility = useRecoilValue(tourVisibilityState)

    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )
    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const [expandWidth, setExpandWidth] = useState(false)
    const [expandedDropdowns, setExpandedDropdowns] = useState(-1)

    useEffect(() => {
        setExpandWidth(expandedDropdowns === 0 ? true : false)
    }, [expandedDropdowns, setExpandWidth])

    const onCloseHandler = useCallback(() => {
        setDataMode('aero')
        setExpandWidth(false)
        setExpandedDropdowns(-1)
    }, [setDataMode])

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    const premiumBadge = (
        <Badge
            display="inline-flex"
            justifyContent="space-evenly"
            alignItems="center"
            ml="2px"
            mr="2px"
            mt="-1px"
            variant="solid"
            bg="rgba(0, 188, 248, 0.1)"
            color="#00B6F1"
            textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
            borderRadius="15"
            pl="7px"
            pr="9px"
            pb="0px"
            pt="0px"
            fontSize="14px"
            fontWeight="500"
        >
            <RiStarLine color="#00B6F1" fontSize="14px" />
            <Text ml="3px">Premium</Text>
        </Badge>
    )

    return (
        advsMode &&
        isPremium &&
        !tourVisibility &&
        !modalVisibility.pdf &&
        !modalVisibility.terms &&
        !modalVisibility.viewLoginMessage &&
        !modalVisibility.announcements && (
            <LightMode>
                <Modal
                    variant="premiumOnly"
                    isCentered
                    onClose={onCloseHandler}
                    isOpen={true}
                    motionPreset="slideInBottom"
                    scrollBehavior="inside"
                    closeOnOverlayClick={false}
                    trapFocus={false}
                    blockScrollOnMount={false}
                >
                    <ModalOverlay />
                    <ModalContent
                        maxHeight={{
                            base: 'calc(100% - 30px)',
                            md: 'calc(100% - 2rem)',
                        }}
                        width={expandWidth ? '7xl' : '3xl'}
                        maxWidth={expandWidth ? '7xl' : '3xl'}
                        transition="all ease 300ms"
                        fontFamily="'Poppins', 'Open Sans', sans-serif"
                    >
                        <ModalCloseButton color="light.100" zIndex="11" />
                        <ModalHeader
                            textAlign="center"
                            background="light.10"
                            color="light.100"
                            fontSize="1rem"
                            fontWeight="600"
                            letterSpacing="0.5px"
                            boxShadow={'0px 3px 15px -1px rgba(0,0,0,0.15)'}
                            borderBottom="1px solid #eaeaea"
                            zIndex="10"
                            minHeight="56px"
                            alignItems="center"
                            justifyContent="center"
                            display="flex"
                        >
                            <RiStarLine color="#00B6F1" fontSize="20px" />
                            <Text pl="6px" pr="10px">
                                PREMIUM FEATURE
                            </Text>
                        </ModalHeader>
                        <ModalBody
                            as="section"
                            bg="gray.50"
                            pt="40px"
                            pb="55px"
                            px={{
                                base: '2',
                                lg: '8',
                            }}
                        >
                            <Flex
                                width="100%"
                                marginLeft="auto"
                                marginRight="auto"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {mfaExpired ? (
                                    <Text
                                        mb="15px"
                                        px="10px"
                                        textAlign="center"
                                        lineHeight="1.8"
                                        color="#687686"
                                        fontSize="15px"
                                    >
                                        {`${
                                            previouslyVerified ? 'Active' : ''
                                        } MFA is required to access ADVS
                                        (Advisory) mode`}
                                    </Text>
                                ) : (
                                    <Flex
                                        textAlign="center"
                                        fontSize="15px"
                                        lineHeight="1.8"
                                        width="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                        gap="5px"
                                        flexDirection={{
                                            base: 'column',
                                            smd: 'row',
                                        }}
                                    >
                                        <Flex>
                                            ADVS (Advisory) mode is available to
                                        </Flex>
                                        <Flex gap="5px">
                                            {premiumBadge}
                                            <Text> users only</Text>
                                        </Flex>
                                    </Flex>
                                )}
                                {!mfaExpired && (
                                    <Flex
                                        width="100%"
                                        marginLeft="auto"
                                        marginRight="auto"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        opacity={expandWidth ? '0' : '1'}
                                        visibility={
                                            expandWidth ? 'hidden' : 'visible'
                                        }
                                        maxHeight={
                                            expandWidth ? '0px' : '200px'
                                        }
                                        fontSize={'14px'}
                                        transition="all ease 200ms"
                                    >
                                        <Text
                                            mt="20px"
                                            mb="15px"
                                            textAlign="center"
                                            fontWeight="300"
                                            lineHeight="1.8"
                                            color="#687686"
                                        >
                                            ADVS mode provides the best
                                            pre-flight situational awareness
                                            tool available for NZ airspace
                                        </Text>

                                        <List
                                            spacing={expandWidth ? '0' : '1'}
                                            maxW="70ch"
                                            px="20px"
                                            mx="auto"
                                            transition="all ease 200ms"
                                            fontWeight="300"
                                            color="#687686"
                                            fontFamily="'Poppins', 'Open Sans', sans-serif"
                                        >
                                            <ListItem
                                                alignContent={'flex-start'}
                                                display="flex"
                                                flexDirection="row"
                                            >
                                                <ListIcon
                                                    fontSize="1.2rem"
                                                    as={GoCheck}
                                                    marginEnd={2}
                                                    color="light.200"
                                                />
                                                <Text lineHeight="1.5">
                                                    View En-route NOTAMs
                                                    graphically on the map
                                                </Text>
                                            </ListItem>

                                            {/* <ListItem
                                        alignContent={'flex-start'}
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        <ListIcon
                                            fontSize="1.2rem"
                                            as={GoCheck}
                                            marginEnd={2}
                                            color="light.200"
                                        />
                                       <Text lineHeight="1.5">
                                            View RPAS flights logged in Airshare
                                        </Text>
                                    </ListItem> */}

                                            <ListItem
                                                alignContent={'flex-start'}
                                                display="flex"
                                                flexDirection="row"
                                            >
                                                <ListIcon
                                                    fontSize="1.2rem"
                                                    as={GoCheck}
                                                    marginEnd={2}
                                                    color="light.200"
                                                />
                                                <Text lineHeight="1.5">
                                                    View SIGMET and SIGWX
                                                    graphically alongside other
                                                    dynamic information
                                                </Text>
                                            </ListItem>
                                        </List>
                                    </Flex>
                                )}

                                {/* MINIMSED SECTION */}
                                {!mfaExpired && (
                                    <Accordion
                                        borderBottom="none"
                                        width="100%"
                                        allowToggle
                                        mt="15px"
                                        index={expandedDropdowns}
                                        onChange={(expandedIndex) => {
                                            setExpandedDropdowns(expandedIndex)
                                        }}
                                        paddingInlineStart="0"
                                        paddingInlineEnd="0"
                                    >
                                        <AccordionItem borderStyle="none">
                                            {({ isExpanded }) => (
                                                <>
                                                    <AccordionButton
                                                        {...noFocus}
                                                        pl="0px"
                                                        pr="0px"
                                                        pt="10px"
                                                        pb="0px"
                                                        justifyContent={{
                                                            base: 'center',
                                                            md: 'flex-end',
                                                        }}
                                                        alignItems="center"
                                                        background={'gray.50'}
                                                        color="gray.400"
                                                        _hover={{
                                                            background:
                                                                'transparent',
                                                            color: 'light.200',
                                                        }}
                                                        _active={{
                                                            background:
                                                                'transparent',
                                                        }}
                                                        _focus={{
                                                            background:
                                                                'transparent',
                                                        }}
                                                    >
                                                        <Flex
                                                            width="100%"
                                                            minHeight={{
                                                                base: '38px',
                                                                md: '34px',
                                                            }}
                                                            justifyContent="center"
                                                            direction={{
                                                                base: 'row',
                                                                md: 'row',
                                                            }}
                                                            alignItems={{
                                                                base: 'center',
                                                                md: 'center',
                                                            }}
                                                            variant="outline"
                                                            fontSize="0.9rem"
                                                            borderRadius="25px"
                                                            fontWeight="400"
                                                            _hover={{
                                                                background:
                                                                    'none',
                                                            }}
                                                        >
                                                            <Flex
                                                                width="100%"
                                                                justifyContent="center"
                                                                pl="1px"
                                                                border="1px solid"
                                                                borderColor="gray.300"
                                                                maxWidth="150px"
                                                                py="5px"
                                                                alignItems="center"
                                                                variant="outline"
                                                                fontSize="0.9rem"
                                                                borderRadius="25px"
                                                                fontWeight="400"
                                                                _hover={{
                                                                    background:
                                                                        'none',
                                                                }}
                                                            >
                                                                <Text
                                                                    pl="5px"
                                                                    pr="5px"
                                                                    fontFamily="'Poppins', 'Open Sans', sans-serif"
                                                                >
                                                                    {isExpanded
                                                                        ? 'Read less'
                                                                        : 'Learn more'}
                                                                </Text>
                                                                <Icon
                                                                    as={
                                                                        FiChevronDown
                                                                    }
                                                                    fontSize="20px"
                                                                    transform={
                                                                        isExpanded &&
                                                                        'rotate(-180deg)'
                                                                    }
                                                                    transition="all ease 200ms"
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                    </AccordionButton>
                                                    <AccordionPanel
                                                        overflow="visible"
                                                        paddingInlineStart="0"
                                                        paddingInlineEnd="0"
                                                    >
                                                        <AdvsDetails />
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                )}

                                <Flex
                                    mt={expandWidth ? '5px' : '30px'}
                                    transition="all ease 200ms"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Button
                                        {...noFocus}
                                        display="inline-flex"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        variant="solid"
                                        bg="brand.premium"
                                        color="#FFFFFF"
                                        textShadow="1px 1px 3px rgba(255, 255, 255, 0.25)"
                                        borderRadius="25"
                                        pl={mfaExpired ? '12px' : '15px'}
                                        pr="17px"
                                        height={{
                                            base: '36px',
                                            md: '32px',
                                        }}
                                        fontSize="0.9rem"
                                        fontWeight="400"
                                        _hover={{
                                            bg: 'brand.premiumHover',
                                        }}
                                        _active={{
                                            bg: 'brand.premiumHover',
                                        }}
                                        onClick={() => {
                                            if (mfaExpired) {
                                                setShowResetMfaModal(true)
                                                setExpandWidth(false)
                                                setExpandedDropdowns(-1)
                                            } else {
                                                setShowPricingTiersModal(true)
                                                setExpandWidth(false)
                                                setExpandedDropdowns(-1)
                                            }
                                        }}
                                    >
                                        {mfaExpired ? (
                                            <ExpiredIcon
                                                color="#FFFFFF"
                                                height="16px"
                                                width="16px"
                                            />
                                        ) : (
                                            <RiStarLine
                                                color="#FFFFFF"
                                                fontSize="16px"
                                            />
                                        )}
                                        {mfaExpired ? (
                                            <Text ml="3px">{`${
                                                previouslyVerified
                                                    ? 'Reset'
                                                    : 'Set'
                                            } MFA`}</Text>
                                        ) : (
                                            <Text ml="3px">
                                                Upgrade to Premium
                                            </Text>
                                        )}
                                    </Button>
                                </Flex>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </LightMode>
        )
    )
}
