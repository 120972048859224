import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function LowCloudForecastIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <g clipPath="url(#clip0_702:31039)">
                {/* <g opacity="0.2">
                    <path
                        d="M3.72898 10.1297C3.48866 10.3699 3.00004 11.1655 3 11.9231C3 13.3153 3.46703 14.0808 4.10261 14.5H4.55317C4.70018 13.4722 5.14487 12.5405 5.7971 11.7949C5.05862 11.2345 4.85287 10.6102 4.45796 9.36112C4.3378 9.48122 4.21564 9.61332 4.09347 9.74542C3.97131 9.87752 3.84914 10.0096 3.72898 10.1297Z"
                        fill="currentColor"
                    />
                    <path
                        d="M14.5167 13.0468C14.7265 13.5 14.8736 13.9881 14.9468 14.5H16.75C17.2255 14.5 17.6568 14.6897 17.9722 14.9975L18.3086 14.9975C19.0496 14.9975 19.6915 14.3121 20.0826 13.8946C20.1461 13.8268 20.203 13.7661 20.2526 13.7165C20.4315 13.5376 20.8249 13.076 20.9816 12.1793C21.0696 11.6754 20.829 10.3939 20.5436 9.71338C20.2258 8.95555 19.6991 8.51058 19.0376 8.08014C18.3301 7.7176 16.6077 7.34355 15.8787 7.56774C17.2172 9.53756 18.0967 12.6798 14.5167 13.0468Z"
                        fill="currentColor"
                    />
                </g> */}
                <path
                    d="M20.8026 14.5568C20.1891 15.2194 19.3786 15.6969 18.4643 15.8965C18.3008 15.0994 17.5954 14.5 16.75 14.5H17.5C19.1569 14.5 20.5 13.1569 20.5 11.5C20.5 9.8879 19.123 8.57279 17.625 8.50292C17.5954 8.50154 17.5658 8.50065 17.5362 8.50025C17.5241 8.50008 17.5121 8.5 17.5 8.5C17.4752 8.5 17.4505 8.5003 17.4259 8.5009C16.5672 8.52176 15.798 8.90341 15.264 9.50002L14.1563 8.48858C14.6065 7.98893 15.1672 7.5907 15.7998 7.33233C14.9336 5.93612 13.3735 5 11.75 5C9.21051 5 7.13647 6.99286 7.00647 9.5H6C4.61929 9.5 3.5 10.6193 3.5 12C3.5 12.8742 3.94875 13.6437 4.62847 14.0905C4.59812 14.2251 4.57295 14.3617 4.55317 14.5H3.25C3.13186 14.5 3.01646 14.5117 2.90489 14.534C2.33938 13.8441 2 12.9617 2 12C2 9.87618 3.65521 8.13895 5.746 8.00794C6.50027 5.40385 8.9028 3.5 11.75 3.5C14.0505 3.5 16.2543 4.92855 17.3251 7.00334C17.3795 7.00126 17.4342 7.00014 17.489 7.00001L17.5 7C19.8182 7 22.0002 9.01472 22.0002 11.5C22.0002 12.6802 21.5458 13.7542 20.8026 14.5568Z"
                    fill="currentColor"
                />
                <path
                    d="M9.27083 17.6458C9.27083 17.9094 9.48646 18.125 9.75 18.125C10.0135 18.125 10.2292 17.9094 10.2292 17.6458C10.2292 17.3823 10.0135 17.1667 9.75 17.1667C9.48646 17.1667 9.27083 17.3823 9.27083 17.6458Z"
                    fill="currentColor"
                />
                <path
                    d="M9.27083 10.9375V12.8542H10.2292V11.9342C11.8535 12.169 13.1042 13.5585 13.1042 15.25C13.1042 17.1044 11.6044 18.6042 9.75 18.6042C7.89563 18.6042 6.39583 17.1044 6.39583 15.25C6.39583 14.445 6.67854 13.7071 7.15292 13.1321L9.75 15.7292L10.4256 15.0535L7.16729 11.7952V11.8048C6.11792 12.5906 5.4375 13.8365 5.4375 15.25C5.4375 17.6315 7.36854 19.5625 9.75 19.5625C12.1315 19.5625 14.0625 17.6315 14.0625 15.25C14.0625 12.8685 12.1315 10.9375 9.75 10.9375H9.27083Z"
                    fill="currentColor"
                />
                <path
                    d="M12.625 15.25C12.625 14.9865 12.4094 14.7708 12.1458 14.7708C11.8823 14.7708 11.6667 14.9865 11.6667 15.25C11.6667 15.5135 11.8823 15.7292 12.1458 15.7292C12.4094 15.7292 12.625 15.5135 12.625 15.25Z"
                    fill="currentColor"
                />
                <path
                    d="M6.875 15.25C6.875 15.5135 7.09062 15.7292 7.35417 15.7292C7.61771 15.7292 7.83333 15.5135 7.83333 15.25C7.83333 14.9865 7.61771 14.7708 7.35417 14.7708C7.09062 14.7708 6.875 14.9865 6.875 15.25Z"
                    fill="currentColor"
                />
                <path
                    d="M3.25 15.5H4.50585C4.5304 16.0237 4.63169 16.5274 4.79873 17H3.25C2.83579 17 2.5 16.6642 2.5 16.25C2.5 15.8358 2.83579 15.5 3.25 15.5Z"
                    fill="currentColor"
                />
                <path
                    d="M14.223 18C13.8617 18.5864 13.3884 19.0963 12.8328 19.5H20.75C21.1642 19.5 21.5 19.1642 21.5 18.75C21.5 18.3358 21.1642 18 20.75 18H14.223Z"
                    fill="currentColor"
                />
                <path
                    d="M14.7013 17C14.8683 16.5274 14.9696 16.0237 14.9942 15.5H16.75C17.1642 15.5 17.5 15.8358 17.5 16.25C17.5 16.6642 17.1642 17 16.75 17H14.7013Z"
                    fill="currentColor"
                />
                <path
                    d="M4.5 21.25C4.5 20.8358 4.83579 20.5 5.25 20.5H18.75C19.1642 20.5 19.5 20.8358 19.5 21.25C19.5 21.6642 19.1642 22 18.75 22H5.25C4.83579 22 4.5 21.6642 4.5 21.25Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    )
}
