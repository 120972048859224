import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function PressureIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 6}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M11.5 4.75C11.5 4.47386 11.7239 4.25 12 4.25C12.2761 4.25 12.5 4.47386 12.5 4.75V5.01758C13.8228 5.11094 15.0442 5.57186 16.0625 6.29882L16.2447 6.08923C16.4259 5.88082 16.7417 5.85874 16.9501 6.03991C17.1585 6.22107 17.1806 6.53688 16.9994 6.74529L16.8333 6.93644C17.7455 7.80744 18.4227 8.92245 18.7602 10.1767L19.0496 10.1309C19.3224 10.0877 19.5785 10.2738 19.6217 10.5465C19.6649 10.8192 19.4788 11.0754 19.2061 11.1186L18.95 11.1591C18.983 11.4348 19 11.7154 19 12C19 13.0066 18.7875 13.9636 18.405 14.8285L18.6664 14.9919C18.9006 15.1382 18.9718 15.4467 18.8255 15.6809C18.6791 15.915 18.3707 15.9862 18.1365 15.8399L17.7125 15.575C17.7049 15.5702 17.6975 15.5653 17.6903 15.5603C17.686 15.5573 17.6818 15.5543 17.6777 15.5512L17.2996 15.3147C17.9018 14.3539 18.25 13.2176 18.25 12C18.25 10.0755 17.3802 8.35418 16.0122 7.20769C15.9964 7.19682 15.9809 7.18491 15.966 7.17197C15.9577 7.16474 15.9497 7.15729 15.942 7.14964C14.8665 6.27457 13.4945 5.75 12 5.75C8.54822 5.75 5.75 8.54822 5.75 12C5.75 13.2176 6.09818 14.3539 6.70042 15.3147L6.06447 15.7125L6.06345 15.7109L5.85691 15.8399C5.62272 15.9862 5.31426 15.915 5.16792 15.6809C5.02159 15.4467 5.0928 15.1382 5.32699 14.9919L5.59357 14.8253C5.21194 13.9612 5 13.0053 5 12C5 11.7158 5.01694 11.4355 5.04986 11.1601L4.78733 11.1186C4.51459 11.0754 4.32851 10.8192 4.37171 10.5465C4.41491 10.2738 4.67103 10.0877 4.94377 10.1309L5.23955 10.1777C5.57647 8.9247 6.25247 7.81061 7.16312 6.93989L6.99396 6.74529C6.8128 6.53688 6.83488 6.22107 7.04329 6.03991C7.2517 5.85874 7.56751 5.88082 7.74867 6.08923L7.93339 6.30173C8.95257 5.57308 10.1754 5.11107 11.5 5.01758V4.75Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5 16C14.5 17.3807 13.3807 18.5 12 18.5C10.6193 18.5 9.5 17.3807 9.5 16C9.5 14.8182 10.32 13.8279 11.4221 13.5671L13.8996 8.2368C14.0189 7.98 14.406 8.08827 14.3747 8.36973L13.7288 14.1941C14.2041 14.6492 14.5 15.2901 14.5 16ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
                fill="currentColor"
            />
        </Icon>
    )
}
