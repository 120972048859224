import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useTier } from '../../../auth/hooks'
import AirspaceLayer from './Airspace'
import RoutesLayer from './Routes'
import HazardsLayer from './Hazards'
import ObstaclesLayer from './Obstacles'
import IcaoWaypointsLayer from './IcaoWaypoints'
import VrpLayer from './VRP'
import NavaidsLayer from './Navaids'
import AeroDisclaimer from '../../modals/alerts/AeroDisclaimer'

import {
    altitudeModeState,
    dataModeState,
    layerSelectionState,
    simulateFreeUserState,
    mfaVerifiedState,
} from '../../../globalState'

export default function AeroLayers() {
    const dataMode = useRecoilValue(dataModeState)
    const layerSelection = useRecoilValue(layerSelectionState)

    const [altitudeMode, setAltitudeMode] = useRecoilState(altitudeModeState)
    const userTier = useTier()
    const simulateFreeUser = useRecoilValue(simulateFreeUserState)
    const mfaVerified = useRecoilValue(mfaVerifiedState)
    const isPremium =
        simulateFreeUser || !mfaVerified ? userTier >= 1 : userTier === 1

    useEffect(() => {
        if (dataMode === 'aero' && altitudeMode !== 'double') {
            setAltitudeMode('double')
        }
    }, [dataMode, altitudeMode, setAltitudeMode])

    return (
        <>
            <AeroDisclaimer show={dataMode === 'aero'} />
            {dataMode === 'aero' && layerSelection.aero.airspace && (
                <AirspaceLayer />
            )}
            {dataMode === 'aero' && layerSelection.aero.hazards && (
                <HazardsLayer />
            )}
            {dataMode === 'aero' && layerSelection.aero.obstacles && (
                <ObstaclesLayer />
            )}
            {dataMode === 'aero' && layerSelection.aero.navaids && (
                <NavaidsLayer />
            )}
            {dataMode === 'aero' && layerSelection.aero.vrp && <VrpLayer />}
            {dataMode === 'aero' && layerSelection.aero.icaoWaypoints && (
                <IcaoWaypointsLayer />
            )}
            {dataMode === 'aero' &&
                layerSelection.aero.routes &&
                !isPremium && <RoutesLayer />}
        </>
    )
}
