import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function AAWIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 4}
            color={color || colorHandler()}
            viewBox="0 0 27 29"
        >
            <path
                d="M23.2142 7.23452C22.0941 5.89052 19.8863 7.04256 20.3343 8.73852C20.4303 9.12246 20.6863 9.41064 20.9424 9.73047C21.2303 10.1144 21.4863 10.4985 21.7104 10.9144C22.1584 11.7144 22.4785 12.5785 22.6705 13.4745C23.3745 16.5466 22.5426 19.8425 20.4305 22.1785C18.4145 24.4184 15.4705 25.6345 12.4625 25.4425C9.48664 25.2506 6.73452 23.6506 5.03852 21.2184C3.37446 18.8184 2.89452 15.7463 3.69452 12.9624C4.52646 10.0825 6.67041 7.71441 9.42252 6.56241L7.95041 8.70641C7.4705 9.37852 7.5984 10.3705 8.20644 10.9145C8.94238 11.5544 10.1266 11.4265 10.6705 10.6265L14.4785 5.1544C15.0544 4.32246 14.7346 3.07452 13.7746 2.69035C11.8547 1.82675 9.93461 0.994577 8.01455 0.13052C7.18261 -0.221491 6.19044 0.162439 5.80649 0.994577C5.45448 1.82652 5.83841 2.81869 6.67055 3.20263L7.75849 3.68254C4.91049 4.96243 2.54244 7.2986 1.23049 10.1465C-0.113507 13.0264 -0.369507 16.3545 0.526493 19.3945C2.25461 25.2185 7.98249 29.1865 14.0625 28.7705C20.1105 28.3545 25.2305 23.6185 26.1265 17.6025C26.6705 13.9227 25.6142 10.0825 23.2142 7.23452C25.1984 9.60258 22.6064 6.53052 23.2142 7.23452Z"
                fill="currentColor"
            />
        </Icon>
    )
}
