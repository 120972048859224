import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function SigmetIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                d="M3.5 10.5C3.5 9.11929 4.61929 8 6 8H7.00647C7.13647 5.49286 9.21051 3.5 11.75 3.5C13.3735 3.5 14.9336 4.43612 15.7998 5.83233C15.1672 6.0907 14.6065 6.48893 14.1563 6.98858L15.264 8.00002C15.798 7.40341 16.5672 7.02176 17.4259 7.0009C17.4505 7.0003 17.4752 7 17.5 7C17.5121 7 17.5241 7.00008 17.5362 7.00025C17.5658 7.00065 17.5954 7.00154 17.625 7.00292C19.123 7.07279 20.5 8.3879 20.5 10C20.5 11.0162 19.9948 11.9143 19.2218 12.457C19.0718 13.3359 18.8856 13.7635 18.373 14.4154C19.3247 14.2283 20.1687 13.7413 20.8026 13.0568C21.5458 12.2542 22.0002 11.1802 22.0002 10C22.0002 7.51472 19.8182 5.5 17.5 5.5L17.489 5.50001C17.4342 5.50014 17.3795 5.50126 17.3251 5.50334C16.2543 3.42855 14.0505 2 11.75 2C8.9028 2 6.50027 3.90385 5.746 6.50794C3.65521 6.63895 2 8.37618 2 10.5C2 12.0627 2.89617 13.4162 4.20263 14.0744L4.95127 12.7701C4.09443 12.3735 3.5 11.5062 3.5 10.5Z"
                fill="currentColor"
            />
            <path
                d="M15.5 9.005C14.7044 9.005 13.9413 9.32106 13.3787 9.88367C12.8161 10.4463 12.75 10.755 12.75 10.755C12.75 10.755 12.7018 10.9371 12.71 11.055C12.7233 11.2453 12.7626 11.363 12.89 11.505C13.0302 11.6613 13.1699 11.7278 13.3787 11.75C13.5244 11.7655 13.6133 11.7478 13.75 11.695C14.0157 11.5923 14.088 11.4133 14.2528 11.1666C14.4176 10.92 14.6519 10.7277 14.926 10.6142C15.2001 10.5007 15.5017 10.4709 15.7926 10.5288C16.0836 10.5867 16.3509 10.7296 16.5607 10.9393C16.7704 11.1491 16.9133 11.4164 16.9712 11.7074C17.0291 11.9984 16.9993 12.2999 16.8858 12.574C16.7723 12.8481 16.58 13.0824 16.3334 13.2472C16.0867 13.412 15.7967 13.5 15.5 13.5H11.465L12.325 15H15.5C16.2956 15 17.0587 14.6839 17.6213 14.1213C18.1839 13.5587 18.5 12.7956 18.5 12C18.5 11.2044 18.1839 10.4413 17.6213 9.87866C17.0587 9.31605 16.2956 9 15.5 9V9.005Z"
                fill="currentColor"
            />
            <path
                d="M19 21.995C18.2043 21.995 17.4412 21.6789 16.8786 21.1163C16.316 20.5537 16.2499 20.245 16.2499 20.245C16.2499 20.245 16.2017 20.0629 16.2099 19.945C16.2232 19.7547 16.2626 19.637 16.3899 19.495C16.5302 19.3387 16.6698 19.2722 16.8786 19.25C17.0244 19.2345 17.1132 19.2522 17.2499 19.305C17.5156 19.4077 17.5879 19.5867 17.7527 19.8334C17.9176 20.08 18.1518 20.2723 18.4259 20.3858C18.7 20.4993 19.0016 20.5291 19.2926 20.4712C19.5836 20.4133 19.8508 20.2704 20.0606 20.0607C20.2704 19.8509 20.4132 19.5836 20.4711 19.2926C20.529 19.0016 20.4993 18.7001 20.3858 18.426C20.2722 18.1519 20.08 17.9176 19.8333 17.7528C19.5866 17.588 19.2966 17.5 19 17.5H13.7715L12.9082 16H19C19.7956 16 20.5587 16.3161 21.1213 16.8787C21.6839 17.4413 21.9999 18.2044 21.9999 19C21.9999 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22V21.995Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9796 19.125L8.21651 10.875C8.12028 10.7083 7.87972 10.7083 7.78349 10.875L3.02035 19.125C2.92413 19.2917 3.04441 19.5 3.23686 19.5H12.7631C12.9556 19.5 13.0759 19.2917 12.9796 19.125ZM8.86603 10.5C8.48113 9.83333 7.51888 9.83333 7.13398 10.5L2.37083 18.75C1.98593 19.4167 2.46706 20.25 3.23686 20.25H12.7631C13.5329 20.25 14.0141 19.4167 13.6292 18.75L8.86603 10.5Z"
                fill="currentColor"
            />
            <path
                d="M7.09481 14.2455C7.04397 13.7117 7.46376 13.25 8 13.25C8.53624 13.25 8.95603 13.7117 8.90519 14.2455L8.72987 16.0863C8.69404 16.4626 8.37799 16.75 8 16.75C7.62201 16.75 7.30596 16.4626 7.27013 16.0863L7.09481 14.2455Z"
                fill="currentColor"
            />
            <path
                d="M8.75 18C8.75 18.4142 8.41421 18.75 8 18.75C7.58579 18.75 7.25 18.4142 7.25 18C7.25 17.5858 7.58579 17.25 8 17.25C8.41421 17.25 8.75 17.5858 8.75 18Z"
                fill="currentColor"
            />
        </Icon>
    )
}
