import { Image as ChakraImage } from '@chakra-ui/react'

import fallback from './assets/image-fallback.svg'

export default function Image(props) {
    const { src } = props
    return (
        <ChakraImage
            src={src}
            fallbackSrc={fallback}
            fallbackStrategy={'onError'}
            {...props}
        />
    )
}
