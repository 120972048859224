import React, { useContext, useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { Source, Layer, MapContext } from 'react-mapbox-gl'
import calcSymbolPlacement from '../functions/dynamicSymbols'
import { VectorLayerRefreshInfo, dataModeState } from '../../../globalState'

export default function FIRLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)
    const dataMode = useRecoilValue(dataModeState)
    const mapInstance = useContext(MapContext)

    // labelData is defined here as a useRef, directly using an empty FeatureCollection in the source breaks dynamic symbol placement
    const labelData = useRef({
        type: 'FeatureCollection',
        features: [],
    })

    useEffect(() => {
        const dynamicSymbols = () => {
            calcSymbolPlacement(mapInstance, 'fir-fill', 'fir-symbol', 'id')
        }

        const moveEndCallback = () => {
            var tileLoad = setInterval(function () {
                if (mapInstance.loaded()) {
                    dynamicSymbols()
                    clearInterval(tileLoad)
                }
            }, 300)
        }

        mapInstance.on('moveend', moveEndCallback)

        function renderCallback(e) {
            if (e.target && e.target.loaded()) {
                dynamicSymbols()
                mapInstance.off('render', renderCallback)
            }
        }

        mapInstance.on('render', renderCallback)

        return () => {
            mapInstance.off('moveend', moveEndCallback)
        }
    }, [layerData.fir.dataURL, mapInstance])

    const lineWidthDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0, // Level
        3,
        2, // Level
        3,
        3, // Level
        3,
        4, // Level
        4,
        5, // Level
        8,
        6, // Level
        10,
        7, // Level
        15,
        8, // Level
        15,
        9, // Level
        15,
        10, // Level
        15,
        11, // Level
        15,
    ]

    const lineOffsetDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0, // Level
        1.5,
        2, // Level
        1.5,
        3, // Level
        1.5,
        4, // Level
        2,
        5, // Level
        3.5,
        6, // Level
        5,
        7, // Level
        6,
        8, // Level
        6,
        9, // Level
        6,
        10, // Level
        6,
        11, // Level
        6,
    ]

    const fontSizeDefinition = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        8,
        3,
        11,
        4,
        11,
        5,
        12,
        6,
        14,
        9,
        10,
        10,
        5,
    ]

    return (
        <>
            <Source
                id="fir"
                geoJsonSource={{
                    type: 'geojson',
                    data: layerData.fir.dataURL,
                }}
            />
            <Source
                id="fir-symbol"
                geoJsonSource={{
                    type: 'geojson',
                    data: labelData,
                }}
            />
            <Layer
                id="fir-fill"
                sourceId="fir"
                type="fill"
                paint={{
                    'fill-antialias': false,
                    'fill-opacity': [
                        'match',
                        ['get', 'designator'],
                        ['NZZO', 'NZZO-MS', 'NZZC'],
                        0,
                        0.1,
                    ],
                }}
            />
            <Layer
                id="fir-line"
                sourceId="fir"
                type="line"
                paint={{
                    'line-color': '#0072bc',
                    'line-width': lineWidthDefinition,
                    'line-offset': lineOffsetDefinition,
                    'line-pattern': 'fir',
                    'line-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        6,
                        dataMode === 'aero' ? 1 : 0.25,
                        6.5,
                        dataMode === 'aero' ? 0.4 : 0.25,
                        7.5,
                        dataMode === 'aero' ? 0 : 0.25,
                    ],
                    // 'line-opacity': 1, // ** used for testing **
                }}
            />
            <Layer
                id="fir-symbol"
                sourceId="fir-symbol"
                layout={{
                    'text-field': [
                        'format',
                        ['get', 'designator'],
                        {},
                        '\n',
                        {},
                        [
                            'concat',
                            ['get', 'name'],
                            ' ',
                            [
                                'match',
                                ['get', 'type'],
                                'SECTOR',
                                'SECTOR',
                                'FIR',
                            ],
                        ],
                        { 'font-scale': 0.7 },
                    ],
                    'text-line-height': 1,
                    'text-font': ['Roboto Bold', 'Arial Unicode MS Bold'],
                    'text-size': fontSizeDefinition,
                }}
                paint={{
                    'text-color': '#0072bc',
                    'text-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        6,
                        dataMode === 'aero' ? 1 : 0.4,
                        6.5,
                        0.5,
                        7.5,
                        dataMode === 'aero' ? 0 : 0.4,
                    ],
                }}
            />
        </>
    )
}
