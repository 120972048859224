import React from 'react'
import { Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import CloudIcon from '../../../../../icons/menu-icons/CloudIcon'
import { ReactComponent as CloudsFewIcon } from '../../../../../icons/assets/clouds-few.svg'
import { ReactComponent as CloudsScatteredIcon } from '../../../../../icons/assets/clouds-scattered.svg'
import { ReactComponent as CloudsBrokenIcon } from '../../../../../icons/assets/clouds-broken.svg'
import { ReactComponent as CloudsOvercastIcon } from '../../../../../icons/assets/clouds-overcast.svg'

export default function AerodromesCloud(props) {
    const {
        metarActive,
        timePeriod,
        aerodromeBackgroundsDisabled,
        cloudCoverBoxColor,
        metarCloud,
        noCloudDetected,
        cloudAndVisibilityOK,
        verticalVisibility,
        reversedCloudLayers,
        infotabGraphicsDisabled,
    } = props

    const cloudImageSize = useBreakpointValue({
        base: '150px',
        sm: '170px',
        md: '130px',
    })

    return (
        <Flex
            width={metarActive ? '100%' : 'auto'}
            background={
                !infotabGraphicsDisabled &&
                (metarActive && !aerodromeBackgroundsDisabled
                    ? cloudCoverBoxColor(
                          timePeriod === 'Night'
                              ? 'Night'
                              : timePeriod === 'Sunrise'
                              ? 'Sunrise'
                              : timePeriod === 'Sunset'
                              ? 'Sunset'
                              : metarCloud
                              ? metarCloud
                              : 'white'
                      )
                    : 'white')
            }
            border={metarActive ? 'none' : '1px solid rgb(229 235 242)'}
            boxShadow={
                !infotabGraphicsDisabled && aerodromeBackgroundsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            backdropFilter={
                infotabGraphicsDisabled || aerodromeBackgroundsDisabled
                    ? 'none'
                    : 'blur(25px) contrast(102%) saturate(102%)'
            }
            borderRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            pt="9px"
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '7px' : '9px'}
            direction={{
                base: 'column',
                md: 'column',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                }}
                width="100%"
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color={
                        aerodromeBackgroundsDisabled
                            ? '#9db2cd'
                            : 'rgba(255,255,255,0.6)'
                    }
                >
                    <CloudIcon boxSize="36px" />
                </Flex>
                <Flex
                    direction="column"
                    pb="3px"
                    gap={{ base: '2px', md: '0px' }}
                    width="100%"
                    pl={infotabGraphicsDisabled ? '15px' : '10px'}
                >
                    {noCloudDetected && !cloudAndVisibilityOK && (
                        <Text
                            fontSize={{
                                base: '1.2rem',
                                md: '1.15rem',
                            }}
                            lineHeight="1.2"
                            pt={{
                                base: '6px',
                                md: '6px',
                            }}
                            fontWeight="600"
                            whiteSpace="pre-line"
                            textShadow="1px 2px 5px rgba(0,0,0,0.06)"
                        >
                            No cloud detected
                        </Text>
                    )}
                    {cloudAndVisibilityOK && (
                        <Text
                            fontSize={{
                                base: '1.2rem',
                                md: '1.15rem',
                            }}
                            lineHeight="1.2"
                            pt={{
                                base: '6px',
                                md: '6px',
                            }}
                            fontWeight="600"
                            whiteSpace="pre-line"
                            textShadow="1px 2px 5px rgba(0,0,0,0.06)"
                        >
                            Cloud and Visibility OK
                        </Text>
                    )}
                    {verticalVisibility && verticalVisibility.skyClear && (
                        <Text
                            fontSize={{
                                base: '1.2rem',
                                md: '1.15rem',
                            }}
                            lineHeight="1.2"
                            pt={{
                                base: '6px',
                                md: '6px',
                            }}
                            fontWeight="600"
                            whiteSpace="pre-line"
                            textShadow="1px 2px 5px rgba(0,0,0,0.06)"
                        >
                            Sky Clear
                        </Text>
                    )}
                    {verticalVisibility && !verticalVisibility.skyClear && (
                        <Flex direction="column">
                            <Text
                                fontSize={{
                                    base: '1.2rem',
                                    md: '1.15rem',
                                }}
                                lineHeight="1.2"
                                pt={{
                                    base: '6px',
                                    md: '6px',
                                }}
                                fontWeight="600"
                                whiteSpace="pre-line"
                                textShadow="1px 2px 5px rgba(0,0,0,0.06)"
                            >
                                Vertical Visibility
                            </Text>
                            <Text
                                fontSize="0.9rem"
                                fontWeight="600"
                                whiteSpace="pre-line"
                                textShadow="1px 2px 5px rgba(0,0,0,0.06)"
                            >
                                {verticalVisibility.value}{' '}
                                {verticalVisibility.unit}
                            </Text>
                        </Flex>
                    )}
                    {reversedCloudLayers &&
                        reversedCloudLayers.length > 0 &&
                        reversedCloudLayers.map((layer, i) => {
                            return (
                                <Flex
                                    direction="row"
                                    key={`InfoContentCloud${i}`}
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Flex
                                        direction="column"
                                        width="100%"
                                        flexGrow="1"
                                        paddingRight="5px"
                                        pb="3px"
                                    >
                                        {layer.amount && (
                                            <Text
                                                fontSize={{
                                                    base: '1.4rem',
                                                    md: '1.2rem',
                                                }}
                                                lineHeight="1.2"
                                                pt={{
                                                    base: '4px',
                                                    md: '6px',
                                                }}
                                                fontWeight="600"
                                                whiteSpace="pre"
                                            >
                                                {layer.amount}
                                            </Text>
                                        )}
                                        {layer.base && layer.base.value && (
                                            <Text
                                                fontSize={{
                                                    base: '0.9rem',
                                                    md: '0.85rem',
                                                }}
                                                fontWeight="600"
                                                whiteSpace="pre"
                                                opacity="0.7"
                                            >
                                                {layer.base.value} ft AGL
                                            </Text>
                                        )}
                                        {layer.type && (
                                            <Text
                                                fontSize={{
                                                    base: '0.9rem',
                                                    md: '0.85rem',
                                                }}
                                                fontWeight="600"
                                                whiteSpace="pre-line"
                                                opacity="0.7"
                                            >
                                                {layer.type}
                                            </Text>
                                        )}
                                    </Flex>
                                    {!infotabGraphicsDisabled && (
                                        <Flex
                                            justifyContent={{
                                                base: 'flex-end',
                                                md: 'flex-end',
                                            }}
                                            alignItems={{
                                                base: 'center',
                                                md: 'center',
                                            }}
                                            mt="4px"
                                            color={
                                                aerodromeBackgroundsDisabled
                                                    ? '#83909F'
                                                    : 'light.10'
                                            }
                                            opacity="0.4"
                                        >
                                            {layer.amount === 'Overcast' ? (
                                                <CloudsOvercastIcon
                                                    width={cloudImageSize}
                                                    height="auto"
                                                />
                                            ) : layer.amount === 'Broken' ? (
                                                <CloudsBrokenIcon
                                                    width={cloudImageSize}
                                                    height="auto"
                                                />
                                            ) : layer.amount === 'Scattered' ? (
                                                <CloudsScatteredIcon
                                                    width={cloudImageSize}
                                                    height="auto"
                                                />
                                            ) : layer.amount === 'Few' ? (
                                                <CloudsFewIcon
                                                    width={cloudImageSize}
                                                    height="auto"
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                            )
                        })}
                </Flex>
            </Flex>
        </Flex>
    )
}
