import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function HazardsIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M10.5962 10.0941C10.5333 9.276 11.1799 8.57747 12.0001 8.57747C12.8203 8.57747 13.4669 9.276 13.404 10.0941L13.0916 14.1579C13.0477 14.7285 12.5721 15.169 12.0001 15.169C11.4281 15.169 10.9526 14.7285 10.9087 14.1579L10.5962 10.0941Z"
                fill="currentColor"
            />
            <path
                d="M13.0138 17.1972C13.0138 17.7572 12.56 18.2113 12.0001 18.2113C11.4403 18.2113 10.9864 17.7572 10.9864 17.1972C10.9864 16.6371 11.4403 16.1831 12.0001 16.1831C12.56 16.1831 13.0138 16.6371 13.0138 17.1972Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3399 17.9577L12.8779 5.02817C12.4877 4.35211 11.5123 4.35211 11.1221 5.02817L3.66013 17.9577C3.26995 18.6338 3.75767 19.4789 4.538 19.4789H19.462C20.2423 19.4789 20.73 18.6338 20.3399 17.9577ZM14.1947 4.26761C13.2193 2.57747 10.7807 2.57746 9.8053 4.2676L2.3433 17.1972C1.36788 18.8873 2.58716 21 4.538 21H19.462C21.4128 21 22.6321 18.8873 21.6567 17.1972L14.1947 4.26761Z"
                fill="currentColor"
            />
        </Icon>
    )
}
