import React, { useCallback, useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    LightMode,
    Text,
    Button,
    Flex,
} from '@chakra-ui/react'
import {
    setModalVisibilityState,
    modalVisibilityState,
    bypassLandingPageState,
} from '../../../globalState'
import MfaContent from './MfaContent'
import { HiArrowNarrowLeft } from 'react-icons/hi'

export default function ResetMFAModal() {
    const modalVisibility = useRecoilValue(modalVisibilityState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setBypassLandingPage = useSetRecoilState(bypassLandingPageState)
    const [step, setStep] = useState(1)
    const [mobileStatus, setMobileStatus] = useState('')
    const [expandedDropdowns, setExpandedDropdowns] = useState(-1)
    const [verificationStatus, setVerificationStatus] = useState('')

    const navigate = useNavigate()
    const setShowWhyMFAModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'whyMfa', value })
        },
        [setModalVisibility]
    )

    const setShow = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        !modalVisibility.whyMfa && modalVisibility.resetMfa
            ? onOpen()
            : onClose()
    }, [modalVisibility, onOpen, onClose])

    function onCloseHandler() {
        setShow(false)
    }

    // this is used instead os isOpen to stop a closing bug
    const modalOpen = modalVisibility && modalVisibility.resetMfa

    return (
        <>
            <LightMode>
                <Modal
                    variant={'mfa'}
                    size="lg"
                    onClose={onCloseHandler}
                    isOpen={isOpen}
                    scrollBehavior={'outside'}
                    motionPreset="slideInBottom"
                    trapFocus={false}
                    closeOnOverlayClick={false}
                >
                    <ModalOverlay />
                    <ModalContent
                        mx="15px"
                        className="mfa"
                        maxWidth={{ base: 'calc(100vw - 30px)', sm: 'lg' }}
                    >
                        <ModalCloseButton
                            color="gray.400"
                            position="absolute"
                            zIndex="10"
                            mt={{
                                base: modalOpen ? '40px' : '0px',
                                md: modalOpen ? '60px' : '0px',
                            }}
                        />
                        <ModalBody
                            mt={{
                                base: modalOpen ? '40px' : '0px',
                                md: modalOpen ? '60px' : '0px',
                            }}
                            overflow="hidden"
                            background="light.20"
                            style={{ padding: 0 }}
                            fontSize="0.8rem"
                            fontFamily="'Poppins','Open Sans',sans-serif"
                        >
                            <MfaContent
                                step={step}
                                setStep={setStep}
                                mobileStatus={mobileStatus}
                                setMobileStatus={setMobileStatus}
                                expandedDropdowns={expandedDropdowns}
                                setExpandedDropdowns={setExpandedDropdowns}
                                setBypassLandingPage={setBypassLandingPage}
                                verificationStatus={verificationStatus}
                                setVerificationStatus={setVerificationStatus}
                            />
                        </ModalBody>
                        <ModalFooter overflow="hidden" pt="3" px="0px">
                            <Flex
                                mb={modalOpen ? '20px' : '0px'}
                                height={
                                    !modalOpen
                                        ? '0px'
                                        : step > 1
                                        ? '70px'
                                        : step === 1
                                        ? '40px'
                                        : '0px'
                                }
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                overflow="hidden"
                            >
                                <Button
                                    fontSize={'0.8rem'}
                                    borderRadius="12px"
                                    fontWeight={400}
                                    color={'white'}
                                    px="0px"
                                    bg="transparent"
                                    cursor="pointer"
                                    _hover={{
                                        opacity: '1',
                                        bg: 'transparent',
                                        color: 'light.200',
                                    }}
                                    _active={{
                                        opacity: '1',
                                        bg: 'transparent',
                                    }}
                                    _focus={{
                                        opacity: '1',
                                        bg: 'transparent',
                                    }}
                                    zIndex="1"
                                    height={modalOpen ? '36px' : '0px'}
                                    transition="all linear 300ms"
                                    onClick={() => {
                                        setShowWhyMFAModal(true)
                                    }}
                                >
                                    <Text
                                        fontWeight="500"
                                        letterSpacing="0.5px"
                                        textShadow="1px 3px 15px rgba(0, 0, 0, 0.3)"
                                    >
                                        Why do we need Multi-factor
                                        Authentication?
                                    </Text>
                                </Button>

                                {step > 1 && (
                                    <Button
                                        mt="0px"
                                        fontSize={'0.8rem'}
                                        borderRadius="12px"
                                        fontWeight={400}
                                        color={'white'}
                                        px="20px"
                                        bg="transparent"
                                        cursor="pointer"
                                        _hover={{
                                            opacity: '1',
                                            bg: 'transparent',
                                            color: 'light.200',
                                        }}
                                        _active={{
                                            opacity: '1',
                                            bg: 'transparent',
                                        }}
                                        _focus={{
                                            opacity: '1',
                                            bg: 'transparent',
                                        }}
                                        zIndex="1"
                                        height={modalOpen ? '36px' : '0px'}
                                        transition="all linear 300ms"
                                        onClick={() => {
                                            if (step === 1) {
                                                setBypassLandingPage(false)
                                                document.body.classList.remove(
                                                    'app'
                                                )
                                                document.body.classList.add(
                                                    'landing-page'
                                                )
                                                navigate('/')
                                            }
                                            if (step === 2) {
                                                // setMobileNumber(modifiedMobileNumber)
                                                setMobileStatus('')
                                                setExpandedDropdowns(-1)
                                                setStep(1)
                                                setVerificationStatus('')
                                            }
                                        }}
                                    >
                                        <HiArrowNarrowLeft fontSize="1.1rem" />

                                        <Text
                                            px="5px"
                                            pb="1px"
                                            fontWeight="500"
                                            letterSpacing="0.5px"
                                            textShadow="1px 3px 15px rgba(0, 0, 0, 0.3)"
                                        >
                                            Go back to Options
                                        </Text>
                                    </Button>
                                )}
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </LightMode>
        </>
    )
}
