export default function uildAerodromeIcon(
    type,
    flightRule,
    runwayAzimuth,
    notam
) {
    const color = flightRule === 'IFR' ? '#92278f' : '#0072bc'
    const notamVisibility = notam && notam > 0 ? 'visible' : 'hidden'

    if (type === 'AD') {
        const svg =
            `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
      <g id="aerodrome-symbol-halo" fill="#fff">
  <rect id="aerodrome-symbol-halo-horizontal" x="2.25" y="9" width="19.5" height="6"/>
  <rect id="aerodrome-symbol-halo-vertical" x="9" y="2.25" width="6" height="19.5"/>
  <circle id="aerodrome-symbol-halo-circle" cx="12" cy="12" r="8"/>
 </g>
        <g id="aerodrome">
          <path
            id="aerodrome-symbol"
            fill="` +
            color +
            `"
            d="m9.9008 3v2.1229c-2.2883 0.69938-4.0797 2.4899-4.7801 4.7779h-2.1206v4.1996h2.1195c0.69813 2.2917 2.4904 4.0861 4.7812 4.7869v2.1128h4.1985v-2.1195c2.2917-0.69813 4.0861-2.4904 4.7869-4.7813h2.1139v-4.1985h-2.1172c-0.70048-2.2896-2.4934-4.0813-4.7835-4.7801v-2.1206z"
          ></path>
          <path
            id="aerodrome-center"
            fill="#fff"
            d="m17 12a5 5 0 1 1-5-5 5 5 0 0 1 5 5z"
          ></path>
        </g>
        <g id="runway"
        transform="rotate(` +
            runwayAzimuth +
            `, 12 12)"
        >
        <rect id="runway-halo" x="10.5" y=".25" width="3" height="23.5" fill="#fff"/>
        <rect id="runway" x="11.25" y="1" width="1.5" height="22" fill="` +
            color +
            `"/>
        </g>
        <circle id="notam" cx="18" cy="18" r="4" fill="#ff7f00" visibility="` +
            notamVisibility +
            `"/>
      </svg>`

        const encodedSvg = btoa(svg)

        return encodedSvg
    }

    if (type === 'HP') {
        const svg =
            `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
 <g id="heliport">
  <circle id="heliport-halo" cx="12" cy="12" r="8" fill="#fff"/>
  <path id="heliport-symbol" d="m12 19.2a7.2 7.2 0 1 1 7.2-7.2 7.2 7.2 0 0 1-7.2 7.2zm0-10.8a3.6 3.6 0 1 0 3.6 3.6 3.6 3.6 0 0 0-3.6-3.6z" fill="` +
            color +
            `"/>
  <path id="heliport-center" d="m17.4 12a5.4 5.4 0 1 1-5.4-5.4 5.4 5.4 0 0 1 5.4 5.4z" fill="#fff"/>
  <path id="heliport-h" d="m9.575 8.748v6.504h1.1v-2.702h2.65v2.7h1.1v-6.501h-1.1v2.703h-2.65v-2.704z" fill="` +
            color +
            `"/>
 </g>
 <circle id="notam" cx="18" cy="18" r="4" fill="#ff7f00" visibility="` +
            notamVisibility +
            `"/>
</svg>`

        const encodedSvg = btoa(svg)

        return encodedSvg
    }

    if (type === 'WD') {
        const svg =
            `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path id="waterdrome-halo" d="m8.9004 2v2.5918c-1.964 0.82971-3.4799 2.345-4.3105 4.3086h-2.5898v6.2012h2.5879c0.82917 1.9672 2.3463 3.485 4.3125 4.3164v2.582h6.1992v-2.5879c1.9666-0.82862 3.4864-2.3453 4.3184-4.3105h2.582v-6.2012h-2.5898c-0.83049-1.9643-2.346-3.4806-4.3105-4.3105v-2.5898zm2 2h2.1992v1.8613l0.70703 0.2168c1.9718 0.6028 3.514 2.1436 4.1172 4.1152l0.2168 0.70703h1.8594v2.2012h-1.8535l-0.2168 0.70703c-0.60463 1.9727-2.1472 3.5143-4.1211 4.1152l-0.70898 0.21484v1.8613h-2.1992v-1.8516l-0.70703-0.2168c-1.973-0.60381-3.5135-2.1475-4.1152-4.1211l-0.2168-0.70898h-1.8613v-2.2012h1.8594l0.2168-0.70703c0.60353-1.9708 2.1442-3.5106 4.1152-4.1133l0.70898-0.2168z" color="#000000" color-rendering="auto" dominant-baseline="auto" fill="#fff" image-rendering="auto" shape-rendering="auto" solid-color="#000000" stop-color="#000000" style="font-feature-settings:normal;font-variant-alternates:normal;font-variant-caps:normal;font-variant-east-asian:normal;font-variant-ligatures:normal;font-variant-numeric:normal;font-variant-position:normal;font-variation-settings:normal;inline-size:0;isolation:auto;mix-blend-mode:normal;shape-margin:0;shape-padding:0;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-orientation:mixed;text-transform:none;white-space:normal"/>
 <g id="waterdrome">
  <path id="waterdrome-symbol" d="m9.9 3v2.123a7.2 7.2 0 0 0-4.779 4.777h-2.121v4.202h2.121a7.2 7.2 0 0 0 4.78 4.785v2.113h4.199v-2.12a7.2 7.2 0 0 0 4.787-4.778h2.113v-4.202h-2.12a7.2 7.2 0 0 0-4.78-4.779v-2.121zm2.055 3.6a5.4 5.4 0 0 1 0.045 0 5.4 5.4 0 0 1 5.4 5.4 5.4 5.4 0 1 1-5.445-5.4z" fill="` +
            color +
            `"/>
  <path id="waterdrome-anchor" d="m13.419 13.001 0.644 0.625s-0.79 1.143-1.635 1.166c0.019 0 5e-3 -3.217 2e-3 -3.997 0.562-0.184 0.972-0.696 0.972-1.306 0-0.765-0.639-1.385-1.426-1.385-0.79 0-1.428 0.62-1.428 1.385 0 0.6 0.397 1.107 0.947 1.3l4e-3 3.99c-1.02-0.07-1.61-1.052-1.61-1.052l0.689-0.666-2.497-0.37 0.378 2.423 0.664-0.643c0.576 0.463 1.225 1.425 2.789 1.425 1.964-0.05 2.417-1.107 2.92-1.523l0.727 0.709 0.36-2.446zm-2.18-3.535c0-0.394 0.33-0.714 0.736-0.714s0.737 0.32 0.737 0.714c0 0.395-0.331 0.715-0.737 0.715a0.725 0.725 0 0 1-0.735-0.715z" fill="` +
            color +
            `"/>
 </g>
 <circle id="notam" cx="18" cy="18" r="4" fill="#ff7f00" visibility="` +
            notamVisibility +
            `"/>
</svg>`

        const encodedSvg = btoa(svg)

        return encodedSvg
    }

    return null
}
