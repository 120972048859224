import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { presentWeatherTranslate } from '../../../../../util/metDecode'
import PrecipitationIcon from '../../../../../icons/menu-icons/PrecipitationIcon'

export default function AwsPresentWeather(props) {
    const { presentWeather, infotabGraphicsDisabled } = props

    return (
        <Flex
            width="100%"
            background="light.10"
            boxShadow={
                !infotabGraphicsDisabled
                    ? '0px 6px 20px -5px rgba(20, 30, 40, 0.1)'
                    : 'none'
            }
            filter={
                infotabGraphicsDisabled
                    ? 'none'
                    : 'contrast(102%) saturate(102%)'
            }
            backdropFilter={
                infotabGraphicsDisabled
                    ? 'none'
                    : 'blur(25px) contrast(102%) saturate(102%)'
            }
            borderTopRadius={infotabGraphicsDisabled ? '0px' : '20px'}
            borderBottomRadius={'20px'}
            pt={'10px'}
            pl={{
                base: '15px',
                md: '15px',
            }}
            pr={{
                base: '15px',
                md: '15px',
            }}
            pb={infotabGraphicsDisabled ? '16px' : '10px'}
            direction={{
                base: 'column',
                md: 'row',
            }}
        >
            <Flex
                direction="row"
                justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                }}
            >
                <Flex
                    width={{
                        base: '41px',
                        md: '43px',
                    }}
                    ml="-2px"
                    color="#9DB2CD"
                >
                    <PrecipitationIcon boxSize="36px" />
                </Flex>
                <Flex direction="column">
                    <Text
                        fontSize={{
                            base: presentWeather.startsWith('/')
                                ? '0.9rem'
                                : '1.1rem',
                            md: presentWeather.startsWith('/')
                                ? '0.9rem'
                                : '1.1rem',
                        }}
                        lineHeight="1.2"
                        pt={{
                            base: '6px',
                            md: '6px',
                        }}
                        pb={{
                            base: '8px',
                            md: '8px',
                        }}
                        pl={infotabGraphicsDisabled ? '12px' : '5px'}
                        fontWeight="600"
                    >
                        {
                            presentWeatherTranslate(presentWeather)
                            //  .map((wx) =>
                            //      wxTranslate(
                            //        wx
                            //    )
                            //  )
                            //  .join(', ')
                        }
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
