import React from 'react'
import { useRecoilValue } from 'recoil'

import {
    mapLoadingAerodromeIconsState,
    mapLoadingStaticIconsState,
    statusData,
    modalVisibilityState,
    isBriefingOnlyState,
    mapDisabledState,
} from '../../globalState'

import DefineDataModeLayerSources from './functions/DefineDataModeLayerSources'
import ConstantLayers from './constant'
import AdminLayers from './admin'
import AeroLayers from './aero'
import MetLayers from './met'
import AdvsLayers from './advs'
import BriefLayers from './brief'

export default function Layers() {
    const status = useRecoilValue(statusData)

    const modalVisibility = useRecoilValue(modalVisibilityState)

    const mapLoadingAerodromeIcons = useRecoilValue(
        mapLoadingAerodromeIconsState
    )
    const mapLoadingStaticIcons = useRecoilValue(mapLoadingStaticIconsState)
    const mapDisabled = useRecoilValue(mapDisabledState)
    const isBriefingOnly = useRecoilValue(isBriefingOnlyState)

    // Waits for the last symbol to load before continuing
    if (
        !mapLoadingAerodromeIcons &&
        !mapLoadingStaticIcons &&
        status &&
        !modalVisibility.statusError
    ) {
        return (
            <>
                {!(isBriefingOnly || mapDisabled) && (
                    <>
                        <DefineDataModeLayerSources />
                        <ConstantLayers />
                        <AdminLayers />
                        <AeroLayers />
                        <MetLayers />
                        <AdvsLayers />
                        <BriefLayers />
                    </>
                )}
            </>
        )
    }

    return null
}
