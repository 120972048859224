import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function WipIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return activeColor
        } else {
            return baseColor
        }
    }

    return (
        <Icon boxSize={boxSize || 7} color={color || colorHandler()}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7284 2H12.2757C12.7079 2 13.0912 2.27772 13.2259 2.68845L17.8155 16.6867C16.0544 17.5286 14.0823 18 12.0002 18C9.9445 18 7.99615 17.5405 6.2522 16.7187L10.7766 2.693C10.8266 2.53811 10.9119 2.40193 11.0219 2.29221C11.0356 2.2785 11.0497 2.2652 11.0642 2.25233C11.2431 2.09357 11.4775 2 11.7284 2ZM15.9785 14.2933C14.7388 14.7504 13.3987 15 12.0002 15C10.6231 15 9.30254 14.7579 8.07871 14.314L9.00078 11.4556C9.93351 11.8075 10.9444 12 12.0002 12C13.0723 12 14.0981 11.8015 15.0427 11.4393L15.9785 14.2933ZM14.1062 8.58244L13.1642 5.76587C12.8063 5.91664 12.4131 5.99998 12.0003 5.99998C11.5876 5.99998 11.1944 5.91664 10.8365 5.76587L9.92383 8.59449C10.5645 8.85591 11.2656 8.99998 12.0003 8.99998C12.7463 8.99998 13.4575 8.85148 14.1062 8.58244Z"
                fill="currentColor"
            />
            <path
                d="M5.64355 16.977L6.23922 15.1305L2.89456 16.8028C2.52603 16.987 2.52603 17.513 2.89456 17.6972L11.1057 21.8028C11.6688 22.0843 12.3315 22.0843 12.8946 21.8028L21.1057 17.6972C21.4742 17.513 21.4742 16.987 21.1057 16.8028L17.8453 15.1726L18.2675 16.4602L18.3667 16.7629C18.387 16.8247 18.4009 16.8875 18.4088 16.9504C16.4886 17.9408 14.3097 18.5 12.0002 18.5C9.71153 18.5 7.55111 17.9508 5.64355 16.977Z"
                fill="currentColor"
            />
        </Icon>
    )
}
