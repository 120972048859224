import { Icon, useColorModeValue } from '@chakra-ui/react'

export default function RulerIcon(props) {
    const baseColor = useColorModeValue('light.500', 'dark.500')
    const activeColor = useColorModeValue('light.200', 'dark.200')

    const { boxSize, color, active } = props

    function colorHandler() {
        if (active) {
            return props.activeColor || activeColor
        } else {
            return props.baseColor || baseColor
        }
    }

    return (
        <Icon
            boxSize={boxSize || 7}
            viewBox="0 0 24 24"
            color={color || colorHandler()}
        >
            <path
                d="M3 17.3949L4.50141 15.902L5.70594 17.0895L6.60509 16.1989L5.40057 15.0028L6.60509 13.7983L8.70028 15.902L9.59943 15.0028L7.50424 12.8992L8.70028 11.7031L9.90481 12.8992L10.804 12L9.59943 10.804L10.804 9.59943L12.8992 11.7031L13.7983 10.804L11.7031 8.70028L12.8992 7.50424L14.0952 8.70028L15.0028 7.80113L13.7983 6.60509L15.0028 5.40057L17.0895 7.50424L17.9972 6.60509L15.902 4.50141L17.3949 3L21 6.60509L6.60509 21L3 17.3949Z"
                fill="currentColor"
            />
        </Icon>
    )
}
