import React, { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAdminRole } from '../../../auth/hooks'
import { Button, Text, Flex, Box, Tooltip } from '@chakra-ui/react'
import { isMobile, isDesktop } from 'react-device-detect'
import PremiumBadge from '../../controls/PremiumBadge'
import {
    setModalVisibilityState,
    showErrorTogglesState,
} from '../../../globalState'
export default function ImprRawToggle(props) {
    const { isPremium, selectedData, raw, setRaw, aaw, mfaExpired, tempATIS } =
        props
    const isAdmin = useAdminRole()
    const showErrorToggle = useRecoilValue(showErrorTogglesState)
    const setModalVisibility = useSetRecoilState(setModalVisibilityState)
    const setShowPricingTiersModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'pricingTiers', value })
        },
        [setModalVisibility]
    )

    const setShowResetMfaModal = useCallback(
        (value) => {
            setModalVisibility({ id: 'resetMfa', value })
        },
        [setModalVisibility]
    )

    const noFocus = {
        _focus: { boxShadow: 'none' },
        _focusVisible: { boxShadow: 'none !important' },
    }

    return (
        <Flex
            right="15px"
            marginTop="0px"
            width="max-content"
            alignContent="center"
        >
            {!tempATIS &&
                (!isPremium ? (
                    <>
                        {selectedData ? (
                            <Button
                                background="#F7FAFC"
                                color="#a8b2c0"
                                variant="outline"
                                fontSize="11px"
                                fontWeight="600"
                                height={{ base: '26px', md: '24px' }}
                                width={isPremium ? '100px' : '92px'}
                                style={{
                                    minWidth: '92px',
                                }}
                                opacity={isPremium ? '0.6' : '1'}
                                justifyContent="initial"
                                paddingLeft="2px"
                                paddingRight="2px"
                                py="2px"
                                borderRadius="20px"
                                {...noFocus}
                                onClick={() => {
                                    setRaw((prevState) => !prevState)
                                }}
                                _active={{
                                    background: '#F2F7FA',
                                    boxShadow:
                                        'inset 0px 0px 5px -2px rgba(0,0,0,0.2)',
                                }}
                                _hover={{
                                    background: '#F2F7FA',
                                }}
                            >
                                <Box
                                    mt="0px"
                                    ml="-1px"
                                    px="17px"
                                    height={{ base: '22px', md: '20px' }}
                                    width="44px"
                                    minWidth="44px"
                                    background="white"
                                    borderRadius="20px"
                                    bg="#ffffff"
                                    boxShadow="0px 0px 1px 0px rgba(0,0,0,0.35)"
                                    transform={
                                        raw
                                            ? 'translateX(0%)'
                                            : 'translateX(100%)'
                                    }
                                    transition="transform 300ms"
                                ></Box>
                                <Flex
                                    justifyContent="space-evenly"
                                    marginLeft="-44px"
                                    width="88px"
                                >
                                    <Tooltip
                                        label={'Standard Text'}
                                        hasArrow
                                        borderRadius="7px"
                                        height="26px"
                                        fontSize="0.7rem"
                                        alignItems="center"
                                        display={
                                            isMobile || !isDesktop
                                                ? 'none'
                                                : 'flex'
                                        }
                                        isDisabled={isMobile || !isDesktop}
                                        placement="top-start"
                                        m="5px"
                                        mr="7px"
                                        color="light.10"
                                        bg="dark.10"
                                    >
                                        <Flex
                                            zIndex="1"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="44px"
                                            opacity={raw ? '1' : '0.4'}
                                            color={
                                                raw ? 'light.200' : 'light.100'
                                            }
                                            transition="all ease 300ms"
                                        >
                                            <Text lineHeight="1.3">STD</Text>
                                        </Flex>
                                    </Tooltip>
                                    <Tooltip
                                        label={'Decoded Text'}
                                        hasArrow
                                        borderRadius="7px"
                                        height="26px"
                                        fontSize="0.7rem"
                                        alignItems="center"
                                        display={
                                            isMobile || !isDesktop
                                                ? 'none'
                                                : 'flex'
                                        }
                                        isDisabled={isMobile || !isDesktop}
                                        placement="top-start"
                                        m="5px"
                                        mr="2px"
                                        color="light.10"
                                        bg="dark.10"
                                    >
                                        <Flex
                                            zIndex="1"
                                            paddingLeft="2px"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="44px"
                                            opacity={!raw ? '1' : '0.4'}
                                            color={
                                                !raw ? 'light.200' : 'light.100'
                                            }
                                            transition="all ease 300ms"
                                        >
                                            <Text lineHeight="1.3">DECD</Text>
                                        </Flex>
                                    </Tooltip>
                                </Flex>
                            </Button>
                        ) : (
                            <Tooltip
                                label="Decoded text is temporarily unavailable"
                                hasArrow
                                borderRadius="7px"
                                height="26px"
                                fontSize="0.7rem"
                                alignItems="center"
                                display={
                                    isMobile || !isDesktop ? 'none' : 'flex'
                                }
                                placement="top"
                                m="0px"
                                mr="5px"
                                color="light.10"
                                bg="dark.10"
                            >
                                <Text
                                    cursor="default"
                                    zIndex="1"
                                    textAlign="right"
                                    paddingLeft="0px"
                                    paddingTop={{
                                        base: '0px',
                                        smd: '5px',
                                        md: '5px',
                                    }}
                                    lineHeight="1.2"
                                    fontSize={{
                                        base: '0.75rem',
                                        md: '0.8rem',
                                    }}
                                    color="light.300"
                                    opacity="0.6"
                                >
                                    Decoded text temporarily unavailable
                                </Text>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <>
                        <Flex
                            cursor="pointer"
                            border="1px solid"
                            borderColor="#E5EAF1"
                            background="#F7FAFC"
                            color="#a8b2c0"
                            variant="outline"
                            fontSize="11px"
                            fontWeight="600"
                            height="22px"
                            width={isPremium ? '100px' : '86px'}
                            style={{
                                minWidth: '86px',
                            }}
                            opacity={isPremium ? '0.6' : '1'}
                            justifyContent="initial"
                            paddingLeft="2px"
                            paddingRight="2px"
                            py="2px"
                            borderRadius="20px"
                            {...noFocus}
                            _hover={{
                                background: '#F2F7FA',
                            }}
                            onClick={() => {
                                if (mfaExpired) {
                                    setShowResetMfaModal(true) && setRaw(true)
                                } else {
                                    setShowPricingTiersModal(true) &&
                                        setRaw(true)
                                }
                            }}
                        >
                            <Text
                                zIndex="1"
                                pl="11px"
                                opacity={isPremium ? '0.4' : raw ? '1' : '0.4'}
                                color="light.100"
                                _selected={{
                                    opacity: '0.6',
                                }}
                                _active={{
                                    opacity: '0.6',
                                }}
                                transition="opacity 300ms"
                            >
                                Decoded Text
                            </Text>
                        </Flex>

                        <Flex // PREMIUM BADGE
                            position="absolute"
                            right={aaw ? '-3px' : '12px'}
                            top={
                                aaw
                                    ? '-5px'
                                    : isAdmin && showErrorToggle
                                    ? '61px'
                                    : '10px'
                            }
                        >
                            <PremiumBadge />
                        </Flex>
                    </>
                ))}

            {tempATIS && (
                <Text
                    cursor="default"
                    zIndex="1"
                    textAlign="right"
                    paddingLeft="0px"
                    paddingTop={{
                        base: '0px',
                        smd: '5px',
                        md: '5px',
                    }}
                    lineHeight="1.2"
                    fontSize={{
                        base: '0.75rem',
                        md: '0.8rem',
                    }}
                    color="light.300"
                    opacity="0.6"
                >
                    Decoded text unavailable
                </Text>
            )}
        </Flex>
    )
}
