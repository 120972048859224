import React, { useState, useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import {
    Center,
    Fade,
    Text,
    Flex,
    CircularProgress,
    CircularProgressLabel,
    Portal,
    Box,
    useBreakpointValue,
} from '@chakra-ui/react'
import Image from '../../../../../components/Image'

import TimeSlider from '../../../../met-imagery/image-loopers/components/TimeSlider'
import ZoomWrapper from './ZoomWrapper'

import formatDateTime from '../../../../../../util/dateFormatter'
import { timeZoneState } from '../../../../../../globalState'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { isEqual } from 'lodash'
import { isIOS, isMobileOnly } from 'react-device-detect'

export function Webcam(props) {
    const [times, setTimes] = useState([])
    const [playing, setPlaying] = useState(false)
    const [looping, setLooping] = useState(false)
    const [speed, setSpeed] = useState('1x')
    const [imageIdLoaded, setImageIdLoaded] = useState([])
    const [percentLoaded, setPercentLoaded] = useState(false)
    const [previousSelectedTime, setPreviousSelectedTime] = useState(null)

    const {
        data,
        webcamsModal,
        selectedTime,
        setSelectedTime,
        loaded,
        setLoaded,
        minimiseOnZoom,
        setMinimiseOnZoom,
        aerodromesFullscreen,
        webcamsModalFullscreen,
    } = props
    const timeZone = useRecoilValue(timeZoneState)

    const positionTimeStamp = webcamsModal || aerodromesFullscreen

    const imagesRef = useRef(null)
    //PORTAL REFERENCES
    const mobile = useRef()
    const desktop = useRef()
    const timeStampPlacement = useBreakpointValue({
        base: { containerRef: mobile },
        md: { containerRef: positionTimeStamp ? desktop : mobile },
    })

    useEffect(() => {
        if (data) {
            const timesArray = data.images
                .map((t) => t.imageTimestamp)
                .reverse()
            setTimes(timesArray)
        }
        if (!isEqual(imagesRef.current, data.images)) {
            imagesRef.current = data.images
        }
    }, [data])

    useEffect(() => {
        if (data) {
            const loadedArray = data.images.map((i) =>
                imageIdLoaded.includes(i.fileLocation)
            )
            const loaded = loadedArray.filter((i) => i)
            const percent = Math.round(
                (100 / data.images.length) * loaded.length
            )
            setPercentLoaded(percent)
        }
    }, [data, imageIdLoaded])

    useEffect(() => {
        if (data && data.images.length) {
            setLoaded(percentLoaded === 100)
        }
    }, [data, percentLoaded, setLoaded])

    function onImageLoad(id) {
        setImageIdLoaded((i) => [...i, id])
    }

    function firstImageLoaded() {
        return imageIdLoaded.includes(data.images[0].fileLocation)
    }

    return (
        <>
            <ZoomWrapper
                webcamsModal={webcamsModal}
                minimiseOnZoom={minimiseOnZoom}
                setMinimiseOnZoom={setMinimiseOnZoom}
                aerodromesFullscreen={aerodromesFullscreen}
                webcamsModalFullscreen={webcamsModalFullscreen}
                loaded={loaded}
            >
                <Flex position={'relative'} w="100%" h="auto">
                    {!loaded && (
                        <Center
                            position={'absolute'}
                            top={0}
                            left={0}
                            zIndex={14}
                            w="100%"
                            h={{ base: '230px', md: '270px' }}
                            direction={'column'}
                        >
                            <CircularProgress
                                value={percentLoaded}
                                size="50px"
                                thickness="6px"
                                color="light.200"
                            >
                                <CircularProgressLabel>{`${Math.round(
                                    percentLoaded
                                )}%`}</CircularProgressLabel>
                            </CircularProgress>
                        </Center>
                    )}
                    <Fade
                        key={`webcamImg${0}`}
                        in={
                            selectedTime === data?.images[0]?.imageTimestamp ||
                            !loaded
                        }
                        position="absolute"
                    >
                        <Flex
                            opacity={loaded ? 1 : 0.7}
                            position="absolute"
                            zIndex={() => {
                                if (
                                    selectedTime ===
                                    data.images[0].imageTimestamp
                                ) {
                                    return 7
                                } else if (
                                    previousSelectedTime ===
                                    data.images[0].imageTimestamp
                                ) {
                                    return 4
                                } else {
                                    return 1
                                }
                            }}
                        >
                            <Image
                                borderRadius="0px"
                                src={`${window.origin}/data/webcam/${data.id}?url=${data.images[0].fileLocation}`}
                                onLoad={() =>
                                    onImageLoad(data.images[0].fileLocation)
                                }
                            />
                        </Flex>
                    </Fade>
                    {firstImageLoaded() &&
                        data.images
                            .slice(1, data.images.length)
                            .map((timeSlice, idx) => (
                                <Fade
                                    key={`webcamImg${idx}`}
                                    in={
                                        (loaded &&
                                            selectedTime ===
                                                timeSlice.imageTimestamp) ||
                                        previousSelectedTime ===
                                            timeSlice.imageTimestamp
                                    }
                                    position="absolute"
                                >
                                    <Flex
                                        opacity={loaded ? 1 : 0.7}
                                        pointerEvents={
                                            loaded && percentLoaded === 100
                                                ? 'auto'
                                                : 'none'
                                        }
                                        position="absolute"
                                        zIndex={() => {
                                            if (
                                                selectedTime ===
                                                timeSlice.imageTimestamp
                                            ) {
                                                return 7
                                            } else if (
                                                previousSelectedTime ===
                                                timeSlice.imageTimestamp
                                            ) {
                                                return 4
                                            } else {
                                                return 1
                                            }
                                        }}
                                    >
                                        <Image
                                            borderRadius="0px"
                                            src={`${window.origin}/data/webcam/${data.id}?url=${timeSlice.fileLocation}`}
                                            onLoad={() =>
                                                onImageLoad(
                                                    timeSlice.fileLocation
                                                )
                                            }
                                        />
                                    </Flex>
                                </Fade>
                            ))}
                </Flex>
            </ZoomWrapper>

            <Flex
                opacity={loaded && percentLoaded === 100 ? '1' : '0.2'}
                pointerEvents={
                    loaded && percentLoaded === 100 ? 'auto' : 'none'
                }
                flexDirection="column"
                zIndex="1"
                pb={{
                    base:
                        minimiseOnZoom && !webcamsModal
                            ? '5px'
                            : positionTimeStamp
                            ? '5px'
                            : '72px',
                    md: positionTimeStamp ? '20px' : '10px',
                    ml: aerodromesFullscreen ? '0px' : '10px',
                }}
                position={{
                    base: 'relative',
                    md: positionTimeStamp ? 'absolute' : 'relative',
                }}
                bottom={{
                    base: 'unset',
                    md: webcamsModal
                        ? '15px'
                        : positionTimeStamp
                        ? '30px'
                        : 'unset',
                    ml: webcamsModal
                        ? '25px'
                        : positionTimeStamp
                        ? '35px'
                        : 'unset',
                }}
                right={{
                    base: 'unset',
                    md: positionTimeStamp ? '35px' : 'unset',
                }}
                width={{
                    base: '100%',
                    md: positionTimeStamp ? 'calc(100% - 70px)' : '100%',
                }}
                minHeight={{
                    base: '10px',
                    md: '10px',
                }}
                mt={{
                    base: '10px',
                    md: '10px',
                }}
                transition="all ease 400ms"
            >
                <Portal
                    style={{
                        height: '100%',
                    }}
                    {...timeStampPlacement}
                >
                    <Flex
                        flexDirection={{
                            base: 'column',
                            xs: 'row',
                            ml: 'row',
                        }}
                        alignItems="center"
                        justifyContent="center"
                        pt={{
                            base: '6px',
                            xs: '7px',
                            md: !positionTimeStamp && '7px',
                        }}
                        mt={{
                            base: '-54px',
                            xs: '-42px',
                            md: positionTimeStamp ? '0px' : '-42px',
                        }}
                        w="100%"
                        height={{
                            base: '44px',
                            xs: '32px',
                        }}
                        pb={{
                            base: '6px',
                            xs: '7px',
                            md: positionTimeStamp ? '8px' : '7px',
                        }}
                        bg={{
                            base: 'rgba(255,255,255,0.5)',
                            md: positionTimeStamp
                                ? 'none'
                                : 'rgba(255,255,255,0.5)',
                        }}
                        backdropFilter={{
                            base: !aerodromesFullscreen ? 'blur(2px)' : 'none',
                            md: !positionTimeStamp ? 'blur(2px)' : 'none',
                        }}
                        textShadow={
                            !aerodromesFullscreen
                                ? '1px 1px 3px rgba(255, 255, 255, 0.25)'
                                : 'none'
                        }
                        transition="all ease 250ms"
                    >
                        <Text
                            px="10px"
                            align={'center'}
                            color={{
                                base: 'light.900',
                                md: positionTimeStamp
                                    ? 'light.10'
                                    : 'light.100',
                            }}
                            fontSize="0.8rem"
                            fontWeight="500"
                            whiteSpace="pre"
                        >
                            {selectedTime &&
                                formatDateTime(selectedTime, timeZone)}
                        </Text>
                        <Text
                            px="10px"
                            align={'center'}
                            fontSize="0.8rem"
                            color={{
                                base: 'light.900',
                                md: positionTimeStamp
                                    ? 'light.10'
                                    : 'light.100',
                            }}
                            fontWeight="300"
                            whiteSpace="pre"
                        >
                            {selectedTime &&
                                parseISO(selectedTime) &&
                                formatDistanceToNowStrict(
                                    parseISO(selectedTime),
                                    {
                                        addSuffix: true,
                                        unit: 'minute',
                                    }
                                )}
                        </Text>
                    </Flex>
                </Portal>
                <Box ref={mobile}></Box>
                <Flex
                    direction={'column'}
                    position="relative"
                    w="100%"
                    height="auto"
                    px={{
                        base: minimiseOnZoom ? '20px' : '5px',
                        md: '0px',
                    }}
                    pb={positionTimeStamp && isIOS && isMobileOnly && '10px'}
                    transition="all ease 400ms"
                >
                    <TimeSlider
                        style={{
                            width: 'calc(100% - 10px)',
                        }}
                        times={times}
                        selectedTime={selectedTime}
                        setSelectedTime={setSelectedTime}
                        setPreviousSelectedTime={setPreviousSelectedTime}
                        speed={speed}
                        setSpeed={setSpeed}
                        playing={playing}
                        setPlaying={setPlaying}
                        looping={looping}
                        setLooping={setLooping}
                        mostRecent={true}
                        positionTimeStamp={positionTimeStamp}
                        desktop={desktop}
                    />
                </Flex>
            </Flex>
        </>
    )
}
