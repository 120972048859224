import React from 'react'
import { useRecoilValue } from 'recoil'
import { GeoJSONLayer } from 'react-mapbox-gl'

import { VectorLayerRefreshInfo } from '../../../globalState'

export default function IcaoWaypointsLayer() {
    const layerData = useRecoilValue(VectorLayerRefreshInfo)

    return (
        <>
            <GeoJSONLayer
                id="icaoWaypoints"
                data={layerData.icaoWaypoints.dataURL}
                before="aerodromes-symbol"
                symbolLayout={{
                    'icon-image': 'waypoint-icao',
                    'text-field': ['get', 'designator'],
                    'text-font': [
                        'Roboto Medium',
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold',
                    ],
                    'text-offset': [0, 0.85],
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        4,
                        0.3,
                        6,
                        0.3,
                        8,
                        0.3,
                        10,
                        0.4,
                        16,
                        0.5,
                    ],
                    'icon-anchor': 'center',
                    'text-anchor': 'top',
                    'text-size': 10,
                }}
                symbolPaint={{
                    'text-color': '#333333',
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 0.8,
                }}
            />
        </>
    )
}
