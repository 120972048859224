import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Flex, Button, Icon, Spinner, Tooltip } from '@chakra-ui/react'

// import { BsPrinter } from 'react-icons/bs'
import { ReactComponent as PdfDownloadIcon } from '../../../../../icons/assets/pdf-file-download.svg'
import {
    briefingParamsState,
    mobileDrawerExpandedState,
    // printPromiseState,
    downloadPromiseState,
    pdfShowState,
    onlineState,
} from '../../../../../globalState'

export default function ExportOptions(props) {
    const { dataLoading } = props
    const mobileDrawerExpanded = useRecoilValue(mobileDrawerExpandedState)
    const setDownloadPromise = useSetRecoilState(downloadPromiseState)
    // const [printPromise, setPrintPromise] = useRecoilState(printPromiseState)
    const pdfState = useRecoilValue(pdfShowState)
    const params = useRecoilValue(briefingParamsState)
    const online = useRecoilValue(onlineState)
    const allItemsDeselected =
        !params.aerodromes &&
        !params.aerodromesNotam &&
        !params.aerodromesMetar &&
        !params.aerodromesTaf &&
        !params.aerodromesAtis &&
        !params.enroute &&
        !params.aaw &&
        !params.grafor &&
        !params.msl &&
        !params.sigmet &&
        !params.sigwx &&
        !params.spacewx

    return (
        <Flex // PRINT AND SAVE OPTIONS
            transition="all ease-in-out 300ms"
            position="relative"
            mt={{ base: mobileDrawerExpanded ? '15px' : '0px', ml: '0px' }}
            mb={{ base: mobileDrawerExpanded ? '40px' : '0px', ml: '0px' }}
            height={{
                base: '34px',
                ml: '40px',
            }}
            // opacity={{
            //     base: mobileDrawerExpanded ? '1' : '0',
            //     md: '1',
            // }}
            // visibility={{
            //     base: mobileDrawerExpanded ? 'visibile' : 'hidden',
            //     md: 'visible',
            // }}
            w="100%"
            zIndex="1"
            justifyContent="center"
            alignItems="center"
        >
            {/* <Tooltip
                label="Print"
                hasArrow
                borderRadius="7px"
                height="22px"
                fontSize="0.7rem"
                alignItems="center"
                display="flex"
                placement="bottom"
                m="5px"
                color="light.10"
                bgColor={'gray.500'}
            >
                <Button // PRINT BUTTON
                    display={{ base: 'none', md: 'inline-flex' }}
                    background={{ base: 'light.20', md: 'light.10' }}
                    color="light.100"
                    opacity="0.9"
                    fontWeight="600"
                    transition="all ease 300ms"
                    disabled={dataLoading || allItemsDeselected}
                    height={{
                        base: '30px',
                        md: '30px',
                    }}
                    width={{ base: '100%', md: '34px' }}
                    minWidth={{ base: 'auto', md: '34px' }}
                    justifyContent="center"
                    alignItems="center"
                    paddingInlineStart="0px"
                    paddingInlineEnd="0px"
                    mr={{
                        base: '12px',
                        md: '7px',
                    }}
                    onClick={() => {
                        setPrintPromise(true)
                    }}
                    _focus={{
                        background: { base: 'light.30', md: 'light.20' },
                        opacity: '1',
                    }}
                    _active={{
                        background: { base: 'light.30', md: 'light.20' },
                        opacity: '1',
                    }}
                    _hover={{
                        background: { base: 'light.30', md: 'light.20' },
                        opacity: '1',
                        cursor:
                            dataLoading || allItemsDeselected
                                ? 'not-allowed'
                                : 'pointer',
                    }}
                    borderColor="#eef1f5"
                    border={{
                        base: mobileDrawerExpanded
                            ? '1px solid #eef1f5'
                            : 'none',
                        md: '1px solid #eef1f5',
                    }}
                    borderRadius={{ base: '15px', md: '10px' }}
                    fontSize="0.9rem"
                >
                    {printPromise ? (
                        <Flex justifyContent="center" alignItems="center">
                            <Spinner size="sm" />
                        </Flex>
                    ) : (
                        <Flex justifyContent="center" alignItems="center">
                            <Icon
                                width={{ base: '22px', md: '18px' }}
                                height={{
                                    base: mobileDrawerExpanded ? '22px' : '0px',
                                    md: '18px',
                                }}
                                transition="all ease 300ms"
                                as={BsPrinter}
                            />
                            <Text
                                ml="5px"
                                display={{ base: 'initial', md: 'none' }}
                            >
                                Print
                            </Text>
                        </Flex>
                    )}
                </Button>
            </Tooltip> */}

            <Tooltip
                label="Save to PDF"
                hasArrow
                borderRadius="7px"
                height="22px"
                fontSize="0.7rem"
                alignItems="center"
                display="flex"
                placement="bottom"
                m="5px"
                color="light.10"
                bgColor={'gray.500'}
            >
                <Button // SAVE BUTTON
                    zIndex="2"
                    onClick={() => {
                        setDownloadPromise(true)
                    }}
                    isDisabled={!online || dataLoading || allItemsDeselected}
                    transition="all ease 300ms"
                    ml={{
                        // base: '12px', // disabled while print button disabled on mobile
                        base: '0px',
                        md: '0px',
                    }}
                    _hover={{
                        opacity: '1',
                        cursor:
                            !online || dataLoading || allItemsDeselected
                                ? 'not-allowed'
                                : 'pointer',
                    }}
                    _active={{ background: 'none' }}
                    _focus={{ background: 'none' }}
                    background="none"
                    variant="outline"
                    paddingInlineStart="0px"
                    paddingInlineEnd="0px"
                    color="light.100"
                    opacity={'0.9'}
                    border="none"
                    fontSize="10px"
                    fontWeight="600"
                    height="40px"
                    width="40px"
                    alignItems="center"
                    justifyContent="center"
                    paddingLeft="2px"
                    paddingRight="2px"
                    borderRadius="10px"
                >
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        border={{
                            base: 'none',
                            ml: 'none',
                        }}
                        borderRadius="10px"
                        color="light.100"
                        variant="outline"
                        fontSize="1rem"
                        height={{
                            base: '40px',
                            ml: '32px',
                        }}
                        width={{
                            base: '40px',
                            ml: '36px',
                        }}
                        minWidth={{
                            base: '40px',
                            ml: '36px',
                        }}
                        transition="transform ease 50ms"
                        _focus={{
                            opacity: '1',
                            background: 'light.20',
                        }}
                        _hover={{
                            opacity: '1',
                            background: 'light.10',
                            boxShadow: '0px 4px 10px -3px rgba(10,20,30,0.35)',
                            transform: {
                                base: 'none',
                                md: 'scale(1.05) translateY(-1px)',
                            },
                        }}
                        _active={{
                            background: 'light.40',
                            boxShadow: '0px 3px 7px -2px rgba(0,0,0,0.3)',
                        }}
                    >
                        {pdfState.loading ? (
                            <Flex justifyContent="center" alignItems="center">
                                <Spinner
                                    boxSize="20px"
                                    color="light.200"
                                    emptyColor={'gray.100'}
                                />
                            </Flex>
                        ) : (
                            <Flex justifyContent="center" alignItems="center">
                                <Icon
                                    width={{ base: '26px', ml: '22px' }}
                                    height={{
                                        base: '26px',
                                        ml: '22px',
                                    }}
                                    transition="all ease 300ms"
                                    as={PdfDownloadIcon}
                                />
                                {/* <Text
                                ml="5px"
                                display={{ base: 'initial', md: 'none' }}
                            >
                                Save to PDF
                            </Text> */}
                            </Flex>
                        )}
                    </Flex>
                </Button>
            </Tooltip>
        </Flex>
    )
}
